import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { deletePreviewTemplateData } from "../../../store/contractManagement/slices/templateData";

import { updateHeaderMenu, updateMenu } from "../../../store/slices/menu";
import OnlyOfficeTextEditor from "../../../common/textEditor/OnlyOfficeTextEditor";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { updateLoader } from "../../../store/slices/loader";
import {
  getGetVCWorkflowProcessesData,
  updateCmWorkFlowInstance,
} from "../../../store/contractManagement/slices/contractRolesAndResponsiblity";
import { useSelector } from "react-redux";
import { getUser } from "../../../store/slices/userData";
import { CMWorflowPages, vendorassetroles } from "../../../config/config";
import { useParams } from "react-router-dom";
import { updaterolesandresponsAddandEdit } from "../../../store/slices/rolesAndResponsiblity";

interface OnlyOfficeTextEditorCM {
  isEditData: any;
  onClose: any;
  processName: any;
  executeVendorWF?: any;
}

const OnlyOfficeTextEditorCM: React.FC<OnlyOfficeTextEditorCM> = ({
  isEditData = null,
  onClose,
  processName,
  executeVendorWF = false,
}) => {
  const dispatch = useAppDispatchThunk();
  const { projectId, workflowInstanceId } = useParams();

  const [previewId, setPreviewId] = useState<number>(0);
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    isRolesAndResponsibilityAdded,
    isRolesAndResponsibilityUpdated,
    isLoading,
    isRolesAndResponsibilityDeleted,
    workFLowInstanceData,
  }: any = contractManagementReducer.contractRolesAndResponsibility;
  const { userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { user } = useSelector((state: RootState) => state.userData);
  const [contractData, setContractData] = useState<any>(null);
  const [filename, setFileName] = useState<string>("");
  const [getUserRole, setGetUserRole] = useState<any>();
  const [isGobacktoSmeReview, setIsGoBAckTOSMEReview] = useState<any>(null);
  useEffect(() => {
    if (isEditData !== null && !executeVendorWF) {
      let request: any = null;
      if (isEditData.ProjectId) {
        request = {
          projectId: isEditData?.ProjectId,
          workflowInstanceId: isEditData?.Workflow_InstanceId,
        };
      }
      dispatch(updateLoader(true));
      dispatch(getGetVCWorkflowProcessesData(request));
    }
  }, [isEditData]);

  useEffect(() => {
    if (userLoginResponse?.UserId) {
      const userId = userLoginResponse.UserId;
      dispatch(getUser({ UserId: userId }));
    }
  }, [userLoginResponse]);

  // useEffect(()=>{
  //   if(isGobacktoSmeReview !==null){
  //     console.log(isGobacktoSmeReview)
  //     //setIsGoBAckTOSMEReview({...isGobacktoSmeReview})
  //   }
  // },[isGobacktoSmeReview])

  const gobackReview = (data: any) => {
    setTimeout(() => {
      setIsGoBAckTOSMEReview(data);
    }, 100);
  };

  let role = userLoginResponse?.UserRole ? userLoginResponse?.UserRole : "";
  useEffect(() => {
    if (workFLowInstanceData !== null) {
      let { REACT_APP_API_URL, REACT_APP_API_CM_URL } = process.env;
      let workFlowInstanceProcessData = workFLowInstanceData?.workflowInstance?.workflowProcessInstances;
      let index =
        workFlowInstanceProcessData.length > 0
          ? workFlowInstanceProcessData.findIndex((dataItem: any) => dataItem.id === isEditData.Id)
          : -1;
      let showRedlineForVendor = false;
      let showRedlineForDoReview = false;
      if (index > -1) {
        showRedlineForVendor = workFlowInstanceProcessData[index - 1].processName === "Vendor Portal";
      }

      if (index > -1) {
        showRedlineForVendor =
          workFlowInstanceProcessData[index + 1].processName === "DO Review" &&
          workFlowInstanceProcessData[index + 1].status === 319;
      }
      setContractData(workFLowInstanceData);
      setFileName(workFLowInstanceData?.contract_Tempate_Name);
      localStorage.setItem("contract_Tempate_Id", `${workFLowInstanceData?.contract_Tempate_Id}`);
      console.log(role, "rolerolerole");
      localStorage.setItem("formName", isEditData?.FormName);
      localStorage.setItem("USER_ROLE_KEY", role);
      localStorage.setItem("processName", processName);
      localStorage.setItem("Show", isEditData.ConditionName);
      localStorage.setItem("APIURL", REACT_APP_API_URL ? REACT_APP_API_URL : "");
      localStorage.setItem("CMAPIURL", REACT_APP_API_CM_URL ? REACT_APP_API_CM_URL : "");
      localStorage.setItem("ShowRedline", showRedlineForVendor || showRedlineForDoReview ? "true" : "false");

      if (user) {
        const formattedUser: any = {
          UserId: user.UserId,
          FirstName: user.FirstName,
          LastName: user.LastName,
          EmailId: user.EmailId,
          Mobile: user.Mobile,
          Country: user.Country,
          OfficeLocation: user.OfficeLocation,
          OfficeAddress: user.OfficeAddress,
          IsActive: user.IsActive,
          RoleId: user.RoleId,
          RoleName: user.RoleName,
          DepartmentId: user.DepartmentId,
          DepartmentName: user.DepartmentName,
          ManagerId: user.ManagerId,
          ManagerEmail: user.ManagerEmail,
          Updated_By: user.Updated_By,
          Updated_On: user.Updated_On,
          DesignationId: user.DesignationId,
          DesignationName: user.DesignationName,
        };
        localStorage.setItem("userData", JSON.stringify({ userData: formattedUser }));
      }
      const vendorPortal = {
        projectId: projectId,
        workflowInstanceId: workflowInstanceId,
        processInstanceId: "processInstanceId",
        company_Name: workFLowInstanceData?.company_Name || "",
        company_Id: workFLowInstanceData?.company_Id || null,
        vendor_Id: workFLowInstanceData?.vendor_Id || null,
        vendor_Name: workFLowInstanceData?.vendor_Name || "",
        vendor_Contract_Id: workFLowInstanceData?.vendor_Contract_Id || null,
        contract_Ref_Number: workFLowInstanceData?.contract_Ref_Number || "",
        contract_Tempate_Id: workFLowInstanceData?.contract_Tempate_Id || null,
        project_Cost: `${workFLowInstanceData?.currency_code || "₹"} ${workFLowInstanceData?.project_Cost || "0.00"}`,
        service_Names: "",
        payloadForUpdate: {},
      };

      console.log(isEditData, workFLowInstanceData, "EditData~workFLowInstanceData");

      // const services = workFLowInstanceData?.serviceMappings || [];
      // const serviceNames = Array.isArray(services)
      //   ? services.map((service) => service?.service_Name).join(", ")
      //   : "No Services Available";

      const services = workFLowInstanceData?.serviceMappings || [];
      const serviceNames = Array.isArray(services)
        ? services.map((service) => service?.services?.service_Name).join(", ")
        : "";

      vendorPortal.service_Names = serviceNames;
      vendorPortal.payloadForUpdate = {
        workflowInstanceId: isEditData?.workflowInstanceId,
        processInstanceId: isEditData?.id,
        conditionValue: 0,
        projectId: projectId,
        updatedBy: userLoginResponse ? userLoginResponse.UserName : "",
      };

      processName === CMWorflowPages.VendorPortal &&
        localStorage.setItem("Vendor_Portal", JSON.stringify(vendorPortal));
      localStorage.setItem("contract_Tempate_Id", `${workFLowInstanceData?.contract_Tempate_Id}`);
    }
  }, [workFLowInstanceData, isEditData]);
  console.log(localStorage.getItem("Vendor_Portal"), "Vendor_Portal");

  // const gobackReview = (data:any) =>{
  //   ()=>{
  //     setIsGoBAckTOSMEReview(data);
  //   }
  // }
  return (
    <React.Fragment>
      {filename ? (
        <Box
          sx={{
            position: "relative",
          }}
        >
          {processName !== CMWorflowPages.VendorPortal && (
            <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
              <CustomButton
                variant="outlined"
                name={"Back"}
                onClick={async () => {
                  // await dispatch(deletePreviewTemplateData(previewId));
                  // dispatch(updateHeaderMenu(true));
                  // dispatch(updateMenu(true));
                  dispatch(updaterolesandresponsAddandEdit(false));
                  if (onClose) {
                    if (isGobacktoSmeReview !== null) {
                      let request = {
                        workflowInstanceId: isEditData?.Workflow_InstanceId,
                        processInstanceId: isEditData.Id,
                        // based on Contion we need to send 1  0r 0  for boardApprovalNeeded we send  vendoe Id
                        conditionValue:
                          isEditData?.ConditionName === "IsBoardApprovalNeeded"
                            ? contractData?.vendor_Contract_Id
                            : isGobacktoSmeReview?.isGoBack
                            ? 1
                            : 0,
                        projectId: isEditData.ProjectId,
                        updatedBy: userLoginResponse ? userLoginResponse.UserName : "",
                        DepartmentId: userLoginResponse ? String(userLoginResponse.Department) : ""
                      };
                      let data = await dispatch(updateCmWorkFlowInstance(request)).unwrap();
                      if (data) {
                        onClose();
                      }
                    } else {
                      onClose();
                    }
                  }
                }}
                size="small"
              />
            </Box>
          )}
          {filename && (
            <OnlyOfficeTextEditor
              fileName={`${filename}.docx`}
              editorsMode={processName === CMWorflowPages.SOReview ? "edit" : "review"}
              isOpenDocument={true}
              updateGobacktoReview={gobackReview}
            />
          )}
        </Box>
      ) : (
        <p>loading</p>
      )}
    </React.Fragment>
  );
};

export default OnlyOfficeTextEditorCM;
