import { Box, Grid, Typography } from "@mui/material";
import { textFieldWidth } from "../../../utils/constants";
import LableandValuesPreview from "../../../common/CommonPreview/LableandValuesPreview";
import dayjs from "dayjs";



interface RequirementDetailsprops {
  data: any;

}

export const RequirementDetails: React.FC<RequirementDetailsprops> = ({ data }) => {

  return (

    <>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography
          sx={{
            height: "12px",
            color: "#959595",
            display: "block",
            fontSize: "15px",
            fontWeight: "bold",
            textAlign: "left",
          }}
        >
          Requirement Details
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <LableandValuesPreview lable="Clause Ref Number" Value={data.clause_reference_no || `-`} />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <LableandValuesPreview lable="Clause Header" Value={data.clause_Header || `-`} />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <LableandValuesPreview lable="Workflow" Value={data.WorkFlow || `-`} />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <LableandValuesPreview lable="Scope of Target" Value={data.scope_Of_Target || `-`} />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <LableandValuesPreview lable="Deliverables" Value={data.deliverables || `-`} />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <LableandValuesPreview lable="SME Review" Value={ `-`} />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <LableandValuesPreview lable="Responder" Value={data.input_Dependency_Name || `-`} />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <LableandValuesPreview lable="Review Period" Value={data.review_End_Date
              ? `${dayjs(data.review_Start_Date).format("DD/MM/YYYY")} - ${dayjs(data.review_End_Date).format("DD/MM/YYYY")}`
              : "-"
            } />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <LableandValuesPreview lable="Review Due By" Value={data.review_End_Date ? dayjs(data.review_End_Date).format("DD/MM/YYYY") : `-`} />
          </Grid>

        </Grid>
      </Box>

    </>
  )

}