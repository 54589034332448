import React, { createContext, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import CustomStepper from "../../common/dynamicSteper/CustomStepper";
import { AddVendorDetails } from "../../components/vendorDetails/AddVendor";
import { Response } from "./Response";
import CustomButton from "../../common/dynamicButton/CustomButton";
import vendorDetailsService from "../../store/services/vendorDetails";
import { setTabs } from "../../store/slices/tabsData";
import {
  addVendorAssessment,
  getVendorAssessment,
  updateVendorAssessmentAddandEdit,
} from "../../store/slices/vendorAssessment";
import {
  ConvertGetData,
  validateAssessmentAll,
} from "../../components/vendorOnBoarding/VendorOnBoardingPreview/AssessmentValidation";
import { useParams } from "react-router-dom";
import { MenuIdForVendoruse, ToasterActivityConfig, VOBWorflowPages } from "../../config/config";
import { addVendorDetails, getVendorDetailsList } from "../../store/slices/vendorDetails";
import { AddVendorConsentForm } from "../../components/consentForms/AddVendorConsentForm";
import workFlowService from "../../store/services/workFlowService";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import CustomSvg from "../../common/CustomSvg";
import { getRiskRateList } from "../../store/slices/riskRateMasterData";
import { updateContentAddandEdit } from "../../store/slices/contentMasterData";
import { getApplicationParameterList } from "../../store/slices/applicationParameterData";
import CustomSucessBanner from "../../common/dynamicMessageBanner/CustomSucessBanner";

interface assesmentContextProps {
  selectedCatagoryPreview: any[];
  validationErrors: any;
  setselectedCatagoryPreview: (value: any) => void;
  setvalidationErrors: (value: any) => void;
}

export const AssessmentVendorDetailContext = createContext<assesmentContextProps | undefined>(undefined);

export const AqmResponse: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isVendorConsentFormAdded, isVendorConsentLoading, vendorConsentToasterMsg } = useSelector(
    (state: RootState) => state.vendorConsentData
  );

  const { vendorDetailsdata } = useSelector((state: RootState) => state.vendorDetails);
  const {
    VendorAssessmentdata,
    isVendorAssessmentAdded,
    isVendorAssessmentSuccess,
    isAddEditVendorAssessmentLoading,
    VendorAssessmentToasterMsg,
  } = useSelector((state: RootState) => state.vendorAssessmentData);
  const { userLoginResponse, isVendorAuthenticated, isLoading, vendorLoginDetails } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { vendor_persional_Id, Project_Details_Id } = useParams();
  const todayDate = new Date().toISOString().split("T")[0];
  const main_steps = ["Vendor Details", "Assessment"];
  const [activestepCount, setactivestepCount] = useState(0);
  const [isAqmResponse, setIsAqmResponse] = useState(true);
  const [isEditData, setIsEditDate] = useState<any>(null);
  const [selectedCatagoryPreview, setselectedCatagoryPreview] = useState<any[]>([]);
  const [validationErrors, setvalidationErrors] = useState<any>({});
  const [assessmentData, setassessmentData] = useState<any>(null);
  const [vendorMainId, setvendorMainId] = useState<any>(null);
  const [currentProcessData, setcurrentProcessData] = useState<any>(null);
  const [instanceValue, setInstanceValue] = useState<any>(null);
  const [getVendorData, setGetVendorData] = useState<any>("");

  const [currentPage, setcurrentPage] = useState<any>("");
  const [consentOk, setconsentOk] = useState<any>(false);

  const handleNextVendor = async (value: any) => {
    console.log(value, "value");
    setIsEditDate(value);
    try {
      if (value) {
        const reqObj = {
          ModuleName: "Vendor Master",
          Activity: ToasterActivityConfig.Edit,
          MenuId: 0,
          Form_Id: 22,
          UserId: userLoginResponse?.UserId,
          UpdatedBy: value?.VendorDetails?.EmailId,
          Workflow_InstanceId : currentProcessData ? currentProcessData?.Workflow_InstanceId : 0 ,
          IsEditByVendor : true ,
          ...value ,
        };
        console.log(getVendorData, value, "getVendorData");

        dispatch(updateLoader(true));
        const response: any = await vendorDetailsService.addVendorDetails(reqObj);
        // const response : any = callVendorDetailsandSubmit(reqObj);
        dispatch(updateLoader(false));
        let vendorResponseData: any = response.data?.toasterMessage;
        console.log(vendorResponseData, "vendorResponseData", response);
        if (vendorResponseData?.Status == "Success") {
          if (vendorMainId) {
            callVendorDetails(vendorMainId);
          }
          setactivestepCount((prevCount) => prevCount + 1);
        } else {
          dispatch(updateLoader(false));
          dispatch(
            updateToaster({
              isToaster: true,
              toasterMsg: vendorResponseData?.Message,
              isTosterFailed: true,
            })
          );
        }
      }
    } catch {
      dispatch(updateLoader(false));
    }
  };

  const handlePrevious = () => {
    dispatch(setTabs(2));
    setactivestepCount((prevCount) => prevCount - 1);
  };

  useEffect(() => {
    dispatch(
      getRiskRateList({
        FormID: 2,
      })
    );
    dispatch(
      getApplicationParameterList({
        FormID: 2,
      })
    );
  }, []);

  useEffect(() => {
    if (isVendorConsentFormAdded) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: vendorConsentToasterMsg }));
      dispatch(updateContentAddandEdit(false));
      checkFlowCompleted(vendorMainId);
    }
  }, [isVendorConsentFormAdded]);

  useEffect(() => {
    if (isVendorAssessmentAdded) {
      dispatch(updateVendorAssessmentAddandEdit(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: VendorAssessmentToasterMsg,
          isTosterFailed: isVendorAssessmentSuccess === false ? true : false,
        })
      );

      if (isVendorAssessmentSuccess !== false) {
        setcurrentPage("Page sucess");
      }
    }
  }, [isVendorAssessmentAdded]);

  useEffect(() => {
    dispatch(updateLoader(isVendorConsentLoading || isAddEditVendorAssessmentLoading));
  }, [isVendorConsentLoading, isAddEditVendorAssessmentLoading]);

  const consentFormAccept = () => {
    setconsentOk((pre: any) => true);
    // checkFlowCompleted(vendorMainId, true);
  };

  useEffect(() => {
    if (vendorMainId) {
      callVendorDetails(vendorMainId);
    }
  }, [vendorMainId]);

  useEffect(() => {
    if (vendorMainId && currentProcessData && currentProcessData?.ProjectId) {
      dispatch(
        getVendorAssessment({
          Vendor_Id: Number(vendorMainId),
          RoleId: 0,
          UserId: 0,
          IsSmeReview: 0,
          Project_Detail_Id: currentProcessData?.ProjectId,
        })
      );
    }
  }, [vendorMainId, currentProcessData, dispatch]);

  useEffect(() => {
    let data: any = VendorAssessmentdata;
    console.log(data);

    if (data && data != undefined && data != null) {
      console.log(data, "ass data");

      setassessmentData(data);
      // convert get method is maintained from separate file check inport
      let updatedCategory: any = ConvertGetData(VendorAssessmentdata);
      setselectedCatagoryPreview(updatedCategory);
    }
  }, [VendorAssessmentdata]);

  useEffect(() => {
    if (isVendorAuthenticated && vendorLoginDetails && vendorLoginDetails?.vendorId) {
      setvendorMainId(vendorLoginDetails?.vendorId);
      checkFlowCompleted(vendorLoginDetails?.vendorId);
      setGetVendorData(vendorLoginDetails);
    }
  }, [isVendorAuthenticated]);

  const checkFlowCompleted = async (vId: any, consentok?: any) => {
    try {
      if (vId) {
        const instanceValue = await workFlowService.getWorkflowInstanceByVendorId({
          vendorId: vId,
          Project_Detail_Id: Project_Details_Id,
        });
        let resposseData = instanceValue?.data;
        console.log(instanceValue?.data, "Vendor_id");

        setInstanceValue(resposseData);
        if (resposseData?.Id) {
          callSetCurrentPage(resposseData, consentok);
        }
      }
    } catch (error) {
      console.error("Error fetching workflow instance:", error);
      return false;
    }
  };

  const callSetCurrentPage = (callInstance: any, consentok?: any) => {
    try {
      let instanceData = JSON.parse(callInstance?.WorkflowProcessInstanceAsJson);
      let firstInstanceData = instanceData.find((item: any) => item?.FormName == VOBWorflowPages.ConsentForm);
      let secoundInstanceDatas = instanceData.find((item: any) => item?.FormName == VOBWorflowPages.VendorResponse);
      console.log(firstInstanceData, secoundInstanceDatas, "secoundInstanceDatas", instanceData, consentOk);

      if (firstInstanceData && firstInstanceData?.Status === "Not Started") {
        setcurrentProcessData(firstInstanceData);
        setcurrentPage(VOBWorflowPages.ConsentForm);
      }

      if (
        secoundInstanceDatas &&
        firstInstanceData &&
        (firstInstanceData?.Status === "Completed" || firstInstanceData?.Status === "NA") &&
        secoundInstanceDatas?.Status === "Not Started"
      ) {
        setcurrentPage(VOBWorflowPages.VendorResponse);
        setcurrentProcessData(secoundInstanceDatas);
      }
      if (
        secoundInstanceDatas &&
        firstInstanceData &&
        firstInstanceData?.Status === "Completed" &&
        secoundInstanceDatas?.Status === "Completed"
      ) {
        setcurrentPage("Page sucess");
      }

      if (consentOk) {
        setcurrentPage("Page rejected");
      }
    } catch {
      setcurrentPage("");
    }
  };

  const callVendorDetails = async (venId: any) => {
    try {
      const res = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: Number(venId) });
      setIsEditDate(res.data);
      const VendorDetailsResponse: any = res.data;
      console.log(VendorDetailsResponse, vendorLoginDetails, "vendorLoginDetails");

      if (VendorDetailsResponse?.VendorDetails?.Updated_By === vendorLoginDetails?.vendorEmail) {
        setactivestepCount(1);
      }
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    }
  };

  const handleSubmit = async (value: any) => {
    console.log("submitted", value);
    let final_data: any = {
      ModuleName: currentProcessData.FormName,
      Activity: "Create",
      UserId: 0,
      // CreatedBy: "string",
      // CreatedBy: isEditData?.VendorDetails?.Legal_Name ||"",
      CreatedBy: getVendorData?.Vendor_Name || "",
      MenuId: MenuIdForVendoruse,
      Form_Id: 0,
      FormId: currentProcessData?.ProcessId,
      Workflow_InstanceId: currentProcessData ? currentProcessData?.Workflow_InstanceId : 0,
      Process_InstanceId: currentProcessData ? currentProcessData?.Id : 0,
      VendorId: currentProcessData ? currentProcessData?.VendorId : 0,
      ConditionValue: currentProcessData ? currentProcessData?.VendorId : 0,
      Project_Detail_Id: currentProcessData ? currentProcessData?.ProjectId : 0,
      IsRenewal: currentProcessData?.IsRenewal ? currentProcessData?.IsRenewal : false,
    };

    let VendorAssessementMappingsData: any[] = [];
    value &&
      value.map((item: any) => {
        item?.Questions &&
          item.Questions.map((q_item: any) => {
            let obj = {
              Assessement_MappingId: q_item.Assessement_MappingId,
              Assessment_Questionaire_Mapping_Id: q_item.Assessment_Questionaire_Mapping_Id,
              Assessment_Questionaire_Id: q_item.Assessment_Questionaire_Id,
              Weightage: q_item?.Weightage,
              Risk_Scorig: JSON.stringify(q_item.ApplicableRisksScore),
              Attachment: q_item.Attachment,
              Best_Score: q_item.Best_Score,
              Response_Received: q_item?.Response_Received || "",
              Actual_Score: q_item.Actual_Score,
              Deviation: q_item.Deviation,
              SME_ReviewStatus: q_item.SME_ReviewStatus ? q_item.SME_ReviewStatus : null,
              SME_Remarks: q_item.SME_Remarks,
              SO_Remarks: q_item.SO_Remarks,
              IsAddInDo: q_item.IsAddInDo,
              DO_ReviewStatus: q_item.DO_ReviewStatus ? q_item.DO_ReviewStatus : null,
              DO_Remarks: q_item.DO_Remarks,
              IsAddInPM: q_item.IsAddInPM,
              Status: q_item.Status,
              Updated_By: "Vendor",
            };
            VendorAssessementMappingsData.push(obj);
          });
      });
    final_data.VendorAssessmentMappings = VendorAssessementMappingsData;
    console.log( JSON.stringify(final_data) , "final_datavendor");
    dispatch(updateLoader(true));
    try {
      dispatch(addVendorAssessment(final_data));
    } catch {
      dispatch(updateLoader(false));
    }
  };

  const validateAllFields = () => {
    let isValid = true;
    let allErrors: { [key: string]: string } = validateAssessmentAll(selectedCatagoryPreview, "vendor");

    setvalidationErrors(allErrors);
    console.log(allErrors);

    if (Object.keys(allErrors).length > 0) {
      isValid = false;
    }

    return isValid;
  };

  const handleSubmitValidData = () => {
    if (validateAllFields()) {
      handleSubmit(selectedCatagoryPreview);
    }
  };

  const setisEditDataForm = (val: any) => {};

  return (
    <>
      {currentPage === VOBWorflowPages?.ConsentForm ? (
        <Box sx={{ width: "100%" }}>
          <AddVendorConsentForm
            onClose={() => setisEditDataForm(null)}
            currentProcessData={currentProcessData}
            edit={true}
            onAccept={() => consentFormAccept()}
          />
        </Box>
      ) : null}

      {currentPage === VOBWorflowPages?.VendorResponse ? (
        <Box sx={{ width: "100%" }}>
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Typography variant="h6" color={"text.primary"}>
                {`Vendor Assesment`}
              </Typography>
            </Box>
            <CustomStepper activeStep={activestepCount} steps={main_steps} />

            <Box>
              {activestepCount == 0 && isEditData && (
                <AddVendorDetails
                  view={false}
                  edit={true}
                  isEditData={isEditData}
                  onClose={() => {}}
                  isAqmResponse={isAqmResponse}
                  activestepCount={activestepCount}
                  handleNext={handleNextVendor}
                  handlePrevious={handlePrevious}
                  isDisableBreadCrums={true}
                />
              )}
              {activestepCount == 1 && (
                <AssessmentVendorDetailContext.Provider
                  value={{ selectedCatagoryPreview, setselectedCatagoryPreview, validationErrors, setvalidationErrors }}
                >
                  <Response currentProcessData={currentProcessData} data={assessmentData} />
                </AssessmentVendorDetailContext.Provider>
              )}
            </Box>

            {activestepCount === 1 ? (
              <Box sx={{ display: "flex", gap: 2, justifyContent: "end" }}>
                <CustomButton
                  type="reset"
                  variant="outlined"
                  name={"Previous"}
                  onClick={() => {
                    handlePrevious();
                  }}
                />

                {selectedCatagoryPreview && selectedCatagoryPreview?.length > 0 && (
                  <CustomButton
                    type="reset"
                    variant="contained"
                    name={"Submit"}
                    onClick={() => {
                      handleSubmitValidData();
                    }}
                  />
                )}
              </Box>
            ) : null}
          </Stack>
        </Box>
      ) : null}

      {currentPage === "Page sucess" ? (
        <CustomSucessBanner label="Your assessment is completed and submitted successfully ! you will hear from us soon!"/>
       
      ) : null}

      {currentPage === "Page rejected" ? (
        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Box
            sx={{
              border: "1px solid #6A6A6A",
              borderRadius: "10px",
              width: "500px",
              height: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "200px",
                width: "200px",
                backgroundColor: "#C0C0C0",
                borderRadius: "200px",
                margin: "0 auto",
                textAlign: "center",
              }}
            >
              {/* <CheckCircleIcon sx={{ fontSize: '48px', color: 'green' }} /> */}
              <Box sx={{ color: "#6A6A6A" }}>
                <CustomSvg name={"thumbs-up"} size={50} />
              </Box>
            </Box>

            <Typography variant="h6" sx={{ marginTop: "20px", width: "400px", textAlign: "center" }}>
              Your Consent is rejected successfully!
            </Typography>
          </Box>
        </Box>
      ) : null}
    </>
  );
};
