import { Box, Grid, Typography } from "@mui/material";
import DynamicBasicTable from "../../../common/dynamicbasicTable/DynamicBasicTable";



interface CommonSLATableprops {
    HeaderTitle?: any;
    Tabledata: any;
    TableColumns: any;

}

export const CommonSLATable: React.FC<CommonSLATableprops> = ({ HeaderTitle, TableColumns, Tabledata }) => {

    return (

        <>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                <Typography
                    sx={{
                        height: "12px",
                        color: "#959595",
                        display: "block",
                        fontSize: "15px",
                        fontWeight: "bold",
                        textAlign: "left",
                    }}
                >
                    {HeaderTitle ? HeaderTitle : "SLA TO MONITOR"}
                </Typography>
            </Box>
            <Box >
                <Box
                    sx={{
                        height: "100%",
                        width: "100%",
                        pl: 1,
                        mt:1
                    }}>

                    <DynamicBasicTable
                        columns={TableColumns}
                        rowData={Tabledata}
                        style={{ border: "1px solid #f1f1f1" }}
                        // handleAction={handleOpen}
                        isEdit={false}
                        isDelete={false}
                        showDeleteButton={false}
                        disableAction={true}
                    // statusIdMapping={statusIdLabelMapping}
                    />
                </Box>
            </Box>

        </>
    )

}