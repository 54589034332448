import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { CLAUSE_DETAILS_URL } from "./endpoints/clause";
import { ICaluseDetailsResponse, ICaluseTypeResponse } from "../../../models/contractManagementModels/clauseModels";

const getClauseDetailsList = (requestBody: any): Promise<ICaluseDetailsResponse> =>
  axiosInstance
    .post(CLAUSE_DETAILS_URL.GetAllClauseDetails, requestBody)
    .then((response: AxiosResponse<ICaluseDetailsResponse>) => {
      if (response.status !== 400 && response.status !== 404 &&   response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Service Catalog data: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getClauseTypeList = (): Promise<ICaluseTypeResponse> =>
  axiosInstance
    .get(CLAUSE_DETAILS_URL.GetAllCaluseList)
    .then((response: AxiosResponse<ICaluseTypeResponse>) => {
      if (response.status !== 400 && response.status !== 404 &&   response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Service Catalog data: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getClauseByService = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(CLAUSE_DETAILS_URL.GetClauseByServices, requestBody)
    .then((response: AxiosResponse<ICaluseDetailsResponse>) => {
      if (response.status !== 400 && response.status !== 404 &&   response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const createClause = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(CLAUSE_DETAILS_URL.CreateClause, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 &&   response.status !== 403) {
        return response.data;
      }
      throw new Error("Error adding Service Catalog: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateClause = (requestBody: any): Promise<any> =>
  axiosInstance
    .put(CLAUSE_DETAILS_URL.UpdateClause, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 &&   response.status !== 403) {
        return response.data;
      }
      throw new Error("Error adding Service Catalog: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getClauseDetailsById = (reqparams: any): Promise<any> => {
  let url = `${CLAUSE_DETAILS_URL.GetCaluseById}/${reqparams}`;
  return axiosInstance
    .get(url)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 &&   response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Service Catalog data: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
};

const activeInactiveClause = (requestBody: any): Promise<any> =>
  axiosInstance
    .put(CLAUSE_DETAILS_URL.ActiveInactive, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 &&   response.status !== 403) {
        return response.data;
      }
      throw new Error("Error adding Service Catalog: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getSystemVariables = (): Promise<ICaluseTypeResponse> =>
  axiosInstance
    .get(CLAUSE_DETAILS_URL.getSystemVariables)
    .then((response: AxiosResponse<ICaluseTypeResponse>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Service Catalog data: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });  
const getVersionHistory = (requestBody: any): Promise<any> =>
  axiosInstance
    .get(CLAUSE_DETAILS_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const clauseService = {
  getClauseDetailsList,
  getClauseTypeList,
  getClauseByService,
  createClause,
  updateClause,
  getClauseDetailsById,
  activeInactiveClause,
  getSystemVariables,
  getVersionHistory,
  //   getVendorDetailsById,
  //   updateVendorDetails,
  //   addVendorDetails,
  //   deleteVendorDetails,
  //   getVersionHistoryVendorDetails,
};

export default clauseService;
