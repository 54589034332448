import { Assessment_Status } from "../../../config/config";

export const checkServiceMapping = (vendorServices : any , allAssessments : any , from : any , parentId? : any) => {
         
    const vendorServiceIds = vendorServices.map((item: any) => item.ServiceCatalogId);

    const usedServices = allAssessments
    .filter((item: any) => {
      const endDate = item?.Assessment_EndDate ? new Date(item?.Assessment_EndDate) : null;
      const isDateValid = endDate && endDate < new Date();
  
      return (
        (!parentId || item?.Project_Detail_Id != parentId) &&
       ( item?.Assessment_StatusName == Assessment_Status.WIP ||
        (item?.Assessment_StatusName == Assessment_Status.Active && isDateValid))
      );
    })
    .flatMap((item: any) => {
      if (typeof item?.Mapped_Services === "string") {
        try {
          return JSON.parse(item.Mapped_Services);
        } catch (error) {
          console.error("Error parsing Mapped_Services:", item?.Mapped_Services);
          return [];
        }
      }
      return item?.Mapped_Services || [];
    });

    const uniqueUsedServices = Array.from(new Set(usedServices));
    const uniqueVendorServices = Array.from(new Set(vendorServiceIds)); 

    if(from == "TP"){ 
      return uniqueUsedServices
    }
    
    return uniqueVendorServices.filter((id: any) => !uniqueUsedServices.includes(id));
        
}