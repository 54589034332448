import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { RootState, useAppDispatchThunk } from "../../store/store";
import CustomDatePicker from "../../common/dynamicInputs/CustomDatePicker";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomButton from "../../common/dynamicButton/CustomButton";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";

import { updateLoader } from "../../store/slices/loader";

import { ACTIVITY_OPTIONS, ACTIVITY } from "../../constants/app.constants";
import {
  HEADING,
  BASE_ACTIVITY_OPTION,
  BASE_MODULE_OPTION,
  START_TIME,
  END_TIME,
  EVENT_LOG_RESPONSE_FIELDS_TYPES,
  EVENT_LOG_RESPONSE_FIELDS_DISPLAY_NAME,
} from "./eventLog.constants";
import { IEventLogRequest } from "../../models/eventLogs.types";
import { getEventLogsByFilter } from "../../store/slices/eventLog";
import { IColumnHeaders } from "../../models/authorize.type";
import { getAllMenuModulesList } from "../../store/slices/EmailTemplateData";
import { IMenuOptions } from "../../models/roles.type";
import { option_ToasterActivityConfig } from "../../config/config";

export interface eventLogFormData {
  eventLogStartDate: any;
  eventLogEndDate: any;
  activity: string;
  module: string;
}

const EventLog = () => {
  const {
    handleSubmit: handleFormSubmit,
    reset,
    setValue,
    formState: { errors: errorsLoginForm },
    control,
    getValues,
  } = useForm<eventLogFormData>({
    defaultValues: {
      eventLogStartDate: null,
    },
    mode: "all",
  });
  let columns: any = {
    table_name: "Vendor Records",
    isAction: true,
    columns: [],
  };
  const columns_version: any = {
    table_name: "Vendor History Reacords",
    isAction: false,
    columns: [],
  };
  const currentDate = dayjs();
  const dispatch = useAppDispatchThunk();
  const [selectedDates, setSelectedDates] = useState({
    start: "",
    end: "",
  });
  const [disableModule, setDisableModule] = useState(false);
  const [columnHeader, setColumnHeader] = useState<any>({});
  const [showTable, setShowTable] = useState<boolean>(false);

  const {  userLoginResponse,currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const { isEventLogFetching, filteredEvents, isEventLogFetchingError } = useSelector((state: RootState) => state.eventLogs);
  const { allMenuData } = useSelector((state: RootState) => state.emailTemplateData);

  const [menuOptions, setMenuOptions] = useState<IMenuOptions[]>([]);
   const { currentpageTitleData } = useSelector(
          (state: RootState) => state.FormTitleMasterData
        );

  const option_activity = option_ToasterActivityConfig;
  useEffect(() => {
    dispatch(getAllMenuModulesList());
  }, []);

  useEffect(() => {
    if (isEventLogFetching) {
      dispatch(updateLoader(true));

    } else dispatch(updateLoader(false));
  }, [isEventLogFetching, dispatch]);

  useEffect(()=>{
      if(allMenuData.length>0){
        console.log(allMenuData, "allMenuData");
        
      //   let option_modules: any[] = [];
      //   allMenuData?.map((item: any) => {
      //     if (item.SubMenus.length == 0) {
      //         option_modules.push({ key: item.MenuName, value: item.MenuName });
      //     } else {
      //         if (item.SubMenus.length != 0) {
      //             item.SubMenus.map((itm: any) => {
      //                 option_modules.push({ key: itm.MenuName, value: itm.MenuName });
      //             });
      //         }
      //     }
      // })
      const findpages = (menus: any[]) => {
        let findedValues: any[] = [];
       
        const findLastSubMenus = (menus: any[], accumulator: any[]) => {
          for (let menu of menus) {
            if (!menu.SubMenus || menu.SubMenus.length === 0) {
              accumulator.push({ key: menu.MenuName, value: menu.MenuName });
            } else {
              findLastSubMenus(menu.SubMenus, accumulator);
            }
          }
        };
    
        findLastSubMenus(menus, findedValues);
        return findedValues;
      };

      let option_modules: any[] = findpages(allMenuData);
      
      option_modules= [...option_modules, {
         key: "Authentication", value: "Authentication"
      }]
        setMenuOptions(option_modules)
      }
  },[allMenuData])

  useEffect(() => {
    if (filteredEvents.length > 0) {
      const tempColumnHeader: IColumnHeaders[] = [];
      for (const [key] of Object.entries(filteredEvents[0])) {
        const colHeader: IColumnHeaders = {
          Field: key,
          FieldType: EVENT_LOG_RESPONSE_FIELDS_TYPES[key],
          DisplayName: EVENT_LOG_RESPONSE_FIELDS_DISPLAY_NAME[key],
        };
        tempColumnHeader.push(colHeader);
      }
      let re_columns = {
        ...columns_version,
        columns: tempColumnHeader,
      };
      setColumnHeader(re_columns);
      setShowTable(true);
    } else setShowTable(false);
  }, [filteredEvents, columns_version]);

  useEffect(()=>{
    if (isEventLogFetchingError) {
      setShowTable(false);
    }
  }, [isEventLogFetchingError])

  const handleSubmit = () => {
    let { activity, module } = { ...getValues() };
    const payload: IEventLogRequest = {
      ModuleName: module,
      Action: activity,
      FromDate: selectedDates.start,
      ToDate: selectedDates.end,
    };
    console.log(payload);
    dispatch(getEventLogsByFilter(payload));
  };

  const renderHeading = () => (
    <Typography variant="h6" color={"text.primary"}>
                                {" "}
                                {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                                {/* Holiday Master */}
                            </Typography>
  );

  const renderStartDatePicker = () => (
    <Grid item xs={3}>
      <Controller
        name="eventLogStartDate"
        control={control}
        defaultValue={null}
        rules={{
          required: "Start Date is Required",
        }}
        render={({ field }) => (
          <CustomDatePicker
            required={true}
            label={"Start Date"}
            value={field.value}
            onChange={(val) => {
              field.onChange(val);
              const date = moment(val.$d).format("YYYY-MM-DD");
              setSelectedDates({
                ...selectedDates,
                start: `${date}${START_TIME}`,
              });
            }}
            maxDate={currentDate.toDate()}
            error={Boolean(errorsLoginForm.eventLogStartDate)}
            helperText={errorsLoginForm.eventLogStartDate && errorsLoginForm.eventLogStartDate.message?.toString()}
          />
        )}
      />
    </Grid>
  );

  const renderEndDatePicker = () => (
    <Grid item xs={3}>
      <Controller
        name="eventLogEndDate"
        control={control}
        defaultValue={null}
        rules={{
          required: "End Date is Required",
        }}
        render={({ field }) => (
          <CustomDatePicker
            required={true}
            label={"End Date"}
            value={field.value}
            onChange={(val) => {
              field.onChange(val);
              const date = moment(val.$d).format("YYYY-MM-DD");
              setSelectedDates({
                ...selectedDates,
                end: `${date}${END_TIME}`,
              });
            }}
            maxDate={currentDate.toDate()}
            error={Boolean(errorsLoginForm.eventLogEndDate)}
            helperText={errorsLoginForm.eventLogEndDate && errorsLoginForm.eventLogEndDate.message?.toString()}
          />
        )}
      />
    </Grid>
  );

  const renderActivityDropDown = () => (
    <Grid item xs={3}>
      <Controller
        name="activity"
        control={control}
        defaultValue={""}
        rules={{ required: "Activity is required" }}
        render={({ field }) => (
          <CustomSelect
            name={"activity"}
            valueKey={"id"}
            optionLabelKey="lable"
            options={option_activity}
            required={true}
            label={"Activity"}
            value={field.value}
            onChange={(val) => {
              field.onChange(val);
              // if (val === ACTIVITY.LOGOUT || val === ACTIVITY.LOGIN) {
              //   setValue("module", "");
              //   setDisableModule(true);
              // } else {
              //   setDisableModule(false);
              // }
            }}
            error={Boolean(errorsLoginForm.activity)}
            helperText={errorsLoginForm.activity && errorsLoginForm.activity.message?.toString()}
          />
        )}
      />
    </Grid>
  );

  const renderModuleDropDown = () => (
    <Grid item xs={3}>
      <Controller
        name="module"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <CustomSelect
            name={"module"}
            valueKey={"key"}
            optionLabelKey="value"
            options={BASE_MODULE_OPTION.concat(menuOptions)}
            required={false}
            label={"Module Name"}
            value={field.value}
            disabled={disableModule}
            onChange={field.onChange}
            error={Boolean(errorsLoginForm.module)}
            helperText={errorsLoginForm.module && errorsLoginForm.module.message?.toString()}
          />
        )}
      />
    </Grid>
  );

  const renderTable = () => {
    if (!showTable) return null;

    return (
      <Stack sx={{ overflowY: "scroll" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <MaterialDynamicGrid
            data={columnHeader}
            rows={filteredEvents}
            handleAction={() => {}}
            enableZoomIcon={true}
            showDelete={false}
            showhistory={false}
            showView={false}
            showEdit={false}
            showExports
            pageName="Events Log"
            userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
          />
        </Box>
      </Stack>
    )
  }

  const renderEventLogFilterForm = () => (
    <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
      <Grid container mt={2} spacing={2}>
        {renderStartDatePicker()}
        {renderEndDatePicker()}
        {renderActivityDropDown()}
        {renderModuleDropDown()}
      </Grid>
    </Box>
  );

  const renderCustomButtons = () => (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        justifyContent: "right",
        width: "100%",
        mt: 2,
      }}
    >
      <CustomButton type="reset" variant="outlined" name={"Clear"} onClick={reset} />
      <CustomButton type="submit" name={"Search"} onClick={handleFormSubmit(handleSubmit)} />
    </Box>
  );

  return (
    <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
      {renderHeading()}
      {renderEventLogFilterForm()}
      {renderCustomButtons()}
      {renderTable()}
    </Grid>
  );
};

export default EventLog;
