import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { textFieldWidth } from "../../utils/constants";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { getLovList } from "../../store/slices/lovMasterData";
import { getParentLocationList } from "../../store/slices/locationMasterData";
import ProgressBar from "../../common/progressBar/progressBar";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { LocationConfig, LovConfig } from "../../config/config";
import { updateLoader } from "../../store/slices/loader";
import { getAllApiMaster, saveApiMaster, updateApiMaster, apiMasterAddandSave } from "../../store/slices/apiMaster";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import IconButton from '@mui/material/IconButton';
import {
  getApplicationParameterList,
  getApplicationParameterHistory,
  updateAPAddandEdit,
  deleteApplicationParameter,
} from "../../store/slices/applicationParameterData";
import { ApplicationParameterName, Assessment_Type } from "../../config/config";

interface FormData {
  Id?: any;
  APImasterid?: any;
  API_Group_Code: string;
  API_Group: string;
  API_Provider: string;
  User_Name: string;
  Login_Id: string;
  Pwd: string;
  API_Key: string;
  Format: string;
  API_Version: string;
  IsActive: any;
}

export interface RegionalSettingsProps {
  view?: boolean;
  closePopUp?: () => void;
  edit?: boolean;
  isEditData?: any;
  ModuleId?: any;

}

export const AddApiMaster: React.FC<RegionalSettingsProps> = ({ view, closePopUp, edit, isEditData, ModuleId }) => {
  const dispatch = useAppDispatchThunk();
  const { ApiMasterTable, isApiMasterUpdated, isApiMasterAdded, ToasterMsg, isLoading } = useSelector(
    (state: RootState) => state.ApiMasterData
  );
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { formConfiguration } = useSelector((state: RootState) => state.customFormFields);
  const [message, setMessage] = useState<boolean>(false);
  const [currencyCode_Data, setCurrencyCode_Data] = useState([]);
  const [AddLocationForm, setAddLocationForm] = useState<boolean>(false);
  const { ParentLocationTable } = useSelector((state: RootState) => state.locationMasterData);
  const [showPassword, setShowPassword] = useState(true);
  const [Pass_Masking_data, setPass_Masking_data] = useState(false);

  const { applicationParameterData, isAPAdded, isAPDeleted, isAPUpdated, versionHistory, apToasterMsg } = useSelector(
    (state: RootState) => state.applicationParameter
  );

  const [defaultApiGroupCode, setDefaultApiGroupCode] = useState<any>();


  console.log(isEditData, "isEditData");

  useEffect(() => {
    dispatch(
      getApplicationParameterList({
        FormID: 2,
      })
    );
  }, []);

  let formData: any = {
    // Id:isEditData?isEditData.Id:0,
    APImasterid: isEditData ? isEditData.Id : 0,
    API_Group_Code: isEditData ? isEditData?.API_Group_Code : "",
    API_Group: isEditData ? isEditData?.API_Group : "",
    API_Provider: isEditData ? isEditData?.API_Provider : "",
    User_Name: isEditData ? isEditData?.User_Name : "",
    Login_Id: isEditData ? isEditData?.Login_Id : "",
    Pwd: isEditData ? isEditData?.Pwd : "",
    API_Key: isEditData ? isEditData?.API_Key : "",
    Format: isEditData ? isEditData?.Format : "",
    API_Version: isEditData ? isEditData?.API_Version : "",
    IsActive: isEditData ? (isEditData?.IsActive === "Active" ? 1 : 0) : 1,

  };
  let ModifiedBy = isEditData ? isEditData?.UpdatedBy : "";
  let CreatedBy = isEditData ? isEditData?.CreatedBy : "";


  const {
    handleSubmit: handleRegionalSettings,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });


  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };
  const isActiveDisabled = watch("IsActive");



  const handleSubmit = (data: any) => {

    console.log(isEditData, "isEditData");

    // dispatch(updateLoader(true));
    let formData = data;
    // setLoader(true);
    formData.IsActive = formData.IsActive ? 1 : 0;
    formData.Created_By = user?.UserName;
    formData.ModuleName = "API MASTER";
    formData.Remarks = "";
    formData.MenuId = currentPagePermissionData?.MenuId;;
    formData.Form_Id = 18;
    formData.Status = 1;
    formData.UserId = userLoginResponse?.UserId;



    // let formData : any={


    // Id:data.Id,

    // Updated_By: user?.UserName,
    // Updated_On: new Date().toISOString(),
    // IsActive: data.IsActive == "IsActive" ? true : false,
    // ModuleName: "API MASTER",
    // MenuId: ModuleId,
    // Form_Id: 18,
    // UserId: userLoginResponse?.UserId,
    // Created_By: user?.UserName,
    // Activity: "Create",
    // Status: 1,

    // } 

    let JsonDataForDisplay: any = {


      "API_Group_Code": formData.API_Group_Code,
      "API_Group": formData.API_Group,
      "API_Provider": formData.API_Provider,
      "User_Name": formData.User_Name,
      "Login_Id": formData.Login_Id,
      "Pwd": formData.Pwd,
      "API_Key": formData.API_Key,
      "Format": formData.Format,
      "API_Version": formData.API_Version,
      Status: formData.IsActive ? "Active" : "InActive",
      "Modified By": user?.UserName,
    }

    if (edit) {

      formData.Remarks = "";
      formData.Activity = "Edit";
      formData.UserId = userLoginResponse?.UserId;
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);

      // formData.UpdatedBy = user?.UserName;
      // formData.Created_By = user?.UserName;
      // formData.MenuId = currentPagePermissionData?.MenuId;
      // formData.ModuleName = "API MASTER";
      // formData.Activity = "Edit";
      // formData.ActionTaken = "Edit";
      // formData.DataForDisplay = JSON.stringify(JsonDataForDisplay) ;
      // formData.UserId = userLoginResponse?.UserId;
      // formData.Form_Id = 13;
      // formData.APImasterid = formData.Id;

      dispatch(saveApiMaster(formData));

    } else {
      formData.UpdatedBy = user?.UserName;
      formData.CreatedBy = user?.UserName;
      // delete formData.DepartmentId;
      formData.MenuId = currentPagePermissionData?.MenuId;
      formData.ModuleName = "API MASTER";
      formData.IsActive = 1;

      formData.Activity = "Create";
      // formData.ActionTaken = "Create";
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      formData.UserId = userLoginResponse?.UserId;
      formData.Form_Id = 13;
      formData.APImasterid = 0;

      console.log(formData, 'formData')
      dispatch(saveApiMaster(formData));
    }
    console.log(formData, 'formData')
  };

  useEffect(() => {
    if (defaultApiGroupCode && !edit && !view) {
      setValue("API_Group_Code", defaultApiGroupCode)
    }


  }, [defaultApiGroupCode])

  useEffect(() => {
    if (isApiMasterAdded) {
      dispatch(
        getAllApiMaster({
          FormID: 13,
        })
      );
      dispatch(apiMasterAddandSave(false))
      reset(formData);

      dispatch(updateLoader(false));
      if (closePopUp) { closePopUp(); }

      // } else if ( ) {
      //   dispatch(
      //     getAllApiMaster({
      //       FormID: 13,
      //     })
      //   );
      //   dispatch(apiMasterAddandSave(false))
      //   reset(formData);

      //   dispatch(updateLoader(false));
      //   closePopUp();
    }
  }, [isApiMasterAdded]);

  useEffect(() => {
    dispatch(
      getApplicationParameterList({
        FormID: 2,
      })
    );

  }, [])

  useEffect(() => {
    if (applicationParameterData?.length) {
      const GroupCode: any = ApplicationParameterName.ApiGroupCode;
      const APIMasterGroupCodeDefault = ApplicationParameterName.APIMasterCode;

      let finedDefaultGroupCode: any = applicationParameterData.find(
        (applicationParamitem) => applicationParamitem.Parameter_Name === GroupCode && applicationParamitem.Parameter_Group === APIMasterGroupCodeDefault
      )?.Parameter_Value;

      setDefaultApiGroupCode(finedDefaultGroupCode)

    }
  }, [applicationParameterData]);

  console.log(defaultApiGroupCode, "defaultApiGroupCode")

  return (
    <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
      <Grid
        // item
        // item xs={12} sm={12} md={6} lg={6} xl={6}
        sx={{
          backgroundImage: `url()`,
          backgroundRepeat: "no-repeat",
          // backgroundColor: (t) =>
          //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
          // backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          margin: "0",
        }}
      >
        {false && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              margin: "0",
            }}
          >
            {!view ? (
              <>
                {!edit ? (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Add Branch Master
                  </Typography>
                ) : (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Edit Branch Master
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="h6" sx={{ m: 1 }}>
                View Branch Master
              </Typography>
            )}
          </Box>
        )}
        <Grid
          sx={{
            height: "auto",
            width: "100%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Box
            sx={{
              px: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
              },

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="API_Group_Code"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "APIGroupCode is Required",

                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 1 characters Required",
                        maxLength: (value) => value.length <= 100 || "Maximum 100 characters Required",
                        minAlphabets: (value) => (value?.match(/[a-zA-Z]/g) || []).length >= 2 || "Minimum 2 alphabets required",

                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"API Group Code"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"API Group Code"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.API_Group_Code)}
                        helperText={errorsloginform.API_Group_Code && errorsloginform.API_Group_Code.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="API_Group"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "APIGroupName is Required",

                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 1 characters Required",
                        maxLength: (value) => value.length <= 100 || "Maximum 100 characters Required",
                        minAlphabets: (value) => (value?.match(/[a-zA-Z]/g) || []).length >= 2 || "Minimum 2 alphabets required",

                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"API Group"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"API Group"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.API_Group)}
                        helperText={errorsloginform.API_Group && errorsloginform.API_Group.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="API_Provider"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "APIProvider is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 1 characters Required",
                        maxLength: (value) => value.length <= 100 || "Maximum 100 characters Required",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"API Provider"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"API Provider"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.API_Provider)}
                        helperText={errorsloginform.API_Provider && errorsloginform.API_Provider.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="User_Name"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "UserName is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 1 characters Required",
                        maxLength: (value) => value.length <= 100 || "Maximum 100 characters Required",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"UserName"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"User Name"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.User_Name)}
                        helperText={errorsloginform.User_Name && errorsloginform.User_Name.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Login_Id"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "LoginId is Required",

                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 1 characters Required",
                        maxLength: (value) => value.length <= 100 || "Maximum 100 characters Required",
                        // ValueLimitation: (value) => /^[a-zA-Z]+$/.test(value.trim()) || "Currency Code cannot contain special characters, number and spaces",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"LoginId"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"Login Id"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Login_Id)}
                        helperText={errorsloginform.Login_Id && errorsloginform.Login_Id.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Pwd"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Password is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 1 characters Required",
                        maxLength: (value) => value.length <= 10 || "Maximum 10 characters Required",

                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}

                        name={"Password"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"Password"}
                        type={showPassword ? "text" : "password"}
                        value={field.value}
                        onChange={field.onChange}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                // onMouseDown={() => setShowPassword(!showPassword)}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                disabled={view}
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(errorsloginform.Pwd)}
                        helperText={errorsloginform.Pwd && errorsloginform.Pwd.message?.toString()}

                      />
                    )}
                  />
                </Grid>



                <Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="API_Key"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "APIKey is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 1 characters Required",
                        maxLength: (value) => value.length <= 100 || "Maximum 100 characters Required",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"API Key"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"API Key"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.API_Key)}
                        helperText={errorsloginform.API_Key && errorsloginform.API_Key.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Format"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Format is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 1 characters Required",
                        maxLength: (value) => value.length <= 20 || "Maximum 20 characters Required",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"Format"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"Format"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Format)}
                        helperText={errorsloginform.Format && errorsloginform.Format.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="API_Version"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "API Version is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 1 characters Required",
                        maxLength: (value) => value.length < 10 || "Maximum 10 characters Required",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"API Version"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"API Version"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.API_Version)}
                        helperText={errorsloginform.API_Version && errorsloginform.API_Version.message?.toString()}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "end",
                  mt: 2,
                }}
              >
                {view ? (
                  <CustomButton
                    type="reset"
                    name={"Cancel"}
                    onClick={() => {
                      closePopUp && closePopUp();
                    }}
                  />
                ) : edit ? (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        reset(formData);
                        closePopUp && closePopUp();
                      }}
                    />
                    <CustomButton
                      type="reset"
                      disabled={!isDirty}
                      name={"Update"}
                      onClick={handleRegionalSettings(handleSubmit)}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        reset(formData);
                        closePopUp && closePopUp();
                      }}
                    />
                    <CustomButton
                      type="reset"
                      disabled={!isDirty}
                      name={"Submit"}
                      onClick={handleRegionalSettings(handleSubmit)}
                    />
                  </React.Fragment>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
