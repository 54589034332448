import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import CustomCheckBox from "../../common/dynamicInputs/CustomCheckBox";
import { Box, Stack, Typography } from "@mui/material";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { getMakerCheckerList, updateMakerChecker, updateMakerCheckerList } from "../../store/slices/makerChecker";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { useSelector } from "react-redux";
import { IMakerChecker } from "../../models/makerChecker";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import PageMainHeading from "../../common/CommonHeadings/PageMainHeading";

export interface IMakerCheckerProps {
  isWorkFlow?: boolean;
}
const MakerChecker: React.FC<IMakerCheckerProps> = ({ isWorkFlow }) => {
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [permssionPage, setPermissionForpage] = useState<any>();
  const [checked, setChecked] = React.useState<IMakerChecker[]>([]);
  const [left, setLeft] = React.useState<IMakerChecker[]>([]);
  const [right, setRight] = React.useState<IMakerChecker[]>([]);
  const [updatedMakerChecker, setUpdatedMakerChecker] = React.useState<boolean>(false);
  const dispatch = useAppDispatchThunk();
  const not = (a: IMakerChecker[], b: IMakerChecker[]) => {
    return a.filter((value) => b.filter((data) => data.MasterName === value.MasterName).length === 0);
  };


   const { currentpageTitleData } = useSelector(
          (state: RootState) => state.FormTitleMasterData
        );
  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);
  const intersection = (a: IMakerChecker[], b: IMakerChecker[]) => {
    return a.filter((value) => b.filter((data) => data.MasterName === value.MasterName).length !== 0);
  };

  const { makerCheckers, updated } = useSelector((state: RootState) => state.makerChecker);

  useEffect(() => {
    dispatch(getMakerCheckerList({ category: "master" }));
  }, []);

  useEffect(() => {
    if (makerCheckers.length > 0) {
      let copyOfMakerCheckrs: IMakerChecker[] = JSON.parse(JSON.stringify(makerCheckers));
      let splitRight = copyOfMakerCheckrs.filter((rightItem: IMakerChecker) => rightItem.IsMakerChecker);
      let splitleft = copyOfMakerCheckrs.filter((leftItem: IMakerChecker) => !leftItem.IsMakerChecker);
      setLeft(splitleft);
      setRight(splitRight);
    }
  }, [makerCheckers]);

  useEffect(() => {
    if (updated) {
      setUpdatedMakerChecker(true);
    }
  }, [updated]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: IMakerChecker) => () => {
    const currentIndex = checked.findIndex((item) => item.MasterName === value.MasterName);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    let copyLeft = left.map((leftItem) => {
      leftItem.IsMakerChecker = true;
      return leftItem;
    });
    setRight([...right, ...copyLeft]);
    setLeft([]);
  };

  const handleCheckedRight = () => {
    let copyCheckedLeft = [...leftChecked];

    copyCheckedLeft = copyCheckedLeft.map((leftItem) => {
      leftItem.IsMakerChecker = true;
      return leftItem;
    });
    setRight([...right, ...copyCheckedLeft]);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    let copyCheckedright = [...rightChecked];
    copyCheckedright = copyCheckedright.map((rigthItem) => {
      rigthItem.IsMakerChecker = false;
      return rigthItem;
    });
    setLeft([...left, ...copyCheckedright]);
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    let copyCheckedright = right.map((rigthItem) => {
      rigthItem.IsMakerChecker = false;
      return rigthItem;
    });
    setLeft([...left, ...copyCheckedright]);
    setRight([]);
  };

  const customList = (items: IMakerChecker[], title: string) => (
    <>
      <Typography variant="subtitle1" sx={{ py: 1 }}>
        {" "}
        {title}{" "}
      </Typography>
      <Paper sx={{ width: 250, height: 280, overflow: "auto" }}>
        <List dense component="div" role="list">
          {items.map((value: IMakerChecker) => {
            const labelId = `transfer-list-item-${value}-label`;

            return (
              <ListItemButton
                key={value.MasterName}
                role="listitem"
                onClick={handleToggle(value)}
                sx={{
                  justifyContent: "flex-start",
                  wordBreak: "break-word",
                  // pl: {
                  //   xs: 4,
                  // },
                }}
              >
                <CustomCheckBox
                  value={checked.filter((item) => item.MasterName === value.MasterName).length > 0}
                  Width={"15%"}
                />
                <Typography variant="subtitle2"> {value.MasterName}</Typography>
              </ListItemButton>
            );
          })}
        </List>
      </Paper>
    </>
  );

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
          }}
        >
           <Typography variant="h6" color={"text.primary"}>
                                      {" "}
                                      {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                                      {/* Holiday Master */}
                                  </Typography>
        </Box>
        <Stack spacing={2}>
          <Grid container sx={{ height: "100%" }} spacing={2} justifyContent="center" alignItems="center">
            <Grid item>{customList(left, "Maker Checker Disabled")}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                  aria-label="move all right"
                >
                  ≫
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                  aria-label="move all left"
                >
                  ≪
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList(right, "Maker Checker Enabled")}</Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "right",
              mt: 3,
            }}
          >
            <CustomButton
              type="reset"
              name={"Submit"}
              onClick={() => {
                dispatch(updateMakerCheckerList([...right, ...left]));
              }}
            />
          </Box>
        </Stack>
      </Box>
      <ModalPopUp
        header="Maker Checker"
        text="Maker Checker Updated SuccessFully"
        onHide={() => {
          setUpdatedMakerChecker(false);
          dispatch(updateMakerChecker(false));
        }}
        show={updatedMakerChecker}
      />
    </>
  );
};

export default MakerChecker;
