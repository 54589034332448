import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import CustomButton from "../../../../../../common/dynamicButton/CustomButton";
import CustomMultilineTextField from "../../../../../../common/dynamicInputs/CustomMultilineTextField";
import CustomNumberField from "../../../../../../common/dynamicInputs/CustomNumberField";
import { textFieldWidth } from "../../../../../../utils/constants";
import CustomSelect from "../../../../../../common/dynamicInputs/CustomSelect";
import CustomBreadcrumbs from "../../../../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import CustomTextField from "../../../../../../common/dynamicInputs/CustomTextField";
import CustomMultiSelect from "../../../../../../common/dynamicInputs/CustomMultiSelect";
import CustomCheckBox from "../../../../../../common/dynamicInputs/CustomCheckBox";
import CustomDatePicker from "../../../../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import { slaMonitorContext } from "../AddTargetKPI";
import { AddSLA } from "./AddSLA";
import CustomDialog from "../../../../../../common/dynamicDialog/CustomDialog";
import DynamicBasicTable from "../../../../../../common/dynamicbasicTable/DynamicBasicTable";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { findMeasuredInDate } from "../addSLAValidation";
import { LovConfig } from "../../../../../../config/config";
import ModalPopUp from "../../../../../../common/modalComponent/ModalPopUp";


interface slatomonitorDetailsprops {
  view?: boolean;
  edit?: boolean;
  isEditData?: any;
  add?: boolean;
}

export const SLAtoMonitor: React.FC<slatomonitorDetailsprops> = ({ view , edit, isEditData , add }) => {
        const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  
     const slatomonitorcontext = useContext(slaMonitorContext);
     const {
        slaMonitorList,
        setslaMonitorList,
        slanodatawarning
    } = slatomonitorcontext!;
   const [addSLA, setaddSLA] = useState<boolean>(false);
   const [isslaMonitordelete, setisslaMonitordelete] = useState<boolean>(false);
    const [measuredList, setmeasuredList] = useState<any>([]);
    const [measurededitIndex, setmeasurededitIndex] = useState<any>("");
    const [ismeasuredEditData, setismeasuredEditData] = useState<any>("");
   
   const SlatoMonitorColumn = [
    { Field: "sno", DisplayName: "S.No", },
    { Field: "sla", DisplayName: "SLA" },
    { Field: "measured_In_Name", DisplayName: "Measured In" },
    { Field: "severity_Of_SLA_Name", DisplayName: "Serverity" },
    { Field: "target_Min_value", DisplayName: "Target Min" },
    { Field: "target_Max_value", DisplayName: "Target Max" },
    { Field: "tolerance_Level", DisplayName: "Tolerance Level" },
    { Field: "remark", DisplayName: "Remark" }
  ];
   const SLAAdd = (val: any) => {

    if(setslaMonitorList && slaMonitorList){
      let updatedList : any[]= [...slaMonitorList];
      if(measurededitIndex || measurededitIndex === 0){
        updatedList[measurededitIndex] = val
      }else{
        updatedList.push(val);
      }
    
      console.log(updatedList , "updatedList");
      setslaMonitorList(updatedList) ;
      closeAddorEdit()
      setaddSLA(false)
    }
  }
  useEffect(() => {
    setmeasuredList(lovTable.filter((item: any) => item.Name === LovConfig?.Measured_In) || []);
  }, [lovTable]);

    const transformData = (data: any) => {
      return data.map((parameteritem: any , index: any) => {
        return {
          ...parameteritem,
          sno : index + 1 ,
          isActive : true,
          target_Max_value : findMeasuredInDate(parameteritem?.measured_In , measuredList) ? dayjs(parameteritem?.target_max_date).format("DD/MM/YYYY") : parameteritem?.target_Max ,
          target_Min_value  : findMeasuredInDate(parameteritem?.measured_In , measuredList) ? dayjs(parameteritem?.target_min_date).format("DD/MM/YYYY") : parameteritem?.target_Min
        };
      });
    };

    let vall = transformData(slaMonitorList);
    console.log(vall);
    

  const handleOpenSLAMonitor = (row : any, func : any, index : any) => {
         if(func == "edit"){
          setmeasurededitIndex(index)
          setismeasuredEditData(row)
          setaddSLA(true)
         }else{
          setmeasurededitIndex(index) 
          setismeasuredEditData(row) 
          setisslaMonitordelete(true) ;
         }
  }


  const closeAddorEdit =() => {

    setmeasurededitIndex("") 
    setismeasuredEditData("")
  }

  const deleteSLAList = () => {
    let updatedList : any[]= [...slaMonitorList];
    if((measurededitIndex || measurededitIndex == 0) && ismeasuredEditData){
      updatedList.splice(measurededitIndex, 1);
      setslaMonitorList(updatedList) ;
      setisslaMonitordelete(false) ;
    }
  }

  return (
    <>
       <Box>
        <Stack spacing={4}>
        <Box sx={{display:'flex' , alignItems :'center' , justifyContent :'end'}}>  <CustomButton
                type="reset"
                variant="outlined"
                name={"Add SLA"}
                onClick={() => {
                  setaddSLA(true);
                }}
              /></Box>

<Box  sx={{ width: "100%" }}>
                  <DynamicBasicTable
                    columns={SlatoMonitorColumn}
                    rowData={transformData(slaMonitorList)}
                    style={{ border: "1px solid #f1f1f1" }}
                    handleAction={handleOpenSLAMonitor}
                    isEdit={true}
                    showDeleteButton={true}
                    disableAction={view || false}
                    statusIdMapping = {""}
                  />
                </Box>
                  <Box >
                                <Typography
                                  sx={{ fontSize: "8px", textAlign: "left", color: slanodatawarning ? "red" : "#6A6A6A" }}
                                >
                                 {slanodatawarning ? "Warning : Atleast One SLA is required , after Add able to submit" : `Note : Atleast One SLA is required.`} 
                                </Typography>

                              </Box>

      </Stack>
      </Box>

      <CustomDialog
                show={addSLA}
                onHide={() => {
                  closeAddorEdit()
                  setaddSLA(false);
                }}
                maxWidth={"lg"}
                minHeight={"50vh"}
                header={ `${ismeasuredEditData ? "Edit" : "Add"} SLA`}
                contentNode={
                 <>
                     <AddSLA isEditData={ismeasuredEditData} closePopUp={() =>  setaddSLA(false)} add={true} handlesubmit={(val) => SLAAdd(val)}/>
                 </>
                }
            />

<ModalPopUp
            onDeleteMethod={() => deleteSLAList()}
            isDelete={true}
            onHide={() => {
              setisslaMonitordelete(false);
              closeAddorEdit()
            }
            }
            ActionName={"Delete"}
            show={isslaMonitordelete}
            header={"Confirm"}
            text={`Are you sure you want to delete SLA ?`}
          />
    </>
  );
};
