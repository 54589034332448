import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { ARTICAL_MASTER_URL } from "./endpoints/articalMaster";

const getAllArticalMasters = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(ARTICAL_MASTER_URL.getAllArticalMasters,requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const ArticalMasterSubmit = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(ARTICAL_MASTER_URL.saveArticalMaster, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const editArticalMaster = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(ARTICAL_MASTER_URL.editArticalMaster, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getByIdArticalMaster = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(ARTICAL_MASTER_URL.getByIdArticalMaster, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const activeInactiveArticalMaster = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(ARTICAL_MASTER_URL.activeInactiveArticalMaster, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getVersionHistory = (requestBody: any): Promise<any> =>
  axiosInstance
    .get(ARTICAL_MASTER_URL.versionArticalMaster, { params: requestBody })
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const articleMasterService = {
  getAllArticalMasters,
  ArticalMasterSubmit,
  editArticalMaster,
  activeInactiveArticalMaster,
  getVersionHistory,
  getByIdArticalMaster,
};

export default articleMasterService;
