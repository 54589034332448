import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import PageMainHeading from "../../../common/CommonHeadings/PageMainHeading";
import AddArticleMaster from "./AddArticleMaster";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { getDepartmentHistory } from "../../../store/slices/departmentData";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { ACTIVE, INACTIVE } from "../../../utils/constants";
import { IMenu } from "../../../models/loggin.type";
import {
  ArticalMasterActiveInactive,
  ArticalMasterGetAll,
  articleMasterReset,
} from "../../../store/contractManagement/slices/articleMasterSlice";
import { getLocationList } from "../../../store/slices/locationMasterData";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";

const ArticleMaster: React.FC = () => {
  const [permissionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [isAddNewClauseType, setIsAddNewClauseType] = useState<boolean>(false);
  const [isEditData, setIsEditData] = useState<any>({});
  const [articleData, setArticleData] = useState<any[]>([]);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleteClause, setIsDeleteClause] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [option, setOption] = useState("");
 const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const { handleRecordLockUpdate } = RecordLockRelease();
  const dispatch = useAppDispatchThunk();

  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    articalMasterData,
    isLoading,
    isArticalMasterDataAdded,
    isArticalMasterDataUpdated,
    isArticalMasterDataDeleted,
    ToasterMsg,versionHistory
  }: any = contractManagementReducer.articleMasterData;
  const { locationTable } = useSelector((state: RootState) => state.locationMasterData);
  const { userLoginResponse, currentPagePermissionData, user } = useSelector((state: RootState) => state.loggerData);
  const [locationData, setLocationData] = useState<any[]>([]);

  const { currentpageTitleData } = useSelector((state: RootState) => state.FormTitleMasterData);

  const columns: any = {
    table_name: "Article Master",
    isAction: true,
    columns: [
      { Field: "state", FieldType: "string", DisplayName: "State" },
      { Field: "articleCode", FieldType: "string", DisplayName: "Article Code" },
      { Field: "articleNo", FieldType: "string", DisplayName: "Article Number" },
      { Field: "articleName", FieldType: "string", DisplayName: "Article Name" },
      { Field: "IsActive", FieldType: "string", DisplayName: "Status" },
      { Field: "updatedBy", FieldType: "string", DisplayName: "Modified By" },
    ],
  };
  useEffect(() => {
    if (locationTable) {
      let changedValue = locationTable
        .map((item: any) => ({
          ...item,
          End_Pin: item.End_Pin === 0 ? "" : item.End_Pin,
          Start_Pin: item.Start_Pin === 0 ? "" : item.Start_Pin,
        }))
        .filter((i: any) => i.LocationTypeValue === "State");
      setLocationData(changedValue);

      if (articalMasterData?.length > 0) {
        const updatedData = articalMasterData.map((clause: any) => ({
          ...clause,
          state: changedValue.find((e: any) => e.Location_Id === clause.stateId)?.Location_Name,
          IsActive: clause.isActive === 0 ? INACTIVE : ACTIVE,
        }));
        setArticleData(updatedData);
      }
    }
  }, [locationTable, articalMasterData]);

  useEffect(() => {
    dispatch(
      getLocationList({
        FormID: 2,
      })
    );
  }, []);
  const columns_version: any = {
    table_name: "Contract Cost Approval Metrics",
    isAction: false,
    columns: [],
  };
  useEffect(() => {
    if (versionHistory && versionHistory?.ClauseListHeader?.length > 0) {
      if (versionHistory?.ClauseListHeader && versionHistory?.ClauseListHeader.length > 0) {
        setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory?.ClauseListHeader,
        };
        setVersionHistoryData(re_columns);
      }
    }
  }, [versionHistory]);

  console.log(articleData, locationData, columns, "articleMasterData");

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  const handleApiCall = () => {
    dispatch(ArticalMasterGetAll({}));
  };
  useEffect(() => {
    handleApiCall();
  }, []);

  const handleOpen = async (data: any, option: string) => {
    console.log(data, "data!");
    const updatedData = articalMasterData?.find((i: any) => i.articleId === data.articleId);
    if (!updatedData) return;
    setOption(option);

    switch (option) {
      case "delete":
        setIsDeleteData(updatedData);
        setIsDeleteClause(true);
        break;
      case "history":
        setIsVersionHistory(true);
        setIsEditData(updatedData);
        // dispatch(getDepartmentHistory({ departmentId: data.ClauseTypeId, formID: 7 }));
        break;
      case "view":
        setIsEditData(updatedData);
        setIsEdit(true);
        break;
      case "edit":
        setIsEditData(updatedData);
        setIsEdit(true);
        break;
      default:
        setIsEdit(true);
        break;
    }
  };

  const deleteArticleMaster = () => {
    console.log(isDeleteData, "isDeleteData");
    const inactiveFormData = {
      isActive: isDeleteData?.isActive === 0 ? 1 : 0,
      activity: isDeleteData?.isActive === 0 ? ACTIVE : INACTIVE,
      articleId: isDeleteData?.articleId,
      stateId: isDeleteData?.stateId,
      articleCode: isDeleteData?.articleCode,
      articleNo: isDeleteData?.articleNo,
      articleName: isDeleteData?.articleName,
      updatedBy: user?.UserName,
      updatedOn: new Date().toISOString(),
      UserId: userLoginResponse?.UserId,
      moduleName: "Article Master",
      createdBy: user?.UserName,
      actionTaken: isDeleteData?.isActive === 0 ? ACTIVE : INACTIVE,
    };
    dispatch(ArticalMasterActiveInactive(inactiveFormData));
  };
  console.log(ToasterMsg, "ToasterMsg");

  useEffect(() => {
    const handleFunc = () => {
      if (isArticalMasterDataAdded || isArticalMasterDataUpdated || isArticalMasterDataDeleted) {
        dispatch(articleMasterReset(false));
        handleRecordLockUpdate();

        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: ToasterMsg?.Message,
            isTosterFailed: ToasterMsg?.Status === "false" ? true : false,
          })
        );
        handleApiCall();
        setIsDeleteClause(false);
      }
    };
    handleFunc();
  }, [isArticalMasterDataAdded, isArticalMasterDataUpdated, isArticalMasterDataDeleted]);
  console.log(isEdit, "isEditisEdit");
  return (
    <Box sx={{ width: "100%" }}>
      <Stack direction="column" spacing={2}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
          {/* <PageMainHeading title="Article Master" /> */}
          {permissionPage && <PageMainHeading title={permissionPage?.MenuName ?? "Article Master"} />}
        </Box>
        <Box sx={{ width: "100%" }}>
          <MaterialDynamicGrid
            data={columns}
            rows={articleData}
            loading={isLoading}
            handleAction={handleOpen}
            handleAdd={() => setIsAddNewClauseType(true)}
            enableZoomIcon={false}
            showhistory={true}
            showAdd={permissionPage?.IsAdd === 1}
            showDelete={permissionPage?.IsDelete === 1}
            showView={permissionPage?.IsView === 1}
            showEdit={permissionPage?.IsEdit === 1}
            showExports={permissionPage?.IsExport === 1}
            userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
            pageName="Article Master"
          />
        </Box>
      </Stack>

      <CustomDialog
        show={isAddNewClauseType}
        onHide={() => setIsAddNewClauseType(false)}
        maxWidth="sm"
        header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Article Master"}`}
        contentNode={
          <AddArticleMaster isNew={true} onClose={() => setIsAddNewClauseType(false)} />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => setIsEdit(false)}
        maxWidth="sm"
        header={
          option === "view"
            ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Article Master"}`
            : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Article Master"}`
        }
        contentNode={
          <AddArticleMaster
            existingData={isEditData}
            isView={option === "view"}
            isEdit={isEdit}
            onClose={() => setIsEdit(false)}
          />
        }
      />

      <ModalPopUp
        onDeleteMethod={deleteArticleMaster}
        isDelete
        onHide={() => setIsDeleteClause(false)}
        show={isDeleteClause}
        header="Confirm"
        text={`Are you sure you want to ${
          isDeleteData?.isActive === INACTIVE || isDeleteData?.isActive === 1 ? "deactivate" : "activate"
        } the selected Article Master ?`}
        ActionName={isDeleteData?.isActive === INACTIVE || isDeleteData?.isActive === 1 ? INACTIVE : ACTIVE}
      />
      
      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
        }}
        maxWidth={"lg"}
        header={`Article Master Version History`}
        contentNode={
          <>
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistory?.ClauseList}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permissionPage?.IsDelete === 1}
              showView={permissionPage?.IsView === 1}
              showEdit={permissionPage?.IsEdit === 1}
              showExports={permissionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Article Master  Version History"
            />
          </>
        }
      />
    </Box>
  );
};

export default ArticleMaster;
