import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import industryMasterService from "../services/industryMasterServices";
import { chkDate } from "../../utils/constants";
import { IHoliday, IVHColumnRes } from "../../models/holiday.type";
import HolidayMasterService from "../services/holidayMasterService";
import dayjs from "dayjs";

export interface IHolidayData {
    HolidayTable: any;
    HolidayBydateRangeTable: any;
    isLoading: boolean;
    isHolidayAdded: boolean;
    isHolidayUpdated: boolean;
    versionHistory: { HolidayRecords: IHoliday[], HolidayVHeader: IVHColumnRes[] };
    isHolidayHistory: boolean;
    isHolidayDeleted: boolean;
    ToasterMsg: any;

}

export const initialState: IHolidayData = {
    HolidayTable: [],
    HolidayBydateRangeTable: [],
    isLoading: false,
    isHolidayAdded: false,
    isHolidayUpdated: false,
    versionHistory: { HolidayRecords: [], HolidayVHeader: [] },
    isHolidayHistory: false,
    isHolidayDeleted: false,
    ToasterMsg: { Message: "", Status: "" },
};

export const getHolidayList = createAsyncThunk(
    "getHolidayList",
    async (requesrParams: { FormID: number }, { rejectWithValue }) => {
        try {
            const response = await HolidayMasterService.getHolidayList(requesrParams);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getHolidayWithdaterange = createAsyncThunk("getHolidayWithdaterange", async (requesrParams: any, { rejectWithValue }) => {
    try {
        let reqbody = { ...requesrParams };
        const response: any = await HolidayMasterService.getHolidayWithdaterange(reqbody);
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const CreateHoliday = createAsyncThunk("CreateHoliday", async (requesrParams: any, { rejectWithValue }) => {    
    try {
        const response: any = await HolidayMasterService.CreateHoliday(requesrParams);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const UpdateHoliday = createAsyncThunk("UpdateHoliday", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await HolidayMasterService.UpdateHoliday(requesrParams);
        console.log(requesrParams, "requesrParams");

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteHoliday = createAsyncThunk(
    "deleteHoliday",
    async (requesrParams: any, { rejectWithValue }) => {
        console.log(requesrParams, "requesrParams");

        try {
            let reqbody = { ...requesrParams };
            console.log(reqbody, "requesrParams");
            const response: any = await HolidayMasterService.deleteHoliday(reqbody);
            return response.data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getHolidayHistory = createAsyncThunk("getHolidayHistory", async (requesrParams: any, { rejectWithValue }) => {
    try {
        let reqbody = { ...requesrParams };
        const response: any = await HolidayMasterService.getVersionHistoryHoliday(reqbody);
        const { HolidayList, HeaderJson } = response.data;
        return { HolidayList: HolidayList, HolidayVHeader: HeaderJson.ColumnHeaders }

    } catch (error) {
        return rejectWithValue(error);
    }
});

export const HolidayMasterDataSlice = createSlice({
    name: "HolidayMasterData",
    initialState,
    reducers: {
        updateHolidayAddandEdit: (state, action) => {
            state.isHolidayAdded = false;
            state.isHolidayUpdated = false;
            state.isHolidayDeleted = false;
            state.ToasterMsg = { Message: "", Status: "" };
        },
        updateUser: (state, action) => {
            if (action.payload.UserId) {
                let indexVendor = state.HolidayTable.findIndex((data: any) => data.UserId === action.payload.UserId);
                let copyVendorData = [...state.HolidayTable];
                copyVendorData[indexVendor] = action.payload;
                state.HolidayTable = copyVendorData;
            }
        },
        updateVersionHistory: (state, action) => {
            state.versionHistory.HolidayRecords = [];
            state.versionHistory.HolidayVHeader = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getHolidayList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getHolidayList.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action.payload, "action.payload");

                state.HolidayTable = action.payload.map((user: any) => {
                    user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                    user.Holiday_Date = dayjs(user?.Holiday_Date)?.format('DD-MMM-YYYY'); ;
                    user.Holiday_Type_National = user.Holiday_Type_National === true ? "Local Holiday" : "National Holiday";
                    return user;
                });
                state.isLoading = false;
            })
            .addCase(getHolidayList.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })

            .addCase(getHolidayWithdaterange.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getHolidayWithdaterange.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action.payload, "action.payload");

                state.HolidayBydateRangeTable = action.payload.map((user: any) => {
                    user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                    user.Holiday_Date = dayjs(user?.Holiday_Date)?.format('DD-MMM-YYYY'); ;
                    user.Holiday_Type_National = user.Holiday_Type_National === true ? "Local Holiday" : "National Holiday";
                    return user;
                });
                state.isLoading = false;
            })
            .addCase(getHolidayWithdaterange.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })

            .addCase(CreateHoliday.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(CreateHoliday.fulfilled, (state, action: PayloadAction<any>) => {
                
                state.isHolidayAdded = true;
                state.isLoading = false;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(CreateHoliday.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                // state.ToasterMsg = action.payload.response.data;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })

            .addCase(UpdateHoliday.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(UpdateHoliday.fulfilled, (state, action: PayloadAction<any>) => {
               
                state.isHolidayUpdated = true;
                state.isLoading = false;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(UpdateHoliday.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                // state.ToasterMsg = action.payload.response.data;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })

            .addCase(deleteHoliday.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteHoliday.fulfilled, (state, action: PayloadAction<any>) => {
                state.isHolidayDeleted = true;
                state.isLoading = false;
                // state.ToasterMsg = action.payload.Message;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(deleteHoliday.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                // state.ToasterMsg = action.payload.response.data;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(getHolidayHistory.pending, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(getHolidayHistory.fulfilled, (state, action: PayloadAction<any>) => {
                state.versionHistory.HolidayRecords = action.payload.HolidayList.map((user: any) => {
                    user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                    user.Holiday_Type_National = user.Holiday_Type_National === true ? "Local Holiday" : "National Holiday";
                    user.Holiday_Date = chkDate(user.Holiday_Date);

                    return user;
                });
                state.versionHistory.HolidayVHeader = action.payload.HolidayVHeader
                state.isLoading = false;
                // state.ToasterMsg = "Department data deleted successfully";
            })
            .addCase(getHolidayHistory.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })

    },
});

export const { updateHolidayAddandEdit, updateUser, updateVersionHistory } = HolidayMasterDataSlice.actions;
export default HolidayMasterDataSlice.reducer;
