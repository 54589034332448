import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRecordLockSettings } from "../../models/recordLock.type";
import recordLockService from "../services/recordLockService";

export interface IRecordData {
  recordLock: IRecordLockSettings[];
  isLoading: boolean;
  isRecordLockFetched: boolean;
  isRecordLockAdded: boolean;
  isRecordLockUpdated: boolean;
  isRecordLockEditing: boolean;
  lockeRecorddData: any;
}

export const initialState: IRecordData = {
  recordLock: [],
  isLoading: false,
  isRecordLockFetched: false,
  isRecordLockAdded: false,
  isRecordLockUpdated: false,
  isRecordLockEditing: false,
  lockeRecorddData: null,
};

export const GetRecordLock = createAsyncThunk("getRecordLock", async (requestBody: any, { rejectWithValue }) => {
  try {
    const response = await recordLockService.GetRecordLock(requestBody);
    const data = response;
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const UpdateRecordLock = createAsyncThunk("UpdateRecordLock", async (requestBody: any, { rejectWithValue }) => {
  try {
    const response = await recordLockService.UpdateRecordLock(requestBody);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const AddRecordLock = createAsyncThunk("AddRecordLock", async (requestBody: any, { rejectWithValue }) => {
  try {
    const response = await recordLockService.AddRecordLock(requestBody);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const ReleaseRecordLock = createAsyncThunk("ReleaseRecordLock", async (requestBody: any, { rejectWithValue }) => {
  try {
    const response = await recordLockService.UpdateRecordLock(requestBody);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const recordLocks = createSlice({
  name: "recordLockData",
  initialState,
  reducers: {
    updateRecordLock: (state, action: PayloadAction<IRecordLockSettings[]>) => {
      state.recordLock = action.payload;
      state.isLoading = false;
      state.isRecordLockFetched = false;
      state.isRecordLockAdded = false;
      state.isRecordLockUpdated = true;
    },
    SetRecordLockData: (state, action: PayloadAction<any>) => {
      state.lockeRecorddData = action.payload;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetRecordLock.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetRecordLock.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.recordLock = action.payload;
        state.isLoading = false;
        state.isRecordLockFetched = true;
        state.isRecordLockAdded = false;
        state.isRecordLockUpdated = false;
      })
      .addCase(GetRecordLock.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(AddRecordLock.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AddRecordLock.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.isRecordLockFetched = false;
        state.isRecordLockAdded = true;
        state.isRecordLockUpdated = false;
      })
      .addCase(AddRecordLock.rejected, (state) => {
        state.isLoading = false;
      }) .addCase(ReleaseRecordLock.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ReleaseRecordLock.fulfilled, (state, action: any) => {
        state.lockeRecorddData = null
        state.isLoading = false;
      })
      .addCase(ReleaseRecordLock.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { updateRecordLock , SetRecordLockData } = recordLocks.actions;
export default recordLocks.reducer;
