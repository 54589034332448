import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip, 
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import BasicTableStyle from "../../../../common/dynamicDataGrid/basicTable.module.scss";
import { useTheme } from "@mui/material/styles";
import { LovConfig, VOBWorflowPages } from "../../../../config/config";
import CustomSvg from "../../../../common/CustomSvg";
import CustomFileUploader from "../../../../common/dynamicFileUploader/CustomFileUploader";
import PageMainHeading from "../../../../common/CommonHeadings/PageMainHeading";
import PageInnerHeading from "../../../../common/CommonHeadings/PageInnerHeading";
import CustomRadioButton from "../../../../common/dynamicInputs/CustomRadioButton";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import CustomMultilineTextField from "../../../../common/dynamicInputs/CustomMultilineTextField";
import { textFieldWidth } from "../../../../utils/constants";
import LableandValuesPreview from "../../../../common/CommonPreview/LableandValuesPreview";
import CustomCheckBox from "../../../../common/dynamicInputs/CustomCheckBox";
import { AssessmentDetailContext } from "../CustomerOnBoardingAssessment";
import { validateCOBField } from "../AssessmentValidation";
import CustomSelect from "../../../../common/dynamicInputs/CustomSelect";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store/store";
import { fileHandlerUpdate, UploadAFile } from "../../../../store/slices/fileHandler";
import { updateLoader } from "../../../../store/slices/loader";
import fileHandlerService from "../../../../store/services/fileHandlerService";
import * as mime from "react-native-mime-types";
import FilteViewer from "../../../../common/fileViewer/FilteViewer";
interface AssessmentResponseCOBAnswerModeProps {
  isEdit?: boolean;
  isView?: boolean;
  preViewMode?: boolean;
  PageName: string;
  iscompleteDo: boolean;
  isAvailableDo: boolean;
}

const MemoizedFileViewer = React.memo(FilteViewer);
export const AssessmentResponseCOBAnswerMode: React.FC<AssessmentResponseCOBAnswerModeProps> = ({
  isEdit,
  isView,
  preViewMode,
  PageName,
  iscompleteDo,
  isAvailableDo,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { isFileUpload, uploadData, isFileUploadLoading } = useSelector((state: RootState) => state.fileHandler);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const Customercontext = useContext(AssessmentDetailContext);
  const [responseData, setResponseData] = useState<any[]>([]);
  const [optionData, setoptionData] = useState<any[]>([]);
  const [isEditData, setisEditData] = useState<any>({});
  const [Errors, setvalidationErrors] = useState<any>({});
  const [ApproveList, setApproveList] = useState<any[]>([]);
  const [attachmentFile, setAttachmentFile] = useState<any>("");
  const [IsfilePreview, setIsfilePreview] = useState<any>(false);
  const [filePreviewurl, setIsfilePreviewurl] = useState<any>("");
  const [fileName, setFileName] = useState<any>("");
  const [ischanges, setischanges] = useState<boolean>(false);

  const {
    selectedQuestion,
    QuestionList,
    selectedQuestionIndex,
    validationErrors,
    setselectedQuestionindex,
    setsaveQuestion,
  } = Customercontext!;

  useEffect(() => {
    setApproveList(lovTable.filter((item: any) => item.Name === LovConfig?.Pre_Approval) || []);
  }, [lovTable]);

  useEffect(() => {
    if (selectedQuestion) {
      console.log(selectedQuestion, "selectedQuestion");
      clearValuesandSetdefault();
      setisEditData(selectedQuestion);
      if (selectedQuestion?.Options) {
        converCheboxOptions(selectedQuestion?.Options);
      }
      if (selectedQuestion?.Attachment) {
        onfilePreview(selectedQuestion?.Attachment);
      }
    }
  }, [selectedQuestion]);

  const clearValuesandSetdefault = () => {
    setvalidationErrors({});
    setAttachmentFile("");
    setFileName("");
    setIsfilePreviewurl("");
    setIsfilePreview(false);
    setischanges(false);
  };

  const SelectedFileUpload = (data: any) => {
    if (data && data != null) {
      setAttachmentFile(data);
    } else {
      setAttachmentFile("");
    }
  };

  const uploadFile = () => {
    if (attachmentFile) {
      const blob = attachmentFile.pop();
      let formData = new FormData();
      formData.append("fileUploadRequest", blob);
      dispatch(UploadAFile(formData));
    }
  };

  const onfilePreview = async (bloburl: any) => {
    if (bloburl) {
      const fileName = decodeURIComponent(bloburl.substring(bloburl.indexOf("_") + 1));
      const response: any = await fileHandlerService.DownLoadAFile({ fileUrl: bloburl });
      const { data } = response;
      const mimeType = mime.lookup(fileName.substring(fileName.lastIndexOf(".") + 1));
      setFileName(fileName as string);
      setIsfilePreviewurl(`data:${mimeType};base64,${data}`);
      setIsfilePreview(true);
    }
  };

  useEffect(() => {
    dispatch(updateLoader(isFileUploadLoading));
  }, [isFileUploadLoading]);

  useEffect(() => {
    if (isFileUpload && uploadData) {
      setAttachmentFile("");
      handleCommonValueChange("Attachment", uploadData);
      dispatch(fileHandlerUpdate(false));
    }
  }, [isFileUpload]);

  const handleSubmitsingleAssessmentForm = () => {
    let staticindex: any = 0;
    let validationError: any = Errors;

    if (PageName == VOBWorflowPages.SMEResponse || PageName == VOBWorflowPages.SOReview) {
      if (isEditData?.Answer_Type) {
        validationError = validateCOBField(
          staticindex,
          "Response_In_Option",
          isEditData?.Response_In_Option,
          validationError
        );
      } else {
        validationError = validateCOBField(
          staticindex,
          "Response_In_Text",
          isEditData?.Response_In_Text,
          validationError
        );
      }
      if (isEditData?.EvidenceRequired) {
        validationError = validateCOBField(staticindex, "Attachment", isEditData?.Attachment, validationError);
      }
    }

    if (PageName == VOBWorflowPages.SOReview) {
      validationError = validateCOBField(staticindex, "SO_ReviewStatus", isEditData?.SO_ReviewStatus, validationError);
    }

    if (PageName == VOBWorflowPages.DoReview) {
      validationError = validateCOBField(staticindex, "DO_ReviewStatus", isEditData?.DO_ReviewStatus, validationError);
    }
    setvalidationErrors(validationError);

    if (validationError && Object.keys(validationError).length === 0) {
      setsaveQuestion && setsaveQuestion(isEditData);
      setischanges(false);
    }
    console.log(validationError, "validationError");
  };

  const handleCommonValueChange = (field: any, value: any) => {
    setischanges(true);
    setisEditData((pre: any) => ({ ...pre, [field]: value }));

    if (field !== "Attachment") {
      setvalidationErrors((pre: any) => validateCOBField(0, field, value, pre));
    } else {
      if (isEditData?.EvidenceRequired && field == "Attachment") {
        setvalidationErrors((pre: any) => validateCOBField(0, field, value, pre));
      }
    }
    if (field === "Attachment" && value) {
      onfilePreview(value);
    }
    if (field === "Attachment" && !value) {
      setFileName("");
      setIsfilePreviewurl("");
      setIsfilePreview(false);
    }
  };

  const converCheboxOptions = (opt: any) => {
    let val: any = [];
    opt.forEach((item: any) => {
      val.push({ value: item?.Assessment_Questionnaire_OptionId, lable: item?.Attribute_Response });
    });
    setoptionData(val);
  };

  const Answer = useMemo(() => {
    let ans: any = isEditData?.Answer_Type
      ? isEditData?.Options.find(
          (item: any) => item?.Assessment_Questionnaire_OptionId == isEditData?.Response_In_Option
        )?.Attribute_Response
      : isEditData?.Response_In_Text;

    return ans ? ans : "";
  }, [isEditData]);

  const fileViewer = useMemo(() => {
    return <MemoizedFileViewer URL={filePreviewurl} fileName={fileName} />;
  }, [filePreviewurl, fileName]);

  return (
    <>
      <Stack key={`sigleQuestionanswerMode-${selectedQuestionIndex + 1}`} spacing={3}>
        <Box sx={{ display: "flex", width: "100%", minHeight: "100%" }}>
          <Box sx={{ width: "50%", paddingY: "10px" }}>
            <Stack spacing={2}>
              <Typography variant="h4" sx={{ fontSize: "20px", textAlign: "left" }}>
                {isEditData?.Question}
              </Typography>

              {(PageName != VOBWorflowPages.SMEResponse || preViewMode) && (
                <Box>
                  <LableandValuesPreview lable="Response" Value={`${Answer || "-"}`} />
                </Box>
              )}
              {(PageName != VOBWorflowPages.SMEResponse || preViewMode) && (
                <Box>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={textFieldWidth.xs}
                      sm={textFieldWidth.sm}
                      md={textFieldWidth.md}
                      lg={textFieldWidth.lg}
                      xl={textFieldWidth.xl}
                    >
                      <LableandValuesPreview lable="SME Department" Value={`${isEditData?.SME_Name || "-"}`} />
                    </Grid>
                    <Grid
                      item
                      xs={textFieldWidth.xs}
                      sm={textFieldWidth.sm}
                      md={textFieldWidth.md}
                      lg={textFieldWidth.lg}
                      xl={textFieldWidth.xl}
                    >
                      <LableandValuesPreview lable="Responder" Value={`${isEditData?.IsSME ? "SME" : "SO"}`} />
                    </Grid>
                    {(PageName == VOBWorflowPages.DoReview || preViewMode) && (
                      <Grid
                        item
                        xs={textFieldWidth.xs}
                        sm={textFieldWidth.sm}
                        md={textFieldWidth.md}
                        lg={textFieldWidth.lg}
                        xl={textFieldWidth.xl}
                      >
                        <LableandValuesPreview
                          lable="SO Decision"
                          Value={`${
                            ApproveList.find((statusitem: any) => statusitem.LovId == isEditData?.SO_ReviewStatus)
                              ?.Options || "-"
                          }`}
                        />
                      </Grid>
                    )}
                    {(PageName == VOBWorflowPages.DoReview || preViewMode) && (
                      <Grid
                        item
                        xs={textFieldWidth.xs}
                        sm={textFieldWidth.sm}
                        md={textFieldWidth.md}
                        lg={textFieldWidth.lg}
                        xl={textFieldWidth.xl}
                      >
                        <LableandValuesPreview lable="SO Remark" Value={`${isEditData?.SO_Remarks || "-"}`} />
                      </Grid>
                    )}

                    {((PageName == VOBWorflowPages.SOReview && iscompleteDo) || preViewMode) && (
                      <Grid
                        item
                        xs={textFieldWidth.xs}
                        sm={textFieldWidth.sm}
                        md={textFieldWidth.md}
                        lg={textFieldWidth.lg}
                        xl={textFieldWidth.xl}
                      >
                        <LableandValuesPreview
                          lable="DO Decision"
                          Value={`${
                            ApproveList.find((statusitem: any) => statusitem.LovId == isEditData?.DO_ReviewStatus)
                              ?.Options || "-"
                          }`}
                        />
                      </Grid>
                    )}

                    {((PageName == VOBWorflowPages.SOReview && iscompleteDo) || preViewMode) && (
                      <Grid
                        item
                        xs={textFieldWidth.xs}
                        sm={textFieldWidth.sm}
                        md={textFieldWidth.md}
                        lg={textFieldWidth.lg}
                        xl={textFieldWidth.xl}
                      >
                        <LableandValuesPreview lable="DO Remark" Value={`${isEditData?.DO_Remarks || "-"}`} />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}

              {(PageName == VOBWorflowPages.SMEResponse || PageName == VOBWorflowPages.SOReview) && (
                <Box sx={{ paddingY: "15px" }}>
                  {isEditData?.Answer_Type ? (
                    <CustomRadioButton
                      key={`multi-${selectedQuestionIndex + 1}`}
                      list={optionData}
                      style={{ marginTop: "-10px" }}
                      label=""
                      value={isEditData?.Response_In_Option}
                      readOnly={isView || preViewMode}
                      direction="column"
                      onChange={(e) => {
                        handleCommonValueChange("Response_In_Option", e);
                      }}
                      error={Boolean(Errors[`${0}-Response_In_Option`])}
                      helperText={Errors[`${0}-Response_In_Option`]}
                    />
                  ) : (
                    <CustomMultilineTextField
                      key={`text-${selectedQuestionIndex + 1}`}
                      label={"Enter Answer"}
                      readOnly={isView || preViewMode}
                      rows={6}
                      maxRows={10}
                      value={isEditData?.Response_In_Text}
                      onChange={(value) => handleCommonValueChange("Response_In_Text", value)}
                      error={Boolean(Errors[`${0}-Response_In_Text`])}
                      helperText={Errors[`${0}-Response_In_Text`]}
                    />
                  )}
                </Box>
              )}

              {PageName == VOBWorflowPages.SOReview ? (
                <Box>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={textFieldWidth.xs}
                      sm={textFieldWidth.sm}
                      md={textFieldWidth.md}
                      lg={textFieldWidth.lg}
                      xl={textFieldWidth.xl}
                    >
                      {ApproveList?.length > 0 && (
                        <CustomSelect
                          name={"SO_ReviewStatus"}
                          key={`Questions SO Review status-${selectedQuestionIndex + 1}`}
                          valueKey={"LovId"}
                          optionLabelKey="Options"
                          options={ApproveList?.length > 0 ? ApproveList : []}
                          required={true}
                          label={"SO Review Status"}
                          value={isEditData?.SO_ReviewStatus}
                          onChange={(value) => handleCommonValueChange("SO_ReviewStatus", value)}
                          error={Boolean(Errors[`${0}-SO_ReviewStatus`])}
                          helperText={Errors[`${0}-SO_ReviewStatus`]}
                        />
                      )}
                    </Grid>{" "}
                  </Grid>{" "}
                </Box>
              ) : null}

              {PageName == VOBWorflowPages.SOReview && (
                <Box>
                  <CustomMultilineTextField
                    key={`So_remark_assessment-${selectedQuestionIndex + 1}`}
                    label={"SO Remark"}
                    readOnly={isView || preViewMode}
                    rows={6}
                    maxRows={10}
                    value={isEditData?.SO_Remarks}
                    onChange={(value) => handleCommonValueChange("SO_Remarks", value)}
                    error={Boolean(Errors[`${0}-SO_Remarks`])}
                    helperText={Errors[`${0}-SO_Remarks`]}
                  />
                </Box>
              )}

              <Divider />

              {PageName == VOBWorflowPages.DoReview ? (
                <Box>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={textFieldWidth.xs}
                      sm={textFieldWidth.sm}
                      md={textFieldWidth.md}
                      lg={textFieldWidth.lg}
                      xl={textFieldWidth.xl}
                    >
                       {ApproveList?.length > 0 && (
                      <CustomSelect
                        name={""}
                        key={`Questions Do Review-${selectedQuestionIndex + 1}`}
                        valueKey={"LovId"}
                        optionLabelKey="Options"
                        options={ApproveList}
                        required={true}
                        label={"DO Review Status"}
                        value={isEditData?.DO_ReviewStatus}
                        onChange={(value) => handleCommonValueChange("DO_ReviewStatus", value)}
                        error={Boolean(Errors[`${0}-DO_ReviewStatus`])}
                        helperText={Errors[`${0}-DO_ReviewStatus`]}
                      />)}
                    </Grid>{" "}
                  </Grid>{" "}
                </Box>
              ) : null}

              {PageName == VOBWorflowPages.DoReview && (
                <Box>
                  <CustomMultilineTextField
                    key={`Do_remark_assessment-${selectedQuestionIndex + 1}`}
                    label={"DO Remark"}
                    readOnly={isView || preViewMode}
                    rows={6}
                    maxRows={10}
                    value={isEditData?.DO_Remarks}
                    onChange={(value) => handleCommonValueChange("DO_Remarks", value)}
                    error={Boolean(Errors[`${0}-DO_Remarks`])}
                    helperText={Errors[`${0}-DO_Remarks`]}
                  />
                </Box>
              )}

              <Box>
                <Grid container spacing={2}>
                  {((PageName == VOBWorflowPages.SOReview && isAvailableDo && !iscompleteDo) || preViewMode) && (
                    <Grid
                      item
                      xs={textFieldWidth.xs}
                      sm={textFieldWidth.sm}
                      md={textFieldWidth.md}
                      lg={textFieldWidth.lg}
                      xl={textFieldWidth.xl}
                    >
                      <CustomCheckBox
                        key={`${""} - Questions is Do-${selectedQuestionIndex + 1}`}
                        Width={"100%"}
                        label="Send To DO"
                        isStyle={true}
                        readOnly={isView || preViewMode}
                        value={Boolean(isEditData?.IsDo)}
                        onChange={
                          (event) => handleCommonValueChange("IsDo", event.target.checked)
                          // handleCommonValueChange("")
                        }
                      />
                    </Grid>
                  )}
                  {((PageName == VOBWorflowPages.SOReview && iscompleteDo) || PageName == VOBWorflowPages.DoReview ||
                    preViewMode) && (
                      <Grid
                        item
                        xs={textFieldWidth.xs}
                        sm={textFieldWidth.sm}
                        md={textFieldWidth.md}
                        lg={textFieldWidth.lg}
                        xl={textFieldWidth.xl}
                      >
                        <CustomCheckBox
                          key={`${""} - Questions is Pm-${selectedQuestionIndex + 1}`}
                          Width={"100%"}
                          label="Send To PM"
                          isStyle={true}
                          readOnly={isView || preViewMode}
                          value={Boolean(isEditData?.IsAddInPM)}
                          onChange={
                            (event) => handleCommonValueChange("IsAddInPM", event.target.checked)
                            // handleCommonValueChange()
                          }
                        />
                      </Grid>
                    )}
                </Grid>
              </Box>

              {!preViewMode && (
                <Box sx={{ display: "flex" }}>
                  <CustomButton
                    disabled={!ischanges}
                    type="submit"
                    name={"Submit Answer"}
                    onClick={() => handleSubmitsingleAssessmentForm()}
                  />
                </Box>
              )}
              {!preViewMode && (
                <Box sx={{ mt: 2 }}>
                  <Typography sx={{ fontSize: "8px", textAlign: "left", color: ischanges ? "red" : "#6A6A6A" }}>
                    {!ischanges
                      ? `Note : You made changes. Please click "Submit Answer" to save them; otherwise, your changes will not be saved.`
                      : `Warning : Your changes have not been saved. Please click "Submit Answer" to save them.`}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Box>

          <Box key={`docfiles-${selectedQuestionIndex + 1}`} sx={{ width: "50%", padding: "10px", height: "100%" }}>
            
            <Box>
            <Typography  variant="overline" gutterBottom sx={{ textAlign: 'left' , color : '#6a6a6a' , display: 'block' , textTransform:'uppercase' , fontSize:'13px' }}>
        {"ATTACHMENT"} &nbsp; {isEditData?.EvidenceRequired ? <span style={{ color: "red" }}>*</span> : null }
      </Typography>  
            </Box>
            {!IsfilePreview && !preViewMode ? (
              <CustomFileUploader onFileUpload={SelectedFileUpload} maxFileSize={5048576} acceptFormats={[".png", ".jpeg", ".jpg", ".pdf"]} />
            ) : (
              <Box>
                {!preViewMode && (PageName == VOBWorflowPages.SOReview || PageName == VOBWorflowPages.SMEResponse) &&  (
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingBottom: 1 }}>
                    {" "}
                    <Tooltip title="Remove File">
                      <IconButton
                        sx={{ padding: "0px" }}
                        disabled={isView}
                        onClick={() => handleCommonValueChange("Attachment", "")}
                      >
                        <CustomSvg color={`${theme.palette.info.main}`} size={"24px"} name={"delete"} />{" "}
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                {fileViewer}{" "}
              </Box>
            )}
            <Box sx={{ mt: 1, width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              {attachmentFile && !IsfilePreview && (
                <CustomButton type="submit" name={"Upload File"} onClick={() => uploadFile()} />
              )}
            </Box>

            <FormHelperText error={Boolean(Errors[`${0}-Attachment`])} id="my-helper-text">
              {Errors[`${0}-Attachment`]}
            </FormHelperText>
          </Box>
        </Box>
       {(setselectedQuestionindex && (selectedQuestionIndex || selectedQuestionIndex === 0) ) ? <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, width: "100%" }}>
          <CustomButton
            type="reset"
            variant="outlined"
            disabled={ischanges || selectedQuestionIndex == 0}
            name={"previous"}
            onClick={() => {
              setselectedQuestionindex &&  setselectedQuestionindex(selectedQuestionIndex - 1);
            }}
          /> 
       <CustomButton
            disabled={ischanges || QuestionList?.length == selectedQuestionIndex + 1}
            variant="outlined"
            type="reset"
            name={"Next"}
            onClick={() => setselectedQuestionindex && setselectedQuestionindex(selectedQuestionIndex + 1)}
          /> 
        </Box> : null }
      </Stack>
    </>
  );
};
