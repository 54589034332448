import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PMContractService from "../services/PMContractListService";

export interface IContractDetailsData {
  contractViewDetails: any[];
  PerformaceScopeDetails: any[];
  contractViewDetailsWithDateRange: any[];
  contractClauseDetails: any[];
  isContractDetailsLoading: boolean;
  isContractDetailsAdded: boolean;
  isContractDetailsUpdated: boolean;
  isContractDetailsDeleted: boolean;
  ToasterMsg: { message: string; status: string };
  
}

export const initialState: IContractDetailsData = {
  contractViewDetails: [],
  PerformaceScopeDetails: [],
  contractViewDetailsWithDateRange: [],
  contractClauseDetails: [],
  isContractDetailsLoading: false,
  isContractDetailsAdded: false,
  isContractDetailsUpdated: false,
  isContractDetailsDeleted: false,
  ToasterMsg: { message: "", status: "" },
};



export const getContractViewList = createAsyncThunk("getContractViewList", async () => {
  try {
    const response: any = await PMContractService.getContractViewDetail({});

    return response;
  } catch (error) {
    console.log(error);
  }
});

export const getContractViewWithdaterange = createAsyncThunk("getContractViewWithdaterange", async (requesrParams: any, { rejectWithValue }) => {
  try {
      let reqbody = { ...requesrParams };
      const response: any = await PMContractService.getContractViewWithdaterange(reqbody);
      const { data } = response;
      return data;
  } catch (error) {
      return rejectWithValue(error);
  }
});

export const getPerfomenceScopeDetails = createAsyncThunk("getPerfomenceScopeDetails", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await PMContractService.getPerfomanceScopeDetail(reqbody);

    return response;
  } catch (error) {
    console.log(error);
  }
});

export const getClauseDetailsBycontract = createAsyncThunk("getClauseDetailsBycontract", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await PMContractService.getClauseDetailsByContrctId(reqbody);

    return response?.data;
  } catch (error) {
    console.log(error);
  }
});

export const PMContractListSlice = createSlice({
  name: "PMContractListSlice",
  initialState,
  reducers: {
    updateContractDetailsAddandEdit: (state, action) => {
      state.isContractDetailsAdded = false;
      state.isContractDetailsUpdated = false;
      state.isContractDetailsDeleted = false;
      state.ToasterMsg = { message: "", status: "" };
    },
    clearData: (state, action) => {
    },
    updateVersionHistory: (state, action) => {
      console.log(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      
      .addCase(getContractViewList.pending, (state) => {
        state.isContractDetailsLoading = true;
      })
      .addCase(getContractViewList.fulfilled, (state, action: PayloadAction<any>) => {
        state.contractViewDetails = action.payload.contractDetails.map((user: any) => {
          user.status = user.status === true ? "Active" : "Inactive";
          return user;
      });;
        state.isContractDetailsLoading = false;
      })
      .addCase(getContractViewList.rejected, (state) => {
        state.isContractDetailsLoading = false;
      })
      .addCase(getContractViewWithdaterange.pending, (state) => {
        state.isContractDetailsLoading = true;
      })
      .addCase(getContractViewWithdaterange.fulfilled, (state, action: PayloadAction<any>) => {
        state.contractViewDetailsWithDateRange = action.payload?.contractDetails.map((user: any) => {
          user.status = user?.status === true ? "Active" : "Inactive";
          return user;
      });      
        state.isContractDetailsLoading = false;
      })
      .addCase(getContractViewWithdaterange.rejected, (state) => {
        state.isContractDetailsLoading = false;
      })
      .addCase(getPerfomenceScopeDetails.pending, (state) => {
        state.isContractDetailsLoading = true;
      })
      .addCase(getPerfomenceScopeDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.PerformaceScopeDetails = action.payload?.contractDetails;
        state.isContractDetailsLoading = false;
      })
      .addCase(getPerfomenceScopeDetails.rejected, (state) => {
        state.isContractDetailsLoading = false;
      })
      .addCase(getClauseDetailsBycontract.pending, (state) => {
        state.isContractDetailsLoading = true;
        // state.contractClauseDetails = []
      })
      .addCase(getClauseDetailsBycontract.fulfilled, (state, action: PayloadAction<any>) => {
        state.contractClauseDetails = action.payload?.clauseDetails;
        state.isContractDetailsLoading = false;
      })
      .addCase(getClauseDetailsBycontract.rejected, (state) => {
        state.isContractDetailsLoading = false;
      })
     
  },
});

export const { updateContractDetailsAddandEdit, updateVersionHistory, clearData } = PMContractListSlice.actions;
export default PMContractListSlice.reducer;
