export const AQMMapping_URL={
    AddAQMMapping: `/addAssessmentQuestionaireMapping`,
    GetAQMMapping:`/getAssessmentQuestionaireMappings`,
    GetAQMMappingbyvendorandproject:`/getAssessmentQuestionaireMappingsByVendorProject`,
    GetAllAQMMapping:`/getAssessmentQuestionaireMappings`,
    UpdateAQMMapping:`/updateAssessmentQuestionaireMapping`,
    DeleteAQMMapping:`/deleteAssessmentQuestionaireMapping`,
    VersionHistory:`/getAssessmentQuestionaireMappingHistory`,
    GetAQMMappingByVendorID:`/getAssessmentQuestionaireMappingsByVendorID`,
    getAssessmentHistryByAQMMappingID:`/getAssessmentMappingQuestionsByID`,
}