import { Box, Stack, Typography } from "@mui/material";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatch } from "../../store/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { clearProbeData } from "../../store/slices/ProbeData";
import { AddApiCatalog } from "./AddApiCatalog";
import { apiCatalogAddandSave, getAllApiCatalog, GetApiCatalogWithAllDetailsByID, RemoveApiCatalog } from "../../store/slices/apiCatalog";
import ApiCatalogService from "../../store/services/apiCatalog";
import { FormProvider, useForm } from "react-hook-form";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { updateLoader, updateToaster } from "../../store/slices/loader";
const columns = {
    table_name: "API Catalog",
    isAction: true,
    columns: [
        {
            Field: "API_Group",
            FieldType: "string",
            DisplayName: "API Group",
        },

        {
            Field: "APICode",
            FieldType: "string",
            DisplayName: "API Code",
        },

        {
            Field: "API_Group",
            FieldType: "string",
            DisplayName: "API Name",
        },
        {
            Field: "URL",
            FieldType: "string",
            DisplayName: "Url",
        },
        {
            Field: "NoOfParameters",
            FieldType: "string",
            DisplayName: "No of parameter",
        },
        {
            Field: "IsActive",
            DisplayName: "Status",
            FieldType: "string",
        },
    ],
};

const ApiCatalog = () => {
    const dispatch = useAppDispatch();
    const { draftdata, isLoadingDraft, isDraftAdded, isDraftUpdated, DraftToasterMsg } = useSelector(
        (state: RootState) => state.saveAsDraft
    );
    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );
    const { ApiCatalogTable, isLoading, isApiCatalogAdded, isApiCatalogUpdated, ToasterMsg, apiCatalogDatas, isCatalogRemoved } =
        useSelector((state: RootState) => state.ApiCatalogData);
    const [apiCatalogFormData, setApiCatalogFormData] = useState<any>([]);
    const [HeaderColumn] = useState(columns);
    const [IsEditData, setIsEditData] = useState<any>();
    const [isEdit, setIsEdit] = useState(false);
    const [Option, setOption] = useState("");
    const [isAddorEdit, setIsAddorEdit] = useState(false);
    const [isEditData, setIsEditDate] = useState<any>(null);
    const [isDraftData, setIsDraftData] = useState(false);
    const [isDeleteData, setIsDeleteData] = useState<any>(null);

    const columns_version: any = {
        table_name: "API catalog",
        isAction: false,
        columns: [],
    };
    useEffect(() => {

        if (isApiCatalogAdded) {
            dispatch(apiCatalogAddandSave(false));
            dispatch(
                updateToaster({
                    isToaster: true,
                    toasterMsg: ToasterMsg.Message,
                    isTosterFailed: ToasterMsg.Status === "false" ? true : false,
                })
            );
            dispatch(
                getAllApiCatalog({
                    FormID: 2
                })
            );
            //   if (userLoginResponse) {
            //     dispatch(getNotifications(userLoginResponse?.UserId));
            //   }
        } else if (isApiCatalogUpdated) {
            dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message }));

            dispatch(apiCatalogAddandSave(false));
            dispatch(
                getAllApiCatalog({
                    FormID: 2
                })
            );
        }
        else if (isCatalogRemoved) {
            setIsDeleteData(null);
            dispatch(
                updateToaster({
                    isToaster: true,
                    toasterMsg: ToasterMsg.Message,
                    isTosterFailed: ToasterMsg.Status === "false" ? true : false,
                })
            );
            setTimeout(() => {
                dispatch(apiCatalogAddandSave(false));
            }, 1000);
            dispatch(
                getAllApiCatalog({
                    FormID: 2,
                })
            );
        }
    }, [isCatalogRemoved, isApiCatalogUpdated, isApiCatalogAdded])
    useEffect(() => {
        callApiData();
    }, []);

    const callApiData = () => {
        dispatch(getAllApiCatalog({ FormID: 2 }));
    };
    useEffect(() => {
        setApiCatalogFormData(ApiCatalogTable)
    }, [ApiCatalogTable])
    useEffect(() => {
        dispatch(getAllApiCatalog({ FormID: 2 }));
    }, [IsEditData, isCatalogRemoved]);
    let ApicatalogFormData = {
        APIParameters: isEditData ? isEditData?.APIParameters : [],
        ApiFields: isEditData ? isEditData?.ApiFields : [],
    };
    const ApicatalogFormDataFormMethods = useForm({ defaultValues: ApicatalogFormData });
    const {
        handleSubmit: ApicatalogFormDataHandleSubmit,
        getValues: ApicatalogFormDataGetValues,
        formState: { isDirty: otherDetailsIsDirty },
        setValue: ApicatalogFormDataSetValue,
        reset: ApicatalogFormDataReset,
    } = ApicatalogFormDataFormMethods;

    const handleOpen = async (data: any, Option: string) => {
        setIsEditData(null)
        if (Option === "edit") {
            // dispatch(GetApiCatalogWithAllDetailsByID({CatalogID:data?.Catalog_Id} ));
            const response = await ApiCatalogService.GetApiCatalogWithAllDetailsByID({ CatalogID: data?.Catalog_Id });
            setIsEditData(response.data)
            setIsEditData(response.data)
            setOption(Option);
            setIsEdit(true);
            setIsAddorEdit(true);
            dispatch(updateLoader(false));
        } else if (Option === "view") {
            const response = await ApiCatalogService.GetApiCatalogWithAllDetailsByID({ CatalogID: data?.Catalog_Id });
            setIsEditData(response.data)
            setIsEditData(response.data)
            setOption(Option);
            setIsEdit(true);
            setIsAddorEdit(true);
            dispatch(updateLoader(false));
        } if (Option === "delete") {
            setOption(Option);
            setIsDeleteData(data);
        }
    };

    const setAddFormcloseoropen = (value: any) => {
        setIsEditData(null);
        setIsAddorEdit(value);

        // setIsDraftDate(null)
        setOption("");
        setIsDraftData(false);
    };
    const ApiActive = () => {
        // let formData = { ...isDeleteData };
        let formData: any = {};
        formData.ModuleName = "API Catalog";
        formData.Activity = isDeleteData.IsActive === "Active" ? "Inactive" : "Active";
        formData.IsActive = isDeleteData.IsActive === "Active" ? 0 : 1;
        formData.Catalog_Id = isDeleteData.Catalog_Id;
        formData.Remarks = "";
        formData.Created_By = user?.UserName;
        formData.APImasterid = isDeleteData.Id;
        formData.UpdatedBy = user?.UserName;
        // formData.Status= isDeleteData.IsActive === "Active" ? false : true;
        // formData.IsActive = isDeleteData.IsActive === "Active" ? 0 : 1;
        dispatch(RemoveApiCatalog(formData));
        setIsDeleteData(null);
    };
    return (
        <>
            {!isAddorEdit ? (
                <Box
                    sx={{
                        width: "100%",
                    }}
                >
                    <Stack spacing={2}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "left",
                            }}
                        >
                            <Typography variant="h6" color={"text.primary"}>
                                API Catalog
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <MaterialDynamicGrid
                                data={HeaderColumn}
                                rows={apiCatalogFormData}
                                // rows={isShowDraft ? vendorDetails : vendorDetailsDraftData}
                                handleAction={handleOpen}
                                handleAdd={() => { setAddFormcloseoropen(true) }}
                                enableZoomIcon={true}
                                showAdd={true}
                                showhistory={false}
                                showDelete={true}
                                showView={true}
                                showEdit={true}
                                showExports={true}
                                userId={userLoginResponse?.UserId || 0}
                                pageName="Api Catalog"
                            />
                        </Box>
                    </Stack>
                </Box>
            ) : (
                <Box sx={{ width: "100%" }}>
                    <FormProvider {...ApicatalogFormDataFormMethods}>
                        <AddApiCatalog
                            view={Option == "view" ? true : false}
                            edit={Option == "edit" ? true : false}
                            isEditData={IsEditData}
                            //    SignzyData={SignzyData}
                            onClose={() => {
                                setAddFormcloseoropen(false);
                                dispatch(clearProbeData())
                            }}
                            isDraftData={isDraftData}
                        />
                    </FormProvider>
                </Box>
            )}
            <ModalPopUp
                onDeleteMethod={() => ApiActive()}
                isDelete={true}
                onHide={() => setIsDeleteData(null)}
                show={isDeleteData ? true : false}
                header={"Confirm Action"}
                text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"} the selected API?`}
                ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
            />

        </>
    );
};

export default ApiCatalog;
