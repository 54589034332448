import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { ISeverityMasterResponse } from "../../../models/performanceManagementModels/severityMaster.type";
import { Threshold_MASTER_URL } from './endpoint/ThresholdMaster'
import { IThresholdMasterResponse } from "../../../models/performanceManagementModels/thresholdMaster.type";

const getAllThresholdMaster = (): Promise<IThresholdMasterResponse> => axiosInstance
  .post(Threshold_MASTER_URL.getAllThresholdMaster)
  .then((response: AxiosResponse<IThresholdMasterResponse>) => {
    if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
      return response.data;
    }
    throw new Error("Error getting Data" + response);
  })
  .catch((error: AxiosError | string) => {
    throw error;
  });
const saveThresholdMaster = (requestBody: any): Promise<IThresholdMasterResponse> =>
  axiosInstance
    .post(Threshold_MASTER_URL.saveThresholdMaster, requestBody)
    .then((response: AxiosResponse<IThresholdMasterResponse>) => {

      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const updateThresholdMaster = (requestBody: any): Promise<IThresholdMasterResponse> =>
  axiosInstance
    .post(Threshold_MASTER_URL.updateThresholdMaster, requestBody)
    .then((response: AxiosResponse<IThresholdMasterResponse>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const deleteThresholdMaster = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(Threshold_MASTER_URL.EnableOrDisableSeverity, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403)  {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const ThresholdMasterMasterService = {
    getAllThresholdMaster, saveThresholdMaster, updateThresholdMaster,deleteThresholdMaster
};

export default ThresholdMasterMasterService;
