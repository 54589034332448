import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FormTitleMasterService from "../services/formTitleMasterService";

export interface IFormTitleData {
    FormTitleTable: any;
    currentpageTitleData: any;
    currentWorkFlowStepTitle: any;
    isLoading: boolean;
    isFormTitleAddandEdit: boolean;
    // versionHistory: { HolidayRecords: any[], HolidayVHeader: any[] };
    versionHistory: any;
    isFormTitleHistory: boolean;
    isFormTitleDeleted: boolean;
    ToasterMsg: any;

}

export const initialState: IFormTitleData = {
    FormTitleTable: [],
    currentpageTitleData: null,
    currentWorkFlowStepTitle: null,
    isLoading: false,
    isFormTitleAddandEdit: false,
    // versionHistory: { HolidayRecords: [], HolidayVHeader: [] },
    versionHistory: [],
    isFormTitleHistory: false,
    isFormTitleDeleted: false,
    ToasterMsg: { Message: "", Status: "" },
};

export const getFormTitleList = createAsyncThunk(
    "getFormTitleList",
    async (requesrParams: {}, { rejectWithValue }) => {
        try {
            const response = await FormTitleMasterService.getFormTitleList(requesrParams);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const AddorEditFormTitle = createAsyncThunk("AddorEditFormTitle", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await FormTitleMasterService.AddorEditFormTitle(requesrParams);
        console.log(requesrParams, "requesrParams");

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteFormTitle = createAsyncThunk(
    "deleteFormTitle",
    async (requesrParams: any, { rejectWithValue }) => {
        console.log(requesrParams, "requesrParams");

        try {
            let reqbody = { ...requesrParams };
            console.log(reqbody, "requesrParams");
            const response: any = await FormTitleMasterService.deleteFormTitle(reqbody);
            return response.data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getFormTitleHistory = createAsyncThunk("getFormTitleHistory", async (requesrParams: any, { rejectWithValue }) => {
    try {
        let reqbody = { ...requesrParams };
        const response: any = await FormTitleMasterService.getVersionHistoryFormTitle(reqbody);
        // const { HolidayList, HeaderJson } = response.data;
        // return { HolidayList: HolidayList, HolidayVHeader: HeaderJson.ColumnHeaders }
        const { data } = response;
        return data;

    } catch (error) {
        return rejectWithValue(error);
    }
});

// export const setCurrentPageTitleData = (data: any) => ({
//     type: "SET_CURRENT_PAGE_TITLE_DATA",
//     payload: data,
// });

export const FormTitleMasterDataSlice = createSlice({
    name: "FormTitleMasterData",
    initialState,
    reducers: {
        updateFormTitleAddandEdit: (state, action) => {
            state.isFormTitleAddandEdit = false;
            state.isFormTitleDeleted = false;
            state.ToasterMsg = { Message: "", Status: "" };
        },
        updateUser: (state, action) => {
            if (action.payload.UserId) {
                let indexVendor = state.FormTitleTable.findIndex((data: any) => data.UserId === action.payload.UserId);
                let copyVendorData = [...state.FormTitleTable];
                copyVendorData[indexVendor] = action.payload;
                state.FormTitleTable = copyVendorData;
            }
        },
        updateVersionHistory: (state, action) => {
            // state.versionHistory.HolidayRecords = [];
            // state.versionHistory.HolidayVHeader = [];
            state.versionHistory = [];
        },
        FormTitleMasterDataReducer: (state, action: any) => {
            state.currentpageTitleData = action.payload;

        },
        FormTitleWorkflowDataReducer: (state, action: any) => {
            state.currentWorkFlowStepTitle = action.payload;

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFormTitleList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFormTitleList.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action.payload, "action.payload");

                state.FormTitleTable = action.payload.map((user: any) => {
                    user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                    return user;
                });
                state.isLoading = false;
            })
            .addCase(getFormTitleList.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })

            .addCase(AddorEditFormTitle.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(AddorEditFormTitle.fulfilled, (state, action: PayloadAction<any>) => {

                state.isFormTitleAddandEdit = true;
                state.isLoading = false;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(AddorEditFormTitle.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                // state.ToasterMsg = action.payload.response.data;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })

            .addCase(deleteFormTitle.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteFormTitle.fulfilled, (state, action: PayloadAction<any>) => {
                state.isFormTitleDeleted = true;
                state.isLoading = false;
                // state.ToasterMsg = action.payload.Message;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(deleteFormTitle.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                // state.ToasterMsg = action.payload.response.data;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(getFormTitleHistory.pending, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(getFormTitleHistory.fulfilled, (state, action: PayloadAction<any>) => {
                // state.versionHistory.HolidayRecords = action.payload.HolidayList.map((user: any) => {
                //     user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                //     // user.UpdatedOn = chkDate(user.UpdatedOn);

                //     return user;
                // });
                // state.versionHistory.HolidayVHeader = action.payload.HolidayVHeader
                state.versionHistory = action.payload.map((user: any) => {
                    user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                    return user;
                });
                state.isLoading = false;
                // state.ToasterMsg = "Department data deleted successfully";
            })
            .addCase(getFormTitleHistory.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })

    },
});

export const { updateFormTitleAddandEdit, updateUser, updateVersionHistory, FormTitleMasterDataReducer, FormTitleWorkflowDataReducer } = FormTitleMasterDataSlice.actions;
export default FormTitleMasterDataSlice.reducer;
