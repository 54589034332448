import React, { ReactNode } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Box,
  DialogProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModalPopUp from "../modalComponent/ModalPopUp";
import CommonBackDrop from "../CommonBackdrop/CommonBackdrop";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { ApplicationParameterName } from "../../config/config";
import InactivityTimer from "../InactivityTimer/InactivityTimer";

interface CustomDialogProps {
  show: boolean;
  onHide: () => void;
  header: string;
  contentNode?: ReactNode;
  headerSecoundaryNode?: ReactNode;
  actionNode?: ReactNode;
  maxWidth?: any;
  minHeight?: any;
  style?: any;
  isCloseIconDisabled?: boolean;
  hideBackdrop?: boolean;
  hideDivider?: boolean;
  key?: string;
  isEdit_Backdrop?: any;
  isEdit?: boolean;
}

const CustomDialog: React.FC<CustomDialogProps> = (props) => {
  const {
    key = "showPopupValue",
    show,
    onHide,
    header,
    headerSecoundaryNode,
    contentNode,
    actionNode,
    maxWidth = "lg",
    minHeight = "20vh",
    style,
    isCloseIconDisabled,
    hideBackdrop,
    hideDivider,
    isEdit_Backdrop,
    isEdit,
  } = props;
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const [timeout, setTimeout] = React.useState<any>(null);
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const handleBackdropClick = () => {
    if (isEdit_Backdrop) {
      setIsVisible(true);
    }
  };
  React.useEffect(() => {
    if (isEdit) {
      const RecordTimeOut: any = ApplicationParameterName.RecordTimeOut;
      let findRecordTimeOut = applicationParameterData.find(
        (applicationParamitem) =>
          applicationParamitem.Parameter_Name === RecordTimeOut &&
          applicationParamitem.Parameter_Group === RecordTimeOut
      )?.Parameter_Value;
      setTimeout(10000);
    }
  }, []);

  return (
    <React.Fragment>
      <Dialog
        key={`${key}-popupfor`}
        open={show}
        hideBackdrop={hideBackdrop}
        // onClose={onHide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={maxWidth}
        fullWidth
        sx={{ style }}
        scroll={scroll}
        onBackdropClick={handleBackdropClick}
      >
        <Box sx={{display :"flex" , alignItems :'center' , justifyContent : 'space-between', width : '100% !important'}}>

      
        <Box sx={{ display: "flex", alignItems: "center" , width : '90% !important' }}>
          <DialogTitle sx={{width :headerSecoundaryNode ? 'auto': '100% !important'}} id="alert-dialog-title">{header}</DialogTitle>
          <> {headerSecoundaryNode}</>
        </Box>
        <Box>
        {!isCloseIconDisabled && (
          <IconButton
            aria-label="close"
            onClick={onHide}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        </Box>

</Box>

        <DialogContent dividers={!hideDivider} sx={{}}>
          {contentNode}
          <InactivityTimer edit ={isEdit}  />
          {isVisible && <CommonBackDrop isVisible={isVisible} setIsVisible={setIsVisible} />}
        </DialogContent>
        {actionNode ? <DialogActions>{actionNode}</DialogActions> : null}
      </Dialog>
      
    </React.Fragment>
  );
};

export default CustomDialog;
