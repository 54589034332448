import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { PMEvent_URL } from "./endpoint/PMEventLog";

const getAllEventLog = (): Promise<any> => axiosInstance
  .post(PMEvent_URL.getAllEventLog)
  .then((response: AxiosResponse<any>) => {
    if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
      return response.data;
    }
    throw new Error("Error getting Data" + response);
  })
  .catch((error: AxiosError | string) => {
    throw error;
  });

  const CreateEventLogSLA = (requestBody: any): Promise<any> =>
    axiosInstance
      .post(PMEvent_URL.CreateEventLogSLA, requestBody)
      .then((response: AxiosResponse<any>) => {
  
        if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
          return response.data;
        }
        throw new Error("Error getting Data" + response);
      })
      .catch((error: AxiosError | string) => {
        throw error;
      });

    

          const GetEventLogSLAByID = (requestBody: any): Promise<any> =>
            axiosInstance
              .get(PMEvent_URL.GetEventLogSLAByID, {params: requestBody} )
              .then((response: AxiosResponse<any>) => {
          
                if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
                  return response.data;
                }
                throw new Error("Error getting Data" + response);
              })
              .catch((error: AxiosError | string) => {
                throw error;
              });

const PMEventLogService = {
  getAllEventLog,
  CreateEventLogSLA,
  GetEventLogSLAByID
};

export default PMEventLogService;
