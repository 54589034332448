import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { textFieldWidth } from "../../utils/constants";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomButton from "../../common/dynamicButton/CustomButton";
import CustomCheckBox from "../../common/dynamicInputs/CustomCheckBox";
import CustomMultilineTextField from "../../common/dynamicInputs/CustomMultilineTextField";
import CustomFileUploader from "../../common/dynamicFileUploader/CustomFileUploader";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import { upload } from "@testing-library/user-event/dist/upload";
import { fileHandlerUpdate, UploadAFile } from "../../store/slices/fileHandler";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import FilePreviewer from "../../common/fileViewer/FilePreviewer";
import { AddWithdrawConsentForm, updateVendorConsentAddandEdit } from "../../store/slices/consentFormData";
import { ToasterActivityConfig } from "../../config/config";
import { red } from "@mui/material/colors";

interface FormData {
  Id?: any;
  Reason?: string;
  PartyName?: string;
  WithdrawnBy?: string;
  WithdrawnOn?: string;
  Attach?: string;
  Vendor_Id?: string;
  User_Id?: any;
  
}

export interface vendorConsentProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  masterId?: string;
  onClose?: any;
  isDraftData?: any;
  ModuleId?: number | undefined;
}

export const VendorConsentWithdrawalForm: React.FC<vendorConsentProps> = ({
  view,
  isEditData,
  edit,
  masterId,
  onClose,
  ModuleId,
}) => {
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { isFileUpload, uploadData, isFileUploadLoading } = useSelector((state: RootState) => state.fileHandler);
  const { isVendorConsentLoading, isVendorConsentWithdraw, vendorConsentToasterMsg , isVendorConsentWithstatus } = useSelector(
    (state: RootState) => state.vendorConsentData
  );
  const currentDate = new Date().toLocaleDateString();
  const dispatch = useAppDispatch();
  let formData = {
    Id: isEditData ? isEditData?.Id : 0,
    Reason: isEditData ? isEditData?.Reason : "",
    PartyName: isEditData ? isEditData?.Legal_Name : "",
    WithdrawnBy: isEditData ? isEditData?.WithdrawnBy : user?.UserEmail,
    WithdrawnOn: isEditData ? isEditData?.WithdrawnOn : "",
    Attach: isEditData ? isEditData?.Attach : "",
    Vendor_Id: isEditData ? isEditData?.Vendor_Id : "",
  };

  const {
    handleSubmit: handlewithdrawForm,
    reset,
    setValue,
    formState: { errors: errorsWithdrawform },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  useEffect(() => {
    dispatch(updateLoader(isFileUploadLoading));
  }, [isFileUploadLoading]);

  useEffect(() => {
    if (isVendorConsentWithdraw) {
      dispatch(updateVendorConsentAddandEdit(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: vendorConsentToasterMsg,
          isTosterFailed :  !isVendorConsentWithstatus ,
        })
      );
      if(isVendorConsentWithstatus){
        onClose()
      }
    }
  }, [isVendorConsentWithdraw]);

 

  useEffect(() => {
    dispatch(updateLoader(isVendorConsentLoading));
  }, [isVendorConsentLoading]);

  useEffect(() => {
    if (isFileUpload && uploadData) {
      setValue("Attach", uploadData);
      dispatch(fileHandlerUpdate(false));
    }
  }, [isFileUpload]);
  const onRemovefile = () => {
    setValue("Attach", "");
  };
  const uploadFile = (attachmentFile: any) => {
    if (attachmentFile) {
      const blob = attachmentFile.pop();
      let formData = new FormData();
      formData.append("fileUploadRequest", blob);
      dispatch(UploadAFile(formData));
    }
  };

  const handleSubmit = (data: any) => {

    const formData = new FormData();

    // --- file upload convert blob and file upload -------- 
    const blob = new Blob([data.Attach], {
      type: data.Attach[0]?.type,
    });

    formData.append("file", blob );
    formData.append("Consent_Id", "0");
    formData.append("Consent_Status", "0" );
    formData.append("Consent_WithDrawn_By", user?.UserName ?? "" );
    formData.append("Reason_To_WithDraw", data.Reason);
    formData.append("ModuleName", 'Withdraw Consent');
    formData.append("Updated_By", user?.UserName ?? "");
    formData.append("User_Id", `${userLoginResponse?.UserId}` ?? "");
    formData.append("Activity", `${ToasterActivityConfig?.Withdraw}`);
    formData.append("Vendor_Id", data.Vendor_Id);

    // let request: any = {
    //   file: data.Attach,
    //   Consent_Id: 0,
    //   Consent_Status: 0 ,
    //   Consent_WithDrawn_By: user?.UserName ?? "",
    //   Reason_To_WithDraw: data.Reason,
    //   ModuleName: "Withdrawal",
    //   Updated_By: user?.UserName ?? "",
    //   Activity: "Withdraw",
    //   Vendor_Id: data.Vendor_Id,
    // };
    // console.log(request);

    dispatch(AddWithdrawConsentForm(formData));
  };

  return (
    <>
      <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Box sx={{ width: "50%" }}>{`Party Name : ${isEditData?.Legal_Name}`}</Box>
              {/* <Box sx={{width:'50%'}}>Consent On : </Box> */}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Box sx={{ width: "50%" }}> {`Withdrawn By : ${user?.UserEmail}`}</Box>
              <Box sx={{ width: "50%" }}> {`Withdrawn On : ${currentDate}`}</Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="Reason"
              control={control}
              defaultValue={""}
              rules={{
                required: "Reason is Required",
                validate: (data: any) => {
                  if (!data.trim()) {
                    return "Only empty space not allowed, Reason is required";
                  }

                  if (data.length < 4) {
                    return "Minimal 4 characters required";
                  }
                  if (data.length > 100) {
                    return "Maximum 100 characters allowed";
                  }

                  return true;
                },
              }}
              render={({ field }) => (
                <CustomMultilineTextField
                  label={"Reason"}
                  value={field.value}
                  rows={3}
                  required={true}
                  disabled={view}
                  onChange={field.onChange}
                  error={Boolean(errorsWithdrawform.Reason)}
                  helperText={errorsWithdrawform.Reason && errorsWithdrawform.Reason.message?.toString()}
                />
              )}
            />
          </Grid>
          {!watch("Attach") ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{m:2}}>
              <Controller
                name="Attach"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Attachment is Required",
                  
                }}
                render={({ field }) => (
                  <CustomFileUploader
                    onFileUpload={(value) => {
                      console.log(value);
                      // uploadFile(value);
                      field.onChange(value)
                    }}
                    maxFileSize={1048576}
                    acceptFormats={[".png", ".jpeg", ".jpg", ".pdf", ".docx"]}
                  />
                )}
              />
              {errorsWithdrawform.Attach ? (
                <FormHelperText 
                  error={Boolean(errorsWithdrawform.Attach && errorsWithdrawform.Attach.message?.toString())}
                  id="my-helper-text"
                >
                                
                  {errorsWithdrawform.Attach && errorsWithdrawform.Attach.message?.toString()}
                </FormHelperText>
              ) : null}
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FilePreviewer onRemovefile={() => onRemovefile()} file={watch("Attach")} />
            </Grid>
          )}
        </Grid>

        <Typography sx={{ mt:5, ml:2, color:'red' }}>
    Note:  Post withdrawal of consent the Thirdparty status will be changed to Inactive
  </Typography>      

        <Box
  sx={{
    display: "flex",
    gap: "20px",
    justifyContent: "right",
    mt: 6,
  }}
  
>



          {!view ? (
            !edit ? (
              <>
                <CustomButton
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    onClose();
                  }}
                />
                <CustomButton variant="contained" name={"Submit"} onClick={handlewithdrawForm(handleSubmit)} />
              </>
            ) : (
              <>
                <CustomButton
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    onClose();
                  }}
                />
                <CustomButton variant="contained" name={"Submit"} onClick={handlewithdrawForm(handleSubmit)} />
              </>
            )
          ) : (
            <CustomButton
              variant="outlined"
              name={"Cancel"}
              onClick={() => {
                onClose();
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
