import React, { useEffect, useState } from "react";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import CustomStepper from "../../../common/dynamicSteper/CustomStepper";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { INACTIVE, textFieldWidth } from "../../../utils/constants";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { IMenu } from "../../../models/loggin.type";
import AddClause from "./AddClause";
import { getUsersList } from "../../../store/slices/userData";
import { getThirdPartyList } from "../../../store/slices/thirdPartyData";
import {
  clearData,
  createClause,
  getClauseTypeList,
  getSystemVariables,
  updateClause,
  updateClauseDetailsAddandEdit,
} from "../../../store/contractManagement/slices/clauseDataSlice";
import { getDepartmentList } from "../../../store/slices/departmentData";
import { getContractType, getTemplateList } from "../../../store/contractManagement/slices/templateData";
import { getLovList } from "../../../store/slices/lovMasterData";
import { LovConfig } from "../../../config/config";
import SearchServices from "../SearchService/SearchServices";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";

export interface IAddClauseStepperProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  masterId?: string;
  onClose?: any;
  isDraftData?: boolean;
  ModuleId?: number | undefined;
  isDisableBreadCrums?: boolean;
  isDisableActionButton?: boolean;
  onSaveClause?: (savedClause: any) => void;
}

export interface AddClauseForm {
  clauseType_Id: number;
  regulator_Id?: number;
  regulatory_Reference?: string;
  clause_Header: string;
  clause_Contract_Header: string;
  clause: string;
  service_Type_Id?: number;
  service_Group_Id?: number;
  department_Id?: number;
  contract_Template_id?: number;
  isActive?: boolean;
  actionTaken?: string;
  remarks?: string;
  templateMappings?: number[];
  serviceMappings?: number[];
  isNonNegotiableClause?: boolean;
  sla?: boolean;
  pm?: boolean;
  riskId: number;
  isSMEReviewRequired: boolean;
  isRiskApplicable: boolean;
  riskMappings: number[];
  isSubClause: boolean;
  subClauses: any[];
  hasSubClause: boolean;
  contract_Type_Id: number;
  systemVariableMappings: number[];
}

interface serviceMapping {
  clause_ServiceMapping_Id: number;
  serviceCatalog_Id: number;
  isDeleted: boolean;
  isActive: boolean;
}

interface templateMapping {
  clause_CTMapping_Id: number;
  contract_Template_Id: number;
  isDeleted: boolean;
}

interface IserviceMapping {
  serviceMappings?: number[];
}

interface IRiskMapping {
  id: number;
  risk_Category_Id: number;
  isDeleted: boolean;
  isActive: boolean;
}

interface ISystemVaraibleMapping {
  "clause_SysVariables_Id": number;
  "clause_Id": number;
  "system_Variables_Id": number;
  "isActive": boolean;
  "updated_By": string;
  "isDeleted": boolean;
}

const AddClauseStepper: React.FC<IAddClauseStepperProps> = ({
  view,
  edit,
  isEditData,
  isDraftData,
  onClose,
  onSaveClause,
  isDisableBreadCrums= false
}) => {
  const dispatch = useAppDispatchThunk();
  const navigate = useNavigate();
  const location = useLocation();
  const [activestepCount, setactivestepCount] = useState(0);
  const [isUpdateEnabled, setIsUpdateEnabled] = useState(false);
  const [expanded, setExpanded] = useState<string | null>(null);
  const [isDraftEnabled, setIsDraftEnabled] = useState(true);
  const [CompanyTypelov, setCompanyTypelov] = useState<any[]>([]);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const [editorContent, setEditorContent] = useState<any>();
  const [discard, setDiscard] = useState<boolean>(false);
  const { thirdPartyData } = useSelector((state: RootState) => state.thirdPartyData);
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const { templateList } = useSelector((state: any) => state.contractManagementReducer.templateData);
  const {
    clauseTypeList,
    isClauseDetailsAdded,
    clauseToasterStatus,
    isClauseDetailsUpdated,
    clauseToasterMsg,
    clauseData,
    systemVariables
  }: any = contractManagementReducer.clauseData;
  const { isclauseMasterDetailsAdded }: any = contractManagementReducer.clauseMasterData;

  const { currentpageTitleData } = useSelector((state: RootState) => state.FormTitleMasterData);

  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [isActive, setIsActive] = useState<boolean>(true);
  const closeForm = (value: any) => {
    if (onClose && value === 0) {
      onClose();
    }
  };

  const CallonpageLoad = () => {
    dispatch(
      getUsersList({
        FormID: 2,
      })
    );
    dispatch(getThirdPartyList({ FormID: 0 }));
    dispatch(getClauseTypeList());
    dispatch(
      getDepartmentList({
        FormID: 2,
      })
    );
    dispatch(getTemplateList());
    dispatch(getLovList({ Name: "" }));
    dispatch(getContractType());
    if(systemVariables.length === 0){
      dispatch(getSystemVariables());
    }    
  };

  useEffect(() => {
    CallonpageLoad();
  }, []);
  let defaultClauseSteppersteps = ["Clause Details"];
  const [main_steps, setMainSteps] = useState<string[]>(defaultClauseSteppersteps);

  let clauseFormData: AddClauseForm = {
    clauseType_Id: isDraftData ? 0 : isEditData ? isEditData?.clauseType?.clauseType_Id : null,
    regulator_Id: isDraftData ? 0 : isEditData ? isEditData?.regulator?.thirdParty_Id : null,
    regulatory_Reference: isDraftData ? "" : isEditData ? isEditData?.regulatory_Reference : "",
    clause_Header: isDraftData ? "" : isEditData ? isEditData?.clause_Header : "",
    clause_Contract_Header: isDraftData ? "" : isEditData ? isEditData?.clause_Contract_Header : "",
    clause: isDraftData ? 0 : isEditData ? isEditData?.clause : "",
    service_Type_Id: 0,
    service_Group_Id: 0,
    department_Id: isDraftData ? 0 : isEditData ? isEditData?.department?.departmentId : null,
    isActive: isDraftData ? 0 : isEditData ? isEditData?.isActive : true,
    actionTaken: "",
    remarks: "",
    isNonNegotiableClause: isEditData ? isEditData?.isNonNegotiableClause : false,
    sla: isEditData ? isEditData?.sla : false,
    pm: isEditData ? isEditData?.pm : false,
    riskId: isDraftData ? 0 : isEditData ? isEditData?.risk?.lov_Id : 0,
    templateMappings: isDraftData
      ? 0
      : isEditData
        ? isEditData?.contractTemplates?.length > 0
          ? isEditData?.contractTemplates?.map((data: any) => data.contract_Template_Id)
          : []
        : [],
    serviceMappings: isDraftData
      ? 0
      : isEditData
        ? isEditData?.serivceMapping?.length > 0
          ? isEditData?.serivceMapping?.map((data: any) => data?.services?.serviceCatalog_Id)
          : []
        : [],
    isSMEReviewRequired: isEditData ? isEditData?.isSMEReviewRequired : false,
    isRiskApplicable: isEditData ? isEditData?.isRiskApplicable : false,
    riskMappings: isDraftData
      ? 0
      : isEditData
        ? isEditData?.riskMappings?.length > 0
          ? isEditData?.riskMappings?.map((data: any) => data.riskCategory?.lov_Id)
          : []
        : [],
    isSubClause: isEditData ? isEditData?.isSubClause : false,
    hasSubClause: isEditData ? isEditData?.hasSubClause : false,
    subClauses: isDraftData ? 0 : isEditData ? (isEditData?.subClauses?.length > 0 ? isEditData?.subClauses : []) : [],
    contract_Type_Id: isEditData ? isEditData?.contract_Type?.contractType_Id : null,
    systemVariableMappings: isEditData ? (isEditData?.systemVariableMappings?.length > 0 ? isEditData?.systemVariableMappings.map((syemId: any) => syemId.systemVariableId) : []) : [],
  };

  const initialServicdMappingData: IserviceMapping = {
    serviceMappings: isDraftData
      ? 0
      : isEditData
        ? isEditData?.serivceMapping?.length > 0
          ? isEditData?.serivceMapping?.map((data: any) => data?.services?.serviceCatalog_Id)
          : []
        : [],
  };

  const initialClauseDetailsData: any = {
    ...clauseFormData,
    ...initialServicdMappingData,
  };

  let initialServiceMappingFormData = {
    serviceMappings: isDraftData
      ? 0
      : isEditData
        ? isEditData?.serivceMapping?.length > 0
          ? isEditData?.serivceMapping?.map((data: any) => data?.services?.serviceCatalog_Id)
          : []
        : [],
  };

  const serviceMappingFormData = useForm({ defaultValues: initialServiceMappingFormData });
  const {
    handleSubmit: CompanyAddressHandleSubmit,
    getValues: serviceMappingAddress,
    setValue: mapServiceMappingFormData,
    formState: { isDirty: CompanyAddressIsDirty },
    reset: clauseServicemappingDataReset,
  } = serviceMappingFormData;

  const clauseDetailsData = useForm<any>({
    defaultValues: initialClauseDetailsData,
    mode: "onChange",
  });

  const {
    handleSubmit: handleClauseFormSubmit,
    getValues: ClauseDetailsGetValues,
    setValue: clauseDataSetValue,
    formState: { isDirty: CompanyDetailsIsDirty },
    reset: CompanyDetailsReset,
    watch,
  } = clauseDetailsData;

  const clauseInfoFields = Object.keys(clauseFormData);
  const serviceMapping = Object.keys(initialServicdMappingData);

  const nextStep = () => {
    setactivestepCount((prevCount) => prevCount + 1);
  };
  const handleNext = async () => {
    if (view || !initialClauseDetailsData?.isActive) {
      setactivestepCount((prevCount) => prevCount + 1);
    } else {
      const isValid = await clauseDetailsData.trigger();
      const clauseInfoFieldsErrors = clauseInfoFields.filter((field) => clauseDetailsData.getFieldState(field).invalid);
      if (clauseInfoFieldsErrors.length > 0) {
        setExpanded("All");
      } else {
        setactivestepCount((prevCount) => prevCount + 1);
      }
    }
  };

  // const handleNext = async () => {
  //   if (activestepCount === 0) {
  //     setExpanded(null);
  //     setactivestepCount((prevCount) => prevCount + 1);
  //   }
  // };

  const handlePrevious = () => {
    setactivestepCount((prevCount) => prevCount - 1);
  };


  const finalizeUpdateSystemVaraibles = (editDataSystemVariableMappings: any[], localSystemVaraibleMapping: any[], clauseId: any, isDelete: boolean = false) => {
    let systemVariableMappings: ISystemVaraibleMapping[] = [];
    let updateSystemVaraibles = [...localSystemVaraibleMapping];
    // updating System Variabel Mapping
    if (isDelete) {
      for (let i = 0; i < editDataSystemVariableMappings?.length; i++) {
        let systemVaraibelId = editDataSystemVariableMappings[i]?.systemVariableId;
        let system_Mapping_Id = editDataSystemVariableMappings[i]?.systemVariableMappingId;
        let systemVaraibleMapping: ISystemVaraibleMapping = {
          "clause_SysVariables_Id": system_Mapping_Id,
          "clause_Id": clauseId,
          "system_Variables_Id": systemVaraibelId,
          "isActive": true,
          "updated_By": userLoginResponse && userLoginResponse?.UserName ? userLoginResponse?.UserName : "",
          "isDeleted": true
        };
        systemVaraibleMapping.isDeleted = true;
        systemVariableMappings.push(systemVaraibleMapping);
      }
    } else {

      for (let i = 0; i < editDataSystemVariableMappings?.length; i++) {
        let systemVaraibelId = editDataSystemVariableMappings[i]?.systemVariableId;
        let system_Mapping_Id = editDataSystemVariableMappings[i]?.systemVariableMappingId;
        let systemVaraibleMapping: ISystemVaraibleMapping = {
          "clause_SysVariables_Id": system_Mapping_Id,
          "clause_Id": clauseId,
          "system_Variables_Id": systemVaraibelId,
          "isActive": true,
          "updated_By": userLoginResponse && userLoginResponse?.UserName ? userLoginResponse?.UserName : "",
          "isDeleted": false
        };
        if (
          updateSystemVaraibles &&
          updateSystemVaraibles.length > 0 &&
          updateSystemVaraibles.findIndex((data: any) => 
            {
              if(typeof(data) === "object"){
                return data.systemVariableId === systemVaraibelId
              }else{
                return data === systemVaraibelId
              }
            }) > -1
        ) {
          systemVariableMappings.push(systemVaraibleMapping);
        } else {
          systemVaraibleMapping.isDeleted = true;
          systemVariableMappings.push(systemVaraibleMapping);
        }
      }

      for (let i = 0; i < updateSystemVaraibles?.length; i++) {
        let newSystemVarId = typeof(updateSystemVaraibles[i]) === "object" ? updateSystemVaraibles[i].systemVariableId : updateSystemVaraibles[i];
        let systemVaraibleMapping: ISystemVaraibleMapping = {
          "clause_SysVariables_Id": 0,
          "clause_Id": isEditData?.clause_Id,
          "system_Variables_Id": newSystemVarId,
          "isActive": true,
          "updated_By": userLoginResponse && userLoginResponse?.UserName ? userLoginResponse?.UserName : "",
          "isDeleted": false
        };
        if (editDataSystemVariableMappings?.findIndex((data: any) => data?.systemVariableId === newSystemVarId) === -1) {
          systemVariableMappings.push(systemVaraibleMapping);
        }
      }
    }
    return systemVariableMappings;
  }



  const handleSaveAsDraft = () => {
    dispatch(updateLoader(true));
    if (edit) {
      let formValue: any = ClauseDetailsGetValues();
      formValue["clause_Id"] = isEditData?.clause_Id;
      formValue.regulator_Id = formValue.regulator_Id === null ? 0 : formValue.regulator_Id;

      // updating Service mapping
      let serviceMappings: serviceMapping[] = [];

      for (let i = 0; i < isEditData?.serivceMapping?.length; i++) {
        let servicemappingId = isEditData?.serivceMapping[i].clause_ServiceMapping_Id;
        let serviceCatalogId = isEditData?.serivceMapping[i]?.services?.serviceCatalog_Id;
        let serviceMapping = {
          clause_ServiceMapping_Id: servicemappingId,
          serviceCatalog_Id: serviceCatalogId,
          isDeleted: false,
          isActive: true,
        };
        if (selectedServices.findIndex((data: any) => data === serviceCatalogId) > -1) {
          serviceMappings.push(serviceMapping);
        } else {
          serviceMapping.isDeleted = true;
          serviceMappings.push(serviceMapping);
        }
      }

      for (let i = 0; i < selectedServices?.length; i++) {
        let newSelectedClauseId = selectedServices[i];
        let serviceMapping = {
          clause_ServiceMapping_Id: 0,
          serviceCatalog_Id: selectedServices[i],
          isDeleted: false,
          isActive: true,
        };
        if (
          isEditData?.serivceMapping.findIndex(
            (data: any) => data?.services?.serviceCatalog_Id === newSelectedClauseId
          ) === -1
        ) {
          serviceMappings.push(serviceMapping);
        }
      }
      formValue.serviceMappings = serviceMappings;

      // updating template mapping
      let contractTemplatesMappings: templateMapping[] = [];
      let selectedTemplates = ClauseDetailsGetValues("templateMappings") || [];
      for (let i = 0; i < isEditData?.contractTemplates?.length; i++) {
        let templateId = isEditData?.contractTemplates[i].contract_Template_Id;
        let clauseMappingId = isEditData?.contractTemplates[i].clause_CTMapping_Id;
        let templateMapping = {
          clause_CTMapping_Id: clauseMappingId,
          contract_Template_Id: templateId,
          isDeleted: false,
        };
        if (
          selectedTemplates &&
          selectedTemplates.length > 0 &&
          selectedTemplates.findIndex((data: any) => data === templateId) > -1
        ) {
          contractTemplatesMappings.push(templateMapping);
        } else {
          templateMapping.isDeleted = true;
          contractTemplatesMappings.push(templateMapping);
        }
      }

      for (let i = 0; i < selectedTemplates?.length; i++) {
        let newSelectedClauseId = selectedTemplates[i];
        let templatMapping = {
          clause_CTMapping_Id: 0,
          contract_Template_Id: selectedTemplates[i],
          isDeleted: false,
        };
        if (
          isEditData?.contractTemplates?.findIndex((data: any) => data.contract_Template_Id === newSelectedClauseId) ===
          -1
        ) {
          contractTemplatesMappings.push(templatMapping);
        }
      }
      formValue.templateMappings = contractTemplatesMappings;

      // updating Risk Mapping
      let riskTemplatesMappings: IRiskMapping[] = [];

      let selectedRiskMapping = ClauseDetailsGetValues("riskMappings") || [];
      for (let i = 0; i < isEditData?.riskMappings?.length; i++) {
        let id = isEditData?.riskMappings[i]?.id;
        let risk_Category_Id = isEditData?.riskMappings[i]?.riskCategory?.lov_Id;
        let riskMapping = {
          id: id,
          risk_Category_Id: risk_Category_Id,
          isDeleted: false,
          isActive: true,
        };
        if (
          selectedRiskMapping &&
          selectedRiskMapping.length > 0 &&
          selectedRiskMapping.findIndex((data: any) => data === risk_Category_Id) > -1
        ) {
          riskTemplatesMappings.push(riskMapping);
        } else {
          riskMapping.isDeleted = true;
          riskTemplatesMappings.push(riskMapping);
        }
      }

      for (let i = 0; i < selectedRiskMapping?.length; i++) {
        let newRiskId = selectedRiskMapping[i];
        let templatRiskMapping = {
          id: 0,
          risk_Category_Id: newRiskId,
          isDeleted: false,
          isActive: true,
        };
        if (isEditData?.riskMappings?.findIndex((data: any) => data?.riskCategory?.lov_Id === newRiskId) === -1) {
          riskTemplatesMappings.push(templatRiskMapping);
        }
      }
      formValue.riskMappings = riskTemplatesMappings;


      //update SystemVariables
      let localSystemVaraibe = ClauseDetailsGetValues("systemVariableMappings") || [];
      formValue.systemVariableMappings = finalizeUpdateSystemVaraibles(isEditData?.systemVariableMappings, localSystemVaraibe, isEditData.clause_Id);


      let subClausestemp = ClauseDetailsGetValues("subClauses") || [];
      if (subClausestemp.length > 0) {
        formValue.subClauses = subClausesUpdate(subClausestemp, isEditData?.subClauses);
      }

      let { subClauses, ...restData } = formValue;

      let updateFormData: any = {
        clauseMaster: restData,
        subClauses: subClauses,
      };
      if (
        updateFormData?.clauseMaster?.contract_Type_Id === null ||
        updateFormData?.clauseMaster?.contract_Type_Id === undefined
      ) {
        updateFormData.clauseMaster.contract_Type_Id = 0;
      }
      updateFormData.approved_By = userLoginResponse?.UserName;
      updateFormData.updated_By = userLoginResponse?.UserName;
      updateFormData.userId = userLoginResponse?.UserId;
      updateFormData.moduleName = "Clause Library";
      updateFormData.activity = "Edit";
      updateFormData.createdBy = userLoginResponse?.UserName;
      dispatch(updateClause(updateFormData));
    } else {
      let { subClauses, ...payload }: any = ClauseDetailsGetValues();
      subClauses = subClauses.map((subClausedata: any) => {
        subClausedata.regulator_Id = 0;
        subClausedata.clauseType_Id = 0;
        subClausedata.riskId = subClausedata.isRiskApplicable == true ? subClausedata.riskId : 0;
        subClausedata.department_Id = subClausedata.isSMEReviewRequired === true ? subClausedata.department_Id : 0;
        return subClausedata;
      });
      payload.regulator_Id = payload.regulator_Id === null ? 0 : payload.regulator_Id;
      payload.riskId = payload.isRiskApplicable == true ? payload.riskId : 0;
      payload.department_Id = payload.isSMEReviewRequired === true ? payload.department_Id : 0;
      payload.subClauses = payload.hasSubClause === true ? payload.subClauses : [];
      payload.approved_By = userLoginResponse?.UserName;
      payload.updated_By = userLoginResponse?.UserName;
      if (payload.contract_Type_Id === null) {
        payload.contract_Type_Id = 0;
      }

      //payload.templateMappings = [17,52];
      let formValue: any = {
        clauseMaster: payload,
        subClauses: subClauses,
      };

      formValue.userId = userLoginResponse?.UserId;
      formValue.moduleName = "Clause Library";
      formValue.activity = "create";
      formValue.createdBy = userLoginResponse?.UserName;
      //formValue
      dispatch(createClause(formValue));
    }
  };

  const subClausesUpdate = (localSubClauses: any, ediotrSubClsuses: any) => {
    let subClauseMappings: any[] = [];

    let updatedSubClauseMappings = localSubClauses.length > 0 ? localSubClauses : [];
    for (let i = 0; i < ediotrSubClsuses.length; i++) {
      let id = ediotrSubClsuses[i]?.clause_Id;

      let updateSubCluseMapping = {
        ...ediotrSubClsuses[i],
        isDeleted: false,
      };
      if (
        updatedSubClauseMappings &&
        updatedSubClauseMappings.length > 0 &&
        updatedSubClauseMappings.findIndex((data: any) => data?.clause_Id === id) > -1
      ) {
        let localSubClause = updatedSubClauseMappings[updatedSubClauseMappings.findIndex((data: any) => data?.clause_Id === id)]
        updateSubCluseMapping = { ...localSubClause, isDeleted: false };
        updateSubCluseMapping.riskMappings = localupdateriskMapping(
          updateSubCluseMapping.riskMappings,
          ediotrSubClsuses[i].riskMappings,
          false
        );

        //update SystemVaraibles mapping
        updateSubCluseMapping.systemVariableMappings = finalizeUpdateSystemVaraibles(ediotrSubClsuses[i]?.systemVariableMappings, updateSubCluseMapping.systemVariableMappings, ediotrSubClsuses[i].clause_Id);
        updateSubCluseMapping.department_Id = updateSubCluseMapping.isSMEReviewRequired
          ? typeof updateSubCluseMapping.department === "object"
            ? updateSubCluseMapping.department.departmentId
            : updateSubCluseMapping.department_Id
          : 0;
        if (updateSubCluseMapping.department) {
          delete updateSubCluseMapping.department;
        }

        updateSubCluseMapping.riskId = updateSubCluseMapping.isRiskApplicable
          ? typeof updateSubCluseMapping.risk === "object"
            ? updateSubCluseMapping.risk.lov_Id
            : updateSubCluseMapping.riskId
          : 0;
        if (updateSubCluseMapping.risk) {
          delete updateSubCluseMapping.risk;
        }
        updateSubCluseMapping.isSubClause = true;
        subClauseMappings.push(updateSubCluseMapping);
      } else {
        updateSubCluseMapping.isDeleted = true;
        updateSubCluseMapping.department_Id = updateSubCluseMapping.isSMEReviewRequired
          ? typeof updateSubCluseMapping.department === "object"
            ? updateSubCluseMapping.departmentId
            : updateSubCluseMapping.department_Id
          : 0;
        if (updateSubCluseMapping.department) {
          delete updateSubCluseMapping.department;
        }

        updateSubCluseMapping.riskId = updateSubCluseMapping.isRiskApplicable
          ? typeof updateSubCluseMapping.risk === "object"
            ? updateSubCluseMapping.risk.lov_Id
            : updateSubCluseMapping.riskId
          : 0;
        if (updateSubCluseMapping.risk) {
          delete updateSubCluseMapping.risk;
        }

        //update SystemVaraibles mapping
        updateSubCluseMapping.systemVariableMappings = finalizeUpdateSystemVaraibles(ediotrSubClsuses[i]?.systemVariableMappings, updateSubCluseMapping.systemVariableMappings, ediotrSubClsuses[i]?.clause_Id, true);
        updateSubCluseMapping.riskMappings = localupdateriskMapping(
          updateSubCluseMapping.riskMappings,
          ediotrSubClsuses[i].riskMappings,
          true
        );
        updateSubCluseMapping.isSubClause = true;
        subClauseMappings.push(updateSubCluseMapping);
      }
    }

    for (let i = 0; i < updatedSubClauseMappings?.length; i++) {
      let newClauseID = updatedSubClauseMappings[i].clause_Id;
      let updateSubCluseMapping = {
        ...updatedSubClauseMappings[i],
        isDeleted: false,
      };
      if (ediotrSubClsuses?.findIndex((data: any) => data?.clause_Id === newClauseID) === -1) {
        updateSubCluseMapping.riskMappings = localupdateriskMapping(updateSubCluseMapping.riskMappings, [], false);
        updateSubCluseMapping.riskId = updateSubCluseMapping.isRiskApplicable ? updateSubCluseMapping.riskId : 0;
        updateSubCluseMapping.department_Id = updateSubCluseMapping.isSMEReviewRequired
          ? updateSubCluseMapping.department_Id
          : 0;
        //update SystemVaraibles mapping
        updateSubCluseMapping.systemVariableMappings = finalizeUpdateSystemVaraibles([], updateSubCluseMapping.systemVariableMappings, newClauseID);
        updateSubCluseMapping.isSubClause = true;
        subClauseMappings.push(updateSubCluseMapping);
      }
    }
    return subClauseMappings;
  };

  const localupdateriskMapping = (localRiskMapping: any, editorriskMapping: any, isDelete: boolean) => {
    let riskTemplatesMappings: any[] = [];

    let selectedRiskMapping = [...localRiskMapping];
    if (isDelete) {
      for (let i = 0; i < editorriskMapping.length; i++) {
        let id = editorriskMapping[i]?.id;
        let risk_Category_Id = editorriskMapping[i]?.riskCategory?.lov_Id;
        let riskMapping = {
          id: id,
          risk_Category_Id: risk_Category_Id,
          isDeleted: true,
          isActive: true,
        };
        riskTemplatesMappings.push(riskMapping);
      }
      return riskTemplatesMappings;
    } else if (!isDelete && editorriskMapping && editorriskMapping.length === 0) {
      for (let i = 0; i < localRiskMapping.length; i++) {
        let id = 0;
        let risk_Category_Id = localRiskMapping[i];
        let riskMapping = {
          id: 0,
          risk_Category_Id: risk_Category_Id,
          isDeleted: false,
          isActive: true,
        };
        riskTemplatesMappings.push(riskMapping);
      }
      return riskTemplatesMappings;
    } else {
      for (let i = 0; i < editorriskMapping.length; i++) {
        let id = editorriskMapping[i]?.id;
        let risk_Category_Id = editorriskMapping[i]?.riskCategory?.lov_Id;
        let riskMapping = {
          id: id,
          risk_Category_Id: risk_Category_Id,
          isDeleted: false,
          isActive: true,
        };
        if (
          selectedRiskMapping &&
          selectedRiskMapping.length > 0 &&
          selectedRiskMapping.findIndex(
            (data: any) => data === risk_Category_Id || data?.riskCategory?.lov_Id === risk_Category_Id
          ) > -1
        ) {
          riskTemplatesMappings.push(riskMapping);
        } else {
          riskMapping.isDeleted = true;
          riskTemplatesMappings.push(riskMapping);
        }
      }

      for (let i = 0; i < selectedRiskMapping?.length; i++) {
        let newRiskId = selectedRiskMapping[i]?.riskCategory?.lov_Id
          ? selectedRiskMapping[i]?.riskCategory?.lov_Id
          : selectedRiskMapping[i];
        let templatRiskMapping = {
          id: 0,
          risk_Category_Id: newRiskId,
          isDeleted: false,
          isActive: true,
        };
        if (editorriskMapping?.findIndex((data: any) => data?.riskCategory?.lov_Id === newRiskId) === -1) {
          riskTemplatesMappings.push(templatRiskMapping);
        }
      }
      return riskTemplatesMappings;
    }
  };

  const updateServices = (updatedservices: any[]) => {
    setSelectedServices(updatedservices);
    // if(edit){
    //   for(let i = 0; i<)
    // }
    mapServiceMappingFormData("serviceMappings", updatedservices);
    clauseDataSetValue("serviceMappings", updatedservices);
  };

  useEffect(() => {
    if ((view || edit) && clauseTypeList.length > 0) {
      let regulatorValue = clauseTypeList.filter(
        (item: any) => item.clauseType_Id === isEditData?.clauseType?.clauseType_Id
      );
      if (isEditData?.serivceMapping && isEditData?.serivceMapping?.length > 0) {
        let values = isEditData?.serivceMapping?.map((data: any) => data?.services?.serviceCatalog_Id);
        setSelectedServices(values);
      }

      if (isEditData?.serviceMappings && isEditData?.serviceMappings?.length > 0) {
        let values = isEditData?.serviceMappings?.map((data: any) => data?.services?.serviceCatalog_Id);
        setSelectedServices(values);
      }
      dispatch(updateLoader(false));
      mapSteps();
    }
  }, [edit, view, clauseTypeList]);

  useEffect(() => {
    if (isClauseDetailsAdded) {
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: clauseToasterMsg,
          isTosterFailed: clauseToasterStatus,
        })
      );
      dispatch(updateClauseDetailsAddandEdit(false));
      dispatch(updateLoader(false));
      if (onClose && !clauseToasterStatus) {
        onClose();
      } else if (!onClose && !clauseToasterStatus) {
        if (location.state && location.state.targetPath) {
          navigate(location.state.targetPath, {
            state: { ...location.state, clauseData },
          });
        } else navigate(-1);
      }

      if (onSaveClause) {
        onSaveClause(clauseData);
        dispatch(clearData(""));
      }
    } else if (isClauseDetailsUpdated) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: clauseToasterMsg, isTosterFailed: clauseToasterStatus }));
      dispatch(updateClauseDetailsAddandEdit(false));
      if (onClose && !clauseToasterStatus) {
        onClose();
      }
      //setisShowDraft(1)
      dispatch(updateLoader(false));
    }
  }, [isClauseDetailsAdded, isClauseDetailsUpdated]);

  const mapSteps = () => {
    let clauseDetailsValues = ClauseDetailsGetValues();
    if (clauseDetailsValues?.clauseType_Id) {
      let clauseType = clauseTypeList.filter((data: any) => data.clauseType_Id === clauseDetailsValues?.clauseType_Id);
      if (clauseType && clauseType.length > 0 && clauseType[0]?.isServiceMappingRequired && main_steps.length == 1) {
        let steps = [...main_steps];
        steps.push("Service Mapping");
        setMainSteps(steps);
      } else if (
        clauseType &&
        clauseType.length > 0 &&
        clauseType[0]?.isServiceMappingRequired &&
        main_steps.length == 2
      ) {
        setMainSteps(main_steps);
      } else {
        setMainSteps(defaultClauseSteppersteps);
      }
    }
  };
  useEffect(() => {
    mapSteps();
  }, [watch("clauseType_Id")]);

  const breadcrumb = [
    { value: 0, name: "Clause Library" },
    { value: 1, name: edit ? "Edit" : view ? "View" : "Add New" },
  ];

  return (
    <Box>
      {!isDisableBreadCrums?<Box pt={2}>
        <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
          <CustomBreadcrumbs list={breadcrumb} onchange={closeForm} />
        </Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="h6" color={"text.primary"} fontWeight={700}>
            {view
              ? `View ${currentpageTitleData ? currentpageTitleData.ActionTitle : "Clause"}`
              : edit
                ? `Update ${currentpageTitleData ? currentpageTitleData.ActionTitle : "Clause"}`
                : `Add ${location && location.state && location.state.targetPath ? "Clause Library" : currentpageTitleData ? currentpageTitleData.ActionTitle : "Clause"}`}
          </Typography>
        </Stack>
        <Divider sx={{ my: 4, position: "relative", right: "2rem", width: "104%" }} variant="fullWidth" />
      </Box> : null}
      <Box
        sx={{
          maxWidth: 300,
          py: 3,
        }}
      >
        <CustomStepper activeStep={activestepCount} steps={main_steps} />
      </Box>

      <Box sx={{ position: "relative" }}>
        {activestepCount === 0 && (
          <FormProvider {...clauseDetailsData}>
            <Box>
              <Stack spacing={2} direction={"column"}>
                <AddClause
                  view={view || !initialClauseDetailsData?.isActive}
                  active={initialClauseDetailsData?.isActive} 
                  edit={edit}                 
                />
              </Stack>
            </Box>
          </FormProvider>
        )}
        {activestepCount === 1 && (
          <FormProvider {...serviceMappingFormData}>
            <SearchServices
              updateSelectedServices={updateServices}
              selectedServices={selectedServices}
              reset={discard}
              updateDiscard={() => {
                setDiscard(false);
              }}
              view={view || !initialClauseDetailsData?.isActive}
            />
          </FormProvider>
        )}
      </Box>

      <Grid sx={{ display: "flex", gap: "20px", justifyContent: "end" }}>
        {activestepCount === 1 && (
          <CustomButton variant="outlined" name="Previous" onClick={handlePrevious} size="small" key="Previous_ClauseLibarary"/>
        )}
        {/* {(!isDraftDisable && !edit) || isEditData?.DraftId ? (
                <CustomButton
                  variant="contained"
                  name="Save as Draft"
                  onClick={() => {
                    handleSaveAsDraft();
                  }}
                  size="small"
                  disabled={!isDraftEnabled}
                />
              ) : null} */}
        { location && location.state && location.state.targetPath && activestepCount !== 1 &&(
          <CustomButton
            variant="contained"
            disabled={!initialClauseDetailsData?.isActive || view}
            name={"Previous"}
            key="Previous_Contract_Template"
            onClick={
              ()=>{
                navigate(location.state.targetPath, {
                  state: { ...location.state},
                });
              }
            }
            size="small"
          />
        )}      
        {main_steps.length > 1 && activestepCount === 0 && (
          <CustomButton variant="contained" name="Proceed To Next" onClick={handleNext} size="small" />
        )}
        
        {(main_steps.length === 1 || activestepCount === 1) && (
          <CustomButton
            variant="contained"
            disabled={!initialClauseDetailsData?.isActive || view}
            name={edit && isEditData?.draft_data ? "Submit" : edit ? "Update" : "Submit"}
            onClick={handleClauseFormSubmit(handleSaveAsDraft)}
            size="small"
          />
        )}

        
      </Grid>
    </Box>
  );
};

export default AddClauseStepper;
