import { AQMConfig, PM_MeasuredIn, SLASeverity } from "../../../../../config/config";


export const setSeverityColorValue = (data: any[]) => {
  
    const FormattedData = data || [];
  
    return FormattedData.map((item) => ({
      ...item,
      color: 
        item?.Rating == AQMConfig?.High
          ? "#F01717"
          : item?.Rating == AQMConfig?.Low
          ? "#FFC536"
          : item?.Rating == AQMConfig?.Medium ?  "#FDB913" : "", // Default color for other ratings
    }));
  };

  export const setSLASeverityColorValue = (data: any[]) => {
  
    const FormattedData = data || [];
  
    return FormattedData.map((item) => ({
      ...item,
      color: 
        item?.severity == SLASeverity?.High
          ? "#F01717"
          : item?.severity == SLASeverity?.Low
          ? "#FFC536"
          : item?.severity == SLASeverity?.Medium ?  "#FDB913" : "", // Default color for other ratings
    }));
  };

  export const findMeasuredInDate = (id : any , list : any[]) => {
    let value :any  = list?.find((item  :any) => item?.LovId == id)?.Options ;
        if(value == PM_MeasuredIn.Date){
          return true
        }else{
            return false    
        }
  }
  