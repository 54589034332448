import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { PMRandR } from "./endpoint/PMRandR";
import { IRandR } from "../../../models/performanceManagementModels/pmRAndR.type";
const getAllRandRReview = (): Promise<IRandR> => axiosInstance
  .post(PMRandR.GetAllRRReview)
  .then((response: AxiosResponse<IRandR>) => {
    if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
      return response.data;
    }
    throw new Error("Error getting Data" + response);
  })
  .catch((error: AxiosError | string) => {
    throw error;
  });

  const GetRRReviewByEventId = (requestBody: any): Promise<IRandR> => axiosInstance
  .get(PMRandR.GetRRReviewByEventId , {params : requestBody })
  .then((response: AxiosResponse<IRandR>) => {
    if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
      return response.data;
    }
    throw new Error("Error getting Data" + response);
  })
  .catch((error: AxiosError | string) => {
    throw error;
  });
  
const saveRandRReview = (requestBody: any): Promise<IRandR> =>
  axiosInstance
    .post(PMRandR.CreateRRReview, requestBody)
    .then((response: AxiosResponse<IRandR>) => {

      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const PmRRreviewService = {
    getAllRandRReview, saveRandRReview, GetRRReviewByEventId
};

export default PmRRreviewService;
