import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useState , createContext, useEffect } from "react";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import CustomButton from "../../../../../common/dynamicButton/CustomButton";
import CustomMultilineTextField from "../../../../../common/dynamicInputs/CustomMultilineTextField";
import CustomNumberField from "../../../../../common/dynamicInputs/CustomNumberField";
import { ACTIVE, getDateOrConvertSameTime, textFieldWidth } from "../../../../../utils/constants";
import CustomSelect from "../../../../../common/dynamicInputs/CustomSelect";
import CustomBreadcrumbs from "../../../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { ScopeDetails } from "./scopeDetails/ScopeDetails";
import { MonitoringDetails } from "./monitoringPeriod/MonitoringPeriod";
import dayjs from "dayjs";
import { SLAtoMonitor } from "./slatomonitor/SLAtoMonitor";
import CustomStepper from "../../../../../common/dynamicSteper/CustomStepper";
import { useSelector } from "react-redux";
import { ApplicationParameterName, LovConfig, MetrixMasterConfig, PM_frequency } from "../../../../../config/config";
import { RootState } from "../../../../../store/store";

interface AddTargetKPIprops {
  view?: boolean;
  closeAddtarget: (val: any) => void;
  edit?: boolean;
  isEditData?: any;
  add?: boolean;
  handlesubmit: (val: any) => void;
}

interface severityOfTarget {
  label: string;
  risk_Id: any;
  value: any;
  metrics_Id: any;
}

interface ScopeDetailsFormData {
  pR_Clause_Target_Id: any;
  scope_Of_Target: any;
  category: any;
  category_Name: any;
  deliverables: any;
  obligation: any;
  obligation_Name: any;
  exclusion: any;
  sme_Involved: any;
  input_Dependency: any;
  evidence_Required: boolean;
  project_Cost: any;
  currency: any;
  penality_For_Deviation: any;
  stakeholder_acceptance_required: any;
  penality_deviation_remark: any;
  severityof_Target?: severityOfTarget[];
}

interface MonitoringPeriodFormDataprops {
  start_Date: any;
  end_Date: any;
  review_Type: any;
  review_Trigger: any;
  review_Trigger_Name: any;
  frequency: any;
  frequency_Name: any;
  review_Start_Date: any;
  review_End_Date: any;
  reminder_Before: any;
  escalation_Before: any;
  reviewDates: any;
}

interface slaMonitorContextProps {
  slaMonitorList : any
  setslaMonitorList : (value: any) => void;
  slanodatawarning : any
}

export const slaMonitorContext = createContext<slaMonitorContextProps | undefined>(undefined);

export const AddTargetKPI: React.FC<AddTargetKPIprops> = ({ view, closeAddtarget, edit, isEditData, handlesubmit , add }) => {
   const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const { RiskRateDataActive } = useSelector((state: RootState) => state.RiskRateData);
    const { metricsMasterData } = useSelector((state: RootState) => state.metricsMasters);
        const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
      const { CurrencyData } = useSelector((state: RootState) => state.CurrencyData);
  const [step, setstep] = useState<any>(0);
  const [slaMonitorList, setslaMonitorList] = useState<any>([]);
  const [slanodatawarning, setslanodatawarning] = useState<any>(false);
 const [severity_of_slaList, setseverity_of_slaList] = useState<any>([]);

  let ScopeDetailsFormData = {
    
    pR_Clause_Target_Scope_Id: isEditData ? isEditData?.pmprctScope?.pR_Clause_Target_Scope_Id : 0,
    scope_Of_Target: isEditData ? isEditData?.pmprctScope?.scope_Of_Target : "",
    category: isEditData ? isEditData?.pmprctScope?.category : "",
    category_Name: isEditData ? isEditData?.pmprctScope?.category_Name : "",
    deliverables: isEditData ? isEditData?.pmprctScope?.deliverables : "",
    obligation: isEditData ? isEditData?.pmprctScope?.obligation : "",
    obligation_Name: isEditData ? isEditData?.pmprctScope?.obligation_Name : "",
    exclusion: isEditData ? isEditData?.pmprctScope?.exclusion : "",
    sme_Involved: isEditData ? JSON.parse(isEditData?.pmprctScope?.sme_Involved) : "",
    input_Dependency: isEditData ? isEditData?.pmprctScope?.input_Dependency : "",
    evidence_Required: isEditData ? isEditData?.pmprctScope?.evidence_Required : false,
    project_Cost: isEditData ? isEditData?.pmprctScope?.project_Cost : "",
    currency: isEditData ? isEditData?.pmprctScope?.currency : "",
    penality_For_Deviation: isEditData ? isEditData?.pmprctScope?.penality_For_Deviation : false,
    stakeholder_acceptance_required: isEditData ? isEditData?.pmprctScope?.stakeholder_acceptance_required : false,
    penality_deviation_remark: isEditData ? isEditData?.pmprctScope?.penality_deviation : "",
    severityof_Target: isEditData ? JSON.parse(isEditData?.pmprctScope?.severityof_Target) : [],
  };
  const scopeDetailsFormMethods = useForm<ScopeDetailsFormData>({
    defaultValues: ScopeDetailsFormData,
    mode: "onChange",
  });

  let MonitoringPeriodFormData = {
    pR_Clause_Target_Monitor_Id: isEditData ? isEditData?.pmprctMonitorPeriods?.pR_Clause_Target_Monitor_Id : 0,
    start_Date: isEditData ? isEditData?.pmprctMonitorPeriods?.start_Date : null,
    end_Date: isEditData ? isEditData?.pmprctMonitorPeriods?.end_Date : null,
    review_Type: isEditData ? isEditData?.pmprctMonitorPeriods?.review_Type : 0,
    frequency: isEditData ? isEditData?.pmprctMonitorPeriods?.frequency : "",
    frequency_Name: isEditData ? isEditData?.pmprctMonitorPeriods?.frequency_Name : "",
    review_Trigger: isEditData ? isEditData?.pmprctMonitorPeriods?.review_Trigger : "",
    review_Trigger_Name: isEditData ? isEditData?.pmprctMonitorPeriods?.review_Trigger_Name : "",
    review_Start_Date: isEditData ? isEditData?.pmprctMonitorPeriods?.review_Start_Date : null ,
    review_End_Date: isEditData ? isEditData?.pmprctMonitorPeriods?.review_End_Date : null,
    reminder_Before: isEditData ? isEditData?.pmprctMonitorPeriods?.reminder_Before : "",
    escalation_Before: isEditData ? isEditData?.pmprctMonitorPeriods?.escalation_Before : "",
    reviewDates : isEditData ? isEditData?.pmprctMonitorPeriods?.reviewDates : [] ,
  };
  
  useEffect(() => {
    if(isEditData && isEditData?.pmprctsla){
      setslaMonitorList(isEditData?.pmprctsla)
    }

  },[isEditData])
     useEffect(() => {
       if (metricsMasterData) {
         let findedRiskType = metricsMasterData.filter(
           (risk_item :any) => risk_item?.Metrics === MetrixMasterConfig.AQ_Response_Type && risk_item?.IsActive === "Active"
         );
   
         setseverity_of_slaList(findedRiskType || []);
       }
     }, [metricsMasterData]);
  const MonitoringPeriodFormMethods = useForm<MonitoringPeriodFormDataprops>({
    defaultValues: MonitoringPeriodFormData,
    mode: "onChange",
  });

  const {
    handleSubmit: scopeDetailsHandleSubmit,
    formState: { errors: errorsscopeDetailsform, isDirty: scopeDetailsIsDirty },
    getValues: scopeDetailsGetValues,
    setValue: scopeDetailsSetValue,
    trigger : scopeDetailstrigger ,
    reset: scopeDetailsReset,
    control 
  } = scopeDetailsFormMethods;



  const {
    handleSubmit: MonitoringPeriodHandleSubmit,
    formState: { errors: errorsMonitoringPeriodform, isDirty: MonitoringPeriodIsDirty },
    getValues: MonitoringPeriodGetValues,
    setValue: MonitoringPeriodSetValue,
    watch : watchMonitoringPeriod ,
    reset: MonitoringPeriodReset,
  } = MonitoringPeriodFormMethods;

  const SubmitScopeForm = (val: any) => {
    setstep(1);
  };

  const SubmitMonitoringForm = (val: any) => {
    setstep(2);
  };


  useEffect(() => {
        if (RiskRateDataActive) {
          if(add && RiskRateDataActive){
            let riskList: any[] = RiskRateDataActive?.map((item: any , index :number) => {
              return {
                label: item?.RiskCategory,
                risk_Id: item?.RiskCategory_Id,
                metrics_Id: "",
                value: "",
                id : index + 1
              };
            });
            scopeDetailsSetValue("severityof_Target", riskList || []);
          }else if(edit && view && isEditData && RiskRateDataActive){
                  
          }
        }
   }, [RiskRateDataActive]);
  
  
   useEffect(() => {
    if (applicationParameterData.length > 0 && add) {
  
            const filteredRegionalData = applicationParameterData.find(
                (item: any) => item.Parameter_Name === ApplicationParameterName.EstimateSpend && item.IsActive === ACTIVE
              );
         
      const lastParameterValue = filteredRegionalData?.Parameter_Value || 1;
      scopeDetailsSetValue("project_Cost", lastParameterValue);
      scopeDetailstrigger("project_Cost");
    
    }
  }, [applicationParameterData]);

    function calculateDatesWithFrequency(
      startDate: string,
      baseEndDate: string,
      notifyBefore: number,
      escalationBefore: number,
      frequency: any
    ) {
      const results: any = [];
      
      let currentStartDate = dayjs(startDate);
      const finalEndDate = dayjs(baseEndDate);
    
      while (currentStartDate <= finalEndDate ) {
        let periodEndDate: dayjs.Dayjs;
        switch (frequency) {
          case PM_frequency.Monthly:
            periodEndDate = currentStartDate.add(1, "month").subtract(1, "day");
            break;
          case PM_frequency.Quarterly:
            periodEndDate = currentStartDate.add(3, "month").subtract(1, "day");
            break;
          case PM_frequency.Half_yearly:
            periodEndDate = currentStartDate.add(6, "month").subtract(1, "day");
            break;
          case PM_frequency.Yearly:
            periodEndDate = currentStartDate.add(1, "year").subtract(1, "day");
            break;
          default:
            throw new Error("Invalid frequency");
        }
    
        if (periodEndDate.isAfter(finalEndDate)) {
          periodEndDate = finalEndDate;
        }
    
  
        results.push({
          "review_StartDate": getDateOrConvertSameTime(new Date(currentStartDate.toDate()) , "start"),
          "review_EndDate": getDateOrConvertSameTime(new Date(periodEndDate.toDate())  , "end"),
          "notify_Date": getDateOrConvertSameTime(new Date(currentStartDate.subtract(notifyBefore, "days").toDate()) , "start"),
          "escalation_Date": getDateOrConvertSameTime(new Date(periodEndDate.subtract(escalationBefore, "days").toDate()) , "start")
      })
    
        currentStartDate = periodEndDate.add(1, "day");
      }
    
      return results;
    }
  
  
    function calculateOntime(   startDate: string,
      baseEndDate: string,
      notifyBefore: number,
      escalationBefore: number){
        let results: any = [];
      let currentStartDate = dayjs(startDate);
      const finalEndDate = dayjs(baseEndDate);
    
      if(currentStartDate  <= finalEndDate ) {
       results = [{
          "review_StartDate": getDateOrConvertSameTime(new Date(currentStartDate.toDate()) , "start"),
          "review_EndDate": getDateOrConvertSameTime(new Date(finalEndDate.toDate()) , "end"),
          "notify_Date": getDateOrConvertSameTime(new Date(currentStartDate.subtract(notifyBefore, "days").toDate()) , "start"),
          "escalation_Date": getDateOrConvertSameTime(new Date(finalEndDate.subtract(escalationBefore, "days").toDate()) , "start"),
      }]
    }
  
    return results;
      }

    useEffect(() => {
      const reviewType = watchMonitoringPeriod("review_Type");
      const frequency = watchMonitoringPeriod("frequency");
      const reviewStartDate = watchMonitoringPeriod("review_Start_Date");
      const reviewEndDate = watchMonitoringPeriod("review_End_Date");
      const reminderBefore = watchMonitoringPeriod("reminder_Before");
      const escalationBefore = watchMonitoringPeriod("escalation_Before");
       let FrequencyList : any = lovTable.filter((item: any) => item.Name === LovConfig?.Frequency) || [] 
      let frequency_value = FrequencyList?.find((fr_item : any) => fr_item?.LovId == frequency)?.Options || ""
          let val : any [] = []
      if (reviewType === 1 && (frequency_value && frequency && reviewStartDate && reviewEndDate && reminderBefore && escalationBefore)) {
          val = calculateDatesWithFrequency(reviewStartDate, reviewEndDate, reminderBefore, escalationBefore, frequency_value);
      }else if(reviewType === 0 && reviewStartDate && reviewEndDate && reminderBefore && escalationBefore){
        val = calculateOntime(reviewStartDate, reviewEndDate, reminderBefore, escalationBefore);
      }
    
      MonitoringPeriodSetValue("reviewDates" , val , frequency_value)
          
    }, [
      watchMonitoringPeriod("review_Type"),
      watchMonitoringPeriod("frequency"),
      watchMonitoringPeriod("review_Start_Date"),
      watchMonitoringPeriod("review_End_Date"),
      watchMonitoringPeriod("reminder_Before"),
      watchMonitoringPeriod("escalation_Before"),
      lovTable,
      add
    ]);
  
  
   useEffect(() => {
      if (applicationParameterData.length > 0 && add) {
        let CurrencyCode: any = applicationParameterData?.find(
          (item) =>
            item.Parameter_Group === "Currency" && item.Parameter_Name === ApplicationParameterName.DefaultCurrency
        )?.Parameter_Value;
    const ActiveCurrencies = CurrencyData.filter((item: any) => item.IsActive === ACTIVE);
        const CurrencyId = ActiveCurrencies?.find((item : any) => item.CurrencyCode === CurrencyCode)?.CurrencyId;
    console.log(CurrencyId , "CurrencyId");
    
        if (CurrencyId) {
          scopeDetailsSetValue("currency", CurrencyId);
          scopeDetailstrigger("currency");
        }
      }
    }, [applicationParameterData, CurrencyData]);

  const SubmitSLAMonitoringForm = () => {

    if (slaMonitorList && slaMonitorList.length > 0) {
      setslanodatawarning(false)
      let scopevalue : any = scopeDetailsGetValues();
     let  scopevalueTarget : any =  scopevalue?.severityof_Target || [];
     let scopevalueTargetValueAdded = scopevalueTarget.map((item: any) => {
      let findedValue = severity_of_slaList?.find(
        (met_item: any) => met_item?.Metrics_Id == item?.metrics_Id
      )?.Rating || "";
      return {
        ...item,
        value: findedValue,
      };
    });
 
    scopevalue.severityof_Target = scopevalueTargetValueAdded;
      const finalData = {
        pR_Clause_Target_Id : isEditData ? isEditData?.pR_Clause_Target_Id : 0  ,
        pmprctScope:  scopevalue,
        pmprctMonitorPeriods : MonitoringPeriodGetValues() ,
        pmprctsla : slaMonitorList,
      };
    
      handlesubmit(finalData);
    }else{
      setslanodatawarning(true)
    }

      
  }

  return (
    <>
      <Box>
        <Stack spacing={4}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <CustomBreadcrumbs
              list={[
                { value: 0, name: "Pending Actions" },
                { value: 1, name: `PR Scope Definition` },
                { value: 2, name: `Add Target / KPI` },
              ]}
              onchange={closeAddtarget}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" color={"text.primary"}>
              {`Add Target / KPI`}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "start", width: "100%" , alignItems:"center" }}>
            <Box sx={{ width: "60%" }}>
              <CustomStepper activeStep={step} steps={["Scope Details", "Monitoring Period" , "SLA to Monitor"]} />
            </Box>
          </Box>
    <Divider></Divider>
          {step === 0 ? (
            <FormProvider {...scopeDetailsFormMethods}>
              <ScopeDetails add={true} />
            </FormProvider>
          ) : null}

{step === 1 ? (
            <FormProvider {...MonitoringPeriodFormMethods}>
              <MonitoringDetails add={true} />
            </FormProvider>
          ) : null}

{step === 2 ? (
          <slaMonitorContext.Provider
                           value={{
                            slaMonitorList,
                            setslaMonitorList,
                            slanodatawarning
                           }}
                         >
              <SLAtoMonitor add={true} />
              </slaMonitorContext.Provider>
          ) : null}


          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "end",
              mt: 2,
            }}
          >
            <React.Fragment>
              <CustomButton
                type="reset"
                variant="outlined"
                name={"Cancel"}
                onClick={() => {
                  closeAddtarget && closeAddtarget(1);
                }}
              />
            {step === 0 ? ( <>
              <CustomButton
                type="submit"
                disabled={!scopeDetailsIsDirty}
                name={"Monitoring Period"}
                onClick={scopeDetailsHandleSubmit(SubmitScopeForm)}
              />


            </>  ) : null }
                
              {step === 1 ? ( <> <CustomButton
                type="submit"
                name={"Scope Details"}
                onClick={() => setstep(0)}
              />
           <CustomButton
                type="submit"
                disabled={!MonitoringPeriodIsDirty}
                name={"SLA to Monitor"}
                onClick={MonitoringPeriodHandleSubmit(SubmitMonitoringForm)}
              />   </>  ) : null }

{step === 2 ? ( <><CustomButton
                type="submit"
                name={"Monitoring Period"}
                onClick={() => setstep(1)}
              />
              
              <CustomButton
                type="submit"
                disabled={!MonitoringPeriodIsDirty}
                name={"Add"}
                onClick={() => SubmitSLAMonitoringForm()}
              /></>  ) : null }
            </React.Fragment>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
