import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { Box, Divider, Grid, Stack } from "@mui/material";
import PageMainHeading from "../../../common/CommonHeadings/PageMainHeading";
import { CustomerDetailsSection } from "../CommonPages/CustomerDetailsSection";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import EmailForm from "../../contractManagement/CostApproval/EmailForm/EmailForm";
import RichTextEditor from "react-rte";
import { emailDataProps } from "../../contractManagement/CostApproval/CostApproval";
import { FormProvider, useForm } from "react-hook-form";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomRadioButton from "../../../common/dynamicInputs/CustomRadioButton";
import { CustomBasicAccordion } from "../../../common/dynamicAccordion/CustomBasicAccordion";
import { textFieldWidth } from "../../../utils/constants";
import LableandValuesPreview from "../../../common/CommonPreview/LableandValuesPreview";
import { RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import CustomerAssessmentService from "../../../store/services/CustomerAssessmentService";
import { processQuestionsCOB } from "../COBAssessments/assessmentResponseCOB/AssessmentResponseCustomerOB";
import { LovConfig } from "../../../config/config";
import CommonDataExport, { exportExcelNew } from "../../../common/CommonSheetsDownload/ExcelDownload";
import { getEmailTemplateList } from "../../../store/slices/EmailTemplateData";
import { sendEmailToCustomer, updateisMailsendtoCustomer } from "../../../store/slices/assessmentQuestionnaireMappingCOB";
import { getUsersList } from "../../../store/slices/userData";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import vendorDetailsService from "../../../store/services/vendorDetails";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";



interface SendResponseToCustomerProps {
      onClose?:any
      data:any
      prePageName?:any
}

export const SendResponseToCustomer :React.FC<SendResponseToCustomerProps> = ({onClose , data , prePageName}) => {
  const [customerDetails , setcustomerDetails] =useState<any>({})
  const { isAuthenticated, user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { isAQMMappingLoading , AQMMappingToasterMsg , isMailsendtoCustomer } = useSelector((state: RootState) => state.assesmentQuestionnaireMappingCOBData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const dispatch = useAppDispatch();
  const [isEmailModelOpen , setIsEmailModelOpen] =useState<boolean>(false)
  const [isConformclose , setisConformclose] =useState<boolean>(false)
  const [isConformemail , setisConformemail] =useState<boolean>(false)
  const [expanded , setExpanded] =useState<boolean>(true)
  const [emailtosend , setemailtosend] =useState<any>("")
  const [templateName , settemplateName] =useState<any>("COB - Send Response to Customer")
  const [checkedValue , setcheckedValue] =useState<any>(0)
  const [assessmentData , setAssessmentData] =useState<any>(null)
  const [vendorDetails , setVendorDetails] =useState<any>(null)
  const [ApproveList, setApproveList] = useState<any[]>([]);
  const [assessmentQuestionData , setassessmentQuestionData] =useState<any>([])
  const [optionData, setoptionData] = useState<any[]>([{value : 0 , lable : "Download Response"},{value : 1 , lable : "Email Response"}]);
 
  const header = [ { DisplayName: "Questions", Field : "Question" },
{ DisplayName: "Response", Field : "answer"  },
{ DisplayName: "Type", Field : "Answer_Type_value" },
{ DisplayName: "SO Decision", Field : "SO_ReviewStatus_value"},
{ DisplayName: "SO Review", Field : "SO_Remarks" },
{ DisplayName: "Send To DO", Field : "IsDo_value" },
{ DisplayName: "DO Status", Field : "Do_ReviewStatus_value" },
{ DisplayName: "DO Review", Field : "DO_Remarks" },
{ DisplayName: "Send To PM", Field : "IsAddInPM_value" },
]

useEffect(() => {
  dispatch(getEmailTemplateList());
  dispatch(getUsersList({ FormID: 2 }));
},[])
  useEffect(() => {
    if(data){
        setcustomerDetails(data)
        getAssessmentData(data)
        callVendorDetails(data?.Vendor_Id)
    }
  },[data])
  useEffect(() => {
    dispatch(updateLoader(isAQMMappingLoading));
  },[isAQMMappingLoading])
  useEffect(() => {
       if(isMailsendtoCustomer){
        emailDetailsReset();
        setIsEmailModelOpen(false);
        setisConformemail(false)
        dispatch(updateisMailsendtoCustomer(false));
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg:AQMMappingToasterMsg,
          })
        );
       
       }
  },[isMailsendtoCustomer])

  useEffect(() => {
    setApproveList(lovTable.filter((item: any) => item.Name === LovConfig?.Pre_Approval) || []);
  }, [lovTable]);
  let EmailFormData: emailDataProps = {
    email_template_id: null,
    email_content: RichTextEditor.createEmptyValue(),
    is_Msword: false,
    is_Pdf: false,
    emailFormat: "",
    notify_email_id: null,
    subject: "",
    to_email_id: null,
  };

  const getAssessmentData = async (data:any) => {
        
    try {
      if (data) {
        const instanceValue = await CustomerAssessmentService.getCustomerAssessment({
          Vendor_Id: data?.Vendor_Id,
          Project_Detail_Id: data?.Project_Detail_Id,
          UserId: userLoginResponse?.UserId,
          IsSME: false,
          IsDo: false,
        });
        let resposseData = instanceValue?.data;
      
          setAssessmentData(resposseData);
      }
    } catch {}
    
  }

  const callVendorDetails = async (venId: any) => {
    try {
      const res : any = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: Number(venId) });

      setVendorDetails(res.data);
     
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    }
  };

  const saveProcessedData = async (QuestionListDub : any) => {
    const processedData = await processQuestionsCOB(QuestionListDub, "cxo", ApproveList);
    console.log(processedData , 'processedData');
    
    setassessmentQuestionData(processedData)
  };
useEffect(() => {

  if(assessmentData && assessmentData?.AssessmentQuestionnaires){
     
       const ParedQuestions : any = JSON.parse(assessmentData?.AssessmentQuestionnaires)
       saveProcessedData(ParedQuestions)
  }

},[assessmentData , ApproveList])

  const emailDetailsFormMethods = useForm<emailDataProps>({ defaultValues: EmailFormData, mode: "onChange" });
  const {
    handleSubmit: emailDetailFormMethodsHandleSubmit,
    formState: { errors: errorsEmailDetailFormMethodsform, isDirty: emailDetailsFormMethodsIsDirty },
    getValues: emailDetailsFormMethodsGetValues,
    setValue: emailDetailsFormMethodsSetValue,
    reset: emailDetailsReset,
  } = emailDetailsFormMethods;

const   handleButtonClick = () => {
 
        setIsEmailModelOpen(true);
        emailDetailsFormMethodsSetValue('email_template_id' , "")
        emailDetailsFormMethodsSetValue('to_email_id' , vendorDetails?.VendorDetails?.EmailId)
  }




const handleEmailFormSubmit = (fromDatas : any) => {
  const formData : any = fromDatas ;
  const emailList = Array.isArray(formData?.notify_email_id)
  ? formData.notify_email_id.join(',')
  : '';
  const payload : any ={
    "ToEmail": formData?.to_email_id , 
     "VendorId": data?.Vendor_Id, 
      "TemplateName": templateName, 
       "EmailSubject": formData?.subject, 
        "EmailBody": formData?.email_content?.toString("html"), 
         "CcEmail": emailList,
          "userId": userLoginResponse?.UserId,
          AttachFile : ""
  }
  exportExcelNew(user , true , header, data = assessmentQuestionData ,  "Assessment Response" , undefined , undefined , "" , "DD/MM/YYYY" , true ).then((base64String: string | undefined) => {

    payload.AttachFile = base64String
    dispatch(sendEmailToCustomer(payload))
 
  }).catch((err: any) => {
    console.error("Error generating base64 string:", err);
  });
 
}

const conformcloseEmailForm = () => {

  emailDetailsReset();
  setIsEmailModelOpen(false);
}
     return (
        <>
 <Stack spacing={3}>
            
              <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                <CustomBreadcrumbs
                  list={[
                    { value: 0, name: prePageName ? prePageName : "COB Assessment" },
                    { value: 1, name: "Customer Response"  },
                  ]}
                  onchange={(val : any) => { if(val == 0 && onClose){
                    onClose()
                  } }}
                />
              </Box>
          
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <PageMainHeading title={`Send Response to Customer`} />
            </Box>
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <CustomBasicAccordion
        expanded={expanded}
        handleChange={() => setExpanded(!expanded)}
        title="Customer Details"
        details={<>   <Box>
            <Grid container spacing={2} >
              <Grid
               item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <LableandValuesPreview lable="Assessment No" Value={`${customerDetails?.Assessment_Number || '-'}`} />
              </Grid>
              <Grid
                    item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                    <LableandValuesPreview lable="Customer Name" Value={`${customerDetails?.Vendor_Name || '-'}`} />
              </Grid>
              <Grid
                    item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                         <LableandValuesPreview lable="Services" Value={`${customerDetails?.Services || '-'}`} />
              </Grid>
              <Grid
                    item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                         <LableandValuesPreview lable="Last Response Send On" Value={`${customerDetails?.Services || '-'}`} />
              </Grid>
             
            </Grid>
          </Box></>}
      />

              <Divider />
              <Box sx={{paddingY:2}}>
              <CustomRadioButton
                      key={`multi-choice in customer- email-response`}
                      list={optionData}
                      style={{ marginTop: "-10px" }}
                      label=""
                      value={checkedValue}
                      direction="column"
                      onChange={(e) => {
                        setcheckedValue(e);
                      }}
                    //   error={Boolean(Errors[`${0}-Response_In_Option`])}
                    //   helperText={Errors[`${0}-Response_In_Option`]}
                    />
              </Box>
              <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", gap: 2, width: "100%" }}>
                <CustomButton
                  type="reset"
                  variant="outlined"
                  name={"Close"}
                  onClick={() => {
                    setisConformclose(true)
                  }}
                />
             
             
             {checkedValue == 1 ? <CustomButton
                    type="reset"
                    name={ "Send Mail"}
                    onClick={() => handleButtonClick() }
                  /> :  <CommonDataExport header={header} data={assessmentQuestionData} table_name={'Customer Assessment'} button_title={'Download Assessment'} variant="contained" />
             }
           
              </Box>
            </Box>
            </Stack>
         
            <CustomDialog
        show={isEmailModelOpen}
        onHide={() => {
          emailDetailsReset();
          setIsEmailModelOpen(false);
        }}
        maxWidth={"md"}
        header={"Send Email to Customer"}
        contentNode={
          <>
            {" "}
            <FormProvider {...emailDetailsFormMethods}>
              <EmailForm
               isDisableAttachment={true}
                templateName={templateName}
                to_emails={emailtosend}
                onSubmit={(data) => {
                  handleEmailFormSubmit(data); 
                }}
                onClose={() => {
                  conformcloseEmailForm()
                }}
              />
            </FormProvider>
          </>
        }
      />



<ModalPopUp
            onDeleteMethod={() => onClose && 
              onClose() }
              isDelete={true}
            onHide={() => setisConformclose(false)}
            show={isConformclose}
            ActionName={"Close"}
            header={"Confirm"}
            text={`Are you sure you want to Close?`}
          />
        </>
     )

}