import dayjs from "dayjs";

export const EDIT_SUCCESS_MSG = "Vendor Profile Updated Successfully";
export const ADD_SUCCESS_MSG = "Vendor Profile Added Successfully";

export const USER_SUCCESS_MSG = "User Added Successfully";

export const EDIT_UPDATING_MSG = "Vendor Profile updating...";
export const ADD_UPDATING_MSG = "Vendor Profile is inserting...";

export const TOASTER_ADD_SUCCESS_MSG = "Toaster Added Successfully";
export const TOASTER_ADD_UPDATING_MSG = "Toaster is updating...";
export const TOASTER_EDIT_SUCCESS_MSG = "Toaster Updated Successfully";
export const TOASTER_EDIT_UPDATING_MSG = "Toaster is updating...";

export const EMAIL_ADD_SUCCESS_MSG = "Email Added Successfully";
export const EMAIL_ADD_UPDATING_MSG = "Email is updating...";
export const EMAIL_EDIT_SUCCESS_MSG = "Email Updated Successfully";
export const EMAIL_EDIT_UPDATING_MSG = "Email is updating...";

export const PermissionColumn = {
  table_name: "Sample Drill Down",
  columns: [
    {
      Field: "Select",
      DisplayName: "Select",
      FieldType: "select",
    },
    {
      Field: "MenuName",
      DisplayName: "Menu Name",
      FieldType: "string",
    },
    {
      Field: "IsView",
      DisplayName: "View",
      FieldType: "number",
      width: 30,
    },
    {
      Field: "IsAdd",
      DisplayName: "Create",
      FieldType: "number",
      width: 30,
    },
    {
      Field: "IsEdit",
      DisplayName: "Edit",
      FieldType: "number",
      width: 30,
    },
    {
      Field: "IsDelete",
      DisplayName: "Status",
      FieldType: "number",
      width: 30,
    },
    {
      Field: "IsExport",
      DisplayName: "Export",
      FieldType: "number",
      width: 30,
    },
    {
      Field: "IsApproval",
      DisplayName: "Approval",
      FieldType: "number",
      width: 30,
    },
  ],
};

export const addMenu = {
  IsAdd: 0,
  IsEdit: 0,
  IsDelete: 0,
  IsView: 0,
  IsExport: 0,
  IsImport: 0,
  IsApproval: 0,
};

export const ACTIVE = "Active";
export const INACTIVE = "Inactive";

export const YES = "Yes";
export const NO = "No";

export const textFieldWidth = {
  xs: 12, // Full width on extra small screens
  sm: 12, // Full width on small screens
  md: 4, // Half width on medium screens
  lg: 4, // One-third width on large screens
  xl: 4, // One-fourth width on extra large screens
  margin: "8px", // One-fourth width on extra large screens
};

export const templateName = [
  {
    id: 1,
    value: "Forgot Password",
  },
  {
    id: 2,
    value: "Create User",
  },
  {
    id: 3,
    value: "Vendor Response",
  },
  {
    id: 4,
    value: "Accept VOB Vendor",
  },
  {
    id: 5,
    value: "Accept VOB StakeHolder",
  },
  {
    id: 6,
    value: "Terminate VOB Vendor",
  },
  {
    id: 7,
    value: "Terminate VOB StakeHolder",
  },
  {
    id: 8,
    value: "Accept Vendor Consent",
  },
  {
    id: 9,
    value: "Reject Vendor Consent",
  },
  {
    id: 10,
    value: "VoB Setup Initiated Email",
  },
  {
    id: 11,
    value: "VoB Setup Completed Email",
  },
  {
    id: 12,
    value: "SME Response Pending",
  },
  {
    id: 13,
    value: "SME Review Completed",
  },
  {
    id: 14,
    value: "SO Approval Pending",
  },
  {
    id: 15,
    value: "SO Approval Completed",
  },
  {
    id: 16,
    value: "DO Review Pending",
  },
  {
    id: 17,
    value: "DO Review Completed",
  },
  {
    id: 18,
    value: "SO Review Pending",
  },
  {
    id: 19,
    value: "SO Review Completed",
  },
  {
    id: 20,
    value: "Cxo Review Pending",
  }, {
    id: 21,
    value: "Vendor Response Renewal",
  },
  {
    id: 22,
    value: "Accept VOB Vendor Renewal",
  },
  {
    id: 23,
    value: "Accept VOB StakeHolder Renewal",
  },
  {
    id: 24,
    value: "Terminate VOB Vendor Renewal",
  },
  {
    id: 25,
    value: "Terminate VOB StakeHolder Renewal",
  },
  {
    id: 26,
    value: "Accept Vendor Consent Renewal",
  },

  {
    id: 27,
    value: "VoB Setup Initiated Email Renewal",
  },
  {
    id: 28,
    value: "VoB Setup Completed Email Renewal",
  },
  {
    id: 29,
    value: "SME Response Pending Renewal",
  },
  {
    id: 30,
    value: "SME Review Completed Renewal",
  },
  {
    id: 31,
    value: "SO Approval Pending Renewal",
  },
  {
    id: 32,
    value: "SO Approval Completed Renewal",
  },
  {
    id: 34,
    value: "DO Review Pending Renewal",
  },
  {
    id: 35,
    value: "DO Review Completed Renewal",
  },
  {
    id: 36,
    value: "SO Review Pending Renewal",
  },
  {
    id: 37,
    value: "SO Review Completed Renewal",
  },
  {
    id: 38,
    value: "Cxo Review Pending Renewal",
  },
  {
    id: 39,
    value: "Withdraw Consent",
  },
  {
    id: 40,
    value: "Vendor Details Update From Vendor Portal",
  },
  {
    id: 41,
    value: "VOB Escalation Notice",
  },
  {
    id: 42,
    value: "Cost Approval ",
  },
  {
    id: 43,
    value: "Send for Vendor Review",
  },
  {
    id: 44,
    value: "Board Approval",
  },
  {
    id: 45,
    value: "E sign",
  },
 
];

export const sampleJson = {
  AMC: [
    {
      LovId: 13,
      Name: "LOB",
      Code: "AMC",
      Description: "Asset Management",
      CreatedBy: null,
      Options: "Asset Management",
      Status: "true",
    },
  ],
  PMS: [
    {
      LovId: 14,
      Name: "LOB",
      Code: "PMS",
      Description: null,
      CreatedBy: null,
      Options: "PMS",
      Status: "D",
    },
  ],
  EPF: [
    {
      LovId: 15,
      Name: "LOB",
      Code: "EPF",
      Description: "EPFO",
      CreatedBy: null,
      Options: "EPFO",
      Status: "true",
    },
  ],
  TES: [
    {
      LovId: 72,
      Name: "LOB",
      Code: "TES",
      Description: "string",
      CreatedBy: "string",
      Options: "TEST",
      Status: "D",
    },
  ],
  TLOB: [
    {
      LovId: 73,
      Name: "LOB",
      Code: "TLOB",
      Description: "Test Lob",
      CreatedBy: "Admin",
      Options: "Test Lob",
      Status: "true",
    },
    {
      LovId: 74,
      Name: "LOB",
      Code: "TLOB",
      Description: "Test Lob3",
      CreatedBy: "Admin",
      Options: "Test Lob3",
      Status: "true",
    },
  ],
  "001": [
    {
      LovId: 75,
      Name: "LOB",
      Code: "001",
      Description: "Vignesh",
      CreatedBy: "Karthi A",
      Options: "Vignesh",
      Status: "D",
    },
  ],
  ABC: [
    {
      LovId: 76,
      Name: "LOB",
      Code: "ABC",
      Description: "Test23",
      CreatedBy: "Karthi A",
      Options: "Test23",
      Status: "true",
    },
    {
      LovId: 79,
      Name: "LOB",
      Code: "ABC",
      Description: "Test23",
      CreatedBy: "Karthi A",
      Options: "Test23",
      Status: "A",
    },
  ],
};

export const menuIcons = [
  {
    MenuName: "Favourites",
    icon: "star",
  },
  {
    MenuName: "User Management",
    icon: "users",
  },
  {
    MenuName: "Logs",
    icon: "logs",
  },
  {
    MenuName: "Third party Management",
    icon: "users",
  },
  {
    MenuName: "UI Controls",
    icon: "shield",
  },
  {
    MenuName: "Configure Settings",
    icon: "settings",
  },
  {
    MenuName: "Template Configuration",
    icon: "Template",
  },
  {
    MenuName: "Contract Management",
    icon: "file-text",
  },
  {
    MenuName: "Performance Management",
    icon: "bar-chart",
  },
  {
    MenuName: "Configuration Management",
    icon: "settings",
  },
  {
    MenuName: "Security Management",
    icon: "shield",
  },
  {
    MenuName: "Queue Management",
    icon: "Queue_Management",
  },
];
export const Renewal_Date = 30;
export const RenewalParameterName = "Renewal Date";
export const defaultDate = "01-01-0001";
export const threshold = 100;

export const DAY_MILLI_SECS = 86400000;

export const chkExpiryDate = (date: string | null | undefined) => {
  if (!date) {
    return "";
  }

  const [datePart] = date.split("T");

  if (datePart === defaultDate) {
    return "";
  }

  return date;
};
export const defaultHistoryDate = "0001-01-01";
export const chkDate = (date: string | null | undefined) => {
  if (!date) {
    return "";
  }
  const [datePart] = date.split("T");

  if (datePart === defaultHistoryDate) {
    return "";
  }

  return date;
};

const dateformateStartend = {

}

export type DateFormatStartEnd = 'start' | 'end' | 'current';

export const getDateOrConvertSameTime = (date?: Date, format?: DateFormatStartEnd) => {

  const originalFormate = format ? format : "";
  const originalDate = date ? date : new Date();
  const currentDate = new Date();

  // Manually originalFormate the date to keep the time as is
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(originalDate.getDate()).padStart(2, '0');
  const hours = originalFormate === 'start' ? '00' : originalFormate === 'end' ? '23' : originalFormate === 'current' ?  String(currentDate.getHours()).padStart(2, '0') : String(originalDate.getHours()).padStart(2, '0');
  const minutes = originalFormate === 'start' ? '00' : originalFormate === 'end' ? '59' : originalFormate === 'current' ?  String(currentDate.getMinutes()).padStart(2, '0') : String(originalDate.getMinutes()).padStart(2, '0');
  const seconds = originalFormate === 'start' ? '00' : originalFormate === 'end' ? '59' : originalFormate === 'current' ?  String(currentDate.getSeconds()).padStart(2, '0') : String(originalDate.getSeconds()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return formattedDate;
};

export const addWorkingDays = (startDate: any, days: any, HolidayData: any) => {
  let holidaydates = HolidayData.map((item: any) => dayjs(item?.Holiday_Date));
  console.log(holidaydates, "holidaydates");

  let currentDate = dayjs(startDate);
  let daysAdded = 0;

  while (daysAdded <= days - 1) {
    currentDate = currentDate.add(1, "day");

    const isWeekend = currentDate.day() === 0 || currentDate.day() === 6;
    const isHoliday = holidaydates?.some((holiday: any) => holiday?.isSame(currentDate, 'day'));

    if (!isWeekend && !isHoliday) {
        daysAdded += 1;
    }

  }

  return currentDate;
};

export const extractContent = (htmlString: string) => {
  // Create a new DOMParser instance
  const parser = new DOMParser();

  // Parse the HTML string into a document
  const doc = parser.parseFromString(htmlString, "text/html");

  // Extract and return the text content
  return doc.body.textContent || "";
}

export const EstimateSpend = "Estimate Spend"



// export const  bizAdminDepartmentId=280;
export const bizAdminDepartmentId = 39;

export const COBWorkFlowName = "Client Onboarding";

export const bizAdminDepartmentName = "BizAdmin";
// export const bizAdminDepartmentName = "admin";


export const getVendorHeading = (data: string): string => {
  switch (data) {
    case "VendorAttachments":
      return "Vendor Attachments";
    case "VendorDetails":
      return "Vendor Details";
    default:
      return "";
  }
};

export const compare=(a:any, b:any,field:string) =>{  
  const bandA = a[field];
  const bandB = b[field];

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}

export const REGULATION = "Regulation";

export const PLUGIN_STEPPER ={
  SME_REVIEW:"smereview",
  DO:"do",
  SO:"so",
  SME:'sme',
  SME_SIGN_OFF:"smeSignOff"
}

export const ContractVendorGreetingName = "Contract - Vendor Welcome Greeting";