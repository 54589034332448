import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { VendorOnboardingAssessment } from "../vendorOnBoarding/VendorOnBoardingPreview";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import { updateVendorAssessmentAddandEdit } from "../../store/slices/vendorAssessment";
import { VendorOnboardingSignOff } from "../vendorOnBoarding/signoff/SignOff";
import { AddVendorDetails } from "../vendorDetails/AddVendor";
import { AQMMapping } from "../vendorOnBoarding/aqmMapping/AQMMapping";
import { CMWorflowPages, PMWorflowPages, vendorassetroles, VOBWorflowPages, WorkFlowType } from "../../config/config";
import { updateVendorDetailsAddandEdit } from "../../store/slices/vendorDetails";
import { updateAQMMappingAddandEdit } from "../../store/slices/assesmentQuestionnaireMapping";
import { updateVendorSignOff } from "../../store/slices/vendorSignOff";
import vendorDetailsService from "../../store/services/vendorDetails";
import { AQMMappingCOB } from "../customerOnBoarding/AQMappingforCOB/AQMappingCOB";
import { ContractRolesandResponsibility } from "../contractManagement/rolesAndResponsibility/ContractRolesandResponsibility";
import { updaterolesandresponsAddandEdit } from "../../store/contractManagement/slices/contractRolesAndResponsiblity";
import CostApproval from "../contractManagement/CostApproval/CostApproval";
import OnlyOfficeTextEditorCM from "../contractManagement/OnlyOfficeTextEditor/OnlyOfficeTextEditor";
import { VendorOnboardingSignOffcxo } from "../vendorOnBoarding/signoff/vendorCXOSignoff";
import { costApprovalResetState } from "../../store/contractManagement/slices/CostApprovalSlice";
import { VendorReview } from "../contractManagement/vendorReview/VendorReview";
import BoardApproval from "../contractManagement/BoardApproval/BoardApproval";
import NotifySigningAuthority from "../contractManagement/NotifySigningAuthority/NotifySigningAuthority";
import CustomerOnBoardingAssessment from "../customerOnBoarding/COBAssessments/CustomerOnBoardingAssessment";
import COBCXOApproval from "../customerOnBoarding/CXOApproval/CXOApproval";
import PhysicalUpload from "../contractManagement/PhysicalUpload/PhysicalUpload";
import { InitiateReview } from "../performanceManagement/InitiateReview/InitiateReview";
import { PMResponse } from "../performanceManagement/prReviews/PMResponse";
import { PMResponseAddandEditUpdate } from "../../store/performanceManagement/slices/pmInitiateandResponseReview";
import { EventAddandEditUpdate } from "../../store/performanceManagement/slices/pmEventLog";

export interface VOBPreviewProps {
  closeForm?: any;
  data?: any;
}
export const ActionScreens: React.FC<VOBPreviewProps> = ({ closeForm, data }) => {
  const {
    VendorAssessmentdata,
    isAddEditVendorAssessmentLoading,
    isVendorAssessmentAdded,
    isVendorAssessmentUpdated,
    VendorAssessmentToasterMsg,
    isVendorAssessmentSuccess,
  } = useSelector((state: RootState) => state.vendorAssessmentData);
  const {
    vendorDetailsdata,
    isVendorDetailsAdded,
    isVendorDetailsUpdated,
    vendorDetailsToasterStatus,
    isVendorDetailsLoading,
    vendorDetailsToasterMsg,
  } = useSelector((state: RootState) => state.vendorDetails);
  const { isAQMMappingAdded, isAddEditAQMMappingLoading, AQMMappingToasterMsg } = useSelector(
    (state: RootState) => state.aQMMappingData
  );
  const { isVendorSignOffLoading, isVendorSignOffAdded, VendorSignOffToasterMsg } = useSelector(
    (state: RootState) => state.vendorSignOff
  );
  const { EventLogSLAAdded, PMEventLogToasterMsg } = useSelector(
    (state: any) => state.performanceManagementReducer?.PMEventLogDataSlice
  );
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    isRolesAndResponsibilityAdded,
    isRolesAndResponsibilityUpdated,
    isLoading,
    isRolesAndResponsibilityDeleted,
    RolesAndResonsibilitytoasterMsg,
  }: any = contractManagementReducer.contractRolesAndResponsibility;
  const { isPMResponseLoading, pMResponseAAdded, PMResponseToasterMsg } = useSelector(
    (state: any) => state.performanceManagementReducer?.PMResponseDataSlice
  );
  const [isSmeReview, setIsSmeReview] = useState(false);
  const [vendor_Id, setvendor_Id] = useState("");
  const [processName, setprocessName] = useState("");
  const [worfkowCatagory, setworfkowCatagory] = useState("");
  const [assessmentRole, setAssessmentRole] = useState("");
  const [currentProcessInstance, setcurrentProcessInstance] = useState("");
  const [VendorDetails, setVendorDetails] = useState<any>(undefined);
  const dispatch = useAppDispatchThunk();

  const { workFLowInstanceData }: any = useSelector(
    (state: any) => state.contractManagementReducer?.contractRolesAndResponsibility
  );
  // console.log(workFLowInstanceData,"workFLowInstanceDataworkFLowInstanceData")
  useEffect(() => {
    if (data) {
      setcurrentProcessInstance(data);
      setvendor_Id(data.VendorId);
      setprocessName(data.FormName);
      setworfkowCatagory(data.Workflow_CategoryName);

      if (data.FormName == VOBWorflowPages.SMEResponse) {
        setAssessmentRole(vendorassetroles.SME);
      } else if (data.FormName == VOBWorflowPages.SMEReview) {
        setIsSmeReview(true);
        setAssessmentRole(vendorassetroles.SME);
      } else if (data.FormName == VOBWorflowPages.SOReview) {
        setAssessmentRole(vendorassetroles.SO);
      } else if (data.FormName == VOBWorflowPages.DoReview) {
        setAssessmentRole(vendorassetroles.DO);
      }
    }
  }, [data]);

  const closeFormValue = () => {
    if (closeForm) {
      closeForm();
    }
  };

  useEffect(() => {
    if (isRolesAndResponsibilityAdded) {
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: RolesAndResonsibilitytoasterMsg.message,
          isTosterFailed: RolesAndResonsibilitytoasterMsg.status !== "Success",
        })
      );
      dispatch(updaterolesandresponsAddandEdit(false));
      dispatch(updateLoader(false));
      closeFormValue();
    }
  }, [isRolesAndResponsibilityAdded]);


  useEffect(() => {
    if (pMResponseAAdded) {
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: PMResponseToasterMsg.message,
          isTosterFailed: PMResponseToasterMsg.status !== "Success",
        })
      );
      dispatch(PMResponseAddandEditUpdate(false));
      closeFormValue();
    }
  }, [pMResponseAAdded]);



  useEffect(() => {
    const callVendorDetails = async (venId: any) => {
      try {
        const res = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: Number(venId) });

        setVendorDetails(res.data);
      } catch (error) {
        console.error("Error fetching vendor details:", error);
      }
    };

    if (vendor_Id && data && data.FormName == VOBWorflowPages.VendorDetail) {
      callVendorDetails(vendor_Id);
    }
  }, [vendor_Id, data]);

  useEffect(() => {
    dispatch(
      updateLoader(
        isAddEditVendorAssessmentLoading ||
        isVendorDetailsLoading ||
        isAddEditAQMMappingLoading ||
        isVendorSignOffLoading
      )
    );
  }, [isAddEditVendorAssessmentLoading, isVendorDetailsLoading, isAddEditAQMMappingLoading, isVendorSignOffLoading]);

  useEffect(() => {
    if (closeForm && isVendorAssessmentAdded) {
      dispatch(updateVendorAssessmentAddandEdit(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: VendorAssessmentToasterMsg,
          isTosterFailed: isVendorAssessmentSuccess === false ? true : false,
        })
      );

      if (isVendorAssessmentSuccess !== false) {
        closeForm();
      }
    }
  }, [isVendorAssessmentAdded]);

  useEffect(() => {
    if (closeForm && isVendorDetailsAdded) {
      dispatch(updateVendorDetailsAddandEdit(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: vendorDetailsToasterMsg,
          isTosterFailed: vendorDetailsToasterStatus === false ? true : false,
        })
      );
      if (vendorDetailsToasterStatus != false) {
        closeForm();
      }
    }
  }, [isVendorDetailsAdded]);

  useEffect(() => {
    if (closeForm && isAQMMappingAdded) {
      dispatch(updateAQMMappingAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: AQMMappingToasterMsg }));
      closeForm();
    }
  }, [isAQMMappingAdded]);

  useEffect(() => {
    if (closeForm && isVendorSignOffAdded) {
      dispatch(updateVendorSignOff(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: VendorSignOffToasterMsg }));
      closeForm();
    }
  }, [isVendorSignOffAdded]);

  useEffect(() => {
    if (EventLogSLAAdded) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: PMEventLogToasterMsg.Message, isTosterFailed: PMEventLogToasterMsg.Status === "false" ? true : false }));
      dispatch(EventAddandEditUpdate(false));

      if (closeFormValue) {
        closeFormValue();
      }
    }
  }, [EventLogSLAAdded]);

  console.log(currentProcessInstance, "CMWorflowPagesCMWorflowPages");

  return (
    <>
      {currentProcessInstance ? (
        <Box sx={{ width: "100%" }}>
          {processName === VOBWorflowPages?.VendorDetail &&
            (VendorDetails ? (
              <AddVendorDetails
                key={"Vendor Component From work flow renewal ass"}
                view={false}
                edit={!!VendorDetails}
                isEditData={VendorDetails || undefined}
                prePageName={"Pending Action"}
                onClose={closeFormValue}
                isWorkFlow={currentProcessInstance}
              />
            ) : (
              <AddVendorDetails
                key={"Vendor Component From work flow First ass"}
                view={false}
                edit={!!VendorDetails}
                isEditData={VendorDetails || undefined}
                prePageName={"Pending Action"}
                onClose={closeFormValue}
                isWorkFlow={currentProcessInstance}
              />
            ))}

          {processName === VOBWorflowPages?.AQMMapping && worfkowCatagory == WorkFlowType.vendorOnboarding ? (
            <AQMMapping
              currentProcessData={currentProcessInstance}
              prePageName={"Pending Action"}
              onClose={() => closeFormValue()}
            />
          ) : null}

          {processName === VOBWorflowPages?.AQMMapping && worfkowCatagory == WorkFlowType.customerOnboarding ? (
            <AQMMappingCOB
              currentProcessData={currentProcessInstance}
              prePageName={"Pending Action"}
              onclose={() => closeFormValue()}
            />
          ) : null}

          {processName === VOBWorflowPages?.CXO && worfkowCatagory == WorkFlowType.customerOnboarding ? (
            <COBCXOApproval
              currentProcessData={currentProcessInstance}
              prePageName={"Pending Action"}
              onclose={() => closeFormValue()}
            />
          ) : null}

          {vendor_Id &&
            worfkowCatagory == WorkFlowType.customerOnboarding &&
            (processName === VOBWorflowPages.SMEResponse ||
              processName === VOBWorflowPages.SOReview ||
              processName === VOBWorflowPages.DoReview) ? (
            <CustomerOnBoardingAssessment
              currentProcessData={currentProcessInstance}
              prePageName={"Pending Action"}
              onclose={() => closeFormValue()}
            />
          ) : null}

          {vendor_Id &&
            worfkowCatagory == WorkFlowType.vendorOnboarding &&
            (processName === VOBWorflowPages.SMEResponse ||
              processName === VOBWorflowPages.SMEReview ||
              processName === VOBWorflowPages.SOReview ||
              processName === VOBWorflowPages.DoReview) ? (
            <VendorOnboardingAssessment
              onClose={() => closeFormValue()}
              currentProcessData={currentProcessInstance}
              vendor_Id={vendor_Id}
              issmeReview={isSmeReview}
            />
          ) : null}

          {vendor_Id && worfkowCatagory == WorkFlowType.vendorOnboarding && processName === VOBWorflowPages?.CXO ? (
            <VendorOnboardingSignOffcxo
              currentProcessData={currentProcessInstance}
              vendor_Id={vendor_Id}
              onClose={() => closeFormValue()}
            />
          ) : null}
          {vendor_Id && worfkowCatagory == WorkFlowType.contractManagement && processName === CMWorflowPages?.RR ? (
            <ContractRolesandResponsibility
              onClose={() => {
                closeFormValue();
              }}
              isEditData={currentProcessInstance}
            />
          ) : null}
          {vendor_Id &&
            worfkowCatagory == WorkFlowType.contractManagement &&
            processName === CMWorflowPages?.CostApproval ? (
            <CostApproval
              onClose={() => {
                closeFormValue();
              }}
              formData={currentProcessInstance}
            />
          ) : null}
          {vendor_Id &&
            worfkowCatagory == WorkFlowType.contractManagement &&
            (processName === CMWorflowPages?.SMEReview ||
              processName === CMWorflowPages?.SOReview ||
              processName === CMWorflowPages?.DoReview ||
              processName === CMWorflowPages?.VendorPortal ||
              processName === CMWorflowPages?.SignOff) ? (
            <OnlyOfficeTextEditorCM
              isEditData={currentProcessInstance}
              onClose={() => {
                closeFormValue();
              }}
              processName={processName}
            />
          ) : null}
          {vendor_Id &&
            worfkowCatagory == WorkFlowType.contractManagement &&
            processName === CMWorflowPages?.VendorReview ? (
            <VendorReview
              isEditData={currentProcessInstance}
              onClose={() => {
                closeFormValue();
              }}
            />
          ) : null}
          {vendor_Id &&
            worfkowCatagory == WorkFlowType.contractManagement &&
            processName === CMWorflowPages?.BoardApproval ? (
            <BoardApproval
              isEditData={currentProcessInstance}
              onClose={() => {
                closeFormValue();
              }}
            />
          ) : null}
          {vendor_Id &&
            worfkowCatagory == WorkFlowType.contractManagement &&
            processName === CMWorflowPages?.PhysicalUpload ? (
            <PhysicalUpload isEditData={currentProcessInstance} onClose={() => closeFormValue()} />
          ) : null}
          {vendor_Id &&
            worfkowCatagory == WorkFlowType.contractManagement &&
            processName === CMWorflowPages?.NotifySigningAuthority ? (
            <NotifySigningAuthority
              isEditData={currentProcessInstance}
              onClose={() => {
                closeFormValue();
              }}
            />
          ) : null}

          {vendor_Id &&
            worfkowCatagory == WorkFlowType.PerformanceManagement &&
            processName === PMWorflowPages.initiateReview ? (
            <InitiateReview
              view={false}
              edit={false}
              isEditData={currentProcessInstance}
              onClose={() => {
                closeFormValue()
              }}
            />
          ) : null}


{vendor_Id &&
          worfkowCatagory == WorkFlowType.PerformanceManagement &&
          (processName == PMWorflowPages.SOReview || processName == PMWorflowPages.CXO) ? (
               <PMResponse  prePageName={"Pending Action"}   isEditData={currentProcessInstance} PageName={processName}  onClose={() => {
                closeFormValue() 
               }}/>
                    
          ) : null}  

        </Box>
      ) : null}
    </>
  );
};
