import React, { useEffect, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { textFieldWidth } from "../../../utils/constants";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import { useTheme } from "@mui/material/styles";
import CustomCheckBox from "../../../common/dynamicInputs/CustomCheckBox";
import Editor from "../../../common/editor/Editor";
import { useSelector } from "react-redux";
import { LovConfig } from "../../../config/config";
import { Controller, useForm } from "react-hook-form";
import { addorupdateSubClauses } from "../../../store/contractManagement/slices/clauseDataSlice";
import CustomMultiSelect from "../../../common/dynamicInputs/CustomMultiSelect";

export interface AddSubClauseForm {
  clause_Id?: number;
  clauseType_Id?: number;
  regulator_Id?: number;
  regulatory_Reference?: string;
  clause_Header: string;
  clause_Contract_Header: string;
  clause: string;
  service_Type_Id?: number;
  service_Group_Id?: number;
  department_Id?: number;
  contract_Template_id?: number;
  isActive?: boolean;
  actionTaken?: string;
  remarks?: string;
  templateMappings?: number[];
  serviceMappings?: number[];
  isNonNegotiableClause?: boolean;
  sla?: boolean;
  pm?: boolean;
  riskId: number;
  isSMEReviewRequired: boolean;
  isRiskApplicable: boolean;
  riskMappings: number[];
  clause_Order: number;
  isMandatory: boolean;
  hasSubClause: boolean;
  isSubClause: boolean;
  contract_Type_Id: number;
  systemVariableMappings: number[],
}
export interface AddSubCaluseProps {
  isadd?: boolean;
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  masterId?: string;
  onClose?: any;
  isDraftData?: boolean;
  ModuleId?: number | undefined;
  isDisableBreadCrums?: boolean;
  isDisableActionButton?: boolean;
  onSaveClause?: (savedClause: any) => void;
  isNonNegotiableClause: boolean;
  sla: boolean;
  pm: boolean;
  isRiskApplicable: boolean;
  isSMEReviewRequired: boolean;
  department_Id: number;
  riskId: number;
  riskMappings: number[];
  subClauses: any[];
  showSme:boolean;
}

interface serviceMapping {
  clause_ServiceMapping_Id: number;
  serviceCatalog_Id: number;
  isDeleted: boolean;
  isActive: boolean;
}

interface templateMapping {
  clause_CTMapping_Id: number;
  contract_Template_Id: number;
  isDeleted: boolean;
}

const SubClause: React.FC<AddSubCaluseProps> = ({
  isadd = false,
  view,
  isEditData,
  edit,
  masterId,
  onClose,
  isDraftData,
  ModuleId,
  isDisableBreadCrums,
  isDisableActionButton,
  onSaveClause,
  isNonNegotiableClause = false,
  sla = false,
  pm = false,
  isRiskApplicable = false,
  isSMEReviewRequired = false,
  department_Id = null,
  riskId = null,
  riskMappings = [],
  subClauses = [],
  showSme
}) => {
  const dispatch = useAppDispatchThunk();
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const theme = useTheme();
  const [editorContent, setEditorContent] = useState<any>();
  const [discard, setDiscard] = useState<boolean>(false);
  const { thirdPartyData } = useSelector((state: RootState) => state.thirdPartyData);
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const { templateList } = useSelector((state: any) => state.contractManagementReducer.templateData);
  const {
    clauseTypeList,
    isClauseDetailsAdded,
    clauseToasterStatus,
    isClauseDetailsUpdated,
    clauseToasterMsg,
    clauseData,
  }: any = contractManagementReducer.clauseData;
  const { isclauseMasterDetailsAdded }: any = contractManagementReducer.clauseMasterData;
  const { dataTable } = useSelector((state: RootState) => state.departmentData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [regulatorData, setRegulatorData] = useState<any[]>([]);
  const [depatmentDropdownData, setDepartmentDropDownData] = useState<any[]>([]);
  const [regulatorFileds, setRegulatorFields] = useState<boolean>(false);
  const [contractTemplates, setContractTemplates] = useState<any[]>([]);
  const [riskValues, setRiskDropValues] = useState<any[]>([]);
  const [riskCategory, setRiskCategory] = useState<any[]>([]);
  const [selectedClauseType, setSelectedClauseType] = useState<any>();
  const [updatedservicesMappings, setUpdatedServicesMappings] = useState<any>([]);
  const [updatedTemplates, setUpdatedtemplates] = useState<any>([]);
  const [isAddNewClauseType, setIsAddNewClauseType] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [enableAddSubClause, setEnableAddSubClause] = useState<boolean>(false);
  const [subClausesData, setSubClausesData] = useState<any>([]);
  const [enableSMERequired, setEnableSMERequired] = useState<boolean>(false);

  const closeForm = () => {
    if (onClose) {
      onClose();
    }
  };
  useEffect(() => {
    const filteredRegionalData = thirdPartyData.filter((item: any) => item.ThirdPartyTypeValue === LovConfig.Regulator);
    setRegulatorData(filteredRegionalData.filter((item: any) => item.IsActive === "Active"));
  }, [thirdPartyData]);

  useEffect(() => {
    const filteredDepartmentData = dataTable.filter((item: any) => item.Parent_LevelValue === "Level 1");
    setDepartmentDropDownData(filteredDepartmentData.filter((item: any) => item.IsActive === "Active"));
  }, [dataTable]);

  useEffect(() => {
    let filterTemplates = templateList.filter(
      (item: any) =>
        item.isActive === true &&
        item.contract_Template_Name !== null &&
        item.contract_Template_Name !== undefined &&
        item.contract_Template_Name !== ""
    );
    setContractTemplates([...filterTemplates]);
  }, [templateList]);

  useEffect(() => {
    setRiskDropValues(
      lovTable.filter((item: any) => item.Name === LovConfig?.RiskCategoryI && item.Options !== "N / A") || []
    );

    setRiskCategory(
      lovTable.filter((item: any) => item.Name === LovConfig?.RiskCategoryIII && item.Options !== "N / A") || []
    );
  }, [lovTable]);

  useEffect(() => {
    if(!edit){
      setEnableSMERequired(isSMEReviewRequired);
    }    
  }, [isSMEReviewRequired]);

  let clauseFormData: AddSubClauseForm = {
    clause_Id: isEditData ? isEditData?.clause_Id : 0,
    clauseType_Id: isEditData ? ((isEditData.clauseType && isEditData.clauseType.clauseType_Id)? isEditData?.clauseType?.clauseType_Id : 0 ): 0,
    regulator_Id: isEditData ? isEditData?.regulator?.thirdParty_Id : 0,
    regulatory_Reference: isEditData ? isEditData?.regulatory_Reference : "",
    clause_Header: isEditData ? isEditData?.clause_Header : "",
    clause_Contract_Header: isEditData ? isEditData?.clause_Contract_Header : "",
    clause: isEditData ? isEditData?.clause : "",
    service_Type_Id: 0,
    service_Group_Id: 0,
    department_Id: isadd
      ? department_Id
      : isEditData
      ? isEditData?.department_Id
        ? isEditData?.department_Id
        : isEditData?.department
        ? isEditData?.department?.departmentId
        : null
      : null,
    isActive: isEditData ? isEditData?.isActive : true,
    actionTaken: "",
    remarks: "",
    isNonNegotiableClause: isadd ? isNonNegotiableClause : isEditData ? isEditData?.isNonNegotiableClause : false,
    sla: isadd ? sla : isEditData ? isEditData?.sla : false,
    pm: isadd ? pm : isEditData ? isEditData?.pm : false,
    riskId: isadd
      ? riskId
      : isEditData
      ? isEditData?.riskId
        ? isEditData?.riskId
        : isEditData?.risk
        ? isEditData?.risk?.lov_Id
        : null
      : null,
    templateMappings: [],
    serviceMappings: [],
    isSMEReviewRequired: isadd ? (isSMEReviewRequired ? true : false) :(isEditData ? isEditData?.isSMEReviewRequired === true ? true : false : false),
    isRiskApplicable: isadd ? isRiskApplicable : isEditData ? isEditData?.isRiskApplicable : false,
    riskMappings: isadd
      ? riskMappings
      : isDraftData
      ? 0
      : isEditData
      ? isEditData?.riskMappings?.length > 0 && typeof isEditData?.riskMappings[0] === "object"
        ? isEditData?.riskMappings.map((data: any) => data.riskCategory.lov_Id)
        : isEditData?.riskMappings.length > 0
        ? isEditData?.riskMappings
        : []
      : [],
    clause_Order: isEditData ? isEditData?.clause_Order : null,
    isMandatory: isEditData ? isEditData?.isMandatory : false,
    hasSubClause: false,
    isSubClause: true,
    contract_Type_Id: 0,
    systemVariableMappings: isEditData ? (isEditData?.systemVariableMappings?.length>0  && typeof isEditData?.systemVariableMappings[0] === "object"?isEditData?.systemVariableMappings.map((syemId:any)=> syemId.systemVariableId): isEditData?.systemVariableMappings?.length>0 ? isEditData?.systemVariableMappings: [])  : [],
  };

  const {
    handleSubmit: handleClauseForm,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
    setError,
    clearErrors,
  } = useForm<AddSubClauseForm>({
    defaultValues: clauseFormData,
    mode: "onChange",
  });
  let parentSystemVariables =getValues("systemVariableMappings");
  const handleSaveAsDraft = () => {
    let formData = getValues();
    if (!showSme) {
      formData.department_Id = 0; 
      formData.isSMEReviewRequired = false; 
    }
    dispatch(addorupdateSubClauses(formData));
    // if (onClose) {
    //   onClose();
    // }
  };

  const updateServices = (updatedservices: any[]) => {
    setSelectedServices(updatedservices);
    // if(edit){
    //   for(let i = 0; i<)
    // }
    setValue("serviceMappings", updatedservices);
  };

  const updateEditorContent = (content: string) => {
    setValue("clause", content);
    setEditorContent(content);
  };

  const updateSystemVaraibles = (updatedsystemVaraibeles: number[]) =>{
    let uniqueValues:any= new Set([...updatedsystemVaraibeles]);
    setValue("systemVariableMappings",[...uniqueValues])
  }

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        rowGap: "15px",
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Controller
            name="clause_Header"
            control={control}
            defaultValue={""}
            rules={{
              required: "Sub Clause Header is required",
              validate: {
                noLeadingSpaces: (value) => !/^\s/.test(value ? value : "") || "Leading spaces are not allowed",
                maxLength: (value) => (value && value.length <= 100) || "Maximum 100 characters allowed",
                ValueLimitaion: (value) => {
                  const regex = /^[^0-9].*$/;
                  return regex.test(value) || "Clause Header cannot start with numbers";
                },
              },
            }}
            render={({ field }) => (
              <CustomTextField
                name={"clause_Header"}
                required={true}
                readOnly={view || !isActive}
                label={"Sub Clause Header"}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errorsloginform.clause_Header)}
                helperText={errorsloginform.clause_Header && errorsloginform.clause_Header.message?.toString()}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Controller
            name="clause_Order"
            control={control}
            rules={{
              required: true,
              validate: (orderNum) => {
                return (
                  (edit && isEditData?.clause_Order === orderNum) ||
                  subClauses.filter((data) => data?.clause_Order === orderNum).length === 0 ||
                  "Give Unique order number"
                );
              },
            }}
            render={({ field }) => (
              <CustomTextField
                type="number"
                name={"clause_Order"}
                required={true}
                readOnly={view || !isActive}
                label={"Order"}
                value={field.value}
                onChange={(val) => {
                  field.onChange(+val);
                }}
                error={Boolean(errorsloginform.clause_Order)}
                helperText={errorsloginform.clause_Order && errorsloginform.clause_Order.message?.toString()}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Controller
            name="clause_Contract_Header"
            control={control}
            defaultValue={""}
            rules={{
              required: false,
              validate: {
                noLeadingSpaces: (value) => !/^\s/.test(value ? value : "") || "Leading spaces are not allowed",
                maxLength: (value) => value.length <= 100 || "Maximum 100 characters allowed",
                ValueLimitaion: (value) => {
                  const regex = /^[0-9]/;
                  return regex.test(value) ? "Sub Clause Header for Contract cannot start with numbers" : true;
                },
              },
            }}
            render={({ field }) => (
              <CustomTextField
                name={"clause_Contract_Header"}
                required={false}
                readOnly={view || !isActive}
                label={"Sub Clause Header for Contract"}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errorsloginform.clause_Contract_Header)}
                helperText={
                  errorsloginform.clause_Contract_Header && errorsloginform.clause_Contract_Header.message?.toString()
                }
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={textFieldWidth.sm}

          // sm={textFieldWidth.sm}
          // md={textFieldWidth.md}
          // lg={textFieldWidth.lg}
          // xl={textFieldWidth.xl}
        >
          <Grid
            item
            xs={textFieldWidth.xs}

            // sm={textFieldWidth.sm}
            // md={textFieldWidth.md}
            // lg={textFieldWidth.lg}
            // xl={textFieldWidth.xl}
          >
            <Controller
              name="clause"
              control={control}
              defaultValue={""}
              rules={{
                required: false,
                // validate: {
                //   ValueLimitaion: (value) => value !== "<p></p>" || "Clause Details is required",
                // },
              }}
              render={({ field }) => (
                <Editor
                  updateEditorContent={updateEditorContent}
                  editorContent={field.value}
                  placeHolder={"Clause"}
                  view={view || !isActive}
                  error={Boolean(errorsloginform.clause)}
                  helperText={
                    errorsloginform.clause && errorsloginform.clause.message?.toString()
                      ? errorsloginform.clause.message?.toString()
                      : ""
                  }
                  showSystemVariable={true}
                  ediotrSystemVaraibles={parentSystemVariables}
                  updateSystemVaraibles={updateSystemVaraibles}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      {showSme ?<Grid container spacing={2}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "self-start",
              paddingTop: "10px",
            }}
          >
            <Controller
              name="isSMEReviewRequired"
              control={control}
              render={({ field }) => (
                <CustomCheckBox
                  isStyle={true}
                  key={"subisSMEReviewRequired"}
                  //readOnly={props.view}
                  alignItems="center"
                  label="Is SME Review Required"
                  onChange={(val) => {
                    setEnableSMERequired(val.target.checked);
                    if (val.target.checked === false) {
                      setValue("department_Id", 0);
                    }
                    field.onChange(val);
                  }}
                  value={field.value}
                  //withLabelStyle={true}
                  readOnly={view || !isActive}
                />
              )}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Controller
            name="department_Id"
            control={control}
            defaultValue={0}
            rules={{
              required: enableSMERequired ? "Department is required" : false,
            }}
            render={({ field }) => (
              <CustomSelect
                name={"department"}
                valueKey={"DepartmentId"}
                optionLabelKey={`DepartmentName`}
                options={depatmentDropdownData}
                label={"Select SME"}
                value={field.value}
                onChange={field.onChange}
                key={"subClauseDepartmentId"}
                error={Boolean(errorsloginform.department_Id)}
                helperText={errorsloginform.department_Id && errorsloginform.department_Id.message?.toString()}
                required={getValues("isSMEReviewRequired") === true ? true : false}
                readOnly={(getValues("isSMEReviewRequired")  === true ?  false : true) || view || !isActive}
              />
            )}
          />
        </Grid>

        {/* <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
        >
        <Controller
            name="templateMappings"
            control={control}
            defaultValue={[]}
            rules={{
            required: "Contract Template is required",
            }}
            render={({ field }) => (
            <CustomMultiSelect
                optionLabelKey={`contract_Template_Name`}
                optionIdKey={`contract_Template_Id`}
                options={contractTemplates}
                readOnly={view || !isActive}
                required={true}
                label={"Contract Template"}
                value={field.value}
                onChange={(field: any) => {
                setValue("templateMappings", field.target.value);
                if (field.target.value.length > 0) {
                    clearErrors("templateMappings");
                }
                // const dname = field.target.value.map(
                //   (id: number) => dataTable.find((data: any) => data.DepartmentId === id).contract_Template_Name
                // );
                // setValue("templateMappings", dname.toString());
                }}
                error={Boolean(errorsloginform.templateMappings)}
                helperText={
                errorsloginform.templateMappings && errorsloginform.templateMappings?.message?.toString()
                }
            />
            )}
        />
        </Grid> */}
        {/* <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
        sx={{ pt: "0" }}
        >
        <Box
            sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            }}
        >
            <Controller
            name="sla"
            control={control}
            render={({ field }) => (
                <CustomCheckBox
                isStyle={true}
                //readOnly={props.view}
                alignItems="center"
                label="Is a SLA Clause"
                onChange={field.onChange}
                value={field.value}
                //withLabelStyle={true}
                disabled={view || !isActive}
                />
            )}
            />
        </Box>
        </Grid>
        <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
        sx={{ pt: "0" }}
        >
        <Box
            sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            }}
        >
            <Controller
            name="pm"
            control={control}
            render={({ field }) => (
                <CustomCheckBox
                isStyle={true}
                //readOnly={props.view}
                alignItems="center"
                label="Is a PM Clause"
                onChange={field.onChange}
                value={field.value}
                //withLabelStyle={true}
                disabled={view || !isActive}
                />
            )}
            />
        </Box>
        </Grid>
        <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
        >
        <Box
            sx={{
            display: "flex",
            alignItems: "self-start",
            paddingTop: "10px",
            }}
        >
            <Controller
            name="isNonNegotiableClause"
            control={control}
            render={({ field }) => (
                <CustomCheckBox
                isStyle={true}
                //readOnly={props.view}
                alignItems="center"
                label="Is it a Non Negotiable Clause"
                onChange={field.onChange}
                value={field.value}
                //withLabelStyle={true}
                disabled={view || !isActive}
                />
            )}
            />
        </Box>
        </Grid> */}
      </Grid> : null}

      <Grid container spacing={2}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
          sx={{ pt: "0" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Controller
              name="sla"
              control={control}
              render={({ field }) => (
                <CustomCheckBox
                  isStyle={true}
                  //readOnly={props.view}
                  alignItems="center"
                  label="Is a SLA Clause"
                  onChange={field.onChange}
                  value={field.value}
                  //withLabelStyle={true}
                  readOnly={view || !isActive}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
          sx={{ pt: "0" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Controller
              name="pm"
              control={control}
              render={({ field }) => (
                <CustomCheckBox
                  isStyle={true}
                  //readOnly={props.view}
                  alignItems="center"
                  label="Is a PM Clause"
                  onChange={field.onChange}
                  value={field.value}
                  //withLabelStyle={true}
                  readOnly={view || !isActive}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "self-start",
              paddingTop: "10px",
            }}
          >
            <Controller
              name="isNonNegotiableClause"
              control={control}
              render={({ field }) => (
                <CustomCheckBox
                  isStyle={true}
                  //readOnly={props.view}
                  alignItems="center"
                  label="Is it a Non Negotiable Clause"
                  onChange={field.onChange}
                  value={field.value}
                  //withLabelStyle={true}
                  readOnly={view || !isActive}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "self-start",
              paddingTop: "10px",
            }}
          >
            <Controller
              name="isRiskApplicable"
              control={control}
              render={({ field }) => (
                <CustomCheckBox
                  isStyle={true}
                  //readOnly={props.view}
                  alignItems="center"
                  label="Is the Risk applicable"
                  onChange={(val) => {
                    if (val.target.checked === false) {
                      setValue("riskMappings", []);
                      setValue("riskId", 0);
                    }
                    field.onChange(val);
                  }}
                  value={field.value}
                  //withLabelStyle={true}
                  readOnly={view || !isActive}
                />
              )}
            />
          </Box>
        </Grid>

        {watch("isRiskApplicable") === true ? (
          <React.Fragment>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
              sx={{ pt: "0" }}
            >
              <Controller
                name="riskMappings"
                control={control}
                defaultValue={[]}
                rules={{
                  required: "Risk Applicable is required",
                }}
                render={({ field }) => (
                  <CustomMultiSelect
                    optionLabelKey={`Options`}
                    optionIdKey={`LovId`}
                    options={riskCategory}
                    readOnly={view || !isActive}
                    required={true}
                    label={"Risk Applicable"}
                    value={field.value}
                    onChange={(field: any) => {
                      setValue("riskMappings", field.target.value);
                      if (field.target.value.length > 0) {
                        clearErrors("riskMappings");
                      }
                      // const dname = field.target.value.map(
                      //   (id: number) => dataTable.find((data: any) => data.DepartmentId === id).contract_Template_Name
                      // );
                      // setValue("templateMappings", dname.toString());
                    }}
                    error={Boolean(errorsloginform.riskMappings)}
                    helperText={errorsloginform.riskMappings && errorsloginform.riskMappings?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
              sx={{ pt: "0" }}
            >
              <Controller
                name="riskId"
                control={control}
                defaultValue={0}
                rules={{
                  validate: {
                    riskRequired: (value) => {
                      return value === 0 ? "Risk Severity is required" : true;
                    },
                  },
                }}
                render={({ field }) => (
                  <CustomSelect
                    name={"riskId"}
                    valueKey={"LovId"}
                    optionLabelKey={`Options`}
                    options={riskValues}
                    label={"Risk"}
                    value={field.value}
                    onChange={field.onChange}
                    error={Boolean(errorsloginform.riskId)}
                    helperText={errorsloginform.riskId && errorsloginform.riskId.message?.toString()}
                    readOnly={view || !isActive}
                    required={true}
                  />
                )}
              />
            </Grid>
          </React.Fragment>
        ) : null}
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "self-start",
              paddingTop: "10px",
            }}
          >
            <Controller
              name="isMandatory"
              control={control}
              render={({ field }) => (
                <CustomCheckBox
                  isStyle={true}
                  //readOnly={props.view}
                  alignItems="center"
                  label="This is Mandatory"
                  onChange={field.onChange}
                  value={field.value}
                  //withLabelStyle={true}
                  readOnly={view || !isActive}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>

      <Stack
        direction="row"
        justifyContent="end"
        alignItems="center"
        spacing={2}
        py={2}
        visibility={isDisableActionButton ? "hidden" : "visible"}
      >
        <CustomButton
          variant="outlined"
          name={"Cancel"}
          onClick={() => {
            closeForm();
          }}
          size="small"
        />
        {/* <CustomButton
          variant="contained"
          name={"Save as Draft"}
          onClick={handleSaveAsDraft}
          disabled={true}
          size="small"
        /> */}
        {view || !isActive ? null : (
          <CustomButton
            variant="contained"
            name={edit ? "Update" : "Submit"}
            onClick={handleClauseForm(handleSaveAsDraft)}
            size="small"
          />
        )}
      </Stack>
    </Box>
  );
};

export default SubClause;
