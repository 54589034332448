import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import departmentService from "../services/departmentService";
import { IAddDepartmentResponse, IDepartmentResponse, IVHColumnRes, Idepartment } from "../../models/department.type";
import { chkDate } from "../../utils/constants";
export interface IDepartmentData {
  dataTable: any;
  departmentTable: any;
  parentTable: any;
  isLoading: boolean;
  isDepartmentAdded: boolean;
  isDepartmentUpdated: boolean;
  versionHistory: { departmentRecords: Idepartment[], departmentVHeader: IVHColumnRes[] };
  isDepartmentHistory: boolean;
  isDepartmentDeleted: boolean;
  ToasterMsg: any;
}

export const initialState: IDepartmentData = {
  dataTable: [],
  departmentTable: [],
  parentTable: [],
  isLoading: false,
  isDepartmentAdded: false,
  isDepartmentUpdated: false,
  versionHistory: { departmentRecords: [], departmentVHeader: [] },
  isDepartmentHistory: false,
  isDepartmentDeleted: false,
  ToasterMsg: { Message: "", Status: "" },
};

export const getDepartmentList = createAsyncThunk(
  "getDepartmentList",
  async (requesrParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await departmentService.getDepartmentList(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getParentDepartmentList = createAsyncThunk(
  "getParentDepartmentList",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await departmentService.getParentDepartmentList(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addDepartment = createAsyncThunk("addDepartment", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response: any = await departmentService.addDepartment(requesrParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateDepartment = createAsyncThunk(
  "updateDepartment",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await departmentService.updateDepartment(reqbody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteDepartment = createAsyncThunk(
  "deleteDepartment",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await departmentService.deleteDepartment(reqbody);
      return response.data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDepartmentHistory = createAsyncThunk("getDepartmentHistory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await departmentService.getVersionHistoryDepartment(reqbody);
    const { DepartmentHistories, HeaderJson } = response.data;
    return { DepartmentHistories: DepartmentHistories, HeaderJson: HeaderJson.ColumnHeaders }

  } catch (error) {
    return rejectWithValue(error);
  }
});

export const departmentDataSlice = createSlice({
  name: "departmentData",
  initialState,
  reducers: {
    updateDepartmentAddandEdit: (state, action) => {
      state.isDepartmentAdded = false;
      state.isDepartmentUpdated = false;
      state.isDepartmentDeleted = false;
    },
    updateUser: (state, action) => {
      if (action.payload.UserId) {
        let indexVendor = state.dataTable.findIndex((data: any) => data.UserId === action.payload.UserId);
        let copyVendorData = [...state.dataTable];
        copyVendorData[indexVendor] = action.payload;
        state.dataTable = copyVendorData;
      }
    },
    updateVersionHistory: (state, action) => {
      state.versionHistory.departmentRecords = [];
      state.versionHistory.departmentVHeader = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDepartmentList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDepartmentList.fulfilled, (state, action: PayloadAction<any>) => {
      
        let DepartmentData = action.payload.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          return user;
        });
        state.dataTable = DepartmentData;
        state.departmentTable = DepartmentData;
        state.isLoading = false;
      })
      .addCase(getDepartmentList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getParentDepartmentList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getParentDepartmentList.fulfilled, (state, action: PayloadAction<any>) => {
     
        state.parentTable = action.payload.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          return user;
        });
        state.isLoading = false;
      })
      .addCase(getParentDepartmentList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(addDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addDepartment.fulfilled, (state, action: PayloadAction<any>) => {
        // console.log(action.payload , 'action.payload1');
        //     let item = {...action.payload};
        //     let copyallUsers= [...state.dataTable];
        //     copyallUsers.push(item);
        //     state.dataTable = copyallUsers;
        state.isDepartmentAdded = true;
        state.isLoading = false;
        // state.ToasterMsg = action.payload.Message;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.IsSuccess;
      })
      .addCase(addDepartment.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(updateDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDepartment.fulfilled, (state, action: PayloadAction<any>) => {
        state.isDepartmentUpdated = true;
        state.isLoading = false;
        // state.ToasterMsg = action.payload.Message;
        // state.ToasterMsg = action.payload.Message;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.IsSuccess;
      })
      .addCase(updateDepartment.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(deleteDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDepartment.fulfilled, (state, action: PayloadAction<any>) => {
        state.isDepartmentDeleted = true;
        state.isLoading = false;
        // state.ToasterMsg = action.payload.Message;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.Status;
      })
      .addCase(deleteDepartment.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getDepartmentHistory.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getDepartmentHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.versionHistory.departmentVHeader = action.payload.HeaderJson
        state.versionHistory.departmentRecords = action.payload.DepartmentHistories.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.ApprovedOn = chkDate(user.ApprovedOn);

          return user;
        })
        state.isLoading = false;
        // state.ToasterMsg = "Department data deleted successfully";
      })
      .addCase(getDepartmentHistory.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })

  },
});
export const { updateDepartmentAddandEdit, updateUser, updateVersionHistory } = departmentDataSlice.actions;
export default departmentDataSlice.reducer;
