import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import CustomChip from "../../common/dynamicChip/CustomChip";
import { Grid } from "@mui/material";
import { textFieldWidth } from "../../utils/constants";


export interface ViewAssessmentHistoryProps {
  view?: boolean;
  closePopUp?: () => void;
  isEditData?: any;
}

export const ViewAssessmentHistory: React.FC<ViewAssessmentHistoryProps> = ({ view, closePopUp, isEditData }) => {

  const { ActiveServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);

  const serviceCatalogIds = isEditData?.ServiceName ? JSON.parse(isEditData?.ServiceName) : []

  const mergedUniqueIds = Array.from(new Set(serviceCatalogIds?.flat()));
  let FilterSelectedServiceData = ActiveServiceCatlogdata?.filter((item: any) =>
    mergedUniqueIds?.includes(item?.ServiceCatalogId)
  );

  return (
    <>

      <Grid container spacing={2}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sx={{
            justifyContent: "left",
            display: "flex",
            paddingTop: "18px",
            columnGap: "10px",
            flexWrap: "wrap",
            rowGap: "10px"
          }}
        >
          {ActiveServiceCatlogdata?.length > 0 &&
            FilterSelectedServiceData?.length > 0 ? (FilterSelectedServiceData?.map((filteredServiceItem: any) => {
              return (
                <CustomChip
                  label={`${filteredServiceItem?.ServiceName} - ${filteredServiceItem?.Summary}`}
                  variant="outlined"
                  color="primary"

                />
              );
            })) : ("No Services Available")
          }
        </Grid>
      </Grid>
    </>
  );
};