import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomBreadcrumbs from "../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import CustomStepper from "../../common/dynamicSteper/CustomStepper";
import React, { useEffect, useMemo, useState } from "react";
import CustomAccordion from "../../common/dynamicAccordion/CustomAccordion";
import CustomMultilineTextField from "../../common/dynamicInputs/CustomMultilineTextField";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { textFieldWidth } from "../../utils/constants";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomRadioButton from "../../common/dynamicInputs/CustomRadioButton";
import CustomButton from "../../common/dynamicButton/CustomButton";
import CustomMultiSelect from "../../common/dynamicInputs/CustomMultiSelect";
import { getLovList } from "../../store/slices/lovMasterData";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../store/store";
import CustomSvg from "../../common/CustomSvg";
import serviceStyle from "./serviceCatalog.module.scss";
import BasicTableStyle from "../../common/dynamicDataGrid/basicTable.module.scss";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { getDepartmentList } from "../../store/slices/departmentData";
import { addServiceCatalog, updateServiceCatalog } from "../../store/slices/serviceCatalog";
import CustomCheckBox from "../../common/dynamicInputs/CustomCheckBox";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";
import { updateLoader } from "../../store/slices/loader";
import {
  AQMConfig,
  DepartmentConfig,
  FormIdConfig,
  LovConfig,
  MetrixMasterConfig,
  serviceCriticality,
  ToasterActivityConfig,
} from "../../config/config";
import { addSaveandDraft, deleteSaveandDraft, updateSaveandDraft } from "../../store/slices/saveasdraft";
import { getRiskRateList } from "../../store/slices/riskRateMasterData";
import { getMetricsMasterList } from "../../store/slices/metricsMasters";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { CommonTooltip } from "../../common/CommonTooltip/CommonTooltip";
import { useTheme } from "@mui/material/styles";
import {
  getApplicationParameterList,
  getApplicationParameterHistory,
  updateAPAddandEdit,
  deleteApplicationParameter,
} from "../../store/slices/applicationParameterData";
import { ApplicationParameterName, Assessment_Type, LocationConfig } from "../../config/config";
import InactivityTimer from "../../common/InactivityTimer/InactivityTimer";
export interface ServiceCatalogProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  masterId?: string;
  onClose?: any;
  isDraftData?: any;
  ModuleId?: number | undefined;
}

interface FormData {
  ServiceCatalogId?: any;
  ServiceName?: any;
  ServiceDescription?: any;
  ServiceType?: any;
  ServiceGroupID?: any;
  Engage?: any;
  ServiceOwnerId?: any;
  SubDepartmentId?: any;
  Remarks?: any;
  IsActive?: any;
}
interface FormDataSecound {
  ApplicableRisk?: any[];
  RegulatoryRef?: any;
  Regulator?: any;
}

export const AddServiceCatalogMaster: React.FC<ServiceCatalogProps> = ({
  view,
  isEditData,
  edit,
  masterId,
  onClose,
  ModuleId,
  isDraftData,
}) => {
  const {
    ServiceCatlogdata,
    isServiceCatalogAdded,
    isServiceCatalogUpdated,
    isServiceCatlogLoading,
    isAddEditServiceCatlogLoading,
  } = useSelector((state: RootState) => state.serviceCatalog);
  const theme = useTheme();
  const { applicationParameterData, isAPAdded, isAPDeleted, isAPUpdated, versionHistory, apToasterMsg } = useSelector(
    (state: RootState) => state.applicationParameter
  );

  const [timeout, setTimeout] = useState<any>(null);
  const { thirdPartyData } = useSelector((state: RootState) => state.thirdPartyData);
  const { draftdata, isAddEditLoadingDraft, isDraftAdded, isDraftUpdated } = useSelector(
    (state: RootState) => state.saveAsDraft
  );
  const { metricsMasterData } = useSelector((state: RootState) => state.metricsMasters);
  const { RiskRateDataActive } = useSelector((state: RootState) => state.RiskRateData);
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const { dataTable } = useSelector((state: RootState) => state.departmentData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const dispatch = useAppDispatchThunk();
  const [Option, setOption] = useState(0);
   const [expanded, setExpanded] = useState<any>({ 0: true, 1: true, 2: true, 3: true});
  const [DepartmentData, setDepartmentData] = useState<any[]>([]);
  const [oldserviceCatalog, setoldserviceCatalog] = useState<any[]>([]);
  const [subDepartmentData, setSubDepartmentData] = useState<any[]>([]);
  const [service_TypeData, setservice_TypeData] = useState<any[]>([]);
  const [regulatorData, setRegulatorData] = useState<any[]>([]);
  const [service_GroupData, setservice_GroupData] = useState<any[]>([]);
  const [applicableRiskData, setApplicableRiskData] = useState<any[]>([]);
  const [selectedRisk, setSelectedRisk] = useState<any[]>([]);
  const [selectedRiskMaxofMax, setSelectedRiskMaxofMax] = useState<any[]>([]);
  const [riskTypelist, setRiskType] = useState<any[]>([]);
  const [closeVisible, setCloseVisible] = useState<any>(false);
  const [cloneData, setCloneData] = useState<any>(null);
  const [serviceCloneData, setserviceCloneData] = useState<any>([]);
  const [scoreMainMax, setscoreMainMax] = useState<any>(0);
  const [scoreMainSum, setscoreMainSum] = useState<any>(0);
  const [isEnableScoreEdit, setisEnableScoreEdit] = useState<any>(true);
  const [isRiskScoreEdited, setIsRiskScoreEdited] = useState<any>(false);
  const [selectedRiskMaxorSum, setselectedRiskMaxorSum] = useState<any>("MAX OF MAX");
  const [serviceCritical, setserviceCritical] = useState<any>("");
  const [openQuestionFroPara, setopenQuestionFroPara] = useState<any>(null);
  const [comFieldId, setComFieldId] = useState<any>(1);
  const [comFieldIdselect, setComFieldIdselect] = useState<any>([]);
  const [updatedDepartmentData, setUpdatedDepartmentData] = useState<any>([]);
  const [changeMaking, setchangeMaking] = useState<any>(false);
  const [onchangeMaking, setonchangeMaking] = useState<any>(true);
  const [serviceScoringList, setServiceScoringList] = useState<any[]>([]);
  //   --------risk table value ----------
  const [selectedTableRisk, setSelectedTableRisk] = useState<any[]>([]);
  const [RegulatoryMax, SetRegulatoryMax] = useState<any>(null);
  const [regulatoryMaxScoring, SetRegulatoryMaxScoring] = useState<any>(null);
  const [regulatoryCriticality, SetRegulatoryCriticality] = useState<any>(null);
  const [maxParameterCount, setMaxParameterCount] = useState<any>(null);

  useEffect(() => {
      if (edit) {
        const RecordTimeOut: any = ApplicationParameterName.RecordTimeOut;
        let findRecordTimeOut = applicationParameterData.find(
          (applicationParamitem) =>
            applicationParamitem.Parameter_Name === RecordTimeOut &&
            applicationParamitem.Parameter_Group === RecordTimeOut
        )?.Parameter_Value;
        setTimeout(Number(findRecordTimeOut));
      }
    }, []);
  

  useEffect(() => {
    dispatch(
      getApplicationParameterList({
        FormID: 2,
      })
    );
  }, []);
  useEffect(() => {
    dispatch(updateLoader(isAddEditServiceCatlogLoading || isAddEditLoadingDraft));
  }, [isAddEditServiceCatlogLoading, isAddEditLoadingDraft]);

  let formData = {
    ServiceCatalogId: isEditData ? isEditData?.ServiceCatalogId : isDraftData ? isDraftData.ServiceCatalogId : 0,
    ServiceName: isEditData ? isEditData?.ServiceName : isDraftData ? isDraftData.ServiceName : "",
    ServiceDescription: isEditData ? isEditData?.ServiceDescription : isDraftData ? isDraftData.ServiceDescription : "",
    ServiceType: isEditData ? isEditData?.ServiceType : isDraftData ? isDraftData.ServiceType : "",
    ServiceGroupID: isEditData ? isEditData?.ServiceGroupId : isDraftData ? isDraftData.ServiceGroupID : "",
    Engage: isEditData ? isEditData?.Engage : isDraftData ? isDraftData.Engage : "",
    ServiceOwnerId: isEditData ? isEditData?.ServiceOwnerId : isDraftData ? isDraftData.ServiceOwnerId : "",
    SubDepartmentId: isEditData ? isEditData?.SubDepartmentId : isDraftData ? isDraftData.SubDepartmentId : "",
    Remarks: isEditData ? isEditData?.Remarks : isDraftData ? isDraftData.Remarks : "",
    IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
  };

  let formDataSecound = {
    ApplicableRisk: isEditData
      ? isEditData?.ApplicableRisk
        ? JSON.parse(isEditData?.ApplicableRisk)
        : []
      : isDraftData
        ? isDraftData?.ApplicableRisk
          ? JSON.parse(isDraftData?.ApplicableRisk)
          : []
        : [],
    Regulator: isEditData
      ? isEditData?.Regulator
        ? isEditData?.Regulator
        : ""
      : isDraftData
        ? isDraftData?.Regulator
          ? isDraftData?.Regulator
          : ""
        : "",
    RegulatoryRef: isEditData
      ? isEditData?.RegulatoryRef
        ? isEditData?.RegulatoryRef
        : ""
      : isDraftData
        ? isDraftData?.RegulatoryRef
          ? isDraftData?.RegulatoryRef
          : ""
        : "",
  };

  const {
    handleSubmit: handleServiceCatalogForm,
    reset,
    setValue,
    formState: { errors: errorsServiceCatalogform, isDirty , isSubmitting  },
    control,
    trigger,
    watch,
    getValues,
    setError,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const {
    handleSubmit: handleServiceCatalogFormSecound,
    reset: reserSecound,
    setValue: setValueSecound,
    formState: { errors: errorsServiceCatalogformSecound, isDirty: isDirtySecound , isSubmitting : isSubmittingSecound },
    control: ControlSecound,
    watch: watchSecound,
    clearErrors: clearErrorsSecond,
    getValues: getValuesSecound,
  } = useForm<FormDataSecound>({
    defaultValues: formDataSecound,
    mode: "onChange",
  });

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
    dispatch(
      getDepartmentList({
        FormID: 2,
      })
    );
    dispatch(
      getRiskRateList({
        FormID: 2,
      })
    );
    dispatch(getMetricsMasterList({}));
  }, []);

  useEffect(() => {
    if (metricsMasterData) {
      let findedRiskType = metricsMasterData.filter(
        (risk_item) => risk_item?.Metrics === MetrixMasterConfig.AQ_Response_Type && risk_item?.IsActive === "Active"
      );
      setRiskType(findedRiskType || []);
      let findedServiceScoringType = metricsMasterData.filter(
        (risk_item) => risk_item?.Metrics === MetrixMasterConfig.Service_Scoringe && risk_item?.IsActive === "Active"
      );
      setServiceScoringList(findedServiceScoringType || []);
    }
  }, [metricsMasterData]);

  const isActiveDisabled = watch("IsActive");

  useEffect(() => {
    let paramScoreValidation : boolean =  ValidaionQuestionandParameter;
       if((isEmpty(errorsServiceCatalogformSecound) || paramScoreValidation) && isSubmittingSecound){
       
        setExpanded((prevExpanded: any[]) => ({ ...prevExpanded, 2 : true , 3 : true }));
       }
  },[errorsServiceCatalogformSecound , isSubmittingSecound])

  useEffect(() => {
    if(isEmpty(errorsServiceCatalogform) && isSubmitting){
     setExpanded((prevExpanded: any[]) => ({ ...prevExpanded, 0 : true , 1 : true  }));
    }
},[errorsServiceCatalogform , isSubmitting])
  useEffect(() => {
    if (view) {
      setisEnableScoreEdit(false);
    }
  }, [view]);

  const handleAccordionChange = (index: number) => {
    setExpanded((prevExpanded: any[]) => ({ ...prevExpanded, [index]: !prevExpanded[index] }));
  };

  const importParametersoldData = () => {
    if (cloneData) {
      let findImportOldValue = oldserviceCatalog.find((item) => item.ServiceCatalogId === cloneData);

      if (findImportOldValue.ServiceParams) {
        let colneUseData = parseJsonFields(findImportOldValue?.ServiceParams, true);

        let updatedState = [...colneUseData];
        updatedState = updatedState.map((item: any) => {
          const updatedMaxScore = selectedRisk.map((risk) => {
            const existingRisk = item.MaxScoring.find((qRisk: any) => qRisk.RiskCategory_Id === risk.RiskCategory_Id);
            return existingRisk
              ? { ...existingRisk }
              : { ...risk, Score: 0, RiskId: "", RiskCategory_Id: risk.RiskCategory_Id };
          });
          if (Array.isArray(item?.Questions)) {
            const updatedQuestions = item.Questions.map((question: any) => {
              const updatedRisks = selectedRisk.map((risk) => {
                const existingRisk = question.Risks.find(
                  (qRisk: any) => qRisk.RiskCategory_Id === risk.RiskCategory_Id
                );
                return existingRisk ? { ...existingRisk } : { ...risk, Score: 0, RiskId: "" };
              });
              return {
                ...question,
                Risks: updatedRisks,
              };
            });

            return {
              ...item,
              Questions: updatedQuestions,
            };
          }

          return {
            ...item,
            MaxScoring: updatedMaxScore,
          };

          // return item;
        });
        setSelectedTableRisk([...selectedTableRisk, ...updatedState]);

        synchronizeRisks();
      }
    }
    setCloseVisible(false);
  };

  useEffect(() => {
    setoldserviceCatalog(ServiceCatlogdata);
  }, [ServiceCatlogdata]);

  useEffect(() => {
    if (isEditData) {
      setselectedRiskMaxorSum(isEditData.ServiceCriticality);
      setserviceCritical(isEditData?.Summary);
    }

    if (isDraftData) {
      setselectedRiskMaxorSum(isDraftData.ServiceCriticality);
      setserviceCritical(isDraftData?.Summary);
    }
  }, [isEditData, isDraftData]);

  useEffect(() => {
    if (isEditData && isEditData?.ServiceParams) {
      setSelectedTableRisk(parseJsonFields(isEditData?.ServiceParams, false));
      setSelectedRiskvalueset(isEditData?.ApplicableRisk ? JSON.parse(isEditData?.ApplicableRisk) : []);
    }
  }, [isEditData, applicableRiskData]);

  useEffect(() => {
    if (isDraftData && isDraftData?.ServiceParams) {
      setSelectedTableRisk(parseJsonFields(isDraftData?.ServiceParams, false));
      setSelectedRiskvalueset(isDraftData?.ApplicableRisk ? JSON.parse(isDraftData?.ApplicableRisk) : []);
    }
  }, [applicableRiskData, isDraftData]);

  const parseRisks = (risks: any) => {
    if (typeof risks === "string") {
      try {
        return JSON.parse(risks);
      } catch (e) {
        console.error("Error parsing Risks:", e);
        return [];
      }
    }
    return risks;
  };

  const parseJsonFields = (data: any, clone: boolean) => {
    let localFieldId = comFieldId; // Use a local variable to track FieldId

    const parsedData = data.map((param: any) => {
      const newParam = { ...param };

      try {
        newParam.MaxScoring = parseRisks(param.MaxScoring);
        if (clone) {
          newParam.ParameterId = 0;
        }
      } catch (e) {
        console.error("Error parsing MaxScoring:", e);
        newParam.MaxScoring = [];
      }

      newParam.Questions = param?.Questions
        ? param?.Questions.map((question: any) => {
          const newQuestion = { ...question };

          try {
            newQuestion.Risks = parseRisks(question.Risks);
            newQuestion.FieldId = localFieldId;
            if (clone) {
              newQuestion.QuestionsRiskScoringId = 0;
            }
            localFieldId += 1;
          } catch (e) {
            console.error("Error parsing Risks:", e);
            newQuestion.Risks = [];
          }
          return newQuestion;
        })
        : [];
      newParam.FieldId = localFieldId;
      localFieldId += 1;

      return newParam;
    });
    setComFieldId(localFieldId);

    return parsedData;
  };

  useEffect(() => {
    if (isServiceCatalogUpdated || isServiceCatalogAdded) {
      // dispatch(updateLoader(false));
      if (isDraftData && isServiceCatalogAdded) {
        deleteDraftList();
      }
      onClose();
    }
  }, [isServiceCatalogAdded, isServiceCatalogUpdated]);

  useEffect(() => {
    if (isDraftAdded || isDraftUpdated) {
      // dispatch(updateLoader(false));

      onClose();
    }
  }, [isDraftAdded, isDraftUpdated]);

  const deleteDraftList = () => {
    let formData = {
      DraftId: isDraftData?.DraftId,
      CreatedBy: user?.UserName,
    };

    dispatch(deleteSaveandDraft(formData));
  };

  useEffect(() => {
    // setDepartmentData(dataTable.filter((item:any) => item.Parent_LevelValue == DepartmentConfig.Department_Level_1) || []);
    setDepartmentData(dataTable);
    setUpdatedDepartmentData(
      dataTable.filter((item: any) => item.Parent_LevelValue == DepartmentConfig.Department_Level_1) || []
    );
  }, [dataTable]);

  let serviceDepartmentValue = watch("ServiceOwnerId");
  useEffect(() => {
    if (serviceDepartmentValue) {
      let findSubValues = DepartmentData.filter((item) => item.ParentDepartmentId == serviceDepartmentValue);
      setSubDepartmentData(findSubValues);
    }
  }, [serviceDepartmentValue, DepartmentData]);
  useEffect(() => {
    if (RiskRateDataActive) {
      setApplicableRiskData(RiskRateDataActive || []);
    }
  }, [RiskRateDataActive]);

  useEffect(() => {
    setservice_TypeData(lovTable.filter((item: any) => item.Name === LovConfig?.ServiceType) || []);
    setservice_GroupData(lovTable.filter((item: any) => item.Name === LovConfig?.ServiceGroup) || []);
  }, [lovTable]);

  useEffect(() => {
    const filteredRegionalData = thirdPartyData.filter((item: any) => item.ThirdPartyTypeValue === LovConfig.Regulator);
    setRegulatorData(filteredRegionalData.filter((item: any) => item.IsActive === "Active"));
  }, [thirdPartyData]);

  const IsfindRequlatry = () => {
    let finedAppList = watchSecound("ApplicableRisk") || [];
    return applicableRiskData.some(
      (item) => finedAppList.includes(item.RiskCategory_Id) && item.RiskCategory === AQMConfig?.Regulatory
    );
  };

  const [isDataAdded, setIsDataAdded] = useState<boolean>(false);
  const [isDataQuestionAdded, setIsDataQuestionAdded] = useState<boolean>(false);
  const [isDataQuestionRiskAdded, setIsDataQuestionRiskAdded] = useState<boolean>(false);

  const addParameters = () => {
    setonchangeMaking(false);
    setIsRiskScoreEdited(false)
    let addedindex: any = selectedTableRisk.length;
    let localFieldId = comFieldId;
    let dubselectedRisk = [...selectedRisk];
    dubselectedRisk = dubselectedRisk.map((item: any) => {
      let newItem: any = {};
      newItem.RiskCategory_Id = item.RiskCategory_Id;
      newItem.Score = 0;
      newItem.RiskId = "";
      return newItem;
    });
    let paraObj = {
      IsActive: true,
      UpdatedBy: user?.UserName,
      ParameterId: 0,
      FieldId: localFieldId + 1,
      Parameter: "",
      Sum: 0,
      Max: 0,
      MaxScoring: [...dubselectedRisk],
    };
    localFieldId += 1;
    setComFieldId(localFieldId);
    setSelectedTableRisk((prevState) => [...prevState, paraObj]);

    setopenQuestionFroPara(addedindex);
    setIsDataAdded(true);
  };

  const deleteParameter = () => {
    setonchangeMaking(false);
    setIsRiskScoreEdited(false)
    setSelectedTableRisk((prevState) => {
      return prevState
        .filter((param) => !comFieldIdselect.includes(param.FieldId)) // Filter parameters
        .map((param) => {
          if (param.Questions) {
            return {
              ...param,
              Questions: param.Questions.filter((question: any) => !comFieldIdselect.includes(question.FieldId)), // Filter questions within each parameter
            };
          }
          return {
            ...param,
          };
        });
    });
    synchronizeRisks();
  };

  const addParametersQuestion = (index: any) => {
    setonchangeMaking(false);
    setIsRiskScoreEdited(false)
    let localFieldId = comFieldId;
    let dubselectedRisk = [...selectedRisk];
    dubselectedRisk = dubselectedRisk.map((item: any) => {
      let newItem: any = {};
      newItem.RiskCategory_Id = item.RiskCategory_Id;
      newItem.Score = 0;
      newItem.RiskId = "";
      return newItem;
    });
    let QuestionObj = {
      Question: "",
      QuestionsRiskScoringId: 0,
      FieldId: localFieldId + 1,
      UpdatedBy: user?.UserName,
      IsActive: true,
      Risks: [...dubselectedRisk],
    };
    localFieldId += 1;
    setComFieldId(localFieldId);
    setSelectedTableRisk((prevState) => {
      if (!Array.isArray(prevState)) {
        console.error("prevState is not an array", prevState);
        return prevState;
      }
      const newState = [...prevState];
      if (index >= 0 && index < newState.length) {
        if (!Array.isArray(newState[index]?.Questions)) {
          newState[index].Questions = [];
        }
        newState[index] = {
          ...newState[index],
          Questions: [...newState[index].Questions, QuestionObj],
        };
      } else {
        console.error("Index is out of bounds", index);
      }
      return newState;
    });
    // clearErrorsSecond()

    setIsDataQuestionAdded(true);
    setIsDataQuestionRiskAdded(true);
  };

  const [errorMsg, setErrorMsg] = useState("");
  const ParameteronChange = (value: any, index: any) => {
    setSelectedTableRisk((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        Parameter: value,
      };
      return newState;
    });

    // Validate parameter and update error message
    if (value.trim() === "") {
      setErrorMsg((prevErrors: any) => ({
        ...prevErrors,
        [index]: "Parameter field is required",
      }));
    } else {
      setErrorMsg((prevErrors: any) => ({
        ...prevErrors,
        [index]: "",
      }));
    }
  };

  const ParameterQuestiononChange = (value: any, index: number, Qindex: number) => {
    setonchangeMaking(false);
    setIsRiskScoreEdited(false)
    setSelectedTableRisk((prevState) => {
      const newState = [...prevState];
      const updatedQuestions = [...newState[index].Questions];
      updatedQuestions[Qindex] = { ...updatedQuestions[Qindex], Question: value };
      newState[index] = { ...newState[index], Questions: updatedQuestions };
      return newState;
    });
  };

  const ParameterQuestiononRiskChange = (value: any, index: number, Qindex: number, Rindex: number) => {
    setonchangeMaking(false);
    setIsRiskScoreEdited(false)
    setSelectedTableRisk((prevState) => {
      const newState = [...prevState];
      const updatedQuestions = [...newState[index].Questions];
      const riskToUpdate = updatedQuestions[Qindex].Risks[Rindex];

      let FindRisk = riskTypelist.find((ri_item) => ri_item.Metrics_Id == value);
      riskToUpdate.RiskId = value;

      riskToUpdate.Score = FindRisk && FindRisk.Range_From ? FindRisk.Range_From : 0;
      updatedQuestions[Qindex].Risks[Rindex] = riskToUpdate;
      newState[index].Questions = updatedQuestions;

      return newState;
    });
    synchronizeRisks();
  };

  const ClickedRowChecked = (fieldId: any) => {
    setComFieldIdselect((prevSelected: any) => {
      if (prevSelected.includes(fieldId)) {
        return prevSelected.filter((id: any) => id !== fieldId);
      } else {
        return [...prevSelected, fieldId];
      }
    });
  };

  const resetParameters = () => {
    if (isEditData) {
      setValueSecound(
        "ApplicableRisk",
        isEditData ? (isEditData?.ApplicableRisk ? JSON.parse(isEditData?.ApplicableRisk) : []) : []
      );

      setTimeout(() => {
        setSelectedTableRisk(parseJsonFields(isEditData?.ServiceParams, false));
        setSelectedRiskvalueset(isEditData?.ApplicableRisk ? JSON.parse(isEditData?.ApplicableRisk) : []);
      }, );
    } else if (isDraftData) {
      setValueSecound(
        "ApplicableRisk",
        isDraftData ? (isDraftData?.ApplicableRisk ? JSON.parse(isDraftData?.ApplicableRisk) : []) : []
      );

      setTimeout(() => {
        setSelectedTableRisk(parseJsonFields(isDraftData?.ServiceParams, false));
        setSelectedRiskvalueset(isDraftData?.ApplicableRisk ? JSON.parse(isDraftData?.ApplicableRisk) : []);
      }, );
    } else {
      setSelectedTableRisk([]);
      setSelectedRiskMaxofMax([]);
      setscoreMainMax(0);
      setscoreMainSum(0);
    }
  };

  const openQuestions = (index: any) => {
    setopenQuestionFroPara((prev: any) => (prev == index ? null : index));
  };
  const importParameters = (val: any) => {
    setCloseVisible(val);
  };

  const synchronizeRisks = () => {
    setSelectedTableRisk((prevState) => {
      if (!Array.isArray(prevState)) {
        console.error("prevState is not an array", prevState);
        return prevState;
      }

      let updatedState: any[] = [...prevState];

      updatedState = updatedState.map((item) => {
        const updatedMaxScore = selectedRisk.map((risk) => {
          const existingRisk = item.MaxScoring.find((qRisk: any) => qRisk.RiskCategory_Id === risk.RiskCategory_Id);
          return existingRisk
            ? { ...existingRisk }
            : { ...risk, Score: 0, RiskId: "", RiskCategory_Id: risk.RiskCategory_Id };
        });
        if (Array.isArray(item?.Questions)) {
          const updatedQuestions = item.Questions.map((question: any) => {
            const updatedRisks = selectedRisk.map((risk) => {
              const existingRisk = question.Risks.find((qRisk: any) => qRisk.RiskCategory_Id === risk.RiskCategory_Id);
              return existingRisk ? { ...existingRisk } : { ...risk, Score: 0, RiskId: "" };
            });
            return {
              ...question,
              Risks: updatedRisks,
            };
          });

          return {
            ...item,
            Questions: updatedQuestions,
          };
        }

        return {
          ...item,
          MaxScoring: updatedMaxScore,
        };

        // return item;
      });

      let MainSum = 0;
      let MainMax = 0;
      let dubselectedRisk = [...selectedRisk];
      dubselectedRisk = dubselectedRisk.map((item: any) => {
        let newItem: any = {};
        newItem.RiskCategory_Id = item.RiskCategory_Id;
        newItem.Score = 0;
        return newItem;
      });

      // First pass: accumulate scores for each LovId
      updatedState = updatedState.map((item) => {
        const scoreMap = new Map();
        if (Array.isArray(item?.Questions)) {
          item.Questions.forEach((question: any) => {
            question.Risks.forEach((risk: any) => {
              if (scoreMap.has(risk.RiskCategory_Id)) {
                const currentScore = scoreMap.get(risk.RiskCategory_Id);
                if (risk.Score > currentScore) {
                  scoreMap.set(risk.RiskCategory_Id, risk.Score);
                }
                // scoreMap.set(risk.LovId, scoreMap.get(risk.LovId) + risk.Score);
              } else {
                scoreMap.set(risk.RiskCategory_Id, risk.Score);
              }
            });
          });
        }
        const scoreArray = Array.from(scoreMap.entries()).map(([RiskCategory_Id, Score]) => ({
          RiskCategory_Id,
          Score,
        }));
        let max = 0;
        let sum = 0;
        scoreArray.map((maxitem, index) => {
          sum = sum + maxitem.Score;
          max = max < maxitem.Score ? maxitem.Score : max;
        });

        MainMax = MainMax + max;
        MainSum = 0;
        dubselectedRisk.map((lastScore) => {
          const maxScoreItem = scoreArray.find(
            (eachParamScore) => eachParamScore.RiskCategory_Id === lastScore.RiskCategory_Id
          );
          if (maxScoreItem && maxScoreItem.Score) {
            if (maxScoreItem.Score > lastScore.Score) {
              lastScore.Score = maxScoreItem.Score;
            }
          }
          //  sum = sum + maxitem.Score;
          MainSum = MainSum + lastScore.Score;

          return lastScore;
        });

        return {
          ...item,
          MaxScoring: scoreArray,
          Max: max,
          Sum: sum,
        };
      });

      setSelectedRiskMaxofMax(dubselectedRisk);
      setscoreMainSum(MainSum);
      setscoreMainMax(MainMax);

      return updatedState;
    });
  };

  const validateParameter = (item: any, index: any) => {
    console.log(item, index, "itemValue");
    const invalidCharactersPattern = /[^a-zA-Z0-9,. ]/;
    if (!item || item.trim() === "") {
      return `Invalid Parameter  cannot be empty or just spaces`;
    }
    if (invalidCharactersPattern.test(item)) {
      return `Invalid Parameter contains special characters`;
    }
    let allFields = selectedTableRisk || [];
    const allParameters = allFields.map((field) => field.Parameter);

    if (allParameters.filter((param) => param.trim() === item.trim()).length > 1) {
      return "Duplicate Parameter are not allowed.";
    }
    return null;
  };

  const validateParameters = (item: any) => {
    const invalidCharactersPattern = /[^a-zA-Z0-9,. ]/;
    // if (!item || item.trim() === "") {
    //   return `Invalid Parameter  cannot be empty or just spaces`;
    // }
    if (invalidCharactersPattern.test(item)) {
      return `Invalid Parameter contains special characters`;
    }
    let allFields = selectedTableRisk || [];
    const allParameters = allFields.map((field) => field.Parameter);

    if (allParameters.filter((param) => param.trim() === item.trim()).length > 1) {
      return "Duplicate Parameter are not allowed.";
    }
    return null;
  };
  const validateQuestion = (question: any, index: any) => {
    const invalidCharactersPattern = /[^a-zA-Z0-9,.?!@#$%&()+-_/ ]/;
    if (!question || question.trim() === "") {
      return `Invalid Question: cannot be empty or just spaces`;
    }
    if (invalidCharactersPattern.test(question)) {
      return `Invalid Question: contains special characters`;
    }

    let allFields = selectedTableRisk[index].Questions || [];
    const allParameters = allFields.map((field: any) => field.Question);

    if (allParameters.filter((param: any) => param.trim() === question.trim()).length > 1) {
      return "Duplicate Question are not allowed.";
    }
    return null;
  };

  const validateRisk = (risk: any) => {
    // Example validation for Risk. Adjust as necessary.
    if (!risk) {
      return `Invalid Risk: RiskId is Required`;
    }
    return null;
  };


  const toolTipData = (maxScore:any,criticality:any) => {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 1 }}>
          <Stack spacing={2}>
            {false && (
              <Box>
                Next to Service criticality display a small icon (information) to show the range for service criticality
              </Box>
            )}
            <Box sx={{ fontWeight: 600 }}>Service Criticality Range</Box>
            <Box>
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow sx={{ border: "2px solid #f1f1f1", borderBottom: "0px" }}>
                    <TableCell
                      sx={{
                        border: "2px solid #f1f1f1",
                        fontWeight: "bold",
                        width: "25%",
                        p: 1,
                        py: 0.5,
                        textAlign: "center",
                      }}
                    >
                      Range From
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "2px solid #f1f1f1",
                        fontWeight: "bold",
                        width: "25%",
                        p: 1,
                        py: 0.5,
                        textAlign: "center",
                      }}
                    >
                      Range To
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "2px solid #f1f1f1",
                        fontWeight: "bold",
                        width: "40%",
                        p: 1,
                        py: 0.5,
                        textAlign: "center",
                      }}
                    >
                      Rating
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <Box sx={{ maxHeight: 200, overflowY: "auto", width: "100%" }}>
                <Table sx={{ width: "100%" }}>
                  <TableBody sx={{ borderTop: "0px" }}>
                    {serviceScoringList &&
                      serviceScoringList.length > 0 &&
                      serviceScoringList.map((scoring, index) => (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              border: "2px solid #f1f1f1",
                              borderTop: index === 0 ? "0px" : "2px solid #f1f1f1",
                              width: "28.3%",
                              p: 1,
                              py: 0.5,
                              textAlign: "left",
                            }}
                          >
                            {scoring.Range_From}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "2px solid #f1f1f1",
                              borderTop: index === 0 ? "0px" : "2px solid #f1f1f1",
                              width: "28.4%",
                              p: 1,
                              py: 0.5,
                              textAlign: "left",
                            }}
                          >
                            {scoring.Range_To}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "2px solid #f1f1f1",
                              borderTop: index === 0 ? "0px" : "2px solid #f1f1f1",
                              p: 1,
                              py: 0.5,
                              textAlign: "left",
                            }}
                          >
                            {scoring.Rating}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Box>
            {`For any of the parameter if the rating for regulatory is ${maxScore} then by default the service  criticality will be ${criticality}`}
          </Stack>
        </Box>
      </>
    );
  };

  const validateQuestionScore = () => {
    if (selectedTableRisk && selectedTableRisk.length > 0) {
      const parameterSet = new Set();

      for (let index = 0; index < selectedTableRisk.length; index++) {
        const item = selectedTableRisk[index];
        let checkedParamater = validateParameter(item.Parameter, index);
        if (checkedParamater) {
          return checkedParamater;
        }

        if (item.Questions && item.Questions.length > 0) {
          const questionSet = new Set();
          for (let q_index = 0; q_index < item.Questions.length; q_index++) {
            const q_item = item.Questions[q_index];
            let checkedQuestion = validateQuestion(q_item.Question, index);
            if (checkedQuestion) {
              return checkedQuestion;
            }
            // const trimmedQuestion = q_item.Question.trim();
            // if (questionSet.has(trimmedQuestion)) {
            //   return `Duplicate Question at index:${index}, question_index:${q_index}`;
            // }
            // questionSet.add(trimmedQuestion);

            if (q_item.Risks && q_item.Risks.length > 0) {
              for (let r_index = 0; r_index < q_item.Risks.length; r_index++) {
                const r_item = q_item.Risks[r_index];
                let checkedRisk = validateRisk(r_item.RiskId);
                if (checkedRisk) {
                  return checkedRisk;
                }
              }
            } else {
              return `index:${index} Parameter, ${q_index} At least one risk is Required`;
            }
          }
        } else {
          return `index:${index} Parameter - at least one Question is Required`;
        }
      }
    } else {
      return "Need Atleast One Parameters";
    }

    return "";
  };

 

  const ValidaionQuestionandParameter = useMemo(() => {

        return  Boolean(validateQuestionScore() == "" ? false : true) 
  },[selectedTableRisk])
 
  useEffect(() => { }, [selectedRisk]);

  useEffect(() => { }, [selectedTableRisk]);

  const closeForm = (value: any) => {
    if (onClose && value === 0) {
      if (isDirtySecound || isDirty || !onchangeMaking) {
        setchangeMaking(true);
      } else {
        onClose();
      }
    }
  };
  useEffect(() => {
    synchronizeRisks();
  }, [selectedRisk]);

  const setSelectedRiskvalueset = (RiskIdvalue: any) => {
    if (applicableRiskData) {
      let findedList = applicableRiskData.filter((item) => RiskIdvalue.includes(item.RiskCategory_Id)) || [];

      setSelectedRisk(findedList || []);
      synchronizeRisks();
      // console.log(RiskIdvalue , 'RiskIdvalue' , findedList );
    }
  };

  const moveTonext = () => {
  
    setOption(1);
    
  };

  useEffect(() => {
    if(applicationParameterData?.length){
      const ServiceCatalogRegulatorCriticality: any =ApplicationParameterName.ServiceCatalogRegulatorCriticality;
      const ServiceCatalogGrpName: any =ApplicationParameterName.ServiceCatalogGrpName;
      let finedServiceCatalogRegulatorCriticality: any = applicationParameterData.find(
        (applicationParamitem) => applicationParamitem.Parameter_Name === ServiceCatalogRegulatorCriticality && applicationParamitem.Parameter_Group===ServiceCatalogGrpName
      )?.Parameter_Value;
      const ServiceCatalogRegulatorMaxScoring: any =ApplicationParameterName.ServiceCatalogRegulatorMaxScoring;
      let finedServiceCatalogRegulatorMaxScoring: any = applicationParameterData.find(
        (applicationParamitem) => applicationParamitem.Parameter_Name === ServiceCatalogRegulatorMaxScoring && applicationParamitem.Parameter_Group===ServiceCatalogGrpName
      )?.Parameter_Value;

      const ServiceCatalogMaxParameter: any = ApplicationParameterName.ServiceCatalogMaxParameter;
      let findServiceCatalogMaxParameter: any = applicationParameterData.find(
        (applicationParamitem) => applicationParamitem.Parameter_Name === ServiceCatalogMaxParameter && applicationParamitem.Parameter_Group===ServiceCatalogGrpName
      )?.Parameter_Value;
      SetRegulatoryMaxScoring(finedServiceCatalogRegulatorMaxScoring)
      SetRegulatoryCriticality(finedServiceCatalogRegulatorCriticality)
      setMaxParameterCount(findServiceCatalogMaxParameter)

    }
   }, [applicationParameterData]);

  
  const calculateMatrics = (sel: any) => {
    let isRegulatory=false
    let selectedMax = sel == "SUM OF MAX" ? scoreMainSum : scoreMainMax;

    let score_itemFind = serviceScoringList.find(
      (score_item) => score_item.Range_From <= selectedMax && score_item.Range_To >= selectedMax
    );
    // const ServiceCatalogRegulatorCriticality: any =ApplicationParameterName.ServiceCatalogRegulatorCriticality;
    // const ServiceCatalogGrpName: any =ApplicationParameterName.ServiceCatalogGrpName;
    // // let finedServiceCatalogRegulatorCriticality: any = applicationParameterData.find(
    // //   (applicationParamitem) => applicationParamitem.Parameter_Name === ServiceCatalogRegulatorCriticality && applicationParamitem.Parameter_Group===ServiceCatalogGrpName
    // // )?.Parameter_Value;
    // const ServiceCatalogRegulatorMaxScoring: any =ApplicationParameterName.ServiceCatalogRegulatorMaxScoring;
    // // let finedServiceCatalogRegulatorMaxScoring: any = applicationParameterData.find(
    // //   (applicationParamitem) => applicationParamitem.Parameter_Name === ServiceCatalogRegulatorMaxScoring && applicationParamitem.Parameter_Group===ServiceCatalogGrpName
    // // )?.Parameter_Value;
    selectedRisk.map((risk) => {
      if (risk.RiskCategory === ApplicationParameterName.applicableRiskForMaterial) {       
        const maxScoreItem = selectedRiskMaxofMax.find(
          (maxitem: any) => risk.RiskCategory_Id === maxitem.RiskCategory_Id
        )
        const MaxLastScore = maxScoreItem ? maxScoreItem.Score : 0;
        if (MaxLastScore == regulatoryMaxScoring) {
           isRegulatory=true
        }
      }
    });
  
    if(isRegulatory){
      setserviceCritical(regulatoryCriticality)
    }else if(score_itemFind && score_itemFind?.Rating){
      setserviceCritical(score_itemFind?.Rating);
    }else{
      setserviceCritical("");
    }
  };

  const moveToback = (val: any) => {
    if (val === 0) {
      if (isDirtySecound || isDirty || !onchangeMaking) {
        setchangeMaking(true);
      } else {
        onClose();
      }
    } else {
      setOption(0);
    }
  };

  const submitValidDataForm = () => {
    const currentDate = new Date();
    let form1 = getValues();
    let form2 = getValuesSecound();

    let formData = { ...form1, ...form2 };
    let formDataValue: any = formData;
    const selectedApplicableRiskData = applicableRiskData.filter((item)=>formDataValue.ApplicableRisk.includes(item.RiskCategory_Id))
    let JsonDataForDisplay: any = {
      "Service Name": formDataValue.ServiceName,
      "Service Type": service_TypeData.find((item) => item.LovId == formDataValue.ServiceType)?.Options || "",
      "Service Group": service_GroupData.find((item) => item.LovId == formDataValue.ServiceGroupID)?.Options || "",
      "Service Description": formDataValue.ServiceDescription,
      "Engage":formDataValue.Engage,
      "Service Owner":updatedDepartmentData.find((item: any) => item.DepartmentId == formDataValue.ServiceOwnerId)?.DepartmentName || "",
      "Sub Department" :subDepartmentData.find((item: any) => item.DepartmentId == formDataValue.SubDepartmentId)?.DepartmentName || '',
      "Remark" : formDataValue.Remarks,
      "ApproverComments": formDataValue.Remarks,
      "ApplicableRisk":selectedApplicableRiskData.map((item)=>item.RiskCategory).toString(),
      "Service Criticality": selectedRiskMaxorSum,
      "Summary": serviceCritical === "Non-Critical" ? "Low Critical" : serviceCritical,
      IsActive: "Active",
      "Modified By": user?.UserName,
      selectedTableRisk: selectedTableRisk,
      selectedRisk :selectedRisk,
      "Risk Scoring":'',
      SumOfMaxCritical : scoreMainSum.toString()
    };
    formDataValue.Form_Id =  FormIdConfig?.ServiceCatalogFormId;
    formDataValue.MenuId  = currentPagePermissionData?.MenuId
    if (edit && isEditData) {
      formDataValue.IsActive = true;
      formDataValue.Approved_By = user?.UserName;
      formDataValue.Updated_By = user?.UserName;
      formDataValue.Updated_On = currentDate;
      formDataValue.Approved_On = currentDate;
      formDataValue.Status = 1;
      formDataValue.ApproverComments = formDataValue.Remarks;
      formDataValue.Activity = ToasterActivityConfig.Edit;
      formDataValue.ModuleName = currentPagePermissionData?.MenuName;
      formDataValue.UserId = userLoginResponse?.UserId;
      formDataValue.Remark = formDataValue.Remarks;
      formDataValue.ApplicableRisk = JSON.stringify(formDataValue.ApplicableRisk);
      formDataValue.ServiceCriticality = selectedRiskMaxorSum;
      formDataValue.Summary = serviceCritical === "Non-Critical" ? "Low Critical" : serviceCritical;
      // formDataValue.Remark = selectedTableRisk;
      if (!IsfindRequlatry()) {
        formDataValue.Regulator = 0;
        formDataValue.RegulatoryRef = "";
      }
      formDataValue.ServiceParams = selectedTableRisk;
      formDataValue.SumOfMaxCritical = scoreMainSum.toString();
      formDataValue.MaxOfMaxCritical = scoreMainMax.toString();
      formDataValue.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      // dispatch(updateLoader(true));
      dispatch(updateServiceCatalog(formDataValue));
    } else { 
      formDataValue.IsActive = true;
      formDataValue.Approved_By = user?.UserName;
      formDataValue.Updated_By = user?.UserName;
      formDataValue.Updated_On = currentDate;
      formDataValue.Approved_On = currentDate;
      formDataValue.Status = 1;
      formDataValue.ApproverComments = formDataValue.Remarks;
      formDataValue.Activity = ToasterActivityConfig.create;
      formDataValue.ModuleName = currentPagePermissionData?.MenuName;
      formDataValue.UserId = userLoginResponse?.UserId;
      formDataValue.Remark = formDataValue.Remarks;
      formDataValue.ServiceCriticality = selectedRiskMaxorSum;
      formDataValue.Summary = serviceCritical === "Non-Critical" ? "Low Critical" : serviceCritical;

      formDataValue.ApplicableRisk = JSON.stringify(formDataValue.ApplicableRisk);
      formDataValue.ServiceParams = selectedTableRisk;
      formDataValue.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      if (!IsfindRequlatry()) {
        formDataValue.Regulator = 0;
        formDataValue.RegulatoryRef = "";
      }
      formDataValue.SumOfMaxCritical = scoreMainSum.toString();
      formDataValue.MaxOfMaxCritical = scoreMainMax.toString();

      // dispatch(updateLoader(true));
      dispatch(addServiceCatalog(formDataValue));
    }
  };

  const saveAsDraftServiceCall = () => {
    let form1 = getValues();
    let form2 = getValuesSecound();

    let formDatanew = { ...form1, ...form2 };
    let formDataValue: any = formDatanew;
    formDataValue.ApplicableRisk = JSON.stringify(formDataValue.ApplicableRisk);
    formDataValue.ServiceCriticality = selectedRiskMaxorSum;
    formDataValue.Summary = serviceCritical === "Non-Critical" ? "Low Critical" : serviceCritical;
    formDataValue.Remark = formDataValue.Remarks;
    formDataValue.ServiceParams = selectedTableRisk;

    let optionDepart =
      updatedDepartmentData.find((item: any) => item.DepartmentId == formDataValue.ServiceOwnerId)?.DepartmentName ||
      "";
    let optiongroup = service_GroupData.find((item) => item.LovId == formDataValue.ServiceGroupID)?.Options || "";
    let optionsertype = service_TypeData.find((item) => item.LovId == formDataValue.ServiceType)?.Options || "";

    formDataValue.ServicetypeName = optionsertype;
    formDataValue.ServiceGroupName = optiongroup;
    formDataValue.ServiceOwnerName = optionDepart;
    formDataValue.Updated_By = user?.UserName;
    formDataValue.IsActive = "Active";

    const saveAsDraft: any = {
      UserId: userLoginResponse?.UserId,
      ModuleName: currentPagePermissionData?.MenuName,
      Activity: isDraftData ? ToasterActivityConfig.editDraft : ToasterActivityConfig.createDraft,
      ScreenName: currentPagePermissionData?.MenuName,
      DraftData: JSON.stringify(formDataValue),
      DraftStatus: true,
      CreatedBy: user?.UserName,
    };
    if (isDraftData) {
      saveAsDraft.DraftId = isDraftData.DraftId;
      saveAsDraft.UpdatedBy = user?.UserName;
      dispatch(updateSaveandDraft(saveAsDraft));
    } else {
      dispatch(addSaveandDraft(saveAsDraft));
    }
  };

  const getCriticalityColor = (criticality: any) => {
    switch (criticality) {
      case serviceCriticality.material:
        return "#F44336"; // Red
      case serviceCriticality.low_critical:
        return "#4CAF50"; // Green
      case serviceCriticality.critical:
        return "#FFC107"; // Amber
      default:
        return "#939393";
    }
  };
  const criticalityColor = getCriticalityColor(serviceCritical);

  const isEmpty = (obj: any) => {
    return !(!obj || Object.keys(obj).length === 0);
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
          <CustomBreadcrumbs
            list={[
              { value: 0, name: "Service Catalog" },
              { value: 1, name: `${edit ? "Edit" : view ? "View" : "Add New"}` },
            ]}
            onchange={closeForm}
          />
        </Box>

        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {`${edit ? "Edit" : view ? "View" : "Add New"} Service`}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Box sx={{ width: "60%" }}>
              <CustomStepper activeStep={Option} steps={["Service Overview", "Risk Scoring"]} />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {Option === 0 ? `Service Overview` : "Risk Scoring"}
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <CustomButton
                type="reset"
                variant="outlined"
                name={"Previous"}
                onClick={() => {
                  moveToback(Option);
                }}
              />
              {!isEditData || isDraftData ? (
                <CustomButton
                  type="reset"
                  variant="contained"
                  name={"Save as Draft"}
                  disabled={!isDirtySecound && !isDirty && onchangeMaking}
                  onClick={() => {
                    saveAsDraftServiceCall();
                    
                  }}
                />
              ) : null}

              {Option == 0 ? (
                <CustomButton
                  type="reset"
                  variant="contained"
                  name={"Proceed to Next"}
                  onClick={handleServiceCatalogForm(moveTonext)}
                />
              ) : null}
              {Option == 1 && !view  ? (
                <CustomButton
                  type="reset"
                  variant="contained"
                  disabled={
                    ValidaionQuestionandParameter ||
                    (!isDirtySecound && !isDirty && onchangeMaking) ||
                    !isActiveDisabled ||
                    ( !onchangeMaking && !isRiskScoreEdited )
                  }
                  name={edit ? "Update":"Save"}
                  onClick={handleServiceCatalogFormSecound(submitValidDataForm)}
                />
              ) : null}
            </Box>
          </Box>

          {/* ----------------sec1---------- */}
          {Option === 0 ? (
            <>
              <CustomAccordion
                expanded={expanded[0]}
                handleChange={() => handleAccordionChange(0)}
                title="Service Details"
                details={
                  <>
                    <Box component="form" noValidate sx={{ mt: 1, width: "50%" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Controller
                            name="ServiceName"
                            control={control}
                            defaultValue={""}
                            rules={{
                              required: "Service Name is Required",
                              validate: {
                                maxLength: (value) => {
                                  if (value === undefined || value.trim() === "") return "Service Name is Required";
                                  return value.length <= 100 || "Maximum 100 characters allowed";
                                },
                              },
                            }}
                            render={({ field }) => (
                              <CustomTextField
                                name={"Service Name"}
                                required={true}
                                readOnly={view || !isActiveDisabled}
                                label={"Service Name"}
                                value={field.value}
                                onChange={field.onChange}
                                error={Boolean(errorsServiceCatalogform.ServiceName)}
                                helperText={
                                  errorsServiceCatalogform.ServiceName &&
                                  errorsServiceCatalogform.ServiceName.message?.toString()
                                }
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                          <Controller
                            name="ServiceType"
                            control={control}
                            defaultValue={""}
                            rules={{ required: "Service Type is Required" }}
                            render={({ field }) => (
                              <CustomSelect
                                name={"Service Type"}
                                valueKey={"LovId"}
                                optionLabelKey="Options"
                                options={service_TypeData}
                                readOnly={view || !isActiveDisabled}
                                required={true}
                                label={"Service Type"}
                                value={field.value}
                                onChange={field.onChange}
                                error={Boolean(errorsServiceCatalogform.ServiceType)}
                                helperText={
                                  errorsServiceCatalogform.ServiceType &&
                                  errorsServiceCatalogform.ServiceType.message?.toString()
                                }
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                          <Controller
                            name="ServiceGroupID"
                            control={control}
                            defaultValue={""}
                            rules={{ required: "Service Group is Required" }}
                            render={({ field }) => (
                              <CustomSelect
                                name={"Service Group"}
                                valueKey={"LovId"}
                                optionLabelKey="Options"
                                options={service_GroupData}
                                readOnly={view || !isActiveDisabled}
                                required={true}
                                label={"Service Group"}
                                value={field.value}
                                onChange={field.onChange}
                                error={Boolean(errorsServiceCatalogform.ServiceGroupID)}
                                helperText={
                                  errorsServiceCatalogform.ServiceGroupID &&
                                  errorsServiceCatalogform.ServiceGroupID.message?.toString()
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                          <Controller
                            name="Engage"
                            control={control}
                            defaultValue={""}
                            rules={{
                              required: "Engage is Required",
                            }}
                            render={({ field }) => (
                              <CustomRadioButton
                                direction={"row"}
                                list={[
                                  { value: "multiple", lable: "Multiple Third Party" },
                                  { value: "single", lable: "Single Third Party" },
                                ]}
                                label="Engage"
                                readOnly={view || !isActiveDisabled}
                                value={field.value}
                                onChange={field.onChange}
                                error={Boolean(errorsServiceCatalogform.Engage)}
                                helperText={
                                  errorsServiceCatalogform.Engage && errorsServiceCatalogform.Engage.message?.toString()
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                          <Controller
                            name="ServiceDescription"
                            control={control}
                            defaultValue={""}
                            rules={{
                              required: "Service Description is Required",
                              validate: {
                                maxLength: (value) => {
                                  if (value === undefined || value.trim() === "")
                                    return "Service Description is Required";
                                  return value.length <= 100 || "Maximum 100 characters allowed";
                                },
                              },
                            }}
                            render={({ field }) => (
                              <CustomMultilineTextField
                                label={"Service Description"}
                                value={field.value}
                                rows={4}
                                required={true}
                                readOnly={view || !isActiveDisabled}
                                onChange={field.onChange}
                                error={Boolean(errorsServiceCatalogform.ServiceDescription)}
                                helperText={
                                  errorsServiceCatalogform.ServiceDescription &&
                                  errorsServiceCatalogform.ServiceDescription.message?.toString()
                                }
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                }
              />
              <CustomAccordion
                expanded={expanded[1]}
                handleChange={() => handleAccordionChange(1)}
                title="Ownership Details"
                details={
                  <Box component="form" noValidate sx={{ mt: 1, width: "50%" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                        <Controller
                          name="ServiceOwnerId"
                          control={control}
                          defaultValue={""}
                          rules={{ required: "Service Owner is Required" }}
                          render={({ field }) => (
                            <CustomSelect
                              name={"Service Owner"}
                              valueKey={"DepartmentId"}
                              optionLabelKey="DepartmentName"
                              options={updatedDepartmentData}
                              readOnly={view || !isActiveDisabled}
                              required={true}
                              label={"Service Owner"}
                              value={field.value}
                              onChange={field.onChange}
                              error={Boolean(errorsServiceCatalogform.ServiceOwnerId)}
                              helperText={
                                errorsServiceCatalogform.ServiceOwnerId &&
                                errorsServiceCatalogform.ServiceOwnerId.message?.toString()
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                        <Controller
                          name="SubDepartmentId"
                          control={control}
                          defaultValue={""}
                          rules={{ required: "Sub Department is Required" }}
                          render={({ field }) => (
                            <CustomSelect
                              name={"Sub Department"}
                              valueKey={"DepartmentId"}
                              optionLabelKey="DepartmentName"
                              options={subDepartmentData}
                              readOnly={view || !isActiveDisabled}
                              required={true}
                              label={"Sub Department"}
                              value={field.value}
                              onChange={field.onChange}
                              error={Boolean(errorsServiceCatalogform.SubDepartmentId)}
                              helperText={
                                errorsServiceCatalogform.SubDepartmentId &&
                                errorsServiceCatalogform.SubDepartmentId.message?.toString()
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                        <Controller
                          name="Remarks"
                          control={control}
                          defaultValue={""}
                          rules={{
                            // required: "Remarks is Required",
                            validate: {
                              // noLeadingSpaces: (value: string | undefined) => {
                              //   if (value === undefined) return "Value is required";
                              //   return !/^\s/.test(value) || "Leading spaces are not allowed";
                              // },

                              maxLength: (value) => {
                                if (value === undefined) return "Value is Required";
                                return value.length <= 100 || "Maximum 100 characters allowed";
                              },
                            },
                          }}
                          render={({ field }) => (
                            <CustomMultilineTextField
                              label={"Remarks"}
                              value={field.value}
                              rows={4}
                              required={false}
                              readOnly={view || !isActiveDisabled}
                              onChange={field.onChange}
                              error={Boolean(errorsServiceCatalogform.Remarks)}
                              helperText={
                                errorsServiceCatalogform.Remarks && errorsServiceCatalogform.Remarks.message?.toString()
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                }
              />
            </>
          ) : null}

          {/* ----------------sec2---------- */}
          {Option === 1 ? (
            <>
              <CustomAccordion
                expanded={expanded[2]}
                handleChange={() => handleAccordionChange(2)}
                title="Applicable Risk"
                details={
                  <Box component="form" noValidate sx={{ width: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={textFieldWidth.xs}
                        sm={textFieldWidth.sm}
                        md={textFieldWidth.md}
                        lg={textFieldWidth.lg}
                        xl={textFieldWidth.xl}
                      >
                        <Controller
                          name="ApplicableRisk"
                          control={ControlSecound}
                          // defaultValue={[]}
                          rules={{
                            required: "Applicable Risk is Required",
                            validate: (data: any) => {
                              return data.length <= 6 || "Not Exceed more than 6 Applicable Risk ";
                            },
                          }}
                          render={({ field }) => (
                            <CustomMultiSelect
                              optionLabelKey="RiskCategory"
                              optionIdKey="RiskCategory_Id"
                              options={applicableRiskData}
                              required={true}
                              readOnly={view || !isActiveDisabled}
                              label={"Applicable Risk"}
                              value={field.value}
                              onChange={(RiskVal) => {
                                field.onChange(RiskVal.target.value);
                                setSelectedRiskvalueset(RiskVal.target.value);
                              }}
                              error={Boolean(errorsServiceCatalogformSecound.ApplicableRisk)}
                              helperText={
                                errorsServiceCatalogformSecound.ApplicableRisk &&
                                errorsServiceCatalogformSecound.ApplicableRisk.message?.toString()
                              }
                            />
                          )}
                        />
                      </Grid>
                      {IsfindRequlatry() ? (
                        <Grid
                          item
                          xs={textFieldWidth.xs}
                          sm={textFieldWidth.sm}
                          md={textFieldWidth.md}
                          lg={textFieldWidth.lg}
                          xl={textFieldWidth.xl}
                        >
                          <Controller
                            name="Regulator"
                            control={ControlSecound}
                            defaultValue={""}
                            rules={{ required: "Regulator is required" }}
                            render={({ field }) => (
                              <CustomSelect
                                name={"Regulator"}
                                valueKey={"ThirdPartyId"}
                                optionLabelKey="ThirdPartyName"
                                options={regulatorData}
                                readOnly={view || !isActiveDisabled}
                                required={true}
                                label={"Regulator"}
                                value={field.value}
                                onChange={field.onChange}
                                error={Boolean(errorsServiceCatalogformSecound.Regulator)}
                                helperText={
                                  errorsServiceCatalogformSecound.Regulator &&
                                  errorsServiceCatalogformSecound.Regulator.message?.toString()
                                }
                              />
                            )}
                          />
                        </Grid>
                      ) : null}

                      {IsfindRequlatry() ? (
                        <Grid
                          item
                          xs={textFieldWidth.xs}
                          sm={textFieldWidth.sm}
                          md={textFieldWidth.md}
                          lg={textFieldWidth.lg}
                          xl={textFieldWidth.xl}
                        >
                          <Controller
                            name="RegulatoryRef"
                            control={ControlSecound}
                            defaultValue={""}
                            rules={{
                              required: "Regulatory Reference is Required",
                              validate: {
                                maxLength: (value) => {
                                  if (value === undefined || value.trim() === "")
                                    return "Regulatory Reference is Required";
                                  return value.length <= 100 || "Maximum 100 characters allowed";
                                },
                              },
                            }}
                            render={({ field }) => (
                              <CustomTextField
                                name={"Regulatory Reference"}
                                required={true}
                                readOnly={view || !isActiveDisabled}
                                label={"Regulatory Reference"}
                                value={field.value}
                                onChange={field.onChange}
                                error={Boolean(errorsServiceCatalogformSecound.RegulatoryRef)}
                                helperText={
                                  errorsServiceCatalogformSecound.RegulatoryRef &&
                                  errorsServiceCatalogformSecound.RegulatoryRef.message?.toString()
                                }
                              />
                            )}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                }
              />
              <CustomAccordion
                expanded={expanded[3]}
                handleChange={() => handleAccordionChange(3)}
                title="Risk Scoring"
                details={
                  <Box component="form" noValidate sx={{ width: "100%" }}>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", gap: 2 }}>
                      {isEnableScoreEdit ? (
                        <CustomButton
                          type="reset"
                          variant="outlined"
                          name={"Reset"}
                          onClick={() => {
                            resetParameters();
                          }}
                        />
                      ) : null}
                      {isEnableScoreEdit ? (
                        <CustomButton
                          type="reset"
                          variant="outlined"
                          name={"Delete"}
                          disabled={!isActiveDisabled}
                          onClick={() => {
                            deleteParameter();
                          }}
                        />
                      ) : null}

                      {isEnableScoreEdit ? (
                        <CustomButton
                          type="reset"
                          variant="outlined"
                          name={"Import"}
                          disabled={!isActiveDisabled}
                          onClick={() => {
                            importParameters(true);
                          }}
                        />
                      ) : null}

                      {isEnableScoreEdit ? (
                        <CustomButton
                          type="reset"
                          variant="contained"
                          name={"Calculate"}
                          disabled={ValidaionQuestionandParameter}
                          onClick={() => {
                            setisEnableScoreEdit(false);
                            setIsRiskScoreEdited(true)
                            calculateMatrics(selectedRiskMaxorSum);
                          }}
                        />
                      ) : null}

                      {!isEnableScoreEdit && !view ? (
                        <CustomButton
                          type="reset"
                          variant="contained"
                          name={"Edit"}
                          onClick={() => {
                            setisEnableScoreEdit(true);
                          }}
                        />
                      ) : null}
                    </Box>

                    <Box className={BasicTableStyle.tab_outer} sx={{ width: "100%", mt: 2 }}>
                      <Table className={BasicTableStyle.tab_outer} sx={{ maxWidth: "100%" }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className={BasicTableStyle.tab_header} width={"15%"}>
                              Parameter
                            </TableCell>
                            {/* <TableCell className={BasicTableStyle.tab_header} width={'15%'} >Question</TableCell> */}
                            {selectedRisk.map((item) => (
                              <TableCell className={BasicTableStyle.tab_header} align="center">
                                {item.RiskCategory}
                              </TableCell>
                            ))}
                            {/* <TableCell className={BasicTableStyle.tab_header} align="center" width={"100px"}>
                              {" "}
                              SUM of MAX
                            </TableCell> */}
                            {/* <TableCell className={BasicTableStyle.tab_header} align="center" width={"100px"}>
                              {" "}
                              MAX of MAX
                            </TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedTableRisk.map((item, index) => {
                            
                            return (
                              <React.Fragment key={index}>
                                <TableRow>
                                  <TableCell
                                    sx={{ verticalAlign: "top" }}
                                    className={BasicTableStyle.tab_data}
                                    align={isEnableScoreEdit ? "center" : "left"}
                                    width={"25%"}
                                  >
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      {" "}
                                      <IconButton onClick={() => openQuestions(index)}>
                                        {openQuestionFroPara === index ? (
                                          <CustomSvg size={"20px"} name={"chevron-down"} />
                                        ) : (
                                          <CustomSvg size={"20px"} name={"chevron-right"} />
                                        )}
                                      </IconButton>
                                      <Box sx={{ ml: 1 }}>
                                        <CustomCheckBox
                                          value={comFieldIdselect.includes(item?.FieldId)}
                                          onChange={() => ClickedRowChecked(item?.FieldId)}
                                        />
                                      </Box>
                                      {/**Create a Controller */}
                                      {isEnableScoreEdit ? (
                                        <CustomTextField
                                          name={"Attribute Response"}
                                          required={true}
                                          Width={"90%"}
                                          disabled={view || !isActiveDisabled}
                                          label={"Parameter"}
                                          value={item.Parameter}
                                          onChange={(value) => {
                                            ParameteronChange(value, index);
                                            setIsDataAdded(false);
                                          }}
                                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                            if (
                                              !isDataAdded &&
                                              !Boolean(validateParameter(item.Parameter, index)) &&
                                              (e.key === "Enter" || e.key === "Tab")
                                            ) {
                                              e.preventDefault();
                                              openQuestions(index);
                                            }
                                          }}
                                          onBlur={() => {
                                            if (!isDataAdded && !Boolean(validateParameter(item.Parameter, index))) {
                                              openQuestions(index);
                                            }
                                          }}
                                          error={!isDataAdded && Boolean(validateParameter(item.Parameter, index))}
                                          helperText={(!isDataAdded && validateParameter(item.Parameter, index)) || ""}
                                        />
                                      ) : (
                                        item.Parameter
                                      )}
                                    </Box>
                                  </TableCell>

                                  {selectedRisk.map((R_item, R_index) => {
                                    const maxScoreItem = item.MaxScoring.find(
                                      (maxitem: any) => R_item.RiskCategory_Id === maxitem.RiskCategory_Id
                                    );
                                    const Score = maxScoreItem ? maxScoreItem.Score : 0;
                                    return (
                                      <TableCell
                                        className={BasicTableStyle.tab_data}
                                        key={`${index}-${"maxval"}-${R_index}`}
                                        align="center"
                                      >
                                        {Score}
                                      </TableCell>
                                    );
                                  })}
                                  {/* <TableCell className={BasicTableStyle.tab_data} align="center">
                                    {" "}
                                    {item.Sum}{" "}
                                  </TableCell> */}
                                  {/* <TableCell className={BasicTableStyle.tab_data} align="center">
                                    {" "}
                                    {item.Max}{" "}
                                  </TableCell> */}
                                </TableRow>
                                {item?.Questions &&
                                  item?.Questions.map((Q_item: any, qIndex: any) =>
                                    openQuestionFroPara == index ? (
                                      <TableRow
                                        className={serviceStyle.questionRow}
                                        key={`${index}-${qIndex}-question for service catalog`}
                                      >
                                        <TableCell
                                          className={BasicTableStyle.tab_data}
                                          align={isEnableScoreEdit ? "center" : "left"}
                                          width={"25%"}
                                          sx={{ paddingLeft: "40px !important", verticalAlign: "top" }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              paddingLeft: "15px !important",
                                            }}
                                          >
                                            <Box sx={{ ml: 1 }}>
                                              <CustomCheckBox
                                                value={comFieldIdselect.includes(Q_item?.FieldId)}
                                                onChange={() => ClickedRowChecked(Q_item?.FieldId)}
                                              />
                                            </Box>
                                            {isEnableScoreEdit ? (
                                              <CustomTextField
                                                name={"Question"}
                                                required={true}
                                                disabled={view || !isActiveDisabled}
                                                label={"Question"}
                                                value={Q_item.Question}
                                                onChange={(value) => {
                                                  ParameterQuestiononChange(value, index, qIndex);
                                                  setIsDataQuestionAdded(false);
                                                }}
                                                error={
                                                  !isDataQuestionAdded &&
                                                  Boolean(
                                                    validateQuestion(Q_item.Question, index) !== null ? true : false
                                                  )
                                                }
                                                helperText={
                                                  (!isDataQuestionAdded && validateQuestion(Q_item.Question, index)) ||
                                                  ""
                                                }
                                              />
                                            ) : (
                                              Q_item.Question
                                            )}
                                          </Box>
                                        </TableCell>
                                        {selectedRisk.map((R_item, R_index) => (
                                          <TableCell
                                            sx={{ verticalAlign: "top" }}
                                            className={BasicTableStyle.tab_data}
                                            key={`${index}-${qIndex}-${R_index}`}
                                            align="center"
                                          >
                                            {isEnableScoreEdit ? (
                                              <CustomSelect
                                                name="Risk value"
                                                valueKey={"Metrics_Id"}
                                                optionLabelKey="Rating"
                                                options={riskTypelist}
                                                required={true}
                                                disabled={view || !isActiveDisabled}
                                                label={""}
                                                value={Q_item.Risks[R_index]?.RiskId}
                                                onChange={(value) => {
                                                  ParameterQuestiononRiskChange(value, index, qIndex, R_index);
                                                  setIsDataQuestionRiskAdded(false);
                                                }}
                                                error={
                                                  !isDataQuestionRiskAdded &&
                                                  Boolean(
                                                    validateRisk(Q_item.Risks[R_index]?.RiskId) !== null ? true : false
                                                  )
                                                }
                                                helperText={
                                                  (!isDataQuestionRiskAdded &&
                                                    validateRisk(Q_item.Risks[R_index]?.RiskId)) ||
                                                  ""
                                                }
                                              />
                                            ) : (
                                              Q_item.Risks[R_index]?.Score
                                            )}
                                          </TableCell>
                                        ))}
                                        {/* <TableCell className={BasicTableStyle.tab_data} align="center"></TableCell> */}
                                        {/* <TableCell className={BasicTableStyle.tab_data} align="center"></TableCell> */}
                                      </TableRow>
                                    ) : null
                                  )}

                                {isEnableScoreEdit && openQuestionFroPara == index ? (
                                  <TableRow className={serviceStyle.questionRow}>
                                    <TableCell className={BasicTableStyle.tab_data} align="center">
                                      <CustomButton
                                        type="reset"
                                        variant="outlined"
                                        disabled={view || !isActiveDisabled}
                                        name={"Add Questions"}
                                        onClick={() => {
                                          addParametersQuestion(index);
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      colSpan={selectedRisk.length + 3}
                                      className={BasicTableStyle.tab_data}
                                      align="center"
                                    >
                                      {" "}
                                    </TableCell>
                                  </TableRow>
                                ) : null}
                              </React.Fragment>
                            );
                          })}
                          {isEnableScoreEdit ? (
                            <TableRow>
                              <TableCell className={BasicTableStyle.tab_data} align="center" width={"40px"}>
                                <CustomButton
                                  type="reset"
                                  variant="outlined"
                                  name={"Add Parameter"}
                                  disabled={(view || !isActiveDisabled) || selectedTableRisk.length == maxParameterCount}
                                  onClick={() => {
                                    addParameters();
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                colSpan={selectedRisk.length + 3}
                                className={BasicTableStyle.tab_data}
                                align="center"
                              >
                                {" "}
                              </TableCell>
                            </TableRow>
                          ) : null}

                          <TableRow>
                            <TableCell className={BasicTableStyle.tab_data}>MAX OF MAX</TableCell>

                            {selectedRisk.map((R_item, R_index) => {
                              const maxScoreItem = selectedRiskMaxofMax.find(
                                (maxitem: any) => R_item.RiskCategory_Id === maxitem.RiskCategory_Id
                              );

                              const MaxLastScore = maxScoreItem ? maxScoreItem.Score : 0;
                            
                              return (
                                <TableCell
                                  className={BasicTableStyle.tab_data}
                                  key={`${1}-${"maxval"}-${R_index}`}
                                  align="center"
                                >
                                  {MaxLastScore}
                                </TableCell>
                              );
                            })}
                            {/* <TableCell className={BasicTableStyle.tab_data} align="center" width={"40px"}>
                              {scoreMainSum}
                            </TableCell> */}
                            {/* <TableCell className={BasicTableStyle.tab_data} align="center" width={"40px"}>
                              {scoreMainMax}
                            </TableCell> */}
                          </TableRow>

                          {/* {!isEnableScoreEdit ?  */}
                            <TableRow>
                              <TableCell
                                // colSpan={selectedRisk.length + 1}
                                className={BasicTableStyle.tab_data}
                                align="center"
                              >
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Box>FINAL SCORE - SUM OF MAX</Box>
                                </Box>
                                { !isEnableScoreEdit ? 
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                  <Box>Service Criticality</Box>
                                  <Box sx={{ marginTop: "-4px" }}>
                                    <CommonTooltip
                                      arrow={true}
                                      placement={"top-start"}
                                      content={toolTipData(regulatoryMaxScoring,regulatoryCriticality)}
                                      width={"400px"}
                                    />
                                  </Box>
                                </Box> : null }
                              </TableCell>

                              {selectedRisk.map((item, index) => (
                                (index != selectedRisk.length -1) ?
                               (<TableCell  align="center">
                              </TableCell>) :
                              <TableCell
                              colSpan={selectedRisk.length + 1}
                              className={BasicTableStyle.tab_data}
                              align="center"
                              width={"40px"}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "25px"
                                }}
                              >
                                <Box>{scoreMainSum}</Box>
                              </Box>
                              { !isEnableScoreEdit ? 
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {/* <Box>
                                  <CustomRadioButton
                                    list={[
                                   //   { value: "SUM OF MAX", lable: "SUM OF MAX" },
                                      { value: "MAX OF MAX", lable: "MAX OF MAX" },
                                    ]}
                                    infoList={[
                                  //    { value: "Sum of all the parameter`s score", label: "SUM OF MAX" },
                                      { value: "Maximum of all the parameter`s score", label: "MAX OF MAX" },
                                    ]}
                                    arrow={true}
                                    placement="top-end"
                                    isInfo={true}
                                    style={{ marginRight: 0 }}
                                    label=""
                                    value={selectedRiskMaxorSum}
                                    onChange={(val) => {
                                      setselectedRiskMaxorSum(val);
                                      calculateMatrics(val);
                                      setonchangeMaking(false);
                                      setIsRiskScoreEdited(false)
                                    }}
                                  />
                                </Box> */}
                                <Box
                                  sx={{
                                    backgroundColor: criticalityColor,
                                    padding: "2px 8px",
                                    borderRadius: "16px",
                                    height: "24px",
                                    maxWidth: "110px",
                                    color: "white !important",
                                    mt: "-5px",
                                  }}
                                >
                                  {serviceCritical}
                                </Box>
                              </Box>
                              : null }
                            </TableCell>
                            ))}                              
                            </TableRow>
                            {/* : null} */}
                        </TableBody>
                      </Table>
                    </Box>
                  </Box>
                }
              />
            </>
          ) : null}

          <CustomDialog
            show={closeVisible}
            onHide={() => importParameters(false)}
            maxWidth={"sm"}
            header={"Import Risk scoring from existing service"}
            contentNode={
              <>
                <CustomSelect
                  name={"service Type"}
                  valueKey={"ServiceCatalogId"}
                  optionLabelKey="ServiceName"
                  options={oldserviceCatalog.filter((item) => item.ServiceCatalogId !== isEditData?.ServiceCatalogId)}
                  disabled={view || !isActiveDisabled}
                  required={true}
                  label={"Services"}
                  value={cloneData}
                  onChange={setCloneData}
                />
                <Box sx={{ width: "100%", mt: 2, display: "flex", justifyContent: "flex-end", gap: 2 }}>
                  <CustomButton
                    type="reset"
                    variant="outlined"
                    name={"Close"}
                    onClick={() => {
                      importParameters(false);
                    }}
                  />
                  <CustomButton
                    type="reset"
                    variant="contained"
                    name={"Import"}
                    onClick={() => {
                      importParametersoldData();
                    }}
                  />
                </Box>
              </>
            }
          />
         
        </Stack>
      </Box>

      <ModalPopUp
        onDeleteMethod={() => {
          if (onClose) {
            onClose();
          }
        }}
        isDelete={true}
        ActionName={"Close"}
        onHide={() => {
          setchangeMaking(false);
        }}
        show={changeMaking}
        header={"Confirm"}
        text={"Are you sure you want to close ?"}
      />
    </>
  );
};
