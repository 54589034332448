import { Box, Grid, Typography } from "@mui/material";
import { textFieldWidth } from "../../../utils/constants";
import LableandValuesPreview from "../../../common/CommonPreview/LableandValuesPreview";



interface SeverityOfTargetDetailsprops {
    headerTitle?: any;
    data: any;

}

export const SeverityOfTargetDetails: React.FC<SeverityOfTargetDetailsprops> = ({ headerTitle, data }) => {
    const SOTDetails = JSON.parse(data)

    const renderGridRow = (displayName: any, Value: any) => {


        return (
            <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
            >
                <LableandValuesPreview lable={displayName} Value={`${Value || '-'}`} />
            </Grid>
        );
    };

    return (

        <>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography
                    sx={{
                        height: "12px",
                        color: "#959595",
                        display: "block",
                        fontSize: "15px",
                        fontWeight: "bold",
                        textAlign: "left",
                    }}
                >
                    {headerTitle ? headerTitle : "SEVERITY OF TARGET"}
                </Typography>
            </Box>
            <Box sx={{ mt: 2, pl: 1 }}>
                <Grid container spacing={2}>
                {SOTDetails?.map((item: any, index: number) => (
                    renderGridRow(item?.label, item?.value || "-")
                ))}
                </Grid>
            </Box>

        </>
    )

}