import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import CustomButton from "../../../../../../common/dynamicButton/CustomButton";
import CustomMultilineTextField from "../../../../../../common/dynamicInputs/CustomMultilineTextField";
import CustomNumberField from "../../../../../../common/dynamicInputs/CustomNumberField";
import { getDateOrConvertSameTime, textFieldWidth } from "../../../../../../utils/constants";
import CustomSelect from "../../../../../../common/dynamicInputs/CustomSelect";
import CustomBreadcrumbs from "../../../../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import CustomTextField from "../../../../../../common/dynamicInputs/CustomTextField";
import CustomMultiSelect from "../../../../../../common/dynamicInputs/CustomMultiSelect";
import CustomCheckBox from "../../../../../../common/dynamicInputs/CustomCheckBox";
import CustomDatePicker from "../../../../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import CustomToogleinput from "../../../../../../common/dynamicInputs/CustomToogleInput";
import CustomDialog from "../../../../../../common/dynamicDialog/CustomDialog";
import { ReviewDates } from "./ReviewDates";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { LovConfig, PM_frequency } from "../../../../../../config/config";


interface monitoringDetailsprops {
  view?: boolean;
  edit?: boolean;
  isEditData?: any;
  add?: boolean;
}



export const MonitoringDetails: React.FC<monitoringDetailsprops> = ({ view , edit, isEditData , add }) => {
  
    const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
   const [reviewTypeList, setreviewTypeList] = useState<any>([{id:0,value : "One Time"}, {id:1,value : "Periodically"}]);
   const [FrequencyList, setFrequencyList] = useState<any>([]);
   const [ReviwTriggerList, setReviwTriggerListt] = useState<any>([]);
   const [IsreviewDates, setIsreviewDates] = useState<boolean>(false);

  const {
    handleSubmit: MonitoringPeriodHandleSubmit,
    formState: { errors: errorsMonitoringPeriodform, isDirty: MonitoringPeriodIsDirty },
    getValues: MonitoringPeriodGetValues,
    setValue: MonitoringPeriodSetValue,
    control,
    reset: MonitoringPeriodReset,
    trigger: MonitoringPeriodtrigger,
    watch : watchMonitoringPeriod
  } = useFormContext();
  
  useEffect(() => {
    setReviwTriggerListt(lovTable.filter((item: any) => item.Name === LovConfig?.pm_reviewtrigger) || []);
    setFrequencyList(lovTable.filter((item: any) => item.Name === LovConfig?.Frequency) || []);
  }, [lovTable]);

  type Frequency = "Monthly" | "Quarterly" | "HalfYearly" | "Yearly";

  function calculateDatesWithFrequency(
    startDate: string,
    baseEndDate: string,
    notifyBefore: number,
    escalationBefore: number,
    frequency: any
  ) {
    const results: any = [];
    
    let currentStartDate = dayjs(startDate);
    const finalEndDate = dayjs(baseEndDate);
  
    while (currentStartDate.isBefore(finalEndDate)) {
      let periodEndDate: dayjs.Dayjs;
      switch (frequency) {
        case PM_frequency.Monthly:
          periodEndDate = currentStartDate.add(1, "month").subtract(1, "day");
          break;
        case PM_frequency.Quarterly:
          periodEndDate = currentStartDate.add(3, "month").subtract(1, "day");
          break;
        case PM_frequency.Half_yearly:
          periodEndDate = currentStartDate.add(6, "month").subtract(1, "day");
          break;
        case PM_frequency.Yearly:
          periodEndDate = currentStartDate.add(1, "year").subtract(1, "day");
          break;
        default:
          throw new Error("Invalid frequency");
      }
  
      if (periodEndDate.isAfter(finalEndDate)) {
        periodEndDate = finalEndDate;
      }
  

      results.push({
        "review_StartDate": getDateOrConvertSameTime(new Date(currentStartDate.toDate()) , "start"),
        "review_EndDate": getDateOrConvertSameTime(new Date(periodEndDate.toDate())  , "end"),
        "notify_Date": getDateOrConvertSameTime(new Date(currentStartDate.subtract(notifyBefore, "days").toDate()) , "start"),
        "escalation_Date": getDateOrConvertSameTime(new Date(periodEndDate.subtract(escalationBefore, "days").toDate()) , "start")
    })
  
      currentStartDate = periodEndDate.add(1, "day");
    }
  
    return results;
  }


  function calculateOntime(   startDate: string,
    baseEndDate: string,
    notifyBefore: number,
    escalationBefore: number){
      let results: any = [];
    let currentStartDate = dayjs(startDate);
    const finalEndDate = dayjs(baseEndDate);
  
    if(currentStartDate.isBefore(finalEndDate)) {
     results = [{
        "review_StartDate": getDateOrConvertSameTime(new Date(currentStartDate.toDate()) , "start"),
        "review_EndDate": getDateOrConvertSameTime(new Date(finalEndDate.toDate()) , "end"),
        "notify_Date": getDateOrConvertSameTime(new Date(currentStartDate.subtract(notifyBefore, "days").toDate()) , "start"),
        "escalation_Date": getDateOrConvertSameTime(new Date(finalEndDate.subtract(escalationBefore, "days").toDate()) , "start"),
    }]
  }

  return results;
    }
    let reviewStartDate = watchMonitoringPeriod("review_Start_Date")
    let reviewEndDate = watchMonitoringPeriod("review_End_Date")
    let monitorStartDate = watchMonitoringPeriod("start_Date")
    let monitorEndDate = watchMonitoringPeriod("end_Date")

      useEffect(() => {
        if (reviewStartDate) {
          MonitoringPeriodtrigger("review_Start_Date");
        }
        if (reviewEndDate) {
          MonitoringPeriodtrigger("review_End_Date");
        }
        if (monitorStartDate) {
          MonitoringPeriodtrigger("start_Date");
        }
        if (monitorEndDate) {
          MonitoringPeriodtrigger("end_Date");
        }
        
      }, [reviewStartDate , reviewEndDate , monitorEndDate , monitorStartDate]);
  // useEffect(() => {
  //   const reviewType = watchMonitoringPeriod("review_Type");
  //   const frequency = watchMonitoringPeriod("frequency");
  //   const reviewStartDate = watchMonitoringPeriod("review_Start_Date");
  //   const reviewEndDate = watchMonitoringPeriod("review_End_Date");
  //   const reminderBefore = watchMonitoringPeriod("reminder_Before");
  //   const escalationBefore = watchMonitoringPeriod("escalation_Before");
  //   let frequency_value = FrequencyList?.find((fr_item : any) => fr_item?.LovId == frequency)?.Options || ""
  //       let val : any [] = []
  //   if (reviewType === 1 && (frequency_value && frequency && reviewStartDate && reviewEndDate && reminderBefore && escalationBefore)) {
  //       val = calculateDatesWithFrequency(reviewStartDate, reviewEndDate, reminderBefore, escalationBefore, frequency_value);
  //   }else if(reviewType === 0 && reviewStartDate && reviewEndDate && reminderBefore && escalationBefore){
  //     val = calculateOntime(reviewStartDate, reviewEndDate, reminderBefore, escalationBefore);
  //   }
  
  //   MonitoringPeriodSetValue("reviewDates" , val , frequency_value)
        
  // }, [
  //   watchMonitoringPeriod("review_Type"),
  //   watchMonitoringPeriod("frequency"),
  //   watchMonitoringPeriod("review_Start_Date"),
  //   watchMonitoringPeriod("review_End_Date"),
  //   watchMonitoringPeriod("reminder_Before"),
  //   watchMonitoringPeriod("escalation_Before"),
  //   FrequencyList
  // ]);


  const findandsetfrequencyName = (id: any) => {
        
    let value = FrequencyList?.find((item : any) => item.LovId == id)?.Options
    if(value){
      MonitoringPeriodSetValue("frequency_Name" , value)
    }
  
}

const findandsetreviewTriggerName = (id: any) => {
      
  let value = ReviwTriggerList?.find((item : any) => item.LovId == id)?.Options
  if(value){
    MonitoringPeriodSetValue("review_Trigger_Name" , value)
  }

}
  return (
    <>
       <Box>
        <Stack spacing={4}>
        <Box>
        <Grid container spacing={2}>
        <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
          <Controller
                    name="start_Date"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "Start Date is Required" }}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={ dayjs()}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"Monitoring Start Date"}
                        value={field.value ? dayjs(field.value) : null}
                        required={true}
                        readOnly={view}
                        onChange={field.onChange}
                        error={Boolean(errorsMonitoringPeriodform.start_Date)}
                        helperText={
                          errorsMonitoringPeriodform.start_Date &&
                          errorsMonitoringPeriodform.start_Date.message?.toString()
                        }
                      /> )}
                      />
                     </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
             <Controller
                    name="end_Date"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "End Date is Required" }}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={ dayjs()}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"Monitoring End Date"}
                        value={field.value ? dayjs(field.value) : null}
                        required={true}
                        readOnly={view}
                        onChange={field.onChange}
                        error={Boolean(errorsMonitoringPeriodform.end_Date)}
                        helperText={
                          errorsMonitoringPeriodform.end_Date &&
                          errorsMonitoringPeriodform.end_Date.message?.toString()
                        }
                      /> )}
                      />
          </Grid>
          </Grid>
          </Box>
          <Divider></Divider>
          <Box>
          <Grid container spacing={2}>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="review_Type"
              control={control}
              defaultValue={""}
              rules={{ required: "Review Type Required is required" }}
              render={({ field }) => (
                <CustomToogleinput
                  name={"review_Type"}
                  valueKey="id"
                  optionLabelKey="value"
                  options={reviewTypeList}
                  readOnly={view}
                  required={true}
                  positionSet={true}
                  label={"Review Type"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorsMonitoringPeriodform.review_Type)}
                  helperText={errorsMonitoringPeriodform.review_Type?.message?.toString()}
                />
              )}
            />
          </Grid>
         {watchMonitoringPeriod("review_Type") == 1 ? <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="frequency"
              control={control}
              defaultValue={""}
              rules={{ required: "Frequency is required" }}
              render={({ field }) => (
                <CustomSelect
                  name={"frequency"} valueKey={"LovId"}
                      optionLabelKey="Options"
                  options={FrequencyList}
                  readOnly={view}
                  required={true}
                  label={"Frequency"}
                  value={field.value}
                  onChange={(e) => {field.onChange(e); findandsetfrequencyName(e)}}
                  error={Boolean(errorsMonitoringPeriodform.frequency)}
                  helperText={errorsMonitoringPeriodform.frequency?.message?.toString()}
                />
              )}
            />
          </Grid> : null }
                     <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="review_Trigger"
              control={control}
              defaultValue={""}
              rules={{ required: "Review Trigger is required" }}
              render={({ field }) => (
                <CustomSelect
                  name={"review_Trigger"} valueKey={"LovId"}
                      optionLabelKey="Options"
                  options={ReviwTriggerList}
                  readOnly={view}
                  required={true}
                  label={"Review Trigger"}
                  value={field.value}
                  onChange={(e) => {field.onChange(e); findandsetreviewTriggerName(e)}}
                  error={Boolean(errorsMonitoringPeriodform.review_Trigger)}
                  helperText={errorsMonitoringPeriodform.review_Trigger?.message?.toString()}
                />
              )}
            />
          </Grid>
          </Grid>
          </Box>
          <Box>
          <Grid container spacing={2}>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
         <Controller
                    name="review_Start_Date"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "Review Start Date is Required"  ,
                    validate: {
                                            startDateValidation: (value) => {
                                              if (monitorStartDate)
                                                return (
                                                  dayjs(value) >= dayjs(monitorStartDate) ||
                                                  "Review Start Date cannot be lesser than Monitoring Start Date."
                                                );
                                            },
                                            dateValidation: (value) => {
                                              if (monitorEndDate)
                                                return (
                                                  dayjs(value) <= dayjs(monitorEndDate) ||
                                                  "Review Start Date cannot be grater than Monitoring End Date."
                                                );
                                            },
                                          }}}
                    
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={ dayjs()}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"Review Start Date"}
                        value={field.value ? dayjs(field.value) : null}
                        required={true}
                        readOnly={view}
                        onChange={field.onChange}
                        error={Boolean(errorsMonitoringPeriodform.review_Start_Date)}
                        helperText={
                          errorsMonitoringPeriodform.review_Start_Date &&
                          errorsMonitoringPeriodform.review_Start_Date.message?.toString()
                        }
                      /> )}
                      />
                     </Grid>
                     <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
                    name="review_End_Date"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "Review End Date is Required" , validate: {
                      startDateValidation: (value) => {
                        if (reviewStartDate)
                          return (
                            dayjs(value) >= dayjs(reviewStartDate) ||
                            "Review End Date cannot be less than  Review Start Date."
                          );
                      },
                      dateValidation: (value) => {
                        if (monitorEndDate)
                          return (
                            dayjs(value) <= dayjs(monitorEndDate) ||
                            "Review End Date cannot be grater than Monitoring End Date."
                          );
                          
                      },
                     
                    } }}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={ dayjs()}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"Review End Date"}
                        value={field.value ? dayjs(field.value) : null}
                        required={true}
                        readOnly={view}
                        onChange={field.onChange}
                        error={Boolean(errorsMonitoringPeriodform.review_End_Date)}
                        helperText={
                          errorsMonitoringPeriodform.review_End_Date &&
                          errorsMonitoringPeriodform.review_End_Date.message?.toString()
                        }
                      /> )}
                      />
      </Grid>
     
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
           <Controller
              name="reminder_Before"
              control={control}
              defaultValue={""}
              rules={{
                required: "Reminder Before (Days) is required",
              }}
              render={({ field }) => (
                <CustomNumberField
                  label={"Reminder Before (Days)"}
                  name={"reminder_Before"}
                  value={field.value}
                  isDecimal={true}
                  required={true}
                  onChange={field.onChange}
                  error={Boolean(errorsMonitoringPeriodform.reminder_Before)}
                  helperText={
                    errorsMonitoringPeriodform.reminder_Before && errorsMonitoringPeriodform.reminder_Before.message?.toString()
                  }
                />
              )}
            />
          </Grid>
          
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="escalation_Before"
              control={control}
              defaultValue={""}
              rules={{
                required: "Escalation Before (Days) is required",
              }}
              render={({ field }) => (
                <CustomNumberField
                  label={"Escalation Before (Days)"}
                  name={"escalation_Before"}
                  value={field.value}
                  isDecimal={true}
                  required={true}
                  onChange={field.onChange}
                  error={Boolean(errorsMonitoringPeriodform.escalation_Before)}
                  helperText={
                    errorsMonitoringPeriodform.escalation_Before && errorsMonitoringPeriodform.escalation_Before.message?.toString()
                  }
                />
              )}
            />
          </Grid>

        </Grid>
        </Box>
      
    <Divider></Divider>

<Box sx={{display:'flex' , alignItems :'center' , justifyContent :'end'}}>  <CustomButton
                type="reset"
                variant="outlined"
                name={"Preview Dates"}
                onClick={() => {
                  setIsreviewDates(true)
                }}
              /></Box>
      </Stack>
      </Box>

      <CustomDialog
                show={IsreviewDates}
                onHide={() => {
                  
                  setIsreviewDates(false);
                }}
                maxWidth={"lg"}
                minHeight={"50vh"}
                header={ `Review Dates`}
                contentNode={
                 <>
                     <ReviewDates closePopUp={() => setIsreviewDates(false)} add={true} isEditData={MonitoringPeriodGetValues()} />
                 </>
                }
            />
    </>
  );
};
