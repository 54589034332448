import React, { useEffect, useState } from "react";
import CustomAccordion from "../../../../common/dynamicAccordion/CustomAccordion";
import { Box, Typography } from "@mui/material";
import DynamicBasicTable from "../../../../common/dynamicbasicTable/DynamicBasicTable";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import CustomDialog from "../../../../common/dynamicDialog/CustomDialog";
import { AddressForm } from "./AddressForm";
import { SpocForm } from "./SpocForm";
import { EscalationForm } from "./EscalationForm";
import { RootState, useAppDispatchThunk } from "../../../../store/store";
import ModalPopUp from "../../../../common/modalComponent/ModalPopUp";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { LovConfig } from "../../../../config/config";

interface OtherDetailsProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  masterId?: string;
  onClose?: any;
  ModuleId?: number | undefined;
  isDraftData?: any;
  onFormSubmit?: any;
  onFormCancel?: any;
  isAqmResponse?: any;
}

export const OtherDetails: React.FC<OtherDetailsProps> = ({
  view,
  isEditData,
  edit,
  masterId,
  onClose,
  ModuleId,
  isDraftData,
  onFormSubmit,
  onFormCancel,
  isAqmResponse,
}) => {
  const dispatch = useAppDispatchThunk();
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [isNewSpoc, setIsNewSpoc] = useState(false);
  const [isNewEsc, setIsNewEsc] = useState(false);
  const [option, setOption] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const [IsEditData, setIsEditData] = useState<any>({});
  const [isEditOrViewAddress, setIsEditOrViewAddress] = useState(false);
  const [isEditOrViewSPOC, setIsEditOrViewSPOC] = useState(false);
  const [isEditOrViewEsc, setIsEditOrViewEsc] = useState(false);
  const [isDeleteAddress, setIsDeleteAddress] = useState<any>(null);
  const [isDeleteSPOC, setIsDeleteSPOC] = useState<any>(null);
  const [isDeleteEsc, setIsDeleteEsc] = useState<any>(null);
  const [expanded, setExpanded] = useState<any>(3);
  const [addressTypeOptions, setAddressTypeOptions] = useState<any[]>([]);
  const [addressTypeFilteredOptions, setAddressTypeFilteredOptions] = useState<any[]>([]);

  const [addressType, setAddressType] = useState<any[]>([]);
  const [statusIdLabelMapping, setStatusIdLabelMapping] = useState<any>({});
  const [lovActiveStatusId, setLovActiveStatusId] = useState<any[]>([]);

  const [Purpose, SetPurpose] = useState<any[]>([]);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);

  const AddressColumns = [{ Field: "AddressNumber", DisplayName: "Address No" },
  // { Field: "AddressType", DisplayName: "Address Type ID", DisplayValue: addressType },
  { Field: "Address_Type_Value", DisplayName: "Address Type", },
  { Field: "Address", DisplayName: "Address" },
  { Field: "Pin", DisplayName: "Pincode" },
    "City", "State", "Country"];
  const SpocColumns = [{ Field: "SpocNumber", DisplayName: "POC No" },
    //  { Field: "Purpose", DisplayName: "Purpose", DisplayValue: Purpose },
     { Field: "Purpose_Value", DisplayName: "Purpose",},
      "Name", "Designation", { Field: "ContactNumber", DisplayName: "Contact No" },
       { Field: "EmailId", DisplayName: "Email Id" }];
  const EscalationColumns = [{ Field: "EscalationLevel", DisplayName: "Esc Level" }, "Name", "Designation", { Field: "ContactNumber", DisplayName: "Contact No" }, { Field: "EmailId", DisplayName: "Email Id" }];

  const { control, formState: { errors }, clearErrors } = useFormContext()

  const { fields: addressFields, append: appendAddress, update: updateAddress, remove: removeAddress, replace: replaceAddress } = useFieldArray({
    control,
    name: "address",
    rules: {
      required: "Atleast one Address is required",
      validate: (i) => {
        let seen = new Set();
        let findRegiterAddress: any = addressTypeOptions.find(
          (item) => item.Options === "Registered Address"
        );

        let res = i.filter((currentObject: any) => currentObject.Status === lovActiveStatusId)
        console.log(i, lovActiveStatusId, findRegiterAddress, "Address");
        let RegisterAddressMandatory = i.filter((item: any) => item.Status === lovActiveStatusId && item.AddressType === findRegiterAddress.LovId)

        if (RegisterAddressMandatory?.length === 0) return "Registered Address is required"

        if (res.length === 0) return "Atleast one active Address is required"

        let hasDuplicates = i.some((currentObject: any) => {
          if (currentObject.Status === lovActiveStatusId)
            return seen.size === seen.add(currentObject.AddressType).size;
        });
        if (!hasDuplicates) {
          clearErrors('address')
          return undefined
        }
        else return 'Address type should be unique'
      }
    }
  });
  const { fields: spocsFields, append: appendSpocs, update: updateSpocs, remove: removeSpocs, replace: replaceSpocs } = useFieldArray({
    control,
    name: "spocs",
    rules: {
      required: "Atleast one Poc is required",
      validate: (i) => {
        let res = i.filter((currentObject: any) => currentObject.Status === lovActiveStatusId)
        if (res.length === 0) return "Atleast one active Poc is required"
      }

    }
  });

  const { fields: escalationsFields, append: appendEscalations, update: updateEscalations, remove: removeEscalations, replace: replaceEscalations } = useFieldArray({
    control,
    name: "escalations",
  });

  const getDisplayValue = (type: string) => {
    let res = lovTable.filter((item: any) => item.Name === type) || []
    return res.reduce((agg: any, i: any) => {
      return { ...agg, [i.LovId]: i['Options'] }
    }, {})
  }

  useEffect(() => {
    setAddressType(getDisplayValue("Address Type"));
    let res = lovTable.filter((item: any) => item.Name === "Address Type") || []
    setAddressTypeOptions(res);
    setAddressTypeFilteredOptions(res)
    SetPurpose(getDisplayValue("Purpose"));
    setStatusIdLabelMapping(getDisplayValue(LovConfig.Status))
    setLovActiveStatusId(
      lovTable.find((item: any) => item.Name === LovConfig.Status && item.Description === "Active").LovId || 0
    );
  }, [lovTable])

  useEffect(() => {
    let filtered = addressTypeOptions.filter((item) => {
      return !addressFields.find((item1: any) => item.LovId === item1.AddressType && statusIdLabelMapping[item1.Status] == 'Active')
    })
    setAddressTypeFilteredOptions(filtered)
  }, [addressFields, addressTypeOptions])

  const handleOpen = (data: any, option: string, index?: number) => {
    setOption(option);
    if (index || index === 0) setSelectedIndex(index)
    if (expanded === 3) {
      handleAddressOption(data, option, index);
    } else if (expanded === 4) {
      handleSpocOption(data, option, index);
    } else if (expanded === 5) {
      handleEscalationOption(data, option, index);
    }
  };

  const transformData = (data: any) => {
    return data.map((Addressitem: any) => {
      const AddressTypeID = Addressitem?.AddressType ? Addressitem?.AddressType : 0;
      const addressDatas = addressTypeOptions.find((item: any) => item?.LovId === AddressTypeID)?.Options;

      return {
        ...Addressitem,
        Address_Type_Value: addressDatas !== undefined ? addressDatas : "",
      };
    });
  };

  const transformSPOCData = (data: any) => {
    console.log(data,"Spocitem");
    
    return data.map((Spocitem: any) => {
      let PurposeLOV = lovTable.filter((item: any) => item.Name === "Purpose") || []
      const PurposeID = Spocitem?.Purpose ? Spocitem?.Purpose : 0;
      const SPOCDatas = PurposeLOV.find((item: any) => item?.LovId === PurposeID)?.Options;

      return {
        ...Spocitem,
        Purpose_Value: SPOCDatas !== undefined ? SPOCDatas : "",
      };
    });
  };

  function getKeyByValue(object: Record<string, string>, value: string) {
    return Object.keys(object).find(key => object[key] === value);
  }

  const handleAddressOption = (data: any, option: string, index?: number) => {
    setIsEditOrViewEsc(false);
    setIsEditOrViewSPOC(false);
    if (index || index === 0) {
      if (option === "edit" || option === "view") {
        setIsEditOrViewAddress(data);
        setIsNewAddress(false);
      } else if (option === "delete") {
        let updated = addressFields.reduce((acc: any, item: any, i: number) => {
          if (i != index) acc.push({ ...item, AddressNumber: i < index ? i + 1 : i })
          return acc
        }, [])
        replaceAddress(updated)
      }
      else if (option === "InActivate") {
        const id = getKeyByValue(statusIdLabelMapping, 'In Active')
        let obj = { ...data, Status: parseInt(id as string) }
        updateAddress(index, obj)
      }
      else if (option === "Activate") {
        const id = getKeyByValue(statusIdLabelMapping, 'Active')
        let obj = { ...data, Status: parseInt(id as string) }
        updateAddress(index, obj)
      }
    }
  };

  console.log(addressFields, "addressFields",statusIdLabelMapping,spocsFields,escalationsFields);


  const handleSpocOption = (data: any, option: string, index?: number) => {
    setIsEditOrViewAddress(false);
    setIsEditOrViewEsc(false);
    if (index || index === 0) {

      if (option === "edit" || option === "view") {
        setIsEditOrViewSPOC(data);
        setIsNewSpoc(false);
      } else if (option === "delete") {
        let updated = spocsFields.reduce((acc: any, item: any, i: number) => {
          if (i != index) acc.push({ ...item, SpocNumber: i < index ? i + 1 : i })
          return acc
        }, [])
        replaceSpocs(updated)
      }
      else if (option === "InActivate") {
        const id = getKeyByValue(statusIdLabelMapping, 'In Active')
        let obj = { ...data, Status: parseInt(id as string) }
        updateSpocs(index, obj)
      }
      else if (option === "Activate") {
        const id = getKeyByValue(statusIdLabelMapping, 'Active')
        let obj = { ...data, Status: parseInt(id as string) }
        updateSpocs(index, obj)
      }
    }
  };

  const handleEscalationOption = (data: any, option: string, index?: number) => {
    setIsEditOrViewAddress(false);
    setIsEditOrViewSPOC(false);
    if (index || index === 0) {

      if (option === "edit" || option === "view") {
        setIsEditOrViewEsc(data);
        setIsNewEsc(false);
      } else if (option === "delete") {
        let updated = escalationsFields.reduce((acc: any, item: any, i: number) => {
          if (i != index) acc.push({ ...item, EscalationLevel: i < index ? i + 1 : i })
          return acc
        }, [])
        replaceEscalations(updated)
      }
      else if (option === "InActivate") {
        const id = getKeyByValue(statusIdLabelMapping, 'In Active')
        let obj = { ...data, Status: parseInt(id as string) }
        updateEscalations(index, obj)
      }
      else if (option === "Activate") {
        const id = getKeyByValue(statusIdLabelMapping, 'Active')
        let obj = { ...data, Status: parseInt(id as string) }
        updateEscalations(index, obj)
      }
    }
  };
console.log(SpocColumns,"SpocColumns");

  return (
    <div>
      <CustomAccordion
        expanded={expanded === 3}
        handleChange={() => setExpanded((pre: any) => (pre === 3 ? null : 3))}
        title="Third Party Address"
        details={
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <CustomButton
                variant="outlined"
                onClick={() => {
                  setIsNewAddress(!isNewAddress);
                }}
                disabled={view || addressTypeFilteredOptions.length < 1}
                name="Add Address"
              />
            </Box>
            <Box component="form" noValidate sx={{ width: "100%" }}>
              <DynamicBasicTable
                columns={AddressColumns}
                rowData={transformData(addressFields)}
                style={{ border: "1px solid #f1f1f1" }}
                handleAction={handleOpen}
                isEdit={true}
                isDelete={view || edit}
                showDeleteButton={!view && !edit}
                disableAction={view}
                statusIdMapping={statusIdLabelMapping}
              />
            </Box>
          </Box>
        }
      />
      {errors.address?.root?.message && <Typography variant="subtitle1" sx={{ color: '#D70000', textAlign: 'left', fontSize: '15px' }} >
        {errors.address?.root?.message as any}
      </Typography>}
      <CustomAccordion
        expanded={expanded === 4}
        handleChange={() => setExpanded((pre: any) => (pre === 4 ? null : 4))}
        title="Third Party POC"
        details={
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <CustomButton
                variant="outlined"
                onClick={() => {
                  setIsNewSpoc(!isNewSpoc);
                }}
                name="Add Third Party POC"
                disabled={view}
              />
            </Box>

            <Box component="form" noValidate sx={{ width: "100%" }}>
              <DynamicBasicTable
                columns={SpocColumns}
                rowData={transformSPOCData(spocsFields)}
                style={{ border: "1px solid #f1f1f1" }}
                handleAction={handleOpen}
                isEdit={true}
                isDelete={view || edit}
                showDeleteButton={!view && !edit}
                disableAction={view}
                statusIdMapping={statusIdLabelMapping}
              />
            </Box>
          </Box>
        }
      />
      {errors.spocs?.root?.message && <Typography variant="subtitle1" sx={{ color: '#D70000', textAlign: 'left', fontSize: '15px' }}>
        {errors.spocs?.root?.message as any}
      </Typography>}
      <CustomAccordion
        expanded={expanded === 5}
        handleChange={() => setExpanded((pre: any) => (pre === 5 ? null : 5))}
        title="Third Party POC For Escalation"
        details={
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <CustomButton
                variant="outlined"
                onClick={() => {
                  setIsNewEsc(!isNewEsc);
                }}
                name="Add Escalation Level"
                disabled={view}
              />
            </Box>

            <Box component="form" noValidate sx={{ width: "100%" }}>
              <DynamicBasicTable
                columns={EscalationColumns}
                rowData={escalationsFields}
                handleAction={handleOpen}
                style={{ border: "1px solid #f1f1f1" }}
                isEdit={true}
                isDelete={view || edit}
                showDeleteButton={!view && !edit}
                disableAction={view}
                statusIdMapping={statusIdLabelMapping}
              />
            </Box>
          </Box>
        }
      />
      {/* New Address */}
      <CustomDialog
        show={isNewAddress}
        onHide={() => setIsNewAddress(false)}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={"Add Address"}
        contentNode={
          <AddressForm
            view={false}
            isAqmResponse={isAqmResponse}
            onClose={() => {
              setIsNewAddress(false);
            }}
            addressType={addressTypeFilteredOptions}
            addressTableValue={addressFields}
            onSubmit={(data) => {
              appendAddress({ ...data, AddressNumber: addressFields.length + 1, Status: lovActiveStatusId })
              setIsNewAddress(false);
            }}
          />
        }
      />
      {/*Edit || View Address */}
      <CustomDialog
        show={isEditOrViewAddress}
        onHide={() => setIsEditOrViewAddress(false)}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={option === "edit" ? "Edit Address" : "View Address"}
        contentNode={
          <>
            {option === "edit" && (
              <AddressForm
                view={false}
                isAqmResponse={isAqmResponse}
                onClose={() => {
                  setIsEditOrViewAddress(false);
                }}
                addressType={addressTypeOptions}
                isEditData={isEditOrViewAddress}
                addressTableValue={addressFields}
                onSubmit={(data) => {
                  if (selectedIndex || selectedIndex === 0) updateAddress(selectedIndex, data)
                  setIsEditOrViewAddress(false)
                }}
              />
            )}
          </>
        }
      />

      {/*New SPOC */}
      <CustomDialog
        show={isNewSpoc}
        onHide={() => setIsNewSpoc(false)}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={"Add Third Party POC"}
        contentNode={
          <SpocForm
            view={false}
            onClose={() => {
              setIsNewSpoc(false);
            }}
            onSubmit={(data) => {
              appendSpocs({ ...data, SpocNumber: spocsFields.length + 1, Status: lovActiveStatusId })
              setIsNewSpoc(false);
            }} />
        }
      />
      {/*Edit || View SPOC */}
      <CustomDialog
        show={isEditOrViewSPOC}
        onHide={() => setIsEditOrViewSPOC(false)}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={option === "edit" ? "Edit SPOC" : "View SPOC"}
        contentNode={
          <>
            {option === "edit" && (
              <SpocForm
                view={false}
                onClose={() => {
                  setIsEditOrViewSPOC(false);
                }}
                isEditData={isEditOrViewSPOC}
                onSubmit={(data) => {
                  if (selectedIndex || selectedIndex === 0) updateSpocs(selectedIndex, data)
                  setIsEditOrViewSPOC(false)
                }}
              />
            )}

          </>
        }
      />
      {/*New Escalation */}
      <CustomDialog
        show={isNewEsc}
        onHide={() => setIsNewEsc(false)}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={"Add Escalation Level"}
        contentNode={
          <EscalationForm
            view={false}
            onClose={() => {
              setIsNewEsc(false);
            }}
            onSubmit={(data) => {
              appendEscalations({ ...data, EscalationLevel: escalationsFields.length + 1, Status: lovActiveStatusId })
              setIsNewEsc(false);
            }}
          />
        }
      />
      {/*Edit || View Esc */}
      <CustomDialog
        show={isEditOrViewEsc}
        onHide={() => setIsEditOrViewEsc(false)}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={option === "edit" ? "Edit Escalation" : "View Escalation"}
        contentNode={
          <>
            {option === "edit" && (
              <EscalationForm
                view={false}
                onClose={() => {
                  setIsEditOrViewEsc(false);
                }}
                isEditData={isEditOrViewEsc}
                onSubmit={(data) => {
                  if (selectedIndex || selectedIndex === 0) updateEscalations(selectedIndex, data)
                  setIsEditOrViewEsc(false)
                }}
              />
            )}
          </>
        }
      />
    </div>
  );
};

export default OtherDetails;
