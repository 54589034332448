import { VOBWorflowPages } from "../../../config/config";


export const validateCOBField = (index: number, field: string, value: any, validationErrors: any) => {
    let errors: { [key: string]: string } = { ...validationErrors };
  
    switch (field) {
      case "Response_In_Option":
        if (!value || value <= 0) {
          errors[`${index}-Response_In_Option`] = "Response is required";
        } else {
          delete errors[`${index}-Response_In_Option`];
        }
        break;
      case "Response_In_Text":
        if (!value) {
          errors[`${index}-Response_In_Text`] = "Response is required";
        } else {
          if (value && value.length > 500) {
            errors[`${index}-Response_In_Text`] = "Maximum 500 characters are allowed";
          } else {
            delete errors[`${index}-Response_In_Text`];
          }
        }
        break;
      case "Attachment":
        if (!value) {
          errors[`${index}-Attachment`] = "Attachment is required";
        } else {
          delete errors[`${index}-Attachment`];
        }
        break;
        case "SO_ReviewStatus":
        if (!value) {
          errors[`${index}-SO_ReviewStatus`] = "Review Status is required";
        } else {
          delete errors[`${index}-SO_ReviewStatus`];
        }
        break;
      
      case "SO_Remarks":
        if (value && value.length > 250) {
          errors[`${index}-SO_Remarks`] = "Maximum 250 characters are allowed";
        } else {
          delete errors[`${index}-SO_Remarks`];
        }
        break;
      case "DO_Remarks":
        if (value && value.length > 250) {
          errors[`${index}-DO_Remarks`] = "Maximum 250 characters are allowed";
        } else {
          delete errors[`${index}-DO_Remarks`];
        }
        break;
      case "DO_ReviewStatus":
          if (!value) {
            errors[`${index}-DO_ReviewStatus`] = "Do Status is required";
          } else {
            delete errors[`${index}-DO_ReviewStatus`];
          }
          break;
      default:
        break;
    }
  
    // setvalidationErrors(errors);
    return errors;
  };



  export const validateEachCOBField = async (PageName: string, item: any): Promise<boolean> => {
    let isCompleted: boolean = false;
  
    // Determine the answer based on Answer_Type
    const answer: any = item?.Answer_Type
      ? item?.Options.find(
          (option: any) => option?.Assessment_Questionnaire_OptionId == item?.Response_In_Option
        )?.Attribute_Response
      : item?.Response_In_Text;
  
    // Check if evidence (attachment) is required and present
    const isEvidenceValid = item?.EvidenceRequired ? Boolean(item?.Attachment) : true;
  
    // Validation logic based on PageName
    switch (PageName) {
      case VOBWorflowPages?.SMEResponse:
        isCompleted = Boolean(answer && isEvidenceValid);
        break;
  
      case VOBWorflowPages?.SOReview:
        isCompleted = Boolean(answer && item?.SO_ReviewStatus !== 0 && isEvidenceValid);
        break;
  
      case VOBWorflowPages?.DoReview:
        isCompleted = Boolean(item?.DO_ReviewStatus !== 0);
        break;
  
      default:
        isCompleted = false; // For unknown PageName
        break;
    }
  
    return isCompleted;
  };
  
  export const validateALLCOBField = async (PageName: string, QuestionList: any): Promise<boolean> => {
    if (!QuestionList || QuestionList.length === 0) return false;
    const validationResults = await Promise.all(
      QuestionList.map((item: any) => validateEachCOBField(PageName, item))
    );
    return validationResults.every((isValid) => isValid);
  };

  export const possibletosendDoCOBField = async (QuestionList: any): Promise<boolean> => {
  
    if (!QuestionList || QuestionList.length === 0) return false;
    const results = await Promise.all(
      QuestionList.map(async (item: any) => {
        return item.IsDo === true;
      })
    );
    return results.includes(true);
  };

  
  export const checkStepStatus = async (
    steps: any
  ): Promise<Record<string, boolean>> => {
    const conditionsObj = {
      Do: false,
      Do_Status: false,
      SME: false,
    };
  
    if (!steps) return {};
  
    const conditions: Record<string, (item: any) => boolean> = {
      Do: (item) => item.FormName === VOBWorflowPages.DoReview,
      SME: (item) => item.FormName === VOBWorflowPages.SMEResponse,
      Do_Status: (item) =>
        item.FormName === VOBWorflowPages.DoReview && item.Status === "Completed",
    };
  
    return Object.fromEntries(
      Object.entries(conditionsObj).map(([key]) => {
        const condition = conditions[key];
        const actualValue = condition ? steps.some(condition) : false;
        return [key, actualValue];
      })
    );
  };
  