import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { WorkFlowHistorySteper } from "./WorkFlowHistorySteper";

import { Step, StepConnector, StepIconProps, StepLabel, Stepper, stepConnectorClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { CustomStatusStepper } from "../../common/dynamicSteper/CustomStatusStepper";
import { getWorkFlowHistoryList, updateWorkFlowHistoryView } from "../../store/slices/workFlowHistoryData";
import { VendorHistory } from "../vendorHistory/VendorHistory";

const columns: any = {
    table_name: "WorkFlow History",
    isAction: true,
    columns: [
        {
            Field: "Workflow_Name",
            FieldType: "string",
            DisplayName: "Workflow",
        },
        {
            Field: "Vendor_Name",
            FieldType: "string",
            DisplayName: "Vendor Name ",
        },
        // {
        //     Field: "Role",
        //     FieldType: "string",
        //     DisplayName: "Role",
        // },

        // {
        //     Field: "Assignedto",
        //     FieldType: "string",
        //     DisplayName: "Assigned to",
        // },
        {
            Field: "Workflow_Status",
            FieldType: "string",
            DisplayName: "Status ",
        },
        // {
        //     Field: "CurrentStatus",
        //     FieldType: "string",
        //     DisplayName: "Current Status",
        // },

        {
            Field: "Workflow_Start_Date",
            FieldType: "date",
            DisplayName: "Start Date",
        },

        {
            Field: "Workflow_End_Date",
            FieldType: "date",
            DisplayName: "End Date",
        },

        {
            Field: "TAT",
            FieldType: "string",
            DisplayName: "Estimated Time(In Days)",
        },


    ],
};

export const WorkFlowHistory: React.FC<any> = ({ }) => {
    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );
    const dispatch = useAppDispatch();
    const [HeaderColumn, setHeaderColumn] = useState(columns);
    const [IsHistoryOpen, setIsHistoryOpen] = useState(false);
    const [isRunData, setisRunData] = useState('');
    const [HeaderData, setHeaderData] = useState('');
    const [permssionPage, setPermissionForpage] = useState<any>();
    const [pendingActions, setpendingActions] = useState<any>();
    const [WorkFlowHistoryTableData, setWorkFlowHistoryTableData] = useState<any[]>([]);
    const { WorkFlowHistoryTable, WorkFlowHistoryViewData, ToasterMsg, isLoading } = useSelector(
        (state: RootState) => state.WorkFlowHistoryData
      );

      const { currentpageTitleData } = useSelector(
        (state: RootState) => state.FormTitleMasterData
      );

    useEffect(() => {
        dispatch(
            getWorkFlowHistoryList({
            FormID: 2,
          })
        );
      }, []);

      useEffect(() => {
       
        setWorkFlowHistoryTableData(WorkFlowHistoryTable);

      }, [WorkFlowHistoryTable]);

    useEffect(() => {
        if (currentPagePermissionData !== null) {
            setPermissionForpage(currentPagePermissionData);
        }
    }, [currentPagePermissionData]);

    const handleOpen = (data: any) => {
        console.log(data);
        setHeaderData(data?.Vendor_Name)
        setIsHistoryOpen(true)
        setisRunData(data)

    };
    return (
        <>
        {/* <Box>
        <VendorHistory isEditData={isRunData}/>
        
        </Box> */}
            <Box
                sx={{
                    width: "100%",
                }}
            >
                <Stack spacing={2}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                        }}
                    >
                            <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                            {/* Holiday Master */}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <MaterialDynamicGrid
                            data={columns}
                            rows={WorkFlowHistoryTableData}
                            handleAction={handleOpen}
                            enableZoomIcon={true}
                            disableselect={false}
                            showAdd={false}
                            showDelete={false}
                            showEdit={false}
                            showView={true}
                            showhistory={false}
                            showExports={true}
                            userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                            pageName="Work Flow History"
                        />
                    </Box>
                </Stack>
            </Box>

            <CustomDialog
                show={IsHistoryOpen}
                onHide={() => {
                    setIsHistoryOpen(false);
                    updateWorkFlowHistoryView(false);
                }}
                maxWidth={"lg"}
                header={HeaderData !== "" ? `${HeaderData} History` : "Work Flow History"}
                contentNode={
                    <>
                        {" "}
                        <WorkFlowHistorySteper
                            view={false}
                            isEditData={isRunData}
                            closePopUp={() => {
                                setIsHistoryOpen(false);
                                updateWorkFlowHistoryView(false);
                            }}

                        />

                    </>
                }
            />
            {/* <TestCustomStatusStepper /> */}
        </>
    );
};


// export default function TestCustomStatusStepper() {
//     const main_steps = ['R and R', 'Vendor Details', 'Department Master', 'Company Master', 'Regional Settings'];
//     // const error_steps = main_steps.reduce((acc, step, index) => {
//     //     if (['Department Master', 'Company Master'].includes(step)) {
//     //         acc.push(index);
//     //     }
//     //     return acc;
//     // }, [] as number[]);
//     const error_steps = [1,3, ]
//     const Complete_steps = [2, ]

//     console.log(error_steps, "error_steps");
    
//     return (
//         // <CustomStatusStepper activeStep={3} steps={main_steps} errorSteps={error_steps} CompleteSteps={Complete_steps} />
//         <CustomStatusStepper activeStep={3} steps={main_steps} errorSteps={error_steps} />
//     );
// }