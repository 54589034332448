import {
  Box,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import CustomButton from "../../../../common/dynamicButton/CustomButton";

import BasicTableStyle from "../../../../common/dynamicDataGrid/basicTable.module.scss";
import CustomCheckBox from "../../../../common/dynamicInputs/CustomCheckBox";
import CustomMultilineTextField from "../../../../common/dynamicInputs/CustomMultilineTextField";
import CustomDialog from "../../../../common/dynamicDialog/CustomDialog";
import { AddRequirement } from "./AddRequirement";
import { RequirementDetailContext } from "../ScopeDefinition";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { extractContent } from "../../../../utils/constants";

interface Requirementprops {
  view?: boolean;
  closePopUp?: any;
  edit?: boolean;
  isEditData?: any;
  add?: boolean;
}
export const Requirement: React.FC<Requirementprops> = ({ view, closePopUp, edit, isEditData , add }) => {
   const contractManagementReducer:any = useSelector((state: RootState) => state?.contractManagementReducer);
    const {
      clauseDetailsData, 
    }:any = contractManagementReducer.clauseData;
 
 const Requirementcontext = useContext(RequirementDetailContext);
 const {
  requirementList,
  setRequirementList,
  validationErrors,
  validateRequirement,
  isnorequirementwarning
} = Requirementcontext!;
  const [responseData, setResponseData] = useState<any>([]);
  const [errorRequirement, seterrorRequirement] = useState<any>({});
  const [addRequirement, setaddRequirement] = useState<any>(false);

useEffect(() => {
  seterrorRequirement(validationErrors)
  console.log(validationErrors , "errorRequirement");
  
},[validationErrors])

  useEffect(() => {
    if(requirementList){
      setResponseData(requirementList)
    }
  },[requirementList])

  const RequirementAdd = (val: any) => {

    if(requirementList && setRequirementList){
      let updatedList : any[]= [...requirementList];
      updatedList.push(val)
      setRequirementList(updatedList) ;
      setaddRequirement(false)
    }
  }



  const ChangeValueinRequirement = (value: any, index: any, field: string , ) => {
    if(requirementList && setRequirementList){
      setRequirementList((prevList: any[]) => {
        const updatedList = [...prevList];
        if (updatedList[index]) {
          updatedList[index] = {
            ...updatedList[index],
            [field]: value,
          };
          // if(field == ){

          // }
        }
        return updatedList;
      });
    }
  
  };
  const headers = useMemo(
    () => [
      {
        label: "S.NO",
        Field: "Sno",
        condition: true,
        render: (row: any, index: any) => (
          <Box
            sx={{
              height: "18px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {index + 1}
          </Box>
        ),
      },
      {
        label: "Clause Reference",
        Field: "clause_reference_no",
        condition: true,
        render: (row: any, index: number) => <span style={{ cursor: "pointer" }}>{row?.clause_reference_no}</span>,
      },
      { label: "Clause Header", Field: "clause_Header", condition: true, render: (row: any) => row?.clause_Header },
      {
        label: "Clause",
        Field: "clause_content",
        condition: true,
        render: (row: any) => row?.clause_content,
      },
      {
        label: "Exclude",
        Field: "exclude",
        render: (row: any, index: any) => (
          <>
            {" "}
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CustomCheckBox
                isStyle={true}
                alignItems="center"
                label=""
                disabled={view}
                onChange={(value : any) => ChangeValueinRequirement(value?.target.checked, index, "exclude" )}
                value={row.exclude || false}
              />
            </Box>{" "}
          </>
        ),
      },
      {
        label: "Reason For Exclusion",
        Field: "reason_For_Exclude",
        render: (row: any, index: any) => (
          <>
            {row.exclude ? (
              <>
                {" "}
                <CustomMultilineTextField
                  label={""}
                  value={row.reason_For_Exclude}
                  disabled={view}
                  onChange={(value : any) => ChangeValueinRequirement(value, index, "reason_For_Exclude" )}
                  onBlur={(e) => {
                    validateRequirement && validateRequirement()
                  }}
                  rows={1}
                  required={true}
                  
                  error={Boolean(errorRequirement?.[`${index}-reason_For_Exclude`])}
                  helperText={
                    errorRequirement?.[`${index}-reason_For_Exclude`] &&
                    errorRequirement?.[`${index}-reason_For_Exclude`]?.toString()
                  }
                />
                 
              </>
            ) : (
              <></>
            )}
          </>
        ),
      },
    ],
    [errorRequirement]
  );

 
  return (
    <>
    
    <Box>
      <Stack spacing={4}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography
            sx={{
              height: "12px",
              color: "#959595",
              display: "block",
              fontSize: "15px",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            {`REQUIREMENT (PM/SLA CLAUSES)`}
          </Typography>
        {!view ?  <CustomButton type="reset" variant="outlined" name={"Add Requirement"} onClick={() => {setaddRequirement(true);}} /> : null }
        </Box>

        <TableContainer style={{ border: "1px solid #f1f1f1", borderRadius: "5px", maxHeight: "70vh" }}>
          <Table stickyHeader aria-label="simple basic table">
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell key={index} className={`${BasicTableStyle.tab_header}`}>
                    {header.label} {/* Renders the label string */}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {requirementList && requirementList.length > 0 ? (
                requirementList.map((row: any, rowIndex: number) => (
                  <TableRow key={`Row_${rowIndex}`} className={BasicTableStyle.alternateRow}>
                    {headers.map((header, colIndex) => (
                      <TableCell key={`Cell_${rowIndex}_${colIndex}`} className={`${BasicTableStyle.tab_data}`}>
                        {header.render ? header.render(row, rowIndex) : null} {/* Safeguards invalid render */}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={headers.length} className={`${BasicTableStyle.tab_data}`} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>


        {requirementList && requirementList?.length == 0 ? <Box >
                                        <Typography
                                          sx={{ fontSize: "8px", textAlign: "left", color: isnorequirementwarning ? "red" : "#6A6A6A" }}
                                        >
                                         {isnorequirementwarning ? "Warning : Atleast one Requirment is required, after able to add Target/KPI" : `Note : Atleast One Requirment is required.`} 
                                        </Typography>
        
                                      </Box> : null }
      </Stack>
    </Box>

    <CustomDialog
                show={addRequirement}
                onHide={() => {
                  
                    setaddRequirement(false);
                }}
                maxWidth={"lg"}
                minHeight={"50vh"}
                header={ `Add Requirement`}
                contentNode={
                 <>
                     <AddRequirement addedList={requirementList} closePopUp={() => setaddRequirement(false)} add={true} handlesubmit={(val) => RequirementAdd(val)}/>
                 </>
                }
            />
    </>
  );
};
