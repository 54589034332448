import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";

import workFlowService from "../../../store/services/workFlowService";
import { VOBWorflowPages, CMWorflowPages } from "../../../config/config";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { Box, Typography } from "@mui/material";
import { ContractRolesandResponsibility } from "../rolesAndResponsibility/ContractRolesandResponsibility";
import InitiateContract from "../ContractList/InitiateContract";
import { updateContractDetailsAddandEdit } from "../../../store/contractManagement/slices/contractListSlice";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import CostApproval from "../CostApproval/CostApproval";
import { updaterolesandresponsAddandEdit } from "../../../store/contractManagement/slices/contractRolesAndResponsiblity";

export interface vobonboardworkflowprops {
  data?: any;
  isRenewal?: any;
  onClose?: () => void;
  renewalVendorId?: any;
}

export const CMMMainWorkFlow: React.FC<vobonboardworkflowprops> = ({ data, onClose, isRenewal, renewalVendorId }) => {
  const dispatch = useAppDispatch();

  const [instanceValue, setInstanceValue] = useState<any>(null);
  const [workFlowData, setworkFlowData] = useState<any>();
  const [currentProcess, setcurrentProcess] = useState<any>("");
  const [processList, setprocessList] = useState<any>([]);
  const [instancedata, setinstanceNewFromrr] = useState<any>(null);
  const [currentProcessData, setCurrentProcessData] = useState<any>(null);


  const [confirmText, setConfirmText] = useState<string>("");

  const [showPopup, setShowPopup] = useState<boolean>(false);

  const [isloadingProcessCompleteCheck, setisloadingProcessCompleteCheck] = useState<any>(false);

  const { contractDetailsData, isContractDetailsAdded, ToasterMsg, workFLowInstanceData } = useSelector(
    (state: any) => state.contractManagementReducer?.contractlist
  );
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    isRolesAndResponsibilityAdded,
    isRolesAndResponsibilityUpdated,
    isLoading,
    isRolesAndResponsibilityDeleted,
    RolesAndResonsibilitytoasterMsg,
  }: any = contractManagementReducer.contractRolesAndResponsibility;

  const [rrinstanceData, setRRInstanceData] = useState<any>();

  // useEffect(() => {
  //   return () => {
  //     dispatch(updaterolesandresponsAddandEdit(false));
  //     dispatch(updaterolesandresponsresposeData(false));
  //     dispatch(updateVendorDetailsAddandEdit(false));
  //     dispatch(updateAQMMappingAddandEdit(false));
  //   };
  // }, []);

  useEffect(() => {
    dispatch(updateLoader(isloadingProcessCompleteCheck));
  }, [isloadingProcessCompleteCheck]);

  useEffect(() => {
    if (data) {
      console.log(data, "data && ");
      try {
        console.log("data && instanceData 11");
        let instanceData = JSON.parse(data?.WorkflowProcessAsJson);
        console.log(instanceData, "data && instanceData");
        let firstInstanceData = instanceData.find(
          (item: any) => item?.FormName == CMWorflowPages.InitiateContract || item?.ProcessId == 26
        );
        console.log(instanceData, "data && instanceData");
        if (firstInstanceData) {
          setcurrentProcess(firstInstanceData.ProcessName);
          setworkFlowData(data);
          setprocessList([]);
        } else {
          setcurrentProcess("Not Found Process");
          setprocessList([]);
        }
      } catch {
        setcurrentProcess("Not Found Process");
        setprocessList([]);
      }
    }
  }, [data]);

  const closeWorflow = () => {
    if (onClose) {
      onClose();
      dispatch(updateLoader(false));
    }
  };

  const checkFlowCompleted = async (Instvalue: any) => {
    try {
      if (Instvalue) {
        const instanceValue = await workFlowService.getWorkflowInstanceById({
          workflowId: Instvalue?.workflow_Id,
          InstanceId: Instvalue?.workflowInstance?.id,
        });
        let resposseData = instanceValue?.data;
        setInstanceValue(resposseData);
        if (resposseData?.Id) {
          return resposseData;
        }
        return false;
      }
    } catch (error) {
      console.error("Error fetching workflow instance:", error);
      return false;
    }
  };

  const checkProcessCompleteandNextProcessGo = async (createdData: any) => {
    setinstanceNewFromrr(createdData);
    setisloadingProcessCompleteCheck(true);
    // call api and take update of this instance data
    let checkOk = await checkFlowCompleted(createdData);
    setisloadingProcessCompleteCheck(false);
    // then data get go next
    console.log(checkOk, "flow - checkOk number");
    if (checkOk) {
      let instanceData = JSON.parse(checkOk?.WorkflowProcessInstanceAsJson);

      let firstInstanceData = instanceData.find((item: any) => item.FormName == currentProcess);
      // take current process updated data and check current process completed or not , if completed go next or go work flow page
      console.log(firstInstanceData, "flow - firstInstanceData number");
      if (firstInstanceData && firstInstanceData.Status == "Completed") {
        let nextSeq = "";
        // this condition used to cindition applied to process check next process IfYes_GoTo not condition applied find next sequence number of this workflow
        if (firstInstanceData?.ConditionTobeChecked != null && firstInstanceData?.ConditionTobeChecked != "") {
          nextSeq = firstInstanceData.IfYes_GoTo;
          console.log(nextSeq, "flow - condition applied");
        } else {
          let currentSeqNo = firstInstanceData?.ProcessSeqno;
          const filteredData = instanceData.filter((SEQitem: any) => SEQitem.ProcessSeqno > currentSeqNo);

          const nextSequence = filteredData.reduce((prev: any, curr: any) => {
            return prev.ProcessSeqno < curr.ProcessSeqno ? prev : curr;
          });
          console.log(nextSequence, "flow - next aeq find ,  not applied");

          nextSeq = nextSequence?.ProcessSeqno;

          console.log(nextSeq, "flow - condition not applied");
        }
        console.log(nextSeq, "flow - nextSeq number");
        // find the next process , not find go to workflow page
        let nextProcess = instanceData.find((item: any) => item.ProcessSeqno == nextSeq);
        console.log(nextProcess, "flow - nextprocess ");
        if (nextProcess) {
          let NextPageName = nextProcess?.FormName;
          if (NextPageName) {
            setCurrentProcessData(nextProcess);
            setcurrentProcess(NextPageName);
          }
        } else {
          closeWorflow();
        }
      } else {
        closeWorflow();
      }
    } else {
      closeWorflow();
    }
  };
  useEffect(() => {
    if (isContractDetailsAdded) {
      setRRInstanceData(workFLowInstanceData);
      checkProcessCompleteandNextProcessGo(workFLowInstanceData);
      //setcurrentProcess(CMWorflowPages.RR);
      // setworkFlowData(data);
      // setShowPopup(true);
      // setConfirmText("Workflow has Been updates for Initiate contract");
      
      if (ToasterMsg) {
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: ToasterMsg.message,
            isTosterFailed: ToasterMsg.status !== "Success",
          })
        );
      }
      dispatch(updateContractDetailsAddandEdit(false));
      dispatch(updateLoader(false));
      // if (onClose) {
      //   onClose();
      // }
    }
  }, [isContractDetailsAdded]);

  useEffect(() => {
    if (isRolesAndResponsibilityAdded) {
      checkProcessCompleteandNextProcessGo(instancedata);
      // setcurrentProcess(CMWorflowPages.RR);
      // setworkFlowData(data);
      // setShowPopup(true);
      // setConfirmText("Workflow has Been updates for Roles and Responsibility");
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: RolesAndResonsibilitytoasterMsg.message,
          isTosterFailed: RolesAndResonsibilitytoasterMsg.status !== "Success",
        })
      );
      dispatch(updaterolesandresponsAddandEdit(false));
      dispatch(updateLoader(false));
      // if (onClose) {
      //   onClose();
      // }
    }
  }, [isRolesAndResponsibilityAdded]);

  // useEffect(() => {

  //   const runEffect = async () => {
  //     if (isVendorDetailsAdded && currentProcess == VOBWorflowPages.VendorDetail && instancedata) {

  //       checkProcessCompleteandNextProcessGo(instancedata);
  //       dispatch(updateVendorDetailsAddandEdit(false));
  //       dispatch(updateToaster({ isToaster: true, toasterMsg: vendorDetailsToasterMsg }));
  //     }
  //   };
  //   runEffect();
  // }, [isVendorDetailsAdded]);

  // useEffect(() => {
  //   const runEffectaqm = async () => {
  //     if (isAQMMappingAdded && currentProcess == VOBWorflowPages.AQMMapping && instancedata) {
  //       checkProcessCompleteandNextProcessGo(instancedata);
  //       dispatch(updateAQMMappingAddandEdit(false));
  //       dispatch(updateToaster({ isToaster: true, toasterMsg: AQMMappingToasterMsg }));
  //       setcurrentProcess("Not Found Process");
  //     }
  //   };
  //   runEffectaqm();
  // }, [isAQMMappingAdded]);

  console.log("currentProcess",currentProcess)
  return (
    <>
      {workFlowData && currentProcess === CMWorflowPages.InitiateContract && (
        <InitiateContract
          onClose={() => {
            //setcurrentProcess(CMWorflowPages.InitiateContract);
            closeWorflow();
          }}
          workFlowTrigger={true}
          prePageName={"WorkFlow Manager"}
          workFlowData={data}
        />
      )}
      {workFlowData && currentProcess === CMWorflowPages.RR && (
        <ContractRolesandResponsibility
          onClose={() => {
            //setcurrentProcess(CMWorflowPages.InitiateContract);
            closeWorflow();
          }}
          prePageName={"WorkFlow Manager"}
          isEditData={rrinstanceData}
        />
      )}
      {/* {workFlowData && currentProcess === CMWorflowPages.CostApproval && (
        // <ContractRolesandResponsibility onClose={() => closeWorflow()} isEditData={workFlowData}/>
        <CostApproval onClose={() => {
          setcurrentProcess(CMWorflowPages.InitiateContract)
          closeWorflow()
        }}  />
      )} */}

      {/* {workFlowData && currentProcess == VOBWorflowPages.VendorDetail && currentProcessData && (
        <AddVendorDetails
          view={false}
          edit={false}
          prePageName={"Workflow Manager"}
          onClose={() => closeWorflow()}
          isEditData={null}
          isDraftData={false}
          isWorkFlow={currentProcessData}
        />
      )} */}
      {/* {workFlowData && currentProcess == VOBWorflowPages.AQMMapping && currentProcessData && (
        <AQMMapping
          currentProcessData={currentProcessData}
          prePageName={"Workflow Manager"}
          onClose={() => closeWorflow()}
        />
      )} */}

      {currentProcess === "Not Found Process" ? (
        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Box
            sx={{
              border: "1px solid gray",
              borderRadius: "10px",
              width: "500px",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: "18px !important" }}>
              Current Process Complete <br></br> or <br></br> Something went wrong try again later
            </Typography>
            <Typography onClick={() => closeWorflow()} sx={{ fontSize: "14px", mt: 2, cursor: "pointer" }}>
              Back to Workflow
            </Typography>
          </Box>
        </Box>
      ) : null}

      <ModalPopUp
        onHide={() => {
          if (currentProcess === CMWorflowPages.InitiateContract) {
            setShowPopup(false);
            setcurrentProcess(CMWorflowPages.RR);
            setworkFlowData(data);
          } else if (currentProcess === CMWorflowPages.RR) {
            setShowPopup(false);
            setcurrentProcess(CMWorflowPages.CostApproval);
            setworkFlowData(data);
          } else if (currentProcess === CMWorflowPages.CostApproval) {
            setShowPopup(false);
            // setcurrentProcess(CMWorflowPages.RR);
            // setworkFlowData(data);
          }
        }}
        show={showPopup}
        header={"Confirm"}
        text={confirmText}
      />
    </>
  );
};
