import React, { useState, useEffect } from "react";
import { Box, Typography, Stack, Divider } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { useAppDispatchThunk, RootState } from "../../../store/store";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useSelector } from "react-redux";
import DynamicBasicTable from "../../../common/dynamicbasicTable/DynamicBasicTable";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomMultiFileUploader from "../../../common/dynamicFileUploader/CustomMultiFileUploader";
// import ContractTerminationTable from "./ContractTerminationTable";

interface IContractTerminationProps {
  onClose?: any;
}

const ContractTermination: React.FC<IContractTerminationProps> = ({ onClose }) => {
  const dispatch = useAppDispatchThunk();
  const [contractTerminationData, setContractTerminationData] = useState<any[]>([]);
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const { contractTermination } = contractManagementReducer;
  const [contractActionData, setContractActionData] = useState([
    {
      srNo: 1,
      action: "Legal Notice to Vendor",
      status: "Completed",
    },
    {
      srNo: 2,
      action: "Stake holder approval",
      status: "Completed",
    },
    {
      srNo: 3,
      action: "Onboarding of vendor",
      status: "Pending",
    },
  ]);

  const [contractBasicData, setContractBasicData] = useState<any>({
    "Company Name": "Clife",
    "Vendor Name": "Clife",
    "Contract Number": "Contract-SW011",
  });

  const [expandSummary, setExpandSummary] = useState<boolean>(false);

  useEffect(() => {
    // dispatch(getContractTerminationData());
  }, []);

  useEffect(() => {
    if (contractTermination) {
      setContractTerminationData(contractTermination);
    }
  }, [contractTermination]);

  const handleOpenParameter = (data: any, option: string, index?: number) => {
    console.log(data, option, index, "doi");
  };

  const contractActionColumn = [
    { Field: "srNo", DisplayName: "Sr. No." },
    { Field: "action", DisplayName: "Action" },
    { Field: "status", DisplayName: "Status" },
  ];

  return (
    <Box sx={{ width: "100%", px: 2 }}>
      <Box pt={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <ChevronLeftIcon sx={{ cursor: "pointer" }} onClick={() => (onClose ? onClose() : "")} />
          <Typography variant="h6" color={"text.primary"} fontWeight={700}>
            Initiate Contract Termination
          </Typography>
        </Stack>
        <Divider sx={{ my: 4 }} variant="fullWidth" />
      </Box>
      <Box>
        <Typography variant="subtitle1" textAlign="left" fontWeight="bold">
          Contract Details:
        </Typography>
        <Stack spacing={8} direction="row" mt={2}>
          {Object.entries(contractBasicData).map(([label, value]: any) => (
            <Box key={label}>
              <Typography textAlign="left" fontWeight="bold">
                {label}
              </Typography>
              <Typography textAlign="left">{value}</Typography>
            </Box>
          ))}
        </Stack>
        <Stack direction="row" justifyContent="space-between" mt={4}>
          <Box display="flex" alignItems="center" gap={4}>
            <Typography fontWeight="bold">Contract Summary</Typography>
            {expandSummary ? (
              <ExpandLess onClick={() => setExpandSummary(false)} sx={{ cursor: "pointer" }} />
            ) : (
              <ExpandMore onClick={() => setExpandSummary(true)} sx={{ cursor: "pointer" }} />
            )}
          </Box>
        </Stack>
        <Box display={expandSummary ? "block" : "none"}>
          <Typography textAlign="left" mt={2}>
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla. */}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 4 }} />
      <Box>
        <DynamicBasicTable
          columns={contractActionColumn}
          rowData={contractActionData}
          style={{ border: "1px solid #f1f1f1" }}
          handleAction={handleOpenParameter}
          isEdit={true}
          //     isDelete={true}
          //   showDeleteButton={!view && !edit}
          disableAction={false}
          //     showDeleteButton={true}
          //    statusIdMapping={statusIdLabelMapping}
        />
      </Box>
      <Box mt={2}>
        <CustomMultilineTextField label={"Reason for Termination"} value={""} onChange={() => {}} required rows={2} />
      </Box>
      <Box mt={2}>
        <CustomSelect
          name={"status"}
          valueKey={"id"}
          optionLabelKey="lable"
          options={[
            {
              lable: "Dormant",
              id: "DORMANT",
            },
            {
              lable: "Terminated",
              id: "TERMINATED",
            },
          ]}
          label={"Status of Contract After Termination"}
          value={""}
          onChange={() => {}}
        />
      </Box>
      <Box mt={2}>
        <Typography align="left">Attachment</Typography>
        <CustomMultiFileUploader
          onFileUpload={(file) => {
            // if (file && file.length > 0) {
            //   const uploadedFile = file[0];
            //   const reader = new FileReader();
            //   reader.onloadend = () => {
            //     setImage(reader.result);
            //     field.onChange(uploadedFile);
            //   };
            //   reader.readAsDataURL(uploadedFile);
            //   handleSubmitLogo(file?.[0] || null);
            // }
          }}
          maxFileSize={1048576}
          acceptFormats={[".png", ".jpeg", ".jpg"]}
        />
      </Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" pt={2}>
        <CustomButton
          variant="outlined"
          name={"Discard"}
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
        <CustomButton variant="contained" name={"Send it for Approval"} onClick={() => {}} />
      </Stack>
    </Box>
  );
};

export default ContractTermination;
