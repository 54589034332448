import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IMenu } from "../../../models/loggin.type";
import { RootState, useAppDispatch } from "../../../store/store";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { getHolidayWithdaterange } from "../../../store/slices/holidayMasterData";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import axios from "axios";
import { getContractViewList, getContractViewWithdaterange } from "../../../store/contractManagement/slices/PMContractListSlice";
import { ScopeDefinition } from "../scopedefinition/ScopeDefinition";
import { getAllSaveandDraftList, updateSaveasDraftAddandEdit } from "../../../store/slices/saveasdraft";
import CustomStatusChip from "../../../common/dynamicChip/CustomStatusChip";


export default function ContractView() {
    const dispatch = useAppDispatch();
    const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
    const [addForm, setAddForm] = useState(false);
    const [Option, setOption] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [isScopeDefine, setIsScopeDefine] = useState(false);
    const [isShowDraft, setisShowDraft] = useState(1);
    const [IsEditData, setIsEditData] = useState<any>("");
    const [IsDraftData, setIsDraftData] = useState<any>("");
    const [ContractViewData, setContractViewData] = useState<any[]>([]);
    const [ContractViewDraftData, setContractViewDraftData] = useState<any[]>([]);
    const [StartDate, setStartDate] = useState(null);
    const [EndDate, setEndDate] = useState(null);
    const [isDeleteData, setIsDeleteData] = useState<any>(null);
    const [toggleSwitch, setToggleSwitch] = useState<boolean>(false);
    const [OpenModalServerFilter, setOpenModalServerFilter] = React.useState(false);
    const { currentpageTitleData } = useSelector(
        (state: RootState) => state.FormTitleMasterData
    );

    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );
  const { draftdata, isLoadingDraft, isDraftAdded, isDraftUpdated, isDraftDeleted, DraftToasterMsg } = useSelector(
    (state: RootState) => state.saveAsDraft
  );
     const performanceManagementReducer: any = useSelector((state: RootState) => state?.performanceManagementReducer);
      
      const {isScopeAdded , isScopeLoading  } = useSelector(
        (state: RootState) => performanceManagementReducer.PMScopeDataSlice
      );

    const { contractViewDetailsWithDateRange, contractViewDetails, isContractDetailsDeleted, ToasterMsg } = useSelector(
        (state: any) => state.contractManagementReducer?.PMContractListSlice
    );
    const { handleRecordLockUpdate } = RecordLockRelease();


    const columns: any = {
        table_name: "ContractView",
        isAction: true,
        columns: [

            {
                Field: "contractRefNumber",
                DisplayName: "Contract Number",
                FieldType: "string",
              
            },
            {
                Field: "prStatus",
                DisplayName: "PR Status",
                FieldType: "string",
            },
            {
                Field: "contractTypeName",
                DisplayName: "Type",
                FieldType: "string",
            },
            {
                Field: "companyName",
                DisplayName: "Company",
                FieldType: "string",
            },
            {
                Field: "vendorName",
                DisplayName: "Vendor",
                FieldType: "string",
            },
            {
                Field: "service_Name",
                DisplayName: "Service",
                FieldType: "string",
            },
            {
                Field: "status",
                DisplayName: "Status",
                FieldType: "string",
            },
            {
                Field: "effective_from",
                DisplayName: "Effective From",
                FieldType: "date",
            },
            {
                Field: "clauseCount",
                DisplayName: "No of SLA/Clauses",
                FieldType: "string",
            },
            // {
            //     Field: "Outliers",
            //     DisplayName: "No of Outliers",
            //     FieldType: "string",
            // },
            
        ],
    };

    useEffect(() => {
        callpageLoadApi()
        callDraft()
    }, []);
    useEffect(() => {
        if(isScopeAdded){
            callpageLoadApi()
        }
    }, [isScopeAdded]);

    const callpageLoadApi =() => {
        dispatch(getContractViewList());
    }

    useEffect(() => {
        if (currentPagePermissionData !== null) {
            setPermissionForpage(currentPagePermissionData);

        }
    }, [currentPagePermissionData]);

      useEffect(() => {
        if (isDraftAdded) {
          dispatch(updateSaveasDraftAddandEdit(false));
          dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
          setisShowDraft(0);
          callDraft();
          closeDefineScope()
        } else if (isDraftUpdated) {
          dispatch(updateSaveasDraftAddandEdit(false));
          dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
          callDraft();
          closeDefineScope()
        } else if (isDraftDeleted) {
          dispatch(updateSaveasDraftAddandEdit(false));
          callDraft();
        }
      }, [isDraftAdded, isDraftUpdated, isDraftDeleted]);

    const handleOpen = async (data: any, Option: string) => {
  console.log(Option , data , "data");
  
        if (Option === "next") {
            setOption(Option);
            setIsEditData(data);
            setIsScopeDefine(true);

        } else if (Option === "view") {
            setOption(Option);
            setIsEditData(data);
            setIsEdit(true);
        } else if (Option === "edit") {
            setOption(Option);
            setIsEditData(data);
            setIsDraftData(data);
            setIsScopeDefine(true);
        } else if (Option === "delete") {

            setOption(Option);
            setIsDeleteData(data);

        }
    };

    const handleStartDateChange = (e: any) => {
        setStartDate(e);
        if (contractViewDetails && !e) {
            setContractViewData(contractViewDetails)
        }
    };

    const handleEndDateChange = (e: any) => {
        setEndDate(e);
        if (contractViewDetails && !e) {
            setContractViewData(contractViewDetails)
        }
    };

    const handleServerFilter = () => {
        if (!StartDate && !EndDate) {
            setOpenModalServerFilter(true);
        } else {
            dispatch(
                getContractViewWithdaterange({
                    fromDate: StartDate,
                    toDate: EndDate,
                })
            );
        }

    };

      useEffect(() => {
        let modifiedData: any[] = [];
        if (draftdata && Array.isArray(draftdata)) {
          draftdata &&
            draftdata.forEach((item: any) => {
              let JsonData = JSON.parse(item.DraftData);
              JsonData.DraftStatus = item.DraftStatus;
              JsonData.DraftId = item.DraftId;
              modifiedData.push(JsonData);
            });
        }
        setContractViewDraftData(modifiedData);
      }, [draftdata]);

        const callDraft = () => {
          const dataObj = {
            UserId: userLoginResponse?.UserId,
            ModuleName: "Define Scope",
            ScreenName: "Define Scope",
          };
          dispatch(getAllSaveandDraftList(dataObj));
        };

    useEffect(() => {
        if (contractViewDetails) {
            setContractViewData(contractViewDetails)
        }
        if (contractViewDetailsWithDateRange?.length > 0) {
            setContractViewData(contractViewDetailsWithDateRange)
        }

    }, [contractViewDetails, contractViewDetailsWithDateRange]);

    const handleToggleSwitch = (data: any) => {
        setToggleSwitch(data);
        console.log(data,"data");
        
        if (data) {
            setContractViewData(ContractViewData.filter((item:any)=>item.prStatus === "Not Start"))
            console.log("Not Started");
            
        } else {
            setContractViewData(contractViewDetails);
            setStartDate(null);
            setEndDate(null);
        }
    };

    const closeDefineScope = () => {
        setOption("");
        setIsEditData("");
        setIsDraftData("");
        setIsScopeDefine(false);
    }

    return (
        <>
           {!isScopeDefine ? <Box
                sx={{
                    width: "100%",

                }}
            >
                <Stack spacing={2}>
                    <Box
                        sx={{
                            display: "flex",
                            // flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {/* {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName} */}
                            Contract View
                        </Typography>

                        <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Grid
                            sx={{
                                display: "flex",
                                gap: "20px",
                                justifyContent: "left",
                                alignItems: "center",
                            }}
                        >
                            <Box sx={{ display: "flex", width: "250px" }}>

                                <CustomDatePicker
                                    minDate={dayjs().subtract(1, "month").toDate()}
                                    maxDate={dayjs().toDate()}
                                    required={true}
                                    label={"Start Date"}
                                    value={dayjs(StartDate)}
                                    readOnly={false}
                                    onChange={handleStartDateChange}
                                    error={false}
                                    helperText={""}
                                />
                            </Box>

                            <Box sx={{ display: "flex", width: "250px" }}>

                                <CustomDatePicker
                                    minDate={dayjs().subtract(1, "month").toDate()}
                                    maxDate={dayjs().toDate()}
                                    required={true}
                                    label={"End Date"}
                                    value={dayjs(EndDate)}
                                    readOnly={false}
                                    onChange={handleEndDateChange}
                                    error={false}
                                    helperText={""}
                                />
                            </Box>
                            <Box sx={{ display: "flex" }}>
                                <CustomButton variant="contained" name={"Submit"} onClick={() => handleServerFilter()} size="small" />
                            </Box>
                            <Box sx={{ display: "flex", width: "250px" }}>
                                <CustomToogleSwitch
                                    label={""}
                                    dynamicLabel={toggleSwitch ? "PR Not Started Contracts" : "All Contracts"}
                                    value={toggleSwitch}
                                    onChange={(e) => {
                                        handleToggleSwitch(e);
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Box>

                    <Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <MaterialDynamicGrid
                                data={columns}
                                rows={isShowDraft ? ContractViewData : ContractViewDraftData }
                                handleAction={handleOpen}
                                enableZoomIcon={true}
                             
                                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                                handleAdd={() => setAddForm(true)}
                                showAdd={false}
                                showhistory={false}
                                showDelete={false}
                                showView={isShowDraft ? permssionPage?.IsView === 1 : false }
                                showEdit={isShowDraft ? false : true }
                                showNext={isShowDraft ? true : false }
                                propNextbtn={{ name: "Define Scope" }}
                                isDraftView={true}
                                showExports={permssionPage?.IsExport === 1}
                                isNextDisableByValue={[{key :"prStatus" , value : "Not Start"}]}
                                pageName="ContractView"
                                draftCount={ContractViewDraftData.length}
                                isEnableDraft={isShowDraft}
                                handleIsDraft={(val: any) => setisShowDraft(val)}
                                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                            />
                        </Box>
                    </Box>
                </Stack>
            </Box> :

              <ScopeDefinition isDraftData={IsDraftData} contarctDetails={IsEditData} prePageName={"Contract View"}  add={true} closePopUp={closeDefineScope} /> }

            
            <ModalPopUp
                header="Contract View"
                text="Please Select Start Date and End date "
                onHide={() => {
                    setOpenModalServerFilter(false);
                }}
                show={OpenModalServerFilter}
            />
        </>
    );
}
