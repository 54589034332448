import { AxiosError, AxiosResponse } from "axios";
import { axiosProbeInstance } from "./config/axiosConfig";
import { PROBE_URL } from "./endpoints/probeAPI";
import { createDynamicAxiosInstance } from "./config/ProbeService";
import { ProApiDetailsprops } from "../slices/ProbeData";
import eventLogService from "./eventLogs";
import ErrorLogService from "./ErrorLogServices";

const getCompanyBase_Details = (requestBody: any): Promise<any> =>
  axiosProbeInstance
    .get(`${requestBody.ID}/${PROBE_URL.Comprehensive_Details}`, {
      params: {
        identifier_type: requestBody.identifier_type,
      },
    })
    // .get(PROBE_URL.base_Details, { params: requestBody })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getCompanyComprehensive_Details = async (ID: any, Type: any, configData: ProApiDetailsprops): Promise<any> => {
  try {
    // Create Axios instance dynamically
    const axiosInstance = createDynamicAxiosInstance({
      baseURL: configData.baseURL,
      version: configData.version,
      apiKey: configData.apiKey,
      accept: configData.accept || "application/json",
      ID,
      Type,
    });

    const dynamicParams: Record<string, string> = {};

    if (configData?.inputMapping) {
      configData?.inputMapping
        .filter((param: any) => param.IsActive) // Include only active parameters
        .forEach((param: any) => {
          const { API_Json_Field, CLife_Json_Field } = param;

          if (CLife_Json_Field === "<<ID>>") {
            dynamicParams[API_Json_Field] = ID;
          } else if (CLife_Json_Field === "<<TYPE>>") {
            dynamicParams[API_Json_Field] = Type;
          } else {
            dynamicParams[API_Json_Field] = CLife_Json_Field;
          }
        });
    }

    const response = await axiosInstance.get("", {
      params: dynamicParams,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Error getting Data: ${response.statusText}`);
    }
  } catch (error: any) {
    let errorMsg = "Unknown Error";

    if (error.response) {
      errorMsg = `Details fetched via third-party API: Status Code: ${error.response.status}, Message: ${error.response.statusText}`;
    } else {
      errorMsg = `Details fetched via third-party API: Message: ${error.message}`;
    }

    console.error("Error in getCompanyComprehensive_Details:", errorMsg);
    ErrorLogService.addErrorLog({ errorMsg });

    throw error;
  }
};

const ProbeService = {
  getCompanyBase_Details,
  getCompanyComprehensive_Details,
};

export default ProbeService;
