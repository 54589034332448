import React from "react";
import ModalPopUp from "../modalComponent/ModalPopUp";

interface commonBackDropProps {
  setIsVisible: (value: boolean) => void;
  isVisible: boolean;
}

const CommonBackDrop: React.FC<commonBackDropProps> = ({ isVisible, setIsVisible }) => {
  return (
    <ModalPopUp
      onDeleteMethod={() => setIsVisible(false)}
      isDelete={true}
      onHide={() => setIsVisible(false)}
      show={isVisible}
      header="Warning"
      text="Please close before moving to the next tab"
      ActionName="Ok"
      CancelName="Cancel"
    />
  );
};
export default CommonBackDrop;
