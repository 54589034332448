import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { AddBranchMaster } from "./AddBranchMaster";
import { UploadFiles } from "./UploadFiles";
import { IMenu } from "../../../models/loggin.type";
import { RootState, useAppDispatch } from "../../../store/store";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import {
  GetBranchByCompanyList,
  deleteBranch,
  getBranchHistory,
  updateBranchAddandEdit,
  updateVersionHistory,
} from "../../../store/slices/branchMasterData";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { getCompanyList } from "../../../store/slices/companyMasterData";
import { HandleRecordLockCheck, RecordLockUpdate } from "../../recordLock/RecordLock";
import masterEditEnableService from "../../../store/services/masterEditEnableService";
import { Edit_Enable_tosterMSG } from "../../../config/config";
import { getNotifications } from "../../../store/slices/notification";

export interface BranchMasterProps {
  handlePrevious?: () => void;
  handleNext?: () => void;
}

export const BranchMaster: React.FC<BranchMasterProps> = ({ handlePrevious, handleNext }) => {
  const dispatch = useAppDispatch();
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [addForm, setAddForm] = useState(false);
  const [UploadFile, setUploadFile] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [formDisplay, setformDisplay] = useState(false);
  const [filterTableData, setFilterTableData] = useState<any[]>([]);
  const [BranchData, setBranchData] = useState<any[]>([]);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [selectedValue, setselectedValue] = useState(null);
  const [FilterData, setFilterData] = useState<any[]>([]);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const [OpenModalServerFilter, setOpenModalServerFilter] = React.useState(false);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const { handleRecordLockUpdate } = RecordLockUpdate();

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );

  const { BranchTable, BranchByCompanyTable, isBranchAdded, isBranchDeleted, versionHistory, ToasterMsg, isLoading } =
    useSelector((state: RootState) => state.BranchMasterData);

  const { currentpageTitleData } = useSelector((state: RootState) => state.FormTitleMasterData);

  const { CompanyTable } = useSelector((state: RootState) => state.CompanyMasterData);

  const allOption = {
    Company_Id: 0,
    Legal_Name: "All",
  };

  const modifiedLovNames = [...CompanyTable];

  console.log(modifiedLovNames);

  const columns_version: any = {
    table_name: "Branch History Records",
    isAction: false,
    columns: [],
  };

  const columns: any = {
    table_name: "Branch",
    isAction: true,
    columns: [
      {
        Field: "BranchCode",
        DisplayName: "Branch Code",
        FieldType: "string",
      },
      {
        Field: "BranchName",
        DisplayName: "Branch Name",
        FieldType: "string",
      },
      {
        Field: "BranchTypeValue",
        DisplayName: "Branch Type",
        FieldType: "string",
      },
      {
        Field: "Company_legalName",
        DisplayName: "Company",
        FieldType: "string",
      },
      {
        Field: "City",
        DisplayName: "City",
        FieldType: "number",
      },

      {
        Field: "State",
        DisplayName: "State",
        FieldType: "number",
      },
      {
        Field: "Region",
        DisplayName: "Region",
        FieldType: "number",
      },
      {
        Field: "District",
        DisplayName: "Zone",
        FieldType: "number",
      },
      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",
      },
      {
        Field: "UpdatedBy",
        DisplayName: "Modified By",
        FieldType: "string",
      },
    ],
  };

  useEffect(() => {
    dispatch(
      getCompanyList({
        FormID: 2,
      })
    );
  }, []);

  useEffect(() => {
    console.log(BranchByCompanyTable, "dataTable");

    setBranchData(BranchByCompanyTable);
  }, [BranchByCompanyTable]);

  // const transformData = (data: any) => {
  //     return data.map((Branch: any) => {
  //       const BranchCompanyArray = Branch?.CompanyId ? JSON.parse(Branch?.CompanyId) : [];
  //       console.log(BranchCompanyArray,"BranchCompanyArray");

  //       const Legalname = BranchCompanyArray.map((item: any) => item?.Legal_Name).join(",");

  //       console.log(Legalname,"Legalname");

  //       return {
  //         ...Branch,
  //         Company_legalName: Legalname,
  //       };
  //     });
  //   };

  const transformData = (data: any) => {
    return data.map((Branch: any) => {
      let BranchCompanyArray = [];

      if (Branch?.CompanyId) {
        try {
          BranchCompanyArray = JSON.parse(Branch.CompanyId);
        } catch (error) {
          console.error("Invalid JSON in Branch.CompanyId:", Branch.CompanyId, error);
        }
      }

      const Legalname = BranchCompanyArray.map((item: any) => item?.Legal_Name).join(",");

      return {
        ...Branch,
        Company_legalName: Legalname,
      };
    });
  };
  const transformedData = transformData(BranchData);
  console.log(transformedData);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    if (versionHistory.BranchVHeader.length > 0 && versionHistory.BranchVHeader.length > 0) {
      if (versionHistory.BranchVHeader && versionHistory.BranchVHeader.length > 0) {
        setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory.BranchVHeader,
        };
        setVersionHistoryData(re_columns);
        // console.log(versionHistory, "versionHistory ");
      }
    }
  }, [versionHistory]);

  const handleAddRecordLock = (id: number) => {
    const recordLockData = {
      RecordId: id,
      UserId: userLoginResponse?.UserId || 0,
      CreatedBy: userLoginResponse?.UserName || "",
      MenuId: permssionPage?.MenuId,
      ModuleName: "Branch Master",
      Activity: "edit",
    };
    return recordLockData;
  };

  const handleOpen = async (data: any, Option: string) => {
    let props = {
      recordId: data?.BranchId,
      permissionPage: userLoginResponse,
      moduleName: "Branch Master",
      dispatch: dispatch,
      setIsEditing: setIsEdit,
    };
    if (Option === "edit") {
      setOption(Option);
      setIsEditData(data);
      dispatch(updateLoader(true));

      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.BranchId, FormID: 14 });
      const Edit_data: any = response;

      if (Edit_data?.data === true) {
        const canEdit = await HandleRecordLockCheck(props);
        if (canEdit) {
          setIsEdit(true);
          dispatch(updateLoader(false));
        }
      } else {
        dispatch(updateLoader(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));
      }

      // setUserId(data.UserId);
    } else if (Option === "view") {
      console.log(data, Option, "Option");

      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
    } else if (Option === "history") {
      console.log(data, Option, "Option");

      setOption(Option);
      dispatch(
        getBranchHistory({
          BranchId: data.BranchId,
          formID: 14,
          // Approved_By: ,
        })
      );
      // setIsVersionHistory(true);
    } else if (Option === "delete") {
      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.BranchId, FormID: 14 });
      const Edit_data: any = response;

      if (Edit_data?.data === true) {
        setOption(Option);
        setIsDeleteData(data);
      } else {
        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));
      }
    }
  };

  useEffect(() => {
    if (isBranchAdded) {
      dispatch(updateBranchAddandEdit(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.Message,
          isTosterFailed: ToasterMsg.Status === "false" ? true : false,
        })
      );
      dispatch(
        GetBranchByCompanyList({
          CompanyId: selectedValue,
        })
      );
      if (userLoginResponse) {
        dispatch(getNotifications(userLoginResponse?.UserId));
      }
    } else if (isBranchDeleted) {
      setIsDeleteData(null);
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.Message,
          isTosterFailed: ToasterMsg.Status === "false" ? true : false,
        })
      );
      setTimeout(() => {
        dispatch(updateBranchAddandEdit(false));
      }, 1000);
      dispatch(
        GetBranchByCompanyList({
          CompanyId: selectedValue,
        })
      );
    }
  }, [isBranchAdded, isBranchDeleted]);

  const deleteDepartmentList = () => {
    console.log(isDeleteData, "isDeleteData");

    // let formData = { ...isDeleteData };
    let formData: any = {};
    let JsonDataForDisplay: any = {
      "Branch Code": isDeleteData?.BranchCode || "",
      "Branch Name": isDeleteData.BranchName,
      "Branch Type": isDeleteData.BranchTypeValue,
      Company: isDeleteData.Company_legalName,
      "Branch City": isDeleteData?.City || "",
      "Branch State": isDeleteData?.State || "",
      "Branch Region": isDeleteData?.Region || "",
      "Branch Zone": isDeleteData?.District || "",
      Status: isDeleteData.IsActive === "Active" ? "inactive" : "active",
      "Modified By": user?.UserName,
    };

    formData.IsActive = isDeleteData.IsActive === "Active" ? false : true;
    formData.Activity = isDeleteData.IsActive === "Active" ? "inactive" : "active";
    formData.MenuId = currentPagePermissionData?.MenuId;
    formData.Form_Id = 14;
    formData.BranchId = isDeleteData.BranchId;
    formData.BranchName = isDeleteData.BranchName;
    formData.ModuleName = "Branch Master";
    formData.CreatedBy = userLoginResponse?.UserName;
    formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
    console.log(formData);

    dispatch(deleteBranch(formData));
  };

  const previous = () => {
    handlePrevious && handlePrevious();
  };

  const next = () => {
    handleNext && handleNext();
  };

  useEffect(() => {
    console.log(selectedValue, "v_data");

    if (selectedValue !== null) {
    } else {
    }
  }, [selectedValue]);

  const handleChange = (event: any) => {
    console.log(event, "event");

    setselectedValue(event);
  };

  const handleServerFilter = () => {
    console.log(selectedValue, "selectedValue");

    if (selectedValue === null) {
      setOpenModalServerFilter(true);
    }
    if (selectedValue !== null) {
      dispatch(
        GetBranchByCompanyList({
          CompanyId: selectedValue,
        })
      );
      setformDisplay(true);
    }
  };

  console.log(Option, "OptionOptionOption");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          // backgroundColor: "#ffff",
          // backgroundColor: "background.default",
          // px: 3,
          // py: 4,
          // borderRadius: "10px",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {" "}
              {/* {currentPagePermissionData?.MenuName} */}
              {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
            </Typography>

            {currentPagePermissionData?.MenuName === "Customer Onboarding" ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "center",
                  }}
                >
                  <CustomButton variant="outlined" name={"Previous Master"} onClick={() => previous()} size="small" />
                  <CustomButton
                    variant="contained"
                    name={"Proceed to next master"}
                    onClick={() => next()}
                    size="small"
                  />
                </Grid>
              </Box>
            ) : (
              <></>
            )}
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Grid
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", width: "250px" }}>
                <CustomSelect
                  name={"listName"}
                  valueKey={"Company_Id"}
                  addField={false}
                  showAll={allOption}
                  showAllOption={true}
                  optionLabelKey="Legal_Name"
                  options={modifiedLovNames}
                  value={selectedValue}
                  onChange={handleChange}
                  disabled={false}
                  required={true}
                  label={"Company"}
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <CustomButton variant="contained" name={"Submit"} onClick={() => handleServerFilter()} size="small" />
              </Box>
            </Grid>
          </Box>

          {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Grid
                            sx={{
                                display: "flex",
                                gap: "20px",
                                justifyContent: "center",
                                mt: 2
                            }}
                        >

                            <CustomButton variant="outlined" name={"Upload File"} onClick={() => setUploadFile(true)} size="small" />
                        </Grid>
                    </Box> */}

          <Box>
            {!formDisplay ? (
              ""
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <MaterialDynamicGrid
                  data={columns}
                  rows={BranchData && BranchData.length > 0 ? transformData(BranchData) : []}
                  handleAction={handleOpen}
                  enableZoomIcon={true}
                  // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                  handleAdd={() => setAddForm(true)}
                  showAdd={true}
                  showhistory={true}
                  showDelete={permssionPage?.IsDelete === 1}
                  showView={permssionPage?.IsView === 1}
                  showEdit={permssionPage?.IsEdit === 1}
                  showExports={permssionPage?.IsExport === 1}
                  pageName="Branch Master"
                  userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                />
              </Box>
            )}
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Branch"}`}
        contentNode={
          // <></>
          <AddBranchMaster
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
            isSelectedData={selectedValue}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          if (Option === "edit") {
            handleRecordLockUpdate();
          }
          setIsEdit(false);
        }}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={
          Option === "view"
            ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Branch"}`
            : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Branch"}`
        }
        contentNode={
          <>
            {Option === "edit" && (
              <AddBranchMaster
                view={false}
                closePopUp={() => {
                  handleRecordLockUpdate();
                  setIsEdit(false);
                }}
                edit={isEdit}
                isEditData={IsEditData}
                isSelectedData={selectedValue}
              />
              // <></>
            )}
            {Option === "view" && (
              <AddBranchMaster
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                isEditData={IsEditData}
                isSelectedData={selectedValue}
              />
            )}
          </>
        }
      />

      <CustomDialog
        show={UploadFile}
        onHide={() => setUploadFile(false)}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={"Upload File"}
        contentNode={
          // <> </>
          <UploadFiles
            view={false}
            closePopUp={() => {
              setUploadFile(false);
            }}
            edit={isEdit}
            isEditData={IsEditData}
          />
        }
      />

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
          dispatch(updateVersionHistory([]));
        }}
        maxWidth={"lg"}
        // header={"Branch Master Version History"}
        header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Branch"} Version History`}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistory.BranchRecords}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showDelete={false}
              showhistory={false}
              showView={false}
              showEdit={false}
              showExports={true}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Branch Master Version History"
            />
          </>
        }
      />

      <ModalPopUp
        onDeleteMethod={() => deleteDepartmentList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${
          isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
        } the selected Branch`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />

      <ModalPopUp
        header="Company"
        text="Please Select a Company "
        onHide={() => {
          setOpenModalServerFilter(false);
        }}
        show={OpenModalServerFilter}
      />
    </>
  );
};
