import React from "react";
import { Box, Grid, Stack } from "@mui/material";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../../../common/dynamicInputs/CustomTextField";
import CustomNumberField from "../../../../common/dynamicInputs/CustomNumberField";

interface EscalationFormProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  onClose?: any;
  onSubmit: (data:any)=>void;
}

interface FormData {
  EscalationLevel:number;
  EscalationId: number;
  VendorId: number;
  Name: string;
  Designation: string;
  ContactNumber: string;
  EmailId: string;
  Status: number;
}

export const EscalationForm: React.FC<EscalationFormProps> = ({ view, isEditData, edit, onClose, onSubmit }) => {
  const formData: FormData = {
    EscalationLevel: isEditData ? isEditData.EscalationLevel : 0,
    EscalationId: isEditData ? isEditData.EscalationId : 0,
    VendorId: isEditData ? isEditData.VendorId : 0,
    Name: isEditData ? isEditData.Name : "",
    Designation: isEditData ? isEditData.Designation : "",
    ContactNumber: isEditData ? isEditData.ContactNumber : null,
    EmailId: isEditData ? isEditData.EmailId : "",
    Status :isEditData ? isEditData.Status : ""
  };

  const {
    handleSubmit: handleAddressForm,
    reset,
    setValue,
    formState: { errors: error },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Stack spacing={3}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Controller
                name="Name"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Name is required",
                  validate: {
                    noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                    maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                    ValueLimitation: (value) => {
                      if (!value) return true;
                      return /^[a-zA-Z0-9,.\- ]+$/.test(value) || "Cannot contain special characters";
                    },
                  },
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"Name"}
                    name={"Name"}
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error["Name"])}
                    helperText={error["Name"] && error["Name"].message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              <Controller
                name="Designation"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Designation is required",
                  validate: {
                    noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                    maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                    ValueLimitaion: (value) =>
                      /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value) || "Cannot contain special characters",
                  },
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"Designation"}
                    name={"Designation"}
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.Designation)}
                    helperText={error.Designation?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="ContactNumber"
                control={control}
                rules={{
                  required: "Contact number is required",
                  pattern: {
                    value: /^\d{10,15}$/,
                    message: "Contact number must be 10 to 15 digits",
                  }
                  // validate: {
                  //   noLeadingSpaces: (value: string | undefined) => {
                  //     if (value === undefined) return "Value is required";
                  //     return !/^\s/.test(value) || "Leading spaces are not allowed";
                  //   },
                  //   noSpecialCharacters: (value: string | undefined) => {
                  //     if (value === undefined) return "Value is required";
                  //     return !/[^a-zA-Z0-9\s\-+\(\)]/.test(value) || "Special Characters are not allowed";
                  //   },
                  //   minLength: (value) => {
                  //     if (value === undefined) return "Value is required";
                  //     return value.length >= 6 || "Minimum 6 characters required";
                  //   },
                  //   maxLength: (value) => {
                  //     if (value === undefined) return "Value is required";
                  //     return value.length <= 15 || "Maximum 15 characters allowed";
                  //   }
                  // }
                }}
                render={({ field }) => (
                  <CustomNumberField
                    label={"Contact No"}
                    name={"ContactNumber"}
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.ContactNumber)}
                    helperText={ error.ContactNumber?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="EmailId"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Email is required",
                  validate: {
                    noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                    maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                    ValueLimitaion: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"Email Id"}
                    name={"EmailId"}
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.EmailId)}
                    helperText={error.EmailId?.message?.toString()}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <CustomButton
              variant="outlined"
              name={"Cancel"}
              onClick={(e, value) => {
                onClose();
              }}
            />
            <CustomButton variant="contained" name={"Save"} onClick={handleAddressForm(onSubmit)} size="small" />
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};
