import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomerAssessmentService from "../services/CustomerAssessmentService";

export interface ICustomerAssessmentdata {
  
  CustomerAssessmentdata: any;
  CustomerAssessmentAlldata: any;
  isCustomerAssessmentLoading: boolean;
  isAddEditcustomerAssessmentLoading: boolean;
  iscustomerAssessmentAdded: boolean;
  CustomerAssessmentToasterMsg: string;
  iscustomerAssessmentSuccess: any;

}

export const initialState: ICustomerAssessmentdata = {
  
  CustomerAssessmentdata: {},
  CustomerAssessmentAlldata: [],
  isCustomerAssessmentLoading: false,
  isAddEditcustomerAssessmentLoading: false,
  iscustomerAssessmentAdded: false,
  iscustomerAssessmentSuccess: false,
  CustomerAssessmentToasterMsg: "",
};




export const getCustomerAssessmentbyId = createAsyncThunk(
  "getCustomerAssessmentbyId",
  async (requesrParams: { Vendor_Id : number , UserId : any , IsSME : boolean ,  IsDo : boolean ,  Project_Detail_Id  : any }, { rejectWithValue }) => {
    try {
      const response = await CustomerAssessmentService.getCustomerAssessment(requesrParams);
      const { data } = response;
      return data ;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addcustomerAssessment = createAsyncThunk("addcustomerAssessment", async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response: any = await CustomerAssessmentService.addCustomerAssessment(requesrParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

  export const getCustomerAssessmentbycustomerIdorall = createAsyncThunk(
    "getCustomerAssessmentbycustomerIdorall",
    async (requesrParams: { customerId ? : any }, { rejectWithValue }) => {
      try {
        const response = await CustomerAssessmentService.getCustomerAssessmentbycustomerIdorall(requesrParams);
        const { data } = response;
        return data ;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );



export const CustomerAssessmentDataSlice = createSlice({
  name: "CustomerAssessmentDetails",
  initialState,
  reducers: {
    updateCustomerAssessmentAddandEdit: (state, action) => {
   
      state.CustomerAssessmentToasterMsg = '';
      state.isAddEditcustomerAssessmentLoading = false;
      state.iscustomerAssessmentAdded = false;
    },
  
  },
  extraReducers: (builder) => {
    builder
    
    
      .addCase(getCustomerAssessmentbyId.pending, (state) => {
        
        state.isCustomerAssessmentLoading = true;
      })
      .addCase(getCustomerAssessmentbyId.fulfilled, (state, action: PayloadAction<any>) => {
        let actualData : any = action.payload
        state.CustomerAssessmentdata = {
       ...actualData,
           AssessmentQuestionnaires : JSON.parse(actualData.AssessmentQuestionnaires)
        } 
        state.isCustomerAssessmentLoading = false;
      })
      .addCase(getCustomerAssessmentbyId.rejected, (state, action: PayloadAction<any>) => {
        state.isCustomerAssessmentLoading = false;
      }) .addCase(addcustomerAssessment.pending, (state) => {
      
        state.isAddEditcustomerAssessmentLoading = true;
      })
      .addCase(addcustomerAssessment.fulfilled, (state, action: PayloadAction<any>) => {
        // state.VendorAssessmentdataTableList = [...state.VendorAssessmentdataTableList , ...action.payload  ]
        state.iscustomerAssessmentAdded = true;
        state.isAddEditcustomerAssessmentLoading = false;
        state.CustomerAssessmentToasterMsg = action.payload.Message;
        state.iscustomerAssessmentSuccess = action.payload?.Status == "Success" ? true : false;
      })
      .addCase(addcustomerAssessment.rejected, (state, action: PayloadAction<any>) => {
  
        state.isAddEditcustomerAssessmentLoading = false;
      }) .addCase(getCustomerAssessmentbycustomerIdorall.pending, (state) => {
        
        state.isCustomerAssessmentLoading = true;
      })
      .addCase(getCustomerAssessmentbycustomerIdorall.fulfilled, (state, action: PayloadAction<any>) => {
        let actualData : any = action.payload
        state.CustomerAssessmentAlldata = actualData?.map((item : any)  => {
        return  {
               ...item,
                AssessmentQuestionnaires : JSON.parse(item.AssessmentQuestionnaires)
             } 
      }) 
        state.isCustomerAssessmentLoading = false;
      })
      .addCase(getCustomerAssessmentbycustomerIdorall.rejected, (state, action: PayloadAction<any>) => {
        state.isCustomerAssessmentLoading = false;
      })
      
      
      

  },
});

export const { updateCustomerAssessmentAddandEdit } = CustomerAssessmentDataSlice.actions;
export default CustomerAssessmentDataSlice.reducer;
