import axios, { AxiosError, AxiosResponse } from "axios";
import { CONTRACT_FILE_HANDLER } from "./endpoints/contractFileHandler";
import { axiosInstance } from "./config/axiosConfig";
const { REACT_APP_API_CM_URL } = process.env;

const UploadAFile = async (requestBody: any): Promise<any> => {
  const baseURLWithoutVersion = REACT_APP_API_CM_URL;

  const axiosInstanceWithoutVersion = axios.create({
    baseURL: baseURLWithoutVersion,
  });

  return axiosInstanceWithoutVersion
    .post(CONTRACT_FILE_HANDLER.UploadAFile, requestBody, {
      headers: {
        "Content-Type": "multipart/form-data",
        accept: "/*",
      },
    })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
};

const DownLoadAFile = (reqparams: any): Promise<any> =>
  axiosInstance
    .get(CONTRACT_FILE_HANDLER.DownLoadAFile, { params: reqparams })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const fileHandlerService = {
  UploadAFile,
  DownLoadAFile,
};

export default fileHandlerService;
