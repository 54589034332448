import React, { useEffect, useState } from "react";
import { textFieldWidth } from "../../../../utils/constants";
import { Box, FormHelperText, Grid, IconButton, useTheme } from "@mui/material";
import { emailDataProps } from "../../../contractManagement/CostApproval/CostApproval";
import { Controller, useFormContext } from "react-hook-form";
import CustomTextField from "../../../../common/dynamicInputs/CustomTextField";
import CustomRadioButton from "../../../../common/dynamicInputs/CustomRadioButton";
import CustomSvg from "../../../../common/CustomSvg";
import CustomFileUploader from "../../../../common/dynamicFileUploader/CustomFileUploader";

import CustomAutoComplete from "../../../../common/dynamicInputs/CustomAutoComplete";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../../store/store";
import { IndustryConfig, LovConfig } from "../../../../config/config";
import CustomMultiSelect from "../../../../common/dynamicInputs/CustomMultiSelect";
import { DownLoadAFile, UploadAFile } from "../../../../store/slices/fileHandler";
import fileHandlerService from "../../../../store/services/fileHandlerService";

interface CompanyInformationProps {
  edit?: boolean;
  view?: any;
  isWorkFlow?: any;
  formData?: any;
}

const CompanyInformation: React.FC<CompanyInformationProps> = ({ edit, isWorkFlow, view, formData }) => {
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    trigger,
    clearErrors,
    setError,
    watch,
    formState: { errors, isDirty },
  } = useFormContext();
  const [image, setImage] = useState<any>("");
  const { CompanyTable } = useSelector((state: RootState) => state.CompanyMasterData);
  const { industryTable, ParentindustryTable } = useSelector((state: RootState) => state.indutryMasterData);
  const [CompanyTypelov, setCompanyTypelov] = useState<any[]>([]);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const isActiveDisabled = isWorkFlow ? true : watch("IsActive") === true ? true : false;
  const IndustryWatchValue = watch("Industry");
  const theme = useTheme();
  const dispatch = useAppDispatchThunk();
  useEffect(() => {
    setCompanyTypelov(
      lovTable.filter((item: any) => item.Name === LovConfig.Company_Type && item.IsActive === "Active") || []
    );
  }, [lovTable]);

  const logoUrl = watch("Logo");

  const fetchData = async () => {
    try {
      const { data } = await fileHandlerService.DownLoadAFile({ fileUrl: logoUrl });
      if (data) {
        const blob = "data:image/jpeg;base64," + data;
        setImage(blob);
        setValue('logoUrl',logoUrl);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  useEffect(() => {
    if (typeof logoUrl === 'string' && logoUrl.startsWith("http")) {
      fetchData(); 
    } else if (logoUrl instanceof File) {
      const reader = new FileReader();
      const fileType = logoUrl?.type; 
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setValue("Logo", base64String); 
      };
  
      reader.onerror = () => {
        console.error("Error reading the file.");
      };
  
      reader.readAsDataURL(logoUrl);
    } else {
      setImage(logoUrl);
    }
  }, [logoUrl]);
  
  
  

  // console.log(getValues(), "get Company Informations value");

  const fetchSector = (id: any) => {
    const arr = industryTable?.filter((item: any) => item.Parent_Industry_Id === id) || [];
    return arr;
  };
  const urlRegex = new RegExp(
    "^(https?:\\/\\/)?" +
      "((www\\.)?)" +
      "([a-zA-Z0-9-]+\\.)?" +
      "([a-zA-Z0-9-]{1,63})" +
      "\\." +
      "([a-zA-Z]{2,6})" +
      "(\\:[0-9]{1,5})?" +
      "(\\/[^\\s]*)?$"
  );

  function isValidURL(url: any) {
    return urlRegex.test(url);
  }
  const ParentCompanywatch = watch("Is_Parent_Company");

  const onProceedNext = async (data: any) => {
    const isValid = await trigger();
    if (isValid) {
      console.log("Proceeding to next step with data:", data);
      // onNext(data);
    } else {
      console.error("Validation failed, unable to proceed to next step.");
    }
  };

  const handleSubmitLogo = async (uploadedFile: File | null) => {
    if (uploadedFile) {
      const formData = new FormData();
      formData.append("fileUploadRequest", uploadedFile);
      const getUploadUrl = await dispatch(UploadAFile(formData));
      setValue("Logo", getUploadUrl.payload);
    }
  };

  const GridItem = React.memo(({ content }: { content: React.ReactNode }) => (
    <Grid
      item
      xs={textFieldWidth.xs}
      sm={textFieldWidth.sm}
      md={textFieldWidth.md}
      lg={textFieldWidth.lg}
      xl={textFieldWidth.xl}
    >
      {content}
    </Grid>
  ));
  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="Legal_Name"
          control={control}
          defaultValue={""}
          rules={{
            required: "Legal Name is Required",
            // validate: {
            //   noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
            //   minLength: (value) => value.length >= 1 || "Minimum 1 characters required",
            //   maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
            //   ValueLimitation: (value) => {
            //     if (!value) return true;
            //     return /^[a-zA-Z0-9,.\- ]+$/.test(value) || "Cannot contain special characters";
            //   },
            // },
            validate: {
              noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
              minLength: (value) => value.length >= 1 || "Minimum 1 character required",
              maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
              ValueLimitation: (value) => {
                if (!value) return true;
                return /^[a-zA-Z0-9,.\- @#$%&]+$/.test(value) || "Can special characters";
              },
            }
          }}
          render={({ field }) => (
            <CustomTextField
              name={"Legal_Name"}
              required={true}
              readOnly={view || !isActiveDisabled}
              label={"Legal Name"}
              value={field.value}
              onChange={field.onChange}
              error={Boolean(errors.Legal_Name)}
              helperText={errors.Legal_Name && errors.Legal_Name?.message?.toString()}
            />
          )}
        />
      </Grid>

      {/* Brand Name */}
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="Brand_Name"
          control={control}
          defaultValue={""}
          rules={{
            // required: "Brand Name is Required",
            // validate: (data) => {
            //     return (
            //        data.length >= 1 || "Minimal 1 characters requires"
            //     );
            // },
            validate: {
              noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
              minLength: (value) => value.length >=0 || "Minimum 1 character required",
              maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
              ValueLimitation: (value) => {
                if (!value) return true;
                return /^[a-zA-Z0-9,.\- @#$%&]+$/.test(value) || "Can special characters";
              },
            }
          }}
          render={({ field }) => (
            <CustomTextField
              name={"Brand_Name"}
              required={false}
              readOnly={view || !isActiveDisabled}
              label={"Brand Name"}
              value={field.value}
              onChange={field.onChange}
              error={Boolean(errors.Brand_Name)}
              helperText={errors.Brand_Name && errors.Brand_Name?.message?.toString()}
            />
          )}
        />
      </Grid>

      {/* Short Name */}
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="Short_Name"
          control={control}
          rules={{
            required: "Short Name is Required",
            validate: {
              noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
              minLength: (value) => value.length >= 1 || "Minimum 1 characters required",
              maxLength: (value) => value.length <= 10 || "Maximum 10 characters allowed",
              ValueLimitation: (value) => {
                if (!value) return true;
                return /^[a-zA-Z0-9,.\- ]+$/.test(value) || "Cannot contain special characters";
              },
            },
          }}
          render={({ field }) => (
            <CustomTextField
              name={"Short_Name"}
              required={true}
              readOnly={view || !isActiveDisabled}
              label={"Short Name"}
              value={field.value}
              onChange={field.onChange}
              error={Boolean(errors.Short_Name)}
              helperText={errors.Short_Name && errors.Short_Name?.message?.toString()}
            />
          )}
        />
      </Grid>

      {/* Parent Company Checkbox */}
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="Is_Parent_Company"
          control={control}
          rules={{
            required: "Parent Company is Required",
          }}
          render={({ field }) => (
            <CustomRadioButton
              list={[
                { value: 1, lable: "Yes" },
                { value: 2, lable: "No" },
              ]}
              style={{ marginTop: "-7px" }}
              label="Is Parent Company"
              value={field.value}
              required={true}
              onChange={(e) => {
                field.onChange(e);
                setValue("Parent_Company_Name", null, {
                  shouldValidate: false,
                  shouldDirty: true,
                });
                setValue("Company_Type", null, {
                  shouldValidate: false,
                  shouldDirty: true,
                });
                clearErrors("Parent_Company_Name");
                clearErrors("Company_Type");
              }}
              readOnly={view || !isActiveDisabled}
              error={Boolean(errors.Is_Parent_Company)}
              helperText={errors.Is_Parent_Company && errors.Is_Parent_Company?.message?.toString()}
            />
          )}
        />
      </Grid>

      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="Parent_Company_Name"
          control={control}
          defaultValue={""}
          rules={{
            required: ParentCompanywatch == 1 && "Parent Company Name is Required",
          }}
          render={({ field }) => (
            <CustomAutoComplete
              optionValueKey={"Company_Id"}
              optionLabelKey="Legal_Name"
              options={CompanyTable.filter((data) => {
                if (formData?.Company_Id === undefined || formData?.Company_Id === null) {
                  return data.IsActive === "Active" && data.Is_Parent_Company === true;
                }
                return (
                  data.IsActive === "Active" &&
                  data.Company_Id !== formData.Company_Id &&
                  data.Is_Parent_Company === true
                );
              })}
              disabled={Number(ParentCompanywatch) == 2 ? true : false}
              readOnly={Number(ParentCompanywatch) == 2 ? true : false || view || !isActiveDisabled}
              required={Number(ParentCompanywatch) == 1 ? true : false || view || !isActiveDisabled}
              label={"Parent Company"}
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
              }}
              // onFocus={(e) => {
              //   trigger("Parent_Company_Name");
              // }}
              error={Boolean(errors.Parent_Company_Name)}
              helperText={errors.Parent_Company_Name && errors.Parent_Company_Name?.message?.toString()}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="Company_Type"
          control={control}
          defaultValue=""
          rules={{
            required: ParentCompanywatch == 1 && "Company Type is Required",
          }}
          render={({ field }) => (
            <CustomAutoComplete
              optionValueKey={"LovId"}
              optionLabelKey="Options"
              options={CompanyTypelov}
              disabled={ParentCompanywatch == 2 ? true : false}
              readOnly={ParentCompanywatch == 2 ? true : false || view || !isActiveDisabled}
              required={ParentCompanywatch == 1 ? true : false || view || !isActiveDisabled}
              label={"Company Type"}
              value={field.value}
              onChange={field.onChange}
              // onFocus={(e) => {
              //   trigger("Company_Type");
              // }}
              error={Boolean(errors.Company_Type)}
              helperText={errors.Company_Type && errors.Company_Type?.message?.toString()}
            />
          )}
        />
      </Grid>

      {/* Group Name */}
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="Group_Name"
          control={control}
          rules={{
            required: "Group Name is Required",
            validate: {
              noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
              minLength: (value) => value.length >= 1 || "Minimum 1 characters required",
              maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
              ValueLimitation: (value) => {
                if (!value) return true;
                return /^[a-zA-Z0-9,.\- ]+$/.test(value) || "Cannot contain special characters";
              },
            },
          }}
          render={({ field }) => (
            <CustomTextField
              name={"Group_Name"}
              required={true}
              readOnly={view || !isActiveDisabled}
              label={"Group Name"}
              value={field.value}
              onChange={field.onChange}
              error={Boolean(errors.Group_Name)}
              helperText={errors.Group_Name && errors.Group_Name?.message?.toString()}
            />
          )}
        />
      </Grid>

      {/* Industry */}
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="Industry"
          control={control}
          rules={{ required: "Industry Type is required" }}
          render={({ field }) => (
            <CustomAutoComplete
              optionValueKey={"IndustryId"}
              optionLabelKey="IndustryName"
              options={
                industryTable?.filter(
                  (item: any) =>
                    item.IndustryTypeValues === IndustryConfig.Industry && item.IsActive === "Active"
                ) || []
              }
              readOnly={view || !isActiveDisabled}
              required={true}
              label={"Industry"}
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
                fetchSector(e);
                trigger("Industry");
                setValue("Sector", []);
              }}
              // onFocus={() => {
              //   trigger("Industry");
              // }}
              error={Boolean(errors.Industry)}
              helperText={errors.Industry && errors.Industry?.message?.toString()}
            />
          )}
        />
      </Grid>

      {/* Sector */}
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="Sector"
          control={control}
          rules={{
            required: "Sector is Required",
          }}
          render={({ field }) => (
            <CustomMultiSelect
              optionLabelKey={`IndustryName`}
              optionIdKey={`IndustryId`}
              options={industryTable?.filter((item: any) => item?.Parent_Industry_Id === IndustryWatchValue) || []}
              required={true}
              label={"Sector"}
              readOnly={view || !isActiveDisabled}
              value={Array.isArray(field.value) ? field.value : []}
              onChange={field.onChange}
              error={Boolean(errors.Sector)}
              helperText={errors.Sector && errors.Sector?.message?.toString()}
            />
          )}
        />
      </Grid>

      {/* Website */}
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="WebSite"
          control={control}
          defaultValue={""}
          rules={{
            required: "Website is Required",
            validate: {
              minLength: (value) => value.trim().length >= 1 || "Minimum 1 character required",
              maxLength: (value) =>
                value.trim().length < 100 || "Maximum 100 characters allowed, please use shorter URL",
              validate: (value) => isValidURL(value) || "Invalid URL format",
            },
          }}
          render={({ field }) => (
            <CustomTextField
              name={"WebSite"}
              required={true}
              readOnly={view || !isActiveDisabled}
              label={"Website"}
              value={field.value}
              onChange={field.onChange}
              error={Boolean(errors.WebSite)}
              helperText={errors.WebSite && errors.WebSite?.message?.toString()}
            />
          )}
        />
      </Grid>

      {/* Logo */}
      <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={8} lg={8} xl={8}>
        <Box>
          {!image && (
            <Box>
              <Controller
                name="Logo"
                control={control}
                rules={{
                  required: "Logo is Required",
                }}
                render={({ field }) => (
                  <CustomFileUploader
                    title={"Logo"}
                    onFileUpload={(file) => {
                      if (file && file.length > 0) {
                        const uploadedFile = file[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          setImage(reader.result);
                          field.onChange(uploadedFile);
                        };
                        reader.readAsDataURL(uploadedFile);
                        handleSubmitLogo(file?.[0] || null);
                      }
                    }}
                    setImage={setImage}
                    maxFileSize={1048576}
                    acceptFormats={[".png", ".jpeg", ".jpg"]}
                  />
                )}
              />
              {errors.Logo ? (
                <FormHelperText error={Boolean(errors.Logo && errors.Logo?.message?.toString())} id="my-helper-text">
                  {errors.Logo && errors.Logo?.message?.toString()}
                </FormHelperText>
              ) : null}
            </Box>
          )}
          <Box sx={{ position: "relative" }}>
            {image && (
              <Box sx={{ width: "100%", maxHeight: "150px" }}>
                <img src={image} style={{ width: "150px", height: "auto", objectFit: "contain" }} alt={"CompanyLogo"} />
              </Box>
            )}
            {image && !view && isActiveDisabled && (
              <IconButton
                onClick={() => {
                  setImage(null);
                  setValue("Logo", null);
                }}
                sx={{
                  position: "absolute",
                  top: 7,
                  left: 7,
                  background: "#d3d3d3",
                  color: "white",
                  borderRadius: "3px",
                  padding: "2px",

                  cursor: "pointer",
                  zIndex: 1,
                  "&:hover": {
                    background: "#bdbdbd",
                  },
                }}
              >
                <CustomSvg
                  color={`${theme.palette.text.secondary}`}
                  size={"20px"}
                  name={"delete"}
                  sx={{ ml: "3px", mt: "1px" }}
                />
              </IconButton>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CompanyInformation;
