import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IApimasterResponse } from "../../models/apiMaster.type";
import { API_Master } from "./endpoints/apiMaster";

const getAllApiMaster = (): Promise<IApimasterResponse> =>
  axiosInstance
    .get(API_Master.GetAllApiMaster)
    .then((response: AxiosResponse<IApimasterResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateApiMaster = (requestBody: any): Promise<IApimasterResponse> =>
  axiosInstance
    .post(API_Master.SaveApiMaster, requestBody)
    .then((response: AxiosResponse<IApimasterResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const saveApiMaster = (requestBody: any): Promise<IApimasterResponse> =>
  axiosInstance
    .post(API_Master.SaveApiMaster, requestBody)
    .then((response: AxiosResponse<IApimasterResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteApi = (requestBody: any): Promise<IApimasterResponse> =>
  axiosInstance
    .post(API_Master.RemoveApiMaster, requestBody)
    .then((response: AxiosResponse<IApimasterResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });



const ApiMasterService = {
    getAllApiMaster,
    saveApiMaster,
    updateApiMaster,
    deleteApi,
 
};

export default ApiMasterService;
