import React, { useEffect, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  createMasterClause,
  updateClauseMasterDetailsAddandEdit,
  updateMasterClause,
} from "../../../store/contractManagement/slices/clauseMasterSlice";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { ACTIVE } from "../../../utils/constants";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { LovConfig } from "../../../config/config";
import { getLocationList } from "../../../store/slices/locationMasterData";
import CustomAutoComplete from "../../../common/dynamicInputs/CustomAutoComplete";
import {
  ArticalMasterEdit,
  ArticalMasterSubmit,
  articleMasterReset,
} from "../../../store/contractManagement/slices/articleMasterSlice";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";

export interface AddArticleMaster {
  articleId?: number;
  stateId?: number;
  articleCode: string;
  articleNo: string;
  articleName: string;
  isActive: any;
  updated_By: string;
}

interface AddArticleMasterProps {
  existingData?: AddArticleMaster;
  isView?: boolean;
  isEdit?: boolean;
  isNew?: boolean;
  onClose: () => void;
}

const AddArticleMaster: React.FC<AddArticleMasterProps> = ({
  existingData,
  isEdit,
  isView,
  onClose,
  isNew = false,
}) => {
  const dispatch = useAppDispatchThunk();
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const [locationData, setLocationData] = useState<any[]>([]);
  const {
    articalMasterData,
    isLoading,
    isArticalMasterDataAdded,
    isArticalMasterDataUpdated,
    isArticalMasterDataDeleted,
    ToasterMsg,
  }: any = contractManagementReducer.articleMasterData;
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { locationTable } = useSelector((state: RootState) => state.locationMasterData);
  const { handleRecordLockUpdate } = RecordLockRelease();
  console.log(existingData, "existingDataexistingData");
  useEffect(() => {
    if (locationTable) {
      let changedValue = locationTable
        .map((item: any) => {
          return {
            ...item,
            End_Pin: item.End_Pin === 0 ? "" : item.End_Pin,
            Start_Pin: item.Start_Pin === 0 ? "" : item.Start_Pin,
          };
        })
        .filter((i: any) => i.LocationTypeValue === "State");
      setLocationData(changedValue);
    }
  }, [locationTable]);
  const initialData: AddArticleMaster = {
    articleId: isEdit ? existingData?.articleId || 0 : 0,
    stateId: isEdit ? existingData?.stateId || 0 : 0,
    articleCode: isEdit ? existingData?.articleCode || "" : "",
    articleNo: isEdit ? existingData?.articleNo || "" : "",
    articleName: isEdit ? existingData?.articleName || "" : "",
    isActive: isEdit ? existingData?.isActive || existingData?.isActive : 1,
    updated_By: isEdit ? existingData?.updated_By || "" : "",
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    reset,
  } = useForm<AddArticleMaster>({
    defaultValues: initialData,
    mode: "onChange",
  });

  const isArticleActive =  watch("isActive") === 0 ? true : false;
  console.log(isArticleActive,watch("isActive"), "isArticleActive");

  const handleSave = async (data: AddArticleMaster) => {
    try {
      dispatch(updateLoader(true));

      let updatedFormData = {
        articleId: data?.articleId,
        stateId: data?.stateId,
        articleCode: data?.articleCode,
        isActive: data?.isActive,
        articleNo: data?.articleNo,
        articleName: data?.articleName,
        updatedBy: user?.UserName,
        updatedOn: new Date().toISOString(),
        UserId: userLoginResponse?.UserId,
        moduleName: "Article Master",
        activity: "create",
        createdBy: user?.UserName,

        // approved_By: user?.UserName,
        // approved_On: new Date().toISOString(),
        // remarks: "",
      };

      if (updatedFormData.articleId === 0) {
        await dispatch(ArticalMasterSubmit(updatedFormData));
      } else {
        updatedFormData = { ...updatedFormData, activity: "edit" };
        await dispatch(ArticalMasterEdit(updatedFormData));
      }
    } catch (error) {
      console.error("Error saving clause:", error);
      // dispatch(
      //   updateToaster({
      //     isToaster: true,
      //     ArticalMasterToasterMsg: `Failed to save clause`,
      //     isTosterFailed: true,
      //   })
      // );
    } finally {
      dispatch(updateLoader(false));
    }
  };
  console.log(
    ToasterMsg,
    isArticalMasterDataAdded,
    "isArticalMasterDataUpdated",
    isArticalMasterDataUpdated,
    "isArticalMasterDataUpdated"
  );
  useEffect(() => {
    const handleFunc = () => {
      if (isArticalMasterDataAdded || isArticalMasterDataUpdated) {
        onClose();
      }
    };
    handleFunc();
  }, [isArticalMasterDataAdded, isArticalMasterDataUpdated]);

  return (
    <Box>
      <Grid container sx={{ display: "flex", gap: "10px" }}>
        <Grid item xs={12} sm={12}>
          <Controller
            name="stateId"
            control={control}
            rules={{ required: "State is required" }}
            render={({ field }) => (
              <CustomAutoComplete
                // name="stateId"
                optionValueKey="Location_Id"
                optionLabelKey="Location_Name"
                options={locationData}
                readOnly={isView || isArticleActive}
                required={true}
                // addField={true}
                label="State"
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errors.stateId)}
                helperText={errors.stateId && errors.stateId.message?.toString()}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name="articleCode"
            control={control}
            rules={{
              required: "Article Code is required",
              validate: {
                noLeadingSpaces: (value) => !/^\s/.test(value.trim()) || "Leading spaces are not allowed",
                minLength: (value) => value.trim().length >= 1 || "Minimum 1 character is required",
                maxLength: (value) => value.trim().length <= 10 || "Maximum 10 characters allowed",
                ValueLimitation: (value) => {
                  const trimmedValue = value.trim();
                  if (!trimmedValue) return true;
                  return /^[a-zA-Z0-9,.\- ]+$/.test(trimmedValue) || "Cannot contain special characters";
                },
              },
            }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                label={"Article Code"}
                required
                readOnly={isView || isArticleActive}
                error={Boolean(errors.articleCode)}
                helperText={errors.articleCode?.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Controller
            name="articleNo"
            control={control}
            rules={{
              required: "Article Number is required",
              validate: {
                noLeadingSpaces: (value) => !/^\s/.test(value.trim()) || "Leading spaces are not allowed",
                minLength: (value) => value.trim().length >= 1 || "Minimum 1 character is required",
                maxLength: (value) => value.trim().length <= 20 || "Maximum 20 characters allowed",
                ValueLimitation: (value) => {
                  const trimmedValue = value.trim();
                  if (!trimmedValue) return true;
                  return /^[a-zA-Z0-9,.\- ]+$/.test(trimmedValue) || "Cannot contain special characters";
                },
              },
            }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                label={"Article Number"}
                required
                readOnly={isView || isArticleActive}
                error={Boolean(errors.articleNo)}
                helperText={errors.articleNo?.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Controller
            name="articleName"
            control={control}
            rules={{
              required: "Article Name is required",
              validate: {
                noLeadingSpaces: (value) => !/^\s/.test(value.trim()) || "Leading spaces are not allowed",
                minLength: (value) => value.trim().length >= 1 || "Minimum 1 character is required",
                maxLength: (value) => value.trim().length <= 200 || "Maximum 200 characters allowed",
                ValueLimitation: (value) => {
                  const trimmedValue = value.trim();
                  if (!trimmedValue) return true;
                  return /^[a-zA-Z0-9,.\- ]+$/.test(trimmedValue) || "Cannot contain special characters";
                },
              },
            }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                label={"Article Name"}
                required
                readOnly={isView || isArticleActive}
                error={Boolean(errors.articleName)}
                helperText={errors.articleName?.message?.toString()}
              />
            )}
          />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="end" alignItems="center" spacing={2} py={2}>
        <CustomButton
          variant="outlined"
          name={"Cancel"}
          size="small"
          onClick={() => {
            onClose();
          }}
        />
        <CustomButton
          variant="contained"
          name={isEdit ? "Update" : "Submit"}
          type="submit"
          size="small"
          disabled={isView || isArticleActive || !isDirty}
          onClick={handleSubmit(handleSave)}
        />
      </Stack>
    </Box>
  );
};

export default AddArticleMaster;
