import React, { useEffect, useState } from "react";
import CustomDialog from "../../../../common/dynamicDialog/CustomDialog";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store/store";
import { Box } from "@mui/material";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import { ACTIVE, ContractVendorGreetingName } from "../../../../utils/constants";
import { getWorkFlowByCode } from "../../../../store/slices/workFlowExecutionslice";
import { fetchWorkFlowByCode, getWorkList } from "../../../../store/slices/workFlowSlice";
import { getLovList } from "../../../../store/slices/lovMasterData";
import { IWorkflowData } from "../../../../models/workFlow.type";
import { updateLoader, updateToaster } from "../../../../store/slices/loader";
import { useNavigate } from "react-router-dom";
import { hideMenu, showMenu } from "../../../../store/slices/MenuShow";
import { getContentForm } from "../../../../store/slices/contentMasterData";
import OnlyOfficeTextEditorCM from "../../OnlyOfficeTextEditor/OnlyOfficeTextEditor";

interface IWelcomeGreetings {
  workFLowInstanceData: any;
}

export const WelcomeGreetings: React.FC<IWelcomeGreetings> = ({ workFLowInstanceData }) => {
  const currentWFInstance = workFLowInstanceData?.workflowInstance?.workflowProcessInstances?.find(
    (data: any) => data.processName === "Vendor Portal"
  );

  const dispatch = useAppDispatch();

  const [getGreetingMessage, setGreetingMessage] = useState<string>("");
  const { vendorConentPurposes } = useSelector((state: RootState) => state.contentData);
  const { workFlowsData } = useSelector((state: RootState) => state.workFlow);

  const [showEditor, setShownEditor] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, user, userLoginResponse, isLoginFailed, menuData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const loadData = () => {
    const consentPurposesArray = [ContractVendorGreetingName];
    const queryString = consentPurposesArray.reduce((acc, purpose, index) => {
      acc[`consentPurposes[${index}]`] = purpose;
      return acc;
    }, {} as Record<string, string>);
    dispatch(getContentForm(queryString));
  };
  useEffect(() => {
    loadData();
    dispatch(hideMenu());
  }, []);

  const cleanHtmlString = (html: string): string => {
    // Decode HTML entities
    const textarea = document.createElement("textarea");
    textarea.innerHTML = html;
    let decodedHtml = textarea.value;
    decodedHtml = decodedHtml.replace(/&nbsp;/g, "");
    decodedHtml = decodedHtml.replace(/<p>\s*<\/p>/g, "");
    return decodedHtml;
  };

  useEffect(() => {
    if (vendorConentPurposes && vendorConentPurposes.length > 0) {
      const customerOnboardingContent = vendorConentPurposes.filter((item: any) => item.IsActive);

      if (customerOnboardingContent.length > 0) {
        let message = customerOnboardingContent[0].Content_Text;

        if (user?.UserName) {
          message = message.replace("XXXX", `${user.UserName}`);
        }
        // if (user?.UserName) {
        //   message = message.replace("XXXX@yyy.com", `Hi ${user.UserName}`);
        // }
        setGreetingMessage(message);
      }
    }
  }, [vendorConentPurposes]);

  return (
    <React.Fragment>
      {!showEditor && (
        <CustomDialog
          show={true}
          minHeight={"50vh"}
          onHide={() => {}}
          header={"Welcome to C-Life"}
          style={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                overflowY: "hidden",
                margin: "0px !important",
                minWidth: "100vw important",
                minHeight: "100vh !important",
              },
            },
          }}
          isCloseIconDisabled={true}
          contentNode={
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <div dangerouslySetInnerHTML={{ __html: getGreetingMessage }} />
            </Box>
          }
          actionNode={
            <CustomButton
              type="submit"
              variant="contained"
              disabled={getGreetingMessage === ""}
              name={"Ok"}
              onClick={() => {
                setShownEditor(true);
              }}
            />
          }
        />
      )}
      {showEditor && currentWFInstance && (
        <Box>
          <OnlyOfficeTextEditorCM
            isEditData={currentWFInstance}
            onClose={() => {}}
            processName={"Vendor Portal"}
            executeVendorWF={true}
          />
        </Box>
      )}
    </React.Fragment>
  );
};
