import { Box, Grid, Stack, Typography } from "@mui/material";
import * as mime from "react-native-mime-types";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatch } from "../../../store/store";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import InitiateContract from "./InitiateContract";
import {
  getContractList,
  updateContractDetailsAddandEdit,
  activeInactiveContract,
  getContractById,
  getContractDetailsById,
} from "../../../store/contractManagement/slices/contractListSlice";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { updateHeaderMenu, updateMenu } from "../../../store/slices/menu";
import OnlyOfficeTextEditor from "../../../common/textEditor/OnlyOfficeTextEditor";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { getCompanyById } from "../../../store/slices/companyMasterData";
import moment from "moment";
import { ContractManagemnt_InternalCode, LocationConfig } from "../../../config/config";
import { ACTIVE, DAY_MILLI_SECS } from "../../../utils/constants";
import { getContractSummary, getTemplateById } from "../../../store/contractManagement/slices/templateData";
import { getLocationList } from "../../../store/slices/locationMasterData";
import fileHandlerService from "../../../store/services/fileHandlerService";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomMultiSelect from "../../../common/dynamicInputs/CustomMultiSelect";

const columns = {
  table_name: "Contract",
  isAction: true,
  columns: [
    {
      Field: "contract_Number",
      FieldType: "string",
      DisplayName: "Contract No",
    },
    {
      Field: "contractType",
      FieldType: "string",
      DisplayName: "Contract Type",
    },
    {
      Field: "startDate",
      FieldType: "date",
      DisplayName: "Start Date",
    },
    {
      Field: "endDate",
      FieldType: "date",
      DisplayName: "End date",
    },
    {
      Field: "services",
      FieldType: "string",
      DisplayName: "Service",
    },
    {
      Field: "amount",
      FieldType: "number",
      DisplayName: "Amount",
    },
    {
      Field: "workflowStatusString",
      FieldType: "string",
      DisplayName: "Workflow Status",
    },
    {
      Field: "isActive",
      FieldType: "string",
      DisplayName: "Status",
    },
  ],
};

export interface IContractListProps {
  view?: boolean;
  vendorId?: any;
}

const ContractMaster: React.FC<IContractListProps> = ({ view = false, vendorId = null }) => {
  const dispatch = useAppDispatch();
  const { contractDetailsData, isContractDetailsDeleted, ToasterMsg } = useSelector(
    (state: any) => state.contractManagementReducer?.contractlist
  );
  const { user } = useSelector((state: RootState) => state.userData);
  const [contractData, setContractData] = useState<any>(null);
  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { locationTable } = useSelector((state: RootState) => state.locationMasterData);

  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    isRolesAndResponsibilityAdded,
    isRolesAndResponsibilityUpdated,
    isLoading,
    isRolesAndResponsibilityDeleted,
    workFLowInstanceData,
  }: any = contractManagementReducer.contractRolesAndResponsibility;
  const [filename, setFileName] = useState<string>("");
  const [HeaderColumn] = useState(columns);
  const [contractlist, setContractList] = useState<any[]>([]);
  const [permssionPage, setPermissionForpage] = useState<any>();
  const [Option, setOption] = useState("");
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [isEditData, setIsEditDate] = useState<any>(null);
  const [isShowDraft, setisShowDraft] = useState<any>(1);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [isViewDocument, setIsViewDocument] = useState<boolean>(false);
  const [updatedTemplateId, setUpdatedTemplatedId] = useState<any>();

  const [contractDetails, setContractDetails] = useState<any>(null);
  const [companyDetails, setCompanyDetails] = useState<any>(null);
  const [vendorDetails, setVendorDetails] = useState<any>(null);
  const [isPhysicalUpload, setIsPhysicalUpload] = useState<boolean>(false);

  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const [renewalDate, setRenewalDate] = useState<number | null>(null);

  const [showCurrentWFstatus, setShowCurrentWFstatus] = useState<boolean>(false);

  const { REACT_APP_TEXT_EDITOR, REACT_APP_API_BLOB_URL, REACT_APP_API_BLOB_URL_CONTAINER } = process.env;

  const { currentpageTitleData } = useSelector((state: RootState) => state.FormTitleMasterData);

  const [selectedContractType, setSelectedContractType] = useState("All");
  const [selectedVendor, setSelectedVendor] = useState("All");
  const [selectedService, setSelectedService] = useState(["All"]);
  const [filteredContracts, setFilteredContracts] = useState<any[]>(contractlist || []);

  useEffect(() => {
    dispatch(
      getLocationList({
        LocationTypeId: 0,
      })
    );
  }, []);

  const renderHTML = (content: any) => {
    if (!content) return <p>No content available</p>;

    const sanitizeContent = (html: string) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");

      doc.querySelectorAll("[style]").forEach((element) => {
        element.removeAttribute("style");
      });

      return doc.body.innerHTML;
    };

    const sanitizedContent = sanitizeContent(content);

    if (sanitizedContent.includes("<table>")) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(sanitizedContent, "text/html");
      const table = doc.querySelector("table");
      if (table) {
        const headers = Array.from(table.querySelectorAll("thead th")).map((header) => header.textContent || "");
        const rows = Array.from(table.querySelectorAll("tbody tr")).map((row) =>
          Array.from(row.querySelectorAll("td")).map((cell) => cell.textContent || "")
        );

        return (
          <table border={1} style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
    }

    if (sanitizedContent.startsWith("http") && /\.(jpg|jpeg|png|gif|svg)$/.test(sanitizedContent)) {
      return <img src={sanitizedContent} alt="Clause Content" style={{ maxWidth: "100%", height: "auto" }} />;
    }

    return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
  };

  const processVendorData = (vendorData: any[]) => {
    const commonObject: any = {};
    const modifiedClauses: any[] = [];

    if (vendorData.length > 0) {
      const referenceClause = vendorData[0];
      for (const key in referenceClause) {
        const isCommon = vendorData.every((item: any) => item[key] === referenceClause[key]);
        if (isCommon) {
          commonObject[key] = referenceClause[key];
        }
      }

      vendorData.forEach((item: any) => {
        const changes: any = {};
        for (const key in item) {
          if (item[key] !== commonObject[key]) {
            changes[key] = item[key];
          }
        }
        modifiedClauses.push(changes);
      });
    }

    return { commonObject, modifiedClauses };
  };

  const groupClauses = (modifiedClauses: any[]) =>
    modifiedClauses.map((item: any) => ({
      TaggingRisk: item?.taggingRisk || "",
      Header: renderHTML(item?.clause_Contract_Header || item?.clause_Header),
      Content: renderHTML(item?.clause),
    }));

  const formatDate = (date: string) => (date ? moment(date).format("DD-MMM-YYYY") : "");

  const columns_version: any = {
    table_name: "Vendor History Records",
    isAction: false,
    columns: [],
  };

  useEffect(() => {
    initializeData();
    setIsViewDocument(false);
  }, []);

  const initializeData = () => {
    dispatch(getContractList());
  };

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  function prepareContractDetails(data: any, companyData: any, vendorData: any) {
    const { commonObject, modifiedClauses } = processVendorData(vendorData);
    const clausesGrouped = groupClauses(modifiedClauses);

    return {
      "Contract No": data.contract_Number,
      "Contract type": data.contractType,
      Company: companyData[0]?.Legal_Name || "",
      Vendor: vendorData[0]?.vendor_Legal_Name || "",
      Services: Array.isArray(data.services) ? data.services : [data.services],
      "Contract Amount": `${vendorData[0]?.currency_Symbol || ""} ${vendorData[0]?.project_Cost || "0.00"}`,
      Jurisdiction: locationTable.find((e: any) => e.Location_Id === companyData[0]?.Jurisdiction)?.Location_Name || "",
      "Contract Period": `${formatDate(vendorData[0]?.contract_Start_Date)} - ${formatDate(
        vendorData[0]?.contract_End_Date
      )}`,
      ClauseWithHighRisk: {
        title: "Clause with High Risk",
        data: clausesGrouped.filter((clause) => clause.TaggingRisk.toLowerCase() === "high"),
      },
    };
  }

  const handleOpen = async (data: any, option: string) => {
    if (option === "history") {
      setOption(option);
      setIsAddorEdit(false);
      // setIsVersionHistory(true);
    } else if (option === "delete") {
      setOption(option);
      setIsDeleteData(data);
    } else if (option === "edit" || option === "clone" || option === "renew") {
      dispatch(updateLoader(true));
      dispatch(getContractById(data.contractId))
        .unwrap()
        .then((contractDetils: any) => {
          setIsEditDate({ ...contractDetils });
          setIsAddorEdit(true);
          setOption(option);
        })
        .catch((error) => {
          //  console.log(error);
        });
    } else if (option === "view") {
      dispatch(updateLoader(true));
      //  console.log("OpenDocument");
      //  console.log(data, "Data Content");

      try {
        const [companyData, vendorData] = await Promise.all([
          dispatch(getCompanyById(data.company)).unwrap(),
          dispatch(getContractSummary(data.contractId)).unwrap(),
        ]);
        const isStampingNeeded = vendorData[0]?.isStampingNeeded || false;
        const templateData = await dispatch(getTemplateById(vendorData[0]?.vendor_Contract_Template_Id)).unwrap();
        setFileName(templateData?.contract_Template_Name);
        //  console.log(!isStampingNeeded, "templateDatatemplateDatatemplateData");
        setIsPhysicalUpload(!isStampingNeeded);
        if (data?.workflowStatus === true) {
          const contractDetails = prepareContractDetails(data, companyData, vendorData);
          setContractDetails(contractDetails);

          setIsViewDocument(true);
        } else {
          setShowCurrentWFstatus(true);
        }
      } catch (error) {
        console.error("Error fetching contract details or processing data:", error);
      } finally {
        dispatch(updateLoader(false));
      }
    }
  };

  const setAddFormcloseoropen = (value: any) => {
    setIsAddorEdit(value);
    setIsEditDate(null);
    setOption("");
    initializeData();
  };

  useEffect(() => {
    if (vendorId !== null) {
      let filterDataVendorData: any = [];
      filterDataVendorData = contractDetailsData.filter((data: any) => data.vendor === Number(vendorId));
      setContractList(filterDataVendorData);
    } else {
      setContractList(contractDetailsData);
    }
  }, [contractDetailsData, vendorId]);

  const loadData = () => {
    if (vendorId !== null) {
      let filterDataVendorData: any = [];

      filterDataVendorData = contractDetailsData.filter((data: any) => data.vendor === Number(vendorId));

      setContractList(filterDataVendorData);
      setFilteredContracts(filterDataVendorData);
    } else if (renewalDate !== null && vendorId === null) {
      let filterDataVendorData = contractDetailsData.map((data: any) => {
        const currentDate = new Date();
        const endDate = new Date(data.endDate);
        let diff = endDate.getTime() - currentDate.getTime();
        const difference = Math.floor(diff / DAY_MILLI_SECS) + 1;
        return {
          ...data,
          isRenewalEnabled: difference <= renewalDate && data.endDate !== null ? true : false,
        };
      });
      //  console.log("vendorList", filterDataVendorData);
      setContractList(filterDataVendorData);
      setFilteredContracts(filterDataVendorData);
    } else {
      setContractList(contractDetailsData);
      setFilteredContracts(contractDetailsData);
    }
  };

  useEffect(() => {
    loadData();
  }, [contractDetailsData, vendorId, renewalDate]);

  let isOnline = false;

  console.log(contractDetailsData, "contractDetailsData");

  const offileFileConverter = async (
    blobUrl: string
  ): Promise<{ data: string; mimeType: string; fileName: string }> => {
    try {
      const response = await fileHandlerService.DownLoadAFile({ fileUrl: blobUrl });
      const { data } = response;
      return data;
    } catch (error) {
      console.error("Error in offileFileConverter:", error);
      throw error;
    }
  };

  const downloadFileOffline = (base64Data: any, fileName: string, mimeType: string) => {
    try {
      const byteCharacters = atob(base64Data);
      const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = `${fileName}`;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error in downloadFileOffline:", error);
    }
  };

  const urlGenerator = async (
    fileName: string,
    userId: string,
    type: string,
    isOnlyOffice: boolean
  ): Promise<string | null> => {
    try {
      if (isOnlyOffice) {
        return `${REACT_APP_TEXT_EDITOR}webeditor.ashx?type=${type}&fileName=${fileName}&userid=${userId}`;
      } else {
        if (!isOnlyOffice) {
          const blobUrl = `${REACT_APP_API_BLOB_URL}/${REACT_APP_API_BLOB_URL_CONTAINER}/${fileName}`;
          const data = await offileFileConverter(blobUrl);
          downloadFileOffline(data, fileName, "pdf");
          return null;
        }
        return null;
      }
    } catch (error) {
      console.error("Error in urlGenerator:", error);
      return null;
    }
  };

  const handleDownloadAsWord = async () => {
    if (userLoginResponse) {
      try {
        const url = await urlGenerator(`${filename}.docx`, userLoginResponse.UserId as any, "download", true);
        if (url) {
          window.open(url, "_blank", "noopener,noreferrer");
        }
      } catch (error) {
        console.error("Error in handleDownloadAsWord:", error);
      }
    } else {
      console.error("UserLoginResponse is not available");
    }
  };

  const handleDownloadAsPDF = async () => {
    if (userLoginResponse) {
      try {
        const url = await urlGenerator(
          `${filename}.pdf`,
          userLoginResponse.UserId as any,
          "download",
          isPhysicalUpload ? false : true
        );
        if (url) {
          window.open(url, "_blank", "noopener,noreferrer");
        }
      } catch (error) {
        console.error("Error in handleDownloadAsPDF:", error);
      }
    } else {
      console.error("UserLoginResponse is not available");
    }
  };

  const handleDelete = () => {
    let inactiveFormData = {
      vendor_Contract_Id: isDeleteData.contractId,
      isActive: isDeleteData?.isActive === "Active" ? false : true,
      updated_By: userLoginResponse?.UserName,
      approved_By: userLoginResponse?.UserName,
      userId: userLoginResponse?.UserId,
      moduleName: "Contracts",
      activity: isDeleteData?.isActive === "Active" ? "inactive" : "active",
      createdBy: userLoginResponse?.UserName,
    };
    dispatch(activeInactiveContract(inactiveFormData));
  };

  useEffect(() => {
    if (isContractDetailsDeleted === true) {
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.message,
          isTosterFailed: ToasterMsg.status !== "Success",
        })
      );
      dispatch(updateContractDetailsAddandEdit(false));
      initializeData();
      setIsDeleteData("");
      setOption("");
    }
  }, [ToasterMsg, isContractDetailsDeleted]);

  let role = userLoginResponse?.UserRole ? userLoginResponse?.UserRole : "";
  //  console.log(isEditData, workFLowInstanceData, "isEditDataisEditData");
  useEffect(() => {
    if (workFLowInstanceData !== null) {
      let { REACT_APP_API_URL, REACT_APP_API_CM_URL } = process.env;
      let workFlowInstanceProcessData = workFLowInstanceData?.workflowInstance?.workflowProcessInstances;
      setContractData(workFLowInstanceData);
      setFileName(workFLowInstanceData?.contract_Tempate_Name);
      localStorage.setItem("contract_Tempate_Id", `${workFLowInstanceData?.contract_Tempate_Id}`);
      //  console.log(role, "rolerolerole");
      localStorage.setItem("formName", isEditData?.FormName);
      localStorage.setItem("USER_ROLE_KEY", role);
      localStorage.setItem("processName", "processName");
      localStorage.setItem("APIURL", REACT_APP_API_URL ? REACT_APP_API_URL : "");
      localStorage.setItem("CMAPIURL", REACT_APP_API_CM_URL ? REACT_APP_API_CM_URL : "");

      if (user) {
        const formattedUser: any = {
          UserId: user.UserId,
          FirstName: user.FirstName,
          LastName: user.LastName,
          EmailId: user.EmailId,
          Mobile: user.Mobile,
          Country: user.Country,
          OfficeLocation: user.OfficeLocation,
          OfficeAddress: user.OfficeAddress,
          IsActive: user.IsActive,
          RoleId: user.RoleId,
          RoleName: user.RoleName,
          DepartmentId: user.DepartmentId,
          DepartmentName: user.DepartmentName,
          ManagerId: user.ManagerId,
          ManagerEmail: user.ManagerEmail,
          Updated_By: user.Updated_By,
          Updated_On: user.Updated_On,
          DesignationId: user.DesignationId,
          DesignationName: user.DesignationName,
        };
        localStorage.setItem("userData", JSON.stringify({ userData: formattedUser }));
      }
      const vendorPortal = {
        projectId: "projectId",
        workflowInstanceId: "workflowInstanceId",
        processInstanceId: "processInstanceId",
        company_Name: workFLowInstanceData?.company_Name || "",
        company_Id: workFLowInstanceData?.company_Id || null,
        vendor_Id: workFLowInstanceData?.vendor_Id || null,
        vendor_Name: workFLowInstanceData?.vendor_Name || "",
        vendor_Contract_Id: workFLowInstanceData?.vendor_Contract_Id || null,
        contract_Ref_Number: workFLowInstanceData?.contract_Ref_Number || "",
        contract_Tempate_Id: workFLowInstanceData?.contract_Tempate_Id || null,
        project_Cost: `${workFLowInstanceData?.currency_code || "₹"} ${parseFloat(
          workFLowInstanceData?.project_Cost || "0.00"
        ).toFixed(2)}`,
        service_Names: "",
        payloadForUpdate: {},
      };

      const services = workFLowInstanceData?.serviceMappings || [];
      const serviceNames = Array.isArray(services)
        ? services.map((service) => service?.services?.service_Name).join(", ")
        : "";

      vendorPortal.service_Names = serviceNames;
      vendorPortal.payloadForUpdate = {
        workflowInstanceId: isEditData?.workflowInstanceId,
        processInstanceId: isEditData?.id,
        conditionValue: 0,
        projectId: "projectId",
        updatedBy: userLoginResponse ? userLoginResponse.UserName : "",
      };

      localStorage.setItem("Vendor_Portal", JSON.stringify(vendorPortal));
      localStorage.setItem("contract_Tempate_Id", `${workFLowInstanceData?.contract_Tempate_Id}`);
    }
  }, [workFLowInstanceData, isEditData]);
  //  console.log(contractDetails, "Contract_number");
  useEffect(() => {
    if (applicationParameterData.length > 0) {
      let contactManagemtnAppParms: any[] = applicationParameterData?.filter(
        (item) => item.Parameter_Group === ContractManagemnt_InternalCode.ContractManagement
      );
      if (contactManagemtnAppParms.length > 0) {
        let renwalDays = contactManagemtnAppParms.filter(
          (data: any) => data.Parameter_Name === ContractManagemnt_InternalCode.RenewalDate
        )[0].Parameter_Value;
        if (renwalDays) {
          setRenewalDate(Number(renwalDays));
        } else {
          setRenewalDate(null);
        }
      }
    }
  }, [applicationParameterData]);

  let allOption = { Name: "All" };
  let vendorName = [
    allOption,
    ...Array.from(new Set(contractlist.map((contract: any) => contract.vendorName)))
      .map((vendorName: any) => ({ Name: vendorName.toString() }))
      .sort((a, b) => a.Name.localeCompare(b.Name)),
  ];
  const handleFilterChange = () => {
    let filtered: any[] = [];
    let filteredServices: any[] = [];
    if (contractlist && contractlist.length > 0) {
      filtered = contractlist.filter((contract: any) => {
        const matchesContractType = selectedContractType === "All" || contract.contractType === selectedContractType;
        const matchesVendor = selectedVendor === "All" || contract.vendorName === selectedVendor;

        const matchServices = selectedService.filter((data: any) => {
          let flag= false
          if (data.toLowerCase() !== "all") {
            if (contract.services.toLowerCase().includes(data.toLowerCase())) {
              filteredServices.push(contract);
               flag = true;
            }
          } else {
             flag = true;
          }
          return flag
        });
        console.log(matchesContractType,matchesVendor,"matchesVendormatchesVendor")

        return matchesContractType && matchesVendor && matchServices.length > 0;
      });
      console.log(filtered, `!filtered`);
      console.log(filteredServices, "filteredServices~filteredServices");
    }

    setFilteredContracts(filtered);
  };

  console.log(filteredContracts, "`filteredContractsfilteredContracts");

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {!showCurrentWFstatus && (
          <Stack spacing={2}>
            {!isAddorEdit ? (
              <React.Fragment>
                {view ? null : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <Typography variant="h6" color={"text.primary"}>
                      Contract Repository
                    </Typography>
                  </Box>
                )}
                {contractlist && contractlist.length > 0 && (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Grid
                      sx={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <CustomSelect
                        name={"contractType"}
                        valueKey={"Name"}
                        optionLabelKey="Name"
                        showAllOption={false}
                        options={[
                          allOption,
                          ...Array.from(new Set(contractlist.map((contract: any) => contract.contractType))).map(
                            (type) => ({ Name: type })
                          ),
                        ]}
                        value={selectedContractType}
                        onChange={(e: any) => setSelectedContractType(e)}
                        required={true}
                        label={"Select Contract Type"}
                      />
                      <CustomSelect
                        name={"vendor"}
                        valueKey={"Name"}
                        optionLabelKey="Name"
                        showAllOption={false}
                        isSort={false}
                        options={[
                          allOption,
                          ...Array.from(new Set(contractlist.map((contract: any) => contract.vendorName)))
                            .map((vendorName: any) => ({ Name: vendorName.toString() }))
                            .sort((a, b) => a.Name.localeCompare(b.Name)),
                        ]}
                        value={selectedVendor}
                        onChange={(e) => setSelectedVendor(e)}
                        required={true}
                        label={"Select Vendor"}
                      />
                      <CustomMultiSelect
                        optionIdKey="Name"
                        optionLabelKey="Name"
                        options={[
                          { Name: "All" },
                          ...Array.from(
                            new Set(
                              contractlist
                                .flatMap((contract: any) => contract.services.split(","))
                                .map((service: any) => service.trim())
                            )
                          )
                            .sort((a, b) => a.localeCompare(b))
                            .map((service) => ({ Name: service })),
                        ]}
                        value={selectedService}
                        onChange={(e) => {
                          const selectedValues = Array.isArray(e.target.value) ? e.target.value : [];
                          if (selectedValues.includes("All")) {
                            setSelectedService(["All", ...selectedValues.filter((option: any) => option !== "All")]);
                          } else {
                            setSelectedService(selectedValues.filter((option: any) => option !== "All"));
                          }
                        }}
                        // value={field.value}
                        // onChange={(field:any) => {
                        //   setValue(field.target.value)
                        //   const dname = field.target.value.map((id:number)=>dataTable.find((data:any)=> data.DepartmentId ===id).DepartmentName)
                        //   setValue("DepartmentName", dname.toString())
                        // }}

                        required={true}
                        label={"Select Service"}
                      />
                      {/* <CustomDatePicker
                        minDate={dayjs().subtract(100, "year").toDate()}
                        maxDate={dayjs().toDate()}
                        label={"From Date"}
                        value={startDate ? dayjs(startDate) : null}
                        readOnly={false}
                        onChange={(date: any) => setStartDate(date ? dayjs(date).format("YYYY-MM-DD") : "")}
                        error={!!dateError.fromDate}
                        helperText={dateError.fromDate}
                        format="DD-MM-YYYY"
                      />
                      <CustomDatePicker
                        minDate={dayjs().subtract(100, "year").toDate()}
                        maxDate={dayjs().toDate()}
                        label={"To Date"}
                        value={endDate ? dayjs(endDate) : null}
                        readOnly={false}
                        onChange={(date: any) => setEndDate(date ? dayjs(date).format("YYYY-MM-DD") : "")}
                        error={!!dateError.toDate}
                        helperText={dateError.toDate}
                        format="DD-MM-YYYY"
                      /> */}
                      {/* <CustomDatePicker
                        label={"From Date"}
                        value={startDate ? dayjs(startDate) : null}
                        readOnly={false}
                        onChange={(date: any) => setStartDate(date ? dayjs(date).format("YYYY-MM-DD") : "")}
                        error={!!dateError.fromDate}
                        helperText={dateError.fromDate}
                        format="DD-MM-YYYY"
                      />
                      <CustomDatePicker
                        label={"To Date"}
                        value={endDate ? dayjs(endDate) : null}
                        readOnly={false}
                        onChange={(date: any) => setEndDate(date ? dayjs(date).format("YYYY-MM-DD") : "")}
                        error={!!dateError.toDate}
                        helperText={dateError.toDate}
                        format="DD-MM-YYYY"
                      /> */}
                      <CustomButton
                        variant="contained"
                        name={"Submit"}
                        // disabled={!!dateError.fromDate || !!dateError.toDate}
                        onClick={handleFilterChange}
                        size="small"
                      />
                    </Grid>
                  </Box>
                )}

                {filteredContracts && contractlist.length > 0 && (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <MaterialDynamicGrid
                      data={HeaderColumn}
                      rows={filteredContracts}
                      handleAction={handleOpen}
                      handleAdd={() => setAddFormcloseoropen(true)}
                      enableZoomIcon={true}
                      showhistory={false}
                      isDraftView={false}
                      draftCount={0}
                      isEnableDraft={false}
                      handleIsDraft={(val: any) => setisShowDraft(val)}
                      showAdd={!view}
                      showDelete={!view}
                      showView={!view}
                      showEdit={false}
                      showClone={true}
                      showRenewal={true}
                      showExports={false}
                      userId={userLoginResponse?.UserId || 0}
                      pageName="Contract Repository"
                      disableselect={true}
                      isActivekey="isActive"
                      showTerminate={true}
                      isRenewalDisableByValue={[
                        { key: "workflowStatus", value: true },
                        { key: "isRenewalEnabled", value: true },
                      ]}
                      isCloneDisableByValue={[{ key: "workflowStatus", value: true }]}
                      // isRunDisableByValue={[{ key: "workflowStatus", value: true }]}
                      isDeleteDisableByValue={[{ key: "workflowStatus", value: true }]}
                    />
                  </Box>
                )}
              </React.Fragment>
            ) : (
              <Box sx={{ width: "100%" }}>
                <InitiateContract
                  view={Option == "view" || (isEditData && !isEditData?.isActive) ? true : false}
                  edit={Option == "edit" ? true : false}
                  clone={Option == "clone" ? true : false}
                  renewal={Option == "renew" ? true : false}
                  isEditData={isEditData}
                  onClose={() => setAddFormcloseoropen(false)}
                  // isDraftData={isDraftData}
                />
              </Box>
            )}
          </Stack>
        )}
        {showCurrentWFstatus && (
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
              <CustomButton
                variant="outlined"
                name={"Back"}
                onClick={() => {
                  setShowCurrentWFstatus(false);
                  setFileName("");
                }}
                size="small"
              />
            </Box>

            {filename && (
              <OnlyOfficeTextEditor
                fileName={`${filename}.docx`}
                editorsMode={"view"}
                isOpenDocument={true}
                autoStart={false}
              />
            )}
          </Box>
        )}
        <ModalPopUp
          onDeleteMethod={() => handleDelete()}
          isDelete={true}
          onHide={() => setIsDeleteData(null)}
          show={isDeleteData ? true : false}
          header={"Confirm"}
          text={`Are you sure you want to ${
            isDeleteData?.isActive === "Active" ? "deactivate" : "activate"
          } the selected Contract`}
          ActionName={isDeleteData?.isActive === "Active" ? "Inactive" : "Active"}
        />

        <CustomDialog
          show={isViewDocument}
          onHide={() => setIsViewDocument(false)}
          maxWidth={"lg"}
          minHeight={"50vh"}
          header={contractDetails ? contractDetails?.["Contract No"] : "Contract Summary"}
          contentNode={
            contractDetails ? (
              <div>
                <div style={{ maxHeight: "70vh", overflowY: "auto", paddingRight: "5px" }}>
                  <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px" }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            padding: "8px",
                            border: "1px solid #ddd",
                            fontFamily: "aptos-bold",
                          }}
                        >
                          Parameter
                        </th>
                        <th
                          style={{
                            textAlign: "left",
                            padding: "8px",
                            border: "1px solid #ddd",
                            fontFamily: "aptos-bold",
                          }}
                        >
                          Value
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(contractDetails).map(([key, value]: any) => {
                        if (
                          typeof value === "object" &&
                          value !== null &&
                          "title" in value &&
                          Array.isArray(value.data)
                        ) {
                          const section = value as any;
                          //  console.log(section, "sectionsection");
                          return (
                            <React.Fragment key={key}>
                              <tr>
                                <td colSpan={2}>
                                  <p
                                    style={{
                                      padding: "8px",
                                      marginBottom: "0px",
                                      fontFamily: "aptos-bold",
                                      background: "#f9f9f9",
                                    }}
                                  >
                                    {section.title}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{ padding: "0", border: "none" }}>
                                  <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                                    <table style={{ width: "100%", borderCollapse: "collapse", margin: "10px 0" }}>
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              textAlign: "left",
                                              padding: "8px",
                                              border: "1px solid #ddd",
                                              fontFamily: "aptos-bold",
                                            }}
                                          >
                                            Header
                                          </th>
                                          <th
                                            style={{
                                              textAlign: "left",
                                              padding: "8px",
                                              border: "1px solid #ddd",
                                              fontFamily: "aptos-bold",
                                            }}
                                          >
                                            Content
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {section.data.length > 0 ? (
                                          section.data.map((item: any, index: any) => (
                                            <tr key={index}>
                                              <td style={{ padding: "8px", border: "1px solid #ddd", width: "22%" }}>
                                                <p style={{ position: "sticky" }}>{item.Header}</p>
                                              </td>
                                              <td style={{ padding: "8px", border: "1px solid #ddd", width: "50%" }}>
                                                {item.Content}
                                              </td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan={2}
                                              style={{ padding: "8px", border: "1px solid #ddd", textAlign: "center" }}
                                            >
                                              No Data Available
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <tr key={key}>
                              <td style={{ padding: "8px", border: "1px solid #ddd" }}>{key}</td>
                              <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                                {Array.isArray(value) ? value.join(", ") : value}
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <CustomButton
                      onClick={() => {
                        handleDownloadAsWord();
                        //  console.log("Download as Word");
                      }}
                      name="Download as Word"
                      color="primary"
                      variant="contained"
                    />
                    <CustomButton
                      disabled={false}
                      onClick={() => {
                        handleDownloadAsPDF();
                        //  console.log("Download as PDF");
                      }}
                      name="Download as PDF"
                      variant="contained"
                    />
                  </Box>
                </div>
              </div>
            ) : (
              <p>Loading...</p>
            )
          }
        />
      </Box>
    </>
  );
};

export default ContractMaster;
