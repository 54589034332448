import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { addWorkingDays, textFieldWidth } from "../../../utils/constants";
import LableandValuesPreview from "../../../common/CommonPreview/LableandValuesPreview";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import PageMainHeading from "../../../common/CommonHeadings/PageMainHeading";
import ExcelDownload from "../../../common/CommonSheetsDownload/ExcelDownload";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import { RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { LovConfig } from "../../../config/config";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { VendorHistory } from "../../vendorHistory/VendorHistory";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { addCustomerSignOff, getCustomerSignOffbyId, updateCustomerSignOff } from "../../../store/slices/customerSignoff";
import dayjs from "dayjs";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { COBProcessPreviews } from "../customerOnBoardingProcessPreview/COBProcessPreview";
import { useTheme } from "@mui/material/styles";
import { getServiceCatalogList } from "../../../store/slices/serviceCatalog";
//cancel send to cxo

interface CustomerOnBoardingCXOApprovalProps {
    isEditData?: any;
    view?: boolean;
    edit?: boolean;
    isViewOnly?: boolean;
    currentProcessData?: any;
    prePageName?: any;
    onclose?: (val? : any) => void;
    onsubmitso?: (val : any) => void;
    SoPreview?: boolean
  }
const COBCXOApproval : React.FC<CustomerOnBoardingCXOApprovalProps> = ({
    isEditData,
    view,
    currentProcessData,
    prePageName,
    onclose,
    isViewOnly,
    edit,
    onsubmitso,
    SoPreview = false,
  }) => {
    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
      );
      const {
        ServiceCatlogdata,
      } = useSelector((state: RootState) => state.serviceCatalog);
      const theme = useTheme();
      const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
      const { CustomerSignOffdataTable , isAddEditCustomerSignOffLoading ,isCustomerSignOffAdded , isCustomerSignOffLoading , CustomerSignOffToasterMsg } = useSelector((state: RootState) => state.CustomerSignoffData);
      const currentDate = dayjs().format("DD/MM/YYYY");
  const [VendorStatus, setVendorStatus] = useState<any>([]);
      const dispatch = useAppDispatch();
      const [isConform, setisConform] = useState<any>(false);
      const [submitstatus, setsubmitstatus] = useState<any>("");
    const [remark, setRemark] = useState<any>("");
    const [remarkerror, setErrorRemark] = useState<any>("");
    const [customerDetails, setcustomerDetails] = useState<any>({});
    const [cancelBtnDisabled,setcancelBtnDisabled]=useState(true)
    const [signOffBtnDisabled,setsignOffBtnDisabled]=useState(true)
    const [terminateBtnDisabled,setterminateBtnDisabled]=useState(true)
    const [submitBtnDisabled,setsubmitBtnDisabled]=useState(true)
    const [previewsection, setpreviewsection] = useState<any>(false);
    const [serviceTypes, setserviceTypes] = useState<any>("");
  const converttodateformate = (dateStr : any) => {
    const dateObj = dayjs(dateStr).format("DD/MM/YYYY");
return  dateObj;
  }  

  useEffect(() => {
    dispatch(getServiceCatalogList());
  },[])
    useEffect(() => {
        if(CustomerSignOffdataTable){
            let serviceStart = converttodateformate(CustomerSignOffdataTable?.Project_Start_Date)
            let serviceEnd = converttodateformate(CustomerSignOffdataTable?.Project_End_Date)
            let mergeredservicedate = `${serviceStart} - ${serviceEnd} `
            setcustomerDetails({...CustomerSignOffdataTable , service_date  : mergeredservicedate })
        }
       
    },[CustomerSignOffdataTable])

    useEffect(() => {
      if(customerDetails && customerDetails?.No_Of_Service_Mapped && ServiceCatlogdata){
           
 
             try{
              let ServiceType : any = []
             const Services : any[] = JSON.parse(customerDetails?.No_Of_Service_Mapped)
             Services.forEach((serID : any) => {
              
             let mappedService : any =   ServiceCatlogdata.find((serviceItem) => serviceItem?.ServiceCatalogId == serID);
             if(mappedService && mappedService?.ServicetypeName){
              ServiceType.push(mappedService?.ServicetypeName)
             }
             });
             setserviceTypes(ServiceType?.join(","))
             
             }catch{

             }
             
      }
     
    },[customerDetails , ServiceCatlogdata])

    useEffect(() => {
      dispatch(updateLoader(isAddEditCustomerSignOffLoading));
    },[isAddEditCustomerSignOffLoading])
    useEffect(() => {
      if (onclose && isCustomerSignOffAdded) {
        dispatch(updateCustomerSignOff(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: CustomerSignOffToasterMsg }));
        onclose();
      }
    }, [isCustomerSignOffAdded]);
    useEffect(() => {
        setVendorStatus(lovTable.filter((item: any) => item.Name === LovConfig.VendorStatus) || []);
      }, [lovTable]);

      useEffect(() => {
        if (currentProcessData) {
          dispatch(
            getCustomerSignOffbyId({
                customerId: currentProcessData?.VendorId,
              project_Detail_Id: currentProcessData?.ProjectId,
            })
          );
        }
      }, [currentProcessData]);

    const conformTheSignoff = (val: any) => {
        if (remark) {
          setsubmitstatus(val);
          setisConform(true);
        } else {
          setErrorRemark("Remarks is required");
        }
      };
    
      const closeConformationPopup = () => {
        setsubmitstatus("");
        setisConform(false);
      };


    const submitvendorSignOff = () => {
        const val = submitstatus;
        if (remark) {
          setErrorRemark("");
          let findedStatus =
            VendorStatus.find((item: any) => item?.Options == (val == 1 ? "Active" : "Terminated"))?.LovId || 0;
    
          let obj: any = {
            ModuleName: currentProcessData?.FormName,
            Activity: val == 1 ? "Create" : "Rejected",
            UserId: userLoginResponse?.UserId,
            CreatedBy: userLoginResponse?.UserEmail,
            MenuId: currentPagePermissionData?.MenuId,
            Form_Id: currentProcessData?.ProcessId,
            Vendor_Id: currentProcessData?.VendorId,
            Project_Detail_Id: currentProcessData ? currentProcessData?.ProjectId : 0,
            IsRenewal: currentProcessData?.IsRenewal ? currentProcessData?.IsRenewal : false,
            Workflow_InstanceId: currentProcessData ? currentProcessData?.Workflow_InstanceId : 0,
            Process_InstanceId: currentProcessData ? currentProcessData?.Id : 0,
            Remarks: remark,
            Status: findedStatus,
          };
    
          dispatch(addCustomerSignOff(obj));
        } else {
          setErrorRemark("Remarks is required");
        }
      };

const findCountofservices = (data : any) => {
  try{
    
    return JSON.parse(data)?.length

  }catch{
    return "-"
  }
   
}



    return (
        <>
          { !previewsection ? <>
                <Stack spacing={2}>
                {!isViewOnly  && ( 
              <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                <CustomBreadcrumbs
                  list={ !SoPreview ? [
                    { value: 0, name: prePageName ? prePageName : "COB Assessment" },
                    { value: 1, name:"CXO Preview" },
                  ]  : [
                    { value: 0, name: prePageName ? prePageName : "Pending Action" },
                    { value: 1, name: "COB Assessment" },
                    { value: 2, name:"CXO Preview" },
                  ]  }
                  onchange={(val : any) => {
                    if(!SoPreview){
                      val == 0 && onclose && onclose()
                    }else{
                      val != 2 && onclose && onclose(val) 
                    }
                    }
                  }
                />

                {currentProcessData && (
                  <Box sx={{ width: "20" }}>
                    <VendorHistory isEditData={currentProcessData} />
                  </Box>
                )}
              </Box>
            )}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                        }}
                    >
                        <PageMainHeading title={`${customerDetails?.Legal_Name || ""}`} />
                    </Box>
  <Box sx={{display:'flex' , alignItems:'center' , justifyContent:'space-between'}}>



                    <Typography sx={{ height: '12px', color: '#959595', display: 'block', fontSize: '15px', fontWeight: "bold", textAlign: 'left' }}>
                        CUSTOMER DETAILS
                    </Typography>
                
                    {!SoPreview &&  <Typography
                          onClick={() => {
                            setpreviewsection && setpreviewsection(true);
                          }}

                         
                          color={`${theme.palette.primary.main}`} 
                          sx={{ textAlign: "left", cursor: "pointer", textDecoration: "underline" }}
                        >
                          Customer Assessment Details
                        </Typography>}
                        </Box>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                        <Grid
                            item
                            xs={textFieldWidth.xs}
                            sm={textFieldWidth.sm}
                            md={textFieldWidth.md}
                            lg={textFieldWidth.lg}
                            xl={textFieldWidth.xl}
                        >
                            <LableandValuesPreview lable="Services Type" Value={`${serviceTypes}`} />
                        </Grid>
                        <Grid
                            item
                            xs={textFieldWidth.xs}
                            sm={textFieldWidth.sm}
                            md={textFieldWidth.md}
                            lg={textFieldWidth.lg}
                            xl={textFieldWidth.xl}
                        >
                          
                        </Grid>
                        <Grid
                            item
                            xs={textFieldWidth.xs}
                            sm={textFieldWidth.sm}
                            md={textFieldWidth.md}
                            lg={textFieldWidth.lg}
                            xl={textFieldWidth.xl}
                        >
                         
                        </Grid>
                   
                        <Grid
                            item
                            xs={textFieldWidth.xs}
                            sm={textFieldWidth.sm}
                            md={textFieldWidth.md}
                            lg={textFieldWidth.lg}
                            xl={textFieldWidth.xl}
                        >
                            <LableandValuesPreview lable="Service Mapped" Value={`${findCountofservices(customerDetails?.No_Of_Service_Mapped) || ""}`} />

                        </Grid>
                        <Grid
                            item
                            xs={textFieldWidth.xs}
                            sm={textFieldWidth.sm}
                            md={textFieldWidth.md}
                            lg={textFieldWidth.lg}
                            xl={textFieldWidth.xl}
                        >
                            <LableandValuesPreview lable="Service Period" Value={`${customerDetails?.service_date || ""}`} />

                        </Grid>
                        <Grid
                            item
                            xs={textFieldWidth.xs}
                            sm={textFieldWidth.sm}
                            md={textFieldWidth.md}
                            lg={textFieldWidth.lg}
                            xl={textFieldWidth.xl}
                        >
                            <LableandValuesPreview lable="Service Owner" Value={`${customerDetails?.Service_Owner_Name || ""}`} />

                        </Grid>


                    </Grid>
                    <Divider />

                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>

                        <Typography sx={{ height: '12px', color: '#959595', display: 'block', fontSize: '15px', fontWeight: "bold" }}>
                            ASSESSMENT SUMMARY
                        </Typography>
                        <Box sx={{ display: "flex", gap: 2 }}>
                            {/* <CustomButton variant="text" name={"Download"} onClick={() => exportExcelNew("file name is ", ["a", "b", "c", "d"])} /> */}
                            {/* <CustomButton variant="outlined" name={"View Assessment"} onClick={() => exportExcelNew("file name is ", ["a", "b", "c", "d"])} /> */}
                        </Box>
                    </Box>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                        <Grid
                            item
                            xs={textFieldWidth.xs}
                            sm={textFieldWidth.sm}
                            md={textFieldWidth.md}
                            lg={textFieldWidth.lg}
                            xl={textFieldWidth.xl}
                        >
                            <LableandValuesPreview lable="Number of Questions" Value={`${customerDetails?.No_Of_Questions || ""}`} />
                        </Grid>
                        <Grid
                            item
                            xs={textFieldWidth.xs}
                            sm={textFieldWidth.sm}
                            md={textFieldWidth.md}
                            lg={textFieldWidth.lg}
                            xl={textFieldWidth.xl}
                        >
                            <LableandValuesPreview lable="Number of Deviations" Value={''} />
                        </Grid>
                    </Grid>
                   { !SoPreview && <Box sx={{ paddingY: "15px" }}>

                        <CustomMultilineTextField
                            key={`text`}
                            label={"Remarks"}
                            readOnly={false}
                            rows={3}
                            maxRows={10}
                            value={remark}
                            onChange={(e) => {
                                setRemark(e);
                                if (e) {
                                  setErrorRemark("");
                                } else {
                                  setErrorRemark("Remark is required");
                                }
                                if(remark.length>0){
                                    setsignOffBtnDisabled(false)
                                    setterminateBtnDisabled(false)
                                }else{
                                    setsignOffBtnDisabled(true)
                                    setterminateBtnDisabled(true)
                                }
                              }}
                              error={Boolean(remarkerror)}
                              helperText={remarkerror}
                        />
                    </Box> }
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                        <Grid
                            item
                            xs={textFieldWidth.xs}
                            sm={textFieldWidth.sm}
                            md={textFieldWidth.md}
                            lg={textFieldWidth.lg}
                            xl={textFieldWidth.xl}
                        >
                            <LableandValuesPreview lable="Approved By" Value= {userLoginResponse?.UserName} />
                        </Grid>
                        <Grid
                            item
                            xs={textFieldWidth.xs}
                            sm={textFieldWidth.sm}
                            md={textFieldWidth.md}
                            lg={textFieldWidth.lg}
                            xl={textFieldWidth.xl}
                        >
                            <LableandValuesPreview lable="Approved Date" Value={`${currentDate}`} />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    {/* <CustomButton variant="text" disabled={cancelBtnDisabled} name={"Cancel"} onClick={() => exportExcelNew("file name is ", ["a", "b", "c", "d"])} /> */}
                        <Box sx={{ display: "flex", gap: 2 }}>
                        
                           {!SoPreview && <> <CustomButton variant="outlined" color="success" disabled={signOffBtnDisabled} name={"Sign Off"} onClick={() => conformTheSignoff(1) } />
                            <CustomButton variant="outlined" color="error" name={"Terminate"} disabled={terminateBtnDisabled} onClick={() => conformTheSignoff(0) } /> </> }
                          
                        </Box>

                        <Box sx={{ display: "flex", gap: 2 }}>
                           {SoPreview && <> <CustomButton variant="outlined" name={"Back to Assessment"} onClick={() => onsubmitso && onsubmitso(false) } />
                           <CustomButton  name={"Send to CXO"}  onClick={() => onsubmitso && onsubmitso(true) } /> </>}
                           
                        </Box>
                    </Box>
                </Stack>
            </> :   <COBProcessPreviews
          vendor_Id={currentProcessData?.VendorId}
          currentProcessData={currentProcessData}
          onClose={(e: any) => {
            if (e === 0) {
              onclose && onclose()
            } else if (e === 1) {
              setpreviewsection(false);
            }
          }}
        /> }
            <ModalPopUp
            onDeleteMethod={() => submitvendorSignOff()}
            isDelete={true}
            onHide={() => closeConformationPopup()}
            show={isConform}
            ActionName={"Complete"}
            header={"Confirm"}
            text={`Are you sure you want to ${submitstatus === 1 ? "Sign off" : "Terminate"}  the Customer ?`}
          />
        </>
    );
};

export default COBCXOApproval;