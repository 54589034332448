import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store/store";
import CustomSucessBanner from "../../common/dynamicMessageBanner/CustomSucessBanner";
import { PMResponse } from "../../components/performanceManagement/prReviews/PMResponse";
import { PMWorflowPages } from "../../config/config";
import PmRRreviewService from "../../store/performanceManagement/services/pmRRreviewService";
import { EventAddandEditUpdate } from "../../store/performanceManagement/slices/pmEventLog";
import { updateLoader, updateToaster } from "../../store/slices/loader";
export const PMVendorRespondor: React.FC = () => {
    const dispatch = useAppDispatch();
  const { ispmEventLoading , EventLogSLAAdded , ToasterMsg   } = useSelector(
        (state: any) => state.performanceManagementReducer?.PMEventLogDataSlice 
    );
  
    const { isVendorAuthenticated  , isLoading , vendorLoginDetails } = useSelector((state: RootState) => state.loggerData);
    const [currentPage,setcurrentPage] = useState<any>("")
    const [currentProcessData,setcurrentProcessData] = useState<any>("")
    const [InstanceValue,setInstanceValue] = useState<any>("")
  
    const { vendor_persional_Id , event_Id , instance_Details_Id } = useParams();

      let obj = {ProjectId : event_Id}
   
      

  useEffect(() => {
    checkFlowCompleted(event_Id)
  },[event_Id])

  useEffect(() => {
    dispatch(updateLoader(isLoading))
  },[isLoading])

     useEffect(() => {
          if (EventLogSLAAdded) {
              dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg?.Message, isTosterFailed: ToasterMsg?.Status === "false" ? true : false }));
              dispatch(EventAddandEditUpdate(false));
              checkFlowCompleted(event_Id)
             
          }
      }, [EventLogSLAAdded]);

      const checkFlowCompleted = async (vId: any) => {
        try {
          if (vId) {
            dispatch(updateLoader(true))
            const instanceValue : any = await PmRRreviewService.GetRRReviewByEventId({
              id: vId,
            });
            dispatch(updateLoader(false))
            let resposseData : any = instanceValue;
         console.log(resposseData , "resposseData");
         
            if (resposseData?.workflowProcessInstance && resposseData?.event_Id && resposseData?.vendor_Id) {
              setInstanceValue(resposseData);
              callSetCurrentPage(resposseData)
            }
          }
        } catch (error) {
          dispatch(updateLoader(false))
          console.error("Error fetching workflow instance:", error);
          return false;
        }
      };
    const callSetCurrentPage = (callInstance: any) => {
      try {
        let instanceData = callInstance?.workflowProcessInstance;
        let firstInstanceData = instanceData.find((item: any) => item?.formName == PMWorflowPages.VendorPortal);
        console.log(firstInstanceData , "firstInstanceData");
           if (firstInstanceData && firstInstanceData?.status_Name == "Not Started") {
                  setcurrentProcessData({...firstInstanceData,  ProjectId : callInstance?.event_Id , Workflow_InstanceId : firstInstanceData?.workflow_InstanceId , Id : firstInstanceData?.process_InstanceId  });
                  setcurrentPage(PMWorflowPages.VendorPortal);
              }else if(firstInstanceData && firstInstanceData?.status_Name == "Completed"){
                setcurrentPage("Page sucess");
              }else{
                     
              }
        }
          catch{
                  
      }

      }
     return(
        <>
          {currentPage === PMWorflowPages.VendorPortal ? ( <Box sx={{p:isVendorAuthenticated?2:""  }} >
            <PMResponse isEditData={currentProcessData} PageName={currentPage}/>
        </Box> ) : null }
        {currentPage === "Page sucess" ? (
        <CustomSucessBanner label="Your Response is completed and submitted successfully ! you will hear from us soon!"/>
       
      ) : null}
        </>
     )
}