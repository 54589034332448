import React, { useEffect } from "react";
import { Box, Stack, Grid } from "@mui/material";
import { textFieldWidth } from "../../utils/constants";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import { Controller, useForm } from "react-hook-form";
import CustomDatePicker from "../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import { VOBPreview } from "../../components/vendorOnBoarding/VendorOnBoardingPreview/VendorOnBoardPreview";

interface ResponseProps {
  data?: any;
  currentProcessData?: any;
}

export const Response: React.FC<ResponseProps> = ({ data, currentProcessData }) => {
  const {
    handleSubmit: handleAqmSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
    watch,
    getValues,
  } = useForm<any>({
    defaultValues: "",
    mode: "onChange",
  });

  useEffect(() => {
    if (data) {
      setValue("Assesment_No", data?.Assessment_Number || "");
      setValue("Vendor_Name", data?.Vendor_Name || "");
      setValue("start_date", dayjs(data?.Start_Date) || null);
      setValue("date_of_completion", dayjs(data?.Target_Date) || null);
    }
  }, [data]);

  return (
    <Box>
      <Stack spacing={4}>
        <Grid container spacing={2} sx={{ marginLeft: "-16px !important" }}>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="Assesment_No"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  name={"Assesment_No"}
                  required={true}
                  readOnly={true}
                  label={"Assesment Number"}
                  //value={field.value}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errors.Assesment_No)}
                  helperText={errors.Assesment_No && errors.Assesment_No.message?.toString()}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="Vendor_Name"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  name={"Vendor Name"}
                  required={true}
                  readOnly={true}
                  label={"Vendor Name"}
                  //value={field.value}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errors.Vendor_Name)}
                  helperText={errors.Vendor_Name && errors.Vendor_Name.message?.toString()}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="start_date"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <CustomDatePicker
                  label={"Start Date"}
                  value={field.value}
                  readOnly={true}
                  onChange={(newValue: any) => field.onChange(newValue)}
                  error={Boolean(errors.start_date)}
                  helperText={errors.start_date && errors.start_date.message?.toString()}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="date_of_completion"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <CustomDatePicker
                  label={"Target Date of Completion"}
                  value={field.value}
                  readOnly={true}
                  onChange={(newValue: any) => field.onChange(newValue)}
                  error={Boolean(errors.date_of_completion)}
                  helperText={errors.date_of_completion && errors.date_of_completion.message?.toString()}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box>
          <VOBPreview currentProcessData={currentProcessData} isEditData={data} IsUser="vendor" />
        </Box>
      </Stack>
    </Box>
  );
};
