import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { PMScope_URL } from "./endpoint/ScopeDefine";

const getAllScope = (): Promise<any> => axiosInstance
  .post(PMScope_URL.getAllScope)
  .then((response: AxiosResponse<any>) => {
    if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
      return response.data;
    }
    throw new Error("Error getting Data" + response);
  })
  .catch((error: AxiosError | string) => {
    throw error;
  });
const getScopeById = (requestBody: any): Promise<any> =>
  axiosInstance
    .get(PMScope_URL.getScopeById, { params: requestBody })
    .then((response: AxiosResponse<any>) => {

      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getScopeByEventLogId = (requestBody: any): Promise<any> =>
  axiosInstance
    .get(PMScope_URL.getScopeByEventLogId, { params: requestBody })
    .then((response: AxiosResponse<any>) => {

      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        console.log(response.data, "response.data");
        
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const ApproveRejectScope = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(PMScope_URL.ApproveRejectScope, requestBody)
    .then((response: AxiosResponse<any>) => {

      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addUpdateScope = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(PMScope_URL.addupdateScope, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });


const ScopeDefineService = {
  getAllScope,
  getScopeById,
  addUpdateScope,
  ApproveRejectScope,
  getScopeByEventLogId,
};

export default ScopeDefineService;
