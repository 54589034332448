import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { textFieldWidth } from "../../../utils/constants";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import {
  addAssessmentCategory,
  getAssessmentCategoryList,
  getParentAssessmentCategoryList,
  updateAssessmentCategory,
  updateAssessmentCategoryAddandEdit,
} from "../../../store/slices/assessmentCategoryMasterData";
import ProgressBar from "../../../common/progressBar/progressBar";
import Toaster from "../../toaster/Toaster";
import {
  addSaveandDraft,
  getSaveandDrafList,
  updateSaveandDraft,
  updateSaveasDraftAddandEdit,
} from "../../../store/slices/saveasdraft";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { getLovList } from "../../../store/slices/lovMasterData";
import { AssessmentCategoryConfig, LovConfig } from "../../../config/config";
import { updateLoader } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { ApplicationParameterName, DepartmentConfig } from "../../../config/config";
// import InactivityTimer from "../../../common/InactivityTimer/InactivityTimer";


interface FormData {
  AQ_Category_Id?: number;
  Type?: string;
  AQ_Category_Name?: string;
  AQ_Category_Type?: string;
  Map_Service_Catalogue?: boolean;
  Parent_Level?: string;
  Parent_AQ_Category_Id?: string;
  Level?: string;
  Parent?: string;
  IsActive?: any;
}
export interface assessmentCategoryProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  // setIsEdit?:boolean;
  isEdited?: any;
  closePopUp?: () => void;
  onchangeLable?: any;
  isAdded?: any;
  masterId?: string;
  onClose?: any;
  isDraftData?: any;
  ModuleId?: number | undefined;
}
interface FormDataDraft {
  UserId?: number | undefined;
  ModuleName: string;
  ScreenName: string;
  DraftData: string;
  DraftStatus: boolean;
  CreatedBy: string | undefined; // Assuming user?.UserName can be undefined
  DraftId?: string | undefined; // Assuming user?.UserName can be undefined
}

export const AddAssessmentCategory: React.FC<assessmentCategoryProps> = ({
  view,
  isEditData,
  edit,
  isEdited,
  isAdded,
  masterId,
  onClose,
  onchangeLable,
  isDraftData,
  ModuleId,
  closePopUp,
}) => {
  const dispatch = useAppDispatchThunk();
  const [ParentAssessmentCategoryData, setParentAssessmentCategoryData] = useState<any[]>([]);
  const [AssessmentCategoryTypelov, setAssessmentCategoryTypelov] = useState<any[]>([]);
  const [Levelslov, setLevelslov] = useState<any[]>([]);
  const [ParentVisible, setParentVisible] = useState<boolean>(false);
  const [IsCancel, setIsCancel] = useState<boolean>(false);

  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);

  const { dataTable, isAssessmentCategoryAdded, isAssessmentCategoryUpdated, isLoading, parentTable } = useSelector(
    (state: RootState) => state.assessmentCategoryMasterData
  );

  const { draftdata, isLoadingDraft, isDraftAdded, isDraftUpdated, isDraftDeleted } = useSelector(
    (state: RootState) => state.saveAsDraft
  );

  const [timeout, setTimeout] = useState<any>(null);
    const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);


    useEffect(() => {
        if (edit) {
          const RecordTimeOut: any = ApplicationParameterName.RecordTimeOut;
          let findRecordTimeOut = applicationParameterData.find(
            (applicationParamitem) =>
              applicationParamitem.Parameter_Name === RecordTimeOut &&
              applicationParamitem.Parameter_Group === RecordTimeOut
          )?.Parameter_Value;
          setTimeout(Number(findRecordTimeOut));
        }
      }, []);
    

  let formData = {
    AQ_Category_Id: isEditData ? isEditData?.AQ_Category_Id : 0,
    AQ_Category_Name: isEditData ? isEditData?.AQ_Category_Name : "",
    AQ_Category_Type: isEditData ? isEditData?.AQ_Category_Type : '',
    Parent_AQ_Category_Id: isEditData ? isEditData?.Parent_AQ_Category_Id : '',
    Map_Service_Catalogue: isEditData ? isEditData?.Map_Service_Catalogue : false,
    Parent_Level: isEditData ? isEditData?.Parent_Level : '',
    CreatedBy: isEditData ? isEditData?.UpdatedBy : "",
    IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
  };

  const {
    handleSubmit: handleVendorForm,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
    clearErrors,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const isActiveDisabled = watch("IsActive");
  const AssessmentCategoryTypeWatchvalue = watch("AQ_Category_Type");
  const AssessmentCategoryLevelWatch = watch("Parent_Level");
  const DepartmentLevelAsString = `${AssessmentCategoryLevelWatch}`;

  const handleSubmit = (data: any) => {
    dispatch(updateLoader(true));
    let formData = data;
    formData.MenuId = ModuleId;
    formData.Form_Id = 40;
    formData.ModuleName = ParentVisible ? "Assessment Questionnaire Category" : "Assessment Questionnaire Category";
    formData.CreatedBy = user?.UserName;
    // formData.Map_Service_Catalogue = formData.Map_Service_Catalogue ? formData.Map_Service_Catalogue : "";
    formData.AQ_Category_Type = formData.AQ_Category_Type ? formData.AQ_Category_Type : "";
    formData.AQ_Category_Name = formData.AQ_Category_Name ? formData.AQ_Category_Name : "";
    formData.Parent_AQ_Category_Id = formData.Parent_AQ_Category_Id ? formData.Parent_AQ_Category_Id : 0;
    formData.Parent_Level = formData.Parent_Level ? formData.Parent_Level : 0;
    formData.IsActive = formData.IsActive ? true : false;

    let finededDeptType = AssessmentCategoryTypelov.find(
      (item) => item.Options === AssessmentCategoryConfig?.Category && formData.AQ_Category_Type == item.LovId
    );

    let JsonDataForDisplay;

    if (finededDeptType) {
      JsonDataForDisplay = {
          "Type": AssessmentCategoryTypelov.find((item) => item.LovId == formData?.AQ_Category_Type)?.Options || '',
          "Assessment Questionnaire Category Name": formData.AQ_Category_Name,
          "Map Service Catalogue": formData.Map_Service_Catalogue == true ? "Yes" : "No",
          "Status": formData.IsActive ? "Active" : "Inactive",
          "Modified By": user?.UserName,
      };
  } else {
      JsonDataForDisplay = {
          "Type": AssessmentCategoryTypelov.find((item) => item.LovId == formData?.AQ_Category_Type)?.Options || '',
          "Assessment Questionnaire Category Name": formData.AQ_Category_Name,
          // "Department Code": formData.Department_Code,
          "Parent Level": Levelslov.find((item) => item.LovId == formData?.Parent_Level)?.Options || '',
          "Parent Assessment Questionnaire Category Name": ParentAssessmentCategoryData.find((item) => item.AQ_Category_Id == formData?.Parent_AQ_Category_Id)?.AQ_Category_Name || '',
          "Status": formData.IsActive ? "Active" : "Inactive",
          "Modified By": user?.UserName,
      };
  }
    if (edit) {
      formData.UserId = userLoginResponse?.UserId;
      formData.Activity = "Edit";
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      dispatch(updateAssessmentCategory(formData));
    } else {
      formData.Activity = "Create";

      if (finededDeptType) {
        let finededIndDeptLevel = Levelslov.find((item: any) => item.Options === AssessmentCategoryConfig?.Category_Level_1);
        formData.Parent_Level = finededIndDeptLevel ? finededIndDeptLevel.LovId : 0;
      }
      // JsonDataForDisplay.Parent_LevelValue = Levelslov.find((item) => item.LovId == formData?.Parent_Level)?.Options || '';
      formData.IsActive = true;
      formData.UserId = userLoginResponse?.UserId;
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      dispatch(addAssessmentCategory(formData));
    }
  };

  useEffect(() => {
    if (isAssessmentCategoryAdded || isAssessmentCategoryUpdated) {
      dispatch(
        getAssessmentCategoryList({
          FormID: 2,
        })
      );
      dispatch(updateAssessmentCategoryAddandEdit(false));
      reset(formData);
      if (onClose) {
        onClose();
        // setLoader(false);
      }
    }
  }, [isAssessmentCategoryAdded, isAssessmentCategoryUpdated]);

  useEffect(() => {
    setParentAssessmentCategoryData(parentTable);
  }, [parentTable]);

  const findParentAssessmentCategoryLevel = (currentLevel: any) => {
    let level = currentLevel;
    while (level > 1) {
      level -= 1;
      const targetOption = `Level ${level}`;
      const findSelectAssessmentCategoryLevel = Levelslov.find((item: any) => item.Options === targetOption);
      if (findSelectAssessmentCategoryLevel) {
        return findSelectAssessmentCategoryLevel.LovId;
      }
    }
    return 0;
  };

  useEffect(() => {
    if (isEditData && isEditData.Parent_Level) {
      let findSelectAssessmentCategory = Levelslov.find((item: any) => item.LovId == isEditData.Parent_Level);

      if (findSelectAssessmentCategory) {
        const currentLevel = parseInt(findSelectAssessmentCategory.Options.split(" ")[1]);
        const parentAssessmentCategoryId = findParentAssessmentCategoryLevel(currentLevel);
        dispatch(getParentAssessmentCategoryList({ levelId: parentAssessmentCategoryId }));
      }
    }
  }, [isEditData, Levelslov]);

  let levelIdofAssessmentCategory = watch("Parent_Level");

  useEffect(() => {
    let findSelectAssessmentCategory = Levelslov.find((item: any) => item.LovId == levelIdofAssessmentCategory);

    if (findSelectAssessmentCategory) {
      setValue("Parent_AQ_Category_Id", '')
      const currentLevel = parseInt(findSelectAssessmentCategory.Options.split(" ")[1]);
      const parentAssessmentCategoryId = findParentAssessmentCategoryLevel(currentLevel);
      dispatch(getParentAssessmentCategoryList({ levelId: parentAssessmentCategoryId }));
    }
  }, [levelIdofAssessmentCategory]);

  // useEffect(() => {
  //   DepartmentLevelChange()
  // }, []);

  useEffect(() => {
    let finededIndType: any = AssessmentCategoryTypelov.find(
      (item) => item.Options === AssessmentCategoryConfig?.Category && AssessmentCategoryTypeWatchvalue == item.LovId
    );

    if (finededIndType) {
      if (onchangeLable && AssessmentCategoryTypeWatchvalue) {
        onchangeLable('Assessment Questionnaire Category')
      }
      // setValue("Parent_Industry_Id", "0");
      setParentVisible(false);
    } else {
      if (onchangeLable && AssessmentCategoryTypeWatchvalue) {
        onchangeLable('Assessment Questionnaire Sub Category')
      }
      setParentVisible(true);
    }
  }, [AssessmentCategoryTypeWatchvalue, isEditData, AssessmentCategoryTypelov]);

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
  }, []);

  useEffect(() => {
    const Levelslov = lovTable.filter((item: any) => item.Name === LovConfig?.AQ_Category_Level) || [];
    setLevelslov(Levelslov);
    setAssessmentCategoryTypelov(lovTable.filter((item: any) => item.Name === LovConfig?.AQ_Category_Type) || []);
  }, [lovTable]);

  useEffect(() => {
    if (isDraftAdded || isDraftUpdated || isDraftDeleted) {
      dispatch(updateSaveasDraftAddandEdit(false));
      dispatch(
        getSaveandDrafList({
          DraftId: 12,
        })
      );
      reset(formData);
      dispatch(updateLoader(false));
      if (onClose) {
        onClose();
        // setLoader(false);
      }
    }
  }, [isDraftAdded, isDraftUpdated, isDraftDeleted]);

  const handleCancelPopup = () => {

    if (isDirty) {
      setIsCancel(true);
    } else {
      onClose && onClose();
    }
  }

  return (
    <>
      <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden", position: "relative" }}>
        {/* {isLoading || isLoadingDraft ? <ProgressBar showProgress={isLoading || isLoadingDraft} /> : null} */}

        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="AQ_Category_Type"
                control={control}
                defaultValue=""
                rules={{ required: "Type is required" }}
                render={({ field }) => (
                  <CustomSelect
                    name={"Type"}
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={AssessmentCategoryTypelov.sort((a, b) => a.Options.localeCompare(b.Options))}
                    disabled={view || edit}
                    required={true}
                    // addField={true}
                    label={"Type"}
                    value={field.value}
                    // onChange={field.onChange}
                    onChange={(e) => {
                      field.onChange(e);
                      clearErrors();
                    }}
                    error={Boolean(errorsloginform.AQ_Category_Type)}
                    helperText={errorsloginform.AQ_Category_Type && errorsloginform.AQ_Category_Type.message?.toString()}
                  />
                )}
              />
            </Grid>

            {!ParentVisible && watch('AQ_Category_Type') ? (
              <>
                <Grid item xs={12}>
                  <Controller
                    name="AQ_Category_Name"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Category name is required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value ?? "") || "Leading spaces are not allowed",
                        minLength: (value) => (value ?? "").length >= 4 || "Minimum 4 characters required",
                        maxLength: (value) => (value ?? "").length <= 100 || "Maximum 100 characters required",
                        ValueLimitaion: (value) => /^(?:^[a-zA-Z0-9\s,. -]+)$/.test(value ?? "") || "Category Name cannot contain special characters except spaces and ,.-",
                        minAlphabets: (value) => (value?.match(/[a-zA-Z]/g) || []).length >= 2 || "Minimum 2 alphabets required",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"AQ_Category_Name"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Category"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.AQ_Category_Name)}
                        helperText={
                          errorsloginform.AQ_Category_Name && errorsloginform.AQ_Category_Name.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                <Controller
                    name="Map_Service_Catalogue"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                    <CustomToogleSwitch
                      label="Map Service Catalogue"
                      value={field.value}
                      onChange={field.onChange}
                      disabled={view || !isActiveDisabled}
                      required={true}
                      readOnly={view}
                      error={Boolean(errorsloginform.Map_Service_Catalogue)}
                      helperText={errorsloginform?.Map_Service_Catalogue?.message?.toString()}
                    />   
                    )}
                  />
                </Grid>
              </>
            ) : null}

            {ParentVisible && watch('AQ_Category_Type') ? (
              <>
                <Grid item xs={12}>
                  <Controller
                    name="AQ_Category_Name"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Sub Category name is required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value ?? "") || "Leading spaces are not allowed",
                        minLength: (value) => (value ?? "").length >= 4 || "Minimum 4 characters required",
                        maxLength: (value) => (value ?? "").length <= 100 || "Maximum 100 characters required",
                        ValueLimitaion: (value) => /^(?:^[a-zA-Z0-9\s,. -]+)$/.test(value ?? "") || "Sub Category Name cannot contain special characters and empty value",
                        minAlphabets: (value) => (value?.match(/[a-zA-Z]/g) || []).length >= 2 || "Minimum 2 alphabets required",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"AQ_Category_Name"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Sub Category Name"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.AQ_Category_Name)}
                        helperText={
                          errorsloginform.AQ_Category_Name && errorsloginform.AQ_Category_Name.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="Parent_Level"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Level is required" }}
                    render={({ field }) => (
                      <CustomSelect
                        name="Parent_Level"
                        valueKey="LovId"
                        optionLabelKey="Options"
                        options={Levelslov.filter((item) => item.Options != "Level 1").sort((a, b) => a.Options.localeCompare(b.Options))}
                        disabled={view || !isActiveDisabled}
                        required={true}
                        // addField={true}
                        label="Level"
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Parent_Level)}
                        helperText={
                          errorsloginform.Parent_Level && errorsloginform.Parent_Level.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="Parent_AQ_Category_Id"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Parent is required" }}
                    render={({ field }) => (
                      <CustomSelect
                        name={"Parent_AQ_Category_Id"}
                        valueKey="AQ_Category_Id"
                        optionLabelKey="AQ_Category_Name"
                        options={ParentAssessmentCategoryData.filter((item) => item.IsActive !== "Inactive")}
                        label={"Parent"}
                        disabled={view || !isActiveDisabled}
                        // addField={true}
                        required={true}
                        // disabled
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Parent_AQ_Category_Id)}
                        helperText={
                          errorsloginform.Parent_AQ_Category_Id && errorsloginform.Parent_AQ_Category_Id.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
              </>
            ) : null}
           
          </Grid>

          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "right",
              mt: 2,
              // position: "absolute",
              // right: 24,
              // bottom: 16,
            }}
          >
            {!view ? (
              !edit ? (
                <>
                  <CustomButton
                    type="reset"
                    variant="outlined"
                    name={"Cancel"}
                    onClick={() => {
                      // onClose();
                      handleCancelPopup();
                    }}
                  />

                  {/* <CustomButton type="reset" name={"Save as Draft"} onClick={() => handleSubmitDraft()} /> */}

                  <CustomButton type="reset" disabled={!isDirty} name={"Submit"} onClick={handleVendorForm(handleSubmit)} />
                </>
              ) : (
                <>
                  <CustomButton
                    variant="outlined"
                    name={"Cancel"}
                    onClick={() => {
                      // onClose();
                      handleCancelPopup();
                    }}
                  />
                  <CustomButton variant="contained" disabled={!isDirty} name={"Update"} onClick={handleVendorForm(handleSubmit)} />
                </>
              )
            ) : (
              <CustomButton
                variant="outlined"
                name={"Cancel"}
                onClick={() => {
                  // onClose();
                  handleCancelPopup();
                }}
              />
            )}
 
          </Box>
        </Box>
      </Grid>

      <ModalPopUp
        onDeleteMethod={() => onClose && onClose()}
        isDelete={true}
        onHide={() => setIsCancel(false)}
        show={IsCancel}
        header={"Confirm"}
        text={`Are you sure to cancel`}
        ActionName={"Yes"}
      />

    </>
  );
};
