import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomButton from "../../common/dynamicButton/CustomButton";
import ProgressBar from "../../common/progressBar/progressBar";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import CustomMultilineTextField from "../../common/dynamicInputs/CustomMultilineTextField";
import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";
import { ACTIVE, INACTIVE, NO, YES } from "../../utils/constants";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import {
  createWorkFlow,
  getAllFormsConfiguration,
  getListOfValues,
  getListOfValuesProcessType,
  getListOfValuesofNext,
  setWorkFlowData,
  updateWorkFlow,
  updateWorkFlowAddandEdit,
} from "../../store/slices/workFlowSlice";
import { updateToaster } from "../../store/slices/loader";
import { useNavigate } from "react-router-dom";
import AddStep from "../addSteps/AddStep";
import { getDepartmentList } from "../../store/slices/departmentData";
import { getRolesList } from "../../store/slices/roleData";
import CustomBreadcrumbs from "../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { LovConfig, WorkFlowType } from "../../config/config";
import { IMenu } from "../../models/loggin.type";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import {
  addApplicationParameter,
  getApplicationParameterList,
  updateApplicationParameter,
} from "../../store/slices/applicationParameterData";
// import AddResponse from "./addResponse";

export interface workFlowStep {
  Id: number;
  WorkflowId: number;
  ProcessSeqno: number;
  ProcessName: string;
  ProcessType: number;
  ProcessTypeName?: string;
  ProcessId: number;
  ProcessIdName?: string;
  ProcessNextStep: number;
  ProcessNextStepName?: string;
  ConditionTobeChecked: string;
  IfYes_GoTo: number;
  IfNo_GoTo: number;
  IsActive: boolean;
  IsActiveName: string;
  Updated_By: string;
}

export interface workFlowDetails {
  Id: number;
  WorkflowName: string;
  WorkflowType?: number;
  Workflow_Category: number;
  WorkflowCode: string;
  WorkflowDescription: string;
  TAT: string;
  IsActive: boolean;
  Schedule: boolean;
  Updated_By: string;
  UserId: number;
  ModuleName: string;
  Activity: string;
  workflowProcesses: workFlowStep[];
}

export interface CreateWorkFlowProps {
  view?: boolean;
  editWorkFlowData?: any;
  edit?: boolean;
  // masterId?: string;
  onClose?: any;
  // isDraftData?: boolean;
  // ModuleId?: number | undefined;
  // isAqmResponse?: any;
  // activestepCount?: any;
  // handleNext?: any;
  // handlePrevious?: any;
}

const CreateWorkFlow: React.FC<CreateWorkFlowProps> = ({ editWorkFlowData, onClose, view, edit }) => {
  const dispatch = useAppDispatchThunk();
  const { user, userLoginResponse, currentPagePermissionData, menuData, menuOptions } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [stepsData, setStepsData] = useState<workFlowStep[]>([]);
  const [openAddForm, setOpenAddForm] = useState<boolean>(false);
  const [workFlowTypeOption, setWorkFlowTypeOption] = useState<any[]>([]);
  const [workFlowCategoryOption, setWorkFlowCategoryOption] = useState<any[]>([]);
  const [worFlowProcessType, setWorFlowProcessType] = useState<any[]>([]);
  const [workFlowProcessNext, setWorkFlowProcessNext] = useState<any[]>([]);
  const { isWorkFlowCreated , formConfiguration } = useSelector((state: RootState) => state.workFlow);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [permissionPage, setPermissionForpage] = useState<IMenu | null>(null);

  const [editStepData, setEditStepData] = useState<any>();
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [editStepDataIndex, setEditStepDataIndex] = useState<any>();
  const [openEditStepForm, setOpenEditStepForm] = useState<boolean>(false);
  const [categoryItem, setCategoryItem] = useState(false);
  let watchWorkFlowCategory = "";
  const { applicationParameterData, isAPAdded, isAPDeleted, isAPUpdated, versionHistory, apToasterMsg } = useSelector(
    (state: RootState) => state.applicationParameter
  );
  const [applicationParameterDatas, setApplicationParameterDatas] = useState<any[]>([]);

  const navigate = useNavigate();
  // findMenuIdByMenuName
  const findMenuIdByMenuName = (menuData: any[], menuName: string): number | null => {
    let menuId: number | null = null;

    const searchMenu = (menus: any[]): void => {
      for (const menu of menus) {
        if (menu.MenuName === menuName) {
          menuId = menu.MenuId;
          return;
        }
        if (menu.SubMenus && menu.SubMenus.length > 0) {
          searchMenu(menu.SubMenus);
        }
        if (menuId) break;
      }
    };

    searchMenu(menuData);
    return menuId;
  };
  const menuName = "Application Parameter";
  const workFlowGroup = "Customer Onboarding";
  const appParameter_moduleId = menuData ? findMenuIdByMenuName(menuData, menuName) : null;
  // findMenuNameByMenuId
  console.log(appParameter_moduleId, "appParameter_moduleId");
  const findMenuNameByMenuId = (menuData: any[], menuId: number): string | null => {
    let menuName: string | null = null;

    const searchMenu = (menus: any[]): void => {
      for (const menu of menus) {
        if (menu.MenuId === menuId) {
          menuName = menu.MenuName;
          return;
        }
        if (menu.SubMenus && menu.SubMenus.length > 0) {
          searchMenu(menu.SubMenus);
        }
        if (menuName) break;
      }
    };

    searchMenu(menuData);
    return menuName;
  };
  // findParameterIdByWorkflowName
  const findParameterIdByWorkflowName = (workflowName: string, applicationParameterData: any): number | null => {
    const parameter = applicationParameterData.find(
      // (param: any) => param?.Parameter_Name === workflowName && param?.Parameter_Group === workFlowGroup
      (param: any) => param?.Parameter_Name === workflowName && param?.Parameter_Group === workFlowGroup
    );
    return parameter ? parameter?.Parameter_Id : null;
  };

  // const menuIdToFind = 32;
  // const menuNameFound = menuData ? findMenuNameByMenuId(menuData, menuIdToFind) : null;

  const parameterId = editWorkFlowData
    ? findParameterIdByWorkflowName(editWorkFlowData?.WorkflowCategoryName, applicationParameterDatas)
    : 0;

  useEffect(() => {
    if (applicationParameterData && applicationParameterData.length > 0) {
      const tempData =
        applicationParameterData.filter((n) => n.Parameter_Group === WorkFlowType.customerOnboarding) || [];
      setApplicationParameterDatas(tempData);
    }
  }, [applicationParameterData]);

  console.log(
    applicationParameterDatas,
    parameterId,
    applicationParameterDatas,
    editWorkFlowData,
    "applicationParameterDatas"
  );
  const columns: any = {
    table_name: "Steps",
    isAction: true,
    columns: [
      {
        Field: "ProcessSeqno",
        DisplayName: "Step Seq No",
        FieldType: "number",
      },
      {
        Field: "ProcessName",
        DisplayName: "Process Name",
        FieldType: "string",
      },
      {
        Field: "ProcessTypeName",
        DisplayName: "Process Type",
        FieldType: "string",
      },
      {
        Field: "ProcessIdName",
        DisplayName: "Process_Id",
        FieldType: "string",
      },
      {
        Field: "ProcessNextStepName",
        DisplayName: "Next Step",
        FieldType: "string",
      },
      {
        Field: "ConditionName",
        DisplayName: "ConditionToBeChecked",
        FieldType: "string",
      },
      {
        Field: "IfYes_GoTo",
        DisplayName: "IFYes-Goto",
        FieldType: "string",
      },
      {
        Field: "IfNo_GoTo",
        DisplayName: "IFNo-Goto",
        FieldType: "string",
      },
      {
        Field: "Branching",
        DisplayName: "Branching",
        FieldType: "string",
      },
      {
        Field: "RoleName",
        DisplayName: "Role",
        FieldType: "string",
      },
      {
        Field: "DepartmentName",
        DisplayName: "Dept",
        FieldType: "string",
      },
      {
        Field: "TAT",
        DisplayName: "Estimated Time(In Days)",
        FieldType: "string",
      },
      {
        Field: "Exit_StatusName",
        DisplayName: "Exit Status",
        FieldType: "string",
      },
      {
        Field: "IsActiveName",
        DisplayName: "Status",
        FieldType: "string",
      },
    ],
  };
  useEffect(() => {
    if (lovTable && lovTable.length) {
      setWorkFlowTypeOption(lovTable.filter((item: any) => item.Name === LovConfig.WorkFlowType) || []);
      setWorkFlowCategoryOption(lovTable.filter((item: any) => item.Name === LovConfig.WorkflowCategory) || []);
      setWorFlowProcessType(lovTable.filter((item: any) => item.Name === LovConfig.Process_Type) || []);
      setWorkFlowProcessNext(lovTable.filter((item: any) => item.Name === LovConfig.Process_Workflow) || []);
    }
  }, [lovTable]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  let formData: workFlowDetails = {
    Id: editWorkFlowData !== null ? editWorkFlowData.Id : 0,
    WorkflowName: editWorkFlowData !== null ? editWorkFlowData.WorkflowName : "",
    Workflow_Category:
      editWorkFlowData !== null
        ? workFlowCategoryOption.length > 0
          ? workFlowCategoryOption.find((item) => item.LovId === editWorkFlowData.WorkflowCategory)?.LovId
          : 0
        : null,
    WorkflowType:
      editWorkFlowData !== null
        ? workFlowTypeOption.length > 0
          ? workFlowTypeOption.find((item) => item.LovId === editWorkFlowData.WorkflowType)?.LovId
          : 0
        : null,
    WorkflowCode: editWorkFlowData !== null ? editWorkFlowData.WorkflowCode : "",
    WorkflowDescription: editWorkFlowData !== null ? editWorkFlowData.WorkflowDescription : "",
    TAT: editWorkFlowData !== null ? editWorkFlowData.TAT : "",
    IsActive: editWorkFlowData !== null ? (editWorkFlowData.IsActive === "Active" ? true : false) : true,
    Schedule: editWorkFlowData !== null ? editWorkFlowData.Schedule : true,
    Updated_By: "",
    UserId: 0,
    ModuleName: "",
    Activity: "",
    workflowProcesses:
      editWorkFlowData !== null &&
      editWorkFlowData?.WorkflowProcessAsJson &&
      editWorkFlowData.WorkflowProcessAsJson !== null
        ? JSON.parse(editWorkFlowData.WorkflowProcessAsJson)
        : [],
  };
  const {
    handleSubmit: handleUserForm,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
  } = useForm<workFlowDetails>({
    defaultValues: formData,
    mode: "onChange",
  });
  console.log(editWorkFlowData, "editWorkFlowData");
  const watchWorkFlowCategorydata = watch("Workflow_Category");

  const foundCategoryItem =
    (workFlowCategoryOption &&
      workFlowCategoryOption.length > 0 &&
      workFlowCategoryOption.find((item) => item.LovId === watchWorkFlowCategorydata)?.Options) ||
    [];
  console.log(foundCategoryItem, "foundCategoryItemfoundCategoryItem");

  useEffect(() => {
    if (foundCategoryItem && foundCategoryItem.length > 0) {
      setCategoryItem(foundCategoryItem);
    }
  }, [foundCategoryItem]);

  useEffect(() => {
    setLoader(true);
    dispatch(getDepartmentList({ FormID: 2 }));
    dispatch(getRolesList({ formId: 3 }));
    dispatch(getAllFormsConfiguration());
    dispatch(
      getApplicationParameterList({
        FormID: 2,
      })
    );
  }, []);

  useEffect(() => {
    if (workFlowTypeOption?.length > 0 && workFlowProcessNext?.length > 0 && workFlowCategoryOption?.length > 0) {
      setLoader(false);
      if (editWorkFlowData) {
        const foundTypeItem = workFlowTypeOption.find((item) => item.LovId === editWorkFlowData.WorkflowType);
        if (foundTypeItem) {
          setValue("WorkflowType", foundTypeItem.LovId);
        }
        if (workFlowCategoryOption && workFlowCategoryOption.length > 0) {
          const foundCategoryItem = workFlowCategoryOption.find(
            (item) => item.LovId === editWorkFlowData?.WorkflowCategory
          );
          if (foundCategoryItem) {
            setValue("Workflow_Category", foundCategoryItem.LovId);
          }
        }
      }
    }
  }, [workFlowTypeOption, workFlowProcessNext, workFlowCategoryOption]);

  // console.log(selectedWorkFlow, "selectedWorkFlow");

  useEffect(() => {
    if (isWorkFlowCreated) {
      dispatch(updateWorkFlowAddandEdit(false));
      dispatch(setWorkFlowData(null));
      // navigate("/workFlows")
      onClose();
    }
  }, [isWorkFlowCreated]);

  useEffect(() => {
    if (editWorkFlowData != null) {
      if (editWorkFlowData?.WorkflowProcessAsJson && editWorkFlowData.WorkflowProcessAsJson !== null) {
        let values = JSON.parse(editWorkFlowData.WorkflowProcessAsJson);
        values = values.map((workFlowJson: any) => {
          workFlowJson.IsActiveName = workFlowJson.IsActive ? "Active" : "Inactive";
          let processTypeDetails = worFlowProcessType.filter(
            (processType: any) => processType.Options === workFlowJson.ProcessType
          )[0];
          if (processTypeDetails) {
            workFlowJson.ProcessType = processTypeDetails.LovId;
            workFlowJson.ProcessTypeName = processTypeDetails.Options;
          }
          let processNextDetails = workFlowProcessNext.filter(
            (processType: any) => processType.Options === workFlowJson.ProcessNextStep
          )[0];
          if (processNextDetails) {
            workFlowJson.ProcessNextStep = processNextDetails.LovId;
            workFlowJson.ProcessNextStepName = processNextDetails.Options;
          }
          let ProcessIdDetails = formConfiguration.filter(
            (processType: any) => processType.FormId === workFlowJson.ProcessId
          )[0];
          if (ProcessIdDetails) {
            workFlowJson.ProcessId = ProcessIdDetails.FormId;
            workFlowJson.ProcessIdName = ProcessIdDetails.FormName;
          }

          return workFlowJson;
        });
        setStepsData(values);
      }
    }
  }, [editWorkFlowData, worFlowProcessType, workFlowProcessNext, formConfiguration]);

  const handleSubmit = async () => {
    let values = getValues();

    // values.WorkflowType = values?.Workflow_Category;
    let copyofStepsData: workFlowStep[] = [];
    if (stepsData.length > 0) {
      copyofStepsData = JSON.parse(JSON.stringify(stepsData));
      copyofStepsData = copyofStepsData.map((processStepsData: any, index: number) => {
        if (processStepsData.ConditionTobeChecked === "") processStepsData.ConditionTobeChecked = null;
        if (processStepsData.IfYes_GoTo === "") processStepsData.IfYes_GoTo = null;
        if (processStepsData.IfNo_GoTo === "") processStepsData.IfNo_GoTo = null;
        if (processStepsData.Branching === "") processStepsData.Branching = null;
        if (processStepsData.Exit_Status === "") processStepsData.Exit_Status = null;

        delete processStepsData.ProcessTypeName;
        delete processStepsData.ProcessIdName;
        delete processStepsData.ProcessNextStepName;
        delete processStepsData.IsActiveName;
        delete processStepsData.ConditionName;
        delete processStepsData.DepartmentName;
        delete processStepsData.Exit_StatusName;
        delete processStepsData.RoleName;
        return { ...processStepsData };
      });
    }
    values.workflowProcesses = copyofStepsData;
    if (values.Id === 0) {
      values.ModuleName = "WorkFlow";
      values.Activity = "Create";
      values.UserId = userLoginResponse && userLoginResponse.UserId ? userLoginResponse.UserId : 0;
      values.Updated_By = userLoginResponse && userLoginResponse.UserName ? userLoginResponse.UserName : "";
      console.log("buvi ~ handleSubmit ~ values:", values);
      const response = (await dispatch(createWorkFlow(values))) as { payload: { WorkflowId: number } };
      const payload = response?.payload;
      // if (foundCategoryItem === "Customer Onboarding") {
      //   if (applicationParameterDatas && applicationParameterDatas.length === 0) {
      //     if (payload) {
      //       console.log(payload, "payload");
      //       const formDatas: any = {
      //         Parameter_Id: 0,
      //         Parameter_Group: foundCategoryItem,
      //         Parameter_Name: foundCategoryItem,
      //         Parameter_Description: values.WorkflowDescription,
      //         Parameter_Options: "",
      //         Is_Muliple_Options: false,
      //         Parameter_Value: payload?.WorkflowId.toString(),
      //         Updated_By: user?.UserName,
      //         Updated_On: new Date().toISOString(),
      //         IsActive: false,
      //         ModuleName: "Application Parameter",
      //         MenuId: appParameter_moduleId,
      //         Form_Id: 18,
      //         UserId: userLoginResponse?.UserId,
      //         CreatedBy: user?.UserName,
      //         Activity: "Create",
      //         Status: 1,
      //         // Approved_By: user?.UserName,
      //         // Approved_On: new Date().toISOString(),
      //       };
      //       let JsonDataForDisplay: any = {
      //         "Parameter Group": foundCategoryItem || "",
      //         "Parameter Name": values?.WorkflowName || "",
      //         // BranchTypeValue: BranchTypelov.find((item: any) => item.LovId == formData?.BranchType)?.Options || "",
      //         // Company_legalName: transformData(formData.CompanyId) || "",
      //         "Parameter Description": values?.WorkflowDescription || "",
      //         "Parameter Value": payload?.WorkflowId.toString() || "",
      //         "Parameter Options": "",
      //         Status: "InActive",
      //         "Modified By": user?.UserName,
      //       };

      //       formDatas.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      //       dispatch(addApplicationParameter(formDatas));
      //     }
      //   } else {
      //     if (payload) {
      //       console.log(payload, "updatePayload");
      //       const parameterValue = payload?.WorkflowId ? payload.WorkflowId.toString() : "";
      //       console.log(parameterValue, parameterId, "payload");
      //       const formDatas: any = {
      //         Parameter_Id: parameterId || 0,
      //         Parameter_Group: foundCategoryItem,
      //         Parameter_Name: foundCategoryItem,
      //         Parameter_Description: values.WorkflowDescription,
      //         Parameter_Options: "",
      //         Is_Muliple_Options: false,
      //         Parameter_Value: payload?.WorkflowId ? payload.WorkflowId.toString() : "",
      //         Updated_By: user?.UserName,
      //         Updated_On: new Date().toISOString(),
      //         IsActive: false,
      //         ModuleName: "Application Parameter",
      //         MenuId: appParameter_moduleId,
      //         Form_Id: 18,
      //         UserId: userLoginResponse?.UserId,
      //         CreatedBy: user?.UserName,
      //         Activity: parameterId ? "Update" : "Create",
      //         Status: 1,
      //       };
      //       let JsonDataForDisplay: any = {
      //         "Parameter Group": foundCategoryItem || "",
      //         "Parameter Name": values?.WorkflowName || "",
      //         "Parameter Description": values?.WorkflowDescription || "",
      //         "Parameter Value": payload?.WorkflowId ? payload.WorkflowId.toString() : "" ,
      //         "Parameter Options": "",
      //         Status: "InActive",
      //         // "Modified By": user?.UserName,
      //       };

      //       formDatas.Updated_By = user?.UserName;
      //       formDatas.Activity = "Edit";
      //       formDatas.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      //       console.log(formDatas, "Update WorkFlow");

      //       formDatas.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      //       dispatch(updateApplicationParameter(formDatas));
      //     }
      //   }
      // }
    } else {
      //values.workflowProcesses =stepsData;
      values.ModuleName = "WorkFlow";
      values.Activity = "Update";
      values.UserId = userLoginResponse && userLoginResponse.UserId ? userLoginResponse.UserId : 0;
      values.Updated_By = userLoginResponse && userLoginResponse.UserName ? userLoginResponse.UserName : "";
      console.log("buvi ~ handleSubmit ~ values:", values);
      const response = (await dispatch(updateWorkFlow(values))) as { payload: { WorkflowId: number } };
      const payload = response?.payload;
      // if (foundCategoryItem === "Customer Onboarding") {
      //   if (payload) {
      //     console.log(payload, "updatePayload");
      //     const parameterValue = payload?.WorkflowId ? payload.WorkflowId.toString() : "";
      //     console.log(parameterValue, parameterId, "payload");
      //     const formDatas: any = {
      //       Parameter_Id: parameterId || 0,
      //       Parameter_Group: foundCategoryItem,
      //       Parameter_Name: foundCategoryItem,
      //       Parameter_Description: values.WorkflowDescription,
      //       Parameter_Options: "",
      //       Is_Muliple_Options: false,
      //       Parameter_Value: payload?.WorkflowId ? payload.WorkflowId.toString() : "",
      //       Updated_By: user?.UserName,
      //       Updated_On: new Date().toISOString(),
      //       IsActive: false,
      //       ModuleName: "Application Parameter",
      //       MenuId: appParameter_moduleId,
      //       Form_Id: 18,
      //       UserId: userLoginResponse?.UserId,
      //       CreatedBy: user?.UserName,
      //       Activity: parameterId ? "Update" : "Create",
      //       Status: 1,
      //     };
      //     let JsonDataForDisplay: any = {
      //       "Parameter Group": foundCategoryItem || "",
      //       "Parameter Name": values?.WorkflowName || "",
      //       "Parameter Description": values?.WorkflowDescription || "",
      //       "Parameter Value": payload?.WorkflowId ? payload.WorkflowId.toString() : "" ,
      //       "Parameter Options": "",
      //       Status: "InActive",
      //       // "Modified By": user?.UserName,
      //     };

      //     formDatas.Updated_By = user?.UserName;
      //     formDatas.Activity = "Edit";
      //     formDatas.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      //     console.log(formDatas, "Update WorkFlow");

      //     formDatas.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      //     dispatch(updateApplicationParameter(formDatas));
      //   }
      // }
    }
  };

  const handleOpen = (data: any, option: string) => {
    if (option === "edit" || option === "view" || option === "delete") {
      if (option === "edit" || option === "delete") {
        let index = stepsData.findIndex((stepItem) => stepItem.ProcessSeqno === data.ProcessSeqno);
        setEditStepDataIndex(index);
      }
      if (option === "delete") setIsDeleteData(data);
      else {
        setOpenEditStepForm(true);
        setEditStepData(data);
      }
    }
  };

  const deleteWorkflow = () => {
    let copyOfStepsData = [...stepsData];
    copyOfStepsData[editStepDataIndex] = {
      ...isDeleteData,
      IsActive: !isDeleteData.IsActive,
      IsActiveName: !isDeleteData.IsActive ? "Active" : "Inactive",
    };
    setValue("TAT", copyOfStepsData.reduce((n, { TAT, IsActive }: any) => (IsActive ? n + Number(TAT) : n), 0) as any);
    setStepsData([...copyOfStepsData]);
    setIsDeleteData(null);
  };

  const closeForm = (value: any) => {
    if (onClose && value === 0) {
      onClose();
    }
  };
  return (
    <>
      {loader ? <ProgressBar showProgress={loader} /> : null}
      <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
        <CustomBreadcrumbs
          list={[
            { value: 0, name: "Workflow Manager" },
            { value: 1, name: `${edit ? "Edit" : view ? "View" : "Add New"}` },
          ]}
          onchange={closeForm}
        />
      </Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Typography variant="h6" color={"text.primary"}>
          {edit ? "Update Workflow" : view ? "View Workflow" : "Create Workflow"}
        </Typography>
      </Stack>
      <Grid
        sx={{
          width: "100%",
          py: 4,
        }}
      >
        <Box
          sx={{
            px: {
              xs: 2,
              sm: 4,
              md: 2,
              lg: 2,
              xl: 2,
            },

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Controller
                  name="Workflow_Category"
                  control={control}
                  rules={{
                    required: "Workflow Type is Required",
                  }}
                  render={({ field }) => (
                    <CustomSelect
                      name={"Workflow_Category"}
                      valueKey={"LovId"}
                      optionLabelKey="Options"
                      options={workFlowCategoryOption}
                      required={true}
                      readOnly={view}
                      label={"Workflow Category"}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.Workflow_Category)}
                      helperText={
                        errorsloginform.Workflow_Category && errorsloginform.Workflow_Category.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Controller
                  name="WorkflowCode"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: "Workflow Code is Required",
                    validate: {
                      maxLength: (value) => {
                        if (value === undefined) return "WorkFlow Code is required";
                        return value.length <= 5 || "Maximum 5 characters allowed";
                      },
                    },
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"WorkflowCode"}
                      required={true}
                      readOnly={view}
                      label={"Code"}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.WorkflowCode)}
                      helperText={errorsloginform.WorkflowCode && errorsloginform.WorkflowCode.message?.toString()}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Controller
                  name="WorkflowName"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: "Workflow Name is Required",
                    validate: {
                      maxLength: (value) => {
                        if (value === undefined) return "Value is required";
                        return value.length <= 100 || "Maximum 100 characters allowed";
                      },
                    },
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"WorkflowName"}
                      required={true}
                      readOnly={view}
                      label={"Workflow Name"}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.WorkflowName)}
                      helperText={errorsloginform.WorkflowName && errorsloginform.WorkflowName.message?.toString()}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Controller
                  name="WorkflowDescription"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: "Workflow Description is Required",
                    validate: {
                      noSpecialCharacters: (value: string | undefined) => {
                        if (value === undefined) return "Value is required";
                        return !/[^a-zA-Z0-9\-\. ]/.test(value) || "Special Characters are not allowed";
                      },
                      maxLength: (value) => {
                        if (value === undefined) return "Value is required";
                        return value.length <= 100 || "Maximum 100 characters allowed";
                      },
                    },
                  }}
                  render={({ field }) => (
                    <CustomMultilineTextField
                      label={"Description"}
                      value={field.value}
                      rows={5}
                      required={true}
                      readOnly={view}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.WorkflowDescription)}
                      helperText={
                        errorsloginform.WorkflowDescription && errorsloginform.WorkflowDescription.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Controller
                    name="WorkflowType"
                    control={control}
                    rules={{
                      required: "Workflow Type is Required",
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        name={"WorkflowType"}
                        valueKey={"LovId"}
                        optionLabelKey="Options"
                        options={workFlowTypeOption}
                        required={true}
                        readOnly={view}
                        label={"Workflow Type"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.WorkflowType)}
                        helperText={errorsloginform.WorkflowType && errorsloginform.WorkflowType.message?.toString()}
                      />
                    )}
                  />
                  <Controller
                    name="IsActive"
                    control={control}
                    render={({ field }) => (
                      <CustomToogleSwitch
                        label={"Active"}
                        dynamicLabel={field.value ? ACTIVE : INACTIVE}
                        value={field.value}
                        disabled={view}
                        onChange={(data) => {
                          setValue("IsActive", data);
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Controller
                    name="TAT"
                    control={control}
                    defaultValue={"" as any}
                    render={({ field }) => (
                      <CustomTextField
                        name={"TAT"}
                        label={"Estimated Time(In Days)"}
                        value={field.value}
                        onChange={field.onChange}
                        readOnly={view}
                      />
                    )}
                  />
                  <Controller
                    name="Schedule"
                    control={control}
                    render={({ field }) => (
                      <CustomToogleSwitch
                        label={"Schedule"}
                        // dynamicLabel={field.value ? YES : NO}
                        value={field.value}
                        disabled={view}
                        onChange={(data) => {
                          setValue("Schedule", data);
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              {false && (
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} sx={{ paddingTop: "25px !important" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "center" }}>
                    <Controller
                      name="IsActive"
                      control={control}
                      render={({ field }) => (
                        <CustomToogleSwitch
                          label={"Active"}
                          dynamicLabel={field.value ? ACTIVE : INACTIVE}
                          value={field.value}
                          disabled={view}
                          onChange={(data) => {
                            setValue("IsActive", data);
                          }}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
            <Box>
              <Box sx={{ display: "flex", flexDirection: "column", py: 2 }}>
                <MaterialDynamicGrid
                  data={columns}
                  rows={stepsData}
                  handleAction={handleOpen}
                  enableZoomIcon={false}
                  showhistory={false}
                  showDelete={permissionPage?.IsDelete === 1 && !view}
                  showView={view as boolean}
                  showEdit={!view}
                  showExports={true}
                  showAdd={!view}
                  handleAdd={() => setOpenAddForm(true)}
                  userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                  pageName="Steps"
                  isActivekey="IsActiveName"
                />
              </Box>
            </Box>

            {!view && (
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "right",
                  mt: 2,
                }}
              >
                <React.Fragment>
                  <CustomButton
                    type="reset"
                    variant="outlined"
                    name={"Cancel"}
                    onClick={() => {
                      dispatch(setWorkFlowData(null));
                      // navigate("/workFlows")
                      onClose();
                    }}
                  />
                  <CustomButton
                    type="reset"
                    name={editWorkFlowData ? "Update" : "Submit"}
                    disabled={stepsData.length < 1}
                    onClick={handleUserForm(handleSubmit)}
                  />
                </React.Fragment>
              </Box>
            )}
          </Box>

          <CustomDialog
            show={openAddForm}
            onHide={() => {
              setOpenAddForm(false);
            }}
            maxWidth={"lg"}
            minHeight={"50vh"}
            header={"Add Step"}
            contentNode={
              <>
                <AddStep
                  workFlowCategory={categoryItem}
                  closePopUp={() => {
                    setOpenAddForm(false);
                  }}
                  addStep={(newdata: any) => {
                    const updatedData = [...stepsData, { ...newdata, DepartmentId: newdata.DepartmentId.toString() }];
                    setValue(
                      "TAT",
                      updatedData.reduce((n, { TAT, IsActive }) => (IsActive ? n + Number(TAT) : n), 0)
                    );
                    setStepsData(updatedData);
                    setOpenAddForm(false);
                  }}
                  exisitingSeqNo={stepsData.reduce((acc: number[], i) => {
                    acc.push(i.ProcessSeqno);
                    return acc;
                  }, [])}
                />
              </>
            }
          />

          <CustomDialog
            show={openEditStepForm}
            onHide={() => {
              setOpenEditStepForm(false);
            }}
            maxWidth={"lg"}
            minHeight={"50vh"}
            header={view ? "View Step" : "Update Step"}
            contentNode={
              <>
                <AddStep
                  workFlowCategory={categoryItem}
                  closePopUp={() => {
                    setOpenEditStepForm(false);
                  }}
                  addStep={(newdata: any) => {
                    let copyOfStepsData = [...stepsData];
                    copyOfStepsData[editStepDataIndex] = { ...newdata, DepartmentId: newdata.DepartmentId.toString() };
                    setValue(
                      "TAT",
                      copyOfStepsData.reduce((n, { TAT, IsActive }: any) => (IsActive ? n + Number(TAT) : n), 0) as any
                    );
                    setStepsData([...copyOfStepsData]);
                    setOpenEditStepForm(false);
                  }}
                  isEdit={true}
                  view={view}
                  editStepData={editStepData}
                  exisitingSeqNo={stepsData.reduce((acc: number[], i, index) => {
                    if (index != editStepDataIndex) acc.push(i.ProcessSeqno);
                    return acc;
                  }, [])}
                />
              </>
            }
          />
          <ModalPopUp
            onDeleteMethod={() => deleteWorkflow()}
            isDelete={true}
            onHide={() => setIsDeleteData(null)}
            show={isDeleteData ? true : false}
            header={"Confirm"}
            text={`Are you sure you want to ${isDeleteData?.IsActive ? "deactivate" : "activate"} the selected Process`}
            ActionName={isDeleteData?.IsActive ? "Inactive" : "Active"}
          />
        </Box>
      </Grid>
    </>
  );
};

export default CreateWorkFlow;
