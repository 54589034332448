import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AqmResponse } from "./aqmResponse/AqmResponse";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store/store";
import { useParams } from "react-router-dom";
import { getLogginVendor, logoutUser } from "../store/slices/authorization";
import { updateLoader } from "../store/slices/loader";
import VendorLoginpage from "./vendorLogin/VendorLoginPage";

export const VendorPortal: React.FC = () => {
    const dispatch = useAppDispatch();

    const { isVendorAuthenticated  , isLoading , vendorLoginDetails } = useSelector((state: RootState) => state.loggerData);
    const { vendor_persional_Id , Project_Details_Id } = useParams();

    useEffect(() => {

        dispatch(updateLoader( isLoading));
    
      }, [ isLoading]);

      useEffect(()=>{
        dispatch(logoutUser(false));
      },[])

     return(
        <>
         <Box sx={{p:isVendorAuthenticated?2:"" , overflowY:'scroll' , height:'100vh'  }} >
           {(isVendorAuthenticated && vendorLoginDetails?.vendorId)  ? <AqmResponse/> : <VendorLoginpage /> }
        </Box>
        </>
     )
}