import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { Controller, useForm } from "react-hook-form";
import { ACTIVE, textFieldWidth } from "../../../utils/constants";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import CustomAutoComplete from "../../../common/dynamicInputs/CustomAutoComplete";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { getCurrencyList } from "../../../store/slices/currencyData";
import { getParentLocationList } from "../../../store/slices/locationMasterData";
import { LocationConfig, LovConfig } from "../../../config/config";
import { getListOfValues } from "../../../store/slices/ListOfValues";
import contractRRService from "../../../store/contractManagement/services/contractRolesAndResponsibility";

interface StampingDetailsProps {
  onClose?: () => void;
  onSubmit?: (data: any) => void;
  control: any;
  errorDetailsForm: any;
  watch: any;
  contractData: any;
  setValue: any;
}

const StampingDetails: React.FC<StampingDetailsProps> = ({
  onClose,
  onSubmit,
  control,
  errorDetailsForm,
  watch,
  setValue,
  contractData,
}) => {
  const dispatch = useAppDispatchThunk();
  const [getCurrency, setCurrency] = useState<any[]>([]);

  const [contractTypeOptions, setContractTypeOptions] = useState<any>([]);
  const { CurrencyData } = useSelector((state: RootState) => state.CurrencyData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { ParentLocationTable } = useSelector((state: RootState) => state.locationMasterData);
  const [articles, setArticles] = useState<any[]>([]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSubmitForm = (data: any) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  const CallonpageLoad = () => {
    // dispatch(getContractType());
    dispatch(getListOfValues({ Name: LovConfig.Contract_Nature }));
    dispatch(getCurrencyList({ FormID: 0 }));
  };

  const fetchParentLocation = () => {
    let dublocationType: any[] = lovTable.filter((item: any) => item.Name === LovConfig?.Location_Type);
    if (dublocationType && dublocationType.length > 0) {
      let countryTypeId: any = dublocationType.find((item) => item.Options === LocationConfig?.Location_State);
      if (countryTypeId && countryTypeId.LovId) {
        dispatch(getParentLocationList({ LocationtypeId: countryTypeId.LovId }));
      }
    }
  };

  const fetchContractNature = () => {
    const contractNature = lovTable.filter((item: any) => item.Name === LovConfig?.Contract_Nature);
    if (contractNature && contractNature.length > 0) {
      setContractTypeOptions(contractNature.map((item: any) => ({ type: item.Options })));
    }
  };

  useEffect(() => {
    fetchParentLocation();
    fetchContractNature();
  }, [lovTable]);

  useEffect(() => {
    CallonpageLoad();
  }, []);

  const getArticle = async (location: string) => {
    const data = await contractRRService.getArticleByLocation({ id: location });
    setArticles(data.articleDetails);
  };

  useEffect(() => {
    if (watch("location")) {
      const selectedLocation = watch("location");
      const locationObj = ParentLocationTable.find((data: any) => data.Location_Name === selectedLocation);
      getArticle(locationObj.Location_Id);
    }
  }, [watch("location")]);

  useEffect(() => {
    if (CurrencyData && CurrencyData.length > 0) {
      const ActiveCurrencies = CurrencyData.filter((item: any) => item.IsActive === ACTIVE);
      const MergedCurrencies = ActiveCurrencies.map((item: any) => ({
        ...item,
        mergedCurrency: `${item.CurrencyCode} (${item.Symbol}) `,
      }));
      setCurrency(MergedCurrencies);
    }
  }, [CurrencyData]);

  return (
    <Box>
      <Box>
        <Grid container spacing={2} mt={2}>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              defaultValue={"online"}
              name="stampingType"
              control={control}
              rules={{
                required: "Stamping type is Required",
              }}
              render={({ field }) => (
                <CustomSelect
                  name={"stampingType"}
                  valueKey={"id"}
                  optionLabelKey="stampingType"
                  options={[
                    { id: "online", stampingType: "Online" },
                    { id: "offline", stampingType: "Offline" },
                  ]}
                  required={true}
                  label={"stamping Type"}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  error={Boolean(errorDetailsForm.ContractType)}
                  helperText={errorDetailsForm.ContractType && errorDetailsForm.ContractType.message?.toString()}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="ContractNature"
              control={control}
              rules={{
                required: "Contract Nature is required",
              }}
              render={({ field }) => (
                <CustomSelect
                  name={"ContractNature"}
                  valueKey={"type"}
                  optionLabelKey="type"
                  options={contractTypeOptions}
                  required={true}
                  label={"Nature of Contract"}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  error={Boolean(errorDetailsForm.ContractNature)}
                  helperText={errorDetailsForm.ContractNature && errorDetailsForm.ContractNature.message?.toString()}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="location"
              control={control}
              rules={{
                required: "Location is required",
              }}
              render={({ field }) => (
                <CustomSelect
                  name={"location"}
                  valueKey={"Location_Name"}
                  optionLabelKey="Location_Name"
                  options={ParentLocationTable || []}
                  required={true}
                  label={"Stamping Location (State)"}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("article", "");
                  }}
                  error={Boolean(errorDetailsForm.location)}
                  helperText={errorDetailsForm.location && errorDetailsForm.location.message?.toString()}
                />
              )}
            />
          </Grid>
          {watch("stampingType") === "online" && (
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
            >
              <Controller
                name="article"
                defaultValue={""}
                control={control}
                rules={{
                  required: "Article is required",
                }}
                render={({ field }) => (
                  <CustomSelect
                    name={"article"}
                    valueKey={"articleNo"}
                    optionLabelKey="articleNo"
                    options={articles || []}
                    required={watch("stampingType") === "online" ? true : false}
                    label={"Article code"}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    error={Boolean(errorDetailsForm.article)}
                    helperText={errorDetailsForm.article && errorDetailsForm.article.message?.toString()}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        {watch("stampingType") === "online" && (
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <CustomMultilineTextField
                    maxRows={4}
                    readOnly={true}
                    label={"Description of Documentation"}
                    value={watch("article") ? articles.find((i) => i.articleNo === watch("article"))?.articleName : ""}
                    onChange={field.onChange}
                    error={Boolean(errorDetailsForm.mobileNo)}
                    helperText={errorDetailsForm.mobileNo && errorDetailsForm.mobileNo.message?.toString()}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
            <Box sx={{ display: "flex", gap: 0.4 }}>
              <Controller
                name="contractValue"
                control={control}
                rules={{
                  required: "Contract Value is Required",
                  validate: {
                    maxLength: (value) => {
                      if (value === undefined) return "Value is required";
                      return value.length <= 100 || "Maximum 100 characters allowed";
                    },
                  },
                }}
                render={({ field }) => (
                  <CustomNumberField
                    name={"contractValue"}
                    label={"Contract Value"}
                    value={field.value}
                    onChange={field.onChange}
                    required={true}
                    isDecimal={true}
                    error={Boolean(errorDetailsForm.contractValue)}
                    helperText={errorDetailsForm.contractValue && errorDetailsForm.contractValue.message?.toString()}
                  />
                )}
              />
              <Box sx={{ width: "50%" }}>
                <Controller
                  name="currencyCode"
                  control={control}
                  defaultValue="INR"
                  rules={{
                    required: "Currency code is Required",
                  }}
                  render={({ field }) => (
                    <CustomAutoComplete
                      optionValueKey={"CurrencyCode"}
                      optionLabelKey="mergedCurrency"
                      options={getCurrency || []}
                      value={field.value}
                      onChange={field.onChange}
                      label={"Currency"}
                      key={"CurrencyCode"}
                      error={Boolean(errorDetailsForm.currencyCode)}
                      helperText={errorDetailsForm.currencyCode && errorDetailsForm.currencyCode.message?.toString()}
                    />
                  )}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
            <Box sx={{ display: "flex", gap: 0.4 }}>
              <Controller
                name="stampingDuty"
                control={control}
                rules={{
                  required: "Stamping Duty is Required",
                  validate: {
                    maxLength: (value) => {
                      if (value === undefined) return "Value is required";
                      return value.length <= 100 || "Maximum 100 characters allowed";
                    },
                  },
                }}
                render={({ field }) => (
                  <CustomNumberField
                    name={"stampingDuty"}
                    label={"Stamping Duty"}
                    value={field.value}
                    onChange={field.onChange}
                    required={true}
                    isDecimal={true}
                    error={Boolean(errorDetailsForm.stampingDuty)}
                    helperText={errorDetailsForm.stampingDuty && errorDetailsForm.stampingDuty.message?.toString()}
                  />
                )}
              />
              <Box sx={{ width: "50%" }}>
                <Controller
                  name="currencyCode"
                  control={control}
                  defaultValue="INR"
                  rules={{
                    required: "Currency code is Required",
                  }}
                  render={({ field }) => (
                    <CustomAutoComplete
                      optionValueKey={"CurrencyCode"}
                      optionLabelKey="mergedCurrency"
                      options={getCurrency || []}
                      value={field.value}
                      onChange={field.onChange}
                      label={"Currency"}
                      key={"CurrencyCode"}
                      error={Boolean(errorDetailsForm.currencyCode)}
                      helperText={errorDetailsForm.currencyCode && errorDetailsForm.currencyCode.message?.toString()}
                    />
                  )}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
            <Controller
              name="stampingDutyPurchased"
              control={control}
              rules={{
                required: "Stamping Duty purchased is Required",
              }}
              render={({ field }) => (
                <CustomSelect
                  name={"stampingDutyPurchased"}
                  valueKey={"id"}
                  optionLabelKey="status"
                  options={
                    contractData
                      ? [contractData.company_Name, contractData.vendor_Name].map((data) => ({
                          id: data,
                          status: data,
                        }))
                      : []
                  }
                  label={"Stamping Duty Purchased / Paid By"}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  error={Boolean(errorDetailsForm.stampingDutyPurchased)}
                  helperText={
                    errorDetailsForm.stampingDutyPurchased && errorDetailsForm.stampingDutyPurchased.message?.toString()
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default StampingDetails;
