import { Box, Grid, Stack, Typography, Divider, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Tooltip, IconButton, Collapse } from "@mui/material";
import React, { createContext, useEffect, useMemo, useState } from "react";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import PageMainHeading from "../../../common/CommonHeadings/PageMainHeading";
import LableandValuesPreview from "../../../common/CommonPreview/LableandValuesPreview";
import { textFieldWidth } from "../../../utils/constants";
import PageInnerHeading from "../../../common/CommonHeadings/PageInnerHeading";
import BasicTableStyle from "../../../common/dynamicDataGrid/basicTable.module.scss";
import { useTheme, } from '@mui/material/styles'
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomSvg from "../../../common/CustomSvg";
import gridStyle from "../../../common/dynamicDataGrid/dynamicgrid.module.scss";
import { ContractDetails } from "../PMCommonDetails/ContractDetails";
import { RequirementDetails } from "../PMCommonDetails/RequirementDetails";
import PRSlaDetailsTable from "./PRSlaDetailsTable";
import { PMWorflowPages } from "../../../config/config";
import { getAllThresholdMaster } from "../../../store/performanceManagement/slices/thresholdMaster";
import { RootState, useAppDispatch } from "../../../store/store";
import { getAllSeverityMaster } from "../../../store/performanceManagement/slices/severityMaster";
import ScopeDefineService from "../../../store/performanceManagement/services/scopeDefine";
import PMEventLogService from "../../../store/performanceManagement/services/pmEventlogservice";
import { CreateEventLogSLA } from "../../../store/performanceManagement/slices/pmEventLog";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { useSelector } from "react-redux";
import { BulkUploadQuestionPMPR } from "./prReviewBulkUpload/prReviewUploadTemplateDownload";
import { updateLoader } from "../../../store/slices/loader";
import { CreatePMResponseSoandCXO } from "../../../store/performanceManagement/slices/pmInitiateandResponseReview";
import { getAllDeviationToleranceLevelMaster } from "../../../store/performanceManagement/slices/deviationToldeviationToleranceLevelMaster";
export interface PMResponseProps {
    view?: any;
    edit?: any;
    isEditData?: any;
    onClose?: () => void;
    PageName: any;
    prePageName? :any
}


interface slaContextProps {

  slaList: any[];
  validationErrors: any;
  setSlaList: (value : any) => void;
  setvalidationErrors: (value: any) => void;
}

export const SlaDetailsContext = createContext<slaContextProps | undefined>(undefined);
export const PMResponse: React.FC<PMResponseProps> = ({
    view,
    edit,
    isEditData,
    onClose,
    PageName,
    prePageName
}) => {
    const { ispmEventLoading , EventLogSLAAdded   } = useSelector(
        (state: any) => state.performanceManagementReducer?.PMEventLogDataSlice 
    );

    const {  isPMResponseLoading  , pMResponseAAdded , PMResponseToasterMsg } = useSelector(
        (state: any) => state.performanceManagementReducer?.PMResponseDataSlice
    );
    const {  ThresholdTable }: any =  useSelector( (state: any) => state.performanceManagementReducer?.ThresholdMasterDataSlice);
          const {  SeverityMasterTable }: any = useSelector( (state: any) => state.performanceManagementReducer.SeverityMasterDataSlice);
      const {  DeviationToleranceLevelTable,  }: any = useSelector( (state: any) => state.performanceManagementReducer.DeviationToleranceLevelMasterDataSlice);
  
  const dispatch = useAppDispatch();
    const theme = useTheme();
    const [Remark, setRemark] = useState('')
    const [ThresholdValue , setThresholdValues] = useState<any[]>([])
    const [SeverityValue , setSeverityValues] = useState<any[]>([])
    const [DeviationToleranceLevel , setDeviationToleranceLevel] = useState<any[]>([])
    
   
    const [contractdetails, setcontractdetails] = useState<any>({});
    const [validationErrors, setvalidationErrors] = useState<any>({});
    const [finalRating, setfinalRating] = useState<any>(0);
    const [finalRatingValue, setfinalRatingValue] = useState<any>("");
    const [submitstatus, setsubmitstatus] = useState<any>(1);
    const [isVendorConform, closeisVendorConformPopup] = useState<any>(false);
    const [isuserConform, closeisuserConformPopup] = useState<any>(false);
    const [EventData, setEventData] = useState<any>("");
    const [RequirementData, setRequirementData] = useState<any>({});
    const [ScoreAndSLADetails, setScoreAndSLADetails] = useState<any>();
    const [slaList, setSlaList] = useState([]);
      const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
            (state: RootState) => state.loggerData
        );
 
    useEffect(() => {
     dispatch(getAllThresholdMaster({
            FormID: 2
        }))
           dispatch(getAllSeverityMaster({
                            FormID: 2
                        }))
         dispatch(getAllDeviationToleranceLevelMaster({
                    FormID: 2
                }))
    },[])

      useEffect(() => {
        dispatch(updateLoader(ispmEventLoading || isPMResponseLoading ));
      },[ispmEventLoading , isPMResponseLoading])

    useEffect(() => {
        if (isEditData && isEditData.ProjectId) {
           
            fetchSLA()
        }
    }, [isEditData]);
    useEffect(() => {
     
        let filtersThreshold : any[] = ThresholdTable?.filter((item: any) => item?.isActive == "Active" ) || []
        setThresholdValues(filtersThreshold)
      },[ThresholdTable])

      useEffect(() => {
     
        let filtersDeviationToleranceLevelTable : any[] = DeviationToleranceLevelTable?.filter((item: any) => item?.isActive == "Active" ) || []
        setDeviationToleranceLevel(filtersDeviationToleranceLevelTable)
      },[DeviationToleranceLevelTable])

      
      
      useEffect(() => {
      
        let filteredSeverity : any[] = SeverityMasterTable?.filter((item: any) => item?.isActive == "Active" ) || []
        setSeverityValues(filteredSeverity)
      
      },[SeverityMasterTable])
    useEffect(() => {
        if (isEditData && isEditData.ProjectId) {
            fetchScope();
        }
    }, [isEditData]);
    

    const closeTheForm = () => {
        onClose && onClose()
    }

    const fetchSLA = async () => {
        try {
            const res = await PMEventLogService.GetEventLogSLAByID({PR_EventLog_Id : isEditData.ProjectId});
            setSlaList(res?.eventLogSLAParams || []);
            setScoreAndSLADetails(res)
        } catch (error) {
            console.error("Error fetching scope data:", error);
        }
    };

    const fetchScope = async () => {
        try {
            const res = await ScopeDefineService.getScopeByEventLogId({ id: isEditData.ProjectId });
            setEventData(res);
        } catch (error) {
            console.error("Error fetching scope data:", error);
        }
    };

useEffect(() => {
      
      if(ScoreAndSLADetails && ScoreAndSLADetails?.eventLogSLAParams &&  PageName !=  PMWorflowPages?.VendorPortal){
         if(PageName == PMWorflowPages.SOReview){
            let {Rating ,Value}  =   calculateRating(ScoreAndSLADetails?.eventLogSLAParams || []) 
            setfinalRating(Number(Rating) || 0)
            setfinalRatingValue(Value)
         }else{
             let FinalTotalRating =  ScoreAndSLADetails?.rating_Percentage || 0;
            let findedFinalTotalRatingValue = DeviationToleranceLevel.find((item : any) => item?.fromRange <= FinalTotalRating && item?.toRange >= FinalTotalRating)?.description;
            setfinalRating(FinalTotalRating)
            setfinalRatingValue(findedFinalTotalRatingValue || "")
         }

      }

},[ScoreAndSLADetails , ThresholdValue , SeverityValue , DeviationToleranceLevel])



const ValidateScore = () => {
    let errors: { [key: string]: string } = { ...validationErrors };
    slaList.forEach((item: any, index: any) => {
      if (item && !item?.actual) {
        errors[`${index}-actual`] = "Actual is required";
      } else {
        delete errors[`${index}-actual`];
      }
    });
    setvalidationErrors(errors);
    return errors
   
  }

const handleSubmitVendor = () => {
    let errors : any =  ValidateScore()
   
    if (Object.keys(errors).length === 0 && slaList && slaList?.length > 0) {
        closeisVendorConformPopup(true)      
    }
}

   const handleSlaFormSubmit = () => {
        const formData: any = {};

        formData.eventlog_Target_Id = isEditData?.ProjectId;
        formData.moduleName = isEditData?.formName;
        formData.activity = "Create";
        formData.Workflow_InstanceId = isEditData?.Workflow_InstanceId;
        formData.Process_InstanceId = isEditData?.Id;
        formData.PR_ReviewId = isEditData?.ProjectId;
        formData.slaProcess = [];
        if (slaList) {
            slaList.forEach((item: any) =>
                formData.slaProcess.push({
                    eventlog_SLA_Id: item?.eventlog_SLA_Id,
                    sla: item.sla,
                    measured_In: item.measured_In,
                    target_Min: item.target_Min,
                    target_Max: item.target_Max,
                    tolerance_Level: item.tolerance_Level,
                    bestScore: item.bestScore,
                    severity_Of_SLA: item.severity_Of_SLA,
                    remark: item.remark,
                    isActive: item.isActive,
                    updatedBy: userLoginResponse?.UserName,
                    updatedOn: new Date(),
                    actual: item.actual,
                    score: item.score,
                    deviation: item?.deviation?.toString(),
                }))
        }
        console.log(formData);
        dispatch(CreateEventLogSLA(formData));

    };


  const   submitReviewPRApproved =() => {
    const formData: any =  {
        "workflow_InstanceId": isEditData?.Workflow_InstanceId,
        "process_InstanceId": isEditData?.Id,
        "event_Id": isEditData?.ProjectId,
        "so_Remarks": PageName == PMWorflowPages.SOReview  ? Remark : "",
        "cxo_Remarks": PageName == PMWorflowPages.CXO  ? Remark : "" ,
        "rating_Percentage": finalRating,
        "isActive": true,
        "updatedBy": userLoginResponse?.UserEmail,
        "updatedOn": new Date(),
        "userId": userLoginResponse?.UserId,
        moduleName : isEditData?.FormName,
        activity : "Create",
        "ConditionValue" : submitstatus
      };

      dispatch(CreatePMResponseSoandCXO(formData));
      
  
  }


const calculateRating =(slaListData : any[]) => {
   let TotalBestScore = 0
   let TotalDeviation = 0

    slaListData.forEach((slaItem : any) => {
        let best : number = slaItem?.score || 0 ;
        let Threshold : number = 0
        let Seviarity : number = SeverityValue?.find((item : any) => item?.severityId == slaItem?.severity_Of_SLA )?.score || 0  
        let findedThreshold = ThresholdValue.find((item : any) => item?.fromRange <= best && item?.toRange >= best)
      
               if(findedThreshold && findedThreshold?.score){
                    Threshold = Number(findedThreshold?.score);
               }
      
               let ActualScore = Number(Seviarity) * Threshold;
               let BestScore = Number(Seviarity) * Number(slaItem?.bestScore);
               let Deviation = Number(BestScore) - Number(ActualScore) ;

               TotalBestScore += BestScore
               TotalDeviation += Deviation
    })

    
   let FinalTotalRating = Math.abs(TotalDeviation / TotalBestScore) * 100
   let FinalTotalRatingValue = ""

   
   let findedFinalTotalRatingValue = DeviationToleranceLevel.find((item : any) => item?.fromRange <= FinalTotalRating && item?.toRange >= FinalTotalRating)
      
   if(findedFinalTotalRatingValue && findedFinalTotalRatingValue?.description){
    FinalTotalRatingValue = findedFinalTotalRatingValue?.description;
   }
  
    return {Rating : FinalTotalRating.toFixed(2) , Value : FinalTotalRatingValue}
   
}

    useEffect(() => {
        if (EventData) {
            const formData = mapFormData(EventData);
            setRequirementData(formData);
        }
    }, [EventData]);

 

    const mapFormData = (data: any) => ({
        clause_reference_no: data?.pmprClauses?.[0]?.clause_reference_no || '',
        clause_Header: data?.pmprClauses?.[0]?.clause_Header || '',
        WorkFlow: '',
        scope_Of_Target: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctScope?.scope_Of_Target || '',
        deliverables: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctScope?.deliverables || '',
        review_Trigger_Name: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctMonitorPeriods?.review_Trigger_Name || '',
        frequency_Name: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctMonitorPeriods?.frequency_Name || '',
        review_Start_Date: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctMonitorPeriods?.review_Start_Date || '',
        review_End_Date: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctMonitorPeriods?.review_End_Date || '',
        input_Dependency_Name: data?.pmprClauses?.[0]?.pmprClauseTarget?.[0]?.pmprctScope?.input_Dependency_Name || '',
        companyName: data?.company_Name,
        vendorName: data?.vendor_Name,
        services_Name: data?.service_Name,  
        contractRefNumber: data?.vendor_Contract_Number,
        pM_Ref_Number: data?.prRef_Number,
    });

    return (<>
        <Box sx={{ width: "100%" }}>
            <Stack spacing={2}>
            {prePageName && <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CustomBreadcrumbs
                list={[
                  { value: 0, name: `${prePageName}` },
                  { value: 1, name: `PR Scope Definition` },
                ]}
                onchange={(val: number) => {if(val == 0 && onClose){
                    onClose()
                }}}
              />
            </Box> }
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                    }}
                >
                    <Typography variant="h6" color={"text.primary"}>
                        {`${PageName}`}
                    </Typography>
                </Box>
               
                <ContractDetails data={RequirementData} />
                <Divider sx={{ border: '1px solid', color: "#e0e0e3" }} />
                <RequirementDetails data={RequirementData} />
                
                <Divider sx={{ border: '1px solid', color: "#e0e0e3" }} />

                <Box
                    sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, width: "100%" }}>
                    <PageInnerHeading title={"SLA To Monitor"} />
                    {PageName == PMWorflowPages?.VendorPortal &&
                        <Box sx={{display : 'flex' , gap :1 }}>
                            <>
                            <BulkUploadQuestionPMPR SLAData={slaList} />
                            </>
                            <Box>
                            <Tooltip title="Export">
                                <IconButton onClick={() => { }}>
                                    <CustomSvg color={`${theme.palette.primary.main}`} size={18} name={"export"} />
                                </IconButton>
                            </Tooltip>
                            </Box>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Box>
                            <Tooltip title="Import">
                                <IconButton onClick={() => { }}>
                                    <CustomSvg color={`${theme.palette.primary.main}`} size={18} name={"import"} />
                                </IconButton>
                            </Tooltip>
                            </Box>
                        </Box>
                    }


                </Box>
                 <SlaDetailsContext.Provider
                              value={{
                                slaList,
                                validationErrors,
                                setSlaList,
                                setvalidationErrors
                              }}
                            >
                <PRSlaDetailsTable
                    PageName={PageName}
                />
                </SlaDetailsContext.Provider>

               { PageName != PMWorflowPages?.VendorPortal ? <Box sx={{display :'flex' , gap :2 , justifyContent :'flex-end' }}>
                        <Typography>
                            Final Rating 
                        </Typography>
                        <Typography>
                            {`${finalRating}% | ${finalRatingValue}`}
                        </Typography>
                </Box> : null }
             
                { PageName != PMWorflowPages?.VendorPortal ?   <Box
                    sx={{
                        height: "100%",
                        width: "100%",
                    }}
                >
                 <Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CustomMultilineTextField
                                label={"Remarks"}
                                value={Remark}
                                rows={3}
                                required={true}
                                onChange={(e) => {
                                    setRemark(e)
                                }}
                            // error={Boolean(remarkerror)}
                            // helperText={remarkerror}
                            />
                        </Grid>
                    </Grid>

                </Box> : null }
                {
                    PageName === PMWorflowPages?.VendorPortal ? <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, width: "100%" }}>
                       
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "flex-end", width :'100%' }}>
                         
                            <CustomButton
                                type="reset"
                                name={"Submit"}
                                onClick={() => {
                                    handleSubmitVendor()
                                }}
                            />
                        </Box>
                    </Box> :
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, width: "100%" }}>
                            <CustomButton
                                type="reset"
                                variant="outlined"
                                disabled={false}
                                name={"Discard"}
                                onClick={() => {
                                    onClose && onClose()
                                }}
                            />
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "space-between", marginLeft: 3 }}>
                              
                                <CustomButton
                                    disabled={Remark.length == 0}
                                    type="reset"
                                    name={"Accept"}
                                    onClick={() => {
                                        setsubmitstatus(1)
                                        closeisuserConformPopup(true)
                                    }}
                                />
                                <CustomButton
                                    disabled={Remark.length == 0}
                                    type="reset"
                                    name={"Reject"}
                                    onClick={() => {
                                        setsubmitstatus(0)
                                        closeisuserConformPopup(true)

                                    }}
                                />
                            </Box>
                        </Box>
                }
            </Stack>
        </Box>
        <ModalPopUp
                    onDeleteMethod={() => handleSlaFormSubmit()}
                    isDelete={true}
                    onHide={() => closeisVendorConformPopup(false)}
                    show={isVendorConform}
                    ActionName={"Submit"}
                    header={"Confirm"}
                    text={`Are you sure you want to submit?`}
                />

        <ModalPopUp
                    onDeleteMethod={() => submitReviewPRApproved()}
                    isDelete={true}
                    onHide={() => closeisuserConformPopup(false)}
                    show={isuserConform}
                    ActionName={"Submit"}
                    header={"Confirm"}
                    text={`Are you sure you want to ${submitstatus === 1 ? "Approve" : "Reject"}  the PR?`}
                />
    </>)
}