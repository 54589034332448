import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import { getAllApiMaster } from "../../store/slices/apiMaster";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomMultilineTextField from "../../common/dynamicInputs/CustomMultilineTextField";
import CustomAccordion from "../../common/dynamicAccordion/CustomAccordion";
import CustomButton from "../../common/dynamicButton/CustomButton";
import DynamicBasicTable from "../../common/dynamicbasicTable/DynamicBasicTable";
import CustomBreadcrumbs from "../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { AddApiParameter } from "./AddApiParameter";
import { AddApiFields } from "./AddApiFields";
import {
  getApplicationParameterList,
} from "../../store/slices/applicationParameterData";
import { ApplicationParameterName } from "../../config/config";
import { getAllApiCatalog, GetApiCatalogWithAllDetailsByID, saveApiCatalog, updateApiCatalog } from "../../store/slices/apiCatalog";
import { isActive } from "@tiptap/core";
import { log } from "console";
import CustomNumberField from "../../common/dynamicInputs/CustomNumberField";

export interface ApicatalogProps {
  view?: boolean;
  isEditData?: any;
  SignzyData?: any;
  edit?: boolean;
  masterId?: string;
  onClose?: any;
  isDraftData?: boolean;
  ModuleId?: number | undefined;
  isAqmResponse?: any;
  activestepCount?: any;
  handleNext?: any;
  handlePrevious?: any;
  isWorkFlow?: any;
  prePageName?: any;
  isDisableBreadCrums?: boolean;
}
interface FormData {
  APIMasterID?: string;
  ApiCode?: string;
  ApiName?: string;
  Url?: string;
  NoOfParameters?: number;
  ApiMasters?: any;
  APIParameters: any;
  ApiFields: any;

  // SubDepartmentName?: string;
  Department_Code?: string;
  Department_Type?: string;
  Parent_Level?: string;
  Parent_Department_Id?: string;
  Level?: string;
  Parent?: string;
  IsActive?: any;
}
export const AddApiCatalog: React.FC<ApicatalogProps> = ({
  view,
  isEditData,
  edit,
  onClose,
  prePageName,
  isDisableBreadCrums,
}) => {
  const dispatch = useAppDispatchThunk();
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const [apiMasterFormData, setApiMasterFormData] = useState<any>([]);
  const [statusIdLabelMapping, setStatusIdLabelMapping] = useState<any>({
    1: "Active",
    0: "Inactive",
  });

  const [expanded, setExpanded] = useState<any>(1);
  const [isNewParameter, setIsNewParameter] = useState(false);

  const [IsEditparameter, setIsEditparameter] = useState(false);
  const [IsEditField, setIsEditField] = useState(false);
  const [noOfParameters, setNoOfParameters] = useState<any>(null);
  const [isNewField, setIsNewField] = useState(false);
  const [addApiParameter, setAddApiParameter] = useState(false);
  const [apiFieldsEditData, setapiFieldsEditData] = useState();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [apiParameterEditData, setapiParameterEditData] = useState();
  const [Option, setOption] = useState(false);
  const { ApiMasterTable, isLoading, isApiMasterAdded, isApiMasterUpdated, ToasterMsg } =
    useSelector((state: RootState) => state.ApiMasterData);
  const { applicationParameterData, isAPAdded, isAPDeleted, isAPUpdated, versionHistory, apToasterMsg } = useSelector(
    (state: RootState) => state.applicationParameter
  );
  const ParameterColumns = [
    { Field: "ParameterNo", DisplayName: "Parameter No", },
    { Field: "Parameter", DisplayName: "Parameter" },
    { Field: "CLife_Json_Field", DisplayName: "CLife JSON Field" },
    { Field: "API_Json_Field", DisplayName: "API JSON Field" },
    { Field: "DataType", DisplayName: "Data Type" },
    { Field: "Width", DisplayName: "Width" },
    { Field: "Purpose", DisplayName: "Purpose" },
    { Field: "IsPrimaryDisplay", DisplayName: "IsPrimary" }
  ];
  const FieldsColumns = [
    { Field: "FieldNo", DisplayName: "Field No", },
    { Field: "CLife_Json_Field", DisplayName: "CLife JSON Field" },
    { Field: "API_Json_Field", DisplayName: "API JSON Field" },
    { Field: "DataType", DisplayName: "Data Type" },
    { Field: "Width", DisplayName: "Width" },
    { Field: "Context", DisplayName: "Purpose" },
  ]
  const callApiData = () => {
    dispatch(getAllApiCatalog({ FormID: 0 }));
  };

  let formData: any = {
    Id: edit ? isEditData?.Id : 0,
    APIMasterID: isEditData ? isEditData?.APIMasterID : 0,
    ApiMasters: isEditData ? isEditData?.ApiMasters : [],
    ApiName: isEditData ? isEditData?.APIName : "",
    ApiCode: isEditData ? isEditData?.APICode : "",
    NoOfParameters: isEditData ? isEditData?.NoOfParameters : "",
    User_Name: isEditData ? isEditData?.User_Name : "",
    Url: isEditData ? isEditData?.URL : "",
    // Login_Id: isEditData ? isEditData?.Login_Id : "",
    // Pwd: isEditData ? isEditData?.Pwd : "",
    Format: isEditData ? isEditData?.Format : "",
    IsActive: isEditData ? isEditData?.IsActive : true,
  };

  const {
    handleSubmit: handleCatalogForm,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
    clearErrors,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });
  useEffect(() => {
    callApiData();
  }, []);
  useEffect(() => {
    if (isEditData) {
      const apiParametersData = isEditData.APIParameters;

      // Append the data to the `ParameterField` using `appendParameter`
      apiParametersData?.forEach((param: any, index: any) => {
        appendParameter({
          ParameterNo: index + 1,
          API_ParameterID: param.API_ParameterID ? param.API_ParameterID : "",
          Parameter: param.Parameter ? param.Parameter : "",
          DataType: param.DataType ? param.DataType : "",
          CLife_Json_Field: param.CLife_Json_Field ? param.CLife_Json_Field : "",
          API_Json_Field: param.API_Json_Field ? param.API_Json_Field : "",
          IsPrimary: param?.IsPrimary,
          IsPrimaryDisplay: param?.IsPrimary ? "Yes" : "No",
          isActive: param?.IsActive,
          IsActive: param?.IsActive,
          //  IsActive: param.IsActive?"Active":"InActive",
          Status: param?.IsActive ? 1 : 0,
          Status_Value: param?.IsActive ? "InActive" : "Active",
          Width: param?.Width,
          Purpose: param?.Purpose,
        });
      });
    }
  }, [isEditData])

  useEffect(() => { dispatch(getAllApiMaster({ FormID: 2 })); }, []
  )
  useEffect(() => {
    // Assuming `isEditData` contains the data object
    if (isEditData) {
      const apiFieldsData = isEditData?.ApiFields;

      // Append the data to the `ApiFieldsField` using `appendApiFields`
      apiFieldsData?.forEach((field: any, index: any) => {
        appendApiFields({
          FieldNo: index + 1,
          Field_ID: field.Field_ID ? field.Field_ID : "",
          API_Json_Field: field.API_Json_Field ? field.API_Json_Field : "",
          DataType: field.DataType ? field.DataType : "",
          Context: field.Context ? field.Context : "",
          CLife_Json_Field: field.CLife_Json_Field ? field.CLife_Json_Field : "",
          isActive: field.IsActive,
          IsActive: field.IsActive,
          // IsActive: field.IsActive?"Active":"InActive",
          Status: field.IsActive ? 1 : 0,
          Status_Value: field.IsActive ? "InActive" : "Active",
          Width: field.Width ? field.Width : 1,
        });
      });
    }
  }, [isEditData]);

  useEffect(() => {
    const APICodePrefix: any = ApplicationParameterName.APICodePrefix;
    const APICatalogGrpName: any = ApplicationParameterName.APICatalogGrpName
    let finedAPIPrefix: any = applicationParameterData.find(
      (applicationParamitem) => applicationParamitem.Parameter_Name === APICodePrefix && applicationParamitem.Parameter_Group === APICatalogGrpName
    )?.Parameter_Value;
    !isEditData && setValue("ApiCode", finedAPIPrefix)
    //  !isEditData&& appendParameter( isEditData?.APIParameters)
    //  !isEditData&& appendApiFields(ApiFields)

  }, [])
  useEffect(() => {
    let value = getValues('NoOfParameters')
    setNoOfParameters(value)
  }, [getValues()])
  useEffect(() => {
    let value = getValues('NoOfParameters')
    setNoOfParameters(value)
  }, [])
  useEffect(() => {
    setApiMasterFormData(ApiMasterTable)
  }, [ApiMasterTable])
  const closeForm = (value: any) => {
    if (onClose && value === 0) {
      onClose();
    }
  };

  // const { control, formState: { errors }, clearErrors } = useFormContext()
  const { fields: ParameterField, append: appendParameter, update: updateParameter, remove: removeParameter, replace: replaceParameter } = useFieldArray({
    control,
    name: "APIParameters",
    rules: {
      validate: (i): string | true => {

        const noOfParametersValue = getValues('NoOfParameters');
        let res = i.filter((currentObject: any) => currentObject.IsActive === true)
        //  if (res.length === 0) return "Atleast one active API Parameter is required"
        if (noOfParametersValue != undefined) {
          if (i.length < noOfParametersValue) {
            return `${noOfParametersValue} Parameter is required`
          }
        }
        if (res.length != noOfParametersValue) return `${noOfParametersValue} Active Parameter is required`
        return true
      }

    }
  });

  const { fields: ApiFieldsField, append: appendApiFields, update: updateApiFields, remove: removeApiFields, replace: replaceApiFields } = useFieldArray({
    control,
    name: "ApiFields",
  });
  useEffect(() => {
    const noOfParametersValue = getValues("NoOfParameters")
    let res = ParameterField.filter((currentObject: any) => currentObject.IsActive === true)
    if (noOfParametersValue != undefined) {
      if (noOfParametersValue > res.length) {
        setAddApiParameter(false)
      } else {
        setAddApiParameter(true)
      }
    }
  }, [ParameterField, noOfParameters])
  const handleSubmit = (data: any) => {

    // dispatch(updateLoader(true));
    let formData = data;
    formData.ModuleName = "API Catalog";
    formData.Status = formData.Status ? 1 : 0;
    formData.IsActive = formData.IsActive;
    let JsonDataForDisplay: any = {
      "Api Code": formData.ApiCode,
      "API Name": formData.ApiName,
      "URL": formData.Url,
      "UserName": formData.User_Name,
      // "LoginId" : formData.Login_Id,
      // "Password" : formData.Pwd,
      "Format": formData.Format,
      "Status": formData.IsActive ? "Active" : "InActive",
      "Modified By": user?.UserName,
    }

    if (edit) {
      formData.IsActive = formData.IsActive
      formData.Updated_By = user?.UserName;
      formData.CreatedBy = user?.UserName;
      // formData.MenuId = currentPagePermissionData?.MenuId;
      formData.ModuleName = "API Catalog";
      formData.Activity = "update";
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      // formData.UserId = userLoginResponse?.UserId;
      formData.Form_Id = 13;
      formData.Catalog_Id = isEditData.Catalog_Id
      dispatch(updateApiCatalog(formData));
      closeForm(0)
    } else {
      formData.Updated_By = user?.UserName;
      formData.CreatedBy = user?.UserName;
      // formData.MenuId = currentPagePermissionData?.MenuId;
      formData.ModuleName = "API Catalog";
      formData.Activity = "create";
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      // formData.UserId = userLoginResponse?.UserId;
      formData.Form_Id = 13;
      dispatch(saveApiCatalog(formData));
      closeForm(0)
    }
  };
  const isActiveDisabled = watch("IsActive");
  const handleOpenFields = (data: any, option: string, index?: number) => {
    // setapiFieldsEditData(true)
    let Index = index;
    if (index || index === 0) setSelectedIndex(index)
    setStatusIdLabelMapping({
      1: "Active",
      0: "Inactive",
    })
    if (option == "edit") {
      setapiFieldsEditData(data)
      setIsEditField(true)
      setIsNewField(true)
    } else {
      if (Index || Index === 0) updateApiFields(Index, { ...data, Status_Value: data.Status == 1 ? "InActive" : "Active", IsActive: data.Status == 1 ? false : true, isActive: data.Status == 1 ? false : true, Status: data.Status == 1 ? 0 : 1, })

    }
    setOption(Option);
  };
  const handleOpenParameter = (data: any, option: string, index?: number) => {
    let Index = index;
    if (index || index === 0) setSelectedIndex(index)
    setStatusIdLabelMapping({
      1: "Active",
      0: "Inactive",
    })
    if ("edit" == option) {

      setIsEditparameter(true)
      setapiParameterEditData(data)
      setIsNewParameter(true)
    } else {
      if (Index || Index === 0) updateParameter(Index, { ...data, Status_Value: data.Status == 1 ? "InActive" : "Active", IsActive: !data.IsActive, isActive: data.Status == 1 ? false : true, IsPrimaryDisplay: data.IsPrimary ? "Yes" : "No", Status: data.Status == 1 ? 0 : 1 })
    }
  };
  const transformData = (data: any) => {
    return data.map((parameteritem: any) => {
      return {
        ...parameteritem,
        IsActive: parameteritem.isActive,
      };
    });
  };
  // console.log(FieldsColumns, ApiFieldsField, "ApiFieldsField3", Option, ParameterField)
  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {!isDisableBreadCrums && (
                <CustomBreadcrumbs
                  list={[
                    { value: 0, name: prePageName ? prePageName : "API Catalog" },
                    { value: 1, name: `${edit ? "Edit" : view ? "View" : "Add New"}` },
                  ]}
                  onchange={closeForm}
                />
              )}
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
          </Box>
          <Grid container spacing={1}>

            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <Controller
                name="APIMasterID"
                control={control}
                defaultValue={""}
                rules={{ required: "APIGroupName is required" }}
                render={({ field }) => (
                  <CustomSelect
                    name={"Id"}
                    valueKey="Id"
                    optionLabelKey="API_Group"
                    options={apiMasterFormData}
                    readOnly={view || !isActiveDisabled}
                    required={true}
                    label={"API Group Name"}
                    value={field.value}
                    onChange={field.onChange}
                    error={Boolean(errorsloginform.APIMasterID)}
                    helperText={errorsloginform.APIMasterID?.message?.toString()}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>

            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <Controller
                name="ApiCode"
                control={control}
                defaultValue={""}
                rules={{
                  required: "API Code is required",
                }}
                render={({ field }) => (
                  <CustomTextField
                    name={"ApiCode"}
                    required={true}
                    readOnly={view || !isActiveDisabled}
                    label={"API Code"}
                    value={field.value}
                    onChange={field.onChange}
                    error={Boolean(errorsloginform.ApiCode)}
                    helperText={
                      errorsloginform.ApiCode?.message?.toString()
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>

            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <Controller
                name="ApiName"
                control={control}
                defaultValue={""}
                rules={{
                  required: "API Name is required",
                }}
                render={({ field }) => (
                  <CustomTextField
                    name={"ApiName"}
                    required={true}
                    readOnly={view || !isActiveDisabled}
                    label={"API Name"}
                    value={field.value}
                    onChange={field.onChange}
                    error={Boolean(errorsloginform.ApiName)}
                    helperText={
                      errorsloginform.ApiName?.message?.toString()
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>

            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
            >
              <Controller
                name="Url"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Url is required",
                }}
                render={({ field }) => (
                  <CustomMultilineTextField
                    label={"Url"}
                    value={field.value}
                    onChange={field.onChange}
                    required
                    rows={2}
                    readOnly={view || !isActiveDisabled}
                    error={Boolean(errorsloginform.Url)}
                    helperText={errorsloginform?.Url?.message?.toString()}
                  />
                )}
              />
            </Grid>
          </Grid>
          <CustomAccordion
            expanded={expanded === 1}
            handleChange={() => setExpanded((pre: any) => (pre === 1 ? null : 1))}
            title="API Input Parameters"
            details={
              <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "15px" }}>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Controller
                    name="NoOfParameters"
                    control={control}
                    defaultValue={0}
                    rules={{
                      required: "No Of Parameters is Required",
                    }}
                    render={({ field }) => (
                      <CustomNumberField
                        name={"NoOfParameters"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"No Of Parameters "}
                        value={field.value}
                        onChange={field.onChange}

                        inputProps={{
                          sx: {
                            width: "270px",
                          }
                        }}
                        isDecimal={true}
                        error={Boolean(errorsloginform.NoOfParameters)}
                        helperText={
                          errorsloginform.NoOfParameters &&
                          errorsloginform.NoOfParameters.message?.toString()
                        }
                      />
                    )}
                  />

                  <CustomButton
                    variant="outlined"
                    onClick={() => {
                      setapiParameterEditData(undefined)
                      setIsNewParameter(!isNewParameter);
                    }}
                    name="Add a Parameters"
                    disabled={view || addApiParameter || !isActiveDisabled}
                  />
                </Box>

                <Box component="form" noValidate sx={{ width: "100%" }}>
                  <DynamicBasicTable
                    columns={ParameterColumns}
                    rowData={transformData(ParameterField)}
                    style={{ border: "1px solid #f1f1f1" }}
                    handleAction={handleOpenParameter}
                    isEdit={true}
                    isDelete={true}
                    //   showDeleteButton={!view && !edit}
                    disableAction={view || !isActiveDisabled}
                    //     showDeleteButton={true}
                    statusIdMapping={statusIdLabelMapping}
                  />
                </Box>
              </Box>
            }
          />
          {errorsloginform.APIParameters?.root?.message && <Typography variant="subtitle1" sx={{ color: '#D70000', textAlign: 'left', fontSize: '15px' }} >
            {errorsloginform.APIParameters?.root?.message as any}</Typography>}
          <CustomAccordion
            expanded={expanded === 2}
            handleChange={() => setExpanded((pre: any) => (pre === 2 ? null : 2))}
            title="API Output Fields"
            details={
              <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "15px" }}>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <CustomButton
                    variant="outlined"
                    onClick={() => {
                      setapiFieldsEditData(undefined)
                      setIsNewField(!isNewField);

                    }}
                    name="Add a Fields"
                    disabled={view || !isActiveDisabled}
                  />
                </Box>
                <Box component="form" noValidate sx={{ width: "100%" }}>
                  <DynamicBasicTable
                    columns={FieldsColumns}
                    rowData={ApiFieldsField ? ApiFieldsField : []}
                    style={{ border: "1px solid #f1f1f1" }}
                    handleAction={handleOpenFields}
                    isEdit={true}
                    isDelete={true}
                    //  showDeleteButton={true}
                    disableAction={view || !isActiveDisabled}
                    statusIdMapping={statusIdLabelMapping}
                  />
                </Box>
              </Box>
            }
          />   {errorsloginform.ApiFields?.root?.message && <Typography variant="subtitle1" sx={{ color: '#D70000', textAlign: 'left', fontSize: '15px' }} >
            {errorsloginform.ApiFields?.root?.message as any}</Typography>}
          <Box sx={{ display: "flex", gap: 2, justifyContent: "end" }}>
            <CustomButton
              type="reset"
              //  disabled={view}
              name={"Cancel"}
              onClick={() => {
                closeForm(0);
              }}
            />

            <CustomButton
              type="button"
              disabled={view || !isActiveDisabled || !isDirty}
              variant="contained"
              name={!edit ? "Submit" : "Update"}
              onClick={handleCatalogForm(handleSubmit)}
            />
          </Box>
        </Stack>

      </Box>
      <CustomDialog
        show={isNewParameter}
        onHide={() => setIsNewParameter(false)}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={"Add Parameter"}
        contentNode={

          <AddApiParameter
            view={false}
            isEditData={apiParameterEditData}
            //  isAqmResponse={isAqmResponse}
            onClose={() => {
              setIsNewParameter(false);
            }}
            ParameterTableValue={ParameterField}
            // addressType={addressTypeFilteredOptions}
            onSubmit={(data) => {
              if (IsEditparameter) {
                if (selectedIndex || selectedIndex === 0) updateParameter(selectedIndex, { ...data, IsPrimaryDisplay: data.IsPrimary ? "Yes" : "No", })
              } else {
                appendParameter({ ...data, ParameterNo: ParameterField.length + 1, IsPrimaryDisplay: data.IsPrimary ? "Yes" : "No" })
                setIsNewParameter(false);
              }
              setIsNewParameter(false);
              setIsEditparameter(false)
            }}
          />

        }
      />
      <CustomDialog
        show={isNewField}
        onHide={() => setIsNewField(false)}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={"Add Field"}
        contentNode={

          <AddApiFields
            view={false}
            isEditData={apiFieldsEditData}
            //  isAqmResponse={isAqmResponse}
            onClose={() => {
              setIsNewField(false);
            }}
            FieldsTableValue={ApiFieldsField}
            // addressType={addressTypeFilteredOptions}
            onSubmit={(data) => {
              if (IsEditField) {
                if (selectedIndex || selectedIndex === 0) updateApiFields(selectedIndex, { ...data })
              } else {
                appendApiFields({ ...data, FieldNo: ApiFieldsField.length + 1 })
                setIsNewField(false);
              }
              setIsNewField(false);
              setIsEditField(false)
            }}
          />

        }
      />
    </>
  );
};
