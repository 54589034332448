import React from "react";
import { Lock, LockOpen, TextFields } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import type { EditorEvents, EditorOptions } from "@tiptap/core";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditor,
  RichTextReadOnly,
  TableBubbleMenu,
  insertImages,
  type RichTextEditorRef,
} from "mui-tiptap";
import EditorMenuControls from "./EditorMenuControls";
import useExtensions from "./useExtensions";
import { textFieldWidth } from "../../utils/constants";
import { useSelector } from "react-redux";
import styles from './editor.module.scss';
import { RootState } from "../../store/store";

export interface EditorProps {
  updateEditorContent: any;
  editorContent: any;
  placeHolder?: any;
  view?: boolean;
  error?: boolean;
  helperText?: string;
  showSystemVariable?: boolean;
  ediotrSystemVaraibles?: number[];
  updateSystemVaraibles?:any;
}
const Editor: React.FC<EditorProps> = ({
  updateEditorContent,
  editorContent,
  placeHolder = "Add Your Content here",
  view = false,
  error = false,
  helperText = "",
  showSystemVariable = false,
  ediotrSystemVaraibles= [],
  updateSystemVaraibles=null
}) => {
  const extensions = useExtensions({
    placeholder: placeHolder,
  });
  const rteRef = useRef<RichTextEditorRef>(null);
  const [isEditable, setIsEditable] = useState(true);
  const [showMenuBar, setShowMenuBar] = useState(true);
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    systemVariables
  }: any = contractManagementReducer.clauseData;
  useEffect(() => {
    if (view) {
      setIsEditable(false);
    }
  }, [view]);
  const sendTextToEditor = (text: any) => {  
    if(updateSystemVaraibles !== null){
      updateSystemVaraibles([...ediotrSystemVaraibles,text.system_Variables_Id])  
    }
    insertText(text, rteRef);
  };

  const theme = useTheme();
  const update = (data: any) => {
    if (updateEditorContent) {
      updateEditorContent(data?.editor?.getHTML() ?? "");
    } else {
      console.log(data?.editor?.getHTML() ?? "");
    }
  };

  const insertText = (text: any, editorValue: any) => {    
    editorValue.current.editor.commands.insertContentAt(
      editorValue.current.editor.state.selection.$anchor.pos,
      `<<${text.module_Name}.${text.system_Variable}>>`
    );
  };

  return (
    <React.Fragment>
      <Grid item xs={textFieldWidth.xs}>
        <Box
          sx={{
            // An example of how editor styles can be overridden. In this case,
            // setting where the scroll anchors to when jumping to headings. The
            // scroll margin isn't built in since it will likely vary depending on
            // where the editor itself is rendered (e.g. if there's a sticky nav
            // bar on your site).
            "& .ProseMirror": {
              "& h1, & h2, & h3, & h4, & h5, & h6": {
                scrollMarginTop: showMenuBar ? 50 : 0,
              },
              textAlign: "left",
              wordBreak: "break-all",
              maxHeight: "250px",
              overflowY: "scroll",
            },
          }}
        >
          <RichTextEditor
            ref={rteRef}
            extensions={extensions}
            content={editorContent}
            editable={isEditable}
            //   editorProps={{
            //     handleDrop: handleDrop,
            //     handlePaste: handlePaste,
            //   }}

            onUpdate={update}
            renderControls={() => <EditorMenuControls />}
            RichTextFieldProps={{
              // The "outlined" variant is the default (shown here only as
              // example), but can be changed to "standard" to remove the outlined
              // field border from the editor
              variant: "outlined",
              MenuBarProps: {
                hide: !showMenuBar,
              },
              // Below is an example of adding a toggle within the outlined field
              // for showing/hiding the editor menu bar, and a "submit" button for
              // saving/viewing the HTML content
              // footer: (
              //   <Stack
              //     direction="row"
              //     spacing={2}
              //     sx={{
              //       borderTopStyle: "solid",
              //       borderTopWidth: 1,
              //       borderTopColor: (theme) => theme.palette.divider,
              //       py: 1,
              //       px: 1.5,
              //     }}
              //   >
              //     <MenuButton
              //       value="formatting"
              //       tooltipLabel={
              //         showMenuBar ? "Hide formatting" : "Show formatting"
              //       }
              //       size="small"
              //       onClick={() =>
              //         setShowMenuBar((currentState) => !currentState)
              //       }
              //       selected={showMenuBar}
              //       IconComponent={TextFields}
              //     />

              //     <MenuButton
              //       value="formatting"
              //       tooltipLabel={
              //         isEditable
              //           ? "Prevent edits (use read-only mode)"
              //           : "Allow edits"
              //       }
              //       size="small"
              //       onClick={() => setIsEditable((currentState) => !currentState)}
              //       selected={!isEditable}
              //       IconComponent={isEditable ? Lock : LockOpen}
              //     />

              //     <Button
              //       variant="contained"
              //       size="small"
              //       onClick={() => {
              //         setSubmittedContent(
              //           rteRef.current?.editor?.getHTML() ?? ""
              //         );
              //       }}
              //     >
              //       Save
              //     </Button>
              //   </Stack>
              // ),
            }}
          >
            {() => (
              <>
                <LinkBubbleMenu />
                <TableBubbleMenu />
              </>
            )}
          </RichTextEditor>
          {(editorContent.length === 0 || editorContent === "<p></p>") && error && helperText && helperText.length > 0 ? <p className={styles.helperTextError} >{helperText}</p> : null}
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          py: 1,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            textAlign: "left",
            color: theme.palette.grey[50],
            fontSize: "12px",
          }}
        >
          Placeholder to insert in the body text area
        </Typography>
        {showSystemVariable && Object?.keys(systemVariables)?.length>0? <React.Fragment>
          
          {Object.keys(systemVariables).map((placeHolderKey: string, index:number) => {
            return <React.Fragment>
              <List component={Stack} direction="row" sx={{ p: 0 }}>
                <ListItem disablePadding>
                  <ListItemText
                    primary={placeHolderKey}
                    sx={{
                      color: theme.palette.grey[100],
                      paddingRight: "5px"
                    }}
                  />
                  {systemVariables[placeHolderKey].map((systemVariable: any) => {
                    return <React.Fragment>
                      <ListItemButton disabled={view}>
                        <ListItemText
                          sx={{
                            color: index%2 === 0 ?theme.palette.grey[200] :theme.palette.primary.dark
                          }}
                          primary={systemVariable.system_Variable}
                          onClick={() => {
                            sendTextToEditor(systemVariable);
                          }}
                        />
                      </ListItemButton>
                    </React.Fragment>
                  })
                  }
                </ListItem>
              </List>
            </React.Fragment>
          })}
            
        </React.Fragment> : null}
      </Grid>
    </React.Fragment>
  );
};

export default Editor;
