import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { ERROR_LOGS } from "./endpoints/errorLog";


const addErrorLog = (logData: { errorMsg: string }): Promise<any> => {
  return axiosInstance
    .post(ERROR_LOGS.AddErrorLog, null, { params: logData }) // Pass logData as query params
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error Adding Event Log: " + JSON.stringify(response));
    })
    .catch((error: AxiosError | string) => {
      console.error("Error occurred while adding error log:", error);
      throw error;
    });
};
const getErrorLogs = (requestBody: any): Promise<any> => {
  return axiosInstance
    .get(ERROR_LOGS.GetErrorLogs, { params: requestBody })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error Retrieving Event Log" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
};





const ErrorLogService = {
  addErrorLog,
  getErrorLogs,
};

export default ErrorLogService;
