import React, { useEffect, useRef, useState } from "react";
import { Box, Collapse, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { RootState, useAppDispatch, useAppDispatchThunk } from "../../../store/store";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { Controller, FormProvider, useForm } from "react-hook-form";
import CustomAutoComplete from "../../../common/dynamicInputs/CustomAutoComplete";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SearchServices from "../SearchService/SearchServices";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import CustomFileUploader from "../../../common/dynamicFileUploader/CustomFileUploader";
import AddClause from "../ClauseLibrary/AddClause";
import { createTemplate, getContractType } from "../../../store/contractManagement/slices/templateData";
import contractTemplateService from "../../../store/contractManagement/services/templateService";
import clauseService from "../../../store/contractManagement/services/clauseService";
import { useSelector } from "react-redux";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import dayjs from "dayjs";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import CustomSvg from "../../../common/CustomSvg";
import CustomDateTimePicker from "../../../common/dynamicInputs/CustomDateTimePicker";
import CustomTimePicker from "../../../common/dynamicInputs/CustomTimePicker";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import CustomChip from "../../../common/dynamicChip/CustomChip";
import { initialData } from "./InitialData";
import EmailForm from "./EmailForm/EmailForm";
import RichTextEditor, { EditorValue } from "react-rte";
import { getCurrencyList } from "../../../store/slices/currencyData";
import { ACTIVE, getDateOrConvertSameTime } from "../../../utils/constants";
import { getEmailTemplateList } from "../../../store/slices/EmailTemplateData";
import { getUsersList } from "../../../store/slices/userData";
import { getLovList } from "../../../store/slices/lovMasterData";
import FilePreviewer from "../../../common/fileViewer/FilePreviewer";
import FilteViewer from "../../../common/fileViewer/FilteViewer";
import {
  costApprovalResetEmailState,
  costApprovalSendEmail,
} from "../../../store/contractManagement/slices/costApprovalSliceEmail";
import {
  costApprovalResetState,
  costApprovalSubmitCostApproval,
} from "../../../store/contractManagement/slices/CostApprovalSlice";
import {
  getGetVCWorkflowProcessesData,
  updateCmWorkFlowInstance,
} from "../../../store/contractManagement/slices/contractRolesAndResponsiblity";
import moment from "moment";
import { IServiceCatalog } from "../../../models/serviceCatalog.type";
import { getServiceCatalogList } from "../../../store/slices/serviceCatalog";
import { getallCostApprovalMetricList } from "../../../store/contractManagement/slices/costApprovalMetricSlice";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";

export type ContractClause = {
  clauseType: string;
  clauseHeader: string;
  service: string;
  sme: string;
  order: number;
  clause_Id: string;
};

interface formDataProps {
  Company: string;
  Vendor: string;
  OnboardingApprovedBy?: string;
  ProjectCost?: any;
  OnboardingApprovedOnDate?: any;
  OnboardingApprovedOnTime?: any;
  currencyCode?: any;
  ApproverName?: any;
  ApprovedDate?: any;
  ApprovedTime?: any;
  RejectedDate?: any;
  RejectedTime?: any;
  Designation?: any;
  ApprovalStatus?: any;
  email_template_id?: any;
  to_email_id?: any;
  notify_email_id?: any;
  subject?: string;
  email_content?: string;
  is_Msword?: boolean;
  is_Pdf?: boolean;
  emailFormat?: string;
  attachment?: File;
}

export interface emailDataProps {
  email_template_id: any;
  to_email_id: any;
  notify_email_id: any;
  subject: string;
  email_content: EditorValue;
  email_content_string?: string;
  is_Msword: boolean;
  is_Pdf: boolean;
  emailFormat: string;
}

export interface CostApprovalProps {
  view?: boolean;
  edit?: boolean;
  formData?: any;
  onClose?: () => void;
}

const CostApproval: React.FC<CostApprovalProps> = ({ view, edit, formData, onClose }) => {
  const [selectedActiveServiceCatlogdata, setActiveServiceCatlogdata] = useState<any[]>([]);
  const [isEmailModelOpen, setIsEmailModelOpen] = useState<boolean>(false);
  const [getCurrency, setCurrency] = useState<any[]>([]);
  const { CurrencyData } = useSelector((state: RootState) => state.CurrencyData);
  const { allUsers } = useSelector((state: RootState) => state.userData);
  const [usersData, setUsersData] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [contractData, setContractData] = useState<any>(null);
  const [projectApprovalData, setProjectApprovalData] = useState<any>(null);
  const [ApprovalStatusOptions, setApprovalStatusOptions] = useState([
    { LovId: 1, Options: "Approve" },
    { LovId: 2, Options: "Reject" },
  ]);
  const {
    isEmailSent,
    emailToasterMsg,
    contractType: contractTypeOptions,
  } = useSelector((state: any) => state.contractManagementReducer?.costApprovalEmail);
  const { workFLowInstanceData }: any = useSelector(
    (state: any) => state.contractManagementReducer?.contractRolesAndResponsibility
  );
  const { CostApprovalMetricData }: any = useSelector(
    (state: any) => state.contractManagementReducer?.costApprovalMetric
  );
  const { ActiveServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const [enableApproveComments, setEnableApproveComments] = useState(false);
  const dispatch = useAppDispatchThunk();
  useEffect(() => {
    if (CurrencyData && CurrencyData.length > 0) {
      const ActiveCurrencies = CurrencyData.filter((item: any) => item.IsActive === ACTIVE);
      const MergedCurrencies = ActiveCurrencies.map((item: any) => ({
        ...item,
        mergedCurrency: `${item.CurrencyCode} (${item.Symbol}) `,
      }));
      setCurrency(MergedCurrencies);
    }
  }, [CurrencyData]);

  useEffect(() => {
    let filteredUsers = allUsers
      .filter((item: any) => item.IsActive === ACTIVE)
      .map((user: any) => ({
        ...user,
        fullName: `${user.FirstName} ${user.LastName}`,
      }));
    setUsersData(filteredUsers);
  }, [allUsers]);

  const tempData: any = useRef(null);

  const breadcrumb = [
    { value: 0, name: "Pending Actions" },
    { value: 1, name: "Cost Approval" },
  ];

  let FormData = {
    Company: edit ? formData?.Company : "",
    Vendor: edit ? formData?.Vendor : "",
  };

  let EmailFormData: emailDataProps = {
    email_template_id: edit ? formData?.email_template_id ?? null : null,
    email_content: edit
      ? RichTextEditor.createValueFromString(formData?.email_content ? formData?.email_content : "", "html") ??
        RichTextEditor.createValueFromString(formData?.email_content ? formData?.email_content : "", "html")
      : RichTextEditor.createEmptyValue(),
    is_Msword: edit ? formData?.is_Msword ?? false : false,
    is_Pdf: edit ? formData?.is_Pdf ?? false : false,
    emailFormat: edit ? formData?.emailFormat ?? "" : "",
    notify_email_id: edit ? formData?.notify_email_id ?? null : null,
    subject: edit ? formData?.subject ?? "" : "",
    to_email_id: edit ? formData?.to_email_id ?? null : null,
  };

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors: errors },
    control,
    watch,
    getValues,
  } = useForm<formDataProps>({
    defaultValues: FormData,
    mode: "onChange",
  });

  const emailDetailsFormMethods = useForm<emailDataProps>({ defaultValues: EmailFormData, mode: "onChange" });

  const {
    handleSubmit: emailDetailFormMethodsHandleSubmit,
    formState: { errors: errorsEmailDetailFormMethodsform, isDirty: emailDetailsFormMethodsIsDirty },
    getValues: emailDetailsFormMethodsGetValues,
    setValue: emailDetailsFormMethodsSetValue,
    reset: emailDetailsReset,
  } = emailDetailsFormMethods;

  useEffect(() => {
    dispatch(getContractType());
  }, []);
  useEffect(() => {
    setActiveServiceCatlogdata(initialData);
  }, []);

  console.log(emailToasterMsg, "emailToasterMsgemailToasterMsg");
  useEffect(() => {
    if (isEmailSent) {
      dispatch(costApprovalResetEmailState());
      dispatch(updateLoader(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: emailToasterMsg?.message,
          isTosterFailed: emailToasterMsg?.status === false || emailToasterMsg?.status !== "Success" ? true : false,
        })
      );
      emailDetailsReset();
      setIsEmailModelOpen(false);
      setEnableApproveComments(true);
    }
  }, [isEmailSent]);

  const callPageloading = () => {
    dispatch(getCurrencyList({ FormID: 0 }));
    dispatch(getEmailTemplateList());
    dispatch(getUsersList({ FormID: 2 }));
    dispatch(getLovList({ Name: "" }));
    dispatch(getServiceCatalogList());
    if (formData !== null) {
      let request: any = null;
      if (formData?.ProjectId) {
        request = {
          projectId: formData?.ProjectId,
          workflowInstanceId: formData?.Workflow_InstanceId,
        };
      } else {
        request = {
          projectId: formData?.project_Id,
          workflowInstanceId: formData?.workflowInstance?.id,
        };
      }
      dispatch(updateLoader(true));
      dispatch(getGetVCWorkflowProcessesData(request));
      dispatch(getallCostApprovalMetricList({ FormID: 0 }));
    }
  };

  useEffect(() => {
    callPageloading();
  }, []);

  useEffect(() => {
    if (workFLowInstanceData !== null) {
      console.log(workFLowInstanceData);
      // let obj = {
      //   // "Company Name":
      //   //   CompanyTable.find((item: any) => workFLowInstanceData?.company_Id == item?.Company_Id)?.Legal_Name || "",
      //   // "Vendor Name":
      //   //   vendorDetailsdata.find((item: any) => workFLowInstanceData?.vendor_Id == item?.Vendor_Id)?.Legal_Name || "",
      //   "Company Name" :  workFLowInstanceData?.company_Name,
      //   "Vendor Name" :  workFLowInstanceData?.vendor_Name,
      //   "Contract Number": workFLowInstanceData?.contract_Ref_Number,
      // };
      // setContractBasicData(obj);

      let selectedServices = ActiveServiceCatlogdata.filter(
        (serviceItem: IServiceCatalog) =>
          workFLowInstanceData?.serviceMappings?.filter(
            (item: any) => item?.services?.serviceCatalog_Id === serviceItem.ServiceCatalogId
          ).length > 0
      );
      setActiveServiceCatlogdata(selectedServices);
      let projectCost = workFLowInstanceData?.project_Cost;
      setValue("ProjectCost", workFLowInstanceData?.project_Cost);
      setValue("currencyCode", workFLowInstanceData?.currency_Id);
      if (CostApprovalMetricData && CostApprovalMetricData.length > 0) {
        let data = CostApprovalMetricData.filter((data: any) => data.isActive === ACTIVE).find((data: any) => {
          return projectCost >= data.from_Amount && projectCost <= data.to_Amount;
        });
        if (data) {
          setValue("Designation", data?.approver_Designation);
          setValue("ApproverName", data?.approver_Name);
          setValue("to_email_id", data?.approver_Name);
          setContractData(workFLowInstanceData);
          setEnableApproveComments(workFLowInstanceData?.costApproval_Email)
          setProjectApprovalData(data);
        }
        dispatch(updateLoader(false));
      }
    }
  }, [workFLowInstanceData, ActiveServiceCatlogdata, CostApprovalMetricData]);

  const onSubmit = async (data: formDataProps) => {
    try {
      dispatch(updateLoader(true));
      const onboardingApprovedDate = watch("ApprovedDate")?.$d;
      const onrejectData = watch("RejectedDate")?.$d;

      const onboardingApprovedOnDate = onboardingApprovedDate ? new Date(onboardingApprovedDate).toISOString() : null;
      const onrejectDate = onrejectData ? new Date(onrejectData).toISOString() : null;
      let approvalStatus = ApprovalStatusOptions.filter((item: any) => item.LovId === getValues("ApprovalStatus"))[0]
        .Options;
      const payload = {
        contract_WF_Approval_Id: 0,
        contract_Number: contractData?.contract_Ref_Number,
        workflow_Approval_Type: "",
        project_Cost: getValues("ProjectCost").toString(),
        project_Cost_Code: getCurrency?.find((data) => data.CurrencyId === getValues("currencyCode"))?.mergedCurrency,
        approvar_Name: watch("ApproverName"),
        designation: watch("Designation"),
        approval_Status: approvalStatus,
        approval_Date: approvalStatus === "Approve" ? onboardingApprovedOnDate : onrejectDate,
        approval_Time: approvalStatus === "Approve" ? onboardingApprovedOnDate : onrejectDate,
      };
      dispatch(costApprovalSubmitCostApproval(payload));
    } catch (error) {}
  };
  const handleEmail = async (data: formDataProps) => {
    try {
      setIsEmailModelOpen(true);
    } catch (error) {}
  };
  const handleEmailFormSubmit = async (data: emailDataProps) => {
    dispatch(updateLoader(true));
    data.email_content_string = data.email_content.toString("html");
    console.log(data, "Email Content");
    const onboardingApprovedDate = watch("OnboardingApprovedOnDate")?.$d;
    const onboardingApprovedTime = watch("OnboardingApprovedOnTime")?.$d;
    const updatedContent = {
      // email_template_id: data.email_template_id,
      // to_Email_Ids: [data.to_email_id],
      // notify_email_id: data.notify_email_id,
      // subject: data.subject,
      // email_content_string: data.email_content_string,
      // is_Msword: data.is_Msword,
      // is_Pdf: data.is_Pdf,
      // email_Format: data.is_Msword === true ? "Word" : data.is_Pdf === true ? "PDF" : "",
      // from_Email_Id: 0,
      // email_template_id: data.email_template_id,
      // to_Email_Id: "",
      // To_Email_Ids: [],
      // notify_email_id: data.notify_email_id,
      // subject: data.subject,
      // email_content_string: data.email_content_string,
      // is_Msword: data.is_Msword,
      // is_Pdf: data.is_Pdf,
      // contract_number: workFLowInstanceData?.contract_Ref_Number,
      // email_Format: data.is_Msword === true ? "Word" : data.is_Pdf === true ? "PDF" : "",
      // from_Email_Id: 1,
      email_Template_Id: data.email_template_id,
      to_Email_Id: "",
      to_Email_Ids: [],
      notify_Email_Id: data.notify_email_id,
      subject: data.subject,
      email_Content_String: data.email_content_string,
      is_MsWord: data.is_Msword,
      is_Pdf: data.is_Pdf,
      email_Format: data.is_Msword === true ? "Word" : data.is_Pdf === true ? "PDF" : "",
      from_Email_Id: 0,
      to_Emails: [data?.to_email_id],
      vendor_contract_Id: contractData?.vendor_Contract_Id,
      CostApproval_Email: true
    };
    console.log(updatedContent, "updatedContent");
    dispatch(costApprovalSendEmail(updatedContent));
  };

  const watchApprovalStatus = watch("ApprovalStatus");
  const isSendEmail = watch("ProjectCost") && watch("currencyCode") && watch("ApproverName") && watch("Designation");
  const [selectedFiles, setSelectedFiles] = useState<File | null>(null);
  const [isFileView, setIsFileView] = useState<boolean>(false);
  const [fileconvert, setFileConvert] = useState<string>("");
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const { isCostApprovalDataAdded, costApprovalToasterMsg }: any = contractManagementReducer.costApproval;
  // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const files = e.target.files;
  //   if (files && files.length > 0) {
  //     const file = files[0];
  //     if (
  //       file.type === "application/pdf" ||
  //       file.type === "application/msword" ||
  //       file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  //     ) {
  //       setSelectedFiles(file);
  //       setValue("attachment", file);
  //     } else {
  //       dispatch(
  //         updateToaster({
  //           isToaster: true,
  //           toasterMsg: "Please select a PDF or Word document.",
  //           isTosterFailed: false,
  //         })
  //       );
  //     }
  //   }
  // };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          setFileConvert(base64String);
          setSelectedFiles(file);
          setValue("attachment", file);
        };
        reader.readAsDataURL(file);
      } else {
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: "Please select a PDF or Word document.",
            isTosterFailed: true,
          })
        );
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteFile = (fileToDelete: File) => {
    setSelectedFiles(null);
  };

  const updateCostApproval = async () => {
    if (onClose && isCostApprovalDataAdded) {
      let request = {
        workflowInstanceId: formData?.Workflow_InstanceId,
        processInstanceId: formData.Id,
        conditionValue: contractData?.vendor_Contract_Id,
        projectId: Number(formData.ProjectId),
        updatedBy: userLoginResponse ? userLoginResponse.UserName : "",
        DepartmentId: userLoginResponse ? String(userLoginResponse.Department) : "",
      };
      // try{

      // }catch(e){

      // }
      let data = await dispatch(updateCmWorkFlowInstance(request)).unwrap();
      if (data) {
        dispatch(costApprovalResetState(false));
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: costApprovalToasterMsg.message,
            isTosterFailed: costApprovalToasterMsg.status !== "Success" ? true : false,
          })
        );
        dispatch(updateLoader(false));
        onClose();
      }
    }
  };

  useEffect(() => {
    updateCostApproval();
  }, [isCostApprovalDataAdded]);

  return (
    <Box sx={{ width: "100%", px: 2 }}>
      <Box pt={2}>
        <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
          <CustomBreadcrumbs
            list={breadcrumb}
            onchange={(val: number) => {
              if (val === 0 && onClose) {
                onClose();
              }
            }}
          />
        </Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="h6" color={"text.primary"} fontWeight={700}>
            Cost Approval
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <CustomButton
              variant="outlined"
              name={"Cancel"}
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
            />
            <CustomButton
              variant="contained"
              color="secondary"
              disabled={!enableApproveComments}
              name={"Submit"}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        </Stack>
        <Divider sx={{ my: 4, position: "relative", right: "2rem", width: "104%" }} variant="fullWidth" />
      </Box>
      <Box>
        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5} lg={2} xl={2}>
              <Box key={workFLowInstanceData?.company_Name}>
                <Typography textAlign="left" fontWeight="bold">
                  {"Company"}{" "}
                </Typography>
                <Typography textAlign="left">{workFLowInstanceData?.company_Name}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={2} xl={2}>
              <Box key={workFLowInstanceData?.vendor_Name}>
                <Typography textAlign="left" fontWeight="bold">
                  {"Vendor"}{" "}
                </Typography>
                <Typography textAlign="left">{workFLowInstanceData?.vendor_Name}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={2} xl={2}>
              <Box key={workFLowInstanceData?.vendor_Name}>
                <Typography textAlign="left" fontWeight="bold">
                  {"Contract Number"}{" "}
                </Typography>
                <Typography textAlign="left">{workFLowInstanceData?.contract_Ref_Number}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mt: 2 }} variant="fullWidth" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ display: "flex", gap: 1, flexDirection: "column", mb: 0.5 }}>
                <Typography variant="h6" color={"text.primary"} sx={{ textAlign: "left" }} fontWeight={500}>
                  Selected Services
                </Typography>
                {selectedActiveServiceCatlogdata.length > 0 ? (
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        justifyContent: "left",
                        display: "flex",
                        paddingTop: "18px",
                        columnGap: "10px",
                      }}
                    >
                      {selectedActiveServiceCatlogdata.length > 0 &&
                        selectedActiveServiceCatlogdata.map((filteredServiceItem: any) => {
                          return (
                            <CustomChip
                              label={`${filteredServiceItem.ServiceName} - ${filteredServiceItem.Summary}`}
                              variant="outlined"
                              color="primary"
                            />
                          );
                        })}
                    </Grid>
                  </Grid>
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Box sx={{ display: "flex", gap: 0.4 }}>
                <Controller
                  name="ProjectCost"
                  control={control}
                  // rules={{
                  //   required: "Project Cost is Required",
                  //   validate: {
                  //     maxLength: (value) => {
                  //       if (value === undefined) return "Value is required";
                  //       return value.length <= 100 || "Maximum 100 characters allowed";
                  //     },
                  //   },
                  // }}
                  render={({ field }) => (
                    <CustomNumberField
                      name={"ProjectCost"}
                      label={"Project Cost"}
                      value={field.value}
                      onChange={field.onChange}
                      required={true}
                      readOnly={true}
                      isDecimal={true}
                      error={Boolean(errors.ProjectCost)}
                      helperText={errors.ProjectCost && errors.ProjectCost.message?.toString()}
                    />
                  )}
                />
                <Box sx={{ width: "50%" }}>
                  <Controller
                    name="currencyCode"
                    control={control}
                    rules={{
                      required: "Currency code is Required",
                    }}
                    render={({ field }) => (
                      <CustomAutoComplete
                        optionValueKey={"CurrencyId"}
                        optionLabelKey="mergedCurrency"
                        options={getCurrency || []}
                        value={field.value}
                        onChange={field.onChange}
                        required={true}
                        readOnly={true}
                        label={"Currency"}
                        error={Boolean(errors.currencyCode)}
                        helperText={errors.currencyCode && errors.currencyCode.message?.toString()}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Controller
                name="ApproverName"
                key="ApproverName"
                control={control}
                defaultValue={"" as any}
                rules={{
                  required: "Approver Name is Required",
                }}
                render={({ field }) => (
                  <CustomTextField
                    name={"ApproverName"}
                    required={true}
                    //disabled={true}
                    label={"Approver Name"}
                    readOnly={true}
                    value={field.value}
                    onChange={field.onChange}
                    error={Boolean(errors.ApproverName)}
                    helperText={errors.ApproverName && errors.ApproverName.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={2} xl={2}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Controller
                  key="Designation"
                  name="Designation"
                  defaultValue={"" as any}
                  control={control}
                  rules={{
                    required: "Designation is Required",
                    validate: {
                      maxLength: (value) => {
                        if (value === undefined) return "Value is required";
                        return value.length <= 100 || "Maximum 100 characters allowed";
                      },
                    },
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"Designation"}
                      label={"Designation"}
                      value={field.value}
                      onChange={field.onChange}
                      required={true}
                      readOnly={true}
                      error={Boolean(errors.Designation)}
                      helperText={errors.Designation && errors.Designation.message?.toString()}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={1} xl={2}>
              <Box sx={{ display: "flex" }}>
                <CustomButton
                  variant="contained"
                  color="secondary"
                  name={"Send Email"}
                  disabled={!isSendEmail || enableApproveComments || contractData?.costApproval_Email}
                  // onClick={handleSubmit(handleEmail)}
                  onClick={() => {
                    setIsEmailModelOpen(true);
                  }}
                />
              </Box>
            </Grid>

            {enableApproveComments ? (
              <React.Fragment>
                <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                  <Controller
                    name="ApprovalStatus"
                    control={control}
                    defaultValue={"" as any}
                    rules={{
                      required: "Approval Status is Required",
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        valueKey={"LovId"}
                        optionLabelKey="Options"
                        options={ApprovalStatusOptions}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        required={true}
                        label={"Approval Status"}
                        name={"Approval Status"}
                        error={Boolean(errors.ApprovalStatus)}
                        helperText={errors.ApprovalStatus && errors.ApprovalStatus.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                {watchApprovalStatus == 1 && (
                  <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                    <Controller
                      name="ApprovedDate"
                      control={control}
                      defaultValue={null}
                      rules={{ required: "Approved Date is Required" }}
                      render={({ field }) => (
                        <CustomDatePicker
                          minDate={dayjs()}
                          maxDate={dayjs().add(100, "year").toDate()}
                          label={"Approved Date"}
                          required={true}
                          value={field.value ? field.value : null}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          error={Boolean(errors.ApprovedDate)}
                          helperText={errors.ApprovedDate && errors.ApprovedDate.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                )}
                {watchApprovalStatus == 2 && (
                  <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                    <Controller
                      name="RejectedDate"
                      control={control}
                      defaultValue={null}
                      rules={{ required: "Rejected Date is Required" }}
                      render={({ field }) => (
                        <CustomDatePicker
                          minDate={dayjs()}
                          maxDate={dayjs().add(100, "year").toDate()}
                          label={"Rejected Date"}
                          value={field.value ? field.value : null}
                          required={true}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          error={Boolean(errors.RejectedDate)}
                          helperText={errors.RejectedDate && errors.RejectedDate.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                )}
                {false && watchApprovalStatus == 1 && (
                  <Grid item xs={12} sm={12} md={5} lg={2} xl={2}>
                    <Controller
                      name="ApprovedTime"
                      control={control}
                      defaultValue={"" as any}
                      rules={{
                        required: "Approved Time is Required",
                      }}
                      render={({ field }) => (
                        <CustomTimePicker
                          label="Approved Time"
                          value={field.value ? field.value : null}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          format="HH:mm"
                          required={true}
                          disabled={false}
                          readOnly={false}
                          error={Boolean(errors.ApprovedTime)}
                          helperText={errors.ApprovedTime && errors.ApprovedTime.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                )}
                {false && watchApprovalStatus == 2 && (
                  <Grid item xs={12} sm={12} md={5} lg={2} xl={2}>
                    <Controller
                      name="RejectedTime"
                      control={control}
                      defaultValue={"" as any}
                      rules={{
                        required: "Rejected Time is Required",
                      }}
                      render={({ field }) => (
                        <CustomTimePicker
                          label="Rejected Time"
                          value={field.value ? field.value : null}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          format="HH:mm"
                          required={true}
                          disabled={false}
                          readOnly={false}
                          error={Boolean(errors.RejectedTime)}
                          helperText={errors.RejectedTime && errors.RejectedTime.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                )}
                {!selectedFiles ? (
                  <Grid item xs={12} sm={12} md={5} lg={1} xl={2}>
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ mt: 0.3 }}>
                        <CustomButton
                          variant="outlined"
                          color="secondary"
                          name={"Attach File"}
                          onClick={handleButtonClick}
                          startIcon={<CustomSvg size={"15px"} name={"paperclip"} />}
                        />
                        <input
                          ref={fileInputRef}
                          accept=".pdf,.doc,.docx"
                          style={{ display: "none" }}
                          id="file-input"
                          type="file"
                          onChange={handleFileChange}
                          multiple={false}
                        />
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box sx={{ display: "flex", alignItems: "flex-start", mt: 0.3 }}>
                      {selectedFiles && (
                        <CustomChip
                          key={selectedFiles.name}
                          label={selectedFiles.name}
                          variant="outlined"
                          color="primary"
                          onClick={() => setIsFileView(true)}
                          onDelete={() => handleDeleteFile(selectedFiles)}
                        />
                      )}
                    </Box>
                  </Grid>
                )}{" "}
              </React.Fragment>
            ) : null}
          </Grid>
        </Box>
      </Box>
      <CustomDialog
        show={isEmailModelOpen}
        onHide={() => {
          emailDetailsReset();
          setIsEmailModelOpen(false);
        }}
        maxWidth={"md"}
        header={"Send Email for Cost Approval"}
        contentNode={
          <>
            {" "}
            <FormProvider {...emailDetailsFormMethods}>
              <EmailForm
                // onSubmit={emailDetailsFormMethods.handleSubmit((data) => {
                //   console.log(data);
                //   setIsEmailModelOpen(false);
                // })}
                to_emails={projectApprovalData?.approver_Email}
                templateName="Cost Approval "
                onSubmit={(data) => {
                  handleEmailFormSubmit(data);
                }}
                onClose={() => {
                  emailDetailsReset();
                  setIsEmailModelOpen(false);
                }}
              />
            </FormProvider>
          </>
        }
      />
      {isFileView && selectedFiles && (
        <CustomDialog
          show={isFileView}
          onHide={() => {
            setIsFileView(false);
          }}
          key={"File_preview_from_file_Previewer"}
          maxWidth={"lg"}
          header={`${selectedFiles?.name?.split(".")[0] || "File Preview"}`}
          contentNode={
            <>
              <FilteViewer URL={fileconvert} fileName={selectedFiles.name} />
            </>
          }
        />
      )}
    </Box>
  );
};

export default CostApproval;
