import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import axios from "axios";

import { localConfig, localhostConfig } from "./mockData";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { createDocument, deleteDocument, getDocument, resetDocumentState } from "../../store/slices/textEditorSlice";
import { textEditorPortVersion } from "../../store/services/config/constant";
import "./OnlyOfficeTextEditor.css";
import PluginStepper from "./plugin/PluginStepper/PluginStepper";
import { updateLoader } from "../../store/slices/loader";
import { getUser } from "../../store/slices/userData";
import CustomButton from "../dynamicButton/CustomButton";

interface IContractTemplate {
  templateId?: any;
  fileName?: any;
  editorsMode?: "edit" | "view" | "filter" | "blockContent" | "review";
  isNewContract?: boolean;
  setUpdatedTemplatedId?: any;
  isOpenDocument?: boolean;
  updateGobacktoReview?: any;
  autoStart?: boolean;
}

const onDocumentReady = async (
  event: any,
  setUpdatedTemplatedId: any,
  updateGobacktoReview: any,
  selectedFileConfigData: any
) => {
  console.log(event, `eventevent@event`);
  window.addEventListener("message", (message) => {
    const isValidOrigin = [
      "http://localhost:3000",
      "https://dev.clife.ai",
      "https://qa.clife.ai",
      "https://uat.clife.ai",
    ].includes(message.origin);

    if (isValidOrigin && message.data === "message") {
      const iframe = document.querySelector('iframe[name="frameEditor"]') as HTMLIFrameElement | null;
      if (iframe?.contentWindow) {
        localStorage.setItem("doc_Key",selectedFileConfigData?.document?.key)
        const object = {
          ClauseData: localStorage.getItem("ClauseData"),
          loginData: localStorage.getItem("persist:root"),
          USER_ROLE_KEY: localStorage.getItem("USER_ROLE_KEY"),
          processName: localStorage.getItem("processName"),
          userData: localStorage.getItem("userData"),
          contract_Tempate_Id: localStorage.getItem("contract_Tempate_Id"),
          formName: localStorage.getItem("formName"),
          Vendor_Portal: localStorage.getItem("Vendor_Portal"),
          showTab: localStorage.getItem("Show"),
          APIURL: localStorage.getItem("APIURL"),
          CMAPIURL: localStorage.getItem("CMAPIURL"),
          ShowRedline: localStorage.getItem("ShowRedline"),
          Vendor_Contract_Id: localStorage.getItem("vendor_Contract_Id"),
          user_Id: localStorage.getItem("user_Id"),
          template_Name: localStorage.getItem("template_Name"),
          doc_Key: selectedFileConfigData?.document?.key,
          signees: localStorage.getItem("signees")
        };
        console.log(`${object.APIURL}/api/v1/Clause/masterclausebyservices`);
        if (message.origin === "https://uat.clife.ai") {
          iframe.contentWindow.postMessage(JSON.stringify(object), "https://uatonlyoffice.clife.ai");
        } else {
          iframe.contentWindow.postMessage(JSON.stringify(object), "https://onlyoffice.clife.ai");
        }
      }
    } else if (isValidOrigin && typeof message.data === "string" && message.data.includes("pluginKey")) {
      const pluginData = JSON.parse(localStorage.getItem("pluginData") || "[]");
      if (!pluginData.includes(message.data)) {
        pluginData.push(message.data);
        localStorage.setItem("pluginData", JSON.stringify(pluginData));
      }

      const lastElement = JSON.parse(pluginData[pluginData.length - 1]);
      if (setUpdatedTemplatedId) {
        setUpdatedTemplatedId(lastElement?.value);
      }
    } else if (isValidOrigin && typeof message.data === "string" && message.data.includes("workFlowUpdate")) {
      const workFlowUpdate = JSON.parse(message.data)?.workFlowUpdate === "workFLowUpdate";
      if (workFlowUpdate && updateGobacktoReview && updateGobacktoReview !== null) {
        updateGobacktoReview(JSON.parse(message.data));
      }
    } else if (isValidOrigin && typeof message.data === "string" && message.data.includes("systemVariables")) {
      if (setUpdatedTemplatedId) {
        setUpdatedTemplatedId(true);
      }
    }
  });
};

const errorMessages: { [key: number]: string } = {
  [-1]: "Unknown error loading component",
  [-2]: "Error loading DocsAPI from document server",
  [-3]: "DocsAPI is not defined",
};

const onLoadComponentError = (errorCode: number, errorDescription: string) => {
  console.error(errorMessages[errorCode] || "Unhandled error:", errorDescription);
};

const OnlyOfficeTextEditor: React.FC<IContractTemplate> = ({
  templateId,
  fileName,
  editorsMode,
  isNewContract,
  setUpdatedTemplatedId,
  isOpenDocument,
  updateGobacktoReview = null,
  autoStart = true,
}) => {
  const dispatch = useAppDispatchThunk();
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const { userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { REACT_APP_TEXT_EDITOR, REACT_APP_TEXT_EDITOR_SERVER } = process.env;
  const { editorDocumentID } = useSelector((state: any) => state.contractManagementReducer.editorDoc);
  const { isLoading } = useSelector((state: RootState) => contractManagementReducer.textEditor);

  const [selectedFileConfig, setSelectedFileConfig] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const serviceUrl = `${REACT_APP_TEXT_EDITOR}`;
  useEffect(() => {
    if (userLoginResponse?.UserId) {
      const userId = userLoginResponse.UserId;
      dispatch(getUser({ UserId: userId }));
    }
  }, [userLoginResponse]);
  const handleCreateNewFile = async () => {
    if (!fileName) return;
    const req = {
      filename: fileName,
      directUrl: false,
      fileExt: "docx",
      templateId,
      userid: userLoginResponse?.UserId,
      editorsMode: editorsMode || "review",
    };

    try {
      let response = await dispatch(createDocument(req)).unwrap();
      if (!autoStart) {
        if (response.editorConfig?.plugins?.autostart) {
          response = {
            ...response,
            editorConfig: {
              ...response.editorConfig,
              plugins: {
                ...response.editorConfig.plugins,
                autostart: undefined,
              },
            },
          };
        }

        setSelectedFileConfig(response);
      } else {
        setSelectedFileConfig(response);
      }
    } catch (error) {
      console.error("Config not found in response.");
    }
  };

  const handleOpenFile = async () => {
    if (!fileName) return;

    try {
      const getReq = {
        filename: fileName,
        editorsMode: editorsMode || "review",
        userid: userLoginResponse?.UserId,
      };
      let response = await dispatch(getDocument(getReq)).unwrap();
      if (!autoStart) {
        if (response.editorConfig?.plugins?.autostart) {
          response = {
            ...response,
            editorConfig: {
              ...response.editorConfig,
              plugins: {
                ...response.editorConfig.plugins,
                autostart: undefined,
              },
            },
          };
        }
        setSelectedFileConfig(response);
      } else {
        setSelectedFileConfig(response);
      }

      dispatch(updateLoader(false));
    } catch (error) {
      console.error("Config not found in response.");
    }
  };

  const handleDeletePreview = async (fileName: any) => {
    if (!fileName) return;

    const req = { filename: fileName };

    try {
      await dispatch(deleteDocument(req)).unwrap();
      return true;
    } catch (error) {
      console.error("Error deleting preview:", error);
    }
  };

  useEffect(() => {
    if (!isOpenDocument) {
      handleCreateNewFile();
    } else {
      handleOpenFile();
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const onRequestHistory = async () => {
    try {
      const response = await axios.post(`${REACT_APP_TEXT_EDITOR}getHistory`, {
        fileName,
        directUrl: null,
      });
      console.log(window, "windowwindow");
      window.DocEditor.instances.docxEditor.refreshHistory({
        currentVersion: response.data.countVersion,
        history: response.data.history,
      });

      setSelectedFile((prev: any) => ({ ...prev, ...response.data }));
    } catch (error) {
      console.error("Error requesting history:", error);
    }
  };

  const onRequestHistoryData = async (event: any) => {
    const version = event.data;
    const historyData = selectedFile?.historyData[version - 1] || null;
    window.DocEditor.instances.docxEditor.setHistoryData(historyData);
  };

  const onRequestHistoryClose = () => {
    document.location.reload();
  };

  const onRequestSaveAs = (object:any) =>{
    console.log("Changes",object)
  }

  const convertDocument = async () => {
    try {
      let payload = {
        async: false,
        filetype: selectedFileConfig?.document?.title.split(".").pop(),
        outputtype: "pdf",
        //key: selectedFileConfig?.document?.key,
        key: selectedFileConfig?.token,
        url: selectedFileConfig?.document?.url,
        title: selectedFileConfig?.document?.title.replace(/\.[^/.]+$/, ""),
      };

      const response = await axios.post(
        `https://onlyoffice.clife.ai/ConvertService.ashx?shardkey=${payload.key}`,
        payload
      );
      console.log(response, "ConversionResponse");
      if (response.data.error) {
        console.error("Conversion error:", response.data.error);
      } else {
      }
    } catch (error) {
      console.error("Error converting document:", error);
    }
  };

  console.log(selectedFileConfig, "selectedFileConfig");
  return (
    <div style={{ height: "100vh" }}>
      {/* <CustomButton name="Convert to Pdf" onClick={convertDocument} variant="outlined" /> */}
      {selectedFileConfig && (
        <DocumentEditor
          id="docxEditor"
          documentServerUrl={`${REACT_APP_TEXT_EDITOR_SERVER}`}
          config={selectedFileConfig}
          events_onDocumentReady={(event) => {
            onDocumentReady(event, setUpdatedTemplatedId, updateGobacktoReview, selectedFileConfig);
          }}
          onLoadComponentError={onLoadComponentError}
          events_onRequestHistory={onRequestHistory}
          events_onRequestHistoryClose={onRequestHistoryClose}
          events_onRequestHistoryData={onRequestHistoryData}
          events_onRequestSaveAs={onRequestSaveAs}
          
        />
      )}

      {isLoading && <p>Loading...</p>}
    </div>
  );
};

export default OnlyOfficeTextEditor;
