import { Box, Grid, CircularProgress } from "@mui/material";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { ACTIVE, textFieldWidth } from "../../../utils/constants";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { LovConfig } from "../../../config/config";
import { FetchFromThirdParty } from "../../fetchFromThirdParty/FetchFromThirdParty";
import { clearSignzyData } from "../../../store/slices/signzySlice";
import { ProbeCompanyComprehensive } from "../../../store/slices/ProbeData";
import { updateLoader } from "../../../store/slices/loader";
import { addEventLog } from "../../../store/slices/eventLog";
import { ACTIVITY } from "../../../constants/app.constants";

const convertString = (str: any) => {
  return str
    .split("")
    .map((char: any) => {
      if (!isNaN(char) && char !== " ") {
        return "9";
      } else {
        return "X";
      }
    })
    .join("");
};

const generateRegexFromFormats = (formats: any) => {
  return formats.map((format: any) => {
    const formatData = convertString(format);
    const escapedFormat = formatData.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regexPattern = escapedFormat.replace(/X/g, "[A-Z]").replace(/9/g, "\\d");
    const regex = new RegExp(`^${regexPattern}$`);

    return regex;
  });
};

const getValidationForUniqueID = (idType: string, regionalTable: any[]) => {
  const format = regionalTable?.find((data: any) => data.Equivalent === idType)?.Format;

  if (format) {
    const regex = generateRegexFromFormats([format]).pop();
    return (value: string) => (regex?.test(value) ? true : `Invalid ${idType} format. Example: ${format}`);
  }

  return () => true;
};

interface SearchFormProps {
  isViewMode: boolean;
  disableIsActiveField: boolean;
}

const UniqueIdSearchForm: React.FC<SearchFormProps> = ({ isViewMode, disableIsActiveField }) => {
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    clearErrors,
    watch,
    resetField,
    reset,
  } = useForm({
    defaultValues: {
      UniqueID: "",
      UniqueIDText: "",
    },
    mode: "onChange",
  });
  const { SignzyData } = useSelector((state: RootState) => state.signzySlice);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { RegionalTable } = useSelector((state: RootState) => state.RegionalSettingsData);
  const dispatch = useAppDispatchThunk();
  const [options, setOptions] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const { user, } = useSelector((state: RootState) => state.loggerData);
  const { CompanyComprehensiveData, CompanyComprehensiveAdded, CompanyComprehensiveFailed, ProbeToasterMsg } = useSelector((state: RootState) => state.ProbeData);
  const UniqueTypeWatch = watch("UniqueID")
  const UniqueIDWatch = watch("UniqueIDText")
  console.log(UniqueIDWatch, "UniqueIDWatch");

  useEffect(() => {
    const filteredOptions = lovTable.filter(
      (item: any) => item.Name === LovConfig.UniqueIDType && item.IsActive === ACTIVE
    );
    setOptions(filteredOptions);
  }, [lovTable]);
  const [searchData, setSearchData] = useState<{ category: any; ID: string } | null>(null);

  useEffect(() => {
    if (CompanyComprehensiveAdded) {
      //    reset()
    }
    else if (CompanyComprehensiveFailed) {
      // setUniqueIdValue("");
      // setSelectedUniqueIdKey(undefined);
    }

  }, [CompanyComprehensiveAdded, CompanyComprehensiveFailed]);

  const handleSearch = async (data: any) => {
    if (data) {
      const updatedData = {
        category: options.find((data) => data?.LovId === getValues("UniqueID"))?.Options || "",
        ID: getValues("UniqueIDText"),
      };
      setSearchData(updatedData);
      dispatch(updateLoader(true));
      dispatch(ProbeCompanyComprehensive({
        ID: updatedData.ID,
        identifier_type: updatedData.category,
      }));
      const addEventLogPayload: any = {
        ModuleName: "Company Master",
        Action: ACTIVITY.CREATE,
        Description: `Details have been successfully retrieved using ${updatedData.category}`,
        UserId: 1,
        CreatedBy: user?.UserName || "",
      };
      dispatch(addEventLog(addEventLogPayload));
    } else {
      setSearchData({
        category: "",
        ID: "",
      });
    }
  };

  const clearSearch = () => {
    dispatch(clearSignzyData());
    reset();
  };

  return (
    <Box display="flex" flexDirection="column">
      <Grid container spacing={2}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Controller
            name="UniqueID"
            control={control}
            defaultValue=""
            rules={{
              required: "Unique ID Type is required",
            }}
            render={({ field }) => (
              <CustomSelect
                label="Unique ID Type"
                name="UniqueID"
                options={options}
                valueKey="LovId"
                optionLabelKey="Options"
                required={true}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors("UniqueIDText");
                  resetField("UniqueIDText");
                }}
                readOnly={isViewMode || !disableIsActiveField}
                error={Boolean(errors.UniqueID)}
                helperText={errors.UniqueID?.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Controller
            name="UniqueIDText"
            control={control}
            defaultValue=""
            rules={{
              required: "Unique ID is required",
              validate: {
                regexValidation: (value) => {
                  const getValue = options?.find((data: any) => data.LovId === getValues("UniqueID"))?.Options;
                  const format = RegionalTable?.find((data: any) => data.Equivalent === getValue)?.Format;
                  if (format) {
                    const regex = generateRegexFromFormats([format]).pop();
                    return regex?.test(value) ? true : `Invalid format. Example: ${format}`;
                  }

                  return true;
                },
              },
            }}
            render={({ field }) => (
              <CustomTextField
                label="Unique ID"
                name="UniqueIDText"
                required={true}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                }}
                readOnly={isViewMode || !disableIsActiveField}
                error={Boolean(errors.UniqueIDText)}
                helperText={errors.UniqueIDText?.message?.toString()}
              />
            )}
          />
        </Grid>

        {/* Search button or loading spinner */}
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <Box display="flex" justifyContent="flex-start" sx={{ mt: 0.3 }}>
            {isSearching ? (
              <CircularProgress />
            ) : (
              // <CustomButton
              //   name="Search"
              //   variant="contained"
              //   disabled={isViewMode || !disableIsActiveField || isSearching}
              //   onClick={handleSubmit(handleSearch)}
              // />
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                {Object.entries(SignzyData).length === 0 ? (
                  // <FetchFromThirdParty
                  //   displayName={"Fetch Details"}
                  //   data={{
                  //     category: options.find((data) => data?.LovId === getValues("UniqueID"))?.Options || "",
                  //     ID: getValues("UniqueIDText"),
                  //   }}
                  //   handleFunction={handleSubmit(handleSearch)}
                  //   disabled={isViewMode}
                  // />
                  <CustomButton variant="contained" name={"Fetch Details"} onClick={handleSearch} disabled={isViewMode || !UniqueTypeWatch || !UniqueIDWatch} />

                ) : (
                  <CustomButton
                    variant="contained"
                    name={"Clear"}
                    valuePass={{ name: "2", id: 2 }}
                    onClick={clearSearch}
                    disabled={isViewMode}
                  />
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UniqueIdSearchForm;
