import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

interface OtpTimerProps {
  duration: number; // Duration in seconds
  onTimeout: () => void;
}

const Time: React.FC<OtpTimerProps> = ({ duration, onTimeout }) => {
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    if (timeLeft === 0) {
      onTimeout();
      return;
    }

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  return (
    <Typography textAlign="left">
       OTP Expires in: {timeLeft} seconds
    </Typography>
   
  );
};

export default Time;
