import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { textFieldWidth } from "../../../utils/constants";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { getLovList } from "../../../store/slices/lovMasterData";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import CustomMultiSelect from "../../../common/dynamicInputs/CustomMultiSelect";
import CustomCheckBox from "../../../common/dynamicInputs/CustomCheckBox";
import CustomRadioButton from "../../../common/dynamicInputs/CustomRadioButton";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import assesmentQustionStyle from "./assesmentQuestion.module.scss";
import CustomSvg from "../../../common/CustomSvg";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { getDepartmentList } from "../../../store/slices/departmentData";
import BasicTableStyle from "../../../common/dynamicDataGrid/basicTable.module.scss";
import { addAQM } from "../../../store/slices/assessmentQuestionnaireMaster";
import { addSaveandDraft, deleteSaveandDraft, updateSaveandDraft } from "../../../store/slices/saveasdraft";
import { updateLoader } from "../../../store/slices/loader";
import { AQMResponder, AssessmentCategoryConfig, FormIdConfig, LovConfig, ModuleNames, ToasterActivityConfig } from "../../../config/config";
import { getRiskRateList } from "../../../store/slices/riskRateMasterData";
import { RecordSearch } from "../../recordSearch/RecordSearch";
import CustomTabs from "../../../common/dynamicTabs/CustomTabs";
import SearchServices from "../../contractManagement/SearchService/SearchServices";
import { setTabs } from "../../../store/slices/tabsData";
import { getServiceCatalogList } from "../../../store/slices/serviceCatalog";
import { ApplicationParameterName, DepartmentConfig } from "../../../config/config";
import InactivityTimer from "../../../common/InactivityTimer/InactivityTimer";


export interface AQMProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  HideHeader?: boolean;
  masterId?: string;
  onClose?: any;
  isDraftData?: any;
  ModuleId?: number | undefined;
}

interface Response {
  lable?: string;
  Attribute_Response?: string;
  Score?: number;
  IsActive?: boolean;
  Assessment_Questionaire_RatingId?: any;
  Assessment_Questionaire_Id?: any;
  status?: any;
  Id?: any;
}

interface newChoicesProps {
  isAdded: boolean;
  isLastIndex: number;
}

interface FormData {
  Question_CategoryId?: any;
  Question_CategoryId_Level2?: any;
  Question_CategoryId_Level3?: any;
  Assessment_Questionaire_Id?: any;
  Applicable_For?: any;
  Question?: any;
  Question_Title?: any;
  ApplicableRisks?: any;
  Answer_Type?: any;
  Assessment_TypeID?: any;
  Responder?: any;
  SME?: any;
  IsActive?: any;
  EvidenceRequired?: any;
  SMEVerified?: any;
  assessmentQuestionaireRatings?: Response[];
}

const clearAllScoreErrors = (clearErrors: any, watch: any) => {
  const allFields = watch("assessmentQuestionaireRatings") || [];
  allFields.forEach((_: any, index: number) => {
    clearErrors(`assessmentQuestionaireRatings.${index}.Score` as const);
  });
};

export const AddPreviewAssessmentQuestionnaireMaster: React.FC<AQMProps> = ({
  view,
  isEditData,
  edit,
  HideHeader,
  masterId,
  onClose,
  ModuleId,
  isDraftData,
}) => {
  const addIdinEditAttribute = (attribute: any) => {
    try {
      let id = 0;
      let converted_data: any = JSON.parse(attribute);
      return converted_data.map((item: any) => {
        id += 1;
        return {
          ...item,
          Id: id,
        };
      });
    } catch {
      return [];
    }
  };

  const { RiskRateDataActive } = useSelector((state: RootState) => state.RiskRateData);
  const { ServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);
  const currentDate = new Date();
  const dispatch = useAppDispatchThunk();
  const { dataTable } = useSelector((state: RootState) => state.departmentData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [QuestionCategoryLV, setQuestionCategoryLV] = useState<any[]>([]);
  const [ApplicableForLV, setApplicableForLV] = useState<any[]>([]);
  const [AssesmentTypeLV, setAssesmentTypeLV] = useState<any[]>([]);
  const [SMELV, setSMELV] = useState<any[]>([]);
  const [applicableRiskData, setApplicableRiskData] = useState<any[]>([]);
  const [ResponderLV, setResponderLV] = useState<any[]>([]);
  const [riskType, setRiskType] = useState<any[]>([]);
  const [scoreList, setScoreList] = useState<any[]>([]);
  const [scoreandRiskList, setScoreandRiskList] = useState<any[]>([]);
  const [scoreandRiskListDraft, setScoreandRiskListDraft] = useState<any[]>([]);
  const [changeMaking, setchangeMaking] = useState<any>(false);
  const [ischangeDefault, setIschangeDefault] = useState<any>(false);
  const [ischangelowscore, setIschangelowscore] = useState<any>(false);
  const [AttriError, setAttriError] = useState<any>(null);
  const [isNewChoices, setIsNewChoices] = useState<newChoicesProps>({ isAdded: false, isLastIndex: 0 });
  const [lovActiveStatusId, setLovActiveStatusId] = useState<any>(null);
  const [lovInActiveStatusId, setLovInActiveStatusId] = useState<any>(null);
  const [serviceCatalog, setserviceCatalog] = useState<any[]>([]);

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { draftdata, isAddEditLoadingDraft, isDraftAdded, isDraftUpdated } = useSelector(
    (state: RootState) => state.saveAsDraft
  );
  const { AQMdataTable, isAddEditAQMLoading, isAQMAdded, isAQMUpdated, isAQMLoading } = useSelector(
    (state: RootState) => state.aQMData
  );

  const { dataTable: assessmentCategoryMasterData } = useSelector(
    (state: RootState) => state.assessmentCategoryMasterData
  );

   const [timeout, setTimeout] = useState<any>(null);
    const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);


      useEffect(() => {
        if (edit) {
          const RecordTimeOut: any = ApplicationParameterName.RecordTimeOut;
          let findRecordTimeOut = applicationParameterData.find(
            (applicationParamitem) =>
              applicationParamitem.Parameter_Name === RecordTimeOut &&
              applicationParamitem.Parameter_Group === RecordTimeOut
          )?.Parameter_Value;
          setTimeout(Number(findRecordTimeOut));
        }
      }, []);

  let formData = {
    Assessment_Questionaire_Id: isEditData ? isEditData?.Assessment_Questionaire_Id : 0,
    Question_CategoryId: isEditData
      ? isEditData?.Question_CategoryId
      : isDraftData
        ? isDraftData?.Question_CategoryId
        : "",
    Question_CategoryId_Level2: isEditData
      ? isEditData?.Question_CategoryId_Level2 === 0
        ? ""
        : isEditData?.Question_CategoryId_Level2
      : isDraftData
        ? isDraftData?.Question_CategoryId_Level2
        : "",
    Question_CategoryId_Level3: isEditData
      ? isEditData?.Question_CategoryId_Level3 === 0
        ? ""
        : isEditData?.Question_CategoryId_Level3
      : isDraftData
        ? isDraftData?.Question_CategoryId_Level3
        : "",
    Applicable_For: isEditData ? isEditData?.Applicable_For : isDraftData ? isDraftData?.Applicable_For : "",
    Question: isEditData ? isEditData?.Question : isDraftData ? isDraftData?.Question : "",
    Question_Title: isEditData ? isEditData?.Question_Title : isDraftData ? isDraftData?.Question_Title : "",
    ApplicableRisks: isEditData
      ? isEditData?.ApplicableRisks
        ? JSON.parse(isEditData?.ApplicableRisks)
        : []
      : isDraftData
        ? isDraftData?.ApplicableRisks
        : [],
    EvidenceRequired: isEditData ? isEditData?.EvidenceRequired : isDraftData ? isDraftData?.EvidenceRequired : false,
    SMEVerified: isEditData ? isEditData?.SMEVerified : isDraftData ? isDraftData.SMEVerified : false,
    Answer_Type: isEditData
      ? isEditData.Answer_Type == 1
        ? "multiple"
        : "text"
      : isDraftData
        ? isDraftData.Answer_Type == true
          ? "multiple"
          : "text"
        : "multiple",
    Assessment_TypeID: isEditData
      ? isEditData?.Assessment_TypeID
        ? JSON.parse(isEditData?.Assessment_TypeID)
        : []
      : isDraftData
        ? isDraftData?.Assessment_TypeID
        : [],
    Responder: isEditData ? isEditData?.Responder : isDraftData ? isDraftData?.Responder : "",
    SME: isEditData
      ? isEditData.SME === 0
        ? ""
        : isEditData.SME
      : isDraftData
        ? isDraftData.SME === 0
          ? ""
          : isDraftData.SME
        : "",
    IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
    assessmentQuestionaireRatings: isEditData
      ? isEditData?.AQMRatingJson
        ? addIdinEditAttribute(isEditData?.AQMRatingJson)
        : []
      : isDraftData
        ? isDraftData?.assessmentQuestionaireRatings
        : [],
  };

  const {
    handleSubmit: handleAQMForm,
    reset,
    setValue,
    formState: { errors: errorsAQMform, isDirty },
    control,
    watch,
    getValues,
    clearErrors,
    trigger,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "assessmentQuestionaireRatings",
  });

  const assessmentQuestionaireRatingsstop = watch("assessmentQuestionaireRatings") || [];
  let isActiveDisabled = watch("IsActive");

  const Question_CategoryId = watch("Question_CategoryId");
  const Question_CategoryId_Level2 = watch("Question_CategoryId_Level2");

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
    dispatch(
      getDepartmentList({
        FormID: 2,
      })
    );
    dispatch(
      getRiskRateList({
        FormID: 2,
      })
    );
    dispatch(getServiceCatalogList());
  }, []);

  useEffect(() => {
    setserviceCatalog(ServiceCatlogdata);
  }, [ServiceCatlogdata]);

  useEffect(() => {
    dispatch(updateLoader(isAddEditAQMLoading || isAddEditLoadingDraft));
  }, [isAddEditAQMLoading, isAddEditLoadingDraft]);

  useEffect(() => {
    if (isEditData?.AQMBestScoreJson) {
      setScoreandRiskList(JSON.parse(isEditData?.AQMBestScoreJson));
    }
    if (isEditData?.AQMServiceMappingJson && lovActiveStatusId) {
      const data = JSON.parse(isEditData?.AQMServiceMappingJson)
        .filter((i: any) => i.Status === lovActiveStatusId)
        .map((item: any) => item.ServiceCatalog_Id);
      setUnmappedService(data);
    }
    return () => {
      dispatch(setTabs(0));
    };
  }, [isEditData, lovActiveStatusId]);

  useEffect(() => {
    if (isDraftData?.assessmentQuestionairebestScores) {
      try {
        setScoreandRiskList(JSON.parse(isDraftData?.assessmentQuestionairebestScores));
      } catch {
        setScoreandRiskList(isDraftData?.assessmentQuestionairebestScores || []);
      }
    }
    if (isDraftData?.AssessmentQuestionaireServiceMappings)
      setUnmappedService(isDraftData?.AssessmentQuestionaireServiceMappings);
  }, [isDraftData]);

  const IsfindSME = () => {
    let finedResponder = watch("Responder") || "";
    let finalValueofSMEselect = ResponderLV.find(
      (item) => item.Options === "Service Owner" && item.LovId === finedResponder
    );
    return finalValueofSMEselect ? false : true;
  };

  const IsfindSMEVerified = () => {
    let finedResponder = watch("Responder") || "";
    let finalValueofSMEselect = ResponderLV.find(
      (item) => item.Options === AQMResponder.Vendor && item.LovId === finedResponder
    );
    return finalValueofSMEselect ? true : false;
  };

  const ratingData = useWatch({
    control,
    name: "assessmentQuestionaireRatings",
  });
  useEffect(() => {
    if (isAQMAdded || isAQMUpdated || isDraftAdded || isDraftUpdated) {
      if (onClose) {
        if (isDraftData && isAQMAdded) {
          deleteDraftList();
        }
        onClose();
      }
    }
  }, [isAQMAdded, isAQMUpdated, isDraftAdded, isDraftUpdated]);

  const deleteDraftList = () => {
    let formData = {
      DraftId: isDraftData?.DraftId,
      CreatedBy: user?.UserName,
    };

    dispatch(deleteSaveandDraft(formData));
  };

  useEffect(() => {
    let changedRating = ratingData?.map((item) => {
      item.lable = `${item.Attribute_Response} Score - ${item.Score}`;
      return item;
    });
    setScoreList(changedRating || []);
  }, [watch("assessmentQuestionaireRatings"), fields, ratingData]);

  const findLowScoreofChoice = () => {
    let ratingData = watch("assessmentQuestionaireRatings") || [];
    return ratingData.reduce((low: any, item: any) => {
      if (item.Score !== undefined && item.Score !== null && item.Score < low) {
        return item.Score;
      }
      return low;
    }, Infinity);
  };

  const SetDefaultScoringLow = (option?: any) => {
    let ratingData = watch("assessmentQuestionaireRatings");
    let finedLowScore = findLowScoreofChoice();
    let riskList = watch("ApplicableRisks");
    let changeDefaultList = applicableRiskData.map((item) => {
      let findOldScore = scoreandRiskList.find((oldItem) => oldItem.RiskId == item.RiskCategory_Id);

      let checkFindAvailable = finedLowScore;
      ratingData &&
        ratingData.map((item) => {
          if (item.Score && findOldScore?.Score && item.Score == findOldScore.Score) {
            checkFindAvailable = findOldScore.Score;
          }
        });

      return {
        ...item,
        Assessment_Questionaire_BestScoreId: findOldScore?.Assessment_Questionaire_BestScoreId
          ? findOldScore.Assessment_Questionaire_BestScoreId
          : 0,
        Assessment_Questionaire_Id: findOldScore?.Assessment_Questionaire_BestScoreId
          ? findOldScore.Assessment_Questionaire_BestScoreId
          : 0,
        RiskId: findOldScore?.RiskId ? findOldScore.RiskId : item.RiskCategory_Id,
        Score: findOldScore
          ? riskList.includes(item.RiskCategory_Id)
            ? checkFindAvailable
            : finedLowScore
          : finedLowScore,
        Active: riskList.includes(item.RiskCategory_Id) ? true : false,
      };
    });
    if (option) {
      return changeDefaultList;
    }
    setScoreandRiskList(changeDefaultList);
    setScoreandRiskListDraft(changeDefaultList);
  };

  const onchangeDefaultScore = (index: any, value: any) => {
    setIschangelowscore(true);
    setScoreandRiskList((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        Score: value,
      };
      return newState;
    });
  };

  const onCloseWithoutSave = () => {
    let lastChange = [...scoreandRiskListDraft];
    if (lastChange.length > 0) {
      setScoreandRiskList(lastChange);
    }
    setScoreandRiskListDraft([]);
    setIschangeDefault(false);
  };

  const onCloseWithSave = () => {
    setScoreandRiskListDraft([]);
    setIschangeDefault(false);
  };

  useEffect(() => {
    setLovActiveStatusId(
      lovTable.find((item: any) => item.Name === LovConfig.Status && item.Description === "Active")?.LovId || 0
    );
    setLovInActiveStatusId(
      lovTable.find((item: any) => item.Name === LovConfig.Status && item.Description === "In Active")?.LovId || 0
    );
    setQuestionCategoryLV(lovTable.filter((item: any) => item.Name === LovConfig?.AQ_Catagory) || []);
    setResponderLV(lovTable.filter((item: any) => item.Name === LovConfig?.AQ_Responder) || []);
    setAssesmentTypeLV(lovTable.filter((item: any) => item.Name === LovConfig.Assessment_Type) || []);
    setApplicableForLV(lovTable.filter((item: any) => item.Name === LovConfig.Assessment_TypeII) || []);
    // setApplicableRiskData(lovTable.filter((item: any) => item.Name === "Risk Category III") || []);
  }, [lovTable]);

  useEffect(() => {
    if (RiskRateDataActive) {
      setApplicableRiskData(RiskRateDataActive || []);
    }
  }, [RiskRateDataActive]);
  useEffect(() => {
    setSMELV(dataTable);
  }, [dataTable]);

  const closeForm = (value: any) => {
    if (onClose && value === 0) {
      if (isDirty) {
        setchangeMaking(true);
      } else {
        onClose();
      }
    }
  };
  const ChangeDetails = (value: boolean) => {
    setIschangeDefault(value);
  };

  const addAtributes = () => {
    setAttriError(null);
    const dubAttributeResponse = getValues("assessmentQuestionaireRatings");
    if ((dubAttributeResponse && dubAttributeResponse?.length <= 4) || !dubAttributeResponse) {
      const scoringRangeFromcheck = 1;
      const scoringRangeTocheck = dubAttributeResponse ? dubAttributeResponse.length + 1 : 1;

      let scorestart = scoringRangeFromcheck >= 0 ? scoringRangeFromcheck : 1;
      let scoreend =
        scoringRangeTocheck >= 0 ? scoringRangeTocheck : scoringRangeFromcheck >= 0 ? scoringRangeFromcheck : 1;
      let Id = 1;
      let Score = scorestart;

      if (dubAttributeResponse && dubAttributeResponse.length > 0) {
        Id = dubAttributeResponse.length + 1;
        dubAttributeResponse.map((item) => {
          if (item.Id == Id) {
            Id = Id + 1;
          }
          if (item.Score && item.Score >= 0 && item.Score < scoreend) {
            Score = Number(item.Score) + 1;
          } else if (item.Score && item.Score >= 0 && item.Score >= scoreend) {
            Score = scoreend;
          } else {
            Score = scorestart;
          }
        });
      }
      append({
        Score: Score,
        Assessment_Questionaire_RatingId: 0,
        Assessment_Questionaire_Id: 0,
        status: 1,
        IsActive: true,
        Id: Id,
      });
      if (dubAttributeResponse) {
        clearErrors(`assessmentQuestionaireRatings.${dubAttributeResponse.length}.Attribute_Response`);
        clearErrors(`assessmentQuestionaireRatings.${dubAttributeResponse.length}.Score`);
      }

      SetDefaultScoringLow();
      setIsNewChoices({ isAdded: true, isLastIndex: fields.length + 1 });
    }
  };

  const deleteAttributes = (index: number) => {
    const attributeResponse = getValues("assessmentQuestionaireRatings");
    if (attributeResponse && attributeResponse.length > index) {
      const updatedAttributeResponse = attributeResponse.slice();
      updatedAttributeResponse.splice(index, 1);
      clearErrors(`assessmentQuestionaireRatings.${index}.Attribute_Response`);
      clearErrors(`assessmentQuestionaireRatings.${index}.Score`);

      setValue("assessmentQuestionaireRatings", updatedAttributeResponse, { shouldDirty: true, shouldValidate: true });
      trigger("assessmentQuestionaireRatings");
    }
    SetDefaultScoringLow();
  };

  const ismultipleEnable = watch("Answer_Type") === "multiple" ? true : false;
  const checkrestype = () => {
    return ismultipleEnable;
  };
  const handleSubmit = (data: any) => {
    let scoreandRiskListFinal: any = SetDefaultScoringLow(true) || [];
    let formData = data;
    if (!formData.Question_CategoryId_Level2) formData.Question_CategoryId_Level2 = null;
    if (!formData.Question_CategoryId_Level3) formData.Question_CategoryId_Level3 = null;
    let form1 = getValues();
    let formData1 = { ...form1 };
    let formDataValue: any = formData1;

    let RiskListDataSheet = applicableRiskData
      ?.filter((item: any) => formData?.ApplicableRisks?.includes(item?.RiskCategory_Id))
      .map((item: any) => item.RiskCategory)
      .join(",");
    let AssementTypwListDataSheet = AssesmentTypeLV?.filter((item: any) =>
      formData?.Assessment_TypeID?.includes(item?.LovId)
    )
      .map((item: any) => item.Options)
      .join(",");


    let serviceMappingListDataSheet: any = serviceCatalog
      ?.filter((item: any) => unmappedService.includes(item?.ServiceCatalogId))
      .map((item: any) => item.ServiceName)
      .join(",");
     let MultichoiceScore =  formData.assessmentQuestionaireRatings ? formData.assessmentQuestionaireRatings.map((Score_item : any) => { return { "Attribute Response" : Score_item?.Attribute_Response ,  Score : Score_item?.Score

     }  }) : ""
    console.log(unmappedService, serviceMappingListDataSheet, "unmappedService", serviceCatalog);
    let JsonDataForDisplay: any = {
      "Question Title": formData.Question_Title,
      Question: formData.Question,
      "Applicable For": ApplicableForLV.find((item: any) => item.LovId == formDataValue.Applicable_For)?.Options || "",
      "Category I": assessmentCategoryMasterData.find((item: any) => item.AQ_Category_Id == formDataValue.Question_CategoryId)?.AQ_Category_Name || "",
      "Category II": assessmentCategoryMasterData.find((item: any) => item.AQ_Category_Id == formDataValue.Question_CategoryId_Level2)?.AQ_Category_Name || "",
      "Category III": assessmentCategoryMasterData.find((item: any) => item.AQ_Category_Id == formDataValue.Question_CategoryId_Level3)?.AQ_Category_Name || "",
      "Evidence Required": formDataValue.EvidenceRequired == true ? "Evidence Required" : "Evidence Not Required",
      "Answer Type": formData?.Answer_Type === "multiple" ? "Multiple Choice" : "Free Text",
      "Applicale Risk": RiskListDataSheet,
      "Assessment Type": AssementTypwListDataSheet,
      Respondor: ResponderLV.find((item: any) => item.LovId == formDataValue.Responder)?.Options || "",
      Department: SMELV.find((item: any) => item.DepartmentId == formDataValue.SME)?.DepartmentName || "",
      "SME verification": formDataValue?.SMEVerified ? "Yes" : "No",
      Score: JSON.stringify(formData.assessmentQuestionaireRatings || ""),
      "Service Mapping": serviceMappingListDataSheet || "",
      Status: "Active",
      "Modified By": user?.UserName,
    };

    if ((checkrestype() && formData.assessmentQuestionaireRatings.length > 0) || !checkrestype()) {
      formData.ApplicableRisks = JSON.stringify(formData.ApplicableRisks);
      formData.Answer_Type = formData?.Answer_Type === "multiple" ? true : false;
      formData.Approved_By = user?.UserName;
      formData.Updated_By = user?.UserName;
      formData.Updated_On = currentDate;
      formData.Approved_On = currentDate;
      formData.Status = 1;
      formData.CreatedBy = user?.UserName;
      formData.ActionTaken = edit ? ToasterActivityConfig.Edit : ToasterActivityConfig.create;
      if (!IsfindSME()) {
        formData.SME = 0;
        formData.SMEVerified = false;
      }
      if (checkrestype()) {
        formData.assessmentQuestionaireRatings.map((item: any) => {
          item.Approved_By = user?.UserName;
          item.Updated_By = user?.UserName;
          item.Updated_On = currentDate;
          item.Approved_On = currentDate;
          return item;
        });
        let changedbestScore = scoreandRiskListFinal.map((item: any) => {
          item.IsActive = item.Active;
          item.Approved_By = user?.UserName;
          item.Updated_By = user?.UserName;
          item.Updated_On = currentDate;
          item.Approved_On = currentDate;
          return item;
        });
        formData.assessmentQuestionairebestScores = changedbestScore.filter((item: any) => item.IsActive);
      } else {
        formData.ApplicableRisks = "[]";
        formData.assessmentQuestionaireRatings = [];
        formData.assessmentQuestionairebestScores = [];
      }
      formData.Assessment_TypeID = JSON.stringify(formData.Assessment_TypeID);
      let assessmentQuestionaireServiceMappings: any;
      if (edit) {
        const AQMServiceMappingJson = isEditData?.AQMServiceMappingJson
          ? JSON.parse(isEditData?.AQMServiceMappingJson)
          : [];
        assessmentQuestionaireServiceMappings = unmappedService.map((item) => {
          const res = AQMServiceMappingJson.find((item1: any) => item === item1.ServiceCatalog_Id);
          return {
            ServiceMapping_Id: res?.ServiceMapping_Id || 0,
            Assessment_Questionaire_Id: isEditData?.Assessment_Questionaire_Id || 0,
            ServiceCatalog_Id: item,
            Status: lovActiveStatusId,
            Updated_By: user?.UserName,
          };
        });

        AQMServiceMappingJson.map((item: any) => {
          if (!unmappedService.includes(item.ServiceCatalog_Id)) {
            assessmentQuestionaireServiceMappings = [
              ...assessmentQuestionaireServiceMappings,
              {
                ...item,
                Status: lovInActiveStatusId,
                Updated_By: user?.UserName,
              },
            ];
          }
        });
      } else
        assessmentQuestionaireServiceMappings = unmappedService.map((item) => {
          return {
            ServiceMapping_Id: 0,
            Assessment_Questionaire_Id: 0,
            ServiceCatalog_Id: item,
            Status: lovActiveStatusId,
            Updated_By: user?.UserName,
          };
        });
      formData.AssessmentQuestionaireServiceMappings = assessmentQuestionaireServiceMappings;
      formData.ApproverComments  = "";
      let FinalForm = {
        assessmentQuestionaire: formData,
        ModuleName: ModuleNames.AQMaster,
        UserId: userLoginResponse?.UserId,
        Activity: edit ? ToasterActivityConfig.Edit : ToasterActivityConfig.create,
        UpdatedBy: user?.UserName,
        DataForDisplay: JSON.stringify(JsonDataForDisplay),
        CreatedBy: user?.UserName,
        "Form_Id": FormIdConfig.AQMasterFormId,
        ActionTaken: edit ? ToasterActivityConfig.Edit : ToasterActivityConfig.create,
      };
      // formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      if (edit) {
        dispatch(addAQM(FinalForm));
      } else {
        dispatch(addAQM(FinalForm));
      }
    } else {
      setAttriError("Atleast One Score is Required");
    }
  };

  const saveAsDraftServiceCall = () => {
    let scoreandRiskListFinal: any = SetDefaultScoringLow(true) || [];
    let form1 = getValues();
    let formData1 = { ...form1 };
    let formDataValue: any = formData1;
    if (!formDataValue.Question_CategoryId_Level2) formDataValue.Question_CategoryId_Level2 = null;
    if (!formDataValue.Question_CategoryId_Level3) formDataValue.Question_CategoryId_Level3 = null;
    formDataValue.Assessment_TypeID = formDataValue.Assessment_TypeID;

    formDataValue.Answer_Type = formDataValue?.Answer_Type === "multiple" ? 1 : 0;
    // formDataValue.assessmentQuestionairebestScores = scoreandRiskList;
    if (checkrestype()) {
      formDataValue.assessmentQuestionairebestScores = scoreandRiskListFinal.filter((item: any) => item.Active);
    } else {
      formDataValue.ApplicableRisks = [];
      formDataValue.assessmentQuestionaireRatings = [];
      formDataValue.assessmentQuestionairebestScores = [];
    }

    formDataValue.AssessmentQuestionaireServiceMappings = unmappedService;

    let applicableval =
      QuestionCategoryLV.find((item) => item.LovId == formDataValue.Question_CategoryId)?.Options || "";
    let questionsval = ApplicableForLV.find((item) => item.LovId == formDataValue.Applicable_For)?.Options || "";

    formDataValue.Question_CategoryId_Value = questionsval;
    formDataValue.Applicable_For_Value = applicableval;
    formDataValue.IsEvidenceRequired =
      formDataValue.EvidenceRequired == true ? "Evidence Required" : "Evidence Not Required";
    formDataValue.Updated_By = user?.UserName;

    const saveAsDraft: any = {
      UserId: userLoginResponse?.UserId,
      ModuleName: "AssessmentQuestionaire",
      Activity: isDraftData ? ToasterActivityConfig.editDraft : ToasterActivityConfig.createDraft,
      ScreenName: "AssessmentQuestionaire",
      DraftData: JSON.stringify(formDataValue),
      DraftStatus: true,
      CreatedBy: user?.UserName,
    };
    if (isDraftData) {
      saveAsDraft.DraftId = isDraftData.DraftId;
      saveAsDraft.UpdatedBy = user?.UserName;
      dispatch(updateSaveandDraft(saveAsDraft));
    } else {
      dispatch(addSaveandDraft(saveAsDraft));
    }
  };

  const [question, setQuestion] = useState<string | null>(null);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const { active } = useSelector((state: RootState) => state.tabData);
  const [rowData, setRowData] = useState<any[]>([]);
  const [tapSteps, setTapSteps] = useState<any[]>([
    { name: "Questionnaire Details" },
    { name: "Service Catalog Mapping", disabled: true },
  ]);
  const [unmappedService, setUnmappedService] = React.useState<any[]>([]);
  const [showServiceMapping, setShowServiceMapping] = React.useState(false);

  const updateServices = (updatedservices: any[]) => {
    setUnmappedService(updatedservices);
  };

  useEffect(() => {
    if (Question_CategoryId) {
      const res = assessmentCategoryMasterData.filter((item: any) => item.AQ_Category_Id === Question_CategoryId)[0];
      setTapSteps([
        { name: "Questionnaire Details" },
        { name: "Service Catalog Mapping", disabled: !res?.Map_Service_Catalogue },
      ]);
      setShowServiceMapping(res?.Map_Service_Catalogue);
    }
  }, [Question_CategoryId]);

  const moveToNextTab = () => {
    dispatch(setTabs(active + 1));
  };

  const handleTabNavigation = async (event: React.SyntheticEvent, targetTab?: number) => {
    if (targetTab !== undefined && targetTab < active) {
      dispatch(setTabs(targetTab));
    } else handleAQMForm(moveToNextTab)(event);
  };
  const handleRecordSearch = (Question: string) => {
    setQuestion(Question);
    setIsDialogVisible(true);
  };
  const RecordSearchColumns = {
    table_name: "Record Search",
    isAction: false,
    columns: [
      { Field: "question", DisplayName: "Question", FieldType: "string" },
      { Field: "responder", DisplayName: "Responder", FieldType: "string" },
      { Field: "sme", DisplayName: "SME", FieldType: "string" },
      { Field: "field_type", DisplayName: "DD or Free", FieldType: "string" },
    ],
  };
  const RecordSearchCategoryData: any[] = [
    {
      id: 1,
      category: "Category One",
      questions: [
        { id: 1, question: "What is your name?", responder: "Alice", sme: "Bob", field_type: "Free" },
        { id: 2, question: "What is your favorite color?", responder: "Charlie", sme: "Diana", field_type: "DD" },
      ],
    },
    {
      id: 2,
      category: "Category Two",
      questions: [
        { id: 3, question: "What is your quest?", responder: "Eve", sme: "Frank", field_type: "Free" },
        {
          id: 4,
          question: "What is the airspeed velocity of an unladen swallow?",
          responder: "Arthur",
          sme: "Merlin",
          field_type: "Free",
        },
      ],
    },
  ];

  return (
    <>
      <Box component="main" sx={{ height: "auto", width: "100%", overflow: "hidden", position: "relative" }}>
        {!HideHeader && (
          <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
            <CustomBreadcrumbs
              list={[
                { value: 0, name: currentPagePermissionData?.MenuName },
                { value: 1, name: `${edit ? "Edit" : view ? "View" : "Add New"}` },
              ]}
              onchange={closeForm}
            />
          </Box>
        )}

        <Stack spacing={2}>
          {!HideHeader && (
            <Box>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography variant="h6" color={"text.primary"}>
                  {`${edit ? "Edit" : view ? "View" : "Add New"} Assessment Questionnaire`}
                </Typography>
                {!view && !edit && (
                  <CustomButton
                    type="reset"
                    variant="contained"
                    name={"Save as Draft"}
                    disabled={!isActiveDisabled}
                    onClick={() => {
                      saveAsDraftServiceCall();
                    }}
                  />
                )}
              </Stack>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Box sx={{ width: "100%" }}>
              <CustomTabs steps={tapSteps} handleTabNavigation={handleTabNavigation} />
            </Box>
          </Box>
          {false && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <CustomButton
                type="reset"
                variant="outlined"
                name={"Add Questions"}
                onClick={() => {
                  handleRecordSearch("Question");
                }}
              />
            </Box>
          )}
          {active === 0 ? (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                width: "100%",
                border: "1px solid #F7F7F7",
              }}
            >
              <Box
                sx={{
                  width: "60%",
                  padding: 2,
                }}
              >
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                    <Controller
                      name="Question_CategoryId"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please select a category" }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"Question Group"}
                          valueKey={"AQ_Category_Id"}
                          optionLabelKey="AQ_Category_Name"
                          options={assessmentCategoryMasterData.filter(
                            (item: any) =>
                              item.AQ_Category_Type_Value === AssessmentCategoryConfig?.Category &&
                              item.IsActive === "Active"
                          )}
                          readOnly={view || !isActiveDisabled}
                          required={true}
                          label={"Category"}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                            setValue("Question_CategoryId_Level2", "");
                            setValue("Question_CategoryId_Level3", "");
                          }}
                          error={Boolean(errorsAQMform.Question_CategoryId)}
                          helperText={
                            errorsAQMform.Question_CategoryId && errorsAQMform.Question_CategoryId.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                    <Controller
                      name="Question_CategoryId_Level2"
                      control={control}
                      defaultValue=""
                      // rules={{ required: "Category II is Required" }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"Question Group"}
                          valueKey={"AQ_Category_Id"}
                          optionLabelKey="AQ_Category_Name"
                          options={assessmentCategoryMasterData.filter(
                            (item: any) => item.Parent_AQ_Category_Id === Question_CategoryId
                          )}
                          readOnly={view || !isActiveDisabled}
                          // required={true}
                          label={"Sub Category I"}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                            setValue("Question_CategoryId_Level3", "");
                          }}
                          error={Boolean(errorsAQMform.Question_CategoryId_Level2)}
                          helperText={
                            errorsAQMform.Question_CategoryId_Level2 &&
                            errorsAQMform.Question_CategoryId_Level2.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                    <Controller
                      name="Question_CategoryId_Level3"
                      control={control}
                      defaultValue=""
                      // rules={{ required: "Category III is Required" }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"Question Group"}
                          valueKey={"AQ_Category_Id"}
                          optionLabelKey="AQ_Category_Name"
                          options={assessmentCategoryMasterData.filter(
                            (item: any) => item.Parent_AQ_Category_Id === Question_CategoryId_Level2
                          )}
                          readOnly={view || !isActiveDisabled}
                          // required={true}
                          label={"Sub Category II"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsAQMform.Question_CategoryId_Level3)}
                          helperText={
                            errorsAQMform.Question_CategoryId_Level3 &&
                            errorsAQMform.Question_CategoryId_Level3.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                    <Controller
                      name="Applicable_For"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please specify the applicable for" }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"Applicable for"}
                          valueKey={"LovId"}
                          optionLabelKey="Options"
                          options={ApplicableForLV}
                          readOnly={view || !isActiveDisabled}
                          required={true}
                          label={"Applicable for"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsAQMform.Applicable_For)}
                          helperText={errorsAQMform.Applicable_For && errorsAQMform.Applicable_For.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                      <Controller
                        name="Question_Title"
                        control={control}
                        defaultValue={""}
                        rules={{
                          required: "Please provide a title for your question",
                          validate: {
                            maxLength: (value) => {
                              if (value === undefined) return "Please provide a title for your question";
                              return value.length <= 100 || "Maximum 100 characters allowed";
                            },
                          },
                        }}
                        render={({ field }) => (
                          <CustomTextField
                            label={"Question Title"}
                            name={"Question Title"}
                            value={field.value}
                            readOnly={view || !isActiveDisabled}
                            required={true}
                            onChange={field.onChange}
                            error={Boolean(errorsAQMform.Question_Title)}
                            helperText={
                              errorsAQMform.Question_Title && errorsAQMform.Question_Title.message?.toString()
                            }
                          />
                        )}
                      />
                      <Controller
                        name="Question"
                        control={control}
                        defaultValue={""}
                        rules={{
                          required: "Please provide a Question",
                          validate: {
                            maxLength: (value) => {
                              if (value === undefined) return "Please provide a Question";
                              return value.length <= 250 || "Maximum 250 characters allowed";
                            },
                          },
                        }}
                        render={({ field }) => (
                          <CustomMultilineTextField
                            label={"Question"}
                            value={field.value}
                            readOnly={view || !isActiveDisabled}
                            rows={4}
                            required={true}
                            onChange={field.onChange}
                            error={Boolean(errorsAQMform.Question)}
                            helperText={errorsAQMform.Question && errorsAQMform.Question.message?.toString()}
                          />
                        )}
                      />

                      <Controller
                        name="EvidenceRequired"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <CustomCheckBox
                            label="Evidence Required"
                            isStyle={true}
                            readOnly={view || !isActiveDisabled}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                    <Box>
                      <Controller
                        name="Answer_Type"
                        control={control}
                        defaultValue={""}
                        rules={{
                          required: "Engage is Required",
                        }}
                        render={({ field }) => (
                          <CustomRadioButton
                            direction={"row"}
                            list={[
                              { value: "multiple", lable: "Multiple Choice" },
                              { value: "text", lable: "Free Text" },
                            ]}
                            label="Answer Type"
                            readOnly={view || !isActiveDisabled}
                            // disabled={view || !isActiveDisabled}
                            value={field.value}
                            onChange={(val) => {
                              field.onChange(val);
                              setAttriError(null);
                            }}
                            error={Boolean(errorsAQMform.Answer_Type)}
                            helperText={errorsAQMform.Answer_Type && errorsAQMform.Answer_Type.message?.toString()}
                          />
                        )}
                      />
                      <Box sx={{ mt: 1 }}>
                        {ismultipleEnable && (
                          <Table sx={{ maxWidth: "1o0%" }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell className={assesmentQustionStyle.score_cell}>Choices</TableCell>
                                <TableCell className={assesmentQustionStyle.score_cell} align="center">
                                  Score
                                </TableCell>
                                {!view ? (
                                  <TableCell className={assesmentQustionStyle.score_cell} align="center"></TableCell>
                                ) : null}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {fields.map((row, index) => {
                                return (
                                  <TableRow
                                    key={row.Id}
                                  //   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell
                                      className={assesmentQustionStyle.score_cell}
                                      sx={{ verticalAlign: "top" }}
                                    >
                                      <Box
                                        sx={{
                                          width: "100%",
                                          height: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "top",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <Controller
                                          name={`assessmentQuestionaireRatings.${index}.Attribute_Response`}
                                          control={control}
                                          defaultValue={""}
                                          rules={{
                                            required: "Choice Field is Required",
                                            validate: (data: string | undefined) => {
                                              if (data === undefined || data.trim() === "") {
                                                return "Choice Field is Required";
                                              }
                                              let allFields = watch("assessmentQuestionaireRatings") || [];
                                              const choices = allFields.map(
                                                (field: any) => field?.Attribute_Response?.trim() || ""
                                              );
                                              const duplicateChoices = choices.filter(
                                                (choice: any) => choice === data.trim()
                                              );

                                              if (duplicateChoices.length > 1) {
                                                return "Duplicate Choices are not allowed.";
                                              }

                                              return true;
                                            },
                                          }}
                                          render={({ field }) => (
                                            <CustomTextField
                                              name={"Attribute Response"}
                                              required={true}
                                              readOnly={view || !isActiveDisabled}
                                              label={""}
                                              value={field.value}
                                              onChange={(e) => {
                                                field.onChange(e);
                                                trigger("assessmentQuestionaireRatings");
                                              }}
                                              error={Boolean(
                                                errorsAQMform?.assessmentQuestionaireRatings &&
                                                errorsAQMform?.assessmentQuestionaireRatings[index]
                                                  ?.Attribute_Response
                                              )}
                                              helperText={
                                                errorsAQMform?.assessmentQuestionaireRatings &&
                                                errorsAQMform?.assessmentQuestionaireRatings[index]
                                                  ?.Attribute_Response &&
                                                errorsAQMform?.assessmentQuestionaireRatings[
                                                  index
                                                ]?.Attribute_Response?.message?.toString()
                                              }
                                            />
                                          )}
                                        />
                                      </Box>
                                    </TableCell>
                                    <TableCell
                                      className={assesmentQustionStyle.score_cell}
                                      sx={{ verticalAlign: "top" }}
                                    >
                                      <Box
                                        sx={{
                                          width: "100%",
                                          height: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "top",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <Controller
                                          name={`assessmentQuestionaireRatings.${index}.Score`}
                                          control={control}
                                          defaultValue={0}
                                          rules={{
                                            required: "Score Field is Required",
                                            validate: (data: number | undefined) => {
                                              if (data === undefined || isNaN(data)) {
                                                return "Score Field is Required";
                                              }
                                              const number = parseInt(data.toString(), 10);
                                              let valuesFrom = 1;
                                              let valuesTo = fields.length > 0 ? fields.length : 1;
                                              const numberFrom = parseInt(valuesFrom?.toString() ?? "", 10);
                                              const numberTo = parseInt(valuesTo?.toString() ?? "", 10);
                                              if (isNaN(number) || number < 1) {
                                                return `Minimum Score should be at least 1`;
                                              }
                                              // Check if valuesFrom and valuesTo are valid numbers
                                              if (isNaN(numberFrom) || isNaN(numberTo)) {
                                                return "Please enter valid numbers for the scoring range.";
                                              }

                                              if (numberFrom > number || numberTo < number) {
                                                return "Please enter valid numbers for the scoring range.";
                                              }

                                              // Check for duplicate scores
                                              let allFields = watch("assessmentQuestionaireRatings") || [];
                                              const scores = allFields.map((field) => Number(field.Score));

                                              if (scores.filter((Score) => Score === number).length > 1) {
                                                return "Duplicate scores are not allowed.";
                                              }

                                              return true;
                                            },
                                          }}
                                          render={({ field }) => (
                                            <CustomNumberField
                                              name={"Attribute Response"}
                                              // required={true}
                                              readOnly={view || !isActiveDisabled}
                                              label={""}
                                              inputProps={{
                                                min: 1, // Set your desired minimum value here
                                              }}
                                              value={field.value}
                                              onChange={(RiskVal) => {
                                                field.onChange(RiskVal);
                                                clearAllScoreErrors(clearErrors, watch);
                                                SetDefaultScoringLow();
                                                trigger("assessmentQuestionaireRatings");
                                                // setSelectedRiskvalueset(RiskVal.target.value);
                                              }}
                                              error={Boolean(
                                                errorsAQMform?.assessmentQuestionaireRatings &&
                                                errorsAQMform?.assessmentQuestionaireRatings[index]?.Score
                                              )}
                                              helperText={
                                                errorsAQMform?.assessmentQuestionaireRatings &&
                                                errorsAQMform?.assessmentQuestionaireRatings[index]?.Score &&
                                                errorsAQMform?.assessmentQuestionaireRatings[
                                                  index
                                                ]?.Score?.message?.toString()
                                              }
                                            />
                                          )}
                                        />
                                      </Box>
                                    </TableCell>
                                    {!view ? (
                                      <TableCell
                                        className={assesmentQustionStyle.score_cell}
                                        sx={{ verticalAlign: "top" }}
                                      >
                                        {" "}
                                        <IconButton
                                          onClick={() => {
                                            deleteAttributes(index);
                                          }}
                                        >
                                          <CustomSvg name={"trash"} size={20} />
                                        </IconButton>
                                      </TableCell>
                                    ) : null}
                                  </TableRow>
                                );
                              })}
                              <TableRow>
                                {!view ? (
                                  <TableCell className={assesmentQustionStyle.score_cell} align="left">
                                    {" "}
                                    <CustomButton
                                      type="reset"
                                      variant="outlined"
                                      disabled={assessmentQuestionaireRatingsstop.length >= 4}
                                      name={"Add"}
                                      onClick={() => {
                                        addAtributes();
                                        clearErrors();
                                        // trigger("assessmentQuestionaireRatings");
                                        clearErrors(`assessmentQuestionaireRatings.${fields.length + 1}.Score`);
                                      }}
                                    />
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            </TableBody>
                          </Table>
                        )}
                      </Box>
                      <FormHelperText sx={{ color: "red", paddingRight: 4 }} id="my-helper-text">
                        {" "}
                        {AttriError}
                      </FormHelperText>
                    </Box>
                  </Grid>
                </Grid>

                {ismultipleEnable ? <Divider /> : null}
                {ismultipleEnable ? (
                  <Box sx={{ my: 2, width: "100%", display: "flex", justifyContent: "start" }}>
                    <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: 500 }}>
                      {`Applicable Risks`}
                    </Typography>
                  </Box>
                ) : null}
                {ismultipleEnable ? (
                  <Grid container spacing={2} sx={{ mb: 3 }}>
                    <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                      <Controller
                        name="ApplicableRisks"
                        control={control}
                        defaultValue={[]}
                        rules={{
                          required: "Please specify the applicable risk",
                          validate: (data: any) => {
                            return data.length <= 6 || "Not Exceed more than 6 Applicable Risk";
                          },
                        }}
                        render={({ field }) => (
                          <CustomMultiSelect
                            optionLabelKey="RiskCategory"
                            optionIdKey="RiskCategory_Id"
                            options={applicableRiskData}
                            required={true}
                            readOnly={view || !isActiveDisabled}
                            label={"Applicable Risk"}
                            value={field.value}
                            selectAll={true}
                            onChange={(RiskVal) => {
                              field.onChange(RiskVal.target.value);
                              SetDefaultScoringLow();
                              // setSelectedRiskvalueset(RiskVal.target.value);
                            }}
                            error={Boolean(errorsAQMform.ApplicableRisks)}
                            helperText={
                              errorsAQMform.ApplicableRisks && errorsAQMform.ApplicableRisks.message?.toString()
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                      <Typography variant="h6" sx={{ fontSize: "14px", textAlign: "left" }}>
                        {`Best rating is considered Low - ${findLowScoreofChoice()} for all applicable risks`}
                      </Typography>
                      <Typography
                        onClick={() => {
                          ChangeDetails(true);
                          SetDefaultScoringLow();
                        }}
                        variant="h6"
                        sx={{ fontSize: "14px", textAlign: "left", cursor: "pointer", color: "#1D6DE5" }}
                      >
                        Change Defaults
                      </Typography>
                    </Grid>
                  </Grid>
                ) : null}
                <Divider />
                <Box sx={{ my: 2, width: "100%", display: "flex", justifyContent: "start" }}>
                  <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {`Advanced`}
                  </Typography>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                    <Controller
                      name="Assessment_TypeID"
                      control={control}
                      defaultValue={[]}
                      rules={{ required: "Please select an assessment type" }}
                      render={({ field }) => (
                        <CustomMultiSelect
                          optionIdKey={"LovId"}
                          optionLabelKey="Options"
                          options={AssesmentTypeLV}
                          readOnly={view || !isActiveDisabled}
                          required={true}
                          label={"Assessment Type"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsAQMform.Assessment_TypeID)}
                          helperText={
                            errorsAQMform.Assessment_TypeID && errorsAQMform.Assessment_TypeID.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                    <Controller
                      name="Responder"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please specify the responder" }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"Responder"}
                          valueKey={"LovId"}
                          optionLabelKey="Options"
                          options={ResponderLV}
                          readOnly={view || !isActiveDisabled}
                          required={true}
                          label={"Responder"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsAQMform.Responder)}
                          helperText={errorsAQMform.Responder && errorsAQMform.Responder.message?.toString()}
                        />
                      )}
                    />
                  </Grid>

                  {IsfindSME() ? (
                    <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                      <Controller
                        name="SME"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Please provide the SME" }}
                        render={({ field }) => (
                          <CustomSelect
                            name={"SME"}
                            valueKey={"DepartmentId"}
                            optionLabelKey="DepartmentName"
                            options={SMELV}
                            readOnly={view || !isActiveDisabled}
                            required={true}
                            label={"Department"}
                            value={field.value}
                            onChange={field.onChange}
                            error={Boolean(errorsAQMform.SME)}
                            helperText={errorsAQMform.SME && errorsAQMform.SME.message?.toString()}
                          />
                        )}
                      />
                    </Grid>
                  ) : null}
                  {IsfindSME() ? (
                    <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={6} lg={6} xl={6}>
                      <Box sx={{ m: 2 }}>
                        <Controller
                          name="SMEVerified"
                          control={control}
                          defaultValue={false}
                          rules={{ required: IsfindSMEVerified() ? "SME verification is required" : "" }}
                          render={({ field }) => (
                            <CustomCheckBox
                              label="SME verification is needed"
                              isStyle={true}
                              readOnly={view || !isActiveDisabled}
                              value={field.value}
                              onChange={field.onChange}
                              error={Boolean(errorsAQMform.SMEVerified)}
                              helperText={errorsAQMform.SMEVerified && errorsAQMform.SMEVerified.message?.toString()}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              </Box>
              <Box
                sx={{
                  width: "40%",
                  padding: 2,
                  backgroundColor: "#F7F7F7",

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Typography variant="h6" sx={{ fontSize: "18px", textAlign: "left", color: "#909090" }}>
                    {`Preview`}
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "14px", textAlign: "left", fontWeight: 800 }}>
                    {watch("Question_Title")}
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "14px", textAlign: "left" }}>
                    {watch("Question")}
                  </Typography>

                  <Box>
                    {ismultipleEnable ? (
                      <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className={assesmentQustionStyle.score_cell}>Choices</TableCell>
                            <TableCell className={assesmentQustionStyle.score_cell}>Score</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ratingData &&
                            ratingData.map((row, index) => (
                              <TableRow
                                key={row.Id}
                              //   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell className={assesmentQustionStyle.score_cell} sx={{ verticalAlign: "top" }}>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "top",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {row.Attribute_Response}
                                  </Box>
                                </TableCell>
                                <TableCell className={assesmentQustionStyle.score_cell} sx={{ verticalAlign: "top" }}>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "top",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {row.Score}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <CustomTextField
                        label={"Answer"}
                        name={"Question Title"}
                        value={""}
                        disabled={true}
                        required={true}
                        onChange={() => console.log()}
                      //   error={Boolean(errorsAQMform.QuestionTitle)}
                      //   helperText={errorsAQMform.QuestionTitle && errorsAQMform.QuestionTitle.message?.toString()}
                      />
                    )}
                  </Box>
                  {watch("EvidenceRequired") ? (
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Typography variant="h6" sx={{ fontSize: "16px", textAlign: "left" }}>
                        {`Attachment `}
                      </Typography>
                      <CustomButton
                        variant="text"
                        name={"Attach File"}
                        valuePass={{ name: "2", id: 2 }}
                        onClick={() => console.log("sf")}
                        disabled={true}
                      />
                    </Box>
                  ) : null}
                  {ismultipleEnable ? (
                    <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
                      <Typography variant="h6" sx={{ fontSize: "16px", textAlign: "left" }}>
                        {`Applicable Risks`}
                      </Typography>
                      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", width: "100%", overflow: "hidden" }}>
                        {watch("ApplicableRisks").map((RiskId: any, index: any) => {
                          const risk = applicableRiskData.find((item) => item.RiskCategory_Id === RiskId);
                          return (
                            <React.Fragment key={index}>
                              <Typography variant="h6" sx={{ fontSize: "14px", textAlign: "left" }}>
                                {risk ? risk?.RiskCategory : ""}
                              </Typography>
                              {index !== watch("ApplicableRisks").length - 1 && (
                                <Divider orientation="vertical" flexItem />
                              )}
                            </React.Fragment>
                          );
                        })}
                      </Box>
                    </Box>
                  ) : null}

                  <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ fontSize: "16px", textAlign: "left" }}>
                      {`Advanced `}
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Typography variant="h6" sx={{ fontSize: "14px", textAlign: "left" }}>
                        {`Assessment - ${AssesmentTypeLV.find((item) => item.LovId === watch("Assessment_TypeID"))?.Options || ""
                          }`}
                      </Typography>
                      <Divider orientation="vertical" flexItem />
                      <Typography variant="h6" sx={{ fontSize: "14px", textAlign: "left" }}>
                        {`Responder - ${ResponderLV.find((item) => item.LovId === watch("Responder"))?.Options || ""}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}

          {active === 1 ? (
            <SearchServices updateSelectedServices={updateServices} selectedServices={unmappedService} />
          ) : null}
        </Stack>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "right",
            mt: 2,
          }}
        >
          {!view ? (
            <>
              <CustomButton
                type="reset"
                variant="outlined"
                name={"Cancel"}
                onClick={() => {
                  // onClose();
                  setchangeMaking(true);
                }}
              />
              {active != 0 && (
                <CustomButton
                  type="reset"
                  variant="outlined"
                  name={"Previous"}
                  onClick={() => {
                    dispatch(setTabs(active - 1));
                  }}
                />
              )}
              <CustomButton
                type="reset"
                name={showServiceMapping && active === 0 ? "Next" : edit ? "Update" : "Submit"}
                disabled={
                  (isDraftData?.DraftId ? false : !isActiveDisabled) || (active === 1 && unmappedService.length === 0)
                }
                onClick={showServiceMapping && active === 0 ? handleTabNavigation : handleAQMForm(handleSubmit)}
              />
            </>
          ) : (
            <CustomButton
              variant="outlined"
              name={"Cancel"}
              onClick={() => {
                onClose();
              }}
            />
          )}
        </Box>
      </Box>
      <CustomDialog
        show={ischangeDefault}
        onHide={() => onCloseWithoutSave()}
        maxWidth={"sm"}
        header={`Change Defaults`}
        contentNode={
          <>
            <Box>
              <Table sx={{ maxWidth: "1o0%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={assesmentQustionStyle.score_cell}>Applicable Risks</TableCell>
                    <TableCell className={assesmentQustionStyle.score_cell}>Low Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scoreandRiskList.map((item, index) => (
                    <TableRow
                      key={index + 1}
                    //   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell className={assesmentQustionStyle.score_cell} sx={{ verticalAlign: "top" }}>
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "top",
                            flexDirection: "row",
                          }}
                        >
                          <CustomCheckBox
                            label={item.RiskCategory}
                            isStyle={true}
                            value={item.Active}
                            readOnly={!item.Active}
                            onChange={() => console.log()}
                          />
                        </Box>
                      </TableCell>
                      <TableCell className={assesmentQustionStyle.score_cell} sx={{ verticalAlign: "top" }}>
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "top",
                            flexDirection: "row",
                          }}
                        >
                          <CustomSelect
                            name={"Question Group"}
                            valueKey={"Score"}
                            optionLabelKey="lable"
                            options={scoreList}
                            readOnly={view || !item.Active || !isActiveDisabled}
                            required={true}
                            label={"Low Score"}
                            value={item.Score}
                            onChange={(value) => onchangeDefaultScore(index, value)}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <Box sx={{ display: "flex", gap: 2, justifyContent: "right", mt: 1 }}>
                <CustomButton
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    onCloseWithoutSave();
                  }}
                />
                <CustomButton
                  disabled={!isDirty || !isActiveDisabled || !ischangelowscore}
                  variant="contained"
                  name={"Save"}
                  onClick={() => onCloseWithSave()}
                />
        
              </Box>
            </Box>
          </>
        }
      />
      <ModalPopUp
        onDeleteMethod={() => {
          if (onClose) {
            onClose();
          }
        }}
        isDelete={true}
        ActionName={"Yes"}
        onHide={() => {
          setchangeMaking(false);
        }}
        show={changeMaking}
        header={"Confirm"}
        text={"Are you sure you want to close ?"}
      />
      {/* <CustomDialog
        show={isDialogVisible}
        onHide={() => {
          setIsDialogVisible(false);
          setQuestion(null);
        }}
        maxWidth={"md"}
        header={"Add Question"}
        contentNode={
          question ? <RecordSearch Question={question} setQuestion={setQuestion} setRowData={setRowData} /> : null
        }
      /> */}
      {question ? (
        <RecordSearch
          Question={question}
          columns={RecordSearchColumns}
          categoryData={RecordSearchCategoryData}
          setQuestion={setQuestion}
          rowData={setRowData}
        />
      ) : null}

{timeout && (
              <InactivityTimer
                timeout={timeout}
                onTimeout={() => {}}
                renderPrompt={(isVisible, handleResponse) =>
                  isVisible && (
                    <ModalPopUp
                      onDeleteMethod={() => {}}
                      isDelete={false}
                      onHide={() => handleResponse(false)}
                      show={isVisible}
                      header={"Confirm"}
                      text={`Are you still editing?`}
                      ButtonName={"Yes"}
                    />
                  )
                }
              />
            )}
    </>
  );
};
