import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Login from "./components/login/Login";
import { Box, Theme } from "@mui/material";

import { RootState, useAppDispatchThunk, useAppSelector } from "./store/store";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { AppRoutes } from "./utils/AppRoutes";
import SandClockLoader from "./common/sandClockLoader/SandClockLoader";
import ADLogin from "./adLogin/AdLogin";
import { MsalProvider } from "@azure/msal-react";
import { useSelector } from "react-redux";
import ChangePassword from "./components/changePassword/ChangePassword";
import ToasterCompnent from "./toast/Toaster";
import { useEffect } from "react";
import { AqmResponse } from "./vendorPortal/aqmResponse/AqmResponse";
import axios from "axios";
import { VendorPortal } from "./vendorPortal/VendorPortal";
import PluginStepper from "./common/textEditor/plugin/PluginStepper/PluginStepper";
import ONLYOFFICEPlugin from "./common/textEditor/plugin/OnlyOfficePlugin";
import ContractTemplateList from "./components/contractManagement/ContractTemplate/ContractTemplateList";
import ViewContractTemplate from "./components/contractManagement/ContractTemplate/ViewContractTemplate";
import { WelcomeGreetings } from "./components/contractManagement/VendorPortal/welcomeGreetings/WelcomeGreetings";
import { ContractVendorPortal } from "./components/contractManagement/VendorPortal/ContractVendorPortal";
import { ContractPortalLoginPage } from "./components/contractManagement/VendorPortal/ContractPortalLoginPage";
import { PMVendorPortal } from "./vendorPortal/PMVendorPortal";
import { PMResponse } from "./components/performanceManagement/prReviews/PMResponse";
// import CustomFont from './common/fonts/aptos.ttf';

// commonText color ---------
let color_primary_text_white = "#ffff";
let color_primary_text_black = "#000";
// common background color ---------
let color_primary_background_white = "#ffff";
let color_primary_background_black = "#2e2e2e";
// // ----blue theme -------
// let color_primary = '#047fff'
// let color_secondary = "#e7f1ff"

// -----vilot theme ---------

export const color_primary = "#321149";
let color_primary_light = "#FDB913";
let color_secondary = "#5F2872";
let color_teritary = "#A967AB";
let color_teritary_sec = "#E6D5E9";
let color_grayScale = "#1F1F1";

//-----yellow theme ---------

let color_primary_dark = "#aeaeae";
// let color_secondary = "#ffdaa2"

// ----orange theme -------
// let color_primary = '#FF4500'
// let color_secondary = "#FFDC98"

function App({ instance }: any) {
  const { themeMode } = useSelector((state: RootState) => state.menu);
  const { isVendorAuthenticated, isAuthenticated } = useSelector((state: RootState) => state.loggerData);

  const dispatch = useAppDispatchThunk();
  // const navigate = useNavigate();
  const root = document.documentElement;
  root.style.setProperty("--primary-color", themeMode === "dark" ? color_primary_dark : color_primary);
  root.style.setProperty("--secondary-color", color_secondary);

  root.style.setProperty("--themeMode_background", themeMode === "dark" ? "#2e2e2e" : "#ffff");

  const outerTheme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 32,
            height: 18,
            padding: 0,
            display: "flex",
            margin: "0px 10px",
          },
          switchBase: {
            padding: 2,
            "&.Mui-checked": {
              transform: "translateX(16px)",
              color: color_primary,
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: color_primary_light,
              },
            },
            "&:active .MuiSwitch-thumb": {
              width: 16,
            },
            "&.Mui-checked.Mui-checked": {
              transform: "translateX(13px)",
            },
          },
          thumb: {
            boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
            width: 14,
            height: 14,
            borderRadius: 9,
            // transition: theme.transitions.create(["width"], {
            //   duration: 200,
            // }),
          },
          track: {
            borderRadius: 18 / 2,
            opacity: 1,
            backgroundColor: "rgba(0,0,0,.25)",
            boxSizing: "border-box",
            "&.Mui-checked": {
              backgroundColor: color_primary_light,
            },
          },
        },
      },

      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            padding: "0px",
            marginTop: "0px",
            minHeight: "10px",
            backgroundColor: "none", // Customize the background color
            transition: "box-shadow 0.3s ease",
            "&:before": {
              boxShadow: "none",
              position: "relative",

              top: "0px",
            },
            "&.Mui-expanded:after": {
              boxShadow: "none",
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
            backgroundColor: themeMode === "dark" ? color_primary_background_black : color_primary_background_white,
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: "#ff0000", // Red color for the asterisk
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            height: "16px",
            width: "16px", // Set the height of the checkbox
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root.Mui-focused": {
              boxShadow: "1px 0px 6px 0px rgba(0, 0, 0, 0.25)",
            },
          },
        },
      },

      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#6A6A6A",
              color: color_grayScale,
              // height :'40px'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? color_primary_dark : color_primary,
              borderWidth: "1px",
              color: "black",
            },
            "& .MuiInputLabel-root": {
              color: themeMode === "dark" ? "#6A6A6A" : "#1F1F1F",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: themeMode === "dark" ? color_primary_dark : "#6A6A6A",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              //  boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset",
              //boxShadow: "0px -51px 79px -113px rgba(0,0,0,0.25) inset",
            },
            // '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
            //   color: '#ff0000', // Set required symbol color to red
            // },

            "& .MuiInputAdornment-root.MuiInputAdornment-positionEnd": {
              color: "#ff0000", // Set required symbol color to red
            },
            "& .asterisk": {
              color: "red", // Set required symbol color to red
            },
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "outlined" },
            style: {
              textTransform: "none",
              height: "40px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "16px !important",
              minWidth: "100px",

              "&:hover": {
                backgroundColor: color_secondary,
                color: "#ffff",
              },
            },
          },
          {
            props: { variant: "outlined", size: "small" },
            style: {
              textTransform: "none",
              height: "32px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "14px !important",
              minWidth: "100px",

              "&:hover": {
                backgroundColor: color_secondary,
                color: "#ffff",
              },
            },
          },
          {
            props: { variant: "contained" },
            style: {
              textTransform: "none",
              height: "40px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "16px !important",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              minWidth: "100px",
              "&:hover": {
                backgroundColor: color_secondary,
              },
              "&:disabled": {
                color: "#ffff",
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              },
            },
          },
          {
            props: { variant: "contained", size: "small" },
            style: {
              textTransform: "none",
              height: "32px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "14px !important",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              minWidth: "100px",
              "&:hover": {
                backgroundColor: color_secondary,
              },
              "&:disabled": {
                color: "#ffff",
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              },
            },
          },
          {
            props: { variant: "text" },
            style: {
              textTransform: "none",
              height: "40px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "16px !important",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              backgroundColor: "#fff",
              minWidth: "100px",
              "&:hover": {
                backgroundColor: color_teritary_sec,
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              },
              "&:disabled": {
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              },
            },
          },
          {
            props: { variant: "text", size: "small" },
            style: {
              textTransform: "none",
              height: "32px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "14px !important",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              backgroundColor: "#fff",
              minWidth: "100px",
              "&:hover": {
                backgroundColor: color_teritary_sec,
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              },
              "&:disabled": {
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              },
            },
          },
        ],
      },
    },
    palette: {
      mode: themeMode as Theme["palette"]["mode"],
      primary: {
        main: themeMode === "dark" ? color_primary_dark : color_primary,
        light: color_primary_light,
        dark: color_teritary,
      },
      secondary: {
        main: color_secondary,
      },
      text: {
        primary: themeMode === "dark" ? color_primary_text_white : color_primary_text_black,
      },
      grey: {
        50: "#909090",
        100: "#6A6A6A",
        200: "#1D6DE5",
      },
      background: {
        paper: themeMode === "dark" ? "#2e2e2e" : "#ffff",
        default: themeMode === "dark" ? "#2e2e2e" : "#ffff",
      },
      error: {
        main: "#D70000",
      },
    },

    typography: {
      body1: {
        fontSize: "14px !important",
        lineHeight: "normal",
        letterSpacing: 0.8,
      },
      h6: {
        fontSize: "21px",
        // fontWeight: '700'
      },
      // fontFamily : 'aptos' ,
      fontSize: 14,
      fontFamily: "aptos",
    },
  });

  return (
    <Box className="App" sx={{ height: "100vh" }}>
      <ThemeProvider theme={outerTheme}>
        <MsalProvider instance={instance}>
          <SandClockLoader />
          <ToasterCompnent />

          {/* <ProgressBar/>  */}
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<AppRoutes />} />
              <Route path="/" element={<Login />} />
              <Route path="/adLogin/AdLogin" element={<ADLogin />} />
              <Route path="/Login" element={<Login />} />

              {/* -----------vendor user only link click ------------ */}
              <Route path="/vendor/:vendor_persional_Id/:Project_Details_Id" element={<VendorPortal />} />
              {/* -----------vendor portal Contract user only link click ------------ */}
              <Route path="/contract/:vendorId/:projectId/:workflowInstanceId" element={<ContractPortalLoginPage />} />
                 {/* -----------vendor portal PM user only link click ------------ */}
              <Route path="/pmrespondor/:vendor_persional_Id/:event_Id" element={<PMVendorPortal />} />
         
              {/* <Route path="/changepassword" element= {<ChangePassword/>}/> */}
              {/* -----------text Editor Plugin ------------ */}
              <Route path="/textEditor-plugin" element={<ONLYOFFICEPlugin />} />
              <Route path="/addExistingClass" element={<ViewContractTemplate />} />
              {/* <Route path="/vendorWorkFlow" element={<ContractVendorPortal />} /> */}
            </Routes>
          </BrowserRouter>
        </MsalProvider>
      </ThemeProvider>
    </Box>
  );
}

export default App;
