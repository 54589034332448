import { Box, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store/store";
import CustomSvg from "../CustomSvg";
interface InputProps {
    label: string;
}

const CustomSucessBanner = memo((props: InputProps) => {
  const {
    label
    
  } = props;
     return(
        <>
        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Box
            sx={{
              border: "1px solid gray",
              borderRadius: "10px",
              width: "500px",
              height: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "200px",
                width: "200px",
                backgroundColor: "#F8FAF5",
                borderRadius: "200px",
                margin: "0 auto",
                textAlign: "center",
              }}
            >
              {/* <CheckCircleIcon sx={{ fontSize: '48px', color: 'green' }} /> */}
              <Box sx={{ color: "green" }}>
                <CustomSvg name={"thumbs-up"} size={50} />
              </Box>
            </Box>

            <Typography variant="h6" sx={{ marginTop: "20px", width: "400px", textAlign: "center" }}>
            {label}
            </Typography>
          </Box>
        </Box>
        </>
     )
})

export default CustomSucessBanner;
