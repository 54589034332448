import { Box, Stack, Typography, Grid } from "@mui/material";
//import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { AddRegionalSettings } from "./AddRegionalSettings";
import { RootState, useAppDispatch } from "../../../store/store";
import { IMenu } from "../../../models/loggin.type";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import {
  deleteRegional,
  getRegionalHistory,
  getRegionalList,
  updateRegionalAddandEdit,
  updateVersionHistory,
} from "../../../store/slices/regionalSettingsData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import { getLocationList, getParentLocationList } from "../../../store/slices/locationMasterData";
import { HandleRecordLockCheck, recordLockGet, RecordLockUpdate } from "../../recordLock/RecordLock";
import { getApplicationParameterList } from "../../../store/slices/applicationParameterData";
import { FormatDate } from "../../applicationParameters/functions";
import { Edit_Enable_tosterMSG, FormIdConfig, LovConfig } from "../../../config/config";
import masterEditEnableService from "../../../store/services/masterEditEnableService";
import { getNotifications } from "../../../store/slices/notification";
import PageMainHeading from "../../../common/CommonHeadings/PageMainHeading";
import { getLovList } from "../../../store/slices/lovMasterData";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";


export interface RegionalSettingsProps {
  handlePrevious?: () => void;
  handleNext?: () => void;
  isWorkFlow?: boolean;
}

export const RegionalSettings: React.FC<RegionalSettingsProps> = ({ handlePrevious, handleNext, isWorkFlow }) => {
  const dispatch = useAppDispatch();
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [addForm, setAddForm] = useState(false);
  const [Option, setOption] = useState(" ");
  const [isEdit, setIsEdit] = useState(false);
  const [formDisplay, setformDisplay] = useState(false);
  const [filterTableData, setFilterTableData] = useState<any[]>([]);
  const [RegionalData, setRegionalData] = useState<any[]>([]);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const [selectedValue, setselectedValue] = useState(null);
  const [FilterData, setFilterData] = useState<any[]>([]);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [versionHistoryRows, setVersionHistoryRows] = useState<any>([]);
  const [OpenModalServerFilter, setOpenModalServerFilter] = React.useState(false);
  const [ParentLocationData, setParentLocationData] = useState<any[]>([]);

  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [parameterData, setParameterData] = useState<any>(applicationParameterData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { RegionalTable, isRegionalAdded, isRegionalDeleted, ToasterMsg, isLoading, versionHistory } = useSelector(
    (state: RootState) => state.RegionalSettingsData
  );

  const { currentpageTitleData } = useSelector(
    (state: RootState) => state.FormTitleMasterData
  );
  const filteredLovTableDataType = lovTable.filter((item: any) => item.Name === LovConfig.Data_Type);
  const { handleRecordLockUpdate } = RecordLockRelease();

  const columns_version: any = {
    table_name: "Regional History Records",
    isAction: false,
    columns: [],
  };

  const { ParentLocationTable } = useSelector((state: RootState) => state.locationMasterData);
  useEffect(() => {
    console.log(ParentLocationTable, "dataTable");

    setParentLocationData(ParentLocationTable);
  }, [ParentLocationTable]);


  const allOption = {
    Location_Id: 0,
    Location_Name: "All",
    
  };

console.log(currentpageTitleData, "currentpageTitleData");
    
const modifiedLovNames = [ ...ParentLocationTable];
   

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
  }, []);

  // useEffect(() => {
  //   if (parameterData && parameterData.length > 0) {
  //     parameterData.filter((data: any) => {
  //       if (data.Parameter_Group === currentPagePermissionData?.MenuName) {
  //         console.log(data, versionHistoryRows, "Parameter_Group");

  //         const dateFormat = data.Parameter_Value;

  //         if (versionHistoryRows && versionHistoryRows.length > 0) {
  //          const updatedRows = versionHistoryRows.map((row: any) => {
  //             if (row.UpdatedOn) {
  //               const formattedDate = FormatDate(row.UpdatedOn, dateFormat);
  //               console.log(formattedDate,"formattedDate")
  //               return { ...row, UpdatedOn: formattedDate };
  //             }
  //             return row;
  //           });
  //           setVersionHistoryRows(updatedRows);
  //         }
  //       }
  //     });
  //   }
  // }, [parameterData,versionHistoryRows]);

  const versionHistoryOnClick = () => {
    if (parameterData && parameterData.length > 0) {
      parameterData.filter((data: any) => {
        if (data.Parameter_Group === currentPagePermissionData?.MenuName) {
          console.log(data, versionHistoryRows, "Parameter_Group");

          const dateFormat = data.Parameter_Value;

          if (versionHistoryRows && versionHistoryRows.length > 0) {
            const updatedRows = versionHistoryRows.map((row: any) => {
              if (row.UpdatedOn) {
                const formattedDate = FormatDate(row.UpdatedOn, dateFormat);
                return { ...row, UpdatedOn: formattedDate };
              }
              return row;
            });
            console.log(updatedRows, "formattedDate");
            setVersionHistoryRows(updatedRows);
          }
        }
      });
    }
  };

  console.log(versionHistoryRows, "versionHistoryRows");

  const columns: any = {
    table_name: "Region",
    isAction: true,
    columns: [
      // {
      //     Field: "RegionalSettingId",
      //     DisplayName: "Regional Setting Id",
      //     FieldType: "string",
      // },
      {
        Field: "LocationName",
        DisplayName: "Country",
        FieldType: "string",
      },
      {
        Field: "Setting",
        DisplayName: "Setting",
        FieldType: "string",
      },
      {
        Field: "Equivalent",
        DisplayName: "Equivalent",
        FieldType: "string",
      },

      {
        Field: "DataTypeValue",
        DisplayName: "Data Type",
        FieldType: "string",
      },
      // {
      //   Field: "DataWidth",
      //   DisplayName: "Data Width",
      //   FieldType: "string",
      // },
      {
        Field: "IsMandatory",
        DisplayName: "Is Mandatory",
        FieldType: "string",
      },
      {
        Field: "Format",
        DisplayName: "Format",
        FieldType: "string",
      },
      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",
      },
      {
        Field: "UpdatedBy",
        DisplayName: "Modified By",
        FieldType: "string",
      },
    ],
  };

  useEffect(() => {
    dispatch(
      getApplicationParameterList({
        FormID: 2,
      })
    );
    dispatch(
      getRegionalList({
        FormId: 2,
      })
    );
  }, []);

  useEffect(() => {

    setRegionalData(RegionalTable);
  }, [RegionalTable]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    let locationTypeList = lovTable.filter((item: any) => item.Name == "Location Type");

    let countryTypeId: any = locationTypeList.find((item: any) => item.Options === "Country");
    if (countryTypeId && countryTypeId.LovId) {
      dispatch(getParentLocationList({ LocationtypeId: countryTypeId.LovId }));
    }
  }, [lovTable]);

  useEffect(() => {
    if (versionHistory.RegionVHeader.length > 0 && versionHistory.RegionVHeader.length > 0) {
      if (versionHistory.RegionVHeader && versionHistory.RegionVHeader.length > 0) {
        setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory.RegionVHeader,
        };
        setVersionHistoryData(re_columns);
        setVersionHistoryRows(versionHistory.RegionRecords);
      }
    }
  }, [versionHistory]);

  const handleOpen = async (data: any, Option: string) => {
   
    if (Option === "edit") {
    
      setIsEdit(true);
      setOption(Option);
      setIsEditData(data);

      // const response = await masterEditEnableService.GetMasterEditEnable({
      //   ItemID: data.RegionalSettingId,
      //   FormID: 11,
      // });
      // const Edit_data: any = response;
      // const canEdit = await HandleRecordLockCheck(props);
      // if (Edit_data?.data === true) {
      //   if (canEdit) {
        
      //     dispatch(updateLoader(false));
      //   }
       
      // } else {
      //   dispatch(updateLoader(false));
      //   dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));
      // }

      // setUserId(data.UserId);
    } else if (Option === "view") {
      console.log(data, Option, "Option");
      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
    } else if (Option === "history") {
      setOption(Option);
      await dispatch(
        getRegionalHistory({
          RegionalSettingId: data.RegionalSettingId,
          FormId: FormIdConfig?.RegionalSettingFormId,
        })
      );
      versionHistoryOnClick();
      // setIsVersionHistory(true);
    } else if (Option === "delete") {
      setOption(Option);
      setIsDeleteData(data);
    
    }
  };

  useEffect(() => {
    if (isRegionalAdded) {
      dispatch(updateRegionalAddandEdit(false));
      handleRecordLockUpdate();
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      dispatch(
        getRegionalList({
          LocationId: selectedValue,
        })
      );
      if (userLoginResponse) {
        dispatch(getNotifications(userLoginResponse?.UserId));
      }
    } else if (isRegionalDeleted) {
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      setTimeout(() => {
        dispatch(updateRegionalAddandEdit(false));
      }, 1000);
      dispatch(
        getRegionalList({
          LocationId: selectedValue,
        })
      );
    }
  }, [isRegionalAdded, isRegionalDeleted]);

  const deleteDepartmentList = () => {
    console.log(isDeleteData);
    let JsonDataForDisplay: any = {
      Setting: isDeleteData.Setting,
      Country: isDeleteData.LocationName,
      Equivalent: isDeleteData?.Equivalent || "",
      "Data Type": isDeleteData.DataTypeValue,
      // "Data Width": formData?.DataWidth || "",
      "Is Mandatory": isDeleteData.IsMandatory ? "Yes" : "No",
      Format: isDeleteData?.Format || "",
      Status: isDeleteData.IsActive === "Active" ? "Inactive" : "Active",
      "Modified By": user?.UserName,
    };

    // let formData = { ...isDeleteData };
    let formData: any = {};
    // setLoader(true);
    formData.IsActive = isDeleteData.IsActive === "Active" ? false : true;
    formData.UserId = userLoginResponse?.UserId;
    formData.Activity = isDeleteData.IsActive === "Active" ? "inactive" : "active";
    formData.MenuId = currentPagePermissionData?.MenuId;
    formData.Form_Id = 11;
    formData.RegionalSettingId = isDeleteData.RegionalSettingId;
    formData.Setting = isDeleteData.Setting;
    formData.ModuleName = "Regional Settings";
    formData.CreatedBy = userLoginResponse?.UserName;
    formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
    console.log(formData);

    dispatch(deleteRegional(formData));
  };

  const previous = () => {
    handlePrevious && handlePrevious();
  };

  const next = () => {
    // handleNext && handleNext();
  };

  const handleChange = (event: any) => {
    console.log(event, "event1");

    setselectedValue(event);
  };

  const handleServerFilter = () => {
    // if (selectedValue !== null) {
    //   // setFilterData(RegionalData.filter((item: any) => item.LovId === selectedValue) || []);
    //   console.log(selectedValue,"selectedValue");

    if (selectedValue !== null) {
      dispatch(
        getRegionalList({
          LocationId: selectedValue,
        })
      );
      setformDisplay(true);
    } else {
      // LocationId: 0;
       
      setOpenModalServerFilter(true);

    }
  };
 

  return (
    <>
      <Box
        sx={{
          width: "100%",
          // backgroundColor: "#ffff",
          // backgroundColor: "background.default",
          // px: 3,
          // py: 4,
          // borderRadius: "10px",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* <Box sx={{ display: "flex", width: "25%", }} >
                            <CustomSelect
                                name={"listName"}
                                valueKey={"id"}
                                optionLabelKey="lable"
                                options={location_Data}
                                value={formData.listName}
                                onChange={handleChange}
                                required={true}
                                label={"Select List Name"}
                            />
                        </Box> */}
            {/* <Typography variant="h6" color={"text.primary"}>
              {" "}
              {currentPagePermissionData?.MenuName}
            </Typography> */}
            <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                        </Typography>

          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Grid
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", width: "250px" }}>
<CustomSelect
                  name={"Location_Name"}
                  valueKey={"Location_Id"}
                  showAll={allOption}
                  showAllOption = {true}
                  optionLabelKey="Location_Name"
                  options={modifiedLovNames}
                  value={selectedValue}
                  onChange={handleChange}
                  disabled={false}
                  required={true}
                  label={"Country"}
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <CustomButton variant="contained" name={"Submit"} onClick={() => handleServerFilter()} size="small" />
              </Box>
            </Grid>
          </Box>
          <Box>
            {!formDisplay ? (
              ""
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <MaterialDynamicGrid
                  data={columns}
                  rows={RegionalData}
                  handleAction={handleOpen}
                  enableZoomIcon={true}
                  // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                  handleAdd={() => setAddForm(true)}
                  showAdd={permssionPage?.IsAdd === 1}
                  showhistory={true}
                  showDelete={permssionPage?.IsDelete === 1}
                  showView={permssionPage?.IsView === 1}
                  showEdit={permssionPage?.IsEdit === 1}
                  showExports={permssionPage?.IsExport === 1}
                  pageName="Regional Settings Master"
                   isEnableRecordLock = {{ IdField : "RegionalSettingId" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                                    FormID : FormIdConfig?.RegionalSettingFormId,
                                                  
                                                    MenuID : currentPagePermissionData?.MenuId,
                                                    IsCheckPending : true  }}}
                  userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                />
              </Box>
            )}
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Regional Setting"}`}
        contentNode={
          // <></>
          <AddRegionalSettings
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          if (Option === "edit") {
            handleRecordLockUpdate()
            setIsEdit(false);
          }else if(Option=="view"){
            setIsEdit(false);
          }
        }}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={Option === "view" ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Regional Settings"}` : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Regional Settings"}`}
        contentNode={
          <>
            {Option === "edit" && (
              <AddRegionalSettings
                view={false}
                closePopUp={() => {
                  handleRecordLockUpdate()
                  setIsEdit(false);
                }}
                edit={isEdit}
                isEditData={IsEditData}
              />
              // <></>
            )}
            {Option === "view" && (
              <AddRegionalSettings
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                  
                }}
                isEditData={IsEditData}
              />
            )}
          </>
        }
      />

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
          dispatch(updateVersionHistory([]));
        }}
        maxWidth={"lg"}
        header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Regional Settings"} Version History`}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistoryRows}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Regional Settings Version History"
            />
          </>
        }
      />

      <ModalPopUp
        onDeleteMethod={() => deleteDepartmentList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
          } the selected Regional`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />

      <ModalPopUp
        header="Company"
        text="Please Select a Country "
        onHide={() => {
          setOpenModalServerFilter(false);
        }}
        show={OpenModalServerFilter}
      />
  
    </>
  );
};
