import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import pendingActionsService from "../services/pendingActionsService";
export interface IPendingActionsData {
  PendingActionsdataTable: any;
  isPendingActionsLoading: boolean;
  isAddEditPendingActionsLoading: boolean;
  isPendingActionsAdded: boolean;
  isUpdatePrimaryUser: boolean;
  PendingActionsToasterMsg: string;
}

export const initialState: IPendingActionsData = {
  PendingActionsdataTable: [],
  isPendingActionsLoading: false,
  isAddEditPendingActionsLoading: false,
  isPendingActionsAdded: false,
  isUpdatePrimaryUser: false,
  PendingActionsToasterMsg: "",
};

export const getAllWorkFlowInstance = createAsyncThunk(
  "getAllWorkFlowInstance",
  async (_, { rejectWithValue }) => {
    try {
      const response = await pendingActionsService.getAllWorkFlowInstance();
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPendingActionsbyId = createAsyncThunk(
  "getPendingActionsbyId ",
  async (requesrParams: { userId: any }, { rejectWithValue }) => {
    try {
      const response = await pendingActionsService.getVendorList(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addPendingActions = createAsyncThunk(
  "addPendingActions",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response: any = await pendingActionsService.addVendor(requesrParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const UpdatePrimaryUser = createAsyncThunk(
  "UpdatePrimaryUser",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response: any = await pendingActionsService.UpdatePrimaryUser(requesrParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const pendingActionsDataSlice = createSlice({
  name: "pendingActions",
  initialState,
  reducers: {
    updatePendingActions: (state, action) => {
      state.isPendingActionsAdded = false;
      state.isUpdatePrimaryUser = false;
      state.PendingActionsToasterMsg = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWorkFlowInstance.pending, (state) => {
        state.isPendingActionsLoading = true;
      })
      .addCase(getAllWorkFlowInstance.fulfilled, (state, action: PayloadAction<any>) => {
        let value = [];
        try {
          const payloadData = action.payload.filter((data: any) => data.WorkflowProcessInstanceAsJson);
          value = payloadData
            .map((data: any) => {
              try {
                return JSON.parse(data.WorkflowProcessInstanceAsJson);
              } catch (error) {
                console.error("Error parsing JSON:", error);
                return [];
              }
            })
            .flat();
        } catch (error) {
          console.error("Error processing payload:", error);
        }
        
        state.PendingActionsdataTable = value;
        state.isPendingActionsLoading = false;
      })

      .addCase(getAllWorkFlowInstance.rejected, (state, action: PayloadAction<any>) => {
        state.isPendingActionsLoading = false;
      })
      .addCase(getPendingActionsbyId.pending, (state) => {
        state.isPendingActionsLoading = true;
      })
      .addCase(getPendingActionsbyId.fulfilled, (state, action: PayloadAction<any>) => {
        let value = [];
        try {
          let parsedvalue = JSON.parse(action.payload?.WorkflowProcessInstanceAsJson);
          value = parsedvalue;
        } catch {}
        state.PendingActionsdataTable = value;
        state.isPendingActionsLoading = false;
      })
      .addCase(getPendingActionsbyId.rejected, (state, action: PayloadAction<any>) => {
        state.isPendingActionsLoading = false;
      })
      .addCase(addPendingActions.pending, (state) => {
        state.isPendingActionsLoading = true;
        state.isAddEditPendingActionsLoading = true;
      })
      .addCase(addPendingActions.fulfilled, (state, action: PayloadAction<any>) => {
        // state.PendingActionsdataTable = [...state.PendingActionsdataTable , ...action.payload  ]
        state.isPendingActionsAdded = true;
        state.isPendingActionsLoading = false;
        state.isAddEditPendingActionsLoading = false;
        state.PendingActionsToasterMsg = action.payload.Message;
      })
      .addCase(addPendingActions.rejected, (state, action: PayloadAction<any>) => {
        state.isPendingActionsLoading = false;
        state.isAddEditPendingActionsLoading = false;
      })
      .addCase(UpdatePrimaryUser.pending, (state) => {
        state.isPendingActionsLoading = true;
        state.isAddEditPendingActionsLoading = true;
      })
      .addCase(UpdatePrimaryUser.fulfilled, (state, action: PayloadAction<any>) => {
        // state.PendingActionsdataTable = [...state.PendingActionsdataTable , ...action.payload  ]
        state.isUpdatePrimaryUser = true;
        state.isPendingActionsLoading = false;
        state.isAddEditPendingActionsLoading = false;
        state.PendingActionsToasterMsg = action.payload.Message;
      })
      .addCase(UpdatePrimaryUser.rejected, (state, action: PayloadAction<any>) => {
        state.isPendingActionsLoading = false;
        state.isAddEditPendingActionsLoading = false;
      });
  },
});

export const { updatePendingActions } = pendingActionsDataSlice.actions;
export default pendingActionsDataSlice.reducer;
