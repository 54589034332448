import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormHelperText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import BasicTableStyle from "../../../common/dynamicDataGrid/basicTable.module.scss";
import PreviewStyle from "./vendorpreview.module.scss";
import { AQMMappingDatadublicate } from "../../../mockData/mockData";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomCheckBox from "../../../common/dynamicInputs/CustomCheckBox";
import {
  ApplicationParameterName,
  AQMResponder,
  Default_threshold,
  LovConfig,
  vendorAssetStatus,
  VOBWorflowPages,
  WorkflowCondition,
} from "../../../config/config";
import CustomSvg from "../../../common/CustomSvg";
import { useTheme } from "@mui/material/styles";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import { AssessmentDetailContext } from ".";
import { AssessmentVendorDetailContext } from "../../../vendorPortal/aqmResponse/AqmResponse";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import CustomFileUploader from "../../../common/dynamicFileUploader/CustomFileUploader";
import { fileHandlerUpdate, UploadAFile } from "../../../store/slices/fileHandler";
import { updateLoader } from "../../../store/slices/loader";
import FilteViewer from "../../../common/fileViewer/FilteViewer";
import useValidation, {  checkVOBStepStatus } from "./AssessmentValidation";
import validateField from "./AssessmentValidation";
import fileHandlerService from "../../../store/services/fileHandlerService";
import * as mime from "react-native-mime-types";
import { getLovList } from "../../../store/slices/lovMasterData";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { getRiskRateList } from "../../../store/slices/riskRateMasterData";
import { updatethresholdforVendorAssessment } from "../../../store/slices/vendorAssessment";
import { findDefaultthreshold } from "./FinalAssesmentScoring";
import { BulkUploadQuestionVOBAssessment } from "./BulkUploadAndDownloadVOBAssessment/VOBAssessmentBulkDownloadQuestion";
import { BulkUploadAssessmentQuestionVOB } from "./BulkUploadAndDownloadVOBAssessment/VOBAssessmentBulkUploadQuestion";
import { ExportVOBAssessment } from "./BulkUploadAndDownloadVOBAssessment/VOBAssessmentTableDataExport";
type UserType = "vendor" | "SME" | "SO" | "DO" | "SMEE";
export interface VOBPreviewProps {
  IsUser: UserType;
  currentProcessData?: any;
  isPreviewMode?: any;
  processStepsList?: any;
  isEditData: any;
}

export const VOBPreview: React.FC<VOBPreviewProps> = ({
  IsUser,
  currentProcessData,
  isPreviewMode,
  processStepsList,
  isEditData,
}) => {
  const dispatch = useAppDispatch();
  const context = useContext(AssessmentDetailContext);
  const Vendorcontext = useContext(AssessmentVendorDetailContext);
  const theme = useTheme();
  const { themeMode } = useSelector((state: RootState) => state.menu);
  const { RiskRateDataActive } = useSelector((state: RootState) => state.RiskRateData);
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { isFileUpload, uploadData, isFileUploadLoading } = useSelector((state: RootState) => state.fileHandler);
  const { VendorAssessmentdata, thresholdforVendorAssessment, isVendorAssessmentthreshhold } = useSelector(
    (state: RootState) => state.vendorAssessmentData
  );

  const [PageName, setPageName] = useState<String>("");
  const [isBulkUpload, setIsBulkUpload] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
  });
  const [allExpanded, setAllExpanded] = useState(false);
  const [applicableRiskData, setApplicableRiskData] = useState<any[]>([]);
  const [ApproveList, setApproveList] = useState<any[]>([]);
  const [ResponderLV, setResponderLV] = useState<any[]>([]);
  const [isfileUpload, setIsfileUpload] = useState<boolean>(false);
  const [isfileUploadData, setIsfileUploadData] = useState<any>("");
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [isfilePreview, setIsfilePreview] = useState<boolean>(false);
  const [isfilePreviewurl, setIsfilePreviewurl] = useState<any>("");
  const [attachmentFile, setAttachmentFile] = useState<any>("");
  const [ThresholdData, setThresholdData] = useState<any>("");

  //------------
 
  //----------
  const [ShowriskanswerSection, setShowriskanswerSection] = useState(false);
  const [responseview, setresponseview] = useState<boolean>(false);
  const [iscompleteDo, setiscompleteDo] = useState<boolean>(false);
  const [isAvailableDo, setisAvailableDo] = useState<boolean>(false);
  const [isAvailableSME, setisAvailableSME] = useState<boolean>(false);
  const [isAvailableSMEReview, setisAvailableSMEReview] = useState<boolean>(false);

  useEffect(() => {
    if (!isPreviewMode && applicableRiskData && applicableRiskData.length > 0 && ThresholdData) {
      syncCalculation();
    }
  }, [applicableRiskData, ThresholdData, isPreviewMode]);

  useEffect(() => {
    console.log(currentProcessData , "currentProcessData" );
    if (currentProcessData) {
     
      
      setPageName(currentProcessData?.FormName);
      if(currentProcessData?.FormName  != VOBWorflowPages.VendorResponse && currentProcessData?.FormName !== VOBWorflowPages.SMEResponse && currentProcessData?.FormName !== VOBWorflowPages.SOReview){
        setresponseview(true)
      }
    }
  }, [currentProcessData]);

  const Defaultthreshold = useMemo(() => {
    return applicationParameterData ? findDefaultthreshold(applicationParameterData) : "";
  }, [applicationParameterData]);

  useEffect(() => {
    const getData = isEditData && isEditData?.ThresholdValue ? isEditData?.ThresholdValue : Defaultthreshold;
    dispatch(updatethresholdforVendorAssessment({ value: getData }));
  }, [applicationParameterData, isEditData, Defaultthreshold]);

  useEffect(() => {
    const fetchStepStatus = async () => {
      if (processStepsList) {
        const obje: any = await checkVOBStepStatus(processStepsList);
        setiscompleteDo(Boolean(obje?.Do_Status));
        setisAvailableDo(Boolean(obje?.Do));
        setisAvailableSME(Boolean(obje?.SME));
        setisAvailableSMEReview(Boolean(obje?.SME_Review));
      }
    };

    fetchStepStatus();
  }, [processStepsList]);

  useEffect(() => {
    if (thresholdforVendorAssessment) {
      setThresholdData(thresholdforVendorAssessment);
    }
  }, [thresholdforVendorAssessment]);

  // vendor: 1,
  // SME: 2,
  // SO: 3,
  // DO: 4,
  // SMEE: 5,
  // Cxo: 6,
 

  useEffect(() => {
    if (RiskRateDataActive) {
      setApplicableRiskData(RiskRateDataActive || []);
    }
  }, [RiskRateDataActive]);

  const onchangeAnserSectionShow = () => {
  
    setShowriskanswerSection((pre) => !pre);
  };

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
    dispatch(
      getRiskRateList({
        FormID: 2,
      })
    );
  }, []);
  useEffect(() => {
    toggleAllExpandFirst();
  }, []);

  useEffect(() => {
    setApproveList(lovTable.filter((item: any) => item.Name === LovConfig?.Pre_Approval) || []);
    setResponderLV(lovTable.filter((item: any) => item.Name === LovConfig?.AQ_Responder) || []);
  }, [lovTable]);

  useEffect(() => {}, [isFileUploadLoading]);
  useEffect(() => {
    dispatch(updateLoader(isFileUploadLoading));
  }, [isFileUploadLoading]);

  useEffect(() => {
    if (isFileUpload && isfileUploadData && isfileUploadData?.cat_index >= 0) {
      handleCommonValueChange(isfileUploadData?.cat_index, isfileUploadData.quest_index, uploadData, "Attachment");
      setIsfileUploadData(null);
      setIsfileUpload(false);
      dispatch(fileHandlerUpdate(false));
    }
  }, [isFileUpload]);

  if (
    (!context && (IsUser === "SME" || IsUser === "SO" || IsUser === "DO")) ||
    (!Vendorcontext && IsUser === "vendor")
  ) {
    return null;
  }

  const findResponder = (Res_id: any) => {
    return ResponderLV.find((item) => item.LovId == Res_id)?.Options || "";
  };

  const onButtonClick = async (bloburl: any) => {
    if (bloburl) {
      const fileName = decodeURIComponent(bloburl.substring(bloburl.indexOf("_") + 1));
      const response: any = await fileHandlerService.DownLoadAFile({ fileUrl: bloburl });
      const { data } = response;
      const mimeType = mime.lookup(fileName.substring(fileName.lastIndexOf(".") + 1));
      setFileName(fileName as string);
      setIsfilePreviewurl(`data:${mimeType};base64,${data}`);
      setIsfilePreview(true);
    }
  };

  const { selectedCatagoryPreview, validationErrors, setselectedCatagoryPreview, setvalidationErrors } =
    IsUser === "vendor" ? Vendorcontext! : context!;

  const handleAccordionChange = (index: number) => {
    setExpanded((prevExpanded) => ({ ...prevExpanded, [index]: !prevExpanded[index] }));
  };

  const statusCheck = (id: any) => {
    return ApproveList.find((item) => item.LovId == id)?.Options || "";
  };

  const toggleAllExpandFirst = () => {
    if (selectedCatagoryPreview && selectedCatagoryPreview.length > 0) {
      const newExpandedState: { [key: number]: boolean } = {};
      selectedCatagoryPreview.forEach((_: any, index: any) => {
        newExpandedState[index] = true;
      });
      setExpanded(newExpandedState);
    }
  };

  const toggleAll = () => {
    const newState = !allExpanded;
    setAllExpanded(newState);
    const newExpandedState: { [key: number]: boolean } = {};
    selectedCatagoryPreview.forEach((_: any, index: any) => {
      newExpandedState[index] = newState;
    });
    setExpanded(newExpandedState);
  };

  const validatecatagory = (item: any) => {
    return null;
  };

  const handleSubmitBulkAssessmentQuestion = (data: any) => {
    setselectedCatagoryPreview(data);
    syncCalculation();
    setIsBulkUpload(false);
  };

  const catagoryScoreChangeOverall = (item: any, questionIndex: any, catagoryIndex: any, field: any) => {
    setselectedCatagoryPreview((prevData: any) => {
      let newData = [...prevData];
      let category = { ...newData[catagoryIndex] };
      let question = { ...category.Questions[questionIndex] };
      question.ChoicesScore = item;
      question.ApplicableRisksScore = question.ApplicableRisksScore.map((risk: any) => {
        return { ...risk, Score: item };
      });
      category.Questions[questionIndex] = question;
      newData[catagoryIndex] = category;
      return newData;
    });
    setvalidationErrors(validateField(catagoryIndex, questionIndex, field, item, validationErrors));
    syncCalculation();
  };

  const catagoryScoreChange = (item: any, questionIndex: any, riskId: any, catagoryIndex: any) => {
    setselectedCatagoryPreview((prevData: any) => {
      let newData = [...prevData];
      let category = newData[catagoryIndex];
      let question = category.Questions[questionIndex];
      let riskScore = question.ApplicableRisksScore.find((risk: any) => risk.RiskId === riskId);
      if (riskScore) {
        riskScore.Score = item;
      } else {
        console.error(`Risk ID ${riskId} not found in ApplicableRisksScore`);
      }

      return newData;
    });
    syncCalculation();
  };

  const handleCommonValueChange = (categoryIndex: any, questionIndex: any, newValue: any, field: string) => {
    setselectedCatagoryPreview((prevData: any) => {
      const newData = [...prevData];
      newData[categoryIndex].Questions[questionIndex][field] = newValue;
      return newData;
    });

    setvalidationErrors(validateField(categoryIndex, questionIndex, field, newValue, validationErrors));
  };

  const giveCalculationValue = (id: any) => {
    try {
      let findRiskWeightage = applicableRiskData.find((item: any) => item?.RiskCategory_Id == id)?.Weightage || "";

      if (findRiskWeightage && findRiskWeightage != undefined && findRiskWeightage != null) {
        return findRiskWeightage || 0;
      } else {
        return 0;
      }
    } catch {
      return 0;
    }
  };

  const syncCalculation = () => {
    let updatedValue = selectedCatagoryPreview.map((item: any) => {
      if (item.Questions) {
        item.Questions = item.Questions.map((q_item: any, index: any) => {
          if (q_item.Answer_Type === true) {
            let lowScoreValue = 0;
            let actualScoreValue = 0;

            q_item.ApplicableRisks.forEach((risk_item: any) => {
              let RiskDefaultValue = giveCalculationValue(risk_item);

              let lowScore =
                q_item?.ApplicableRisk_LowScore.find((selChoice: any) => selChoice.RiskId == risk_item)?.Score || 0;
              let actualScore =
                q_item?.ApplicableRisksScore.find((selChoice: any) => selChoice.RiskId == risk_item)?.Score || 0;

              lowScoreValue = lowScoreValue + Number(lowScore) * Number(RiskDefaultValue);

              actualScoreValue = actualScoreValue + Number(actualScore) * Number(RiskDefaultValue);
            });

            // lowscore mean best score

            let lowScoreValuewithwtg = (lowScoreValue * Number(q_item.Ques_Wtg)) / 100;
            let actualScoreValuewithwtg = (actualScoreValue * Number(q_item.Ques_Wtg)) / 100;

            lowScoreValuewithwtg = Number(Math.floor(lowScoreValuewithwtg * 100) / 100);
            // actualScoreValuewithwtg =  Number(actualScoreValuewithwtg.toFixed(2)) ;
            actualScoreValuewithwtg = Number(Math.floor(actualScoreValuewithwtg * 100) / 100);

            let finalValue = actualScoreValuewithwtg - lowScoreValuewithwtg;
            // console.log( finalValue , 'finalValue 2' );
            let percentage: any = 0;
            if (lowScoreValuewithwtg === 0) {
              percentage = 0;
            } else {
              percentage = (finalValue / lowScoreValuewithwtg) * 100;
            }
            // let percentage : any = (finalValue / lowScoreValuewithwtg) * 100;
            // console.log( percentage , 'finalValue 3');
            let final_To_DP = q_item.IsAddInDo;

            if (percentage >= ThresholdData) {
              final_To_DP = true;
            } else {
              final_To_DP = false;
            }

            // console.log("best Score : " + lowScoreValue + "; best Value : " + lowScoreValuewithwtg);
            // console.log("Actual Score : " + actualScoreValue + "; Actual Value : " + actualScoreValuewithwtg);
            // console.log("finalValue: " + finalValue);
            // console.log("percentage: " + percentage);

            return {
              ...q_item,
              Best_Score: lowScoreValuewithwtg,
              Actual_Score: actualScoreValuewithwtg,
              Deviation: percentage.toFixed(2),
              IsAddInDo: final_To_DP,
            };
          }

          return q_item;
        });
      }

      return item;
    });

    setselectedCatagoryPreview(updatedValue);
  };

  const fileUploadClickOpen = (cat_index: any, quest_index: any) => {
    setIsfileUpload(true);
    setIsfileUploadData({ cat_index: cat_index, quest_index: quest_index });
    setAttachmentFile("");
  };
  const fileUploadClickClose = () => {
    setIsfileUpload(false);
    setIsfileUploadData(null);
    setAttachmentFile("");
  };

 


  const SelectedFileUpload = (data: any) => {
    if (data) {
      setAttachmentFile(data);
    }
  };

  const uploadFile = () => {
    if (attachmentFile) {
      const blob = attachmentFile.pop();
      let formData = new FormData();
      formData.append("fileUploadRequest", blob);
      dispatch(UploadAFile(formData));
    }
  };

  const expandedKeys = Object.keys(expanded).filter((key) => expanded[Number(key)]);

  const headers = [
    {
      label: "Questions",
      condition: true,
      Field : "Question",
      sticky: true,
      minWidth: "350px",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          Questions
          <IconButton sx={{ padding: "0px" }} onClick={() => toggleAll()}>
            {expandedKeys?.length === selectedCatagoryPreview?.length ? (
              <CustomSvg color={theme.palette.text.secondary} size="20px" name="chevron-down" />
            ) : (
              <CustomSvg color={theme.palette.text.secondary} size="20px" name="chevron-up" />
            )}
          </IconButton>
        </Box>
      ),
    },
    {
      label: "Best Score for all Risk",
      Field : "Best_Score",
      condition:
        (PageName !== VOBWorflowPages.VendorResponse && PageName !== VOBWorflowPages.SMEResponse) || isPreviewMode,
      minWidth: "200px",
     
    },
    {
      label: "Response",
      condition: true,
      Field : "response",
      minWidth: "300px",
      renderHeader: () => (
       (PageName !== VOBWorflowPages.VendorResponse && PageName !== VOBWorflowPages.SMEResponse) || isPreviewMode ? <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          Response
          <IconButton sx={{ padding: "0px" }} onClick={() => onchangeAnserSectionShow()}>
            <CustomSvg color={theme.palette.text.secondary} size="20px" name="chevrons-right" />
          </IconButton>
        </Box> : <> {`Response`} </>
      ),
    },
    ...applicableRiskData.map((risk_item) => ({
      label: risk_item?.RiskCategory,
      condition: ShowriskanswerSection,
      minWidth: "150px",
    })),
    {
      label: "Actual Score",
      Field : "Actual_Score",
      condition:
        (PageName !== VOBWorflowPages.VendorResponse && PageName !== VOBWorflowPages.SMEResponse) || isPreviewMode,
      minWidth: "150px",
    },
    {
      label: "Deviation",
      Field : "Deviation",
      condition:
        (PageName !== VOBWorflowPages.VendorResponse && PageName !== VOBWorflowPages.SMEResponse) || isPreviewMode,
      minWidth: "100px",
    },
    {
      label: "Attachment",
      Field : "Attachment",
      condition: true,
      minWidth: "150px",
    },
    {
      label: "Responder",
      Field : "Responder",
      condition:
        (PageName !== VOBWorflowPages.VendorResponse && PageName !== VOBWorflowPages.SMEResponse) || isPreviewMode,
      minWidth: "200px",
    },
    {
      label: "SME Review Status",
      Field : "SME_ReviewStatus_value",
      condition:
        (PageName !== VOBWorflowPages.VendorResponse &&
          PageName !== VOBWorflowPages.SMEResponse &&
          isAvailableSMEReview) ||
        isPreviewMode,
      minWidth: "150px",
    },
    {
      label: "SME Remarks",
      Field : "SME_Remarks",
      condition:
        (PageName !== VOBWorflowPages.VendorResponse &&
          PageName !== VOBWorflowPages.SMEResponse &&
          isAvailableSMEReview) ||
        isPreviewMode,
      minWidth: "300px",
    },
    {
      label: "SO Remarks",
      Field : "SO_Remarks",
      condition: PageName == VOBWorflowPages.SOReview || PageName == VOBWorflowPages.DoReview || isPreviewMode,
      minWidth: "300px",
    },
    {
      label: "DO Review Status",
      Field : "DO_ReviewStatus_value",
      condition:
        PageName === VOBWorflowPages.DoReview ||
        (PageName === VOBWorflowPages.SOReview && iscompleteDo) ||
        isPreviewMode,
      minWidth: "150px",
    },
    {
      label: "Action",
      Field : "DO_Remarks",
      condition:
        PageName === VOBWorflowPages.DoReview ||
        (PageName === VOBWorflowPages.SOReview && iscompleteDo) ||
        isPreviewMode,
      minWidth: "300px",
    },
    {
      label: "Send To DO",
      Field : "IsAddInDo_value",
      condition: (PageName === VOBWorflowPages.SOReview && isAvailableDo) || isPreviewMode,
      minWidth: "150px",
    },
    {
      label: "Send it for PM?",
      Field : "IsAddInPM_value",
      condition:
        (PageName === VOBWorflowPages.SOReview && iscompleteDo) ||
        PageName === VOBWorflowPages.DoReview ||
        isPreviewMode,
      minWidth: "150px",
    },
  ];

  return (
    <>
      {PageName && selectedCatagoryPreview && selectedCatagoryPreview?.length > 0 ? (
        <>
          
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingY: 2, gap: 2 }}>
            {IsUser === "vendor" ? (  <> <CustomButton
                type="reset"
                variant="text"
                startIcon={<CustomSvg color={`${theme.palette.primary.main}`} size={"16px"} name={"import"} />}
                name={"Upload Response"}
                onClick={() => setIsBulkUpload(true)}
              />
              <BulkUploadQuestionVOBAssessment AssessmentData={selectedCatagoryPreview} />
              </> ) : <> <ExportVOBAssessment ResponderLV={ResponderLV} applicableRiskData={applicableRiskData} ApproveList={ApproveList} header={headers} AssessmentData={selectedCatagoryPreview} /></>}
            </Box>
          
          {/* -------------------- tab1 ---------------- */}
          <Box className={BasicTableStyle.tab_outer} sx={{ width: "100%", overflowX: "auto" }}>
            <Table sx={{ width: "100%", overflowX: "scroll" }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{borderBottom :'1px solid gray'}}>
                  {headers
                    .filter((header: any) => header.condition)
                    .map((header: any, index: any) => (
                      <TableCell
                        key={index}
                        sx={{
                          minWidth: header.minWidth,
                          position: header.sticky ? "sticky" : "static",
                          left: header.sticky ? 0 : "auto",
                          backgroundColor:  theme.palette.background.paper ,
                          zIndex: header.sticky ? "4 !important" : "auto",
                        }}
                        className={`${BasicTableStyle.tab_header} ${PreviewStyle.headerWidth}`}
                      >
                        {header.renderHeader ? header.renderHeader() : header.label}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {selectedCatagoryPreview.map((item: any, index: number) => {
                  const hasError = validatecatagory(item) !== null;
                  const isItemChanged = item?.onChange && item?.onChange === true;
                  const showError = (hasError && isItemChanged) || hasError;

                  return  (
                    <React.Fragment key={`${index}-ParentRow`}>
                      {/* Parent Row */}
                      <TableRow>
                        {headers
                          .filter((header: any) => header.condition)
                          .map((header: any, colIndex) => (
                            <TableCell
                              key={`${index}-${colIndex}`}
                              sx={{
                                minWidth: header.minWidth,
                                padding :'10px !important',
                                ...(header.sticky && {
                                  position: "sticky",
                                  left: 0,
                                  backgroundColor: theme.palette.background.paper,
                                  zIndex: 1,
                                }),
                              }}
                            >
                              {header.label === "Questions" ? (
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                  {item.Options}
                                  <IconButton sx={{padding :'0px !important'}} onClick={() => handleAccordionChange(index)}>
                                    {!!expanded[index] ? (
                                      <CustomSvg color={theme.palette.text.secondary} size="20px" name="chevron-down" />
                                    ) : (
                                      <CustomSvg color={theme.palette.text.secondary} size="20px" name="chevron-up" />
                                    )}
                                  </IconButton>
                                </Box>
                              ) : null /* Placeholder for other parent-specific content */}
                            </TableCell>
                          ))}
                      </TableRow>

                      {/* Sub-rows (Questions) */}
                      {!!expanded[index] &&
                        item.Questions.map((q_item: any, q_index: number) =>
                        
                            <TableRow
                              key={`${index}-${q_index}-SubRow`}
                              sx={{
                                backgroundColor: themeMode === "dark" ? theme.palette.background.default : "#F7F7F7",
                              }}
                            >
                           {headers
        .filter((header) => header.condition)
        .map((header: any, colIndex) => {
          // Define variables before returning JSX
          const SME_Status = statusCheck(q_item?.SME_ReviewStatus);
          const DO_Status = statusCheck(q_item?.DO_ReviewStatus);

          return (
                                  <TableCell
                                    className={` ${PreviewStyle.headerWidth} ${BasicTableStyle.tab_data} ${
                                      header.label == "SME Review Status" ? SME_Status == vendorAssetStatus.approve 
                                        ? PreviewStyle.approveSec
                                        : SME_Status == vendorAssetStatus.reject
                                        ? PreviewStyle.rejectedSec
                                        : "" : ""
                                    } ${
                                      header.label == "DO Review Status" ? DO_Status == vendorAssetStatus.approve 
                                        ? PreviewStyle.approveSec
                                        : DO_Status == vendorAssetStatus.reject
                                        ? PreviewStyle.rejectedSec
                                        : "" : ""
                                    }`}
                                    key={`${index}-${q_index}-${colIndex}`}
                                    sx={{
                                      minWidth: header.minWidth,
                                      padding :'8px !important',
                                      ...(header.sticky && {
                                        position: "sticky",
                                        left: 0,
                                        backgroundColor:
                                          themeMode === "dark" ? theme.palette.background.default : "#F7F7F7",
                                        zIndex: 2,
                                      }),
                                    }}
                                  >
                                    {(() => {
                                      switch (header.label) {
                                        case "Questions":
                                          return ( <Box sx={{ml:3 , display:'flex'  , justifyContent:'left' , textAlign:'left'}}>
                                            {q_item.Question}
                                          </Box>) ;
                                        case "Best Score for all Risk":
                                          return <>{q_item?.Best_Score}</>;

                                        case "Response":
                                          return (
                                          <>
                                           { q_item?.Answer_Type == false ? (
                                              <CustomMultilineTextField
                                                key={`${index + 1 + q_index} - Questions ChoicesScore freetext`}
                                                label={""}
                                                readOnly={responseview || isPreviewMode}
                                                rows={0}
                                                maxRows={4}
                                                value={q_item?.Response_Received}
                                                onChange={(value) =>
                                                  handleCommonValueChange(index, q_index, value, "Response_Received")
                                                }
                                                error={Boolean(
                                                  validationErrors[`${index}-${q_index}-Response_Received`]
                                                )}
                                                helperText={validationErrors[`${index}-${q_index}-Response_Received`]}
                                              />
                                            ) : (
                                              <CustomSelect
                                                key={`${index + 1 + q_index} - Questions ChoicesScore multichoice`}
                                                name={""}
                                                valueKey={"Score"}
                                                optionLabelKey="Attribute_Response"
                                                options={q_item?.choices}
                                                required={true}
                                                label={""}
                                                readOnly={responseview || isPreviewMode}
                                                value={q_item?.ChoicesScore}
                                                onChange={(value) =>
                                                  catagoryScoreChangeOverall(value, q_index, index, "ChoicesScore")
                                                }
                                                error={Boolean(validationErrors[`${index}-${q_index}-ChoicesScore`])}
                                                helperText={validationErrors[`${index}-${q_index}-ChoicesScore`]}
                                              />
                                            ) 
                                    }
                                            </>);
                                        case applicableRiskData.find(
                                          (Risk_item) => Risk_item?.RiskCategory == header.label
                                        )?.RiskCategory:
                                          let Risk_value: any = applicableRiskData.find(
                                            (Risk_item) => Risk_item?.RiskCategory == header.label
                                          );
                                          return (
                                            <Box>
                                              {q_item?.Answer_Type === true &&
                                              q_item?.ApplicableRisks.includes(Risk_value?.RiskCategory_Id) ? (
                                                <>
                                                  {
                                                    q_item?.choices.find(
                                                      (score_item: any) => score_item.Score === q_item?.ChoicesScore
                                                    )?.Attribute_Response
                                                  }
                                                </>
                                              ) : (
                                                "-"
                                              )}
                                            </Box>
                                          );

                                        case "Actual Score":
                                          return q_item?.Actual_Score;

                                        case "Deviation":
                                          return (
                                            <>
                                              {q_item?.Deviation}
                                              {" %"}
                                            </>
                                          );

                                        case "Attachment":
                                          return (
                                            <Box>
                                              <Box
                                                sx={{ display: "flex", width: "100%", justifyContent: "space-evenly" }}
                                              >
                                                {PageName == VOBWorflowPages?.VendorResponse ||
                                                PageName == VOBWorflowPages?.SOReview ||
                                                PageName == VOBWorflowPages?.SMEResponse ? (
                                                  <Tooltip title="Upload">
                                                    <IconButton
                                                      disabled={isPreviewMode}
                                                      onClick={() => fileUploadClickOpen(index, q_index)}
                                                    >
                                                      <CustomSvg
                                                        color={`${theme.palette.text.secondary}`}
                                                        size={"20px"}
                                                        name={"upload"}
                                                      />{" "}
                                                    </IconButton>
                                                  </Tooltip>
                                                ) : null}
                                                <Tooltip title="View">
                                                  <IconButton
                                                    onClick={() => {
                                                      onButtonClick(q_item?.Attachment);
                                                    }}
                                                    disabled={!q_item?.Attachment}
                                                  >
                                                    <CustomSvg
                                                      color={
                                                        !q_item?.Attachment
                                                          ? `${theme.palette.text.secondary}`
                                                          : `${theme.palette.info.main}`
                                                      }
                                                      size={"20px"}
                                                      name={"view"}
                                                    />{" "}
                                                  </IconButton>
                                                </Tooltip>

                                                {PageName == VOBWorflowPages?.VendorResponse ||
                                                PageName == VOBWorflowPages?.SOReview ||
                                                PageName == VOBWorflowPages?.SMEResponse ? (
                                                  <Tooltip title="Delete">
                                                    <IconButton
                                                      disabled={isPreviewMode}
                                                      onClick={() =>
                                                        handleCommonValueChange(index, q_index, "", "Attachment")
                                                      }
                                                    >
                                                      <CustomSvg
                                                        color={`${theme.palette.text.secondary}`}
                                                        size={"20px"}
                                                        name={"delete"}
                                                      />{" "}
                                                    </IconButton>
                                                  </Tooltip>
                                                ) : null}
                                              </Box>
                                              <FormHelperText
                                                error={Boolean(validationErrors[`${index}-${q_index}-Attachment`])}
                                                id="my-helper-text"
                                              >
                                                {validationErrors[`${index}-${q_index}-Attachment`]}
                                              </FormHelperText>
                                            </Box>
                                          );
                                        case "Responder":
                                          return (
                                            <>
                                              {" "}
                                              {findResponder(q_item?.Responder)}
                                              {findResponder(q_item?.Responder) === AQMResponder?.SME
                                                ? ` [${q_item?.SME_Name}]`
                                                : ""}
                                            </>
                                          );
                                        case "SME Review Status":
                                          return (
                                            <Box
                                              key={`${index + 1 + q_index} - Questions SME Review section`}
                                              className={` `}
                                            >
                                              {PageName == VOBWorflowPages?.SMEReview ||
                                              PageName == VOBWorflowPages?.SOReview ? (
                                                <CustomSelect
                                                  name={""}
                                                  key={`${index + 1 + q_index} - Questions SME Review`}
                                                  valueKey={"LovId"}
                                                  optionLabelKey="Options"
                                                  options={ApproveList}
                                                  required={true}
                                                  label={""}
                                                  readOnly={isPreviewMode}
                                                  value={q_item?.SME_ReviewStatus}
                                                  onChange={(value) =>
                                                    handleCommonValueChange(index, q_index, value, "SME_ReviewStatus")
                                                  }
                                                  error={Boolean(
                                                    validationErrors[`${index}-${q_index}-SME_ReviewStatus`]
                                                  )}
                                                  helperText={validationErrors[`${index}-${q_index}-SME_ReviewStatus`]}
                                                />
                                              ) : (
                                                <Box>{SME_Status}</Box>
                                              )}
                                            </Box>
                                          );

                                        case "SME Remarks":
                                          return (
                                            <>
                                              {PageName == VOBWorflowPages?.SMEReview ? (
                                                <CustomMultilineTextField
                                                  // name={"Answer"}
                                                  key={`${index + 1 + q_index} - Questions SME Remark`}
                                                  rows={0}
                                                  maxRows={4}
                                                  required={true}
                                                  label={""}
                                                  readOnly={isPreviewMode}
                                                  value={q_item?.SME_Remarks}
                                                  onChange={(value) =>
                                                    handleCommonValueChange(index, q_index, value, "SME_Remarks")
                                                  }
                                                  error={Boolean(validationErrors[`${index}-${q_index}-SME_Remarks`])}
                                                  helperText={validationErrors[`${index}-${q_index}-SME_Remarks`]}
                                                />
                                              ) : (
                                                q_item?.SME_Remarks || "-"
                                              )}
                                            </>
                                          );
                                        case "SO Remarks":
                                          return (
                                            <>
                                              {PageName == VOBWorflowPages?.SOReview ? (
                                                <CustomMultilineTextField
                                                  // name={"Answer"}
                                                  key={`${index + 1 + q_index} - Questions SO Remark`}
                                                  rows={0}
                                                  maxRows={4}
                                                  required={true}
                                                  label={""}
                                                  readOnly={isPreviewMode}
                                                  value={q_item?.SO_Remarks}
                                                  onChange={(value) =>
                                                    handleCommonValueChange(index, q_index, value, "SO_Remarks")
                                                  }
                                                  error={Boolean(validationErrors[`${index}-${q_index}-SO_Remarks`])}
                                                  helperText={validationErrors[`${index}-${q_index}-SO_Remarks`]}
                                                />
                                              ) : (
                                                q_item?.SO_Remarks || "-"
                                              )}
                                            </>
                                          );
                                        case "Action":
                                          return (
                                            <>
                                              {PageName == VOBWorflowPages?.DoReview ? (
                                                <CustomMultilineTextField
                                                  // name={"Answer"}
                                                  key={`${index + 1 + q_index} - Questions  Do remark`}
                                                  rows={0}
                                                  maxRows={4}
                                                  required={true}
                                                  label={""}
                                                  readOnly={isPreviewMode}
                                                  value={q_item?.DO_Remarks}
                                                  onChange={(value) =>
                                                    handleCommonValueChange(index, q_index, value, "DO_Remarks")
                                                  }
                                                  error={Boolean(validationErrors[`${index}-${q_index}-DO_Remarks`])}
                                                  helperText={validationErrors[`${index}-${q_index}-DO_Remarks`]}
                                                />
                                              ) : (
                                                q_item?.DO_Remarks || "-"
                                              )}
                                            </>
                                          );
                                        case "DO Review Status":
                                          return (
                                            <Box
                                              className={` ${
                                                statusCheck(q_item?.DO_ReviewStatus) == vendorAssetStatus.approve
                                                  ? PreviewStyle.approveSec
                                                  : statusCheck(q_item?.DO_ReviewStatus) == vendorAssetStatus.reject
                                                  ? PreviewStyle.rejectedSec
                                                  : ""
                                              }`}
                                            >
                                              {" "}
                                              {PageName == VOBWorflowPages?.DoReview ? (
                                                <CustomSelect
                                                  name={""}
                                                  valueKey={"LovId"}
                                                  optionLabelKey="Options"
                                                  options={ApproveList}
                                                  readOnly={isPreviewMode}
                                                  required={true}
                                                  label={""}
                                                  value={q_item?.DO_ReviewStatus}
                                                  onChange={(value) =>
                                                    handleCommonValueChange(index, q_index, value, "DO_ReviewStatus")
                                                  }
                                                  error={Boolean(
                                                    validationErrors[`${index}-${q_index}-DO_ReviewStatus`]
                                                  )}
                                                  helperText={validationErrors[`${index}-${q_index}-DO_ReviewStatus`]}
                                                />
                                              ) : (
                                                statusCheck(q_item?.DO_ReviewStatus) || "-"
                                              )}
                                            </Box>
                                          );

                                        case "Send To DO":
                                          return (
                                            <CustomCheckBox
                                              key={`${index + 1 + q_index} - Questions is Do`}
                                              Width={"10%"}
                                              label=""
                                              isStyle={false}
                                              readOnly={iscompleteDo || isPreviewMode}
                                              value={q_item?.IsAddInDo}
                                              onChange={(event) =>
                                                handleCommonValueChange(
                                                  index,
                                                  q_index,
                                                  event.target.checked,
                                                  "IsAddInDo"
                                                )
                                              }
                                            />
                                          );
                                        case "Send it for PM?":
                                          return (
                                            <CustomCheckBox
                                              key={`${index + 1 + q_index} - Questions  is PM check`}
                                              Width={"10%"}
                                              label=""
                                              isStyle={true}
                                              readOnly={PageName != VOBWorflowPages?.DoReview || isPreviewMode}
                                              value={q_item?.IsAddInPM}
                                              onChange={(event) =>
                                                handleCommonValueChange(
                                                  index,
                                                  q_index,
                                                  event.target.checked,
                                                  "IsAddInPM"
                                                )
                                              }
                                            />
                                          );

                                        default:
                                          return "N/A"; // Fallback for unspecified columns
                                      }
                                    })()}
                                  </TableCell>
         ) }
                )}
                            </TableRow>
                          
                        )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ width: "100%", overflowX: "auto" }}>
            <Typography>No Assessment data Available</Typography>
          </Box>
        </>
      )}
      <CustomDialog
        show={isfileUpload}
        onHide={() => {
          fileUploadClickClose();
        }}
        maxWidth={"sm"}
        header={"Upload Files"}
        contentNode={
          <>
            <CustomFileUploader
              onFileUpload={(value) => {
                SelectedFileUpload(value);
              }}
              maxFileSize={5242880}
              acceptFormats={[".png", ".jpeg", ".jpg", ".pdf", ".docx"]}
            />
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "right",
                mt: 2,
              }}
            >
              <>
                <CustomButton
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    fileUploadClickClose();
                  }}
                />

                <CustomButton
                  variant="contained"
                  disabled={!attachmentFile}
                  name={"Save"}
                  onClick={() => uploadFile()}
                />
              </>
            </Box>
          </>
        }
      />

      <CustomDialog
        show={isBulkUpload}
        onHide={() => {
          setIsBulkUpload(false);
        }}
        maxWidth={"lg"}
        header={"Upload Questions"}
        contentNode={
          <>
            <BulkUploadAssessmentQuestionVOB
              handleSubmitBulkAssessmentQuestion={handleSubmitBulkAssessmentQuestion}
              onclose={() => setIsBulkUpload(false)}
              AssessmentData={selectedCatagoryPreview}
            />
          </>
        }
      />

      <CustomDialog
        show={isfilePreview}
        onHide={() => {
          setIsfilePreview(false);
        }}
        key={"VOB File Upload"}
        maxWidth={"lg"}
        header={"File Preview"}
        contentNode={
          <>
            <FilteViewer URL={isfilePreviewurl} fileName={fileName} />
          </>
        }
      />
    </>
  );
};
