import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { ApplicationParameterName, LocationConfig, option_ToasterActivityConfig, ToasterActivityConfig } from "../../config/config";
import dayjs from "dayjs";
import CustomDatePicker from "../../common/dynamicInputs/CustomDatePicker";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomMultiSelect from "../../common/dynamicInputs/CustomMultiSelect";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { AddorEditFormTitle } from "../../store/slices/formTitleMasterData";
import InactivityTimer from "../../common/InactivityTimer/InactivityTimer";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";


interface FormData {
    FormId?: any;
    FormName: any;
    ViewTitle: string;
    ActionTitle: string;
    IsActive: any;
}

export interface ScreenTitleMasterProps {
    view?: boolean;
    closePopUp?: () => void;
    edit?: boolean;
    isEditData?: any;
}

export const AddScreenTitleMaster: React.FC<ScreenTitleMasterProps> = ({ view, closePopUp, edit, isEditData }) => {

    const dispatch = useAppDispatchThunk();
    const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
    const [StateData, setStateData] = useState<[]>([]);
    const { FormTitleTable, isFormTitleAddandEdit, versionHistory, ToasterMsg } = useSelector(
        (state: RootState) => state.FormTitleMasterData
    );
    const [timeout, setTimeout] = useState<any>(null);
    const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);

    let formData: any = {
        FormId: isEditData ? isEditData?.FormId : 0,
        FormName: isEditData ? isEditData?.FormName : "",
        ViewTitle: isEditData ? isEditData?.ViewTitle : "",
        ActionTitle: isEditData ? isEditData?.ActionTitle : "",
        IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
    };


    useEffect(() => {
        if (edit) {
            const RecordTimeOut: any = ApplicationParameterName.RecordTimeOut;
            let findRecordTimeOut = applicationParameterData.find(
                (applicationParamitem) =>
                    applicationParamitem.Parameter_Name === RecordTimeOut &&
                    applicationParamitem.Parameter_Group === RecordTimeOut
            )?.Parameter_Value;
            setTimeout(Number(findRecordTimeOut));
        }
    }, []);
    const {
        handleSubmit: handleTitleForm,
        reset,
        setValue,
        formState: { errors: errorsloginform, isDirty },
        control,
        watch,
        getValues,
        clearErrors,
    } = useForm<FormData>({
        defaultValues: formData,
        mode: "onChange",
    });
    // console.log(getValues(), "PurposeId");
    // const Form_StatusWatch = watch("Form_Status")
    const isActiveDisabled = watch("IsActive");

    const handleSubmit = (data: any) => {
        let formData = data;
        // dispatch(updateLoader(true));

        // formData.MenuId = currentPagePermissionData?.MenuId;
        formData.ModuleName = "Form Title Master";
        formData.UpdatedBy = user?.UserName;
        let JsonDataForDisplay: any = {
            // "Form Id": formData?.Title_Id,
            "Form Name": formData.FormName,
            "View Title": formData.ViewTitle,
            "Action Title": formData.ActionTitle,
            Status: formData.IsActive ? "Active" : "InActive",
            "Modified By": user?.UserName,
        };

        if (edit) {
            formData.IsActive = formData.IsActive ? true : false;
            formData.Activity = ToasterActivityConfig.Edit;
            // formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);

            dispatch(AddorEditFormTitle(formData));
        } else {
            formData.Activity = ToasterActivityConfig.create;
            formData.IsActive = true;
            // formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);

            dispatch(AddorEditFormTitle(formData));
        }
        console.log(formData, "formData");
    };

    useEffect(() => {
        if (isFormTitleAddandEdit) {
            closePopUp && closePopUp()

        }
    }, [isFormTitleAddandEdit]);


    return (
        <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
            <Grid
                // item
                // item xs={12} sm={12} md={6} lg={6} xl={6}
                sx={{
                    backgroundImage: `url()`,
                    backgroundRepeat: "no-repeat",
                    // backgroundColor: (t) =>
                    //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
                    // backgroundSize: "cover",
                    backgroundPosition: "center",
                    // height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    margin: "0",
                }}
            >

                <Grid
                    sx={{
                        height: "auto",
                        width: "100%",
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            px: {
                                xs: 2,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            },

                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                            <Grid container spacing={2}>

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Controller
                                        name="FormName"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            required: "Form Name is Required",
                                            validate: {
                                                noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                                                minLength: (value) => value.length >= 1 || "Minimum 1 characters required",
                                                maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                                                ValueLimitaion: (value) =>
                                                    /^[a-zA-Z,.-]+(?:\s[a-zA-Z-,.]+)*$/.test(value.trim()) ||
                                                    "Name cannot contain special characters and empty value",
                                            },
                                        }}
                                        render={({ field }) => (
                                            <CustomTextField
                                                name={"Form_Name"}
                                                required={true}
                                                readOnly={view || edit || !isActiveDisabled}
                                                // readOnly={view}
                                                label={"Form Name"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.FormName)}
                                                helperText={errorsloginform.FormName && errorsloginform.FormName.message?.toString()}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Controller
                                        name="ViewTitle"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            // required: "View Title is Required",
                                            validate: (value) => {
                                                if (value === "") {
                                                    return true;
                                                }
                                                if (/^\s/.test(value)) {
                                                    return "Leading spaces are not allowed";
                                                }
                                                if (value.length > 100) {
                                                    return "Maximum 100 characters required";
                                                }
                                                if (!/^[a-zA-Z\s\-. ]+$/.test(value)) {
                                                    return "View Title can only contain letters, spaces, hyphens, and dots.";
                                                }
                                                return true;
                                            }
                                        }}
                                        render={({ field }) => (
                                            <CustomTextField
                                                name={"View_Title"}
                                                // required={true}
                                                readOnly={view || !isActiveDisabled}
                                                // readOnly={view}
                                                label={"View Title"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.ViewTitle)}
                                                helperText={errorsloginform.ViewTitle && errorsloginform.ViewTitle.message?.toString()}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Controller
                                        name="ActionTitle"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            // required: "Action Title is Required",
                                            validate: (value) => {
                                                if (value === "") {
                                                    return true;
                                                }
                                                if (/^\s/.test(value)) {
                                                    return "Leading spaces are not allowed";
                                                }
                                                if (value.length > 100) {
                                                    return "Maximum 100 characters required";
                                                }
                                                if (!/^[a-zA-Z\s\-. ]+$/.test(value)) {
                                                    return "Action Title can only contain letters, spaces, hyphens, and dots.";
                                                }
                                                return true;
                                            }
                                        }}
                                        render={({ field }) => (
                                            <CustomTextField
                                                name={"ActionTitle"}
                                                // required={true}
                                                readOnly={view || !isActiveDisabled}
                                                label={"Action Title"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.ActionTitle)}
                                                helperText={errorsloginform.ActionTitle && errorsloginform.ActionTitle.message?.toString()}
                                            />
                                        )}
                                    />
                                </Grid>

                            </Grid>

                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "20px",
                                    justifyContent: "right",
                                    mt: 2,
                                }}
                            >
                                {view ? (
                                    <CustomButton
                                        type="reset"
                                        name={"Cancel"}
                                        onClick={() => {
                                            closePopUp && closePopUp();
                                        }}
                                    />
                                ) : edit ? (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton
                                            type="reset"
                                            disabled={!isDirty}
                                            name={"Update"}
                                            onClick={handleTitleForm(handleSubmit)}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton
                                            type="reset"
                                            disabled={!isDirty}
                                            name={"Submit"}
                                            onClick={handleTitleForm(handleSubmit)}
                                        />
                                    </React.Fragment>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            {timeout && (
                <InactivityTimer
                    timeout={timeout}
                    onTimeout={() => { }}
                    renderPrompt={(isVisible, handleResponse) =>
                        isVisible && (
                            <ModalPopUp
                                onDeleteMethod={() => { }}
                                isDelete={false}
                                onHide={() => handleResponse(false)}
                                show={isVisible}
                                header={"Confirm"}
                                text={`Are you still editing?`}
                                ButtonName={"Yes"}
                            />
                        )
                    }
                />
            )}
        </Grid>
    );
};
