import React, { useEffect, useState } from 'react'
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { useSelector } from 'react-redux';

import { useTheme } from "@mui/material/styles";
import { RootState, useAppDispatch } from '../../../../store/store';
import { ContentNameConfig } from '../../../../config/config';
import CustomButton from '../../../../common/dynamicButton/CustomButton';
import CustomSvg from '../../../../common/CustomSvg';
import CommonDataTemplateSheet from '../../../../common/CommonDataUploadTemplate/CommonDataTemplate';

export const VOBAssessmentTemplate = [
    {
      Field: "SrNo",
      FieldType: "string",
      DisplayName: "SrNo",
      config: {
        required: true,
      },
    },
    {
      Field: "Answer_Type",
      FieldType: "string",
      DisplayName: "Answer Type",
      config: {
        required: true,
      },
    },
    {
      Field: "Question",
      FieldType: "string",
      DisplayName: "Question",
      config: {
        required: true,
      },
    },
    {
        Field: "response",
        FieldType: "string",
        DisplayName: "Response",
        config: {
          required: true,
        },
        optionsField : "choice" 
      },
     
  ];

interface BulkUploadQuestionCOBTemplateProps {
  AssessmentData : any[];
}

export const BulkUploadQuestionVOBAssessment : React.FC <BulkUploadQuestionCOBTemplateProps> = ({AssessmentData }) => {
  const { vendorConentPurposes } = useSelector(
    (state: RootState) => state.contentData
  );
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [Note, setNote] = useState<any>("");
  const [Data, setData] = useState<any>([]);


useEffect(() => {

    console.log(AssessmentData , 'AssessmentData');
    let AssessmentDatawithOptions : any[] = []

    AssessmentData.forEach((item : any , index : any) => {
        item?.Questions.forEach((Q_item : any , Q_index : any) => {
            let obj : any ={SrNo : `${index+1}.${Q_index+1}` ,Question : Q_item?.Question , Answer_Type :  Q_item?.Answer_Type ? "Multichoice" : "Free text" , response : ""   }
               if(Q_item?.Answer_Type){
                let choiceResponse = Q_item?.choices.find((c_item : any) => c_item?.Score == Q_item?.ChoicesScore)?.Attribute_Response || "";  
                    let choice : any =    Q_item?.choices.map((c_item : any) => c_item?.Attribute_Response) || [];
                    obj.choice = choice
                  obj.response = choiceResponse || ""
               }else{
                  obj.response = Q_item?.Response_Received || ""
               }
               AssessmentDatawithOptions.push(obj)
        })
    })

    setData(AssessmentDatawithOptions)

},[AssessmentData])
 



     return(
        <>
           <CommonDataTemplateSheet sheetName='Assessment Questions' buttonName='Download Questions' header={VOBAssessmentTemplate}  Data={Data}  />
        </>
     )
}