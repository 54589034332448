import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ICaluseApiDetails,
  IClauseDetails,
  IMasterClauseType,
  IRiskMapping,
  ISystemVaraibles,
} from "../../../models/contractManagementModels/clauseModels";
import clauseService from "../services/clauseService";

export interface IClauseDetailsData {
  clauseDetailsData: IClauseDetails[];
  clauseTypeList: IMasterClauseType[];
  isClauseDetailsLoading: boolean;
  isClauseDetailsAdded: boolean;
  clauseToasterStatus: boolean | null;
  isClauseDetailsUpdated: boolean;
  isClauseDetailsDeleted: boolean;
  clauseToasterMsg: string;
  clauseData: any;
  subClause: any;
  systemVariables: ISystemVaraibles[];
  versionHistory: { departmentRecords: any[]; departmentVHeader: any[] };
}

export const initialState: IClauseDetailsData = {
  clauseDetailsData: [],
  clauseTypeList: [],
  isClauseDetailsLoading: false,
  isClauseDetailsAdded: false,
  clauseToasterStatus: false,
  isClauseDetailsUpdated: false,
  isClauseDetailsDeleted: false,
  clauseToasterMsg: "",
  clauseData: null,
  subClause: null,
  systemVariables: [],
  versionHistory: { departmentRecords: [], departmentVHeader: [] },
};

export const getClauseDetailsList = createAsyncThunk("getClauseDetailsList", async (_, { rejectWithValue }) => {
  try {
    const response: any = await clauseService.getClauseDetailsList({});
    const data = response.clauseList.map((i: ICaluseApiDetails): IClauseDetails => {
      return {
        clause_Id: i?.clause_Id,
        clause_header: i?.clause_Header,
        clause_type: i?.clauseType?.clauseType_Name,
        regulator: i?.clauseType?.clauseType_Name === "Regulation" ? i?.regulator?.thirdParty_Name : "",
        regulatory_Reference: i?.clauseType?.clauseType_Name === "Regulation" ? i?.regulatory_Reference : "",
        clause: i?.clause?.length > 100 ? `${i?.clause?.substring(0, 100)}...` : i?.clause,
        isSme: i.isSMEReviewRequired ? "Yes" : "No",
        sme: i?.department?.departmentName,
        isSLA: i?.sla ? "Yes" : "No",
        isPm: i?.pm ? "Yes" : "No",
        isRisk: i?.isRiskApplicable ? "Yes" : "No",
        riskLevel: i?.risk?.description,
        riskCategory:
          i?.riskMappings?.length > 0
            ? i?.riskMappings?.map((item: IRiskMapping) => item?.riskCategory?.description).join(",")
            : "",
        isNonNegotiable: i?.isNonNegotiableClause ? "Yes" : "No",
        isActive: i?.isActive ? "Active" : "Inactive",
        modifiedBy: i?.updated_By,
      };
    });
    return data;
  } catch (e: any) {
    return rejectWithValue(e?.response?.data?.errors[0]);
  }
});

export const getClauseTypeList = createAsyncThunk("getClauseTypeList", async () => {
  const response: any = await clauseService.getClauseTypeList();
  const data = response.masterClauseTypeList.filter((i: IMasterClauseType) => {
    if (i.isActive === true) {
      return i;
    }
  });
  return data;
});

export const createClause = createAsyncThunk("createClause", async (requestBody: any, { rejectWithValue }) => {
  try {
    const response: any = await clauseService.createClause(requestBody);
    return response;
  } catch (e: any) {
    return rejectWithValue(e?.response?.data?.errors[0]);
  }
});

export const updateClause = createAsyncThunk("updateClause", async (requestBody: any, { rejectWithValue }) => {
  try {
    const response: any = await clauseService.updateClause(requestBody);
    return response;
  } catch (e: any) {
    return rejectWithValue(e?.response?.data?.errors[0]);
  }
});

export const getClauseDataById = createAsyncThunk("getCaluseById", async (requestparams: any, { rejectWithValue }) => {
  try {
    const response: any = await clauseService.getClauseDetailsById(requestparams);
    return response;
  } catch (error) {
    return error;
  }
});

export const activeInactiveClause = createAsyncThunk(
  "activeInactiveClause",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response: any = await clauseService.activeInactiveClause(requestBody);
      return response;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);

export const getSystemVariables = createAsyncThunk("getSystemVariables", async () => {
  const response: any = await clauseService.getSystemVariables();
  // const data = response.masterClauseTypeList.filter((i: ISystemVaraibles) => {
  //   if (i.isActive === true) {
  //     return i;
  //   }
  // });
  let systemVariable: any = {};
  response.systemVariableList.forEach((data: ISystemVaraibles) => {
    if (!systemVariable[data.module_Name]) {
      let copyArr: any = [];
      copyArr.push(data);
      systemVariable[data.module_Name] = copyArr;
    } else {
      let copyArr: any = [...systemVariable[data.module_Name]];
      copyArr.push(data);
      systemVariable[data.module_Name] = copyArr;
    }
  });
  return systemVariable;
});

export const getClauseHistory = createAsyncThunk("getClauseHistory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await clauseService.getVersionHistory(reqbody);
    const { DepartmentHistories, HeaderJson } = response.data;
    return { DepartmentHistories: DepartmentHistories, HeaderJson: HeaderJson.ColumnHeaders }

  } catch (error) {
    return rejectWithValue(error);
  }
});

export const clauseDataSlice = createSlice({
  name: "clauseDetails",
  initialState,
  reducers: {
    updateClauseDetailsAddandEdit: (state, action) => {
      state.isClauseDetailsAdded = false;
      state.isClauseDetailsUpdated = false;
      state.isClauseDetailsDeleted = false;
      state.clauseToasterMsg = "";
      state.clauseToasterStatus = null;
    },
    addorupdateSubClauses: (state, action) => {
      state.subClause = action.payload;
    },
    clearData: (state, action) => {
      state.clauseData = null;
    },
    updateVersionHistory: (state, action) => {
      console.log(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClauseDetailsList.pending, (state) => {
        state.isClauseDetailsLoading = true;
      })
      .addCase(getClauseDetailsList.fulfilled, (state, action: PayloadAction<any>) => {
        state.clauseDetailsData = action.payload;
        state.isClauseDetailsLoading = false;
      })
      .addCase(getClauseDetailsList.rejected, (state) => {
        state.isClauseDetailsLoading = false;
      })
      .addCase(getClauseTypeList.pending, (state) => {
        state.isClauseDetailsLoading = true;
      })
      .addCase(getClauseTypeList.fulfilled, (state, action: PayloadAction<any>) => {
        state.clauseTypeList = action.payload;
        state.isClauseDetailsLoading = false;
      })
      .addCase(getClauseTypeList.rejected, (state) => {
        state.isClauseDetailsLoading = false;
      })
      .addCase(createClause.pending, (state) => {
        state.isClauseDetailsLoading = true;
      })
      .addCase(createClause.fulfilled, (state, action: PayloadAction<any>) => {
        state.isClauseDetailsLoading = false;
        state.isClauseDetailsAdded = true;
        state.clauseToasterStatus = false;
        state.clauseToasterMsg = action?.payload?.toaster?.message;
        state.clauseData = action.payload;
      })
      .addCase(createClause.rejected, (state, action: PayloadAction<any>) => {
        state.isClauseDetailsLoading = false;
        state.isClauseDetailsAdded = true;
        state.clauseToasterStatus = true;
        state.clauseToasterMsg = action?.payload;
      })
      .addCase(getClauseDataById.pending, (state) => {
        state.isClauseDetailsLoading = true;
      })
      .addCase(getClauseDataById.fulfilled, (state, action: PayloadAction<any>) => {
        state.isClauseDetailsLoading = false;
        state.clauseData = action.payload;
      })
      .addCase(getClauseDataById.rejected, (state) => {
        state.isClauseDetailsLoading = false;
      })
      .addCase(updateClause.pending, (state) => {
        state.isClauseDetailsLoading = true;
      })
      .addCase(updateClause.fulfilled, (state, action: PayloadAction<any>) => {
        state.isClauseDetailsLoading = false;
        state.isClauseDetailsUpdated = true;
        state.clauseToasterMsg = action?.payload?.toaster?.message;
      })
      .addCase(updateClause.rejected, (state, action: PayloadAction<any>) => {
        state.isClauseDetailsLoading = false;
        state.clauseToasterStatus = true;
        state.isClauseDetailsUpdated = true;
        state.clauseToasterMsg = action?.payload;
      })
      .addCase(activeInactiveClause.pending, (state) => {
        state.isClauseDetailsLoading = true;
      })
      .addCase(activeInactiveClause.fulfilled, (state, action: PayloadAction<any>) => {
        state.isClauseDetailsLoading = false;
        state.isClauseDetailsDeleted = true;
        state.clauseToasterMsg = action?.payload?.toaster?.message;
      })
      .addCase(activeInactiveClause.rejected, (state, action: PayloadAction<any>) => {
        state.isClauseDetailsLoading = false;
        state.clauseToasterStatus = true;
        state.isClauseDetailsDeleted = true;
        state.clauseToasterMsg = action?.payload;
      })
      .addCase(getSystemVariables.pending, (state) => {
        state.isClauseDetailsLoading = true;
      })
      .addCase(getSystemVariables.fulfilled, (state, action: PayloadAction<any>) => {
        state.systemVariables = action.payload;
        state.isClauseDetailsLoading = false;
      })
      .addCase(getSystemVariables.rejected, (state) => {
        state.isClauseDetailsLoading = false;
      });
  },
});

export const { updateClauseDetailsAddandEdit, updateVersionHistory, clearData, addorupdateSubClauses } =
  clauseDataSlice.actions;
export default clauseDataSlice.reducer;
