import React, { useEffect, useState } from "react";
import * as mime from "react-native-mime-types";
import { IUser } from "../../../models/user.type";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { getDepartmentList } from "../../../store/slices/departmentData";
import CustomStepper from "../../../common/dynamicSteper/CustomStepper";
import { Box, Grid, Stack, Typography } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CompanyDetails from "../companyDetails/CompanyDetails";
import { ACTIVE, INACTIVE } from "../../../utils/constants";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import UniqueIdSearchForm from "../UniqueIdSearch/UniqueIdSearchForm";
import CompanyAddress from "../CompanyAddress/index";
import { DownLoadAFile, UploadAFile } from "../../../store/slices/fileHandler";
import { COMPANYMASTER_URL } from "../../../store/services/endpoints/commonMasters";
import { useSelector } from "react-redux";
import {
  LovConfig,
  StatusConfig,
  VendorMaster_Address_Type,
  LocationConfig,
  ApplicationParameterName,
} from "../../../config/config";

import {
  addCompany,
  getCompanyList,
  updateCompany,
  updateCompanyAddandEdit,
} from "../../../store/slices/companyMasterData";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import fileHandlerService from "../../../store/services/fileHandlerService";
import { IMenu } from "../../../models/loggin.type";
import { addSaveandDraft, deleteSaveandDraft, updateSaveandDraft } from "../../../store/slices/saveasdraft";
import { getNotifications } from "../../../store/slices/notification";
import { clearProbeData, updateProbeAddandEdit } from "../../../store/slices/ProbeData";
import { getParentLocationList } from "../../../store/slices/locationMasterData";
import InactivityTimer from "../../../common/InactivityTimer/InactivityTimer";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import dayjs from "dayjs";

interface ICompanyMasterProps {
  view?: boolean;
  closePopUp?: () => void;
  edit?: boolean;
  userdata?: IUser | null;
  defaultdata?: any;
  isDefault?: boolean;
  defaultId?: number;
  isEditData?: any;
  setFormDatas: any;
  formDatas: any;
  setIsEditData?: any;
  setinitialData?: any;
  initialData?: any;
  recordLockUpdate?: any;
  isDraftDisable?: any;
  isWorkFlow?: any;
  isEdited?: any;
  isAdded?: any;
  formData?: any;
}

export interface ICompanyInformationForm {
  Company_Id: any;
  Legal_Name: string;
  Brand_Name: string;
  Short_Name: string;
  Is_Parent_Company: number;
  Parent_Company_Name: string;
  Company_Type: string;
  Group_Name: string;
  Industry: any;
  Sector: any;
  WebSite: string;
  Logo: any;
  logoUrl?: any;
  IsActive: boolean;
}

export interface ICompanyRegulationForm {
  PAN: string;
  TAN: string;
  CIN: string;
  Constitution: string;
  Jurisdiction: string;
  Is_Regulated: number;
  Regulator: any;
  About_Company: string;
  Company_LOB: any;
  Department: any;
  IsActive: boolean;
}

interface ICompanyDetailsForm {
  ICompanyInformationForm: ICompanyInformationForm;
  ICompanyRegulationForm: ICompanyRegulationForm;
}

const CompanyMasterStepper: React.FC<ICompanyMasterProps> = ({
  view,
  edit,
  isDefault = false,
  formData,
  setFormDatas,
  isEditData,
  setIsEditData,
  setinitialData,
  isDraftDisable,
  isWorkFlow,
  closePopUp,
}) => {
  const dispatch = useAppDispatchThunk();
  const [activestepCount, setactivestepCount] = useState(0);
  const [isUpdateEnabled, setIsUpdateEnabled] = useState(false);
  const [expanded, setExpanded] = useState<string | null>(null);
  const [isDraftEnabled, setIsDraftEnabled] = useState(true);
  const { locationTable } = useSelector((state: RootState) => state.locationMasterData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { industryTable, ParentindustryTable } = useSelector((state: RootState) => state.indutryMasterData);
  const { thirdPartyData } = useSelector((state: RootState) => state.thirdPartyData);
  const { dataTable } = useSelector((state: RootState) => state.departmentData);
  const [CompanyTypelov, setCompanyTypelov] = useState<any[]>([]);
  const [ProbeCompanyData, setProbeCompanyData] = useState<any>([]);
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const { ParentLocationTable, islocationAdded } = useSelector((state: RootState) => state.locationMasterData);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const { CompanyComprehensiveData, ProApiDetails, CompanyComprehensiveAdded, CompanyComprehensiveFailed, ProbeToasterMsg } =
    useSelector((state: RootState) => state.ProbeData);
  let AddressTypeLOV = lovTable.filter((item: any) => item.Name === "Address Type") || [];
  const [purposeType, SetPurposeType] = useState<any[]>([]);
  const [addressType, setAddressType] = useState<any[]>([]);
  const [lovActiveStatusId, setLovActiveStatusId] = useState<any>("");
  let CompanyConstitutionLOV = lovTable.filter((item: any) => item.Name === "Company Constitution") || [];
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const {
    CompanyTable,
    ToasterMsg,
    isCompanyAdded,
    isCompanyDeleted,
    isCompanyUpdated,
    versionHistory,
    getImageUrl,
    isLoading,
  } = useSelector((state: RootState) => state.CompanyMasterData);

  const main_steps = ["Company Details", "Company Address"];

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    dispatch(getDepartmentList({ FormID: 2 }));
    setFormDatas({});
  }, []);
  useEffect(() => {
    setCompanyTypelov(
      lovTable.filter((item: any) => item.Name === LovConfig.Company_Type && item.IsActive === ACTIVE) || []
    );
    fetchParentLocation();
  }, [lovTable]);


  const fetchParentLocation = () => {
    let dublocationType: any[] = lovTable.filter((item: any) => item.Name === LovConfig?.Location_Type);
    if (dublocationType && dublocationType.length > 0) {
      let countryTypeId: any = dublocationType.find((item) => item.Options === LocationConfig?.Location_City);
      if (countryTypeId && countryTypeId.LovId) {
        dispatch(getParentLocationList({ LocationtypeId: countryTypeId.LovId }));
      }
    }
  };

  const cleanJSONString = (data: string): string => {
    if ((data.startsWith("'") && data.endsWith("'")) || (data.startsWith('"') && data.endsWith('"'))) {
      data = data.substring(1, data.length - 1);
    }
    return data;
  };
  const isJSONString = (data: any): boolean => {
    if (typeof data !== "string") return false;
    try {
      JSON.parse(data);
      return true;
    } catch (e) {
      return false;
    }
  };
  const LOBMapArray = (data: any) => {
    try {
      if (typeof data === "string") {
        data = cleanJSONString(data);
      }

      if (isJSONString(data)) {
        const parseData = JSON.parse(data);
        const optionArray = parseData.map((doc: any) => doc?.LovId);
        return optionArray;
      } else if (Array.isArray(data)) {
        return data;
      } else {
        console.error("Invalid data type for LOBMapArray:", data);
        return data;
      }
    } catch (error) {
      console.error("Failed to parse data in LOBMapArray:", error, "Data:", data);
      return data;
    }
  };

  const DoCArray = (data: any) => {
    try {
      if (typeof data === "string") {
        data = cleanJSONString(data);
      }

      if (isJSONString(data)) {
        const parseData = JSON.parse(data);
        const optionArray = parseData.map((doc: any) => doc?.DepartmentId);
        return optionArray;
      } else if (Array.isArray(data)) {
        return data;
      } else {
        console.error("Invalid data type for DoCArray:", data);
        return data;
      }
    } catch (error) {
      console.error("Failed to parse data in DoCArray:", error, "Data:", data);
      return data;
    }
  };

  const SectorArray = (data: any) => {
    try {
      if (typeof data === "string") {
        data = cleanJSONString(data);
      }

      if (isJSONString(data)) {
        const parseData = JSON.parse(data);
        const optionArray = parseData.map((doc: any) => doc?.IndustryId);
        return optionArray;
      } else if (Array.isArray(data)) {
        return data;
      } else {
        console.error("Invalid data type for SectorArray:", data);
        return data;
      }
    } catch (error) {
      console.error("Failed to parse data in SectorArray:", error, "Data:", data);
      return data;
    }
  };

  const parseJSONSafely = (data: any) => {
    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
  };

  const initialCompanyInfoData: ICompanyInformationForm = {
    Company_Id: isEditData?.Company_Id || 0,
    Legal_Name: isEditData?.Legal_Name || "",
    Brand_Name: isEditData?.Brand_Name || "",
    Short_Name: isEditData?.Short_Name || "",
    Is_Parent_Company: isEditData?.Is_Parent_Company === 1 || isEditData?.Is_Parent_Company === true ? 1 : 2,
    Parent_Company_Name: isEditData?.Parent_Company_Name || 0,
    Company_Type: isEditData?.Company_Type || 0,
    Group_Name: isEditData?.Group_Name || "",
    Industry: isEditData?.Industry || null,
    Sector: isEditData?.Sector ? SectorArray(parseJSONSafely(isEditData?.Sector)) : [],
    WebSite: isEditData?.WebSite || "",
    Logo: isEditData?.Logo || "",
    IsActive: isEditData?.IsActive == INACTIVE ? false : true,
  };

  const initialCompanyRegulationData: ICompanyRegulationForm = {
    PAN: isEditData?.PAN || "",
    TAN: isEditData?.TAN || "",
    CIN: isEditData?.CIN || "",
    Constitution: isEditData?.Constitution || 0,
    Jurisdiction: isEditData?.Jurisdiction || 0,
    Is_Regulated: isEditData?.Is_Regulated === 1 || isEditData?.Is_Regulated === true ? 1 : 2,
    Regulator: isEditData?.Regulator ? Number(isEditData.Regulator) : 0,
    About_Company: isEditData?.About_Company || "",
    Company_LOB: isEditData?.Company_LOB ? LOBMapArray(parseJSONSafely(isEditData?.Company_LOB)) : [],
    Department: isEditData?.Department ? DoCArray(parseJSONSafely(isEditData?.Department)) : [],
    IsActive: isEditData?.IsActive == INACTIVE ? false : true,
  };


  function safeJsonParse(jsonString: any) {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Invalid JSON:", jsonString);
      return [];
    }
  }

  const initialCompanyDetailsData: any = {
    ...initialCompanyInfoData,

    ...initialCompanyRegulationData,
  };

  let initialCompanyAddressFormData = {
    address: isEditData?.CompanyAddressAsJson ? safeJsonParse(isEditData.CompanyAddressAsJson) : [],
    representatives: isEditData?.CompanyRepresentativeAsJson
      ? safeJsonParse(isEditData.CompanyRepresentativeAsJson)
      : [],
  };

  const CompanyAddressFormMethods = useForm({ defaultValues: initialCompanyAddressFormData });
  const {
    handleSubmit: CompanyAddressHandleSubmit,
    getValues: CompanyAddressGetValues,
    setValue: CompanyAddressSetValues,
    formState: { isDirty: CompanyAddressIsDirty },
    reset: CompanyAddressReset,
  } = CompanyAddressFormMethods;

  const CompanyDetailsFormMethods = useForm<any>({
    defaultValues: initialCompanyDetailsData,
    mode: "onChange",
  });

  const {
    getValues: CompanyDetailsGetValues,
    formState: { isDirty: CompanyDetailsIsDirty },
    setValue: CompanyDetailsSetValues,
    reset: CompanyDetailsReset,
  } = CompanyDetailsFormMethods;

  let findRegiterAddressID: any = addressType.find(
    (item: any) => item.Options === VendorMaster_Address_Type.RegisteredAddress
  );

  let findBusinessAddressID: any = addressType.find(
    (item: any) => item.Options === VendorMaster_Address_Type.BusinessAddress
  );

  useEffect(() => {
    try {
      if (CompanyComprehensiveData) {
        setProbeCompanyData(CompanyComprehensiveData?.data);
      }
    } catch (error) {
      console.error("Error in setting SignzyDatas:", error);
    }
  }, [CompanyComprehensiveData]);

  useEffect(() => {
    try {
      setAddressType(lovTable.filter((item: any) => item.Name === LovConfig?.Address_Type) || []);
      setLovActiveStatusId(
        lovTable.find((item: any) => item.Name === LovConfig.Status && item.Options === StatusConfig?.Active)?.LovId || 0
      );
      SetPurposeType(lovTable.filter((item: any) => item.Name === LovConfig?.Purpose) || []);
    } catch {

    }

  }, [lovTable]);

  useEffect(() => {
    try {
      const outputMapping = ProApiDetails?.outputMapping;
      console.log(outputMapping, "outputMapping");

      // Process active fields from outputMapping
      outputMapping?.forEach((mapping) => {
        if (mapping.IsActive) {
          try {
            const fieldValue = eval(mapping.API_Json_Field); // Dynamically evaluate the field value
            CompanyDetailsSetValues(mapping.CLife_Json_Field, fieldValue);

            // Handle specific fields with custom logic
            if (mapping.CLife_Json_Field === "Industry") {
              const ProbeindustryName = eval(mapping.API_Json_Field);
              const industryid =
                industryTable?.find((item: any) => item.IndustryName === ProbeindustryName)?.IndustryId || "";
              CompanyDetailsSetValues("Industry", industryid);
            }

            if (mapping.CLife_Json_Field === "Sector") {
              const ProbeSubindustryName = eval(mapping.API_Json_Field) || [];
              const SubindustryIds = ProbeSubindustryName.map(
                (SubIndus: any) =>
                  industryTable?.find((item: any) => item.IndustryName === SubIndus)?.IndustryId || []
              );
              CompanyDetailsSetValues("Sector", SubindustryIds);
            }

            if (mapping.CLife_Json_Field === "Constitution") {
              const ProbeConstitutionName = eval(mapping.API_Json_Field);
              const ConstitutionId =
                CompanyConstitutionLOV?.find((item: any) => item.Description === ProbeConstitutionName)?.LovId || 0;
              CompanyDetailsSetValues("Constitution", ConstitutionId);
            }

            if (mapping.CLife_Json_Field === "Jurisdiction") {
              const ProbeJuridictionCityName = eval(mapping.API_Json_Field);
              const JuridictionCityID =
                ParentLocationTable?.find((item: any) => item.Location_Name === ProbeJuridictionCityName)?.Location_Id || 0;
              CompanyDetailsSetValues("Jurisdiction", JuridictionCityID);
            }
            if (mapping.CLife_Json_Field === "Group_Name") {
              const Group_NameField = eval(mapping.API_Json_Field);

              CompanyDetailsSetValues("Group_Name", Group_NameField);
              // CompanyDetailsSetValues("Group_Name", ProbeCompanyData?.company?.legal_name?.split(" ")[0]);
            }

            if (ProbeCompanyData) {
              const getAddressValue = (fieldKey: string): string => {
                try {
                  const mappingForField = outputMapping?.find((mapping) => mapping.CLife_Json_Field === fieldKey);
                  if (mappingForField) {
                    const value = eval(mappingForField.API_Json_Field);
                    console.log(`FieldKey: ${fieldKey}, Value: ${value}`); // Debugging output
                    return value || ""; // Ensure fallback to an empty string if undefined
                  }
                  return "";
                } catch (error) {
                  console.error(`Error evaluating field ${fieldKey}:`, error);
                  return ""; // Fallback for safety
                }
              };

              const CompanyAddress = [
                {
                  Address_Number: 1,
                  Address_Type_Value: VendorMaster_Address_Type.RegisteredAddress,
                  Address_Type: findRegiterAddressID.LovId,
                  Address: getAddressValue("registered_address"),
                  Pin: getAddressValue("registered_Pin"),
                  City: getAddressValue("registered_City"),
                  State: getAddressValue("registered_State"),
                  Country: "India",
                  Status:
                    lovTable?.find((item: any) => item.Name === LovConfig.Status && item.Description === "Active")?.LovId || 0,
                },
                {
                  Address_Number: 2,
                  Address_Type_Value: VendorMaster_Address_Type.BusinessAddress,
                  Address_Type: findBusinessAddressID.LovId,
                  Address: getAddressValue("business_address"),
                  Pin: getAddressValue("business_Pin"),
                  City: getAddressValue("business_City"),
                  State: getAddressValue("business_State"),
                  Country: "India",
                  Status:
                    lovTable?.find((item: any) => item.Name === LovConfig.Status && item.Description === "Active")?.LovId || 0,
                },
              ];

              console.log(CompanyAddress, "CompanyAddress"); // Log the result for debugging

              CompanyAddressSetValues("address", CompanyAddress);
            } else {
              // Reset values if ProbeCompanyData is undefined
              CompanyDetailsReset();
              CompanyAddressReset();
            }

          } catch (error) {
            console.error(`Error processing field ${mapping.CLife_Json_Field}:`, error);
          }
        }
      });

    } catch (error) {
      console.error("Error in processing outputMapping:", error);
    }
  }, [ProbeCompanyData, CompanyComprehensiveData, ProApiDetails]);


  useEffect(() => {
    if (CompanyComprehensiveAdded) {
      dispatch(updateProbeAddandEdit(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ProbeToasterMsg.Message,
          isTosterFailed: ProbeToasterMsg.Status === "false" ? true : false,
        })
      );
      dispatch(updateLoader(false));
    } else if (CompanyComprehensiveFailed) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: ProbeToasterMsg.Message, isTosterFailed: true }));
      dispatch(updateProbeAddandEdit(false));
      dispatch(updateLoader(false));
    }
  }, [CompanyComprehensiveAdded, CompanyComprehensiveFailed]);

  useEffect(() => {
    if (CompanyAddressIsDirty || CompanyDetailsIsDirty) {
      setIsUpdateEnabled(true);
    } else {
      setIsUpdateEnabled(false);
    }
  }, [CompanyAddressIsDirty, CompanyDetailsIsDirty]);

  const watchedFields = useWatch({
    control: CompanyDetailsFormMethods.control,
    name: Object.keys(initialCompanyDetailsData),
  });

  useEffect(() => {
    const isDirty = !deepEqual(initialCompanyDetailsData, watchedFields);
    setIsDraftEnabled(isDirty);
  }, [watchedFields]);

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : null);
  };
  const companyInfoFields = Object.keys(initialCompanyInfoData);
  const companyRegulationFields = Object.keys(initialCompanyRegulationData);

  const deepEqual = (obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) return true;
    if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
    }
    return true;
  };

  const handleNext = async () => {
    if (activestepCount === 0) {
      const isValid = await CompanyDetailsFormMethods.trigger();
      const companyInfoErrors = companyInfoFields.filter(
        (field) => CompanyDetailsFormMethods.getFieldState(field).invalid
      );
      const companyRegulationErrors = companyRegulationFields.filter(
        (field) => CompanyDetailsFormMethods.getFieldState(field).invalid
      );
      if (companyInfoErrors.length > 0 && companyRegulationErrors.length > 0) {
        setExpanded("All");
      } else if (companyInfoErrors.length > 0) {
        setExpanded("companyDetails");
      } else if (companyRegulationErrors.length > 0) {
        setExpanded("regulatoryDetails");
      } else {
        setExpanded(null);
        setactivestepCount((prevCount) => prevCount + 1);
      }
    }
  };

  const handlePrevious = () => {
    setactivestepCount((prevCount) => prevCount - 1);
  };

  const handleFileUpload = async (data: any) => {
    try {
      const formData = new FormData();
      formData.append("fileUploadRequest", data);
      const getUploadUrl = await dispatch(UploadAFile(formData));
      const uploadedUrl = getUploadUrl.payload as string;
      return uploadedUrl;
    } catch (error) {
      console.error("File upload failed", error);
      throw new Error("Failed to upload file. Please try again.");
    }
  };

  const transformData = (data: any) => {
    return data.map((Addressitem: any) => {
      const AddressTypeID = Addressitem?.Address_Type ? Addressitem?.Address_Type : 0;
      const addressDatas = addressType.find((item: any) => item?.LovId === AddressTypeID)?.Options;
      const addressDatasstatus = lovActiveStatusId == Addressitem.Status ? "Active" : "Inactive";

      return {
        ...Addressitem,
        status_value: addressDatasstatus,
        Address_Type_Value: addressDatas !== undefined ? addressDatas : "",
      };
    });
  };

  const transformDataRepres = (data: any) => {
    return data.map((Repitem: any) => {
      const purposeTypeID = Repitem?.Purpose ? Repitem?.Purpose : 0;
      const RepDatas = purposeType.find((item: any) => item?.LovId === purposeTypeID)?.Options;
      const addressDatasstatus = lovActiveStatusId == Repitem.Status ? "Active" : "Inactive";
      return {
        ...Repitem,
        status_value: addressDatasstatus,
        Purpose_value: RepDatas !== undefined ? RepDatas : "",
      };
    });
  };

  const handleSubmitForm = async () => {
    const isValid = await CompanyAddressFormMethods.trigger();

    const companyInfoErrors = companyInfoFields.filter(
      (field) => CompanyDetailsFormMethods.getFieldState(field).invalid
    );
    const companyRegulationErrors = companyRegulationFields.filter(
      (field) => CompanyDetailsFormMethods.getFieldState(field).invalid
    );

    if (isValid) {
      const companyDetailsData = CompanyDetailsFormMethods.getValues();
      const companyAddressData = CompanyAddressGetValues();
      let JsonDataForDisplay = {
        "Legal Name": companyDetailsData?.Legal_Name,
        "Brand Name": companyDetailsData.Brand_Name || "",
        "Short Name": companyDetailsData.Short_Name,
        "Parent Company Name": companyDetailsData?.Parent_Company_Name
          ? CompanyTable.find((item: any) => item.Company_Id === companyDetailsData.Parent_Company_Name)?.Legal_Name ||
          ""
          : "",

        "Company Type": companyDetailsData?.Company_Type
          ? CompanyTypelov.find((item: any) => item.LovId == companyDetailsData.Company_Type)?.Options
          : "",
        "Group Name": companyDetailsData.Group_Name,
        Industry: companyDetailsData?.Industry
          ? industryTable.find((item: any) => item.IndustryId === companyDetailsData.Industry)?.IndustryName
          : "",
        Sector: companyDetailsData?.Sector
          ? companyDetailsData?.Sector?.map((data: any) => {
            const Industry = industryTable?.find((item: any) => item.IndustryId === data);
            return Industry?.IndustryName || "";
          })
            .filter(Boolean)
            .join(", ")
          : "",
        Website: companyDetailsData?.WebSite,
        CIN: companyDetailsData.CIN,
        TAN: companyDetailsData.TAN,
        PAN: companyDetailsData.PAN,
        Constitution: companyDetailsData?.Constitution
          ? lovTable?.find((item: any) => item.LovId === companyDetailsData.Constitution)?.Options
          : "",
        Jurisdiction: companyDetailsData?.Jurisdiction
          ? locationTable?.find((item: any) => item.Location_Id === companyDetailsData.Jurisdiction)?.Location_Name
          : "",
        "Line Of Business Mapping": companyDetailsData?.Company_LOB
          ? companyDetailsData?.Company_LOB?.map((data: any) => {
            const Company_LOB = lovTable?.find((item: any) => item.LovId === data);
            return Company_LOB?.Options || "";
          })
            .filter(Boolean)
            .join(", ")
          : "",
        "Department Mapping": companyDetailsData?.Department
          ? companyDetailsData?.Department?.map((data: any) => {
            const department = dataTable?.find((item: any) => item.DepartmentId === data);
            return department?.DepartmentName || "";
          })
            .filter(Boolean)
            .join(", ")
          : "",

        Regulator: companyDetailsData?.Regulator
          ? thirdPartyData.find((item: any) => item.ThirdPartyId === companyDetailsData.Regulator)?.ThirdPartyName
          : "",
        About: companyDetailsData.About_Company,
        Status: companyDetailsData.IsActive ? "Active" : "InActive",
        "Modified By": user?.UserName,
        // CompanyAddressAsJson: JSON.stringify(companyAddressData.address),
        // CompanyRepresentativeAsJson: JSON.stringify(companyAddressData.representatives),
        CompanyRepresentativeAsJson: JSON.stringify(transformDataRepres(companyAddressData.representatives)),
        CompanyAddressAsJson: JSON.stringify(transformData(companyAddressData.address)),
        Logo: companyDetailsData?.Logo,
      };

      const { logoUrl, Logo, ...restCompanyDetails } = companyDetailsData;

      const finalFormData = {
        ...restCompanyDetails,
        Logo: logoUrl,
        Regulator: restCompanyDetails?.Regulator.toString(),
        Sector:
          typeof restCompanyDetails.Sector === "string"
            ? JSON.stringify(restCompanyDetails.Sector.split(",").map(Number))
            : JSON.stringify(restCompanyDetails.Sector || []),

        Company_LOB:
          typeof restCompanyDetails.Company_LOB === "string"
            ? JSON.stringify(restCompanyDetails.Company_LOB.split(",").map(Number))
            : JSON.stringify(restCompanyDetails.Company_LOB || []),

        Department:
          typeof restCompanyDetails.Department === "string"
            ? JSON.stringify(restCompanyDetails.Department.split(",").map(Number))
            : JSON.stringify(restCompanyDetails.Department || []),
        Parent_Company_Name: restCompanyDetails?.Parent_Company_Name || 0,
        Company_Type: restCompanyDetails?.Company_Type || 0,
        CompanyAddressAsJson: JSON.stringify(companyAddressData.address),
        CompanyRepresentativeAsJson: JSON.stringify(companyAddressData.representatives),
        DataForDisplay: JSON.stringify(JsonDataForDisplay),
        Updated_By: user?.UserName || "",
        ModuleName: "Company Master",
        Activity: restCompanyDetails?.Company_Id !== 0 ? "Edit" : "Create",
        MenuId: permssionPage?.MenuId,
        Form_Id: 19,
        UserId: userLoginResponse?.UserId,
        Unique_Id: "string",
        Unique_Id_Type: "string",
        Status: restCompanyDetails.Status || 1,
        Remarks: restCompanyDetails.Remarks || "",
        Updated_On: dayjs(),
        Approved_By: "",
        Approved_On: dayjs(),
      };

      const specialKeys = ["IsActive", "Is_Parent_Company", "Is_Regulated"];
      Object.keys(finalFormData).forEach((key) => {
        const value = finalFormData[key];
        if (value !== undefined && value !== null && key !== "Logo") {
          if (specialKeys.includes(key)) {
            finalFormData[key] = value == 1 || value === true;
          } else {
            finalFormData[key] = value;
          }
        }
      });

      if (finalFormData?.Company_Id === 0) {
        dispatch(updateLoader(true));
        dispatch(addCompany(finalFormData));
        console.log("Add Form data:", finalFormData);
      } else {
        dispatch(updateLoader(true));
        console.log("Update Form data:", finalFormData);
        dispatch(updateCompany(finalFormData));
      }
    }
  };

  const handleSaveAsDraft = () => {
    const companyDetailsData = CompanyDetailsFormMethods.getValues();
    const companyAddressData = CompanyAddressGetValues();
    let JsonDataForDisplay = {
      "Legal Name": companyDetailsData?.Legal_Name,
      "Brand Name": companyDetailsData.Brand_Name || "",
      "Short Name": companyDetailsData.Short_Name,
      "Parent Company Name":
        companyDetailsData?.Parent_Company_Name !== 0 || companyDetailsData?.Parent_Company_Name !== ""
          ? companyDetailsData?.Parent_Company_Name
          : "",
      "Company Type": companyDetailsData?.Company_Type
        ? CompanyTypelov.find((item: any) => item.LovId == companyDetailsData.Company_Type)?.Options
        : "",
      "Group Name": companyDetailsData.Group_Name,
      Industry: companyDetailsData?.Industry
        ? industryTable.find((item: any) => item.IndustryId === companyDetailsData.Industry)?.IndustryName
        : "",
      Sector: companyDetailsData?.Sector
        ? companyDetailsData?.Sector?.map((data: any) => {
          const Industry = industryTable?.find((item: any) => item.IndustryId === data);
          return Industry?.IndustryName || "";
        })
          .filter(Boolean)
          .join(", ")
        : "",
      Website: companyDetailsData?.WebSite,
      CIN: companyDetailsData?.CIN,
      TAN: companyDetailsData?.TAN,
      PAN: companyDetailsData?.PAN,
      Constitution: companyDetailsData?.Constitution
        ? lovTable?.find((item: any) => item?.LovId === companyDetailsData?.Constitution)?.Options
        : "",
      Jurisdiction: companyDetailsData?.Jurisdiction
        ? locationTable?.find((item: any) => item?.Location_Id === companyDetailsData?.Jurisdiction)?.Location_Name
        : "",
      "Line Of Business Mapping": companyDetailsData?.Company_LOB
        ? companyDetailsData?.Company_LOB?.map((data: any) => {
          const Company_LOB = lovTable?.find((item: any) => item?.LovId === data);
          return Company_LOB?.Options || "";
        })
          .filter(Boolean)
          .join(", ")
        : "",
      "Department Mapping": companyDetailsData?.Department
        ? companyDetailsData?.Department?.map((data: any) => {
          const department = dataTable?.find((item: any) => item?.DepartmentId === data);
          return department?.DepartmentName || "";
        })
          .filter(Boolean)
          .join(", ")
        : "",

      Regulator: companyDetailsData?.Regulator
        ? thirdPartyData.find((item: any) => item?.ThirdPartyId === companyDetailsData?.Regulator)?.ThirdPartyName
        : "",
      About: companyDetailsData?.About_Company,
      Status: companyDetailsData?.IsActive ? "Active" : "InActive",
      "Modified By": user?.UserName,
      CompanyAddressAsJson: JSON.stringify(companyAddressData?.address),
      CompanyRepresentativeAsJson: JSON.stringify(companyAddressData?.representatives),
      Logo: companyDetailsData?.logoUrl,
    };
console.log(companyDetailsData,"companyDetailsData");

    const { logoUrl, Logo, ...restCompanyDetails } = companyDetailsData;

    const finalFormData = {
      ...restCompanyDetails,
      Logo: logoUrl,
      Regulator: restCompanyDetails?.Regulator.toString(),
      Sector:
        typeof restCompanyDetails?.Sector === "string"
          ? JSON.stringify(restCompanyDetails?.Sector?.split(",").map(Number))
          : JSON.stringify(restCompanyDetails?.Sector || []),

      Company_LOB:
        typeof restCompanyDetails.Company_LOB === "string"
          ? JSON.stringify(restCompanyDetails?.Company_LOB?.split(",")?.map(Number))
          : JSON.stringify(restCompanyDetails?.Company_LOB || []),

      Department:
        typeof restCompanyDetails.Department === "string"
          ? JSON.stringify(restCompanyDetails.Department.split(",").map(Number))
          : JSON.stringify(restCompanyDetails.Department || []),
      Parent_Company_Name: restCompanyDetails?.Parent_Company_Name || 0,
      Company_Type: restCompanyDetails?.Company_Type || 0,
      CompanyAddressAsJson: JSON.stringify(companyAddressData.address),
      CompanyRepresentativeAsJson: JSON.stringify(companyAddressData.representatives),
      DataForDisplay: JSON.stringify(JsonDataForDisplay),
      Updated_By: user?.UserName || "",
      Updated_On: dayjs(),
      Approved_By: "",
      Approved_On: "",
      ModuleName: "Company Master",
      Activity: restCompanyDetails?.Company_Id !== 0 ? "Edit" : "Create",
      MenuId: permssionPage?.MenuId,
      Form_Id: 19,
      UserId: userLoginResponse?.UserId,
      Unique_Id: "string",
      Unique_Id_Type: "string",
      Status: restCompanyDetails.Status || 1,
      Remarks: restCompanyDetails.Remarks || "",
    };

    const specialKeys = ["IsActive", "Is_Parent_Company", "Is_Regulated"];
    Object.keys(finalFormData).forEach((key) => {
      const value = finalFormData[key];
      if (value !== undefined && value !== null && key !== "Logo") {
        if (specialKeys.includes(key)) {
          finalFormData[key] = value == 1 || value === true;
        } else {
          finalFormData[key] = value;
        }
      }
    });
    const saveAsDraft = {
      UserId: userLoginResponse?.UserId,
      ModuleName: "Company Master Draft",
      ScreenName: "Company Master Draft",
      Activity: "Draft",
      DraftData: JSON.stringify({
        finalFormData,
      }),
      DraftStatus: true,
      CreatedBy: user?.UserName,
    };
    const updateSaveAsDraft = {
      DraftId: isEditData?.DraftId,
      ModuleName: "Company Master Draft",
      ScreenName: "Company Master Draft",
      Activity: "Draft",
      DraftData: JSON.stringify({
        finalFormData,
      }),
      DraftStatus: isEditData?.DraftStatus,
      UserId: userLoginResponse?.UserId,
      UpdatedBy: user?.UserName,
    };
    if (isEditData?.DraftId) {
      dispatch(updateLoader(true));
      dispatch(updateSaveandDraft(updateSaveAsDraft));
    } else {
      dispatch(addSaveandDraft(saveAsDraft));
    }
  };
  const handleCompanyUpdate = async () => {
    if (isCompanyAdded || isCompanyUpdated) {
      if (ToasterMsg?.Message && !ToasterMsg.Message.includes("exists")) {
        if (isEditData?.DraftId) {
          const Draft_id = isEditData?.DraftId;
          await dispatch(deleteSaveandDraft({ DraftId: Draft_id, CreatedBy: user?.UserName }));
        }
        await dispatch(getCompanyList({ FormID: 2 }));
        dispatch(updateCompanyAddandEdit(false));
        if (closePopUp) {
          await closePopUp();
          CompanyDetailsReset();
          CompanyAddressReset();
        }
        dispatch(
          getCompanyList({
            FormID: 2,
          })
        );
        dispatch(updateLoader(false));
        dispatch(updateCompanyAddandEdit(false));
        CompanyDetailsReset();
        CompanyAddressReset();
        setIsEditData({});
        setFormDatas({});
      } else {
        if (isEditData?.DraftId) {
          const Draft_id = isEditData?.DraftId;
          await dispatch(deleteSaveandDraft({ DraftId: Draft_id, CreatedBy: user?.UserName }));
        }
        await dispatch(getCompanyList({ FormID: 2 }));
        dispatch(updateCompanyAddandEdit(false));
        if (closePopUp) {
          await closePopUp();
          CompanyDetailsReset();
          CompanyAddressReset();
        }
        dispatch(
          getCompanyList({
            FormID: 2,
          })
        );
        dispatch(updateLoader(false));
        dispatch(updateCompanyAddandEdit(false));
        CompanyDetailsReset();
        CompanyAddressReset();
        setIsEditData({});
        setFormDatas({});
        if (closePopUp) {
          closePopUp();
        }
      }
      if (userLoginResponse) {
        dispatch(getNotifications(userLoginResponse?.UserId));
      }
      if (isWorkFlow) {
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: "Initial Setup Completed Successfully! ",
            isTosterFailed: false,
          })
        );
      }
    }
  };
  useEffect(() => {
    handleCompanyUpdate();
    dispatch(clearProbeData());
  }, [isCompanyAdded, isCompanyUpdated]);

  return (
    <Box sx={{ width: "100%", paddingTop: 2, minHeight: "calc(100vh - 180px)" }}>
      <Stack spacing={6} sx={{ mb: "30px" }}>
        <Box>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              top: !isWorkFlow ? "65px" : "10px",
              left: "-10px",
              p: 1,
              pt: "12px",
              pb: "12px",
              backgroundColor: "background.paper",
              zIndex: 1000,
            }}
          >
            <Box sx={{ mb: "10px" }}>
              <CustomStepper activeStep={activestepCount} steps={main_steps} />
            </Box>
            <Grid sx={{ display: "flex", gap: "20px", justifyContent: "end" }}>
              {activestepCount === 1 && (
                <CustomButton variant="outlined" name="Previous" onClick={handlePrevious} size="small" />
              )}
              {(!isDraftDisable && !edit) || isEditData?.DraftId ? (
                <CustomButton
                  variant="contained"
                  name="Save as Draft"
                  onClick={() => {
                    handleSaveAsDraft();
                  }}
                  size="small"
                  disabled={!isDraftEnabled}
                />
              ) : null}
              {activestepCount === 0 && (
                <CustomButton variant="contained" name="Proceed To Next" onClick={handleNext} size="small" />
              )}
              {activestepCount === 1 && (
                <CustomButton
                  variant="contained"
                  disabled={initialCompanyDetailsData?.IsActive === INACTIVE || !isUpdateEnabled}
                  name={edit && isEditData?.draft_data ? "Submit" : edit ? "Update" : "Submit"}
                  onClick={handleSubmitForm}
                  size="small"
                />
              )}
            </Grid>
          </Box>
          <Box sx={{ position: "relative", top: !isWorkFlow ? 100 : 130 }}>
            {activestepCount === 0 && (
              <FormProvider {...CompanyDetailsFormMethods}>
                <Box>
                  <Stack spacing={2} direction={"column"}>
                    {(isWorkFlow || isDraftDisable !== true) && !edit && !view && (
                      <UniqueIdSearchForm disableIsActiveField={true} isViewMode={false} />
                    )}
                    <CompanyDetails
                      view={view}
                      edit={edit}
                      formData={isEditData}
                      expanded={expanded}
                      handleChange={handleChange}
                    />
                  </Stack>
                </Box>
              </FormProvider>
            )}
            {activestepCount === 1 && (
              <FormProvider {...CompanyAddressFormMethods}>
                <CompanyAddress view={view} edit={edit} isEditData={isEditData} />
              </FormProvider>
            )}
          </Box>
        </Box>
      </Stack>
     
    </Box>
  );
};

export default CompanyMasterStepper;

export const base64ToFile = (base64String: string, fileName: string, mimeType: string) => {
  const byteString = atob(base64String.split(",")[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }

  return new File([intArray], fileName, { type: mimeType });
};
