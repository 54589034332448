import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import fileHandlerService from "../services/fileHandlerService";

const initialState = {
  isFileUpload: false,
  isFileUploadLoading: false,
  uploadData: "",
};

export interface addEventLogRequestParam {
  ModuleName: string;
  Action: string;
  Description: string;
  UserId: number;
  CreatedBy: string;
}

export const ContractUploadAFile = createAsyncThunk("UploadAFile", async (requestParams: any, { rejectWithValue }) => {
  try {
    const response: any = await fileHandlerService.UploadAFile(requestParams);
    if (response) {
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const contractFileHandler = createSlice({
  name: "contractFileHandler",
  initialState,
  reducers: {
    contractFileHandlerUpdate: (state, action) => {
      state.isFileUpload = false;
      state.isFileUploadLoading = false;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(ContractUploadAFile.pending, (state) => {
        state.isFileUpload = false;
        state.isFileUploadLoading = true;
      })
      .addCase(ContractUploadAFile.fulfilled, (state, action: PayloadAction<any>) => {
        state.isFileUpload = true;
        state.isFileUploadLoading = false;
        state.uploadData = action.payload;
      })
      .addCase(ContractUploadAFile.rejected, (state, action: PayloadAction<any>) => {
        state.isFileUpload = false;
        state.isFileUploadLoading = false;
      }),
});

export const { contractFileHandlerUpdate } = contractFileHandler.actions;
export default contractFileHandler.reducer;
