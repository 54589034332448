import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import serviceCatalogService from "../services/serviceCatalogService";
import { IServiceCatalog } from "../../models/serviceCatalog.type";
import { AnyNsRecord } from "dns";
import { chkDate } from "../../utils/constants";

export interface IServiceCatalogData {
  ActiveServiceCatlogdata: IServiceCatalog[];
  ServiceCatlogdata: IServiceCatalog[];
  isServiceCatlogLoading: boolean;
  isAddEditServiceCatlogLoading: boolean;
  isServiceCatalogAdded: boolean;
  isServiceCatalogUpdated: boolean;
  isServiceCatalogDeleted: boolean;
  servicecatlogtoasterMsg: string;
  ServiceCatalogversionHistory:{ServiceCatalogversionHistory : any[] , ServiceCatalogVHeader : any[]};
}

export const initialState: IServiceCatalogData = {
  ActiveServiceCatlogdata:[],
  ServiceCatlogdata: [],
  isServiceCatlogLoading: false,
  isAddEditServiceCatlogLoading: false,
  isServiceCatalogAdded: false,
  isServiceCatalogUpdated: false,
  isServiceCatalogDeleted: false,
  servicecatlogtoasterMsg: "",
  ServiceCatalogversionHistory:{ServiceCatalogversionHistory :[] , ServiceCatalogVHeader : []},
};

export const getServiceCatalogList = createAsyncThunk("getServiceCatalogList", async () => {
  const response = await serviceCatalogService.getServiceCatalogList();
  return response.data;
});

export const addServiceCatalog = createAsyncThunk(
  "addServiceCatalog",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await serviceCatalogService.addServiceCatalog(requesrParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateServiceCatalog = createAsyncThunk(
  "updateServiceCatalog",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response = await serviceCatalogService.updateServiceCatalog(requestParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteServiceCatalog = createAsyncThunk(
  "deleteServiceCatalog",
  async (serviceCatalogId: any, { rejectWithValue }) => {
    try {
      const response = await serviceCatalogService.deleteServiceCatalog(serviceCatalogId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const versionHistoryServiceCatalog = createAsyncThunk(
  "versionHistoryServiceCatalog",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await serviceCatalogService.versionHistoryServiceCatalog(reqbody);
      const {serviceCatalogs , HeaderJson } =response.data;
      return {ServiceCatalogversionHistory: serviceCatalogs , ServiceCatalogVHeader : HeaderJson.ColumnHeaders}
          
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const serviceCatalogSlice = createSlice({
  name: "serviceCatalog",
  initialState,
  reducers: {
    updateServiceCatalogAddandEdit: (state, action) => {
      state.isServiceCatalogAdded = false;
      state.isServiceCatalogUpdated = false;
      state.isServiceCatalogDeleted = false;
    },
    updateServicecatalogVersionHistory:  (state, action)=>{            
      state.ServiceCatalogversionHistory.ServiceCatalogVHeader = [];
      state.ServiceCatalogversionHistory.ServiceCatalogversionHistory = [];
    }, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServiceCatalogList.pending, (state) => {
        state.isServiceCatlogLoading = true;
      })
      .addCase(getServiceCatalogList.fulfilled, (state, action: PayloadAction<any>) => {
        state.ActiveServiceCatlogdata = action.payload.filter((service: any) => {
          return service.IsActive;
         });
        state.ServiceCatlogdata = action.payload ? action.payload.map((service: any) => {
          service.IsActive = service.IsActive === true ? "Active" : "Inactive";
          return service;
        }) : [] ;
        state.isServiceCatlogLoading = false;
      })
      .addCase(getServiceCatalogList.rejected, (state) => {
        state.isServiceCatlogLoading = false;
      })
      .addCase(addServiceCatalog.pending, (state) => {
        state.isServiceCatlogLoading = true;
        state.isAddEditServiceCatlogLoading = true;
      })
      .addCase(addServiceCatalog.fulfilled, (state, action: PayloadAction<any>) => {
        state.isServiceCatalogAdded = true;
        state.isServiceCatlogLoading = false;
        state.isAddEditServiceCatlogLoading = false;
        state.servicecatlogtoasterMsg = action.payload.Message;
      })
      .addCase(addServiceCatalog.rejected, (state) => {
        state.isServiceCatlogLoading = false;
        state.isAddEditServiceCatlogLoading = false;
      })
      .addCase(updateServiceCatalog.pending, (state) => {
        state.isServiceCatlogLoading = true;
        state.isAddEditServiceCatlogLoading = true;
      })
      .addCase(updateServiceCatalog.fulfilled, (state, action: PayloadAction<any>) => {
        state.isServiceCatalogUpdated = true;
        state.isServiceCatlogLoading = false;
        state.isAddEditServiceCatlogLoading = false;
        state.servicecatlogtoasterMsg = action.payload.Message;
      })
      .addCase(updateServiceCatalog.rejected, (state) => {
        state.isServiceCatlogLoading = false;
        state.isAddEditServiceCatlogLoading = false;
      })
      .addCase(deleteServiceCatalog.pending, (state) => {
        state.isServiceCatlogLoading = true;
      })
      .addCase(deleteServiceCatalog.fulfilled, (state, action: PayloadAction<any>) => {
        state.isServiceCatalogDeleted = true;
        state.isServiceCatlogLoading = false;
        state.servicecatlogtoasterMsg = action.payload.Message;
      })
      .addCase(deleteServiceCatalog.rejected, (state) => {
        state.isServiceCatlogLoading = false;
      }) .addCase(versionHistoryServiceCatalog.pending, (state, action: PayloadAction<any>) => {
        state.isServiceCatlogLoading = false;
      })
      .addCase(versionHistoryServiceCatalog.fulfilled, (state, action: PayloadAction<any>) => {

        state.ServiceCatalogversionHistory.ServiceCatalogVHeader = action.payload.ServiceCatalogVHeader
        state.ServiceCatalogversionHistory.ServiceCatalogversionHistory = action.payload.ServiceCatalogversionHistory.map((user: any) => {
          user.Approved_On=chkDate(user.Approved_On);
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          // user.Approved_By = "";
          // user.Approved_On = "";
          return user;
      });

        state.isServiceCatlogLoading = false;
        // state.aQMToasterMsg = "AQM data deleted successfully";
      })
      .addCase(versionHistoryServiceCatalog.rejected, (state, action: PayloadAction<any>) => {
        state.isServiceCatlogLoading = false;
      })
  },
});

export const { updateServiceCatalogAddandEdit , updateServicecatalogVersionHistory } = serviceCatalogSlice.actions;
export default serviceCatalogSlice.reducer;
