import { Box, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageMainHeading from "../../../common/CommonHeadings/PageMainHeading";

import CustomSvg from "../../../common/CustomSvg";
import PageInnerHeading from "../../../common/CommonHeadings/PageInnerHeading";
import { addWorkingDays, getDateOrConvertSameTime, textFieldWidth } from "../../../utils/constants";
import LableandValuesPreview from "../../../common/CommonPreview/LableandValuesPreview";
import { Controller, useForm } from "react-hook-form";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { AQMMappingCOBQuestionsTable } from "./MappedQuestionListTable";
import { AAddAssessmentQuestionnaireforCOB } from "./AddAssessmentQuestionnaireforCOB";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { CustomerDetailsSection } from "../CommonPages/CustomerDetailsSection";
import { getLovList } from "../../../store/slices/lovMasterData";
import { getDepartmentList } from "../../../store/slices/departmentData";
import { RootState, useAppDispatch } from "../../../store/store";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { BulkUploadQuestionCOB } from "./QuestionbulkuploadforCOB/BulkUploadQuestionCOB";
import { BulkUploadQuestionCOBTemplate } from "./QuestionbulkuploadforCOB/BulkUploadQuestionCOBTemplate";
import { useSelector } from "react-redux";
import workFlowService from "../../../store/services/workFlowService";
import { Applicable_For, LovConfig, ToasterActivityConfig, VOBWorflowPages } from "../../../config/config";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { VendorHistory } from "../../vendorHistory/VendorHistory";
import { addAQMMappingCOB, updateAQMMappingCOBAddandEdit } from "../../../store/slices/assessmentQuestionnaireMappingCOB";
import vendorDetailsService from "../../../store/services/vendorDetails";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import CustomerAssessmentService from "../../../store/services/CustomerAssessmentService";
import { checkServiceMapping } from "./validationcobmapppingQuestion";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { useTheme } from "@mui/material/styles";
interface AQMMappingCOBProps {
  isEditData?: any;
  view?: boolean;
  edit?: boolean;
  isViewOnly?: boolean;
  currentProcessData?: any;
  prePageName?: any;
  onclose?: () => void;
}

interface FormData {
  Assessment_Questionaire_Mapping_Id?: any;
  Assessment_Number?: any;
  Vendor_Id?: any;
  Vendor_Name?: any;
  Validity_in_Months?: any;
  Assessment_Type_1?: any;
  Assessment_Type_2?: any;
  WorkFlow_Id?: any;
  Workflow_Name?: any;
  Start_Date?: any;
  Target_Date?: any;
}
export const AQMMappingCOB: React.FC<AQMMappingCOBProps> = ({
  isEditData,
  view,
  currentProcessData,
  prePageName,
  onclose,
  isViewOnly,
  edit,
}) => {
  const dispatch = useAppDispatch();
  const currentDate = new Date();
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  
  const theme = useTheme();

  const { dataTable: DepartmentData } = useSelector((state: RootState) => state.departmentData);
  const { AQMMappingToasterMsg , isAQMMappingAdded , isAddEditAQMMappingLoading } = useSelector((state: RootState) => state.assesmentQuestionnaireMappingCOBData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const {  allUsers } = useSelector((state: RootState) => state.userData);
  const [assesmentTypeILV, setassesmentTypeILV] = useState<any[]>([]);
  const [assesmentTypeIILV, setassesmentTypeIILV] = useState<any[]>([]);
  const [customerName, setCustomerName] = useState<any>("ABC Ltd");
  const [openCustomerDetails, setOpenCustomerDetails] = useState<boolean>(true);
  const [ProjectTAT, setProjectTAT] = useState<any>(1);
  const [HolidayData, setHolidayData] = useState<any[]>([]);
  const [QuestionData, setQuestionData] = useState<any[]>([]);
  const [addQuestion, setaddQuestion] = useState<boolean>(false);
  const [EditQuestion, setEditQuestion] = useState<boolean>(false);
  const [deleteQuestion, setdeleteQuestion] = useState<boolean>(false);
  const [isuploadQuestion, setisuploadQuestion] = useState<boolean>(false);
  const [EditData, setEditData] = useState<any>(undefined);
  const [EditDataindex, setEditDataindex] = useState<any>(undefined);
  const [customerDetails, setcustomerDetails] = useState<any>({});
  const [allDepartmentsList, setAllDepartmentsList] = useState<any[]>([]);
  const [SMEDepartmentsList, setSMEDepartmentsList] = useState<any[]>([]);
  const [allUserList, setallUserList] = useState<any[]>([]);
  //----
  const [SmeQuestionAvailable, setSmeQuestionAvailable] = useState<boolean>(false);
  const [SmeResponseStep, setSmeResponseStep] = useState<any>("");
  const [VendorDetails, setVendorDetails] = useState<any>(undefined);
  const [CustomerAllAssessment, setCustomerAllAssessment] = useState<any>([]);
  const [Mapped_Services, setMapped_Services] = useState<any>([]);
  const [CustomerAllAssessmentresponseget, setCustomerAllAssessmentresponseget] = useState<any>(false);

  let formData = {
    Assessment_Questionaire_Mapping_Id: isEditData ? isEditData?.Assessment_Questionaire_Mapping_Id : 0,
    Assessment_Number: isEditData ? isEditData?.Assessment_Number : "",
    Vendor_Id: isEditData ? isEditData?.Vendor_Id : "",
    Vendor_Name: isEditData ? isEditData?.Vendor_Name : "",
    Validity_in_Months: isEditData ? isEditData?.Validity_in_Months : 12,
    Assessment_Type_1: isEditData ? isEditData?.Assessment_Type_1 : "",
    Assessment_Type_2: isEditData ? isEditData?.Assessment_Type_2 : "",
    WorkFlow_Id: isEditData ? isEditData?.WorkFlow_Id : "",
    Workflow_Name: isEditData ? isEditData?.WorkFlow_Name : "",
    Start_Date: isEditData ? (isEditData?.Start_Date ? dayjs(isEditData?.Start_Date) : null) : null,
    Target_Date: isEditData ? (isEditData?.Target_Date ? dayjs(isEditData?.Target_Date) : null) : null,
  };

  const {
    handleSubmit: handleAQMMappingForm,
    reset,
    setValue,
    formState: { errors: errorsAQMMappingform, isDirty },
    control,
    clearErrors,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
    dispatch(
      getDepartmentList({
        FormID: 2,
      })
    );
  }, []);

  useEffect(() => {
    if (allUsers) {
      setallUserList(allUsers);
    }
  }, [allUsers]);

  useEffect(() => {
    if(SmeResponseStep && allUserList?.length > 0 && allDepartmentsList?.length > 0  ){
       findsmeDepartmentlist()
    }

  },[SmeResponseStep , allDepartmentsList , allUserList  ])

  useEffect(() => {
    if(isEditData && isEditData?.AssessmentQuestionnaires){
        console.log(isEditData , "isEditData"  , JSON.parse(isEditData?.AssessmentQuestionnaires));
        const ModifiedData = JSON.parse(isEditData?.AssessmentQuestionnaires).map((item : any)  => ( {...item , assessmentQuestionnaireOptions : item?.AssessmentQuestionnaireOptions  }));
        setQuestionData(ModifiedData)
        
    }

  },[isEditData])

  useEffect(() => {
    setassesmentTypeILV(lovTable.filter((item: any) => item.Name === LovConfig?.Assessment_Type) || []);
    setassesmentTypeIILV(lovTable.filter((item: any) => item.Name === LovConfig?.Assessment_TypeII) || []);
  }, [lovTable]);
  useEffect(() => {
    dispatch(updateLoader(isAddEditAQMMappingLoading));
  },[isAddEditAQMMappingLoading])

  useEffect(() => {
    if (isAQMMappingAdded) {
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg:AQMMappingToasterMsg,
        })
      );
      dispatch(updateAQMMappingCOBAddandEdit(false));
    
      if(isAQMMappingAdded && onclose){
        onclose()
      }
   
    }
  }, [isAQMMappingAdded]);

  useEffect(() => {

   const callfindedneedinSmeQuestion = async () => {
      if(QuestionData?.length > 0){
        let findedneedinSmeQuestion  = await needinSmeQuestion(QuestionData);
        setSmeQuestionAvailable(findedneedinSmeQuestion)
      }
    }

     callfindedneedinSmeQuestion()
  },[QuestionData , SmeResponseStep])

 const needinSmeQuestion = async (QuestionList: any): Promise<boolean> => {
  
    if (!QuestionList || QuestionList.length === 0) return false;
    if (!SmeResponseStep) return true;

    const results = await Promise.all(
      QuestionList.map(async (item: any) => {
        return item.IsSME === true;
      })
    );
    return results.includes(true);
  };

  const findsmeDepartmentlist = () => {
    let SMEResponseUserDepartmentsanduserName: any[] = [];
    //find sme response user Department
    if (SmeResponseStep && SmeResponseStep?.Primary_User) {
      let SmeresponseEachUser = SmeResponseStep?.Primary_User + SmeResponseStep?.Secondary_User;
      const userIdArrayResponse: string[] = SmeresponseEachUser.split(",").map((secid: any) => Number(secid.trim()));

      userIdArrayResponse.map((departMentDataId: any) => {
        let finedUseData = allUserList.find((userItem) => userItem.UserId == departMentDataId);
        let departmentData : any = allDepartmentsList.find((item : any) => item.DepartmentId == finedUseData?.DepartmentId)
        SMEResponseUserDepartmentsanduserName.push(departmentData)
      });
      setSMEDepartmentsList(SMEResponseUserDepartmentsanduserName)
    }
  }

  useEffect(() => {
    if (watch()) {
      let cuDetails: any = getValues();
      cuDetails.Assessment_Type_1 =
        assesmentTypeILV.find((item) => item.LovId == cuDetails.Assessment_Type_1)?.Options || "";
      cuDetails.Assessment_Type_2 =
        assesmentTypeIILV.find((item) => item.LovId == cuDetails.Assessment_Type_2)?.Options || "";
      setcustomerDetails(cuDetails);
    }
  }, [watch(), getValues(), assesmentTypeILV, assesmentTypeIILV]);

  useEffect(() => {
    const callVendorDetails = async (venId: any) => {
      try {
        const res = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: Number(venId) });

        setVendorDetails(res.data);
      } catch (error) {
        console.error("Error fetching vendor details:", error);
      }
    };
    const callthiscustomerAllAssessment = async (venId: any) => {
      try {
        const res = await CustomerAssessmentService.getCustomerAssessmentbycustomerIdorall({ customerId: Number(venId) });
        setCustomerAllAssessmentresponseget(true)
        setCustomerAllAssessment(res.data);
      } catch (error) {
        console.error("Error fetching vendor details:", error);
      }
    };
    if (currentProcessData && currentProcessData?.VendorId) {
      callVendorDetails(currentProcessData?.VendorId);
      callthiscustomerAllAssessment(currentProcessData?.VendorId);
    }

  }, [currentProcessData]);



  useEffect(() => {
    if (assesmentTypeIILV && assesmentTypeIILV.length > 0 && currentProcessData && !view) {
   
      if (currentProcessData?.IsRenewal === true) {
        let findAssessmentIIRenewal =
          assesmentTypeIILV.find((item) => item.Options == Applicable_For.Renewal)?.LovId || "";
        setValue("Assessment_Type_2", findAssessmentIIRenewal);
      } else {
        let findAssessmentIIFirst =
          assesmentTypeIILV.find((item) => item.Options == Applicable_For.First_Assessment)?.LovId || "";
        setValue("Assessment_Type_2", findAssessmentIIFirst);
      }
    }
  }, [currentProcessData, assesmentTypeIILV]);

  useEffect(() => {
    if (VendorDetails && VendorDetails.VendorDetails && VendorDetails?.VendorDetails?.Assessment_TypeId && !view) {
      setValue("Assessment_Type_1", VendorDetails?.VendorDetails?.Assessment_TypeId);
    }
  }, [currentProcessData, VendorDetails]);

  useEffect(() => {
    if (VendorDetails && VendorDetails.VendorServiceMappings && !view && CustomerAllAssessmentresponseget && CustomerAllAssessment) {
              let mapservices : any[] = checkServiceMapping(VendorDetails.VendorServiceMappings , CustomerAllAssessment , "AQM" , currentProcessData?.Parent_ProjectId);
           
              setMapped_Services(mapservices);

    }
  }, [CustomerAllAssessment, , VendorDetails , CustomerAllAssessmentresponseget]);



  useEffect(() => {
    setAllDepartmentsList(DepartmentData);
  }, [DepartmentData]);

  const uploadQuestion = (val: boolean) => {
    setisuploadQuestion(val);
  };

  const closeform = () => {
    setEditQuestion(false);
    setaddQuestion(false);
    setdeleteQuestion(false);
    setEditData(undefined);
    setEditDataindex(undefined);
  };

  const onQuestionAdd = () => {
    setaddQuestion(true);
    setEditData(undefined);
    setEditDataindex(undefined);
  };

  const onQuestionChange = (row: any, isEdit: boolean, index: any) => {
    setEditData(row);
    setEditDataindex(index);
    if (isEdit) {
      setEditQuestion(true);
    } else {
      setdeleteQuestion(true);
    }
  };

  const addEditDeleteQuestion = (row: any) => {
    setQuestionData((prevData) => {
      if (addQuestion) {
        return [...prevData, row];
      }
      if (EditQuestion && typeof EditDataindex === "number") {
        return prevData.map((item, index) => (index === EditDataindex ? row : item));
      }
      if (deleteQuestion && typeof EditDataindex === "number") {
        return prevData.filter((_, index) => index !== EditDataindex);
      }
      return prevData;
    });

    closeform();
  };

  const handleSubmitBulkAssessmentQuestion = (data: any) => {
    uploadQuestion(false);
    setQuestionData((pre) => [...pre, ...data]);
  };

  useEffect(() => {
    const fetchWorkflowInstance = async () => {
      if (currentProcessData?.WorkflowId) {

        try {
          const fetchData = await workFlowService.getWorkflowInstanceById({
            workflowId: currentProcessData?.WorkflowId,
            InstanceId: currentProcessData?.Workflow_InstanceId,
          });

          const responseData = fetchData?.data;

          if (responseData?.Id) {
            try {
              let WorkflowProcessInstanceAsJson = JSON.parse(responseData?.WorkflowProcessInstanceAsJson);

              let smeResponseValue = WorkflowProcessInstanceAsJson.find(
                (smeResponseitem: any) => smeResponseitem?.FormName === VOBWorflowPages?.SMEResponse
              );
              if (smeResponseValue) {
                setSmeResponseStep(smeResponseValue);
              }
            } catch {
              console.log("workflow instance Parse Failed");
            }
          }
        } catch (error) {
          console.error("Error fetching workflow instance:", error);
        }
      }
    };

    if (currentProcessData && !view) {
      setValue("Vendor_Id", currentProcessData?.VendorId);
      setValue("Vendor_Name", currentProcessData?.Vendor_Name);
      setValue("WorkFlow_Id", currentProcessData?.WorkflowId);
      setValue("Workflow_Name", currentProcessData?.Workflow_Name);
      setValue("Assessment_Number", currentProcessData?.Assessment_Number);
      setValue("Start_Date", currentProcessData?.Workflow_Start_Date ? dayjs(currentProcessData?.Workflow_Start_Date) : null);
      setValue("Target_Date", currentProcessData?.Workflow_End_Date ? dayjs(currentProcessData?.Workflow_End_Date) : null);
      fetchWorkflowInstance();
      // setcustomerDetails(currentProcessData);
    }
  }, [currentProcessData]);

  useEffect(() => {
    if (assesmentTypeIILV && assesmentTypeIILV.length > 0 && currentProcessData && !view) {
     
      if (currentProcessData?.IsRenewal === true) {
        let findAssessmentIIRenewal =
          assesmentTypeIILV.find((item) => item.Options == Applicable_For.Renewal)?.LovId || "";
        setValue("Assessment_Type_2", findAssessmentIIRenewal);
      } else {
        let findAssessmentIIFirst =
          assesmentTypeIILV.find((item) => item.Options == Applicable_For.First_Assessment)?.LovId || "";
        setValue("Assessment_Type_2", findAssessmentIIFirst);
      }
    }
  }, [currentProcessData, assesmentTypeIILV]);

  useEffect(() => {
    if (VendorDetails && VendorDetails.VendorDetails && VendorDetails?.VendorDetails?.Assessment_TypeId) {
      setValue("Assessment_Type_1", VendorDetails?.VendorDetails?.Assessment_TypeId);
    }
  }, [currentProcessData, VendorDetails]);

  const handleSubmitAssessmentQuestion = (data: any) => {
   
    if(QuestionData && QuestionData.length > 0 && Mapped_Services && Mapped_Services?.length > 0){
    let finalformdata : any = data;
    
    finalformdata.Project_Detail_Id = currentProcessData ? currentProcessData?.ProjectId : 0;
    finalformdata.Updated_By = user?.UserName;
    finalformdata.Updated_On = currentDate;
    finalformdata.Status = 1;
    finalformdata.IsActive = true;
    finalformdata.IsRenewal = currentProcessData?.IsRenewal ? currentProcessData?.IsRenewal : false;
    finalformdata.Mapped_Services = JSON.stringify(Mapped_Services) 
    finalformdata.Assessment_Status = 266 

    finalformdata.assessmentQuestionnaires =
      QuestionData &&
      QuestionData.map((Questionitem: any) => ({
        ...Questionitem,
        Answer_Type: Questionitem.Answer_Type == "multiple" ? true : false,
        Updated_By: user?.UserName,
        Updated_On: currentDate,
        IsSME :  Questionitem.IsSME ? true : false ,
        EvidenceRequired :  Questionitem.EvidenceRequired ? true : false ,
        SME :  Questionitem.SME ? Questionitem.SME : 0 ,
        assessmentQuestionnaireOptions:
          Questionitem.Answer_Type == "multiple"
            ? Questionitem.assessmentQuestionnaireOptions.map((Optionitem: any) => {
                delete Optionitem.Id; // Remove the Id property
                return {
                  ...Optionitem,
                  Updated_By: user?.UserName,
                  Updated_On: currentDate,
                };
              })
            : null,
      }));
     
    let finalData: any = {
      assessmentQuestionnaireMapping: { ...finalformdata },
      Activity: ToasterActivityConfig.create,
      ModuleName: "Assessment Questionnaire Mapping",
      UserId: userLoginResponse?.UserId,
      Workflow_InstanceId: currentProcessData ? currentProcessData?.Workflow_InstanceId : 0,
      Process_InstanceId: currentProcessData ? currentProcessData?.Id : 0,
    };
  
    dispatch(addAQMMappingCOB(finalData));
  }else{
      
  }
  };

  return (
    <>
      <Stack spacing={2}>
        {!isViewOnly && (
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <CustomBreadcrumbs
              list={[
                { value: 0, name: prePageName ? prePageName : "Assessment Questionnaire Mapping" },
                { value: 1, name: `${edit ? "Edit" : view ? "View" : "Add New"}` },
              ]}
              onchange={onclose}
            />

            {currentProcessData && (
              <Box sx={{ width: "20" }}>
                <VendorHistory isEditData={currentProcessData} />
              </Box>
            )}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
          }}
        >
          <PageMainHeading title={`${currentProcessData?.Vendor_Name || ""}`} />
        </Box>
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <CustomerDetailsSection isEditData={customerDetails} />

          <Divider />
        </Box>

        <Box>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
            >
              <Controller
                name={`Validity_in_Months`}
                control={control}
                defaultValue={0}
                rules={{
                  required: "Validity Field is Required",
                  validate: (data: number | undefined) => {
                    if (data === undefined || isNaN(data)) {
                      return "Validity Field is Required";
                    }
                    const number = parseInt(data.toString(), 10);
                    let valuesFrom = 1;
                    let valuesTo = 60;
                    const numberFrom = parseInt(valuesFrom?.toString() ?? "", 10);
                    const numberTo = parseInt(valuesTo?.toString() ?? "", 10);

                    // Check if valuesFrom and valuesTo are valid numbers
                    if (isNaN(numberFrom) || isNaN(numberTo)) {
                      return "Please enter valid numbers for the scoring range.";
                    }

                    // Check if it's a valid number and falls within the specified range
                    if (isNaN(number) || number < numberFrom || number > numberTo) {
                      return `Please enter a number between ${numberFrom} and ${numberTo}`;
                    }

                    return true;
                  },
                }}
                render={({ field }) => (
                  <CustomNumberField
                    name={"Validity In Months"}
                    required={true}
                    readOnly={view || isViewOnly}
                    label={"Validity In Months"}
                    value={field.value}
                    onChange={field.onChange}
                    error={Boolean(errorsAQMMappingform.Validity_in_Months)}
                    helperText={
                      errorsAQMMappingform.Validity_in_Months &&
                      errorsAQMMappingform.Validity_in_Months.message?.toString()
                    }
                  />
                )}
              />
            </Grid>
          
          </Grid>

          <Divider />
        </Box>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "13px" }}>
            <PageInnerHeading title={`Question List`} />

          {!isViewOnly &&  <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
              <BulkUploadQuestionCOBTemplate DepartmentListdata={SMEDepartmentsList} />
              <CustomButton
                type="reset"
                variant="text"
                startIcon={<CustomSvg  color={`${theme.palette.primary.main}`} size={"16px"} name={"import"} />}
                name={"Upload Questionnaire"}
                onClick={() => uploadQuestion(true)}
              />
              <Divider orientation="vertical" flexItem />
              <CustomButton
                type="reset"
                variant="outlined"
                startIcon={<CustomSvg color={`${theme.palette.primary.main}`} size={"16px"} name={"plus"} />}
                name={"Add Question"}
                onClick={() => onQuestionAdd()}
              />
            </Box> }
          </Box>

          <Divider />
        </Box>

        <Box>
          <AQMMappingCOBQuestionsTable view={view || isViewOnly} QuestionList={QuestionData} onclickChange={onQuestionChange} />
        </Box>
        
       

   {!isViewOnly && <Box sx={{ mt: 2 }}>
    {Mapped_Services?.length == 0 &&    <Typography sx={{ fontSize: "8px", textAlign: "left", color: Mapped_Services?.length == 0 ? "red" : "#6A6A6A" }}>
               { `Warning : All available services have already been used in other assessments. Please add new services.`}
                  </Typography> }

                  {!SmeQuestionAvailable &&  <Typography sx={{mt:1, fontSize: "8px", textAlign: "left", color: Mapped_Services?.length == 0 ? "red" : "#6A6A6A" }}>
               { `Note : The SME Response process is available for this customer onboarding. Please ensure at least one SME question is selected.`}
                  </Typography>}
                </Box> }

       {!isViewOnly && <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", gap: 2, width: "100%" }}>
          <CustomButton
            type="reset"
            variant="outlined"
            name={"Close"}
            onClick={() => {
              onclose && onclose();
            }}
          />
          <CustomButton type="reset" disabled={QuestionData?.length == 0 ||  Mapped_Services?.length == 0 || !SmeQuestionAvailable} name={"Submit"} onClick={handleAQMMappingForm(handleSubmitAssessmentQuestion)} />
        </Box> }

    
      </Stack>

      <CustomDialog
        show={EditQuestion || addQuestion}
        onHide={() => {
          closeform();
        }}
        maxWidth={"lg"}
        header={addQuestion ? "Add New Question" : "Edit Question"}
        contentNode={
          <>
            <AAddAssessmentQuestionnaireforCOB
              SMEDepartmentsListdata={SMEDepartmentsList}
              onclicksave={addEditDeleteQuestion}
              onclose={() => closeform()}
              isEditData={EditData}
              ExistingData={QuestionData}
              EditDataindex={EditDataindex}
            />
          </>
        }
      />

      <CustomDialog
        show={isuploadQuestion}
        onHide={() => {
          uploadQuestion(false);
        }}
        maxWidth={"lg"}
        header={"Upload Questions"}
        contentNode={
          <>
            <BulkUploadQuestionCOB
              handleSubmitBulkAssessmentQuestion={handleSubmitBulkAssessmentQuestion}
              DepartmentListdata={SMEDepartmentsList}
              onclose={() => uploadQuestion(false)}
              ExistingData={QuestionData}
            />
          </>
        }
      />

      <ModalPopUp
        onDeleteMethod={() => addEditDeleteQuestion(EditData)}
        isDelete={true}
        onHide={() => closeform()}
        show={deleteQuestion && EditData}
        ActionName={"Delete"}
        header={"Confirm"}
        text={`Are you sure you want to delete : ${EditData?.Question} , this Question ?`}
      />
    </>
  );
};
