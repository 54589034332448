import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomSelect from "../../../../../../common/dynamicInputs/CustomSelect";
import CustomNumberField from "../../../../../../common/dynamicInputs/CustomNumberField";
import CustomMultilineTextField from "../../../../../../common/dynamicInputs/CustomMultilineTextField";
import CustomButton from "../../../../../../common/dynamicButton/CustomButton";
import { textFieldWidth } from "../../../../../../utils/constants";
import CustomTextField from "../../../../../../common/dynamicInputs/CustomTextField";
import { DynamicToggleButton } from "../../../../../../common/dynamicToggle/DynamicToggleButton";
import CustomToogleinput from "../../../../../../common/dynamicInputs/CustomToogleInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { AQMConfig, LovConfig, MetrixMasterConfig, PM_MeasuredIn } from "../../../../../../config/config";
import CustomDatePicker from "../../../../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import { findMeasuredInDate, setSeverityColorValue, setSLASeverityColorValue } from "../addSLAValidation";

interface AddSLAprops {
  view?: boolean;
  closePopUp?: any;
  edit?: boolean;
  isEditData?: any;
  add?: boolean;
  handlesubmit : (val : any) => void
}

interface FormData {
    sla : any
  measured_In : any
  measured_In_Name : any
  target_Min : any
  target_Max : any
  target_min_date : any
  target_max_date : any
  tolerance_Level : any
  bestScore : any
  severity_Of_SLA : any
  severity_Of_SLA_Name : any
  remark :any
  
}
export const AddSLA: React.FC<AddSLAprops> = ({ view, closePopUp, edit, isEditData , handlesubmit }) => {
      const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
       const performanceManagementReducer: any = useSelector((state: RootState) => state?.performanceManagementReducer);
      const {  SeverityMasterTable }: any = performanceManagementReducer.SeverityMasterDataSlice;

  const [measuredList, setmeasuredList] = useState<any>([]);
  const [severity_of_slaList, setseverity_of_slaList] = useState<any>([]);
  useEffect(() => {
    if (SeverityMasterTable) {
      let findedRiskType = SeverityMasterTable.filter(
        (risk_item : any) =>  risk_item?.IsActive === "Active"
      );
       setseverity_of_slaList(setSLASeverityColorValue(findedRiskType || []));
    }
  }, [SeverityMasterTable]);


  let formData: any = {
    pR_Clause_SLA_Id: isEditData ? isEditData.pR_Clause_SLA_Id : 0,
    sla: isEditData ? isEditData.sla : "",
    measured_In: isEditData ? isEditData?.measured_In : "",
    measured_In_Name : isEditData ? isEditData?.measured_In_Name : "",
    target_Min : isEditData ? isEditData?.target_Min : "",
    target_Max: isEditData ? isEditData?.target_Max : "",
    target_min_date : isEditData ? dayjs(isEditData?.target_min_date) :  null,
    target_max_date: isEditData ? dayjs(isEditData?.target_max_date) : null,
    tolerance_Level: isEditData ? isEditData?.tolerance_Level : "",
    bestScore : isEditData ? isEditData?.bestScore : "",
    severity_Of_SLA: isEditData ? isEditData?.severity_Of_SLA : "",
    severity_Of_SLA_Name: isEditData ? isEditData?.severity_Of_SLA_Name : "",
    remark: isEditData ? isEditData?.remark : "",
  };
  const {
    handleSubmit: handleSLA,
    reset,
    setValue,
    formState: { errors: errorssla, isDirty },
    control,
    watch,
    resetField,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const findandsetmeasuredInName = (id: any) => {
        
      let value = measuredList?.find((item : any) => item.LovId == id)?.Options
      if(value){
        setValue("measured_In_Name" , value)
      }
    
  }

  const findandsetseverityOfSLAName = (id: any) => {
        
    let value = severity_of_slaList?.find((item : any) => item.severityId == id)?.severity
    if(value){
      setValue("severity_Of_SLA_Name" , value)
    }
  
}

const setColor = () => {

}


  useEffect(() => {
  
      resetField("target_Min");
      resetField("target_Max");
      resetField("target_min_date");
      resetField("target_max_date");
    
  }, [watch("measured_In")]);

  useEffect(() => {
    setmeasuredList(lovTable.filter((item: any) => item.Name === LovConfig?.Measured_In) || []);
  }, [lovTable]);
  const SubmitForm =(val : any) => {
    handlesubmit(val)
  }

  return (
    <>
      <Box>
        <Grid container spacing={2}>
        <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
        <Controller
                    name="sla"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "SLA is Required",
                      validate: (data) => {
                        return  data.length > 5 || "Minimal 5 characters requires";
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"sla"}
                        required={true}
                        label={"SLA"}
                        value={field.value}
                        disabled={view}
                        onChange={field.onChange}
                        error={Boolean(errorssla.sla)}
                        helperText={errorssla.sla && errorssla.sla.message?.toString()}
                      />
                    )}
                  />
                     </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="measured_In"
              control={control}
            //   defaultValue={""}
              rules={{ required: "Measured In is required" }}
              render={({ field }) => (
                <CustomSelect
                  name={"measured_In"}
                  valueKey={"LovId"}
                  optionLabelKey="Options"
                  options={measuredList}
                  readOnly={view}
                  required={true}
                  label={"Measured In"}
                  value={field.value}
                  onChange={(e) => {field.onChange(e) ; findandsetmeasuredInName(e)}}
                  error={Boolean(errorssla.measured_In)}
                  helperText={errorssla.measured_In?.message?.toString()}
                />
              )}
            />
          </Grid>
          { !findMeasuredInDate(watch("measured_In") , measuredList)   ? <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="target_Min"
              control={control}
              defaultValue={""}
              rules={{
                required: "Target Min is required",
                validate: (data: string | undefined) => {
                  if (data === undefined || data.trim() === "") {
                    return "Target Min is Required";
                  }
                  

                  if (Number(data) < 1) {
                    return "Minimun Target Min is Required";
                  }

                  return true;
                },
              }}
              render={({ field }) => (
                <CustomNumberField
                  label={"Target Min"}
                  name={"target_Min"}
                  value={field.value}
                  isDecimal={true}
                  required={true}
                  onChange={field.onChange}
                  error={Boolean(errorssla.target_Min)}
                  helperText={
                    errorssla.target_Min && errorssla.target_Min.message?.toString()
                  }
                />
              )}
            /> 
        </Grid> :  <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
          <Controller
                    name="target_min_date"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "Target Min is Required" }}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={ dayjs()}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"Target Min"}
                        value={field.value ? dayjs(field.value) : null}
                        required={true}
                        readOnly={view}
                        onChange={field.onChange}
                        error={Boolean(errorssla.target_min_date)}
                        helperText={
                          errorssla.target_min_date &&
                          errorssla.target_min_date.message?.toString()
                        }
                      /> )}
                      />
                     </Grid> }
          {  !findMeasuredInDate(watch("measured_In") , measuredList)   ?   <Grid
          key={"num_field"}
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="target_Max"
              control={control}
              defaultValue={""}
              rules={{
                required: "Tartget Max is required",
                validate: (data: string | undefined) => {
                  if (data === undefined || data.trim() === "") {
                    return "Target Max is Required";
                  }
                  

                  if (Number(data) < 1) {
                    return "Minimun Target Max is Required";
                  }
                  if (Number(watch("target_Min")) && Number(data) <= Number(watch("target_Min"))) {
                    return "Target Max is not smaller then or equal Target Min";
                  }
                  return true;
                },
              }}
              render={({ field }) => (
                <CustomNumberField
                  label={"Tartget Max"}
                  name={"target_Max"}
                  value={field.value}
                  isDecimal={true}
                  required={true}
                  onChange={field.onChange}
                  error={Boolean(errorssla.target_Max)}
                  helperText={
                    errorssla.target_Max && errorssla.target_Max.message?.toString()
                  }
                />
              )}
            />
          </Grid> :  <Grid
            item
            key={"date_field"}
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
          <Controller
                    name="target_max_date"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "Target Max is Required" , validate: {
                                          startDateValidation: (value) => {
                                          let  reviewStartDate = watch("target_min_date")
                                            if (reviewStartDate)
                                              return (
                                                dayjs(value) > dayjs(reviewStartDate) ||
                                                "Target Max Date cannot be less than or equal Target Min Date."
                                              );
                                          },} }}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={ dayjs()}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"Target Max"}
                        value={field.value ? dayjs(field.value) : null}
                        required={true}
                        readOnly={view}
                        onChange={field.onChange}
                        error={Boolean(errorssla.target_max_date)}
                        helperText={
                          errorssla.target_max_date &&
                          errorssla.target_max_date.message?.toString()
                        }
                      /> )}
                      />
                     </Grid> }
         
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="tolerance_Level"
              control={control}
              defaultValue={""}
              rules={{
                required: "Tolerance Level is required",
                validate: (data: string | undefined) => {
                  if (data === undefined || data.trim() === "") {
                    return "Tolerance Level is Required";
                  }
                  

                  if (Number(data) < 1) {
                    return "Minimun Tolerance Level % is Required";
                  }

                  if (Number(data) > 100) {
                    return "Maximum Tolerance Level % is 100";
                  }

                  return true;
                },
              }}
              render={({ field }) => (
                <CustomNumberField
                  label={"Tolerance Level %"}
                  name={"tolerance_Level"}
                  value={field.value}
                  isDecimal={true}
                  required={true}
                  onChange={field.onChange}
                  error={Boolean(errorssla.tolerance_Level)}
                  helperText={
                    errorssla.tolerance_Level && errorssla.tolerance_Level.message?.toString()
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="bestScore"
              control={control}
              defaultValue={""}
              rules={{
                required: "Best Score is required",
                validate: (data: string | undefined) => {
                  if (data === undefined || data.trim() === "") {
                    return "Best Score is Required";
                  }
                  

                  if (Number(data) < 1) {
                    return "Minimun best score is Required";
                  }

                  if (Number(data) > 4) {
                    return "Maximum best score is 4";
                  }

                  return true;
                },
              }}
              render={({ field }) => (
                <CustomNumberField
                  label={"Best Score"}
                  name={"bestScore"}
                  value={field.value}
                  isDecimal={true}
                  required={true}
                  onChange={field.onChange}
                  error={Boolean(errorssla.bestScore)}
                  helperText={
                    errorssla.bestScore && errorssla.bestScore.message?.toString()
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="severity_Of_SLA"
              control={control}
              defaultValue={""}
              rules={{ required: "Review Type Required is required" }}
              render={({ field }) => (
                <CustomToogleinput
                  name={"severity_Of_SLA"}
                  valueKey="severityId"
                  optionLabelKey="severity"
                  options={severity_of_slaList}
                  readOnly={view}
                  required={true}
                  label={"Severity Of SLA"}
                  value={field.value}
                  onChange={(e) => {field.onChange(e) ; findandsetseverityOfSLAName(e)}}
                  error={Boolean(errorssla.severity_Of_SLA)}
                  helperText={errorssla.severity_Of_SLA?.message?.toString()}
                />
              )}
            />
        </Grid>
        </Grid>
        <Box sx={{mt:2}}>
        <Controller
              name="remark"
              control={control}
              //defaultValue={}
              rules={{
                required: "Remark is required",
                validate: (data) => {

                  if (data === undefined || data.trim() === "") {
                    return "Remark is Required";
                  }
                  if (data.length < 5) {
                    return "Minimal 5 characters required";
                  }
                  if (data.length > 100) {
                    return "Maximum 100 characters is allowed";
                  }
                  return true; 
                },
              }}
              render={({ field }) => (
        <CustomMultilineTextField
                  label={"Remark"}
                  value={field.value}
                  disabled={view}
                  onChange={field.onChange}
                  rows={2}
                  required={true}
                  error={Boolean(errorssla?.remark)}
                  helperText={
                    errorssla?.remark &&
                    errorssla?.remark?.message?.toString()
                  }
                />
            )}
            />
        </Box>

        <Box
                                sx={{
                                    display: "flex",
                                    gap: "20px",
                                    justifyContent: "end",
                                    mt: 2,
                                }}
                            >
                               
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton
                                            type="reset"
                                            disabled={!isDirty}
                                            name={"Submit"}
                                            onClick={handleSLA(SubmitForm)}
                                        />
                                    </React.Fragment>
                               
                            </Box>
      </Box>
    </>
  );
};
