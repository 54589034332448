import { Box, Stack, Typography } from "@mui/material"
import CustomStepper from "../../common/dynamicSteper/CustomStepper"
import { useEffect, useState } from "react";
import DynamicBasicTable from "../../common/dynamicbasicTable/DynamicBasicTable";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../store/store";
import moment from "moment";
import { CustomStatusStepper } from "../../common/dynamicSteper/CustomStatusStepper";
import { getWorkFlowHistoryView } from "../../store/slices/workFlowHistoryData";


export interface WorkFlowHistoryProps {
    view?: boolean;
    closePopUp?: () => void;
    edit?: boolean;
    lovID?: any;
    isEditData?: any;
}

export const WorkFlowHistorySteper: React.FC<WorkFlowHistoryProps> = ({
    view, closePopUp, edit, lovID, isEditData
}) => {

    const dispatch = useAppDispatchThunk();
    const [activestepCount, setactivestepCount] = useState(2);
    const [WorkFlowViewData, setWorkFlowViewData] = useState<any[]>([]);
    const [ProcessTooltip, setProcessTooltip] = useState<any[]>([]);

    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );
    const { WorkFlowHistoryTable, WorkFlowHistoryViewData, ToasterMsg, isLoading } = useSelector(
        (state: RootState) => state.WorkFlowHistoryData
    );

    const main_steps = WorkFlowViewData?.map((item: any) => {
        return item.Process_Name;
    });


    const Complete_data = WorkFlowViewData?.filter((item: any) => {
        return item.Process_Status === "Completed";
    });

    const Active_step_Count = Complete_data?.length - 1
    const Formate_Complete_data = Complete_data?.map((item: any) => {
        return item.Process_Name;
    });

    const Complete_steps = main_steps?.reduce((acc, step, index) => {
        if (Formate_Complete_data?.includes(step)) {
            acc.push(index);
        }
        return acc;
    }, [] as number[]);

    const error_steps: number[] | undefined = []

    useEffect(() => {
        dispatch(
            getWorkFlowHistoryView({
                Workflow_Instanceid: isEditData?.Workflow_Instance_Id || isEditData?.Workflow_InstanceId,
            })
        );
    }, []);

    useEffect(() => {
      
        setWorkFlowViewData(WorkFlowHistoryViewData?.Process);

    }, [WorkFlowHistoryViewData]);

    const FormateTriggered_On = moment(WorkFlowHistoryViewData?.Triggered_On).format("DD-MMM-YYYY HH:mm");


    const handleOpen = (index: any, label: any) => {

        const Complete_data = WorkFlowViewData?.filter((item: any) => {
            return item.Process_Name === label;
        });
        setProcessTooltip(Complete_data)
    };

    return (


        <Box sx={{ width: "100%", paddingTop: 2 }}>


            <Stack spacing={6}>

                <CustomStatusStepper activeStep={Active_step_Count} steps={main_steps} errorSteps={error_steps} CompleteSteps={Complete_steps} handleAction={handleOpen} TooltipData={ProcessTooltip}/>

                <Box
                    sx={{
                        mt: 2
                    }}
                >
                    {activestepCount == 0 &&

                        <></>
                        // < CompanyMasterMain
                        //     handlePrevious={handlePrevious}
                        //     handleNext={handleNext}
                        // />
                    }


                </Box>
            </Stack>

            <Box
                sx={{
                    display: "flex",
                    // flexDirection: "row",
                    width: "35%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    pl: 3, pb: 1,
                }}
            >
                <Box sx={{ display: "flex", width: "250px", }}>
                    <Typography variant="body1" color={"text.primary"}>
                        Triggered By
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", width: "250px", }}>
                    <Typography variant="body2" color={"text.primary"}>
                        {/* {isEditData?.Triggered_By || isEditData?.Updated_By} */}
                        {WorkFlowHistoryViewData.Triggered_By}

                    </Typography>
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    // flexDirection: "row",
                    width: "35%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    pl: 3,
                }}
            >
                <Box sx={{ display: "flex", width: "250px", }}>
                    <Typography variant="body1" color={"text.primary"}>
                        Triggered On
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", width: "250px", }}>
                    <Typography variant="body2" color={"text.primary"}>
                        {FormateTriggered_On}
                        {""}
                    </Typography>
                </Box>
            </Box>


        </Box>
    )
}
