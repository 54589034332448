import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IMenu } from "../../../models/loggin.type";
import { RootState, useAppDispatch } from "../../../store/store";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { getHolidayWithdaterange } from "../../../store/slices/holidayMasterData";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import { ApproveRejectScope } from "./scopeApprovalPreview/ApproveRejectScope";
import { InitiateReview } from "../InitiateReview/InitiateReview";
import ScopeDefineService from "../../../store/performanceManagement/services/scopeDefine";
import { getPerfomenceScopeDetails } from "../../../store/contractManagement/slices/PMContractListSlice";
import { PMScopeAddandEditUpdate } from "../../../store/performanceManagement/slices/defineScope";
import { PR_Scope_Status } from "../../../config/config";


export default function ScopeApprovel() {
    const dispatch = useAppDispatch();
    const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
    const [addForm, setAddForm] = useState(false);
    const [Option, setOption] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [IsEditData, setIsEditData] = useState<any[]>([]);
    const [IsRowData, setIsRowData] = useState<any[]>([]);

    const [AddorEditOpen, setAddorEditOpen] = useState<boolean>(false);
    const { currentpageTitleData } = useSelector(
        (state: RootState) => state.FormTitleMasterData
    );

    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );

    const { contractDetailsData, PerformaceScopeDetails } = useSelector(
        (state: any) => state.contractManagementReducer?.PMContractListSlice
    );
    const { isScopeApproveandReject, ToasterMsg } = useSelector(
        (state: any) => state.performanceManagementReducer?.PMScopeDataSlice
    );
    const { handleRecordLockUpdate } = RecordLockRelease();


    const columns: any = {
        table_name: "Scope Approval",
        isAction: true,
        columns: [

            {
                Field: "contractRefNumber",
                DisplayName: "Contract Number",
                FieldType: "string",
            },
            {
                Field: "pM_Ref_Number",
                DisplayName: "PM Ref Number",
                FieldType: "string",
            },
            {
                Field: "contractTypeName",
                DisplayName: "Type",
                FieldType: "string",
            },
            {
                Field: "companyName",
                DisplayName: "Company",
                FieldType: "string",
            },
            {
                Field: "vendorName",
                DisplayName: "Vendor",
                FieldType: "string",
            },
            {
                Field: "service_Name",
                DisplayName: "Service",
                FieldType: "string",
            },
            {
                Field: "effective_from",
                DisplayName: "Effective From",
                FieldType: "date",
            },
            {
                Field: "clauseCount",
                DisplayName: "No of SLA/Clauses",
                FieldType: "string",
            },
            {
                Field: "Outliers",
                DisplayName: "No of Outliers",
                FieldType: "string",
            },
            {
                Field: "prStatus",
                DisplayName: "Approval Status",
                FieldType: "string",
            },

        ],
    };

    useEffect(() => {
        dispatch(getPerfomenceScopeDetails({}));
    }, []);

    console.log(PerformaceScopeDetails, "PerformaceScopeDetails");

    useEffect(() => {
        if (currentPagePermissionData !== null) {
            setPermissionForpage(currentPagePermissionData);

        }
    }, [currentPagePermissionData]);

    const handleOpen = async (data: any, Option: string) => {

        if (Option === "next") {
            const res = await ScopeDefineService.getScopeById({ id: data.prRef_Id });
            setOption(Option);
            setIsRowData(data)
            setIsEditData(res);
            setIsEdit(true);
            setAddorEditOpen(true);

        } else if (Option === "view") {
            const res = await ScopeDefineService.getScopeById({ id: data.prRef_Id });
            setOption(Option);
            setIsEditData(res);
            setIsRowData(data)
            setIsEdit(true);
            setAddorEditOpen(true);

        } else if (Option === "delete") {

            setOption(Option);

        }
    };

    useEffect(() => {
        if (isScopeApproveandReject) {
            dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
            dispatch(PMScopeAddandEditUpdate(false));
            setAddorEditOpen(false);
            dispatch(getPerfomenceScopeDetails({}));

        }
    }, [isScopeApproveandReject]);

    return (
        <>
             {!AddorEditOpen ? <>
                <Box
                    sx={{
                        width: "100%",

                    }}
                >
                    <Stack spacing={2}>
                        <Box
                            sx={{
                                display: "flex",
                                // flexDirection: "row",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography variant="h6" color={"text.primary"}>
                                {" "}
                                Scope Approval
                            </Typography>

                            <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
                        </Box>

                        <Box>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <MaterialDynamicGrid
                                    data={columns}
                                    rows={PerformaceScopeDetails?.filter((item: any) => item.prStatus === PR_Scope_Status.Approval_Pending)}
                                    handleAction={handleOpen}
                                    enableZoomIcon={true}
                                    handleAdd={() => setAddForm(true)}
                                    showAdd={false}
                                    showhistory={false}
                                    showDelete={false}
                                    showView={permssionPage?.IsView === 1}
                                    showEdit={false}
                                    showNext={true}
                                    propNextbtn={{ name: "Approve" }}
                                    // showNext={true}
                                    // propNextbtn={{name:"Define Scope"}}
                                    showExports={permssionPage?.IsExport === 1}
                                    pageName="ScopeApproval"

                                    userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                                />
                            </Box>
                        </Box>
                    </Stack>
                </Box>

                <CustomDialog
                    show={addForm}
                    onHide={() => setAddForm(false)}
                    maxWidth={"xs"}
                    minHeight={"50vh"}
                    header={`Add`}
                    contentNode={
                        <></>
                    }
                />
            </> : <>
                <Box sx={{ width: "100%" }}>
                    <ApproveRejectScope
                        view={Option == "view" ? true : false}
                        edit={Option == "edit" ? true : false}
                        isEditData={IsEditData}
                        RowData={IsRowData}
                        onClose={() => {
                            handleRecordLockUpdate();
                            setAddorEditOpen(false);
                            dispatch(PMScopeAddandEditUpdate(false));
                        }}
                    />
                </Box>
            </>} 
            {/* <InitiateReview
                view={Option == "view" ? true : false}
                edit={Option == "edit" ? true : false}
                isEditData={IsEditData}
                onClose={() => {
                    handleRecordLockUpdate();
                    setAddorEditOpen(false);
                }}
            /> */}
        </>
    );
}
