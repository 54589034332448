import React, { useEffect, useRef, useState } from "react";
import { Box, Collapse, Divider, FormHelperText, Grid, IconButton, Stack, Typography } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { RootState, useAppDispatch, useAppDispatchThunk } from "../../../store/store";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { Controller, FormProvider, useForm } from "react-hook-form";
import CustomAutoComplete from "../../../common/dynamicInputs/CustomAutoComplete";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";

import { useSelector } from "react-redux";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import CustomChip from "../../../common/dynamicChip/CustomChip";
import { ACTIVE, textFieldWidth } from "../../../utils/constants";

import CustomRadioButton from "../../../common/dynamicInputs/CustomRadioButton";
import CustomSvg from "../../../common/CustomSvg";
import { getCurrencyList } from "../../../store/slices/currencyData";
import { fileHandlerUpdate, UploadAFile } from "../../../store/slices/fileHandler";
import { getListOfValues } from "../../../store/slices/ListOfValues";
import { ContractManagemnt_InternalCode, LovConfig } from "../../../config/config";
import { getLocationList } from "../../../store/slices/locationMasterData";
import {
  PhysicalUploadResetState,
  PhysicalUploadSubmit,
} from "../../../store/contractManagement/slices/physicalUploadSlice";
import {
  getGetVCWorkflowProcessesData,
  updateCmWorkFlowInstance,
} from "../../../store/contractManagement/slices/contractRolesAndResponsiblity";
import { getTemplateById, savePreviewTemplate } from "../../../store/contractManagement/slices/templateData";
import { setEditorDocsId } from "../../../store/slices/docsIdData";
import { generateClauseHTML } from "../../../common/textEditor/generateClauseHTML";
import { updateHeaderMenu, updateMenu } from "../../../store/slices/menu";
import { asBlob } from "html-docx-js-typescript";
import { blobToBase64 } from "../ContractTemplate/ContractTemplate";
import OnlyOfficeTextEditor from "../../../common/textEditor/OnlyOfficeTextEditor";
import { ContractUploadAFile } from "../../../store/contractManagement/slices/contractFileHandler";

export type ContractClause = {
  clauseType: string;
  clauseHeader: string;
  service: string;
  sme: string;
  order: number;
  clause_Id: string;
};

interface formDataProps {
  Company: string;
  Vendor: string;
  consideration_value: any;
  currency_Id: any;
  attachment: any;
  nature_of_contract: any;
  stamping_location: any;
  stamping: any;
  stamp_duty: any;
  stamp_duty_currency_id: any;
  stamp_duty_paid_by: any;
  stamp_paper_procured_by: any;
}
export interface PhysicalUploadProps {
  isEditData?: any;
  onClose?: () => void;
}

const PhysicalUpload: React.FC<PhysicalUploadProps> = ({ isEditData, onClose }) => {
  const [getCurrency, setCurrency] = useState<any[]>([]);
  const { CurrencyData } = useSelector((state: RootState) => state.CurrencyData);
  const fileInputRef = useRef<HTMLInputElement>(null);
  let { workFLowInstanceData }: any = useSelector(
    (state: any) => state.contractManagementReducer?.contractRolesAndResponsibility
  );
  console.log(workFLowInstanceData, "workFLowInstanceData");
  const { lovTable } = useSelector((state: RootState) => state.listOfValues);
  const { locationTable } = useSelector((state: RootState) => state.locationMasterData);
  const [uploadError, setUploadError] = useState<string>("");
  const { userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { user } = useSelector((state: RootState) => state.userData);
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const [appParamGroup, setAppParamGroup] = useState<any>("");

  const [stamping, setStamping] = useState<any[]>([]);
  const [NatureOfContract, setNatureOfContract] = useState<any[]>([]);
  const [locationData, setLocationData] = useState<any[]>([]);
  const dispatch = useAppDispatchThunk();
  const [selectedFiles, setSelectedFiles] = useState<File | null>(null);
  const [isFileView, setIsFileView] = useState<boolean>(false);
  const [fileconvert, setFileConvert] = useState<string>("");
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const { isphysicalUploadDataAdded, PhysicalUploadToasterMsg } = contractManagementReducer.physicalUploadData;
  const { isFileUpload, uploadData } = contractManagementReducer.contractFileHandler;
  const [showPreviewContract, setPreviewContract] = useState<boolean>(false);

  function truncateFileName(fileName: string, maxWordLength = 10) {
    const lastDotIndex = fileName.lastIndexOf(".");
    const namePart = fileName.substring(0, lastDotIndex);
    const extension = fileName.substring(lastDotIndex);

    const words = namePart.split("-");

    const truncatedWords = fileName.length > maxWordLength ? fileName.slice(0, maxWordLength) + "..." : fileName;
    return truncatedWords + extension;
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type === "application/pdf") {
        try {
          const fileName = `${workFLowInstanceData?.contract_Tempate_Name}.pdf` || "NewFile.pdf";
          const renamedFile = new File([file], fileName, { type: file.type });
          updateLoader(true);
          dispatch(ContractUploadAFile({ uploadRequest: renamedFile })).then((data: any) => {
            if (data) {
              setSelectedFiles(renamedFile);
              setUploadError("");
            }
          });
        } catch (error) {
          updateLoader(false);
          setUploadError(error as string);
        }
      } else {
        setUploadError("Only PDF files are allowed");
      }
    } else {
      setUploadError("Please upload a PDF file");
    }
  };

  const handleDeleteFile = (fileToDelete: File) => {
    setSelectedFiles(null);
    setUploadError("");
  };

  useEffect(() => {
    if (isEditData !== null) {
      let request: any = null;
      if (isEditData?.ProjectId) {
        request = {
          projectId: isEditData?.ProjectId,
          workflowInstanceId: isEditData?.Workflow_InstanceId,
        };
      }
      dispatch(updateLoader(true));
      dispatch(getGetVCWorkflowProcessesData(request)).finally(() => {
        dispatch(updateLoader(false));
      });
    }
  }, []);
  console.log(isEditData, "isEditDataisEditData");
  useEffect(() => {
    if (isFileUpload) {
      updateLoader(false);
      if (uploadData as string) {
        setValue("attachment", uploadData);
      }
    }
    fileHandlerUpdate(false);
  }, [isFileUpload]);

  useEffect(() => {
    if (isEditData) {
      setValue("Company", isEditData?.Company);
      setValue("Vendor", isEditData?.Vendor);
    }
  }, [isEditData]);

  useEffect(() => {
    let stamping = [
      { value: 1, lable: "eStamping" },
      { value: 2, lable: "Physical" },
    ];
    setStamping(stamping);
  }, []);

  const breadcrumb = [
    { value: 0, name: "Pending Actions" },
    { value: 1, name: "Upload Contract" },
  ];

  let FormData = {
    Company: isEditData ? isEditData?.Company : "",
    Vendor: isEditData ? isEditData?.Vendor : "",
  };

  const callPageloading = () => {
    dispatch(getCurrencyList({ FormID: 0 }));
    dispatch(getListOfValues({ Name: LovConfig.Nature_of_contract }));
    dispatch(getLocationList({ FormID: 0 }));
  };

  useEffect(() => {
    callPageloading();
  }, []);

  useEffect(() => {
    if (lovTable[LovConfig.Nature_of_contract]) {
      setNatureOfContract(lovTable[LovConfig.Nature_of_contract].data);
    }
  }, [lovTable]);

  useEffect(() => {
    if (locationTable) {
      let changedValue = locationTable
        .map((item: any) => {
          return {
            ...item,
            End_Pin: item.End_Pin === 0 ? "" : item.End_Pin,
            Start_Pin: item.Start_Pin === 0 ? "" : item.Start_Pin,
          };
        })
        .filter((i: any) => i.LocationTypeValue === "State");
      setLocationData(changedValue);
    }
  }, [locationTable]);

  useEffect(() => {
    if (CurrencyData && CurrencyData.length > 0) {
      const ActiveCurrencies = CurrencyData.filter((item: any) => item.IsActive === ACTIVE);
      const MergedCurrencies = ActiveCurrencies.map((item: any) => ({
        ...item,
        mergedCurrency: `${item.CurrencyCode} (${item.Symbol}) `,
      }));
      setCurrency(MergedCurrencies);
    }
  }, [CurrencyData]);

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors: errors },
    control,
    watch,
    getValues,
  } = useForm<formDataProps>({
    defaultValues: FormData,
    mode: "onChange",
  });

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onSubmit = async (data: formDataProps) => {
    if (!data.attachment) {
      setUploadError("Please upload a PDF file");
      return;
    }

    const formattedData = {
      physicalUploadId: 0,
      uploadFile: data.attachment,
      natrue_of_Contract: data.nature_of_contract || 0,
      location: data.stamping_location || 0,
      consideration_Value: data.consideration_value || 0,
      consideration_Currency: data.currency_Id || 0,
      stamping: stamping.filter((e) => e.value === data.stamping)[0]?.lable || "",
      stamp_Duty: data.stamp_duty || 0,
      stamp_Currency: data.stamp_duty_currency_id || 0,
      stamp_Duty_Paid_By: data.stamp_duty_paid_by || "",
      stamp_Paper_Procured_by: data.stamp_paper_procured_by || "",
      vendorId: workFLowInstanceData?.vendor_Id || 0,
      companyId: workFLowInstanceData?.company_Id || 0,
      workFlowInstanceId: isEditData?.Workflow_InstanceId || 0,
      isActive: 1,
      updatedBy: userLoginResponse?.UserName,
      updatedOn: new Date().toISOString(),
      userId: userLoginResponse?.UserId,
      moduleName: "Physical Upload",
      activity: "create",
    };

    try {
      dispatch(updateLoader(true));
      dispatch(PhysicalUploadSubmit(formattedData));
      console.log(formattedData, "Final~output");
    } catch (error) {
      console.error("Error submitting form data:", error);
    } finally {
      dispatch(updateLoader(false));
    }
    console.log(data, "Final~output");
  };

  const updatePhysicalUpload = async () => {
    if (onClose && isphysicalUploadDataAdded) {
      let request = {
        workflowInstanceId: isEditData?.Workflow_InstanceId,
        processInstanceId: isEditData.Id,
        conditionValue: workFLowInstanceData?.vendor_Contract_Id,
        projectId: Number(isEditData.ProjectId),
        updatedBy: userLoginResponse ? userLoginResponse.UserName : "",
        DepartmentId: userLoginResponse ? String(userLoginResponse.Department) : "",
      };

      let data = await dispatch(updateCmWorkFlowInstance(request)).unwrap();
      if (data) {
        dispatch(PhysicalUploadResetState(false));
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: PhysicalUploadToasterMsg.message,
            isTosterFailed: PhysicalUploadToasterMsg.status !== "Success" ? true : false,
          })
        );
        dispatch(updateLoader(false));
        onClose();
      }
    }
  };

  useEffect(() => {
    if (isphysicalUploadDataAdded) {
      updatePhysicalUpload();
    }
  }, [isphysicalUploadDataAdded]);

  const formatPreviewContract = () => {
    setPreviewContract(true);
    dispatch(updateMenu(false));
    dispatch(updateHeaderMenu(false));
  };

  const handleClosePreview = () => {
    setPreviewContract(false);
    dispatch(updateMenu(true));
    dispatch(updateHeaderMenu(true));
  };

  useEffect(() => {
    if (applicationParameterData.length > 0) {
      let contactManagemtnAppParms: any[] = applicationParameterData?.filter(
        (item) => item.Parameter_Group === ContractManagemnt_InternalCode.ContractManagement
      );

      let currencyCode = contactManagemtnAppParms?.filter(
        (dataCurrencyItem: any) => dataCurrencyItem?.Parameter_Name === "Currency"
      )[0]?.Parameter_Value;

      setValue("stamp_duty_currency_id", Number(currencyCode));
      setValue("currency_Id", Number(currencyCode));
    }
  }, [applicationParameterData]);

  return (
    <Box>
      {!showPreviewContract ? (
        <Box sx={{ width: "100%", px: 2 }}>
          <Box pt={2}>
            <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
              <CustomBreadcrumbs
                list={breadcrumb}
                onchange={(val: number) => {
                  if (val === 0 && onClose) {
                    onClose();
                  }
                }}
              />
            </Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Typography variant="h6" color={"text.primary"} fontWeight={700}>
                Physical Upload
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                  <Box key={workFLowInstanceData?.company_Name}>
                    <Typography textAlign="left" fontWeight="bold">
                      {"Company"}
                    </Typography>
                    <Typography textAlign="left">{workFLowInstanceData?.company_Name}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                  <Box key={workFLowInstanceData?.vendor_Name}>
                    <Typography textAlign="left" fontWeight="bold">
                      {"Vendor"}
                    </Typography>
                    <Typography textAlign="left">{workFLowInstanceData?.vendor_Name}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                  <Box key={workFLowInstanceData?.vendor_Name}>
                    <Typography textAlign="left" fontWeight="bold">
                      {"Contract Number"}{" "}
                    </Typography>
                    <Typography textAlign="left">{workFLowInstanceData?.contract_Ref_Number}</Typography>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={3} lg={2} xl={2}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      justifyContent: "flex-end",
                      mt: 2,
                    }}
                  >
                    <CustomButton
                      variant="contained"
                      color="secondary"
                      disabled={false}
                      name={"Preview Contract"}
                      onClick={() => {
                        formatPreviewContract();
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ mt: 2 }} variant="fullWidth" />
                </Grid>
                <Grid item xs={12}>
                  {!selectedFiles ? (
                    <Grid item xs={12} sm={12} md={5} lg={1} xl={2}>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ mt: 0.3 }}>
                          <CustomButton
                            variant="outlined"
                            color="secondary"
                            name={"Upload a PDF File *"}
                            onClick={handleButtonClick}
                            startIcon={<CustomSvg size={"15px"} name={"paperclip"} />}
                          />
                          <input
                            ref={fileInputRef}
                            accept=".pdf,.doc,.docx"
                            style={{ display: "none" }}
                            id="file-input"
                            type="file"
                            onChange={handleFileChange}
                            multiple={false}
                          />
                          {uploadError && (
                            <FormHelperText error sx={{ mt: 1 }}>
                              {uploadError}
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box sx={{ display: "flex", alignItems: "flex-start", mt: 0.3 }}>
                        {selectedFiles && (
                          <CustomChip
                            key={selectedFiles.name}
                            label={truncateFileName(selectedFiles.name)}
                            variant="outlined"
                            color="primary"
                            title={selectedFiles.name}
                            onDelete={() => handleDeleteFile(selectedFiles)}
                          />
                        )}
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="nature_of_contract"
                    control={control}
                    rules={{
                      required: "Nature of Contract is Required",
                    }}
                    render={({ field }) => (
                      <CustomAutoComplete
                        optionValueKey={"LovId"}
                        optionLabelKey="Options"
                        options={NatureOfContract || []}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        required={true}
                        label="Nature of Contract"
                        error={Boolean(errors.nature_of_contract)}
                        helperText={errors.nature_of_contract?.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="stamping_location"
                    control={control}
                    rules={{
                      required: "Location is Required",
                    }}
                    render={({ field }) => (
                      <CustomAutoComplete
                        optionValueKey="Location_Id"
                        optionLabelKey="Location_Name"
                        options={locationData}
                        readOnly={false}
                        value={field.value}
                        onChange={field.onChange}
                        required={true}
                        label="Sample Location (State)"
                        error={Boolean(errors.stamping_location)}
                        helperText={errors.stamping_location?.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Box sx={{ display: "flex", columnGap: "10px" }}>
                    <Controller
                      key={"consideration_value"}
                      name="consideration_value"
                      control={control}
                      rules={{
                        required: "Consideration Value is Required",
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          key={`consideration_value`}
                          name={"Consideration Value"}
                          label={"Consideration Value"}
                          value={field.value === 0 ? "" : field.value}
                          onChange={(val) => {
                            if (Number.isNaN(+val) || val === "" || val === "0") {
                              field.onChange("");
                            } else {
                              field.onChange(+val);
                            }
                          }}
                          type="number"
                          required={true}
                          error={Boolean(errors.consideration_value)}
                          helperText={errors.consideration_value && errors.consideration_value.message?.toString()}
                        />
                      )}
                    />
                    <Controller
                      key="currency_Id"
                      name="currency_Id"
                      control={control}
                      rules={{
                        required: "Currency is Required",
                      }}
                      render={({ field }) => (
                        <CustomAutoComplete
                          optionValueKey={"CurrencyId"}
                          optionLabelKey="mergedCurrency"
                          options={getCurrency || []}
                          value={field.value}
                          onChange={field.onChange}
                          required={true}
                          label={"Currency"}
                          key={"CurrencyId"}
                          error={Boolean(errors.currency_Id)}
                          helperText={errors.currency_Id && errors.currency_Id.message?.toString()}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item container sx={{ gap: 2, pt: 1 }}>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Box sx={{ display: "flex", columnGap: "15px", whiteSpace: "pre", alignItems: "center" }}>
                      <Typography sx={{ mt: "-15px !important" }}>Stamping :</Typography>
                      <Controller
                        name="stamping"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Stamping is required" }}
                        render={({ field }) => (
                          <CustomRadioButton
                            list={stamping}
                            style={{ marginTop: "-10px" }}
                            label=""
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(Number(e));
                            }}
                            error={Boolean(errors.stamping)}
                            helperText={errors.stamping && errors.stamping.message?.toString()}
                          />
                        )}
                      />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Box sx={{ display: "flex", columnGap: "10px" }}>
                      <Controller
                        key={"stampDuty"}
                        name="stamp_duty"
                        control={control}
                        defaultValue={"" as any}
                        rules={{
                          required: "Stamp Duty is Required",
                        }}
                        render={({ field }) => (
                          <CustomTextField
                            key={`stamp_duty`}
                            name={"Stamp Duty"}
                            label={"Stamp Duty"}
                            value={field.value === 0 ? "" : field.value}
                            onChange={(val) => {
                              if (Number.isNaN(+val) || val === "" || val === "0") {
                                field.onChange("");
                              } else {
                                field.onChange(+val);
                              }
                            }}
                            type="number"
                            required={true}
                            error={Boolean(errors.stamp_duty)}
                            helperText={errors.stamp_duty && errors.stamp_duty.message?.toString()}
                          />
                        )}
                      />
                      <Controller
                        key="stamp_duty_currency_id"
                        name="stamp_duty_currency_id"
                        control={control}
                        rules={{
                          required: "Currency is Required",
                        }}
                        render={({ field }) => (
                          <CustomAutoComplete
                            optionValueKey={"CurrencyId"}
                            optionLabelKey="mergedCurrency"
                            options={getCurrency || []}
                            value={field.value}
                            onChange={field.onChange}
                            required={true}
                            label={"Currency"}
                            key={"CurrencyId"}
                            error={Boolean(errors.stamp_duty_currency_id)}
                            helperText={
                              errors.stamp_duty_currency_id && errors.stamp_duty_currency_id.message?.toString()
                            }
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item container sx={{ gap: 2, pt: 1 }}>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="stamp_duty_paid_by"
                      control={control}
                      rules={{
                        required: "Stamp Duty Paid By is Required",
                      }}
                      render={({ field }) => (
                        <CustomAutoComplete
                          optionValueKey={"id"}
                          optionLabelKey="name"
                          options={[
                            {
                              id: workFLowInstanceData?.company_Name || null,
                              name: workFLowInstanceData?.company_Name || "",
                            },
                            {
                              id: workFLowInstanceData?.vendor_Name || null,
                              name: workFLowInstanceData?.vendor_Name || "",
                            },
                          ]}
                          value={field.value}
                          onChange={field.onChange}
                          required={true}
                          label="Stamp Duty Paid By"
                          error={Boolean(errors.stamp_duty_paid_by)}
                          helperText={errors.stamp_duty_paid_by?.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="stamp_paper_procured_by"
                      control={control}
                      rules={{
                        required: "Stamp Paper Procured By is Required",
                      }}
                      render={({ field }) => (
                        <CustomAutoComplete
                          optionValueKey={"id"}
                          optionLabelKey="name"
                          options={[
                            {
                              id: workFLowInstanceData?.company_Name || null,
                              name: workFLowInstanceData?.company_Name || "",
                            },
                            {
                              id: workFLowInstanceData?.vendor_Name || null,
                              name: workFLowInstanceData?.vendor_Name || "",
                            },
                          ]}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          required={true}
                          label="Stamp Paper Procured By"
                          error={Boolean(errors.stamp_paper_procured_by)}
                          helperText={errors.stamp_paper_procured_by?.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      justifyContent: "right",
                      mt: 2,
                    }}
                  >
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        reset();
                        if (onClose) {
                          onClose();
                        }
                      }}
                    />
                    <CustomButton type="submit" name={"Submit"} onClick={handleSubmit(onSubmit)} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <CustomButton name="Back" variant="outlined" onClick={handleClosePreview} />
          </Box>
          {/* {showPreviewContract && (
      <CustomDialog
        show={showPreviewContract && previewId}
        onHide={handleClosePreview}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={"Preview Contract"}
        style={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              overflowY: "hidden",
            },
          },
        }}
        contentNode={
          <>
          </>
          }
          />
          )} */}
          {showPreviewContract && (
            <OnlyOfficeTextEditor
              fileName={`${workFLowInstanceData?.contract_Tempate_Name}.docx`}
              editorsMode="view"
              isOpenDocument={true}
              autoStart={false}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default PhysicalUpload;
