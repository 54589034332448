import axios from "axios";

const apiBaseUrl = "https://api.probe42.in/probe_pro_sandbox/companies";

interface BaseDetailsResponse {
  // Define the response structure
}

export const getCompanyBaseDetails = async (cin: any): Promise<BaseDetailsResponse> => {
  try {
    const response = await axios.get<BaseDetailsResponse>(`${apiBaseUrl}/${cin}/comprehensive-details`, {
      headers: {
        accept: "application/json",
        "x-api-version": "1.3",
        "x-api-key": "UEFbcjDqZp3eeH3sLnpx33SiQZoq1clz1PLD02HC",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching company base details", error);
    throw error;
  }
};

// Function to dynamically configure Axios instance
export const createDynamicAxiosInstance = ({
  baseURL,
  version,
  apiKey,
  accept = "application/json",
  ID,
  Type,
}: {
  baseURL: string;
  version: string;
  apiKey: string;
  accept?: string;
  ID: string;
  Type: string;
}) => {
  const instance = axios.create({
    baseURL: `${baseURL}`,
  });
  instance.interceptors.request.use(
    (config: any) => {
      config.headers["accept"] = accept;
      config.headers["x-api-version"] = version;
      if (apiKey) {
        config.headers["x-api-key"] = apiKey;
      }
      if (config.baseURL) {
        config.baseURL = config.baseURL.replace(/<<ID>>/g, ID);
        config.baseURL = config.baseURL.replace(/<<TYPE>>/g, Type);
      }

      return config;
    },
    (error: any) => Promise.reject(error)
  );

  return instance;
};
