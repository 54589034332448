import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, } from "@mui/material";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { ACTIVE, INACTIVE, textFieldWidth } from "../../utils/constants";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomButton from "../../common/dynamicButton/CustomButton";
import ProgressBar from "../../common/progressBar/progressBar";
import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";
import roleService from "../../store/services/rolesService";
import workFlowService from "../../store/services/workFlowService";
import CustomMultiSelect from "../../common/dynamicInputs/CustomMultiSelect";
import { LovConfig, WorkflowCategory } from "../../config/config";
import { CommonTooltip } from "../../common/CommonTooltip/CommonTooltip";
import { deletecontentMaster , getContentForm , getContentHistory, getContentList, updateContentAddandEdit, updateVersionHistory } from "../../store/slices/contentMasterData";
import { ContentNameConfig, Edit_Enable_tosterMSG } from "../../config/config";


 
export interface IStepsFormData {
  Id: number;
  WorkflowId: number;
  ProcessSeqno: number;
  ProcessName: string;
  ProcessType: number;
  ProcessTypeName: string;
  ProcessId: number;
  ProcessIdName: string;
  ProcessNextStep: number;
  ProcessNextStepName: string;
  ConditionTobeChecked: number | null;
  ConditionName: string;
  IfYes_GoTo: number | null;
  IfNo_GoTo: number | null;
  Branching: number | null;
  RoleId: number;
  RoleName: string;
  DepartmentId: number;
  DepartmentName: string;
  TAT: number;
  Exit_Status:number;
  Exit_StatusName:string;
  IsActive: boolean;
  IsActiveName: string
  Updated_By: string;
}

interface IAddStep {
  closePopUp: any;
  addStep: any;
  isEdit?: boolean;
  editStepData?: any;
  view?: boolean;
  exisitingSeqNo:number[];
  workFlowCategory?:any;
}

const AddStep: React.FC<IAddStep> = ({workFlowCategory, closePopUp, addStep, isEdit=false, editStepData,view,exisitingSeqNo }) => {
  const dispatch = useAppDispatchThunk(); 
  const { userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { vendorConentPurposes, vendorConentDataTable, vendorConsentToasterMsg, versionHistory, isVendorConsentLoading, isVendorConsentAdded, isVendorConsentUpdated, isVendorConsentDeleted } = useSelector((state: RootState) => state.contentData);
  const [loader, setLoader] = useState<boolean>(false);
  const { allRoles } = useSelector((state: RootState) => state.roleData);
  const [worFlowProcessType, setWorFlowProcessType] = useState<any[]>([]);
  const [workFlowProcessNext, setWorkFlowProcessNext] = useState<any[]>([]);
  const { formConfiguration } = useSelector((state: RootState) => state.workFlow);
  const { dataTable } = useSelector((state: RootState) => state.departmentData);
  const [rolesData, setRolesData] = useState<any[]>([]);
  const [workFlowConditions, setWorkFlowConditions] = useState<any[]>([]);
  const [exitStatus, setExitStatus] = useState<any[]>([]);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  

  const [vendorConsentData, setVendorConsentData] = useState<any>();
  

  let formData: IStepsFormData = {
    Id: isEdit ? editStepData.Id : 0,
    WorkflowId: isEdit ? editStepData.WorkflowId : 0,
    ProcessSeqno: isEdit ? editStepData.ProcessSeqno : null,
    ProcessName: isEdit ? editStepData.ProcessName : "",
    ProcessType:  isEdit ? editStepData.ProcessType : null,
    ProcessTypeName:isEdit ? editStepData.ProcessTypeName : "",
    ProcessId: isEdit ? editStepData.ProcessId : null,
    ProcessIdName: isEdit ? editStepData.ProcessIdName : "",
    ProcessNextStep:  isEdit ? editStepData.ProcessNextStep : null,
    ProcessNextStepName: isEdit ? editStepData.ProcessNextStepName : "",
    ConditionTobeChecked: (isEdit && editStepData.ConditionTobeChecked) ? editStepData.ConditionTobeChecked : null,
    ConditionName: isEdit ? editStepData.ConditionName : "",
    IfYes_GoTo: (isEdit && editStepData.IfYes_GoTo ) ? editStepData.IfYes_GoTo : null,
    IfNo_GoTo: (isEdit && editStepData.IfNo_GoTo ) ? editStepData.IfNo_GoTo : null,
    Branching:(isEdit && editStepData.Branching ) ? editStepData.Branching : null,
    RoleId:isEdit ? editStepData.RoleId : '', 
    RoleName: isEdit ? editStepData.RoleName : "",
    DepartmentId:(isEdit && editStepData.DepartmentId != "")? editStepData.DepartmentId?.split(",").map(Number) : [],
    DepartmentName: isEdit ? editStepData.DepartmentName : "",
    TAT:isEdit ? editStepData.TAT : null,
    Exit_Status:(isEdit && editStepData.Exit_Status ) ? editStepData.Exit_Status : null,
    Exit_StatusName:isEdit ? editStepData.Exit_StatusName : '',
    IsActive: isEdit ? (editStepData.IsActive? true : false) : true,
    IsActiveName: isEdit ? editStepData.IsActive : "Active",
    Updated_By: "",
  };
  const {
    handleSubmit: handleUserForm,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
  } = useForm<IStepsFormData>({
    defaultValues: formData,
    mode: "onChange",
  });
  const formValues =  getValues()

  const fetchConditions = async () =>{
    const res = await workFlowService.getWorkflowConditions()
    setWorkFlowConditions(res.data)
  }

  useEffect(()=>{    
    fetchConditions()
  },[])


  useEffect(() => {
    callPAgeLoadData();
  }, []);


  

  const callPAgeLoadData = () => {
    dispatch(
      getContentList({
        FormID: 2,
      })
    );
  }
    


  useEffect(() => {

    if(vendorConentDataTable){
      console.log(vendorConentDataTable, 'vendorConentDataTable')
       let findContentMasterRow = vendorConentDataTable.filter((item:any)=>{
       if(item?.Content_Purpose == ContentNameConfig.PurposeName){
        setVendorConsentData(item)
       }
       })

    }
    
  }, [vendorConentDataTable]);

  console.log(vendorConsentData, "vendorConsentData1")


  useEffect(() => {
    console.log( formConfiguration , 'formConfiguration');
  },[formConfiguration])


console.log(workFlowCategory,"lovTable")
  useEffect(() => {
    if(lovTable){
      
      // setExitStatus(lovTable.filter((item: any) => item.Name === LovConfig.VOBWorkflowStatus) || [])
      setExitStatus(lovTable.filter((item: any) => item.Name === WorkflowCategory(workFlowCategory)) || [])
      setWorFlowProcessType(lovTable.filter((item: any) => item.Name === LovConfig.Process_Type) || [])
      setWorkFlowProcessNext(lovTable.filter((item: any) => item.Name === LovConfig.Process_Workflow) || [])
    }
  }, [lovTable]);
  
  useEffect(()=>{
    if(formValues.RoleId) getRolesByMenu(formValues.RoleId);

  },[formValues.RoleId, JSON.stringify(rolesData)])

  useEffect(() => {
      if(allRoles){
        setRolesData(allRoles?.roles || []) 
      }
  },[allRoles])


  



  

  const handleSubmit = () => {
    if (closePopUp && addStep) {
      let stepFormValues = getValues();
      if(!stepFormValues.IfYes_GoTo) stepFormValues.IfYes_GoTo = null
      if(!stepFormValues.IfNo_GoTo) stepFormValues.IfNo_GoTo = null
      if(!stepFormValues.Branching) stepFormValues.Branching = null
      stepFormValues.Updated_By = userLoginResponse && userLoginResponse.UserName ? userLoginResponse.UserName: "";
      stepFormValues.IsActiveName = stepFormValues.IsActive? "Active": "Inactive";
      addStep(stepFormValues);
      closePopUp();
      setLoader(false);
    }
  };

  const getRolesByMenu = async (id: number) => {
    const res = await roleService.GetRolesByMenuId({MenuId:id})
    // setRolesData(res?.data  || [])
  };

  const MaualStepDisableCondition = workFlowProcessNext.find((val) => val.LovId == watch('ProcessNextStep'))?.Options == 'Manual' || false


  const ManualStepSelectReset = () => {
    setValue("IfYes_GoTo", null ); 
    setValue("IfNo_GoTo", null ); 
    setValue("ConditionTobeChecked", null); 
    setValue("Branching", null ); 
    setValue("ConditionName", "")
  }

  const toolTipData =()=>{
    return(
      <Box sx={{ maxHeight: 200, overflowY: "auto", width: "100%" }}>
      <Table sx={{ width: "100%" }}>
        <TableBody sx={{ borderTop: "0px" }}>
         
            {vendorConsentData &&
              
              <TableRow>
                <TableCell
                  sx={{
                    border: "2px solid #f1f1f1",
                    // borderTop: index === 0 ? "0px" : "2px solid #f1f1f1",
                    width: "28.3%",
                    p: 1,
                    py: 0.5,
                    textAlign: "left",
                  }}
                >
                 {
        // Split by <li> tags and render each item on a new line
        vendorConsentData.Content_Text.replace(/<\/?ul>/g, "") // Remove <ul> tags
          .split(/<\/?li>/) 
          .filter((item : any) => item.trim()) // Remove empty items
          .map((item :any, index:any) => (
            <div key={index}>{item.trim()}</div> // Display each item in a new line
          ))
      }
                </TableCell>
                {/* <TableCell
                  sx={{
                    border: "2px solid #f1f1f1",
                    borderTop: index === 0 ? "0px" : "2px solid #f1f1f1",
                    width: "28.4%",
                    p: 1,
                    py: 0.5,
                    textAlign: "left",
                  }}
                >
                  {scoring.Range_To}
                </TableCell>
                <TableCell
                  sx={{
                    border: "2px solid #f1f1f1",
                    borderTop: index === 0 ? "0px" : "2px solid #f1f1f1",
                    p: 1,
                    py: 0.5,
                    textAlign: "left",
                  }}
                >
                  {scoring.Rating}
                </TableCell> */}
              </TableRow>
            }
        </TableBody>
      </Table>
    </Box>
    )

  }


  return (
    <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden", position: "relative" }}>
      {loader ? <ProgressBar showProgress={loader} /> : null}
      <Grid
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            px: {
              xs: 2,
              sm: 4,
              md: 2,
              lg: 2,
              xl: 2,
            },

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="ProcessSeqno"
                  control={control}
                  rules={{
                    required: "Sequence Number is Required",
                    validate: {
                      isDuplicateSeqNo: (value) => {
                        if (value )
                          if (exisitingSeqNo.includes(value)) return "Sequence Number already exists"
                        else return true;
                      },
                      isPositiveNumber: (value) => {
                        if (value )
                          if (isNaN(value) ) return "Please enter a valid number"
                          else return value > 0 || "Please enter a valid positive number"
                        else return true;
                      },
                      maxLength: (value) => {
                        if (value ) 
                          if(value % 1 === 0) return String(value).length <= 5 || "Maximum 5 digits allowed";
                          else return "Please enter a valid number";
                        else return true;
                      }
                    }
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"ProcessSeqno"}
                      required={true}
                      readOnly={view}
                      label={"Step Seq No"}
                      type={"number"}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.ProcessSeqno)}
                      helperText={errorsloginform.ProcessSeqno && errorsloginform.ProcessSeqno.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="ProcessName"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: "Process Name is Required",
                    validate: {
                      maxLength: (value) => {
                        if (value === undefined) return "Value is required";
                        return value.length <= 100 || "Maximum 100 characters allowed";
                      }
                    }
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"ProcessName"}
                      required={true}
                      readOnly={view}
                      label={"Process Name"}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.ProcessName)}
                      helperText={errorsloginform.ProcessName && errorsloginform.ProcessName.message?.toString()}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="ProcessType"
                  control={control}
                  rules={{
                    required: "Process Type is Required",
                  }}
                  render={({ field }) => (
                    <CustomSelect
                      name={"ProcessType"}
                      valueKey={"LovId"}
                      optionLabelKey="Options"
                      options={worFlowProcessType}
                      required={true}
                      readOnly={view}
                      label={"Process Type"}
                      value={field.value}
                      onChange={(field)=>{                       
                        setValue("ProcessType", field)
                        setValue("ProcessTypeName", worFlowProcessType.filter(data=> data.LovId ===field)[0].Options)
                      }}
                      error={Boolean(errorsloginform.ProcessType)}
                      helperText={
                        errorsloginform.ProcessType && errorsloginform.ProcessType.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="ProcessId"
                  control={control}
                  rules={{
                    required: "ProcessId is Required",
                  }}
                  render={({ field }) => (
                    <CustomSelect
                      name={"ProcessId"}
                      valueKey={"FormId"}
                      optionLabelKey="FormName"
                      options={formConfiguration}
                      required={true}
                      readOnly={view}
                      label={"Process_Id"}
                      value={field.value}
                      onChange={(field)=>{    
                        getRolesByMenu(field)
                        setValue("ProcessId", field)
                        setValue("ProcessIdName", formConfiguration.filter(data=> data.FormId === field)[0].FormName)
                      }}
                      error={Boolean(errorsloginform.ProcessId)}
                      helperText={
                        errorsloginform.ProcessId && errorsloginform.ProcessId.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="ProcessNextStep"
                  control={control}
                  rules={{
                    required: "Process NextStep is Required",
                  }}
                  render={({ field }) => (
                    <CustomSelect
                      name={"ProcessNextStep"}
                      valueKey={"LovId"}
                      optionLabelKey="Options"
                      options={workFlowProcessNext}
                      required={true}
                      
                      readOnly={view}
                      label={"Next Step"}
                      value={field.value}
                      onChange={(field)=>{                       
                        setValue("ProcessNextStep", field)
                        setValue("ProcessNextStepName", workFlowProcessNext.filter(data=> data.LovId ===field)[0].Options);
                        console.log(field , 'ProcessNextStep 1');
                        
                        if(workFlowProcessNext.find((val) => val.LovId == field)?.Options == 'Manual'){
                          console.log(field , 'ProcessNextStep 2');
                          ManualStepSelectReset()
                        }
                      }}
                      error={Boolean(errorsloginform.ProcessNextStep)}
                      helperText={
                        errorsloginform.ProcessNextStep && errorsloginform.ProcessNextStep.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>             

              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="ConditionTobeChecked"
                  control={control}
                  defaultValue={null}
                  // rules={{
                  //   required: "ConditionTobeChecked is Required",
                  // }}
                  render={({ field }) => (
                    <CustomSelect
                      name={"ConditionTobeChecked"}
                      valueKey="Id"
                      optionLabelKey="Condition"
                      options={workFlowConditions}
                      readOnly={view}
                      disabled={MaualStepDisableCondition}
                      label={"ConditionTobeChecked"}
                      value={field.value}
                      onChange={(field) => {
                        setValue("ConditionTobeChecked", field);
                        setValue("ConditionName", workFlowConditions.filter(data=> data.Id ===field)[0].Condition)
                       
                      }}
                      error={Boolean(errorsloginform.ConditionTobeChecked)}
                      helperText={
                        errorsloginform.ConditionTobeChecked && errorsloginform.ConditionTobeChecked.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="IfYes_GoTo"
                  control={control}
                  defaultValue={null}
                  rules={{
                    validate: {
                      isPositiveNumber: (value) => {
                        if (value )
                          if (isNaN(value) ) return "Please enter a valid number"
                          else return value > 0 || "Please enter a valid positive number"
                        else return true;
                      },
                      maxLength: (value) => {
                        if (value ) 
                          if(value % 1 === 0) return String(value).length <= 5 || "Maximum 5 digits allowed";
                          else return "Please enter a valid number";
                        else return true;
                      }
                    }
                }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"IfYes_GoTo"}
                      readOnly={view}
                      type={"number"}
                      label={"IfYes_GoTo"}
                      value={field.value}
                      disabled={MaualStepDisableCondition}
                       onChange={field.onChange}
                      error={Boolean(errorsloginform.IfYes_GoTo)}
                      helperText={
                        errorsloginform.IfYes_GoTo && errorsloginform.IfYes_GoTo.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              > 
                <Controller
                  name="IfNo_GoTo"
                  control={control}
                  defaultValue={null}
                  rules={{
                    validate: {
                      isPositiveNumber: (value) => {
                        if (value )
                          if (isNaN(value) ) return "Please enter a valid number"
                          else return value > 0 || "Please enter a valid positive number"
                        else return true;
                      },
                      maxLength: (value) => {
                        if (value ) 
                          if(value % 1 === 0) return String(value).length <= 5 || "Maximum 5 digits allowed";
                          else return "Please enter a valid number";
                        else return true;
                      }
                    }
                }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"IfNo_GoTo"}
                      readOnly={view}
                      type={"number"}
                      label={"IfNo_GoTo"}
                      disabled={MaualStepDisableCondition}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.IfNo_GoTo)}
                      helperText={
                        errorsloginform.IfNo_GoTo && errorsloginform.IfNo_GoTo.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="Branching"
                  control={control}
                  defaultValue={null}
                  rules={{
                    validate: {
                      isPositiveNumber: (value) => {
                        if (value )
                          if (isNaN(value) ) return "Please enter a valid number"
                          else return value > 0 || "Please enter a valid positive number"
                        else return true;
                      },
                      maxLength: (value) => {
                        if (value ) 
                          if(value % 1 === 0) return String(value).length <= 5 || "Maximum 5 digits allowed";
                          else return "Please enter a valid number";
                        else return true;
                      }
                    }
                }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"Branching"}
                      readOnly={view}
                      type={"number"}
                      label={"Branching"}
                      disabled={MaualStepDisableCondition}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.Branching)}
                      helperText={errorsloginform?.Branching?.message?.toString()}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="RoleId"
                  control={control}
                  rules={{
                    required: "Role is Required",
                  }}
                  // defaultValue={''}
                  render={({ field }) => (
                    <CustomSelect
                      name="RoleId"
                      valueKey="RoleId"
                      optionLabelKey="RoleName"
                      options={rolesData}
                      required={true}
                      readOnly={view}
                      label={"Role"}
                      value={field.value}
                      onChange={(field)=>{                       
                        setValue("RoleId", field)
                        setValue("RoleName", rolesData.filter(data=> data.RoleId ===field)[0].RoleName)
                      }}                      
                      error={Boolean(errorsloginform.RoleId )}
                      helperText={errorsloginform.RoleId?.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="DepartmentId"
                  control={control}
                  render={({ field }) => (
                  <CustomMultiSelect
                    optionLabelKey={`DepartmentName`}
                    optionIdKey={`DepartmentId`}
                    options={dataTable}
                    readOnly={view}
                    label={"Dept"}
                    value={field.value}
                    onChange={(field:any) => {
                      setValue("DepartmentId", field.target.value)
                      const dname = field.target.value.map((id:number)=>dataTable.find((data:any)=> data.DepartmentId ===id).DepartmentName)
                      setValue("DepartmentName", dname.toString())
                    }}                      
                    error={Boolean(errorsloginform.DepartmentId )}
                    helperText={errorsloginform.DepartmentId?.message?.toString()}
                  />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="TAT"
                  control={control}
                  rules={{
                    required: "Estimated Time must be a valid number",
                    validate: {
                      isPositiveNumber: (value) => {
                        if (isNaN(value) ) return "Please enter a valid number"
                        else return value > 0 || "Please enter a valid positive number"
                      },
                      maxLength: (value) => {
                        if (value ) 
                          if(value % 1 === 0) return String(value).length <= 5 || "Maximum 5 digits allowed";
                          else return "Please enter a valid number";
                      }
                    }
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"TAT"}
                      required={true}
                      readOnly={view}
                      type={"number"}
                      label={"Estimated Time (in Days)"}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.TAT)}
                      helperText={errorsloginform.TAT?.message?.toString()}
                    />
                  )}
                />
              </Grid>

           <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="Exit_Status"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      name={"Exit_Status"}
                      valueKey={"LovId"}
                      optionLabelKey="Options"
                      options={exitStatus}
                      readOnly={view}
                      label={"Exit Status"}
                      value={field.value}
                      onChange={(field)=>{                       
                        setValue("Exit_Status", field)
                        setValue("Exit_StatusName", exitStatus.filter(data=> data.LovId ===field)[0].Options)
                      }}
                      error={Boolean(errorsloginform.Exit_Status)}
                      helperText={
                        errorsloginform.Exit_Status && errorsloginform.Exit_Status.message?.toString()
                      }
                    />
                  )}
                />
              
                
                </Grid> 
               
              {/* <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}    
              >
                <Controller
                  name="IsActive"
                  control={control}
                  render={({ field }) => (
                    <CustomToogleSwitch
                      label={""}
                      dynamicLabel={field.value ? ACTIVE : INACTIVE}
                      value={field.value}
                      disabled={view}
                      onChange={(data) => {
                        setValue("IsActive", data);
                      }}
                    />
                  )}
                />
              </Grid>*/}
            </Grid>

            <Box sx={{ 
              mt:2
             }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                  <Box>Notes</Box>
                                  <Box sx={{ marginTop: "-4px" }}>
                                    <CommonTooltip
                                      arrow={true}
                                      placement={"top-start"}
                                      content={toolTipData()}
                                      width={"400px"}
                                    />
                                  </Box>
                                </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "right",
                mt: 2,
              }}
            >
              <React.Fragment>
                <CustomButton
                  type="reset"
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    reset(formData);
                    closePopUp && closePopUp();
                  }}
                />
                { !view && 
                <CustomButton type="reset" name={isEdit ? "Update":"Submit"} onClick={handleUserForm(handleSubmit)} />
                }
              </React.Fragment>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddStep;
