import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../../store/store";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateLoader } from "../../../store/slices/loader";
import { getLogginVendor } from "../../../store/slices/authorization";
import { WelcomeGreetings } from "./welcomeGreetings/WelcomeGreetings";
import { getGetVCWorkflowProcessesData } from "../../../store/contractManagement/slices/contractRolesAndResponsiblity";
import { getEmailTemplateList } from "../../../store/slices/EmailTemplateData";
import { getServiceCatalogList } from "../../../store/slices/serviceCatalog";
import { getUsersList } from "../../../store/slices/userData";
import { getCompanyList } from "../../../store/slices/companyMasterData";
import { getVendorDetailsList } from "../../../store/slices/vendorDetails";

export const ContractVendorPortal: React.FC = () => {
  const dispatch = useAppDispatch();
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const { workFLowInstanceData }: any = contractManagementReducer.contractRolesAndResponsibility;
  const { vendorId,projectId, workflowInstanceId } = useParams();
  

  useEffect(() => {
    let request: any = null;
    request = {
      projectId: Number(projectId),
      workflowInstanceId: Number(workflowInstanceId),
    };
    dispatch(getGetVCWorkflowProcessesData(request));
   
  }, []);
  console.log(workFLowInstanceData, "workFLowInstanceDataworkFLowInstanceData");

  // useEffect(() => {
  //   if (workFLowInstanceData) {
  //     setcurrentservices(workFLowInstanceData?.serviceMappings || []);
  //     setContractData(workFLowInstanceData);
  //   }
  // }, [workFLowInstanceData]);

  // useEffect(() => {
  //   if (workFLowInstanceData) {
  //     const serviceCatalogIds = workFLowInstanceData?.serviceMappings.map(
  //       (mapping: any) => mapping.services.serviceCatalog_Id
  //     );
  //     console.log(serviceCatalogIds, "serviceCatalogIds");
  //     const filteredServices = ActiveServiceCatlogdata.filter((service) =>
  //       serviceCatalogIds.includes(service.ServiceCatalogId)
  //     );
  //     setcurrentservices(filteredServices || []);
  //     console.log(ActiveServiceCatlogdata, "ActiveServiceCatlogdata");
  //   }
  // }, [workFLowInstanceData, ActiveServiceCatlogdata]);

  return (
    <>
      <Box sx={{ p: 2, overflowY: "scroll", height: "100vh" }}>
        {workFLowInstanceData && <WelcomeGreetings workFLowInstanceData={workFLowInstanceData}  />}
      </Box>
    </>
  );
};
