import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PhysicalUpload from "../services/physicalUploadService";

export interface IPhysicalUploadState {
  physicalUploadData: any;
  isLoading: boolean;
  isphysicalUploadDataAdded: boolean;
  isphysicalUploadDataUpdated: boolean;
  isphysicalUploadDataDeleted: boolean;
  PhysicalUploadToasterMsg: { message: string; status: string };
}

export const initialState: IPhysicalUploadState = {
  physicalUploadData: [],
  isLoading: false,
  isphysicalUploadDataAdded: false,
  isphysicalUploadDataUpdated: false,
  isphysicalUploadDataDeleted: false,
  PhysicalUploadToasterMsg: { message: "", status: "" },
};

export const PhysicalUploadSubmit = createAsyncThunk(
  "PhysicalUploadSubmit",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response: any = await PhysicalUpload.PhysicalUploadSubmit(requestBody);
      return response;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);
export const PhysicalUploadEdit = createAsyncThunk(
  "PhysicalUploadEdit",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response: any = await PhysicalUpload.editPhysicalUpload(requestBody);
      return response;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);
export const PhysicalUploadGetById = createAsyncThunk(
  "PhysicalUploadGetById",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response: any = await PhysicalUpload.getByIdPhysicalUpload(requestBody);
      return response;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);
export const PhysicalUploadGetAll = createAsyncThunk(
  "PhysicalUploadGetAll",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response: any = await PhysicalUpload.getAllPhysicalUploads(requestBody);
      return response;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);
export const PhysicalUploadSlice = createSlice({
  name: "PhysicalUpload",
  initialState,
  reducers: {
    PhysicalUploadResetState: (state, action: PayloadAction<any>) => {
      state.physicalUploadData = [];
      state.isLoading = false;
      state.isphysicalUploadDataAdded = false;
      state.isphysicalUploadDataUpdated = false;
      state.isphysicalUploadDataDeleted = false;
      state.PhysicalUploadToasterMsg = { message: "", status: "" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PhysicalUploadSubmit.pending, (state) => {
        state.isLoading = true;
        state.isphysicalUploadDataAdded = false;
        state.isphysicalUploadDataUpdated = false;
        state.isphysicalUploadDataDeleted = false;
      })
      .addCase(PhysicalUploadSubmit.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isphysicalUploadDataAdded = true;
        state.PhysicalUploadToasterMsg = action?.payload?.toaster;
      })
      .addCase(PhysicalUploadSubmit.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isphysicalUploadDataAdded = true;
        state.PhysicalUploadToasterMsg = action?.payload;
        state.PhysicalUploadToasterMsg.message =
          action?.payload?.toaster?.message || action?.payload || "";
        state.PhysicalUploadToasterMsg.status = "false";
      })
      .addCase(PhysicalUploadEdit.pending, (state) => {
        state.isLoading = true;
        state.isphysicalUploadDataAdded = false;
        state.isphysicalUploadDataUpdated = false;
        state.isphysicalUploadDataDeleted = false;
      })
      .addCase(PhysicalUploadEdit.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isphysicalUploadDataUpdated = true;
        state.PhysicalUploadToasterMsg = action?.payload?.toaster;
      })
      .addCase(PhysicalUploadEdit.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isphysicalUploadDataUpdated = true;
        state.PhysicalUploadToasterMsg = action?.payload;
        state.PhysicalUploadToasterMsg.message = action?.payload?.toaster?.message;
        state.PhysicalUploadToasterMsg.status = "false";
      })
      .addCase(PhysicalUploadGetById.pending, (state) => {
        state.isLoading = true;
        state.isphysicalUploadDataAdded = false;
        state.isphysicalUploadDataUpdated = false;
        state.isphysicalUploadDataDeleted = false;
      })
      .addCase(PhysicalUploadGetById.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.physicalUploadData = action?.payload?.payload;
        state.PhysicalUploadToasterMsg = action?.payload?.toaster;
      })
      .addCase(PhysicalUploadGetById.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.physicalUploadData = action?.payload?.payload;
        state.PhysicalUploadToasterMsg = action?.payload?.toaster;
      })
      .addCase(PhysicalUploadGetAll.pending, (state) => {
        state.isLoading = true;
        state.isphysicalUploadDataAdded = false;
        state.isphysicalUploadDataUpdated = false;
        state.isphysicalUploadDataDeleted = false;
      })
      .addCase(PhysicalUploadGetAll.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.physicalUploadData = action?.payload?.payload;
        state.PhysicalUploadToasterMsg = action?.payload?.toaster;
      })
      .addCase(PhysicalUploadGetAll.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.physicalUploadData = action?.payload?.payload;
        state.PhysicalUploadToasterMsg = action?.payload?.toaster;
      });
  },
});

export const { PhysicalUploadResetState } = PhysicalUploadSlice.actions;
export default PhysicalUploadSlice.reducer;
