import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AnyMxRecord } from "dns";
import contractRolesAndResponsibilityService from "../services/contractRolesAndResponsibility";

export interface contractRolesAndResponsibilityData {
  contractRolesAndResponsibilityData: any[];
  isLoading: boolean;
  isAddEditRolesAndResonsibilityLoading: boolean;
  isRolesAndResponsibilityAdded: boolean;
  isRolesAndResponsibilityUpdated: boolean;
  isRolesAndResponsibilityDeleted: boolean;
  isRRSetupAdded: boolean;
  isRRSetupUpdated: boolean;
  RolesAndResonsibilitytoasterMsg: { message: string; status: string };
  RolesAndResonsibilityInstanceId: any;
  ContractRolesAndResponsibilityversionHistory: {
    ContractRolesAndResponsibilityversionHistory: any[];
    RolesAndResponsibilityVHeader: any[];
  };
  workFLowInstanceData: any;
  updateWorkFLowInstance: boolean;
  signingStatus: any;
  signingAuthorities: any;
}

export const initialState: contractRolesAndResponsibilityData = {
  contractRolesAndResponsibilityData: [],
  isLoading: false,
  isAddEditRolesAndResonsibilityLoading: false,
  isRolesAndResponsibilityAdded: false,
  isRolesAndResponsibilityUpdated: false,
  isRolesAndResponsibilityDeleted: false,
  isRRSetupAdded: false,
  isRRSetupUpdated: false,
  RolesAndResonsibilitytoasterMsg: { message: "", status: "" },
  RolesAndResonsibilityInstanceId: "",
  ContractRolesAndResponsibilityversionHistory: {
    ContractRolesAndResponsibilityversionHistory: [],
    RolesAndResponsibilityVHeader: [],
  },
  workFLowInstanceData: null,
  updateWorkFLowInstance: false,
  signingStatus: null,
  signingAuthorities: null,
};

export const getRolesAndResponsibilityList = createAsyncThunk(
  "getRolesAndResponsibilityList",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.getRolesAndResponsiblityList();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addRolesAndResponsibility = createAsyncThunk(
  "addRolesAndResponsibility",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.addRoleAndResponsiblity(requesrParams);
      return response;
      // const toaster = { toaster: { message: "Roles and Responsibility Added SuccessFully", status: "Success" } };
      // return toaster;
    } catch (e: any) {
      return rejectWithValue({ message: "Roles and Responsibility Added SuccessFully", status: "Success" });
      //rejectWithValue({message:e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage})
    }
  }
);

export const updateRRWorkflowProcess = createAsyncThunk(
  "updateRRWorkflow",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.updateRoleAndResponsiblityWorkflow(requesrParams);
      return response;
      // const toaster = { toaster: { message: "Roles and Responsibility Added SuccessFully", status: "Success" } };
      // return toaster;
    } catch (e: any) {
      return rejectWithValue({ message: "Roles and Responsibility Added SuccessFully", status: "Success" });
      //rejectWithValue({message:e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage})
    }
  }
);

export const deleteRRSetup = createAsyncThunk("deleteRRSetup", async (requestBody: any, { rejectWithValue }) => {
  try {
    const response = await contractRolesAndResponsibilityService.deleteRRSetup(requestBody);
    return response.data;
  } catch (e: any) {
    return rejectWithValue({ message: "Roles and Responsibility Added SuccessFully", status: "Success" });
    //rejectWithValue({message:e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage})
  }
});

export const getGetVCWorkflowProcessesData = createAsyncThunk(
  "getGetVCWorkflowProcessesData",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.getGetVCWorkflowProcesses(requesrParams);
      return response;
    } catch (e: any) {
      rejectWithValue({ message: e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage });
    }
  }
);

export const updateRolesAndResponsibility = createAsyncThunk(
  "updateRolesAndResponsibility",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.updateRoleAndResponsiblity(requestParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteRolesAndResponsibility = createAsyncThunk(
  "deleteRolesAndResponsibility",
  async (RolesAndResponsibilityId: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.deleteRoleAndResponsiblity(RolesAndResponsibilityId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCmWorkFlowInstance = createAsyncThunk(
  "updateCmWorkFlowInstance",
  async (reqParams: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.updateWorkFlowProcess(reqParams);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveSigningAuthority = createAsyncThunk(
  "saveSigningAuthority",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.saveSigningAuthority(requestBody);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getArticleByLocation = createAsyncThunk(
  "getArticleByLocation",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.getArticleByLocation(requesrParams);
      return response;
    } catch (e: any) {
      rejectWithValue({ message: e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage });
    }
  }
);

export const getSigningStatus = createAsyncThunk(
  "getSigningStatus",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.getSigningStatus(requesrParams);
      return response;
    } catch (e: any) {
      rejectWithValue({ message: e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage });
    }
  }
);

export const getSigningAuthorities = createAsyncThunk(
  "getSigningAuthorities",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.getSigningAuthorities(requesrParams);
      return response;
    } catch (e: any) {
      rejectWithValue({ message: e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage });
    }
  }
);

export const saveTempSigningAuthorities = createAsyncThunk(
  "saveTempSigningAuthorities",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.saveTempSigningAuthorities(requesrParams);
      return response;
    } catch (e: any) {
      rejectWithValue({ message: e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage });
    }
  }
);

export const initiateEmbedSign = createAsyncThunk(
  "initiateEmbedSign",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.initiateEmbedSign(requestBody);
      return response;
    } catch (e: any) {
      rejectWithValue({ message: e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage });
    }
  }
);

export const downloadWorkflowDocument = createAsyncThunk(
  "downloadWorkflowDocument",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response = await contractRolesAndResponsibilityService.downloadWorkflowDocument(requestBody);
      return response;
    } catch (e: any) {
      rejectWithValue({ message: e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage });
    }
  }
);

export const sendReminder = createAsyncThunk("sendReminder", async (requestBody: any, { rejectWithValue }) => {
  try {
    const response = await contractRolesAndResponsibilityService.sendReminder(requestBody);
    return response;
  } catch (e: any) {
    rejectWithValue({ message: e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage });
  }
});


export const convertToPDF = createAsyncThunk("convertToPDF", async (requestBody: any, { rejectWithValue }) => {
  try {
    const response = await contractRolesAndResponsibilityService.convertTOPDF(requestBody);
    return response;
  } catch (e: any) {
    rejectWithValue({ message: e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage });
  }
});

export const contractRolesAndResponsibilitySlice = createSlice({
  name: "rolesAndResponsibility",
  initialState,
  reducers: {
    updaterolesandresponsAddandEdit: (state, action) => {
      state.isRolesAndResponsibilityAdded = false;
      state.isRolesAndResponsibilityUpdated = false;
      state.isRolesAndResponsibilityDeleted = false;
      state.RolesAndResonsibilityInstanceId = "";
      state.workFLowInstanceData = null;
    },

    updaterolesandresponsresposeData: (state, action) => {
      state.RolesAndResonsibilityInstanceId = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRolesAndResponsibilityList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRolesAndResponsibilityList.fulfilled, (state, action: PayloadAction<any>) => {
        state.contractRolesAndResponsibilityData = action.payload
          ? action.payload
          : // .map((user: any) => {
            //     console.log("inside iteration", user);
            //     // user.IsActive = user.IsActive === true ? "Active" : "Inactive";
            //     return { ...user, isActive: user.IsActive == true ? "Active" : "Inactive" };
            //   })
            [];
        state.isLoading = false;
      })
      .addCase(getRolesAndResponsibilityList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addRolesAndResponsibility.pending, (state) => {
        state.isLoading = true;
        state.isAddEditRolesAndResonsibilityLoading = true;
      })
      .addCase(addRolesAndResponsibility.fulfilled, (state, action: PayloadAction<any>) => {
        state.isRolesAndResponsibilityAdded = true;
        state.isLoading = false;
        state.isAddEditRolesAndResonsibilityLoading = false;
        state.RolesAndResonsibilitytoasterMsg = action?.payload?.data?.toasterMessage;
        //state.RolesAndResonsibilityInstanceId = action.payload?.ResponseProjectDetails;
      })
      .addCase(addRolesAndResponsibility.rejected, (state) => {
        state.isLoading = false;
        state.isAddEditRolesAndResonsibilityLoading = false;
      })
      .addCase(updateRRWorkflowProcess.pending, (state) => {
        state.isLoading = true;
        state.isAddEditRolesAndResonsibilityLoading = true;
      })
      .addCase(updateRRWorkflowProcess.fulfilled, (state, action: PayloadAction<any>) => {
        state.isRolesAndResponsibilityAdded = true;
        state.isLoading = false;
        state.isAddEditRolesAndResonsibilityLoading = false;
        state.RolesAndResonsibilitytoasterMsg = action?.payload?.toasterMessage;
        //state.RolesAndResonsibilityInstanceId = action.payload?.ResponseProjectDetails;
      })
      .addCase(updateRRWorkflowProcess.rejected, (state) => {
        state.isLoading = false;
        state.isAddEditRolesAndResonsibilityLoading = false;
      })
      .addCase(updateRolesAndResponsibility.pending, (state) => {
        state.isLoading = true;
        state.isAddEditRolesAndResonsibilityLoading = true;
      })
      .addCase(updateRolesAndResponsibility.fulfilled, (state, action: PayloadAction<any>) => {
        state.isRolesAndResponsibilityUpdated = true;
        state.isLoading = false;
        state.isAddEditRolesAndResonsibilityLoading = false;
        state.RolesAndResonsibilitytoasterMsg = action.payload.Message;
      })
      .addCase(updateRolesAndResponsibility.rejected, (state) => {
        state.isLoading = false;
        state.isAddEditRolesAndResonsibilityLoading = false;
      })
      .addCase(deleteRolesAndResponsibility.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRolesAndResponsibility.fulfilled, (state, action: PayloadAction<any>) => {
        state.isRolesAndResponsibilityDeleted = true;
        state.isLoading = false;
        state.RolesAndResonsibilitytoasterMsg = action.payload.Message;
      })
      .addCase(deleteRolesAndResponsibility.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteRRSetup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRRSetup.fulfilled, (state, action: PayloadAction<any>) => {
        state.isRolesAndResponsibilityDeleted = true;
        state.isLoading = false;
        state.RolesAndResonsibilitytoasterMsg = action.payload;
      })
      .addCase(deleteRRSetup.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getGetVCWorkflowProcessesData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGetVCWorkflowProcessesData.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.workFLowInstanceData = action.payload;
      })
      .addCase(getGetVCWorkflowProcessesData.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateCmWorkFlowInstance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCmWorkFlowInstance.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.updateWorkFLowInstance = action.payload;
      })
      .addCase(updateCmWorkFlowInstance.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(saveSigningAuthority.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveSigningAuthority.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.RolesAndResonsibilitytoasterMsg = action.payload;
      })
      .addCase(saveSigningAuthority.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getSigningStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSigningStatus.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.signingStatus = action.payload;
      })
      .addCase(getSigningStatus.rejected, (state) => {
        state.isLoading = false;
        state.signingStatus = {};
      })
      .addCase(getSigningAuthorities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSigningAuthorities.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.signingAuthorities = action.payload;
      })
      .addCase(getSigningAuthorities.rejected, (state) => {
        state.isLoading = false;
        state.signingAuthorities = {};
      })
      .addCase(saveTempSigningAuthorities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveTempSigningAuthorities.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(saveTempSigningAuthorities.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(convertToPDF.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(convertToPDF.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(convertToPDF.rejected, (state) => {
        state.isLoading = false;
      });;
  },
});

export const { updaterolesandresponsAddandEdit, updaterolesandresponsresposeData } =
  contractRolesAndResponsibilitySlice.actions;
export default contractRolesAndResponsibilitySlice.reducer;
