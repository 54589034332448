import { Default } from "react-toastify/dist/utils";

export const LovConfig = {
  All: "All",
  AQ_Catagory: "AQ Catagory",
  AQ_Group: "AQ Group",
  AQ_Responder: "AQ Responder",
  AQ_Responder_Type: "AQ Responder Type",
  AQ_Response_Type: "AQ Response Type",
  AQ_Type: "AQ Type",
  Assessment_Type: "Assessment Type",
  Assessment_TypeII: "Assessment Type II",
  Branch_Type: "Branch Type",
  Company_Constitution: "Company Constitution",
  Company_Type: "Company Type",
  Data_Type: "Data Type",
  Department_Level: "Department Level",
  Department_Type: "Department Type",
  AQ_Category_Level:"Assessment Questionaire Category Level",
  AQ_Category_Type:"Assessment Questionaire Category Type",
  Document_Ext: "Document Ext",
  Industry_Level: "Industry Level",
  Industry_Type: "Industry Type",
  LOB: "LOB",
  Location_Type: "Location Type",
  Maker_Checker: "Maker Checker",
  Process_Type: "ProcessType",
  Process_Workflow: "ProcessWorkflow",
  Purpose: "Purpose",
  Record_Status: "Record Status",
  Region: "Region",
  RiskCategoryI: "Risk Category I",
  RiskCategoryII: "Risk Category II",
  RiskCategoryIII: "Risk Category III",
  Sector: "Sector",
  ServiceType: "Service Type",
  ServiceGroup: "Service Group",
  Status: "Status",
  ThirdPartyType: "Third Party Type",
  UniqueIDType: "Unique Id Type",
  WorkFlowType: "WorkflowType",
  WorkflowCategory: "Workflow Category",
  Zone: "Zone",
  Regulator: "Regulator",
  VendorStatus: "Vendor Status",
  VendorTypeI: "Vendor Type I",
  VendorTypeII: "Vendor Type II",
  VOBWorkflowStatus: "VOB Workflow Status",
  COBWorkflowStatus: "COB Workflow Status",
  CMWorkflowStatus: "CM Workflow Status",
  COWorkflowStatus: "CO Workflow Status",
  PMWorkflowStatus: "PM Workflow Status",
  Pre_Approval: "Pre Approval",
  Designation: "Designation",
  Vendor_Contract_Process_Type: "Vendor Conract Process Type",
  Address_Type : "Address Type",
  Email_template_name : "Email Template Name",
  Assessment_Status : "Assessment Status",
  Nature_of_contract : "Nature of Contract",
  Contract_Nature: "Nature of Contract",
  pm_category: "PM Category",
  pm_reviewtrigger : "PM Review Trigger",
  pm_oblication: "PM Obligation",
  pm_Responder : "PM Responder",
  Frequency : "PM Frequency",
  Measured_In : "Measured In",
  PR_scope_Status : "PR Scope Status",
  PR_Status : "PR Status",
};
export const StatusConfig = {
  Active : "Active",
  Inactive : 'Inactive'
}
export const IndustryConfig = {
  Industry: "Industry",
  Sub_Industry: "Sub Industry",
  Industry_Level_1: "Level 1",
};

export const DepartmentConfig = {
  Department: "Department",
  Sub_Department: "Sub Department",
  Department_Level_1: "Level 1",
};

export const MetrixMasterConfig = {
  Deviation_Matrix : "Deviation Matrix",
  AQ_Response_Type : "AQ Response Type",
  Service_Scoringe : "Service Scoring"
}

export const AssessmentCategoryConfig = {
  Category: "Category",
  Sub_Category: "Sub Category",
  Category_Level_1: "Level 1",
  Category_Level_2: "Level 2",
  Category_Level_3: "Level 3",
};

export const LocationConfig = {
  Location_Country: "Country",
  Location_State: "State",
  Location_City: "City",
};

export const AQMConfig = {
  Regulatory: "Regulatory",
  High: "High",
  Medium: "Medium",
  Low: "Low",
  NA : "NA"
};

export const SLASeverity = {
  High: "High",
  Medium: "Medium",
  Low: "Low",
};
export const ToasterActivityConfig = {
  create: "create",
  view: "view",
  Edit: "Edit",
  export: "export",
  default: "default",
  approved: "approved",
  rejected: "rejected",
  active: "active",
  inactive: "inactive",
  createDraft: "Create Draft",
  editDraft: "Edit Draft",
  deleteDraft: "Delete Draft",
  Withdraw: "withdraw",
};

export const option_ToasterActivityConfig: any[] = [
  { id: ToasterActivityConfig.create, lable: "Create" },
  { id: ToasterActivityConfig.view, lable: "View" },
  { id: ToasterActivityConfig.Edit, lable: "Edit" },
  { id: ToasterActivityConfig.export, lable: "Export" },
  { id: ToasterActivityConfig.default, lable: "Default" },
  { id: ToasterActivityConfig.approved, lable: "Approved" },
  { id: ToasterActivityConfig.rejected, lable: "Rejected" },
  { id: ToasterActivityConfig.active, lable: "Active" },
  { id: ToasterActivityConfig.inactive, lable: "Inactive" },
  { id: ToasterActivityConfig.createDraft, lable: "Create Draft" },
  { id:ToasterActivityConfig.editDraft, lable: "Edit Draft" },
  { id: ToasterActivityConfig.deleteDraft , lable: "Delete Draft" },
  { id: ToasterActivityConfig.Withdraw , lable: "Withdraw" },
  { id: `${ToasterActivityConfig.create} Pending`, lable: "Create Pending" },
  { id: `${ToasterActivityConfig.Edit} Pending`, lable: "Edit Pending" },
  { id: `${ToasterActivityConfig.active} Pending`, lable: "Active Pending" },
  { id: `${ToasterActivityConfig.inactive} Pending` , lable: "Inactive Pending" },
];

export const userConfig = {
  Country: 39,
  State: 38,
};

export const vendorAssetStatus = {
  approve: "Approve",
  reject: "Reject",
};



export const vendorassetroles = {
  SME: "SME",
  SO: "Service Owner",
  DO: "Deviation Officer",
  SMEE: "SME",
};

export const AQMResponder = {
  SME: "SME",
  SO: "Service Owner",
  Vendor: "Vendor",
};

export const WorkflowCondition = {
  doApproval: "IsDOApprovalNeeded",
};

export const recordLockTimeOut = 3000; //15 mins
export const ignoreDate = "01-01-0001";

//this id use for notification show for vendor portal login use
export const MenuIdForVendoruse = 39;

export const VOBWorflowPages = {
  RR: "Roles and Responsibility",
  VendorDetail: "Vendor Details",
  AQMMapping: "Assessment Questionnaire Mapping",
  ConsentForm: "Consent Form",
  VendorResponse: "Vendor Assessment",
  SMEResponse: "SME Response",
  SMEReview: "SME Review",
  SOReview: "So Review",
  DoReview: "Do Review",
  CXO: "Sign Off",
};


export const PMWorflowPages = {
  RR: "Roles and Responsibility",
  initiateReview : "Initiate Review",
  VendorPortal: "Vendor Portal",
  SOReview: "So Review",
  CXO: "Sign Off",
}

export const CMWorflowPages = {
  RR: "Roles and Responsibility", 
  InitiateContract: "Initiate Contract" ,
  CostApproval:"Cost Approval",
  SMEReview: "SME Review",
  SOReview: "So Review",
  DoReview: "Do Review",
  VendorReview: "Send for Vendor Review",
  BoardApproval: "Board Approval",
  VendorPortal: "Vendor Portal",
  SignOff: "Sign Off",
  NotifySigningAuthority:"Notifying Signing authority",
  PhysicalUpload:"Physical Upload"
};

export const Edit_Enable_tosterMSG = {
  message: "This item wait for Approval",
};

export const Default_threshold = 100;

export const WorkflowCategory = (data: string): string => {
  switch (data) {
    case "Vendor Onboarding":
      return LovConfig.VOBWorkflowStatus;
    case "Customer Onboarding":
      return LovConfig.COBWorkflowStatus;
    case "Contract Management":
      return LovConfig.CMWorkflowStatus;
      case "Client Onboarding":
        return LovConfig.COWorkflowStatus;
        case "Performance Management":
          return LovConfig.PMWorkflowStatus;
        
    default:
      return "";
  }
};

export const WorkFlowType = {
  vendorOnboarding: "Vendor Onboarding",
  customerOnboarding: "Customer Onboarding",
  contractManagement: "Contract Management",
  PerformanceManagement: "Performance Management",
};

export const COBWorflowPages = {
  ApplicationParameter: "Application Parameter",
  RegionalSettings: "Regional Settings",
  MakerChecker: "Maker Checker",
  CompanyMaster: "Company Master",
};

export const Assessment_Status = {
  WIP : "Work in Progress",
  Expired: "Expired",
  Inactive: "Inactive",
  Active: "Active",
};


export const serviceCriticality = {
  material: "Materials",
  critical: "Critical",
  low_critical : "Low Critical",
  
};
export const ScoreDeviation = {
  low : "Low",
  critical: "Medium",
  low_critical : "Poor",
  
};

export const Assessment_Type ={ 
  Advanced : 'Advanced',
  Basic : 'Basic'
}

export const Applicable_For ={ 
  First_Assessment : 'First Assessment',
   Renewal : 'Renewal',
   Both : 'Both'
}

export const PM_frequency = {
  Half_yearly : "Half yearly",
  Monthly : "Monthly",
  Quarterly : "Quarterly",
  Yearly : "Yearly" 
}

export const PM_MeasuredIn = {
  Date : "Date",
  Hours : "Hours",
  Percentage : "Percentage"
}

 
export const ApplicationParameterName={
      EstimateSpend:"Estimate Spend",
      CurrentBasicWorkflowvob : 'Current Basic Workflow for VOB',
      CurrentAdvanceWorkflowvob : 'Current Advance Workflow for VOB',
       CurrentBasicWorkflowforRenewalvob : 'Current Basic Workflow for Renewal VOB',
      CurrentAdvanceWorkflowforRenewalvob : 'Current Advance Workflow for Renewal VOB',
      CurrentBasicWorkflowcob : 'Current Basic Workflow for COB',
      CurrentAdvanceWorkflowcob : 'Current Advance Workflow for COB',
       CurrentBasicWorkflowforRenewalcob : 'Current Basic Workflow for Renewal COB Renewal',
      CurrentAdvanceWorkflowforRenewalcob : 'Current Advance Workflow for COB Renewal',
      Masking:"Masking",
      Masking_PAN_Name:"PAN",
      Masking_TAN_Name:"TAN",
      Masking_CIN_Name:"CIN",
      Threshold :"Threshold for Outliers",
      ServiceCatalogGrpName:"Service catalog master",
      ServiceCatalogRegulatorCriticality:"Service Catalog regulator criticality",
      ServiceCatalogRegulatorMaxScoring:"Service Catalog regulator max scoring",
      ServiceCatalogMaxParameter:"Service Catalog max parameters",
      applicableRiskForMaterial:"Regulatory",
      DefaultCurrency :"Default Currency",
      APIMasterCode:"API Master",
      ApiGroupCode: "GroupCode",
      
      APICodePrefix:"API Code Prefix",
      APICatalogGrpName:"API Catalog",
      RecordTimeOut:"Record Time Out" ,
      companyDetailsFromTrirdPartys : "Company Details",
      PM_NotifyToResponder : "PM - Notification to Responder",

}

export const WorkFlowStatusName ={
  Completed:"Completed" ,
  NotStarted:"Not Started",
  Pending:"Pending"
}

export const ModuleNames = {
    
    workflowManager : 'Workflow Manager',
    CompanyMaster : 'Company Master',
    VendorMaster : 'Third Party Master',
    AQMaster : 'Assessment Questionnaire Master',
    UserMaster: 'User Master',
    IndustryMaster: 'Industry Master',
    RoleMaster: 'Role Master',
    ServiceCatalog: 'ServiceCatalog' ,
    scopeDefine : 'ServiceCatalog'
}

export const Register_Address_tosterMSG = {
  message: "Please Add Register Address",
};

export const VendorMaster_Address_Type ={ 
  RegisteredAddress : "Registered Address",
  BusinessAddress : "Business Address"
}

export const VendorMaster_InternalVendorCode ={ 
  VendorMaster : "Third Party Master",
  InternalVendorCode : "Internal Third Party Code",
  InternalVendorCodePrefix : "Internal Third Party Code Format prefix",
  InternalVendorCodeSuffix : "Internal Third Party Code Format suffix",
}

export const thirdPrtyType = {
     vendor : "Vendor" ,
     customer : "Customer"
}

export const PR_Scope_Status = {
  Approved : "Approved" ,
  Rejected : "Rejected" ,
  Approval_Pending : "Approval Pending" ,
  Not_Started : "Not Started"
}

export const PR_Event_Status = {
  Completed : "Completed" ,
  Rejected : "Rejected" ,
  Inprogress : "Inprogress" ,
  Not_Started : "Not Started"
}

export const ContractManagemnt_InternalCode ={ 
  ContractManagement : "Contract Management",  
  ContractMangementPrefix : "Contract No Format Prefix",
  ContractMangementSuffix : "Contract No Format suffix",
  Currency: "Currency",
  RenewalDate: "Renewal Date"
}

export const CompanyMaster_Address_Type ={
  RegisteredAddress : "Registered Address"
}
export const MasterBulkUpload = {
     
     exportButtonName : 'Export Template',
     ValidationRowCount : 100
}

export const Probe_ThirdParty_API_tosterMSG = {
  Succes_message: "Successfully loaded profile details",
  Failed_message: "Failed to load the profile details, check error log",
};

export const FormIdConfig = {
    AQMasterFormId  : 17,
    userMasterFormId : 2,
    departmentFormId : 7,
    IndustryFormId   : 8,
    LocationFormId   :9,
    DocumentFormId : 12,
    CurrencyFormId : 13,
    ThirdPartFormyId : 10,
    ContentFormId : 15,
    HolidayFormId : 41,
    roleMasterForm : 3 ,
    CompanyFormId : 19,
    ServiceCatalogFormId : 21,
    RiskCategoryFormId : 24,
    AQCategoryFormId : 40,
    VendorFormId : 22,
    ParameterFormId:18,
    SecurityParameterFormId: 20,
    RegionalSettingFormId:11,
    LovFormId:16,
    MetricsFormId:25,
    ClauseFormId:22,

}

export const ContentNameConfig = {
    
     vendorwelcometext : "Third Party Welcome Text",
     vendorContext : "Third Party Consent Text",
     cobQuestionSheetNote : "COB Questionnaire Template Note",
     PurposeName : "Full Form"
}
export const SecurityParameterName={
  VendorPortalLoginOtpTimeGroupName:"Vendor portal",
  VendorPortalLoginOtpTimeParameterName:"OTP Valid Time",
}

export const thirdPrtyApiDetailsParameterName = {

}

// export  const FullForm ={
//     ContentPurpose: 'Full Form'
// }