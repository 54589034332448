import { AxiosError, AxiosResponse } from "axios";
import { LOGGIN_URL } from "./endpoints/loggin";
import { ILogginResponse, IMenuRoleResponse } from "../../models/loggin.type";
import { axiosInstance } from "./config/axiosConfig";

const getLogginRequest = (requestParams: any): Promise<ILogginResponse> =>
  axiosInstance
    .post(LOGGIN_URL.Login_User, requestParams)
    .then((response: AxiosResponse<ILogginResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getLogginVendor = (requestParams: any): Promise<ILogginResponse> =>
  axiosInstance
    .post(LOGGIN_URL.Loggin_Vendor, requestParams)
    .then((response: AxiosResponse<ILogginResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });


const getMenuByRoleId = (requestParams: any): Promise<IMenuRoleResponse> =>
  axiosInstance
    .get(LOGGIN_URL.Get_MENUS_BY_Role_USER, {
      params: requestParams,
    })
    .then((response: AxiosResponse<IMenuRoleResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });



const getMenuByUserId = (requestParams: any): Promise<IMenuRoleResponse> =>
  axiosInstance
    .get(LOGGIN_URL.Get_MENUS_BY_USER, {
      params: requestParams,
    })
    .then((response: AxiosResponse<IMenuRoleResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

// const ValidateVendorOTP = (requestParams: any): Promise<ILogginResponse> =>
//   axiosInstance
//     .post(LOGGIN_URL.ValidateVendorOtp, requestParams)
//     .then((response: AxiosResponse<ILogginResponse>) => {
//       if (response.status === 200) {
//         return response.data;
//       }
//       throw new Error("Error getting Data" + response);
//     })
//     .catch((error: AxiosError | string) => {
//       throw error;
//     });

const GetMailForOTP = (requestParams: any): Promise<ILogginResponse> =>
  axiosInstance
    .post(LOGGIN_URL.GetMailforOTP, requestParams)
    .then((response: AxiosResponse<ILogginResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const loginService = {
  getLogginRequest,
  getMenuByRoleId,
  getLogginVendor,
  getMenuByUserId,
  GetMailForOTP,
};

export default loginService;
