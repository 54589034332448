import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { AddEmailTemplate } from "./AddEmailTemplate";
import { useSelector, useDispatch } from "react-redux";
import { RootState, useAppDispatch, useAppDispatchThunk } from "../../store/store";
import { DeleteEmailTemplete, getAllMenuModulesList, getEmailTemplateList, updateAddandEdit } from "../../store/slices/EmailTemplateData";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import { IEmailTemplate } from "../../models/authorize.type";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { IMenu } from "../../models/loggin.type";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import PageMainHeading from "../../common/CommonHeadings/PageMainHeading";
import { RecordLockRelease } from "../recordLock/CommonRecordLock";


export default function EmailTemplate() {
  const [addPopup, setAddPopup] = useState(false);
  const [permissionPage, setPermissionForPage] = useState<IMenu | null>(null);
  const { dataTable } = useSelector((state: RootState) => state.emailTemplateData);
  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { isEmailTemplatedeleted, isEmailLoading, emailtemplatetoasterMsg, isEmailTemplateAdded, isEmailTemplateUpdated } = useSelector(
    (state: RootState) => state.emailTemplateData
  );
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);

  const [emailTemplateDatas, setEmailTemplateData] = useState<IEmailTemplate[]>([]);
  const [option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isEditData, setIsEditData] = useState<any>({});
  const [editId, setEditId] = useState<any>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const { currentpageTitleData } = useSelector(
    (state: RootState) => state.FormTitleMasterData
  );
  const { handleRecordLockUpdate } = RecordLockRelease();

  // useEffect(() => {
  //   const handleTabClose = async (event: BeforeUnloadEvent) => {
  //     const data = recordLockUpdate();
  //     console.log(data, "dataLock");
  //     if (data.length > 0) {
  //       event.preventDefault();
  //       event.returnValue = "";
  //       try {
  //         await dispatch(UpdateRecordLock(data.pop())).unwrap();
  //       } catch (error) {
  //         console.error("Failed to update record lock:", error);
  //       }
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleTabClose);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleTabClose);
  //   };
  // }, []);

  const dispatch = useAppDispatch();

  const closeAddForm = () => {
    setIsEditData({})
    dispatch(getEmailTemplateList());
  }
  const columns = {
    table_name: "Email table",
    isAction: true,
    columns: [
      {
        Field: "ModuleName",
        FieldType: "string",
        DisplayName: "ModuleName",
      },
      {
        Field: "TemplateName",
        FieldType: "string",
        DisplayName: "Template Name",
      },
      {
        Field: "EmailSubject",
        FieldType: "string",
        DisplayName: "EmailSubject",
      },
      {
        Field: "FromEmail",
        FieldType: "string",
        DisplayName: "FromEmail",
      },
      {
        Field: "Template",
        FieldType: "string",
        DisplayName: "Template",
      },
      {
        Field: "IsActive",
        FieldType: "string",
        DisplayName: "Status",
      },
    ],
  };

  useEffect(() => {
    callPageloading()
    dispatch(getAllMenuModulesList());
  }, [dispatch]);

  const callPageloading = () => {
    dispatch(getEmailTemplateList());
  }


  useEffect(() => {
    if (dataTable?.emailTemplate.length > 0) {
      setEmailTemplateData(dataTable?.emailTemplate);
      // dispatch(updateLoader(false));
    } else {
      // dispatch(updateLoader(false));
    }
  }, [dataTable, dispatch]);

  useEffect(() => {
    if (isEmailTemplatedeleted) {
      callPageloading()
      dispatch(updateAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: emailtemplatetoasterMsg }));
      setIsDeleteData("");
      setOption('')
    }
    if (isEmailTemplateAdded) {
      dispatch(getEmailTemplateList());
      dispatch(updateToaster({ isToaster: true, toasterMsg: emailtemplatetoasterMsg }));
      dispatch(updateAddandEdit(false));

    } else if (isEmailTemplateUpdated) {
      dispatch(getEmailTemplateList());
      handleRecordLockUpdate()
      dispatch(updateToaster({ isToaster: true, toasterMsg: emailtemplatetoasterMsg }));
      dispatch(updateAddandEdit(false));
    }

  }, [isEmailTemplatedeleted, isEmailTemplateAdded, isEmailTemplateUpdated]);


  useEffect(() => {
    dispatch(updateLoader(isEmailLoading));
  }, [isEmailLoading]);


  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForPage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  // const handleAddRecordLock = (id: number) => {
  //   const recordLockData = {
  //     RecordId: id,
  //     UserId: userLoginResponse?.UserId || 0,
  //     CreatedBy: userLoginResponse?.UserName || "",
  //     MenuId: permissionPage?.MenuId,
  //     ModuleName: "EmailTemplate",
  //     Activity: "edit",
  //   };
  //   return recordLockData;
  // };
  // const handleRecordLockCheck = async (recordId: number) => {
  //   const resultAction = await dispatch(GetRecordLock(recordLockGet(recordId)));
  //   if (GetRecordLock.fulfilled.match(resultAction)) {
  //     const recordLockData = resultAction.payload;
  //     if (recordLockData && recordLockData.length > 0) {
  //       setIsEditing(true);
  //       dispatch(updateLoader(false));
  //       dispatch(
  //         updateToaster({
  //           isToaster: true,
  //           toasterMsg: `${resultAction.payload[0]?.CreatedBy} is Editing`,
  //           isTosterFailed: true,
  //         })
  //       );
  //       return false;
  //     }
  //   }
  //   return true;
  // };

  const handleAction = async (data: any, opt: string) => {
    setOption(opt);
    if (opt === "edit") {
      setEditId(data?.EmailTempId);
      setIsEdit(true);
      setIsEditData(data);
      
    } else if (opt === "view") {
      setIsEdit(true);
      setIsEditData(data);
    } else if (opt === "delete") {
      setIsDeleteData(data);
    }
  };

  const inactiveTemplate = () => {
    console.log(isDeleteData);

    let JsonDataForDisplay: any = {
      "Department Name": isDeleteData.DepartmentName,
      "Status": isDeleteData.IsActive === "Active" ? "inactive" : "active",
      "Modified By": userLoginResponse?.UserName,
    }
    let inactiveFormData = {
      "ModuleName": "Email Template",
      "Activity": isDeleteData.IsActive === "Active" ? "inactive" : "active",
      "UserId": userLoginResponse?.UserId,
      "MenuId": 0,
      "Form_Id": 0,
      "EmailTemplateId": isDeleteData.EmailTempId,
      "TemplateName": isDeleteData.TemplateName,
      "IsActive": isDeleteData.IsActive === "Active" ? 0 : 1,
      CreatedBy: userLoginResponse?.UserName,
    }

    dispatch(DeleteEmailTemplete(inactiveFormData));
  };

  
  return (
    <>
      <CustomDialog
        show={addPopup}
        onHide={() => setAddPopup(false)}
        maxWidth={"xl"}
        header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Email Template"}`}
        contentNode={
          <AddEmailTemplate
            view={false}
            isAdded={(flag: boolean) => {
              setAddPopup(flag);
            }}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          setIsEdit(false);
        handleRecordLockUpdate();
        }}
        maxWidth={option === "edit" ? "Edit Vendor" : "View Vendor"}
        header={option === "view" ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Email Template"}` : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Email Template"}`}
        contentNode={
          <>
            {option === "edit" && (
              <AddEmailTemplate isEditData={isEditData} edit={true} view={false} isEdited={() =>{ handleRecordLockUpdate() ;
                setIsEdit(false) }} />
            )}
            {option === "view" && (
              <AddEmailTemplate view={true} isEditData={isEditData} edit={false} isEdited={() => setIsEdit(false)} />
            )}
          </>
        }
      />
      {/* <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "1rem 0px",
        }}
      >
        <CustomButton
          variant="contained"
          name={"Add Email Template "}
          onClick={() => {
            setAddPopup(true);
          }}
        />
      </Grid> */}
      <Stack spacing={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
          }}
        >
          <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                            {/* Holiday Master */}
                        </Typography>

        </Box>
        <MaterialDynamicGrid
          data={columns}
          rows={emailTemplateDatas}
          handleAction={handleAction}
          enableZoomIcon={true}
          showhistory={false}
          handleAdd={() => setAddPopup(true)}
          showAdd={permissionPage?.IsAdd === 1}
          showDelete={permissionPage?.IsDelete === 1}
          showView={permissionPage?.IsView === 1}
          showEdit={permissionPage?.IsEdit === 1}
          showExports={permissionPage?.IsExport === 1}
          userId={userLoginResponse?.UserId || 0}
          isEnableRecordLock = {{ IdField : "EmailTempId" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                            // FormID : FormIdConfig?.IndustryFormId,
                                          
                                            MenuID : currentPagePermissionData?.MenuId,
                                            IsCheckPending : false  }}}
          pageName="Email Template Master"
        />
        <ModalPopUp
          onDeleteMethod={() => inactiveTemplate()}
          isDelete={true}
          onHide={() => setIsDeleteData(null)}
          show={isDeleteData ? true : false}
          header={"Confirm"}
          text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"} the selected Email Template`}
          ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
        />
      </Stack>
    </>
  );
}
