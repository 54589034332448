import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { AQMResponder, LocationConfig, option_ToasterActivityConfig, ToasterActivityConfig } from "../../config/config";
import dayjs from "dayjs";
import CustomDatePicker from "../../common/dynamicInputs/CustomDatePicker";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomMultiSelect from "../../common/dynamicInputs/CustomMultiSelect";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { textFieldWidth } from "../../utils/constants";
import { getServiceCatalogList } from "../../store/slices/serviceCatalog";
import { getDepartmentList } from "../../store/slices/departmentData";
import { getRolesList } from "../../store/slices/roleData";
import { getUsersList } from "../../store/slices/userData";
import LableandValuesPreview from "../../common/CommonPreview/LableandValuesPreview";
import CustomCheckBox from "../../common/dynamicInputs/CustomCheckBox";
import { UpdatePrimaryUser } from "../../store/slices/pendingAction";

type SubmitUserData = {
    PrimaryUserIds: string;
    NewUserEmailIds: string;
    UserIds: Array<{ OldId: any; NewId: any }>;
};

interface FormData {
    VendorId?: any;
    Assessment_Number: any;
    Vendor_Name: any;
    ProcessName: any;
    Primary_User: any;
    New_Primary_User: any;
    DepartmentId: any;
    IsApplicableAllTasks: any;
    [key: `DepartmentId_${number}`]: any;
    [key: `New_Primary_User_${number}`]: any;
}

export interface ChangeTaskOwnerProps {
    view?: boolean;
    closePopUp?: () => void;
    edit?: boolean;
    isEditData?: any;
    UserRoll?: any;
}

export const ChangeTaskOwner: React.FC<ChangeTaskOwnerProps> = ({ view, closePopUp, edit, isEditData, UserRoll }) => {

    const dispatch = useAppDispatchThunk();
    const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
    const [PrimeUserData, setPrimeUserData] = useState<any>([]);
    const [SmeDeptBasedUser, setSmeDeptBasedUser] = useState<any>([]);
    const [SoSelectedPrimaryUser, setSoSelectedPrimaryUser] = useState<any>([]);
    const { ActiveServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);
    const { allUsers } = useSelector((state: RootState) => state.userData);
    const { dataTable } = useSelector((state: RootState) => state.departmentData);
    const { allRoles } = useSelector((state: RootState) => state.roleData);


    let formData: any = {
        VendorId: isEditData ? isEditData?.VendorId : 0,
        Assessment_Number: isEditData ? isEditData?.Assessment_Number : "",
        Vendor_Name: isEditData ? isEditData?.Vendor_Name : "",
        ProcessName: isEditData ? isEditData?.ProcessName : "",
        // Primary_User: isEditData ? isEditData?.Primary_User : "",

        IsApplicableAllTasks: isEditData ? isEditData?.IsApplicableAllTasks : false,
    };

    const {
        handleSubmit: handleTitleForm,
        reset,
        setValue,
        formState: { errors: errorsloginform, isDirty },
        control,
        watch,
        getValues,
        clearErrors,
    } = useForm<FormData>({
        defaultValues: formData,
        mode: "onChange",
    });

    const PrimaryUserWatch = watch("Primary_User");
    const DepartmentWatch = watch("DepartmentId");
    const logedUser: any = userLoginResponse?.UserId;

    useEffect(() => {
        if (PrimaryUserWatch) {
            const selUsers = allUsers?.filter((item: any) =>
                PrimaryUserWatch?.some((PrimaryUser: any) => PrimaryUser === item?.UserId)
            );
            setSoSelectedPrimaryUser(selUsers);

            const FilteredDepartment = dataTable?.filter((item: any) =>
                selUsers?.some((depart: any) => depart?.DepartmentId === item?.DepartmentId)
            );

            if (FilteredDepartment.length > 0 && PrimaryUserWatch) {
                PrimaryUserWatch.map((user: any, index: number) => {
                    setValue(`DepartmentId_${index}`, FilteredDepartment[index]?.DepartmentId); 

                })
            }

        }

    }, [PrimaryUserWatch, setValue, dataTable, allUsers]);

    useEffect(() => {
        if (UserRoll == AQMResponder.SME) {


            const SMEUsers = allUsers?.filter((item) => logedUser === item?.UserId);

            const FilteredDepartment = dataTable?.filter((item: any) =>
                SMEUsers?.some((depart: any) => depart?.DepartmentId === item?.DepartmentId)
            );
            const DepartmentBasedUsers = allUsers?.filter((item) => FilteredDepartment[0]?.DepartmentId === item?.DepartmentId  && SMEUsers[0]?.RoleId === item?.RoleId && logedUser != item?.UserId );

            setSmeDeptBasedUser(DepartmentBasedUsers);

            if (FilteredDepartment.length > 0) {
                setValue("DepartmentId", FilteredDepartment[0]?.DepartmentId); // Set the first matched DepartmentId
            }
        }

    }, []);


    useEffect(() => {

        dispatch(getServiceCatalogList());
        dispatch(getDepartmentList({ FormID: 2 }));
        dispatch(getRolesList({ formId: 3 }));
        dispatch(getUsersList({ FormID: 2 }));

    }, []);

    const PrimaryUser = (primaryUser: string) => {
        const emails = primaryUser?.split(',').map((email) => email.trim());

        const primaryUserData = allUsers?.filter((item) => emails.includes(item.EmailId));

        setPrimeUserData(primaryUserData);

        // return primaryUserData;
    };
    useEffect(() => {

        PrimaryUser(isEditData?.Primary_User);

    }, [isEditData]);

    const handleComparison = (PrimaryUserWatch: number[], data: any, primaryUserData: any) => {
        console.log(PrimaryUserWatch, data, primaryUserData, "primaryUserwatch, data, primaryUserData");

        let SubmitUserData: SubmitUserData = {
            PrimaryUserIds: "",
            NewUserEmailIds: "",
            UserIds: [],
        };

        let filteredPrimaryUserData: any

        if (UserRoll === AQMResponder?.SME) {

            filteredPrimaryUserData = primaryUserData?.filter(
                (userId: any) => PrimaryUserWatch != userId
            );
            filteredPrimaryUserData?.push(data?.New_Primary_User);

            let newUserEmail = allUsers?.filter((item: any) => data?.New_Primary_User === item.UserId);
            SubmitUserData.NewUserEmailIds = newUserEmail?.map((user: any, index: number) => { return user.EmailId }).join(", ");

            // SubmitUserData.UserIds.push({
            //     OldId: Number(PrimaryUserWatch),
            //     NewId: Number(data?.New_Primary_User),
            // });

        } else {
            filteredPrimaryUserData = primaryUserData?.filter(
                (userId: any) => !PrimaryUserWatch?.includes(userId)
            );
            let newUser: any[] = []; 

            PrimaryUserWatch?.forEach((user: number, index: number) => {
                const newPrimaryUser = data[`New_Primary_User_${index}`];
                if (newPrimaryUser) {
                    newUser?.push(newPrimaryUser);
                    filteredPrimaryUserData?.push(newPrimaryUser);

                    SubmitUserData.UserIds?.push({
                        OldId: user,
                        NewId: Number(newPrimaryUser),
                    });
                }
            });
            let NewUserEmail = allUsers?.filter((item: any) => newUser?.includes(item.UserId));
            SubmitUserData.NewUserEmailIds = NewUserEmail?.map((user: any, index: number) => { return user.EmailId }).join(", ");

        }
        SubmitUserData.PrimaryUserIds = filteredPrimaryUserData.join(", ");

        console.log(filteredPrimaryUserData, SubmitUserData, "filteredPrimaryUserWatch");
        return SubmitUserData
    };

    const handleSubmit = (data: any) => {
        let formData: any = {};
        console.log(data, "data");

        const oldprimeDataIDS = PrimeUserData.map((user: any, index: number) => {
            return user.UserId
        })


        formData.Workflow_InstanceId = isEditData?.Workflow_InstanceId;
        formData.Process_InstanceId = isEditData?.Id;
        formData.VendorName = isEditData?.Vendor_Name;
        formData.Expiry_Date = dayjs(isEditData?.Expiry_Date).format("DD-MMM-YYYY");
        formData.Updated_By = userLoginResponse?.UserEmail;
        formData.Updated_By_Name = userLoginResponse?.UserName;
        formData.ModuleName = "WorkflowDelegateUsers";
        formData.Activity = "Update";


        if (UserRoll === AQMResponder.SME) {

            const FormatePrimeUser = handleComparison(logedUser, data, oldprimeDataIDS);
            console.log(FormatePrimeUser, "FormatePrimeUser");
            formData.PrimaryUserIds = FormatePrimeUser?.PrimaryUserIds;
            formData.NewUserEmailIds = FormatePrimeUser?.NewUserEmailIds;
            formData.UserIds = [];
            formData.IsChangetoAllTasks = false;

            dispatch(UpdatePrimaryUser(formData));
        } else {
            const FormatePrimeUser = handleComparison(PrimaryUserWatch, data, oldprimeDataIDS);
            console.log(FormatePrimeUser, "FormatePrimeUser");
            formData.PrimaryUserIds = FormatePrimeUser?.PrimaryUserIds;
            formData.NewUserEmailIds = FormatePrimeUser?.NewUserEmailIds;
            formData.IsChangetoAllTasks = data?.IsApplicableAllTasks;
            if (data?.IsApplicableAllTasks) {

                formData.UserIds = FormatePrimeUser?.UserIds;
            } else {

                formData.UserIds = [];
            }

            dispatch(UpdatePrimaryUser(formData));
        }
    };


    return (
        <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
            <Grid
                // item
                // item xs={12} sm={12} md={6} lg={6} xl={6}
                sx={{
                    backgroundImage: `url()`,
                    backgroundRepeat: "no-repeat",
                    // backgroundColor: (t) =>
                    //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
                    // backgroundSize: "cover",
                    backgroundPosition: "center",
                    // height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    margin: "0",
                }}
            >

                <Grid
                    sx={{
                        height: "auto",
                        width: "100%",
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            px: {
                                xs: 2,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            },

                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>

                            <Grid container spacing={2}>

                                <Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <LableandValuesPreview lable="Assessment Number" Value={`${isEditData?.Assessment_Number || '-'}`} />
                                </Grid>
                                <Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <LableandValuesPreview lable="Third Party Name" Value={`${isEditData?.Vendor_Name || '-'}`} />

                                </Grid>
                                <Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <LableandValuesPreview lable="Process" Value={`${isEditData?.ProcessName || '-'}`} />

                                </Grid>
                                <Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <LableandValuesPreview lable="Service" Value={`${isEditData?.Service || '-'}`} />
                                </Grid>

                                <Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    {UserRoll == AQMResponder.SME ? (
                                        <LableandValuesPreview lable="Primary User" Value={`${userLoginResponse?.UserEmail || '-'}`} />

                                    ) : (
                                        <Controller
                                            name="Primary_User"
                                            control={control}
                                            defaultValue={[]}
                                            rules={{
                                                required: "Template Name is Required",
                                            }}
                                            render={({ field }) => (
                                                <CustomMultiSelect
                                                    optionLabelKey="EmailId"
                                                    optionIdKey="UserId"
                                                    options={PrimeUserData}
                                                    required={true}
                                                    readOnly={view}
                                                    label={"Current Primary User"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    error={Boolean(errorsloginform.Primary_User && errorsloginform.Primary_User.message?.toString())}
                                                    helperText={
                                                        errorsloginform.Primary_User &&
                                                        errorsloginform.Primary_User.message?.toString()
                                                    }
                                                />
                                            )}
                                        />

                                    )}

                                </Grid>

                            </Grid>

                            <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold", mt: 2, fontSize: '18px' }}>
                                New Primary User
                            </Typography>


                            {UserRoll === AQMResponder.SME ? (
                                // Render one field if UserRoll is SME
                                <Grid container spacing={2} sx={{}}>
                                    <Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >
                                        <Controller
                                            name="DepartmentId"
                                            defaultValue={""}
                                            control={control}
                                            rules={{
                                                required: "Department Name is Required",
                                            }}
                                            render={({ field }) => (
                                                <CustomSelect
                                                    name="select"
                                                    valueKey="DepartmentId"
                                                    optionLabelKey="DepartmentName"
                                                    options={dataTable}
                                                    required={true}
                                                    label={"Department"}
                                                    readOnly={true}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    error={Boolean(errorsloginform.DepartmentId?.message)}
                                                    helperText={errorsloginform.DepartmentId && errorsloginform.DepartmentId?.message?.toString()}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >

                                        <Controller
                                            name="New_Primary_User"
                                            control={control}
                                            rules={{
                                                required: "New Primary User is Required",
                                            }}
                                            render={({ field }) => (
                                                <CustomSelect
                                                    name="select"
                                                    valueKey="UserId"
                                                    optionLabelKey="EmailId"
                                                    options={SmeDeptBasedUser}
                                                    required={true}
                                                    label={"New Primary User"}
                                                    disabled={view}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    error={errorsloginform.New_Primary_User && errorsloginform.New_Primary_User.message?.toString()}
                                                    helperText={
                                                        errorsloginform.New_Primary_User &&
                                                        errorsloginform.New_Primary_User.message?.toString()
                                                    }
                                                />
                                            )}
                                        />

                                    </Grid>
                                </Grid>

                            ) : (
                                SoSelectedPrimaryUser &&
                                Array.isArray(SoSelectedPrimaryUser) &&
                                SoSelectedPrimaryUser.map((user: any, index: number) => (
                                    <Grid container spacing={2} sx={{ mt: 1 }}>

                                        <Grid item
                                            xs={textFieldWidth.xs}
                                            sm={textFieldWidth.sm}
                                            md={textFieldWidth.md}
                                            lg={textFieldWidth.lg}
                                            xl={textFieldWidth.xl} key={index}>
                                            <Controller
                                                name={`DepartmentId_${index}`} // Dynamic field name
                                                control={control}
                                                defaultValue={""}
                                                // rules={{
                                                //     required: `Department Name is Required for User ${index + 1}`,
                                                // }}
                                                render={({ field }) => (
                                                    <CustomSelect
                                                        name="select"
                                                        valueKey="DepartmentId"
                                                        optionLabelKey="DepartmentName"
                                                        options={dataTable}
                                                        // required
                                                        readOnly={true}
                                                        label={`Department`}
                                                        value={field.value}
                                                        onChange={() => {
                                                            field.onChange();
                                                            // SOnewPrimaryUser(user, index)
                                                        }}
                                                        error={Boolean(
                                                            errorsloginform[`DepartmentId_${index}`]?.message
                                                        )}
                                                        helperText={
                                                            errorsloginform[`DepartmentId_${index}`]?.message?.toString()
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={textFieldWidth.xs}
                                            sm={textFieldWidth.sm}
                                            md={textFieldWidth.md}
                                            lg={textFieldWidth.lg}
                                            xl={textFieldWidth.xl}
                                            key={index}
                                        >

                                            <Controller
                                                name={`New_Primary_User_${index}`}
                                                control={control}
                                                defaultValue={""}
                                                rules={{
                                                    required: "New Primary User is Required",
                                                }}
                                                render={({ field }) => (
                                                    <CustomSelect
                                                        name="select"
                                                        valueKey="UserId"
                                                        optionLabelKey="EmailId"
                                                        options={allUsers?.filter((item) => watch(`DepartmentId_${index}`) === item?.DepartmentId && user?.RoleId === item?.RoleId && user?.UserId != item?.UserId )}
                                                        required={true}
                                                        label={`New Primary User ${index + 1}`}
                                                        disabled={view}
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        error={Boolean(
                                                            errorsloginform[`New_Primary_User_${index}`]?.message
                                                        )}
                                                        helperText={
                                                            errorsloginform[`New_Primary_User_${index}`]?.message?.toString()
                                                        }
                                                    />
                                                )}
                                            />

                                        </Grid>
                                    </Grid>
                                ))
                            )}

                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Controller
                                        name="IsApplicableAllTasks"
                                        control={control}
                                        defaultValue={false}
                                        // rules={{
                                        //   required: "Required Status",
                                        // }}
                                        render={({ field }) => (

                                            <CustomCheckBox
                                                label="Applicable to all remaining actions in this assessment"
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.IsApplicableAllTasks)}
                                                helperText={errorsloginform.IsApplicableAllTasks && errorsloginform.IsApplicableAllTasks.message?.toString()}
                                                isStyle={true}
                                            />

                                        )}
                                    />
                                </Grid>

                            </Grid>

                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "20px",
                                    justifyContent: "right",
                                    mt: 2,
                                }}
                            >
                                {view ? (
                                    <CustomButton
                                        type="reset"
                                        name={"Cancel"}
                                        onClick={() => {
                                            closePopUp && closePopUp();
                                        }}
                                    />
                                ) : edit ? (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton
                                            type="reset"
                                            disabled={!isDirty}
                                            name={"Update"}
                                            onClick={handleTitleForm(handleSubmit)}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton
                                            type="reset"
                                            disabled={!isDirty}
                                            name={"Submit"}
                                            onClick={handleTitleForm(handleSubmit)}
                                        />
                                    </React.Fragment>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>

            </Grid>
        </Grid>
    );
};
