import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ScopeDefineService from "../services/scopeDefine";


export interface PMScopeDefineData {
    ScopeListTable: any[];
    isScopeLoading: boolean;
    isScopeAdded: boolean;
    isScopeApproveandReject: boolean;
    ToasterMsg: { Message: string; Status: string };
    ScopeDataByID: any;
    ScopeDataByEventLogID: any;
}

export const initialState: PMScopeDefineData = {
    ScopeListTable: [],
    isScopeLoading: false,
    isScopeAdded: false,
    isScopeApproveandReject: false,
    ToasterMsg: { Message: "", Status: "" },
    ScopeDataByID: {},
    ScopeDataByEventLogID: {},
};

export const getAllPMScope = createAsyncThunk(
    "getAllPMScope",
    async (requestParams: { FormID: number }, { rejectWithValue }) => {
        try {
            const response  = await ScopeDefineService.getAllScope();
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getPMScopeById = createAsyncThunk("getPMScopeById", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await ScopeDefineService.getScopeById(requesrParams);
        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getScopeByEventLogId = createAsyncThunk("getScopeByEventLogId", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await ScopeDefineService.getScopeByEventLogId(requesrParams);
        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const addUpdateScope = createAsyncThunk("addUpdateScope", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await ScopeDefineService.addUpdateScope(requesrParams);

        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const ApproveRejectDefinScope = createAsyncThunk("ApproveRejectDefinScope", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await ScopeDefineService.ApproveRejectScope(requesrParams);
        console.log(requesrParams, "requesrParams");

        return response.toaster;
    } catch (error) {
        return rejectWithValue(error);
    }
});


export const PMScopeDataSlice = createSlice({
    name: "PMScopeData",
    initialState,
    reducers: {
        PMScopeAddandEditUpdate: (state, action) => {
            state.isScopeAdded = false;
            state.isScopeApproveandReject = false;
            state.ToasterMsg.Message = "";
            state.ToasterMsg.Status = "";
        }
      
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllPMScope.pending, (state) => {
               
            })
            .addCase(getAllPMScope.fulfilled, (state, action) => {                
                state.ScopeListTable = action.payload.map((data: any) => {
                    data.IsActive = data.isActive === true ? "Active" : "Inactive";
                    return data;
                });
              
            })
            .addCase(getAllPMScope.rejected, (state) => {
            })
            .addCase(getPMScopeById.pending, (state) => {
                state.isScopeLoading = true;
            })
            .addCase(getPMScopeById.fulfilled, (state, action: PayloadAction<any>) => {
                state.isScopeLoading = false;
                state.ScopeDataByID = action.payload;
            })
            .addCase(getPMScopeById.rejected, (state, action: PayloadAction<any>) => {
                state.isScopeLoading = false;
            })
            .addCase(getScopeByEventLogId.pending, (state) => {
                state.isScopeLoading = true;
            })
            .addCase(getScopeByEventLogId.fulfilled, (state, action: PayloadAction<any>) => {
                state.isScopeLoading = false;
                state.ScopeDataByEventLogID = action.payload;
            })
            .addCase(getScopeByEventLogId.rejected, (state, action: PayloadAction<any>) => {
                state.isScopeLoading = false;
            })
            .addCase(addUpdateScope.pending, (state) => {
                state.isScopeLoading = true;
            })
            .addCase(addUpdateScope.fulfilled, (state, action: PayloadAction<any>) => {
                state.isScopeAdded = true;
                state.isScopeLoading = false;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(addUpdateScope.rejected, (state, action: PayloadAction<any>) => {
                state.isScopeLoading = false;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(ApproveRejectDefinScope.pending, (state) => {
                state.isScopeLoading = true;
            })
            .addCase(ApproveRejectDefinScope.fulfilled, (state, action: PayloadAction<any>) => {
                state.isScopeLoading = false;
                state.isScopeApproveandReject = true;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(ApproveRejectDefinScope.rejected, (state, action: PayloadAction<any>) => {
                state.isScopeLoading = false;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
           
    },
});

export const { PMScopeAddandEditUpdate } = PMScopeDataSlice.actions;
export default PMScopeDataSlice.reducer;
