import {
    Box,
    Divider,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import React, { useContext, useEffect, useMemo, useState } from "react";
  import CustomButton from "../../../../common/dynamicButton/CustomButton";
  
  import BasicTableStyle from "../../../../common/dynamicDataGrid/basicTable.module.scss";
  import CustomCheckBox from "../../../../common/dynamicInputs/CustomCheckBox";
  import CustomMultilineTextField from "../../../../common/dynamicInputs/CustomMultilineTextField";
  import CustomDialog from "../../../../common/dynamicDialog/CustomDialog";
import { AddTargetKPI } from "./addTargetKPI/AddTargetKPI";
  
import { useTheme } from "@mui/material/styles";
import { RequirementDetailContext } from "../ScopeDefinition";
import CustomSvg from "../../../../common/CustomSvg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
  interface TargetKPIprops {
    view?: boolean;
    closePopUp?: any;
    edit?: boolean;
    isEditData?: any;
    add?: boolean;
  }
  export const TargetKPI: React.FC<TargetKPIprops> = ({ view, closePopUp, edit, isEditData }) => {
      const theme = useTheme();
     const Requirementcontext = useContext(RequirementDetailContext);
     const {
      requirementList,
      setRequirementList,
      validationErrors,
      selectedRequirement ,
      selectedRequirementIndex,
      addTarget,
      isnotargetwarning,
      setaddTarget
    } = Requirementcontext!;
    
        const { RiskRateDataActive } = useSelector((state: RootState) => state.RiskRateData);
    const [applicableRiskData, setapplicableRiskData] = useState<any>([]);
    const [requirementTargetData, setrequirementTargetData] = useState<any>([]);

    useEffect(() => {

      if(selectedRequirement && selectedRequirement?.pmprClauseTarget){
        setrequirementTargetData(selectedRequirement?.pmprClauseTarget)
      }else{
        setrequirementTargetData([])
      }
       
    },[selectedRequirement])

    useEffect(() => {

      if(RiskRateDataActive){
        setapplicableRiskData(RiskRateDataActive) 
      }

    },[RiskRateDataActive])
    console.log(requirementTargetData , "requirementTargetData");
    
  
    const headers = useMemo(
      () => [
        {
          label: "S.NO",
          Field: "Sno",
          condition: true,
          sticky : true ,
          left: 0,
          minWidth: "50px",
          render: (row: any, index: any) => (
            <Box
              sx={{
                height: "18px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {index + 1}
            </Box>
          ),
        },
        {
          label: "Scope",
          Field: "pmprctScope",
          condition: true,
          sticky : true ,
          left: 50,
          minWidth: "250px",
          render: (row: any, index: number) => <span style={{ cursor: "pointer" }}>{row?.pmprctScope ? row?.pmprctScope?.scope_Of_Target : "" }</span>,
        },
        { label: "Category", minWidth: "150px", Field: "category_Name", condition: true, render: (row: any) => row?.pmprctScope ? row?.pmprctScope?.category_Name : "" },
        {
          label: "Deliverables",
          Field: "clasue",
          minWidth: "150px",
          condition: true,
          render: (row: any) => row?.pmprctScope ? row?.pmprctScope?.deliverables : "",
        },
        { label: "Oblication To", Field: "obligation_Name", 
          minWidth: "150px", condition: true, render: (row: any) => row?.pmprctScope ? row?.pmprctScope?.obligation_Name : "",  },
       
          ...applicableRiskData.map((risk_item : any) => ({
            label: risk_item?.RiskCategory,
            condition: true,
            minWidth: "150px", 
            render: (row: any) => { 
              const val = row?.pmprctScope?.severityof_Target?.find(
                (item: any) => item?.risk_Id === risk_item?.RiskCategory_Id
              )?.value || "";
              return val 
            }, 
          })),
      ],
      [applicableRiskData]
    );
  
    const TargetAdd = (val: any) => {
  
      // setResponseData((preval: any) => [...preval , ...val]) 
    }
    return (
      <>
      
      <Box>
        <Stack spacing={4}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography
              sx={{
                height: "12px",
                color: theme.palette.primary.main,
                display: "block",
                fontSize: "15px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {`REQUIREMENT ${selectedRequirementIndex + 1} / ${requirementList?.length} : CLAUSE REFERENCE : ${selectedRequirement?.clause_reference_no || "-"} | CLAUSE HEADER : ${selectedRequirement?.clause_Header || "-"}`}
            </Typography>
            {!view ?<CustomButton  startIcon={<CustomSvg size={"15px"} name={"plus"} />}type="reset" variant="outlined" name={"Add Target/KPI"} onClick={() => {setaddTarget(true)}} /> : null }
          </Box>
  
          <TableContainer style={{ border: "1px solid #f1f1f1", borderRadius: "5px", maxHeight: "50vh" }}>
            <Table stickyHeader aria-label="simple basic table">
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell   key={index}
                    sx={{
                      minWidth: header.minWidth,
                      position: header.sticky ? "sticky" : "static",
                      left: header.sticky ? header?.left : "auto",
                      backgroundColor: theme.palette.background.paper,
                      zIndex: header.sticky ? "4 !important" : "auto",
                    }}  className={`${BasicTableStyle.tab_header}`}>
                      {header.label} {/* Renders the label string */}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
  
              <TableBody>
                {requirementTargetData && requirementTargetData.length > 0 ? (
                  requirementTargetData.map((row: any, rowIndex: number) => (
                    <TableRow key={`Row_${rowIndex}`} className={BasicTableStyle.alternateRow}>
                      {headers.map((header, colIndex) => (
                        <TableCell  sx={{
                          minWidth: header.minWidth,
                          position: header.sticky ? "sticky" : "static",
                          left: header.sticky ? header?.left : "auto",
                          backgroundColor: theme.palette.background.paper,
                          zIndex: header.sticky ? "4 !important" : "auto",
                        }} key={`Cell_${rowIndex}_${colIndex}`} className={`${BasicTableStyle.tab_data}`}>
                          {header.render ? header.render(row, rowIndex) : null} {/* Safeguards invalid render */}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={headers.length} className={`${BasicTableStyle.tab_data}`} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

           {!view ? <Box >
                                                  <Typography
                                                    sx={{ fontSize: "8px", textAlign: "left", color: isnotargetwarning ? "red" : "#6A6A6A" }}
                                                  >
                                                   {isnotargetwarning ? "Warning : Atleast one Target/KPI is required each Requirement, after able to Submit" : `Note :  Atleast one Target/KPI is required each Requirement.`} 
                                                  </Typography>
                  
                                                </Box> : null }
        </Stack>
      </Box>
  
    
      </>
    );
  };
  