import React, { useEffect, useState } from 'react'
import { ContentNameConfig, MasterBulkUpload } from '../../../../config/config';
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import CustomButton from '../../../../common/dynamicButton/CustomButton';
import CustomSvg from '../../../../common/CustomSvg';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../store/store';
import { getContentForm } from '../../../../store/slices/contentMasterData';

import { useTheme } from "@mui/material/styles";
import CommonDataTemplateSheet from '../../../../common/CommonDataUploadTemplate/CommonDataTemplate';

export const AQMasterCOBImportdata: any = [
  {
    Field: "SrNo",
    FieldType: "string",
    DisplayName: "SrNo",
    config: {
      required: true,
    },
  },{
    Field: "question_type",
    FieldType: "string",
    DisplayName: "Answer Type",
    config: {
      required: true,
    },
  },
  {
    Field: "question",
    FieldType: "string",
    DisplayName: "Question",
    config: {
      required: true,
    },
  },
  {
    Field: "choices_Count",
    FieldType: "array",
    DisplayName: "Choices Count",
    config: {
      required: false,
      info: "Required if question type is 'Multiple Choice'",
    },
  },
  {
    Field: "choices",
    FieldType: "array",
    DisplayName: "Choices (Comma Seperated)",
    config: {
      required: false,
      info: "Required if question type is 'Multiple Choice'",
    },
  },

  {
    Field: "evidence_required",
    FieldType: "boolean",
    DisplayName: "Evidence Required",
    config: {
      required: false,
      default: false,
    },
  },
  {
    Field: "Is_SME_respond",
    FieldType: "boolean",
    DisplayName: "Is SME Respond",
    config: {
      required: false,
      default: false,
    },
  },
  {
    Field: "department",
    FieldType: "string",
    DisplayName: "Department",
    config: {
      required: true,
    },
  }

];

interface BulkUploadQuestionCOBTemplateProps {
  DepartmentListdata : any[]
}

export const BulkUploadQuestionCOBTemplate : React.FC <BulkUploadQuestionCOBTemplateProps> = ({DepartmentListdata}) => {
  const { vendorConentPurposes } = useSelector(
    (state: RootState) => state.contentData
  );
  
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [question_typeList, setquestion_typeList] = useState<any[]>(["Multiple Choice", "Free Text"]);
  const [checkbokvalue, setcheckbokvalue] = useState<any[]>(["Yes", "No"]);
  const [DepartmentList, setDepartmentList] = useState<any[]>([]);
  const [Note, setNote] = useState<any>("");
  const [templateHeader, setHeader] = useState<any[]>([]);


  useEffect(() => {
   if(DepartmentListdata){
    setDepartmentList(DepartmentListdata);
   }
  },[DepartmentListdata])
  
  useEffect(() => {
    const consentPurposesArray = [ContentNameConfig.cobQuestionSheetNote];

    // Construct the request body
    const queryString = consentPurposesArray.reduce((acc, purpose, index) => {
      acc[`consentPurposes[${index}]`] = purpose;
      return acc;
    }, {} as Record<string, string>);
    dispatch(getContentForm(queryString));
  }, []);
  function extractContent(htmlString: string): string {
    // Create a new DOMParser instance
    const parser = new DOMParser();

    // Parse the HTML string into a document
    const doc = parser.parseFromString(htmlString, "text/html");

    // Extract and return the text content
    return doc.body.textContent || "";
  }
  useEffect(() => {
    console.log(vendorConentPurposes);
    
    vendorConentPurposes.map((item: any) => {
      if (item.Content_Purpose === ContentNameConfig.cobQuestionSheetNote) {
        let notes = item.Content_Text;
        setNote( extractContent(notes));
      }
   
    });
  }, [vendorConentPurposes]);
  
        
    useEffect(() => {

        let HeaderwithOption : any = []
        let DepartmentListDataSheet = DepartmentList?.map((item: any) => item.DepartmentName);
        AQMasterCOBImportdata.forEach((item : any) => {
            
          let obj = {...item}
          if(item.Field == "department"){
            obj.options = DepartmentListDataSheet
          }else if(item.Field == "Is_SME_respond"){
            obj.options = checkbokvalue
          }else if(item.Field == "evidence_required"){
            obj.options = checkbokvalue
          }else if(item.Field == "question_type"){
            obj.options = question_typeList
          }
          HeaderwithOption.push(obj)  
        } )
        setHeader(HeaderwithOption)

    },[AQMasterCOBImportdata , DepartmentList])
      
     return(
        <>
             <CommonDataTemplateSheet header={templateHeader}  Note={Note}  sheetName={"Assessment_Questionnaire_bulk_upload_template"} />
           
        </>
     )
}