import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import {  IVHColumnRes, } from "../../models/assessmentQuestionnaireMaster.type";
import { CustomerAssessment_URL } from "./endpoints/customerAssessment";


    const getCustomerAssessment = (reqparams: any): Promise<any> =>
        axiosInstance
          .get(CustomerAssessment_URL.getCustomerAssessment , {params:reqparams})
          .then((response: AxiosResponse<any>) => {
            if (response.status === 200) {
              return response.data;
            }
            throw new Error("Error getting Data" + response);
          })
          .catch((error: AxiosError | string) => {
            throw error;
          });

          const getCustomerAssessmentbycustomerIdorall = (reqparams: any): Promise<any> =>
            axiosInstance
              .get(CustomerAssessment_URL.getCustomerAssessmentbycustomerIdorall , {params:reqparams})
              .then((response: AxiosResponse<any>) => {
                if (response.status === 200) {
                  return response.data;
                }
                throw new Error("Error getting Data" + response);
              })
              .catch((error: AxiosError | string) => {
                throw error;
              });

          
const addCustomerAssessment = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(CustomerAssessment_URL.AddCustomerAssessment, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });


const CustomerAssessmentService = {
  
  getCustomerAssessment,
  addCustomerAssessment,
  getCustomerAssessmentbycustomerIdorall
};

export default CustomerAssessmentService;
