import { Box, Grid, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import { textFieldWidth } from "../../utils/constants";
import CustomMultilineTextField from "../../common/dynamicInputs/CustomMultilineTextField";
import RemarkSectionApproval from "./RemarkSection";
import fileHandlerService from "../../store/services/fileHandlerService";
import CustomSvg from "../../common/CustomSvg";
import { useTheme } from "@mui/material/styles";
import * as mime from "react-native-mime-types";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import FilteViewer from "../../common/fileViewer/FilteViewer";
interface comapnymasterApprovaltbleProps {
  selectedGridRecord?: any;
  selectedRecordDetails: any;
  setSelectedRecordDetails?: any;
  closePopUp?: any;
  view?: boolean;
  edit?: boolean;
}

const AQMasterApproveReject: React.FC<comapnymasterApprovaltbleProps> = ({
  selectedGridRecord,
  selectedRecordDetails,
  setSelectedRecordDetails,
  closePopUp,
  view,
  edit,
}) => {
  const theme = useTheme();
  const [oldValues, setOldValues] = useState<any>("");
  const [oldValuesAddress, setOldValuesAddress] = useState<any>([]);
  const [oldValuesRepresentative, setOldValuesRepresentative] = useState<any>([]);
  const [newValues, setNewValues] = useState<any>("");
  const [newValuesAddress, setNewValuesAddress] = useState<any>([]);
  const [newValuesRepresentative, setNewValuesRepresentative] = useState<any>([]);
  const [header, setHeader] = useState<any>([]);
  const [newLogo, setnewLogo] = useState<any>("");
  const [oldLogo, setoldLogo] = useState<any>("");
  const [openViewFileModel, setOpenViewFileModel] = useState(false);
  const [fileUrl, setFileUrl] = useState<string>("");
  const [fileName, setFileName] = useState<any>(undefined);



  useEffect(() => {
    if (selectedRecordDetails) {
      if (selectedRecordDetails?.NewValues) {
        const newHeader: any = [{ DisplayName: "Fields" }, { DisplayName: "New Values" }];

        if (selectedRecordDetails?.OldValues?.Score) {
          newHeader.splice(1,0,{ DisplayName: "Old Values" });
        }
        setHeader(newHeader);
      }
      try {
        if (selectedRecordDetails?.OldValues?.Score) {
         
          setOldValues(selectedRecordDetails?.OldValues);
          setOldValuesAddress(JSON.parse(selectedRecordDetails?.OldValues?.Score));
        }else{
          setOldValuesAddress("")
        }
        if (selectedRecordDetails?.NewValues) {
        
          setNewValues(selectedRecordDetails?.NewValues);
          setNewValuesAddress(JSON.parse(selectedRecordDetails?.NewValues?.Score));
        }
      } catch {}
    }
  }, [selectedRecordDetails]);
  console.log(selectedRecordDetails, "selectedRecordDetails");
  const ButtonClick = async (bloburl: any) => {
    if (bloburl) {
      const fileName = decodeURIComponent(bloburl.substring(bloburl.indexOf("_") + 1));
      const response: any = await fileHandlerService.DownLoadAFile({ fileUrl: bloburl });
      const { data } = response;
      const mimeType = mime.lookup(fileName.substring(fileName.lastIndexOf(".") + 1));

      setFileName(fileName as string);
      setFileUrl(`data:${mimeType};base64,${data}`);
      setOpenViewFileModel(true);
    }
  };

  const handleDialogHide = () => {
    if (openViewFileModel) {
      setOpenViewFileModel(false);
    }
  };
  const CompanyDisplayColumn: any = [
    {
      Field: "Category I",
      FieldType: "string",
      DisplayName: "Category I",
    },
    {
      Field: "Category II",
      FieldType: "string",
      DisplayName: "Category II",
    },
    {
      Field: "Category III",
      FieldType: "string",
      DisplayName: "Category III",
    },
    {
      Field: "Applicable For",
      FieldType: "string",
      DisplayName: "Applicable For",
    },
    {
      Field: "Answer Type",
      FieldType: "string",
      DisplayName: "Answer Type",
    },
    {
      Field: "Question Title",
      FieldType: "string",
      DisplayName: "Question Title",
    },
    {
      Field: "Question",
      FieldType: "string",
      DisplayName: "Question",
    },
    {
      Field: "Evidence Required",
      FieldType: "string",
      DisplayName: "Is Evidence Required",
    },
    {
      Field: "Applicale Risk",
      FieldType: "string",
      DisplayName: "Applicale Risks",
    },
    {
      Field: "Assessment Type",
      FieldType: "string",
      DisplayName: "Assessment Type",
    },
    {
      Field: "Respondor",
      FieldType: "string",
      DisplayName: "Respondor",
    },
    {
      Field: "Department",
      FieldType: "string",
      DisplayName: "Department",
    },
    {
      Field: "SME verification",
      FieldType: "string",
      DisplayName: "SME verification",
    },
    {
      Field: "Service Mapping",
      FieldType: "string",
      DisplayName: "Service Mapping",
    },
    {
      Field: "Status",
      FieldType: "string",
      DisplayName: "Status",
    },

   
    {
      Field: "Modified By",
      FieldType: "string",
      DisplayName: "Modified By",
    }
  ];



  const renderAddressRow = (displayName: string, newValue: any, oldValue?: any, addType?: any) => (
    <TableRow key={`${displayName}-${addType || ""}`}>
      <TableCell sx={{ textAlign: "left", border: "2px solid #f1f1f1" }}>{addType || ""}</TableCell>
      
      {oldValuesAddress?.length ? (
        <TableCell sx={{ textAlign: "left", border: "2px solid #f1f1f1" }}>
          {oldValue !== undefined ? displayName + " - " + oldValue : ""}
        </TableCell>

      ) : null}
      <TableCell sx={{ textAlign: "left", border: "2px solid #f1f1f1" }}>
        {newValue !== undefined ? displayName + " - " + newValue : ""}
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <Stack>
        <Box>
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            Questions Details
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-column Section company Master approval`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {CompanyDisplayColumn.map((col: any) => (
                <TableRow>
                  <TableCell
                    key={`${col.DisplayName}-column Section company Master approval`}
                    sx={{
                      textAlign: "left",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                  {oldValues ? (
                     <TableCell
                      key={`${col.DisplayName}-column Section company Master approval`}
                      sx={{
                        textAlign: "left",

                        border: "2px solid #f1f1f1",
                      }}
                    >
                      {col.FieldType !== "image" ? oldValues[col.Field] :   oldValues[col.Field] && (
            <Tooltip title="View">
            <IconButton
              onClick={() => {
                ButtonClick(oldValues[col.Field]?.toString());
              }}
            >
              <CustomSvg color={`${theme.palette.info.main}`} size={"20px"} name={"view"} />
            </IconButton>
          </Tooltip>
            )}
                      
                    </TableCell> 
                  ) : null}
                  {newValues ? (
                  <TableCell
                      key={`${col.DisplayName}-column Section company Master approval`}
                      sx={{
                        textAlign: "left",
                        border: "2px solid #f1f1f1",
                      }}
                    >
                             {col.FieldType !== "image" ?  newValues[col.Field] :  newValues[col.Field] && (
            <Tooltip title="View">
            <IconButton
              onClick={() => {
                ButtonClick(newValues[col.Field]?.toString());
              }}
            >
              <CustomSvg color={`${theme.palette.info.main}`} size={"20px"} name={"view"} />
            </IconButton>
          </Tooltip>
            )}
                 
                    </TableCell> 
                  ) : null}
                 
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        {/*  Choices / Score section */}
        <Box>
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
          Choices / Score
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-column Section company Master approval`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: Math.max(newValuesAddress?.length || 0, oldValuesAddress?.length || 0) }).map(
                (_, index: number) => {
                  const newAddress = newValuesAddress ? newValuesAddress[index] : null;
                  const oldAddress = oldValuesAddress ? oldValuesAddress[index] : null;

                  return (
                    <React.Fragment key={`address-row-${index}`}>
                      {renderAddressRow(
                        "Choice",
                        newAddress?.Attribute_Response,
                        oldAddress?.Attribute_Response,
                       
                        
                        `Response ${index + 1}`
                      )}
                      {renderAddressRow("Score", newAddress?.Score, oldAddress?.Score )}
               
                    </React.Fragment>
                  );
                }
              )}
            </TableBody>
          </Table>
        </Box>

      

        <RemarkSectionApproval selectedGridRecord={selectedGridRecord} closePopUp={closePopUp} view={view} />
      </Stack>

      <CustomDialog
        hideBackdrop={true}
        onHide={handleDialogHide}
        maxWidth={"lg"}
        show={openViewFileModel}
        header={"File Preview"}
        key={'company_master_pending_approval_preview_File_Preview'}
        contentNode={
          <>
            <FilteViewer URL={fileUrl} fileName={fileName} />
          </>
        }
      />
    </>
  );
};

export default AQMasterApproveReject;
