import { Box, Divider, Grid, Stack } from "@mui/material"
import { textFieldWidth } from "../../../../../../utils/constants"
import LableandValuesPreview from "../../../../../../common/CommonPreview/LableandValuesPreview"
import CustomButton from "../../../../../../common/dynamicButton/CustomButton";
import { useEffect, useState } from "react";
import DynamicBasicTable from "../../../../../../common/dynamicbasicTable/DynamicBasicTable";
import dayjs from "dayjs";


interface ReviewDatesprops {
    view?: boolean;
    closePopUp?: any;
    edit?: boolean;
    isEditData?: any;
    add?: boolean;
  }
  

export const ReviewDates: React.FC<ReviewDatesprops> = ({ view, closePopUp, edit, isEditData }) => {

    const [ReviewDateList, setReviewDateList] =useState<any[]>([])
    const ReviewDateColumn = [
        { Field: "s_no", DisplayName: "Sr", },
        { Field: "review_StartDate", DisplayName: "Review Start Date" },
        { Field: "notify_Date", DisplayName: "Notified On" }
      ];
    const handlesubmitReviewDates = () => {

    }

    useEffect(() => {
      console.log(isEditData , "review dates");
    let values : any[] =  isEditData?.reviewDates || [];
       
   
let transformedValues : any[] = values.map((item: any , index : any) => ({
  ...item,
  s_no : index +1,
  review_StartDate: dayjs(new Date(item?.review_StartDate)).format("DD-MM-YYYY"),
  review_EndDate: dayjs(new Date(item?.review_EndDate)).format("DD-MM-YYYY"),
  notify_Date: dayjs(new Date(item?.notify_Date)).format("DD-MM-YYYY"),
  escalation_Date: dayjs(new Date(item?.escalation_Date)).format("DD-MM-YYYY"),
}));
      
      setReviewDateList(transformedValues || [])
    },[isEditData])
    return (
        <>
         <Box>
         <Stack spacing={4}>
        <Box>
        <Grid container spacing={2}>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <LableandValuesPreview lable="Start Date" Value={`${dayjs(new Date(isEditData?.review_Start_Date)).format("DD-MM-YYYY")}`} />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <LableandValuesPreview lable="End Date" Value={`${dayjs(new Date(isEditData?.review_End_Date)).format("DD-MM-YYYY")}`} />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          ></Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <LableandValuesPreview lable="Review Type" Value={isEditData?.review_Type == 0 ?  "One Time" : "Peridically"} />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <LableandValuesPreview lable="Frequency" Value={`${isEditData?.frequency_Name}`} />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <LableandValuesPreview lable="No. Of Reviews" Value={`${ReviewDateList?.length || 0}`} />
          </Grid>
        </Grid>
      </Box>
      <Divider></Divider>

      <Box  sx={{ width: "100%" }}>
                  <DynamicBasicTable
                    columns={ReviewDateColumn}
                    rowData={ReviewDateList}
                    style={{ border: "1px solid #f1f1f1" }}
                    disableAction={true}
                  />
                </Box>

      <Box
                                sx={{
                                    display: "flex",
                                    gap: "20px",
                                    justifyContent: "end",
                                    mt: 2,
                                }}
                            >
                               
                                    <>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Close"}
                                            onClick={() => {
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        {/* <CustomButton
                                            type={"reset"}
                                            name={"Submit"}
                                            onClick={() => handlesubmitReviewDates()}
                                        /> */}
                                    </>
                               
                            </Box>
                            </Stack>
                            </Box>
     </>
    )
}