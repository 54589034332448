import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { FORMTITLEMASTER_URL } from "./endpoints/commonMasters";

const getFormTitleList = (reqparams: any): Promise<any> =>
    axiosInstance
        .get(FORMTITLEMASTER_URL.GetFormTitle)
        .then((response: AxiosResponse<any>) => {
            if (response.status === 200) {
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });

const AddorEditFormTitle = (requestBody: any): Promise<any> =>
    axiosInstance
        .post(FORMTITLEMASTER_URL.AddorEditFormTitle, requestBody)
        .then((response: AxiosResponse<any>) => {
            if (response.status === 200) {
                console.log(requestBody, "requestBody");
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });

const deleteFormTitle = (requestBody: any): Promise<any> =>
    axiosInstance
        .post(FORMTITLEMASTER_URL.DeleteFormTitle, requestBody)
        .then((response: AxiosResponse<any>) => {
            if (response.status === 200) {
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });
const getVersionHistoryFormTitle = (requestBody: any): Promise<any> =>
    axiosInstance
        .get(FORMTITLEMASTER_URL.VersionHistory, { params: requestBody })
        .then((response: AxiosResponse<any>) => {
            if (response.status === 200) {
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });

const FormTitleMasterService = {
    getFormTitleList,
    AddorEditFormTitle,
    deleteFormTitle,
    getVersionHistoryFormTitle,
};

export default FormTitleMasterService;
