import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";

import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { textFieldWidth } from "../../../utils/constants";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import {
  addEditIndustry,
  getIndustryList,
  getParentIndustryList,
  updateIndustryAddandEdit,
} from "../../../store/slices/industryMasterData";
import ProgressBar from "../../../common/progressBar/progressBar";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { getLovList, updateLovAddandEdit } from "../../../store/slices/lovMasterData";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { AddListValues } from "../listofValues/AddListValues";
import { config } from "process";
import { ApplicationParameterName, IndustryConfig, LovConfig } from "../../../config/config";
import { updateLoader } from "../../../store/slices/loader";
import InactivityTimer from "../../../common/InactivityTimer/InactivityTimer";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";

interface FormData {
  industryID?: number;
  Industry_Name: string;
  Parent_Industry_Id: string;
  IndustryType: string;
  Parent_Level: string;
  IsActive?: any;
}

export interface IndustryProps {
  view?: boolean;
  closeIndustryPopUp?: () => void;
  onchangelable?: any;
  edit?: boolean;
  isEditData?: any;
}

export const AddIndustry: React.FC<IndustryProps> = ({ view, closeIndustryPopUp, onchangelable, edit, isEditData }) => {
  const dispatch = useAppDispatchThunk();
  const { formConfiguration } = useSelector((state: RootState) => state.customFormFields);
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const [message, setMessage] = useState<boolean>(false);
  const [ParentVisible, setParentVisible] = useState<boolean>(false);
  const [ParentIndustryData, setParentIndustryData] = useState<any[]>([]);
  const [Levelslov, setLevelslov] = useState<any[]>([]);
  const [IndustryTypelov, setIndustryTypelov] = useState<any[]>([]);
  const [AddlovPopup, setAddlovPopup] = useState<boolean>(false);
  const [addPopupHeader, setAddPopupHeader] = useState<string>("");
  const [lovName, setLovName] = useState<string>("");
  const [timeout, setTimeout] = useState<any>(null);
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);

  const { industryTable, ParentindustryTable, isindustryAdded, isindustryDeleted, ToasterMsg, isLoading } = useSelector(
    (state: RootState) => state.indutryMasterData
  );

  const { lovTable, islovAdded } = useSelector((state: RootState) => state.lovMasterData);

  console.log(isEditData, "isEditData");

  let formData: any = {
    industryID: edit ? isEditData?.IndustryId : 0,
    Industry_Name: isEditData ? isEditData?.IndustryName : "",
    IndustryType: isEditData ? isEditData?.IndustryType : "",
    Parent_Industry_Id: isEditData ? isEditData?.Parent_Industry_Id : "",
    Parent_Level: isEditData ? isEditData?.Parent_Level : "",
    // IsActive: isEditData ? isEditData?.IsActive : false,
    IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
  };

  let ModifiedBy = isEditData ? isEditData?.UpdatedBy : "";
  let CreatedBy = isEditData ? isEditData?.CreatedBy : "";

  const {
    handleSubmit: handleParameterForm,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const IndustryTypeWatch = watch("IndustryType");
  const industryTypeAsString = `${IndustryTypeWatch}`;

  const IndustryLevelWatch = watch("Parent_Level");
  const industryLevelAsString = `${IndustryLevelWatch}`;

  const isActiveDisabled = watch("IsActive");

  useEffect(() => {
    if (isEditData && isEditData.Parent_Level) {
      let findSelectIndustry = Levelslov.find((item: any) => item.LovId == isEditData.Parent_Level);

      if (findSelectIndustry) {
        const currentLevel = parseInt(findSelectIndustry.Options.split(" ")[1]);
        const parentIndustryId = findParentIndustryLevel(currentLevel);
        dispatch(getParentIndustryList({ IndustryId: parentIndustryId }));
      }
    }
  }, [isEditData, Levelslov]);

  useEffect(() => {
    if (IndustryLevelWatch) {
      let findSelectIndustry = Levelslov.find((item: any) => item.LovId == IndustryLevelWatch);

      if (findSelectIndustry) {
        const currentLevel = parseInt(findSelectIndustry.Options.split(" ")[1]);
        const parentIndustryId = findParentIndustryLevel(currentLevel);
        dispatch(getParentIndustryList({ IndustryId: parentIndustryId }));
      }
    }
  }, [IndustryLevelWatch]);

  const findParentIndustryLevel = (currentLevel: any) => {
    let level = currentLevel;
    while (level > 1) {
      level -= 1;
      const targetOption = `Level ${level}`;
      const findSelectIndustryLevel = Levelslov.find((item: any) => item.Options === targetOption);
      if (findSelectIndustryLevel) {
        return findSelectIndustryLevel.LovId;
      }
    }
    return 0;
  };

  useEffect(() => {
    let finededsubIndType: any = IndustryTypelov.find(
      (item) => item.Options === IndustryConfig?.Sub_Industry && IndustryTypeWatch == item.LovId
    );

    if (finededsubIndType) {
      setParentVisible(true);
      if (onchangelable && IndustryTypeWatch) {
        onchangelable("Sub Industry");
      }
    } else {
      setParentVisible(false);
      if (onchangelable && IndustryTypeWatch) {
        onchangelable("Industry");
      }
    }
  }, [IndustryTypeWatch, isEditData, IndustryTypelov]);

  const handleFetchValues = () => {
    dispatch(getLovList({ Name: "" }));
  };

  useEffect(() => {
    handleFetchValues();
  }, []);
  useEffect(() => {
    if (edit) {
      const RecordTimeOut: any = ApplicationParameterName.RecordTimeOut;
      let findRecordTimeOut = applicationParameterData.find(
        (applicationParamitem) =>
          applicationParamitem.Parameter_Name === RecordTimeOut &&
          applicationParamitem.Parameter_Group === RecordTimeOut
      )?.Parameter_Value;
      setTimeout(Number(findRecordTimeOut));
    }
  }, []);

  useEffect(() => {
    const Levelslov = lovTable.filter((item: any) => item.Name === LovConfig.Industry_Level) || [];

    setLevelslov(Levelslov);
    setIndustryTypelov(lovTable.filter((item: any) => item.Name === LovConfig.Industry_Type) || []);
  }, [lovTable]);

  useEffect(() => {
    console.log(ParentindustryTable, "dataTable");

    setParentIndustryData(ParentindustryTable);
  }, [ParentindustryTable]);

  const handleSubmit = (data: any) => {
    console.log(data);
    dispatch(updateLoader(true));
    let formData = data;
    // setLoader(true);
    formData.IsActive = formData.IsActive ? true : false;
    formData.CreatedBy = user?.UserName;
    formData.ModuleName = ParentVisible ? "Sub Industry Master" : "Industry";
    formData.MenuId = currentPagePermissionData?.MenuId;
    formData.Form_Id = 8;
    formData.Status = 1;

    let JsonDataForDisplay: any = {
      "Industry Name": formData.Industry_Name,
      "Industry Type": IndustryTypelov.find((item) => item.LovId == formData?.IndustryType)?.Options || "",
      Parent: ParentIndustryData.find((item) => item.IndustryId == formData?.Parent_Industry_Id)?.IndustryName || "",
      Level: Levelslov.find((item) => item.LovId == formData?.Parent_Level)?.Options || "",
      Status: formData.IsActive ? "Active" : "InActive",
      "Modified By": user?.UserName,
    };

    if (edit) {
      formData.Remarks = "";
      formData.Activity = "Edit";
      formData.UserId = userLoginResponse?.UserId;
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);

      dispatch(addEditIndustry(formData));
    } else {
      formData.IsActive = true;
      formData.Activity = "Create";

      let finededIndType = IndustryTypelov.find(
        (item) => item.Options === IndustryConfig?.Industry && formData.IndustryType == item.LovId
      );
      if (finededIndType) {
        let finededIndTypeLevel = Levelslov.find((item: any) => item.Options === IndustryConfig?.Industry_Level_1);

        formData.Parent_Level = finededIndTypeLevel ? finededIndTypeLevel.LovId : 0;
        JsonDataForDisplay.Level = IndustryConfig?.Industry_Level_1;
        formData.Parent_Industry_Id = 0;
      }
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      formData.Remarks = "";
      formData.UserId = userLoginResponse?.UserId;
      dispatch(addEditIndustry(formData));
    }
  };
  useEffect(() => {
    if (isindustryAdded) {
      dispatch(
        getIndustryList({
          FormID: 2,
        })
      );
      dispatch(updateIndustryAddandEdit(false));
      reset(formData);
      dispatch(updateLoader(false));
      if (closeIndustryPopUp) {
        closeIndustryPopUp();
        // setLoader(false);
      }
    }
  }, [isindustryAdded]);

  // const onAddType = (header: string, fieldName: any, LovName: any) => {
  //   setAddPopupHeader(header);
  //   setLovName(LovName);
  //   setAddlovPopup(true);
  // };

  useEffect(() => {
    if (islovAdded) {
      handleFetchValues();
      dispatch(updateLovAddandEdit(false));
    }
  }, [islovAdded]);

  return (
    <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
      <Grid
        // item
        // item xs={12} sm={12} md={6} lg={6} xl={6}
        sx={{
          backgroundImage: `url()`,
          backgroundRepeat: "no-repeat",
          // backgroundColor: (t) =>
          //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
          // backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          margin: "0",
        }}
      >
        {false && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              margin: "0",
            }}
          >
            {!view ? (
              <>
                {!edit ? (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Add Branch Master
                  </Typography>
                ) : (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Edit Branch Master
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="h6" sx={{ m: 1 }}>
                View Branch Master
              </Typography>
            )}
          </Box>
        )}
        <Grid
          sx={{
            height: "auto",
            width: "100%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Box
            sx={{
              px: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
              },

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  // sm={textFieldWidth.sm}
                  // md={textFieldWidth.md}
                  // lg={textFieldWidth.lg}
                  // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="IndustryType"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Industry Type is Required" }}
                    render={({ field }) => (
                      <CustomSelect
                        name={"IndustryType"}
                        valueKey={"LovId"}
                        optionLabelKey="Options"
                        options={IndustryTypelov}
                        // addField={true}
                        // onAddClick={() => {
                        //   onAddType("Add Industry Type", "IndustryType", LovConfig.Industry_Type);
                        // }}
                        disabled={view || edit}
                        required={true}
                        label={"Type"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.IndustryType)}
                        helperText={errorsloginform.IndustryType && errorsloginform.IndustryType.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  // sm={textFieldWidth.sm}
                  // md={textFieldWidth.md}
                  // lg={textFieldWidth.lg}
                  // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Industry_Name"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: ParentVisible ? "Sub Industry Name is Required" : "Industry Name is Required",

                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 2 characters requires",
                        maxLength: (value) => value.length <= 100 || "Maximum 100 characters requires",
                        ValueLimitaion: (value) =>
                          /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value) ||
                          "Name cannot contain special characters and empty value",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"Industry_Name"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={ParentVisible ? "Sub Industry Name" : "Industry Name"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Industry_Name)}
                        helperText={errorsloginform.Industry_Name && errorsloginform.Industry_Name.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                {ParentVisible ? (
                  <Grid
                    item
                    xs={12}
                    // sm={textFieldWidth.sm}
                    // md={textFieldWidth.md}
                    // lg={textFieldWidth.lg}
                    // xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="Parent_Level"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Level Type is Required" }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"Parent_Level"}
                          valueKey={"LovId"}
                          optionLabelKey="Options"
                          options={Levelslov.filter((item) => item.Options != "Level 1")}
                          // addField={true}
                          // onAddClick={() => {
                          //   onAddType("Add Industry Level", "Parent_Level", LovConfig.Industry_Level);
                          // }}
                          disabled={view || !isActiveDisabled}
                          required={true}
                          label={"Level"}
                          value={field.value}
                          // onChange={field.onChange}
                          onChange={(e) => {
                            field.onChange(e);
                            setValue("Parent_Industry_Id", "");
                          }}
                          error={Boolean(errorsloginform.Parent_Level)}
                          helperText={errorsloginform.Parent_Level && errorsloginform.Parent_Level.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                ) : null}

                {ParentVisible ? (
                  <Grid
                    item
                    xs={12}
                    // sm={textFieldWidth.sm}
                    // md={textFieldWidth.md}
                    // lg={textFieldWidth.lg}
                    // xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="Parent_Industry_Id"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Parent Industry is Required" }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"Parent_Industry_Id"}
                          valueKey={"IndustryId"}
                          optionLabelKey="IndustryName"
                          options={ParentIndustryData.filter((item) => item.IsActive != "Inactive")}
                          disabled={view || !isActiveDisabled}
                          required={true}
                          label={"Parent"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsloginform.Parent_Industry_Id)}
                          helperText={
                            errorsloginform.Parent_Industry_Id && errorsloginform.Parent_Industry_Id.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>
                ) : null}

                {/* {CreatedBy !== "" && (
                  <Grid
                    item
                    xs={12}
                    // sm={textFieldWidth.sm}
                    // md={textFieldWidth.md}
                    // lg={textFieldWidth.lg}
                    // xl={textFieldWidth.xl}
                  >
                    <CustomTextField
                      name={"CreatedBy"}
                      required={true}
                      disabled={true}
                      label={"Created By"}
                      value={CreatedBy}
                    />
                  </Grid>
                )} */}
                {false && ModifiedBy !== "" && (
                  <Grid
                    item
                    xs={12}
                    // sm={textFieldWidth.sm}
                    // md={textFieldWidth.md}
                    // lg={textFieldWidth.lg}
                    // xl={textFieldWidth.xl}
                  >
                    <CustomTextField
                      name={"ModifiedBy"}
                      required={true}
                      disabled={true}
                      label={"Modified By"}
                      value={ModifiedBy}
                    />
                  </Grid>
                )}

                {/* {edit || view ? (
                  <Grid
                    item
                    xs={12}
                    // sm={textFieldWidth.sm}
                    // md={textFieldWidth.md}
                    // lg={textFieldWidth.lg}
                    // xl={textFieldWidth.xl}
                  >
                    <Box sx={{ mt: 1 }}>
                      <Controller
                        name="IsActive"
                        control={control}
                        render={({ field }) => (
                          <CustomToogleSwitch
                            label={"User Status"}
                            dynamicLabel={field.value ? "ACTIVE" : "INACTIVE"}
                            disabled={view}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                ) : null} */}
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "end",
                  mt: 2,
                }}
              >
                {view ? (
                  <CustomButton
                    type="reset"
                    name={"Cancel"}
                    onClick={() => {
                      closeIndustryPopUp && closeIndustryPopUp();
                    }}
                  />
                ) : edit ? (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        reset(formData);
                        closeIndustryPopUp && closeIndustryPopUp();
                      }}
                    />
                    <CustomButton
                      type="reset"
                      disabled={!isDirty}
                      name={"Update"}
                      onClick={handleParameterForm(handleSubmit)}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        reset(formData);
                        closeIndustryPopUp && closeIndustryPopUp();
                      }}
                    />
                    <CustomButton
                      type="reset"
                      disabled={!isDirty}
                      name={"Submit"}
                      onClick={handleParameterForm(handleSubmit)}
                    />
                  </React.Fragment>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {timeout && (
        <InactivityTimer
          timeout={timeout}
          onTimeout={() => {}}
          renderPrompt={(isVisible, handleResponse) =>
            isVisible && (
              <ModalPopUp
                onDeleteMethod={() => {}}
                isDelete={false}
                onHide={() => handleResponse(false)}
                show={isVisible}
                header={"Confirm"}
                text={`Are you still editing?`}
                ButtonName={"Yes"}
              />
            )
          }
        />
      )}
    </Grid>
  );
};
