import { Box, Grid, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import vendorDetailsService from "../../../store/services/vendorDetails";
import { updateToaster, updateLoader } from "../../../store/slices/loader";
import { updateVersionHistory } from "../../../store/slices/vendorData";
import {
  getVendorDetailsList,
  updateVendorDetailsAddandEdit,
  getVendorDetailsHistory,
} from "../../../store/slices/vendorDetails";
import { useAppDispatch, RootState } from "../../../store/store";
import { AddVendorDetails } from "../../vendorDetails/AddVendor";
import AddClause from "./AddClause";
import {
  activeInactiveClause,
  getClauseDataById,
  getClauseDetailsList,
  getClauseTypeList,
  getSystemVariables,
  updateClauseDetailsAddandEdit,
} from "../../../store/contractManagement/slices/clauseDataSlice";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { useNavigate } from "react-router-dom";
import AddClauseStepper from "./AddClauseStepper";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";
import { FormIdConfig } from "../../../config/config";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomButton from "../../../common/dynamicButton/CustomButton";

const columns = {
  table_name: "Clause",
  isAction: true,
  columns: [
    // {
    //   Field: "clause_Id",
    //   FieldType: "string",
    //   DisplayName: "ID",
    // },
    {
      Field: "clause_header",
      FieldType: "string",
      DisplayName: "Header",
    },
    {
      Field: "clause_type",
      FieldType: "string",
      DisplayName: "Type",
    },
    {
      Field: "regulator",
      FieldType: "string",
      DisplayName: "Regulator",
    },
    {
      Field: "regulatory_Reference",
      FieldType: "string",
      DisplayName: "Regulatory Reference",
    },
    // {
    //   Field: "clause",
    //   FieldType: "string",
    //   DisplayName: "Clause",
    // },
    {
      Field: "isSme",
      FieldType: "string",
      DisplayName: "Is SME",
    },
    {
      Field: "sme",
      FieldType: "string",
      DisplayName: "SME",
    },
    {
      Field: "isSLA",
      FieldType: "string",
      DisplayName: "Is SLA",
    },
    {
      Field: "isPm",
      FieldType: "string",
      DisplayName: "Is PM",
    },
    {
      Field: "isRisk",
      FieldType: "string",
      DisplayName: "Is Risk",
    },
    {
      Field: "riskLevel",
      FieldType: "string",
      DisplayName: "Risk Level",
    },
    {
      Field: "riskCategory",
      FieldType: "string",
      DisplayName: "Risk Category",
    },
    {
      Field: "isNonNegotiable",
      FieldType: "string",
      DisplayName: "Is Non-Negotiable",
    },
    {
      Field: "isActive",
      FieldType: "string",
      DisplayName: "Status",
    },
    {
      Field: "modifiedBy",
      FieldType: "string",
      DisplayName: "Modified By",
    },
  ],
};

const ClauseList = () => {
  const dispatch = useAppDispatch();

  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    clauseDetailsData,
    isClauseDetailsLoading,
    isClauseDetailsAdded,
    clauseToasterStatus,
    isClauseDetailsUpdated,
    isClauseDetailsDeleted,
    clauseToasterMsg,
    versionHistory,
  }: any = contractManagementReducer.clauseData;

  const { draftdata, isLoadingDraft, isDraftAdded, isDraftUpdated, DraftToasterMsg } = useSelector(
    (state: RootState) => state.saveAsDraft
  );
  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [HeaderColumn] = useState(columns);
  const [clauseDetails, setClauseDetails] = useState<any[]>([]);
  const [permssionPage, setPermissionForpage] = useState<any>();
  const [Option, setOption] = useState("");
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [isEditData, setIsEditDate] = useState<any>(null);
  const [isShowDraft, setisShowDraft] = useState<any>(1);
  const [clauseDetailsDraftData, setClauseDetailsDraftData] = useState<any[]>([]);
  const [isDraftData, setIsDraftData] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [isDeleteData, setIsDeleteData] = useState<any>("");
  const navigate = useNavigate();

  const { currentpageTitleData } = useSelector((state: RootState) => state.FormTitleMasterData);
  const { handleRecordLockUpdate } = RecordLockRelease();

  const [OpenModalServerFilter, setOpenModalServerFilter] = useState(false);
  const [formDisplay, setformDisplay] = useState(false);
  const [selectedValue, setselectedValue] = useState(null);
  const [selectedClauseType, setSelectedClauseType] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [filteredList, setFilteredList] = useState([...clauseDetails]);

  const columns_version: any = {
    table_name: "Clause History Records",
    isAction: false,
    columns: [],
  };

  useEffect(() => {
    initializeData();
  }, []);

  const getDraftData = () => {
    const dataObj = {
      UserId: userLoginResponse?.UserId,
      ModuleName: "Clause Master",
      ScreenName: "Clause Master",
    };
    //dispatch(getAllSaveandDraftList(dataObj));
  };

  const initializeData = () => {
    dispatch(updateLoader(true));
    dispatch(getClauseDetailsList());
    dispatch(getSystemVariables());
    //getDraftData();
  };

  useEffect(() => {
    if (isClauseDetailsDeleted === true) {
      handleRecordLockUpdate();
      dispatch(updateToaster({ isToaster: true, toasterMsg: clauseToasterMsg, isTosterFailed: clauseToasterStatus }));
      dispatch(updateClauseDetailsAddandEdit(false));
      initializeData();
      setIsDeleteData("");
      setOption("");
      //setisShowDraft(1)
    }
  }, [clauseToasterStatus, isClauseDetailsDeleted]);

  //   useEffect(() => {
  //     if (isDraftAdded) {
  //       dispatch(updateSaveasDraftAddandEdit(false));
  //       dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
  //       initializeData();
  //       setisShowDraft(0)
  //     } else if (isDraftUpdated) {
  //       dispatch(updateSaveasDraftAddandEdit(false));
  //       dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
  //       initializeData();
  //       setisShowDraft(0)
  //     }
  //   }, [isDraftAdded, isDraftUpdated]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    if (versionHistory && versionHistory?.ClauseListHeader?.length > 0) {
      if (versionHistory?.ClauseListHeader && versionHistory?.ClauseListHeader.length > 0) {
        setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory?.ClauseListHeader,
        };
        setVersionHistoryData(re_columns);
        console.log(versionHistory, "versionHistory ");
      }
    }
  }, [versionHistory]);

  useEffect(() => {
    setClauseDetails(clauseDetailsData);
    const filtered = clauseDetailsData.filter((clause: any) => {
      const matchesClauseType = selectedClauseType === "All" || clause.clause_type === selectedClauseType;
      const matchesStatus = selectedStatus === "All" || clause.isActive === selectedStatus;
      return matchesClauseType && matchesStatus;
    });
    setFilteredList(filtered);
    dispatch(updateLoader(false));
  }, [clauseDetailsData]);

  useEffect(() => {
    let modifiedData: any[] = [];
    if (draftdata && Array.isArray(draftdata)) {
      draftdata &&
        draftdata.forEach((item: any) => {
          let JsonData = JSON.parse(item.DraftData);
          modifiedData.push({
            ...JsonData.VendorDetails,
            IsListedValue: JsonData.VendorDetails?.IsListed ? "Yes" : "No",
            IsRegulatedValue: JsonData.VendorDetails?.IsRegulated ? "Yes" : "No",
            DraftStatus: item.DraftStatus,
            DraftId: item.DraftId,
          });
        });
    }
    setClauseDetailsDraftData(modifiedData);
  }, [draftdata]);

  const handleOpen = async (data: any, option: string) => {
    if (option === "history") {
      setOption(option);
      dispatch(
        getVendorDetailsHistory({
          Vendor_Id: data.Vendor_Id,
          formID: FormIdConfig?.ClauseFormId,
        })
      );
      setIsAddorEdit(false);
      setIsVersionHistory(true);
    } else if (option === "delete") {
      setOption(option);
      setIsDeleteData(data);
    } else {
      dispatch(updateLoader(true));
      // if (isShowDraft) {
      //   const res = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: data.Vendor_Id });
      //   setIsAddorEdit(true);
      //   setOption(option);
      //   setIsEditDate(res.data);
      //   setIsDraftData(false);
      // }
      // else {
      // let filteredData = draftdata.find((item: any) => item.DraftId === data.DraftId);
      // let JsonData = JSON.parse(filteredData?.DraftData);

      dispatch(getClauseDataById(data?.clause_Id))
        .unwrap()
        .then((clauseData: any) => {
          setIsEditDate({ ...clauseData });
          setIsAddorEdit(true);
          setOption(option);
        })
        .catch((error) => {
          console.log(error);
        });
      //setIsDraftData(true);
      //}
    }
  };

  const setAddFormcloseoropen = (value: any) => {
    setIsAddorEdit(value);
    setIsEditDate(null);
    // setIsDraftDate(null)
    setOption("");
    if (!value) {
      initializeData();
    }
    setIsDraftData(false);
    //navigate("/addClause")
  };

  const active_Inactive_Clause = () => {
    console.log(isDeleteData);
    let inactiveFormData = {
      clause_Id: isDeleteData.clause_Id,
      isActive: isDeleteData?.isActive === "Active" ? false : true,
      updated_By: userLoginResponse?.UserName,
      approved_By: userLoginResponse?.UserName,
      userId: userLoginResponse?.UserId,
      moduleName: "Clause Library",
      activity: isDeleteData?.isActive === "Active" ? "inactive" : "active",
      createdBy: userLoginResponse?.UserName,
    };
    dispatch(activeInactiveClause(inactiveFormData));
  };

  const handleFilterChange = () => {
    const filtered = clauseDetails.filter((clause) => {
      const matchesClauseType = selectedClauseType === "All" || clause.clause_type === selectedClauseType;
      const matchesStatus = selectedStatus === "All" || clause.isActive === selectedStatus;
      return matchesClauseType && matchesStatus;
    });
    setFilteredList(filtered);
    setformDisplay(true);
  };
  const allOption = [
    { Name: "All", value: "All" },
    { Name: "Active", value: "Active" },
    { Name: "Inactive", value: "Inactive" },
  ];
  const handleChange = (event: any) => {
    console.log(event, "event");

    setselectedValue(event);
  };

  const modifiedClauseList = [...clauseDetails];

  // console.log(filteredList, "clauseDetails~clauseDetails");
  // const transformData = (data: any) => {
  //   return data.map((Branch: any) => {
  //     let BranchCompanyArray = [];

  //     if (Branch?.CompanyId) {
  //       try {
  //         BranchCompanyArray = JSON.parse(Branch.CompanyId);
  //       } catch (error) {
  //         console.error("Invalid JSON in Branch.CompanyId:", Branch.CompanyId, error);
  //       }
  //     }

  //     const Legalname = BranchCompanyArray.map((item: any) => item?.Legal_Name).join(",");

  //     return {
  //       ...Branch,
  //       Company_legalName: Legalname,
  //     };
  //   });
  // };
  // const transformedData = transformData(clauseDetails);
  // console.log(transformedData);
  let arrayData = [
    {
      Name: "All",
    },
    ...Array.from(new Set(clauseDetails.map((clause) => clause.clause_type)))
      .map((type) => ({
        Name: type,
      }))
      .filter((option) => option.Name !== null && option.Name !== undefined)
      .sort((a, b) => a.Name.localeCompare(b.Name)),
  ];
  console.log(arrayData,isVersionHistory, "arrayDataarrayDataarrayData");
  return (
    <>
      {!isAddorEdit ? (
        clauseDetails &&
        clauseDetails?.length > 0 && (
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Stack spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <Typography variant="h6" color={"text.primary"}>
                  {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", width: "500px", gap: 2 }}>
                    {arrayData && arrayData?.length > 0 && (
                      <CustomSelect
                        name={"clauseType"}
                        valueKey={"Name"}
                        optionLabelKey="Name"
                        showAllOption={false}
                        options={arrayData}
                        isSort={false}
                        value={selectedClauseType}
                        onChange={(e) => setSelectedClauseType(e)}
                        // required={true}
                        label={"Select Clause Type"}
                      />
                    )}
                    <CustomSelect
                      name={"status"}
                      valueKey={"Name"}
                      optionLabelKey="Name"
                      showAllOption={false}
                      isSort={false}
                      options={allOption}
                      value={selectedStatus}
                      onChange={(e) => setSelectedStatus(e)}
                      // required={true}
                      label={"Select Status"}
                    />
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <CustomButton
                      variant="contained"
                      name={"Submit"}
                      // disabled={(selectedClauseType === null || selectedStatus === null) ? true : false}
                      onClick={handleFilterChange}
                      size="small"
                    />
                  </Box>
                </Grid>
              </Box>
              {!formDisplay ? (
                ""
              ) : (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <MaterialDynamicGrid
                    data={HeaderColumn}
                    rows={isShowDraft ? filteredList : clauseDetailsDraftData}
                    handleAction={handleOpen}
                    handleAdd={() => setAddFormcloseoropen(true)}
                    enableZoomIcon={true}
                    showhistory={true}
                    isDraftView={false}
                    draftCount={clauseDetailsDraftData.length}
                    isEnableDraft={false}
                    handleIsDraft={(val: any) => setisShowDraft(val)}
                    showAdd={permssionPage?.IsAdd}
                    showDelete={true}
                    showView={permssionPage?.IsView}
                    showEdit={permssionPage?.IsEdit}
                    showExports={permssionPage?.IsExport}
                    userId={userLoginResponse?.UserId || 0}
                    isEnableRecordLock={{
                      IdField: "clause_Id",
                      data: {
                        moduleName: currentPagePermissionData?.MenuName || "",
                        // FormID : FormIdConfig?.ClauseFormId ,
                        MenuID: currentPagePermissionData?.MenuId,
                        IsCheckPending: false,
                      },
                    }}
                    pageName="Clause Master"
                    isActivekey="isActive"
                  />
                </Box>
              )}
            </Stack>
          </Box>
        )
      ) : (
        <Box sx={{ width: "100%" }}>
          <AddClauseStepper
            view={Option == "view" ? true : false}
            edit={Option == "edit" ? true : false}
            isEditData={isEditData}
            onClose={() => {
              handleRecordLockUpdate();
              setAddFormcloseoropen(false);
            }}
            isDraftData={isDraftData}
          />
        </Box>
      )}

      {/* <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
          dispatch(updateVersionHistory([]));
        }}
        maxWidth={"lg"}
        header={"Clause Master Version History"}
        contentNode={
          <> <MaterialDynamicGrid
            data={versionHistoryData}
            rows={versionHistory.VendorDetailsRecords}
            handleAction={handleOpen}
            enableZoomIcon={false}
            showhistory={false}
            showDelete={permssionPage?.IsDelete === 1}
            showView={permssionPage?.IsView === 1}
            showEdit={permssionPage?.IsEdit === 1}
            showExports={permssionPage?.IsExport === 1}
            userId={(userLoginResponse && userLoginResponse?.UserId) ? userLoginResponse?.UserId : 0}
            pageName="Clause Master Version History"
          />
          </>
        }
      /> */}
      <ModalPopUp
        onDeleteMethod={() => active_Inactive_Clause()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${
          isDeleteData?.isActive === "Active" ? "deactivate" : "activate"
        } the selected Clause`}
        ActionName={isDeleteData?.isActive === "Active" ? "Inactive" : "Active"}
      />
      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
        }}
        maxWidth={"lg"}
        header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Clause"} Version History`}
        contentNode={
          <>
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistory?.ClauseList}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Clause Version History"
            />
          </>
        }
      />
    </>
  );
};

export default ClauseList;
