import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { recordLockTimeOut } from "../../config/config";

export const recordLockGet = (id: any, permissionPage: any, moduleName: any) => ({
  MenuId: permissionPage?.MenuId,
  ModuleName: moduleName,
  RecordId: id,
});

const HandleAddRecordLock = (id: number, permissionPage: any, moduleName: string, activity: string) => ({
  RecordId: id,
  UserId: permissionPage?.UserId || 0,
  CreatedBy: permissionPage?.UserName || "",
  MenuId: permissionPage?.MenuId,
  ModuleName: moduleName,
  Activity: activity,
});

export const HandleRecordLockCheck = async (props: any) => {
  const { recordId, permissionPage, moduleName, dispatch, setIsEditing } = props;

  const handleFunctionCall = async () => {
    const dispatchData = HandleAddRecordLock(recordId, permissionPage, moduleName, "Edit");
    await dispatch(AddRecordLock(dispatchData));
    const updatedLockData = recordLockGet(recordId, permissionPage, moduleName);
    await dispatch(GetRecordLock(updatedLockData));
  };

  const recordLockData = recordLockGet(recordId, permissionPage, moduleName);
  const resultAction = await dispatch(GetRecordLock(recordLockData));

  if (!GetRecordLock.fulfilled.match(resultAction)) {
    setIsEditing(true);
    dispatch(updateLoader(false));
    return false;
  }

  const fetchedData: any = resultAction.payload;

  if (fetchedData?.length > 0 && fetchedData[0]?.UserId !== permissionPage?.UserId) {
    await handleEditingConflict(dispatch, setIsEditing, fetchedData[0]?.CreatedBy);
    return false;
  } else {
    setIsEditing(true);
    dispatch(updateLoader(false));
    handleFunctionCall();
  }

  return true;
};

const handleEditingConflict = async (dispatch: any, setIsEditing: any, createdBy: string) => {
  if (setIsEditing) setIsEditing(false);

  dispatch(
    updateToaster({
      isToaster: true,
      toasterMsg: `${createdBy} is Editing`,
      isTosterFailed: true,
    })
  );
};

export const RecordLockUpdate = () => {
  const dispatch = useAppDispatchThunk();
  const { recordLock } = useSelector((state: RootState) => state.recordLock);

  const handleRecordLockUpdate = () => {
    if (recordLock?.length > 0) {
      const data = {
        RecordlockId: recordLock[0].RecordlockId,
        RecordId: recordLock[0].RecordId,
        IsActive: false,
      };
      dispatch(UpdateRecordLock(data));
    }
  };

  return { handleRecordLockUpdate };
};
