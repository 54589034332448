import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { textFieldWidth } from "../../../utils/constants";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomFileUploader from "../../../common/dynamicFileUploader/CustomFileUploader";
import ExcelJS from "exceljs";
import {
  departmentImportdata,
  countrylocationImportdata,
  thirdPartyImportdata,
  statelocationImportdata,
  citylocationImportdata,
  industoryImportdata,
  regionalSettingsImportdata,
  currencyImportdata,
  branchImportdata,
  documentImportdata,
} from "../../../mockData/mockData";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { useSelector } from "react-redux";
import CustomSvg from "../../../common/CustomSvg";
import FileSaver from "file-saver";
import { useTheme } from "@mui/material/styles";
import { IMenu } from "../../../models/loggin.type";
import { addBulkUpload } from "../../../store/slices/bulkuploadMaster";
import ProgressBar from "../../../common/progressBar/progressBar";
import { getLovList } from "../../../store/slices/lovMasterData";
import { getParentLocationList } from "../../../store/slices/locationMasterData";
import { getParentIndustryList } from "../../../store/slices/industryMasterData";
import CustomMultiSelect from "../../../common/dynamicInputs/CustomMultiSelect";
import { getCompanyList } from "../../../store/slices/companyMasterData";
import { updateLoader } from "../../../store/slices/loader";
import { getParentDepartmentList } from "../../../store/slices/departmentData";

const BulkUpload = () => {
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);

  const { ParentLocationTable } = useSelector((state: RootState) => state.locationMasterData);
  const { isLoading, dataTablebulkupload, isBulkUploadAdded } = useSelector((state: RootState) => state.bulkuploadData);
  const dispatch = useAppDispatchThunk();
  const theme = useTheme();
  const { menuData, userLoginResponse, user, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { ParentindustryTable } = useSelector((state: RootState) => state.indutryMasterData);

  const { CompanyTable } = useSelector((state: RootState) => state.CompanyMasterData);

  const { parentTable } = useSelector((state: RootState) => state.departmentData);
  useEffect(() => {
    dispatch(updateLoader(isLoading));
  }, [isLoading]);

  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [mastersList, setmastersList] = useState<any[]>([]);
  const [selectedmasterError, setSelectedMasterError] = useState<string>("");
  const [excelData, setExcelData] = useState<any>(null);
  const [invalidExcelData, setInvalidExcelData] = useState<any>(null);
  const [validExcelData, setValidExcelData] = useState<any>(null);
  const [sheetError, setSheetError] = useState<string>("");
  const [sheetDataError, setSheetDataError] = useState<any[]>([]);
  const [steps, setSteps] = useState<number>(0);
  const [validTableHeader, setvalidTableHeader] = useState<any>(null);
  const [validandInvalidData, setvalidandInvalidData] = useState<any[]>([]);
  const [uploadSucessCount, setUploadSucessCount] = useState<any>(0);

  // ------ main select values --------------
  const [selectedmaster, setSelectedMaster] = useState<string>("");
  const [locationTypeValue, setlocationTypeValue] = useState<any>(null);
  const [parentLocationValue, setParentLocationValue] = useState<any>(null);
  const [thirdpartyValue, setThirdpartyValue] = useState<any>(null);
  const [countrylocationValue, setCountryLocationValue] = useState<any>(null);
  const [branchTypeValue, setBranchTypeValue] = useState<any>(null);
  const [industoryTypeValue, setindustoryTypeValue] = useState<any>(null);
  const [industoryLevelValue, setindustoryLeveelValue] = useState<any>(null);
  const [industoryParentValue, setIndustoryParentValue] = useState<any>(null);
  const [locationZoneValue, setLocationZoneValue] = useState<any>(null);
  const [locationRegionValue, setLocationRegionValue] = useState<any>(null);
  const [regionalDataTypeValue, setRegionalDataTypeValue] = useState<any>(null);
  const [docExtensionValue, setDocExtensionValue] = useState<any>([]);
  const [assesmentTypeValue, setAssesmentTypeValue] = useState<any>(null);
  const [docPuroseValue, setdocPuroseValue] = useState<any>(null);
  const [companyBranchValue, setCompanyBranchValue] = useState<any>([]);
  const [departmentTypeValue, setdepartmentTypeValue] = useState<any>(null);
  const [departmentLevelValue, setdepartmentLeveelValue] = useState<any>(null);
  const [departmentParentValue, setdepartmentParentValue] = useState<any>(null);
  // ------ main select values end -----------

  // ----option datas using validation location -------------
  const [ParentLocationData, setParentLocationData] = useState<any[]>([]);
  const [locationTypeList, setlocationTypeList] = useState<any[]>([]);
  const [thirdPartyTypeList, setThirdPartyTypeList] = useState<any[]>([]);
  const [countryLocationList, setCountryLocationList] = useState<any[]>([]);
  const [industoryTypeList, setIndustoryTypeList] = useState<any[]>([]);
  const [industoryLevelList, setIndustoryLevelList] = useState<any[]>([]);
  const [departmentTypeList, setDepartmentTypeList] = useState<any[]>([]);
  const [departmentLevelList, setDepartmentLevelList] = useState<any[]>([]);
  const [branchTypeList, setBranchTypeList] = useState<any[]>([]);
  const [locationZoneList, setlocationZoneList] = useState<any[]>([]);
  const [locationRegionList, setlocationRegionList] = useState<any[]>([]);
  const [parentIndustryList, setParentIndustryList] = useState<any[]>([]);
  const [regionalDataTypeList, setRegionalDataTypeList] = useState<any[]>([]);
  const [documentPurposeList, setDocumentPurposeList] = useState<any[]>([]);
  const [assesmentTypeList, setAssesmentTypeList] = useState<any[]>([]);
  const [docExtensionList, setDocExtensionList] = useState<any[]>([]);
  const [companyBranchList, setCompanyBranchList] = useState<any[]>([]);
  const [departmentParentList, seDepartmentParentList] = useState<any[]>([]);
  const { currentpageTitleData } = useSelector(
          (state: RootState) => state.FormTitleMasterData
        );

  // ----option datas using validation end-------------

  useEffect(() => {
    setlocationTypeList(lovTable.filter((item: any) => item.Name === "Location Type") || []);
    setThirdPartyTypeList(lovTable.filter((item: any) => item.Name === "Third Party Type") || []);
    setBranchTypeList(lovTable.filter((item: any) => item.Name === "Branch Type") || []);
    setIndustoryTypeList(lovTable.filter((item: any) => item.Name === "Industry Type") || []);
    setlocationZoneList(lovTable.filter((item: any) => item.Name === "Zone") || []);
    setlocationRegionList(lovTable.filter((item: any) => item.Name === "Region") || []);
    setIndustoryLevelList(lovTable.filter((item: any) => item.Name === "Industry Level") || []);
    setRegionalDataTypeList(lovTable.filter((item: any) => item.Name === "Data Type") || []);
    setAssesmentTypeList(lovTable.filter((item: any) => item.Name === "Assessment Type") || []);
    setDocumentPurposeList(lovTable.filter((item: any) => item.Name === "Purpose") || []);
    setDocExtensionList(lovTable.filter((item: any) => item.Name === "Document Ext") || []);
    setDepartmentTypeList(lovTable.filter((item: any) => item.Name === "Department Type") || []);
    setDepartmentLevelList(lovTable.filter((item: any) => item.Name === "Department Level") || []);
  }, [lovTable]);

  useEffect(() => {
    setCompanyBranchList(CompanyTable);
  }, [CompanyTable]);

  useEffect(() => {
    dispatch(
      getCompanyList({
        FormID: 2,
      })
    );
    dispatch(getLovList({ Name: "" }));
  }, []);

  useEffect(() => {
    seDepartmentParentList(parentTable);
  }, [parentTable]);

  useEffect(() => {
    console.log(ParentLocationTable);
    if (selectedmaster === "location") {
      setParentLocationData(ParentLocationTable);
    }

    if (selectedmaster === "regionalsetting") {
      setCountryLocationList(ParentLocationTable);
    }

    if (selectedmaster === "currency") {
      setCountryLocationList(ParentLocationTable);
    }
  }, [ParentLocationTable]);

  const handleExportInvalidSheet = (jsonData: any) => {
    let columnHeader = validTableHeader.columns.slice(0, -3);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`${validTableHeader.table_name} Template`);

    // Define columns with headers and widths
    const columnMaker = columnHeader.map((header: { Field: string }) => ({ width: 20, header: header.Field }));
    worksheet.columns = columnMaker;

    // Add data rows
    jsonData.forEach((data: any) => {
      const row = columnMaker.map((col: any) => data[col.header] || ""); // Use empty string if data is not available
      worksheet.addRow(row);
    });

    // Export to Excel file
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        FileSaver.saveAs(blob, `${validTableHeader.table_name}_Invalid_data.xlsx`);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const handleExportSheet = () => {
    let columnHeader = validTableHeader.columns.slice(0, -3);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`${validTableHeader.table_name} Template`);
    const columnMaker = columnHeader.map((header: { Field: string }) => ({ width: 20, header: header.Field }));

    worksheet.columns = columnMaker;

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });

        FileSaver.saveAs(blob, `${validTableHeader.table_name}_Template.xlsx`);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const handleImportSubmit = () => {
    if (selectedmaster === "department") {
      let modifiedValidExcelData: any[] = [...validExcelData];
      modifiedValidExcelData.map((item: any) => {
        item.Department_Type = departmentTypeValue;

        let finededIndType = departmentTypeList.find(
          (item) => item.Options === "Sub Department" && departmentTypeValue == item.LovId
        );
        if (finededIndType) {
          item.Parent_Level = departmentLevelValue;
          item.Parent_Department_Id = departmentParentValue;
        } else if (industoryTypeList.length > 0) {
          item.Parent_Department_Id = 0;
          let finededIndTypeLevel = departmentLevelList.find((item) => item.Options === "Level 1");
          item.Parent_Level = finededIndTypeLevel ? finededIndTypeLevel?.LovId : 0;
        }
        item.Status = 1;
        item.CreatedBy = user?.UserName;
        item.IsActive = 1;
        item.UserId = userLoginResponse?.UserId;
        return item;
      });
      let formData = { data: modifiedValidExcelData, section: selectedmaster };
      dispatch(addBulkUpload(formData));
    }

    if (selectedmaster === "location") {
      let modifiedValidExcelData: any[] = [...validExcelData];
      modifiedValidExcelData.map((item: any) => {
        item.Location_TypeId = locationTypeValue;
        item.ParentLocation_Id = parentLocationValue || 0;
        item.Start_Pin = item.Start_Pin || 0;
        item.End_Pin = item.End_Pin || 0;
        item.ZoneId = locationZoneValue || 0;
        item.RegionId = locationRegionValue || 0;
        item.Updated_By = user?.UserName;
        item.Status = 1;
        item.ActionTaken = "create";
        item.IsActive = 1;
        item.UserId = userLoginResponse?.UserId;
        return item;
      });

      let formData = { data: modifiedValidExcelData, section: selectedmaster };
      dispatch(addBulkUpload(formData));
    }

    if (selectedmaster === "thirdparty") {
      let modifiedValidExcelData: any[] = [...validExcelData];
      modifiedValidExcelData.map((item: any) => {
        item.ThirdParty_Type = thirdpartyValue;
        item.Updated_By = user?.UserName;
        item.Status = 1;
        item.PinCode = item?.PinCode ? item.PinCode.toString() : "";
        item.ContactNumber = item?.ContactNumber ? item.ContactNumber.toString() : "";
        item.ThirdParty_Address = item?.ThirdParty_Address ? item.ThirdParty_Address.toString() : "";
        item.city = item?.city ? item.city.toString() : "";
        item.ContactPerson = item?.ContactPerson ? item.ContactPerson.toString() : "";
        item.ContactNumber = item?.ContactNumber ? item.ContactNumber.toString() : "";
        item.EmailId = item?.EmailId ? item.EmailId.toString() : "";
        item.ActionTaken = "create";
        item.IsActive = 1;
        item.UserId = userLoginResponse?.UserId;
        return item;
      });

      let formData = { data: modifiedValidExcelData, section: selectedmaster };
      dispatch(addBulkUpload(formData));
    }

    if (selectedmaster === "regionalsetting") {
      let modifiedValidExcelData: any[] = [...validExcelData];
      modifiedValidExcelData.map((item: any) => {
        item.Location_Id = countrylocationValue;
        item.DataType = regionalDataTypeValue;
        item.Updated_By = user?.UserName;
        item.CreatedBy = user?.UserName;
        item.IsMandatory = item.IsMandatory == "true" ? 1 : 0;
        item.Format = item.Formate;
        item.Setting = item.Setting_Name;
        item.Status = 1;
        item.ActionTaken = "create";
        item.IsActive = 1;
        item.UserId = userLoginResponse?.UserId;
        return item;
      });

      let formData = { data: modifiedValidExcelData, section: selectedmaster };
      dispatch(addBulkUpload(formData));
    }
    if (selectedmaster === "currency") {
      let modifiedValidExcelData: any[] = [...validExcelData];

      modifiedValidExcelData.map((item: any) => {
        item.Country_Id = countrylocationValue;
        item.Updated_By = user?.UserName;
        item.Status = 1;
        item.ActionTaken = "create";
        item.UserId = userLoginResponse?.UserId;
        item.IsActive = 1;
        return item;
      });

      let formData = { data: modifiedValidExcelData, section: selectedmaster };
      dispatch(addBulkUpload(formData));
    }

    if (selectedmaster === "industory") {
      console.log(industoryLevelList, industoryTypeList);

      let modifiedValidExcelData: any[] = [...validExcelData];
      modifiedValidExcelData.map((item: any) => {
        item.IndustryType = industoryTypeValue;

        let finededIndType = industoryTypeList.find(
          (item) => item.Options === "Sub Industry" && industoryTypeValue == item.LovId
        );
        if (finededIndType) {
          item.Parent_Level = industoryLevelValue;
          item.Parent_Industry_Id = industoryParentValue;
        } else if (industoryTypeList.length > 0) {
          item.Parent_Industry_Id = 0;
          let finededIndTypeLevel = industoryLevelList.find((item) => item.Options === "Level 1");
          item.Parent_Level = finededIndTypeLevel ? finededIndTypeLevel?.LovId : 0;
        }

        item.Updated_By = user?.UserName;
        item.Status = 1;
        item.ActionTaken = "create";
        item.IsActive = 1;
        item.UserId = userLoginResponse?.UserId;

        return item;
      });

      let formData = { data: modifiedValidExcelData, section: selectedmaster };
      dispatch(addBulkUpload(formData));
    }

    if (selectedmaster === "document") {
      let modifiedValidExcelData: any[] = [...validExcelData];

      let dubExtArray: any[] = [];
      docExtensionValue.map((item: any) => {
        let dubExtobj = { LovId: 0, Description: "" };
        dubExtobj.LovId = item;
        let finded: any = docExtensionList.find((doc_item) => doc_item.LovId == item);
        if (finded) {
          dubExtobj.Description = finded.Description;
        }
        dubExtArray.push(dubExtobj);
      });
      modifiedValidExcelData.map((item: any) => {
        item.Assessment_Type = assesmentTypeValue;
        item.DocType_Extn_Id = JSON.stringify(dubExtArray) || "[]";
        item.Document_Purpose = docPuroseValue;
        item.Updated_By = user?.UserName;
        item.UserId = userLoginResponse?.UserId;
        item.Status = 1;
        item.ActionTaken = "create";
        item.IsActive = 1;
        item.IsMandatory = item.IsMandatory === "true" ? 1 : 0;
        return item;
      });

      let formData = { data: modifiedValidExcelData, section: selectedmaster };
      dispatch(addBulkUpload(formData));
    }
    if (selectedmaster === "branch") {
      let modifiedValidExcelData: any[] = [...validExcelData];
      const transformedDocTypeExtnId = companyBranchValue
        .map((id: any) => {
          const matchingItem = CompanyTable.find((item: any) => item.Company_Id === id);
          return matchingItem ? { Company_Id: matchingItem.Company_Id, Brand_Name: matchingItem.Brand_Name } : null;
        })
        .filter((item: any) => item !== null);

      modifiedValidExcelData.map((item: any) => {
        item.CompanyId = JSON.stringify(transformedDocTypeExtnId) || "[]";
        item.BranchType = branchTypeValue || [];
        item.Updated_By = user?.UserName;
        item.CreatedBy = user?.UserName;
        item.UserId = userLoginResponse?.UserId;
        item.Status = 1;
        item.ActionTaken = "create";
        item.IsActive = 1;
        return item;
      });

      let formData = { data: modifiedValidExcelData, section: selectedmaster };
      dispatch(addBulkUpload(formData));
    }
  };

  useEffect(() => {
    setmastersList([
      { name: "Department", value: "department" },
      { name: "Location", value: "location" },
      { name: "Industry", value: "industory" },
      { name: "Third Party", value: "thirdparty" },
      { name: "Regional Settings", value: "regionalsetting" },
      { name: "Currency", value: "currency" },
      { name: "Company Branch", value: "branch" },
      { name: "Document", value: "document" },
    ]);
  }, []);

  useEffect(() => {
    if (isBulkUploadAdded) {
      let beforeUpload = [...validandInvalidData];
      updateUploadStatus(beforeUpload);
    }
  }, [isBulkUploadAdded]);

  console.log(validandInvalidData, "validandInvalidData");

  const updateUploadStatus = (data: any) => {
    let validCount = 0;
    let beforeUpload = [...data];
    beforeUpload.map((item: any) => {
      let MatchData = dataTablebulkupload.find((resItem: any) => resItem.id === item.id);
      if (MatchData) {
        item.UploadStatus = MatchData.Message;
        if (MatchData.Message === "Added Successfully") {
          validCount++;
        }
      }
      return item;
    });
    setUploadSucessCount(validCount);
    setvalidandInvalidData(beforeUpload);
  };

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  const setSelectedMasterValue = (expression: any) => {
    setvalidTableHeader(null);
    let columns: any = {
      table_name: `Export_${expression}`,
      isAction: false,
      columns: [],
    };
    let sampleHeader: any[];

    switch (expression) {
      case "department":
        if (
          departmentTypeList &&
          departmentTypeList.find((item) => item.Options === "Sub Department" && departmentTypeValue == item.LovId)
        ) {
          if (departmentLevelValue && departmentParentValue) {
            sampleHeader = [...departmentImportdata];
            columns.columns = sampleHeader;
            columns.table_name = `Export_Sub_${expression}`;
          }
        } else if (
          departmentTypeList &&
          departmentTypeList.find((item) => item.Options === "Department" && departmentTypeValue == item.LovId)
        ) {
          sampleHeader = [...departmentImportdata];
          columns.columns = sampleHeader;
        }

        break;
      case "industory":
        if (
          industoryTypeList &&
          industoryTypeList.find((item) => item.Options === "Sub Industry" && industoryTypeValue == item.LovId)
        ) {
          if (industoryLevelValue && industoryParentValue) {
            sampleHeader = [...industoryImportdata];
            columns.columns = sampleHeader;
            columns.table_name = `Export_Sub_${expression}`;
          }
        } else if (
          industoryTypeList &&
          industoryTypeList.find((item) => item.Options === "Industry" && industoryTypeValue == item.LovId)
        ) {
          sampleHeader = [...industoryImportdata];
          columns.columns = sampleHeader;
        }

        break;

      case "location":
        if (
          locationTypeValue &&
          locationTypeList.find(
            (item) => (item.Options === "City" || item.Options === "State") && locationTypeValue == item.LovId
          )
        ) {
          if (parentLocationValue && parentLocationValue !== null) {
            if (locationTypeList.find((item) => item.Options === "City" && locationTypeValue == item.LovId)) {
              if (locationRegionValue && locationZoneValue) {
                sampleHeader = [...citylocationImportdata];
                columns.columns = sampleHeader;
                columns.table_name = `Export_${expression}_city`;
              }
            } else {
              sampleHeader = [...statelocationImportdata];
              columns.columns = sampleHeader;
              columns.table_name = `Export_${expression}_state`;
            }
          }
        } else if (locationTypeValue) {
          sampleHeader = [...countrylocationImportdata];
          columns.columns = sampleHeader;
          columns.table_name = `Export_${expression}_country`;
        }
        break;
      case "thirdparty":
        if (thirdpartyValue) {
          sampleHeader = [...thirdPartyImportdata];
          columns.columns = sampleHeader;
        }
        break;
      case "regionalsetting":
        if (countrylocationValue && regionalDataTypeValue) {
          sampleHeader = [...regionalSettingsImportdata];
          columns.columns = sampleHeader;
        }
        break;
      case "currency":
        if (countrylocationValue) {
          sampleHeader = [...currencyImportdata];
          columns.columns = sampleHeader;
        }
        break;
      case "branch":
        console.log("branch value", branchTypeValue, companyBranchValue);

        if (branchTypeValue && companyBranchValue?.length > 0) {
          console.log("branch value 1", branchTypeValue, companyBranchValue);
          sampleHeader = [...branchImportdata];
          columns.columns = sampleHeader;
        }
        break;
      case "document":
        if (assesmentTypeValue && docPuroseValue && docExtensionValue.length > 0) {
          sampleHeader = [...documentImportdata];
          columns.columns = sampleHeader;
        }
        break;
      default:
    }
    if (columns.columns.length > 0) {
      columns.columns.push(
        {
          Field: "IsValidorNot",
          FieldType: "string",
          DisplayName: "IsValidorNot",
        },
        {
          Field: "Error",
          FieldType: "string",
          DisplayName: "Error",
          Width: "300px",
        },
        {
          Field: "UploadStatus",
          FieldType: "string",
          DisplayName: "Upload Status",
          Width: "300px",
        }
      );
    }

    setvalidTableHeader(columns);
    setSelectedMaster(expression);
  };

  const clearOldData = () => {
    setExcelData("");
    setInvalidExcelData("");
    setValidExcelData("");
    setSheetError("");
    setSheetDataError([]);
    setSteps(0);
    setvalidandInvalidData([]);
 
    // setvalidTableHeader(null);

    // ----main data clear -------
    // setParentLocationData([]);
    // setParentLocationValue(null)
  };

  const handleFileUpload = async (e: any | null) => {
    clearOldData();
    if (e !== null) {
      const file = e[0];
      const workbook = new ExcelJS.Workbook();

      await workbook.xlsx.load(file);

      const worksheet = workbook.worksheets[0];
      const data = worksheet.getSheetValues();

      ValidateSheetCheck(selectedmaster, data);
    } else {
      setExcelData(null);
      clearOldData();
    }
  };

  const ValidateSheetCheck = (expression: any, sheet: any) => {
    let dubHeader = validTableHeader.columns.slice(0, -3) || [];
    switch (expression) {
      case "department":
        CustomValidateSheet(dubHeader, sheet);
        break;
      case "industory":
        CustomValidateSheet(dubHeader, sheet);
        break;
      case "location":
        CustomValidateSheet(dubHeader, sheet);
        break;
      case "thirdparty":
        CustomValidateSheet(dubHeader, sheet);
        break;
      case "regionalsetting":
        CustomValidateSheet(dubHeader, sheet);
        break;
      case "currency":
        CustomValidateSheet(dubHeader, sheet);
        break;
      case "document":
        CustomValidateSheet(dubHeader, sheet);
        break;
      case "branch":
        CustomValidateSheet(dubHeader, sheet);
        break;
      default:
    }
  };

  const CustomValidateSheet = async (validation: any, sheet: any) => {
    const headers = sheet[1];
    if (areAllElementsSame(validation, headers)) {
      let convertedData = await ExcelToJson(sheet);
      if (convertedData?.length === 0) {
        setSheetError("Sheet is empty. Please Fill Valid data");
      } else {
        const validationErrors = ValidateEachFields(convertedData, validation);
      }
    } else {
      setSheetError("Invalid sheet Please Check Sheets Fields");
    }
  };

  const ExcelToJson = async (data: any) => {
    const headers = data[1];

    if (headers) {
      const jsonData = [];

      for (let i = 2; i < data.length; i++) {
        const obj: any = { id: i - 1 };
        for (let j = 1; j < headers?.length; j++) {
          const value = (data[i] as ExcelJS.CellValue[])[j];
          if (value && typeof value === "object" && "text" in value) {
            obj[headers[j]] = value.text; // Extract the text property if it's a hyperlink
          } else {
            obj[headers[j]] = typeof value === "boolean" ? value.toString() : value;
          }
        }
        jsonData.push(obj);
      }

      return jsonData;
    }
  };

  function areAllElementsSame(validation: any, sheethead: any) {
    if (!sheethead || sheethead.length - 1 != validation.length) {
      return false;
    }

    for (let i = 0; i < validation.length; i++) {
      if (sheethead[i + 1] != validation[i].Field) {
        return false;
      }
    }

    return true;
  }

  const validateEmail = (email: any) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // const ValidateEachFields = (jsonData: any, validationImportdata: any) => {
  //   setExcelData(jsonData);
  //   let validDatas: any[] = [];
  //   let invalidDatas: any[] = [];
  //   let invalidDataError: any[] = [];
  //   // Iterate over each object in the JSON data array
  //   for (const dataItem of jsonData) {
  //     // Iterate over each field in the departmentImportdata schema
  //     const errors: any = {};

  //     for (const field of validationImportdata) {
  //       const fieldName = field.Field;
  //       const fieldValue = dataItem[fieldName];
  //       const fieldLabel = field.DisplayName;
  //       const fieldConfig = field.config || {};
  //       const ifRequired = field?.ifRequired;
  //       const ifRequiredField = field.ifRequiredField;

  //       // Check if the field is required and if it's empty
  //       if (fieldConfig.required && !fieldValue) {
  //         errors[fieldLabel] = `${fieldLabel} is required.`;
  //       }
  //       if (fieldConfig.common && fieldConfig.common === "Email" && fieldValue && !validateEmail(fieldValue)) {
  //         errors[fieldLabel] = `${fieldLabel} invalid ${fieldConfig.common} Formate.`;
  //       }

  //       if (field.FieldType === "number" && isNaN(fieldValue) && fieldConfig.required) {
  //         errors[fieldLabel] = `${fieldLabel} must be a valid number.`;
  //       }

  //       // Check minimum length if specified
  //       if (typeof fieldConfig.min === "number" && fieldValue && fieldValue.length < fieldConfig.min) {
  //         errors[fieldLabel] = `${fieldLabel} must be at least ${fieldConfig.min} characters.`;
  //       }

  //       // Check maximum length if specified
  //       if (typeof fieldConfig.max === "number" && fieldValue && fieldValue.length > fieldConfig.max) {
  //         errors[fieldLabel] = `${fieldLabel} cannot exceed ${fieldConfig.max} characters.`;
  //       }

  //       // Check if the field value is in the specified options if provided
  //       if (Array.isArray(field.options) && fieldValue && !field.options.includes(fieldValue.toString())) {
  //         if (ifRequired && ifRequiredField) {
  //           const fieldValueoption = dataItem[ifRequiredField];
  //           if (ifRequired.includes(fieldValueoption.toString()) && fieldValue) {
  //             errors[fieldLabel] = `${fieldLabel} must be one of the following options : ${field.options.join(", ")}.`;
  //           }
  //         } else {
  //           errors[fieldLabel] = `${fieldLabel} must be one of the following options : ${field.options.join(", ")}.`;
  //         }
  //       }

  //       // Check if the field value based on other value , is required
  //       if (ifRequired && ifRequiredField) {
  //         const fieldValueoption = dataItem[ifRequiredField];
  //         if (ifRequired.includes(fieldValueoption.toString()) && !fieldValue) {
  //           errors[fieldLabel] = `${ifRequiredField} is ${fieldValueoption}, ${fieldLabel} is required.`;
  //         }
  //       }
  //     }

  //     const errorsIsEmpty = Object.keys(errors).length === 0;

  //     invalidDataError.push(errors);
  //     if (errorsIsEmpty) {
  //       validDatas.push(dataItem);
  //     } else {
  //       invalidDatas.push(dataItem);
  //     }
  //   }

  //   if (invalidDatas.length !== 0) {
  //     setInvalidExcelData(invalidDatas);
  //   }

  //   if (validDatas.length !== 0) {
  //     setValidExcelData(validDatas);
  //   }

  //   const updatedArray = jsonData.map((obj: any, index: number) => {
  //     const errorObj = invalidDataError[index];

  //     const errorString = Object.entries(errorObj)
  //       .map(([key, value]) => `${value}`)
  //       .join(", ");
  //     return {
  //       ...obj,
  //       IsValidorNot: Object.keys(errorObj).length === 0 ? "Valid" : "Invalid",
  //       Error: Object.keys(errorObj).length === 0 ? "" : errorString,
  //       UploadStatus: "Pending",
  //     };
  //   });

  //   setvalidandInvalidData(updatedArray);
  // };
  const ValidateEachFields = (jsonData: any, validationImportdata: any) => {
    setExcelData(jsonData);
    let validDatas: any[] = [];
    let invalidDatas: any[] = [];
    let invalidDataError: any[] = [];

    for (const dataItem of jsonData) {
      const errors: any = {};
      const pinCodeValue = dataItem["PinCode"];
      const cityValue = dataItem["City"];
      const startPin = dataItem["Start_Pin"];
      const endPin = dataItem["End_Pin"];

      // Custom validation for PinCode and City fields
      if (pinCodeValue && (!cityValue || cityValue === "")) {
        errors["City"] = "City is required if PinCode is provided.";
      }

      if (cityValue && (!pinCodeValue || pinCodeValue === "")) {
        errors["PinCode"] = "PinCode is required if City is provided.";
      }

      if (!pinCodeValue && !cityValue) {
        delete errors["City"];
        delete errors["PinCode"];
      }

      // Overlapping Pin Code Range Validation
      let hasOverlapError = false; // Flag to track if overlap error is added
      for (const otherItem of jsonData) {
        if (otherItem !== dataItem) {
          const otherStartPin = otherItem["Start_Pin"];
          const otherEndPin = otherItem["End_Pin"];
          if (
            (startPin >= otherStartPin && startPin <= otherEndPin) ||
            (endPin >= otherStartPin && endPin <= otherEndPin) ||
            (startPin <= otherStartPin && endPin >= otherEndPin)
          ) {
            if (!hasOverlapError) {
              errors["Pin_Code_Range"] = `Pin code range overlaps with another city.`;
              hasOverlapError = true; // Ensure the error is only added once
            }
            break;
          }
        }
      }

      // General field validation (Required, min/max length, etc.)
      for (const field of validationImportdata) {
        const fieldName = field.Field;
        const fieldValue = dataItem[fieldName];
        const fieldLabel = field.DisplayName;
        const fieldConfig = field.config || {};
        const ifRequired = field?.ifRequired;
        const ifRequiredField = field.ifRequiredField;

        if (fieldConfig.required && !fieldValue) {
          errors[fieldLabel] = `${fieldLabel} is required.`;
        }

        if (fieldConfig.common && fieldConfig.common === "Email" && fieldValue && !validateEmail(fieldValue)) {
          errors[fieldLabel] = `${fieldLabel} invalid ${fieldConfig.common} format.`;
        }

        if (field.FieldType === "number" && isNaN(fieldValue) && fieldConfig.required) {
          errors[fieldLabel] = `${fieldLabel} must be a valid number.`;
        }

        if (typeof fieldConfig.min === "number" && fieldValue && fieldValue.length < fieldConfig.min) {
          errors[fieldLabel] = `${fieldLabel} must be at least ${fieldConfig.min} characters.`;
        }

        if (typeof fieldConfig.max === "number" && fieldValue && fieldValue.length > fieldConfig.max) {
          errors[fieldLabel] = `${fieldLabel} cannot exceed ${fieldConfig.max} characters.`;
        }

        if (Array.isArray(field.options) && fieldValue && !field.options.includes(fieldValue.toString())) {
          if (ifRequired && ifRequiredField) {
            const fieldValueoption = dataItem[ifRequiredField];
            if (ifRequired.includes(fieldValueoption.toString()) && fieldValue) {
              errors[fieldLabel] = `${fieldLabel} must be one of the following options: ${field.options.join(", ")}.`;
            }
          } else {
            errors[fieldLabel] = `${fieldLabel} must be one of the following options: ${field.options.join(", ")}.`;
          }
        }

        if (ifRequired && ifRequiredField) {
          const fieldValueoption = dataItem[ifRequiredField];
          if (ifRequired.includes(fieldValueoption.toString()) && !fieldValue) {
            errors[fieldLabel] = `${ifRequiredField} is ${fieldValueoption}, ${fieldLabel} is required.`;
          }
        }
      }

      const errorsIsEmpty = Object.keys(errors).length === 0;

      invalidDataError.push(errors);
      if (errorsIsEmpty) {
        validDatas.push(dataItem);
      } else {
        invalidDatas.push(dataItem);
      }
    }

    if (invalidDatas.length !== 0) {
      setInvalidExcelData(invalidDatas);
    }

    if (validDatas.length !== 0) {
      setValidExcelData(validDatas);
    }

    const updatedArray = jsonData.map((obj: any, index: number) => {
      const errorObj = invalidDataError[index];

      const errorString = Object.entries(errorObj)
        .map(([key, value]) => `${value}`)
        .join(", ");
      return {
        ...obj,
        IsValidorNot: Object.keys(errorObj).length === 0 ? "Valid" : "Invalid",
        Error: Object.keys(errorObj).length === 0 ? "" : errorString,
        UploadStatus: "Pending",
      };
    });

    setvalidandInvalidData(updatedArray);
  };

  const disableMainSelectValue = () => {
    if (validTableHeader && validandInvalidData.length !== 0) {
      return true;
    }
    return false;
  };

  // ----------------------------onchange main -----------------------

  useEffect(() => {
    clearOldData();
    setSelectedMasterValue(selectedmaster);
    if (selectedmaster === "regionalsetting" || selectedmaster === "currency") {
      let dublocationType: any[] = lovTable.filter((item: any) => item.Name === "Location Type");
      if (dublocationType && dublocationType.length > 0) {
        let countryTypeId: any = dublocationType.find((item) => item.Options === "Country");
        if (countryTypeId && countryTypeId.LovId) {
          dispatch(getParentLocationList({ LocationtypeId: countryTypeId.LovId }));
        }
      }
    }
  }, [selectedmaster]);

  useEffect(() => {
    setSelectedMasterValue(selectedmaster);
  }, [thirdpartyValue]);

  useEffect(() => {
    setSelectedMasterValue(selectedmaster);
  }, [branchTypeValue]);

  useEffect(() => {
    setParentLocationValue(null);
    setLocationRegionValue(null);
    setLocationZoneValue(null);

    // setParentLocationData([]);
    // setParentLocationValue(null)
    if (
      locationTypeValue &&
      locationTypeList.find(
        (item) => (item.Options === "City" || item.Options === "State") && locationTypeValue == item.LovId
      )
    ) {
      let findSeletcetTypeOption = locationTypeList.find((item) => item.LovId == locationTypeValue);
      let findedParentLovId = 0;
      if (findSeletcetTypeOption && findSeletcetTypeOption.Options == "City") {
        findedParentLovId = locationTypeList.find((item) => item.Options === "State")?.LovId;
      }
      if (findSeletcetTypeOption && findSeletcetTypeOption.Options == "State") {
        findedParentLovId = locationTypeList.find((item) => item.Options === "Country")?.LovId;
      }
      dispatch(getParentLocationList({ LocationTypeId: findedParentLovId }));
      setSelectedMasterValue(selectedmaster);
    } else if (locationTypeValue) {
      setSelectedMasterValue(selectedmaster);
      setParentLocationData([]);
    }
  }, [locationTypeValue]);

  useEffect(() => {
    if (countrylocationValue && (selectedmaster === "regionalsetting" || selectedmaster === "currency")) {
      setSelectedMasterValue(selectedmaster);
    }
  }, [countrylocationValue]);

  useEffect(() => {
    if (parentLocationValue) {
      setSelectedMasterValue(selectedmaster);
    }
  }, [parentLocationValue]);

  useEffect(() => {
    if (locationRegionValue) {
      setSelectedMasterValue(selectedmaster);
    }
  }, [locationRegionValue]);
  useEffect(() => {
    if (locationZoneValue) {
      setSelectedMasterValue(selectedmaster);
    }
  }, [locationZoneValue]);

  useEffect(() => {
    if (industoryTypeValue) {
      setIndustoryParentValue(null);
      setindustoryLeveelValue(null);
      setSelectedMasterValue(selectedmaster);
    }
  }, [industoryTypeValue]);

  const findParentIndustryLevel = (currentLevel: any) => {
    let level = currentLevel;
    while (level > 1) {
      level -= 1;
      const targetOption = `Level ${level}`;
      const findSelectIndustryLevel = industoryLevelList.find((item) => item.Options === targetOption);
      if (findSelectIndustryLevel) {
        return findSelectIndustryLevel.LovId;
      }
    }
    return 0;
  };

  useEffect(() => {
    if (industoryLevelValue) {
      setSelectedMasterValue(selectedmaster);
      setParentIndustryList([]);
      setIndustoryParentValue(null);
      let findSelectIndustry = industoryLevelList.find((item) => item.LovId == industoryLevelValue);

      if (findSelectIndustry) {
        const currentLevel = parseInt(findSelectIndustry.Options.split(" ")[1]);
        const parentIndustryId = findParentIndustryLevel(currentLevel);
        dispatch(getParentIndustryList({ IndustryId: parentIndustryId }));
      }
    }
  }, [industoryLevelValue]);

  const findParentDepartmentLevel = (currentLevel: any) => {
    let level = currentLevel;
    while (level > 1) {
      level -= 1;
      const targetOption = `Level ${level}`;
      const findSelectIndustryLevel = departmentLevelList.find((item) => item.Options === targetOption);
      if (findSelectIndustryLevel) {
        return findSelectIndustryLevel.LovId;
      }
    }
    return 0;
  };

  useEffect(() => {
    if (departmentLevelValue) {
      setSelectedMasterValue(selectedmaster);
      seDepartmentParentList([]);
      setdepartmentParentValue(null);
      let findSelectDepartment = departmentLevelList.find((item) => item.LovId == departmentLevelValue);

      if (findSelectDepartment) {
        const currentLevel = parseInt(findSelectDepartment.Options.split(" ")[1]);
        const parentDeparId = findParentDepartmentLevel(currentLevel);
        dispatch(getParentDepartmentList({ levelId: parentDeparId }));
      }
    }
  }, [departmentLevelValue]);

  useEffect(() => {
    setParentIndustryList(ParentindustryTable);
  }, [ParentindustryTable]);

  useEffect(() => {
    setSelectedMasterValue(selectedmaster);
  }, [
    departmentParentValue,
    industoryParentValue,
    regionalDataTypeValue,
    docPuroseValue,
    assesmentTypeValue,
    docExtensionValue,
    companyBranchValue,
    departmentTypeValue,
  ]);

  // useEffect(() => {
  //   setSelectedMasterValue(selectedmaster);
  // }, [regionalDataTypeValue]);

  // useEffect(() => {
  //   setSelectedMasterValue(selectedmaster);
  // },[docPuroseValue])
  // useEffect(() => {
  //   setSelectedMasterValue(selectedmaster);
  // },[assesmentTypeValue])

  // useEffect(() => {
  //   setSelectedMasterValue(selectedmaster);
  // },[docExtensionValue])

  // useEffect(() => {
  //   setSelectedMasterValue(selectedmaster);
  // },[companyBranchValue])

  // useEffect(() => {
  //   setSelectedMasterValue(selectedmaster);
  // },[departmentTypeValue])

  // useEffect(() => {
  //   setSelectedMasterValue(selectedmaster);
  // },[departmentLevelValue])

  // useEffect(() => {
  //   setSelectedMasterValue(selectedmaster);
  // },[departmentParentValue])

  return (
    <>
      <Box sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
        {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
        <Stack spacing={3}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
                                       {" "}
                                       {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                                       {/* Holiday Master */}
                                   </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <CustomSelect
                  name="Select Master"
                  valueKey={"value"}
                  optionLabelKey="name"
                  options={mastersList}
                  required={true}
                  label={"Select Master"}
                  value={selectedmaster}
                  onChange={(value) => setSelectedMaster(value)}
                  disabled={disableMainSelectValue()}
                  error={selectedmasterError}
                />
              </Grid>

              {selectedmaster === "department" ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="DepartmentType"
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={departmentTypeList}
                    required={true}
                    label={"Department Type"}
                    value={departmentTypeValue}
                    onChange={(value) => {
                      setdepartmentTypeValue(value);
                    }}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}

              {selectedmaster === "department" &&
              departmentTypeList.find(
                (item) => item.Options === "Sub Department" && departmentTypeValue == item.LovId
              ) ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="level"
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={departmentLevelList.filter((item) => item.Options != "Level 1")}
                    required={true}
                    label={"Level"}
                    value={departmentLevelValue}
                    onChange={(value) => {
                      setdepartmentLeveelValue(value);
                    }}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}

              {selectedmaster === "department" &&
              departmentTypeList.find(
                (item) => item.Options === "Sub Department" && departmentTypeValue == item.LovId
              ) ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="Parent Department"
                    valueKey={"DepartmentId"}
                    optionLabelKey="DepartmentName"
                    options={departmentParentList}
                    required={true}
                    label={"Parent Department"}
                    value={departmentParentValue}
                    onChange={(value) => {
                      setdepartmentParentValue(value);
                    }}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}

              {selectedmaster === "industory" ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="IndustoryType"
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={industoryTypeList}
                    required={true}
                    label={"Industry Type"}
                    value={industoryTypeValue}
                    onChange={(value) => {
                      setindustoryTypeValue(value);
                    }}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}
              {selectedmaster === "industory" &&
              industoryTypeList.find((item) => item.Options === "Sub Industry" && industoryTypeValue == item.LovId) ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="level"
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={industoryLevelList.filter((item) => item.Options != "Level 1")}
                    required={true}
                    label={"Level"}
                    value={industoryLevelValue}
                    onChange={(value) => {
                      setindustoryLeveelValue(value);
                    }}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}
              {selectedmaster === "industory" &&
              industoryTypeList.find((item) => item.Options === "Sub Industry" && industoryTypeValue == item.LovId) ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="Parent Industry"
                    valueKey={"IndustryId"}
                    optionLabelKey="IndustryName"
                    options={parentIndustryList}
                    required={true}
                    label={"Parent Industry"}
                    value={industoryParentValue}
                    onChange={(value) => {
                      setIndustoryParentValue(value);
                    }}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}

              {selectedmaster === "location" ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="LocationType"
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={locationTypeList}
                    required={true}
                    label={"Location Type"}
                    value={locationTypeValue}
                    onChange={(value) => {
                      setlocationTypeValue(value);
                    }}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}

              {(selectedmaster === "location" &&
                locationTypeList.find(
                  (item) => (item.Options === "City" || item.Options === "State") && locationTypeValue == item.LovId
                ) !== undefined) ||
              null ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="parentlocation"
                    valueKey={"Location_Id"}
                    optionLabelKey="Location_Name"
                    options={ParentLocationData}
                    required={true}
                    label={"Parent Location"}
                    value={parentLocationValue}
                    onChange={(value) => setParentLocationValue(value)}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}

              {(selectedmaster === "location" &&
                locationTypeList.find((item) => item.Options === "City" && locationTypeValue == item.LovId) !==
                  undefined) ||
              null ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="Zone"
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={locationZoneList}
                    required={true}
                    label={"Zone"}
                    value={locationZoneValue}
                    onChange={(value) => setLocationZoneValue(value)}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}

              {(selectedmaster === "location" &&
                locationTypeList.find((item) => item.Options === "City" && locationTypeValue == item.LovId) !==
                  undefined) ||
              null ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="Region"
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={locationRegionList}
                    required={true}
                    label={"Region"}
                    value={locationRegionValue}
                    onChange={(value) => setLocationRegionValue(value)}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}

              {selectedmaster === "thirdparty" ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="Third Party Type"
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={thirdPartyTypeList}
                    required={true}
                    label={"Third Party Type"}
                    value={thirdpartyValue}
                    onChange={(value) => {
                      setThirdpartyValue(value);
                    }}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}

              {selectedmaster === "document" ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="Assesment Type"
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={assesmentTypeList}
                    required={true}
                    label={"Assesment Type"}
                    value={assesmentTypeValue}
                    onChange={(value) => {
                      setAssesmentTypeValue(value);
                    }}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}
              {selectedmaster === "document" ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomMultiSelect
                    optionLabelKey={`Options`}
                    optionIdKey={`LovId`}
                    options={docExtensionList}
                    required={true}
                    label={"Document Extension"}
                    value={docExtensionValue}
                    onChange={(e) => {
                      console.log(e.target?.value);

                      setDocExtensionValue(e.target?.value || []);
                    }}
                    disabled={disableMainSelectValue()}
                    error={Boolean(selectedmasterError)}
                  />
                </Grid>
              ) : null}
              {selectedmaster === "document" ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="Purpose"
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={documentPurposeList}
                    required={true}
                    label={"Purpose"}
                    value={docPuroseValue}
                    onChange={(value) => {
                      setdocPuroseValue(value);
                    }}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}

              {selectedmaster === "regionalsetting" || selectedmaster === "currency" ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="Country"
                    valueKey={"Location_Id"}
                    optionLabelKey="Location_Name"
                    options={countryLocationList}
                    required={true}
                    label={"Country"}
                    value={countrylocationValue}
                    onChange={(value) => {
                      setCountryLocationValue(value);
                    }}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}

              {selectedmaster === "regionalsetting" ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="Data Type"
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={regionalDataTypeList}
                    required={true}
                    label={"Data Type"}
                    value={regionalDataTypeValue}
                    onChange={(value) => {
                      setRegionalDataTypeValue(value);
                    }}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}

              {selectedmaster === "branch" ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomSelect
                    name="Branch Type"
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={branchTypeList}
                    required={true}
                    label={"Branch Type"}
                    value={branchTypeValue}
                    onChange={(value) => {
                      setBranchTypeValue(value);
                    }}
                    disabled={disableMainSelectValue()}
                    error={selectedmasterError}
                  />
                </Grid>
              ) : null}

              {selectedmaster === "branch" ? (
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <CustomMultiSelect
                    optionLabelKey={`Legal_Name`}
                    optionIdKey={`Company_Id`}
                    options={companyBranchList}
                    required={true}
                    label={"Company"}
                    value={companyBranchValue}
                    onChange={(e) => {
                      setCompanyBranchValue(e.target?.value || []);
                    }}
                    disabled={disableMainSelectValue()}
                    error={Boolean(selectedmasterError)}
                  />
                </Grid>
              ) : null}
              {steps === 0 && (
                <Grid item sx={{ display: "flex", justifyContent: "start !important" }}>
                  <Box sx={{ minWidth: "200px", display: "flex", justifyContent: "start", pb: 0 }}>
                    <CustomButton
                      type="reset"
                      disabled={!validTableHeader || validTableHeader.columns.length === 0}
                      name={"Export Template"}
                      onClick={() => handleExportSheet()}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>

          {validTableHeader && validTableHeader.columns.length > 0 && steps === 0 && (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", mt: 10 }}>
              <Box sx={{ width: "50%" }}>
                <CustomFileUploader onFileUpload={handleFileUpload} maxFileSize={1048576} acceptFormats={[".xlsx"]} />

                <Box>
                  <Typography textAlign={"left"} sx={{ color: "#D70000" }}>
                    {sheetError}
                  </Typography>
                </Box>

                {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", mt: 2 }}>
                <CustomButton disabled={!validExcelData} name={"Next"} onClick={() => setSteps(1)} />
              </Box> */}
              </Box>
            </Box>
          )}

          {validTableHeader && validandInvalidData.length !== 0 && (
            <Box sx={{ width: "100%" }}>
              {excelData && (
                <Box
                  sx={{
                    mt: 2,
                    border: "1px solid gray",
                    p: 1,
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    {excelData && (
                      <Typography variant="subtitle1" color={"text.primary"}>
                        Import Data : {excelData.length}
                      </Typography>
                    )}
                    {validExcelData ? (
                      <Typography variant="subtitle1" color={"text.primary"}>
                        {" "}
                        | Valid Data : {validExcelData.length}
                      </Typography>
                    ) : (
                      <Typography color={"text.primary"} variant="subtitle1">
                        {" "}
                        | Valid Data : 0
                      </Typography>
                    )}
                    <Box sx={{ display: "flex" }}>
                      {invalidExcelData ? (
                        <Typography
                          onClick={() => handleExportInvalidSheet(invalidExcelData)}
                          variant="subtitle1"
                          sx={{
                            color: "#D70000",
                            textDecoration: invalidExcelData.length > 0 ? "underline" : "none",
                            cursor: invalidExcelData.length > 0 ? "pointer" : "default",
                          }}
                        >
                          {" "}
                          | Invalid Data : {invalidExcelData.length}
                        </Typography>
                      ) : (
                        <Typography variant="subtitle1" color={"text.primary"}>
                          {" "}
                          | Invalid Data : 0
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {isBulkUploadAdded ? (
                    <Box>
                      {" "}
                      <Typography variant="subtitle1" sx={{ color: "#129507" }}>
                        Sucessfully Uploaded Count : {uploadSucessCount}
                      </Typography>{" "}
                    </Box>
                  ) : null}
                </Box>
              )}
              {/* <Typography variant="subtitle1" color={"text.primary"} sx={{textAlign:'left' , paddingY : 2}}>
        <Box sx={{position:'relative', top:'2px', display:'inline'}}  onClick={() => setSteps(0)}><CustomSvg name={'arrow-left'}/></Box>   Valid Data
            </Typography> */}
              <MaterialDynamicGrid
                data={validTableHeader}
                rows={validandInvalidData}
                enableZoomIcon={true}
                showhistory={false}
                showDelete={permssionPage?.IsDelete === 1}
                showView={permssionPage?.IsView === 1}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={permssionPage?.IsExport === 1}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                pageName="Bulk Upload"
                disableselect={true}
              />

              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", mt: 2, gap: 2 }}>
                <CustomButton variant="outlined" disabled={isLoading} name={"Reset"} onClick={() => clearOldData()} />
                <CustomButton
                  disabled={!validExcelData || isBulkUploadAdded || isLoading}
                  name={"Submit Valid Data"}
                  onClick={() => handleImportSubmit()}
                />
              </Box>
            </Box>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default BulkUpload;
