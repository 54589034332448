import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import masterEditEnableService from "../../../store/services/masterEditEnableService";
import { AddThreshold } from "./AddThreshold";
import { Edit_Enable_tosterMSG, FormIdConfig, LovConfig } from "../../../config/config";
import { deleteThresholdMaster, getAllThresholdMaster, ThresholdMasterAddandSave } from "../../../store/performanceManagement/slices/thresholdMaster";
import { getNotifications } from "../../../store/slices/notification";
export const Threshold: React.FC = () => {
    const dispatch = useAppDispatchThunk();
    const [addForm, setAddForm] = useState(false);
    const [Option, setOption] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [IsEditData, setIsEditData] = useState<any[]>([]);
    const [isVersionHistory, setIsVersionHistory] = useState(false);
    const [isDeleteData, setIsDeleteData] = useState<any>(null);
    const performanceManagementReducer: any = useSelector((state: RootState) => state?.performanceManagementReducer);
    const { isLoading, ToasterMsg, isThresholdMasterRemoved, isThresholdMasterUpdated, isThresholdMasterAdded, ThresholdTable }: any = performanceManagementReducer.ThresholdMasterDataSlice;
    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );
    const { currentpageTitleData } = useSelector(
        (state: RootState) => state.FormTitleMasterData
    );

    useEffect(() => {
        dispatch(getAllThresholdMaster({
            FormID: 2
        }))
       

    }, [])

        const SeverityMasterActive = () => {
            // let formData = { ...isDeleteData };
            let formData: any = {};
            formData.ModuleName = "Threshold Master";
            formData.activity = isDeleteData.isActive === "Active" ? "Inactive" : "Active";
            formData.isActive = isDeleteData.isActive === "Active" ? false : true;
            formData.thresholdId = isDeleteData.thresholdId;
            formData.CreatedBy = user?.UserName;
            formData.APImasterid = isDeleteData.Id;
            formData.UpdatedBy = user?.UserName;
            formData.userId = userLoginResponse?.UserId;
            const currentDate = new Date();
            formData.updatedOn = currentDate.toISOString()
            // formData.Status= isDeleteData.IsActive === "Active" ? false : true;
            // formData.IsActive = isDeleteData.IsActive === "Active" ? 0 : 1;
            dispatch(deleteThresholdMaster(formData));
            setIsDeleteData(null);
        };
    const columns: any = {
        table_name: "Threshold",
        isAction: true,
        columns: [
            {
                Field: "description",
                DisplayName: "Threshold Description",
                FieldType: "string",
            },
            {
                Field: "fromRange",
                DisplayName: "From Range",
                FieldType: "string",
            },
            {
                Field: "toRange",
                DisplayName: "To Range",
                FieldType: "string",
            },
            {
                Field: "score",
                DisplayName: "Score",
                FieldType: "string",
            },
            {
                Field: "isActive",
                DisplayName: "Status",
                FieldType: "string",

            },{
                Field: "updatedBy",
                DisplayName: "Modified By",
                FieldType: "string",
            }
        ],
    };
    const rowData = [
        { ThresholdDescription: "Performed Exceptionally well", fromRange: 1.3, toRange: 2.1, Score: 3, IsActive: "Active" },
        { ThresholdDescription: "ThresholdDescription", fromRange: 2.4, toRange: 3.2, Score: 3, IsActive: "InActive" },
        { ThresholdDescription: "ThresholdDescription", fromRange: 3.7, toRange: 3.9, Score: 3, IsActive: "Active" },
        { ThresholdDescription: "ThresholdDescription", fromRange: 4.8, toRange: 5.0, Score: 3, IsActive: "Active" },
    ]

    useEffect(() => {
            if (isThresholdMasterAdded) {
               dispatch(ThresholdMasterAddandSave(false));
                // setLableName("Industry");
                dispatch(
                    updateToaster({
                        isToaster: true,
                        toasterMsg: ToasterMsg.Message || "Message" ,
                        isTosterFailed: ToasterMsg.Status === "false" ? true : false,
                    })
                );
                dispatch(getAllThresholdMaster({
                    FormID: 2
                }))
                if (userLoginResponse) {
                    dispatch(getNotifications(userLoginResponse?.UserId));
                }
            } else if (isThresholdMasterUpdated) {                
                dispatch(
                    updateToaster({
                        isToaster: true,
                        toasterMsg: ToasterMsg.Message || "Message" ,
                        isTosterFailed: ToasterMsg.Status === "false" ? true : false,
                    })
                );
    
                dispatch(ThresholdMasterAddandSave(false));
                dispatch(getAllThresholdMaster({
                    FormID: 2
                }))
            } else if (isThresholdMasterRemoved) {
                dispatch(getAllThresholdMaster({
                    FormID: 2
                }))
                setIsDeleteData(null);
                dispatch(
                    updateToaster({
                        isToaster: true,
                        toasterMsg: ToasterMsg.Message || "Message" ,
                        isTosterFailed: ToasterMsg.Status === "false" ? true : false,
                    })
                );
                setTimeout(() => {
                    dispatch(ThresholdMasterAddandSave(false));
                }, 1000);
              
                // } else if (isindustryDeletedFailed) {
                //   dispatch(
                //     updateToaster({
                //       isToaster: true,
                //       toasterMsg: ToasterMsg.Message,
                //       isTosterFailed: ToasterMsg.Status === "false" ? true : false,
                //     })
                //   );
    
                //   dispatch(updateIndustryAddandEdit(false));
                //   dispatch(
                //     getIndustryList({
                //       FormID: 2,
                //     })
                //   );
                // }
            }
        }, [isThresholdMasterAdded, isThresholdMasterUpdated, isThresholdMasterRemoved]);
    const handleOpen = async (data: any, Option: string) => {
        if (Option === "edit") {
            dispatch(updateLoader(true));
            setOption(Option);
            setIsEditData(data);
            dispatch(updateLoader(false));
            setIsEdit(true);

        } else if (Option === "view") {
            setOption(Option);
            setIsEditData(data);
            setIsEdit(true);
        }
        else if (Option === "history") {
            setOption(Option);
            setIsVersionHistory(true);
        }
        else if (Option === "delete") {
            setOption(Option);
            setIsDeleteData(data);
        }
    };
    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    // backgroundColor: "#ffff",
                    // backgroundColor: "background.default",
                    // px: 3,
                    // py: 4,
                    // borderRadius: "10px",
                }}
            >
                <Stack spacing={2}>
                    <Box
                        sx={{
                            display: "flex",
                            // flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h6" color={"text.primary"}>
                            {/* {currentpageTitleData ? currentpageTitleData?.ViewTitle : "Threshold Master"} */}
                            Threshold Master
                        </Typography>

                        <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
                    </Box>

                    <Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <MaterialDynamicGrid
                                data={columns}
                                rows={ThresholdTable}
                                handleAction={handleOpen}
                                enableZoomIcon={true}
                                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                                handleAdd={() => setAddForm(true)}
                                showAdd={true}
                                showhistory={false}
                                showDelete={true}
                                showView={true}
                                showEdit={true}
                                showExports={true}
                                pageName="Threshold Master"
                                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                            />
                        </Box>
                    </Box>
                </Stack>
            </Box>

            <CustomDialog
                show={addForm}
                onHide={() => setAddForm(false)}
                maxWidth={"xs"}
                minHeight={"50vh"}
                header={"Add Threshold"}
                // header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Threshold"}`}
                contentNode={
                    <AddThreshold
                        edit={false}
                        view={false}
                        closePopUp={() => {
                            setAddForm(false);
                        }}
                    />

                }
            />

            <CustomDialog
                show={isEdit}
                onHide={() => {
                    // if (Option === "history") {
                    //   dispatch(updateVersionHistory([]));
                    // }
                    setIsEdit(false);
                }}
                maxWidth={"xs"}
                minHeight={"50vh"}
                header={Option === "view" ? `View Threshold` : `Edit Threshold`}
                // header={Option === "view" ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Threshold"}` : `Edit ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Threshold"}`}
                contentNode={
                    <>
                        {Option === "edit" && (
                            <AddThreshold
                                edit={true}
                                view={false}
                                isEditData={IsEditData}
                                closePopUp={() => {
                                    setIsEdit(false);
                                }}
                            />
                        )}
                        {Option === "view" && (
                            <AddThreshold
                                edit={false}
                                view={true}
                                isEditData={IsEditData}
                                closePopUp={() => {
                                    setIsEdit(false);
                                }}
                            />
                        )}
                    </>
                }
            />
            <ModalPopUp
                onDeleteMethod={() => {SeverityMasterActive() }}
                isDelete={true}
                onHide={() => setIsDeleteData(null)}
                show={isDeleteData ? true : false}
                header={"Confirm Action"}
                text={`Are you sure you want to ${isDeleteData?.isActive === "Active" ? "deactivate" : "activate"} the selected Threshold?`}
                ActionName={isDeleteData?.isActive === "Active" ? "Inactive" : "Active"}
            />
              
        </>
    );
};
