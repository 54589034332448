import React, { useEffect, useState } from "react";
import CustomAccordion from "../../../../common/dynamicAccordion/CustomAccordion";
import { addWorkingDays, getDateOrConvertSameTime, textFieldWidth } from "../../../../utils/constants";
import { Box, Stack, Typography, Divider, Grid } from "@mui/material";
import DynamicBasicTable from "../../../../common/dynamicbasicTable/DynamicBasicTable";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import CustomDialog from "../../../../common/dynamicDialog/CustomDialog";
import { AddressForm } from "../../../vendorDetails/AddVendor/OtherDetails/AddressForm";
import { SpocForm } from "../../../vendorDetails/AddVendor/OtherDetails/SpocForm";
import { EscalationForm } from "../../../vendorDetails/AddVendor/OtherDetails/EscalationForm";
import { RootState, useAppDispatchThunk } from "../../../../store/store";
import ModalPopUp from "../../../../common/modalComponent/ModalPopUp";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { getDepartmentList } from "../../../../store/slices/departmentData";
import { useSelector } from "react-redux";
import { Assessment_Status, LovConfig, thirdPrtyType } from "../../../../config/config";
import CustomSelect from "../../../../common/dynamicInputs/CustomSelect";
import CustomBreadcrumbs from "../../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import PageMainHeading from "../../../../common/CommonHeadings/PageMainHeading";
import SingleAssessmentQuestionnaireForCustomer from "./SingleAssessmentQuestionnaireForCustomer";
import { getCustomerAssessmentbycustomerIdorall } from "../../../../store/slices/cobAssessment";
import { getVendorDetailsList } from "../../../../store/slices/vendorDetails";
import { getServiceCatalogList } from "../../../../store/slices/serviceCatalog";
import dayjs from "dayjs";
import CustomAutoComplete from "../../../../common/dynamicInputs/CustomAutoComplete";
export interface CustomerOnBoardingAssessmentProps {
    onClose?: any;
    currentProcessData?: any;
}
export const COBReferHistorical: React.FC<CustomerOnBoardingAssessmentProps> = ({ onClose, currentProcessData }) => {

    const data = [
        { "index": 1, "Assessment No.": "ABCD467", "Date": "jan 2024", "Customer": "ABC", "Department": 1, "Service": "CPV Agency" },
        { "index": 2, "Assessment No.": "ABGH645", "Date": "jan 2024", "Customer": "ABC", "Department": 280, "Service": "CPV Agency" },
        { "index": 3, "Assessment No.": "KHFT689", "Date": "jan 2024", "Customer": "ABC", "Department": 3, "Service": "CPV Agency" },
        { "index": 4, "Assessment No.": "QEFH863", "Date": "jan 2024", "Customer": "ABC", "Department": 5, "Service": "CPV Agency" }
    ]
    const { CustomerAssessmentAlldata } = useSelector((state: RootState) => state.CustomerAssessmentData);
    const [isNewAddress, setIsNewAddress] = useState(false);
    const [isNewSpoc, setIsNewSpoc] = useState(false);
    const [isNewEsc, setIsNewEsc] = useState(false);
    const [option, setOption] = useState("");
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
    const [filterAssessmentdata, setFilterAssessmentdata] = useState<object[]>([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState("")
    const [selectedService, setSelectedService] = React.useState("")
    const [tableData, setTableData] = React.useState(data)
    const [AssessmentAlldata, setAssessmentAlldata] = useState<any>("")
    const [isEditOrViewAddress, setIsEditOrViewAddress] = useState(true);
    const [isEditOrViewSPOC, setIsEditOrViewSPOC] = useState(false);
    const [isEditOrViewEsc, setIsEditOrViewEsc] = useState(false);
    const [expanded, setExpanded] = useState<any>(3);
    const { dataTable } = useSelector((state: RootState) => state.departmentData);
    const {
        vendorDetailsdata,
    } = useSelector((state: RootState) => state.vendorDetails);
    const [statusIdLabelMapping, setStatusIdLabelMapping] = useState<any>({});
    const [isHistorical, setisHistorical] = useState(false);
    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );
    const { ServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);
    const [serviceCatalog, setserviceCatalog] = useState<any[]>([]);
    const [customerList, setCustomerList] = useState<any[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<any>("")
    const TableHead = [
        { Field: "Assessment_Number", DisplayName: "Assessment No." },
        { Field: "Assessment_StartDateValue", DisplayName: "Date" },
        { Field: "Vendor_Name", DisplayName: "Customer" },
        { Field: "ServicesName", DisplayName: "Services" },
    ];
    useEffect(() => {
        dispatch(getCustomerAssessmentbycustomerIdorall({}))
    }, [])

    useEffect(() =>{
        
        let CuList : any[] = vendorDetailsdata?.filter((c_item : any) => c_item?.Type_Of_Vendor2_Value && c_item?.Type_Of_Vendor2_Value == thirdPrtyType?.customer )
        console.log(vendorDetailsdata , CuList , "vendorDetailsdata");
        
        setCustomerList(CuList)
    },[vendorDetailsdata])
    useEffect(() => {
        setserviceCatalog(ServiceCatlogdata);
    }, [ServiceCatlogdata]);
    useEffect(() => {
        if (userLoginResponse?.Department) {
            setSelectedDepartment(userLoginResponse?.Department)
        }
        if (CustomerAssessmentAlldata) {
            setAssessmentAlldata(CustomerAssessmentAlldata);
            findAssessmentdataAndQus()
        }

    }, [CustomerAssessmentAlldata])
    useEffect(() => {
        setTableData(data);
    }, [])

    const findAssessmentdataAndQus = () => {

        if (selectedDepartment) {
            let dubAssessmentAlldata: any[] = [...AssessmentAlldata]
            dubAssessmentAlldata = dubAssessmentAlldata.filter((item) =>
                item?.Assessment_StatusName == Assessment_Status.Active
            ).map((item: any) => {
                let Mapped_Services: any[] = [];
                try {
                    Mapped_Services = item?.Mapped_Services ? JSON.parse(item.Mapped_Services) : [];
                } catch (error) {
                    Mapped_Services = [];
                }
                const serviceCatalogMap = new Map(
                    serviceCatalog.map((service: any) => [service.ServiceCatalogId, service.ServiceName])
                );
                const ServicesName = Mapped_Services
                    ? Mapped_Services.map((data: any) => serviceCatalogMap.get(data) || "")
                        .filter(Boolean)
                        .join(", ")
                    : "";
                let Assessment_StartDateValue = dayjs(item.Assessment_StartDate).format("DD-MM-YYYY")
                let AssessmentTarget_Date = dayjs(item.Target_Date).format("DD-MM-YYYY")

                return {
                    ...item,
                    ServicesName,
                    Assessment_StartDateValue,
                    AssessmentTarget_Date,
                   
                };
            });
            dubAssessmentAlldata = dubAssessmentAlldata.filter((item) => !selectedCustomer || item.Vendor_Id == selectedCustomer)
            if (selectedService) {
                dubAssessmentAlldata = dubAssessmentAlldata.filter((item) => {
                    let Mapped_Services = item?.Mapped_Services || "[]"
                    Mapped_Services = JSON.parse(Mapped_Services)
                    return Mapped_Services?.includes(selectedService)
                }
                );
            }
            let FilterAssessment: any[] = [];
            dubAssessmentAlldata.forEach((data: any, index: any) => {
                let FiltedQuestion: any[] = [];
                data.AssessmentQuestionnaires.map((value: any) => {

                    if (value != undefined) {
                        if (value.SME == selectedDepartment) {
                            FiltedQuestion.unshift(value)
                        }
                    }

                })
                if (FiltedQuestion?.length != 0) {
                    let obj: any = { ...data, AssessmentQuestionnaires: FiltedQuestion,selectedDepartment}
                    FilterAssessment.unshift(obj)
                }
            })
            setFilterAssessmentdata(FilterAssessment);
            console.log(filterAssessmentdata,"FilterAssessment")
        }
    }

    const dispatch = useAppDispatchThunk();
    useEffect(() => {
        dispatch(getDepartmentList({ FormID: 2 }));
        dispatch(getVendorDetailsList());
        dispatch(getServiceCatalogList());
    }, [])

    const closeSection = (value: any) => {
        if (value === 0) {
            onClose(1)
        } if (value === 1) {
            setisHistorical(false)
        }


    };


    const handleOpen = (data: any, option: string, index?: number) => {
        setStatusIdLabelMapping(index)
        setOption("view");
        setisHistorical(true)
        if (option === "active") {
            setIsEditOrViewAddress(false)
        }
        if (index || index === 0) setSelectedIndex(index)
        if (expanded === 3) {
            handleAddressOption(data, option, index);
        } else if (expanded === 4) {
            handleSpocOption(data, option, index);
        } else if (expanded === 5) {
            handleEscalationOption(data, option, index);
        }
    };


    const handleAddressOption = (data: any, option: string, index?: number) => {
        setIsEditOrViewEsc(false);
        setIsEditOrViewSPOC(false);
        if (index || index === 0) {
            if (option === "edit" || option === "view") {
                setIsEditOrViewAddress(data);
                setIsNewAddress(false);
            }
        }
    };



    const handleSpocOption = (data: any, option: string, index?: number) => {
        setIsEditOrViewAddress(false);
        setIsEditOrViewEsc(false);
        if (index || index === 0) {

            if (option === "edit" || option === "view") {
                setIsEditOrViewSPOC(data);
                setIsNewSpoc(false);
            }
        }
    };

    const handleEscalationOption = (data: any, option: string, index?: number) => {
        setIsEditOrViewAddress(false);
        setIsEditOrViewSPOC(false);
        if (index || index === 0) {
            if (option === "edit" || option === "view") {
                setIsEditOrViewEsc(data);
                setIsNewEsc(false);
            }
        }
    };


    return (
        <div>
            {!isHistorical ? (
                <Box
                    sx={{
                        width: "100%",
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
                        <CustomBreadcrumbs
                            list={[
                                { value: 0, name: "Pending Action" },
                                { value: 0, name: "Customer OnBoarding Assessment" },
                                { value: 1, name: "Assessment Questionnaire For Customer" }
                            ]}
                            onchange={closeSection}
                        />
                    </Box>

                    <Stack spacing={2}>

                        <Box
                            sx={{
                                display: "flex",
                                // flexDirection: "row",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <PageMainHeading title={"Assessment Questionnaire For Customer"} />
                        </Box>
                        <Box>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                <Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <CustomSelect
                                        name="DepartmentId"
                                        valueKey="DepartmentId"
                                        optionLabelKey="DepartmentName"
                                        options={dataTable}
                                        readOnly={false}
                                        label={"Department"}
                                        value={selectedDepartment}
                                        onChange={(val) => setSelectedDepartment(val)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <CustomSelect
                                        name={"Vendor_Id"}
                                        valueKey={"Vendor_Id"}
                                        optionLabelKey="Legal_Name"
                                        options={customerList}
                                        label={"Customer"}
                                        value={selectedCustomer}
                                        onChange={(val) => setSelectedCustomer(val)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <CustomSelect
                                        name={"ServiceCatalogId"}
                                        valueKey={"ServiceCatalogId"}
                                        optionLabelKey="ServiceName"
                                        options={serviceCatalog}
                                        label={"Service"}
                                        value={selectedService}
                                        onChange={(val) => setSelectedService(val)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "end" }}>
                            <CustomButton variant="outlined" name={"Search"} size="small" onClick={() => findAssessmentdataAndQus()} />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box >
                                <Divider />
                            </Box>
                        </Box>
                        <Box >
                            <Typography
                                sx={{ fontSize: "16px", display: "flex", justifyContent: "start" }}
                                color="#6A6A6A"
                                variant="caption"
                            >    {filterAssessmentdata.length} Records found
                            </Typography>
                        </Box>
                        <Box>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <DynamicBasicTable
                                    columns={TableHead}
                                    rowData={filterAssessmentdata}
                                    style={{ border: "1px solid #f1f1f1", borderRadius: "5px", maxHeight: "70vh" }}
                                    handleAction={handleOpen}
                                    isView={true}
                                    disableAction={false}
                                    statusIdMapping={statusIdLabelMapping}
                                />
                            </Box>
                        </Box>
                    </Stack>
                </Box>
            ) : (<SingleAssessmentQuestionnaireForCustomer onClose={closeSection} Customerdata={filterAssessmentdata[statusIdLabelMapping]} />)}
        </div>
    )
};
export default COBReferHistorical;