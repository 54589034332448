import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState, createContext } from "react";
import { textFieldWidth } from "../../../utils/constants";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import DepartmentMasterUploadSheet, { departmentImportdata } from "./MasterUploadsheets/DepartmentMasterUploadSheet";
import { RootState, useAppDispatch } from "../../../store/store";
import { getLovList } from "../../../store/slices/lovMasterData";
import CustomFileUploader from "../../../common/dynamicFileUploader/CustomFileUploader";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import { addBulkUpload, resetBulkuploadResponseData } from "../../../store/slices/bulkuploadMaster";
import CurrencyMasterUploadSheet, { CurrencyImportdata } from "./MasterUploadsheets/CurrencyMasterUploadSheet";
import { useSelector } from "react-redux";
import DocumentMasterUploadSheet, { DocumentImportdata } from "./MasterUploadsheets/DocumentMasterUploadSheet";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import IndustoryMasterUploadSheet, { industoryImportdata } from "./MasterUploadsheets/IndustoryMasterUploadSheet";
import LocationMasterUploadSheet, { LocationImportdata } from "./MasterUploadsheets/LocationMasterUploadSheet";
import ThirdPartyMasterUploadSheet, {
  ThirdpartyImportdataImportdata,
} from "./MasterUploadsheets/ThirdPartyMasterUploadSheet";
import BranchMasterUploadSheet, { BranchImportdata } from "./MasterUploadsheets/BranchMasterUploadSheet";
import FilePreviewer from "../../../common/fileViewer/FilePreviewer";
import {
  AQMasterDataMaker,
  branchMasterDataMaker,
  currencyMasterDataMaker,
  departmentMasterDataMaker,
  documentMasterDataMaker,
  industoryMasterDataMaker,
  locationMasterDataMaker,
  thirdPartyMasterDataMaker,
} from "./Masteruploadoutputdatamaker";
import AQMasterUploadSheet, { AQMasterImportdata } from "./MasterUploadsheets/AQMMasterUploadSheet";

interface validationContextProps {
  uploaddata: any[];
  setselectedValidation: (value: any) => void;
}

export const BulkuploadValidationContext = createContext<validationContextProps | undefined>(undefined);
const CommonMasterBulkUpload = () => {
  const dispatch = useAppDispatch();
  const { menuData, userLoginResponse, user, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { isLoading, dataTablebulkupload, isBulkUploadAdded } = useSelector((state: RootState) => state.bulkuploadData);
  const [excelsheet, setvalidexcelsheet] = useState<any>(null);
  const [mastersList, setMastersList] = useState<any[]>([]);
  const [selectedmaster, setSelectedMaster] = useState<any>("");
  const [uploaddata, setUploaddata] = useState<any>([]);
  const [validataedData, setvalidataeddData] = useState<any>([]);
  const [uploadSucessCount, setUploadSucessCount] = useState<any>(0);
  const [validData, setValidData] = useState<any>([]);
  const [invalidExcelData, setInvalidExcelData] = useState<any>([]);
  const [validTableHeader, setvalidTableHeader] = useState<any>("");
  const { currentpageTitleData } = useSelector(
          (state: RootState) => state.FormTitleMasterData
        );

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
    setMastersList([
      { name: "Department", value: "department" },
      { name: "Currency", value: "currency" },
      { name: "Document", value: "document" },
      { name: "Industry", value: "industory" },
      { name: "Location", value: "location" },
      { name: "Third Party", value: "thirdparty" },
      { name: "Branch", value: "branch" },
      { name: "Assessment Questionnaire", value: "assessment_Questions" },
    ]);
  }, []);

  useEffect(() => {
    console.log(validataedData, "validataedData", validData);
  }, [validataedData]);

  useEffect(() => {
    dispatch(updateLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (isBulkUploadAdded) {
      let beforeUpload = [...validataedData];
      updateUploadStatus(beforeUpload);
 
    }
  }, [isBulkUploadAdded, dataTablebulkupload]);

  const updateUploadStatus = (data: any) => {
    console.log(data, "Valid before data");

    let validCount = 0;
    let isWaitingforapproval : boolean = false;
    let beforeUpload = [...data];
    beforeUpload.map((item: any) => {
      let MatchData = dataTablebulkupload.find((resItem: any) => resItem.id === item.id);
      if (MatchData) {
        item.message = MatchData.Message;
        if (MatchData.Message === "Added Successfully") {
          validCount++;
        }

        if (MatchData.Message === "Sent to Pending Approval") {
          isWaitingforapproval = true
          validCount++;
        }
      }
      return item;
    });
    setUploadSucessCount(validCount);
    console.log(beforeUpload, "datavalidated");
    setvalidataeddData(beforeUpload);
    dispatch(updateToaster({ isToaster: true, toasterMsg: isWaitingforapproval ? "Bulk Upload Process Completed and Sent to Pending Approval!"  : 'Bulk Upload Process Completed and data uploaded!' }));
    dispatch(resetBulkuploadResponseData(false));
  };




  const selectMasterDD = (value: any) => {
    setSelectedMaster(value);
    clearOldData();
  
    const importDataMap: { [key: string]: any[] } = {
      department: departmentImportdata,
      currency: CurrencyImportdata,
      document: DocumentImportdata,
      industory: industoryImportdata,
      location: LocationImportdata,
      thirdparty: ThirdpartyImportdataImportdata,
      branch: BranchImportdata,
      assessment_Questions: AQMasterImportdata,
    };
  
    const sampleHeader = importDataMap[value] ? [...importDataMap[value]] : [];
  
    if (sampleHeader.length > 0) {
      sampleHeader.push(
        { Field: "error", FieldType: "string", DisplayName: "Error Message" },
        { Field: "message", FieldType: "string", DisplayName: "Status" }
      );
    }
  
    const columns = {
      table_name: `${value}`,
      isAction: false,
      columns: sampleHeader,
    };
  
    setvalidTableHeader(columns);
  };
  

  const clearOldData = () => {
    setvalidataeddData([]);
    setValidData([]);
    setUploaddata([]);
    setInvalidExcelData([]);
    setUploadSucessCount(0);
    dispatch(resetBulkuploadResponseData(false));
  };

  const handleExportInvalidSheet = (jsonData: any) => {
    let columnHeader = validTableHeader.columns.slice(0, -3);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`${validTableHeader.table_name} Template`);

    // Define columns with headers and widths
    const columnMaker = columnHeader.map((header: { DisplayName: string }) => ({
      width: 20,
      header: header.DisplayName,
    }));
    worksheet.columns = columnMaker;

    jsonData.forEach((data: any) => {
      const row = columnMaker.map((col: any) => data[col.header] || "");
      worksheet.addRow(row);
    });

    // Export to Excel file
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        FileSaver.saveAs(blob, `${validTableHeader.table_name}_Invalid_data.xlsx`);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const setselectedValidation = (val: any) => {
    if (val) {
      let dataMessageAdded: any[] = val?.map((item: any, index: any) => {
        return {
          ...item,
          id: index + 1,
          message: !item.error || item.error == "" ? "Valid" : "Invalid",
        };
      });

      console.log(dataMessageAdded, "datavalidated");
      setvalidataeddData(dataMessageAdded);
      let findValidData: any[] = dataMessageAdded
        ? dataMessageAdded.filter((item: any) => {
            return !item.error || item.error == "";
          })
        : [];
      let findInValidData: any[] = dataMessageAdded
        ? dataMessageAdded.filter((item: any) => {
            return item.error;
          })
        : [];
      setInvalidExcelData(findInValidData);
      setValidData(findValidData);
    }
  };
  const ExcelToJson = async (data: any) => {
    const headers = data[1];

    if (headers) {
      const jsonData = [];

      for (let i = 2; i < data.length; i++) {
        const row = data[i];
        if (!row) {
          continue;
        }
        const obj: any = { id: i - 1 };
        for (let j = 1; j < headers?.length; j++) {
          const cellValue = row[j];
          if (cellValue && typeof cellValue === "object" && "text" in cellValue) {
            obj[headers[j]] = cellValue.text;
          } else {
            obj[headers[j]] = typeof cellValue === "boolean" ? cellValue.toString() : cellValue;
          }
        }

        jsonData.push(obj);
      }

      return jsonData;
    }
  };

  const handleFileUpload = async (e: any | null) => {
    clearOldData();
    setvalidexcelsheet(e);
    if (e !== null) {
      const file = e[0];
      const workbook = new ExcelJS.Workbook();

      await workbook.xlsx.load(file);

      const worksheet = workbook.worksheets[0];
      const data = worksheet.getSheetValues();

      let JsonSheetData: any = await ExcelToJson(data);
      console.log(JsonSheetData, "JsonSheetData");
      setUploaddata(JsonSheetData || []);
      JsonSheetData.map((item: any) => {
        console.log(item);
      });
    } else {
    }
  };



  const handleImportSubmit = (data: any) => {
    const userCreatedBy = userLoginResponse?.UserName;
    const userCreatedById = userLoginResponse?.UserId;
  
    const masterDataMakers: { [key: string]: Function } = {
      department: departmentMasterDataMaker,
      currency: currencyMasterDataMaker,
      document: documentMasterDataMaker,
      branch: branchMasterDataMaker,
      industory: industoryMasterDataMaker,
      thirdparty: thirdPartyMasterDataMaker,
      location: locationMasterDataMaker,
      assessment_Questions: AQMasterDataMaker,
    };
  
    // If `selectedmaster` matches a key in `masterDataMakers`
    const dataMaker = masterDataMakers[selectedmaster];
  
    if (dataMaker) {
      const formData = {
        data: dataMaker([...data], userCreatedBy, userCreatedById),
        section: selectedmaster,
      };
      dispatch(addBulkUpload(formData));
    }
  };
  
  return (
    <>
      <Box sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
        <Stack spacing={3}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              Bulk Upload
            </Typography>
          </Box>

          <Box sx={{ display: "flex"}}>
            <Grid container spacing={2}>
              <Grid
                item
                sx={{width:'250px'}}
                // xs={textFieldWidth.xs}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.xl}
                // lg={textFieldWidth.xl}
                // xl={textFieldWidth.xl}
              >
                <CustomSelect
                  name="Select Master"
                  valueKey={"value"}
                  optionLabelKey="name"
                  options={mastersList}
                  required={true}
                  label={"Select Master"}
                  value={selectedmaster}
                  onChange={(value) => {
                    handleFileUpload(null);
                    selectMasterDD(value);
                  }}
                  //   disabled={disableMainSelectValue()}
                  //   error={selectedmasterError}
                />
              </Grid>
              {/* </Grid>

            <Grid container spacing={2}> */}
              {selectedmaster ? (
                <Grid
                  item
                  sx={{textAlign:'left'}}
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <BulkuploadValidationContext.Provider value={{ uploaddata, setselectedValidation }}>
                    <>
                      {selectedmaster === "department" ? <DepartmentMasterUploadSheet /> : null}
                      {selectedmaster === "currency" ? <CurrencyMasterUploadSheet /> : null}
                      {selectedmaster === "document" ? <DocumentMasterUploadSheet /> : null}
                      {selectedmaster === "industory" ? <IndustoryMasterUploadSheet /> : null}
                      {selectedmaster === "location" ? <LocationMasterUploadSheet /> : null}
                      {selectedmaster === "thirdparty" ? <ThirdPartyMasterUploadSheet /> : null}
                      {selectedmaster === "branch" ? <BranchMasterUploadSheet /> : null}
                      {selectedmaster === "assessment_Questions" ? <AQMasterUploadSheet /> : null}
                    </>
                  </BulkuploadValidationContext.Provider>
                </Grid>
              ) : null}
            </Grid>
          </Box>

          {selectedmaster ? (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", mt: 10 }}>
              <Box sx={{ width: "50%" }}>
                {excelsheet == null ? (
                  <CustomFileUploader onFileUpload={handleFileUpload} maxFileSize={1048576} acceptFormats={[".xlsx"]} />
                ) : (
                  <FilePreviewer onRemovefile={() => handleFileUpload(null)} file={excelsheet} />
                )}

                <Box>
                  {/* <Typography textAlign={"left"} sx={{ color: "#D70000" }}>
                    {sheetError}
                  </Typography> */}
                </Box>
              </Box>
            </Box>
          ) : null}

          {validTableHeader && validataedData.length !== 0 && (
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  mt: 2,
                  border: "1px solid gray",
                  p: 1,
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  {validataedData && (
                    <Typography variant="subtitle1" color={"text.primary"}>
                      Import Data : {validataedData.length}
                    </Typography>
                  )}
                  {validData ? (
                    <Typography variant="subtitle1" color={"text.primary"}>
                      {" "}
                      | Valid Data : {validData.length}
                    </Typography>
                  ) : (
                    <Typography color={"text.primary"} variant="subtitle1">
                      {" "}
                      | Valid Data : 0
                    </Typography>
                  )}
                  <Box sx={{ display: "flex" }}>
                    {invalidExcelData ? (
                      <Typography
                        onClick={() => handleExportInvalidSheet(invalidExcelData)}
                        variant="subtitle1"
                        sx={{
                          color: "#D70000",
                          textDecoration: invalidExcelData.length > 0 ? "underline" : "none",
                          cursor: invalidExcelData.length > 0 ? "pointer" : "default",
                        }}
                      >
                        {" "}
                        | Invalid Data : {invalidExcelData.length}
                      </Typography>
                    ) : (
                      <Typography variant="subtitle1" color={"text.primary"}>
                        {" "}
                        | Invalid Data : 0
                      </Typography>
                    )}
                  </Box>
                </Box>
                {isBulkUploadAdded ? (
                  <Box>
                    {" "}
                    <Typography variant="subtitle1" sx={{ color: "#129507" }}>
                      Sucessfully Uploaded Count : {uploadSucessCount}
                    </Typography>{" "}
                  </Box>
                ) : null}
              </Box>
            </Box>
          )}
          {validTableHeader && selectedmaster && (
            <Box sx={{ width: "100%" }}>
              <MaterialDynamicGrid
                data={validTableHeader}
                rows={validataedData}
                enableZoomIcon={true}
                showhistory={false}
                showDelete={false}
                showView={false}
                showEdit={false}
                showExports={false}
                userId={1}
                pageName="Bulk Upload"
                disableselect={true}
              />
            </Box>
          )}
          {selectedmaster ? (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", mt: 2, gap: 2 }}>
              <CustomButton variant="outlined" name={"Reset"} onClick={() => clearOldData()} />
              <CustomButton
                disabled={!validData || validData?.length <= 0}
                name={"Submit Valid Data"}
                onClick={() => handleImportSubmit(validData)}
              />
            </Box>
          ) : null}
        </Stack>
      </Box>
    </>
  );
};
export default CommonMasterBulkUpload;
