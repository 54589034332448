import { Box, Stack, Typography, IconButton, Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableContainer,
  MRT_TableOptions,
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/HighlightOff";
import { Controller, useForm } from "react-hook-form";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { textFieldWidth } from "../../../utils/constants";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";

interface SigningAuthorityProps {
  authority: string;
  tableData: any;
  view: boolean;
  handleDataChange?: (data: any, type: string) => void;
  key?: string;
}

const SigningAuthority: React.FC<SigningAuthorityProps> = ({ authority, tableData, view, handleDataChange, key }) => {
  const [data, setData] = useState<any[]>(tableData);
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const handleDeleteClause = (row: MRT_Row<any>) => {
    const filteredData = data.filter((_, index) => index !== row.index).map((e, i) => ({ ...e, order: i + 1 }));
    // setData(filteredData);
    if (handleDataChange) {
      handleDataChange(filteredData, authority);
    }
  };

  const validateRequired = (value: string) => !!value.length;
  const validateEmail = (email: string) =>
    !!email.length &&
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  function validateUser(user: any) {
    return {
      name: !validateRequired(user.name) ? "Name is Required" : "",
      designation: !validateRequired(user.designation) ? "Desingation is Required" : "",
      email: !validateEmail(user.email) ? "Incorrect Email Format" : "",
    };
  }

  const handleCreateUser: MRT_TableOptions<any>["onCreatingRowSave"] = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    const updatedData = [{ ...values, order: data.length + 1 }, ...data];
    // setData(updatedData);
    table.setCreatingRow(null);
    if (handleDataChange) {
      handleDataChange(updatedData, authority);
    }
  };

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    trigger,
    clearErrors,
    setError,
    watch,
    formState: { errors: errorsloginform, isDirty },
  } = useForm();

  const col = [
    {
      accessorKey: "name",
      header: "NAME",
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors?.name,
        helperText: validationErrors?.name,
        onFocus: () =>
          setValidationErrors({
            ...validationErrors,
            name: undefined,
          }),
      },
    },
    {
      accessorKey: "designation",
      header: "DESIGNATION",
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors?.designation,
        helperText: validationErrors?.designation,
        onFocus: () =>
          setValidationErrors({
            ...validationErrors,
            designation: undefined,
          }),
      },
    },
    {
      accessorKey: "email",
      header: "EMAIL ID",
      muiEditTextFieldProps: {
        type: "email",
        required: true,
        error: !!validationErrors.email,
        helperText: validationErrors.email,
        onFocus: () =>
          setValidationErrors({
            ...validationErrors,
            email: undefined,
          }),
      },
    },
  ];

  const table = useMaterialReactTable({
    // autoResetPageIndex: false,
    columns: col,
    data,
    createDisplayMode: "row",
    // enableRowOrdering: !view,
    enableSorting: false,
    enableRowNumbers: true,
    // enableEditing: true,
    enableRowActions: !view,
    enableBottomToolbar: false,
    enableColumnActions: false,
    positionActionsColumn: "last",
    enablePagination: false,
    getRowId: (row) => row.order,
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    icons: {
      DragHandleIcon: () => <DragIndicatorIcon fontSize="small" />,
      SaveIcon: () => <SaveIcon fontSize="small" color="primary" />,
      CancelIcon: () => <CancelIcon fontSize="small" color="primary" />,
    },
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data.splice((hoveredRow as MRT_Row<any>).index, 0, data.splice(draggingRow.index, 1)[0]);
          setData(data.map((e, i) => ({ ...e, order: i + 1 })));
        }
      },
    }),
    renderTopToolbar: ({ table }) => (
      <Box width="100%" textAlign="right" mb={2}>
        <CustomButton
          name="Add New"
          variant="outlined"
          onClick={() => setShowAdd(true)}
          startIcon={<AddIcon sx={{ cursor: "pointer" }} />}
        />
      </Box>
    ),
    muiTableBodyCellProps: {
      align: "center",
    },
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableContainerProps: {
      sx: {
        borderRadius: "5px",
        border: "1px solid #0000001f",
      },
    },
    localization: {
      move: "",
      rowNumber: "S.NO.",
      actions: "ACTION",
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton onClick={() => handleDeleteClause(row)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Box>
    ),
    muiTablePaperProps: ({ table }) => ({
      style: {
        border: "none",
        boxShadow: "none",
      },
    }),
  });

  // const handleAddUser = () => {
  //   if (showAdd) {
  //     return;
  //   }
  //   setShowAdd(true);
  // };

  const handleAddRecord = (record: any) => {
    // setData([...data, record]);
    if (handleDataChange) {
      handleDataChange([...tableData, record], authority);
    }
    reset();
    setShowAdd(false);
  };

  const validateMobileNumber = (value: string) => {
    const mobileRegex: any = new RegExp("^([0|+[0-9]{1,5})?([7-9][0-9]{9})$");
    return mobileRegex.test(value) || "Invalid Mobile Number";
  };

  return (
    <Box mb={4} key={authority}>
      <Stack direction="row" justifyContent="space-between">
        <Typography align="left" textTransform="uppercase" fontWeight="bold" sx={{ color: "#6a6a6a" }}>
          {authority} Signing Authority
        </Typography>
      </Stack>
      {data.length > 0 && !showAdd ? (
        <MaterialReactTable table={table} />
      ) : showAdd ? (
        <Box textAlign="right">
          <Grid container spacing={2} mt={2}>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
            >
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "Name is required",
                }}
                render={({ field }) => (
                  <CustomTextField
                    name={"name"}
                    required={true}
                    readOnly={view}
                    label={"Name"}
                    value={field.value}
                    onChange={field.onChange}
                    error={Boolean(errorsloginform.name)}
                    helperText={errorsloginform.name && errorsloginform.name.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
            >
              <Controller
                name="designation"
                control={control}
                rules={{
                  required: "Designation is Required",
                }}
                render={({ field }) => (
                  <CustomTextField
                    name={"designation"}
                    required={true}
                    readOnly={view}
                    label={"Designation"}
                    value={field.value}
                    onChange={field.onChange}
                    error={Boolean(errorsloginform.designation)}
                    helperText={errorsloginform.designation && errorsloginform.designation.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
            >
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email ID is Required",
                  validate: (value) => {
                    return validateEmail(value) || "Email ID is not valid";
                  },
                }}
                render={({ field }) => (
                  <CustomTextField
                    type="email"
                    name={"email"}
                    required={true}
                    readOnly={view}
                    label={"Email ID"}
                    value={field.value}
                    onChange={field.onChange}
                    error={Boolean(errorsloginform.email)}
                    helperText={errorsloginform.email && errorsloginform.email.message?.toString()}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
            >
              <Controller
                name="mobileNo"
                control={control}
                rules={{
                  required: "Mobile No is required",
                  validate: validateMobileNumber,
                }}
                render={({ field }) => (
                  <CustomTextField
                    type="number"
                    name={"mobileNo"}
                    required={true}
                    readOnly={view}
                    label={"Mobile Number"}
                    value={field.value}
                    onChange={field.onChange}
                    error={Boolean(errorsloginform.mobileNo)}
                    helperText={errorsloginform.mobileNo && errorsloginform.mobileNo.message?.toString()}
                  />
                )}
              />
            </Grid>
            {/* <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
            >
              <Controller
                name="status"
                control={control}
                rules={{
                  required: "Status is Required",
                }}
                render={({ field }) => (
                  <CustomSelect
                    name={"status"}
                    valueKey={"id"}
                    optionLabelKey="status"
                    options={[{ id: 1, status: "Approved" }]}
                    required={true}
                    readOnly={view}
                    label={"Status"}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    error={Boolean(errorsloginform.ContractType)}
                    helperText={errorsloginform.ContractType && errorsloginform.ContractType.message?.toString()}
                  />
                )}
              />
            </Grid> */}
          </Grid>
          <CustomButton name="Add" variant="contained" onClick={handleSubmit(handleAddRecord)} />
        </Box>
      ) : (
        <Box mt={2} textAlign="left">
          <CustomButton
            name="Add New"
            variant="outlined"
            onClick={() => setShowAdd(true)}
            startIcon={<AddIcon sx={{ cursor: "pointer" }} />}
          />
        </Box>
      )}
    </Box>
  );
};

export default SigningAuthority;
