import { Box, Dialog, DialogContent, Stack, Typography, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useAppDispatchThunk } from "../../store/store";
import { addRole, deleteRole, getALLMenus, getRoleHistory, getRolesById, getRolesList, updateRoleAddandEdit, updateVersionHistory } from "../../store/slices/roleData";
import Close from "@mui/icons-material/Close";
import CustomButton from "../../common/dynamicButton/CustomButton";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { IRole, IRoleFormData } from "../../models/roles.type";
import AddRole from "./AddRole";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { IMenu } from "../../models/loggin.type";
import { updateToaster } from "../../store/slices/loader";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";
import masterEditEnableService from "../../store/services/masterEditEnableService";
import { Edit_Enable_tosterMSG, FormIdConfig, ToasterActivityConfig } from "../../config/config";
import { addMenu } from "../../utils/constants";
import { RecordLockRelease } from "../recordLock/CommonRecordLock";

const RolesManagement = () => {
  const columns: any = {
    table_name: "Role",
    isAction: true,
    columns: [ {
      Field: "RoleName",
      DisplayName: "Role Name",
      FieldType: "string",
    },
    {
      Field: "Description",
      DisplayName: "Description",
      FieldType: "string",
    },
    {
      Field: "IsActive",
      DisplayName: "Status",
      FieldType: "string",
    },],
  };
    const { handleRecordLockUpdate } = RecordLockRelease();
  const [isEdit, setIsEdit] = useState(false);
  const [addForm, setAddForm] = useState(false);
  //const [formDisplay, setformDisplay] = useState(false);
  const [Option, setOption] = useState("");
  const [isEditRoleData, setisEditRoleData] = useState<IRole | null>(null);
  const dispatch = useAppDispatchThunk();
  const [HeaderColumn, setHeaderColumn] = useState(columns);
  const [editId, setEditId] = useState<any>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);

  //const [errorMsg, setErrorMsg] = useState(false);

  const { allRoles, isRoleAdded, isRoleUpdated, isRoleDeleted, ToasterMsg, errormessage, roleVersionHistory, allMenuData } = useSelector(
    (state: RootState) => state.roleData
  );

  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);

  const [rolesData, setRolesData] = useState<IRole[]>([]);

  const [roleAdded, setRoleAdded] = useState(false);
  const [roleUpdated, setRoleUpdated] = useState(false);
  const [roleDeleted, setRoleDeleted] = useState(false);
  const [roleId, setRoleId] = useState<number>();
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [roleDeletedFail, setRoleDeletedFail] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState<IRole | null | any>(null);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [menuScreensData, setMenuScreens] = useState([]);
  const { currentpageTitleData } = useSelector(
    (state: RootState) => state.FormTitleMasterData
  );

  const columns_version: any = {
    table_name: "Role History Records",
    isAction: false,
    columns: [],
  };



  useEffect(() => {
    if (errormessage !== "") {
      setRoleDeletedFail(true);
      setDeletePopUp(false);
    }
  }, [errormessage]);
  useEffect(() => {
    dispatch(
      getRolesList({
        formId: FormIdConfig.roleMasterForm,
      })
    );
    dispatch(getALLMenus(null));
  }, []);

  useEffect(() => {
    if (allRoles.roles.length > 0) {
      setRolesData(allRoles.roles);
    }

    if (allRoles.headers && allRoles.headers.length > 0) {
      let re_columns = {
        ...columns,
        columns: allRoles.headers,
      };
      setHeaderColumn(re_columns);
    }
  }, [allRoles]);

  useEffect(() => {
    if (isRoleAdded) {
      //setRoleAdded(true);
      dispatch(updateRoleAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      dispatch(
        getRolesList({
          formId: FormIdConfig.roleMasterForm,
        })
      );
    } else if (isRoleUpdated) {
      //setRoleUpdated(true);
      dispatch(updateRoleAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));

      dispatch(
        getRolesList({
          formId: FormIdConfig.roleMasterForm,
        })
      );
    } else if (isRoleDeleted) {
      // setRoleDeleted(true);
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      setDeletePopUp(false);
      dispatch(updateRoleAddandEdit(false));
      dispatch(
        getRolesList({
          formId: FormIdConfig.roleMasterForm,
        })
      );
    }
  }, [isRoleAdded, isRoleUpdated, isRoleDeleted]);

  const selectAllEnable = (item: any): number => {
    let { IsAdd, IsEdit, IsDelete, IsView, IsExport, IsImport, IsApproval, ...rest } = item;
    return IsAdd && IsEdit && IsDelete && IsView && IsExport && IsApproval ? 1 : 0;
  };

  const mapSubMenus = (item: any, role: IMenu | undefined) => {
    if (item.SubMenus && item.SubMenus.length > 0) {
      let copyOfItem = { ...item };
      copyOfItem.SubMenus = copyOfItem.SubMenus.map((item: any) => {
        const res = role?.SubMenus?.filter((data) => data.MenuId === item.MenuId)?.[0]
        let mappedItem = mapSubMenus(item, res);
        return { ...mappedItem, disabled: res?.disabled };
      });
      item = copyOfItem;
    } else {
      item = { ...item, ...addMenu, Select: 0 };
    }
    return { ...item };
  };
  
  const mapMenuBasedOnPermission = (userBasedMenu: any, pageMenuData: any, selectAll: number) => {
    let pageMenu = { ...pageMenuData };
    if (pageMenu.SubMenus && pageMenu.SubMenus.length > 0) {
      let subMenuSelectALL = selectAll;
      pageMenu.SubMenus.map((pageSubMenuitem: any, index: number) => {
        if (userBasedMenu.SubMenus.length > 0) {
          let userBasedMenuItem = userBasedMenu.SubMenus.filter(
            (userBasedMenusubItem: any) => userBasedMenusubItem.MenuId === pageSubMenuitem.MenuId
          );
          if (userBasedMenuItem.length > 0) {
            let mappedItem = mapMenuBasedOnPermission(userBasedMenuItem[0], pageSubMenuitem, selectAll);
            pageMenu.SubMenus[index] = mappedItem;
            if (subMenuSelectALL) {
              subMenuSelectALL = mappedItem.Select;
            }
          } else {
            subMenuSelectALL = 0;
            pageMenu.SubMenus[index] = { ...pageSubMenuitem };
          }
        } else {
          subMenuSelectALL = 0;
          pageMenu.SubMenus[index] = { ...pageSubMenuitem };
        }
      });
      return { ...pageMenu, Select: subMenuSelectALL };
    } 
    else {
      let selectAllValue = selectAllEnable({ ...pageMenu, ...userBasedMenu });
      return { ...pageMenu, ...userBasedMenu, Select: selectAllValue };
    }
  };

  const getJsonDataForDisplay =  () => {

    if (allMenuData.length > 0) {
      let copyoFMenuData: any = JSON.parse(JSON.stringify(allMenuData));
      const roleData:any =  isDeleteData
      let manuplatemenuScreen = copyoFMenuData.map((item: any) => {
        const filterRole = roleData &&
          roleData.Menus &&
          roleData.Menus.length > 0 &&
          roleData.Menus.filter((data:any) => data.MenuId === item.MenuId)?.[0] || undefined
        item = mapSubMenus({ ...item }, filterRole);
        return { ...item, disabled: filterRole && filterRole?.disabled };
      });
      let selectAll = false;
      if (true) {
        selectAll = true;
        manuplatemenuScreen = manuplatemenuScreen.map((mappedMenu: any) => {
          const filterRole = roleData &&
            roleData.Menus &&
            roleData.Menus.length > 0 &&
            roleData.Menus.filter((data:any) => data.MenuId === mappedMenu.MenuId)
          mappedMenu = filterRole && filterRole?.length > 0
            ? mapMenuBasedOnPermission(
              filterRole[0],
              mappedMenu,
              1
            )
            : mappedMenu;

          if (selectAll && mappedMenu.Select) {
            selectAll = true;
          } else {
            selectAll = false;
          }
          return { ...mappedMenu, disabled: filterRole && filterRole[0]?.disabled };
        });
      }
      setMenuScreens(manuplatemenuScreen);
    }
  }

  useEffect(() => {
    if (isDeleteData ) {
      getJsonDataForDisplay()
    }
  }, [isDeleteData]);
  
  const deleteRoleId = () => {
    let JsonDataForDisplay: any = {
      "RoleName": isDeleteData.RoleName,   
      "Description": isDeleteData.Description,
      "Status": isDeleteData.IsActive  === "Active" ? "Inactive" : "Active",
      "Modified By": user?.UserName,
      "PageAccessControl": menuScreensData
    }

    dispatch(
      addRole({
        Form_Id : FormIdConfig.roleMasterForm,
        UserId : userLoginResponse?.UserId,
        IsActive :  isDeleteData.IsActive === "Active" ? false : true,
        Activity: isDeleteData.IsActive === "Active" ? ToasterActivityConfig?.inactive : ToasterActivityConfig?.active,
        CreatedBy : userLoginResponse?.UserName,
        UpdatedBy : userLoginResponse?.UserId,
        ModuleName : currentPagePermissionData?.MenuName,
        DataForDisplay : JSON.stringify(JsonDataForDisplay),
        RoleId : isDeleteData.RoleId,
        ApprovedBy : "" ,
        ApprovedOn : new Date(),
        Remarks : "" ,
        Updated_On : new Date() ,
        RoleName: isDeleteData.RoleName,
        Description: isDeleteData.Description,
        PageAccessControl : [],
      
      })
    );
    setDeletePopUp(false);
  };


  useEffect(() => {
    if (!isEdit) {
      handleRecordLockUpdate()
    }
  }, [isEdit]);


  useEffect(() => {
    if (roleVersionHistory.roleVHeader.length > 0 && roleVersionHistory.roleVHeader.length > 0) {
      if (roleVersionHistory.roleVHeader && roleVersionHistory.roleVHeader.length > 0) {
        setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: roleVersionHistory.roleVHeader,
        };
        setVersionHistoryData(re_columns);
        console.log(roleVersionHistory, "versionHistory ");
      }
    }
  }, [roleVersionHistory]);

  const handleOpen = async (data: IRole, option: string) => {
    if (option === "delete") {
   
        setRoleId(data.RoleId);
        setIsDeleteData(data);
        setDeletePopUp(true);
     
    } else if (option === "edit") {           
      setisEditRoleData(data);
      setOption(option);
      setIsEdit(true);
   
    } else if (option === "history") {
      setOption(option);
      dispatch(
        getRoleHistory({
          roleId: data.RoleId,
          formId: FormIdConfig.roleMasterForm,
        })
      );

    } else if (option === "view") {
      setisEditRoleData(data);
      setOption(option);
      setIsEdit(true);
    }
  };

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
               <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                            {/* Holiday Master */}
                        </Typography>
          </Box>

          {/* {permssionPage?.IsAdd ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CustomButton variant="contained" name={"Add Role"} onClick={() => setAddForm(true)} />
              </Grid>
            </Box>
          ) : null} */}

          <Box>
            <>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {/* <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        sx={{ marginBottom: 1, padding: "10px 20px" }}
                        variant="contained"
                        onClick={() => setAddForm(true)}
                      >
                        ADD Vendor
                      </Button>
                    </Grid> */}
                {/* <DevExpDynamicGrid
                  data={columns}
                  rows={usersData}
                  handleAction={handleOpen}
                  enableZoomIcon={true}
                  uniqueIdKey="RoleId"
                  hideVersionhistory={true}
                /> */}
                <MaterialDynamicGrid
                  data={columns}
                  rows={rolesData}
                  handleAction={handleOpen}
                  enableZoomIcon={true}
                  handleAdd={() => setAddForm(true)}
                  showAdd={true}
                  showDelete={permssionPage?.IsDelete === 1}
                  showhistory={true}
                  showView={permssionPage?.IsView === 1 ? true : false}
                  showEdit={permssionPage?.IsEdit === 1 ? true : false}
                  showExports={permssionPage?.IsExport === 1 ? true : false}
                  pageName="Role"
                  userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                    isEnableRecordLock = { { IdField : "RoleId" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                                      FormID : FormIdConfig.roleMasterForm ,
                                                      MenuID : currentPagePermissionData?.MenuId,
                                                      IsCheckPending : true  }} }
                />
              </Box>
            </>
          </Box>
        </Stack>
      </Box>
      {/* <Dialog
        open={addForm}
        onClose={() => setAddForm(false)}
        maxWidth={"xl"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>          
          <Box
            sx={{
              width: "80vw",
            }}
          >
            <AddRole
              view={false}
              closePopUp={() => {
                setAddForm(false);
              }}
            />
          </Box>
        </DialogContent>
      </Dialog> */}

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Role"}`}
        contentNode={
          <AddRole
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
          />
        }
      />

      {/* <Dialog
        open={isEdit}
        onClose={() => {
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          setIsEdit(false);
        }}
        maxWidth={"xl"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>          
          <Box
            sx={{
              width: "80vw",
            }}
          >
            {Option === "edit" && (
              <AddRole
                closePopUp={() => {
                  setIsEdit(false);
                }}
                edit={isEdit}
                roleData={
                  isEditRoleData &&
                  isEditRoleData !== undefined &&
                  isEditRoleData !== null
                    ? isEditRoleData
                    : null
                }
              />
            )}
            {Option === "view" && (
              <AddRole
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                roleData={
                  isEditRoleData &&
                  isEditRoleData !== undefined &&
                  isEditRoleData !== null
                    ? isEditRoleData
                    : null
                }
              />
            )}
          </Box>
        </DialogContent>
      </Dialog> */}

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
          dispatch(updateVersionHistory([]));
        }}
        maxWidth={"lg"}
        header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Role Master"} Version History`}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={roleVersionHistory.roleRecords}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Role History"
            />
          </>
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          setIsEdit(false);
        }}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={Option === "view" ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Role"}` : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Role"}`}
        contentNode={
          <>
            {Option === "edit" && (
              <AddRole
                closePopUp={() => {
                  setIsEdit(false);
                }}
                edit={isEdit}
                roleData={
                  isEditRoleData && isEditRoleData !== undefined && isEditRoleData !== null ? isEditRoleData : null
                }
              />
            )}
            {Option === "view" && (
              <AddRole
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                roleData={
                  isEditRoleData && isEditRoleData !== undefined && isEditRoleData !== null ? isEditRoleData : null
                }
              />
            )}
          </>
        }
      />
      <React.Fragment>
        <ModalPopUp
          header="Role Added"
          text="Role Created SuccessFully"
          onHide={() => {
            setRoleAdded(false);
            dispatch(updateRoleAddandEdit(false));
          }}
          show={roleAdded}
        />

        <ModalPopUp
          text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
            } the selected Role?`}
          ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
          header="Confirm"
          isDelete={deletePopUp}
          onHide={() => {
            setDeletePopUp(false);
          }}
          onDeleteMethod={() => {
            deleteRoleId();
          }}
          show={deletePopUp}
        />

        <ModalPopUp
          header="Role Updated"
          text="Role Updated SuccessFully"
          onHide={() => {
            setRoleUpdated(false);
            dispatch(updateRoleAddandEdit(false));
          }}
          show={roleUpdated}
        />

        <ModalPopUp
          header="Role Deleted"
          text="Role Deleted SuccessFully"
          onHide={() => {
            setRoleDeleted(false);
            dispatch(updateRoleAddandEdit(false));
          }}
          show={roleDeleted}
        />
        <ModalPopUp
          header="info"
          text={errormessage}
          onHide={() => {
            setRoleDeletedFail(false);
            dispatch(updateRoleAddandEdit(false));
          }}
          show={roleDeletedFail}
        />
      </React.Fragment>
    </>
  );
};

export default RolesManagement;
