import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IMenu } from "../../../models/loggin.type";
import { RootState, useAppDispatch } from "../../../store/store";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { getLovList } from "../../../store/slices/lovMasterData";
import { getContractViewWithdaterange, getPerfomenceScopeDetails } from "../../../store/contractManagement/slices/PMContractListSlice";
import { ScopeDefinition } from "../scopedefinition/ScopeDefinition";
import ScopeDefineService from "../../../store/performanceManagement/services/scopeDefine";
import { LovConfig } from "../../../config/config";


export default function PrScopeView() {
    const dispatch = useAppDispatch();
    const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
    const [addForm, setAddForm] = useState(false);
    const [Option, setOption] = useState("");
    const [EnddateError, setEnddateError] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [IsEditData, setIsEditData] = useState<any>({});
    const [ScopeData, setScopeData] = useState<any>({});
    const [ContractViewData, setContractViewData] = useState<any[]>([]);
    const [StartDate, setStartDate] = useState(null);
    const [EndDate, setEndDate] = useState(null);
    const [selectedValue, setselectedValue] = useState(null);
    const [isDeleteData, setIsDeleteData] = useState<any>(null);
    const [OpenModalServerFilter, setOpenModalServerFilter] = React.useState(false);
    const { lovTable } = useSelector((state: RootState) => state.lovMasterData);

    const { currentpageTitleData } = useSelector(
        (state: RootState) => state.FormTitleMasterData
    );
    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );

    const { contractViewDetailsWithDateRange, contractViewDetails, PerformaceScopeDetails, ToasterMsg } = useSelector(
        (state: any) => state.contractManagementReducer?.PMContractListSlice
    );
    const PRStatus = lovTable.filter((item: any) => item.Name === LovConfig.PR_scope_Status) || [];

    const { handleRecordLockUpdate } = RecordLockRelease();

    const allOption = {
        LovId: 0,
        Options: "All",
    };


    const columns: any = {
        table_name: "PR Scope View",
        isAction: true,
        columns: [

            {
                Field: "contractRefNumber",
                DisplayName: "Contract Number",
                FieldType: "string",
            },
            {
                Field: "pM_Ref_Number",
                DisplayName: "PR Ref Number",
                FieldType: "string",
            },
            {
                Field: "contractTypeName",
                DisplayName: "Type",
                FieldType: "string",
            },
            {
                Field: "companyName",
                DisplayName: "Company",
                FieldType: "string",
            },
            {
                Field: "vendorName",
                DisplayName: "Vendor",
                FieldType: "string",
            },
            {
                Field: "service_Name",
                DisplayName: "Service",
                FieldType: "string",
            },
            // {
            //     Field: "status",
            //     DisplayName: "Status",
            //     FieldType: "string",
            // },
            {
                Field: "effective_from",
                DisplayName: "Effective From",
                FieldType: "date",
            },
            {
                Field: "Requirement",
                DisplayName: "No of Requirement",
                FieldType: "string",
            },
            {
                Field: "clauseCount",
                DisplayName: "No of SLA/Clauses",
                FieldType: "string",
            },
            // {
            //     Field: "Outliers",
            //     DisplayName: "No of Outliers",
            //     FieldType: "string",
            // },
            {
                Field: "prStatus",
                DisplayName: "Approval Status",
                FieldType: "string",
            },
            {
                Field: "Remark",
                DisplayName: "Remark",
                FieldType: "string",
            },
        ],
    };

    useEffect(() => {
        dispatch(getPerfomenceScopeDetails({}));
        dispatch(getLovList({ Name: "" }));

    }, []);
    useEffect(() => {
    }, []);

    console.log(PerformaceScopeDetails, "PerformaceScopeDetails");


    useEffect(() => {
        if (currentPagePermissionData !== null) {
            setPermissionForpage(currentPagePermissionData);

        }
    }, [currentPagePermissionData]);

    const getDcopeDetails = async (id: any) => {

        try {
            let val = await ScopeDefineService?.getScopeById({ id: id });
            return val
        } catch {

            return ""
        }


    }

    const handleOpen = async (data: any, Option: string) => {

        if (Option === "edit") {
            let val = await getDcopeDetails(data?.prRef_Id);
            if (val) {
                setOption(Option);
                setIsEditData(data);
                setScopeData(val);
                setIsEdit(true);
            }


        } else if (Option === "view") {
            let val = await getDcopeDetails(data?.prRef_Id);
            if(val){
            setOption(Option);
            setIsEditData(data);
            setScopeData(val);
            setIsEdit(true);
            }
        }
    };
    const handleChange = (event: any) => {
        console.log(event, "event");

        setselectedValue(event);
    };

    const handleStartDateChange = (e: any) => {
        setStartDate(e);
        if (contractViewDetails && !e) {
            setContractViewData(contractViewDetails)
        }
    };

    const handleEndDateChange = (e: any) => {
        setEndDate(e);
        if (contractViewDetails && !e) {
            setContractViewData(contractViewDetails)
        }
    };

    const handleServerFilter = () => {
        // if (!StartDate && !EndDate) {
        //     setOpenModalServerFilter(true);
        // } else {
        dispatch(
            getPerfomenceScopeDetails({
                status: selectedValue === 0 ? null : selectedValue,
                contract_Start_Date: StartDate ? dayjs(StartDate).toISOString() : null,
                contract_End_Date: EndDate ? dayjs(EndDate).toISOString() : null,
            })
        );

    };

    useEffect(() => {
        if (contractViewDetails) {
            setContractViewData(contractViewDetails)
        }
        if (contractViewDetailsWithDateRange?.length > 0) {
            setContractViewData(contractViewDetailsWithDateRange)
        }

    }, [contractViewDetails, contractViewDetailsWithDateRange]);

    const closeDefineScope = () => {
        setOption("");
        setIsEditData({});
        setIsEdit(false);
    }

    return (
        <>
            {!isEdit ? <Box
                sx={{
                    width: "100%",

                }}
            >
                <Stack spacing={2}>
                    <Box
                        sx={{
                            display: "flex",
                            // flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {/* {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName} */}
                            PR Scope View
                        </Typography>

                        <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Grid
                            sx={{
                                display: "flex",
                                gap: "20px",
                                justifyContent: "left",
                                alignItems: "center",
                            }}
                        >
                            <Box sx={{ display: "flex", width: "250px" }}>
                                <CustomSelect
                                    name={"listName"}
                                    valueKey={"LovId"}
                                    addField={false}
                                    showAll={allOption}
                                    showAllOption={true}
                                    optionLabelKey="Options"
                                    options={PRStatus}
                                    value={selectedValue}
                                    onChange={handleChange}
                                    disabled={false}
                                    required={true}
                                    label={"Approval Status"}
                                />
                            </Box>

                            <Box sx={{ display: "flex", width: "250px" }}>

                                <CustomDatePicker
                                    minDate={dayjs().subtract(1, "month").toDate()}
                                    maxDate={dayjs().toDate()}
                                    required={true}
                                    label={"Start Date"}
                                    value={dayjs(StartDate)}
                                    readOnly={false}
                                    onChange={handleStartDateChange}
                                    error={false}
                                    helperText={""}
                                />
                            </Box>

                            <Box sx={{ display: "flex", width: "250px" }}>

                                <CustomDatePicker
                                    minDate={dayjs().subtract(1, "month").toDate()}
                                    maxDate={dayjs().toDate()}
                                    required={true}
                                    label={"End Date"}
                                    value={dayjs(EndDate)}
                                    readOnly={false}
                                    onChange={(e) => {
                                        handleEndDateChange(e);
                                        // if (e && StartDate) {
                                        //     setEnddateError("");
                                        // } else {
                                        //     setEnddateError("End Date is required");
                                        // }
                                    }}
                                    error={false}
                                    helperText={""}
                                />
                            </Box>
                            <Box sx={{ display: "flex" }}>
                                <CustomButton variant="contained" name={"Submit"} onClick={() => handleServerFilter()} size="small" />
                            </Box>

                        </Grid>
                    </Box>

                    <Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <MaterialDynamicGrid
                                data={columns}
                                rows={PerformaceScopeDetails}
                                handleAction={handleOpen}
                                enableZoomIcon={true}
                                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                                handleAdd={() => setAddForm(true)}
                                showAdd={false}
                                showhistory={false}
                                showDelete={false}
                                showView={permssionPage?.IsView === 1}
                                showEdit={true}
                                showNext={false}
                                showExports={permssionPage?.IsExport === 1}
                                pageName="PR Scope View"
                                isEditDisableByValue={[{key :"prStatus" , value : "Rejected"}]}
                                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                            />
                        </Box>
                    </Box>
                </Stack>
            </Box> :
                <ScopeDefinition isEditData={ScopeData} contarctDetails={IsEditData} prePageName={"Contract View"} view={Option == "view"} edit={Option == "edit"} add={Option == "add"} closePopUp={closeDefineScope} />}



            <ModalPopUp
                header="PR Scope View"
                text="Please Select Start Date and End date "
                onHide={() => {
                    setOpenModalServerFilter(false);
                }}
                show={OpenModalServerFilter}
            />
        </>
    );
}
