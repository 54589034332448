import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { updateToaster } from "../../store/slices/loader";
import masterEditEnableService from "../../store/services/masterEditEnableService";
import { RootState, useAppDispatch } from "../../store/store";
import { AddScreenTitleMaster } from "./AddScreenTitle";
import { deleteFormTitle, getFormTitleHistory, getFormTitleList, updateFormTitleAddandEdit } from "../../store/slices/formTitleMasterData";
import { RecordLockRelease } from "../recordLock/CommonRecordLock";


export default function ScreenTitleMaster() {
    const dispatch = useAppDispatch();
    const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
    const [permssionPage, setPermissionForpage] = useState<any | null>(null);
    const [addForm, setAddForm] = useState(false);
    const [Option, setOption] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [IsEditData, setIsEditData] = useState<any[]>([]);
    const [HolidayTableData, setHolidayTableData] = useState<any[]>([]);
    const [isVersionHistory, setIsVersionHistory] = useState(false);
    const [StartDate, setStartDate] = useState(null);
    const [EndDate, setEndDate] = useState(null);
    const [versionHistoryData, setVersionHistoryData] = useState<any>();
    const [isDeleteData, setIsDeleteData] = useState<any>(null);
    const [OpenModalServerFilter, setOpenModalServerFilter] = React.useState(false);
  const { handleRecordLockUpdate } = RecordLockRelease();


    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );
    const { FormTitleTable, isFormTitleAddandEdit, isFormTitleDeleted, versionHistory, ToasterMsg } = useSelector(
        (state: RootState) => state.FormTitleMasterData
    );

    const columns_version: any = {
        table_name: "Form Title History Records",
        isAction: false,
        columns: [{
            Field: "FormName",
            DisplayName: "Form Name",
            FieldType: "string",
        },
        {
            Field: "ViewTitle",
            DisplayName: "View Title",
            FieldType: "string",
        },
        {
            Field: "ActionTitle",
            DisplayName: "Action Title",
            FieldType: "string",
        },
        {
            Field: "IsActive",
            DisplayName: "Status",
            FieldType: "string",
        },
        {
            Field: "UpdatedBy",
            DisplayName: "Modified By",
            FieldType: "string",
        },
        {
            Field: "UpdatedOn",
            DisplayName: "Modified On",
            FieldType: "date",
        },
        
    ],
    };


    const columns: any = {
        table_name: "Form Title",
        isAction: true,
        columns: [

            // {
            //     Field: "FormId",
            //     DisplayName: "Form ID",
            //     FieldType: "string",
            // },

            {
                Field: "FormName",
                DisplayName: "Form Name",
                FieldType: "string",
            },
            {
                Field: "ViewTitle",
                DisplayName: "View Title",
                FieldType: "string",
            },
            {
                Field: "ActionTitle",
                DisplayName: "Action Title",
                FieldType: "string",
            },
            {
                Field: "IsActive",
                DisplayName: "Status",
                FieldType: "string",
            },
            {
                Field: "UpdatedBy",
                DisplayName: "Modified By",
                FieldType: "string",
            },
        ],
    };

    useEffect(() => {
        dispatch(
            getFormTitleList({})
        );

    }, []);

    console.log(FormTitleTable, "FormTitleTable");

    useEffect(() => {
        if (currentPagePermissionData !== null) {
            setPermissionForpage(currentPagePermissionData);
        }
    }, [currentPagePermissionData]);

    useEffect(() => {
        if (versionHistory > 0) {
                setIsVersionHistory(true);
                // let re_columns = {
                //     ...columns_version,
                //     columns: versionHistory.HolidayVHeader,
                // };
                // setVersionHistoryData(re_columns);
                console.log(versionHistory, "versionHistory ");
        }
    }, [versionHistory]);

    const handleOpen = async (data: any, Option: string) => {
       
        if (Option === "edit") {
            setOption(Option);
            setIsEditData(data);
            setIsEdit(true);
           

        } else if (Option === "view") {
            setOption(Option);
            setIsEditData(data);
            setIsEdit(true);
        } else if (Option === "history") {
            setOption(Option);
            setIsVersionHistory(true);
            dispatch(
                getFormTitleHistory({
                    formId: data.FormId,
                   
                })
            );
           
        } else if (Option === "delete") {

            setOption(Option);
            setIsDeleteData(data);

        } 
    };

    useEffect(() => {
        if (isFormTitleAddandEdit) {
            dispatch(updateFormTitleAddandEdit(false));
            handleRecordLockUpdate();
            dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
            dispatch(
                getFormTitleList({})
            );
    
        }
        else if (isFormTitleDeleted) {
            dispatch(updateFormTitleAddandEdit(false));
            dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
            dispatch(
                getFormTitleList({})
            );
            setIsDeleteData(null);
        }
    }, [isFormTitleAddandEdit, isFormTitleDeleted]);


    
    const deleteHolidayList = () => {

       

        let formData: any = {};
        formData.FormId = isDeleteData?.FormId;
        formData.Updated_By = userLoginResponse?.UserName;
        formData.IsActive = isDeleteData?.IsActive === "Active" ? false : true;
        formData.ModuleName = currentPagePermissionData?.MenuName;
        formData.Activity = isDeleteData?.IsActive === "Active" ? "inactive" : "active";

        // formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);


        dispatch(
            deleteFormTitle((formData))
        );
    };


    
    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    // backgroundColor: "#ffff",
                    // backgroundColor: "background.default",
                    // px: 3,
                    // py: 4,
                    // borderRadius: "10px",
                }}
            >
                <Stack spacing={2}>
                    <Box
                        sx={{
                            display: "flex",
                            // flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {/* {currentPagePermissionData?.MenuName} */}
                            Form Title Master
                        </Typography>

                        <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
                    </Box>


                    <Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <MaterialDynamicGrid
                                data={columns}
                                rows={FormTitleTable}
                                handleAction={handleOpen}
                                enableZoomIcon={true}
                                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                                handleAdd={() => setAddForm(true)}
                                showAdd={true}
                                showhistory={true}
                                showDelete={permssionPage?.IsDelete === 1}
                                showView={permssionPage?.IsView === 1}
                                showEdit={permssionPage?.IsEdit === 1}
                                showExports={permssionPage?.IsExport === 1}
                                pageName="Form Title"
                                isEnableRecordLock = {{ IdField : "FormId" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                    //   FormID : FormIdConfig?.IndustryFormId,
                                    
                                      MenuID : currentPagePermissionData?.MenuId,
                                      IsCheckPending : false  }}}
                                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                            />
                        </Box>
                    </Box>
                </Stack>
            </Box>

            <CustomDialog
                show={addForm}
                onHide={() => setAddForm(false)}
                maxWidth={"xs"}
                minHeight={"50vh"}
                header={"Add Form Title"}
                contentNode={
                    //   <></>
                    <AddScreenTitleMaster
                        view={false}
                        closePopUp={() => {
                            setAddForm(false);
                        }}
                    />
                }
            />

            <CustomDialog
                show={isEdit}
                onHide={() => {
                    handleRecordLockUpdate();
                    setIsEdit(false);
                }}
                maxWidth={"xs"}
                minHeight={"50vh"}
                header={Option === "view" ? "View Form Title" : "Update Form Title"}
                contentNode={
                    <>
                        {Option === "edit" && (
                            <AddScreenTitleMaster
                                view={false}
                                closePopUp={() => {
                                    setIsEdit(false);
                                    handleRecordLockUpdate();
                                }}
                                edit={isEdit}
                                isEditData={IsEditData}
                            />
                            //   <></>
                        )}
                        {Option === "view" && (
                            <AddScreenTitleMaster
                                view={true}
                                closePopUp={() => {
                                    setIsEdit(false);
                                }}
                                isEditData={IsEditData}
                            />
                            //   <></>
                        )}
                    </>
                }
            />

            <CustomDialog
                show={isVersionHistory}
                onHide={() => {
                    setIsVersionHistory(false);
                }}
                maxWidth={"lg"}
                header={"Form Title Version History"}
                contentNode={
                    <>
                        {" "}
                        <MaterialDynamicGrid
                            data={columns_version}
                            rows={versionHistory}
                            handleAction={handleOpen}
                            enableZoomIcon={false}
                            showhistory={false}
                            showDelete={permssionPage?.IsDelete === 1}
                            showView={permssionPage?.IsView === 1}
                            showEdit={permssionPage?.IsEdit === 1}
                            showExports={permssionPage?.IsExport === 1}
                            pageName="Form Title History Records"
                           
                            userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                        />
                    </>
                }
            />
            <ModalPopUp
                onDeleteMethod={() => deleteHolidayList()}
                isDelete={true}
                onHide={() => setIsDeleteData(null)}
                show={isDeleteData ? true : false}
                header={"Confirm"}
                text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
                    } the selected Form Title?`}
                ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
            />

        </>
    );
}
