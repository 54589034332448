import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import { IVendor } from "../../../models/authorize.type";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { AddAssessmentCategory } from "./addAssessmentCategory";
import { RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  getAssessmentCategoryList,
  updateAssessmentCategoryAddandEdit,
  deleteAssessmentCategory,
  updateVersionHistory,
  getAssessmentCategoryHistory,
} from "../../../store/slices/assessmentCategoryMasterData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import ProgressBar from "../../../common/progressBar/progressBar";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { IMenu } from "../../../store/slices/menu";
import { getSaveandDrafList, updateSaveasDraftAddandEdit } from "../../../store/slices/saveasdraft";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import masterEditEnableService from "../../../store/services/masterEditEnableService";
import { Edit_Enable_tosterMSG, FormIdConfig } from "../../../config/config";
import { HandleRecordLockCheck, RecordLockUpdate } from "../../recordLock/RecordLock";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";


let columns: any = {
  table_name: "Assessment Questionnaire Category",
  isAction: true,
  columns: [
    {
      Field: "AQ_Category_Name",
      FieldType: "string",
      DisplayName: "Category Name",
    },
    {
      Field: "AQ_Category_Type_Value",
      FieldType: "string",
      DisplayName: "Type",
    },
    {
      Field: "Parent_AQ_Category_Name",
      FieldType: "string",
      DisplayName: "Parent",
    },
    {
      Field: "Parent_Level_Value",
      FieldType: "number",
      DisplayName: "Level",
    },
    {
      Field: "IsActive",
      FieldType: "string",
      DisplayName: "Status",
    },
    {
      Field: "Updated_By",
      FieldType: "string",
      DisplayName: "Modified By",
    },
  ],
};

const AssessmentCategory = () => {
  const columns_version: any = {
    table_name: "Assessment Questionnaire Category Version History Records",
    isAction: false,
    columns: [],
  };

  const {
    dataTable,
    versionHistory,
    isAssessmentCategoryAdded,
    isAssessmentCategoryUpdated,
    isAssessmentCategoryDeleted,
    ToasterMsg,
    isLoading,
  } = useSelector((state: RootState) => state.assessmentCategoryMasterData);
  const { draftdata, isLoadingDraft, isDraftAdded, isDraftUpdated, isDraftDeleted } = useSelector(
    (state: RootState) => state.saveAsDraft
  );

  const dispatch = useAppDispatch();
  const [formDisplay, setformDisplay] = useState(true);
  const [addForm, setAddForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [Option, setOption] = useState("");
  const [HeaderColumn, setHeaderColumn] = useState(columns);
  const [assessmentCategoryData, setAssessmentCategoryData] = useState<any[]>([]);
  const [isEditDate, setIsEditDate] = useState<any>({});
  const [isDraftDate, setIsDraftDate] = useState<any>(null);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [permssionPage, setPermissionForpage] = useState<any>();
  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [versionHistoryData, getVersionHistoryData] = useState<any>();
  const [editId, setEditId] = useState<any>(0);
  const [lablename, setLableNAme] = useState<any>('Assessment Questionnaire Category');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { currentpageTitleData } = useSelector(
          (state: RootState) => state.FormTitleMasterData
        );
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const {
    watch,

  } = useForm<FormData>({});
  const { handleRecordLockUpdate } = RecordLockRelease();

  useEffect(() => {
    dispatch(
      getAssessmentCategoryList({
        FormID: 40,
      })
    );
    callDraftData();
  }, []);

  useEffect(() => {
    if (draftdata && draftdata.ScreenName === "Assessment Questionnaire Category" && draftdata.DraftData) {
      setIsDraftDate(draftdata);
    } else {
      setIsDraftDate(null);
    }
  }, [draftdata]);

  useEffect(() => {
    if (versionHistory.assessmentCategoryVHeader.length > 0 && versionHistory.assessmentCategoryVHeader.length > 0) {
      if (versionHistory.assessmentCategoryVHeader && versionHistory.assessmentCategoryVHeader.length > 0) {
        // setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory.assessmentCategoryVHeader,
        };
        getVersionHistoryData(re_columns);
      }
    }
  }, [versionHistory]);

  useEffect(() => {
    setAssessmentCategoryData(dataTable);
  }, [dataTable]);

 
  const handleOpen = async (data: any, option: string) => {

   
    if (option === "delete") {
      setIsDeleteData(data);
      setOption(option);
    }
    else if (option === "history") {
      setIsVersionHistory(true);
      setOption(option);
      setIsEditDate(data);
      dispatch(
        getAssessmentCategoryHistory({
          categoryId: data.AQ_Category_Id,
          formID: FormIdConfig?.AQCategoryFormId,
        
        })
      );
    }
    else if (option === "edit") {
      setIsEdit(true);
      setOption(option);
      setIsEditDate(data);
      } else {
      setIsEdit(true);
      setOption(option);
      setIsEditDate(data);
    }
  };

  const callDraftData = () => {
    dispatch(updateSaveasDraftAddandEdit(false));
    dispatch(
      getSaveandDrafList({
        DraftId: 12,
      })
    );
  };

  const deleteAssessmentCategoryList = () => {
    let JsonDataForDisplay: any = {
      "Type": isDeleteData?.AQ_Category_Type_Value,
      "Assessment Questionnaire Category Name": isDeleteData.AQ_Category_Name,
      "Status": isDeleteData.IsActive === "Active" ? "InActive" : "Active",
      "Modified By": userLoginResponse?.UserName,
    }

    if(isDeleteData?.Parent_AQ_Category_Id != 0){
      JsonDataForDisplay["Parent Level"] = isDeleteData?.Parent_Level_Value || '';
      JsonDataForDisplay["Parent Assessment"]  = isDeleteData?.Parent_AQ_Category_Name  || '' ;
         
    }else{
      JsonDataForDisplay["Map Service Catalogue"]  = isDeleteData?.Map_Service_Catalogue ? "Yes"  : "No"  ;
    }

    let formData = {
      IsActive: isDeleteData.IsActive === "Active" ? false : true,
      Activity: isDeleteData.IsActive === "Active" ? "InActive" : "Active",
      AQ_Category_Id: isDeleteData.AQ_Category_Id,
      AQ_Category_Name: isDeleteData.AQ_Category_Name,
      UserId: userLoginResponse?.UserId,
      MenuId: currentPagePermissionData?.MenuId,
      Form_Id: 40,
      ModuleName: "Assessment Questionnaire Category",
      CreatedBy: userLoginResponse?.UserName,
      DataForDisplay: JSON.stringify(JsonDataForDisplay),
    };
    dispatch(
      deleteAssessmentCategory(formData)
    );
  };

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    if (isAssessmentCategoryAdded) {
      dispatch(updateAssessmentCategoryAddandEdit(false));
      dispatch(updateLoader(false));
      // dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === false ? true : false }));
      dispatch(
        getAssessmentCategoryList({
          FormID: 40,
        })
      );
    } else if (isAssessmentCategoryUpdated) {
      dispatch(updateAssessmentCategoryAddandEdit(false));
      dispatch(updateLoader(false));
      handleRecordLockUpdate();
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === false ? true : false }));

      dispatch(
        getAssessmentCategoryList({
          FormID: 40,
        })
      );
    } else if (isAssessmentCategoryDeleted) {
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
      setTimeout(() => {
        dispatch(updateAssessmentCategoryAddandEdit(false));
      }, 1000);
      dispatch(
        getAssessmentCategoryList({
          FormID: 40,
        })
      );
    }
  }, [isAssessmentCategoryAdded, isAssessmentCategoryUpdated, isAssessmentCategoryDeleted]);

  useEffect(() => {
    if (isDraftAdded) {
      callDraftData();
    } else if (isDraftUpdated) {
      callDraftData();
    } else if (isDraftDeleted) {
      callDraftData();
    }
  }, [isDraftAdded, isDraftUpdated, isDraftDeleted]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
             <Typography variant="h6" color={"text.primary"}>
                                       {" "}
                                       {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                                       {/* Holiday Master */}
                                   </Typography>
          </Box>

          {false ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Grid
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "right",
                }}
              >
                <CustomButton
                  variant="contained"
                  name={"Add Assessment Questionnaire Category"}
                  onClick={() => setAddForm(true)}
                  size="small"
                />
              </Grid>
            </Box>
          ) : null}

          {!formDisplay ? (
            ""
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={HeaderColumn}
                rows={assessmentCategoryData}
                handleAction={handleOpen}
                handleAdd={() => setAddForm(true)}
                enableZoomIcon={true}
                showhistory={true}
                showAdd={permssionPage?.IsAdd === 1}
                showDelete={permssionPage?.IsDelete === 1}
                showView={permssionPage?.IsView === 1}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={permssionPage?.IsExport === 1}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                isEnableRecordLock = {{ IdField : "AQ_Category_Id" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                                  FormID : FormIdConfig?.AQCategoryFormId,
                                                
                                                  MenuID : currentPagePermissionData?.MenuId,
                                                  IsCheckPending : true  }}}
                pageName="Assessment Questionnaire Category Master"
              />
            </Box>
          )}
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => { setAddForm(false); setLableNAme('Assessment Questionnaire Category') }}
        maxWidth={"xs"}
        header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Assessment Questionnaire Category"}`}
        contentNode={
          <>
            {" "}
            <AddAssessmentCategory
              ModuleId={permssionPage?.MenuId}
              isDraftData={isDraftDate}
              onClose={() => setAddForm(false)}
              onchangeLable={(value: any) => setLableNAme(value)}
            />{" "}
          </>
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          setIsEdit(false);
          handleRecordLockUpdate()
          // const data = recordLockUpdate();
          // if (data.length > 0) {
          //   dispatch(UpdateRecordLock(data.pop()));
          // }
        }}
        maxWidth={"xs"}
        header={Option === "view" ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Assessment Questionnaire Category"}` : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Assessment Questionnaire Category"}`}
        contentNode={
          <>
            {" "}
            <AddAssessmentCategory
              view={Option === "view" ? true : false}
              edit={true}
              onClose={() => {
                handleRecordLockUpdate();
                setIsEdit(false);
                // const data = recordLockUpdate();
                // if (data.length > 0) {
                //   dispatch(UpdateRecordLock(data.pop()));
                // }
              }}
              isEditData={isEditDate}
              ModuleId={permssionPage?.MenuId}
              onchangeLable={(value: any) => setLableNAme(value)}
            />{" "}
          </>
        }
      />

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          dispatch(updateVersionHistory([]));
          setIsVersionHistory(false);
        }}
        maxWidth={"lg"}
        header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Assessment Questionnaire Category"} Version History`}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistory.assessmentCategoryRecords}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Assessment Questionnaire Category Version History"
            />
          </>
        }
      />
      <ModalPopUp
        onDeleteMethod={() => deleteAssessmentCategoryList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"} the selected Assessment Questionnaire Category`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />
    </>
  );
};

export default AssessmentCategory;