import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { ISeverityMasterResponse } from "../../../models/performanceManagementModels/severityMaster.type";
import { Severity_MASTER_URL } from './endpoint/SeverityMaster'

const getAllSeverityMaster = (): Promise<ISeverityMasterResponse> => axiosInstance
  .post(Severity_MASTER_URL.GetAllSeverityMaster)
  .then((response: AxiosResponse<ISeverityMasterResponse>) => {
    if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
      return response.data;
    }
    throw new Error("Error getting Data" + response);
  })
  .catch((error: AxiosError | string) => {
    throw error;
  });
const saveSeverityMaster = (requestBody: any): Promise<ISeverityMasterResponse> =>
  axiosInstance
    .post(Severity_MASTER_URL.saveSeverityMaster, requestBody)
    .then((response: AxiosResponse<ISeverityMasterResponse>) => {

      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const updateSeverityMaster = (requestBody: any): Promise<ISeverityMasterResponse> =>
  axiosInstance
    .post(Severity_MASTER_URL.UpdateSeverityMaster, requestBody)
    .then((response: AxiosResponse<ISeverityMasterResponse>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const deleteSeverityMaster = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(Severity_MASTER_URL.EnableOrDisableSeverity, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403)  {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const SeverityMasterService = {
  getAllSeverityMaster, saveSeverityMaster, updateSeverityMaster, deleteSeverityMaster
};

export default SeverityMasterService;
