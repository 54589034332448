import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProbeService from "../services/ProbeServices";
import { Probe_ThirdParty_API_tosterMSG } from "../../config/config";

export interface ProApiDetailsprops {
  baseURL: string;
  version: string;
  apiKey: string;
  accept?: string;
  inputMapping: any[];
  outputMapping: any[];
}

export interface IProbeData {
  CompanyBasicData: any;
  CompanyComprehensiveData: any;
  isLoading: boolean;
  CompanyComprehensiveAdded: boolean;
  CompanyComprehensiveFailed: boolean;
  ProbeToasterMsg: any;
  ProApiDetails: ProApiDetailsprops;
}

export const initialState: IProbeData = {
  CompanyBasicData: [],
  CompanyComprehensiveData: [],
  isLoading: false,
  CompanyComprehensiveAdded: false,
  CompanyComprehensiveFailed: false,
  ProbeToasterMsg: { Message: "", Status: "" },
  ProApiDetails: { baseURL: "", version: "", apiKey: "", accept: "application/json" , inputMapping : [] , outputMapping : [] },
};

export const ProbeCompanyBasic = createAsyncThunk(
  "ProbeCompanyBasic",
  async (requesrParams: { ID: any; identifier_type: any }, { rejectWithValue }) => {
    try {
      const response = await ProbeService.getCompanyBase_Details(requesrParams);
      return response.result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const ProbeCompanyComprehensive = createAsyncThunk(
  "ProbeCompanyComprehensive",
  async (requesrParams: { ID: any; identifier_type: any }, { rejectWithValue, getState }) => {
    try {
      const state = getState() as { ProbeData: IProbeData };
      const ProApiDetailsval = state?.ProbeData;
      const dataProApiDetails = ProApiDetailsval?.ProApiDetails;
      console.log(dataProApiDetails, "dataProApiDetails");

      const response = await ProbeService.getCompanyComprehensive_Details(
        requesrParams.ID,
        requesrParams?.identifier_type,
        dataProApiDetails
      );
      console.log(response, "response");

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const ProbeDataSlice = createSlice({
  name: "ProbeData",
  initialState,
  reducers: {
    updateProbeAddandEdit: (state, action) => {
      state.isLoading = false;
      state.CompanyComprehensiveAdded = false;
      state.CompanyComprehensiveFailed = false;
      state.ProbeToasterMsg = { Message: "", Status: "" };
    },
    clearProbeData: (state) => {
      state.CompanyBasicData = [];
      state.CompanyComprehensiveData = [];
      state.isLoading = false;
      state.CompanyComprehensiveAdded = false;
      state.CompanyComprehensiveFailed = false;
    },
    updateProApiDetails: (state, action) => {
      state.ProApiDetails = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ProbeCompanyBasic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ProbeCompanyBasic.fulfilled, (state, action: PayloadAction<any>) => {
        state.CompanyBasicData = action.payload;
        state.isLoading = false;
      })
      .addCase(ProbeCompanyBasic.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(ProbeCompanyComprehensive.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ProbeCompanyComprehensive.fulfilled, (state, action: PayloadAction<any>) => {
        state.CompanyComprehensiveData = action.payload;
        console.log(action.payload, "action.payload");
        state.CompanyComprehensiveAdded = true;
        state.isLoading = false;
        state.ProbeToasterMsg.Message = Probe_ThirdParty_API_tosterMSG.Succes_message;
        state.ProbeToasterMsg.Status = true;
      })
      .addCase(ProbeCompanyComprehensive.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.CompanyComprehensiveFailed = true;
        state.ProbeToasterMsg.Message = action.payload?.response?.data?.message
          ? action?.payload?.response?.data?.message
          : Probe_ThirdParty_API_tosterMSG.Failed_message;
        console.log(action.payload, "action.payload");

        state.ProbeToasterMsg.Status = false;
      });
  },
});

export const { updateProbeAddandEdit, clearProbeData, updateProApiDetails } = ProbeDataSlice.actions;
export default ProbeDataSlice.reducer;
