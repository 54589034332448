import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AQMMappingService from "../services/assesmentQuestionnaireMapping";
import { IVHAQMColumn } from "../../models/assessmentQuestionnaireMaster.type";
export interface IAQMMappingData {
  AQMMappingdataTable: any;
  AQMMappingByVendordata: any;
  isAQMMappingLoading: boolean;
  isAddEditAQMMappingLoading: boolean;
  isAQMMappingAdded: boolean;
  isAQMMappingUpdated: boolean;
  AQMMappingversionHistory: { AQMMappingRecords: any[], AQMMappingVHeader: IVHAQMColumn[] };
  isAQMMappingtHistory: boolean;
  isAQMMappingDeleted: boolean;
  AQMMappingToasterMsg: string;
  AssessmentHistory: any;
}

export const initialState: IAQMMappingData = {
  AQMMappingdataTable: [],
  AQMMappingByVendordata: [],
  isAQMMappingLoading: false,
  isAddEditAQMMappingLoading: false,
  isAQMMappingAdded: false,
  isAQMMappingUpdated: false,
  AQMMappingversionHistory: { AQMMappingRecords: [], AQMMappingVHeader: [] },
  isAQMMappingtHistory: false,
  isAQMMappingDeleted: false,
  AQMMappingToasterMsg: "",
  AssessmentHistory: [],
};

export const getAQMMappingList = createAsyncThunk(
  "getAQMMappingList",
  async (requesrParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await AQMMappingService.getAQMMappingList(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAQMMapping = createAsyncThunk("addAQMMapping", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response: any = await AQMMappingService.addAQMMapping(requesrParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAQMMapping = createAsyncThunk(
  "updateAQMMapping",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await AQMMappingService.updateAQMMapping(reqbody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAQMMapping = createAsyncThunk(
  "deleteAQMMapping",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await AQMMappingService.deleteAQMMapping(reqbody);
      return response.data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAQMMappingHistory = createAsyncThunk("getAQMMappingHistory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await AQMMappingService.getVersionHistoryAQMMapping(reqbody);
    const { AssessmentQuestionaireHistories, HeaderJson } = response.data;
    return { AQMMappingHistories: AssessmentQuestionaireHistories, HeaderJson: HeaderJson.ColumnHeaders }

  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAQMMappingByVendorID = createAsyncThunk("getAQMMappingByVendorID", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await AQMMappingService.getAQMMappingByVendorID(reqbody);
    const { data } = response;
    return data;

  } catch (error) {
    return rejectWithValue(error);
  }
});


export const getAssessmentHistry = createAsyncThunk("getAssessmentHistry", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await AQMMappingService.getAssessmentHistryByAQMMappingID(reqbody);
    const { data } = response;
    return data;

  } catch (error) {
    return rejectWithValue(error);
  }
});



export const AQMMappingDataSlice = createSlice({
  name: "assesmentQuestionnaireMapping",
  initialState,
  reducers: {
    updateAQMMappingAddandEdit: (state, action) => {
      state.isAQMMappingAdded = false;
      state.isAQMMappingUpdated = false;
      state.isAQMMappingDeleted = false;
      state.AQMMappingToasterMsg = '';
    },
    updateUser: (state, action) => {
      if (action.payload.UserId) {
        let indexVendor = state.AQMMappingdataTable.findIndex((data: any) => data.UserId === action.payload.UserId);
        let copyVendorData = [...state.AQMMappingdataTable];
        copyVendorData[indexVendor] = action.payload;
        state.AQMMappingdataTable = copyVendorData;
      }
    },
    updateAQMMappingVersionHistory: (state, action) => {
      state.AQMMappingversionHistory.AQMMappingRecords = [];
      state.AQMMappingversionHistory.AQMMappingVHeader = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAQMMappingList.pending, (state) => {
        state.isAQMMappingLoading = true;
      })
      .addCase(getAQMMappingList.fulfilled, (state, action: PayloadAction<any>) => {
        state.AQMMappingdataTable = action.payload.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.IsEvidenceRequired = user.EvidenceRequired == true ? "Evidence Required" : "Evidence Not Required";
          return user;
        });
        state.isAQMMappingLoading = false;
      })
      .addCase(getAQMMappingList.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMMappingLoading = false;
      })
      .addCase(addAQMMapping.pending, (state) => {
        state.isAQMMappingLoading = true;
        state.isAddEditAQMMappingLoading = true;
      })
      .addCase(addAQMMapping.fulfilled, (state, action: PayloadAction<any>) => {
        // state.AQMMappingdataTable = [...state.AQMMappingdataTable , ...action.payload  ]
        state.isAQMMappingAdded = true;
        state.isAQMMappingLoading = false;
        state.isAddEditAQMMappingLoading = false;
        state.AQMMappingToasterMsg = action.payload.Message;
      })
      .addCase(addAQMMapping.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMMappingLoading = false;
        state.isAddEditAQMMappingLoading = false;
      })
      .addCase(updateAQMMapping.pending, (state) => {
        state.isAQMMappingLoading = true;
        state.isAddEditAQMMappingLoading = true;
      })
      .addCase(updateAQMMapping.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAQMMappingUpdated = true;
        state.isAQMMappingLoading = false;
        state.isAddEditAQMMappingLoading = false;
        state.AQMMappingToasterMsg = action.payload.Message;
      })
      .addCase(updateAQMMapping.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMMappingLoading = false;
        state.isAddEditAQMMappingLoading = false;
      })
      .addCase(deleteAQMMapping.pending, (state) => {
        state.isAQMMappingLoading = true;
      })
      .addCase(deleteAQMMapping.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAQMMappingDeleted = true;
        state.isAQMMappingLoading = false;
        state.AQMMappingToasterMsg = action.payload.Message;
      })
      .addCase(deleteAQMMapping.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMMappingLoading = false;
      })
      .addCase(getAQMMappingHistory.pending, (state, action: PayloadAction<any>) => {
        state.isAQMMappingLoading = false;
      })
      .addCase(getAQMMappingHistory.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action);

        state.AQMMappingversionHistory.AQMMappingVHeader = action.payload.HeaderJson
        state.AQMMappingversionHistory.AQMMappingRecords = action.payload.AQMMappingHistories.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.EvidenceRequired = user.EvidenceRequired === true ? "Evidence Required" : "Evidence Not Required";

          return user;
        });

        state.isAQMMappingLoading = false;
        // state.AQMMappingToasterMsg = "AQMMapping data deleted successfully";
      })
      .addCase(getAQMMappingHistory.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMMappingLoading = false;
      })
      .addCase(getAQMMappingByVendorID.pending, (state) => {
        state.isAQMMappingLoading = true;
      })
      .addCase(getAQMMappingByVendorID.fulfilled, (state, action: PayloadAction<any>) => {
        state.AQMMappingByVendordata = action.payload.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.IsEvidenceRequired = user.EvidenceRequired == true ? "Evidence Required" : "Evidence Not Required";
          return user;
        });
        state.isAQMMappingLoading = false;
      })
      .addCase(getAQMMappingByVendorID.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMMappingLoading = false;
      }) .addCase(getAssessmentHistry.pending, (state) => {
        state.isAQMMappingLoading = true;
      })
      .addCase(getAssessmentHistry.fulfilled, (state, action: PayloadAction<any>) => {
        state.AssessmentHistory = JSON.parse(action.payload) 
        state.isAQMMappingLoading = false;
      })
      .addCase(getAssessmentHistry.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMMappingLoading = false;
      })

      

  },
});

export const { updateAQMMappingAddandEdit, updateUser, updateAQMMappingVersionHistory } = AQMMappingDataSlice.actions;
export default AQMMappingDataSlice.reducer;
