import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomMultilineTextField from "../../../../common/dynamicInputs/CustomMultilineTextField";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import { RootState, useAppDispatch } from "../../../../store/store";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import CustomBreadcrumbs from "../../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { getLovList } from "../../../../store/slices/lovMasterData";
import ModalPopUp from "../../../../common/modalComponent/ModalPopUp";
import { ContractDetails } from "../../PMCommonDetails/ContractDetails";
import { RequirementDetails } from "./RequirementDetails";
import { ApproveRejectDefinScope } from "../../../../store/performanceManagement/slices/defineScope";
import { LovConfig, PR_Event_Status, PR_Scope_Status } from "../../../../config/config";

interface ApproveRejectScopeProps {
    view: any;
    edit: any;
    isEditData: any;
    RowData: any;
    onClose: () => void;
}

export const ApproveRejectScope: React.FC<ApproveRejectScopeProps> = ({
    view,
    edit,
    isEditData,
    RowData,
    onClose,
}) => {
    const dispatch = useAppDispatch();
    const { themeMode } = useSelector((state: RootState) => state.menu);
    const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );
    const [remark, setRemark] = useState("");
    const [remarkerror, setErrorRemark] = useState<any>("");
    const [isConform, setisConform] = useState<any>(false);
    const [submitstatus, setsubmitstatus] = useState<any>("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const currentDate = dayjs();
    const PRStatus = lovTable.filter((item: any) => item.Name === LovConfig.PR_scope_Status) || [];
    const TargetEventStatus = lovTable.filter((item: any) => item.Name === LovConfig.PR_Status) || [];
    const { isScopeApproveandReject } = useSelector(
        (state: any) => state.performanceManagementReducer?.PMScopeDataSlice
    );

    useEffect(() => {
        dispatch(getLovList({ Name: "" }));
    }, []);

    const closeFormValue = () => {
        if (onClose) {
            onClose();
        }
    };

    const NextClick = () => {
        if (currentIndex < isEditData?.pmprClauses?.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const PreviousClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const ApproveReject = (val: any) => {
        if (remark) {
            setsubmitstatus(val);
            setisConform(true);
        } else {
            setErrorRemark("Remarks is required");
        }
    };

    const closeConformationPopup = () => {
        setsubmitstatus("");
        setisConform(false);
    };

    const submitScopeApproved = () => {
        const val = submitstatus;
        console.log(val, "val");
        let formData: any = {};
        let findedStatus = PRStatus.find((item: any) => item?.Options == (val == 1 ? PR_Scope_Status.Approved : PR_Scope_Status.Rejected))?.LovId || 0;
        let NotStartedStatus = TargetEventStatus.find((item: any) => item?.Options ==  PR_Event_Status.Not_Started)?.LovId || 0;

        if (remark !== "") {
            formData.activity = val === 1 ? PR_Scope_Status.Approved : PR_Scope_Status.Rejected;
            formData.prRef_Id = isEditData?.prRef_Id;
            formData.prRef_Status = findedStatus;
            formData.remarks = remark;
            formData.moduleName = "Scope Approvel";
            formData.prEventLogs = [];

            // console.log(isEditData?.pmprClauses, "pmprClauses");

            if (isEditData?.pmprClauses) {
                isEditData.pmprClauses.forEach((clause: any) => {
                    if (clause?.exclude !== true) {
                        clause.pmprClauseTarget.forEach((target: any) => {
                            const monitorPeriods = target.pmprctMonitorPeriods;
                            if (monitorPeriods?.reviewDates) {
                                monitorPeriods.reviewDates.forEach((review: any) => {
                                    if (review?.isActive) {
                                        // console.log(review, "active review");
                                        formData.prEventLogs.push({
                                            req_No: formData.prEventLogs.length + 1,
                                            prRef_Id: isEditData?.prRef_Id,
                                            // vendor_Id: review.updatedBy,
                                            // company_Id: review.updatedOn,
                                            services_Name : RowData?.service_Name ,
                                            prRef_No: isEditData?.prRef_Number,
                                            prReview_Id: review?.pR_Clause_Review_ID,
                                            pR_Target: target?.pmprctScope?.scope_Of_Target,
                                            pR_Target_Id: monitorPeriods?.pR_Clause_Target_Id,
                                            contract_Id: isEditData?.vendor_Contract_Id,
                                            pR_Start_Date: review?.review_StartDate,
                                            pR_End_Date: review?.review_EndDate,
                                            pR_Escalation_Date: review.escalation_Date,
                                            pR_Notify_Date: review.notify_Date,
                                            isActive: review.isActive,
                                            eventLog_Status: NotStartedStatus,
                                            updatedBy: userLoginResponse?.UserName,
                                            updatedOn: currentDate,
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }

            console.log(formData, "Filtered reviewDates");
            dispatch(ApproveRejectDefinScope(formData));


        } else {
            setErrorRemark("Remarks is required");
        }
    };
    console.log(isEditData, RowData, "isEditData");

    useEffect(() => {
        if (isScopeApproveandReject) {
            setsubmitstatus("");
            setisConform(false);
            setRemark("");
        }
    }, [isScopeApproveandReject]);

    const currentItem = isEditData?.pmprClauses[currentIndex];

    return (
        <>
            <Box>
                <Stack spacing={4}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <CustomBreadcrumbs
                            list={[
                                { value: 0, name: "Scope Approval" },
                                { value: 1, name: `Approval` },
                            ]}
                            onchange={closeFormValue}
                        />

                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                        }}
                    >
                        <Typography variant="h6" color={"text.primary"}>
                            {`Scope Approval`}
                        </Typography>
                    </Box>


                    <Box sx={{ display: "flex", width: "100%" }}>

                        <Box sx={{ width: "100%" }}>
                            <Stack spacing={2}>
                                <ContractDetails data={RowData} />

                                <Divider sx={{ border: '1px solid', color: "#e0e0e3" }} />

                                <RequirementDetails data={currentItem} currentIndex={currentIndex} RequirementDataLength={isEditData?.pmprClauses?.length} />


                                {currentIndex === isEditData?.pmprClauses?.length - 1 && !view && (
                                    <Box
                                        sx={{
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    >
                                        <Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <CustomMultilineTextField
                                                    label={"Remarks"}
                                                    value={remark}
                                                    rows={4}
                                                    readOnly={view}
                                                    required={true}
                                                    onChange={(e) => {
                                                        setRemark(e);
                                                        if (e) {
                                                            setErrorRemark("");
                                                        } else {
                                                            setErrorRemark("Remark is required");
                                                        }
                                                    }}
                                                    error={Boolean(remarkerror)}
                                                    helperText={remarkerror}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Box>
                                )}
                            </Stack>
                            <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between", mt: 4 }}>

                                <Box>
                                    <CustomButton
                                        type="submit"
                                        variant="outlined"
                                        name={"Previous"}
                                        disabled={currentIndex === 0}
                                        onClick={() => PreviousClick()}
                                    />
                                </Box>

                                <Box sx={{ display: "flex", gap: 2, }}>
                                    <CustomButton
                                        type="submit"
                                        variant="outlined"
                                        name={"Next"}
                                        disabled={currentIndex === isEditData?.pmprClauses?.length - 1}
                                        onClick={() => NextClick()}
                                    />
                                    {currentIndex === isEditData?.pmprClauses?.length - 1 && !view && (
                                        <>
                                            <CustomButton
                                                type="submit"
                                                variant="contained"
                                                color="success"
                                                disabled={remark === "" ? true : false}
                                                name={"Approve"}
                                                onClick={() => ApproveReject(1)}
                                            />
                                            <CustomButton
                                                type="submit"
                                                variant="contained"
                                                color="error"
                                                disabled={remark === "" ? true : false}
                                                name={"Reject"}
                                                onClick={() => ApproveReject(0)}
                                            />
                                        </>
                                    )}

                                </Box>
                            </Box>
                        </Box>
                    </Box >
                </Stack >
                <ModalPopUp
                    onDeleteMethod={() => submitScopeApproved()}
                    isDelete={true}
                    onHide={() => closeConformationPopup()}
                    show={isConform}
                    ActionName={"OK"}
                    header={"Confirm"}
                    text={`Are you sure you want to ${submitstatus === 1 ? "Approve" : "Reject"}  the Scope ?`}
                />
            </Box >

        </>
    );
};
