import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react"
import CustomTextField from "../../../../common/dynamicInputs/CustomTextField";
import { Controller, useFormContext } from "react-hook-form";
import CustomSelect from "../../../../common/dynamicInputs/CustomSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { textFieldWidth } from "../../../../utils/constants";
import CustomMultiSelect from "../../../../common/dynamicInputs/CustomMultiSelect";
import CustomMultilineTextField from "../../../../common/dynamicInputs/CustomMultilineTextField";
import { LovConfig, VendorMaster_InternalVendorCode } from "../../../../config/config";
import vendorDetailsService from "../../../../store/services/vendorDetails";

export const OtherDetails: React.FC<any> = ({ isEditData, edit, view, control, errorsVendorDetailsform, isDraftData }) => {
  const [vendorTypeI, setVendorTypeI] = useState<any[]>([]);
  const [vendorTypeII, setVendorTypeII] = useState<any[]>([]);
  const [SubIndustryData, setSubIndustryData] = useState<any[]>([]);
  const [APVendorCodeType, setAPVendorCodeType] = useState("");
  const [WarningVendorCode, setWarningVendorCode] = useState("");
  const [vendorStatus, setVendorStatus] = useState<any[]>([]);
  const [intialRendering, setIntialRendering] = useState(true);

  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { industryTable } = useSelector((state: RootState) => state.indutryMasterData);
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const { dataTable } = useSelector((state: RootState) => state.departmentData);

  useEffect(() => {
    setVendorTypeI(lovTable.filter((item: any) => item.Name === LovConfig.VendorTypeI) || []);
    setVendorTypeII(lovTable.filter((item: any) => item.Name === LovConfig.VendorTypeII) || []);
    setVendorStatus(lovTable.filter((item: any) => item.Name === LovConfig.VendorStatus) || []);
  }, [lovTable]);

  useEffect(() => {
    if (applicationParameterData.length > 0) {

      let InternalVendorCodeType = applicationParameterData?.find(
        (item) => item.Parameter_Name === VendorMaster_InternalVendorCode.InternalVendorCode && item.Parameter_Group === VendorMaster_InternalVendorCode.VendorMaster
      )?.Parameter_Value;
      if (InternalVendorCodeType) setAPVendorCodeType(InternalVendorCodeType);

    } else setAPVendorCodeType("");


  }, [applicationParameterData]);

  useEffect(() => {

    if (APVendorCodeType === "Manual" && !edit && !view) {
      let InternalVendorCodeprefix = applicationParameterData?.find(
        (item) => item.Parameter_Name === VendorMaster_InternalVendorCode.InternalVendorCodePrefix && item.Parameter_Group === VendorMaster_InternalVendorCode.VendorMaster
      )?.Parameter_Value;

      let InternalVendorCodesuffix = applicationParameterData?.find(
        (item) => item.Parameter_Name === VendorMaster_InternalVendorCode.InternalVendorCodeSuffix && item.Parameter_Group === VendorMaster_InternalVendorCode.VendorMaster
      )?.Parameter_Value;

      setValue("Internal_VendorCode", `${InternalVendorCodeprefix}`);
      // setValue("Internal_VendorCode", `${InternalVendorCodeprefix}${InternalVendorCodesuffix}`);

    } else {
      // setValue("Internal_VendorCode", "");
    }

  }, [APVendorCodeType]);

  const { getValues, setValue } = useFormContext()
  const formValues = getValues()


  const getSubIndustry = (id: number): void => {
    const filterdSubIndustry = industryTable.filter((item: any) => item.Parent_Industry_Id === id)
    setSubIndustryData(filterdSubIndustry)
    if (intialRendering) setIntialRendering(false)
    else setValue('SubIndustry_Id', [])
  };

  useEffect(() => {
    if (formValues.Industry_Id && industryTable?.length) getSubIndustry(formValues.Industry_Id)
  }, [formValues.Industry_Id, JSON.stringify(industryTable)])

  return (
    <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
      <Grid container spacing={2}>

        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <Controller
            name="KeyCustomer"
            control={control}
            defaultValue={""}
            rules={{
              required: "Key Customers is required",

              validate: {
                noLeadingSpaces: (value: string | undefined) => {
                  if (!value) return;
                  return !/^\s/.test(value) || "Leading spaces are not allowed";
                },
                maxLength: (value) => {
                  if (!value) return;
                  return value.length <= 100 || "Maximum 100 characters allowed";
                }
              }
            }}
            render={({ field }) => (
              <CustomTextField
                name={"Key Customers"}
                readOnly={view}
                required={true}
                label={"Key Customers"}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errorsVendorDetailsform.KeyCustomer)}
                helperText={errorsVendorDetailsform.KeyCustomer?.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <Controller
            name="Industries_Served"
            control={control}
            defaultValue={""}
            rules={{
              validate: {
                noLeadingSpaces: (value: string | undefined) => {
                  if (!value) return;
                  return !/^\s/.test(value) || "Leading spaces are not allowed";
                },
                maxLength: (value) => {
                  if (!value) return;
                  return value.length <= 100 || "Maximum 100 characters allowed";
                }
              }
            }}
            render={({ field }) => (
              <CustomTextField
                name={"Industries served"}
                readOnly={view}
                label={"Industries Catered"}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errorsVendorDetailsform.Industries_Served)}
                helperText={errorsVendorDetailsform.Industries_Served?.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <Controller
            name="VolumeHandled"
            control={control}
            defaultValue={""}
            rules={{
              validate: {
                noLeadingSpaces: (value: string | undefined) => {
                  if (!value) return;
                  return !/^\s/.test(value) || "Leading spaces are not allowed";
                },
                maxLength: (value) => {
                  if (!value) return;
                  return value.length <= 100 || "Maximum 100 characters allowed";
                }
              }
            }}
            render={({ field }) => (
              <CustomTextField
                name={"Volume Handled"}
                readOnly={view}
                label={"Volume Handled"}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errorsVendorDetailsform.VolumeHandled)}
                helperText={errorsVendorDetailsform.VolumeHandled?.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <Controller
            name="Competitor_Details"
            control={control}
            defaultValue={""}
            rules={{
              validate: {
                noLeadingSpaces: (value: string | undefined) => {
                  if (!value) return;
                  return !/^\s/.test(value) || "Leading spaces are not allowed";
                },
                maxLength: (value) => {
                  if (!value) return;
                  return value.length <= 100 || "Maximum 100 characters allowed";
                }
              }
            }}
            render={({ field }) => (
              <CustomTextField
                name={"Competitor Details"}
                readOnly={view}
                label={"Competitor Details"}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errorsVendorDetailsform.Competitor_Details)}
                helperText={errorsVendorDetailsform.Competitor_Details?.message?.toString()}
              />
            )}
          />
        </Grid>
        {APVendorCodeType === "Auto" && !edit && !view || (isDraftData && APVendorCodeType === "Auto") ? (
          <>
          </>
        ) : (
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Controller
              name="Internal_VendorCode"
              control={control}
              defaultValue={""}
              rules={{
                required: "Internal Third Party Code is required",
                validate: {
                  noLeadingSpaces: (value: string | undefined) => {
                    if (!value) return;
                    return !/^\s/.test(value) || "Leading spaces are not allowed";
                  },
                  maxLength: (value) => {
                    if (!value) return;
                    return value.length <= 100 || "Maximum 100 characters allowed";
                  }
                }
              }}
              render={({ field }) => (
                <CustomTextField
                  name={"Internal Third Party Code"}
                  readOnly={view || (!isDraftData && edit)}
                  required={true}
                  label={"Internal Third Party Code"}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={async (e) => {
                    if (e.target.value) {
                      const res = await vendorDetailsService.getIsFieldsExists({
                        Vendor_Id: isEditData? isEditData?.VendorDetails?.Vendor_Id : 0,
                        AttType: "IVCode",
                        AttValue: e.target.value,
                      });
                      if (res.data) setWarningVendorCode(res?.data?.Message);
                      else setWarningVendorCode("");
                    } else setWarningVendorCode("");
                  }}
                  error={Boolean(errorsVendorDetailsform.Internal_VendorCode || WarningVendorCode)}
                  helperText={errorsVendorDetailsform.Internal_VendorCode?.message?.toString() || WarningVendorCode}
                />
              )}
            />
          </Grid>
        )}


      </Grid>
    </Box>
  )
}