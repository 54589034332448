import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IAddCompanyResponse, ICompanyResponse, IVHColumnRes } from "../../models/company.type";
import { COMPANYMASTER_URL } from "./endpoints/commonMasters";

const getCompanyById = (id: any): Promise<any> =>
  axiosInstance
    .get(`${COMPANYMASTER_URL.GetCompanyById}?Company_Id=${id}`)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getCompanyList = (): Promise<ICompanyResponse> =>
  axiosInstance
    .get(COMPANYMASTER_URL.GetCompany)
    .then((response: AxiosResponse<ICompanyResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateCompany = (requestBody: any): Promise<IAddCompanyResponse> =>
  axiosInstance
    .post(COMPANYMASTER_URL.EditCompany, requestBody)
    .then((response: AxiosResponse<IAddCompanyResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addCompany = (requestBody: any): Promise<IAddCompanyResponse> =>
  axiosInstance
    .post(COMPANYMASTER_URL.AddCompany, requestBody)
    .then((response: AxiosResponse<IAddCompanyResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteCompany = (requestBody: any): Promise<IAddCompanyResponse> =>
  axiosInstance
    .post(COMPANYMASTER_URL.DeleteCompany, requestBody)
    .then((response: AxiosResponse<IAddCompanyResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getVersionHistoryCompany = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(COMPANYMASTER_URL.GetCompanyMasterHistoryById, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getImage = (fileName: string): string => {
  return `${axiosInstance.getUri()}${COMPANYMASTER_URL.LogoImage}?fileName=${fileName}`;
};

const CompanyMasterService = {
  getCompanyList,
  updateCompany,
  addCompany,
  deleteCompany,
  getVersionHistoryCompany,
  getImage,
  getCompanyById,
};

export default CompanyMasterService;
