import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomButton from "../dynamicButton/CustomButton";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { RecordLockRelease } from "../../components/recordLock/CommonRecordLock";

interface IModalProps {
  text: string;
  header: string;
  onHide: () => void;
  show: boolean;
  isDelete?: boolean;
  isLoading?: boolean;
  onDeleteMethod?: () => void;
  ActionName?: string;
  CancelName?: string;
  ButtonName?: string;
}

const ModalPopUp: React.FC<IModalProps> = (props) => {
    const { lockeRecorddData} = useSelector(
        (state: RootState) => state.recordLock
      );
        const { handleRecordLockUpdate } = RecordLockRelease();
  const theme = useTheme();



const closePopup = () => {
  // if(lockeRecorddData){
  //   handleRecordLockUpdate();
  // }

  props.onHide()
}

const ondeleteMethod = () => {
  // if(lockeRecorddData){
  //   handleRecordLockUpdate();
  // }
  if(props?.onDeleteMethod){
    props.onDeleteMethod()
  }


}

  return (
    <React.Fragment>
      <Dialog
        open={props.show}
        key={"warning popup"}
        // onClose={props.onHide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth={true}
      >
        <Box
          sx={{
            color: "#ffff",
            backgroundColor: `${theme.palette.primary.main}`,
          }}
        >
          <DialogTitle sx={{ padding: "8px 16px" }} id="alert-dialog-title-wp">
            {props.header}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => props.onHide()}
            sx={{
              position: "absolute",
              right: 4,
              top: 4,
              color: "#ffff",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent sx={{ padding: "8px 16px" }}>
          <Box
            sx={{
              minWidth: "10vw",
              minHeight: "60px",
              display: "flex",
              alignItems: "left",
              justifyContent: "left",
            }}
          >
            <DialogContentText id="alert-dialog-description">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Box sx={{ pt: 3 }}>
                  <FeatherIcon icon="info" size="35" />
                </Box>

                <Typography variant="subtitle1" sx={{ pt: 3, pl: 3 }}>
                  {props.text}
                </Typography>
              </Box>
            </DialogContentText>
          </Box>
        </DialogContent>

        <DialogActions>
          {props.isDelete ? (
            <React.Fragment>
              <CustomButton
                variant="outlined"
                name={props.CancelName || "Cancel"}
                disabled={props.isLoading}
                onClick={(e: any) => {
                  props.onHide()
                }}
              />
              <CustomButton
                variant="contained"
                disabled={props.isLoading}
                name={props.ActionName || "Inactive"}
                onClick={() => props.onDeleteMethod && ondeleteMethod()}
              />
            </React.Fragment>
          ) : (
            <CustomButton onClick={() => closePopup()} variant="outlined" name={props.ButtonName || "Ok"} />
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalPopUp;
