import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";

import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { textFieldWidth } from "../../utils/constants";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomButton from "../../common/dynamicButton/CustomButton";
import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";
import CustomMultilineTextField from "../../common/dynamicInputs/CustomMultilineTextField";
import {
  addApplicationParameter,
  getApplicationParameterList,
  updateAPAddandEdit,
  updateApplicationParameter,
} from "../../store/slices/applicationParameterData";
import { updateLoader } from "../../store/slices/loader";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { ApplicationParameterName } from "../../config/config";
import InactivityTimer from "../../common/InactivityTimer/InactivityTimer";

interface FormData {
  ParameterID?: number;
  parameterGroup: string;
  ParameterName: string;
  Parameterpurpose: string;
  IsMultipleOptions: boolean;
  Options: string;
  Values: string;
  ParameterOrder: string;
  Description: string;
  ParameterValue: string;
  IsActive: string;
}

export interface RegionalSettingsProps {
  view?: boolean;
  closePopUp: () => void;
  edit?: boolean;
  isEditData?: any;
  ModuleId?: any;
}

export const Addparameter: React.FC<RegionalSettingsProps> = ({ view, closePopUp, edit, isEditData, ModuleId }) => {
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { formConfiguration } = useSelector((state: RootState) => state.customFormFields);
  const [message, setMessage] = useState<boolean>(false);
  const [IsCancel, setIsCancel] = useState<boolean>(false);
  
const dispatch = useAppDispatchThunk();
  let formData: any = {
    ParameterID: isEditData ? isEditData?.Parameter_Id : 0,
    parameterGroup: isEditData ? isEditData?.Parameter_Group : "",
    ParameterName: isEditData ? isEditData?.Parameter_Name : "",
    Description: isEditData ? isEditData?.Parameter_Description : "",
    IsMultipleOptions: isEditData ? isEditData?.Is_Muliple_Options : false,
    Options: isEditData ? isEditData?.Parameter_Options : "",
    Values: isEditData ? isEditData?.Parameter_Value : "",
    IsActive: isEditData ? (isEditData?.IsActive == "Inactive" ? false : true) : true,
  };
  const { applicationParameterData, isAPAdded, isAPDeleted, isAPUpdated, versionHistory, apToasterMsg } = useSelector(
    (state: RootState) => state.applicationParameter
  );
  const [timeout, setTimeout] = useState<any>(null);

  const {
    handleSubmit: handleParameterForm,
    reset,
    setValue,
    clearErrors,
    formState: { errors: errorParameterForm, isDirty },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });
  const isActiveDisabled = watch("IsActive");
  const watchIsMultipleOptions = watch("IsMultipleOptions");
  const watchValues = watch("Options");

  const splitData = watchValues !== "" ? watchValues.split(",").map((item: any) => ({ Values: item.trim() })) : [];

  console.log(getValues());
  const handleSubmit = (data: FormData) => {
    dispatch(updateLoader(true));
    const formData: any = {
      Parameter_Id: data.ParameterID,
      Parameter_Group: data.parameterGroup,
      Parameter_Name: data.ParameterName,
      Parameter_Description: data.Description,
      Parameter_Options: data.Options,
      Is_Muliple_Options: data.IsMultipleOptions,
      Parameter_Value: data.Values,
      Updated_By: user?.UserName,
      Updated_On: new Date().toISOString(),
      IsActive: data.IsActive == "Isactive" ? false : true,
      ModuleName: "Application Parameter",
      MenuId: ModuleId,
      Form_Id: 18,
      UserId: userLoginResponse?.UserId,
      CreatedBy: user?.UserName,
      Activity: "Create",
      Status: 1,
      // Approved_By: user?.UserName,
      // Approved_On: new Date().toISOString(),
    };
    let JsonDataForDisplay: any = {
      "Parameter Group": data?.parameterGroup || "",
      "Parameter Name": data?.ParameterName || "",
      // BranchTypeValue: BranchTypelov.find((item: any) => item.LovId == formData?.BranchType)?.Options || "",
      // Company_legalName: transformData(formData.CompanyId) || "",
      "Parameter Description": data?.Description || "",
      "Parameter Value": data?.Values || "",
      "Parameter Options": data?.Options || "",
      Status: data.IsActive ? "Active" : "InActive",
      "Modified By": user?.UserName,
    };

    if (edit) {
      formData.Updated_By = user?.UserName;
      formData.Activity = "Edit";
    }
    if (!edit) {
    }
    if (edit) {
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      dispatch(updateApplicationParameter(formData));
    } else {
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      dispatch(addApplicationParameter(formData));
    }
  };
  useEffect(() => {
    if (isAPAdded || isAPUpdated) {
      dispatch(
        getApplicationParameterList({
          FormID: 2,
        })
      );
      dispatch(updateAPAddandEdit(false));
      reset(formData);
      dispatch(updateLoader(false));

      if (closePopUp) {
        closePopUp();
        // setLoader(false);
      }
    }
  }, [isAPAdded, isAPUpdated]);

  const handleCancelPopup = () => {

    if (isDirty) {
      setIsCancel(true);
    } else {
      closePopUp && closePopUp();
    }
  };

 useEffect(() => {
        if (edit) {
          const RecordTimeOut: any = ApplicationParameterName.RecordTimeOut;
          let findRecordTimeOut = applicationParameterData.find(
            (applicationParamitem) =>
              applicationParamitem.Parameter_Name === RecordTimeOut &&
              applicationParamitem.Parameter_Group === RecordTimeOut
          )?.Parameter_Value;
          setTimeout(Number(findRecordTimeOut));
        }
      }, []);

  
  
  return (
    <>
      <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
        <Grid
          // item
          // item xs={12} sm={12} md={6} lg={6} xl={6}
          sx={{
            backgroundImage: `url()`,
            backgroundRepeat: "no-repeat",
            // backgroundColor: (t) =>
            //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
            // backgroundSize: "cover",
            backgroundPosition: "center",
            // height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            margin: "0",
          }}
        >
          {/* {false && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              margin: "0",
            }}
          >
            {!view ? (
              <>
                {!edit ? (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Add Branch Master
                  </Typography>
                ) : (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Edit Branch Master
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="h6" sx={{ m: 1 }}>
                View Branch Master
              </Typography>
            )}
          </Box>
        )} */}
          <Grid
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                px: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="parameterGroup"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "Parameter Group is Required",
                        validate: {
                          noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                          maxLength: (value) => value.length <= 50 || "Maximum 50 characters allowed",
                          ValueLimitaion: (value) =>
                            /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value) ||
                            "Name cannot contain special characters and space",
                        },
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          name={"parameterGroup"}
                          required={true}
                          disabled={view || !isActiveDisabled}
                          label={"Parameter Group"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorParameterForm.parameterGroup)}
                          helperText={
                            errorParameterForm.parameterGroup && errorParameterForm.parameterGroup.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="ParameterName"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "Parameter Name is Required",
                        validate: {
                          noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                          maxLength: (value) => value.length <= 50 || "Maximum 50 characters allowed",
                          ValueLimitaion: (value) =>
                            /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value) ||
                            "Name cannot contain special characters and space",
                        },
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          name={"ParameterName"}
                          required={true}
                          disabled={view || !isActiveDisabled}
                          label={"Parameter Name"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorParameterForm.ParameterName)}
                          helperText={
                            errorParameterForm.ParameterName && errorParameterForm.ParameterName.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="Description"
                      control={control}
                      rules={{
                        required: "Description is Required",
                        validate: {
                          noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                          maxLength: (value) => value.length <= 100 || "Maximum 100 characters allowed",
                          ValueLimitaion: (value) =>
                            /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value) ||
                            "Name cannot contain special characters and space",
                        },
                      }}
                      render={({ field }) => (
                        <CustomMultilineTextField
                          label={"Description"}
                          value={field.value}
                          disabled={view || !isActiveDisabled}
                          onChange={field.onChange}
                          rows={2}
                          required={true}
                          error={Boolean(errorParameterForm.Description)}
                          helperText={
                            errorParameterForm.Description && errorParameterForm.Description.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="IsMultipleOptions"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Box sx={{ mt: 1 }}>
                          <CustomToogleSwitch
                            label={"Multiple Options"}
                            disabled={view || !isActiveDisabled}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors("Options");
                              clearErrors("Values");
                              console.log(isEditData?.Is_Muliple_Options, watchIsMultipleOptions);

                              // if(isEditData?.Is_Muliple_Options === watchIsMultipleOptions){
                              //   setValue("Values", isEditData?.Parameter_Value || "", { shouldDirty: true });

                              // } else {
                              //   setValue("Values", "");
                              // }
                              setValue("Options", "");
                              setValue("Values", "");
                            }}
                          />
                        </Box>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                    style={{ display: watchIsMultipleOptions ? "block" : "none" }}
                  >
                    <Controller
                      name="Options"
                      control={control}
                      rules={{
                        required: watchIsMultipleOptions ? "Options is Required" : "",
                        validate: {
                          noLeadingSpaces: (value) => {
                            if (/^\s/.test(value)) {
                              return "Leading spaces are not allowed";
                            }
                            return true;
                          },
                          commaCheck: (value) => {
                            if (!watchIsMultipleOptions && value.includes(",")) {
                              return "Not allowed when Multiple Options is off";
                            }
                            return true;
                          },
                          maxLength: (value) => {
                            if (value && value.length > 50) {
                              return "Minimum 50 characters required";
                            }
                            return true;
                          },
                        },
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          required={watchIsMultipleOptions}
                          disabled={view || !isActiveDisabled}
                          label={watchIsMultipleOptions ? "Options (Comma Separated values)" : "Options"}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (!watchIsMultipleOptions && e.key === ",") {
                              e.preventDefault();
                            }
                          }}
                          error={Boolean(errorParameterForm.Options)}
                          helperText={errorParameterForm.Options && errorParameterForm.Options.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="Values"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "Values is Required",
                        validate: {
                          noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                          maxLength: (value) => value.length <= 100 || "Maximum 100 characters allowed",
                          // ValueLimitaion: (value) => 
                          //   /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value) ||
                          //   "Cannot contain special characters and spaces",
                        },
                      }}
                      render={({ field }) =>
                        watchIsMultipleOptions ? (
                          <CustomSelect
                            name={"Values"}
                            valueKey={"Values"}
                            optionLabelKey="Values"
                            options={splitData || []}
                            disabled={view || !isActiveDisabled}
                            required={true}
                            label={"Values"}
                            value={field.value}
                            onChange={field.onChange}
                            error={Boolean(errorParameterForm.Values)}
                            helperText={errorParameterForm.Values && errorParameterForm.Values.message?.toString()}
                          />
                        ) : (
                          <CustomTextField
                            {...field}
                            required
                            disabled={view || !isActiveDisabled}
                            label="Values"
                            error={Boolean(errorParameterForm.Values)}
                            helperText={errorParameterForm.Values && errorParameterForm.Values.message?.toString()}
                          />
                        )
                      }
                    />
                  </Grid>

                  {/* {edit || view ? (
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Box sx={{ mt: 1 }}>
                      <Controller
                        name="IsActive"
                        control={control}
                        render={({ field }) => (
                          <CustomToogleSwitch
                            label={"User Status"}
                            dynamicLabel={field.value ? "ACTIVE" : "INACTIVE"}
                            disabled={view}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                ) : null} */}
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "right",
                    mt: 2,
                    // position: "absolute",
                    // right: 21,
                    // bottom: 16,
                  }}
                >
                  {!view ? (
                    !edit ? (
                      <>
                        <CustomButton
                          type="reset"
                          variant="outlined"
                          name={"Cancel"}
                          onClick={() => {
                            handleCancelPopup();
                          }}
                        />

                        <CustomButton
                          type="reset"
                          name={"Submit"}
                          disabled={!isDirty}
                          onClick={handleParameterForm(handleSubmit)}
                        />
                      </>
                    ) : (
                      <>
                        <CustomButton
                          variant="outlined"
                          name={"Cancel"}
                          onClick={() => {
                            handleCancelPopup();
                          }}
                        />
                        <CustomButton
                          variant="contained"
                          name={"Update"}
                          disabled={!isDirty}
                          onClick={handleParameterForm(handleSubmit)}
                        />
                      </>
                    )
                  ) : (
                    <CustomButton
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        closePopUp();
                      }}
                    />
                  )}

                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <ModalPopUp
        onDeleteMethod={() => closePopUp && closePopUp()}
        isDelete={true}
        onHide={() => setIsCancel(false)}
        show={IsCancel}
        header={"Confirm"}
        text={`Are you sure to cancel`}
        ActionName={"Yes"}
      />

    {timeout && (
      <InactivityTimer
        timeout={timeout}
        onTimeout={() => {}}
        renderPrompt={(isVisible, handleResponse) =>
          isVisible && (
            <ModalPopUp
              onDeleteMethod={() => {}}
              isDelete={false}
              onHide={() => handleResponse(false)}
              show={isVisible}
              header={"Confirm"}
              text={`Are you still editing?`}
              ButtonName={"Yes"}
            />
          )
        }
      />
    )}
    </>
  );
};
