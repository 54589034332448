import React, { useState } from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, IconButton } from "@mui/material";
import { DownLoadAFile } from "../../../store/slices/fileHandler";
import { useAppDispatchThunk } from "../../../store/store";
import dayjs from "dayjs";
import fileHandlerService from "../../../store/services/fileHandlerService";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import * as mime from "react-native-mime-types";
import CustomSvg from "../../../common/CustomSvg";
import FilteViewer from "../../../common/fileViewer/FilteViewer";
import { useTheme } from "@mui/material/styles";
import BasicTableStyle from "../../../common/dynamicDataGrid/basicTable.module.scss";
interface ViewChangesProps {
  data: any;
  onclose: () => void;
  dispatch: any;
}

interface MergedDataObject {
  PreviousData: Record<string, any>;
  CurrentData: Record<string, any>;
}

type MergedData = Record<string, MergedDataObject | MergedDataObject[]>;

export const ViewChanges: React.FC<ViewChangesProps> = ({ data, onclose, dispatch }) => {
  const theme = useTheme();
  const [oldImage, setOldImage] = useState("");
  const [newImage, setNewImage] = useState("");
  const [openViewFileModel, setOpenViewFileModel] = useState(false);
  const [fileUrl, setFileUrl] = useState<string>("");
  const [fileName, setFileName] = useState<any>(undefined);
  if (!data || !data.PreviousData || !data.CurrentData) {
    return null;
  }

  console.log(data.CurrentData, 'viewChangesCurrentData')

  const columns = [
    { Field: "Field", DisplayName: "Field" },
    { Field: "PreviousData", DisplayName: "Old Value" },
    { Field: "CurrentData", DisplayName: "New Value" },
  ];
  const Attachmentscolumns = [
    { Field: "Field", DisplayName: "Field" },
    { Field: "PreviousData", DisplayName: "Old Value" },
    { Field: "CurrentData", DisplayName: "New Value" },
  ];
  const getVendorHeading = (data: string): string => {
    switch (data) {
   
      case "VendorAttachments":
        return "Third Party Attachments";
      case "VendorDetails":
        return "Third Party Details";
      case "VendorAddress":
        return "Third Party Address";
      case "VendorSpocs":
        return "Third Party POC";
      case "VendorEscalations":
        return "Third Party Escalations";
      default:
        return "";
    }
  };
  const getVendorField = (data: string): string => {
    console.log(data, "SwitchData")


    switch (data) {
      case "Type_Of_Vendor2_Value":
        return "Third Party Type";
      case "Group_Name":
        return "Group Name";
      case "Legal_Name":
        return "Third party Name";
      case "Brand_Name":
        return "Brand Name";
      case "WebSite":
        return "Website";
      case "Contact_Number":
        return "Contact Number";
      case "Jurisdiction_Value":
        return "Jurisdiction";
      case "Constitution_Value":
        return "Constitution";
      case "IsListed":
        return "Is Listed";
      case "IsRegulated":
        return "Is Regulated";
      case "Regulator_Value":
        return "Regulator";
      case "Type_Of_Vendor1_Value":
        return "Alliance Type";
      case "Industry_Value":
        return "Industry";
      case "SubIndustry_Value":
        return "Sub Industry";
      case "Service_Owner_Value":
        return "Service Owner";
      case "Scope_Of_Service":
        return "Scope Of Service";
      case "KeyCustomer":
        return "Key Customers";
      case "Industries_Served":
        return "Industries Catered";
      case "VolumeHandled":
        return "Volume Handled";
      case "Competitor_Details":
        return "Competitor Details";
      case "Internal_VendorCode":
        return "Internal Third party Code";
      case "Status_Value":
        return "Status";
      case "Address_Type_Value":
        return "Address Type";
      case "Assessment_Type_Value":
        return "Assessment Type";
      case "UpdatedOn":
        return "Updated On";
      case "UpdatedBy":
        return "Updated By";
      case "Updated_By":
        return "Updated By";
      case "Purpose_Value":
        return "Purpose";
      case "ContactNumber":
        return "Contact Number";
      case "EmailId":
        return "Email Id";
      case "Document_Name":
        return "Document Name";
      case "Status_Value":
        return "Status Value";
      case "UpdatedOn":
        return "Updated On";
      case "IsMandatory":
        return "Is Mandatory";
      case "UpdatedBy":
        return "Updated By";
      case "IsPerpetual":
        return "Is Perpetual";
      case "ExpiryDate":
        return "Expiry Date";
      case "Pin_Value":
        return "Pin Value";
      case "Url":
        return "Attachments";
      case "Vendor_Value":
        return "Vendor Value";
      case "Incorporated_Date":
        return "Incorporated Year";

      default:
        return data;
    }
  };
  const sections = Object.keys(data.CurrentData);

  console.log(sections, 'sectionsss')

  const mergeDatas = (PreviousData: Record<string, any> = {}, CurrentData: Record<string, any> = {}): MergedData => {
    return Object.keys(PreviousData).reduce<MergedData>((result, key) => {
      if (Array.isArray(PreviousData[key])) {
        result[key] = (PreviousData[key] || []).map((oldItem: any, index: number) => ({
          PreviousData: oldItem,
          CurrentData: (CurrentData[key] || [])[index] || {},
        }));
      } else if (typeof PreviousData[key] === "object" && PreviousData[key] !== null) {
        result[key] = {
          PreviousData: PreviousData[key] || {},
          CurrentData: CurrentData[key] || {},
        };
      }
      return result;
    }, {});
  };

  const mergeData = (
    PreviousData: Record<string, any> = {},
    CurrentData: Record<string, any> = {}
  ): Record<string, any> => {
    return Object.keys({ ...PreviousData, ...CurrentData }).reduce<Record<string, any>>((result, key) => {
      if (Array.isArray(PreviousData[key]) || Array.isArray(CurrentData[key])) {
        const maxLength = Math.max(PreviousData[key]?.length || 0, CurrentData[key]?.length || 0);
        result[key] = Array.from({ length: maxLength }).map((_, index) => ({
          PreviousData: PreviousData[key]?.[index] || null,
          CurrentData: CurrentData[key]?.[index] || null,
        }));
      } else if (typeof PreviousData[key] === "object" && PreviousData[key] !== null) {
        result[key] = {
          PreviousData: PreviousData[key] || null,
          CurrentData: CurrentData[key] || null,
        };
      }
      return result;
    }, {});
  };
  

  const isMergedDataObject = (value: MergedDataObject | MergedDataObject[]): value is MergedDataObject => {
    return !Array.isArray(value) && typeof value === "object" && "PreviousData" in value && "CurrentData" in value;
  };

  const mergedData = mergeData(data.PreviousData, data.CurrentData);
  
  console.log(mergedData , "mergedData 0" ,  JSON.stringify(data.PreviousData)  );
  console.log(mergedData , "mergedData 1" ,  JSON.stringify(data.CurrentData) );
  

  const extractValues = (data: string): string[] => {
    try {
      const parsedData = JSON.parse(data);

      const optionsString = parsedData
        .map((item: { Options?: string; Description?: string }) => item.Options || item.Description)
        .filter(Boolean)
        .join(", ");
      const optionsArray = optionsString.split(",").map((option: any) => option.trim());

      return optionsArray;
    } catch (e) {
      console.error("Failed to parse JSON:", e);
      return [];
    }
  };

 
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const formatDateWithOutHours = (dateString: string): string => {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const isBase64Image = (image: any) => {
    return (
      image &&
      (image.startsWith("data:image/jpeg;base64,") ||
        image.startsWith("data:image/png;base64,") ||
        image.startsWith("data:image/jpg;base64,"))
    );
  };
  const handleImageDisplay = async (imageUrl: string): Promise<string | null> => {
    if (imageUrl && !isBase64Image(imageUrl)) {
      const resultAction = await dispatch(DownLoadAFile({ fileUrl: imageUrl })).unwrap();
      if (resultAction) {
        const blob = "data:image/jpeg;base64," + resultAction;
        return blob;
      }
    } else if (isBase64Image(imageUrl)) {
      return imageUrl;
    }
    return null;
  };
  const filterKeys = (obj: Record<string, any>): Record<string, any> => {
    return Object.keys(obj).reduce((acc, key) => {
      // if (key && key == "Logo") {
      //   const imageData = handleImageDisplay(obj[key]).then((e) => {
      //     return e;
      //   });
      //   // console.log(imageData, "Logo Data");
      //   acc[key] = imageData;
      // }
      if (
        key &&
        (!key.endsWith("Id") || key === "emailId") &&
        key !== "Status" &&
        key !== "Jurisdiction" &&
        key !== "Type_Of_Vendor1" &&
        key !== "Type_Of_Vendor2" &&
        key !== "EvaluationCriteria_Used" &&
        key !== "IsAnyActiveContract" &&
        key !== "Constitution" &&
        key !== "UpdatedOn" &&
        key !== "Updated_On" &&
        key !== "Purpose" &&
        key !== "SpocNumber" &&
        key !== "AddressType" &&
        key !== "AddressNumber" &&
        key !== "Service_Owner" &&
        key !== "Consent_Updated_On" &&
        key !== "Consent_Status" &&
        key !== "ThirdParty_Type" &&
        key !== "isWorkflowInitiated"
      ) {
        acc[key] = obj[key];
      }

      return acc;
    }, {} as Record<string, any>);
  };

  const ButtonClickold = async (image: any) => {
    if (image) {
      const fileName = image;
      const response: any = await fileHandlerService.DownLoadAFile({ fileUrl: fileName });
      const { data } = response;
      const mimeType = mime.lookup(fileName.substring(fileName.lastIndexOf(".") + 1));
      setFileName(fileName);
      setFileUrl(`data:${mimeType};base64,${data}`);
      setOpenViewFileModel(true);
    }
  };
  const ButtonClick = async (bloburl: any) => {
    if (bloburl) {
      const fileName = decodeURIComponent(bloburl.substring(bloburl.indexOf("_") + 1));
      const response: any = await fileHandlerService.DownLoadAFile({ fileUrl: bloburl });
      const { data } = response;
      const mimeType = mime.lookup(fileName.substring(fileName.lastIndexOf(".") + 1));

      setFileName(fileName as string);
      setFileUrl(`data:${mimeType};base64,${data}`);
      setOpenViewFileModel(true);
    }
  };

  const handleDialogHide = () => {
    if (openViewFileModel) {
      setOpenViewFileModel(false);
    }
  };
  return (
    <>
      <div>
        {sections.map((section, index) => {
          const sectionData = mergedData[section];
          console.log(sectionData, "sectionDatasectionData");

          if (!sectionData) {
            // console.warn(`No data found for section: ${section}`);
            return null;
          }

          if (isMergedDataObject(sectionData)) {
            const oldSectionData = filterKeys(sectionData?.PreviousData);
            const newSectionData = filterKeys(sectionData?.CurrentData);
            oldSectionData.IsRegulated = oldSectionData.IsRegulated === true ? "Yes" : "No";
            oldSectionData.IsListed = oldSectionData.IsListed === true ? "Yes" : "No";
            newSectionData.IsRegulated = newSectionData.IsRegulated === true ? "Yes" : "No";
            newSectionData.IsListed = newSectionData.IsListed === true ? "Yes" : "No";
            console.log(oldSectionData, newSectionData, "newSectionData");

            return (
              <div key={`${section}-${index + 1}-Section`}>
                <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
                  {getVendorHeading(section)}
                </Typography>

                <Table sx={{ width: "100%", marginBottom: "24px" }}>
                  <TableHead>
                    <TableRow>
                      {columns.map((col) => (
                        <TableCell
                          key={`${col.Field}-column Section`}
                          sx={{
                            textAlign: "center",
                            fontFamily: "aptos-bold",
                            border: "2px solid #f1f1f1",
                          }}
                        >
                          {col.DisplayName}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {oldSectionData && Object.entries(oldSectionData).length > 0 ? (
                      Object.keys(oldSectionData).map((key, index) => {
                        // Check if the current key is valid
                        const oldValue = oldSectionData[key];
                        const newValue = newSectionData?.[key];                        
                        const keys = Object.keys(oldSectionData);

                        // if (oldValue && typeof oldValue === "object") {
                        //   oldValue.then((e: any) => {
                        //     setOldImage(e);
                        //   });
                        // }
                        // if (newValue && typeof newValue === "object") {
                        //   newValue.then((e: any) => {
                        //     setNewImage(e);
                        //   });
                        // }
                        return (
                          <TableRow key={key}>
                            <TableCell
                              sx={{
                                textAlign: "left",
                                border: "1px solid #e0e0e0",
                                fontWeight: "bold",
                              }}
                            >
                              {getVendorField(key)}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "left",
                                border: "1px solid #e0e0e0",
                              }}
                            >
                              {/* {typeof oldValue === "object" ? ( */}
                              {key === "Logo" ? (
                                <Box sx={{ width: "100%", maxHeight: "150px" }}>
                                  {oldValue !== "" ? (
                                    <Tooltip title="View">
                                      <IconButton
                                        onClick={() => {
                                          ButtonClick(oldValue?.toString());
                                        }}
                                      >
                                        <CustomSvg color={`${theme.palette.info.main}`} size={"20px"} name={"view"} />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    "N/A"
                                  )}
                                </Box>
                              ) : key === "DocType_Extn_Id" ? (
                                extractValues(oldValue?.toString() || "[]").join(", ")
                              ) : key === "UpdatedOn" ? (
                                formatDate(oldValue?.toString() || "")
                              )
                                : key == "Incorporated_Date" ? (
                                  formatDateWithOutHours(oldValue?.toString() || "")
                                ) : (
                                  oldValue?.toString() || "N/A"
                                )}
                            </TableCell>

                            <TableCell
                              sx={{
                                textAlign: "left",
                                border: "1px solid #e0e0e0",
                              }}
                            >
                              {/* {typeof newValue === "object" ? ( */}
                              {key === "Logo" ? (
                                <Box sx={{ width: "100%", maxHeight: "150px" }}>
                                  {newValue !== "" ? (
                                    <Tooltip title="View">
                                      <IconButton
                                        onClick={() => {
                                          ButtonClick(newValue?.toString());
                                        }}
                                      >
                                        <CustomSvg color={`${theme.palette.info.main}`} size={"20px"} name={"view"} />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    "N/A"
                                  )}
                                </Box>
                              ) : key === "DocType_Extn_Id" ? (
                                extractValues(newValue?.toString() || "[]").join(", ")
                              ) : key === "Updated_On" ? (
                                formatDate(newValue?.toString() || "")
                              ) : key == "Incorporated_Date" ? (
                                formatDateWithOutHours(oldValue?.toString() || "")
                              ) : (
                                newValue?.toString() || "N/A"
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #e0e0e0",
                            fontWeight: "bold",
                          }}
                          colSpan={columns.length + 1}
                        >
                          No Records Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            );
          }

          return (
            <div key={section}>
              {section !== "VendorServiceMappings" && (
                <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
                  {getVendorHeading(section)}
                </Typography>
              )}
              <Table sx={{ width: "100%", marginBottom: "24px" }}>
                <TableHead>
                  <TableRow>
                    {section !== "VendorAttachments" &&
                      section !== "VendorServiceMappings" &&
                      columns.map((col) => (
                        <TableCell
                          key={col.Field}
                          sx={{
                            textAlign: "center",
                            fontFamily: "aptos-bold",
                            border: "2px solid #f1f1f1",
                          }}
                        >
                          {col.DisplayName}
                        </TableCell>
                      ))}
                    {section == "VendorAttachments" &&
                      Attachmentscolumns.map((col) => (
                        <TableCell
                          key={col.Field}
                          sx={{
                            textAlign: "center",
                            fontFamily: "aptos-bold",
                            border: "2px solid #f1f1f1",
                          }}
                        >
                          {col.DisplayName}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                {section !== "VendorAttachments" && section !== "VendorServiceMappings" && (
  <TableBody>
    {Array.isArray(sectionData) && sectionData?.length > 0 ? (
      sectionData.map((item, index) => {
        let oldValue = item.PreviousData || {}; // Default to an empty object
        let newValue = item.CurrentData || {}; // Default to an empty object

        if (oldValue?.hasOwnProperty("DocType_Extn_Id")) {
          oldValue = { ...oldValue, DocType_Extn_Id: extractValues(oldValue.DocType_Extn_Id).join(", ") };
          newValue = { ...newValue, DocType_Extn_Id: extractValues(newValue.DocType_Extn_Id).join(", ") };
        }

        if (oldValue?.hasOwnProperty("UpdatedOn")) {
          oldValue = { ...oldValue, UpdatedOn: formatDate(oldValue.UpdatedOn) };
          newValue = { ...newValue, UpdatedOn: formatDate(newValue.UpdatedOn) };
        }

        const oldKeys = Object.keys(oldValue).filter(
          (key) =>
            !key.endsWith("Id") &&
            key !== "Status" &&
            key !== "AddressNumber" &&
            key !== "Pin_Value" &&
            key !== "AddressType" &&
            key !== "SpocNumber" &&
            key !== "Purpose" &&
            key !== "Vendor_Value"
        );
        const newKeys = Object.keys(newValue).filter(
          (key) =>
            !key.endsWith("Id") &&
            key !== "Status" &&
            key !== "AddressNumber" &&
            key !== "Pin_Value" &&
            key !== "AddressType" &&
            key !== "SpocNumber" &&
            key !== "Purpose" &&
            key !== "Vendor_Value"
        );
        const allKeys = new Set([...oldKeys, ...newKeys]);

        return Array.from(allKeys).map((key) => (
          <TableRow className={index % 2 == 0 ? BasicTableStyle.alternateRowBg : ""} key={`${index}-${key}`}>
            <TableCell
              sx={{
                textAlign: "left",
                border: "1px solid #e0e0e0",
                fontWeight: "bold",
              }}
            >
              {getVendorField(key)}
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                border: "1px solid #e0e0e0",
              }}
            >
              {oldValue[key]?.toString() || "-"}
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                border: "1px solid #e0e0e0",
              }}
            >
              {newValue[key]?.toString() || "-"}
            </TableCell>
          </TableRow>
        ));
      })
    ) : (
      <TableRow>
        <TableCell
          sx={{
            textAlign: "center",
            border: "1px solid #e0e0e0",
            fontWeight: "bold",
          }}
          colSpan={Attachmentscolumns.length + 1}
        >
          No Records Found
        </TableCell>
      </TableRow>
    )}
  </TableBody>
)}

{section === "VendorAttachments" && (
  <TableBody>
    {Array.isArray(sectionData) && sectionData?.length > 0 ? (
      sectionData.map((item, index) => {
        let oldValue = item.PreviousData || {}; // Default to an empty object
        let newValue = item.CurrentData || {}; // Default to an empty object

        if (oldValue?.hasOwnProperty("DocType_Extn_Id")) {
          oldValue = { ...oldValue, DocType_Extn_Id: extractValues(oldValue.DocType_Extn_Id).join(", ") };
          newValue = { ...newValue, DocType_Extn_Id: extractValues(newValue.DocType_Extn_Id).join(", ") };
        }

        if (oldValue?.hasOwnProperty("UpdatedOn")) {
          oldValue = { ...oldValue, UpdatedOn: oldValue.UpdatedOn ? formatDate(oldValue.UpdatedOn) : "N/A" };
          newValue = { ...newValue, UpdatedOn: newValue.UpdatedOn ? formatDate(newValue.UpdatedOn) : "N/A" };
        }

        if (oldValue?.hasOwnProperty("ExpiryDate")) {
          oldValue = { ...oldValue, ExpiryDate: oldValue?.ExpiryDate ? formatDate(oldValue.ExpiryDate) : "N/A" };
          newValue = { ...newValue, ExpiryDate: newValue?.ExpiryDate ? formatDate(newValue.ExpiryDate) : "N/A" };
        }

        if (oldValue?.hasOwnProperty("IsMandatory")) {
          oldValue = { ...oldValue, IsMandatory: oldValue.IsMandatory === true ? "Yes" : "No" };
          newValue = { ...newValue, IsMandatory: newValue.IsMandatory === true ? "Yes" : "No" };
        }

        if (oldValue?.hasOwnProperty("IsPerpetual")) {
          oldValue = { ...oldValue, IsPerpetual: oldValue.IsPerpetual === true ? "Yes" : "No" };
          newValue = { ...newValue, IsPerpetual: newValue.IsPerpetual === true ? "Yes" : "No" };
        }

        const oldKeys = Object.keys(oldValue).filter(
          (key) =>
            !key.endsWith("Id") &&
            key !== "Status" &&
            key !== "AddressNumber" &&
            key !== "Pin_Value" &&
            key !== "AddressType" &&
            key !== "SpocNumber" &&
            key !== "Purpose" &&
            key !== "Vendor_Value"
        );
        const newKeys = Object.keys(newValue).filter(
          (key) =>
            !key.endsWith("Id") &&
            key !== "Status" &&
            key !== "AddressNumber" &&
            key !== "Pin_Value" &&
            key !== "AddressType" &&
            key !== "SpocNumber" &&
            key !== "Purpose" &&
            key !== "Vendor_Value"
        );
        const allKeys = new Set([...oldKeys, ...newKeys]);

        return Array.from(allKeys).map((key) => (
          <TableRow className={index % 2 == 0 ? BasicTableStyle.alternateRowBg : ""} key={`${index}-${key}`}>
            <TableCell
              sx={{
                textAlign: "left",
                border: "1px solid #e0e0e0",
                fontWeight: "bold",
              }}
            >
              {getVendorField(key)}
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                border: "1px solid #e0e0e0",
              }}
            >
              {oldValue[key]?.toString()?.startsWith("http") ? (
                <Tooltip title="View">
                  <IconButton
                    onClick={() => {
                      ButtonClick(oldValue[key]?.toString());
                    }}
                  >
                    <CustomSvg color={`${theme.palette.info.main}`} size={"20px"} name={"view"} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Box>{oldValue[key]?.toString() || "-"}</Box>
              )}
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                border: "1px solid #e0e0e0",
              }}
            >
              {newValue[key]?.toString()?.startsWith("http") ? (
                <Tooltip title="View">
                  <IconButton
                    onClick={() => {
                      ButtonClick(newValue[key]?.toString());
                    }}
                  >
                    <CustomSvg color={`${theme.palette.info.main}`} size={"20px"} name={"view"} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Box>{newValue[key]?.toString() || "-"}</Box>
              )}
            </TableCell>
          </TableRow>
        ));
      })
    ) : (
      <TableRow>
        <TableCell
          sx={{
            textAlign: "center",
            border: "1px solid #e0e0e0",
            fontWeight: "bold",
          }}
          colSpan={Attachmentscolumns.length + 1}
        >
          No Records Found
        </TableCell>
      </TableRow>
    )}
  </TableBody>
)}

              </Table>


            </div>

          );
        })}
      </div>

      <CustomDialog
        hideBackdrop={true}
        onHide={handleDialogHide}
        maxWidth={"lg"}
        show={openViewFileModel}
        header={"File Preview"}
        key={'Vendor_Master_Viewchanges_in_assessment_Screen_File_Preview'}
        contentNode={
          <>
            <FilteViewer URL={fileUrl} fileName={fileName} />
          </>
        }
      />
      {/* <CustomDialog
            key={`VendorPreview_So_File_view`}
              hideBackdrop={true}
              onHide={() => setOpenViewFileModel(false)}
              show={openViewFileModel}
              header={"Document Viewer"}
              style={{ paper: { boxShadow: "0 12px 24px rgba(0, 0, 0, 0.1)" } }}
              contentNode={<DocViewer pluginRenderers={DocViewerRenderers} documents={[{ uri: fileUrl, fileName }]} />}
            /> */}
    </>
  );
};
