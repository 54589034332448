import React, { useEffect, useState } from "react";
import { Box, Grid, InputAdornment, Stack, IconButton } from "@mui/material";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomNumberField from "../../common/dynamicInputs/CustomNumberField";
import CustomCheckBox from "../../common/dynamicInputs/CustomCheckBox";

interface AddApiCatalogProps {
  view?: boolean;
  isEditData?: any;
  // edit?: boolean;
  onClose?: any;
  onSubmit: (data: any) => void;
  // addressType: any[];
  ParameterTableValue: any[]
}

interface FormData {
  Parameter: String;
  DataType: string;
  CLife_Json_Field: String;
  API_Json_Field: string;
  Width: number;
  Purpose: string;
  IsPrimary: boolean;
}

export const AddApiParameter: React.FC<AddApiCatalogProps> = ({ view, isEditData, onClose, onSubmit, ParameterTableValue }) => {
console.log(isEditData,"dat");

  let formData: any = {
    APIMasterID: isEditData ? isEditData?.APIMasterID : 0,
    API_ParameterID: isEditData ? isEditData?.API_ParameterID : 0,
    ApiName: isEditData ? isEditData?.APIName : "",
    ApiCode: isEditData ? isEditData?.APICode : "",
    NoOfParameters: isEditData ? isEditData?.ParameterNo : "",
    User_Name: isEditData ? isEditData?.User_Name : "",
    CLife_Json_Field: isEditData ? isEditData?.CLife_Json_Field : "",
    API_Json_Field: isEditData ? isEditData?.API_Json_Field : "",
    Parameter: isEditData ? isEditData?.Parameter : "",
    ParameterNo: isEditData ? isEditData?.ParameterNo : 1,
    Purpose: isEditData ? isEditData?.Purpose : "",
    IsPrimary: isEditData ? isEditData?.IsPrimary : false,
    IsPrimaryDisplay: isEditData ? isEditData.IsPrimary ? "Yes" : "No" : "Yes",
    Status: isEditData ? isEditData?.Status : 1,
    Status_Value: isEditData ? isEditData?.Status_Value : 1,
    DataType: isEditData ? isEditData?.DataType : "",
    Width: isEditData ? isEditData?.Width : "",
    IsActive: isEditData ? isEditData?.IsActive : true,
    isActive: isEditData ? isEditData?.isActive : true,
  };
  const {
    handleSubmit: handleParameterForm,
    reset,
    setValue,
    formState: { errors: error },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >

      <Stack spacing={3}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <Controller
                name="Parameter"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Parameter is required",
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"Parameter"}
                    name={"Parameter"}
                    value={field.value}
                    //    disabled={true}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.Parameter)}
                    helperText={error.Parameter && error.Parameter.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="DataType"
                control={control}
                defaultValue={""}
                rules={{
                  required: "DataType is required",
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"Data type"}
                    name={"Country"}
                    value={field.value}
                    required={true}
                    //    disabled={true}
                    onChange={field.onChange}
                    error={Boolean(error.DataType)}
                    helperText={error.DataType && error.DataType.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="CLife_Json_Field"
                control={control}
                defaultValue={""}
                rules={{
                  required: "CLife JSON Field is required",
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"CLife JSON Field "}
                    name={"CLife_Json_Field"}
                    value={field.value}
                    //    disabled={true}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.CLife_Json_Field)}
                    helperText={error.CLife_Json_Field && error.CLife_Json_Field.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="API_Json_Field"
                control={control}
                defaultValue={""}
                rules={{
                  required: "API JSON Field is required",
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"API JSON Field"}
                    name={"APIJSONField"}
                    value={field.value}
                    required={true}
                    //    disabled={true}
                    onChange={field.onChange}
                    error={Boolean(error.API_Json_Field)}
                    helperText={error.API_Json_Field && error.API_Json_Field.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="Width"
                control={control}
                //defaultValue={}
                rules={{
                  required: "Width is required",
                }}
                render={({ field }) => (
                  <CustomNumberField
                    label={"Width"}
                    name={"Width"}
                    value={field.value}
                    //    disabled={true}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.Width)}
                    helperText={error.Width && error.Width.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="Purpose"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Purpose is required",
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"Purpose"}
                    name={"Country"}
                    value={field.value}
                    required={true}
                    //    disabled={true}
                    onChange={field.onChange}
                    error={Boolean(error.Purpose)}
                    helperText={error.Purpose && error.Purpose.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Box sx={{ pt: 1 }}>
                <Controller
                  name="IsPrimary"
                  control={control}
                  render={({ field }) => (
                    <CustomCheckBox
                      isStyle={true}
                      //readOnly={props.view}
                      value={field.value}

                      alignItems="center"
                      label="Is primary"
                      onChange={(e) => {
                        setValue("IsPrimary", e.target.checked);
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <CustomButton
              variant="outlined"
              name={"Cancel"}
              onClick={(e, value) => {
                onClose();
              }}
            />
            <CustomButton variant="contained" name={"Save"} onClick={handleParameterForm(onSubmit)} size="small" />
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};
