import * as React from 'react';
import { styled , useTheme } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';
import CustomSvg from '../CustomSvg';

interface DynamicToggleButtonProps {
    list : any[];
    value : any;
    onchange : any
}
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: 0,
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
}));

export const  DynamicToggleButton : React.FC<DynamicToggleButtonProps> = ({onchange , value , list}) =>  {

    const theme = useTheme();



  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: any,
  ) => {
    onchange(newAlignment)
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={(theme) => ({
          display: 'flex',
          border: `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
        })}
      >
        <StyledToggleButtonGroup
          size="small"
          value={value}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
        >
{list.map((item: any, index: number) => (
  <ToggleButton key={item} value={index} aria-label="left aligned">

    <CustomSvg color={`${theme.palette.primary.main}`} size={"18px"} name={`${item}`} />
  </ToggleButton>
))}

         
          
        </StyledToggleButtonGroup>
     
      </Paper>
    </div>
  );
}