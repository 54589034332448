import React, { useEffect, useState } from "react";
import { Box, Grid, InputAdornment, Stack, IconButton } from "@mui/material";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomNumberField from "../../common/dynamicInputs/CustomNumberField";

interface AddApiCatalogProps {
  view?: boolean;
  isEditData?: any;
  onClose?: any;
  onSubmit: (data: any) => void;
  FieldsTableValue: any[];
}

interface FormData {
  CLife_Json_Field: String;
  API_Json_Field: string;
  DataType: number;
  Width: number;
  Context: string;
}

export const AddApiFields: React.FC<AddApiCatalogProps> = ({ view, isEditData, onClose, onSubmit, FieldsTableValue }) => {
  let formData: any = {
    FieldNo: isEditData ? isEditData?.FieldNo : 0,
    ApiName: isEditData ? isEditData?.APIName : "",
    Context: isEditData ? isEditData?.Context : "",
    DataType: isEditData ? isEditData?.DataType : "",
    Field_ID: isEditData ? isEditData?.Field_ID : 0,
    API_Json_Field: isEditData ? isEditData?.API_Json_Field : "",
    CLife_Json_Field: isEditData ? isEditData?.CLife_Json_Field : "",
    Width: isEditData ? isEditData?.Width : "",
    Format: isEditData ? isEditData?.Format : "",
    Status_Value: isEditData ? isEditData?.Status_Value : "",
    Status: isEditData ? isEditData?.Status : 1,
    IsActive: isEditData ? isEditData?.IsActive : true,
    isActive: isEditData ? isEditData?.isActive : true,
  };
  const {
    handleSubmit: handleFieldForm,
    reset,
    setValue,
    formState: { errors: error },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >

      <Stack spacing={3}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <Controller
                name="CLife_Json_Field"
                control={control}
                defaultValue={""}
                rules={{
                  required: "CLife JSON Field is required",
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"CLife JSON Field "}
                    name={"CLife_Json_Field"}
                    value={field.value}
                    //    disabled={true}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.CLife_Json_Field)}
                    helperText={error.CLife_Json_Field && error.CLife_Json_Field.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="API_Json_Field"
                control={control}
                defaultValue={""}
                rules={{
                  required: "API JSON Field is required",
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"API JSON Field"}
                    name={"APIJSONField"}
                    value={field.value}
                    required={true}
                    //    disabled={true}
                    onChange={field.onChange}
                    error={Boolean(error.API_Json_Field)}
                    helperText={error.API_Json_Field && error.API_Json_Field.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="DataType"
                control={control}
                //defaultValue={}
                rules={{
                  required: "Data Type is required",
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"DataType"}
                    name={"DataType"}
                    value={field.value}
                    //    disabled={true}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.DataType)}
                    helperText={error.DataType && error.DataType.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="Width"
                control={control}
                //defaultValue={}
                rules={{
                  required: "Width is required",
                }}
                render={({ field }) => (
                  <CustomNumberField
                    label={"Width"}
                    name={"Width"}
                    value={field.value}
                    //    disabled={true}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.Width)}
                    helperText={error.Width && error.Width.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="Context"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Purpose is required",
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"Purpose"}
                    name={"Context"}
                    value={field.value}
                    required={true}
                    //    disabled={true}
                    onChange={field.onChange}
                    error={Boolean(error.Context)}
                    helperText={error.Context && error.Context.message?.toString()}
                  />
                )}
              />
            </Grid>

          </Grid>

          <Grid
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <CustomButton
              variant="outlined"
              name={"Cancel"}
              onClick={(e, value) => {
                onClose();
              }}
            />
            <CustomButton variant="contained" name={"Save"} onClick={handleFieldForm(onSubmit)} size="small" />
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};
