import { AxiosError, AxiosResponse } from "axios";
import { Roles_AND_RESPONSIBILITY_URL } from "./endpoints/contractRR";

import { axiosInstance } from "../../services/config/axiosConfig";
import { axiosInstance as cmInstance ,axiosInstanceWithoutVersion} from "./config/axiosConfig";

const getRolesAndResponsiblityList = (): Promise<any> =>
  axiosInstance
    .get(Roles_AND_RESPONSIBILITY_URL.GetRolesAndResponsibility)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getProjectDetail = (requesrParams: any): Promise<any> =>
  axiosInstance
    .get(Roles_AND_RESPONSIBILITY_URL.getProjectDetail, { params: requesrParams })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addRoleAndResponsiblity = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(Roles_AND_RESPONSIBILITY_URL.AddRolesAndResponsibility, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateRoleAndResponsiblityWorkflow = (requestBody: any): Promise<any> =>
  cmInstance
    .put(Roles_AND_RESPONSIBILITY_URL.UpdateRRWorkFlowprocess, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateRoleAndResponsiblity = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(Roles_AND_RESPONSIBILITY_URL.UpdateRolesAndResponsibility, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteRoleAndResponsiblity = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(Roles_AND_RESPONSIBILITY_URL.DeleteRolesAndResponsibility, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getRoleAndResponsiblityById = (req: any): Promise<any> =>
  axiosInstance
    .get(Roles_AND_RESPONSIBILITY_URL.GetRolesAndResponsibilityById, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getIsProjectExists = (req: any): Promise<any> =>
  axiosInstance
    .get(Roles_AND_RESPONSIBILITY_URL.IsProjectExists, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getRRMasterByworkflowId = (req: any): Promise<any> =>
  axiosInstance
    .get(Roles_AND_RESPONSIBILITY_URL.GetRRMasterByworkflowId, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getGetVCWorkflowProcesses = (req: any): Promise<any> =>
  cmInstance
    .post(Roles_AND_RESPONSIBILITY_URL.GetVCWorkflowProcesses, req)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateWorkFlowProcess = (req: any): Promise<any> =>
  cmInstance
    .put(Roles_AND_RESPONSIBILITY_URL.UpdateWorkflowInstance, req)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteRRSetup = (req: any): Promise<any> =>
  axiosInstance
    .put(Roles_AND_RESPONSIBILITY_URL.DeleteRolesAndResponsibility, req)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const saveSigningAuthority = (requestBody: any): Promise<any> =>
  cmInstance
    .post(
      requestBody.eStampType === "online"
        ? Roles_AND_RESPONSIBILITY_URL.saveSigningAuthorityOnline
        : Roles_AND_RESPONSIBILITY_URL.saveSigningAuthorityOffline,
      requestBody
    )
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getArticleByLocation = (req: any): Promise<any> =>
  cmInstance
    .get(Roles_AND_RESPONSIBILITY_URL.getArticleByLocation, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getSigningStatus = (req: any): Promise<any> =>
  cmInstance
    .post(`${Roles_AND_RESPONSIBILITY_URL.getSigningStatus}?contractNo=${req.contractNo}`, {})
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getSigningAuthorities = (req: any): Promise<any> =>
  cmInstance
    .get(`${Roles_AND_RESPONSIBILITY_URL.getSigningAuthorities}?contractRefNo=${req.contractNo}`, {})
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const saveTempSigningAuthorities = (requestBody: any): Promise<any> =>
  cmInstance
    .post(Roles_AND_RESPONSIBILITY_URL.saveSigningAuthorities, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const initiateEmbedSign = (reqBody: any): Promise<any> =>
  cmInstance
    .post(`${Roles_AND_RESPONSIBILITY_URL.initiateEmbedSign}`, reqBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        return response;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const downloadWorkflowDocument = (req: any): Promise<any> =>
  cmInstance
    .post(`${Roles_AND_RESPONSIBILITY_URL.downloadWorkflowDocument}?contractNo=${req.contractNo}`, {})
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const sendReminder = (req: any): Promise<any> =>
  cmInstance
    .post(`${Roles_AND_RESPONSIBILITY_URL.sendReminder}?contractNo=${req.contractNo}`, {})
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const convertTOPDF = (req: any): Promise<any> =>
  axiosInstanceWithoutVersion
    .post(`${Roles_AND_RESPONSIBILITY_URL.convertTOPDF}`, req)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const contractRolesAndResponsibilityService = {
  getRolesAndResponsiblityList,
  addRoleAndResponsiblity,
  updateRoleAndResponsiblity,
  deleteRoleAndResponsiblity,
  getRoleAndResponsiblityById,
  getIsProjectExists,
  getProjectDetail,
  getRRMasterByworkflowId,
  getGetVCWorkflowProcesses,
  updateWorkFlowProcess,
  updateRoleAndResponsiblityWorkflow,
  deleteRRSetup,
  saveSigningAuthority,
  getArticleByLocation,
  getSigningStatus,
  initiateEmbedSign,
  downloadWorkflowDocument,
  sendReminder,
  getSigningAuthorities,
  saveTempSigningAuthorities,
  convertTOPDF
};

export default contractRolesAndResponsibilityService;
