import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import {
  addAQM,
  deleteAQM,
  getAQMHistory,
  getAQMList,
  updateAQMAddandEdit,
  updateAQMVersionHistory,
} from "../../../store/slices/assessmentQuestionnaireMaster";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import { AddPreviewAssessmentQuestionnaireMaster } from "./AddPreviewAssesmentQuestionnaire";
import { AQMMapping } from "../../vendorOnBoarding/aqmMapping/AQMMapping";
import {
  deleteSaveandDraft,
  getAllSaveandDraftList,
  updateSaveasDraftAddandEdit,
} from "../../../store/slices/saveasdraft";
import { RolesandResponsibility } from "../../vendorOnBoarding/rolesAndResponsibility/RolesAndResponsibility";
// import { HandleRecordLockCheck, recordLockGet } from "../../recordLock/RecordLock";
import { getAssessmentCategoryList } from "../../../store/slices/assessmentCategoryMasterData";
import { FormIdConfig, ModuleNames, ToasterActivityConfig } from "../../../config/config";
import { getDateOrConvertSameTime } from "../../../utils/constants";
import { join } from "path";
import { getRiskRateList } from "../../../store/slices/riskRateMasterData";
import { getServiceCatalogList } from "../../../store/slices/serviceCatalog";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";

let columns: any = {
  table_name: "Assessment Questionnaire Master",
  isAction: true,
  columns: [
    {
      Field: "Question_Title",
      FieldType: "string",
      DisplayName: "Question Title",
    },

    {
      Field: "Question",
      FieldType: "string",
      DisplayName: "Question",
    },
    {
      Field: "Question_CategoryId_Value",
      FieldType: "string",
      DisplayName: "Category",
    },
    {
      Field: "Applicable_For_Value",
      FieldType: "string",
      DisplayName: "Applicable For",
    },
    {
      Field: "IsEvidenceRequired",
      FieldType: "string",
      DisplayName: "Evidence Required",
    },
    {
      Field: "IsActive",
      FieldType: "string",
      DisplayName: "Status",
    },
    {
      Field: "Updated_By",
      FieldType: "string",
      DisplayName: "Modified By",
    },
  ],
};

const AssessmentQuestionnaireMaster = () => {
  const dispatch = useAppDispatch();
  const [HeaderColumn, setHeaderColumn] = useState(columns);
  const [HistoryColumn, setHistoryColumn] = useState([]);
  const [AssessmentQuestData, setAssessmentQuestData] = useState<any[]>([]);
  const [isShowDraft, setisShowDraft] = useState<any>(1);
  const [applicableRiskData, setApplicableRiskData] = useState<any[]>([]);
  const [serviceCatalog, setserviceCatalog] = useState<any[]>([]);
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { ServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);
  const { RiskRateDataActive } = useSelector((state: RootState) => state.RiskRateData);

    const { currentpageTitleData } = useSelector(
          (state: RootState) => state.FormTitleMasterData
        );
  

  const columns_version: any = {
    table_name: "Assessment Questionnaire Master Version History",
    isAction: false,
    columns: [],
  };
  const { draftdata, isLoadingDraft, isDraftAdded, isDraftUpdated, isDraftDeleted, DraftToasterMsg } = useSelector(
    (state: RootState) => state.saveAsDraft
  );

  const [permssionPage, setPermissionForpage] = useState<any>();
  const [addForm, setAddForm] = useState<any>(false);
  const [isEditData, setisEditData] = useState<any>(null);
  const [isDraftData, setIsDraftDate] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [Option, setOption] = useState("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [aQMDraft, setAQMDraft] = useState<any>([]);
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const { AQMdataTable, isAQMAdded, isAddEditAQMLoading , isAQMDeleted, isAQMUpdated, aQMToasterMsg, aQMversionHistory } = useSelector(
    (state: RootState) => state.aQMData
  );

  const { handleRecordLockUpdate } = RecordLockRelease();

  useEffect(() => {
    callPAgeLoadData();
    callDraft();
  }, []);

  const callPAgeLoadData = () => {
    dispatch(getAQMList({ FormID: 15 }));
    dispatch(getAssessmentCategoryList({ FormID: 40 }));
    dispatch(
      getRiskRateList({
        FormID: 2,
      })
    );
    dispatch(getServiceCatalogList());
  };

  useEffect(() => {
    let modifiedData: any[] = [];
    if (draftdata && Array.isArray(draftdata)) {
      draftdata &&
        draftdata.forEach((item: any) => {
          let JsonData = JSON.parse(item.DraftData);
          JsonData.DraftStatus = item.DraftStatus;
          JsonData.DraftId = item.DraftId;
          modifiedData.push(JsonData);
        });
    }
    setAQMDraft(modifiedData);
  }, [draftdata]);

  useEffect(() => {
    setserviceCatalog(ServiceCatlogdata);
  }, [ServiceCatlogdata]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);
  useEffect(() => {
    if (RiskRateDataActive) {
      setApplicableRiskData(RiskRateDataActive || []);
    }
  }, [RiskRateDataActive]);

  useEffect(() => {
    dispatch(updateLoader(isAddEditAQMLoading));
  }, [isAddEditAQMLoading]);

  useEffect(() => {
    setAssessmentQuestData(AQMdataTable);
    console.log(AQMdataTable, "AQMdataTable");
  }, [AQMdataTable]);

 

  const handleOpen = async (data: any, option: string) => {

  
    if (option === "delete") {
      setIsDeleteData(data);
    } else if (option === "history") {
      dispatch(
        getAQMHistory({
          Assessment_Questionaire_Id: data.Assessment_Questionaire_Id,
          formID: FormIdConfig.AQMasterFormId,
        })
      );
    } else if (option === "edit") {
      if (isShowDraft !== 0) {
        setIsEdit(true);
  
                  
          setIsAddorEdit(true);
          setOption(option);
          setIsDraftDate(null);
          setisEditData(data);
        
      } else {
        setOption("add");
        setIsDraftDate(data);
        setisEditData(null);
        setIsAddorEdit(true);
      }
    } else if (option === "view") {
      setOption(option);
      setisEditData(data);
      setIsAddorEdit(true);
    }
  };

  const callDraft = () => {
    const dataObj = {
      UserId: userLoginResponse?.UserId,
      ModuleName: "AssessmentQuestionaire",
      ScreenName: "AssessmentQuestionaire",
    };
    dispatch(getAllSaveandDraftList(dataObj));
  };

  useEffect(() => {
    if (isDraftAdded) {
      dispatch(updateSaveasDraftAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
      setisShowDraft(0);
      callDraft();
    } else if (isDraftUpdated) {
      // setUserUpdated(true);
      dispatch(updateSaveasDraftAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
      callDraft();
    } else if (isDraftDeleted) {
      dispatch(updateSaveasDraftAddandEdit(false));
      // dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));

      callDraft();
    }
  }, [isDraftAdded, isDraftUpdated, isDraftDeleted]);

  useEffect(() => {
    if (isAQMAdded === true) {
      callDraft();
      callPAgeLoadData();
      setisShowDraft(1);
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: aQMToasterMsg }));
      dispatch(updateAQMAddandEdit(false));
    }

    if (isAQMUpdated === true) {
     
      callPAgeLoadData();
      handleRecordLockUpdate();
      dispatch(updateToaster({ isToaster: true, toasterMsg: aQMToasterMsg }));
      dispatch(updateAQMAddandEdit(false));
    }
  
  }, [isAQMAdded, isAQMUpdated, isAQMDeleted]);

  const deleteAQMList = () => {
    let formData = { 
      // ...isDeleteData,
      ActionTaken: "Edit",
      ApproverComments : "" ,
      Answer_Type: isDeleteData?.Answer_Type,
      ApplicableRisks: isDeleteData?.ApplicableRisks,
      Applicable_For: isDeleteData?.Applicable_For,
      Approved_By: user?.UserName,
      Approved_On: getDateOrConvertSameTime(),
      Assessment_Questionaire_Id: isDeleteData?.Assessment_Questionaire_Id,
      Assessment_TypeID: isDeleteData?.Assessment_TypeID,
      CreatedBy: isDeleteData?.CreatedBy,
      EvidenceRequired: isDeleteData?.EvidenceRequired,
      Question:isDeleteData?.Question,
      Question_CategoryId: isDeleteData?.Question_CategoryId,
      Question_CategoryId_Level2: isDeleteData?.Question_CategoryId_Level2,
      Question_CategoryId_Level3: isDeleteData?.Question_CategoryId_Level3,
      Question_Title: isDeleteData?.Question_Title,
      Responder: isDeleteData?.Responder,
      SME: isDeleteData?.SME,
      SMEVerified: isDeleteData?.SMEVerified,
      Status: isDeleteData?.Status,
      Updated_On: getDateOrConvertSameTime(),
      UpdatedBy: user?.UserName,
      Updated_By: user?.UserName,
      assessmentQuestionairebestScores: isDeleteData.AQMBestScoreJson ? JSON.parse(isDeleteData.AQMBestScoreJson) : [],
      AssessmentQuestionaireServiceMappings: isDeleteData.AQMServiceMappingJson
        ? JSON.parse(isDeleteData.AQMServiceMappingJson)
        : [],
      assessmentQuestionaireRatings: isDeleteData.AQMRatingJson ? JSON.parse(isDeleteData.AQMRatingJson) : [],
      IsActive: isDeleteData?.IsActive === "Active" ? false : true,
    };

    let RiskListDataSheet = applicableRiskData
      ?.filter((item: any) => JSON.parse(isDeleteData?.ApplicableRisks || '[]')?.includes(item?.RiskCategory_Id))
      .map((item: any) => item.RiskCategory)
      .join(",");
     

      let serviceMappingListDataSheet: any = serviceCatalog
      ?.filter((item: any) => {
        const parsedMapping = isDeleteData?.AQMServiceMappingJson 
          ? JSON.parse(isDeleteData.AQMServiceMappingJson || '[]') 
          : [];
        return parsedMapping.some((mappingItem: any) => mappingItem.ServiceCatalog_Id === item.ServiceCatalogId);
      })
      .map((item: any) => item.ServiceName)
      .join(",");

      
    let JsonDataForDisplay: any = {
      "Question Title": formData.Question_Title,
      Question: formData.Question,
      "Applicable For": isDeleteData?.Applicable_For_Value || "",
      "Category I": isDeleteData?.Question_CategoryId_Value || "",
      "Category II": isDeleteData?.Question_CategoryId_Level2_Value || "",
      "Category III":isDeleteData?.Question_CategoryId_Level3_Value || "",
      "Evidence Required": isDeleteData.EvidenceRequired == true ? "Evidence Required" : "Evidence Not Required",
      "Answer Type": isDeleteData?.Answer_Type === true ? "Multiple Choice" : "Free Text",
      "Applicale Risk": RiskListDataSheet || '',
      "Assessment Type": isDeleteData?.Assessment_TypeID_Value ? JSON.parse(isDeleteData?.Assessment_TypeID_Value  || "[]")?.map((item: any) => item?.Name).join(',') : "",
      Respondor: isDeleteData?.Responder_Value || "",
      Department: isDeleteData?.SME_Value || "",
      "SME verification": isDeleteData?.SMEVerified ? "Yes" : "No",
       Score: isDeleteData.AQMRatingJson || "",
      "Service Mapping": serviceMappingListDataSheet || "",
       Status: isDeleteData?.IsActive === "Active" ? "InActive" : "Active",
      "Modified By": user?.UserName,
    };
  
    console.log(JsonDataForDisplay, "JsonDataForDisplay" , isDeleteData);
    let FinalForm = {
      assessmentQuestionaire: formData,
      ModuleName: ModuleNames.AQMaster,
      UserId: userLoginResponse?.UserId,
      Activity: isDeleteData?.IsActive === "Active" ? ToasterActivityConfig.inactive : ToasterActivityConfig.active,
      UpdatedBy: user?.UserName,
      DataForDisplay: JSON.stringify(JsonDataForDisplay),
      CreatedBy: user?.UserName,
      Form_Id: FormIdConfig.AQMasterFormId,
      ActionTaken: isDeleteData?.IsActive === "Active" ? ToasterActivityConfig.inactive : ToasterActivityConfig.active,
    };
    dispatch(addAQM(FinalForm));
  };

  useEffect(() => {
    console.log(aQMversionHistory, "versionHistory");

    if (aQMversionHistory.AQMVHeader && aQMversionHistory.AQMVHeader.length > 0) {
      setIsVersionHistory(true);
      let re_columns = {
        ...columns_version,
        columns: aQMversionHistory.AQMVHeader,
      };
      setVersionHistoryData(re_columns);
    }
  }, [aQMversionHistory]);

  const setAddFormcloseoropen = (value: any) => {
    setisEditData(null);
    setIsDraftDate(null);
    setOption("");
    setIsAddorEdit(value);
  };

  const deleteDraftList = () => {
    let formData = {
      DraftId: isDeleteData?.DraftId,
      CreatedBy: user?.UserName,
    };

    dispatch(deleteSaveandDraft(formData));
  };

  return (
    <>
      {!isAddorEdit ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Typography variant="h6" color={"text.primary"}>
                                         {" "}
                                         {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                                         {/* Holiday Master */}
                                     </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={HeaderColumn}
                rows={isShowDraft ? AssessmentQuestData : aQMDraft}
                handleAction={handleOpen}
                handleAdd={() => setAddFormcloseoropen(true)}
                enableZoomIcon={true}
                showhistory={isShowDraft ? true : false}
                isDraftView={true}
                disableselect={isShowDraft ? false : true}
                draftCount={aQMDraft.length}
                isEnableDraft={isShowDraft}
                handleIsDraft={(val: any) => setisShowDraft(val)}
                showAdd={isShowDraft ? permssionPage?.IsAdd === 1 : false}
                showDelete={isShowDraft ? permssionPage?.IsDelete === 1 : false}
                showView={isShowDraft ? permssionPage?.IsView === 1 : false}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={isShowDraft ? permssionPage?.IsExport === 1 : false}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                pageName="Assessment Questionnaire"
                isEnableRecordLock = {isShowDraft ?  { IdField : "Assessment_Questionaire_Id" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                  FormID : FormIdConfig.AQMasterFormId ,
                  MenuID : currentPagePermissionData?.MenuId,
                  IsCheckPending : true  }}:null }
              />
            </Box>
          </Stack>

          <CustomDialog
            show={isVersionHistory}
            onHide={() => {
              dispatch(updateAQMVersionHistory([]));
              setIsVersionHistory(false);
            }}
            maxWidth={"lg"}
            header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Assessment Questionnaire"} Version History`}
            contentNode={
              <>
                {" "}
                <MaterialDynamicGrid
                  data={versionHistoryData}
                  rows={aQMversionHistory.AQMRecords}
                  handleAction={handleOpen}
                  enableZoomIcon={false}
                  showhistory={false}
                  showDelete={permssionPage?.IsDelete === 1}
                  showView={permssionPage?.IsView === 1}
                  showEdit={permssionPage?.IsEdit === 1}
                  showExports={permssionPage?.IsExport === 1}
                  userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                  pageName="Assessment Questionnaire History"
                 
                />
              </>
            }
          />

          <ModalPopUp
            onDeleteMethod={() => deleteAQMList()}
            isDelete={true}
            onHide={() => setIsDeleteData(null)}
            ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
            show={isDeleteData ? true : false}
            header={"Confirm"}
            text={`Are you sure you want to  ${
              isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
            } the selected Assessment Questionnaire ?`}
          />
        </Box>
      ) : (
        <AddPreviewAssessmentQuestionnaireMaster
          isDraftData={isDraftData}
          view={Option == "view" ? true : false}
          edit={Option == "edit" ? true : false}
          isEditData={isEditData}
          onClose={() => {
            if (Option == "edit" && isShowDraft !== 0) {
              handleRecordLockUpdate();
            }
            setAddFormcloseoropen(false);
          }}
          ModuleId={permssionPage?.MenuId}
        />
      )}
    </>
  );
};
export default AssessmentQuestionnaireMaster;
