import {
  Box,
  FormHelperText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import BasicTableStyle from "../../../../common/dynamicDataGrid/basicTable.module.scss";
import { useTheme } from "@mui/material/styles";
import { LovConfig, vendorAssetStatus, VOBWorflowPages } from "../../../../config/config";
import CustomSvg from "../../../../common/CustomSvg";
import { AssessmentDetailContext } from "../CustomerOnBoardingAssessment";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import fileHandlerService from "../../../../store/services/fileHandlerService";
import * as mime from "react-native-mime-types";
import CustomDialog from "../../../../common/dynamicDialog/CustomDialog";
import FilteViewer from "../../../../common/fileViewer/FilteViewer";
import { validateEachCOBField } from "../AssessmentValidation";

interface AssessmentResponseCustomerOBProps {
  isEdit?: boolean;
  isView?: boolean;
  isPreviewMode?: boolean;
  PageName: string;
  iscompleteDo: boolean;
  isAvailableDo: boolean;
  onActiveHeadersUpdate? : (head : any) => void
  
}

export const processQuestionsCOB = async (QuestionListDub: any, PageName: any, ApproveList: any) => {
  const ModifiedValue = await Promise.all(
    QuestionListDub.map(async (item: any, index: any) => {
      const isValid = await validateEachCOBField(PageName, item);
      const ans = item?.Answer_Type
        ? item?.Options.find((oitem: any) => oitem?.Assessment_Questionnaire_OptionId == item?.Response_In_Option)
            ?.Attribute_Response
        : item?.Response_In_Text;

      const SO_ReviewStatus_value =
        ApproveList.find((stitem: any) => stitem.LovId === item.SO_ReviewStatus)?.Options || "";
      const Do_ReviewStatus_value =
        ApproveList.find((stitem: any) => stitem.LovId === item.DO_ReviewStatus)?.Options || "";

      return {
        ...item,
        Sno: index + 1,
        completed_status: isValid,
        Answer_Type_value: item?.Answer_Type ? "MultiChoice" : "Free Text",
        answer: ans || "",
        SO_ReviewStatus_value,
        Do_ReviewStatus_value,
        IsDo_value: item.IsDo ? "Yes" : "No",
        IsAddInPM_value: item.IsAddInPM ? "Yes" : "No",
      };
    })
  );
  return ModifiedValue || [];
};

const AssessmentResponseCustomerOB: React.FC<AssessmentResponseCustomerOBProps> = ({
  isEdit,
  isView,
  isPreviewMode,
  PageName,
  iscompleteDo,
  isAvailableDo,
  onActiveHeadersUpdate
}) => {
  const theme = useTheme();
  const Customercontext = useContext(AssessmentDetailContext);
  const [responseData, setResponseData] = useState<any[]>([]);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const {
    selectedQuestion,
    QuestionList,
    validationErrors,
    setselectedQuestionindex,
    onTableValueUpdate,
  } = Customercontext!;
  const [ApproveList, setApproveList] = useState<any[]>([]);

  const [isfilePreview, setIsfilePreview] = useState(false);
  const [isfilePreviewurl, setIsfilePreviewurl] = useState<any>("");
  const [viewfileName, setIsviewfileName] = useState<any>("");

  const onButtonClick = async (bloburl: any) => {
    if (bloburl) {
      const fileName = decodeURIComponent(bloburl.substring(bloburl.indexOf("_") + 1));
      const response: any = await fileHandlerService.DownLoadAFile({ fileUrl: bloburl });
      const { data } = response;
      const mimeType = mime.lookup(fileName.substring(fileName.lastIndexOf(".") + 1));
      setIsviewfileName(fileName as string);
      setIsfilePreviewurl(`data:${mimeType};base64,${data}`);
      setIsfilePreview(true);
    }
  };
  const headers = useMemo(
    () => [
      {
        label: "",
        Field: "Sno",
        condition: true,
        render: (row: any) => (
          <Box
            sx={{
              height: "18px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomSvg
              color={row.completed_status ? theme.palette.success.main : theme.palette.error.main}
              size={"18px"}
              name={row.completed_status ? "sucess_tick" : "error_cross"}
            />
          </Box>
        ),
      },
      {
        label: "Questions",
        Field: "Question",
        condition: true,
        render: (row: any, index: number) => (
          <span onDoubleClick={() => onclickQuestion(index)} style={{ cursor: "pointer" }}>
            {row?.Question}
          </span>
        ),
      },
      { label: "Response", Field: "answer", condition: true, render: (row: any) => row?.answer },
      {
        label: "Type",
        Field: "Answer_Type_value",
        condition: true,
        render: (row: any) => row?.Answer_Type_value,
      },
      {
        label: "SO Decision",
        Field: "SO_ReviewStatus_value",
        condition: PageName === VOBWorflowPages.SOReview || PageName === VOBWorflowPages.DoReview || isPreviewMode,
        render: (row: any) => (
          <span
            style={{
              fontWeight: "700",
              color:
                row?.SO_ReviewStatus_value === vendorAssetStatus.approve
                  ? theme.palette.success.main
                  : theme.palette.error.main,
            }}
          >
            {row?.SO_ReviewStatus_value}
          </span>
        ),
      },
      {
        label: "SO Review",
        Field: "SO_Remarks",
        condition: PageName === VOBWorflowPages.SOReview || PageName === VOBWorflowPages.DoReview || isPreviewMode,
        render: (row: any) => row?.SO_Remarks,
      },
      {
        label: "Send To DO",
        Field: "IsDo_value",
        condition: (PageName === VOBWorflowPages.SOReview && isAvailableDo) || isPreviewMode,
        render: (row: any) => row?.IsDo_value,
      },
      {
        label: "DO Status",
        Field: "Do_ReviewStatus_value",
        condition:
          PageName === VOBWorflowPages.DoReview ||
          (PageName === VOBWorflowPages.SOReview && iscompleteDo) ||
          isPreviewMode,
        render: (row: any) => (
          <span
            style={{
              fontWeight: "700",
              color:
                row?.Do_ReviewStatus_value === vendorAssetStatus.approve
                  ? theme.palette.success.main
                  : theme.palette.error.main,
            }}
          >
            {row?.Do_ReviewStatus_value}
          </span>
        ),
      },
      {
        label: "DO Review",
        Field: "DO_Remarks",
        condition:
          PageName === VOBWorflowPages.DoReview ||
          (PageName === VOBWorflowPages.SOReview && iscompleteDo) ||
          isPreviewMode,
        render: (row: any) => row?.DO_Remarks,
      },
      {
        label: "Send To PM",
        Field: "IsAddInPM_value",
        condition:
          (PageName === VOBWorflowPages.SOReview && iscompleteDo) ||
          PageName === VOBWorflowPages.DoReview ||
          isPreviewMode,
        render: (row: any) => row?.IsAddInPM_value,
      },
      {
        label: "Action",
        condition: true,
        render: (row: any) => (
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Tooltip title="View Attachment">
              <IconButton
                sx={{ padding: "0px" }}
                onClick={() => onButtonClick(row?.Attachment)}
                disabled={!row?.Attachment}
              >
                <CustomSvg
                  color={!row?.Attachment ? theme.palette.text.secondary : theme.palette.info.main}
                  size={"20px"}
                  name={"view"}
                />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [PageName, isAvailableDo, iscompleteDo, isPreviewMode, theme]
  );

  // Filter headers with conditions

  const activeHeaders = useMemo(
    () => headers.filter((header) => header.condition),
    [headers] // Recompute only if headers change
  );

  useEffect(() => {
    if (onActiveHeadersUpdate && activeHeaders) {
      onActiveHeadersUpdate(activeHeaders);
      
    }
  }, [activeHeaders]);

  useEffect(() => {
    setApproveList(lovTable.filter((item: any) => item.Name === LovConfig?.Pre_Approval) || []);
  }, [lovTable]);

  const saveProcessedData = async (QuestionListDub: any) => {
    const processedData = await processQuestionsCOB(QuestionListDub, PageName, ApproveList);
    setResponseData(processedData);
    onTableValueUpdate && onTableValueUpdate(processedData);
  };

  useEffect(() => {
    if (QuestionList && QuestionList.length > 0) {
      let QuestionListDub: any = [...QuestionList];

      saveProcessedData(QuestionListDub);
    }
  }, [QuestionList, ApproveList, PageName]);

  const onclickQuestion = (ind: any) => {
    setselectedQuestionindex && setselectedQuestionindex(ind);
  };

  return (
    <>
      <>
        <TableContainer style={{ border: "1px solid #f1f1f1", borderRadius: "5px", maxHeight: "50vh" }}>
          <Table stickyHeader aria-label="simple basic table">
            <TableHead>
              <TableRow>
                {activeHeaders.map((header, index) => (
                  <TableCell key={index} className={`${BasicTableStyle.tab_header}`}>
                    {header.label} {/* Renders the label string */}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {responseData && responseData.length > 0 ? (
                responseData.map((row: any, rowIndex: number) => (
                  <TableRow key={`Row_${rowIndex}`} className={BasicTableStyle.alternateRow}>
                    {activeHeaders.map((header, colIndex) => (
                      <TableCell key={`Cell_${rowIndex}_${colIndex}`} className={`${BasicTableStyle.tab_data}`}>
                        {header.render ? header.render(row, rowIndex) : null} {/* Safeguards invalid render */}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={activeHeaders.length} className={`${BasicTableStyle.tab_data}`} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>

      <CustomDialog
        show={isfilePreview && isfilePreviewurl}
        onHide={() => {
          setIsfilePreview(false);
        }}
        key={`${viewfileName}_COB File Upload`}
        maxWidth={"lg"}
        header={"File Preview"}
        contentNode={
          <>
            <FilteViewer URL={isfilePreviewurl} fileName={viewfileName} />
          </>
        }
      />
    </>
  );
};

export default memo(AssessmentResponseCustomerOB);
