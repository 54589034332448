import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import vendorDetailsService from "../services/vendorDetails";
import { IVendorDetails, IVHColumn } from "../../models/vendorDetails.type";
import { AnyNsRecord } from "dns";

export interface IVendorDetailsData {
  vendorDetailsdata: IVendorDetails[];
  isVendorDetailsLoading: boolean;
  isVendorDetailsAdded: boolean;
  isVendorDetailsUpdated: boolean;
  isVendorDetailsDeleted: boolean;
  versionHistory: { VendorDetailsRecords: IVendorDetails[]; VendorDetailsVHeader: IVHColumn[] };
  vendorDetailsToasterMsg: string;
  vendorDetailsToasterOncloseStatus: any;
  vendorDetailsSavereturnData: any;
  vendorDetailsToasterStatus: boolean | null;
}

export const initialState: IVendorDetailsData = {
  vendorDetailsdata: [],
  isVendorDetailsLoading: false,
  isVendorDetailsAdded: false,
  isVendorDetailsUpdated: false,
  isVendorDetailsDeleted: false,
  versionHistory: { VendorDetailsRecords: [], VendorDetailsVHeader: [] },
  vendorDetailsToasterMsg: "",
  vendorDetailsToasterOncloseStatus: "",
  vendorDetailsSavereturnData:null,
  vendorDetailsToasterStatus: null
};

export const getVendorDetailsList = createAsyncThunk("getVendorDetailsList", async () => {
  const response = await vendorDetailsService.getVendorDetailsList();
  const data = response.data.map((i) => {
    return {
      ...i,
      IsListedValue: i.IsListed ? 'Yes' : 'No',
      IsRegulatedValue: i.IsRegulated ? 'Yes' : 'No',
    }
  })
  return data;
});

export const addVendorDetails = createAsyncThunk(
  "addVendorDetails",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await vendorDetailsService.addVendorDetails(requesrParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateVendorDetails = createAsyncThunk(
  "updateVendorDetails",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response = await vendorDetailsService.updateVendorDetails(requestParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteVendorDetails = createAsyncThunk(
  "deleteVendorDetails",
  async (vendorDetailsId: any, { rejectWithValue }) => {
    try {
      const response = await vendorDetailsService.deleteVendorDetails(vendorDetailsId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVendorDetailsHistory = createAsyncThunk(
  "getVendorDetailsHistory",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await vendorDetailsService.getVersionHistoryVendorDetails(reqbody);
      const { VendorMasters, HeaderJson } = response.data;
      return { VendorDetailsRecords: VendorMasters, HeaderJson: HeaderJson.ColumnHeaders };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const vendorDetailsSlice = createSlice({
  name: "vendorDetails",
  initialState,
  reducers: {
    updateVendorDetailsAddandEdit: (state, action) => {
      state.isVendorDetailsAdded = false;
      state.isVendorDetailsUpdated = false;
      state.isVendorDetailsDeleted = false;
      state.vendorDetailsToasterMsg = "";
      state.vendorDetailsSavereturnData = null
      state.vendorDetailsToasterStatus = null
    },
    updateVersionHistory: (state, action) => {
      state.versionHistory.VendorDetailsRecords = [];
      state.versionHistory.VendorDetailsVHeader = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVendorDetailsList.pending, (state) => {
        state.isVendorDetailsLoading = true;
      })
      .addCase(getVendorDetailsList.fulfilled, (state, action: PayloadAction<any>) => {
        state.vendorDetailsdata =  action.payload?.map((user: any) => {
          user.isWorkflowInitiated = user.isWorkflowInitiated === true ? "Yes" : "No";
          user.IsActive = user.Status_Value;
          return user;
        });;
        state.isVendorDetailsLoading = false;
      })
      .addCase(getVendorDetailsList.rejected, (state) => {
        state.isVendorDetailsLoading = false;
      })
      .addCase(addVendorDetails.pending, (state) => {
        state.isVendorDetailsLoading = true;
      })
      .addCase(addVendorDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.isVendorDetailsAdded = true;
        state.isVendorDetailsLoading = false;
        state.vendorDetailsSavereturnData = action.payload
        state.vendorDetailsToasterMsg = action.payload?.toasterMessage?.Message;
        state.vendorDetailsToasterStatus = action.payload?.toasterMessage?.Status;
        state.vendorDetailsToasterOncloseStatus = action.payload?.toasterMessage?.Status;
        console.log( action.payload?.toasterMessage?.Status, " action.payload?.toasterMessage?.IsSuccess");
        
      })
      .addCase(addVendorDetails.rejected, (state) => {
        state.isVendorDetailsLoading = false;
      })
      .addCase(updateVendorDetails.pending, (state) => {
        state.isVendorDetailsLoading = true;
      })
      .addCase(updateVendorDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.isVendorDetailsUpdated = true;
        state.isVendorDetailsLoading = false;
           state.vendorDetailsSavereturnData = action.payload
        state.vendorDetailsToasterMsg = action.payload?.toasterMessage?.Message;
        state.vendorDetailsToasterStatus = action.payload?.toasterMessage?.Status;
        state.vendorDetailsToasterOncloseStatus = action.payload?.toasterMessage?.Status;
        console.log( action.payload?.toasterMessage?.Status, " action.payload?.toasterMessage?.IsSuccess");

      })
      .addCase(updateVendorDetails.rejected, (state) => {
        state.isVendorDetailsLoading = false;
      })
      .addCase(deleteVendorDetails.pending, (state) => {
        state.isVendorDetailsLoading = true;
      })
      .addCase(deleteVendorDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.isVendorDetailsDeleted = true;
        state.isVendorDetailsLoading = false;
        state.vendorDetailsToasterMsg = action.payload.Message;
        state.vendorDetailsToasterStatus = action.payload.IsSuccess;
      })
      .addCase(deleteVendorDetails.rejected, (state) => {
        state.isVendorDetailsLoading = false;
      })
      .addCase(getVendorDetailsHistory.pending, (state) => {
        state.isVendorDetailsLoading = false;
      })
    .addCase(getVendorDetailsHistory.fulfilled, (state, action: PayloadAction<any>) => {
      state.versionHistory.VendorDetailsVHeader = action.payload.HeaderJson;
      state.versionHistory.VendorDetailsRecords = action.payload.VendorDetailsRecords.map((user: any) => {
        user.IsListed = user.IsListed === true ? "Yes" : "No";
        user.IsRegulated = user.IsRegulated === true ? "Yes" : "No";
        return user;
    });
      console.log(action.payload.VendorDetailsRecords, "action.payload.VendorDetails");
      
      state.isVendorDetailsLoading = false;
      // state.ToasterMsg = "ThirdParty data deleted successfully";
    })
    .addCase(getVendorDetailsHistory.rejected, (state) => {
      state.isVendorDetailsLoading = false;
    })
},
});

export const { updateVendorDetailsAddandEdit, updateVersionHistory } = vendorDetailsSlice.actions;
export default vendorDetailsSlice.reducer;
