import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";

import VendorLoginpage from "./vendorLogin/VendorLoginPage";
import { RootState, useAppDispatch } from "../../../store/store";
import { updateLoader } from "../../../store/slices/loader";
import { logoutUser } from "../../../store/slices/authorization";
import { ContractVendorPortal } from "./ContractVendorPortal";

export const ContractPortalLoginPage: React.FC = () => {
    const dispatch = useAppDispatch();

    const { isVendorAuthenticated  , isLoading , vendorLoginDetails } = useSelector((state: RootState) => state.loggerData);

    useEffect(() => {

        dispatch(updateLoader( isLoading));
    
      }, [ isLoading]);

      useEffect(()=>{
        dispatch(logoutUser(false));
      },[])

     return(
        <>
         <Box  >
           {(isVendorAuthenticated && vendorLoginDetails?.vendorId)  ? <ContractVendorPortal/> : <VendorLoginpage /> }
        </Box>
        </>
     )
}