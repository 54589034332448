import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import lovMasterService from "../services/lovService";

export interface ILOVData {
  lovTable: { [key: string]: any };
  LovNames: any;
  isLoading: boolean;
}

export const initialState: ILOVData = {
  lovTable: {},
  LovNames: [],
  isLoading: false,
};

export const getListOfValues = createAsyncThunk("getListOfValues", async (requestParams: any, { rejectWithValue }) => {
  try {
    const response = await lovMasterService.getListOfValues(requestParams);
    const { data, params } = response;
    return { data, params };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const listOfValuesSlice = createSlice({
  name: "listOfValuesData",
  initialState,
  reducers: {
    clearListOfValues: (state) => {
      state.isLoading = false;
      state.lovTable = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListOfValues.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getListOfValues.fulfilled, (state, action: PayloadAction<any>) => {
        let { data, params } = action.payload;
        let copyOfdata = data?.data.filter((user: any) => user.IsActive);
        data.data = copyOfdata;
        const listName = params.Name;
        state.lovTable = { ...state.lovTable, [listName]: data };
        state.isLoading = false;
      })
      .addCase(getListOfValues.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { clearListOfValues } = listOfValuesSlice.actions;

export default listOfValuesSlice.reducer;
