export const CLAUSE_DETAILS_URL = {
  GetAllClauseDetails: `/Clause/masterclause`,
  GetAllCaluseList: `/MasterData/masterclausetype`,
  GetClauseByServices: "/Clause/masterclausebyservices",
  CreateClause:"/Clause/createmasterclause",
  UpdateClause:"/Clause/updatemasterclause",
  GetCaluseById: "/Clause",
  ActiveInactive: "/Clause/clauseActivation",
  getSystemVariables: "/SystemVariable/SystemVariables",
  VersionHistory:"/"
};
