export const harmfulExtensions = [
  ".exe",
  ".bat",
  ".sh",
  ".js",
  ".php",
  ".asp",
  ".jsp",
  ".vbs",
  ".py",
  ".cmd",
  ".pl",
  ".rb",
  ".jar",
  ".dll",
  ".msc",
  ".sys",
  ".com",
  ".pif",
  ".scr",
  ".cpl",
  ".ps1",
  ".wsf",
  ".vbe",
  ".hta",
  ".reg",
  ".gadget",
  ".dat",
  ".apk",
  ".bin",
  ".torrent",
  ".html",
  ".htm",
  ".xml",
  ".xsl",
  ".svg",
  ".cfm",
  ".fla",
  ".swf",
  ".sql",
  ".sqlite",
  ".db",
  ".ini",
  ".dat",
];
