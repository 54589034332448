import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Box, FormHelperText, Grid, Stack, Typography } from "@mui/material";
import CustomTextField from "../../../../common/dynamicInputs/CustomTextField";
import { emailDataProps } from "../CostApproval";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import CustomSvg from "../../../../common/CustomSvg";
import CustomCheckBox from "../../../../common/dynamicInputs/CustomCheckBox";
import CustomRadioButton from "../../../../common/dynamicInputs/CustomRadioButton";
import CustomAutoComplete from "../../../../common/dynamicInputs/CustomAutoComplete";
import RichTextEditor from "react-rte";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import CustomMultiSelect from "../../../../common/dynamicInputs/CustomMultiSelect";
import { LovConfig } from "../../../../config/config";
import { ACTIVE } from "../../../../utils/constants";
import ModalPopUp from "../../../../common/modalComponent/ModalPopUp";

interface EmailFormProps {
  onSubmit: (data: any) => void;
  onClose: () => void;
  to_emails? : any;
  templateName?: string;
  isDisableAttachment?: Boolean;
}

const EmailForm: React.FC<EmailFormProps> = ({ onSubmit, onClose , to_emails, templateName= null , isDisableAttachment = false }) => {
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty, isSubmitted,validatingFields,dirtyFields },
  } = useFormContext<emailDataProps>();

  const { dataTable } = useSelector((state: RootState) => state.emailTemplateData);
  const { allUsers } = useSelector((state: RootState) => state.userData);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [emailTemplateData, setEmailTemplateData] = useState<any[]>([]);
  const [usersData, setUsersData] = useState<any[]>([]);
  const [LOBlov, setLOBlov] = useState<any[]>([]);
  const [isConformclose, setisConformclose] = useState<boolean>(false);
  const [isConformemail, setisConformemail] = useState<boolean>(false);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  const handleCancel = () => {
    onClose();
    reset();
  };
  useEffect(() => {
    if(to_emails){
      setValue("to_email_id" , to_emails)
    }
  },[to_emails])

  console.log(isSubmitted,validatingFields,dirtyFields, "dataTable");

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (dataTable?.emailTemplate.length > 0) {
      setEmailTemplateData(dataTable?.emailTemplate);
      if(templateName && templateName !==null){
        let templatDetails:any = dataTable?.emailTemplate.filter((item:any) => item.TemplateName === templateName);
        if(templatDetails.length>0){
          let templateId = templatDetails[0].EmailTempId;
          setValue("subject", templatDetails[0].EmailSubject || "");
          setValue(
            "email_content",
            RichTextEditor.createValueFromString(templatDetails[0]?.Template, "html")
          );
          setValue("email_template_id",templateId)
        }       
      }      
    }
  }, [dataTable]);

  useEffect(() => {
    setLOBlov(lovTable.filter((item: any) => item.Name === LovConfig.LOB && item.IsActive === "Active") || []);
  }, [lovTable]);

  useEffect(() => {
    let filteredUsers = allUsers.filter((item: any) => item.IsActive === ACTIVE);
    setUsersData(filteredUsers);
  }, [allUsers]);

  const validateFormatSelection = () => {
    const { is_Msword, is_Pdf } = getValues();
    if (!is_Msword || !is_Pdf) {
      return "You must select at least one format (Word or PDF).";
    }
    return true;
  };

  const handleConformSubmit = (formData : any) =>{
    setisConformemail(true)
  }

  const handleOnSubmit = () =>{
    const formValues = getValues()
    onSubmit(formValues)
  }
  return (
    <>
  
    <Box component="form" noValidate sx={{ mt: 1, width: "100%" }} onSubmit={handleSubmit(handleConformSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              width: { xs: "100%", md: "100%", lg: "50%" },
              flexDirection: { xs: "column", md: "row" },
              alignItems: "flex-start",
            }}
          >
            <Controller
              name="email_template_id"
              control={control}
              rules={{
                required: "Email Template is Required",
              }}
              render={({ field }) => (
                <CustomAutoComplete
                  optionValueKey="EmailTempId"
                  optionLabelKey="TemplateName"
                  options={emailTemplateData}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    const selectedEmailTemplate = emailTemplateData?.find(
                      (template: any) => template.EmailTempId === e
                    );
                    if (selectedEmailTemplate) {
                      console.log(selectedEmailTemplate, "Selected Email Template");
                      setValue("subject", selectedEmailTemplate.EmailSubject || "");

                      setValue(
                        "email_content",
                        RichTextEditor.createValueFromString(selectedEmailTemplate?.Template, "html")
                      );
                    }
                  }}
                  required={true}
                  label="Email Template"
                  error={Boolean(errors.email_template_id)}
                  helperText={errors.email_template_id?.message?.toString()}
                />
              )}
            />
          </Box>
        </Grid>
        {/* 
        {false && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box>
              <Controller
                name="recipients_id"
                control={control}
                rules={{
                  required: "Email Template is Required",
                }}
                render={({ field }) => (
                  <CustomAutoComplete
                    optionValueKey={"LovId"}
                    optionLabelKey="Options"
                    options={[{ LovId: 1, Options: "One" }]}
                    value={field.value}
                    onChange={field.onChange}
                    required={true}
                    label={"Select Recipients"}
                    error={Boolean(errors.recipients_id)}
                    helperText={errors.recipients_id && errors.recipients_id.message?.toString()}
                  />
                )}
              />
            </Box>
          </Grid>
        )} */}
        {false && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ mt: 0.5 }}>
              <CustomButton
                variant="outlined"
                color="secondary"
                name={"Add Recipient"}
                onClick={() => {
                  ("");
                }}
                startIcon={<CustomSvg size={"15px"} name={"plus"} />}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box sx={{ border: "2px solid #f1f1f1", p: 2 }}>
            <Stack direction={"column"} spacing={2}>
              <Box>
                <Controller
                  name="to_email_id"
                  control={control}
                  rules={{
                    required: "To Email is Required",
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"To"}
                      label={"To"}
                      value={field.value}
                      onChange={field.onChange}
                      required={true}
                      readOnly={true}
                      error={Boolean(errors.to_email_id)}
                      helperText={errors.to_email_id && errors.to_email_id.message?.toString()}
                    />
                   
                  )}
                />
              </Box>
              {allUsers && allUsers?.length > 0 && (
                <Box>
                  <Controller
                    name="notify_email_id"
                    control={control}
                    rules={{
                      required: "Notify Email is Required",
                    }}
                    render={({ field }) => (
                      <CustomMultiSelect
                        optionIdKey="UserId"
                        optionLabelKey="EmailId"
                        options={allUsers.filter((data: any) => data.IsActive === ACTIVE)}
                        required={true}
                        label={"Notify"}
                        value={field.value !== null ? field.value : []}
                        onChange={field.onChange}
                        error={Boolean(errors.notify_email_id)}
                        helperText={errors.notify_email_id && errors.notify_email_id.message?.toString()}
                      />
                    )}
                  />
                </Box>
              )}
              <Box>
                <Controller
                  name="subject"
                  control={control}
                  rules={{
                    required: "subject is Required",
                    validate: {
                      maxLength: (value) => {
                        if (value === undefined) return "Value is required";
                        return value.length <= 100 || "Maximum 100 characters allowed";
                      },
                    },
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"subject"}
                      label={"Subject"}
                      value={field.value}
                      onChange={field.onChange}
                      required={true}
                      error={Boolean(errors.subject)}
                      helperText={errors.subject && errors.subject.message?.toString()}
                    />
                  )}
                />
              </Box>
              <Box>
                <Typography variant="body1">Email Body</Typography>
              </Box>
              <Grid item xs={12}>
                <Controller
                  name="email_content"
                  control={control}
                  rules={{
                    required: "Content is Required",
                  }}
                  render={({ field }) => (
                    <>
                      <RichTextEditor
                        editorClassName="editor"
                        editorStyle={{ fontSize: "25px", height: width >= 768 ? "40vh" : "30vh" }}
                        value={field.value}
                        onChange={field.onChange}
                      />
                      {errors.email_content ? (
                        <span>{errors.email_content && errors.email_content.message?.toString()}</span>
                      ) : null}
                    </>
                  )}
                />
              </Grid>
             {!isDisableAttachment && <Box>
                <Typography variant="body1">What Format would you like to send the contract in?</Typography>
              </Box> }
              {!isDisableAttachment &&   <Box sx={{ display: "flex", gap: 2 }}>
                <Controller
                  name="is_Msword"
                  control={control}
                  // rules={{
                  //   validate: validateFormatSelection,
                  // }}
                  render={({ field }) => (
                    <CustomCheckBox
                      value={field.value}
                      onChange={field.onChange}
                      isStyle={true}
                      required={false}
                      label={"Word"}
                      // error={Boolean(errors.is_Msword)}
                      // helperText={errors.is_Msword && errors.is_Msword.message?.toString()}
                    />
                  )}
                />
                <Controller
                  name="is_Pdf"
                  control={control}
                  // rules={{
                  //   validate: validateFormatSelection,
                  // }}
                  render={({ field }) => (
                    <CustomCheckBox
                      value={field.value}
                      onChange={field.onChange}
                      isStyle={true}
                      required={false}
                      label={"PDF"}
                      // error={Boolean(errors.is_Pdf)}
                      // helperText={errors.is_Pdf && errors.is_Pdf.message?.toString()}
                    />
                  )}
                />
              </Box> }
              {!watch("is_Pdf") && !watch("is_Msword") && !isDisableAttachment &&  (
                <FormHelperText error={true} id="my-helper-text">
                  You must select at least one format (Word or PDF).
                </FormHelperText>
              )} 
              {/* <Box sx={{ display: "flex", gap: 2 }}>
                <Controller
                  name="emailFormat"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: "Email format is required",
                  }}
                  render={({ field }) => (
                    <CustomRadioButton
                      list={[
                        { value: "DOCX", lable: "DOCX" },
                        { value: "PDF", lable: "PDF" },
                      ]}
                      style={{ marginTop: "-10px" }}
                      label=""
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      error={Boolean(errors.emailFormat)}
                      helperText={errors.emailFormat && errors.emailFormat.message?.toString()}
                    />
                  )}
                />
              </Box> */}

              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "right",
                  mt: 2,
                }}
              >
                <CustomButton type="button" variant="outlined" name={"Cancel"} onClick={() => setisConformclose(true)} />
                <CustomButton
                  type="submit"
                  disabled={!isDirty}
                  name={"Send"}
                  onClick={handleSubmit(handleConformSubmit)}
                  // startIcon={<CustomSvg size={"20px"} name={"send"} />}
                />
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
    <ModalPopUp
    onDeleteMethod={() => handleCancel() }
      isDelete={true}
    onHide={() => setisConformclose(false)}
    show={isConformclose}
    ActionName={"Close"}
    header={"Confirm"}
    text={`Are you sure you want to Cancel?`}
  />
  <ModalPopUp
            onDeleteMethod={() => handleOnSubmit()}
            isDelete={true}
            onHide={() => setisConformemail(false)}
            show={isConformemail}
            ActionName={"Send"}
            header={"Confirm"}
            text={`Are you sure you want to send Email?`}
          />

</>

  );
};

export default EmailForm;
