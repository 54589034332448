import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { extractContent, textFieldWidth } from "../../../../utils/constants";
import { Controller, useForm } from "react-hook-form";
import CustomSelect from "../../../../common/dynamicInputs/CustomSelect";
import CustomNumberField from "../../../../common/dynamicInputs/CustomNumberField";
import CustomMultilineTextField from "../../../../common/dynamicInputs/CustomMultilineTextField";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store/store";
import CustomTextField from "../../../../common/dynamicInputs/CustomTextField";
import clauseService from "../../../../store/contractManagement/services/clauseService";
import { updateLoader } from "../../../../store/slices/loader";

interface AddRequirementprops {
  view?: boolean;
  closePopUp?: any;
  edit?: boolean;
  isEditData?: any;
  addedList?: any;
  add?: boolean;
  handlesubmit : (val : any) => void
}

interface FormData {
    clause_Id : any;
    clause_Header : any;
    clause_reference_no : any;
    clause_content: any;
    exclude: boolean;
    reason_For_Exclude: any;
}
export const AddRequirement: React.FC<AddRequirementprops> = ({addedList , view, closePopUp, edit, isEditData , handlesubmit }) => {
    const contractManagementReducer:any = useSelector((state: RootState) => state?.contractManagementReducer);
      const {
        clauseDetailsData, 
      }:any = contractManagementReducer.clauseData;
        const { contractClauseDetails } = useSelector(
              (state: any) => state.contractManagementReducer?.PMContractListSlice
          );
      
        const dispatch = useAppDispatch();
  const [clauseList, setclauseList] = useState<any>([]);
  
  let formData: any = {
    pR_Clause_Id : isEditData ? isEditData.pR_Clause_Id || 0 : 0 ,
    prRef_Id : isEditData ? isEditData.prRef_Id || 0 : 0 ,
    clause_Id: isEditData ? isEditData.clause_Id : "",
    clause_Header: isEditData ? isEditData?.clause_Header : "",
    clause_reference_no: isEditData ? isEditData?.clause_reference_no : "",
    clause_content: isEditData ? isEditData?.clause_content : "",
    exclude: isEditData ? isEditData?.exclude : false,
    reason_For_Exclude: isEditData ? isEditData?.reason_For_Exclude : "",
    isActive: isEditData ? isEditData?.isActive : "",
  };
  const {
    handleSubmit: handleRequirement,
    reset,
    setValue,
    formState: { errors: errorsrequirement, isDirty },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  useEffect(() => {
     if(contractClauseDetails){

     const addedIds = new Set(addedList?.map((item : any) => item.clause_Id));
      const filteredL1 = contractClauseDetails.filter((item : any) => !addedIds.has(item.clauseId));
      
      setclauseList(filteredL1)
      console.log(filteredL1 , "clauseDetailsData");
      
     }
  },[contractClauseDetails , addedList])



  const setClauseValues = async (id : any) => {
        let clauseValue : any = clauseList.find((item : any) => item?.clauseId === id);
        try{
          //    dispatch(updateLoader(true));
          // const response: any = await clauseService.getClauseDetailsById(id);
          //    dispatch(updateLoader(false));
          // console.log(response);
          // let subclause : any[] = response?.subClauses || []

        if(clauseValue){
          setValue("clause_Header" , clauseValue?.clasue_Header || "" )
          setValue("clause_content" ,  extractContent(clauseValue?.clasue_Body) || "" )
          // setValue("clause_reference_no" , `1.${clauseValue?.isSubClause}`  )
          setValue("clause_reference_no" , `1.0`  )
        }
        }catch{
          dispatch(updateLoader(false));  
        }
       
       
  }

  const SubmitForm =(val : any) => {
    handlesubmit(val)
  }

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="clause_Id"
              control={control}
            //   defaultValue={""}
              rules={{ required: "Clause Header is required" }}
              render={({ field }) => (
                <CustomSelect
                  name={"Clause Header"}
                  valueKey="clauseId"
                  optionLabelKey="clasue_Header"
                  options={clauseList}
                  readOnly={view}
                  required={true}
                  label={"Clause Header"}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e); setClauseValues(e)}}
                  error={Boolean(errorsrequirement.clause_Id)}
                  helperText={errorsrequirement.clause_Id?.message?.toString()}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="clause_reference_no"
              control={control}
              defaultValue={""}
              // rules={{
              //   required: "Clause Reference No is required",
              // }}
              render={({ field }) => (
                <CustomTextField
                    label={"Clause Reference No"}
                   name={"Clause Reference No"}
                      value={field.value}
                      onChange={field.onChange}
                      readOnly={true}
                      error={Boolean(errorsrequirement.clause_reference_no)}
                      helperText={
                        errorsrequirement.clause_reference_no && errorsrequirement.clause_reference_no.message?.toString()
                      }
                    />
               
              )}
            />
          </Grid>
        </Grid>
        <Box sx={{mt:2}}>
        <Controller
              name="clause_content"
              control={control}
              //defaultValue={}
              // rules={{
              //   required: "Clause Content is required",
              // }}
              render={({ field }) => (
        <CustomMultilineTextField
                  label={"Clause Content"}
                  value={field.value}
                  disabled={view}
                  onChange={field.onChange}
                  rows={2}
                  readOnly={true}
                  error={Boolean(errorsrequirement?.clause_content)}
                  helperText={
                    errorsrequirement?.clause_content &&
                    errorsrequirement?.clause_content?.message?.toString()
                  }
                />
            )}
            />
        </Box>

        <Box
                                sx={{
                                    display: "flex",
                                    gap: "20px",
                                    justifyContent: "end",
                                    mt: 2,
                                }}
                            >
                               
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton
                                            type="reset"
                                            disabled={!isDirty}
                                            name={"Submit"}
                                            onClick={handleRequirement(SubmitForm)}
                                        />
                                    </React.Fragment>
                               
                            </Box>
      </Box>
    </>
  );
};
