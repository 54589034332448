import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import costApprovalService from "../services/costApprovalService";

export interface IEmailState {
  isEmailSending: boolean;
  isEmailSent: boolean | null;
  emailSendError: string | null;
  emailToasterMsg: { message: string; status: string };
}

export const initialState: IEmailState = {
  isEmailSending: false,
  isEmailSent: false,
  emailSendError: null,
  emailToasterMsg: { message: "", status: "" },
};

export const costApprovalSendEmail = createAsyncThunk(
  "costApprovalSendEmail",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response: any = await costApprovalService.costApprovalSendEmail(requestBody);
      return response;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);
export const costApprovalSubmitCostApproval = createAsyncThunk(
  "costApprovalSubmitCostApproval",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response: any = await costApprovalService.costApprovalSubmitCostApproval(requestBody);
      return response;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);

export const costApprovalEmailSlice = createSlice({
  name: "costApprovalEmail",
  initialState,
  reducers: {
    costApprovalResetEmailState: (state) => {
      state.isEmailSending = false;
      state.isEmailSent = null;
      state.emailSendError = null;
      state.emailToasterMsg = { message: "", status: "" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(costApprovalSendEmail.pending, (state) => {
        state.isEmailSending = true;
        state.isEmailSent = false;
        state.emailSendError = null;
      })
      .addCase(costApprovalSendEmail.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action?.payload?.payload?.toaster,"Payload Action");
        state.isEmailSending = false;
        state.isEmailSent = true;
        state.emailToasterMsg = action?.payload?.payload;
      })
      .addCase(costApprovalSendEmail.rejected, (state, action: PayloadAction<any>) => {
        state.isEmailSending = false;
        state.isEmailSent = true;
        state.emailToasterMsg.message = action?.payload?.payload;
        state.emailToasterMsg.status = 'false';
      })
    
  },
});

export const { costApprovalResetEmailState } = costApprovalEmailSlice.actions;
export default costApprovalEmailSlice.reducer;
