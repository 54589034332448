export const Roles_AND_RESPONSIBILITY_URL = {
  AddRolesAndResponsibility: `/AddMasterRRSertup`,
  IsProjectExists: `/isProjectExists`,
  getProjectDetail: `/getProjectDetail`,
  UpdateRolesAndResponsibility: `/`,
  DeleteRolesAndResponsibility: `/removeRRSetupmaster`,
  GetRolesAndResponsibility: `/getAllRRSetupmaster`,
  GetRolesAndResponsibilityById: `/getRRSetupmasterById`,
  GetRRMasterByworkflowId: `/GetRRSetupmasterByWorkflowId`,
  GetVCWorkflowProcesses: "/VendorContract/GetVCWorkflowProcesses",
  UpdateRRWorkFlowprocess: "VendorContract/UpdateRRWorkflowProcesses",
  UpdateWorkflowInstance: "/VendorContract/UpdateWorkflowInstance",
  saveSigningAuthorityOnline: "/ESignStamping/EStampingOnline",
  saveSigningAuthorityOffline: "/ESignStamping/EStampingOffline",
  getArticleByLocation: "/ArticleSection/GetArticleSectionByLocationId",
  getSigningStatus: "/Esign/EsignWorkFlow",
  initiateEmbedSign: "/Esign/InitiateEmbedSign",
  downloadWorkflowDocument: "/Esign/DownloadWorkflowDocuments",
  sendReminder: "/Esign/SendReminder",
  getSigningAuthorities: "/VendorContract/contractRefNo",
  saveSigningAuthorities: "/VendorContract/SaveContractorDetails",
  convertTOPDF: "/DocumentService/ConvertWordToPDF"
};
