import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IUser, IVHColumnRes } from "../../models/user.type";
import userListService from "../services/usersListService";
import { IRole } from "../../models/roles.type";
import { chkDate } from "../../utils/constants";

export interface IUserData {
  dataTable: { user: IUser[]; UserHeader: IVHColumnRes[] };
  allUsers: IUser[];
  user:any;
  roleMaster: IRole[];
  isLoading: boolean;
  // addUserInitiated: boolean;
  isUserAdded: boolean;
  isUserUpdated: boolean;
  versionHistory: { userHistories: IUser[]; UserVHeader: IVHColumnRes[] };
  isUserHistory: boolean;
  isUserDeleted: boolean;
  ToasterMsg: any;
}

export const initialState: IUserData = {
  dataTable: { user: [], UserHeader: [] },
  allUsers: [],
  user:{},
  roleMaster: [],
  isLoading: false,
  isUserAdded: false,
  // addUserInitiated:false,
  isUserUpdated: false,
  versionHistory: { userHistories: [], UserVHeader: [] },
  isUserHistory: false,
  isUserDeleted: false,
  ToasterMsg: {Message: "", Status: ""},
};

export const getUsersList = createAsyncThunk(
  "getUsersList",
  async (requesrParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await userListService.getUsersList(requesrParams);
      const { Users, totalCount } = response.data;
      return { Users, totalCount };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getUser = createAsyncThunk(
  "getUser",
  async (requesrParams: { UserId: number }, { rejectWithValue }) => {
    try {
      const response = await userListService.getUser(requesrParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllRoleMasterList = createAsyncThunk("getRoleList", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response = await userListService.getRoleMasterList();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addUser = createAsyncThunk("addUser", async (requesrParams: any, { rejectWithValue }) => {
  try {
    //requesrParams["createdBy"] ="Bala";
    const response: any = await userListService.addUser(requesrParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// export const saveUserMenu = createAsyncThunk("saveUserMenu", async (requesrParams: any, { rejectWithValue }) => {
//   try {
//     const response: any = await userListService.saveUserMenu(requesrParams);
//     return response.data;
//   } catch (error) {
//     return rejectWithValue(error);
//   }
// });

export const updateUserDetails = createAsyncThunk(
  "updateUserDetails",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      //reqbody["createdBy"] ="Bala";
      const response: any = await userListService.updateUser(reqbody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteUser = createAsyncThunk("deleteUser", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };

    const response = await userListService.deleteUser(reqbody);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserHistory = createAsyncThunk("getUserHistory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };

    const response = await userListService.getVersionHistoryUser(reqbody);
    const { userHistories, HeaderJson } = response.data;
    return { UserHistories: userHistories, HeaderJson: HeaderJson.ColumnHeaders };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateUserAddandEdit: (state, action) => {
      state.isUserAdded = false;
      state.isUserUpdated = false;
      state.isUserDeleted = false;
      state.ToasterMsg = {Message: "", Status: ""};

    },
    updateUser: (state, action) => {
      if (action.payload.UserId) {
        let indexUser = state.allUsers.findIndex((data) => data.UserId === action.payload.UserId);
        let copyUserData = [...state.allUsers];
        copyUserData[indexUser] = action.payload;
        state.allUsers = copyUserData;
      }
    },
    updateVersionHistory: (state, action) => {
      state.versionHistory.userHistories = [];
    },
    updateUserRecords: (state, action) => {
      state.dataTable.user = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.user = action.payload;
        state.isLoading = false;
      })
      .addCase(getUser.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getUsersList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsersList.fulfilled, (state, action: PayloadAction<any>) => {
        state.allUsers = action.payload.Users.map((user: IUser) => {
          user.IsActive = user.IsActive === 1 ? "Active" : "Inactive";
          return user;
        });
        // state.addUserInitiated = false;
        state.isLoading = false;
      })
      .addCase(getUsersList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(addUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addUser.fulfilled, (state, action: PayloadAction<any>) => {
        let item = { ...action.payload };
        item.id = state.allUsers.length + 1;
        let copyallUsers = [...state.allUsers];
        copyallUsers.push(item);
        state.allUsers = copyallUsers;
        // state.addUserInitiated = true;
        state.isUserAdded = true;
        state.isLoading = false;
        // state.ToasterMsg = action.payload.Message;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.Status;
        
      })
      .addCase(addUser.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      // .addCase(saveUserMenu.fulfilled, (state, action: PayloadAction<any>) => {
      //   state.isUserAdded = true;
      //   state.isLoading = false;
      //   // state.ToasterMsg = action.payload.Message;
      //   state.ToasterMsg.Message = action.payload.Message;
      //   state.ToasterMsg.Status = action.payload.Status;
      // })
      .addCase(updateUserDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserDetails.fulfilled, (state, action: PayloadAction<any>) => {
        // state.addUserInitiated = true;
        state.isUserUpdated = true;
        // let indexUser= state.dataTable.findIndex(data=> data.UserId === updatedUser.UserId);
        // let copyUserData= [...state.dataTable];
        // copyUserData[indexUser]= updatedUser;
        // state.dataTable = copyUserData;
        state.isLoading = false;
        // state.ToasterMsg = action.payload.Message;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.Status;
      })
      .addCase(updateUserDetails.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.isUserDeleted = true;
        // let indexUser= state.dataTable.findIndex(data=> data.UserId === updatedUser.UserId);
        // let copyUserData= [...state.dataTable];
        // copyUserData[indexUser]= updatedUser;
        // state.dataTable = copyUserData;
        state.isLoading = false;
        // state.ToasterMsg = action.payload.Message;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.Status;
      })
      .addCase(deleteUser.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getAllRoleMasterList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllRoleMasterList.fulfilled, (state, action: PayloadAction<any>) => {
        state.roleMaster = action.payload;
        // let indexUser= state.dataTable.findIndex(data=> data.UserId === updatedUser.UserId);
        // let copyUserData= [...state.dataTable];
        // copyUserData[indexUser]= updatedUser;
        // state.dataTable = copyUserData;
        state.isLoading = false;
      })
      .addCase(getAllRoleMasterList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getUserHistory.pending, (state) => {
        state.isUserHistory = true;
      })
      .addCase(getUserHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.versionHistory.UserVHeader = action.payload.HeaderJson;
        // state.versionHistory.userHistories = action.payload.UserHistories;
        state.versionHistory.userHistories = action.payload.UserHistories.map((user: any) => {
          user.IsActive = user.IsActive === 1 ? "Active" : "Inactive";
            user.ApprovedOn = chkDate(user.ApprovedOn);
          return user;
        });
        state.isUserHistory = false;
      })
      .addCase(getUserHistory.rejected, (state, action: PayloadAction<any>) => {
        state.isUserHistory = false;
      });
  },
});

export const { updateUserAddandEdit, updateUser, updateVersionHistory, updateUserRecords } = userDataSlice.actions;
export default userDataSlice.reducer;
