export const INDUSTRY_URL = {
  AddEditIndustry: `/addEditIndustryMaster`,
  GetIndustry: `/GetIndustryMaster`,
  GetParentIndustry: `/GetSubIndustryMaster`,
  DeleteIndustry: `/deleteIndustryMaster`,
  GetIndustryVersionHistory: `/getIndustryHistory`,
};

export const LOCATION_URL = {
  AddEditLocation: `/addEditLocationMaster`,
  GetLocation: `/GetLocationMaster`,
  GetParentLocation: `/GetLocationType`,
  DeleteLocation: `/deleteLocationMaster`,
  VersionHistory: `/getLocationHistory`,
};

export const LOV_URL = {
  AddEditLov: `/addListOfValues`,
  GetLovNames: `/getListOfValueNames`,
  GetLov: `/getListOfValues`,
  DeleteLov: `/deleteListOfValues`,
  VersionHistoryLov: `/getListOfValuesHistory`,
};

export const DOCUMENT_URL = {
  GetDocument: `/GetDocumentMaster`,
  AddEditDocument: `/addEditDocumentMaster`,
  DeleteDocument: `/deleteDocumentMaster`,
  VersionHistory: `/getDocumentHistory`,
  GetDocumentByAssessmentTypeId:`/GetDocumentMasterByAssessmentTypeId`
};

export const REGIONALSETTINGS_URL = {
  GetRegional: `/GetRegionalSettings`,
  AddEditRegional: `/addEditRegionalSettings`,
  DeleteRegional: `/deleteRegionalSettings`,
  VersionHistory: `/getRegionalSettingsHistory`,
};

export const THIRDPARTY_URL = {
  AddThirdParty: `/CreateEditMasterThirdParty`,
  GetThirdParty: `/GetMasterThirdParty`,
  GetAllThirdParty: `/GetMasterThirdParties`,
  UpdateThirdParty: `/CreateEditMasterThirdParty`,
  DeleteThirdParty: `/DeleteMasterThirdParty`,
  VersionHistory: `/getThirdPartyHistory`,
};

export const CURRENCY_URL = {
  AddCurrency: `/CreateEditMasterCurrency`,
  GetCurrency: `/GetMasterCurrency`,
  GetAllCurrency: `/GetMasterCurrencies`,
  UpdateCurrency: `/CreateEditMasterCurrency`,
  DeleteCurrency: `/DeleteMasterCurrency`,
  VersionHistory: `/getCurrencyLocationHistory`,
};
export const BRANCHMASTER_URL = {
  GetBranch: `/GetMasterBranches`,
  GetBranchByCompany: `/GetMasterBranchByCompanyId`,
  AddEditBranch: `/CreateEditMasterBranch`,
  DeleteBranch: `/DeleteMasterBranch`,
  VersionHistory: `/getBranchHistory`,
};

export const COMPANYMASTER_URL = {
  GetCompany: `/GetAllCompanyMasters`,
  AddCompany: "/AddOrEditCompanyMaster",
  EditCompany: "/AddOrEditCompanyMaster",
  DeleteCompany: "/DeleteCompanyMaster",
  GetCompanyById: `/GetCompanyMasterById`,
  GetCompanyMasterHistoryById: "/GetCompanyMasterHistoryById",
  LogoImage: "/LogoImage",
};

export const RISKMASTER_URL = {
  GetRiskCategories: `/getRiskCategories`,
  AddEditRiskCategory: `/saveRiskCategory`,
  DeleteRiskCategory: `/deleteRiskCategory`,
  VersionHistory: `/getRiskCategoryHistory`,
};

export const HOLIDAYMASTER_URL = {
  GetHoliday: `/GetAllHolidayMaster`,
  GetHolidayWithRange: `/GetHolidayByDateRange`,
  CreateHoliday: `/createHolidayMaster`,
  UpdateHoliday: `/UpdateHolidayMaster`,
  DeleteHoliday: `/DeleteHolidayDetails`,
  VersionHistory: `/GetHolidayHistory`,
};

export const FORMTITLEMASTER_URL = {
  GetFormTitle: `/getAllFormTitles`,
  AddorEditFormTitle: `/addeditFormTitles`,
  DeleteFormTitle: `/deleteFormTitles`,
  VersionHistory: `/getFormTitleHistoryById`,
};