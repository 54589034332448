import React, { useEffect, useState } from 'react'
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { useSelector } from 'react-redux';

import { useTheme } from "@mui/material/styles";
import { RootState, useAppDispatch } from '../../../../store/store';
import { ContentNameConfig, PM_MeasuredIn } from '../../../../config/config';
import CustomButton from '../../../../common/dynamicButton/CustomButton';
import CustomSvg from '../../../../common/CustomSvg';
import CommonDataTemplateSheet from '../../../../common/CommonDataUploadTemplate/CommonDataTemplate';
import dayjs from 'dayjs';


export const VOBAssessmentTemplate = [
    {
      Field: "SrNo",
      FieldType: "string",
      DisplayName: "SrNo",
      config: {
        required: true,
      },
    },
    {
      Field: "sla",
      FieldType: "string",
      DisplayName: "SLA",
      config: {
        required: true,
      },
    },
    {
      Field: "target_Min",
      FieldType: "string",
      DisplayName: "Target Min",
      config: {
        required: true,
      },
    },
    {
        Field: "target_Max",
        FieldType: "string",
        DisplayName: "Target Max",
        config: {
          required: true,
        },
      },
     
      {
        Field: "measured_In_Name",
        FieldType: "string",
        DisplayName: "Measured In",
        config: {
          required: true,
        },
        cellTypeField : ""
      },
      {
        Field: "actual",
        FieldType: "string",
        DisplayName: "Actual",
        config: {
          required: true,
        },
        cellTypeField : "actual_type"
      },
      {
        Field: "bestScore",
        FieldType: "string",
        DisplayName: "Best Score",
        config: {
          required: true,
        },
      },
      

      

      
     
  ];

interface BulkUploadQuestionCOBTemplateProps {
  SLAData : any[];
}

export const BulkUploadQuestionPMPR : React.FC <BulkUploadQuestionCOBTemplateProps> = ({SLAData }) => {
  const { vendorConentPurposes } = useSelector(
    (state: RootState) => state.contentData
  );
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [Note, setNote] = useState<any>("Note : All Actal Fields is Mandatory. if Measured in Date, Actual enter Date Formate Ex:30-12-2024 (DD-MM-YYYY)");
  const [Data, setData] = useState<any>([]);


useEffect(() => {

    console.log(SLAData , 'SLAData');
    let AssessmentDatawithOptions : any[] = []

    SLAData.forEach((item : any , index : any) => {
            let obj : any ={ ...item ,
             SrNo : `${index+1}` ,
             actual : item?.actual ? item?.measured_In_Name == PM_MeasuredIn?.Date ? dayjs(item?.actual).format('DD-MM-YYYY') : item?.actual  : "" ,
            actual_type : item?.measured_In_Name == PM_MeasuredIn?.Date ? "date" : 'number' ,
            target_Min : item?.measured_In_Name == PM_MeasuredIn?.Date ? dayjs(item?.target_Min).format('DD-MM-YYYY')  : item?.target_Min ,
            target_Max : item?.measured_In_Name == PM_MeasuredIn?.Date ? dayjs(item?.target_Max).format('DD-MM-YYYY')  : item?.target_Max ,
        
        }
           
               AssessmentDatawithOptions.push(obj)
    })

    setData(AssessmentDatawithOptions)

},[SLAData])
 



     return(
        <>
           <CommonDataTemplateSheet Note={Note} sheetName='Performance Review SLA' buttonName='Download SLA' header={VOBAssessmentTemplate}  Data={Data}  />
        </>
     )
}