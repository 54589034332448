import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiMasterService from "../services/apiMaster";
import {IApimaster, IApimasterResponse } from "../../models/apiMaster.type";
import { chkDate } from "../../utils/constants";

export interface IApiMasterData {
  ApiMasterTable: IApimaster[];
  isLoading: boolean;
  isApiMasterAdded: boolean;
  isApiMasterUpdated: boolean; 
  isApiDeleted:boolean;
  ToasterMsg: { Message: string; Status: string };

}

export const initialState: IApiMasterData = {
    ApiMasterTable: [],
  isLoading: false,
  isApiMasterAdded: false,
  isApiMasterUpdated: false,
  isApiDeleted:false,

  ToasterMsg: { Message: "", Status: "" },
};

export const getAllApiMaster = createAsyncThunk(
  "getAllApiMaster",
  async (requestParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await ApiMasterService.getAllApiMaster();
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveApiMaster = createAsyncThunk("saveApiMaster", async (requestParams: any, { rejectWithValue }) => {
  try {
    const response: any = await ApiMasterService.saveApiMaster(requestParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateApiMaster = createAsyncThunk("updateApiMaster", async (requestParams: any, { rejectWithValue }) => {
  try {
    const response: any = await ApiMasterService.updateApiMaster(requestParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteApi = createAsyncThunk("deleteApi", async (requestParams: any, { rejectWithValue }) => {
  try {
    const response: any = await ApiMasterService.deleteApi(requestParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});


export const ApiMasterDataSlice = createSlice({
  name: "ApiMaster",
  initialState,
  reducers: {
    apiMasterAddandSave: (state, action) => {
      state.isApiMasterAdded = false;
      state.isApiMasterUpdated = false;
      state.isApiDeleted = false;
    //   state.isDuplicate = false;
      state.ToasterMsg.Message = action.payload.Message;
      state.ToasterMsg.Status = action.payload.Status;
    },
    updateUser: (state, action) => {
      if (action.payload.UserId) {
        let indexVendor = state.ApiMasterTable.findIndex((data: any) => data.UserId === action.payload.UserId);
        let copyVendorData = [...state.ApiMasterTable];
        copyVendorData[indexVendor] = action.payload;
        state.ApiMasterTable = copyVendorData;
      }
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllApiMaster.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllApiMaster.fulfilled, (state, action) => {
        state.ApiMasterTable = action.payload.map((data: any) => {
          
          data.IsActive = data.IsActive === false ? "Inactive" : "Active" ;
          return data;
        });
        state.isLoading = false;
      })
      .addCase(getAllApiMaster.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(saveApiMaster.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveApiMaster.fulfilled, (state, action) => {
        state.isApiMasterAdded = true;
        state.isLoading = false;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.Status;
      })
      .addCase(saveApiMaster.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateApiMaster.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateApiMaster.fulfilled, (state, action: PayloadAction<any>) => {
        state.isApiMasterUpdated = true;
        state.isLoading = false;
        
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.Status;
      })
      .addCase(updateApiMaster.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(deleteApi.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteApi.fulfilled, (state, action: PayloadAction<any>) => {
        state.isApiDeleted = true;
        state.isLoading = false;
        // state.ToasterMsg = action.payload.Message;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.Status;
      })
      .addCase(deleteApi.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
     
  },
});

export const { apiMasterAddandSave, updateUser } = ApiMasterDataSlice.actions;
export default ApiMasterDataSlice.reducer;
