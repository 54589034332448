import { Autocomplete, Button, ListItemText, Paper, TextField, TextFieldVariants } from "@mui/material";
import React, { memo, useEffect, useState } from "react";

interface InputProps {
  label: string;
  optionLabelKey: string;
  optionValueKey: string;
  cascadingKey?: string;
  value: any;
  cascadingvalue?: any;
  options: any;
  variant?: TextFieldVariants;
  required?: boolean;
  error?: string | boolean;
  onChange?: (value: any) => void;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  helperText?: string;
  inputProps?: object;
  Width?: any;
  placeholder?: any;
  size?: 'small' | 'medium';
}

const CustomAutoComplete = (props: InputProps) => {
  const {
    label,
    value,
    optionLabelKey,
    optionValueKey,
    cascadingKey,
    cascadingvalue,
    variant = "outlined",
    required = false,
    error,
    onChange,
    className,
    disabled = false,
    readOnly = false,
    helperText,
    inputProps,
    Width = "100%",
    placeholder = "Search..",
    options = [],
    size = "small"
  } = props;
  const [filteredOptions, setFilteredOptions] = useState<any[]>(options);
  const [open, setOpen] = useState<boolean>(false);
  
  const call_option = () => {
    const filteredOptions_value = cascadingKey
      ? options.filter(
        (item: { [x: string]: any }) =>
          `${item[cascadingKey]}` == cascadingvalue
      )
      : options;
    return filteredOptions_value || [];
  };

  useEffect(() => {
    const filteredOptions_value = cascadingKey
      ? options.filter(
        (item: { [x: string]: any }) =>
          `${item[cascadingKey]}` == cascadingvalue
      )
      : options;
    // options = filteredOptions_value
    setFilteredOptions(filteredOptions_value);
  }, [cascadingKey, cascadingvalue, options, value]);



  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={filteredOptions}
        sx={{ width: Width }}
        className={className}
        disabled={disabled}
        readOnly={readOnly}
        key={JSON.stringify(options)}
        // value={value}
        // value={options.find((option: any) => option[optionValueKey] == value)}
        value={options.find((option: any) => option[optionValueKey] === value) || null}
        // PaperComponent={(props) => (
        //   <Paper onMouseDown={event => event.preventDefault()}>
        //     {/* <Button>Click</Button> */}
        //   </Paper>
        // )}'
        // PopperComponent={(props)}
        fullWidth
        openOnFocus
        size={size}
        // onChange={(e: any, newValue: any) => {
        //   if (onChange && !readOnly) {
        //     if (newValue) {
        //       onChange(newValue[optionValueKey]);
        //     } else {
        //       onChange(newValue);
        //     }
        //   }
        // }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderInput={(params) => (
          <TextField
            placeholder={placeholder}
            variant={variant}
            required={required}
            {...params}
            error={!!error}
            helperText={helperText ? helperText : error}
            label={label}
          />
        )}
        renderOption={(props, item) => {
          console.log("AutoComplete props", props);
          return (
            <ListItemText sx={{
              textAlign: "left",
              p: 1,
              cursor: "pointer"
            }} key={item[optionValueKey]}
              onClick={(e: any) => {
                if (onChange && !readOnly) {
                  if (item) {
                    onChange(item[optionValueKey]);
                    setOpen(false)
                  } else {
                    onChange(item);
                    setOpen(false)
                  }
                }
              }}
            >{item[optionLabelKey]}</ListItemText>)
        }
        }        
        getOptionLabel={(option) => (option ? `${option[optionLabelKey]}` : "")}
        isOptionEqualToValue={(option, value) => option[optionValueKey] == value}
        filterOptions={(options, { inputValue }) => {
          if (inputValue.trim() === "" || inputValue.length < 3) {
            return filteredOptions;// Display first 9999 options if no search input
          } else {
            // Filter options based on search input
            return options
              .filter((option) =>
                `${option[optionLabelKey]}`
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              )
              .slice(0, 9999);
          }
        }}
      />
    </>
  );
};
export default CustomAutoComplete;
