import { Box, Stack, Typography } from "@mui/material";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatch } from "../../../store/store";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import RandRSetup from "./RandRSetup";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import {
  deleteRRSetup,
  getRolesAndResponsibilityList,
  updaterolesandresponsAddandEdit,
} from "../../../store/contractManagement/slices/contractRolesAndResponsiblity";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";


const columns = {
  table_name: "R & R Setup",
  isAction: true,
  columns: [
    {
      Field: "Workflow_Name",
      FieldType: "string",
      DisplayName: "Workflow Name",
    },
    {
      Field: "Workflow_Id",
      FieldType: "string",
      DisplayName: "Workflow Id",
    },
    {
      Field: "WorkflowCount",
      FieldType: "string",
      DisplayName: "Workflow Count",
    },
    {
      Field: "EstimatedDays",
      FieldType: "string",
      DisplayName: "Estimated Days",
    },
    {
      Field: "IsActive",
      FieldType: "string",
      DisplayName: "Status",
    },
  ],
};

export interface IRandRMasterProps {
  view?: boolean;
}

const RandRMaster: React.FC<IRandRMasterProps> = ({ view = false }) => {
  const dispatch = useAppDispatch();
  const {
    isLoading,
    ToasterMsg,
    contractRolesAndResponsibilityData,
    isRolesAndResponsibilityDeleted,
    RolesAndResonsibilitytoasterMsg,
  } = useSelector((state: any) => state.contractManagementReducer?.contractRolesAndResponsibility);

  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [HeaderColumn] = useState(columns);
  const [contractRandRList, setContractRandRList] = useState<any[]>([]);
  const [permssionPage, setPermissionForpage] = useState<any>();
  const [Option, setOption] = useState("");
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [isEditData, setIsEditDate] = useState<any>(null);
  const [isShowDraft, setisShowDraft] = useState<any>(1);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
    const { currentpageTitleData } = useSelector(
          (state: RootState) => state.FormTitleMasterData
        );

          const { handleRecordLockUpdate } = RecordLockRelease();
        
        
  const columns_version: any = {
    table_name: "Vendor History Records",
    isAction: false,
    columns: [],
  };

  useEffect(() => {
    initializeData();
  }, []);

  const initializeData = () => {
    dispatch(getRolesAndResponsibilityList(""));
  };

  useEffect(() => {
    dispatch(updateLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  const handleOpen = async (data: any, option: string) => {
    if (option === "history") {
      setOption(option);
      setIsAddorEdit(false);
      // setIsVersionHistory(true);
    } else if (option === "delete") {
      setOption(option);
      setIsDeleteData(data);
    } else if (option === "edit") {
      setOption(option);
      setIsAddorEdit(true);
      setIsEditDate(data);
    } else if (option === "view") {
      setOption(option);
      setIsAddorEdit(true);
      setIsEditDate(data);
    } else {
    }
  };

  const setAddFormcloseoropen = (value: any) => {
    setIsAddorEdit(value);
    setIsEditDate(null);
    setOption("");
  };

  useEffect(() => {
    setContractRandRList(contractRolesAndResponsibilityData);
  }, [contractRolesAndResponsibilityData]);

  const handleDelete = () => {
    let inactiveFormData = {
      ModuleName: "Roles and Responsibility",
      Activity: isDeleteData?.IsActive === "Active" ? "inactive" : "active",
      UserId: userLoginResponse?.UserId,
      createdBy: userLoginResponse?.UserName,
      MenuId: 0,
      Form_Id: 0,
      Id: isDeleteData?.Workflow_Id,
      IsActive: isDeleteData?.IsActive !== "Active",
    };
    dispatch(deleteRRSetup(inactiveFormData));
    setIsDeleteData(null);
  };

  useEffect(() => {
    if (isRolesAndResponsibilityDeleted) {
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: RolesAndResonsibilitytoasterMsg?.Message,
          isTosterFailed: RolesAndResonsibilitytoasterMsg?.Status !== "Success",
        })
      );
      dispatch(getRolesAndResponsibilityList(""));
      dispatch(updaterolesandresponsAddandEdit(false));
    }
  }, [isRolesAndResponsibilityDeleted]);

  // useEffect(() => {
  //   if (isContractDetailsDeleted === true) {
  //     dispatch(
  //       updateToaster({
  //         isToaster: true,
  //         toasterMsg: ToasterMsg.message,
  //         isTosterFailed: ToasterMsg.status !== "Success",
  //       })
  //     );
  //     dispatch(updateContractDetailsAddandEdit(false));
  //     initializeData();
  //     setIsDeleteData("");
  //     setOption("");
  //   }
  // }, [ToasterMsg]);
  console.log(contractRandRList,"contractRandRList");
  
  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <React.Fragment>
            {view ? null : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <Typography variant="h6" color={"text.primary"}>
                                            {" "}
                                            {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                                            {/* Holiday Master */}
                                        </Typography>
              </Box>
            )}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={HeaderColumn}
                rows={contractRandRList}
                handleAction={handleOpen}
                handleAdd={() => setAddFormcloseoropen(true)}
                enableZoomIcon={true}
                showhistory={false}
                isDraftView={false}
                draftCount={0}
                isEnableDraft={false}
                handleIsDraft={(val: any) => setisShowDraft(val)}
                showAdd={!view}
                showDelete={!view}
                showView={!view}
                showEdit={!view}
                showExports={false}
                userId={userLoginResponse?.UserId || 0}
                pageName="R & R Setup"
                 isEnableRecordLock = {{ IdField : "Workflow_Id" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                                  // FormID : FormIdConfig?.IndustryFormId,
                                                
                                                  MenuID : currentPagePermissionData?.MenuId,
                                                  IsCheckPending : false  }}}
                disableselect={true}
                isActivekey="IsActive"
              />
            </Box>
          </React.Fragment>
        </Stack>
        <CustomDialog
          show={isAddorEdit}
          onHide={() => {setIsAddorEdit(false)
            handleRecordLockUpdate()
          }}
          maxWidth="lg"
          header="R & R Setup"
          contentNode={
            <RandRSetup onHide={() => {setIsAddorEdit(false);
              handleRecordLockUpdate();
            }
            } editData={isEditData} view={Option === "view"} />
          }
        />
        <ModalPopUp
          onDeleteMethod={() => handleDelete()}
          isDelete={true}
          onHide={() => setIsDeleteData(null)}
          show={isDeleteData ? true : false}
          header={"Confirm"}
          text={`Are you sure you want to ${
            isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
          } the selected R & R Setup?`}
          ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
        />
      </Box>
    </>
  );
};

export default RandRMaster;
