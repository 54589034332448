import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { IMenu } from "../../../models/loggin.type";
import { AddIndustry } from "./AddIndustry";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { RootState, useAppDispatch } from "../../../store/store";
import {
  deleteIndustry,
  getIndustryHistory,
  getIndustryList,
  updateIndustryAddandEdit,
  updateVersionHistory,
} from "../../../store/slices/industryMasterData";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import { Edit_Enable_tosterMSG, IndustryConfig, LovConfig,FormIdConfig, ModuleNames } from "../../../config/config";
import { getLovList } from "../../../store/slices/lovMasterData";
import { GetMasterEditEnable } from "../../../store/slices/masterEditEnableData";
import masterEditEnableService from "../../../store/services/masterEditEnableService";
import { getNotifications } from "../../../store/slices/notification";
import { CustomSquareStepper } from "../../../common/dynamicSteper/CustomSquareStepper";
import { CustomStatusStepper } from "../../../common/dynamicSteper/CustomStatusStepper";
import HolidayMaster from "../holidayMaster/HolidayMaster";
  

import { HandleRecordLockCheck, RecordLockUpdate } from "../../recordLock/RecordLock";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";

export default function Industry() {
  const dispatch = useAppDispatch();
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [addForm, setAddForm] = useState(false);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [formDisplay, setformDisplay] = useState(false);
  const [filterTableData, setFilterTableData] = useState<any[]>([]);
  const [IndustryData, setIndustryData] = useState<any[]>([]);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [LableName, setLableName] = useState<any>("Industry");
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const [IndustryTypelov, setIndustryTypelov] = useState<any[]>([]);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const { EditEnableData } = useSelector((state: RootState) => state.MasterEditEnableData);
  const { handleRecordLockUpdate } = RecordLockRelease();

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );

 const { currentpageTitleData } = useSelector(
        (state: RootState) => state.FormTitleMasterData
      );
   
  const { lovTable, islovAdded } = useSelector((state: RootState) => state.lovMasterData);

  const {
    industryTable,
    isindustryAdded,
    versionHistory,
    isindustryAddedFailed,
    isindustryDeleted,
    isindustryDeletedFailed,
    ToasterMsg,
    isLoading,
  } = useSelector((state: RootState) => state.indutryMasterData);

  const columns_version: any = {
    table_name: "Industry History Records",
    isAction: false,
    columns: [],
  };

  const columns: any = {
    table_name: "Industry",
    isAction: true,
    columns: [
     
      {
        Field: "IndustryName",
        DisplayName: "Industry Name",
        FieldType: "string",
      },
      {
        Field: "IndustryTypeValues",
        DisplayName: "Type",
        FieldType: "string",
      },
      {
        Field: "IndustryParentName",
        DisplayName: "Parent",
        FieldType: "string",
      },
      {
        Field: "Parent_LevelValues",
        DisplayName: "Level",
        FieldType: "string",
      },
      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",
      },
      {
        Field: "UpdatedBy",
        DisplayName: "Modified By",
        FieldType: "string",
      },
    ],
  };

  useEffect(() => {
    setIndustryTypelov(lovTable.filter((item: any) => item.Name === LovConfig.Industry_Type) || []);
  }, [lovTable]);

  useEffect(() => {
    dispatch(
      getIndustryList({
        FormID: 2,
      })
    );
    dispatch(getLovList({ Name: "" }));
  }, []);

  useEffect(() => {

    setIndustryData(industryTable);
  }, [industryTable]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    if (versionHistory.industryVHeader.length > 0 && versionHistory.industryVHeader.length > 0) {
      if (versionHistory.industryVHeader && versionHistory.industryVHeader.length > 0) {
        setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory.industryVHeader,
        };
        setVersionHistoryData(re_columns);
      }
    }
  }, [versionHistory]);

  const handleOpen = async (data: any, Option: string) => {
    
    if (Option === "edit") {
      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
      
    } else if (Option === "view") {

      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
    } else if (Option === "history") {
      setOption(Option);
      dispatch(
        getIndustryHistory({
          IndustryId: data.IndustryId,
          formID: FormIdConfig?.IndustryFormId,
        })
      );
    } else if (Option === "delete") {
     setOption(Option);
        setIsDeleteData(data);
    
    }
  };

  useEffect(() => {
    if (isindustryAdded) {
      dispatch(updateIndustryAddandEdit(false));
      setLableName("Industry");
      handleRecordLockUpdate();
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.Message,
          isTosterFailed: ToasterMsg.Status === "false" ? true : false,
        })
      
      );
      dispatch(
        getIndustryList({
          FormID: 2,
        })
      );
      if (userLoginResponse) {
        dispatch(getNotifications(userLoginResponse?.UserId));
      }
    } else if (isindustryAddedFailed) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));

      dispatch(updateIndustryAddandEdit(false));
      dispatch(
        getIndustryList({
          FormID: 2,
        })
      );
    } else if (isindustryDeleted) {
      setIsDeleteData(null);
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.Message,
          isTosterFailed: ToasterMsg.Status === "false" ? true : false,
        })
      );
      setTimeout(() => {
        dispatch(updateIndustryAddandEdit(false));
      }, 1000);
      dispatch(
        getIndustryList({
          FormID: 2,
        })
      );
    } else if (isindustryDeletedFailed) {
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.Message,
          isTosterFailed: ToasterMsg.Status === "false" ? true : false,
        })
      );

      dispatch(updateIndustryAddandEdit(false));
      dispatch(
        getIndustryList({
          FormID: 2,
        })
      );
    }
  }, [isindustryAdded, isindustryDeleted, isindustryAddedFailed, isindustryDeletedFailed]);

  const deleteDepartmentList = () => {

    let finededIndType = IndustryTypelov.find(
      (item) => item.Options === IndustryConfig?.Industry && isDeleteData.IndustryType == item.LovId
    );
    let JsonDataForDisplay: any = {
      "Industry Name": isDeleteData.IndustryName,
      "Industry Type": isDeleteData.IndustryTypeValues,
      Parent: isDeleteData.IndustryParentName,
      Level: isDeleteData.Parent_LevelValues,
      Status: isDeleteData.IsActive === "Active" ? "Inactive" : "Active",
      "Modified By": user?.UserName,
    };

    let formData = {
      Is_Active: isDeleteData.IsActive === "Active" ? false : true,
      Activity: isDeleteData.IsActive === "Active" ? "inactive" : "active",
      IndustryId: isDeleteData.IndustryId,
      IndustryName: isDeleteData.IndustryName,
      UserId: userLoginResponse?.UserId,
      MenuId: currentPagePermissionData?.MenuId,
      Form_Id: 8,
      ModuleName: finededIndType ? ModuleNames.IndustryMaster : "Sub Industry Master",
      CreatedBy: userLoginResponse?.UserName,
      DataForDisplay: JSON.stringify(JsonDataForDisplay),
    };
    dispatch(deleteIndustry(formData));
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {" "}
              {currentPagePermissionData?.MenuName}
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={IndustryData}
                handleAction={handleOpen}
                enableZoomIcon={true}
                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                handleAdd={() => setAddForm(true)}
                showAdd={true}
                showhistory={true}
                showDelete={permssionPage?.IsDelete === 1}
                showView={permssionPage?.IsView === 1}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={permssionPage?.IsExport === 1}
                pageName="Industry"
                 isEnableRecordLock = {{ IdField : "IndustryId" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                  FormID : FormIdConfig?.IndustryFormId,
                                
                                  MenuID : currentPagePermissionData?.MenuId,
                                  IsCheckPending : true  }}}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            </Box>
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => {
          setAddForm(false);
          setLableName("Industry");
        }}
        maxWidth={"xs"}
        minHeight={"50vh"}
        header={`Add ${LableName}`}
        contentNode={
          <AddIndustry
            view={false}
            closeIndustryPopUp={() => {
              setAddForm(false);
              setLableName("Industry");
            }}
            onchangelable={(value: any) => setLableName(value)}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          handleRecordLockUpdate();
          setIsEdit(false);
          setLableName("Industry");
        }}
        maxWidth={"xs"}
        minHeight={"50vh"}
        header={Option === "view" ? `View ${LableName}` : `Update ${LableName}`}
        contentNode={
          <>
            {Option === "edit" && (
              <AddIndustry
                view={false}
                closeIndustryPopUp={() => {
                  handleRecordLockUpdate();
                  setIsEdit(false);
                  setLableName("Industry");
                }}
                edit={isEdit}
                onchangelable={(value: any) => setLableName(value)}
                isEditData={IsEditData}
              />
              // <></>
            )}
            {Option === "view" && (
              <AddIndustry
                view={true}
                closeIndustryPopUp={() => {
                  setIsEdit(false);
                  setLableName("Industry");
                }}
                isEditData={IsEditData}
                onchangelable={(value: any) => setLableName(value)}
              />
              // <></>
            )}
          </>
        }
      />

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
          dispatch(updateVersionHistory([]));
        }}
        maxWidth={"lg"}
        header={"Industry Version History"}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistory.industryRecords}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Industry Version History"
            />
          </>
        }
      />

      <ModalPopUp
        onDeleteMethod={() => deleteDepartmentList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${
          isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
        } the selected Industry`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />
    </>
  );
}
