import { Box, FormHelperText, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import CustomButton from "../dynamicButton/CustomButton";
import CustomSvg from "../CustomSvg";
import { harmfulExtensions } from "./harmfulExtenstion";

interface CustomFileUploaderProps {
  onFileUpload: (files: File[] | null) => void;
  acceptFormats: string[];
  maxFileSize: number;
  title?: string;
  readOnly?: boolean;
  setImage?: (file: any) => void;
  // acceptFormats :
}

const CustomFileUploader: React.FC<CustomFileUploaderProps> = ({
  onFileUpload,
  acceptFormats,
  maxFileSize,
  title,
  readOnly = false,
  setImage,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Update state with accepted files
      onFileUpload(null);
      setFiles([]);
      if (validateFile(acceptedFiles)) {
        setFiles(acceptedFiles);
        if (setImage) {
          setImage(URL.createObjectURL(acceptedFiles[0]));
        }
        onFileUpload(acceptedFiles);
      }

      // Call the onFileUpload callback with the accepted files
    },
    [onFileUpload]
  );

  const removeFile = () => {
    setFiles([]);
    setError("");
    onFileUpload(null);
  };

  const validateFile = (file: File[]): boolean => {
    const fileObj = file[0];
    const filename = decodeURIComponent(fileObj.name);

    if (filename.includes("%00")) {
      setError("Invalid file name: Null byte detected.");
      return false;
    }

    const doubleExtensionRegex = /\.(\w+)\.(\w+)$/;
    if (doubleExtensionRegex.test(filename)) {
      setError("Invalid file format: Double extensions detected.");
      return false;
    }

    const hasHarmfulExtension = harmfulExtensions.some((ext) => filename.toLowerCase().endsWith(ext));
    if (hasHarmfulExtension) {
      setError("Upload of executable or potentially harmful files is not allowed.");
      return false;
    }

    const isValidFormat = acceptFormats.some((format) => filename.toLowerCase().endsWith(format));
    if (!isValidFormat) {
      setError(`Invalid file format. Accepted formats: ${acceptFormats.join(", ")}`);
      return false;
    }

    if (fileObj.size > maxFileSize) {
      setError(`File size exceeds the maximum limit of ${maxFileSize / (1024 * 1024)} MB`);
      return false;
    }

    setError("");
    return true;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <Box
        {...(!readOnly ? getRootProps() : {})}
        style={dropzoneStyles}
        sx={{ border: error ? "1px dashed #D70000" : "1px dashed #6A6A6A" }}
      >
        <input {...getInputProps()} />

        <CustomSvg name={"upload-cloud"} size={"20px"} />

        <Typography sx={{ fontSize: "16px" }} variant="h6" color={"text.primary"}>
          {`Drag and drop the ${title ? title : "file"} here`}
        </Typography>

        <Typography sx={{ fontSize: "16px" }} variant="h6" color={"text.primary"}>
          or{" "}
        </Typography>

        <CustomButton
          variant="text"
          name={`Browse file`}
          size="small"
          onClick={() => {
            getInputProps();
          }}
        />
      </Box>

      {files.map((file, index) => (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Box key={index} sx={{ display: "flex" }}>
            <Typography sx={{ color: "#909090" }}>Selected file : </Typography>{" "}
            <Typography color={"text.primary"}>
              {" "}
              {file.name} - {formatBytes(file.size)}
            </Typography>
          </Box>

          <Typography sx={{ color: "#1D6DE5", cursor: "pointer" }} onClick={() => removeFile()}>
            Remove file
          </Typography>
        </Box>
      ))}
      <FormHelperText error={Boolean(error)} id="my-helper-text">
        {error}
      </FormHelperText>
    </>
  );
};

// Style for the dropzone
const dropzoneStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  borderRadius: "6px",
  padding: "10px",
  textAlign: "center",
  cursor: "pointer",
  marginBottom: "8px",
  height: "100%",
  minHeight: "70px",
  width: "100%",
  // display:'flex',
  // alignItems:'center',
  // justifyContent:'center' ,
  // flexDirection:'column'
};

// Style for images
const imageStyles: React.CSSProperties = {
  maxWidth: "100%",
  maxHeight: "200px",
};

// Function to format file size
const formatBytes = (bytes: number): string => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export default CustomFileUploader;
