import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomDatePicker from "../../common/dynamicInputs/CustomDatePicker";
import CustomButton from "../../common/dynamicButton/CustomButton";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatch } from "../../store/store";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { ParameterForm } from "./ParameterForm";
import { IMenu } from "../../models/loggin.type";
import {
  getSecurityParameters,
  getSecurityParameterHistory,
  updateSecurityParameter,
  deleteSecurityParameter,
  updateAPAddandEdit,
} from "../../store/slices/securityParameters";

import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { Edit_Enable_tosterMSG, FormIdConfig } from "../../config/config";
import masterEditEnableService from "../../store/services/masterEditEnableService";
import { RecordLockRelease } from "../recordLock/CommonRecordLock";


export interface SecurityParametersProps {
  handlePrevious?: () => void;
  handleNext?: () => void;
}

export const SecurityParameters: React.FC<SecurityParametersProps> = ({ handlePrevious, handleNext }) => {
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [formDisplay, setformDisplay] = useState(false);
  const [filterTableData, setFilterTableData] = useState<any[]>([]);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const [versionHistoryData, getVersionHistoryData] = useState<any>();
  const [tableData, setTableData] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const { SecurityParameterData, isAPAdded, isAPDeleted, isAPUpdated, versionHistory, apToasterMsg } = useSelector(
    (state: RootState) => state.securityParameters
  );
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { currentpageTitleData } = useSelector(
    (state: RootState) => state.FormTitleMasterData
  );


    const { handleRecordLockUpdate } = RecordLockRelease();
  
  const columns_version: any = {
    table_name: "Security Parameter History Records",
    isAction: false,
    columns: [],
  };

  useEffect(() => {
    if (versionHistory.SecuritySPHeader.length > 0 && versionHistory.SecuritySPHeader.length > 0) {
      if (versionHistory.SecuritySPHeader && versionHistory.SecuritySPHeader.length > 0) {
        // setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory.SecuritySPHeader,
        };
        getVersionHistoryData(re_columns);
      }
    }
  }, [versionHistory]);
  console.log(versionHistoryData, versionHistory, "versionHistoryData");

  let Table_Data = [
    {
      id: "1",
      ParameterGroup: "Reginonal Settings",
      ParameterName: "Date Format",
      Description: "Date format for display",
      Value: "dd/mm/yyyy",
      Options: "hh:mm:ss",
      Status: "Active",
    },
    {
      id: "2",
      ParameterGroup: "Reginonal Settings",
      ParameterName: "Date Format",
      Description: "Date format for display",
      Value: "dd/mm/yyyy",
      Options: "hh:mm:ss",
      Status: "Active",
    },
    {
      id: "3",
      ParameterGroup: "Reginonal Settings",
      ParameterName: "Date Format",
      Description: "Date format for display",
      Value: "dd/mm/yyyy",
      Options: "hh:mm:ss",
      Status: "Active",
    },
  ];

  const columns: any = {
    table_name: "Security Parameters",
    isAction: true,
    columns: [
      {
        Field: "SecurityParameterGroup",
        DisplayName: "Parameter Group",
        FieldType: "string",
      },
      {
        Field: "SecurityParameterName",
        DisplayName: "Parameter Name",
        FieldType: "string",
      },

      {
        Field: "Description",
        DisplayName: "Description",
        FieldType: "string",
      },
      {
        Field: "Value",
        DisplayName: "Value",
        FieldType: "string",
      },
      {
        Field: "Options",
        DisplayName: "Options",
        FieldType: "string",
      },
      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",
      },
      {
        Field: "Updated_By",
        DisplayName: "Modified By",
        FieldType: "string",
      },
    ],
  };

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    dispatch(
      getSecurityParameters({
        FormID: 20,
      })
    );
  }, []);
  const deleteDepartmentList = () => {
    console.log(isDeleteData,"isDeleteData");
    
    let JsonDataForDisplay: any = {
      "Security Parameter Name": isDeleteData.SecurityParameterName,
      "Security Parameter Group": isDeleteData?.SecurityParameterGroup || "",
      Description: isDeleteData?.Description || "",
      Value: isDeleteData?.Value || "",
      Options: isDeleteData?.Options || "",
      "Status": isDeleteData.IsActive === "Active" ? "Inactive" : "Active",
      "Modified By": user?.UserName,
    }
    let formData = {
      SecurityParameterId: isDeleteData.SecurityParameterId,
      SecurityParameterName: isDeleteData.SecurityParameterName,
      CreatedBy: userLoginResponse?.UserName,
      ModuleName: "Security Parameters",
      UserId: userLoginResponse?.UserId,
      MenuId: currentPagePermissionData?.MenuId,
      Form_Id: 20,
      IsActive: isDeleteData.IsActive === "Active" ? false : true,
      Activity: isDeleteData.IsActive === "Active" ? "inactive" : "active",
      DataForDisplay: JSON.stringify(JsonDataForDisplay),
    };

    // setLoader(true);
    dispatch(deleteSecurityParameter(formData));
  };

  console.log(isAPAdded, "isAPAdded");
  useEffect(() => {
    if (isAPAdded) {
      dispatch(updateAPAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: apToasterMsg }));
      dispatch(
        getSecurityParameters({
          FormID: 20,
        })
      );
    } else if (isAPUpdated) {
      dispatch(updateAPAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: apToasterMsg }));
      handleRecordLockUpdate();
      dispatch(
        getSecurityParameters({
          FormID: 20,
        })
      );
    } else if (isAPDeleted) {
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: apToasterMsg }));
      setTimeout(() => {
        dispatch(updateAPAddandEdit(false));
      }, 1000);
      dispatch(
        getSecurityParameters({
          FormID: 20,
        })
      );
    }
  }, [isAPAdded, isAPDeleted, isAPUpdated]);
  useEffect(() => {
    setTableData(SecurityParameterData);
  }, [SecurityParameterData]);


 

  const handleOpen = async (data: any, Option: string) => {
    console.log(data, Option, "Option");
    if (Option === "edit") {

      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
    
      } else if (Option === "view") {
      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
    } else if (Option === "history") {
      setOption(Option);
      setIsVersionHistory(true);
      dispatch(
        getSecurityParameterHistory({
          SecurityParameter_Id: data.SecurityParameterId,
          FormId: FormIdConfig?.SecurityParameterFormId,
        })
      );
    }
    if (Option === "delete") {
      setOption(Option);
      setIsDeleteData(data);
    }
  };

  const previous = () => {
    handlePrevious && handlePrevious();
  };

  const next = () => {
    handleNext && handleNext();
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          // backgroundColor: "#ffff",
          // backgroundColor: "background.default",
          // px: 3,
          // py: 4,
          // borderRadius: "10px",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* <Box sx={{ display: "flex", width: "25%", }} >
                            <CustomSelect
                                name={"listName"}
                                valueKey={"id"}
                                optionLabelKey="lable"
                                options={location_Data}
                                value={formData.listName}
                                onChange={handleChange}
                                required={true}
                                label={"Select List Name"}
                            />
                        </Box> */}
             <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                        </Typography>

            {currentPagePermissionData?.MenuName === "Customer Onboarding" ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "center",
                  }}
                >
                  <CustomButton variant="outlined" name={"Previous Master"} onClick={() => previous()} size="small" />
                  <CustomButton
                    variant="contained"
                    name={"Proceed To Next Master"}
                    onClick={() => next()}
                    size="small"
                  />
                </Grid>
              </Box>
            ) : (
              <></>
            )}
          </Box>

          {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Grid
                            sx={{
                                display: "flex",
                                gap: "20px",
                                justifyContent: "center",
                                mt: 2
                            }}
                        >
                            
                            <CustomButton variant="contained" name={"Add Parameter"} onClick={() => setAddForm(true)} size="small" />
                        </Grid>
                    </Box> */}

          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={tableData}
                handleAction={handleOpen}
                enableZoomIcon={true}
                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                handleAdd={() => setAddForm(true)}
                showAdd={true}
                showhistory={true}
                showDelete={permssionPage?.IsDelete === 1}
                showView={permssionPage?.IsView === 1}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={permssionPage?.IsExport === 1}
                pageName="Security Parameters"
                 isEnableRecordLock = {{ IdField : "SecurityParameterId" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                                  FormID : FormIdConfig?.SecurityParameterFormId,
                                                
                                                  MenuID : currentPagePermissionData?.MenuId,
                                                  IsCheckPending : true  }}}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            </Box>
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"md"}
        minHeight={"50vh"}
        header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Security Parameter"}`}
        contentNode={
          // <></>
          <ParameterForm
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
            ModuleId={permssionPage?.MenuId}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          handleRecordLockUpdate();
          setIsEdit(false);
        }}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={Option === "view" ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Security Parameter"}` : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Security Parameter"}`}
        contentNode={
          <>
            {Option === "edit" && (
              <ParameterForm
                view={false}
                closePopUp={() => {
                  handleRecordLockUpdate();
                  setIsEdit(false);
                }}
                edit={isEdit}
                isEditData={IsEditData}
                ModuleId={permssionPage?.MenuId}
              />
              // <></>
            )}

            {Option === "view" && (
              <ParameterForm
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                isEditData={IsEditData}
                ModuleId={permssionPage?.MenuId}
              />
              // <></>
            )}
          </>
        }
      />

      {versionHistory?.HistoryRecords && (
        <CustomDialog
          show={isVersionHistory}
          onHide={() => {
            setIsVersionHistory(false);
            // clear the redux
          }}
          maxWidth={"lg"}
          header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Security Parameter"} Version History`}
          contentNode={
            <>
              {" "}
              <MaterialDynamicGrid
                data={versionHistoryData}
                rows={versionHistory.HistoryRecords}
                handleAction={handleOpen}
                enableZoomIcon={false}
                showDelete={false}
                showhistory={false}
                showView={false}
                showEdit={false}
                showExports={true}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                pageName="Security Parameter Version History"
              />
            </>
          }
        />
      )}
      <ModalPopUp
        onDeleteMethod={() => deleteDepartmentList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
          } the selected Parameter`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />
    </>
  );
};
