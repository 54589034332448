import { FC, useCallback, useEffect, useReducer, useState } from "react";
// import Dashboard from "../components/dashboard/Dashboard";
import AddMasters from "../components/addMaster/AddMasters";
import { Box, Button, CircularProgress } from "@mui/material";
import Header from "../components/header/Header";
//import Sidenavbar from "../components/navbars/SideNavBar";
import Sidenavbar from "../components/navbars/SideNavBarNew";
import Dashboard from "../components/dashboard/Dashboard";
import ErrorLog from "../components/errorLog/errorLog";
import EventLogs from "../components/EventLog";
import VendorMain from "../addvendor/VendorMain";
import UserPage from "../components/userManagement/UserManagement";
import RolesManagement from "../components/rolesManagement/RolesManagement";
import EmailTemplate from "../components/emailTemplate/EmailTemplate";
import Toaster from "../components/toaster/Toaster";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../store/store";
import Backdrop from "@mui/material/Backdrop";
import Department from "../components/master/department/Department";
import AssessmentCategory from "../components/master/assessmentCategoryMaster/assessmentCategoryMaster";
import ChangePassword from "../components/changePassword/ChangePassword";
import { Footer } from "../components/footer/Footer";
import BulkUpload from "../components/master/masterBulkUpload/BulkUpload";
import MasterMain from "../components/masterMain/MasterMain";
import SubDepartmentMaster from "../components/subDepartmentMaster/SubDepartmentMaster";
import MakerChecker from "../components/makerChecker/MakerChecker";
import PendingApproval from "../components/pendingApproval/PendingApproval";
import AssessmentQuestionnaireMaster from "../components/master/assessmentQuestionnaireMaster/AssessmentQuestionnaireMaster";
import VendorConsentForm from "../components/master/contentMaster/ContentMaster";
import Industry from "../components/master/industry/Industry";
import Location from "../components/master/location/Location";
import ListofValues from "../components/master/listofValues/ListofValues";
import SamMakerChecker from "../components/makerChecker/SamMakerChecker";
import SamPendingApproval from "../components/pendingApproval/SamPendingApproval";
import DocumentMaster from "../components/master/documentMaster/DocumentMaster";
import { CurrencyMaster } from "../components/master/currencyMaster/CurrencyMaster";
import IrRiskMaster from "../components/irRiskMaster/IrRiskMaster";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { setCurrentPagePermission } from "../store/slices/authorization";
import { SecurityParameters } from "../components/SecurityParameters/SecurityParameters";
import { BranchMaster } from "../components/master/branchMaster/BranchMaster";
import { ApplicationParrameters } from "../components/applicationParameters/ApplicationParameters";
import { ThirdPartyMaster } from "../components/master/thirdPartyMaster/ThirdPartyMaster";
import { RegionalSettings } from "../components/master/regionalSettings/RegionalSettings";
import ServiceCatalogMaster from "../components/serviceCatalog/ServiceCatalogMaster";
import VendorDetailsMaster from "../components/vendorDetails/VendorDetailsMaster";
import { CompanyMasterMain } from "../components/companyMaster/CompanyMasterMain";
import WorkFlow from "../components/workflow/WorkFlow";
import CreateWorkFlow from "../components/workflow/CreateWorkFlow";
import { getNotifications } from "../store/slices/notification";
import RiskRatingMaster from "../components/master/riskRatingMaster/RiskRatingMaster";
import { AQMMapping } from "../components/vendorOnBoarding/aqmMapping/AQMMapping";
import WorkFlowExecution from "../components/workFlowExecution/WorkFlowExecution";
import { MetricsMaster } from "../components/metricsMaster/MetricsMaster";
import { PendingActions } from "../components/pendingActions/PendingActions";
import Protected from "../ProtectedRoute";
import { WorkFlowHistory } from "../components/workFlowHistory/WorkFlowHistory";
import { Renewal } from "../components/renewal/Renewal";
import { getApplicationParameterList } from "../store/slices/applicationParameterData";
import { WelcomeGreetings } from "../components/welcomeGreetings/WelcomeGreetings";

import TextEditor from "../common/textEditor/TextEditor";
import ContractTemplate from "../components/contractManagement/ContractTemplate/ContractTemplate";

import { getFavouriteNavMenu } from "../store/slices/FavouriteNavData";
import OnlyOfficeTextEditor from "../common/textEditor/OnlyOfficeTextEditor";

import ContractTemplateList from "../components/contractManagement/ContractTemplate/ContractTemplateList";

import AddClause from "../components/contractManagement/ClauseLibrary/AddClause";
import ContractList from "../components/contractManagement/ContractList/ContractList";
import ClauseList from "../components/contractManagement/ClauseLibrary/ClauseList";
import ClauseTypeMaster from "../components/contractManagement/ClauseTypeMaster/ClauseTypeMaster";
import ContractTypeMaster from "../components/contractManagement/ContractTypeMaster/ContractTypeMaster";
import { VendorWithdrawal } from "../components/vendorWithdrawal/VendorWithdrawal";
import CommonMasterBulkUpload from "../components/master/masterBulkUpload/CommonMasterBulkUpload";
import HolidayMaster from "../components/master/holidayMaster/HolidayMaster";
import CostApproval from "../components/contractManagement/CostApproval/CostApproval";
import { ContractRolesandResponsibility } from "../components/contractManagement/rolesAndResponsibility/ContractRolesandResponsibility";
import NotifySigningAuthority from "../components/contractManagement/NotifySigningAuthority/NotifySigningAuthority";
import AddClauseStepper from "../components/contractManagement/ClauseLibrary/AddClauseStepper";
import BoardApproval from "../components/contractManagement/BoardApproval/BoardApproval";
import RolesAndResponsibilities from "../components/master/R&R Setup/RandRMaster";
import { VendorReview } from "../components/contractManagement/vendorReview/VendorReview";
import { ContractCostApproval } from "../components/contractManagement/contractCostApprovalmetrics/ContractCostApproval";
import CustomerOnBoardingAssessment from "../components/customerOnBoarding/COBAssessments/CustomerOnBoardingAssessment";
import { AQMMappingCOB } from "../components/customerOnBoarding/AQMappingforCOB/AQMappingCOB";
import CXOApproval from "../components/customerOnBoarding/CXOApproval/CXOApproval";
import { getLovList } from "../store/slices/lovMasterData";
import VendorLoginpage from "../vendorPortal/vendorLogin/VendorLoginPage";
import { ApiMaster } from '../components/apiMaster/ApiMaster';
import ScreenTitleMaster from "../components/ScreenTitleMaster/ScreenTitleMaster";
import ApiCatalog from "../components/apiCatalogue/ApiCatalog";
import PhysicalUpload from "../components/contractManagement/PhysicalUpload/PhysicalUpload";
import { ReleaseRecordLock } from "../store/slices/recordLock";
import { RecordLockRelease } from "../components/recordLock/CommonRecordLock";
import recordLockService from "../store/services/recordLockService";
import ArticleMaster from "../components/contractManagement/ArticleMaster/ArticleMaster";
import { getAllApiCatalog } from "../store/slices/apiCatalog";
import { ApplicationParameterName } from "../config/config";
import ApiCatalogService from "../store/services/apiCatalog";
import { ProApiDetailsprops, updateProApiDetails } from "../store/slices/ProbeData";
import ContractTermination from "../components/contractManagement/ContractTermination";
import ScopeApprovel from "../components/performanceManagement/ScopeApprovel/ScopeApprovel";
import { ScopeDefinition } from "../components/performanceManagement/scopedefinition/ScopeDefinition";
import { PREventLog } from "../components/performanceManagement/prEventLog/PREventLog";
import ContractView from "../components/performanceManagement/ContractView/ContractView";
import PrScopeView from "../components/performanceManagement/PRScopeView/PrScopeView";
import { SeverityMaster } from "../components/performanceManagement/SLAseverityMaster/SeverityMaster";
import { DeviationToleranceLevelMaster } from "../components/performanceManagement/deviationToleranceLevelMaster/DeviationToleranceLevelMaster";
import { Threshold } from "../components/performanceManagement/thresholdMaster/Threshold";
import { PMResponse } from "../components/performanceManagement/prReviews/PMResponse";
interface BackdropState {
  width: number;
  isExpanded: boolean;
}

type Action = { type: "SET_EXPANDED"; payload: boolean } | { type: "SET_WIDTH"; payload: number } | { type: "RESET" };

const initialState: BackdropState = {
  width: 0,
  isExpanded: false,
};

function backdropReducer(state: BackdropState, action: Action): BackdropState {
  switch (action.type) {
    case "SET_EXPANDED":
      return { ...state, isExpanded: action.payload };
    case "SET_WIDTH":
      return { ...state, width: action.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
}

export const AppRoutes: FC = () => {
  const navigate = useNavigate();

  const { show, showHeader } = useSelector((state: RootState) => state.menu);
  const { menuData, userLoginResponse, isAuthenticated, isVendorAuthenticated } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { ApiCatalogTable } =
    useSelector((state: RootState) => state.ApiCatalogData);
  const { lockeRecorddData } = useSelector(
    (state: RootState) => state.recordLock
  );
  const { applicationParameterData } = useSelector(
    (state: RootState) => state.applicationParameter
  );

  const [backdropState, dispatchBackdrop] = useReducer(backdropReducer, initialState);
  const [backDropIsOpen, setIsBackDropIsOpen] = useState<boolean>(false);
  const dispatch = useAppDispatchThunk();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(
        getApplicationParameterList({
          FormID: 2,
        })
      );
      dispatch(getLovList({ Name: "" }));
      dispatch(
        getFavouriteNavMenu({
          UserId: userLoginResponse && userLoginResponse.UserId,
        })
      );
      dispatch(
        getAllApiCatalog({
          FormID: 2
        })
      );
    }

  }, [isAuthenticated]);
  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.setItem("preUrl", location.pathname);
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);


  const UpdateApiDetailsUpdate = (catalogdata: any) => {
    let Details: ProApiDetailsprops = {
      baseURL: "",
      version: "1.1",
      apiKey: "",
      accept: "application/json",
      inputMapping: [],
      outputMapping: []
    }
    console.log(catalogdata, "catalogdata");
    if (catalogdata?.IsActive) {
      Details.baseURL = catalogdata?.URL || "";
      let ApiDetails = catalogdata?.ApiMasters[0];
      if (ApiDetails) {
        Details.apiKey = ApiDetails?.API_Key || "";
        Details.version = ApiDetails?.API_Version || "";
        Details.accept = ApiDetails?.Format || "application/json";
      }

      if (catalogdata?.APIParameters && catalogdata?.APIParameters?.length > 0) {
        Details.inputMapping = catalogdata.APIParameters || []
      }

      if (catalogdata?.ApiFields && catalogdata?.ApiFields?.length > 0) {
        Details.outputMapping = catalogdata.ApiFields || []
      }
    }



    dispatch(updateProApiDetails(Details))

  }

  useEffect(() => {

    const FetchApiCatalogDetails = async () => {
      let ApiGrioupCode = applicationParameterData.find(
        (applicationParamitem: any) =>
          applicationParamitem.Parameter_Name === ApplicationParameterName.companyDetailsFromTrirdPartys
      )?.Parameter_Value;
      if (ApiGrioupCode) {
        const API_GroupId: any = ApiCatalogTable?.find((ApiCatalogItem: any) => ApiCatalogItem?.APICode == ApiGrioupCode)?.Catalog_Id

        if (API_GroupId) {
          try {
            const response: any = await ApiCatalogService.GetApiCatalogWithAllDetailsByID({ CatalogID: API_GroupId });
            const { data } = response;
            UpdateApiDetailsUpdate(data);
          } catch (error) {
            console.log("error Details Fetch ");

          }
        }
      }
    }

    if (applicationParameterData && applicationParameterData?.length > 0 && ApiCatalogTable && ApiCatalogTable?.length > 0 && isAuthenticated) {
      FetchApiCatalogDetails()
    }

  }, [ApiCatalogTable, applicationParameterData, isAuthenticated])

  const findMenuByURL = useCallback((menus: any, targetUrl: string) => {
    for (let menu of menus) {
      if (menu.Url && menu.Url?.trim() === targetUrl) {
        return menu;
      }
      if (menu.SubMenus && menu.SubMenus.length > 0) {
        const foundMenu: any = findMenuByURL(menu.SubMenus, targetUrl);
        if (foundMenu) {
          return foundMenu;
        }
      }
    }
    return null;
  }, []);

  useEffect(() => {
    if (userLoginResponse) {
      dispatch(getNotifications(userLoginResponse?.UserId));
    }
    let currentPath = location.pathname;
    // console.log(currentPath, "currentPath");


    if (currentPath && menuData && menuData.length > 0) {
      const matchingMenu = findMenuByURL(menuData, currentPath);
      // console.log(matchingMenu, "matchingMenu", "currentPath");
      if (matchingMenu !== null) {
        dispatch(setCurrentPagePermission(matchingMenu));

      }
    }
  }, [location.pathname, menuData, dispatch, findMenuByURL, userLoginResponse]);

  const handleClose = useCallback(() => {
    setIsBackDropIsOpen(false);
    dispatchBackdrop({ type: "SET_EXPANDED", payload: false });
    dispatchBackdrop({ type: "SET_WIDTH", payload: show ? 50 : 0 });
  }, [show]);

  const handleOpen = useCallback(
    (data: any) => {
      const isExpanded = !!data;
      const width = show ? (isExpanded ? 310 : 50) : 0;
      dispatchBackdrop({ type: "SET_EXPANDED", payload: isExpanded });
      dispatchBackdrop({ type: "SET_WIDTH", payload: width });
    },
    [show]
  );
  useEffect(() => {
    dispatchBackdrop({ type: "SET_WIDTH", payload: show ? (backdropState.isExpanded ? 310 : 50) : 0 });
  }, [show, backdropState.isExpanded]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (lockeRecorddData) {
        event.preventDefault();
        event.returnValue = "Are you sure you want to leave?"; // Display a confirmation dialog
      }
    };

    const handleUnload = async () => {
      if (lockeRecorddData) {
        // Attempt to release the lock proactively
        try {
          sessionStorage.setItem('recordlock', JSON.stringify(lockeRecorddData))
          // await handleRecordLockUpdate(lockeRecorddData);
        } catch (error) {
          console.error("Failed to release record lock:", error);
        }
      }
    };

    // Attach event listeners
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      // Cleanup event listeners on unmount
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [lockeRecorddData]);

  const handleRecordLockUpdate = async (lockeRecorddData: any) => {
    try {
      const response: any = await recordLockService.UpdateRecordLock(lockeRecorddData);
      return true;
    } catch (error) {
      console.error("Error updating record lock:", error);
      return false;
    }
  };

  useEffect(() => {
    const handleUnload = async () => {
      let RecordLockValue = sessionStorage.getItem('recordlock')
      // localStorage.clear();

      if (!lockeRecorddData && RecordLockValue) {
        try {
          await handleRecordLockUpdate(JSON.parse(RecordLockValue));
          sessionStorage.removeItem("recordlock");
        } catch (error) {
          console.error("Failed to release record lock:", error);
        }
      }
    }

    handleUnload()

  }, [sessionStorage.getItem('recordlock'), lockeRecorddData])

  // useEffect(() => {
  //   const tabId = sessionStorage.getItem("tabId") || Math.random().toString(36).substr(2, 9);
  //   sessionStorage.setItem("tabId", tabId); // Ensure tabId is set for this session

  //   const incrementTabCount = () => {
  //     const openTabs = JSON.parse(localStorage.getItem("openTabs") || "{}");
  //     openTabs[tabId] = true; // Mark this tab as open
  //     localStorage.setItem("openTabs", JSON.stringify(openTabs));
  //   };

  //   const decrementTabCount = () => {
  //     const openTabs = JSON.parse(localStorage.getItem("openTabs") || "{}");
  //     delete openTabs[tabId]; // Remove this tab from the list
  //     if (Object.keys(openTabs).length === 0) {
  //       localStorage.clear(); // Clear if no tabs are open
  //     } else {
  //       localStorage.setItem("openTabs", JSON.stringify(openTabs));
  //     }
  //   };

  //   // Increment the tab count on load
  //   incrementTabCount();

  //   window.addEventListener("beforeunload", decrementTabCount);

  //   return () => {
  //     // Cleanup on component unmount
  //     decrementTabCount();
  //     window.removeEventListener("beforeunload", decrementTabCount);
  //   };
  // }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", overflow: "hidden" }}>
      {showHeader && (
        <Box
          sx={{
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
            borderBottom: "1px solid rgb(182 181 181 / 87%)",
          }}
        >
          <Header />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flex: 1,
          height: "100%",
          overflow: "hidden",
        }}
      >
        {show && (
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              width: "fit-content",
              boxShadow: "inset -12px 0px 8px 10px rgba(103, 103, 103, 0.30)",
            }}
          >
            <Sidenavbar handleOpen={handleOpen} setIsBackDropIsOpen={setIsBackDropIsOpen} />
          </Box>
        )}
        {/* <Backdrop
          sx={{
            left: backdropState.width,
            background: "transparent !important",
            top: 60,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={backDropIsOpen}
          onClick={handleClose}
        /> */}

        <Box
          sx={{
            flex: 1,
            overflowY: "hidden",
            overflowX: "hidden",
            // backgroundColor: "#f5f5f5",
            backgroundColor: "background.paper",
            padding: 0,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ overflowY: "scroll", overflowX: "hidden", px: 2, my: 1, flex: 1 }}>
            <Routes>
              {/* <Route path="/*" element={<Master />} /> */}
              <Route
                path="/dashboard"
                element={
                  <Protected isAllowed="/dashboard">
                    <Dashboard />
                  </Protected>
                }
              />
              <Route path="/errorLog" element={<ErrorLog />} />
              <Route path="/eventLogs" element={<Protected isAllowed="/eventLogs"> <EventLogs /> </Protected>} />
              <Route path="/add_master" element={<Protected isAllowed="/add_master"> <AddMasters /> </Protected>} />
              <Route path="/emailTemplate" element={<Protected isAllowed="/emailtemplate"> <EmailTemplate /> </Protected>} />
              <Route path="/toasterTemplate" element={<Protected isAllowed="/toasterTemplate"> <Toaster /> </Protected>} />
              <Route path="/vendor_management" element={<Protected isAllowed="/vendor_management"> <VendorMain /> </Protected>} />
              <Route path="/users" element={<Protected isAllowed="/users"> <UserPage /> </Protected>} />
              <Route path="/roles" element={<Protected isAllowed="/roles"> <RolesManagement /> </Protected>} />
              {/* <Route path="/department" element={<Department/>}/> */}
              <Route path="/changepassword" element={<ChangePassword />} />
              {/* <Route path="/bulkupload" element={<BulkUpload />} /> */}
              <Route path="/bulkupload" element={<Protected isAllowed="/bulkupload"> <CommonMasterBulkUpload /> </Protected>} />
              <Route path="/mastermain" element={<Protected isAllowed="/mastermain"> <MasterMain /> </Protected>} />
              <Route path="/listofvalues" element={<Protected isAllowed="/listofvalues"> <ListofValues /> </Protected>} />
              <Route path="/industry" element={<Protected isAllowed="/industry"> <Industry /> </Protected>} />
              <Route path="/location" element={<Protected isAllowed="/location"> <Location /> </Protected>} />
              <Route path="/documentmaster" element={<Protected isAllowed="/documentmaster"> <DocumentMaster /> </Protected>} />
              <Route path="/departmentmaster" element={<Protected isAllowed="/departmentmaster"> <Department /> </Protected>} />
              <Route path="/assessmentCategoryMaster" element={<Protected isAllowed="/assessmentCategoryMaster"> <AssessmentCategory /> </Protected>} />
              <Route path="/subdepartmentmaster" element={<Protected isAllowed="/subdepartmentmaster"> <SubDepartmentMaster /> </Protected>} />
              <Route path="/currencymaster" element={<Protected isAllowed="/currencymaster"> <CurrencyMaster /> </Protected>} />
              <Route path="/serviceCatalog" element={<Protected isAllowed="/serviceCatalog"> <ServiceCatalogMaster /> </Protected>} />
              {/* <Route path="/vendorMaster" element= {<VendorDetailsMaster/>}/> */}
              <Route path="/holidaymaster" element={<Protected isAllowed="/holidaymaster"> <HolidayMaster /> </Protected>} />
              <Route path="/formtitlemaster" element={<Protected isAllowed="/formtitlemaster"> <ScreenTitleMaster /> </Protected>} />

              <Route path="/makerChecker" element={<Protected isAllowed="/makerChecker"> <MakerChecker /> </Protected>} />
              <Route path="/smMakerChecker" element={<Protected isAllowed="/smMakerChecker"> <SamMakerChecker /> </Protected>} />
              <Route
                path="/pendingApproval"
                element={
                  <Protected isAllowed="/pendingApproval">
                    <PendingApproval />
                  </Protected>
                }
              />

              {/* <Route path='/vendorOnBoarding/aqmMapping' element={<AQMMapping />} /> */}
              <Route path="/assessmentQuestionnaireMaster" element={<Protected isAllowed="/assessmentQuestionnaireMaster"><AssessmentQuestionnaireMaster /></Protected>} />
              <Route path="/vendorConsentForm" element={<Protected isAllowed="/vendorConsentForm"> <VendorConsentForm /> </Protected>} />
              <Route path="/samPendingApproval" element={<Protected isAllowed="/samPendingApproval"> <SamPendingApproval /> </Protected>} />
              <Route path="/irRiskMaster" element={<Protected isAllowed="/irRiskMaster"> <IrRiskMaster /> </Protected>} />
              <Route path="/security_parameter" element={<Protected isAllowed="/security_parameter">  <SecurityParameters /> </Protected>} />
              {/* <Route path="/companymaster" element= {<CompanyMaster/>}/> */}
              <Route path="/companymaster" element={<Protected isAllowed="/companymaster">  <CompanyMasterMain /> </Protected>} />
              <Route path="/branchmaster" element={<Protected isAllowed="/branchmaster"> <BranchMaster /> </Protected>} />
              <Route path="/applicationparameter" element={<Protected isAllowed="/applicationparameter">  <ApplicationParrameters /> </Protected>} />
              <Route path="/associatedpartymaster" element={<Protected isAllowed="/associatedpartymaster">  <ThirdPartyMaster /> </Protected>} />
              <Route path="/regionalsettings" element={<Protected isAllowed="/regionalsettings">  <RegionalSettings /> </Protected>} />
              <Route path="/workFlows" element={<Protected isAllowed="/workFlows">  <WorkFlow /> </Protected>} />
              <Route path="/addworkFlow" element={<Protected isAllowed="/addworkFlow"> <CreateWorkFlow /> </Protected>} />
              <Route path="/thirdpartymaster" element={<Protected isAllowed="/thirdpartymaster">  <VendorDetailsMaster /> </Protected>} />
              <Route path="/riskcategorymaster" element={<Protected isAllowed="/riskcategorymaster">  <RiskRatingMaster /> </Protected>} />
              <Route
                path="/pendingActions"
                element={
                  <Protected isAllowed="/pendingActions">
                    <PendingActions />
                  </Protected>
                }
              />
              <Route
                path="/pendingActions/:process_Id"
                element={
                  <Protected isAllowed="/pendingActions">
                    <PendingActions />
                  </Protected>
                }
              />
              <Route path="/workflowhistory" element={<Protected isAllowed="/workflowhistory">  <WorkFlowHistory /> </Protected>} />

              <Route path="/metricsMaster" element={<Protected isAllowed="/metricsMaster">  <MetricsMaster /> </Protected>} />
              {/* <Route path="/workFlowExecution" element={<WorkFlowExecution />} /> */}
              <Route path="/assessmentHistory" element={<Protected isAllowed="/assessmentHistory">  <Renewal /> </Protected>} />
              <Route path="/workFlowExecution" element={<WelcomeGreetings />} />
              {/* <Route path="/vendorassessment" element={<TextEditor />} /> */}
              <Route path="/textEditor" element={<Protected isAllowed="/textEditor"> <OnlyOfficeTextEditor /> </Protected>} />
              {/* <Route path="/vendorassessment" element={<TextEditor />} /> */}
              <Route path="/initiateContract" element={<Protected isAllowed="/initiateContract"> <ContractList /> </Protected>} />
              <Route path="/contract-templates/" element={<Protected isAllowed="/contract-templates"> <ContractTemplateList /> </Protected>} />
              <Route path="/clauseTypeLibrary" element={<Protected isAllowed="/clauseTypeLibrary"> <ClauseList /> </Protected>} />
              <Route path="/withdrawconsent" element={<Protected isAllowed="/withdrawconsent"> <VendorWithdrawal /> </Protected>} />
              <Route path="/costApproval" element={<Protected isAllowed="/costApproval"> <CostApproval /> </Protected>} />
              <Route path="/clauseTypeMaster" element={<Protected isAllowed="/clauseTypeMaster"> <ClauseTypeMaster /> </Protected>} />
              <Route path="/contractTypeMaster" element={<Protected isAllowed="/contractTypeMaster"> <ContractTypeMaster /> </Protected>} />
              <Route path="/contract/workflows/Roles-and-Responsibility" element={<Protected isAllowed="/contract/workflows/Roles-and-Responsibility"> <ContractRolesandResponsibility /> </Protected>} />
              <Route path="/notify-signing" element={<NotifySigningAuthority />} />
              <Route path="/addClause" element={<AddClauseStepper />} />
              <Route path="/addTemplate" element={<ContractTemplate />} />
              <Route path="/boardApproval" element={<Protected isAllowed="/boardApproval"> <BoardApproval /> </Protected>} />
              <Route path="/rolesandresponsibilities" element={<Protected isAllowed="/rolesandresponsibilities"> <RolesAndResponsibilities /> </Protected>} />
              <Route path="/contract/vendorReview" element={<Protected isAllowed="/contract/vendorReview"> <VendorReview isEditData={null} /> </Protected>} />
              <Route path="/contract/contractCostApproval" element={<Protected isAllowed="/contract/contractCostApproval"> <ContractCostApproval /> </Protected>} />
              {/* <Route path="/initatieContract" element={<ContractTypeMaster />} /> */}
              <Route path="/CXOApproval" element={<Protected isAllowed="/CXOApproval"> <CXOApproval /> </Protected>} />
              <Route path="/scopeapproval" element={<ScopeApprovel />} />
              <Route path="/prscopeview" element={<PrScopeView />} />
              {/* <Route path="/apicatalog" element={<ApiCatalog/>} /> */}
              <Route path="/apicatalog"
                element={
                  <Protected isAllowed="/apicatalog">
                    <ApiCatalog />
                  </Protected>} />
              <Route path='/apimaster' element={
                <Protected isAllowed="/apimaster">
                  < ApiMaster />
                </Protected>} />
              {/* <Route path='/uploadContract' element={<Protected isAllowed="/uploadContract"> <UploadContract /> </Protected>} /> */}
              <Route path='/articleMaster' element={<ArticleMaster />} />

              <Route path='/physicalUpload' element={<PhysicalUpload />} />
              <Route path="/contract-termination" element={<ContractTermination />} />
              <Route path="/pmdefinescope" element={<ContractView />} />
              <Route path="/pmeventlog" element={<PREventLog />} />
              <Route path="/prscope" element={<PrScopeView />} />
              <Route path="/pmrespondorso" element={  <PMResponse PageName={"So Review"}/>} />
              <Route path="/pmrespondorcxo" element={  <PMResponse PageName={"Sign Off"}/>} />
            

              <Route path="/slaseveritymaster" element={<Protected isAllowed="/slaseveritymaster">
                <SeverityMaster />
              </Protected>} />
              <Route path="/deviationtolerancelevelmaster" element={
                <Protected isAllowed="/deviationtolerancelevelmaster">
                  <DeviationToleranceLevelMaster />
                </Protected>
              } />
              <Route path="/thresholdmaster" element={<Protected isAllowed="/thresholdmaster">
                <Threshold />
              </Protected>} />
            </Routes>
          </Box>
          <Box>
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
