import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import articalMasterService from "../services/articalMasterService";

export interface IArticalMasterState {
  articalMasterData: any;
  isLoading: boolean;
  isArticalMasterDataAdded: boolean;
  isArticalMasterDataUpdated: boolean;
  isArticalMasterDataDeleted: boolean;
  ToasterMsg: { Message: any; Status: string };
}

export const initialState: IArticalMasterState = {
  articalMasterData: [],
  isLoading: false,
  isArticalMasterDataAdded: false,
  isArticalMasterDataUpdated: false,
  isArticalMasterDataDeleted: false,
  ToasterMsg: { Message: "", Status: "" },
};

export const ArticalMasterSubmit = createAsyncThunk(
  "ArticalMasterSubmit",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response: any = await articalMasterService.ArticalMasterSubmit(requestBody);
      console.log(response, "responseresponse");
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);

export const ArticalMasterEdit = createAsyncThunk(
  "ArticalMasterEdit",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response: any = await articalMasterService.editArticalMaster(requestBody);
      return response;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);

export const ArticalMasterGetById = createAsyncThunk(
  "ArticalMasterGetById",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response: any = await articalMasterService.getByIdArticalMaster(requestBody);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);

export const ArticalMasterGetAll = createAsyncThunk(
  "ArticalMasterGetAll",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response: any = await articalMasterService.getAllArticalMasters(requestBody);
      return response.articleSections;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);

export const ArticalMasterActiveInactive = createAsyncThunk(
  "ArticalMasterActiveInactive",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response: any = await articalMasterService.activeInactiveArticalMaster(requestBody);
      return response;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);

const ArticleMasterSlice = createSlice({
  name: "articalMaster",
  initialState,
  reducers: {
    articleMasterReset: (state, action: PayloadAction<any>) => {
      state.isArticalMasterDataAdded = false;
      state.isArticalMasterDataUpdated = false;
      state.isArticalMasterDataDeleted = false;
      state.ToasterMsg = { Message: "", Status: "" };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ArticalMasterSubmit.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(ArticalMasterSubmit.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isArticalMasterDataAdded = true;
      state.ToasterMsg.Message = action?.payload?.toaster?.message;
      state.ToasterMsg.Status = action?.payload?.toaster?.status;
    });
    builder.addCase(ArticalMasterSubmit.rejected, (state, action) => {
      state.isLoading = false;
      state.isArticalMasterDataAdded = false;
    });

    builder.addCase(ArticalMasterEdit.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(ArticalMasterEdit.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isArticalMasterDataUpdated = true;
      state.ToasterMsg.Message = action?.payload?.toaster?.message;
      state.ToasterMsg.Status = action?.payload?.toaster?.status;
    });

    builder.addCase(ArticalMasterEdit.rejected, (state, action) => {
      state.isLoading = false;
      state.isArticalMasterDataUpdated = false;
    });

    builder.addCase(ArticalMasterActiveInactive.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(ArticalMasterActiveInactive.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isArticalMasterDataDeleted = true;
      state.ToasterMsg.Message = action?.payload?.toaster?.message;
      state.ToasterMsg.Status = action?.payload?.toaster?.status;
    });

    builder.addCase(ArticalMasterActiveInactive.rejected, (state, action) => {
      state.isLoading = false;
      state.isArticalMasterDataDeleted = false;
    });

    builder.addCase(ArticalMasterGetById.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(ArticalMasterGetById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.articalMasterData = action.payload;
    });

    builder.addCase(ArticalMasterGetById.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(ArticalMasterGetAll.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(ArticalMasterGetAll.fulfilled, (state, action) => {
      state.isLoading = false;
      state.articalMasterData = action.payload;
    });

    builder.addCase(ArticalMasterGetAll.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { articleMasterReset } = ArticleMasterSlice.actions;
export default ArticleMasterSlice.reducer;
