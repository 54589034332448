import { Box, Chip, IconButton, Typography, withStyles } from "@mui/material";
import React, { ReactNode } from "react";
import CustomSvg from "../CustomSvg";
import { useTheme } from "@mui/material/styles";

interface CustomStatusChipProps {
  label: string;
  onClick?: () => void;
  status?: "NS" | "WIP" | "C";
  color?: any;
  icon?: any;
  height?: number;
}

const CustomStatusChip: React.FC<CustomStatusChipProps> = (props) => {
  const theme = useTheme();
  const {
    label,
    onClick,
    status = "NS",
    color = null ,
    icon = null,
    height = 26 ,
  } = props;

  const statusColors: { [key: string]: string } = {
    NS: "#6A6A6A",
    WIP: "#FFC536",
    C: "#0FC400",
    default: "#0FC400",
  };
  return (
    <>
<Box 
  key={`statuschip-${label}`}
  sx={{
    height: `${height}px`,
    display: "flex",
    justifyContent:'center',
    alignItems: "center",
    border: `1px solid ${color || statusColors[status] || statusColors.default}`,
    borderRadius: "12px", 
    padding: "5px 10px",
    gap: "5px",
  }}
>
<Box sx={{display :'flex' ,position :'relative' , top :'3px'}}>
<CustomSvg
    color={ 
      status === "NS" ? "#6A6A6A" : 
      status === "WIP" ? "#FFC536" : 
      "#0FC400"  }
    size="18px"
    name={ 
      status === "NS" ? "error_cross" : 
      status === "WIP" ? "error_cross" : 
      "sucess_tick" 
      }
  />
</Box>

  <Typography 
    variant="caption" 
    gutterBottom 
    sx={{ position :'relative' , top :'3px', fontSize: '14px', color: color || statusColors[status] || statusColors.default }}
  >
    {label}
  </Typography>
  

</Box>
      
    </>
  );
};

export default CustomStatusChip;
