import axios from 'axios';
import {version} from './constant';
const {REACT_APP_API_PM_URL}= process.env;

export const axiosInstance = axios.create({
    baseURL:`${REACT_APP_API_PM_URL}${version}`
});

axiosInstance.interceptors.request.use(
    (config : any)=>{    
    let token = sessionStorage.getItem("Token");
    //config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json';   
    // config.headers['UserId'] = sessionStorage.getItem("UserId");
    // if(token){
    //     config.headers['Authorization'] = `Bearer ${token}`
    // }   
    return config;
    },
    (error : any) => {
        console.log(error)
        Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    async (response : any) => {
        if(response.status === 401){
            // need to check wat needs to be done if authentication fails
            console.log("Authenicate fails", response)
        }else{
            
        }
        return response
    }
)
 