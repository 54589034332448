import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
import { CircularProgress, IconButton, InputAdornment, Toolbar } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store/store";
import { updateVendorRecords } from "../../store/slices/vendorData";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import { ILogginForm } from "../../models/loggin.type";
import { getLogginUser, getMenuByRolesId, updateAuthorize, updateLogin, getOTPMail, updateLoginMessage } from "../../store/slices/authorization";
import { useSelector } from "react-redux";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
// import ForgotPassword from "../forgotPassword/ForgotPassword";
import loginstyle from "../../components/login/login.module.scss"
import headerlogo from "../../assets/headerlogo.png";
import Loginbg from "../../assets/Loginbg.png";
import { STATUS_CODE_HEADER, STATUS_CODE_TEXT } from "../../components/login/login.constants"
import { getDepartmentList } from "../../store/slices/departmentData";
import { bizAdminDepartmentName, bizAdminDepartmentId } from "../../utils/constants";
import { getFavouriteNavMenu } from "../../store/slices/FavouriteNavData";
import { hideMenu } from "../../store/slices/MenuShow";
import { AnyARecord } from "dns";
import ForgotPassword from "../../components/forgotPassword/ForgotPassword";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import { Padding } from "@mui/icons-material";
import CustomOTPInput from "../../common/dynamicInputs/CustomOTPinput";
import OtpTime from "./OtpTime";
import {
  getSecurityParameters,
  getSecurityParameterHistory,
  updateSecurityParameter,
  deleteSecurityParameter,
  updateAPAddandEdit,
} from "../../store/slices/securityParameters";
import { SecurityParameterName } from "../../config/config";
import { getLogginVendor } from "../../store/slices/authorization";
export default function VendorLoginpage() {
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [showPop, setShowPop] = useState(false);
  const [forgotPasswordPopUp, setForGotPasswordPopUp] = useState(false);
  const [otpBtn, setotpBtn] = useState(false)
  const [resendBtn, setResendBtn] = useState(false)
  const [showNoMenuInfo, setShowNoMeniNfo] = useState(false);
  const [userDetailsInfo, setUserDetailsInfo] = useState(false);
  const [showAccountInfo, toggleAccountInfo] = useState<boolean>(false);
  const { isAuthenticated, userLoginResponse, isTosterFailed , menuData,ToasterMsg,IsSuccess , isLoginFailed } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { dataTable } = useSelector((state: RootState) => state.departmentData);
  const [getDepartmentDetails, setDepartmentDetails] = useState<any>();
  const [otp, setOtp] = useState<any>();
  const [isBizAdmin, setIsBizAdmin] = useState<boolean>(false);
  //const token = localStorage.getItem("AUTH_TOKEN_ZN") || "";
  const [previousURL, setPreviousURL] = useState<any>(null);
  const [otpEnd, setOtpEnd] = useState<boolean>(true);
  const [otpTime, setotpTime] = useState<any>(10);
  const [expiredOtp, setExpiredOtp] = useState<boolean>(false);
  const [otpBtnDisplay, setOtpBtnDisplay] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { vendor_persional_Id, Project_Details_Id } = useParams();
 
  const { SecurityParameterData, isAPAdded, isAPDeleted, isAPUpdated, versionHistory, apToasterMsg } = useSelector(
    (state: RootState) => state.securityParameters
  );
  const [serviceCatalog, setserviceCatalog] = useState<any[]>([]);

  let logginForm = {
    email: "",
    // password: "",
  };

  const {
    handleSubmit: handleSubmitloginform,
    reset,
    formState: { errors: errorsloginform },
    control,

    getValues,
  } = useForm<ILogginForm>({
    defaultValues: logginForm,
    mode: "onChange",
  });
  useEffect(() => {
    dispatch(getSecurityParameters({
      FormID: 20,
    }));
  }, [])

  useEffect(()=>{
    
    if(ToasterMsg ){ 
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg,
          isTosterFailed: isTosterFailed,
        }))
    }
  },[ToasterMsg])

  useEffect(()=>{
    dispatch(updateLoginMessage(false))
    if(IsSuccess && !isLoginFailed){ 
      setOtpBtnDisplay(true)
      setotpBtn(true)
    }
  
  },[IsSuccess , isLoginFailed])
  const handleTimeout = () => {
      setOtpBtnDisplay(true)
      setotpBtn(false)
    setExpiredOtp(true)
    setResendBtn(true)
    setOtpEnd(true)

  };
  useEffect(() => {
    setserviceCatalog(SecurityParameterData);
  }, [SecurityParameterData]);
  const getOtpMail=()=>{
    let formValues = getValues();
    let request: any = {
      EmailID: formValues.email,
      toasterMsg: ToasterMsg ,
      isToaster: true,
    };
 
      setExpiredOtp(false)
      dispatch(getOTPMail(request))

  }
  const handleLogin = () => {
  
    let formValues = getValues();
    console.log(formValues, "formsvalue")
    setExpiredOtp(false)
    
    let OtpTime;
    const OtpTimeGroupName = SecurityParameterName?.VendorPortalLoginOtpTimeGroupName
    const OtpTimeParameterName = SecurityParameterName?.VendorPortalLoginOtpTimeParameterName
    console.log(OtpTimeGroupName, OtpTimeParameterName)
    serviceCatalog.map((item: any) => {
      if (item.SecurityParameterName == OtpTimeParameterName && item.SecurityParameterGroup == OtpTimeGroupName) {
        OtpTime = parseInt(item.Value)
      }
    })
    setotpTime(OtpTime)
    console.log(OtpTime, "filter")
    let request: any = {
      EmailID: formValues.email,
      toasterMsg: ToasterMsg ,
      isToaster: true,
    };
    if (!otpBtnDisplay) {
      dispatch(getOTPMail(request))
    } else {

    }
    let request2: any = {
      OTP: otp,
    };
    if (otpBtnDisplay) {
      const fetchVendorData = async () => {
        if (vendor_persional_Id) {
          try {
            console.log(vendor_persional_Id,otp,"otp")
            const data = await dispatch(
              getLogginVendor({ Vendor_Id: vendor_persional_Id, ...request2 })
            )

          } catch (error) {
            console.error("Error fetching vendor data:", error);
          }
        }
      };

      fetchVendorData();
    }
  }






  return (
    <Grid container component="main" sx={{ height: "100vh", width: "100%" }}>
      <CssBaseline />
      <Grid
        item
        //   xs={false}
        //   sm={4}
        //   md={7}
        sx={{
          backgroundImage: `url(${Loginbg})`,
          backgroundRepeat: "no-repeat",
          // backgroundColor: (t) =>
          //   t.palette.mode === "light" ? "black" : "#F5F5F5",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          width: "78%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className={loginstyle.sliding_border}
      >
        <Grid
          sx={{
            my: 8,
            position: "absolute",
            right: "-25vw",
            top: "10vh",
            zIndex: 1,
            mx: {
              xs: 2,
              sm: 4,
              md: 4,
              lg: 8,
              xl: 12,
            },
          }}
          item
          xs={12}
          sm={8}
          md={5}
          lg={4}
          xl={4}
        // component={Paper}
        // elevation={6}
        // square
        >
          <Box
            sx={{
              py: 8,
              px: {
                xs: 2,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
              },

              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              justifyContent: "left",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left", width: "100%" }}>
              <Typography textAlign="left" component="h1" variant="h4">
                Sign in
              </Typography>
            </Box>

            <Box component="form" noValidate sx={{ mt: 1 }}>
              <Controller
                name="email"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Email is Required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    // sx={{ boxShadow: "inset 0 0 50px rgba(0, 0, 0, 0.2)", }}
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    // label="Email"
                    label=""
                    placeholder="Email"
                    // InputProps={{
                    //   style: { border: "none" },
                    // }}
                    // InputLabelProps={{
                    //   style: { color: "#fff" },
                    // }
                    name="email"
                    size="small"
                    //autoComplete="email"
                    //autoFocus
                    tabIndex={1}
                    error={Boolean(errorsloginform.email)}
                    helperText={errorsloginform.email && errorsloginform.email.message?.toString()}
                    InputProps={{
                      sx: {
                        // width: "270px",
                        boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset",
                        "& input::placeholder": {
                          fontWeight: "bold",
                        },
                        "& input:-ms-input-placeholder": {
                          fontWeight: "bold",
                        },
                        "& input::-ms-input-placeholder": {
                          fontWeight: "bold",
                        },
                      },
                    }}
                  />
                )}
              />
              {otpBtnDisplay &&
                <CustomOTPInput
                  label="OTP"
                  value={otp}
                  onChange={(e) => setOtp(e)}
                />
              }
              {otpBtn &&
                <>
                  <Typography textAlign="left" sx={{ mt: 1 }}>
                    OTP sent to your Registered email id.
                  </Typography>
                  <OtpTime duration={otpTime} onTimeout={handleTimeout} />
                </>


              }
              {expiredOtp &&
                <>
                  <Typography textAlign="left" sx={{ mt: 1 }}>
                    Your OTP has expired. Please request another OTP.
                  </Typography>
                </>

              }
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  //type="submit"
                  size="small"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
                  onClick={handleSubmitloginform(handleLogin)}
                //onClick={handleSubmitloginform(handleSubmit)}
                >
                  {otpBtnDisplay ? "LOGIN" : "GET OTP"}
                </Button>
                {resendBtn &&
                  <Button
                    // type="submit"
                    size="small"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading}
                    onClick={getOtpMail}
                  //onClick={handleSubmitloginform(handleSubmit)}
                  >
                    Resend OTP
                  </Button>
                }
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
