import react, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../common/dynamicButton/CustomButton";
import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import {
  ACTIVE,
  INACTIVE,
  TOASTER_ADD_SUCCESS_MSG,
  TOASTER_ADD_UPDATING_MSG,
  TOASTER_EDIT_SUCCESS_MSG,
  TOASTER_EDIT_UPDATING_MSG,
  textFieldWidth,
} from "../../utils/constants";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import { addToaster, updateToaster } from "../../store/slices/ToasterData";
import { getAllFormsConfiguration } from "../../store/slices/workFlowSlice";
import { option_ToasterActivityConfig } from "../../config/config";


export interface toastProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  isEdited?: any;
  isAdded?: any;
}

interface FormData {
  toasterId?: number;
  ModuleName: string;
  Message: string;
  IsActive?: number;
  CreatedBy?: string;
  UpdateBy?: string;
  Activity?: string;
}

export const AddToaster: React.FC<toastProps> = ({ view, isEditData, edit, isEdited, isAdded }) => {
  const dispatch = useAppDispatchThunk();

  let formData = {
    ToasterId: isEditData ? isEditData?.ToasterId : 0,
    ModuleName: isEditData ? isEditData?.ModuleName : "",
    Message: isEditData ? isEditData?.Message : "",
    IsActive: isEditData && isEditData?.IsActive ? (isEditData.IsActive === "Active" ? 1 : 0) : 1,
    CreatedBy: isEditData ? isEditData?.CreatedBy : 0,
    Activity: isEditData ? isEditData?.Activity : "",
  };
  const {
    handleSubmit: handleToasterForm,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const { dataTable, isToasterAdded, isToasterUpdated } = useSelector((state: RootState) => state.toasterData);
  const [message, setMessage] = useState<boolean>(false);
  const [option_module, setOption_module] = useState<any[]>([]);
  const {  formConfiguration } = useSelector((state: RootState) => state.workFlow);
  const { allMenuData } = useSelector((state: RootState) => state.emailTemplateData);

  const { user } = useSelector((state: RootState) => state.loggerData);

  const { toaster } = dataTable;

  console.log(toaster, "dataTabledataTable");


  useEffect(() => {
    if (isToasterAdded) {
      reset(formData);
      if (isAdded) {
        setMessage(false);
        isAdded(false);
      }
    } else if (isToasterUpdated) {
      if (edit) {
        console.log("edit Toaster");
        setMessage(false);
        isEdited(false);
      }
    }
  }, [isToasterAdded, isToasterUpdated]);


  const findpages = (menus: any[]) => {
    let findedValues: any[] = [];
   
    const findLastSubMenus = (menus: any[], accumulator: any[]) => {
      for (let menu of menus) {
        if (!menu.SubMenus || menu.SubMenus.length === 0) {
          accumulator.push({ key: menu.MenuName, value: menu.MenuName });
        } else {
          findLastSubMenus(menu.SubMenus, accumulator);
        }
      }
    };

    findLastSubMenus(menus, findedValues);
    return findedValues;
  };
  useEffect(() => {
    dispatch(getAllFormsConfiguration());
 

    const option_modules = findpages(allMenuData);

    console.log(option_modules, "findpages");
    setOption_module(option_modules);
  }, []);

  useEffect(() => {

    if(formConfiguration){
      if (formConfiguration) {
        console.log(formConfiguration, 'formConfiguration');
    
        let newModules = formConfiguration.map((item) => ({
          ...item,
          value: item?.FormName,
        }));
        const option_modules = findpages(allMenuData);
        setOption_module((prevModules: any) => [
          ...option_modules ,
          ...newModules,
        ]);
      }
      
      
     
    }

  },[formConfiguration])

  const option_activity = option_ToasterActivityConfig;

 
  const handleSubmit = (data: any) => {
    setMessage(true);
    if (edit) {
      let request: any = { ...getValues() };
      console.log("updateToaster", getValues());
      // request["UpdateBy"] =user?.UserName;
      request["CreatedBy"] = user?.UserName;
      dispatch(updateToaster(request));
    } else {
      let request = { ...getValues() };
      delete request.toasterId;
      request["CreatedBy"] = user?.UserName;
      dispatch(addToaster(request));
      console.log("submitted successFully ", getValues());
    }
  };

  return (
    <>
      <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={textFieldWidth.xs}
            // sm={textFieldWidth.sm}
            // md={textFieldWidth.md}
            // lg={textFieldWidth.lg}
            // xl={textFieldWidth.xl}
            md={6}
          >
            <Controller
              name="ModuleName"
              control={control}
              rules={{
                required: "ModuleName is Required",
              }}
              render={({ field }) => (
                <CustomSelect
                  name="select"
                  valueKey={"value"}
                  optionLabelKey="value"
                  options={option_module}
                  required={true}
                  label={"Module Name"}
                  readOnly={view}
                  value={field.value}
                  onChange={field.onChange}
                  error={errorsloginform.ModuleName && errorsloginform.ModuleName.message?.toString()}
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={textFieldWidth.xs}
            // sm={textFieldWidth.sm}
            // md={textFieldWidth.md}
            // lg={textFieldWidth.lg}
            // xl={textFieldWidth.xl}
            md={6}
          >
            <Controller
              name="Message"
              control={control}
              defaultValue={""}
              rules={{
                required: "Message is Required",
                validate: (data) => {
                  return data.length > 5 || "Minimal 5 characters required";
                },
              }}
              render={({ field }) => (
                <CustomTextField
                  name={"message"}
                  required={true}
                  label={"Message"}
                  value={field.value}
                  readOnly={view}
                  onChange={field.onChange}
                  error={Boolean(errorsloginform.Message)}
                  helperText={errorsloginform.Message && errorsloginform.Message.message?.toString()}
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={textFieldWidth.xs}
            // sm={textFieldWidth.sm}
            // md={textFieldWidth.md}
            // lg={textFieldWidth.lg}
            // xl={textFieldWidth.xl}
            md={6}
          >
            <Controller
              name="Activity"
              control={control}
              rules={{
                required: "Activity is Required",
              }}
              render={({ field }) => (
                <CustomSelect
                  name="select"
                  valueKey={"id"}
                  optionLabelKey="lable"
                  options={option_activity}
                  required={true}
                  label={"Activity"}
                  readOnly={view}
                  value={field.value}
                  onChange={field.onChange}
                  error={errorsloginform.Activity && errorsloginform.Activity.message?.toString()}
                />
              )}
            />
          </Grid>

          {false && <Grid
            item
            xs={textFieldWidth.xs}
            // sm={textFieldWidth.sm}
            // md={textFieldWidth.md}
            // lg={textFieldWidth.lg}
            // xl={textFieldWidth.xl}
            md={6}
          >
            <Controller
              name="IsActive"
              control={control}
              render={({ field }) => (
                <Box sx={{ mt: "8px" }}>
                  <CustomToogleSwitch
                    label={"Status"}
                    dynamicLabel={field.value ? ACTIVE : INACTIVE}
                    value={field.value}
                    readOnly={view}
                    onChange={(data) => {
                      // console.log(field.value)
                      const datas = data == true ? 1 : 0;
                      setValue("IsActive", datas);
                    }}
                  />
                </Box>
              )}
            />
          </Grid>}
          {message ? (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "Center" }}>
              <Typography variant="h6">{edit ? TOASTER_EDIT_UPDATING_MSG : TOASTER_ADD_UPDATING_MSG}</Typography>
            </Box>
          ) : null}
          <Grid item sm={12}>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "right",
              }}
            >
              {!view ? (
                !edit ? (
                  <>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        reset(formData);
                        if (isAdded) {
                          isAdded(false);
                        }
                      }}
                    />
                    <CustomButton type="reset" name={"Submit"} onClick={handleToasterForm(handleSubmit)} />
                  </>
                ) : (
                  <>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        console.log(isEdited);
                        if (isEdited) {
                          isEdited(false);
                        }
                      }}
                    />

                    <CustomButton variant="contained" name={"Update"} onClick={handleToasterForm(handleSubmit)} />
                  </>
                )
              ) : (
                <CustomButton
                  type="reset"
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    console.log(isEdited);
                    if (isEdited) {
                      isEdited(false);
                    }
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>

        {/* {message ? (
          <Box sx={{ display: "flex", justifyContent: "Center" }}>
            <Typography variant="h6"> {edit ? TOASTER_EDIT_SUCCESS_MSG : TOASTER_ADD_SUCCESS_MSG}</Typography>
          </Box>
        ) : null} */}
      </Box>
    </>
  );
};
