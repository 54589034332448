import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomDatePicker from "../../common/dynamicInputs/CustomDatePicker";
import CustomButton from "../../common/dynamicButton/CustomButton";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatch } from "../../store/store";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { Addparameter } from "./AddParameter";
import { IMenu } from "../../models/loggin.type";
import {
  getApplicationParameterList,
  getApplicationParameterHistory,
  updateAPAddandEdit,
  deleteApplicationParameter,
} from "../../store/slices/applicationParameterData";

import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import masterEditEnableService from "../../store/services/masterEditEnableService";
import { Edit_Enable_tosterMSG, FormIdConfig } from "../../config/config";
import { getNotifications } from "../../store/slices/notification";
import PageMainHeading from "../../common/CommonHeadings/PageMainHeading";
import { HandleRecordLockCheck, RecordLockUpdate } from "../recordLock/RecordLock";
import { RecordLockRelease } from "../recordLock/CommonRecordLock";


export interface ApplicationParrametersProps {
  handlePrevious?: () => void;
  handleNext?: () => void;
  isWorkFlow?: boolean;
}

export const ApplicationParrameters: React.FC<ApplicationParrametersProps> = ({
  handlePrevious,
  handleNext,
  isWorkFlow,
}) => {
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [permissionPage, setPermission] = useState<IMenu | null>(null);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [formDisplay, setformDisplay] = useState(false);
  const [filterTableData, setFilterTableData] = useState<any[]>([]);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const [versionHistoryData, getVersionHistoryData] = useState<any>();
  const [tableData, setTableData] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const { applicationParameterData, isAPAdded, isAPDeleted, isAPUpdated, versionHistory, apToasterMsg } = useSelector(
    (state: RootState) => state.applicationParameter
  );
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { handleRecordLockUpdate } = RecordLockRelease();

  const { currentpageTitleData } = useSelector(
    (state: RootState) => state.FormTitleMasterData
  );
  console.log(versionHistoryData, versionHistory, "versionHistoryData");

  const columns_version: any = {
    table_name: "Application Parameter History Records",
    isAction: false,
    columns: [],
  };
  let Table_Data = [
    {
      id: "1",
      ParameterGroup: "Reginonal Settings",
      ParameterName: "Date Format",
      Description: "Date format for display",
      Value: "dd/mm/yyyy",
      Options: "hh:mm:ss",
      Status: "Active",
    },
    {
      id: "2",
      ParameterGroup: "Reginonal Settings",
      ParameterName: "Date Format",
      Description: "Date format for display",
      Value: "dd/mm/yyyy",
      Options: "hh:mm:ss",
      Status: "Active",
    },
    {
      id: "3",
      ParameterGroup: "Reginonal Settings",
      ParameterName: "Date Format",
      Description: "Date format for display",
      Value: "dd/mm/yyyy",
      Options: "hh:mm:ss",
      Status: "Active",
    },
  ];

  const columns: any = {
    table_name: "Application Parameters",
    isAction: true,
    columns: [
      {
        Field: "Parameter_Group",
        DisplayName: "Parameter Group",
        FieldType: "string",
      },
      {
        Field: "Parameter_Name",
        DisplayName: "Parameter Name",
        FieldType: "string",
      },

      {
        Field: "Parameter_Description",
        DisplayName: "Description",
        FieldType: "string",
      },
      {
        Field: "Parameter_Value",
        DisplayName: "Value",
        FieldType: "string",
      },
      {
        Field: "Parameter_Options",
        DisplayName: "Options",
        FieldType: "string",
      },
      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",
      },
      {
        Field: "Updated_By",
        FieldType: "string",
        DisplayName: "Modified By",
      },
    ],
  };

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermission(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    dispatch(
      getApplicationParameterList({
        FormID: 2,
      })
    );
  }, []);
  const deleteDepartmentList = () => {
    console.log(isDeleteData);
    let JsonDataForDisplay: any = {
      "Parameter Group": isDeleteData?.Parameter_Group || "",
      "Parameter Name": isDeleteData?.Parameter_Name || "",
      "Parameter Description": isDeleteData?.Parameter_Description || "",
      "Parameter Value": isDeleteData?.Parameter_Value || "",
      "Parameter Options": isDeleteData?.Parameter_Options || "",
      Status: isDeleteData.IsActive === "Active" ? "Inactive" : "Active",
      "Modified By": user?.UserName,
    };

    let formData = {
      UserId: userLoginResponse?.UserId,
      Parameter_Id: isDeleteData.Parameter_Id,
      Parameter_Name: isDeleteData.Parameter_Name,
      MenuId: permissionPage?.MenuId,
      Form_Id: 18,
      ModuleName: "Application Parameter",
      CreatedBy: userLoginResponse?.UserName,
      IsActive: isDeleteData.IsActive === "Active" ? false : true,
      Activity: isDeleteData.IsActive === "Active" ? "inactive" : "active",
      DataForDisplay: JSON.stringify(JsonDataForDisplay),
    };
    // setLoader(true);
    dispatch(deleteApplicationParameter(formData));
  };

  useEffect(() => {
    if (isAPAdded) {
      dispatch(updateAPAddandEdit(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: apToasterMsg.Message,
          isTosterFailed: apToasterMsg.Status === "false" ? true : false,
        })
      );

      dispatch(
        getApplicationParameterList({
          FormID: 2,
        })
      );
      if (userLoginResponse) {
        dispatch(getNotifications(userLoginResponse?.UserId));
      }
    } else if (isAPUpdated) {
      dispatch(updateAPAddandEdit(false));
      handleRecordLockUpdate();
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: apToasterMsg.Message,
          isTosterFailed: apToasterMsg.Status === "false" ? true : false,
        })
      );

      dispatch(
        getApplicationParameterList({
          FormID: 2,
        })
      );
    } else if (isAPDeleted) {
      setIsDeleteData(null);
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: apToasterMsg.Message,
          isTosterFailed: apToasterMsg.Status === "false" ? true : false,
        })
      );

      setTimeout(() => {
        dispatch(updateAPAddandEdit(false));
      }, 1000);
      dispatch(
        getApplicationParameterList({
          FormID: 2,
        })
      );
    }
  }, [isAPAdded, isAPDeleted, isAPUpdated]);
  useEffect(() => {
    setTableData(applicationParameterData);
  }, [applicationParameterData]);

  const handleOpen = async (data: any, Option: string) => {
  
    if (Option === "edit") {
      setIsEdit(true);
       setOption(Option);
      setIsEditData(data);
    } else if (Option === "view") {
      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
    } else if (Option === "history") {
      setOption(Option);
      setIsVersionHistory(true);
      dispatch(
        getApplicationParameterHistory({
          Parameter_Id: data.Parameter_Id,
          FormId:FormIdConfig?.ParameterFormId ,
        })
      );
    }
    if (Option === "delete") {
      setIsDeleteData(data);
}
  };

  const previous = () => {
    handlePrevious && handlePrevious();
  };

  const next = () => {
    handleNext && handleNext();
  };
  useEffect(() => {
    if (versionHistory.departmentVHeader.length > 0 && versionHistory.departmentVHeader.length > 0) {
      if (versionHistory.departmentVHeader && versionHistory.departmentVHeader.length > 0) {
        // setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory.departmentVHeader,
        };
        getVersionHistoryData(re_columns);
      }
    }
  }, [versionHistory]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          // backgroundColor: "#ffff",
          // backgroundColor: "background.default",
          // px: 3,
          // py: 4,
          // borderRadius: "10px",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* <Box sx={{ display: "flex", width: "25%", }} >
                            <CustomSelect
                                name={"listName"}
                                valueKey={"id"}
                                optionLabelKey="lable"
                                options={location_Data}
                                value={formData.listName}
                                onChange={handleChange}
                                required={true}
                                label={"Select List Name"}
                            />
                        </Box> */}

<Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                            {/* Holiday Master */}
                        </Typography>
            {currentPagePermissionData?.MenuName === "Customer Onboarding" ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "center",
                  }}
                >
                  <CustomButton variant="outlined" name={"Previous Master"} onClick={() => previous()} size="small" />
                  <CustomButton
                    variant="contained"
                    name={"Proceed To Next Master"}
                    onClick={() => next()}
                    size="small"
                  />
                </Grid>
              </Box>
            ) : (
              <></>
            )}
          </Box>

          {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Grid
                            sx={{
                                display: "flex",
                                gap: "20px",
                                justifyContent: "center",
                                mt: 2
                            }}
                        >
                            
                            <CustomButton variant="contained" name={"Add Parameter"} onClick={() => setAddForm(true)} size="small" />
                        </Grid>
                    </Box> */}

          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={tableData}
                handleAction={handleOpen}
                enableZoomIcon={true}
                // showDelete={permissionPage?.IsDelete === 1 ? true : false}
                handleAdd={() => setAddForm(true)}
                showAdd={true}
                showhistory={true}
                showDelete={permissionPage?.IsDelete === 1}
                showView={permissionPage?.IsView === 1}
                showEdit={permissionPage?.IsEdit === 1}
                showExports={permissionPage?.IsExport === 1}
                pageName="Application Parameters"
                  isEnableRecordLock = {{ IdField : "Parameter_Id" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                                  FormID : FormIdConfig?.ParameterFormId,
                                                
                                                  MenuID : currentPagePermissionData?.MenuId,
                                                  IsCheckPending : true  }}}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            </Box>
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"md"}
        minHeight={"50vh"}
        header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Application Parameters"}`}
        contentNode={
          // <></>
          <Addparameter
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
            ModuleId={permissionPage?.MenuId}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          handleRecordLockUpdate();
          setIsEdit(false);
        }}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={Option === "view" ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Application Parameters"}` : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Application Parameters"}`}
        contentNode={
          <>
            {Option === "edit" && (
              <Addparameter
                view={false}
                closePopUp={() => {
                  handleRecordLockUpdate();
                  setIsEdit(false);
                }}
                edit={isEdit}
                isEditData={IsEditData}
                ModuleId={permissionPage?.MenuId}
              />
              // <></>
            )}

            {Option === "view" && (
              <Addparameter
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                isEditData={IsEditData}
                ModuleId={permissionPage?.MenuId}
              />
              // <></>
            )}
          </>
        }
      />

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
        }}
        maxWidth={"lg"}
        header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Application Parameters"} Version History`}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistory.DocumentMaster}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showDelete={false}
              showhistory={false}
              showView={false}
              showEdit={false}
              showExports={true}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Application Parameter Version History"
            />
          </>
        }
      />
      <ModalPopUp
        onDeleteMethod={() => deleteDepartmentList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${
          isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
        } the selected Parameter`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />
    </>
  );
};
