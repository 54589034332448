import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { textFieldWidth } from "../../../utils/constants";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import { CreateSeverityMaster, updateSeverityMaster } from "../../../store/performanceManagement/slices/severityMaster";
import { deleteSeverityMaster, getAllSeverityMaster, SeverityMasterAddandSave } from "../../../store/performanceManagement/slices/severityMaster";
import SeverityMasterDataSlice from "../../../store/performanceManagement/slices/severityMaster";
import SeverityMasterService from "../../../store/performanceManagement/services/severityMaster";
import { getNotifications } from "../../../store/slices/notification";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
interface FormData {
    Id?: any;
    severity?: any;
    score?: string;
    isActive: any;
}
export interface SeverityMasterProps {
    view?: boolean;
    closePopUp?: () => void;
    edit?: boolean;
    isEditData?: any;
    ModuleId?: any;
}
export const AddSeverityMaster: React.FC<SeverityMasterProps> = ({ view, closePopUp, edit, isEditData, ModuleId }) => {
    const dispatch = useAppDispatchThunk();
    const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
    console.log(isEditData, "isEditDat3a");
    const performanceManagementReducer: any = useSelector((state: RootState) => state?.performanceManagementReducer);
    const { isLoading, ToasterMsg, SeverityMasterTable, isSeverityMasterRemoved, isSeverityMasterAdded, isSeverityMasterUpdated }: any = performanceManagementReducer.SeverityMasterDataSlice;
    let formData: any = {
        // Id:isEditData?isEditData.Id:0,
        severity: isEditData ? isEditData.severity : "",
        score: isEditData ? isEditData?.score : "",
        isActive: isEditData ? (isEditData?.isActive === "Active" ? true : false) : true,
        severityId: isEditData ? isEditData?.severityId : "",
    };
    let ModifiedBy = isEditData ? isEditData?.UpdatedBy : "";
    let CreatedBy = isEditData ? isEditData?.CreatedBy : "";
    const {
        handleSubmit: handleSeveritySettings,
        reset,
        setValue,
        formState: { errors: errorsloginform, isDirty },
        control,
        watch,
        getValues,
    } = useForm<FormData>({
        defaultValues: formData,
        mode: "onChange",
    });

    const isActiveDisabled = watch("isActive");



    const handleSubmit = (data: any) => {

        console.log(isEditData, "isEditData");

        // dispatch(updateLoader(true));
        let formData = data;
        // setLoader(true);
        formData.isActive = formData.isActive ? true : false;
        formData.ModuleName = "SLA Severity Master";
        formData.MenuId = currentPagePermissionData?.MenuId;;
        formData.Form_Id = 18;
        const currentDate = new Date(); // Get the current date and time
        formData.createdOn = currentDate.toISOString()
        formData.updatedOn = currentDate.toISOString()
        formData.UserId = userLoginResponse?.UserId;
        if (edit) {

            formData.Remarks = "";
            formData.Activity = "Edit";
            formData.userId = userLoginResponse?.UserId;
            formData.updatedBy = user?.UserName;
            dispatch(updateSeverityMaster(formData))
        } else {
            formData.updatedBy = user?.UserName;
            formData.createdBy = user?.UserName;
            formData.isActive = true;
            formData.Activity = "Create";
            formData.userId = userLoginResponse?.UserId;
            dispatch(CreateSeverityMaster(formData))
            console.log(formData, 'formData')
        }
        console.log(formData, 'formData')
    };
    console.log(isSeverityMasterAdded,isSeverityMasterUpdated,"isSeverityMasterAdded1");
    useEffect(() => {
        console.log(isSeverityMasterAdded,isSeverityMasterUpdated,"isSeverityMasterAdded");
        
        if (isSeverityMasterAdded) {
            dispatch(getAllSeverityMaster({
                FormID: 2
            }))
            if (closePopUp) { closePopUp(); }
        };
        if (isSeverityMasterUpdated) {
            dispatch(getAllSeverityMaster({
                FormID: 2
            }))
            if (closePopUp) { closePopUp(); }
        };
        dispatch(SeverityMasterAddandSave(false))
        reset(formData);

        dispatch(updateLoader(false));


    }, [isSeverityMasterAdded, isSeverityMasterUpdated]);
    return (
        <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
            <Grid
                // item
                // item xs={12} sm={12} md={6} lg={6} xl={6}
                sx={{
                    backgroundImage: `url()`,
                    backgroundRepeat: "no-repeat",
                    // backgroundColor: (t) =>
                    //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
                    // backgroundSize: "cover",
                    backgroundPosition: "center",
                    // height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    margin: "0",
                }}
            >
                {false && (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            margin: "0",
                        }}
                    >
                        {!view ? (
                            <>
                                {!edit ? (
                                    <Typography variant="h6" sx={{ m: 1 }}>
                                        Add Severity Master
                                    </Typography>
                                ) : (
                                    <Typography variant="h6" sx={{ m: 1 }}>
                                        Edit Severity Master
                                    </Typography>
                                )}
                            </>
                        ) : (
                            <Typography variant="h6" sx={{ m: 1 }}>
                                View Severity Master
                            </Typography>
                        )}
                    </Box>
                )}
                <Grid
                    sx={{
                        height: "auto",
                        width: "100%",
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            px: {
                                xs: 2,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            },

                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
                        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                // sm={textFieldWidth.sm}
                                // md={textFieldWidth.md}
                                // lg={textFieldWidth.lg}
                                // xl={textFieldWidth.xl}
                                >
                                    <Controller
                                        name="severity"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            required: "Severity is Required",
                                            validate: {
                                                maxLength: (value) => value.length <= 20 || "Maximum 20 characters allowed",
                                            },
                                        }}
                                        render={({ field }) => (
                                            <CustomTextField
                                                name={"severity"}
                                                required={true}
                                                readOnly={view || !isActiveDisabled}
                                                label={"Severity"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.severity)}
                                                helperText={errorsloginform.severity && errorsloginform.severity.message?.toString()}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                // sm={textFieldWidth.sm}
                                // md={textFieldWidth.md}
                                // lg={textFieldWidth.lg}
                                // xl={textFieldWidth.xl}
                                >
                                    <Controller
                                        name="score"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            required: "Score is Required",

                                            validate: (data) => {
                                                if (data != undefined) {

                                                    const number = parseInt(data, 10);
                                                    if (isNaN(number) || number < 1 || number>=10) {
                                                        return "Score value must be 1 to 9";
                                                    }
                                                    return true;
                                                }
                                            }
                                        }}
                                        render={({ field }) => (
                                            <CustomNumberField
                                                name={"score"}
                                                required={true}
                                                readOnly={view || !isActiveDisabled}
                                                label={"Score"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.score)}
                                                helperText={errorsloginform.score && errorsloginform.score.message?.toString()}
                                            />
                                        )}
                                    />
                                </Grid>


                            </Grid>

                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "20px",
                                    justifyContent: "end",
                                    mt: 2,
                                }}
                            >
                                {view ? (
                                    <CustomButton
                                        type="reset"
                                        name={"Cancel"}
                                        onClick={() => {
                                            closePopUp && closePopUp();
                                        }}
                                    />
                                ) : edit ? (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton
                                            type="reset"
                                            disabled={!isDirty}
                                            name={"Update"}
                                            onClick={handleSeveritySettings(handleSubmit)}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton
                                            type="reset"
                                            disabled={!isDirty}
                                            name={!edit ? "Submit" : "Update"}
                                            onClick={handleSeveritySettings(handleSubmit)}
                                        />
                                    </React.Fragment>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};
