import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { textFieldWidth } from "../../../utils/constants";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import { CreateRandRReview, PmRandRAddandSave } from "../../../store/performanceManagement/slices/pmRandRReview";
import dayjs from "dayjs";
import { PREventLogTable } from "./PREventReviewTable";
import { InitiateReview } from "../InitiateReview/InitiateReview";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import { getAllPMEventLog } from "../../../store/performanceManagement/slices/pmEventLog";
import RandRReview from "../PMRolesandResponsibility/RAndRReview";
import { LovConfig, PR_Event_Status } from "../../../config/config";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import { truncate } from "node:fs";
import CustomStatusChip from "../../../common/dynamicChip/CustomStatusChip";
import { getNotifications } from "../../../store/slices/notification";

interface PREventLogprops {
    view?: boolean;
    closePopUp?: any;
    edit?: boolean;
    isEditData?: any;
    add?: boolean;
  }
interface FormDataprops {

    prStatus : any ;
    date_type : any ;
    period : any ;
    start_date : any ;
    end_date : any ;
}
  
export const PREventLog: React.FC<PREventLogprops> = ({ view, closePopUp, edit, isEditData }) => {
   const { PMEventListTable } = useSelector(
        (state: any) => state.performanceManagementReducer?.PMEventLogDataSlice 
    );
      const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
            (state: RootState) => state.loggerData
        );

    
       const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
    
      const dispatch = useAppDispatch();
     const [PRStatusList , setPRStatusList] = useState<any[]>([])
     const [DateTypeList , setDateTypeList] = useState<any[]>([{id:"pR_Start_Date" , value : "Pr Start Date"},{id:"pR_Due_Date" , value : "Due Date"},{id:"pR_End_Date" , value : "End Date"}])
     const [periodList , setperiodList] = useState<any[]>([{id:"Today" , value : "Today"},{id:"Current_WeeK" , value : "Current Week"},{id:"Current_Month" , value : "Current Month"}, {id:"Custom" , value : "Custom"}])
     const [LogData , setLogData] = useState<any[]>([])
     const [selectedData , setselectedData] = useState<any>({})
     const [isInitiatPr , setisInitiatPr] = useState<boolean>(false)
 const performanceManagementReducer: any = useSelector((state: RootState) => state?.performanceManagementReducer);
  const { isRandRAdded, ToasterMsg , isLoading } = useSelector((state: RootState) => performanceManagementReducer.PmRandRReviewDataSlice);
      let formData: any = {
        prStatus: isEditData ? isEditData.prStatus : "All",
        date_type: isEditData ? isEditData?.date_type : "All",
        period : isEditData ? isEditData?.period : "All",
        start_date: isEditData ? isEditData?.start_date : null,
        end_date: isEditData ? isEditData?.end_date : null,
      };

  useEffect(() => {
    if (isRandRAdded) {
      handleCloseRR()
      dispatch(PmRandRAddandSave(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.Message,
          isTosterFailed: ToasterMsg.Status === "Success" ? false : true,
        })
      );
      callPageOnload()
     
    }
  }, [isRandRAdded]);    

  useEffect(() => {
       dispatch(updateLoader(isLoading))
  },[isLoading])
      const {
        handleSubmit: handlePReventlogfilter,
        reset,
        setValue,
        formState: { errors: errorsPReventlogfilter, isDirty },
        control,
        watch,
        getValues,
      } = useForm<FormDataprops>({
        defaultValues: formData,
        mode: "onChange",
      });
      useEffect(() => {
        setPRStatusList(lovTable.filter((item: any) => item.Name === LovConfig?.PR_Status) || []);
      }, [lovTable]);

      const SubmitForm =(val : any) => {
        console.log(val);
          
        let beforFilter : any[] = PMEventListTable || []
        if(val?.prStatus && val?.prStatus != "All"){
          console.log("val");
          
          beforFilter = beforFilter.filter((item : any) => item?.eventLog_Status == val?.prStatus)
        }

        if(val?.date_type ){
          beforFilter =  checkPeriod(beforFilter , val?.date_type , val)
        }

        setLogData(beforFilter) 
      }

      const columns: any = {
        table_name: "Event Log",
        isAction: true,
        columns: [
    
            {
                Field: "pR_Ref_No",
                DisplayName: "PR No.",
                FieldType: "string",
              
            },
            {
              Field: "eventLog_Status_Value",
              DisplayName: "Status",
              FieldType: "string",
              customCell: ({ cell , row }: { cell: any ,  row: any  }) => (
                <>
                    <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
      
          <CustomStatusChip
            height={32}
            status={row.original?.eventLog_Status_Value == PR_Event_Status?.Not_Started ? "NS" : row.original?.eventLog_Status_Value == PR_Event_Status?.Completed  ? "C" : "WIP"}
            label={row.original?.eventLog_Status_Value}
          />
        </Box>
                </>
            )
            
          },
            {
              Field: "pR_Target",
              DisplayName: "Target/Milestone",
              FieldType: "string",
            
          },
          {
            Field: "req_No",
            DisplayName: "Req. No.",
            FieldType: "string",
          
        },
        {
          Field: "contract_No",
          DisplayName: "Contract No",
          FieldType: "string",
        
      },
      {
        Field: "vendor_Name",
        DisplayName: "Vendor",
        FieldType: "string",
      
    },
    {
      Field: "company_Name",
      DisplayName: "Company",
      FieldType: "string",
    
    },
    {
      Field: "pR_Start_Date",
      DisplayName: "PR Start Date",
      FieldType: "date",
    
    },
    {
    Field: "pR_Due_Date",
    DisplayName: "Due Date",
    FieldType: "date",
    
    },
    {
      Field: "pR_End_Date",
      DisplayName: "End Date",
      FieldType: "date",
    
    } ],
    };

const checkPeriod = (beforFilter :any , field : any , val : any) => {
   let perod_value = val?.period;
  let beforFiltervalue = beforFilter
  if(field == "All" && perod_value == "All"){
       return beforFiltervalue ;
  }else{
    if(perod_value == "All" && field != "All" ){
      return beforFiltervalue ;
    }
    else{
      const { filterStartDate, filterEndDate } = calculateDateRange(perod_value , val?.start_date  , val?.end_date);
   console.log(filterStartDate, filterEndDate , field );
   if(field == "All"){
    beforFiltervalue = beforFiltervalue.filter((item : any) =>{

      return (new Date(item?.pR_Start_Date) >= new Date(filterStartDate) && new Date(item?.pR_Start_Date) <= new Date(filterEndDate)) && (new Date(item?.pR_Due_Date) >= new Date(filterStartDate) && new Date(item?.pR_Due_Date) <= new Date(filterEndDate)) && (new Date(item?.pR_End_Date) >= new Date(filterStartDate) && new Date(item?.pR_End_Date) <= new Date(filterEndDate))
  }  );
  return beforFiltervalue
   }else{
    beforFiltervalue = beforFiltervalue.filter((item : any) =>{

      return new Date(item[field]) >= new Date(filterStartDate) && new Date(item[field]) <= new Date(filterEndDate)
  }  );
  return beforFiltervalue
   }
     
    }
   
  }


 
}

const calculateDateRange = (id: string, customStartDate?: Date, customEndDate?: Date) => {
  let filterStartDate = new Date();
  let filterEndDate = new Date();

  switch (id) {
    case "Today":
      filterStartDate = new Date();
      filterStartDate.setHours(0, 0, 0, 0); 
      filterEndDate = new Date();
      filterEndDate.setHours(23, 59, 59, 999); 
      break;

    case "Current_WeeK":
      const currentDay = filterStartDate.getDay(); 
      const weekStartOffset = currentDay === 0 ? 6 : currentDay - 1;
      filterStartDate.setDate(filterStartDate.getDate() - weekStartOffset);
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate = new Date(filterStartDate);
      filterEndDate.setDate(filterStartDate.getDate() + 6);
      filterEndDate.setHours(23, 59, 59, 999);
      break;

    case "Current_Month":
      filterStartDate = new Date(filterStartDate.getFullYear(), filterStartDate.getMonth(), 1);
      filterEndDate = new Date(filterStartDate.getFullYear(), filterStartDate.getMonth() + 1, 0);
      filterEndDate.setHours(23, 59, 59, 999);
      break;

    case "Custom":
      if (customStartDate && customEndDate) {
        filterStartDate = new Date(customStartDate);
        filterEndDate = new Date(customEndDate);
        filterStartDate.setHours(0, 0, 0, 0);
        filterEndDate.setHours(23, 59, 59, 999);
      } else {
        filterStartDate = new Date() ;
        filterEndDate = new Date() ;
      }
      break;

    default:
      throw new Error("Invalid period ID provided.");
  }

  return { filterStartDate, filterEndDate };
};

      useEffect(() =>{
        callPageOnload()
      },[])

      const callPageOnload = () => {
        dispatch(
          getAllPMEventLog({})
            );
      }

      useEffect(() =>{
        setLogData(PMEventListTable) 
      },[PMEventListTable])


    const closeFormValue = () => {

    }

    // const handlechange = (row: any, field: any, index: any) => {
    //   setselectedData(row)
    //   setisInitiatPr(true)
    // }

    const handleOpen = async (data: any, Option: string) => {

      if (Option === "edit") {
     

      } else if (Option === "next") {
        setselectedData(data)
        setisInitiatPr(true)
      }
  };

  const clearcustomDates = () => {
    setValue("start_date" , null)
    setValue("end_date" , null)
  }

  const handleCloseRR = () => {
  
    setisInitiatPr(false)
  }

return (
<>
   {!isInitiatPr ? <Box>
    <Stack spacing={4}>
      
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" color={"text.primary"}>
         Performance Preview Event Log
        </Typography>
      
      </Box>

      <Box>
        <Grid container spacing={2}>
        <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="prStatus"
              control={control}
              render={({ field }) => (
                <CustomSelect
                showAllOption={true}
                showAll={{LovId:"All" , Options : "All"}}
                  name={"PR Status"}
                  valueKey="LovId"
                  optionLabelKey="Options"
                  options={PRStatusList}
                  readOnly={view}
                  required={true}
                  label={"PR Status"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorsPReventlogfilter.prStatus)}
                  helperText={errorsPReventlogfilter.prStatus?.message?.toString()}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="date_type"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomSelect
                showAllOption={true}
                 showAll={{id:"All" , value : "All"}}
                  name={"Date Type"}
                  valueKey="id"
                  optionLabelKey="value"
                  options={DateTypeList}
                  readOnly={view}
                  required={true}
                  label={"Date Type"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorsPReventlogfilter.date_type)}
                  helperText={errorsPReventlogfilter.date_type?.message?.toString()}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="period"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomSelect
                showAllOption={true}
                showAll={{id:"All" , value : "All"}}
                  name={"period"}
                  valueKey="id"
                  optionLabelKey="value"
                  options={periodList}
                  readOnly={view}
                  required={true}
                  label={"Period"}
                  value={field.value}
                  onChange={(e) => {field.onChange(e) ; clearcustomDates()}}
                  error={Boolean(errorsPReventlogfilter.period)}
                  helperText={errorsPReventlogfilter.period?.message?.toString()}
                />
              )}
            />
          </Grid>
       <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
                   <Controller
                    name="start_date"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={ null}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"Start Date"}
                        value={field.value ? dayjs(field.value) : null}
                        required={watch("period") != "Custom"  ? false : true}
                        readOnly={watch("period") != "Custom" }
                        onChange={field.onChange}
                        error={Boolean(errorsPReventlogfilter.start_date)}
                        helperText={
                            errorsPReventlogfilter.start_date &&
                          errorsPReventlogfilter.start_date.message?.toString()
                        }
                      /> )}
                      />
          </Grid> 
        <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
                    name="end_date"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={ null}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"End Date"}
                        value={field.value ? dayjs(field.value) : null}
                        required={watch("period") != "Custom"  ? false : true}
                        readOnly={watch("period") != "Custom" }
                        onChange={field.onChange}
                        error={Boolean(errorsPReventlogfilter.end_date)}
                        helperText={
                            errorsPReventlogfilter.end_date &&
                          errorsPReventlogfilter.end_date.message?.toString()
                        }
                      /> )}
                      />
          </Grid> 
         <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
              <Box sx={{display: "flex", gap:2 , justifyContent:'flex-end' }}>
                                      <CustomButton
                                            type="reset"
                                            name={"Submit"}
                                            variant="outlined"
                                            onClick={handlePReventlogfilter(SubmitForm)}
                                        />
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider></Divider>
    
    {/* <PREventLogTable data={LogData} handlechange ={handlechange}/> */}
    <Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <MaterialDynamicGrid
                                data={columns}
                                rows={LogData}
                                handleAction={handleOpen}
                                enableZoomIcon={true}
                                showNext={true}
                                propNextbtn={{ name: "Initiate PR" }}
                                showAdd={false}
                                showhistory={false}
                                showDelete={false}
                                showView={true}
                                showEdit={false}
                                showExports={true}
                                pageName="PR Scope View"
                                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                                isNextDisableByValue={[{key :"eventLog_Status_Value" , value : PR_Event_Status?.Not_Started}]}
                                
                            />
                        </Box>
                    </Box>
    </Stack>
  </Box>   : 
  
  // <InitiateReview edit={true} view={false} onClose={() => setisInitiatPr(false) } isEditData={selectedData} /> 
  <RandRReview  view={false} onClose={() => handleCloseRR()  } isEditData={selectedData} /> 
  
  }

  </>
)

}