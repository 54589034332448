import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomFileUploader from "../../../../common/dynamicFileUploader/CustomFileUploader";
import FilePreviewer from "../../../../common/fileViewer/FilePreviewer";
import ExcelJS from "exceljs";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import { VOBAssessmentTemplate } from "./VOBAssessmentBulkDownloadQuestion";
import DynamicBasicTable from "../../../../common/dynamicbasicTable/DynamicBasicTable";
interface BulkUploadQuestionCOBProps {
  onclose?: () => void;
  handleSubmitBulkAssessmentQuestion?: (data:any) => void;
  AssessmentData: any;
}

export const BulkUploadAssessmentQuestionVOB: React.FC<BulkUploadQuestionCOBProps> = ({ onclose  , handleSubmitBulkAssessmentQuestion , AssessmentData }) => {
  const [excelsheet, setvalidexcelsheet] = useState<any>(null);
  const [uploaddata, setUploaddata] = useState<any[]>([]);
  const [DepartmentList, setDepartmentList] = useState<any[]>([]);
  const [selectedCatagoryPreview, setselectedCatagoryPreview] = useState<any[]>([]);
  const [Validatateddata, setselectedValidatateddata] = useState<any[]>([]);
  const [commonError, setcommonError] = useState<any>("");

  const clearOldData = () => {
    setvalidexcelsheet(null);
    setUploaddata([]);
    setselectedValidatateddata([]);
    setselectedCatagoryPreview(AssessmentData)
  };

  useEffect(() => {
    
      setDepartmentList([]);

  }, []);
  useEffect(() => {
    if(uploaddata && uploaddata.length > 0){
      Question_uploadsheet_validation()
    }
   
  },[uploaddata])
  const ExcelToJson = async (data: any) => {
    const headers = data[1];

    if (headers) {
      const jsonData = [];

      for (let i = 2; i < data.length; i++) {
        const row = data[i];
        if (!row) {
          continue;
        }
        const obj: any = { id: i - 1 };
        for (let j = 1; j < headers?.length; j++) {
          const cellValue = row[j];
          if (cellValue && typeof cellValue === "object" && "text" in cellValue) {
            obj[headers[j]] = cellValue.text;
          } else {
            obj[headers[j]] = typeof cellValue === "boolean" ? cellValue.toString() : cellValue;
          }
        }

        jsonData.push(obj);
      }

      return jsonData;
    }
  };
  const handleFileUpload = async (e: any | null) => {
    clearOldData();
    setvalidexcelsheet(e);
    if (e !== null) {
      const file = e[0];
      const workbook = new ExcelJS.Workbook();

      await workbook.xlsx.load(file);

      const worksheet = workbook.worksheets[0];
      const data = worksheet.getSheetValues();

      let JsonSheetData: any = await ExcelToJson(data);
      console.log(JsonSheetData, "JsonSheetData");
      setUploaddata(JsonSheetData || []);
    } else {
    }
  };



const checkSNo = (no: any) => {
    console.log(no);
    const indexs: any[] = no?.toString()?.split(".");
    let valid = true;
  
    if (!indexs[0] || indexs[0] <= 0) {
      valid = false;
    }
    if (!indexs[1] || indexs[1] <= 0) {
      valid = false;
    }
  
    const c_index = indexs[0] ? parseInt(indexs[0]) - 1 : NaN;
    const Q_index = indexs[1] ? parseInt(indexs[1]) - 1 : NaN;
  
    return { c_index, Q_index, valid };
  };
  

  const Question_uploadsheet_validation = () => {
    
    if (uploaddata) {
        let ValidatedData: any[] = [];
        uploaddata.forEach((item: any) => {
          if(item["SrNo"]){
          let error: string = "";
     
          let object: any = {
            SrNo: "",
            Question: "",
            Answer_Type: "",
            response : "",
            error : "",
          };

          let SrNo = item["SrNo"];
          let Question =  item["Question"]
          let Answer_Type = item["Answer Type"];
          let response = item["Response"] ? item["Response"].trim() : "";
          let Answer_Type_value = Answer_Type && Answer_Type == "Multichoice"  ? true : false  ;

          object.SrNo = SrNo
          object.Question = Question
          object.Answer_Type = Answer_Type
          object.response = response

          let { c_index, Q_index, valid } = checkSNo(SrNo);

          if (valid && !isNaN(c_index) && !isNaN(Q_index)) {
            console.log(c_index , Q_index , AssessmentData );
            
            if (AssessmentData[c_index] && AssessmentData[c_index]?.Questions[Q_index]) {
              let QuestionData = AssessmentData[c_index]?.Questions[Q_index];
                      
              if (
                QuestionData?.Question === Question &&
                QuestionData?.Answer_Type == Answer_Type_value &&
                Answer_Type
              ) {

                if(response){
                    let value = ""

                    if(QuestionData?.Answer_Type == true){
                       let choicevalue : any =  QuestionData?.choices.find((choice_item : any) => choice_item?.Attribute_Response == response)?.Score;

                       if(choicevalue){
                        value =  choicevalue
                        setselectedCatagoryPreview((prevData: any) => {
                            let newData = [...prevData];
                            let category = { ...newData[c_index] };
                            let question = { ...category.Questions[Q_index] };
                            question.ChoicesScore = choicevalue;
                            question.ApplicableRisksScore = question.ApplicableRisksScore.map((risk: any) => {
                              return { ...risk, Score: choicevalue };
                            });
                            category.Questions[Q_index] = question;
                            newData[c_index] = category;
                            return newData;
                          }); 
                       }
                    }
 
                    if(QuestionData?.Answer_Type == false){
                        value = response
                        setselectedCatagoryPreview((prevData: any) => {
                            const newData = [...prevData];
                            newData[c_index].Questions[Q_index].Response_Received = response;
                            return newData;
                          });    
                    }

                      if(!value){
                        object.error = "Invalid Response";  
                      }
                }else{
                    object.error = "Response is Required"; 
                }
                 


                          
              } else {
                object.error = "Invalid Question";
              }
            } else {
              object.error = "Invalid SrNo";
            }
          } else {
            object.error = "Invalid SrNo"; 
          }
          
             
            
           
          ValidatedData.push(object);
        }
        });

        // pass parend component
        if(ValidatedData && ValidatedData?.length > 0){
          setselectedValidatateddata(ValidatedData);
        }else{
          setcommonError("Please Upload Valid data or check SrNo ")    
        }
    
      }
             
  }

  const validateValidData = () => {
          
    if(Validatateddata && Validatateddata.length == 0){
         return  []
    }else{
            
          return Validatateddata.filter((item) => !item.error )
    }
  }
  const handlebulkuploadAQMMappingForm = () => {
           let validata : any[] = validateValidData()
           if(selectedCatagoryPreview && validata && validata.length > 0 && handleSubmitBulkAssessmentQuestion){
            handleSubmitBulkAssessmentQuestion(selectedCatagoryPreview)
           }
  }



  return (
    <>
      <Stack spacing={2}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <Box sx={{ width: "50%" }}>
            {excelsheet == null ? (
              <CustomFileUploader onFileUpload={handleFileUpload} maxFileSize={1048576} acceptFormats={[".xlsx"]} />
            ) : (
              <FilePreviewer onRemovefile={() => handleFileUpload(null)} file={excelsheet} />
            )}
          </Box>
        
        </Box>
        {Validatateddata?.length > 0 && <Box sx={{width:'100%'}}>
                
                  <DynamicBasicTable
         key={'VOBAsssessmentUploadedQuestiontable'}
          style={{ border: "1px solid #f1f1f1", borderRadius: "5px", maxHeight: "50vh" }}
          columns={[...VOBAssessmentTemplate, {
            Field: "error",
            DisplayName: "Error",
          
          },]}
          rowData={Validatateddata}
          
        />
                  </Box> }

                  <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent : 'flex-end' , gap: 2 , width :'100%' }}>
          <CustomButton
            type="reset"
            variant="outlined"
            name={"Close"}
            onClick={() => {
                onclose &&
                onclose() 
            }}
          />
          <CustomButton type="reset"  disabled={commonError || Validatateddata?.length == 0 || validateValidData().length != Validatateddata?.length } name={"Submit"} onClick={() => handlebulkuploadAQMMappingForm()} />
        </Box> 
      </Stack>
    </>
  );
};
