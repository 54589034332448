import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm, useFormContext } from "react-hook-form";
import CustomButton from "../../../../../../common/dynamicButton/CustomButton";
import CustomMultilineTextField from "../../../../../../common/dynamicInputs/CustomMultilineTextField";
import CustomNumberField from "../../../../../../common/dynamicInputs/CustomNumberField";
import { ACTIVE, textFieldWidth } from "../../../../../../utils/constants";
import CustomSelect from "../../../../../../common/dynamicInputs/CustomSelect";
import CustomBreadcrumbs from "../../../../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import CustomTextField from "../../../../../../common/dynamicInputs/CustomTextField";
import CustomMultiSelect from "../../../../../../common/dynamicInputs/CustomMultiSelect";
import CustomCheckBox from "../../../../../../common/dynamicInputs/CustomCheckBox";
import CustomToogleinput from "../../../../../../common/dynamicInputs/CustomToogleInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { ApplicationParameterName, LovConfig, MetrixMasterConfig } from "../../../../../../config/config";
import { setSeverityColorValue } from "../addSLAValidation";

interface ScopeDetailsprops {
  view?: boolean;
  edit?: boolean;
  isEditData?: any;
  add?: boolean;
}

export const ScopeDetails: React.FC<ScopeDetailsprops> = ({ view, edit, isEditData, add }) => {
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
        const { metricsMasterData } = useSelector((state: RootState) => state.metricsMasters);
  const [categoryList, setcategoryList] = useState<any>([]);
  const [currencyList, setCurrency] = useState<any>([]);
  const { CurrencyData } = useSelector((state: RootState) => state.CurrencyData);
  const { allUsers } = useSelector((state: RootState) => state.userData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
    const { RiskRateDataActive } = useSelector((state: RootState) => state.RiskRateData);
  const [obligationList, setobligationList] = useState<any>([]);
  const [responderList, setresponderList] = useState<any>([]);
  const [smeinvolvedList, setsmeinvolvedList] = useState<any>([]);
  const [severity_of_slaList, setseverity_of_slaList] = useState<any>([]);
 const [defaultEstimateSpend, setDefaultEstimateSpend] = useState<any>(1);

  const {
    handleSubmit: scopeDetailsHandleSubmit,
    formState: { errors: errorsscopeDetailsform, isDirty: scopeDetailsIsDirty },
    getValues: scopeDetailsGetValues,
    control,
    trigger : scopeDetailstrigger ,
    watch: scopeDetailswatch,
    setValue: scopeDetailsSetValue,
    reset: scopeDetailsReset,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "severityof_Target",
  });

  useEffect(() => {
    setcategoryList(lovTable.filter((item: any) => item.Name === LovConfig?.pm_category) || []);
    setobligationList(lovTable.filter((item: any) => item.Name === LovConfig?.pm_oblication) || []);
    setresponderList(lovTable.filter((item: any) => item.Name === LovConfig?.pm_Responder) || []);
  }, [lovTable]);
  useEffect(() => {
    setsmeinvolvedList(allUsers);
  }, [allUsers]);




 useEffect(() => {
  if (applicationParameterData.length > 0 && add) {

          const filteredRegionalData = applicationParameterData.find(
              (item: any) => item.Parameter_Name === ApplicationParameterName.EstimateSpend && item.IsActive === ACTIVE
            );
       
    const lastParameterValue = filteredRegionalData?.Parameter_Value || 1;
   
    setDefaultEstimateSpend(lastParameterValue);
  
  }
}, [applicationParameterData]);




   useEffect(() => {
     if (metricsMasterData) {
       let findedRiskType = metricsMasterData.filter(
         (risk_item) => risk_item?.Metrics === MetrixMasterConfig.AQ_Response_Type && risk_item?.IsActive === "Active"
       );
 
       setseverity_of_slaList(setSeverityColorValue(findedRiskType || []));
     }
   }, [metricsMasterData]);
  useEffect(() => {
    if (CurrencyData && CurrencyData.length > 0) {
      const ActiveCurrencies = CurrencyData.filter((item: any) => item.IsActive === ACTIVE);
      const MergedCurrencies = ActiveCurrencies.map((item: any) => {
        const mergedCurrency =  `${item.CurrencyCode} (${item.Symbol})`;

        return {
          ...item,
          mergedCurrency,
        };
      });
      setCurrency(MergedCurrencies);
    }
  }, [CurrencyData]);

  const findandsetcategoryName = (id: any) => {
        
    let value = categoryList?.find((item : any) => item.LovId == id)?.Options
    if(value){
      scopeDetailsSetValue("category_Name" , value)
    }
  
}

const findandsetobligationName = (id: any) => {
      
  let value = obligationList?.find((item : any) => item.LovId == id)?.Options
  if(value){
    scopeDetailsSetValue("obligation_Name" , value)
  }

}
  return (
    <>
      <Box>
        <Stack spacing={4}>
          <Box>
            <Grid container spacing={2}>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="scope_Of_Target"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: "Scope of the Target is Required",
                    validate: (data) => {
                      return data.length > 5 || "Minimal 5 characters requires";
                    },
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"scope_Of_Target"}
                      required={true}
                      label={"Scope of the Target"}
                      value={field.value}
                      disabled={view}
                      onChange={field.onChange}
                      error={Boolean(errorsscopeDetailsform.scope_Of_Target)}
                      helperText={
                        errorsscopeDetailsform.scope_Of_Target &&
                        errorsscopeDetailsform.scope_Of_Target.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="category"
                  control={control}
                  defaultValue={""}
                  rules={{ required: "Category is required" }}
                  render={({ field }) => (
                    <CustomSelect
                      name={"category"}
                      valueKey={"LovId"}
                      optionLabelKey="Options"
                      options={categoryList}
                      readOnly={view}
                      required={true}
                      label={"Category"}
                      value={field.value}
                      onChange={(e) => {field.onChange(e) ; findandsetcategoryName(e)}}
                      error={Boolean(errorsscopeDetailsform.category)}
                      helperText={errorsscopeDetailsform.category?.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="deliverables"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: "Deliverables is Required",
                    validate: (data) => {
                      return data.length > 5 || "Minimal 5 characters requires";
                    },
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"deliverables"}
                      required={true}
                      label={"Deliverables"}
                      value={field.value}
                      disabled={view}
                      onChange={field.onChange}
                      error={Boolean(errorsscopeDetailsform.deliverables)}
                      helperText={
                        errorsscopeDetailsform.deliverables && errorsscopeDetailsform.deliverables.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="obligation"
                  control={control}
                  defaultValue={""}
                  rules={{ required: "Obligation of Third-party / Company is required" }}
                  render={({ field }) => (
                    <CustomSelect
                      name={"obligation"}
                        valueKey={"LovId"}
                      optionLabelKey="Options"
                      options={obligationList}
                      readOnly={view}
                      required={true}
                      label={"Obligation of Third-party / Company"}
                      value={field.value}
                      onChange={(e) => {field.onChange(e); findandsetobligationName(e)}}
                      error={Boolean(errorsscopeDetailsform.obligation)}
                      helperText={errorsscopeDetailsform.obligation?.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="exclusion"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: "Exclusions is Required",
                    validate: (data) => {
                      return data.length > 5 || "Minimal 5 characters requires";
                    },
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"exclusion"}
                      required={true}
                      label={"Exclusions"}
                      value={field.value}
                      disabled={view}
                      onChange={field.onChange}
                      error={Boolean(errorsscopeDetailsform.exclusion)}
                      helperText={
                        errorsscopeDetailsform.exclusion && errorsscopeDetailsform.exclusion.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="sme_Involved"
                  defaultValue={[]}
                  control={control}
                  rules={{
                    required: "SMEs Involved is Required",
                  }}
                  render={({ field }) => (
                    <CustomMultiSelect
                      optionLabelKey="EmailId"
                      optionIdKey="UserId"
                      options={smeinvolvedList || []}
                      required={true}
                      label={"SMEs Involved"}
                      readOnly={view}
                      value={Array.isArray(field.value) ? field.value : []}
                      onChange={field.onChange}
                      error={Boolean(errorsscopeDetailsform.sme_Involved)}
                      helperText={
                        errorsscopeDetailsform.sme_Involved && errorsscopeDetailsform.sme_Involved?.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="input_Dependency"
                  control={control}
                  defaultValue={""}
                  rules={{ required: "Responder is required" }}
                  render={({ field }) => (
                    <CustomSelect
                      name={"input_Dependency"}
                      valueKey={"LovId"}
                      optionLabelKey="Options"
                      options={responderList}
                      readOnly={view}
                      required={true}
                      label={"Responder"}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsscopeDetailsform.input_Dependency)}
                      helperText={errorsscopeDetailsform.input_Dependency?.message?.toString()}
                    />
                  )}
                />
              </Grid>
             

              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Box sx={{ display: "flex", gap: 0.4 }}>
                  <Box sx={{ width: "48%", paddingRight: "10px" }}>
                    <Controller
                      name="currency"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "currency is required",
                        
                      }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"Currency_Id"}
                          valueKey={"CurrencyId"}
                          optionLabelKey="mergedCurrency"
                          options={currencyList || []}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          readOnly={view}
                          required={true}
                          label={"Currency"}
                          error={Boolean(errorsscopeDetailsform.currency)}
                          helperText={
                            errorsscopeDetailsform.currency && errorsscopeDetailsform.currency.message?.toString()
                          }
                        />
                      )}
                    />
                  </Box>
                  <Controller
                    name="project_Cost"
                    defaultValue={""}
                    control={control}
                    rules={{
                      required: "Estimated Spend is required",
                      validate: {
                        validNumber: (value) => {
                          const number = parseInt(value, 10);
                          const maxDigits = 15;

                          if (isNaN(number)) {
                            return "Please enter a valid number for Estimated Spend";
                          }

                          // Validate number of digits
                          if (value.length > maxDigits) {
                            return `Estimated Spend should be ${maxDigits} digits or less`;
                          }
                          // Validate number of digits
                          if (value < defaultEstimateSpend) {
                            return `Estimated Spend should be greater than or equal to ${defaultEstimateSpend} `;
                          }

                          return true;
                        },
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <CustomNumberField
                          label={"project Cost"}
                          name={"project Cost"}
                          value={field.value}
                          isDecimal={true}
                          required={true}
                          onChange={field.onChange}
                          error={Boolean(errorsscopeDetailsform.project_Cost)}
                          helperText={
                            errorsscopeDetailsform.project_Cost &&
                            errorsscopeDetailsform.project_Cost.message?.toString()
                          }
                        />
                      </>
                    )}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="evidence_Required"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                   
                      <CustomCheckBox
                        label="Evidence Required"
                        isStyle={true}
                        readOnly={view }
                        value={field.value}
                        onChange={field.onChange}
                      />
                   
                  )}
                />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="penality_For_Deviation"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <CustomCheckBox
                      isStyle={true}
                      alignItems="left"
                      label="Penality for Deviation"
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsscopeDetailsform.penality_For_Deviation)}
                      helperText={
                        errorsscopeDetailsform.penality_For_Deviation &&
                        errorsscopeDetailsform.penality_For_Deviation.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="stakeholder_acceptance_required"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <CustomCheckBox
                      isStyle={true}
                      alignItems="left"
                      label="Stake Holder Acceptance Required?"
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsscopeDetailsform.stakeholder_acceptance_required)}
                      helperText={
                        errorsscopeDetailsform.stakeholder_acceptance_required &&
                        errorsscopeDetailsform.stakeholder_acceptance_required.message?.toString()
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          {scopeDetailswatch("penality_For_Deviation") ? (
            <Box sx={{ mt: 2 }}>
              <Controller
                name="penality_deviation_remark"
                control={control}
                //defaultValue={}
                rules={{
                  required: "Penality Deviation is required",
                }}
                render={({ field }) => (
                  <CustomMultilineTextField
                    label={"Penality Deviation"}
                    value={field.value}
                    disabled={view}
                    onChange={field.onChange}
                    rows={2}
                    required={true}
                    error={Boolean(errorsscopeDetailsform?.penality_deviation_remark)}
                    helperText={
                      errorsscopeDetailsform?.penality_deviation_remark &&
                      errorsscopeDetailsform?.penality_deviation_remark?.message?.toString()
                    }
                  />
                )}
              />
            </Box>
          ) : null}
          <Divider></Divider>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography
              sx={{
                height: "12px",
                color: "#959595",
                display: "block",
                fontSize: "15px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              SEVERITY OF TARGET
            </Typography>
          </Box>

          <Box>
            <Grid container spacing={2}>
              {fields?.map((row: any, index) => {
                return (
                  <Grid
                  key={`severityof_Target-${index+1}`}
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name={`severityof_Target.${Number(index)}.metrics_Id`}
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "Target is Required"
                      }}
                      render={({ field }) => (
                        <CustomToogleinput
                          name={"review_type"}
                          valueKey="Metrics_Id"
                          optionLabelKey="Rating"
                          options={severity_of_slaList}
                          readOnly={view}
                          required={true}
                          label={`${row?.label}`}
                          value={field.value}
                          onChange={field.onChange}
                          // error={!!(errorsscopeDetailsform?.severityof_Target as any[])[index]?.risk_Id}
                          // helperText={(errorsscopeDetailsform?.severityof_Target as any[])[index]?.risk_Id?.message?.toString()}
                    //       error={!!((errorsscopeDetailsform?.severityof_Target as unknown) as any[])[index]?.metrics_Id}
                    //  helperText={((errorsscopeDetailsform?.severityof_Target as unknown) as any[])[index]?.metrics_Id?.message || ""}
                          //  error={Boolean(
                          //   errorsscopeDetailsform?.severityof_Target &&
                          //   errorsscopeDetailsform?.severityof_Target[Number(index)]
                          //     ?.risk_Id
                          // )}
                          // helperText={
                          //   errorsscopeDetailsform?.severityof_Target &&
                          //   errorsscopeDetailsform?.severityof_Target[Number(index)]
                          //     ?.risk_Id &&
                          //   errorsscopeDetailsform?.severityof_Target[
                          //     Number(index)
                          //   ]?.risk_Id?.message?.toString()
                          // }
                        />
                      )}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
