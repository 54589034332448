import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import DynamicBasicTable from "../../../../common/dynamicbasicTable/DynamicBasicTable";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import CustomDialog from "../../../../common/dynamicDialog/CustomDialog";
import CustomBreadcrumbs from "../../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import LableandValuesPreview from "../../../../common/CommonPreview/LableandValuesPreview";
import { addWorkingDays, textFieldWidth } from "../../../../utils/constants";
import { AssessmentResponseCOBAnswerMode } from "../assessmentResponseCOB/AssessmentResponseCOBAnswerMode";
import CommonDataExport from "../../../../common/CommonSheetsDownload/ExcelDownload";
import { AssessmentDetailContext } from "../CustomerOnBoardingAssessment";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../../store/store";
import { getDepartmentList } from "../../../../store/slices/departmentData";

export interface AssessmentQuestionnaireForCustomerProps {
    onClose?: any;
    Customerdata:any;
}
export const SingleAssessmentQuestionnaireForCustomer: React.FC<AssessmentQuestionnaireForCustomerProps> = ({onClose,Customerdata}) => {
    // const dispatch = useAppDispatchThunk();
    const [isNewAddress, setIsNewAddress] = useState(false);
    const [isNewSpoc, setIsNewSpoc] = useState(false);
    const [isNewEsc, setIsNewEsc] = useState(false);
    const [option, setOption] = useState("");
    const [QuestionList,setQuestionList]=useState<any[]>([]);
    const [selectedQuestion,setselectedQuestion]=useState<any>("");
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
    // const [IsEditData, setIsEditData] = useState<any>({});
    const [isEditOrViewAddress, setIsEditOrViewAddress] = useState(true);
    const [isEditOrViewSPOC, setIsEditOrViewSPOC] = useState(false);
    const [rowView, setRowView] = useState(false)
    const [isEditOrViewEsc, setIsEditOrViewEsc] = useState(false);
    const [expanded, setExpanded] = useState<any>(3);
    const { dataTable } = useSelector((state: RootState) => state.departmentData);
    const dispatch = useAppDispatchThunk();
    const [selectedDepartment,setSelectedDepartment]=useState<any>("")
    const TableHead = [
        { Field: "Sr", DisplayName: "Sr." },
        { Field: "Question", DisplayName: "Question" },
        { Field: "type", DisplayName: "Type" },
        { Field: "currenQuestionAns", DisplayName: "Responce" }
    ];
    console.log(Customerdata,"Customerdata")
    useEffect(() => {
        dispatch(getDepartmentList({ FormID: 2 }));
         dataTable.map((item:any)=>
            item.DepartmentId== Customerdata.selectedDepartment && setSelectedDepartment(item.DepartmentName))
    }, [])
    useEffect(()=>{
        let tableData=Customerdata;

        let tableDispalyRow:any[]=[];
        tableData.AssessmentQuestionnaires.map((data:any,index:any)=>{ 
            let queType=data.Answer_Type?"Multi Choice":"Free Text"
            let  currenQuestionAns : any =  data?.Answer_Type
                  ? data?.Options.find(
                      (oitem: any) => oitem?.Assessment_Questionnaire_OptionId == data?.Response_In_Option
                    )?.Attribute_Response
                  : data?.Response_In_Text;
                  
            let row={...data ,Sr:index+1 ,type:queType,currenQuestionAns:currenQuestionAns}
            tableDispalyRow.push(row)
              
        })
        setQuestionList(tableDispalyRow)
    },[Customerdata])
    const handleOpen = (data: any, option: string, index?: number) => {
        setRowView(true)
        setOption(option);
        if (option === "active") {
            setIsEditOrViewAddress(true)
        }
        if (index || index === 0) setSelectedIndex(index); setselectedQuestion(data)
        console.log("my", index)
        if (expanded === 3) {
            handleAddressOption(data, option, index);
        } else if (expanded === 4) {
            handleSpocOption(data, option, index);
        } else if (expanded === 5) {
            handleEscalationOption(data, option, index);
        }
    };

    const setselectedQuestionindex = (dataindex: any) => {
    
        if (dataindex >= 0 && QuestionList && QuestionList.length > dataindex) {
            setSelectedIndex(dataindex);
          let selectedQues: any = QuestionList[dataindex];
         
          setselectedQuestion(selectedQues);
        }
      };

    const handleAddressOption = (data: any, option: string, index?: number) => {
        setIsEditOrViewEsc(false);
        setIsEditOrViewSPOC(false);
        if (index || index === 0) {
            if (option === "edit" || option === "view") {
                setIsEditOrViewAddress(data);
                setIsNewAddress(false);
            }
        }
    };


    const handleSpocOption = (data: any, option: string, index?: number) => {
        setIsEditOrViewAddress(false);
        setIsEditOrViewEsc(false);
        if (index || index === 0) {

            if (option === "edit" || option === "view") {
                setIsEditOrViewSPOC(data);
                setIsNewSpoc(false);
            }
        }
    };

    const handleEscalationOption = (data: any, option: string, index?: number) => {
        setIsEditOrViewAddress(false);
        setIsEditOrViewSPOC(false);
        if (index || index === 0) {
            if (option === "edit" || option === "view") {
                setIsEditOrViewEsc(data);
                setIsNewEsc(false);
            }
        }
    };

    const closeSection = (value: any) => {
        if (value === 0) {
            onClose(0)
        } else if (value === 1) {
            onClose(1)
        }
    };
    return (
        <div>


            <Box
                sx={{
                    width: "100%",
                }}
            >
                <Stack spacing={2}>
                <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
                    <CustomBreadcrumbs
                        list={[
                            { value: 0, name: "Pending Action" },
                            { value: 0, name: "Customer OnBoarding Assessment" },
                            { value: 1, name: "Assessment Questionnaire For Customer" },
                            { value: 2, name: "view" }
                        ]}
                        onchange={closeSection}
                    />
                </Box>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={textFieldWidth.xs}
                        sm={textFieldWidth.sm}
                        md={textFieldWidth.md}
                        lg={textFieldWidth.lg}
                        xl={textFieldWidth.xl}
                    >
                        <LableandValuesPreview lable="Customer Name" Value={Customerdata.Vendor_Name} />
                    </Grid>
                    <Grid
                        item
                        xs={textFieldWidth.xs}
                        sm={textFieldWidth.sm}
                        md={textFieldWidth.md}
                        lg={textFieldWidth.lg}
                        xl={textFieldWidth.xl}
                    >
                        <LableandValuesPreview lable="Service" Value={Customerdata.ServicesName} />
                    </Grid>
                    <Grid
                        item
                        xs={textFieldWidth.xs}
                        sm={textFieldWidth.sm}
                        md={textFieldWidth.md}
                        lg={textFieldWidth.lg}
                        xl={textFieldWidth.xl}
                    >
                        <LableandValuesPreview lable="Date of Completion" Value={Customerdata.AssessmentTarget_Date} />
                    </Grid>
                    <Grid
                        item
                        xs={textFieldWidth.xs}
                        sm={textFieldWidth.sm}
                        md={textFieldWidth.md}
                        lg={textFieldWidth.lg}
                        xl={textFieldWidth.xl}
                    >
                        <LableandValuesPreview lable="SME Department" Value={selectedDepartment} />
                    </Grid>

                </Grid>
                <Divider />
                
                    <Box
                        sx={{
                            display: "flex",
                            // flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box sx={{ marginTop: 2 }}>
                            <Grid >
                                <Typography sx={{ height: '12px', color: '#959595', display: 'block', fontSize: '15px' ,fontWeight:"bold"}}>
                                    QUESTION LIST
                                </Typography>

                            </Grid>
                        </Box>
                        <Box sx={{ marginTop: 2 }}>
                        {/* <Box> <CommonDataExport header={colums} data={Finaldata}   table_name={'Aggregated Deviation'} button_title={'Export All'} /> </Box>  */}
                            {/* <CustomButton variant="contained" name={"Download"} size="small" onClick={() => { }} /> */}
                        </Box>
                    </Box>       
                    <Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <DynamicBasicTable
                                columns={TableHead}
                                rowData={QuestionList}
                                isDownload={true}
                                 table_name={"Historical Data"}
                                style={{ border: "1px solid #f1f1f1", borderRadius: "5px", maxHeight: "70vh" }}
                                handleAction={handleOpen}
                                isView={true}
                                // isEdit={true}
                                //   isDelete={true}
                                //    showDeleteButton={true}
                                disableAction={false}
                            //    statusIdMapping={statusIdLabelMapping}
                            />
                        </Box>
                    </Box>
                
                <CustomDialog
                    show={rowView}
                    onHide={() => { setRowView(false) }}
                    maxWidth={"xl"}
                    minHeight={"50vh"}
                    header={`QUESTION ${selectedIndex !== undefined ? selectedIndex + 1 : 1}/${QuestionList.length}`}
                    contentNode={
                        <>
                            <Grid >
                                <Typography sx={{ height: '12px', color: '#959595', display: 'block', fontSize: '15px' }}>
                                     QUESTION {QuestionList.length} 
                                </Typography>
                                <AssessmentDetailContext.Provider
              value={{
                selectedQuestion,
                QuestionList,
                setselectedQuestionindex,
               selectedQuestionIndex : selectedIndex ,
              }}
            >

                                <AssessmentResponseCOBAnswerMode isAvailableDo={true} iscompleteDo={true} preViewMode={true} PageName={"N/A"}/>
                                </AssessmentDetailContext.Provider>
                              

                            </Grid>
                        </>

                    }
                />
                </Stack>
            </Box>

        </div>
    );
};

export default SingleAssessmentQuestionnaireForCustomer;

