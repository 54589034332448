import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { PHYSICAL_URL } from "./endpoints/physicalUpload";

const PhysicalUploadSubmit = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(PHYSICAL_URL.savePhysicalUpload, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const editPhysicalUpload = (requestBody: any): Promise<any> =>
  axiosInstance.post(PHYSICAL_URL.editPhysicalUpload, requestBody).then((response: AxiosResponse<any>) => {
    if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
      return response.data;
    }
    throw new Error("Error getting Data" + response);
  });

const getByIdPhysicalUpload = (requestBody: any): Promise<any> =>
  axiosInstance.post(PHYSICAL_URL.getByIdPhysicalUpload, requestBody).then((response: AxiosResponse<any>) => {
    if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
      return response.data;
    }
    throw new Error("Error getting Data" + response);
  });
const getAllPhysicalUploads = (requestBody: any): Promise<any> =>
  axiosInstance.post(PHYSICAL_URL.getAllPhysicalUploads, requestBody).then((response: AxiosResponse<any>) => {
    if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
      return response.data;
    }
    throw new Error("Error getting Data" + response);
  });
const physicalUploadService = {
  PhysicalUploadSubmit,
  editPhysicalUpload,
  getByIdPhysicalUpload,
  getAllPhysicalUploads,
};

export default physicalUploadService;
