import axios from "axios";
import { version } from "./constant";
const { REACT_APP_API_CM_URL } = process.env;


if (window.self !== window.top && window.location.pathname !== "/addExistingClass" && window.top !== null) {
  window.top.location = window.location.href;
}

export const axiosInstance = axios.create({
  baseURL: `${REACT_APP_API_CM_URL}${version}`,
  headers: {
    "Content-Type": "application/json",
    "X-Frame-Options": "DENY",
  },
});

export const axiosInstanceWithoutVersion = axios.create({
  baseURL: `${REACT_APP_API_CM_URL}/api`,
  headers: {
    "Content-Type": "application/json",
    "X-Frame-Options": "DENY",
  },
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    let token = sessionStorage.getItem("Token");
    // config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json';
    // config.headers['UserId'] = sessionStorage.getItem("UserId");
    // if (token) {
    //   config.headers['Authorization'] = `Bearer ${token}`;
    // }

    let returnConfig = config;
    console.log(returnConfig, "returnConfigreturnConfig");
    return returnConfig;
  },
  (error: any) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response: any) => {
    if (response.status === 401) {
      console.log("Authentication failed", response);
    }
    return response;
  },
  (error: any) => {
    console.log(error);
    return Promise.reject(error);
  }
);
