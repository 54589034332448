import React, { useEffect, useState } from "react";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import {
  ProcessDetailContext,
  RolesandResponsibility,
} from "../../vendorOnBoarding/rolesAndResponsibility/RolesAndResponsibility";
import { ProcessDetails } from "../../vendorOnBoarding/rolesAndResponsibility/ProcessDetails";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { getWorkList, setWorkFlowData } from "../../../store/slices/workFlowSlice";
import { getLovList } from "../../../store/slices/lovMasterData";
import { Box, Stack, Grid, Typography, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import {
  getRolesAndResponsibilityList,
  addRolesAndResponsibility,
  updaterolesandresponsAddandEdit,
} from "../../../store/contractManagement/slices/contractRolesAndResponsiblity";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { CommonTooltip } from "../../../common/CommonTooltip/CommonTooltip";
import {
  deletecontentMaster,
  getContentForm,
  getContentHistory,
  getContentList,
  updateContentAddandEdit,
  updateVersionHistory,
} from "../../../store/slices/contentMasterData";
import { ContentNameConfig, Edit_Enable_tosterMSG } from "../../../config/config";

interface ValidationErrors {
  [key: string]: string;
}

const RandRSetup = (props: any) => {
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const [workflowProcess, setWorkflowProcess] = useState<any[]>([]);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const { allUsers } = useSelector((state: RootState) => state.userData);
  const [ischangeForm, setischangeForm] = useState<boolean>(false);
  const dispatch = useAppDispatchThunk();
  const [selectedData, setSelectedData] = useState<any>(null);
  const { userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { workFlowsData } = useSelector((state: RootState) => state.workFlow);
  const {
    contractRolesAndResponsibilityData,
    isLoading,
    RolesAndResonsibilitytoasterMsg,
    isRolesAndResponsibilityAdded,
  } = useSelector((state: any) => state.contractManagementReducer?.contractRolesAndResponsibility);
  const {
    vendorConentPurposes,
    vendorConentDataTable,
    vendorConsentToasterMsg,
    versionHistory,
    isVendorConsentLoading,
    isVendorConsentAdded,
    isVendorConsentUpdated,
    isVendorConsentDeleted,
  } = useSelector((state: RootState) => state.contentData);
  const [vendorConsentData, setVendorConsentData] = useState<any>();

  const [activeWorkFlow, setActiveWorkFlow] = useState<any[]>([]);

  useEffect(() => {
    callPAgeLoadData();
  }, []);

  const callPAgeLoadData = () => {
    dispatch(
      getContentList({
        FormID: 2,
      })
    );
  };

  useEffect(() => {
    if (vendorConentDataTable) {
      let findContentMasterRow = vendorConentDataTable.filter((item: any) => {
        if (item?.Content_Purpose == ContentNameConfig.PurposeName) {
          setVendorConsentData(item);
        }
      });
    }
  }, [vendorConentDataTable]);

  useEffect(() => {
    dispatch(getWorkList());
    dispatch(getLovList({ Name: "" }));
  }, []);

  const updateSelectedWorkflow = (data: any, editData: any) => {
    if (data) {
      if (data.WorkflowProcessAsJson) {
        let parsedEditData = JSON.parse(editData.WorkflowProcessDetail);
        let parsedWorkflowProcess = JSON.parse(data.WorkflowProcessAsJson);
        const updatedDataArray = parsedWorkflowProcess?.map((item: any) => {
          const selectedEditData = parsedEditData.find((editItem: any) => editItem.Process_Id === item.ProcessId);
          if (selectedEditData) {
            return {
              ...item,
              Id: selectedEditData.Id,
              PrimaryUser: selectedEditData.PrimaryUser
                ? selectedEditData.PrimaryUser.split(",").map(Number)
                : item.PrimaryUser ?? [],
              DepartmentName: selectedEditData.DepartmentName || "",
              SecondaryUser: selectedEditData.SecondaryUser
                ? selectedEditData.SecondaryUser.split(",").map(Number)
                : item.SecondaryUser ?? [],
              EscalateTo: selectedEditData.EscalateTo
                ? selectedEditData.EscalateTo.split(",").map(Number)
                : item.EscalateTo ?? [],
              DepartmentId: selectedEditData.DepartmentName || "",
              Role: selectedEditData.RoleId || "",
            };
          }
          return item;
        });
        return {
          ...data,
          WorkflowProcessAsJson: JSON.stringify(updatedDataArray),
        };
      }
    }
    return data;
  };

  useEffect(() => {
    if (workFlowsData) {
      const configuredWorkFlows = contractRolesAndResponsibilityData.map((item: any) => item.Workflow_Id);
      let ActiveWorkflowList = workFlowsData.filter(
        (item: any) => item?.IsActive === "Active" && (props.editData || !configuredWorkFlows.includes(item.Id))
      );
      setActiveWorkFlow(ActiveWorkflowList);
      setWorkFlowData(ActiveWorkflowList);
      if (props.editData) {
        setSelectedWorkflow(props.editData.Workflow_Id);
        let data = workFlowsData.find((item) => item.Id === props.editData.Workflow_Id);
        data = updateSelectedWorkflow(data, props.editData);
        setSelectedData(data);
      }
    }
  }, [workFlowsData]);

  const Validatedepartmentuser = (selDepartments: any, selPrimaryusers: any) => {
    if (!selDepartments || !selPrimaryusers) return false;

    try {
      return selDepartments.some((dep_item: any) => {
        return selPrimaryusers.every((user_item: any) => {
          const userData = allUsers.find((user: any) => user.UserId === user_item);
          return userData?.DepartmentId !== dep_item;
        });
      });
    } catch {
      return true;
    }
  };

  const validateField = (index: number, field: string, value: any) => {
    let errors: { [key: string]: string } = { ...validationErrors };

    switch (field) {
      case "RoleId":
        if (!value) {
          errors[`${index}-RoleId`] = "Role is required";
        } else {
          delete errors[`${index}-RoleId`];
        }
        break;

      case "TAT":
        if (!value || isNaN(value)) {
          errors[`${index}-TAT`] = "Estimated Time is required";
        } else {
          if (value && value <= 0) {
            errors[`${index}-TAT`] = "Please enter valid Estimated Time";
          } else if (value && value > 90) {
            errors[`${index}-TAT`] = "Please enter below 90 days";
          } else {
            delete errors[`${index}-TAT`];
          }
        }
        break;
      case "PrimaryUser":
        if (!value || value?.length === 0) {
          errors[`${index}-PrimaryUser`] = "At least one primary user is required";
        } else {
          const CheckWorkflowProcess = [...workflowProcess];
          const department = CheckWorkflowProcess[index]?.DepartmentName || [];
          if (value && Validatedepartmentuser(department, value)) {
            errors[`${index}-PrimaryUser`] = "At least one primary user in selected department";
          } else {
            delete errors[`${index}-PrimaryUser`];
          }
        }
        break;
      default:
        break;
    }

    setValidationErrors(errors);
    return errors;
  };

  const handleInputChange = (index: number, field: string, value: any) => {
    const newWorkflowProcess = [...workflowProcess];
    newWorkflowProcess[index][field] = value;
    if (field === "DepartmentName") {
      newWorkflowProcess[index].PrimaryUser = [];
      newWorkflowProcess[index].SecondaryUser = [];
      newWorkflowProcess[index].EscalateTo = [];
    }
    if (field === "RoleId") {
      newWorkflowProcess[index].PrimaryUser = [];
      newWorkflowProcess[index].SecondaryUser = [];
      newWorkflowProcess[index].EscalateTo = [];
    }
    if (field === "PrimaryUser") {
      let SecoundaryUserData = newWorkflowProcess[index].SecondaryUser;
      if (SecoundaryUserData && SecoundaryUserData?.length > 0) {
        const filteredSecondaryUsers = SecoundaryUserData.filter((user: any) => !value.includes(user));
        newWorkflowProcess[index].SecondaryUser = filteredSecondaryUsers;
      }
    }
    if (field === "PrimaryUser" || field === "SecondaryUser") {
      const escalateToSet = new Set();

      const usersList = [...(newWorkflowProcess[index].SecondaryUser || []), ...newWorkflowProcess[index].PrimaryUser];

      usersList.forEach((userId: any) => {
        const managerId = allUsers.find((user: any) => user.UserId === userId)?.ManagerId;
        if (managerId) {
          escalateToSet.add(managerId);
        }
      });

      newWorkflowProcess[index].EscalateTo = Array.from(escalateToSet);
    }

    // setValue(
    //   "Tat",
    //   newWorkflowProcess.reduce((n, { TAT, IsActive }) => (IsActive ? n + Number(TAT) : n), 0)
    // );
    setischangeForm(true);
    setWorkflowProcess(newWorkflowProcess);
    validateField(index, field, value);
  };

  useEffect(() => {
    if (selectedData) {
      if (selectedData.WorkflowProcessInstance) {
        let parsedWorkflowProcess = JSON.parse(selectedData.WorkflowProcessInstance);
        if (parsedWorkflowProcess) {
          const updatedDataArray = parsedWorkflowProcess?.map((item: any) => ({
            ...item,
            ProcessName: item?.Process_Name,
            PrimaryUser: item.Primary_User ? item.Primary_User.split(",").map(Number) : [],
            SecondaryUser: item.Secondary_User ? item.Secondary_User.split(",").map(Number) : [],
            EscalateTo: item.Escalate_To ? item.Escalate_To.split(",").map(Number) : [],
            DepartmentName: item.Department ? item.Department.split(",").map(Number) : [],
            RoleId: item.Role ? item.Role : "",
          }));
          setWorkflowProcess(updatedDataArray);
        }
      } else {
        try {
          let parsedWorkflowProcess = JSON.parse(selectedData.WorkflowProcessAsJson);
          setWorkflowSteps(parsedWorkflowProcess);
        } catch (error) {
          console.error("Error parsing WorkflowProcessAsJson:", error);
        }
      }
    }
  }, [selectedData]);

  useEffect(() => {
    dispatch(updateLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (isRolesAndResponsibilityAdded) {
      props.onHide();
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: RolesAndResonsibilitytoasterMsg.Message,
          isTosterFailed: RolesAndResonsibilitytoasterMsg.Status !== "Success",
        })
      );
      if (RolesAndResonsibilitytoasterMsg.Status !== "Success") {
        dispatch(updaterolesandresponsAddandEdit(false));
      } else {
        dispatch(getRolesAndResponsibilityList(""));
        dispatch(updaterolesandresponsAddandEdit(false));
      }
    }
  }, [isRolesAndResponsibilityAdded]);

  const setWorkflowSteps = (Allsteps: any) => {
    try {
      let parsedWorkflowProcess = Allsteps;
      if (Array.isArray(parsedWorkflowProcess)) {
        parsedWorkflowProcess = parsedWorkflowProcess.reduce((acc: any, item: any) => {
          if (item.IsActive)
            acc.push({
              ...item,
              Process_Name: item?.ProcessName,
              DepartmentName: item?.DepartmentId
                ? item?.DepartmentId?.split(",").map((ditem: any) => parseInt(ditem, 10))
                : [],
              Role: item?.RoleId,
              PrimaryUser: props.editData ? item?.PrimaryUser : [],
              SecondaryUser: props.editData ? item?.SecondaryUser : [],
              EscalateTo: props.editData ? item?.EscalateTo : [],
              page: "RR Setup",
            });
          return acc;
        }, []);
      }
      setWorkflowProcess(parsedWorkflowProcess);
    } catch (error) {
      console.error("Error parsing WorkflowProcessAsJson:", error);
    }
  };

  const validateAllFields = () => {
    let isValid = true;
    let allErrors: { [key: string]: string } = {};

    workflowProcess.forEach((item, index) => {
      const roleErrors = validateField(index, "RoleId", item.RoleId);
      const departmentErrors = validateField(index, "DepartmentName", item.DepartmentName);
      const tatErrors = validateField(index, "TAT", item.TAT);
      const primaryUserErrors = validateField(index, "PrimaryUser", item.PrimaryUser);
      // const secondaryUserErrors = validateField(index, "SecondaryUser", item.SecondaryUser);
      // const escalateToErrors = validateField(index, "EscalateTo", item.EscalateTo);

      allErrors = {
        ...allErrors,
        ...roleErrors,
        ...departmentErrors,
        ...tatErrors,
        ...primaryUserErrors,
      };
    });

    setValidationErrors(allErrors);

    if (Object.keys(allErrors).length > 0 || workflowProcess?.length === 0) {
      isValid = false;
    }

    return isValid;
  };

  const handleSave = () => {
    const payload = {
      Workflow_Id: selectedData.Id,
      userId: userLoginResponse?.UserId,
      moduleName: "Roles and Responsibility",
      activity: "Create",
      workflowProcess: workflowProcess.map((item) => ({
        Id: props.editData ? item.Id : 0,
        Workflow_Id: item.WorkflowId,
        Process_SeqNo: item.ProcessSeqno,
        Process_Name: item.ProcessName,
        Process_Type: item.ProcessType,
        Process_Id: item.ProcessId,
        Process_NextStep: item.Process_NextStep,
        Condition_Tobe_Checked: item.ConditionTobeChecked ?? "",
        Condition_Name: item.ConditionName ?? "",
        IfYes_Goto: item.IfYes_GoTo ?? 0,
        IfNo_Goto: item.IfNo_GoTo ?? 0,
        Branching: item.Branching ?? 0,
        IsActive: item.IsActive,
        Updated_By: item.Updated_By,
        TAT: item.TAT,
        Role: item.RoleId,
        DepartmentId: item.DepartmentId,
        DepartmentName: item.DepartmentName.join(","),
        PrimaryUser: item.PrimaryUser.join(","),
        SecondaryUser: item.SecondaryUser.join(","),
        EscalateTo: item.EscalateTo.join(","),
        Exit_Status: item.Exit_Status,
      })),
    };
    dispatch(addRolesAndResponsibility(payload));
  };

  const handleSubmit = () => {
    const validFields = validateAllFields();
    if (validFields) {
      handleSave();
    }
  };

  const toolTipData = () => {
    return (
      <Box sx={{ maxHeight: 200, overflowY: "auto", width: "100%" }}>
        <Table sx={{ width: "100%" }}>
          <TableBody sx={{ borderTop: "0px" }}>
            {vendorConsentData && (
              <TableRow>
                <TableCell
                  sx={{
                    border: "2px solid #f1f1f1",
                    // borderTop: index === 0 ? "0px" : "2px solid #f1f1f1",
                    width: "28.3%",
                    p: 1,
                    py: 0.5,
                    textAlign: "left",
                  }}
                >
                  {
                    // Split by <li> tags and render each item on a new line
                    vendorConsentData.Content_Text.replace(/<\/?ul>/g, "") // Remove <ul> tags
                      .split(/<\/?li>/)
                      .filter((item: any) => item.trim()) // Remove empty items
                      .map((item: any, index: any) => (
                        <div key={index}>{item.trim()}</div> // Display each item in a new line
                      ))
                  }
                </TableCell>
                {/* <TableCell
                  sx={{
                    border: "2px solid #f1f1f1",
                    borderTop: index === 0 ? "0px" : "2px solid #f1f1f1",
                    width: "28.4%",
                    p: 1,
                    py: 0.5,
                    textAlign: "left",
                  }}
                >
                  {scoring.Range_To}
                </TableCell>
                <TableCell
                  sx={{
                    border: "2px solid #f1f1f1",
                    borderTop: index === 0 ? "0px" : "2px solid #f1f1f1",
                    p: 1,
                    py: 0.5,
                    textAlign: "left",
                  }}
                >
                  {scoring.Rating}
                </TableCell> */}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    );
  };

  return (
    <>
      <Box style={{ width: "50%" }}>
        <CustomSelect
          name={"WorkflowName"}
          valueKey={"Id"}
          optionLabelKey="WorkflowName"
          options={activeWorkFlow}
          value={selectedWorkflow}
          onChange={(val) => {
            setSelectedWorkflow(val);
            setSelectedData(workFlowsData.find((item) => item.Id === val));
          }}
          required={true}
          label={"WorkFlow"}
          disabled={props.view || props.editData}
        />
      </Box>
      <ProcessDetailContext.Provider value={{ workflowProcess, validationErrors, handleInputChange }}>
        <ProcessDetails view={props.view || props.editData?.IsActive === "Inactive"} />
      </ProcessDetailContext.Provider>

      <Box
        sx={{
          mt: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box>Notes</Box>
          <Box sx={{ marginTop: "-4px" }}>
            <CommonTooltip arrow={true} placement={"top-start"} content={toolTipData()} width={"400px"} />
          </Box>
        </Box>
      </Box>

      <Stack direction="row" spacing={2} justifyContent="flex-end" mt={1}>
        <CustomButton
          variant="outlined"
          name={"Cancel"}
          // disabled={props.isLoading}
          onClick={(e: any) => {
            props.onHide();
          }}
        />
        <CustomButton
          variant="contained"
          disabled={!selectedWorkflow || props.view || props.editData?.IsActive === "Inactive"}
          name={"Save"}
          onClick={() => {
            handleSubmit();
          }}
        />
      </Stack>
    </>
  );
};

export default RandRSetup;
