import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IMenu } from "../../../models/loggin.type";
import { RootState, useAppDispatch } from "../../../store/store";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { AddHolidayMaster } from "./AddHolidayMaster";
import { deleteHoliday, getHolidayHistory, getHolidayList, getHolidayWithdaterange, updateHolidayAddandEdit, updateVersionHistory } from "../../../store/slices/holidayMasterData";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import masterEditEnableService from "../../../store/services/masterEditEnableService";
import { Edit_Enable_tosterMSG, FormIdConfig } from "../../../config/config";
import { FormTitleMasterDataReducer, getFormTitleList } from "../../../store/slices/formTitleMasterData";
import { HandleRecordLockCheck, RecordLockUpdate } from "../../recordLock/RecordLock";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";
import ScopeApprovel from "../../performanceManagement/ScopeApprovel/ScopeApprovel";


export default function HolidayMaster() {
    const dispatch = useAppDispatch();
    const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
    const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
    const [addForm, setAddForm] = useState(false);
    const [Option, setOption] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [IsEditData, setIsEditData] = useState<any[]>([]);
    const [HolidayTableData, setHolidayTableData] = useState<any[]>([]);
    const [isVersionHistory, setIsVersionHistory] = useState(false);
    const [StartDate, setStartDate] = useState(null);
    const [EndDate, setEndDate] = useState(null);
    const [versionHistoryData, setVersionHistoryData] = useState<any>();
    const [isDeleteData, setIsDeleteData] = useState<any>(null);
    const [OpenModalServerFilter, setOpenModalServerFilter] = React.useState(false);
    const { currentpageTitleData } = useSelector(
        (state: RootState) => state.FormTitleMasterData
    );

    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );

    const { HolidayTable, HolidayBydateRangeTable, isHolidayAdded, isHolidayUpdated, versionHistory, isHolidayDeleted, ToasterMsg } = useSelector(
        (state: RootState) => state.HolidayMasterData
    );
    useEffect(()=>{
        console.log(StartDate,EndDate,"se");
        // (StartDate && EndDate ) & 
    },[StartDate,EndDate])

    
  const { handleRecordLockUpdate } = RecordLockRelease();

    const columns_version: any = {
        table_name: "Holiday History Records",
        isAction: false,
        columns: [],
    };


    const columns: any = {
        table_name: "Holiday",
        isAction: true,
        columns: [

            {
                Field: "Holiday_Date",
                DisplayName: "Date",
                FieldType: "Date",
            },

            {
                Field: "Holiday_Type_National",
                DisplayName: "Holiday Type",
                FieldType: "string",
            },
            {
                Field: "CountryName",
                DisplayName: "Country",
                FieldType: "string",
            },
            {
                Field: "Holiday_Description",
                DisplayName: "Description",
                FieldType: "string",
            },
            {
                Field: "StateValues",
                DisplayName: "State",
                FieldType: "string",
            },
            {
                Field: "IsActive",
                DisplayName: "Status",
                FieldType: "string",
            },
            {
                Field: "Created_By",
                DisplayName: "Modified By",
                FieldType: "string",
            },
        ],
    };

    useEffect(() => {
        dispatch(
            getHolidayList({
                FormID: 2,
            })
        );

    }, []);

    useEffect(() => {
        if (HolidayTable) {
            setHolidayTableData(HolidayTable)
        }
        if (HolidayBydateRangeTable?.length > 0) {
            setHolidayTableData(HolidayBydateRangeTable)
        }

    }, [HolidayTable, HolidayBydateRangeTable]);

    useEffect(() => {
        if (currentPagePermissionData !== null) {
            setPermissionForpage(currentPagePermissionData);

        }
    }, [currentPagePermissionData]);


    useEffect(() => {
        if (versionHistory.HolidayVHeader.length > 0 && versionHistory.HolidayVHeader.length > 0) {
            if (versionHistory.HolidayVHeader && versionHistory.HolidayVHeader.length > 0) {
                setIsVersionHistory(true);
                let re_columns = {
                    ...columns_version,
                    columns: versionHistory.HolidayVHeader,
                };
                setVersionHistoryData(re_columns);
            }
        }
    }, [versionHistory]);

    const handleOpen = async (data: any, Option: string) => {

        if (Option === "edit") {
            setOption(Option);
            setIsEditData(data);
            setIsEdit(true);

        } else if (Option === "view") {
            setOption(Option);
            setIsEditData(data);
            setIsEdit(true);
        } else if (Option === "history") {
            setOption(Option);
            dispatch(
                getHolidayHistory({
                    History_Id: data.Holiday_Id,
                    FormId: FormIdConfig?.HolidayFormId,
                })
            );
        } else if (Option === "delete") {

            setOption(Option);
            setIsDeleteData(data);

        }
    };


    useEffect(() => {
        if (isHolidayAdded) {
            dispatch(updateHolidayAddandEdit(false));
            dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
            dispatch(
                getHolidayList({
                    FormID: 2,
                })
            );
        }
        else if (isHolidayUpdated) {
            dispatch(updateHolidayAddandEdit(false));
            handleRecordLockUpdate();
            dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));


            dispatch(
                getHolidayList({
                    FormID: 2,
                })
            );
        }
        else if (isHolidayDeleted) {
            dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
            dispatch(updateHolidayAddandEdit(false));

            dispatch(
                getHolidayList({
                    FormID: 2,
                })
            );
            setIsDeleteData(null);
        }
    }, [isHolidayAdded, isHolidayUpdated, isHolidayDeleted]);



    const deleteHolidayList = () => {

        let JsonDataForDisplay: any = {
            "Date": isDeleteData.Holiday_Date,
            "Holiday Description": isDeleteData.Holiday_Description,
            "Holiday Type": isDeleteData.Holiday_Type_National ? "yes" : "No",
            "Country": isDeleteData.CountryName,
            "State": isDeleteData.StateValues,
            Status: isDeleteData.IsActive === "Active" ? "inactive" : "active",
            "Modified By": user?.UserName,
        }

        let formData: any = {};
        formData.ModuleName = currentPagePermissionData?.MenuName;
        formData.Activity = isDeleteData?.IsActive === "Active" ? "inactive" : "active";
        formData.UserId = userLoginResponse?.UserId;
        formData.Remarks = "";
        formData.CreatedBy = userLoginResponse?.UserName;
        formData.MenuId = currentPagePermissionData?.MenuId;
        formData.Form_Id = 41;
        formData.Holiday_Id = isDeleteData?.Holiday_Id;
        formData.Holiday_Description = isDeleteData?.Holiday_Description;
        formData.IsActive = isDeleteData?.IsActive === "Active" ? false : true;

        formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);


        dispatch(
            deleteHoliday((formData))
        );
    };

    const transformData = (data: any) => {
        return data.map((holidayitem: any) => {
            const StateArray = holidayitem?.State_Id ? holidayitem?.State_Id : [];
            const StateDatas = StateArray.map((item: any) => item?.StateName).join(",");
            return {
                ...holidayitem,
                StateValues: StateDatas,
            };
        });
    };

    const handleStartDateChange = (e: any) => {
        setStartDate(e);
        if (HolidayTable && !e) {
            setHolidayTableData(HolidayTable)
        }
    };

    const handleEndDateChange = (e: any) => {
        setEndDate(e);
        if (HolidayTable && !e) {
            setHolidayTableData(HolidayTable)
        }
    };

    const handleServerFilter = () => {
        if (!StartDate && !EndDate) {
            setOpenModalServerFilter(true);
        } else {
            dispatch(
                getHolidayWithdaterange({
                    startDate: StartDate,
                    endDate: EndDate,
                })
            );
        }

    };

    return (
        <>
            <Box
                sx={{
                    width: "100%",

                }}
            >
                <Stack spacing={2}>
                    <Box
                        sx={{
                            display: "flex",
                            // flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                            {/* Holiday Master */}
                        </Typography>

                        <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Grid
                            sx={{
                                display: "flex",
                                gap: "20px",
                                justifyContent: "left",
                                alignItems: "center",
                            }}
                        >
                            <Box sx={{ display: "flex", width: "250px" }}>

                                <CustomDatePicker
                                    minDate={dayjs().subtract(100, "year").toDate()}
                                    maxDate={dayjs().add(100, "year").toDate()}
                                    required={true}
                                    label={"Start Date"}
                                    value={dayjs(StartDate)}
                                    readOnly={false}
                                    onChange={handleStartDateChange}
                                    error={false}
                                    helperText={""}
                                />
                            </Box>

                            <Box sx={{ display: "flex", width: "250px" }}>

                                <CustomDatePicker
                                    minDate={dayjs(StartDate).toDate()}
                                    maxDate={dayjs().add(100, "year").toDate()}
                                    required={true}
                                    label={"End Date"}
                                    value={dayjs(EndDate)}
                                    readOnly={false}
                                    onChange={handleEndDateChange}
                                    error={false}
                                    helperText={""}
                                />
                            </Box>
                            <Box sx={{ display: "flex" }}>
                                <CustomButton variant="contained" name={"Submit"} onClick={() => handleServerFilter()} size="small" />
                            </Box>
                        </Grid>
                    </Box>

                    <Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <MaterialDynamicGrid
                                data={columns}
                                rows={transformData(HolidayTableData)}
                                handleAction={handleOpen}
                                enableZoomIcon={true}
                                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                                handleAdd={() => setAddForm(true)}
                                showAdd={true}
                                showhistory={true}
                                showDelete={permssionPage?.IsDelete === 1}
                                showView={permssionPage?.IsView === 1}
                                showEdit={permssionPage?.IsEdit === 1}
                                showExports={permssionPage?.IsExport === 1}
                                pageName="Holiday"
                                isEnableRecordLock={{
                                    IdField: "Holiday_Id", data: {
                                        moduleName: currentPagePermissionData?.MenuName || "",
                                        FormID: FormIdConfig?.HolidayFormId,

                                        MenuID: currentPagePermissionData?.MenuId,
                                        IsCheckPending: true
                                    }
                                }}
                                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                            />
                        </Box>
                    </Box>
                </Stack>
            </Box>

            <CustomDialog
                show={addForm}
                onHide={() => setAddForm(false)}
                maxWidth={"xs"}
                minHeight={"50vh"}
                header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Holiday"}`}
                contentNode={
                    //   <></>
                    <AddHolidayMaster
                        view={false}
                        closePopUp={() => {
                            setAddForm(false);
                        }}
                    />
                }
            />

            <CustomDialog
                show={isEdit}
                onHide={() => {
                    setIsEdit(false);
                    handleRecordLockUpdate();
                }}
                maxWidth={"xs"}
                minHeight={"50vh"}
                header={Option === "view" ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Holiday"}` : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Holiday"}`}
                contentNode={
                    <>
                        {Option === "edit" && (
                            <AddHolidayMaster
                                view={false}
                                closePopUp={() => {
                                    setIsEdit(false);
                                    handleRecordLockUpdate();
                                }}
                                edit={isEdit}
                                isEditData={IsEditData}
                            />
                            //   <></>
                        )}
                        {Option === "view" && (
                            <AddHolidayMaster
                                view={true}
                                closePopUp={() => {
                                    setIsEdit(false);
                                }}
                                isEditData={IsEditData}
                            />
                            //   <></>
                        )}
                    </>
                }
            />

            <CustomDialog
                show={isVersionHistory}
                onHide={() => {
                    setIsVersionHistory(false);
                    dispatch(updateVersionHistory([]));
                }}
                maxWidth={"lg"}
                header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Holiday"} Version History`}
                contentNode={
                    <>
                        {" "}
                        <MaterialDynamicGrid
                            data={versionHistoryData}
                            rows={transformData(versionHistory.HolidayRecords)}
                            handleAction={handleOpen}
                            enableZoomIcon={false}
                            showhistory={false}
                            showDelete={permssionPage?.IsDelete === 1}
                            showView={permssionPage?.IsView === 1}
                            showEdit={permssionPage?.IsEdit === 1}
                            showExports={permssionPage?.IsExport === 1}
                            pageName="Holiday History Records"
                            userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                        />
                    </>
                }
            />
            <ModalPopUp
                onDeleteMethod={() => deleteHolidayList()}
                isDelete={true}
                onHide={() => setIsDeleteData(null)}
                show={isDeleteData ? true : false}
                header={"Confirm"}
                text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
                    } the selected Holiday?`}
                ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
            />

            <ModalPopUp
                header="Holiday"
                text="Please Select Start Date and End date "
                onHide={() => {
                    setOpenModalServerFilter(false);
                }}
                show={OpenModalServerFilter}
            />
            {/* <ScopeApprovel/> */}
        </>
    );
}
