import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IApiCatalogResponse } from "../../models/apiCatalog.type";
import { API_Catalog } from "./endpoints/apiCatalog";


const getAllApiCatalog = (): Promise<IApiCatalogResponse> =>
  axiosInstance
    .get(API_Catalog.GetAllApiCatalog)
    .then((response: AxiosResponse<IApiCatalogResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateApiCatalog = (requestBody: any): Promise<IApiCatalogResponse> =>
  axiosInstance
    .post(API_Catalog.SaveEditApiCatalog, requestBody)
    .then((response: AxiosResponse<IApiCatalogResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const saveApiCatalog = (requestBody: any): Promise<IApiCatalogResponse> =>
  axiosInstance
    .post(API_Catalog.SaveEditApiCatalog, requestBody)
    .then((response: AxiosResponse<IApiCatalogResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
    const GetApiCatalogWithAllDetailsByID = (requestBody: any): Promise<IApiCatalogResponse> =>
      axiosInstance
        .get(API_Catalog.GetApiCatalogWithAllDetailsByID,  { params: requestBody })
        .then((response: AxiosResponse<IApiCatalogResponse>) => {
          if (response.status === 200) {
            return response.data;
          }
          throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
          throw error;
        });

const RemoveApiCatalog = (requestBody: any): Promise<IApiCatalogResponse> =>
  axiosInstance
    .post(API_Catalog.RemoveApiCatalog, requestBody)
    .then((response: AxiosResponse<IApiCatalogResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const ApiCatalogService = {
    getAllApiCatalog,
    saveApiCatalog,
    updateApiCatalog,
    GetApiCatalogWithAllDetailsByID,
    RemoveApiCatalog,
};

export default ApiCatalogService;
