import { Box, Grid, Typography, Divider, Stack } from "@mui/material";
import { ScopeDetails } from "../../PMCommonDetails/ScopeDetails";
import { SeverityOfTargetDetails } from "../../PMCommonDetails/SeverityTargetDetails";
import { MonitoringPeriodDetails } from "../../PMCommonDetails/MonitoringPeriodDetails";
import { CommonSLATable } from "../../PMCommonDetails/CommonSLATable";
import { CustomBasicAccordion } from "../../../../common/dynamicAccordion/CustomBasicAccordion";
import { useState } from "react";

import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import { PM_MeasuredIn } from "../../../../config/config";

interface RequirementDetailsprops {
    data: any;
    currentIndex: any;
    RequirementDataLength?: any;
     
}

export const RequirementDetails: React.FC<RequirementDetailsprops> = ({ data, currentIndex, RequirementDataLength }) => {
    const theme = useTheme();
    const [expanded, setExpanded] = useState<any>({ 0: true, });

    const SLAColumns = [
        { Field: "sno", DisplayName: "S.No" },
        { Field: "sla", DisplayName: "SLA" },
        { Field: "measured_In_Name", DisplayName: "Measured In" },
        { Field: "severity_Of_SLA_Name", DisplayName: "Severity" },
        { Field: "target_Min_value", DisplayName: "Target Min" },
        { Field: "target_Max_value", DisplayName: "Target Max" },
        { Field: "tolerance_Level", DisplayName: "Talerence Level" },
        { Field: "remark", DisplayName: "Remark" },
        { Field: "bestScore", DisplayName: "Best Score" },

    ];

      const transformData = (data: any) => {
          return data.map((parameteritem: any , index: any) => {
            return {
              ...parameteritem,
              sno : index + 1 ,
              target_Max_value : parameteritem?.measured_In_Name == PM_MeasuredIn.Date  ? dayjs(parameteritem?.target_Max).format("DD/MM/YYYY") : parameteritem?.target_Max ,
              target_Min_value  : parameteritem?.measured_In_Name == PM_MeasuredIn.Date  ? dayjs(parameteritem?.target_Min).format("DD/MM/YYYY") : parameteritem?.target_Min
            };
          });
        };

    const handleAccordionChange = (index: number) => {
        setExpanded((prevExpanded: any[]) => ({ ...prevExpanded, [index]: !prevExpanded[index] }));
    };

    console.log(data, "data");


    return (

        <>
            <Box
                sx={{
                    display: "flex", justifyContent: "start"
                }}>
                
                  <Typography
                              sx={{
                                marginBottom : "15px" ,
                                height: "12px",
                                color: theme.palette.primary.main,
                                display: "block",
                                fontSize: "15px",
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {`REQUIREMENT ${currentIndex + 1} / ${RequirementDataLength} : CLAUSE REFERENCE : ${data?.clause_reference_no || "-"} | CLAUSE HEADER : ${data?.clause_Header || "-"}`}
                            </Typography>
            </Box>

            {data?.pmprClauseTarget?.map((item: any, index: number) => (
                <CustomBasicAccordion
                    key={item?.pR_Clause_Target_Id || index} // Ensure each accordion has a unique key
                    expanded={expanded[index]}
                    handleChange={() => handleAccordionChange(index)}
                    title={`Target Details - ${index + 1}`}
                    details={
                        <>
                            <Stack spacing={2}>
                                <ScopeDetails data={item?.pmprctScope} />

                                <Divider sx={{ border: '1px solid', color: "#e0e0e3" }} />

                                <SeverityOfTargetDetails data={item?.pmprctScope?.severityof_Target} />

                                <Divider sx={{ border: '1px solid', color: "#e0e0e3" }} />

                                <MonitoringPeriodDetails data={item?.pmprctMonitorPeriods} />

                                <Divider sx={{ border: '1px solid', color: "#e0e0e3" }} />

                                <CommonSLATable HeaderTitle={"SLA To Monitor"} TableColumns={SLAColumns} Tabledata={transformData(item?.pmprctsla)} />
                            </Stack>
                        </>
                    }
                />
            ))}

        </>
    )

}