import { Box, Grid, IconButton, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react"
import CustomTextField from "../../../../common/dynamicInputs/CustomTextField";
import { Controller } from "react-hook-form";
import CustomAutoComplete from "../../../../common/dynamicInputs/CustomAutoComplete";
import CustomSelect from "../../../../common/dynamicInputs/CustomSelect";
import CustomToogleSwitch from "../../../../common/dynamicInputs/CustomToogleSwitch";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { ApplicationParameterName } from "../../../../config/config";
import vendorDetailsService from "../../../../store/services/vendorDetails";


export const ConstitutionDetails: React.FC<any> = ({ isEditData, edit, view, control, errorsVendorDetailsform, watch }) => {
    const [RegionalData, setRegionalData] = useState<any[]>([]);
    const [regulatorData, setRegulatorData] = useState<any[]>([]);
    const [jurisdiction, setJurisdiction] = useState<any[]>([]);
    const [companyConstitutionlov, setCompanyConstitutionlov] = useState<any[]>([]);
    const [isMasked, setIsMasked] = useState(true);
    const [isTANMasked, setIsTANMasked] = useState(true);
    const [isCINMasked, setIsCINMasked] = useState(true);
    const [PAN_Masking_data, setPAN_Masking_data] = useState(false);
    const [TAN_Masking_data, setTAN_Masking_data] = useState(false);
    const [CIN_Masking_data, setCIN_Masking_data] = useState(false);
    const [WarningPAN, setWarningPAN] = useState("");


    const { RegionalTable } = useSelector((state: RootState) => state.RegionalSettingsData);
    const { locationTable } = useSelector((state: RootState) => state.locationMasterData);
    const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
    const { thirdPartyData } = useSelector((state: RootState) => state.thirdPartyData);
    const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);

    const IsRegulatedwatch = watch("IsRegulated");

    useEffect(() => {
        const filteredRegulatorData = thirdPartyData.filter((item: any) => item.IsActive === "Active" && item.ThirdPartyTypeValue === "Regulator");
        setRegulatorData(filteredRegulatorData);
    }, [thirdPartyData]);

    useEffect(() => {
        const filteredRegionalData = RegionalTable.filter((item: any) => item.LocationName === "India");
        setRegionalData(filteredRegionalData.filter((item: any) => item.IsActive === "Active"));
    }, [RegionalTable]);

    useEffect(() => {
        if (locationTable.length > 0) {
            setJurisdiction(locationTable.filter((item: any) => item.LocationTypeValue === "City" && item.IsActive === "Active" ) || []);
        }
    }, [locationTable])

    useEffect(() => {
        setCompanyConstitutionlov(lovTable.filter((item: any) => item.Name === "Company Constitution") || []);
    }, [lovTable]);

    useEffect(() => {
        if (applicationParameterData.length > 0) {
            let Masking_data: any = applicationParameterData?.filter(
                (item) => item.Parameter_Group === ApplicationParameterName.Masking && item.Parameter_Value === "Yes" && item.IsActive === "Active");
            setPAN_Masking_data(Masking_data?.find((item: any) => item.Parameter_Group === ApplicationParameterName.Masking && item.Parameter_Name === ApplicationParameterName.Masking_PAN_Name) ? true : false);
            setTAN_Masking_data(Masking_data?.find((item: any) => item.Parameter_Group === ApplicationParameterName.Masking && item.Parameter_Name === ApplicationParameterName.Masking_TAN_Name) ? true : false);
            setCIN_Masking_data(Masking_data?.find((item: any) => item.Parameter_Group === ApplicationParameterName.Masking && item.Parameter_Name === ApplicationParameterName.Masking_CIN_Name) ? true : false);
        }
    }, [applicationParameterData]);

    const convertString = (str: any) => {
        return str
            .split("")
            .map((char: any) => {
                if (!isNaN(char) && char !== " ") {
                    return "9";
                } else {
                    return "X";
                }
            })
            .join("");
    };

    const generateRegexFromFormats = (formats: any) => {
        return formats.map((format: any) => {
            const formatData = convertString(format);
            const escapedFormat = formatData.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
            const regexPattern = escapedFormat.replace(/X/g, "[A-Z]").replace(/9/g, "\\d");
            const regex = new RegExp(`^${regexPattern}$`);
            return regex;
        });
    }
    const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
        event.preventDefault();
    };
    return (
        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
            <Grid container spacing={2}>

                <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                >
                    <Controller
                        name="PAN"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "PAN is required",
                            validate: {
                                noLeadingSpaces: (value) => {
                                    if (view) return;
                                    return !/^\s/.test(value) || "Leading spaces are not allowed";
                                },
                                maxLength: (value) => {
                                    if (view) return;
                                    return value.length < 50 || "Maximum 50 characters allowed";
                                },
                                ValueLimitation: (value) => {
                                    if (view) return;
                                    return /^[a-zA-Z0-9,.\- ]+$/.test(value) || "Cannot contain special characters";
                                },
                                RegrexValidation: (value) => {
                                    const panFormat = RegionalData.find((data) => data.Equivalent === "PAN")?.Format;
                                    if (view) return;
                                    return panFormat
                                        ? new RegExp(generateRegexFromFormats([panFormat]).pop()).test(value) ||
                                        `Invalid PAN format. Example: ${panFormat}`
                                        : true;
                                },
                            },
                        }}
                        render={({ field }) => (
                            <CustomTextField
                                name={"PAN"}
                                key={'vendor_Pan'}
                                required={true}
                                readOnly={view}
                                label={"PAN"}
                                type={PAN_Masking_data ? (isMasked ? "text" : "password") : "text"}
                                value={field.value}
                                
                                // value={isMasked ? field.value.replace(/./g, '*') : field.value}
                                onChange={field.onChange}
                                inputProps={PAN_Masking_data ? {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { setIsMasked(!isMasked); }}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                disabled={view}
                                            >
                                                {isMasked ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                } : {}}
                                onBlur={async (e) => {
                                    if (e.target.value) {
                                      const res = await vendorDetailsService.getIsFieldsExists({
                                        Vendor_Id: isEditData? isEditData?.VendorDetails?.Vendor_Id : 0,
                                        AttType: "PAN",
                                        AttValue: e.target.value,
                                      });
                                      if (res.data) setWarningPAN(res?.data?.Message);
                                      else setWarningPAN("");
                                    } else setWarningPAN("");
                                  }}
                                error={Boolean(errorsVendorDetailsform.PAN || WarningPAN)}
                                helperText={
                                    errorsVendorDetailsform.PAN?.message?.toString() || WarningPAN
                                }
                            />
                        )}
                    />
                </Grid>

                <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                >
                    <Controller
                        name="TAN"
                        control={control}
                        defaultValue={''}
                        rules={{
                            // required: "TAN is required",
                            validate: {
                                noLeadingSpaces: (value) => {
                                    if (!value || view) return;
                                    return !/^\s/.test(value) || "Leading spaces are not allowed";
                                },
                                maxLength: (value) => {
                                    if (!value || view) return;
                                    return value.length < 50 || "Maximum 50 characters allowed";
                                },
                                ValueLimitation: (value) => {
                                    if (!value || view) return;
                                    return /^[a-zA-Z0-9,.\- ]+$/.test(value) ||
                                        "Cannot contain special characters and spaces";
                                },
                                RegExValidation: (value) => {
                                    const tanFormat = RegionalData.find((data) => data.Equivalent === "TAN")?.Format;
                                    if (!value || view) return;
                                    return tanFormat
                                        ? new RegExp(generateRegexFromFormats([tanFormat]).pop()).test(value) ||
                                        `Invalid TAN format example:${tanFormat}`
                                        : true;
                                },
                            },
                        }}
                        render={({ field }) => (
                            <CustomTextField
                                name={"TAN"}
                                // required={true}
                                readOnly={view}
                                label={"TAN"}
                                type={TAN_Masking_data ? (isTANMasked ? "text" : "password") : "text"}
                                value={field.value}
                                onChange={field.onChange}
                                inputProps={TAN_Masking_data ? {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { setIsTANMasked(!isTANMasked); }}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                disabled={view}
                                            >
                                                {isTANMasked ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                } : {}}
                                error={Boolean(errorsVendorDetailsform.TAN)}
                                helperText={errorsVendorDetailsform.TAN?.message?.toString()}
                            />
                        )}
                    />
                </Grid>

                <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                >
                    <Controller
                        name="CIN"
                        control={control}
                        defaultValue={''}
                        rules={{
                            // required: "CIN is required",
                            validate: {
                                noLeadingSpaces: (value) => {
                                    if (!value || view) return;
                                    return !/^\s/.test(value) || "Leading spaces are not allowed";
                                },
                                maxLength: (value) => {
                                    if (!value || view) return;
                                    return value.length < 50 || "Maximum 50 characters allowed";
                                },
                                ValueLimitation: (value) => {
                                    if (!value || view) return;
                                    return /^[a-zA-Z0-9,.\- ]+$/.test(value) ||
                                        "Cannot contain special characters and spaces";
                                },
                                RegExValidation: (value) => {
                                    const cinFormat = RegionalData.find((data) => data.Equivalent === "CIN")?.Format;
                                    if (!value || view) return;
                                    return cinFormat
                                        ? new RegExp(generateRegexFromFormats([cinFormat]).pop()).test(value) ||
                                        `Invalid CIN format. example: ${cinFormat}`
                                        : true;
                                },
                            },
                        }}

                render={({ field }) => (
                //             <CustomTextField
                //                 name={"CIN"}
                //                 // required={true}
                //                 readOnly={view}
                //                 label={"CIN"}
                //                 type={CIN_Masking_data ? (isCINMasked ? "text" : "password") : "text"}
                //                 value={field.value}
                //                 onChange={field.onChange}
                //                 inputProps={CIN_Masking_data ? {
                //                     endAdornment: (
                //                         <InputAdornment position="end">
                //                             <IconButton
                //                                 aria-label="toggle password visibility"
                //                                 onClick={() => { setIsCINMasked(!isCINMasked); }}
                //                                 onMouseDown={handleMouseDownPassword}
                //                                 edge="end"
                //                                 disabled={view}
                //                             >
                //                                 {isCINMasked ? <VisibilityOff /> : <Visibility />}
                //                             </IconButton>
                //                         </InputAdornment>
                //                     ),
                //                 } : {}}
                //                 error={Boolean(errorsVendorDetailsform.CIN)}
                //                 helperText={errorsVendorDetailsform.CIN?.message?.toString()}
                //             />
                //         )}
                //     />
                // </Grid>

                <CustomTextField
                name={"CIN"}
                // required={true}
                readOnly={view}
                label={"CIN"}
                type={CIN_Masking_data ? (isCINMasked ? "text" : "password") : "text"}
                value={field.value}
                onChange={field.onChange}
                inputProps={CIN_Masking_data ? {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => { setIsCINMasked(!isCINMasked); }}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                disabled={view}
                            >
                                {isCINMasked ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                } : {}}
                error={Boolean(errorsVendorDetailsform.CIN)}
                helperText={errorsVendorDetailsform.CIN?.message?.toString()}
            />
        )}
    />
</Grid>

                <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                >
                    <Controller
                        name="Jurisdiction"
                        control={control}
                        defaultValue={''}
                        rules={{ required: "Jurisdiction is required" }}
                        render={({ field }) => (
                            <CustomAutoComplete
                                optionLabelKey="Location_Name"
                                optionValueKey="Location_Id"
                                options={jurisdiction}
                                readOnly={view}
                                required={true}
                                label={"Applicable Jurisdiction"}
                                value={field.value}
                                onChange={field.onChange}
                                error={Boolean(errorsVendorDetailsform.Jurisdiction)}
                                helperText={errorsVendorDetailsform.Jurisdiction?.message?.toString()}
                            />
                        )}
                    />
                </Grid>

                <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                >
                    <Controller
                        name="Constitution"
                        control={control}
                        defaultValue={''}
                        rules={{ required: "Constitution is required" }}
                        render={({ field }) => (
                            <CustomSelect
                                name={"Constitution"}
                                valueKey={"LovId"}
                                optionLabelKey="Options"
                                options={companyConstitutionlov}
                                readOnly={view}
                                required={true}
                                label={"Constitution"}
                                value={field.value}
                                onChange={field.onChange}
                                error={Boolean(errorsVendorDetailsform.Constitution)}
                                helperText={errorsVendorDetailsform?.Constitution?.message?.toString()}
                            />
                        )}
                    />
                </Grid>


            </Grid>
        </Box>
    )
}