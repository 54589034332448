import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { textFieldWidth } from "../../../utils/constants";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import { getAllThresholdMaster, ThresholdMasterAddandSave, CreateThresholdMaster, updateThresholdMaster } from "../../../store/performanceManagement/slices/thresholdMaster";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
interface FormData {
    Id?: any;
    description?: any;
    fromRange?: string;
    toRange?: string;
    score?: string;
    isActive: any;
}

export interface ThresholdProps {
    view?: boolean;
    closePopUp?: () => void;
    edit?: boolean;
    isEditData?: any;
    ModuleId?: any;

}

export const AddThreshold: React.FC<ThresholdProps> = ({ view, closePopUp, edit, isEditData, ModuleId }) => {
    const dispatch = useAppDispatchThunk();
    const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
    console.log(isEditData, "isEditData");
    const performanceManagementReducer: any = useSelector((state: RootState) => state?.performanceManagementReducer);
    const { isLoading, ToasterMsg, isThresholdMasterRemoved, isThresholdMasterUpdated, isThresholdMasterAdded, ThresholdTable }: any = performanceManagementReducer.ThresholdMasterDataSlice;
    let formData: any = {
        thresholdId: isEditData ? isEditData.thresholdId : 0,
        description: isEditData ? isEditData.description : "",
        fromRange: isEditData ? isEditData?.fromRange : "",
        toRange: isEditData ? isEditData?.toRange : "",
        score: isEditData ? isEditData?.score : "",
        isActive: isEditData ? (isEditData?.isActive === "Active" ? true : false) : true,

    };
    let ModifiedBy = isEditData ? isEditData?.UpdatedBy : "";
    let CreatedBy = isEditData ? isEditData?.CreatedBy : "";


    const {
        handleSubmit: handleRegionalSettings,
        reset,
        setValue,
        formState: { errors: errorsloginform, isDirty },
        control,
        watch,
        getValues,
    } = useForm<FormData>({
        defaultValues: formData,
        mode: "onChange",
    });
    console.log(getValues(), "getValues");

    const isActiveDisabled = watch("isActive");

    useEffect(() => {
        console.log(isThresholdMasterAdded, isThresholdMasterUpdated, "isSeverityMasterAdded");

        if (isThresholdMasterAdded) {
            dispatch(getAllThresholdMaster({
                FormID: 2
            }))
            if (closePopUp) { closePopUp(); }
        };
        if (isThresholdMasterUpdated) {
            dispatch(getAllThresholdMaster({
                FormID: 2
            }))
            if (closePopUp) { closePopUp(); }
        };
        dispatch(ThresholdMasterAddandSave(false))
        reset(formData);

        dispatch(updateLoader(false));


    }, [isThresholdMasterAdded, isThresholdMasterUpdated]);

    const handleSubmit = (data: any) => {

        console.log(data, "isEditData");

        // dispatch(updateLoader(true));
        let formData = data;
        // setLoader(true);
        formData.isActive = formData.isActive ? true : false;
        formData.Created_By = user?.UserName;
        formData.moduleName = "Threshold Master";
        formData.Remarks = "";
        formData.MenuId = currentPagePermissionData?.MenuId;;
        formData.Form_Id = 18;
        formData.Status = 1;
        formData.UserId = userLoginResponse?.UserId;
        const currentDate = new Date();
        formData.createdOn = currentDate.toISOString()
        formData.updatedOn = currentDate.toISOString()
        formData.updatedBy = user?.UserName;
        formData.createdBy = user?.UserName;
        if (edit) {

            formData.Remarks = "";
            formData.activity = "Edit";
            formData.userId = userLoginResponse?.UserId;
            dispatch(updateThresholdMaster(formData))
        } else {
            console.log("lkjh");

            // delete formData.DepartmentId;
            formData.MenuId = currentPagePermissionData?.MenuId;
            formData.moduleName = "Threshold Master";
            formData.isActive = true;

            formData.activity = "Create";
            dispatch(CreateThresholdMaster(formData))
            console.log(formData, 'formData')
        }
        console.log(formData, 'formData')
    };


    return (
        <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
            <Grid
                // item
                // item xs={12} sm={12} md={6} lg={6} xl={6}
                sx={{
                    backgroundImage: `url()`,
                    backgroundRepeat: "no-repeat",
                    // backgroundColor: (t) =>
                    //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
                    // backgroundSize: "cover",
                    backgroundPosition: "center",
                    // height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    margin: "0",
                }}
            >
                {false && (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            margin: "0",
                        }}
                    >
                        {!view ? (
                            <>
                                {!edit ? (
                                    <Typography variant="h6" sx={{ m: 1 }}>
                                        Add Severity Master
                                    </Typography>
                                ) : (
                                    <Typography variant="h6" sx={{ m: 1 }}>
                                        Edit Severity Master
                                    </Typography>
                                )}
                            </>
                        ) : (
                            <Typography variant="h6" sx={{ m: 1 }}>
                                View Severity Master
                            </Typography>
                        )}
                    </Box>
                )}
                <Grid
                    sx={{
                        height: "auto",
                        width: "100%",
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            px: {
                                xs: 2,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            },

                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
                        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                // sm={textFieldWidth.sm}
                                // md={textFieldWidth.md}
                                // lg={textFieldWidth.lg}
                                // xl={textFieldWidth.xl}
                                >
                                    <Controller
                                        name="description"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            required: "Threshold Description is required",
                                        }}
                                        render={({ field }) => (
                                            <CustomMultilineTextField
                                                label={"Threshold Description"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                required
                                                rows={2}
                                                readOnly={view || !isActiveDisabled}
                                                error={Boolean(errorsloginform.description)}
                                                helperText={errorsloginform?.description?.message?.toString()}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                // sm={textFieldWidth.sm}
                                // md={textFieldWidth.md}
                                // lg={textFieldWidth.lg}
                                // xl={textFieldWidth.xl}
                                >
                                    <Controller
                                        name={"fromRange"}
                                        control={control}
                                        // defaultValue={0}
                                        rules={{
                                            required: "From Range is required",
                                            validate: (data) => {
                                                if (data !== undefined) {
                                                    const number = parseFloat(data);

                                                    let torange = watch("toRange"); // Watch returns a string or undefined
                                                    const torangeNumber = torange ? parseFloat(torange) : undefined;
                                            
                                                    if (isNaN(number) || number <= 0) {
                                                        return "Please enter a From range greater than 0";
                                                    } 
                                                    
                                                    if (torangeNumber !== undefined && torangeNumber < number) {
                                                        return "From Range must be less than  To Range";
                                                    }
                                                    
                                                    return true;
                                                }
                                            }
                                        }}
                                        render={({ field }) => (
                                            <CustomNumberField
                                                name={"fromRange"}
                                                required={true}
                                                isDecimal={true}
                                                readOnly={view || !isActiveDisabled}
                                                label={"From Range"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.fromRange)}
                                                helperText={errorsloginform.fromRange && errorsloginform.fromRange.message?.toString()}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                // sm={textFieldWidth.sm}
                                // md={textFieldWidth.md}
                                // lg={textFieldWidth.lg}
                                // xl={textFieldWidth.xl}
                                >
                                    <Controller
                                        name="toRange"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            required: "To Range is Required",

                                            validate: (data) => {
                                                if (data !== undefined) {
                                                    const number = parseFloat(data);
                                                    const fromRange = watch("fromRange"); // Watch returns a string or undefined
                                                    const fromRangeNumber = fromRange ? parseFloat(fromRange) : undefined;
                                    
                                                    if (isNaN(number) || number <= 0) {
                                                        return "Please enter a To range greater than 0";
                                                    }
                                    
                                                    if (fromRangeNumber !== undefined && number < fromRangeNumber) {
                                                        return "To Range must be greater than From Range";
                                                    }
                                    
                                                    return true;
                                                }
                                            }
                                        }}
                                        render={({ field }) => (
                                            <CustomNumberField
                                                name={"toRange"}
                                                required={true}
                                                isDecimal={true}
                                                readOnly={view || !isActiveDisabled}
                                                label={"To Range"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.toRange)}
                                                helperText={errorsloginform.toRange && errorsloginform.toRange.message?.toString()}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                // sm={textFieldWidth.sm}
                                // md={textFieldWidth.md}
                                // lg={textFieldWidth.lg}
                                // xl={textFieldWidth.xl}
                                >
                                    <Controller
                                        name="score"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            required: "Score is Required",
                                            validate: (data) => {
                                                if (data != undefined) {
                                                    const number = parseInt(data, 10);
                                                    if (isNaN(number) || number < 1 || number>=10) {
                                                        return "Score value must be 1 to 9";
                                                    }
                                                    return true;
                                                }
                                            }
                                        }}
                                        render={({ field }) => (
                                            <CustomNumberField
                                                name={"score"}
                                                required={true}
                                                readOnly={view || !isActiveDisabled}
                                                label={"Score"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.score)}
                                                helperText={errorsloginform.score && errorsloginform.score.message?.toString()}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "20px",
                                    justifyContent: "end",
                                    mt: 2,
                                }}
                            >
                                {view ? (
                                    <CustomButton
                                        type="reset"
                                        name={"Cancel"}
                                        onClick={() => {
                                            closePopUp && closePopUp();
                                        }}
                                    />
                                ) : edit ? (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton
                                            type="reset"
                                            disabled={!isDirty}
                                            name={"Update"}
                                            onClick={handleRegionalSettings(handleSubmit) }
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton
                                            type="reset"
                                            disabled={!isDirty}
                                            name={!edit ? "Submit" : "Update"}
                                            onClick={handleRegionalSettings(handleSubmit)}
                                        />
                                    </React.Fragment>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};
