import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { DeviationToleranceLevelMaster_MASTER_URL } from './endpoint/DeviationToleranceLevelMaster'
import { IDeviationToleranceLevelMasterResponse } from "../../../models/performanceManagementModels/deviationToleranceLevelMaster.type";
const getAllDeviationToleranceLevelMaster = (): Promise<IDeviationToleranceLevelMasterResponse> => axiosInstance
  .post(DeviationToleranceLevelMaster_MASTER_URL.GetAllDeviationToleranceLevelMaster)
  .then((response: AxiosResponse<IDeviationToleranceLevelMasterResponse>) => {
    if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
      return response.data;
    }
    throw new Error("Error getting Data" + response);
  })
  .catch((error: AxiosError | string) => {
    throw error;
  });
const saveDeviationToleranceLevelMaster = (requestBody: any): Promise<IDeviationToleranceLevelMasterResponse> =>
  axiosInstance
    .post(DeviationToleranceLevelMaster_MASTER_URL.saveDeviationToleranceLevelMaster, requestBody)
    .then((response: AxiosResponse<IDeviationToleranceLevelMasterResponse>) => {

      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const updateDeviationToleranceLevelMaster = (requestBody: any): Promise<IDeviationToleranceLevelMasterResponse> =>
  axiosInstance
    .post(DeviationToleranceLevelMaster_MASTER_URL.UpdateDeviationToleranceLevelMaster, requestBody)
    .then((response: AxiosResponse<IDeviationToleranceLevelMasterResponse>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const deleteDeviationToleranceLevelMaster = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(DeviationToleranceLevelMaster_MASTER_URL.EnableOrDisableDeviationToleranceLevel, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403)  {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const DeviationToleranceLevelMasterService = {
    getAllDeviationToleranceLevelMaster, saveDeviationToleranceLevelMaster, updateDeviationToleranceLevelMaster,deleteDeviationToleranceLevelMaster
};

export default DeviationToleranceLevelMasterService;
