import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IVHColumnRes, } from "../../models/assessmentQuestionnaireMaster.type";
import { AQMMapping_URL } from "./endpoints/assesmentQuestionnaireMapping";


const getAQMMappingList = (reqparams: any): Promise<any> =>
  axiosInstance
    .get(AQMMapping_URL.GetAllAQMMapping)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateAQMMapping = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(AQMMapping_URL.UpdateAQMMapping, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addAQMMapping = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(AQMMapping_URL.AddAQMMapping, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteAQMMapping = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(AQMMapping_URL.DeleteAQMMapping, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistoryAQMMapping = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(AQMMapping_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getAQMMappingbyVendorAndProjectId = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(AQMMapping_URL.GetAQMMappingbyvendorandproject, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getAQMMappingByVendorID = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(AQMMapping_URL.GetAQMMappingByVendorID, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });


    const getAssessmentHistryByAQMMappingID = (requestBody: any): Promise<any> =>
      axiosInstance
        .get(AQMMapping_URL.getAssessmentHistryByAQMMappingID, { params: requestBody })
        .then((response: AxiosResponse<any>) => {
          if (response.status === 200) {
            return response.data;
          }
          throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
          throw error;
        });

const AQMMappingService = {
  getAQMMappingList,
  updateAQMMapping,
  addAQMMapping,
  deleteAQMMapping,
  getVersionHistoryAQMMapping,
  getAQMMappingbyVendorAndProjectId,
  getAQMMappingByVendorID,
  getAssessmentHistryByAQMMappingID
};

export default AQMMappingService;
