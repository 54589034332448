import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { AddThirdParty } from "./AddThirdParty";
import { IMenu } from "../../../models/loggin.type";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { deleteThirdParty, getThirdPartyHistory, getThirdPartyList, updateThirdPartyAddandEdit, updateVersionHistory } from "../../../store/slices/thirdPartyData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import masterEditEnableService from "../../../store/services/masterEditEnableService";
import { Edit_Enable_tosterMSG, FormIdConfig } from "../../../config/config";
import { HandleRecordLockCheck, RecordLockUpdate } from "../../recordLock/RecordLock";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";


export interface ThirdPartyProps {

    handlePrevious?: () => void;
    handleNext?: () => void;

}


export const ThirdPartyMaster: React.FC<ThirdPartyProps> = ({ handlePrevious, handleNext }) => {

    const dispatch = useAppDispatchThunk();
    const { thirdPartyData, isThirdPartyUpdated, isThirdPartyAdded, isThirdPartyDeleted, versionHistory, ToasterMsg } = useSelector((state: RootState) => state.thirdPartyData);
    const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
    const [thirdPartyFromData, setthirdPartyFromData] = useState([]);
    const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
    const [addForm, setAddForm] = useState(false);
    const [Option, setOption] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [isVersionHistory, setIsVersionHistory] = useState(false);
    const [formDisplay, setformDisplay] = useState(false);
    const [filterTableData, setFilterTableData] = useState<any[]>([]);
    const [IsEditData, setIsEditData] = useState<any[]>([]);
    const [isDeleteData, setIsDeleteData] = useState<any>(null);
    const [versionHistoryData, setVersionHistoryData] = useState<any>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [getRecordLock, setGetRecordLock] = useState<any>([]);
    const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);

    const { currentpageTitleData } = useSelector(
        (state: RootState) => state.FormTitleMasterData
      );
  const { handleRecordLockUpdate } = RecordLockRelease();

    const columns_version: any = {
        table_name: "Associated Party History Records",
        isAction: false,
        columns: [],
    };

    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );

    useEffect(() => {
        callMasterData()
    }, [])

    const callMasterData = () => {
        dispatch(getThirdPartyList({ FormID: 0 }));
    }

    useEffect(() => {
        setthirdPartyFromData(thirdPartyData)
    }, [thirdPartyData])

    const columns: any = {
        table_name: "Associated Party",
        isAction: true,
        columns: [

            {
                Field: "ThirdPartyName",
                DisplayName: "Name",
                FieldType: "string",
            },
            {
                Field: "ThirdPartyTypeValue",
                DisplayName: "Type",
                FieldType: "string",
            },
            {
                Field: "ShortName",
                DisplayName: "Short Name",
                FieldType: "string",
            },
            {
                Field: "IsActive",
                DisplayName: "Status",
                FieldType: "string",
            },
            {
                Field: "UpdatedBy",
                DisplayName: "Modified By",
                FieldType: "string",
            },

        ],
    };

    useEffect(() => {

        if (currentPagePermissionData !== null) {
            setPermissionForpage(currentPagePermissionData)
        }
    }, [currentPagePermissionData]);

    useEffect(() => {
        if (versionHistory.ThirdPartyVHeader.length > 0 && versionHistory.ThirdPartyVHeader.length > 0) {
            if (versionHistory.ThirdPartyVHeader && versionHistory.ThirdPartyVHeader.length > 0) {
                setIsVersionHistory(true);
                let re_columns = {
                    ...columns_version,
                    columns: versionHistory.ThirdPartyVHeader,
                };
                setVersionHistoryData(re_columns);
                console.log(versionHistory, "versionHistory ");

            }
        }
    }, [versionHistory]);

    

    const handleOpen = async (data: any, Option: string) => {
       
        if (Option === "edit") {
            setOption(Option);
            setIsEditData(data);
            setIsEdit(true);

        } else if (Option === "view") {

            setOption(Option);
            setIsEditData(data);
            setIsEdit(true);
        } else if (Option === "history") {

            setOption(Option);
            dispatch(
                getThirdPartyHistory({
                    ThirdPartyId: data.ThirdPartyId,
                    formID: FormIdConfig?.ThirdPartFormyId,
                })
            );
        } else if (Option === "delete") {

            setOption(Option);
        setIsDeleteData(data);
            

        }else {
            setIsEdit(true);
            setOption(Option);
            setIsEditData(data);
           
          }
    };

    const previous = () => {
        handlePrevious && handlePrevious();
    };

    const next = () => {
        handleNext && handleNext();
    };

    const deleteThirdPartyList = () => {

        // let formData = { ...isDeleteData };
        let formData: any = {};

        let JsonDataForDisplay: any = {
            "Associated Party Name": isDeleteData?.ThirdPartyName,
            "Associated Party Type" : isDeleteData?.ThirdPartyTypeValue,
            "Short Name" : isDeleteData.ShortName,
            "Email Id" : isDeleteData.EmailId,
            "City" : isDeleteData.City,
            "Contact Number" : isDeleteData.ContactNumber,
            "Contact Person" : isDeleteData.ContactPerson,
            "PinCode" : isDeleteData.PinCode,
            "Address" : isDeleteData.ThirdParty_Address,
            "Status": isDeleteData?.IsActive === "Active" ? "Inactive" : "Active",
            "Modified By": user?.UserName,
        }
        formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);

        formData.CreatedBy = user?.UserName;
        formData.ModuleName = "Third Party Master";
        formData.IsActive = isDeleteData.IsActive === "Active" ? false : true;
        formData.UserId = userLoginResponse?.UserId;
        formData.Activity = isDeleteData.IsActive === "Active" ? "inactive" : "active";
        formData.MenuId = currentPagePermissionData?.MenuId;
        formData.Form_Id = 10;
        formData.ThirdPartyId = isDeleteData.ThirdPartyId;
        formData.ThirdPartyName = isDeleteData.ThirdPartyName;

        dispatch(deleteThirdParty(formData))
        setIsDeleteData(null)

    };


    useEffect(() => {
        if (isThirdPartyAdded) {
            dispatch(updateThirdPartyAddandEdit(false));
            dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
            callMasterData()
        } else if (isThirdPartyUpdated) {
            dispatch(updateThirdPartyAddandEdit(false));
            handleRecordLockUpdate()
            dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
            callMasterData()
        } else if (isThirdPartyDeleted) {
            setIsDeleteData(null);
            dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
            setTimeout(() => {
                dispatch(updateThirdPartyAddandEdit(false));
            }, 1000);
            callMasterData()

        }
    }, [isThirdPartyUpdated, isThirdPartyAdded, isThirdPartyDeleted]);

   
    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    // backgroundColor: "#ffff",
                    // backgroundColor: "background.default",
                    // px: 3,
                    // py: 4,
                    // borderRadius: "10px",
                }}
            >
                <Stack spacing={2}>

                    <Box
                        sx={{
                            display: "flex",
                            // flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <Box sx={{ display: "flex", width: "25%", }} >
                            <CustomSelect
                                name={"listName"}
                                valueKey={"id"}
                                optionLabelKey="lable"
                                options={location_Data}
                                value={formData.listName}
                                onChange={handleChange}
                                required={true}
                                label={"Select List Name"}
                            />
                        </Box> */}
                        <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                        </Typography>

                        {currentPagePermissionData?.MenuName === "Customer Onboarding" ?

                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Grid
                                    sx={{
                                        display: "flex",
                                        gap: "20px",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CustomButton variant="outlined" name={"Previous Master"} onClick={() => previous()} size="small" />
                                    <CustomButton variant="contained" name={"Proceed to next master"} onClick={() => next()} size="small" />
                                </Grid>
                            </Box>
                            : <></>
                        }

                    </Box>

                    <Box>


                        <Box sx={{ display: "flex", flexDirection: "column" }}>


                            <MaterialDynamicGrid
                                data={columns}
                                rows={thirdPartyFromData}
                                handleAction={handleOpen}
                                enableZoomIcon={true}
                                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                                handleAdd={() => setAddForm(true)}
                                showAdd={permssionPage?.IsAdd === 1}
                                showhistory={true}
                                showDelete={permssionPage?.IsDelete === 1}
                                showView={permssionPage?.IsView === 1}
                                showEdit={permssionPage?.IsEdit === 1}
                                showExports={permssionPage?.IsExport === 1}
                                pageName="Associated Party"
                                isEnableRecordLock = {{ IdField : "ThirdPartyId" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                                                  FormID : FormIdConfig?.ThirdPartFormyId,
                                                                
                                                                  MenuID : currentPagePermissionData?.MenuId,
                                                                  IsCheckPending : true  }}}
                                userId={(userLoginResponse && userLoginResponse?.UserId) ? userLoginResponse?.UserId : 0}
                            />
                        </Box>

                    </Box>
                </Stack>
            </Box>

            <CustomDialog
                show={addForm}
                onHide={() => setAddForm(false)}
                maxWidth={"lg"}
                minHeight={"50vh"}
                header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Associated Party Master"}`}
                contentNode={
                    // <></>
                    <AddThirdParty
                        view={false}
                        closePopUp={() => {
                            setAddForm(false);
                        }}
                        ModuleId={permssionPage?.MenuId}
                    />
                }
            />

            <CustomDialog
                show={isEdit}
                onHide={() => {
                    handleRecordLockUpdate()
                    setIsEdit(false);

                }}
                maxWidth={"lg"}
                minHeight={"50vh"}
                header={Option === "view" ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Associate Party Master"}` : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Associate Party Master"}`}                contentNode={
                    <>
                        {Option === "edit" && (
                            <AddThirdParty
                                view={false}
                                closePopUp={() => {
                                    handleRecordLockUpdate();
                                    setIsEdit(false);
                                   
                                }}
                                edit={isEdit}
                                isEditData={IsEditData}
                                ModuleId={permssionPage?.MenuId}
                            />
                            // <></>
                        )}

                        {Option === "view" && (
                            <AddThirdParty
                                view={true}
                                closePopUp={() => {
                                    setIsEdit(false);
                                }}
                                isEditData={IsEditData}
                                ModuleId={permssionPage?.MenuId}
                            />
                            // <></>
                        )}

                    </>
                }
            />

            <CustomDialog
                show={isVersionHistory}
                onHide={() => {
                    setIsVersionHistory(false);
                    dispatch(updateVersionHistory([]));
                }}
                maxWidth={"lg"}
                header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Associate Party Master"} Version History`}
                contentNode={
                    <> <MaterialDynamicGrid
                        data={versionHistoryData}
                        rows={versionHistory.ThirdPartyRecords}
                        handleAction={handleOpen}
                        enableZoomIcon={false}
                        showhistory={false}
                        showDelete={permssionPage?.IsDelete === 1}
                        showView={permssionPage?.IsView === 1}
                        showEdit={permssionPage?.IsEdit === 1}
                        showExports={permssionPage?.IsExport === 1}
                        userId={(userLoginResponse && userLoginResponse?.UserId) ? userLoginResponse?.UserId : 0}
                        pageName="Associated Party Version History"
                    />
                    </>
                }
            />

            <ModalPopUp
                onDeleteMethod={() => deleteThirdPartyList()}
                isDelete={true}
                onHide={() => setIsDeleteData(null)}
                show={isDeleteData ? true : false}
                header={"Confirm"}
                text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"} the selected Associated Party`}
                ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
            />
        </>
    );

}