import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, Typography, CircularProgress, Stack } from "@mui/material";
import moment from "moment";
import CustomButton from "../../common/dynamicButton/CustomButton";
import Paper from "@mui/material/Paper";
import axios from "axios";
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";


import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import CustomDatePicker from "../../common/dynamicInputs/CustomDatePicker";
import { textFieldWidth } from "../../utils/constants";
import ErrorLogService from "../../store/services/ErrorLogServices";

export interface errorLogFormData {
  errorLogDate: any;
}

const ErrorLog = () => {
  const {
    handleSubmit: handleFormSubmit,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
  } = useForm<errorLogFormData>({
    defaultValues: {
      errorLogDate: null,
    },
    mode: "all",
  });

  const [formattedDate, setFormattedDate] = useState("");
  const [showError, setShowError] = useState(false);

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );

  // useEffect(() => {
  //   console.log(formattedDate);
  // }, [formattedDate]);

  // const getTime = new Date().toISOString().split('T')[0];


  const handleErrorLogDownload = async () => {
    try {
      // const response = axios.get(`https://clifeapidev.azurewebsites.net/getErrorLog?logdate=${formattedDate}`);
      const response =     ErrorLogService.getErrorLogs({ logdate: formattedDate}); 
      console.log(response, "response");
      
      const file = new Blob([(await response)], { type: "text/plain" });
      console.log(file, "Errorlog");
      let tempLink = document.createElement("a");
      tempLink.href = URL.createObjectURL(file);
      tempLink.setAttribute("download", `${formattedDate}_ErrorLog.txt`);
      tempLink.click();
    } catch (error) {
      console.log(error);
      setShowError(true);
    }
  };
  const { currentpageTitleData } = useSelector(
    (state: RootState) => state.FormTitleMasterData
  );


  const currentDate = dayjs();

  return (


    <Grid
      container
      component="main"
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        position: "relative",
        backgroundImage: `url()`,
        backgroundRepeat: "no-repeat",
        backgroundColor: (t) => (t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5"),
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          m: 6,
          p: 6,
          position: "relative",
          height: "80%",
          width: "100%",
          borderRadius: "10px"
        }}
        item
        xs={12}
        component={Paper}
        elevation={6}
        square
      >
        <Stack
          direction={"column"}
          sx={{
            m: 0,
            py: 6,
            px: 0,
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            //alignItems:""
            border: "1px solid black"
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              margin: "0",
              position: "relative",
            }}
          >

            <Typography variant="h6" color={"text.primary"}>
              {" "}
              {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
              {/* Holiday Master */}
            </Typography>

            {/* <Typography variant="h6" sx={{ m: 1 }}>
              {"Download Error Log"}
            </Typography> */}
          </Box>
          <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center" }}>
            <Grid
              item
              xs={4}
            /* sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl} */
            >
              <Controller
                name="errorLogDate"
                control={control}
                defaultValue={null}
                rules={{
                  required: "Date Field is Required",
                }}
                render={({ field }) => (
                  <CustomDatePicker
                    required={true}
                    label={"Date Picker"}
                    value={field.value}
                    onChange={(val) => {
                      field.onChange(val);
                      const date = moment(val.$d).format("YYYY-MM-DD");
                      setFormattedDate(date);
                    }}
                    // onChange={field.onChange}
                    maxDate={currentDate.toDate()}
                    error={Boolean(errorsloginform.errorLogDate)}
                    helperText={errorsloginform.errorLogDate && errorsloginform.errorLogDate.message?.toString()}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "center",
              my: 2,
            }}
          >
            <CustomButton type="reset" variant="outlined" name={"Reset"} onClick={reset} />
            <CustomButton
              type="submit"
              variant="contained"
              disabled={formattedDate === ""}
              name={"Extract"}
              onClick={handleFormSubmit(handleErrorLogDownload)}
            />
          </Box>
        </Stack>
      </Grid>

      <CustomDialog
        show={showError}
        onHide={() => setShowError(false)}
        maxWidth={"xs"}
        header={"Error"}
        contentNode={
          <Typography variant="h6" sx={{ m: 1 }}>
            {"Something went wrong, Please try again."}
          </Typography>
        }
      />
    </Grid>
  );
};

export default ErrorLog;
