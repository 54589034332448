import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SeverityMasterService from "../services/severityMaster";
import { ISeverityMaster } from "../../../models/performanceManagementModels/severityMaster.type";

export interface ISeverityMasterData {
    SeverityMasterTable: ISeverityMaster[];
    isLoading: boolean;
    isSeverityMasterAdded: boolean;
    isSeverityMasterUpdated: boolean;
    ToasterMsg: { Message: string; Status: string };
    SeverityMasterDatas: any;
    severityMasterDetails: any;
    isSeverityMasterRemoved: boolean;
}

export const initialState: ISeverityMasterData = {
    SeverityMasterTable: [],
    isLoading: false,
    isSeverityMasterAdded: false,
    isSeverityMasterUpdated: false,
    ToasterMsg: { Message: "", Status: "" },
    SeverityMasterDatas: {},
    severityMasterDetails: [],
    isSeverityMasterRemoved: false,
};

export const getAllSeverityMaster = createAsyncThunk(
    "getAllSeverityMaster",
    async (requestParams: { FormID: number }, { rejectWithValue }) => {
        try {
            const response = await SeverityMasterService.getAllSeverityMaster();

            const { severityMasterDetails } = response;
            return severityMasterDetails;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const CreateSeverityMaster = createAsyncThunk("saveSeverityMaster", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await SeverityMasterService.saveSeverityMaster(requesrParams);
        return response.toaster;
    } catch (error) {
        return rejectWithValue(error);
    }
});
export const updateSeverityMaster = createAsyncThunk("UpdateHoliday", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await SeverityMasterService.updateSeverityMaster(requesrParams);
        console.log(requesrParams, "requesrParams");

        return response.toaster;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteSeverityMaster = createAsyncThunk("deleteSeverityMaster", async (requestParams: any, { rejectWithValue }) => {
    try {
        const response: any = await SeverityMasterService.deleteSeverityMaster(requestParams);
        return response.toaster;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const SeverityMasterDataSlice = createSlice({
    name: "SeverityMaster",
    initialState,
    reducers: {
        SeverityMasterAddandSave: (state, action) => {
            state.isSeverityMasterAdded = false;
            state.isSeverityMasterRemoved = false;
            state.isSeverityMasterUpdated = false;
            //   state.isCompanyDeleted = false;
            //   state.isDuplicate = false;
            state.ToasterMsg.Message = action.payload.message;
            state.ToasterMsg.Status = action.payload.status;
        },
        updateUser: (state, action) => {
            if (action.payload.UserId) {
                let indexVendor = state.SeverityMasterTable.findIndex((data: any) => data.UserId === action.payload.UserId);
                let copyVendorData = [...state.SeverityMasterTable];
                copyVendorData[indexVendor] = action.payload;
                state.SeverityMasterTable = copyVendorData;
            }

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllSeverityMaster.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllSeverityMaster.fulfilled, (state, action) => {                
                state.SeverityMasterTable = action.payload.map((data: any) => {
                    data.IsActive = data.isActive === true ? "Active" : "Inactive";
                    data.isActive = data.isActive === true ? "Active" : "Inactive";
                    return data;
                });
                console.log(state.SeverityMasterTable,"state.SeverityMasterTable");
                
                state.isLoading = false;
            })
            .addCase(getAllSeverityMaster.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(CreateSeverityMaster.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(CreateSeverityMaster.fulfilled, (state, action: PayloadAction<any>) => {

                state.isSeverityMasterAdded = true;
                state.isLoading = false;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(CreateSeverityMaster.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                // state.ToasterMsg = action.payload.response.data;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(updateSeverityMaster.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateSeverityMaster.fulfilled, (state, action: PayloadAction<any>) => {
                state.isSeverityMasterUpdated = true;
                state.isLoading = false;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(updateSeverityMaster.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                // state.ToasterMsg = action.payload.response.data;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(deleteSeverityMaster.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteSeverityMaster.fulfilled, (state, action: PayloadAction<any>) => {
                state.isSeverityMasterRemoved = true;
                console.log(state.isSeverityMasterRemoved, "state.isSeverityMasterRemoved");

                state.isLoading = false;
                // state.ToasterMsg = action.payload.Message;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(deleteSeverityMaster.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
    },
});

export const { SeverityMasterAddandSave, updateUser } = SeverityMasterDataSlice.actions;
export default SeverityMasterDataSlice.reducer;
