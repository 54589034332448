import { Box, Stack, Typography } from "@mui/material";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatch } from "../../store/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AddVendorDetails } from "./AddVendor/index";
import { deleteVendorDetails, getVendorDetailsHistory, getVendorDetailsList, updateVendorDetailsAddandEdit, updateVersionHistory } from "../../store/slices/vendorDetails";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { getAllSaveandDraftList, updateSaveasDraftAddandEdit } from "../../store/slices/saveasdraft";
import vendorDetailsService from "../../store/services/vendorDetails";
import { clearSignzyData } from "../../store/slices/signzySlice";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { clearProbeData } from "../../store/slices/ProbeData";
import masterEditEnableService from "../../store/services/masterEditEnableService";
import { Edit_Enable_tosterMSG, FormIdConfig, LovConfig } from "../../config/config";
import dayjs from "dayjs";
import { getLovList } from "../../store/slices/lovMasterData";
import { getThirdPartyList } from "../../store/slices/thirdPartyData";
import { getIndustryList } from "../../store/slices/industryMasterData";
import { getDepartmentList } from "../../store/slices/departmentData";
import { getLocationList } from "../../store/slices/locationMasterData";
import { getServiceCatalogList } from "../../store/slices/serviceCatalog";
import { getRegionalList } from "../../store/slices/regionalSettingsData";
import { getDocumentList } from "../../store/slices/documentMasterData";
import { RecordLockRelease } from "../recordLock/CommonRecordLock";

//import { SignzyData } from "./AddVendor/VendorDetails/mockData";

const columns = {
  table_name: "Third Party Master",
  isAction: true,
  columns: [
    // {
    //   Field: "Vendor_Id",
    //   FieldType: "string",
    //   DisplayName: "Vendor Id  (testing purpose only)",
    // },
    {
      Field: "Legal_Name",
      FieldType: "string",
      DisplayName: "Third Party Name",
    },

    {
      Field: "Type_Of_Vendor2_Value",
      FieldType: "string",
      DisplayName: "Third Party Type",
    },

    {
      Field: "Group_Name",
      FieldType: "string",
      DisplayName: "Third Party Group Name",
    },

    {
      Field: "Contact_Number",
      FieldType: "string",
      DisplayName: "Registered Contact No",
    },
    {
      Field: "EmailId",
      FieldType: "string",
      DisplayName: "Registered Email Id",
    },
    {
      Field: "isWorkflowInitiated",
      FieldType: "string",
      DisplayName: "Is Work flow Initiated",

    },
    {
      Field: "PAN",
      FieldType: "string",
      DisplayName: "PAN",
    },
    {
      Field: "TAN",
      FieldType: "string",
      DisplayName: "TAN",
    },
    {
      Field: "CIN",
      FieldType: "string",
      DisplayName: "CIN",
    },
    {
      Field: "Jurisdiction_Value",
      FieldType: "string",
      DisplayName: "Applicable Jurisdiction",
    },
    {
      Field: "Constitution_Value",
      FieldType: "string",
      DisplayName: "Constitution",
    },
    {
      Field: "IsListedValue",
      FieldType: "string",
      DisplayName: "Is Listed",
    },
    {
      Field: "IsRegulatedValue",
      FieldType: "string",
      DisplayName: "Is Regulated",
    },
    {
      Field: "Status_Value",
      DisplayName: "Status",
      FieldType: "string",
    },
    {
      Field: "Updated_By",
      FieldType: "string",
      DisplayName: "Modified By",
    }
  ],
};

const VendorDetailsMaster = () => {
  const dispatch = useAppDispatch();
  const {
    vendorDetailsdata,
    isVendorDetailsAdded,
    isVendorDetailsUpdated,
    isVendorDetailsDeleted,
    vendorDetailsToasterMsg,
    vendorDetailsToasterStatus,
    vendorDetailsToasterOncloseStatus,
    versionHistory,
  } = useSelector((state: RootState) => state.vendorDetails);
  const { SignzyData, isLoading } = useSelector((state: RootState) => state.signzySlice);
  const { draftdata, isLoadingDraft, isDraftAdded, isDraftUpdated, DraftToasterMsg } = useSelector(
    (state: RootState) => state.saveAsDraft
  );
  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [HeaderColumn] = useState(columns);
  const [vendorDetails, setVendorDetails] = useState<any[]>([]);
  const [permssionPage, setPermissionForpage] = useState<any>();
  const [Option, setOption] = useState("");
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [isEditData, setIsEditDate] = useState<any>(null);
  const [isShowDraft, setisShowDraft] = useState<any>(1);
  const [vendorDetailsDraftData, setVendorDetailsDraftData] = useState<any[]>([]);
  const [isDraftData, setIsDraftData] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [isDeleteData, setIsDeleteData] = useState<any>(null);

  const { departmentTable } = useSelector((state: RootState) => state.departmentData);
  const { DocumentTable } = useSelector((state: RootState) => state.DocumentMasterData);
  const { ActiveServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);
  const { thirdPartyData } = useSelector((state: RootState) => state.thirdPartyData);
  const { ParentLocationTable, islocationAdded } = useSelector((state: RootState) => state.locationMasterData);
  const { industryTable } = useSelector((state: RootState) => state.indutryMasterData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { CompanyComprehensiveData, CompanyComprehensiveAdded, CompanyComprehensiveFailed, ProbeToasterMsg } = useSelector((state: RootState) => state.ProbeData);
  let AddressTypeLOV = lovTable.filter((item: any) => item.Name === "Address Type") || [];
  let CompanyConstitutionLOV = lovTable.filter((item: any) => item.Name === "Company Constitution") || [];
  let VendorTypeILOV = lovTable.filter((item: any) => item.Name === LovConfig.VendorTypeI)
  let VendorTypeIILOV = lovTable.filter((item: any) => item.Name === LovConfig.VendorTypeII)
  const filteredRegulatorData = thirdPartyData.filter((item: any) => item.IsActive === "Active" && item.ThirdPartyTypeValue === "Regulator");
  let ActiveStatusID = lovTable.find((item: any) => item.Name === LovConfig.Status && item.Options === "Active")?.LovId || 0;
  let InActiveStatusID =  lovTable.find((item: any) => item.Name === LovConfig.VendorStatus && item.Options === "Inactive")?.LovId || 0
const { currentpageTitleData } = useSelector(
        (state: RootState) => state.FormTitleMasterData
      );


        const { handleRecordLockUpdate } = RecordLockRelease();
      
  const columns_version: any = {
    table_name: "Third Party History Records",
    isAction: false,
    columns: [],
  };

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
    dispatch(getThirdPartyList({ FormID: 0 }));
    dispatch(getIndustryList({ FormID: 2 }));
    dispatch(getDepartmentList({ FormID: 2 }));
    dispatch(getLocationList({ FormID: 2 }));
    dispatch(getServiceCatalogList());
    dispatch(getRegionalList({ FormID: 2 }));
    dispatch(getDocumentList({ FormID: 2, }));

  }, []);

  useEffect(() => {
    initializeData();
  }, []);
  useEffect(() => {
    dispatch(clearSignzyData());
  }, []);

  const getDraftData = () => {
    const dataObj = {
      UserId: userLoginResponse?.UserId,
      ModuleName: "Vendor Master",
      ScreenName: "Vendor Master",
    };
    dispatch(getAllSaveandDraftList(dataObj));
  };

  const initializeData = () => {
    dispatch(getVendorDetailsList());
    getDraftData();
  };

  useEffect(() => {
    if (isVendorDetailsAdded === true) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: vendorDetailsToasterMsg, isTosterFailed: vendorDetailsToasterOncloseStatus == "false" ? true : false }));
      dispatch(updateVendorDetailsAddandEdit(false));
      dispatch(clearProbeData())
      dispatch(updateLoader(false));
      if (vendorDetailsToasterStatus != false) {
        initializeData();
        setisShowDraft(1)
      }
    }
    else if (isVendorDetailsUpdated === true) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: vendorDetailsToasterMsg, isTosterFailed: vendorDetailsToasterOncloseStatus == "false" ? true : false }));
      dispatch(updateVendorDetailsAddandEdit(false));
      handleRecordLockUpdate();
      dispatch(clearProbeData())
      initializeData();
      setisShowDraft(1)
      dispatch(updateLoader(false));

    }
    else if (isVendorDetailsDeleted === true) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: vendorDetailsToasterMsg }));
      dispatch(updateVendorDetailsAddandEdit(false));
      setIsDeleteData(null);
      initializeData();
      setisShowDraft(1)
    }
  }, [isVendorDetailsAdded, vendorDetailsToasterStatus, isVendorDetailsUpdated, isVendorDetailsDeleted]);

  useEffect(() => {
    if (isDraftAdded) {
      dispatch(updateSaveasDraftAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
      initializeData();
      setisShowDraft(0)
    } else if (isDraftUpdated) {
      dispatch(updateSaveasDraftAddandEdit(false));
      handleRecordLockUpdate();
      dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
      initializeData();
      setisShowDraft(0)
    }
  }, [isDraftAdded, isDraftUpdated]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    if (versionHistory.VendorDetailsVHeader.length > 0 && versionHistory.VendorDetailsVHeader.length > 0) {
      if (versionHistory.VendorDetailsVHeader && versionHistory.VendorDetailsVHeader.length > 0) {
        setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory.VendorDetailsVHeader,
        };
        setVersionHistoryData(re_columns);
        console.log(versionHistory, "versionHistory ");

      }
    }
  }, [versionHistory]);

  useEffect(() => {
    setVendorDetails(vendorDetailsdata);
  }, [vendorDetailsdata]);

  useEffect(() => {
    let modifiedData: any[] = [];
    if (draftdata && Array.isArray(draftdata)) {
      draftdata &&
        draftdata.forEach((item: any) => {
          let JsonData = JSON.parse(item.DraftData);
          modifiedData.push({
            ...JsonData.VendorDetails,
            IsListedValue: JsonData.VendorDetails?.IsListed ? "Yes" : "No",
            IsRegulatedValue: JsonData.VendorDetails?.IsRegulated ? "Yes" : "No",
            DraftStatus: item.DraftStatus,
            DraftId: item.DraftId,
          });
        });
    }
    setVendorDetailsDraftData(modifiedData);
  }, [draftdata]);

  const handleOpen = async (data: any, option: string) => {

    if (option === "history") {
      setOption(option);
      dispatch(
        getVendorDetailsHistory({
          Vendor_Id: data.Vendor_Id,
          formID: FormIdConfig?.VendorFormId,
        })
      );
      setIsAddorEdit(false);
      // setIsVersionHistory(true);
    }
    else if (option === "delete") {

      setOption(Option);
      const res = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: data.Vendor_Id });
      setIsDeleteData(res.data);    
        // console.log(data, "data");
      // const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.Vendor_Id, FormID: 22 });
      // const Edit_data: any = response;
      // setIsAddorEdit(false);

      // if (Edit_data?.data === true) {
      //   setOption(Option);
      // const res = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: data.Vendor_Id });
      //  setIsDeleteData(res.data);      
      // } else {
      //   dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));
      // }
    }
    else if (option === "edit" && isShowDraft) {
      // console.log(data, "data");

      const res = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: data.Vendor_Id });
      setIsAddorEdit(true);
      setOption(option);
      setIsEditDate(res.data);
      setIsDraftData(false);

      // const response: any = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.Vendor_Id, FormID: 22 });
      // const Edit_data: any = response;
      // console.log(Edit_data?.data, "Edit_data");

      // if (Edit_data?.data === true) {
      //   if (isShowDraft) {
         
      //   }
      // } else {

      //   dispatch(updateLoader(false));
      //   dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

      // }
    }
   
   
   
    else {
      if (isShowDraft) {
        const res = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: data.Vendor_Id });
        setIsAddorEdit(true);
        setOption(option);
        setIsEditDate(res.data);
        setIsDraftData(false);
      }
      else {
        let filteredData = draftdata.find((item: any) => item.DraftId === data.DraftId);
        let JsonData = JSON.parse(filteredData?.DraftData);
        setIsAddorEdit(true);
        setOption(option);
        setIsEditDate({ ...JsonData, DraftStatus: filteredData.DraftStatus, DraftId: filteredData.DraftId });
        setIsDraftData(true);
      }
    }
  };

  const setAddFormcloseoropen = (value: any) => {
    setIsAddorEdit(value);
    setIsEditDate(null);
    // setIsDraftDate(null)
    setOption("");
    setIsDraftData(false);
  };

  const MCDataForDisplay = (data: any) => {
    console.log(data, "data");

    let industryName = industryTable?.find((item: any) => item.IndustryId === data?.VendorDetails?.Industry_Id)?.IndustryName || "";
    let SubindustryIds = data?.VendorDetails?.SubIndustry_Id.split(",")
    let Subindustryname = SubindustryIds.map((SubIndusID: string) =>
      industryTable.find((item: any) => item.IndustryId == SubIndusID)?.IndustryName
    ).join(", ");
    console.log(SubindustryIds, Subindustryname, "SubindustryIds");

    let AllienceTypeName = VendorTypeILOV.find((item: any) => item.LovId === data?.VendorDetails?.Type_Of_Vendor1)?.Options || "";

    let ThirdPartyTypeName = VendorTypeIILOV.find((item: any) => item.LovId === data?.VendorDetails?.Type_Of_Vendor2)?.Options || "";

    let ConstitutionName = CompanyConstitutionLOV.find((item: any) => item.LovId === data?.VendorDetails?.Constitution)?.Options || "";

    let JuridictionCityName = ParentLocationTable?.find((item: any) => item.Location_Id === data?.VendorDetails?.Jurisdiction)?.Location_Name || "";

    let ISRegulatorName = filteredRegulatorData?.find((item: any) => item.ThirdPartyId === data?.VendorDetails?.Regulator_Id)?.ThirdPartyName || "";
    const Assessment_Type = lovTable.find((item: any) => item.Name === LovConfig.Assessment_Type && item.LovId === data?.VendorDetails?.Assessment_TypeId)?.Options || "";
    let FilterSelectedServiceData = data?.VendorServiceMappings?.map((SCitem: any) =>
      ActiveServiceCatlogdata.find((item) => (SCitem?.ServiceCatalogId === item.ServiceCatalogId))

    );
    let FilterDocumentAttachData = data?.VendorAttachments?.map((Attachitem: any) =>
      DocumentTable.find((item: any) => (Attachitem?.DocumentId === item.DocumentId))

    );
    let OnboardingOwnerName = departmentTable?.find((item: any) => item.DepartmentId === data?.VendorDetails?.Service_Owner)?.DepartmentName || "";

    let BasicDetails: any = {
      ThirdPartyType: ThirdPartyTypeName,
      ThirdPartyName: data?.VendorDetails?.Legal_Name,
      ThirdPartyBrandName: data?.VendorDetails?.Brand_Name,
      ThirdPartyWepsite: data?.VendorDetails?.WebSite,
      ContactNumber: data?.VendorDetails?.Contact_Number,
      EmailID: data?.VendorDetails?.EmailId,
      ThirdPartyGroupName: data?.VendorDetails?.Group_Name,
      Headquater_Country: data?.VendorDetails?.Headquater_Country,
      AllienceType: AllienceTypeName,
      InCorporatedYear: data?.VendorDetails?.Incorporated_Date ? dayjs(data?.VendorDetails?.Incorporated_Date)?.format("DD-MMM-YYYY") : null,
      Industry: industryName,
      SubIndustry: Subindustryname,
    }

    let ConstitutionDetails: any = {
      PAN: data?.VendorDetails?.PAN,
      TAN: data?.VendorDetails?.TAN,
      CIN: data?.VendorDetails?.CIN,
      Jurisdiction: JuridictionCityName,
      Constitution: ConstitutionName,
    }

    let RegulatorDetails: any = {
      IsListed: data?.VendorDetails?.IsListed ? "Yes" : "No",
      ISRegulated: data?.VendorDetails?.IsRegulated ? "Yes" : "No",
      RegulatorName: ISRegulatorName,
      Others_Regulator: data?.VendorDetails?.Others_Regulator,
    }

    let ThirdPartyProfiling: any = {
      KeyCustomer: data?.VendorDetails?.KeyCustomer,
      IndustriesCatered: data?.VendorDetails?.Industries_Served,
      VolumeHandled: data?.VendorDetails?.VolumeHandled,
      CompetitorDetails: data?.VendorDetails?.Competitor_Details,
      InternalThirdPartyCode: data?.VendorDetails?.Internal_VendorCode,
    }

    let ServiceCatlog: any = {
      ScopeOfService: data?.VendorDetails?.Scope_Of_Service,
      Exclusion: data?.VendorDetails?.Exclusion,
      OnboardingOwner: OnboardingOwnerName,
      AssessmentType: Assessment_Type,
      ServiceCatlogMapping: FilterSelectedServiceData,
    }

    let FormateDataForDisplay: any = {

      BasicDetailsData: BasicDetails,
      ConstitutionDetailsData: ConstitutionDetails,
      RegulatorDetailsData: RegulatorDetails,
      ThirdPartyProfilingData: ThirdPartyProfiling,
      ThirdPartyAddressData: data?.VendorAddress,
      VendorSpocsData: data?.VendorSpocs,
      VendorEscalationsData: data?.VendorEscalations,
      ServiceCatlogMappingData: ServiceCatlog,
      VendorAttachmentsData: FilterDocumentAttachData,
    }

    console.log(data, FormateDataForDisplay, "FormateDataForDisplay");
    return FormateDataForDisplay
  }

  console.log(isEditData, 'isEditData')
  const deleteThirdPartyList = () => {
    console.log(isDeleteData, "Isdeletedata");
    let JsonDataForDisplay = JSON.stringify(MCDataForDisplay(isDeleteData));

    let formData = {
      Id: isDeleteData?.VendorDetails?.Vendor_Id,
      Name: isDeleteData?.VendorDetails?.Legal_Name,
      Status: isDeleteData?.VendorDetails?.Status_Value === "Active" ? InActiveStatusID : ActiveStatusID,
      Updated_By: userLoginResponse?.UserName,
      ModuleName: "Vendor Master",
      IsActive: isDeleteData?.VendorDetails?.Status_Value === "Active" ? false : true,
      Activity: isDeleteData?.VendorDetails?.Status_Value === "Active" ? "inactive" : "active",
      UserId: userLoginResponse?.UserId,
      MenuId: currentPagePermissionData?.MenuId,
      Form_Id: 22,
      DataForDisplay: JsonDataForDisplay,
    };
    dispatch(
      deleteVendorDetails((formData))
    );

  };

  return (
    <>
      {!isAddorEdit ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
             
              <Typography variant="h6" color={"text.primary"}>
               
                {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}

              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={HeaderColumn}
                rows={isShowDraft ? vendorDetails : vendorDetailsDraftData}
                handleAction={handleOpen}
                handleAdd={() => setAddFormcloseoropen(true)}
                enableZoomIcon={true}
                showhistory={true}
                isDraftView={true}
                draftCount={vendorDetailsDraftData.length}
                isEnableDraft={isShowDraft}
                handleIsDraft={(val: any) => setisShowDraft(val)}
                showAdd={permssionPage?.IsAdd}
                showDelete={true}
                showView={permssionPage?.IsView}
                showEdit={permssionPage?.IsEdit}
                showExports={permssionPage?.IsExport}
                isEnableRecordLock = {{ IdField : "Vendor_Id" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                                  FormID : FormIdConfig?.VendorFormId,
                                                
                                                  MenuID : currentPagePermissionData?.MenuId,
                                                  IsCheckPending : true  }}}
                                // userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                userId={userLoginResponse?.UserId || 0}
                pageName="Third Party Master"
              />
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <AddVendorDetails
            view={Option == "view" ? true : false}
            edit={Option == "edit" ? true : false}
            isEditData={isEditData}
            SignzyData={SignzyData}
            onClose={() => {
              handleRecordLockUpdate();
              setAddFormcloseoropen(false);
              dispatch(clearProbeData())
            }}
            isDraftData={isDraftData}
          />
        </Box>
      )}

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
          dispatch(updateVersionHistory([]));
        }}
        maxWidth={"lg"}
        // header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Third Party Master"} Version History`}
        // header={"Third Party Master Version History"}
        header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Third Party"} Version History`}

        contentNode={
          <> <MaterialDynamicGrid
            data={versionHistoryData}
            rows={versionHistory.VendorDetailsRecords}
            handleAction={handleOpen}
            enableZoomIcon={false}
            showhistory={false}
            showDelete={permssionPage?.IsDelete === 1}
            showView={permssionPage?.IsView === 1}
            showEdit={permssionPage?.IsEdit === 1}
            showExports={permssionPage?.IsExport === 1}
            userId={(userLoginResponse && userLoginResponse?.UserId) ? userLoginResponse?.UserId : 0}
            pageName="Third Party Master Version History"
          />
          </>
        }
      />
      <ModalPopUp
        onDeleteMethod={() => deleteThirdPartyList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.VendorDetails?.Status_Value === "Active" ? "deactivate" : "activate"} the selected Third Party`}
        ActionName={isDeleteData?.VendorDetails?.Status_Value === "Active" ? "Inactive" : "Active"}

      />
    </>
  );
};

export default VendorDetailsMaster;
