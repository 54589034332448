import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store/store";
import { useParams } from "react-router-dom";
import { getLogginVendor, logoutUser } from "../store/slices/authorization";
import { updateLoader } from "../store/slices/loader";
import VendorLoginpage from "./vendorLogin/VendorLoginPage";
import { PMVendorRespondor } from "./pmResponder/PMResponder";

export const PMVendorPortal: React.FC = () => {
    const dispatch = useAppDispatch();

    const { isVendorAuthenticated  , isLoading , vendorLoginDetails } = useSelector((state: RootState) => state.loggerData);
  
    useEffect(() => {

        // dispatch(updateLoader( isLoading));
    
      }, [ isLoading]);

      useEffect(()=>{
        dispatch(logoutUser(false));
      },[])

     return(
        <>
         <Box sx={{p:!isVendorAuthenticated? "": 2  , overflowY:'scroll' , height:'100vh'  }} >
           {(isVendorAuthenticated && vendorLoginDetails?.vendorId)  ? <PMVendorRespondor/> : <VendorLoginpage /> }
        
        </Box>
        </>
     )
}