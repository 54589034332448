import React, { useEffect, useRef, useState } from "react";
import ModalPopUp from "../modalComponent/ModalPopUp";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ApplicationParameterName, StatusConfig } from "../../config/config";

interface InactivityTimerProps {
  edit?: boolean;
  timeout?: number;
  onTimeout?: () => void;
  renderPrompt?: (isVisible: boolean, handleResponse: (value: boolean) => void) => React.ReactNode;
}

const InactivityTimer: React.FC<InactivityTimerProps> = ({
  edit,
  timeout = 100000,
  onTimeout = () => {},
  renderPrompt,
}) => {
  const [isPromptVisible, setIsPromptVisible] = useState<boolean>(false);
  const [timeouts, setTimeouts] = useState<number | null>(null);
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);

  const inactivityTimer = useRef<NodeJS.Timeout | null>(null);

  const resetInactivityTimer = () => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }

    inactivityTimer.current = setTimeout(() => {
      setIsPromptVisible(true);
      onTimeout();
    }, timeouts || timeout);
  };

  const handlePromptResponse = (value: boolean) => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
    setIsPromptVisible(false);
    if (value) {
      resetInactivityTimer();
    }
  };

  useEffect(() => {
    if (edit) {
      const RecordTimeOut: any = ApplicationParameterName.RecordTimeOut;
      // let testing = applicationParameterData.filter((i:any )=>{
      //   if( i.Parameter_Name!= RecordTimeOut &&
      //     i.Parameter_Group != RecordTimeOut){
      //     return i
      //   }

       



      // })
     
      let findRecordTimeOut = applicationParameterData.find(
        (applicationParamitem) =>
          
          applicationParamitem?.Parameter_Name === RecordTimeOut &&
          applicationParamitem?.Parameter_Group === RecordTimeOut &&
          applicationParamitem?.IsActive == StatusConfig.Active
      )?.Parameter_Value || 0;
      if(findRecordTimeOut !==0){
        setTimeouts(Number(findRecordTimeOut));

      }
    }
  }, [edit, applicationParameterData]);

  const modelPopUp = (isVisible: boolean, handleResponse: (value: boolean) => void) => (
    <ModalPopUp
      onDeleteMethod={() => {}}
      isDelete={false}
      onHide={() => handleResponse(false)}
      show={isVisible}
      header={"Confirm"}
      text={`Are you still editing?`}
      ButtonName={"Yes"}
    />
  );

  useEffect(() => {
    const handleUserActivity = () => {
      resetInactivityTimer();
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    resetInactivityTimer();

    return () => {
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, [timeouts]);

  return <>{timeouts && modelPopUp(isPromptVisible, handlePromptResponse)}</>;
};

export default InactivityTimer;
