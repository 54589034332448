import { Box, Dialog, DialogContent, Stack, Typography, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddUser from "./AddUser";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";

import { updateAddandEdit, updateVersionHistory } from "../../store/slices/vendorData";
import Close from "@mui/icons-material/Close";
import CustomButton from "../../common/dynamicButton/CustomButton";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { IUser } from "../../models/user.type";
import {
  addUser,
  deleteUser,
  getAllRoleMasterList,
  getUserHistory,
  getUsersList,
  updateUserAddandEdit,
} from "../../store/slices/userData";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { IMenu } from "../../models/loggin.type";
import ToasterCompnent from "../../toast/Toaster";
import { updateToaster,updateLoader } from "../../store/slices/loader";

import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { getDefaultValues, updateDefualtAddAndEdit } from "../../store/slices/defaultValues";
import { getALLMenus, getRolesList } from "../../store/slices/roleData";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";
import { getDepartmentList } from "../../store/slices/departmentData";
import { getLovList } from "../../store/slices/lovMasterData";
import { getLocationList, getParentLocationList } from "../../store/slices/locationMasterData";
import { FormIdConfig, ToasterActivityConfig, userConfig } from "../../config/config";
import { GetBranchByCompanyList } from "../../store/slices/branchMasterData";
import { Edit_Enable_tosterMSG, LocationConfig, LovConfig } from "../../config/config";
import masterEditEnableService from "../../store/services/masterEditEnableService";
import { addMenu } from "../../utils/constants";
import { IRole } from "../../models/roles.type";
import loginService from "../../store/services/loginService";
import { RecordLockRelease } from "../recordLock/CommonRecordLock";

const UserManagement = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [addDefault, setAddDefault] = useState(false);
  const [Option, setOption] = useState("");
  const [isEditUserData, setisEditUserData] = useState<IUser | null>(null);
  const [editId, setEditId] = useState<any>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const dispatch = useAppDispatch();
  const { allUsers, isUserAdded, isUserUpdated, isUserDeleted, ToasterMsg, versionHistory, roleMaster } = useSelector(
    (state: RootState) => state.userData
  );

  const { DefaultId, DefaultData, isDefaultValuedAddedd, isDefaultValuedUpdated } = useSelector(
    (state: RootState) => state.defaultData
  );

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { allMenuData } = useSelector( (state: RootState) => state.roleData);
  const columns_version: any = {
    table_name: "User History Records",
    isAction: false,
    columns: [],
  };
  const [defaultData, setDefaultData] = useState<IUser | null>(null);

  const [usersData, setUsersData] = useState<IUser[]>([]);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [userAdded, setUserAdded] = useState(false);
  const [userUpdated, setUserUpdated] = useState(false);
  const [userDeleted, setUserDeleted] = useState(false);
  const [userId, setUserId] = useState<number>();
  const [deleteUserPopUp, setDeleteUserPopUp] = useState<boolean>(false);
  const [historyPopUp, setHistoryPopUp] = useState<boolean>(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [vendorHisHeaderColumn, setVendorHisHeaderColumn] = useState(columns_version);
  const [versionHistoryData, getVersionHistoryData] = useState<any>();
  const [designation, setDesignation] = useState<any[]>([]);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { BranchByCompanyTable } = useSelector((state: RootState) => state.BranchMasterData);
  const [menuScreensData, setMenuScreens] = useState([]);
  const [isEditRoleData, setisEditRoleData] = useState<IRole | null>(null);
  const { allRoles } = useSelector((state: RootState) => state.roleData);
  const [existingMenu, setExistingMenu] = useState<any[]>([]);
  const { dataTable } = useSelector((state: RootState) => state.departmentData);


  const { currentpageTitleData } = useSelector(
    (state: RootState) => state.FormTitleMasterData
  );
  const { handleRecordLockUpdate } = RecordLockRelease();
  const columns: any = {
    table_name: "User",
    isAction: true,
    columns: [
      // {
      //   Field: "UserId",
      //   DisplayName: "UserId",
      //   FieldType: "number",
      // },
      {
        Field: "FirstName",
        DisplayName: "First Name",
        FieldType: "string",
      },
      {
        Field: "LastName",
        DisplayName: "Last Name",
        FieldType: "string",
      },
      {
        Field: "EmailId",
        DisplayName: "Email",
        FieldType: "string",
      },
      {
        Field: "Mobile",
        DisplayName: "Phone Number",
        FieldType: "number",
      },
      {
        Field: "Country",
        DisplayName: "Country",
        FieldType: "string",
      },
      {
        Field: "DepartmentName",
        DisplayName: "Department Name",
        FieldType: "string",
      },
      {
        Field: "ManagerEmail",
        DisplayName: "Reporting To",
        FieldType: "string",
      },
      {
        Field: "OfficeAddress",
        DisplayName: "Address",
        FieldType: "string",
      },
      {
        Field: "BranchName",
        DisplayName: "Branch",
        FieldType: "string",
      }, 
      {
        Field: "DesignationName",
        DisplayName: "Designation",
        FieldType: "string",
      },
      {
        Field: "RoleName",
        DisplayName: "Role",
        FieldType: "string",
      },
      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",
      },
      {
        Field: "Updated_By",
        FieldType: "string",
        DisplayName: "Modified By",
      },
    ],
  };

  useEffect(() => {
    dispatch(
      getUsersList({
        FormID: 2,
      })
    );
    dispatch(getLovList({ Name: "" }));
    // dispatch(getParentLocationList({ LocationTypeId: userConfig.Country }));
    // dispatch(getParentLocationList({ LocationTypeId: userConfig.State }));
    dispatch(
      getLocationList({
        FormID: 2,
      })
    );
    dispatch(getAllRoleMasterList({ get: "roles" }));
    dispatch(
      getDefaultValues({
        ModuleName:  currentPagePermissionData?.MenuName || "",
        ScreenName: currentPagePermissionData?.MenuName || "",
      })
    );
    dispatch(
      getDepartmentList({
        FormID: 2,
      })
    );
    dispatch(
      GetBranchByCompanyList({
        CompanyId: 0,
      })
    );
  }, []);

  // useEffect(() => {
  //   if (versionHistory?.userHistories?.length > 0 && versionHistory?.UserVHeader?.length > 0) {
  //     setIsEdit(true);
  //     setOption("history");
  //     if (versionHistory.UserVHeader && versionHistory.UserVHeader.length > 0) {
  //       let re_columns = {
  //         ...columns_version,
  //         columns: versionHistory.UserVHeader,
  //       };
  //       console.log(versionHistory, versionHistory.UserVHeader, "versionHistoryversionHistory");
  //       setVendorHisHeaderColumn(re_columns);
  //     }
  //   }
  // }, [versionHistory]);

  useEffect(() => {
    const isVersionHistoryClick = () => {
      if (versionHistory?.userHistories?.length > 0 && versionHistory?.UserVHeader?.length > 0) {
        // setIsEdit(true);
        // setOption("history");
        if (versionHistory.UserVHeader && versionHistory.UserVHeader.length > 0) {
          let re_columns = {
            ...columns_version,
            columns: versionHistory.UserVHeader,
          };
          setVendorHisHeaderColumn(re_columns);
        }
      }
    };
    isVersionHistoryClick();
  }, [versionHistory]);

  useEffect(() => {
    if (allUsers.length > 0) {
      setUsersData(allUsers);
    }
  }, [allUsers]);

  useEffect(() => {
    if (isUserAdded) {
      // setUserAdded(true);
      dispatch(updateUserAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
      dispatch(
        getUsersList({
          FormID: 2,
        })
      );
    } else if (isUserUpdated) {
      // setUserUpdated(true);
      dispatch(updateUserAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
      dispatch(
        getUsersList({
          FormID: 2,
        })
      );
    } else if (isUserDeleted) {
      //setUserDeleted(true);
      setDeleteUserPopUp(false);
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
      setTimeout(() => {
        dispatch(updateUserAddandEdit(false));
      }, 1000);
      dispatch(
        getUsersList({
          FormID: 2,
        })
      );
    } else if (isDefaultValuedAddedd) {
      dispatch(
        getDefaultValues({
          ModuleName:  currentPagePermissionData?.MenuName || "",
          ScreenName: currentPagePermissionData?.MenuName || "",
         
        })
      );
      dispatch(updateToaster({ isToaster: true, toasterMsg: "Default Values Update Successfully" }));
      dispatch(updateDefualtAddAndEdit(false));
    } else if (isDefaultValuedUpdated) {
      dispatch(
        getDefaultValues({
          ModuleName:  currentPagePermissionData?.MenuName || "",
          ScreenName: currentPagePermissionData?.MenuName || "",
        })
      );
      dispatch(updateToaster({ isToaster: true, toasterMsg: "Default Values Update Successfully" }));
      dispatch(updateDefualtAddAndEdit(false));
    }
  }, [isUserAdded, isUserUpdated, isUserDeleted, isDefaultValuedAddedd, isDefaultValuedUpdated]);

  useEffect(() => {
    dispatch(
      getRolesList({
        formId: 3,
      })
    );
    dispatch(getALLMenus(null));
  }, []);
  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    if (DefaultData && DefaultData !== "") {
      setDefaultData(JSON.parse(DefaultData));
    }
  }, [DefaultData]);

  useEffect(() => {
    const filtered = lovTable.filter((item: any) => item.Name === LovConfig.Designation) || []
    setDesignation(filtered);
  }, [lovTable]);

  // const deleteRoleId = () => {
  //   dispatch(
  //     deleteUser({
  //       UserId: userId,
  //       CreatedBy: user?.UserName,
  //       Activity: "delete",
  //       ModuleName: "User Management",
  //     })
  //   );
  // };



  useEffect(() => {
    if (!isEdit) {
      handleRecordLockUpdate()
    }
  }, [isEdit]);

  const selectAllEnable = (item: any): number => {
    let { IsAdd, IsEdit, IsDelete, IsView, IsExport, IsImport, IsApproval, ...rest } = item;
    return IsAdd && IsEdit && IsDelete && IsView && IsExport && IsApproval ? 1 : 0;
  };

  const mapSubMenus = (item: any, role: IMenu | undefined) => {
    if (item.SubMenus && item.SubMenus.length > 0) {
      let copyOfItem = { ...item };
      copyOfItem.SubMenus = copyOfItem.SubMenus.map((item: any) => {
        const res = role?.SubMenus?.filter((data) => data.MenuId === item.MenuId)?.[0]
        let mappedItem = mapSubMenus(item, res);
        return { ...mappedItem, disabled: res?.disabled };
      });
      item = copyOfItem;
    } else {
      item = { ...item, ...addMenu, Select: 0 };
    }
    return { ...item };
  };
  
  const mapMenuBasedOnPermission = (userBasedMenu: any, pageMenuData: any, selectAll: number) => {
    let pageMenu = { ...pageMenuData };
    if (pageMenu.SubMenus && pageMenu.SubMenus.length > 0) {
      let subMenuSelectALL = selectAll;
      pageMenu.SubMenus.map((pageSubMenuitem: any, index: number) => {
        if (userBasedMenu.SubMenus.length > 0) {
          let userBasedMenuItem = userBasedMenu.SubMenus.filter(
            (userBasedMenusubItem: any) => userBasedMenusubItem.MenuId === pageSubMenuitem.MenuId
          );
          if (userBasedMenuItem.length > 0) {
            let mappedItem = mapMenuBasedOnPermission(userBasedMenuItem[0], pageSubMenuitem, selectAll);
            pageMenu.SubMenus[index] = mappedItem;
            if (subMenuSelectALL) {
              subMenuSelectALL = mappedItem.Select;
            }
          } else {
            subMenuSelectALL = 0;
            pageMenu.SubMenus[index] = { ...pageSubMenuitem };
          }
        } else {
          subMenuSelectALL = 0;
          pageMenu.SubMenus[index] = { ...pageSubMenuitem };
        }
      });
      return { ...pageMenu, Select: subMenuSelectALL };
    } 
    else {
      let selectAllValue = selectAllEnable({ ...pageMenu, ...userBasedMenu });
      return { ...pageMenu, ...userBasedMenu, Select: selectAllValue };
    }
  };

  const getJsonDataForDisplay =  () => {

    if (allMenuData.length > 0) {
      let copyoFMenuData: any = JSON.parse(JSON.stringify(allMenuData));
      const roleData:any =  isEditRoleData
      let manuplatemenuScreen = copyoFMenuData.map((item: any) => {
        const filterRole = roleData &&
          roleData.Menus &&
          roleData.Menus.length > 0 &&
          roleData.Menus.filter((data:any) => data.MenuId === item.MenuId)?.[0] || undefined
        item = mapSubMenus({ ...item }, filterRole);
        return { ...item, disabled: filterRole && filterRole?.disabled };
      });
      let selectAll = false;
      if (true) {
        selectAll = true;
        manuplatemenuScreen = manuplatemenuScreen.map((mappedMenu: any) => {
          const filterRole = roleData &&
            roleData.Menus &&
            roleData.Menus.length > 0 &&
            roleData.Menus.filter((data:any) => data.MenuId === mappedMenu.MenuId)
          mappedMenu = filterRole && filterRole?.length > 0
            ? mapMenuBasedOnPermission(
              filterRole[0],
              mappedMenu,
              1
            )
            : mappedMenu;

          if (selectAll && mappedMenu.Select) {
            selectAll = true;
          } else {
            selectAll = false;
          }
          return { ...mappedMenu, disabled: filterRole && filterRole[0]?.disabled };
        });
      }
      setMenuScreens(manuplatemenuScreen);
    }
  }

  const fetchMenuByUser = async () => {
    let res = await loginService.getMenuByUserId({ UserId: isDeleteData && isDeleteData.UserId })
    setExistingMenu(res?.data)
  }

  useEffect(() => {
    if (isDeleteData ) {
      fetchMenuByUser()
      if (isEditRoleData) getJsonDataForDisplay()
    }
  }, [isDeleteData, isEditRoleData]);

  useEffect(() => {
    if (isDeleteData && existingMenu) {
    setisEditRoleData(null);
    let selRole = isDeleteData.RoleId;

    if (selRole && allRoles.roles) {
      let selRoleMenu = allRoles.roles.find((item: any) => item.RoleId === selRole);
      let allMenu: IRole = JSON.parse(JSON.stringify(selRoleMenu))
      if (selRoleMenu) {
        // let GetMenu: IMenu[] = JSON.parse(JSON.stringify(selRoleMenu.Menus));
        // console.log("buvi ~ useEffect ~ GetMenu:", GetMenu)
        const r = allMenu?.Menus.map(i => {
          i.disabled = true;
          if (i.SubMenus) {
            i.SubMenus = i.SubMenus.map((sm) => {
              sm.disabled = true;
              if (sm.SubMenus) {
                sm.SubMenus = sm.SubMenus.map((s_sm) => {
                  s_sm.disabled = true;
                  return s_sm
                })
              }
              return sm
            })
          }
          return i
        })
        allMenu.Menus = [...r, ...existingMenu]
        setisEditRoleData(allMenu);
      }
    }
    }
  }, [isDeleteData,existingMenu]);

  const deleteUserFunc = () => {
    let formData = { ...isDeleteData };

    let JsonDataForDisplay: any = {
      "First Name": formData.FirstName,
      "Last Name": formData.LastName,
      "Email Id": formData.EmailId,
      "Mobile": formData.Mobile,
      "Reporting To": allUsers.find((item: any) => item.UserId == formData?.ManagerId)?.EmailId || '',
      "Country": formData.Country,
      "Office Address": formData.OfficeAddress,
      "Role": roleMaster && roleMaster.length > 0 ? roleMaster.find((item: any) => item.RoleId == formData?.RoleId)?.RoleName || '' : '',
      "Department":dataTable.find((item:any)=>item.DepartmentId == formData?.DepartmentId).DepartmentName,
      "Status": formData.IsActive  === "Active" ? "Inactive" : "Active",
      "DesignationId": designation.find((item: any) => item.LovId == formData?.DesignationId)?.Options || '',
      "BranchId": BranchByCompanyTable && BranchByCompanyTable.length > 0 ? BranchByCompanyTable.find((item: any) => item.BranchId == formData?.BranchId)?.BranchName || '' : '',
      "Modified By": user?.UserName,
      "userMenus": menuScreensData
    }

    const expectedPayload:any = {
      IsActive : formData.IsActive === "Active" ? 0 : 1,
      CreatedBy : formData?.Updated_By,
      createUser : formData?.UserRole,
      CreatedUserId :userLoginResponse?.UserId,
      UpdatedUserId : userLoginResponse?.UserId,
      DepartmentId : formData.DepartmentId,
      ManagerId : formData.ManagerId,
      RoleId : formData.RoleId,
      MenuId : permssionPage?.MenuId,
      OfficeAddress : formData.OfficeAddress,
      OfficeLocation : "",
      BranchId : formData.BranchId,
      Mobile : `${formData.Mobile}`,
      LastName : formData.LastName,
      FirstName : formData.FirstName,
      EmailId : formData.EmailId,
      Country : formData.Country,
      DesignationId : formData.DesignationId,
      Form_Id : FormIdConfig?.userMasterFormId,
      UserId : formData?.UserId,
      ModuleName : currentPagePermissionData?.MenuName,
       Updated_On : new Date(),
      Activity : formData.IsActive === "Active" ? ToasterActivityConfig.inactive : ToasterActivityConfig.active,
      DataForDisplay : JSON.stringify(JsonDataForDisplay),
      usersMenus : []
    };
    dispatch(addUser(expectedPayload));
    setIsDeleteData(null)
    // let JsonDataForDisplay: any = {
    //   "First Name": formData.FirstName,
    //   "Last Name": formData.LastName,
    //   "Email Id": formData.EmailId,
    //   "Mobile": formData.Mobile,
    //   "Reporting To": allUsers.find((item: any) => item.UserId == formData?.ManagerId)?.EmailId || '',
    //   "Country": formData.Country,
    //   "Office Address": formData.OfficeAddress,
    //   "Role": roleMaster && roleMaster.length > 0 ? roleMaster.find((item: any) => item.RoleId == formData?.RoleId)?.RoleName || '' : '',
    //   "Status": formData.IsActive ? "Active" : "InActive",
    //   "DesignationId": designation.find((item: any) => item.LovId == formData?.DesignationId)?.Options || '',
    //   "BranchId": BranchByCompanyTable && BranchByCompanyTable.length > 0 ? BranchByCompanyTable.find((item: any) => item.BranchId == formData?.BranchId)?.BranchName || '' : '',
    //   "Modified By": user?.UserName,
    //   // "userMenus": finalMenuData
    // }
    // console.log("buvi ~ deleteUserFunc ~ JsonDataForDisplay:", JsonDataForDisplay)

    // const expectedPayload:any = {
    //   "UserId": formData?.UserId,
    //   "FirstName": formData.FirstName,
    //   "LastName": formData.LastName,
    //   "CreatedBy": userLoginResponse?.UserName,
    //   "ModuleName": "User Management",
    //   "Activity": formData.IsActive === "Active" ? "inactive" : "active",
    //   "MenuId":  permssionPage?.MenuId,
    //   "Form_Id": 2,
    //   "IsActive": formData.IsActive ? 1 : 0,
    //   DataForDisplay : JSON.stringify(JsonDataForDisplay),
    // };

    // console.log("buvi ~ deleteUserFunc ~ expectedPayload:", expectedPayload)
    // dispatch(deleteUser(expectedPayload));
  };

  const handleOpen = async (data: any, option: string) => {
    if (option === "delete") {
    
        setUserId(data.UserId);
        setIsDeleteData(data);
        setDeleteUserPopUp(true);
     
    } else if (option === "history") {
      dispatch(
        getUserHistory({
          userId: data.UserId,
          formID: FormIdConfig.userMasterFormId,
        })
      );

      setIsVersionHistory(true);
    } else if (option === "edit") {
      setOption(option);
      setisEditUserData(data);
      setIsEdit(true);
    }
  else if (option === "view") {
    setOption(option);
    setisEditUserData(data);
    setIsEdit(true);
  }
  };


  return (
    <React.Fragment>
      <>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
            <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                            {/* Holiday Master */}
                        </Typography>
            </Box>

            {/* <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.08)",
                padding: "16px",
                borderRadius: "10px",
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <CustomSelect name={'location'} valueKey={'id'} optionLabelKey='lable' options={location_Data} value={formData.location}
                    onChange={handleChange}  required={true} label={'Location'}    />
                  
                </Grid>
           
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <CustomDatePicker    label={'Registered Date From'} value={
                          formData.registeredDate
                            ? dayjs(formData.registeredDate)
                            : null
                        }
                        onChange={(newValue: any) => {
                          setFormData({
                            ...formData,
                            registeredDate: newValue,
                          });
                        }}  />

                   
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                   
                    <CustomDatePicker    label={'Registered Date To'} 
                           value={
                            formData.EndDate ? dayjs(formData.EndDate) : null
                          }
                          onChange={(newValue: any) => {
                            setFormData({ ...formData, EndDate: newValue });
                          }}  />
                  </Grid>
       
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "10px" }}>
                    <CustomButton    variant="contained" name={'Search'}  onClick={(e: any) => {
                          onProcess(e);
                        }}/>
                       <CustomButton color="inherit"  variant="contained" name={'Clear'}  onClick={() => handleClear()}/>
                       
                     
                      
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box> */}

            {/* {errorMsg ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h5">
                    {" "}
                    Registered Date To should be greater than Registered Date
                    From
                  </Typography>
                </Grid>
              </Box>
            ) : null} */}

            {permssionPage?.IsAdd ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    columnGap: "5px",
                  }}
                >
                  <CustomButton variant="contained" name={"Set Default values"} onClick={() => setAddDefault(true)} />
                  {/* <CustomButton variant="contained" name={"Add User"} onClick={() => setAddForm(true)} /> */}
                </Grid>
              </Box>
            ) : null}

            <Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {/* <DevExpDynamicGrid
                  data={columns}
                  rows={usersData}
                  handleAction={handleOpen}
                  enableZoomIcon={true}
                  uniqueIdKey="id"
                  hideVersionhistory={true}
                /> */}

                <MaterialDynamicGrid
                  data={columns}
                  rows={usersData}
                  handleAction={handleOpen}
                  enableZoomIcon={true}
                  handleAdd={() => setAddForm(true)}
                  showAdd={true}
                  showhistory={true}
                  showDelete={true}
                  showView={permssionPage?.IsView === 1 ? true : false}
                  showEdit={permssionPage?.IsEdit === 1 ? true : false}
                  showExports={permssionPage?.IsExport === 1 ? true : false}
                  userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                  pageName="User"
                     isEnableRecordLock = { { IdField : "UserId" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                    FormID : FormIdConfig.userMasterFormId ,
                                    MenuID : currentPagePermissionData?.MenuId,
                                    IsCheckPending : true  }} }
                />
              </Box>
            </Box>
          </Stack>
        </Box>
        {/* <Dialog
          open={addForm}
          onClose={() => setAddForm(false)}
          maxWidth={"lg"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent>

            <AddUser
              view={false}
              closePopUp={() => {
                setAddForm(false);
              }}
            />
          </DialogContent>
        </Dialog> */}
        <CustomDialog
          show={addForm}
          onHide={() => setAddForm(false)}
          maxWidth={"lg"}
          minHeight={"50vh"}
          header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Create User"}`}
          contentNode={
            <AddUser
              view={false}
              closePopUp={() => {
                setAddForm(false);
              }}
              defaultdata={defaultData}
            />
          }
        />

        <CustomDialog
          show={addDefault}
          onHide={() => setAddDefault(false)}
          maxWidth={"lg"}
          minHeight={"50vh"}
          header={"User Default Values"}
          contentNode={
            <AddUser
              view={false}
              closePopUp={() => {
                setAddDefault(false);
              }}
              isDefault={true}
              defaultdata={defaultData}
              defaultId={DefaultId}
            />
          }
        />

        <CustomDialog
          show={isEdit}
          onHide={() => {
            if (Option === "history") {
              dispatch(updateVersionHistory([]));
            }
            setIsEdit(false);
          }}
          maxWidth={"lg"}
          minHeight={"50vh"}
          header={Option === "view" ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "User"}` : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "User"}`}
          contentNode={
            <>
              {Option === "edit" && (
                <AddUser
                  closePopUp={() => {
                    setIsEdit(false);
                  }}
                  edit={isEdit}
                  userdata={
                    isEditUserData && isEditUserData !== undefined && isEditUserData !== null ? isEditUserData : null
                  }
                />
              )}
              {Option === "view" && (
                <AddUser
                  view={true}
                  closePopUp={() => {
                    setIsEdit(false);
                  }}
                  userdata={
                    isEditUserData && isEditUserData !== undefined && isEditUserData !== null ? isEditUserData : null
                  }
                />
              )}
              {/* {Option === "history" && (
                <MaterialDynamicGrid
                  data={vendorHisHeaderColumn}
                  rows={versionHistory.userHistories}
                  handleAction={handleOpen}
                  enableZoomIcon={false}
                  showhistory={true}
                  showDelete={permssionPage?.IsDelete === 1}
                  showView={permssionPage?.IsView === 1}
                  showEdit={permssionPage?.IsEdit === 1}
                  showExports={permssionPage?.IsExport === 1}
                  pageName="User Management History"
                  userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                />
              )} */}
            </>
          }
        />
        {versionHistory.userHistories && (
          <CustomDialog
            show={isVersionHistory}
            onHide={() => {
              setIsVersionHistory(false);
            }}
            maxWidth={"lg"}
            header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "User"} Version History`}
            contentNode={
              <>
                {" "}
                <MaterialDynamicGrid
                  data={vendorHisHeaderColumn}
                  rows={versionHistory.userHistories}
                  handleAction={handleOpen}
                  enableZoomIcon={false}
                  showhistory={true}
                  showDelete={permssionPage?.IsDelete === 1}
                  showView={permssionPage?.IsView === 1}
                  showEdit={permssionPage?.IsEdit === 1}
                  showExports={permssionPage?.IsExport === 1}
                  pageName="User History"
                  userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                />
              </>
            }
          />
        )}
        {/* <ModalPopUp
          header="User Added"
          text="User Added SuccessFully"
          onHide={() => {
            setUserAdded(false);
            dispatch(updateAddandEdit(false));
          }}
          show={userAdded}
        /> */}
        {/* <ModalPopUp
          header="User Updated"
          text="User Updated SuccessFully"
          onHide={() => {
            setUserUpdated(false);
            dispatch(updateAddandEdit(false));
          }}
          show={userUpdated}
        /> */}
        {/* <ModalPopUp
          header="Role Deleted"
          text="Role Deleted SuccessFully"
          onHide={() => {
            setUserDeleted(false);
            dispatch(updateAddandEdit(false));
          }}
          show={userDeleted}
        /> */}
        {/* <ModalPopUp
          header="Delete User Confirmation"
          text={`Are you sure you want to deactivate the selected user`}
          isDelete={deleteUserPopUp}
          onHide={() => {
            setDeleteUserPopUp(false);
          }}
          onDeleteMethod={() => {
            deleteRoleId();
          }}
          show={deleteUserPopUp}
        /> */}
        <ModalPopUp
          onDeleteMethod={() => deleteUserFunc()}
          isDelete={true}
          onHide={() => setIsDeleteData(null)}
          show={isDeleteData ? true : false}
          header={"Confirm"}
          text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
            } the selected User`}
          ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
        />
        {/* {isUserAdded ? <ToasterCompnent
          msg = {ToasterMsg}
      />:null
    }
   
        {isUserUpdated ? <ToasterCompnent
          msg = {ToasterMsg}
      />:null
    } */}
      </>
    </React.Fragment>
  );
};

export default UserManagement;
