import React, { useEffect, useState } from 'react';
import { Box, Paper, Step, StepConnector, StepIconProps, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, stepConnectorClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch, useAppDispatchThunk } from "../../store/store";
import dayjs from "dayjs";
import { chkDate } from '../../utils/constants';


interface CustomDialogProps {
    steps: string[];
    activeStep: number;
    errorSteps?: number[];
    CompleteSteps?: number[];
    TooltipData?: any;
    handleAction?: (index: number, label: string) => void;
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderTop: `2px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#cccccc'}`,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderTop: `2px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#cccccc'}`,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderTop: `2px dashed ${theme.palette.mode === 'dark' ? theme.palette.grey[600] : '#cccccc'}`,
    },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean; error?: boolean } }>(
    ({ theme, ownerState }) => ({
        color: ownerState.error
            ? theme.palette.error.main
            : theme.palette.mode === 'dark'
                ? theme.palette.grey[700]
                : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',

        '& .QontoStepIcon-completedIcon': {
            color: '#129507',
            zIndex: 1,
            fontSize: 26,
        },
        '& .QontoStepIcon-errorIcon': {
            color: theme.palette.error.main,
            zIndex: 1,
            fontSize: 26,
        },
        '& .QontoStepIcon-circle': {
            width: 22,
            height: 22,
            borderRadius: '50%',
            backgroundColor: ownerState.error
                ? theme.palette.error.light
                : theme.palette.primary.light,
            position: 'relative',
            opacity: ownerState.active ? 1 : 0.6,
        },
        '& .QontoStepIcon-circle-center': {
            width: 14,
            height: 14,
            borderRadius: '50%',
            backgroundColor: ownerState.error
                ? theme.palette.error.main
                : theme.palette.primary.main,
            position: 'absolute',
            left: 4,
            top: 4,
            opacity: ownerState.active ? 1 : 0.6,
        },
    }),
);

function QontoStepIcon(props: StepIconProps) {
    const { active, completed, error } = props;

    return (
        <QontoStepIconRoot ownerState={{ active, error }}>
            {completed ? (
                <CheckCircleIcon className="QontoStepIcon-completedIcon" />
            ) : error ? (
                <ErrorIcon className="QontoStepIcon-errorIcon" />
            ) : (
                <div className="QontoStepIcon-circle">
                    <div className="QontoStepIcon-circle-center"></div>
                </div>
            )}
        </QontoStepIconRoot>
    );
}

export const CustomStatusStepper: React.FC<CustomDialogProps> = (props) => {
    const {
        steps = [],
        activeStep = 0,
        errorSteps = [],
        CompleteSteps = [],
        TooltipData = [],
    } = props;

    const StepsLength = steps?.length;
    const [openTooltip, setOpenTooltip] = React.useState<Array<boolean>>(Array(StepsLength).fill(false));
    const [openTooltipValue, setOpenTooltipValue] = useState<number | null>(null);

    const [onHover, setonHover] = React.useState<boolean>(false);
    const [TooltipTableData, setTooltipTableData] = React.useState<[]>([]);
    const [dataFormate, SetDateFormate] = useState("");

    const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);


    useEffect(() => {
        if (applicationParameterData.length > 0) {
            let dateFormat = applicationParameterData?.find(
                (item) => item.Parameter_Name === "Date Time Format" && item.Parameter_Group === "Date and Time"
            )?.Parameter_Value;
            if (dateFormat) SetDateFormate(dateFormat);
        } else SetDateFormate("DD-MM-YYYY hh:mm");
    }, [applicationParameterData]);


    const handleStepClick = (index: any, label: any) => {

        if (props.handleAction) {
            props.handleAction(index, label);
        }
        if (steps?.length > 0) {
            handleTooltipOpen(index);
        }

    }

    React.useEffect(() => {
        if (TooltipData) {
            const filteredData = TooltipData.filter(
                (item: any) => item.Process_Status !== "Not Started" && item.Process_Status !== "NA" && chkDate(item.Updated_On) !== ""
            );
            setTooltipTableData(filteredData);
        } else {
            setTooltipTableData([]);
        }
    }, [TooltipData]);

    const CustomTooltipContent = () => (
        TooltipTableData.length > 0 ? (
            <TableContainer>
                <Table>
                    <TableBody>
                        {TooltipTableData.map((row: any, index: any) => (
                            <TableRow key={index}>
                                <TableCell style={{ color: 'white', borderBottom: 'none' }}>
                                    {row.Updated_By || "-"}
                                </TableCell>
                                <TableCell style={{ color: 'white', borderBottom: 'none' }}>
                                    {row.Updated_On ? dayjs(chkDate(row.Updated_On)).format(dataFormate).toString() : "-"}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        ) : (
            <>{"No data available"}</>
        )

    );

    const handleTooltipOpen = (index: number) => {

        setOpenTooltipValue(openTooltipValue === index ? null : index);

    };


    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                {steps.map((label, index) => {

                    return (

                        // <Tooltip title={<CustomTooltipContent />} arrow key={index}>
                        <Tooltip
                            // onClose={() => handleTooltipOpen(null)}
                            open={onHover && openTooltipValue === index}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            arrow
                            title={<CustomTooltipContent />}
                        >

                            <Step key={`${label}- ${index + 1} - Status Flow`} onClick={() => { handleStepClick(index, label); setonHover(true); }} >

                                <StepLabel StepIconComponent={(props) => <QontoStepIcon {...props} error={errorSteps.includes(index)} completed={CompleteSteps.includes(index)} />}>
                                    {/* <StepLabel StepIconComponent={(props) => <QontoStepIcon {...props} error={errorSteps.includes(index)} />}> */}
                                    {label}
                                </StepLabel>
                            </Step>
                        </Tooltip>

                    )
                })
                }
            </Stepper >
        </Box >
    );
};