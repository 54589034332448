import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

import { useTheme } from "@mui/material/styles";
import { RootState, useAppDispatch } from '../../../../store/store';
import CommonDataExport from '../../../../common/CommonSheetsDownload/ExcelDownload';
import { AQMResponder } from '../../../../config/config';



interface BulkUploadQuestionCOBTemplateProps {
  AssessmentData : any[];
  header : any[];
  ApproveList : any[];
  applicableRiskData : any[];
  ResponderLV : any[];
}

export const ExportVOBAssessment : React.FC <BulkUploadQuestionCOBTemplateProps> = ({ResponderLV , applicableRiskData , AssessmentData , header , ApproveList }) => {

  const [Note, setNote] = useState<any>("");
  const [Data, setData] = useState<any>([]);
  const [headerData, setheaderData] = useState<any>([]);

useEffect(() => {

    if(header){
        let ModifiedData: any[] = [
            {
              DisplayName: "Category",
              Field: "Category",
            },
            ...header
              .filter((item: any) => item.label != "Attachment" && item?.condition)
              .map((item: any) => ({
                DisplayName: item.label,
                Field: item?.Field ? item?.Field : item.label,
              })),
          ];
         setheaderData(ModifiedData)
    }

},[header])

useEffect(() => {

    console.log(AssessmentData , 'AssessmentData' , applicableRiskData);
    let AssessmentDatawithOptions : any[] = []

    AssessmentData.forEach((item : any , index : any) => {
        item?.Questions.forEach((Q_item : any , Q_index : any) => {
            let obj : any ={...Q_item  ,Question : Q_item?.Question , Answer_Type :  Q_item?.Answer_Type ? "Multichoice" : "Free text" , response : ""   }
            obj.Category = item?.Options;
            let Responder : any = ResponderLV.find((item) => item.LovId == Q_item?.Responder)?.Options || "";
            if(Responder && Responder == AQMResponder?.SME && Q_item?.SME_Name){
                Responder = `${Responder} [${Q_item?.SME_Name}]`; 
            }
            obj.Responder = Responder;
            obj.SME_ReviewStatus_value = ApproveList?.find((Aitem : any) => Aitem.LovId == Q_item?.SME_ReviewStatus)?.Options || ""
            obj.DO_ReviewStatus_value = ApproveList?.find((Aitem : any) => Aitem.LovId == Q_item?.DO_ReviewStatus)?.Options || "" ;

             obj.IsAddInDo_value = Q_item?.IsAddInDo ? "Yes" : "No"
            obj.IsAddInPM_value = Q_item?.IsAddInPM ? "Yes" : "No"

            if(Q_item?.Answer_Type){
                let choiceResponse = Q_item?.choices.find((c_item : any) => c_item?.Score == Q_item?.ChoicesScore)?.Attribute_Response || "";  
                    let choice : any =    Q_item?.choices.map((c_item : any) => c_item?.Attribute_Response) || [];
                    obj.choice = choice
                  obj.response = choiceResponse || "";
                  let applicableRiskDataValue : any = applicableRiskData?.filter((Risk_item : any) => Q_item?.ApplicableRisks.includes(Risk_item?.RiskCategory_Id)) ;
                  if(applicableRiskDataValue){
                    applicableRiskDataValue.forEach((Risk_item : any) =>{
                        obj[Risk_item?.RiskCategory] = choiceResponse || ""          
                    })
                  }
               }else{
                  obj.response = Q_item?.Response_Received || ""
               }
               AssessmentDatawithOptions.push(obj)
        })
    })

    setData(AssessmentDatawithOptions)

},[AssessmentData])
 



     return(
        <>
           <CommonDataExport table_name={"Vendor_Assessment"}  button_title='Download' header={headerData}  data={Data}  />
        </>
     )
}