import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { addWorkingDays, textFieldWidth } from "../../../../utils/constants";
import LableandValuesPreview from "../../../../common/CommonPreview/LableandValuesPreview";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import PageMainHeading from "../../../../common/CommonHeadings/PageMainHeading";
import ExcelDownload from "../../../../common/CommonSheetsDownload/ExcelDownload";
import ComonCompareTable from "./ComonCompareTable";
import CustomBreadcrumbs from "../../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { RootState, useAppDispatch } from "../../../../store/store";
import { getCustomerAssessmentbycustomerIdorall } from "../../../../store/slices/cobAssessment";
import { useSelector } from 'react-redux';
import { CustomerDetailsSection } from "../../CommonPages/CustomerDetailsSection";
import CommonDataExport from "../../../../common/CommonSheetsDownload/ExcelDownload";



export interface CustomerOnBoardingAssessmentProps {
  onClose?: any;
  currentProcessData?: any;
  currentAssessmentData?: any;
  currentQuestionList?: any;
  customerDetails? : any
}


const PreviousAssessmentReviewSO: React.FC<CustomerOnBoardingAssessmentProps> = ({ onClose ,
   currentProcessData ,
    currentAssessmentData , currentQuestionList , customerDetails }) => {
  // const { exportExcelNew } = ExcelDownload()
  const [header, setHeader] = useState<any>([ 
    { "DisplayName": "Questions" , Field : 'question' },
     { "DisplayName": "Latest / Response" , Field : 'latestResponse' }, 
     { "DisplayName": "Latest / SME Department" , Field : 'latestSME' },
       { "DisplayName": "Latest / Responder "  , Field : 'latestResponder'  },
        { "DisplayName": "Current / Response" , Field : 'currentResponse' },
         { "DisplayName": "Current / Department" , Field : 'currentSME' }, 
         { "DisplayName": "Current / Responder" , Field : 'currentResponder' }, 
        
        ]);
  const dispatch = useAppDispatch();

  const [customerDetailsData, setcustomerDetailsData] = useState<any>({});
  const {CustomerAssessmentAlldata}=useSelector((state: RootState) => state.CustomerAssessmentData);
  const [latestProcessData, setLatestProcessData] = useState<any[]>([]);
  const [previousQuestions, setPreviousQuestions] = useState<any[]>([]);
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);

   const [latestcurrentQuestion, setLatestcurrentQuestion] = useState<any[]>([]);


  const closeSection = (value: any) => {
    if (value === 0) {
        onClose(1)
    }
};

useEffect(() => {
  if(customerDetails){
    setcustomerDetailsData(customerDetails)
  }
},[customerDetails])

useEffect(() => {
  if(currentProcessData){
    dispatch(getCustomerAssessmentbycustomerIdorall({ customerId: currentProcessData?.VendorId }));
  }
}, [currentProcessData]);

useEffect(() => {
  if (currentAssessmentData && currentQuestionList && CustomerAssessmentAlldata) {
    let CurrentQuestions : any[] = currentQuestionList;
    let LatestQuestions : any[] = []
    let finalcomparisonDataQuestions : any[] = []
    let findCurrentAssessmentId : any = currentAssessmentData?.Assessment_Questionnaire_Mapping_Id || 0;
    let findcurrentassessmentstartdate : any = currentAssessmentData?.Start_Date || null;
    // let findcurrentassessmentstartdate = CustomerAssessmentAlldata.find((item : any) => item.Assessment_Questionnaire_Mapping_Id == findCurrentAssessmentId)?.Start_Date
    let currentdate : any = new Date(findcurrentassessmentstartdate);
    
    
    let findlatestassessment : any = CustomerAssessmentAlldata.findLast((item : any) => item.Assessment_Questionnaire_Mapping_Id != findCurrentAssessmentId && new Date(item?.Start_Date) < currentdate )
      
     if (findlatestassessment && findlatestassessment?.AssessmentQuestionnaires){
      LatestQuestions =   findlatestassessment?.AssessmentQuestionnaires
     }
  console.log(CurrentQuestions , 'CurrentQuestions' , LatestQuestions);
  
     CurrentQuestions.map((item : any) => {
     let findLatestQuestions = LatestQuestions.find((litem) => item.Question == litem?.Question)
     console.log( findLatestQuestions , 'findLatestQuestions');

     let  currenQuestionAns : any =  item?.Answer_Type
     ? item?.Options.find(
         (oitem: any) => oitem?.Assessment_Questionnaire_OptionId == item?.Response_In_Option
       )?.Attribute_Response
     : item?.Response_In_Text;
     let latestQuestionAns : any = ""
     let attachment : any =" "
     if(findLatestQuestions){
        latestQuestionAns =  findLatestQuestions?.Answer_Type
       
      ? findLatestQuestions?.Options.find(

          (oitem: any) => oitem?.Assessment_Questionnaire_OptionId == findLatestQuestions?.Response_In_Option
        )?.Attribute_Response
      : findLatestQuestions?.Response_In_Text;
      if (findLatestQuestions.Answer_Type === true) {
        attachment = findLatestQuestions.Attachment || ""; 
     }

    
    }
     
     console.log(latestQuestionAns, 'latestQuestionAns')
     
      finalcomparisonDataQuestions.push({
        question: item.Question,
        latestResponse: latestQuestionAns || "",
        latestSME: findLatestQuestions?.SME_Name || null,
        latestResponder: findLatestQuestions?.Updated_By || null,
        currentResponse: currenQuestionAns || "",
        currentSME: item?.SME_Name || null,
        currentResponder: item.Updated_By || null,
        latestAssessment : findLatestQuestions || null,

        

    });
     })

     setLatestcurrentQuestion(finalcomparisonDataQuestions)
      
    }
    
  }
, [currentAssessmentData , CustomerAssessmentAlldata , currentQuestionList]);




return (
    <>
      <>
        <Stack spacing={3}>
        <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
                        <CustomBreadcrumbs
                            list={[
                                { value: 0, name: "Pending Action" },
                                { value: 0, name: "Customer OnBoarding Assessment" },
                                { value: 1, name: "Previous Assessment" }
                            ]}
                            onchange={closeSection}
                        />
                    </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <PageMainHeading title={`${customerDetailsData?.Vendor_Name || ""}`} />
          </Box>
       

          <CustomerDetailsSection isEditData={customerDetailsData} />

            <Divider></Divider>

          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>

            <Typography sx={{ height: '12px', color: '#959595', display: 'block', fontSize: '15px', fontWeight: "bold" }}>
              PREVIOUS ASSESSMENT
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
            <CommonDataExport
            header={header}
            data={latestcurrentQuestion}
            table_name={"PREVIOUS ASSESSMENT"}
            button_title={"Download"}
          />
            </Box>
          </Box>
          <ComonCompareTable latestcurrentQuestion={latestcurrentQuestion}   />
        </Stack>
      </>
           
    </>
  );
};

export default PreviousAssessmentReviewSO;
