import { Box, Grid, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import { textFieldWidth } from "../../utils/constants";
import CustomMultilineTextField from "../../common/dynamicInputs/CustomMultilineTextField";
import RemarkSectionApproval from "./RemarkSection";
import fileHandlerService from "../../store/services/fileHandlerService";
import CustomSvg from "../../common/CustomSvg";
import { useTheme } from "@mui/material/styles";
import * as mime from "react-native-mime-types";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import FilteViewer from "../../common/fileViewer/FilteViewer";

interface comapnymasterApprovaltbleProps {
  selectedGridRecord?: any;
  selectedRecordDetails: any;
  setSelectedRecordDetails?: any;
  closePopUp?: any;
  view?: boolean;
  edit?: boolean;
}

const CompanyMasterApproveReject: React.FC<comapnymasterApprovaltbleProps> = ({
  selectedGridRecord,
  selectedRecordDetails,
  setSelectedRecordDetails,
  closePopUp,
  view,
  edit,
}) => {
  const theme = useTheme();
  const [oldValues, setOldValues] = useState<any>("");
  const [oldValuesAddress, setOldValuesAddress] = useState<any>([]);
  const [oldValuesRepresentative, setOldValuesRepresentative] = useState<any>([]);
  const [newValues, setNewValues] = useState<any>("");
  const [newValuesAddress, setNewValuesAddress] = useState<any>([]);
  const [newValuesRepresentative, setNewValuesRepresentative] = useState<any>([]);
  const [header, setHeader] = useState<any>([]);
  const [openViewFileModel, setOpenViewFileModel] = useState(false);
  const [fileUrl, setFileUrl] = useState<string>("");
  const [fileName, setFileName] = useState<any>(undefined);




  useEffect(() => {
    if (selectedRecordDetails) {
      // if (selectedRecordDetails?.NewValues) {
      //   const newHeader: any = [{ DisplayName: "Fields" }, { DisplayName: "New Values" }];

      //   if (selectedRecordDetails?.OldValues?.CompanyAddressAsJson) {
      //     newHeader.push({ DisplayName: "Old Values" });
      //   }
      //   setHeader(newHeader);
      // }
      if (selectedRecordDetails?.NewValues) {
        const newHeader: any = [{ DisplayName: "Fields" }];

        if (selectedRecordDetails?.OldValues?.CompanyAddressAsJson) {
          newHeader.push({ DisplayName: "Old Values" });
        }

        newHeader.push({ DisplayName: "New Values" });

        setHeader(newHeader);
      }
      try {
        if (selectedRecordDetails?.OldValues?.CompanyAddressAsJson) {

          setOldValues(selectedRecordDetails?.OldValues);
          setOldValuesAddress(JSON.parse(selectedRecordDetails?.OldValues?.CompanyAddressAsJson));
          setOldValuesRepresentative(JSON.parse(selectedRecordDetails?.OldValues?.CompanyRepresentativeAsJson));
        }
        if (selectedRecordDetails?.NewValues) {
          setNewValues(selectedRecordDetails?.NewValues);
          setNewValuesAddress(JSON.parse(selectedRecordDetails?.NewValues?.CompanyAddressAsJson));
          setNewValuesRepresentative(JSON.parse(selectedRecordDetails?.NewValues?.CompanyRepresentativeAsJson));
        }
      } catch { }
    }
  }, [selectedRecordDetails]);


  console.log(selectedRecordDetails, "selectedRecordDetails");
  const ButtonClick = async (bloburl: any) => {
    console.log(bloburl,"url234");
    
    if (bloburl) {
      const fileName = decodeURIComponent(bloburl.substring(bloburl.indexOf("_") + 1));
      const response: any = await fileHandlerService.DownLoadAFile({ fileUrl: bloburl });
      const { data } = response;
      const mimeType = mime.lookup(fileName.substring(fileName.lastIndexOf(".") + 1));

      setFileName(fileName as string);
      setFileUrl(`data:${mimeType};base64,${data}`);
      setOpenViewFileModel(true);
    }
  };

  const handleDialogHide = () => {
    if (openViewFileModel) {
      setOpenViewFileModel(false);
    }
  };
  const CompanyDisplayColumn: any = [
    {
      Field: "Legal Name",
      FieldType: "string",
      DisplayName: "Legal Name",
    },
    {
      Field: "Brand Name",
      FieldType: "string",
      DisplayName: "Brand Name",
    },
    {
      Field: "Short Name",
      FieldType: "string",
      DisplayName: "Short Name",
    },
    {
      Field: "Parent Company Name",
      FieldType: "string",
      DisplayName: "Parent Company Name",
    },
    {
      Field: "Company Type",
      FieldType: "string",
      DisplayName: "Company Type",
    },
    {
      Field: "Group Name",
      FieldType: "string",
      DisplayName: "Group Name",
    },
    {
      Field: "Industry",
      FieldType: "string",
      DisplayName: "Industry",
    },
    {
      Field: "Sector",
      FieldType: "string",
      DisplayName: "Sector",
    },
    {
      Field: "Website",
      FieldType: "string",
      DisplayName: "Website",
    },
    {
      Field: "CIN",
      FieldType: "string",
      DisplayName: "CIN",
    },
    {
      Field: "TAN",
      FieldType: "string",
      DisplayName: "TAN",
    },
    {
      Field: "PAN",
      FieldType: "string",
      DisplayName: "PAN",
    },
    {
      Field: "Constitution",
      FieldType: "string",
      DisplayName: "Constitution",
    },
    {
      Field: "Jurisdiction",
      FieldType: "string",
      DisplayName: "Jurisdiction",
    },
    {
      Field: "Line Of Business Mapping",
      FieldType: "string",
      DisplayName: "Line Of Business Mapping",
    },

    {
      Field: "Department Mapping",
      FieldType: "string",
      DisplayName: "Department",
    },
    {
      Field: "Regulator",
      FieldType: "string",
      DisplayName: "Regulator",
    },
    {
      Field: "About",
      FieldType: "string",
      DisplayName: "About",
    },
    {
      Field: "Status",
      FieldType: "string",
      DisplayName: "Status",
    },
    {
      Field: "Modified By",
      FieldType: "string",
      DisplayName: "Modified By",
    },
    {
      Field: "Logo",
      FieldType: "image",
      DisplayName: "Logo",
    },
  ];



  const renderAddressRow = (displayName: string, oldValue?: any, newValue?: any, addType?: any) => (
    <TableRow key={`${displayName}-${addType || ""}`}>
      <TableCell sx={{ textAlign: "left", border: "2px solid #f1f1f1" }}>{addType || ""}</TableCell>
      {oldValuesAddress?.length ? (
        <TableCell sx={{ textAlign: "left", border: "2px solid #f1f1f1" }}>
          {oldValue !== undefined ? displayName + " - " + oldValue : ""}
        </TableCell>
      ) : null}
      <TableCell sx={{ textAlign: "left", border: "2px solid #f1f1f1" }}>
        {newValue !== undefined ? displayName + " - " + newValue : ""}
      </TableCell>

    </TableRow>
  );

  return (
    <>
      <Stack>
        <Box>
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            Company Details
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-column Section company Master approval`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {CompanyDisplayColumn.map((col: any) => (
                <TableRow>
                  <TableCell
                    key={`${col.DisplayName}-column Section company Master approval`}
                    sx={{
                      textAlign: "left",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                  {oldValues ? (
                    <TableCell
                      key={`${col.DisplayName}-column Section company Master approval`}
                      sx={{
                        textAlign: "left",

                        border: "2px solid #f1f1f1",
                      }}
                    >
                      {col.FieldType !== "image" ? oldValues[col.Field] : oldValues[col.Field] && (
                        <Tooltip title="View">
                          <IconButton
                            onClick={() => {
                              console.log(oldValues[col.Field]?.toString(),"234",oldValues);
                              
                              ButtonClick(oldValues[col.Field]?.toString());
                            }}
                          >
                            <CustomSvg color={`${theme.palette.info.main}`} size={"20px"} name={"view"} />
                          </IconButton>
                        </Tooltip>
                      )}

                    </TableCell>
                  ) : null}
                  {newValues ? (
                    <TableCell
                      key={`${col.DisplayName}-column Section company Master approval`}
                      sx={{
                        textAlign: "left",
                        border: "2px solid #f1f1f1",
                      }}
                    >
                      {col.FieldType !== "image" ? newValues[col.Field] : newValues[col.Field] && (
                        <Tooltip title="View">
                          <IconButton
                            onClick={() => {
                              console.log(newValues[col.Field]?.toString(),"234",newValues);
                              ButtonClick(newValues[col.Field]?.toString());
                            }}
                          >
                            <CustomSvg color={`${theme.palette.info.main}`} size={"20px"} name={"view"} />
                          </IconButton>
                        </Tooltip>
                      )}

                    </TableCell>
                  ) : null}

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        {/* company address section */}
        <Box>
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            Company Address Details
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-column Section company Master approval`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {/* <TableBody>
              {Array.from({ length: Math.max(newValuesAddress?.length || 0, oldValuesAddress?.length || 0) }).map(
                (_, index: number) => {
                  const newAddress = newValuesAddress ? newValuesAddress[index] : null;
                  const oldAddress = oldValuesAddress ? oldValuesAddress[index] : null;
                  console.log(newAddress, oldAddress, "ON")

                  return (
                    <React.Fragment key={`address-row-${index}`}>
                      {renderAddressRow(
                        "Address Number",
                        newAddress?.Address_Number,
                        oldAddress?.Address_Number,
                        `Address ${index + 1}`
                      )}
                      {renderAddressRow("Address Type", newAddress?.Address_Type_Value, oldAddress?.Address_Type_Value)}
                      {renderAddressRow("Address", newAddress?.Address, oldAddress?.Address)}
                      {renderAddressRow("Pin", newAddress?.Pin, oldAddress?.Pin)}
                      {renderAddressRow("City", newAddress?.City, oldAddress?.City)}
                      {renderAddressRow("State", newAddress?.State, oldAddress?.State)}
                      {renderAddressRow("Country", newAddress?.Country, oldAddress?.Country)}
                      {renderAddressRow("Status", newAddress?.status_value, oldAddress?.status_value)}
                    </React.Fragment>
                  );
                }
              )}
            </TableBody> */}
            <TableBody>
              {Array.from({ length: Math.max(newValuesAddress?.length || 0, oldValuesAddress?.length || 0) }).map(
                (_, index: number) => {
                  const newAddress = newValuesAddress ? newValuesAddress[index] : null;
                  const oldAddress = oldValuesAddress ? oldValuesAddress[index] : null;
                  console.log(newAddress, oldAddress, "ON");

                  return (
                    <React.Fragment key={`address-row-${index}`}>
                      {renderAddressRow(
                        "Address Number",
                        oldAddress?.Address_Number,
                        newAddress?.Address_Number,
                        `Address ${index + 1}`
                      )}
                      {renderAddressRow("Address Type", oldAddress?.Address_Type_Value, newAddress?.Address_Type_Value)}
                      {renderAddressRow("Address", oldAddress?.Address, newAddress?.Address)}
                      {renderAddressRow("Pin", oldAddress?.Pin, newAddress?.Pin)}
                      {renderAddressRow("City", oldAddress?.City, newAddress?.City)}
                      {renderAddressRow("State", oldAddress?.State, newAddress?.State)}
                      {renderAddressRow("Country", oldAddress?.Country, newAddress?.Country)}
                      {renderAddressRow("Status", oldAddress?.status_value, newAddress?.status_value)}
                    </React.Fragment>
                  );
                }
              )}
            </TableBody>
          </Table>
        </Box>

        {/* company address section */}
        <Box>
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            Company Representative
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-column Section company Master approval`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {/* <TableBody>
              {Array.from({
                length: Math.max(newValuesRepresentative?.length || 0, oldValuesRepresentative?.length || 0),
              }).map((_, index: number) => {
                const newRepre = newValuesRepresentative ? newValuesRepresentative[index] : null;
                const oldRepre = oldValuesRepresentative ? oldValuesRepresentative[index] : null;

                return (
                  <React.Fragment key={`Repre-row-${index}`}>
                    {renderAddressRow("Purpose", newRepre?.Purpose_value, oldRepre?.Purpose_value, `Representative ${index + 1}`)}
                    {renderAddressRow("Name", newRepre?.Name, oldRepre?.Name,)}
                    {renderAddressRow("Email", newRepre?.EmailId, oldRepre?.EmailId)}
                    {renderAddressRow("Designation", newRepre?.Designation, oldRepre?.Designation)}
                    {renderAddressRow("Contact Number", newRepre?.Contact_Number, oldRepre?.Contact_Number)}
                    {renderAddressRow("Status", newRepre?.status_value, oldRepre?.status_value)}
                  </React.Fragment>
                );
              })}
            </TableBody> */}
            <TableBody>
              {Array.from({
                length: Math.max(newValuesRepresentative?.length || 0, oldValuesRepresentative?.length || 0),
              }).map((_, index: number) => {
                const newRepre = newValuesRepresentative ? newValuesRepresentative[index] : null;
                const oldRepre = oldValuesRepresentative ? oldValuesRepresentative[index] : null;

                return (
                  <React.Fragment key={`Repre-row-${index}`}>
                    {renderAddressRow("Purpose", oldRepre?.Purpose_value, newRepre?.Purpose_value, `Representative ${index + 1}`)}
                    {renderAddressRow("Name", oldRepre?.Name, newRepre?.Name)}
                    {renderAddressRow("Email", oldRepre?.EmailId, newRepre?.EmailId)}
                    {renderAddressRow("Designation", oldRepre?.Designation, newRepre?.Designation)}
                    {renderAddressRow("Contact Number", oldRepre?.Contact_Number, newRepre?.Contact_Number)}
                    {renderAddressRow("Status", oldRepre?.status_value, newRepre?.status_value)}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </Box>

        <RemarkSectionApproval selectedGridRecord={selectedGridRecord} closePopUp={closePopUp} view={view} />
      </Stack>

      <CustomDialog
        hideBackdrop={true}
        onHide={handleDialogHide}
        maxWidth={"lg"}
        show={openViewFileModel}
        header={"File Preview"}
        key={'company_master_pending_approval_preview_File_Preview'}
        contentNode={
          <>
            <FilteViewer URL={fileUrl} fileName={fileName} />
          </>
        }
      />
    </>
  );
};

export default CompanyMasterApproveReject;
