import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { AddSeverityMaster } from "./AddSeverityMaster";
import { deleteSeverityMaster, getAllSeverityMaster, SeverityMasterAddandSave } from "../../../store/performanceManagement/slices/severityMaster";
import { RootState, useAppDispatchThunk } from '../../../store/store'
import { getNotifications } from "../../../store/slices/notification";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";
export const SeverityMaster: React.FC = () => {
    const dispatch = useAppDispatchThunk();
    const [isEditData, setIsEditDate] = useState<any>(null);
    const [isAddorEdit, setIsAddorEdit] = useState(false);
    const { handleRecordLockUpdate } = RecordLockRelease();
    const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
    const [addForm, setAddForm] = useState(false);
    const [Option, setOption] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [IsEditData, setIsEditData] = useState<any[]>([]);
    const [isVersionHistory, setIsVersionHistory] = useState(false);
    const [isDeleteData, setIsDeleteData] = useState<any>(null);
    const performanceManagementReducer: any = useSelector((state: RootState) => state?.performanceManagementReducer);
    const { isLoading, ToasterMsg, SeverityMasterTable, isSeverityMasterAdded, isSeverityMasterUpdated, isSeverityMasterRemoved }: any = performanceManagementReducer.SeverityMasterDataSlice;
    //  const { isLoading, ToasterMsg, } = useSelector((state: RootState) => state.performanceManagementReducer?.SeverityMasterData);
    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );
    const { currentpageTitleData } = useSelector(
        (state: RootState) => state.FormTitleMasterData
    );
    console.log(ToasterMsg, "Toas");

    useEffect(() => {
        if (isSeverityMasterAdded) {
            dispatch(SeverityMasterAddandSave(false));
            // setLableName("Industry");
            dispatch(
                updateToaster({
                    isToaster: true,
                    toasterMsg: ToasterMsg.Message || "Message",
                    isTosterFailed: ToasterMsg.Status === "false" ? true : false,
                })
            ); dispatch(
                updateToaster({
                    isToaster: true,
                    toasterMsg: ToasterMsg.Message || "Message",
                    isTosterFailed: ToasterMsg.Status === "false" ? true : false,
                })
            );
            dispatch(getAllSeverityMaster({
                FormID: 2
            }))
            if (userLoginResponse) {
                dispatch(getNotifications(71));
            }
        } else if (isSeverityMasterUpdated) {
            console.log("love");

            dispatch(
                updateToaster({
                    isToaster: true,
                    toasterMsg: ToasterMsg.Message || "Message",
                    isTosterFailed: ToasterMsg.Status === "false" ? true : false,
                })
            );

            dispatch(SeverityMasterAddandSave(false));
            dispatch(getAllSeverityMaster({
                FormID: 2
            }))
        } else if (isSeverityMasterRemoved) {
            dispatch(getAllSeverityMaster({
                FormID: 2
            }))
            setIsDeleteData(null);
            dispatch(
                updateToaster({
                    isToaster: true,
                    toasterMsg: ToasterMsg.Message || "Message",
                    isTosterFailed: ToasterMsg.Status === "false" ? true : false,
                })
            );
            setTimeout(() => {
                dispatch(SeverityMasterAddandSave(false));
            }, 1000);

            // } else if (isindustryDeletedFailed) {
            //   dispatch(
            //     updateToaster({
            //       isToaster: true,
            //       toasterMsg: ToasterMsg.Message,
            //       isTosterFailed: ToasterMsg.Status === "false" ? true : false,
            //     })
            //   );

            //   dispatch(updateIndustryAddandEdit(false));
            //   dispatch(
            //     getIndustryList({
            //       FormID: 2,
            //     })
            //   );
            // }
        }
    }, [isSeverityMasterAdded, isSeverityMasterUpdated, isSeverityMasterRemoved]);

    console.log(isSeverityMasterAdded, isSeverityMasterUpdated, SeverityMasterTable, isSeverityMasterRemoved, "isSeverityMasterAdded2");
    const columns: any = {
        table_name: "Severity",
        isAction: true,
        columns: [
            {
                Field: "severity",
                DisplayName: "Severity",
                FieldType: "string",
            },
            {
                Field: "score",
                DisplayName: "Score",
                FieldType: "string",
            },
            {
                Field: "isActive",
                DisplayName: "Status",
                FieldType: "string",

            }, {
                Field: "updatedBy",
                DisplayName: "Modified By",
                FieldType: "string",
            }
        ],
    };
    const rowdata = [
        { Severity: "High1", Score: 1, IsActive: "Active" },

        { Severity: "High2", Score: 2, IsActive: "InActive" }
        , { Severity: "Medium", Score: 3, IsActive: "Active" },
        { Severity: "Low", Score: 4, IsActive: "Active" }

    ]

    const SeverityMasterActive = () => {
        // let formData = { ...isDeleteData };
        let formData: any = {};
        formData.ModuleName = "SLA Severity Master";
        formData.Activity = isDeleteData.IsActive === "Active" ? "Inactive" : "Active";
        formData.isActive = isDeleteData.isActive === "Active" ? false : true;
        formData.severityId = isDeleteData.severityId;
        formData.CreatedBy = user?.UserName;
        formData.APImasterid = isDeleteData.Id;
        formData.UpdatedBy = user?.UserName;
        formData.userId = userLoginResponse?.UserId;
        const currentDate = new Date();
        formData.updatedOn = currentDate.toISOString()
        // formData.Status= isDeleteData.IsActive === "Active" ? false : true;
        // formData.IsActive = isDeleteData.IsActive === "Active" ? 0 : 1;
        dispatch(deleteSeverityMaster(formData));
        setIsDeleteData(null);
    };  

    const handleOpen = async (data: any, Option: string) => {
        if (Option === "edit") {
            dispatch(updateLoader(true));
            setOption(Option);
            setIsEditData(data);
            dispatch(updateLoader(false));
            setIsEdit(true);

        } else if (Option === "view") {
            setOption(Option);
            setIsEditData(data);
            setIsEdit(true);
        }
        else if (Option === "history") {
            setOption(Option);
            setIsVersionHistory(true);
        }
        else if (Option === "delete") {
            setOption(Option);
            setIsDeleteData(data);
        }
    };

    useEffect(() => {
        dispatch(getAllSeverityMaster({
            FormID: 2
        }))
    }, [])
    console.log(SeverityMasterTable, "SeverityMasterTable");

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    // backgroundColor: "#ffff",
                    // backgroundColor: "background.default",
                    // px: 3,
                    // py: 4,
                    // borderRadius: "10px",
                }}
            >
                <Stack spacing={2}>
                    <Box
                        sx={{
                            display: "flex",
                            // flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h6" color={"text.primary"}>
                            SLA Severity Master
                            {/* {currentpageTitleData ? currentpageTitleData?.ViewTitle : "SLA Severity Master"} */}
                        </Typography>

                        <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
                    </Box>

                    <Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <MaterialDynamicGrid
                                data={columns}
                                rows={SeverityMasterTable}
                                handleAction={handleOpen}
                                enableZoomIcon={true}
                                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                                handleAdd={() => setAddForm(true)}
                                showAdd={true}
                                showhistory={false}
                                showDelete={true}
                                showView={true}
                                showEdit={true}
                                showExports={true}
                                pageName="Severity Master"
                                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                            />
                        </Box>
                    </Box>
                </Stack>
            </Box>

            <CustomDialog
                show={addForm}
                onHide={() => setAddForm(false)}
                maxWidth={"xs"}
                minHeight={"50vh"}
                header={`Add Severity`}
                // header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Severity"}`}
                contentNode={
                    <AddSeverityMaster
                        edit={false}
                        view={false}
                        closePopUp={() => {
                            setAddForm(false);
                        }}
                    />
                }
            />

            <CustomDialog
                show={isEdit}
                onHide={() => {
                    // if (Option === "history") {
                    //   dispatch(updateVersionHistory([]));
                    // }
                    setIsEdit(false);
                }}
                maxWidth={"xs"}
                minHeight={"50vh"}
                header={Option === "view" ? `View Severity` : `Edit Severity`}
                // header={Option === "view" ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Severity"}` : `Edit ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Severity"}`}
                contentNode={
                    <>
                        {Option === "edit" && (
                            <AddSeverityMaster
                                edit={true}
                                view={false}
                                isEditData={IsEditData}
                                closePopUp={() => {
                                    setIsEdit(false);
                                }}
                            />
                        )}
                        {Option === "view" && (
                            <AddSeverityMaster
                                edit={false}
                                view={true}
                                isEditData={IsEditData}
                                closePopUp={() => {
                                    setIsEdit(false);
                                }}
                            />
                        )}
                    </>
                }
            />
            <ModalPopUp
                onDeleteMethod={() => { SeverityMasterActive() }}
                isDelete={true}
                onHide={() => setIsDeleteData(null)}
                show={isDeleteData ? true : false}
                header={"Confirm Action"}
                text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"} the selected API?`}
                ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
            />
        </>
    );
};
