import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";

import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { textFieldWidth } from "../../../utils/constants";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { getLovList } from "../../../store/slices/lovMasterData";
//import { addCurrency, updateCurrency } from "../../../store/slices/currencyData";
import {
  getallCostApprovalMetricList,
  createCostApprovalMetric,
  updateCostApprovalMetric,
  activeInactiveCostApprovalMetric,
} from "../../../store/contractManagement/slices/costApprovalMetricSlice";
import ProgressBar from "../../../common/progressBar/progressBar";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import CustomAutoComplete from "../../../common/dynamicInputs/CustomAutoComplete";

// import { AddLocation } from "../../location/AddLocation";
import { LocationConfig, LovConfig } from "../../../config/config";
import { updateLoader } from "../../../store/slices/loader";
import { ACTIVE } from "../../../utils/constants";
import { getUsersList } from "../../../store/slices/userData";

interface FormData {
  costApproval_Metrics_Id?: number;
  from_Amount?: any;
  to_Amount?: any;
  approver_Name?: any;
  approver_Email?: any;
  approver_Designation: string;
  isActive: any;
}

export interface RegionalSettingsProps {
  view?: boolean;
  closePopUp?: () => void;
  edit?: boolean;
  isEditData?: any;
}

export const AddContractCostApproval: React.FC<RegionalSettingsProps> = ({ view, closePopUp, edit, isEditData }) => {
  const dispatch = useAppDispatchThunk();
  // const { CurrencyData, isCurrencyUpdated, isCurrencyAdded,
  //    isCurrencyDeleted, ToasterMsg, isLoading } = useSelector(
  //   (state: RootState) => state.CurrencyData
  // );

  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    costApprovalMetric,
    CostApprovalMetricData,
    versionHistory,
    isCostApprovalMetricDataAdded,
    isCostApprovalMetricDataUpdated,
    isCostApprovalMetricDataDeleted,
    CostApprovalMetricToasterStatus,
    ToasterMsg,
  }: any = contractManagementReducer.costApprovalMetric;

  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { formConfiguration } = useSelector((state: RootState) => state.customFormFields);
  const [message, setMessage] = useState<boolean>(false);
  const [currencyCode_Data, setCurrencyCode_Data] = useState([]);
  const [AddLocationForm, setAddLocationForm] = useState<boolean>(false);
  const { ParentLocationTable } = useSelector((state: RootState) => state.locationMasterData);
  const [usersData, setUsersData] = useState<any[]>([]);
  const { allUsers } = useSelector((state: RootState) => state.userData);

 
  console.log(isEditData, "isEditData");

  //   useEffect(()=>{
  //     callPageloading();
  //   },[])

  //   const callPageloading = () => {
  // //dispatch(getCurrencyList({ FormID: 0 }));
  //    dispatch(createCostApprovalMetric({ FormID: 2 }));
  // //dispatch(getLovList({ Name: "" }));
  //   };

  useEffect(() => {
    let filteredUsers = allUsers.filter((item: any) => item.isActive == ACTIVE);
    
    setUsersData(filteredUsers);
  }, [allUsers]);

  let formData: any = {
    costApproval_Metrics_Id: edit ? isEditData?.costApproval_Metrics_Id : 0,
    from_Amount: isEditData
      ? isEditData?.from_Amount
      : CostApprovalMetricData?.length > 0
      ? CostApprovalMetricData[0]?.to_Amount + 1
      : 0,
    to_Amount: isEditData ? isEditData?.to_Amount : 0,
    approver_Name: isEditData ? isEditData?.approver_Name : "",
    approver_Email: isEditData ? isEditData?.approver_Email : "",
    approver_Designation: isEditData ? isEditData?.approver_Designation : "",
    isActive: isEditData ? (isEditData?.isActive == "Active" ? true : false) : true,
    // LoginId: isEditData ? isEditData?.LoginId : "",
    // Password: isEditData ? isEditData?.Password : "",
    // APIKey:  isEditData ? isEditData?. APIKey : "",
    // Format:  isEditData ? isEditData?. Format : "",
    // APIVersion:  isEditData ? isEditData?. APIVersion : "",

    // Default_Currency: isEditData ? isEditData?.Default_Currency : "",

    // Remarks: isEditData ? (isEdit Data?.Remarks ? isEditData?.Remarks : "") : "",
  };
  console.log(formData);

  const {
    handleSubmit: handleRegionalSettings,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });
  console.log(getValues())

  console.log(watch());

  const isActiveDisabled = watch("isActive");
  //isEditData ?false:( CostApprovalMetricData?.length>0?true:false)
  const nextSequence: number = isEditData? isEditData?.from_Amount
    : CostApprovalMetricData?.length > 0
    ? CostApprovalMetricData[0]?.to_Amount + 1
    : 0;

    console.log(isEditData);

//   CostApprovalMetricData.sort ((a:any, b:any) => a.from_Amount - b.from_Amount);

// const nextSequence = isEditData
//     ? isEditData.from_Amount
//     : CostApprovalMetricData.length > 0
//     ? Math.max(...CostApprovalMetricData.map((item:any) => (item.to_Amount)  + 1))
//     : undefined;


  const nextSequenceReadOnly: boolean = view && nextSequence !== 0 ? true : false;
  console.log(nextSequenceReadOnly, "viewview");
  const handleSubmit = (data: any) => {
    console.log(isEditData, "isEditData");
    dispatch(updateLoader(true));

    let formData = data;
    formData.Status = formData.Status ? 1 : 0;
    formData.isActive = formData.isActive ? true : false;
    formData.ActionTaken = "";

    let JsonDataForDisplay: any = {
      from_Amount:Number(formData.from_Amount),
      to_Amount: Number(formData.to_Amount),
      approver_Name: formData.approver_Name,
      approver_Email: formData.approver_Email,
      approver_Designation: formData.approver_Designation,
      Status: formData.isActive ? "Active" : "Inactive",

      // "Format" : formData.Format,
      //  "APIVersion":formData.APIVersion,
      //  "Status" : formData.IsActive? "Active" : "InActive",
      // "Modified By" : user?.UserName,
    };

    if (edit) {
      formData.UpdatedBy = user?.UserName;
      formData.CreatedBy = user?.UserName;
      formData.MenuId = currentPagePermissionData?.MenuId;
      formData.ModuleName = "Cost Approval Metrics";
      formData.Activity = "Edit";
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      formData.UserId = userLoginResponse?.UserId;
      formData.Form_Id = 13;
      formData.isActive = formData.isActive ? true : false;
      formData.from_Amount=Number(formData.from_Amount);
      formData.to_Amount= Number(formData.to_Amount);
      dispatch(updateCostApprovalMetric(formData));
    } else {
      formData.UpdatedBy = user?.UserName;
      formData.CreatedBy = user?.UserName;
      delete formData.DepartmentId;
      formData.MenuId = currentPagePermissionData?.MenuId;
      formData.ModuleName = "Cost Approval Metrics";
      formData.Activity = "create";
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      formData.UserId = userLoginResponse?.UserId;
      formData.Form_Id = 13;
      formData.isActive = true;
      formData.from_Amount=Number(formData.from_Amount);
      formData.to_Amount=Number(formData.to_Amount);
      dispatch(createCostApprovalMetric(formData));
    }
  };

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
  }, []);

  // useEffect(() => {
  //   if (isCostApprovalMetricDataAdded && closePopUp) {
  //     reset(formData);
  //     closePopUp();
  //     dispatch(updateLoader(false));
  //   } else if (isCostApprovalMetricDataUpdated && closePopUp) {
  //     reset(formData);
  //     closePopUp();
  //     dispatch(updateLoader(false));
  //   }
  // }, [isCostApprovalMetricDataUpdated, isCostApprovalMetricDataAdded]);

  return (
    <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
      <Grid
        // item
        // item xs={12} sm={12} md={6} lg={6} xl={6}
        sx={{
          backgroundImage: `url()`,
          backgroundRepeat: "no-repeat",
          // backgroundColor: (t) =>
          //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
          // backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          margin: "0",
        }}
      >
        {false && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              margin: "0",
            }}
          >
            {!view ? (
              <>
                {!edit ? (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Add CostApproval
                  </Typography>
                ) : (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Edit CostApproval
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="h6" sx={{ m: 1 }}>
                View CostApproval
              </Typography>
            )}
          </Box>
        )}
        <Grid
          sx={{
            height: "auto",
            width: "100%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Box
            sx={{
              px: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
              },

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="from_Amount"
                    control={control}
                    //defaultValue={}
                    rules={{
                      required: "FromAmount is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        isNumeric: (value) => /^-?\d+(\.\d+)?$/.test(value) || "Only valid numbers are allowed",
                        // max: (value) => value <= 1000000 || "FromAmount must be less than or equal to 1,000,000",
                        isUnique: (value) => {
                          const exists = CostApprovalMetricData.some((item:any) => item.from_Amount === Number(value));
                          return !exists || "FromAmount is already available";
                      },
                      isInRange: (value) => {
                        const numericValue = Number(value);
                        const overlaps = CostApprovalMetricData.some((item:any) => {
                            return numericValue >= item.from_Amount && numericValue <= item.to_Amount;
                        });
                        return !overlaps || "FromAmount overlaps with an existing range";
                    },
                     
                      },
                    }}
                    render={({ field }) => (
                      <CustomNumberField
                      isDecimal={true}
                        name={"from_Amount"}
                        required={true}
                        readOnly={view || !isActiveDisabled ||nextSequenceReadOnly}
                        label={"From Amount"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.from_Amount)}
                        helperText={errorsloginform.from_Amount && errorsloginform.from_Amount.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="to_Amount"
                    control={control}
                    //defaultValue={0}
                    rules={{
                      required: "ToAmount is Required",
        //               validate: {
        //                 noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
        //                   isNumeric: (value) => /^-?\d+(\.\d+)?$/.test(value) || "Only numbers are allowed",
        //               minFromAmount: (value) => value >= 1 || "FromAmount must be at least 1", // Renamed
        //                        minToAmount: (value) => value >= 1 || "ToAmount must be at least 1", // Renamed
        //                 max: (value) => value <= 1000000 || "ToAmount must be less than or equal to 1,000,000",
        //  isGreaterThanFrom: (value) => {
        //     const fromAmount = parseFloat(getValues().from_Amount) || 0; 
        //             return value > fromAmount || "ToAmount must be greater than FromAmount";
        //               },
        //               isUnique: (value) => {
        //                 const exists = CostApprovalMetricData.some((item:any) => item.to_Amount === Number(value));
        //                 return !exists || "ToAmount is already available";
        //             },

        //             isInRange: (value) => {
        //               const numericValue = Number(value);
        //               const overlaps = CostApprovalMetricData.some((item:any) => {
        //                   return numericValue >= item.from_Amount && numericValue <= item.to_Amount;
        //               });
        //               return !overlaps || "ToAmount overlaps with an existing range";
        //           },
  
        //               },
        validate: {
          noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
          isNumeric: (value) => /^-?\d+(\.\d+)?$/.test(value) || "Only valid numbers are allowed",
          minFromAmount: (value) => value >= 1 || "FromAmount must be at least 1", // Renamed
          minToAmount: (value) => value >= 1 || "ToAmount must be at least 1", // Renamed
            // max: (value) => value <= 1000000 || "ToAmount must be less than or equal to 1,000,000",
          isGreaterThanFrom: (value) => {
              const fromAmount = parseFloat(getValues().from_Amount) || 0; 
              return value > fromAmount || "ToAmount must be greater than FromAmount";
          },
          isUnique: (value) => {
              const numericValue = parseFloat(value); // Ensure we are dealing with a float
              const exists = CostApprovalMetricData.some((item: any) => item.to_Amount === numericValue);
              return !exists || "ToAmount is already available";
          },
          isInRange: (value) => {
              const numericValue = parseFloat(value); //
              const overlaps = CostApprovalMetricData.some((item: any) => {
                  const fromAmount = parseFloat(item.from_Amount);
                  const toAmount = parseFloat(item.to_Amount);
                  return numericValue >= fromAmount && numericValue <= toAmount;
              });
              return !overlaps || "ToAmount overlaps with an existing range";
          },
      }
                    }}
                
                    render={({ field }) => (
                      <CustomNumberField
                      isDecimal={true}
                        name={"to_Amount"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"To Amount"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.to_Amount)}
                        helperText={errorsloginform.to_Amount && errorsloginform.to_Amount.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="approver_Name"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "approver_Name is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 1 characters Required",
                        maxLength: (value) => value.length <= 100 || "Maximum 1 characters Required",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"approver_Name"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"Approver name"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.approver_Name)}
                        helperText={errorsloginform.approver_Name && errorsloginform.approver_Name.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="approver_Email"
                    control={control}
                    rules={{
                      required: "ApproverEmail is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        isValidEmail: (value) =>
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) || "Invalid email address",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name="approver_Email"
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label="Approver Email"
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.approver_Email)}
                        helperText={
                          errorsloginform.approver_Email && errorsloginform.approver_Email.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  // xs={12}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="approver_Designation"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "approver_Designation is Required",

                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 1 characters Required",
                        maxLength: (value) => value.length <= 100 || "Maximum 100 characters Required",
                        ValueLimitation: (value) =>
                          /^[a-zA-Z]+$/.test(value.trim()) ||
                          "Currency Code cannot contain special characters, number and spaces",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"approver_Designation"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"Approver Designation "}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.approver_Designation)}
                        helperText={
                          errorsloginform.approver_Designation &&
                          errorsloginform.approver_Designation.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "end",
                  mt: 2,
                }}
              >
                {view ? (
                  <CustomButton
                    type="reset"
                    name={"Cancel"}
                    onClick={() => {
                      closePopUp && closePopUp();
                    }}
                  />
                ) : edit ? (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        reset(formData);
                        closePopUp && closePopUp();
                      }}
                    />
                    <CustomButton
                      type="reset"
                      disabled={!isDirty}
                      name={"Update"}
                      onClick={handleRegionalSettings(handleSubmit)}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        reset(formData);
                        closePopUp && closePopUp();
                      }}
                    />
                    <CustomButton
                      type="reset"
                      disabled={!isDirty}
                      name={"Submit"}
                      onClick={handleRegionalSettings(handleSubmit)}
                    />
                  </React.Fragment>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
