import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs"; 
import { AddApiMaster } from "./AddApiMaster";
import { IMenu } from "../../models/loggin.type";
import { RootState, useAppDispatchThunk } from "../../store/store";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { getAllApiMaster, deleteApi, saveApiMaster, updateApiMaster, apiMasterAddandSave } from "../../store/slices/apiMaster";
import { getLovList } from "../../store/slices/lovMasterData";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";
import masterEditEnableService from "../../store/services/masterEditEnableService";
import { Edit_Enable_tosterMSG } from "../../config/config";
import { getNotifications } from "../../store/slices/notification";



export interface RegionalSettingsProps {
  handlePrevious?: () => void;
  handleNext?: () => void;
}

export const ApiMaster: React.FC<RegionalSettingsProps> = ({ handlePrevious, handleNext }) => {
  const dispatch = useAppDispatchThunk();
  const { ApiMasterTable, isLoading, isApiMasterAdded, isApiMasterUpdated, isApiDeleted, ToasterMsg } =
    useSelector((state: RootState) => state.ApiMasterData);

  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [apiMasterFormData, setApiMasterFormData] = useState<any>([]);
  const [addForm, setAddForm] = useState(false);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const [isMasked, setIsMasked] = useState(true);

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );



  const columns: any = {
    table_name: "API MASTER",
    isAction: true,
    columns: [
      {
        Field: "API_Group_Code",
        DisplayName: "API Group Code",
        FieldType: "string",
      },
      {
        Field: "API_Group",
        DisplayName: "API Group Name",
        FieldType: "string",
      },
      {
        Field: "API_Provider",
        DisplayName: "API Provider",
        FieldType: "string",
      },
      {
        Field: "User_Name",
        DisplayName: "User Name",
        FieldType: "string",
      },

      {
        Field: "Login_Id",
        DisplayName: "Login Id",
        FieldType: "string",
        Masking: true,

      },
      {
        Field: "Pwd",
        DisplayName: "Password",
        FieldType: "string",
        Masking: true,

      },
      {
        Field: "API_Key",
        DisplayName: "API Key",
        FieldType: "string",
        Masking: true,
      },
      {
        Field: "Format",
        DisplayName: "Format",
        FieldType: "string",
      },
      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",

      },
      {
        Field: "API_Version",
        DisplayName: "API Version",
        FieldType: "string",

      },


    ],
  };
  useEffect(() => {
    setApiMasterFormData(ApiMasterTable)
  }, [ApiMasterTable])


  useEffect(() => {
    callApiData();
  }, []);

  const callApiData = () => {
    dispatch(getAllApiMaster({ FormID: 2 }));
  };




  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  const handleOpen = async (data: any, Option: string) => {
    if (Option === "edit") {


      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.APImasterid, FormID: 14 });
      const Edit_data: any = response;
      // const canEdit = await handleRecordLockCheck(data?.Content_Id);

     // if (Edit_data?.data === true) {
        dispatch(updateLoader(true));
        setOption(Option);
        setIsEditData(data);

        // if (canEdit || true) {
        //   const dispatchData = handleAddRecordLock(data?.Content_Id);
        //   await dispatch(AddRecordLock(dispatchData));
        //   //setIsEditOpen(true);
        //   const resultAction = await dispatch(GetRecordLock(recordLockGet(data?.Content_Id)));
        //   if (GetRecordLock.fulfilled.match(resultAction)) {
        //     const recordLockData = resultAction.payload;
        //     setGetRecordLock(recordLockData);
        //   }


        // }
        dispatch(updateLoader(false));
        setIsEdit(true);
      // } else {

      //   dispatch(updateLoader(false));
      //   dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

      // }

    } else if (Option === "view") {
      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
    }
    else if (Option === "history") {
      setOption(Option);
      setIsVersionHistory(true);
    }
    else if (Option === "delete") {
      setOption(Option);
      setIsDeleteData(data);
      //    const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.Id, FormID: 13 });
      //    const Edit_data: any = response;

      //    if (Edit_data?.data === true) {

      //    } else {

      //      dispatch(updateLoader(false));
      // dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

      //   }
    }
  };

  // useEffect(() => {
  //   if (isApiMasterAdded) {
  //     dispatch(apiMasterAddandSave(false));
  //     dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));

  //     callApiData();
  //   }
  //    else if (isApiMasterUpdated) {
  //     dispatch(apiMasterAddandSave(false));
  //     dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
  //     callApiData();
  //   } 
  //   else if (isApiDeleted) {
  //     setIsDeleteData(null);
  //     dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));

  //       dispatch(apiMasterAddandSave(false));

  //       callApiData();
  //   }
  // }, [isApiMasterAdded, isApiMasterUpdated,isApiDeleted ]);

  // useEffect(() => {
  //         if (isApiMasterAdded) {

  //             dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
  //             dispatch(apiMasterAddandSave(false));
  //             dispatch(
  //               getAllApiMaster({
  //                     FormID: 2,
  //                 })
  //             );
  //         }
  //         else if (isApiMasterUpdated) {
  //             dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
  //             dispatch(apiMasterAddandSave(false));

  //             dispatch(
  //               getAllApiMaster({
  //                     FormID: 2,
  //                 })
  //             );
  //         }
  //         else if (isApiDeleted) {
  //             dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
  //             dispatch(apiMasterAddandSave(false));

  //             dispatch(
  //               getAllApiMaster({
  //                     FormID: 2,
  //                 })
  //             );
  //             setIsDeleteData(null);
  //         }
  //     }, [isApiMasterAdded, isApiMasterUpdated, isApiDeleted]);

  const ApiActive = () => {
    // let formData = { ...isDeleteData };


    let JsonDataForDisplay: any = {
      // "APImasterid":isDeleteData.Id,
      "API_Group_Code": isDeleteData?.API_Group_Code,
      "API_Group": isDeleteData?.API_Group,
      "API_Provider": isDeleteData?.API_Provider,
      "User_Name": isDeleteData?.User_Name,
      "Login_Id": isDeleteData?.Login_Id,
      "Pwd": isDeleteData?.Pwd,
      "API_Key": isDeleteData?.API_Key,
      "Format": isDeleteData?.Format,
      "API_Version": isDeleteData?.API_Version,
      Status: isDeleteData.IsActive === "Active" ? "Inactive" : "Active",
      //  IsActive: isDeleteData.IsActive === "Active" ? false : true,
      "Modified By": user?.UserName,
    }

    let formData: any = {};
    formData.ModuleName = "API MASTER";
    formData.IsActive = isDeleteData.IsActive === "Active" ? 0 : 1;
    formData.Activity = isDeleteData.IsActive === "Active" ? "Inactive" : "Active";
    formData.UserId = userLoginResponse?.UserId;
    formData.Remarks = "";
    formData.Created_By = user?.UserName;
    formData.MenuId = currentPagePermissionData?.MenuId;
    formData.Form_Id = 13;
    formData.APImasterid = isDeleteData.Id;




    formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
    // formData.UpdatedBy = user?.UserName;
    // formData.Status= isDeleteData.IsActive === "Active" ? false : true;

    // formData.IsActive = isDeleteData.IsActive === "Active" ? 0 : 1;

    dispatch(deleteApi(formData));
    setIsDeleteData(null);
  };

  useEffect(() => {
    if (isApiMasterAdded) {
      dispatch(apiMasterAddandSave(false));
      // setLableName("Industry");
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.Message,
          isTosterFailed: ToasterMsg.Status === "false" ? true : false,
        })
      );
      dispatch(
        getAllApiMaster({
          FormID: 2,
        })
      );
      if (userLoginResponse) {
        dispatch(getNotifications(userLoginResponse?.UserId));
      }
    } else if (isApiMasterUpdated) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));

      dispatch(apiMasterAddandSave(false));
      dispatch(
        getAllApiMaster({
          FormID: 2,
        })
      );
    } else if (isApiDeleted) {
      setIsDeleteData(null);
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.Message,
          isTosterFailed: ToasterMsg.Status === "false" ? true : false,
        })
      );
      setTimeout(() => {
        dispatch(apiMasterAddandSave(false));
      }, 1000);
      dispatch(
        getAllApiMaster({
          FormID: 2,
        })
      );
      // } else if (isindustryDeletedFailed) {
      //   dispatch(
      //     updateToaster({
      //       isToaster: true,
      //       toasterMsg: ToasterMsg.Message,
      //       isTosterFailed: ToasterMsg.Status === "false" ? true : false,
      //     })
      //   );

      //   dispatch(updateIndustryAddandEdit(false));
      //   dispatch(
      //     getIndustryList({
      //       FormID: 2,
      //     })
      //   );
      // }
    }
  }, [isApiMasterAdded, isApiMasterUpdated, isApiDeleted]);


  let apiMasterDatas = apiMasterFormData.slice().reverse()

  return (
    <>
      <Box
        sx={{
          width: "100%",
          // backgroundColor: "#ffff",
          // backgroundColor: "background.default",
          // px: 3,
          // py: 4,
          // borderRadius: "10px",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {"API Master"} 
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={apiMasterDatas}
                handleAction={handleOpen}
                enableZoomIcon={true}
                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                handleAdd={() => setAddForm(true)}
                showAdd={true}
                showhistory={false}
                showDelete={true}
                showView={true}
                showEdit={true}
                showExports={true}
                pageName="API MASTER"
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            </Box>
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"xs"}
        minHeight={"50vh"}
        header={"Add API"}
        contentNode={
          // <></>
          <AddApiMaster
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          setIsEdit(false);
        }}
        maxWidth={"xs"}
        minHeight={"50vh"}
        header={Option === "view" ? "View API" : "Update API"}
        contentNode={
          <>
            {Option === "edit" && (
              <AddApiMaster
                view={false}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                edit={isEdit}
                isEditData={IsEditData}
              />
              // <></>
            )}
            {Option === "view" && (
              <AddApiMaster
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                isEditData={IsEditData}
              />
              // <></>
            )}
          </>
        }
      />

      {/* <CustomDialog
        show={isVersionHistory}
        onHide={() => {
        //   setIsVersionHistory(false);
        //   dispatch(updateVersionHistory([]));
        }}
        maxWidth={"lg"}
        header={"API MASTER"}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={apiMasterFormData}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showDelete={false}
              showhistory={false}
              showView={false}
              showEdit={false}
              showExports={true}
            userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Sub Department Version History"
            />
          </>
        }
      /> */}
      <ModalPopUp
        onDeleteMethod={() => ApiActive()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm Action"}
        text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"} the selected API?`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />
      {/* <ModalPopUp
        onDeleteMethod={() => ApiActive()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"} the selected Currency`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      /> */}
    </>
  );
};
