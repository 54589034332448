import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PMEventLogService from "../services/pmEventlogservice";


export interface PMEventLogData {
    PMEventListTable: any[];
    ispmEventLoading: boolean;
    EventLogSLAAdded: boolean;
    PMEventLogToasterMsg: { Message: string; Status: string };

}

export const initialState: PMEventLogData = {
    PMEventListTable: [],
    ispmEventLoading: false,
    EventLogSLAAdded: false,
    PMEventLogToasterMsg: { Message: "", Status: "" },

};

export const getAllPMEventLog = createAsyncThunk(
    "getAllPMEventLog",
    async (requestParams: any, { rejectWithValue }) => {
        try {
            const response  = await PMEventLogService.getAllEventLog();
            return response?.eventLogList;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const CreateEventLogSLA = createAsyncThunk("CreateEventLogSLA", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await PMEventLogService.CreateEventLogSLA(requesrParams);

        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const PMEventLogDataSlice = createSlice({
    name: "PMEventLogData",
    initialState,
    reducers: {
        EventAddandEditUpdate: (state, action) => {
            state.EventLogSLAAdded = false;
            state.PMEventLogToasterMsg.Message = "";
            state.PMEventLogToasterMsg.Status = "";
        }
      
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllPMEventLog.pending, (state) => {
               
            })
            .addCase(getAllPMEventLog.fulfilled, (state, action: PayloadAction<any>) => {                
                state.PMEventListTable = action.payload.map((data: any) => {
                    data.IsActive = data.isActive === true ? "Active" : "Inactive";
                    return data;
                });
              
            })
            .addCase(getAllPMEventLog.rejected, (state) => {
            })

            .addCase(CreateEventLogSLA.pending, (state) => {
                state.ispmEventLoading = true;
            })
            .addCase(CreateEventLogSLA.fulfilled, (state, action: PayloadAction<any>) => {                
                state.ispmEventLoading = false;
                state.EventLogSLAAdded = true;
                console.log(action.payload, "action.payload");
                
                state.PMEventLogToasterMsg.Message = action.payload.toaster.message;
                state.PMEventLogToasterMsg.Status = action.payload.toaster.status;
            })
            .addCase(CreateEventLogSLA.rejected, (state, action: PayloadAction<any>) => {
                state.ispmEventLoading = false;
                console.log(action.payload, "action.payload");

                state.PMEventLogToasterMsg.Message = action.payload.toaster.message;
                state.PMEventLogToasterMsg.Status = action.payload.toaster.status;
            })
           
           
    },
});

export const { EventAddandEditUpdate } = PMEventLogDataSlice.actions;
export default PMEventLogDataSlice.reducer;
