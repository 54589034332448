import { Box, Grid, Typography } from "@mui/material";
import { textFieldWidth } from "../../../utils/constants";
import LableandValuesPreview from "../../../common/CommonPreview/LableandValuesPreview";



interface ScopeDetailsprops {
    headerTitle?: any;
    data: any;

}

export const ScopeDetails: React.FC<ScopeDetailsprops> = ({ headerTitle, data }) => {    

    const renderGridRow = (displayName: any, Value: any) => {


        return (
            <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
            >
                <LableandValuesPreview lable={displayName} Value={`${Value || '-'}`} />
            </Grid>
        );
    };

    return (

        <>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography
                    sx={{
                        height: "12px",
                        color: "#959595",
                        display: "block",
                        fontSize: "15px",
                        fontWeight: "bold",
                        textAlign: "left",
                    }}
                >
                    {headerTitle ? headerTitle : "SCOPE DETAILS"}
                </Typography>
            </Box>
            <Box sx={{mt:2 , pl:1}}>
                <Grid container spacing={2}>
                    {renderGridRow("Scope of the Target", data?.scope_Of_Target || "-")}
                    {renderGridRow("Category", data?.category_Name || "-")}
                    {renderGridRow("Deliverables", data?.deliverables || "-")}
                    {renderGridRow("Oblication of Third party / Company", data?.obligation_Name || "-")}
                    {renderGridRow("Exclusions", data?.exclusion || "-")}
                    {renderGridRow("SMEs Involved", data?.sme_Involved || "-")}
                    {renderGridRow("Input Dependency", data?.input_Dependency_Name || "-")}
                    {renderGridRow("Evidence Required", data?.evidence_Required ? "Yes" : "No" )}
                    {renderGridRow("Project Cost", data?.project_Cost || "-")}
                    {renderGridRow("Stack Holder Acceptions Required", data?.stakeholder_acceptance_required ? "Yes" : "No" )}
                    {renderGridRow("Penalty For Deviation", data?.penality_For_Deviation ? "Yes" : "No" )}
                </Grid>
            </Box>

        </>
    )

}