import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { AQMMappingCOB_URL } from "./endpoints/assessmentQuestionnaireMappingCOB";


const getAQMMappingListCOB = (reqparams: any): Promise<any> =>
  axiosInstance
    .get(AQMMappingCOB_URL.GetAllAQMMappingCOB)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateAQMMappingCOB = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(AQMMappingCOB_URL.UpdateAQMMappingCOB, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addAQMMappingCOB = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(AQMMappingCOB_URL.AddAQMMappingCOB, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });



    const getAQMMappingCOBbyProjectId = (requestBody: any): Promise<any> =>
      axiosInstance
        .get(AQMMappingCOB_URL.GetAQMMappingbyprojectCOB, { params: requestBody })
        .then((response: AxiosResponse<any>) => {
          if (response.status === 200) {
            return response.data;
          }
          throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
          throw error;
        });

        const SendEmailToCustomer = (requestBody: any): Promise<any> =>
          axiosInstance
            .post(AQMMappingCOB_URL.SendEmailToCustomer,  requestBody )
            .then((response: AxiosResponse<any>) => {
              if (response.status === 200) {
                return response.data;
              }
              throw new Error("Error getting Data" + response);
            })
            .catch((error: AxiosError | string) => {
              throw error;
            });
        


const AQMMappingCOBService = {
  getAQMMappingListCOB,
  updateAQMMappingCOB,
  addAQMMappingCOB,
  getAQMMappingCOBbyProjectId,
  SendEmailToCustomer
};

export default AQMMappingCOBService;
