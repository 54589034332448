import { TEMPLATE_MANAGEMENT_URL } from "./endpoints/template";

import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IGetTemplateResponse, ITemplateResponse } from "../../../models/contractManagementModels/templateModels";

const createTemplate = (requestBody: any): Promise<ITemplateResponse> => {
  return axiosInstance
    .post(TEMPLATE_MANAGEMENT_URL.CreateTemplate, requestBody)
    .then((response: AxiosResponse<ITemplateResponse>) => {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
};

const getContractType = (): Promise<any> =>
  axiosInstance
    .get(TEMPLATE_MANAGEMENT_URL.getTemplateType)
    .then((response: AxiosResponse<ITemplateResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Service Catalog data: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getContractSummary = (id: string): Promise<any> =>
  axiosInstance.get(`${TEMPLATE_MANAGEMENT_URL.contractSummary}${id}`).then((response: AxiosResponse<any>) => {
    if (response.status === 200) {
      return response?.data?.data?.result || response.data;
    }
    throw new Error("Error getting Data" + response);
  });

const updateTemplate = (requestBody: any): Promise<ITemplateResponse> =>
  axiosInstance
    .put(TEMPLATE_MANAGEMENT_URL.updateTemplate, requestBody)
    .then((response: AxiosResponse<ITemplateResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getContractTemplateList = (): Promise<IGetTemplateResponse> =>
  axiosInstance
    .get(TEMPLATE_MANAGEMENT_URL.GetTemplates)
    .then((response: AxiosResponse<IGetTemplateResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getContractTemplateById = (id: string): Promise<IGetTemplateResponse> =>
  axiosInstance
    .get(`${TEMPLATE_MANAGEMENT_URL.GetTemplateById}${id}`)
    .then((response: AxiosResponse<IGetTemplateResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const enableDisableContractTemplate = (id: string, status: boolean): Promise<any> =>
  axiosInstance
    .get(`${TEMPLATE_MANAGEMENT_URL.activeInactiveTemplate}${id}/${status}`)
    .then((response: AxiosResponse<IGetTemplateResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const savePreviewTemplate = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(TEMPLATE_MANAGEMENT_URL.savePreviewTemplate, requestBody)
    .then((response: AxiosResponse<IGetTemplateResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deletePreviewData = (id: string): Promise<any> =>
  axiosInstance
    .delete(`${TEMPLATE_MANAGEMENT_URL.deletePreviewTemplateData}${id}`)
    .then((response: AxiosResponse<IGetTemplateResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const contractTemplateService = {
  createTemplate,
  updateTemplate,
  getContractType,
  getContractTemplateList,
  getContractTemplateById,
  enableDisableContractTemplate,
  savePreviewTemplate,
  deletePreviewData,
  getContractSummary,
};

export default contractTemplateService;
