import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import contractTemplateService from "../services/templateService";
import { iTemplateData } from "../../../models/contractManagementModels/templateModels";

export interface IContractTemplateData {
  isLoading: boolean;
  isTemplateAdded: boolean;
  isTemplateUpdated: boolean;
  isTemplateDeleted: boolean;
  ToasterMsg: string;
  contractType: any;
  templateList: iTemplateData[];
  templateToasterStatus: boolean | null;
  templateData: any;
  contractSummaryData: any;
  previewId: string;
}

export const initialState: IContractTemplateData = {
  isLoading: false,
  isTemplateAdded: false,
  isTemplateUpdated: false,
  isTemplateDeleted: false,
  contractType: [],
  ToasterMsg: "",
  templateList: [],
  templateData: null,
  contractSummaryData: null,
  templateToasterStatus: null,
  previewId: "",
};

export const createTemplate = createAsyncThunk("createTemplate", async (requestParams: any, { rejectWithValue }) => {
  try {
    const data: any = await contractTemplateService.createTemplate(requestParams);
    return data;
  } catch (e: any) {
    return rejectWithValue(e?.response?.data?.errors[0]);
  }
});

export const updateTemplate = createAsyncThunk("updateTemplate", async (requestParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requestParams };
    const data: any = await contractTemplateService.updateTemplate(reqbody);
    return data;
  } catch (e: any) {
    return rejectWithValue(e?.response?.data?.errors[0]);
  }
});

export const updateTemplateStatus = createAsyncThunk(
  "updateTemplateStatus",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const data: any = await contractTemplateService.enableDisableContractTemplate(
        requestParams.id,
        requestParams.status
      );
      return data;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);

export const getContractType = createAsyncThunk("contractTypes", async (_, { rejectWithValue }) => {
  try {
    const data: any = await contractTemplateService.getContractType();
    return data.contractTypeList;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getTemplateList = createAsyncThunk("getTemplateList", async (_, { rejectWithValue }) => {
  try {
    let data: any = await contractTemplateService.getContractTemplateList();
    data = data.contractTemplateList.map((i: any) => ({ ...i, status: i?.isActive ? "Active" : "Inactive" }));
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getTemplateById = createAsyncThunk("getTemplateById", async (id: string, { rejectWithValue }) => {
  try {
    const data: any = await contractTemplateService.getContractTemplateById(id);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const savePreviewTemplate = createAsyncThunk(
  "savePreviewTemplate",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const data: any = await contractTemplateService.savePreviewTemplate(requestParams);
      return data.payload;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletePreviewTemplateData = createAsyncThunk(
  "deletePreviewTemplateData",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const data: any = await contractTemplateService.deletePreviewData(requestParams);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getContractSummary = createAsyncThunk(
  "getContractSummary",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const data: any = await contractTemplateService.getContractSummary(requestParams);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const contractTemplateDataSlice = createSlice({
  name: "contractTemplateData",
  initialState,
  reducers: {
    updateAddandEdit: (state, action) => {
      state.isTemplateAdded = false;
      state.isTemplateUpdated = false;
      state.isTemplateDeleted = false;
      state.ToasterMsg = "";
      state.templateToasterStatus = null;
    },
    clearPreview: (state) => {
      state.previewId = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createTemplate.fulfilled, (state, action: PayloadAction<any>) => {
        state.isTemplateAdded = true;
        state.isLoading = false;
        state.ToasterMsg = action.payload?.toaster.message || "Template created successfully";
        state.templateToasterStatus = action.payload?.toaster.status !== "Success";
      })
      .addCase(createTemplate.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isTemplateAdded = true;
        // state.ToasterMsg = action.payload?.toaster.message || "Template creation failed";
        // state.templateToasterStatus = action.payload?.toaster.status !== "Success";
        state.ToasterMsg = action?.payload || "Template update failed";
        state.templateToasterStatus = true;
      })
      .addCase(updateTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTemplate.fulfilled, (state, action: PayloadAction<any>) => {
        state.isTemplateUpdated = true;
        state.isLoading = false;
        state.ToasterMsg = action.payload.toaster.message || "Template updated successfully";
      })
      .addCase(updateTemplate.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isTemplateUpdated = true;
        state.ToasterMsg = action.payload?.toaster.message || "Template update failed";
        state.templateToasterStatus = action.payload?.toaster.status !== "Success";
      })
      .addCase(getContractType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContractType.fulfilled, (state, action: PayloadAction<any>) => {
        state.contractType = action.payload;
        state.isLoading = false;
      })
      .addCase(getContractType.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getTemplateList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTemplateList.fulfilled, (state, action: PayloadAction<any>) => {
        let data = action.payload;
        data = data.map((item: any) => ({
          ...item,
          services: item?.ctServiceMappings?.length
            ? Array.from(
                new Map(
                  item?.ctServiceMappings?.map((item: any) => [
                    item.serviceCatalog_Id,
                    item.serviceCatalog?.service_Name,
                  ])
                ).values()
              ).join(",")
            : "",
          servicesId: item?.ctServiceMappings?.length
            ? item.ctServiceMappings?.map((mapping: any) => mapping?.serviceCatalog_Id)
            : "",
          contractType: item.contractType?.contractType_Name,
        }));
        state.templateList = data;
        state.isLoading = false;
      })
      .addCase(getTemplateList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getTemplateById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTemplateById.fulfilled, (state, action: PayloadAction<any>) => {
        state.templateData = action.payload;
        state.isLoading = false;
      })
      .addCase(getTemplateById.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(updateTemplateStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTemplateStatus.fulfilled, (state, action: PayloadAction<any>) => {
        state.isTemplateDeleted = true;
        state.isLoading = false;
        state.ToasterMsg = action.payload;
      })
      .addCase(updateTemplateStatus.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(savePreviewTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(savePreviewTemplate.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.previewId = action.payload.previewTemplate_Id;
      })
      .addCase(savePreviewTemplate.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(deletePreviewTemplateData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePreviewTemplateData.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.previewId = "";
      })
      .addCase(deletePreviewTemplateData.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getContractSummary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContractSummary.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.contractSummaryData = action.payload;
      })
      .addCase(getContractSummary.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      });
  },
});

export const { updateAddandEdit, clearPreview } = contractTemplateDataSlice.actions;
export default contractTemplateDataSlice.reducer;
