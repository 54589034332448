import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { textFieldWidth } from "../../utils/constants";
import CustomMultilineTextField from "../../common/dynamicInputs/CustomMultilineTextField";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { updateApproveorReject, updateApproveorRejectforAQM, updateApproveorRejectforCompany, updateApproveorRejectforRole, updateApproveorRejectforSC, updateApproveorRejectforUser, updateApproveorRejectforVendor } from "../../store/slices/pendingApprovals";
import { ModuleNames } from "../../config/config";
import { updateLoader } from "../../store/slices/loader";

interface remarkApprovaltbleProps {
  selectedGridRecord: any;
  closePopUp?: any;
  view?: boolean;
}

const RemarkSectionApproval: React.FC<remarkApprovaltbleProps> = ({ selectedGridRecord, closePopUp, view }) => {
  const dispatch = useAppDispatchThunk();
  const [loader, setLoader] = useState<boolean>(false);
  const [remarks, setRemarks] = useState<string>("");
  const [remarksError, setRemarksError] = useState(false);
  const {isLoading , makerCheckerStatus, isApprovedorReject, isApprovedorRejectFailed } = useSelector(
    (state: RootState) => state.pendingApproval
  );
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const approveorReject = (approveOrReject: string) => {
    setLoader(true);
    let req = {
      TempId: selectedGridRecord?.TempId,
      Id: selectedGridRecord?.Id,
      ModuleId: selectedGridRecord?.ModuleId,
      // Status: value.length>0? value[0].LovId : 1,
      Status: approveOrReject === "Approved" ? 1 : 0,
      Remarks: remarks,
      CreatedBy: user?.UserName,
      Activity: approveOrReject,
      LoggedUserId: userLoginResponse?.UserId,
    };
    if( ModuleNames.CompanyMaster == selectedGridRecord.ModuleName){
        dispatch(updateApproveorRejectforCompany(req));

    }else if (ModuleNames.VendorMaster == selectedGridRecord.ModuleName){
         
        dispatch(updateApproveorRejectforVendor(req));
    }else if (ModuleNames.AQMaster == selectedGridRecord.ModuleName){
         
      dispatch(updateApproveorRejectforAQM(req));
    } else if (ModuleNames.UserMaster == selectedGridRecord.ModuleName){
      dispatch(updateApproveorRejectforUser(req));
    } 
    else if (ModuleNames.RoleMaster == selectedGridRecord.ModuleName){
      dispatch(updateApproveorRejectforRole(req));
    } 
    else if (ModuleNames.ServiceCatalog == selectedGridRecord.ModuleName){
      dispatch(updateApproveorRejectforSC(req));
    } 
    else {
         
      dispatch(updateApproveorReject(req));
  }
   
  };

  useEffect(() => {
    dispatch(updateLoader(isLoading));
  },[isLoading])
  const handleRemarksChange = (value: string) => {
    // Check if value exceeds the maximum length
    if (value.length <= 200) {
      setRemarks(value);
      setRemarksError(false); // Clear any previous error
    } else {
      setRemarksError(true); // Show error when max length is exceeded
    }
  };

  useEffect(() => {
    if (isApprovedorReject) {
      setLoader(false);
      closePopUp && closePopUp();
      dispatch(updateLoader(false));

    } else if (isApprovedorRejectFailed) {
      setLoader(false);
      dispatch(updateLoader(false));
    }
  }, [isApprovedorReject, isApprovedorRejectFailed]);
console.log(selectedGridRecord?.UserId,userLoginResponse?.UserId,"selectedGridRecord1");

  return (
    <>
      <Box>
        <Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <CustomMultilineTextField
              label={"Remarks"}
              required={true}
              value={remarks}
              disabled={view}
              error={remarksError ? true : false}
              helperText={remarksError ? `Max 200 characters allowed` : ""}
              onChange={(e) => {
                // setRemarks(e);
                handleRemarksChange(e);
              }}
              rows={2}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "right",
            mt: 2,
          }}
        >
          {view ? (
            <CustomButton
              type="reset"
              variant="outlined"
              name={"Cancel"}
              onClick={() => {
                closePopUp && closePopUp();
              }}
            />
          ) : (
            <>
              <CustomButton
                type="reset"
                variant="outlined"
                name={"Cancel"}
                onClick={() => {
                  closePopUp && closePopUp();
                }}
              />
              <CustomButton
                type="reset"
                name={"Reject"}
                disabled={
                  selectedGridRecord?.UserId === userLoginResponse?.UserId || // Same user cannot reject
                  remarks === "" || // Disabled if remarks is empty
                  view // Disabled if view is true
                }
                onClick={() => {
                  approveorReject("Rejected");
                }}
              />
              <CustomButton
                type="reset"
                name={"Approve"}
                disabled={
                  selectedGridRecord?.UserId === userLoginResponse?.UserId || 
                  remarks === "" || // Disabled if remarks is empty
                  view // Disabled if view is true
                }
                onClick={() => {
                  approveorReject("Approved");
                }}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};


export default RemarkSectionApproval;