import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IRecordLockSettingsResponse } from "../../models/recordLock.type";
import { RECORDLOCK_URL } from "./endpoints/recordLock";

const AddRecordLock = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(RECORDLOCK_URL.AddRecordLock, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data["resutl"];
      } else {
        throw new Error("Error getting notifications: " + response.statusText);
      }
    })
    .catch((error: AxiosError) => {
      throw error;
    });

const GetRecordLock = (requestBody: any): Promise<IRecordLockSettingsResponse[]> =>
  axiosInstance
    .post(RECORDLOCK_URL.GetRecordLock, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data["resutl"].filter((item: any) => item.IsActive === true).sort((a: any, b: any) => new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime()); // Sort by CreatedOn in descending order

      } else {
        throw new Error("Error getting notifications: " + response.statusText);
      }
    })
    .catch((error: AxiosError) => {
      throw error;
    });

const UpdateRecordLock = (requestBody: any): Promise<void> =>
  axiosInstance
    .post(RECORDLOCK_URL.UpdateRecordLock, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Error getting notifications: " + response.statusText);
      }
    })
    .catch((error: AxiosError) => {
      throw error;
    });

const recordLockService = {
  AddRecordLock,
  GetRecordLock,
  UpdateRecordLock,
};

export default recordLockService;
