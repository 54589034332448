import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  Tooltip,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import PasswordIcon from "@mui/icons-material/Password";
import LockResetIcon from "@mui/icons-material/LockReset";
import { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Sidenavbar from "../navbars/SideNavBarNew";
import { Link, useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store/store";
import { logoutUser } from "../../store/slices/authorization";
import { useSelector } from "react-redux";
import { updateMenu, updatethemeMode } from "../../store/slices/menu";
// import { Search } from "@mui/icons-material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import headerstyle from "./header.module.scss";
import headerlogo from "../../assets/headerlogo.png";
import { useTheme } from "@mui/material/styles";
import { ACTIVITY } from "../../constants/app.constants";
import { addEventLog, addEventLogRequestParam, getLandingPageHome, IHomePage } from "../../store/slices/eventLog";
import Notifications from "../notifications/Notifications";
import CustomSvg from "../../common/CustomSvg";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import ImageHandler from "./imageHandler/ImageHandler";
import * as mime from "react-native-mime-types";
import { DownLoadAFile } from "../../store/slices/fileHandler";
import { IMenu } from "../../models/loggin.type";
import { setCurrentPagePermission } from "../../store/slices/authorization";
import { useLocation } from "react-router-dom";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { RecordLockRelease } from "../recordLock/CommonRecordLock";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  border: "1px solid",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset",
  borderColor: alpha(theme.palette.common.black, 0.15),
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 250,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const StyledNotificationMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 250,
    overflow: "hidden",
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const Header = () => {
  const [menuList, setMenuList] = useState(["UserName", "Admin"]);
  const [isMenuOpen, setisMenuOpen] = useState(false);
  const [imageDialog, setImageDialog] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [isNotificationMenuOpen, setisNotificationMenuOpen] = React.useState<null | HTMLElement>(null);
  const [notificationCount, setNotificationCount] = useState(0);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const theme = useTheme();

  const { isAuthenticated, user, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { isShown } = useSelector((state: RootState) => state.menuShown);
  const { downloadFile } = useSelector((state: RootState) => state.fileHandler);

  const { themeMode, show } = useSelector((state: RootState) => state.menu);
  const [isActive, setIsActive] = useState(false);

  const [activeItem, setActiveItem] = useState(null);
  const [ActiveIconItem, setActiveIconItem] = useState(null);
  const [activeItem_sub, setActiveItem_sub] = useState(null);
  const [sideBarWidth, setsideBarWidth] = useState(70);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const landingPage = useSelector((state: RootState) => state.eventLogs.landingPage);
  const { lockeRecorddData } = useSelector((state: RootState) => state.recordLock);

  const [showWarning, setShowWarning] = useState(false);
  const [navigateName, setNavigateName] = useState("");
  const { handleRecordLockUpdate } = RecordLockRelease();

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenNotificationMenu = (event: React.MouseEvent<HTMLElement>) => {
    setisNotificationMenuOpen(event.currentTarget);
  };
  const handleCloseNotificationMenu = () => {
    setisNotificationMenuOpen(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerToggle = () => {
    dispatch(updateMenu(!show));
  };

  const themeChange = (themeMode: any) => {
    if (themeMode == "dark") {
      dispatch(updatethemeMode("light"));
    } else {
      dispatch(updatethemeMode("dark"));
    }
  };

  const handleChangePassword = () => {
    if (lockeRecorddData) {
      setShowWarning(true);
      setNavigateName("changepassword");
    } else {
      navigate("/changepassword");
    }
  };
  const [blob, setBlob] = useState<string>(
    user?.ProfilePicture !== "" ? "data:image/jpeg;base64," + user?.ProfilePicture : ""
  );
  const profileUrl = user?.ProfileUrl || "";
  const profilePicture = sessionStorage.getItem("profilePicture");

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (profilePicture) {
        try {
          const fileUrl = profilePicture;
          const data = await dispatch(DownLoadAFile({ fileUrl })).unwrap();
          if (data) {
            const objectURL = "data:image/jpeg;base64," + data;
            setBlob(objectURL);
          }
        } catch (error) {
          console.error("Error fetching profile picture:", error);
        }
      } else if (user?.ProfilePicture !== "") {
        const objectURL = "data:image/jpeg;base64," + user?.ProfilePicture;

        setBlob(objectURL);
      } else {
        setBlob("");
      }
    };

    fetchProfilePicture();
  }, [profilePicture, user?.ProfilePicture, user]);

  useEffect(() => {
    if (user?.ProfileUrl) {
      sessionStorage.setItem("profilePicture", user?.ProfileUrl);
    }
  }, []);

  const handleSetLandingPage = (event: React.MouseEvent<HTMLElement>) => {
    const requestBody = {
      ModuleName: "HomeLandingPage",
      Activity: "Set Landing Page",
      UserId: userLoginResponse?.UserId || 0,
      MenuId: permssionPage?.MenuId,
    };

    try {
      dispatch(getLandingPageHome(requestBody)).unwrap();
    } catch (error) {
      console.error("Error setting landing page:", error);
    }
    setIsActive(true);
  };

  const addEventLogPayload: addEventLogRequestParam = {
    ModuleName: "Authentication",
    Action: ACTIVITY.LOGOUT,
    Description: "Logout",
    UserId: userLoginResponse && userLoginResponse.UserId ? userLoginResponse.UserId : 0,
    CreatedBy: user?.UserName || "",
  };

  let activeHomePage = userLoginResponse?.MenuId === currentPagePermissionData?.MenuId ? true : false;

  const handleNavigate = (nav: string) => {
    if (nav === "Logout") {
      handlelogout();
    } else if (nav === "changepassword") {
      navigate("/changepassword");
    }
  };

  const handlelogout = () => {
    dispatch(addEventLog(addEventLogPayload));
    dispatch(logoutUser(false));
    dispatch({ type: "LOGOUT" });
  };

  const handleCloseModel = () => {
    handleRecordLockUpdate();
    handleNavigate(navigateName);
  };
  return (
    <Box
      sx={{
        height: "80px",
        backgroundColor: "background.default",
        "&.MuiPaper-elevation4": {
          boxShadow: "0px 4px 16px 0px rgba(103, 103, 103, 0.16)",
        },
      }}
      // position="static"
    >
      {/* <Container maxWidth="xl" >
        <Toolbar disableGutters> */}
      {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className={headerstyle.sliding_border} sx={{ display: "flex" }}>
          {isShown && (
            <Tooltip title="Menu" arrow>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { xs: "", md: "" }, color: "#ffff" }}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={headerlogo} alt="Logo" style={{ width: "70px", height: "27px", objectFit: "contain" }} />
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        <Search sx={{ fontSize: "20px", color: "text.primary" }}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase placeholder="Search…" inputProps={{ "aria-label": "search" }} />
        </Search>

        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            display: "flex",
           
            paddingRight: "20px",
            paddingTop: "4px",
            color: "action.active",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px", flexDirection: "row" }}>
            {/* {isShown &&  <Tooltip title="Set default Page" arrow> 
          <IconButton onClick={handleSetLandingPage} sx={{ fontSize: "20px", color: "text.primary" }}>
          <Badge
              //badgeContent={notificationCount > 0 ? notificationCount : null} // Show badge if count > 0
              color="primary" 
              variant="dot"
              sx={{
                
                "& .MuiBadge-dot": {
                  borderRadius: '50%', // Make sure the badge is circular
                },
              }}
            >
               <HomeIcon
                sx={{
                  marginTop: "6px",
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  minHeight: "30px",
                  cursor: "pointer",
                  backgroundColor: `${isActive ? "background.default" : "background.default"}`,
                  boxShadow: `${isActive ? "background.default" : `1px 3px 6px 0px ${theme.palette.primary.main}24`}`,
                  color: `${isActive ? 'white' : theme.palette.primary.main}`,
                }}
              />
            </Badge>
          </IconButton>
          </Tooltip>}  */}

            {isShown && (
              <Tooltip title="Set default Page" arrow>
                <IconButton onClick={handleSetLandingPage} sx={{ fontSize: "20px", color: "text.primary" }}>
                  {activeHomePage ? (
                    <Badge
                      color="primary"
                      variant="dot"
                      sx={{
                        "& .MuiBadge-dot": {
                          borderRadius: "50%", // Make sure the badge is circular
                        },
                      }}
                    >
                      <HomeIcon
                      // sx={{
                      //   marginTop: "6px",
                      //   boxSizing: "border-box",
                      //   display: "flex",
                      //   alignItems: "center",
                      //   justifyContent: "left",
                      //   minHeight: "30px",
                      //   cursor: "pointer",
                      //   // backgroundColor: `${isActive ? "background.default" : "background.default"}`,
                      //   // //boxShadow: `${isActive ? `1px 3px 6px 0px ${theme.palette.primary.main}24` :  }`,
                      //   // color: `${isActive ? 'white' : theme.palette.primary.main}`,
                      // }}
                      />
                    </Badge>
                  ) : (
                    <HomeTwoToneIcon
                    // sx={{
                    //   marginTop: "6px",
                    //   boxSizing: "border-box",
                    //   display: "flex",
                    //   alignItems: "center",
                    //   justifyContent: "left",
                    //   minHeight: "30px",
                    //   cursor: "pointer",
                    // backgroundColor: `${isActive ? "background.default" : "background.default"}`,
                    // boxShadow: `${isActive ? "background.default" : `1px 3px 6px 0px ${theme.palette.primary.main}24`}`,
                    // color: `${isActive ? 'white' : theme.palette.primary.main}`,
                    // }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            )}

            {isShown && (
              <Tooltip title="Notification" arrow>
                <IconButton onClick={handleOpenNotificationMenu} sx={{ fontSize: "20px", color: "text.primary" }}>
                  <Badge
                    // sx={{
                    //   color: "#0000007d",
                    // }}
                    badgeContent={notificationCount}
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: theme.palette.primary.main,
                        color: "#fff",
                        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                      },
                    }}
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Toggle Theme" arrow>
              <IconButton onClick={() => themeChange(themeMode)} sx={{ fontSize: "20px", color: "text.primary" }}>
                {themeMode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* <Avatar alt={user?.UserName} src="/static/images/avatar/2.jpg" /> */}
                <Avatar alt={user?.UserName} src={blob || "/static/images/avatar/2.jpg"} />
              </IconButton>
            </Tooltip>

            <StyledMenu
              sx={{ mt: "45px", padding: "0px !important" }}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Box
                sx={{
                  minWidth: "200px",
                  paddingX: "10px",
                  paddingY: "10px",
                  backgroundColor: theme.palette.primary.main,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <Avatar alt={user?.UserName} src="/static/images/avatar/2.jpg" /> */}
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-block",
                    "&:hover .overlay": {
                      opacity: 1,
                      cursor: "pointer",
                    },
                  }}
                >
                  {/* {user?.ProfilePicture !== "" ? (
                  <Avatar alt={user?.UserName} src={user?.ProfileUrl} />
                ) : (
                  <Avatar alt={user?.UserName} src="/static/images/avatar/2.jpg" />
                )} */}
                  <Avatar alt={user?.UserName} src={blob || "/static/images/avatar/2.jpg"} />

                  <Box
                    className="overlay"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      opacity: 0,
                      borderRadius: "50%",
                      transition: "opacity 0.3s",
                      paddingTop: "0px",
                    }}
                    onClick={() => {
                      setImageDialog(true);
                      handleCloseUserMenu();
                    }}
                  >
                    <CustomSvg size={"24px"} color={"#ffff"} name={"camera"} />
                  </Box>
                </Box>

                <Box sx={{ paddingLeft: "10px" }}>
                  <Box sx={{ color: "#ffff" }}>
                    <Typography>
                      {user?.UserName} {`(${user?.UserRole})`}
                    </Typography>
                  </Box>
                  <Box sx={{ color: "#ffff" }}>
                    <Typography>{user?.UserEmail}</Typography>
                  </Box>
                </Box>
              </Box>

              <MenuItem key={"ChangePassword"} sx={{ paddingY: "10px" }} onClick={handleChangePassword}>
                <LockResetIcon fontSize="small" />{" "}
                <Typography fontSize="14px" sx={{ paddingLeft: "10px" }}>
                  {/* <Link to="/changepassword" style={{ textDecoration: 'none', color: theme.palette.text.primary }}>ChangePassword</Link> */}
                  Change Password
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (lockeRecorddData) {
                    setShowWarning(true);
                    setNavigateName("Logout");
                  } else {
                    handlelogout();
                  }

                  // Clear any additional data such as cookies or local storage here
                }}
                key={"logout-button-menu"}
                sx={{ paddingY: "10px" }}
              >
                <LogoutIcon fontSize="small" />{" "}
                <Typography fontSize="14px" sx={{ paddingLeft: "10px" }}>
                  Logout
                </Typography>
              </MenuItem>
            </StyledMenu>

            <StyledNotificationMenu
              sx={{ mt: "45px", padding: "0px !important" }}
              anchorEl={isNotificationMenuOpen}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(isNotificationMenuOpen)}
              onClose={handleCloseNotificationMenu}
            >
              <Notifications
                notificationCount={notificationCount}
                setNotificationCount={setNotificationCount}
                onClose={handleCloseNotificationMenu}
              />
            </StyledNotificationMenu>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <Typography
              variant="subtitle2"
              sx={{
                position: "relative",
                display: "block",
                fontSize: "16px",
                minHeight: "16px",
                m: 0,
                textAlign: "right",
              }}
            >
              Role - {`${user?.UserRole}`}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* </Toolbar>
      </Container> */}

      <Drawer
        open={isMenuOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",

            backgroundColor: "sidebar.background",
            color: "sidebar.textColor",
          },
        }}
      >
        {/* {/* <Sidenavbar handleOpen={function (data: any): void {
          throw new Error("Function not implemented.");
        } } setIsBackDropIsOpen={undefined} /> */}
        {/* {/* <Sidenavbar handleOpen={function (data: any): void {
          throw new Error("Function not implemented.");
        } } setIsBackDropIsOpen={undefined} /> */}
      </Drawer>
      <CustomDialog
        show={imageDialog}
        onHide={() => {
          setImageDialog(false);
        }}
        maxWidth={"sm"}
        header={"Change your profile picture"}
        contentNode={
          <>
            <ImageHandler
              profileUrl={profileUrl}
              imageData={blob || ""}
              onClose={() => {
                setImageDialog(false);
              }}
            />
          </>
        }
      />
      <ModalPopUp
        onDeleteMethod={handleCloseModel}
        isDelete={true}
        onHide={() => {
          setShowWarning(false);
          setNavigateName("");
        }}
        show={showWarning}
        header={"Confirm"}
        text={`Are you sure you want to navigate? Changes you made may not be saved.`}
        ActionName={"Sure"}
      />
    </Box>
  );
};
export default Header;
