import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISeverityMaster } from "../../../models/performanceManagementModels/severityMaster.type";
import DeviationToleranceLevelMasterService from "../services/deviationToldeviationToleranceLevelMaster";

export interface ISeverityMasterData {
    DeviationToleranceLevelTable: ISeverityMaster[];
    isLoading: boolean;
    isDeviationToleranceLevelAdded: boolean;
    isDeviationToleranceLevelUpdated: boolean;
    ToasterMsg: { Message: string; Status: string };
    DeviationToleranceLevelDatas: any;
    DeviationToleranceLevelMasterDetails: any;
    isDeviationToleranceLevelRemoved: boolean;
}

export const initialState: ISeverityMasterData = {
    DeviationToleranceLevelTable: [],
    isLoading: false,
    isDeviationToleranceLevelAdded: false,
    isDeviationToleranceLevelUpdated: false,
    ToasterMsg: { Message: "", Status: "" },
    DeviationToleranceLevelDatas: {},
    DeviationToleranceLevelMasterDetails: [],
    isDeviationToleranceLevelRemoved: false,
};

export const getAllDeviationToleranceLevelMaster = createAsyncThunk(
    "getAllDeviationToleranceLevelMaster",
    async (requestParams: { FormID: number }, { rejectWithValue }) => {
        try {
            const response = await DeviationToleranceLevelMasterService.getAllDeviationToleranceLevelMaster();

            const { deviationToleranceLevelDetails } = response;
            return deviationToleranceLevelDetails;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const CreateDeviationToleranceLevelMaster = createAsyncThunk("saveSeverityMaster", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await DeviationToleranceLevelMasterService.saveDeviationToleranceLevelMaster(requesrParams);
        return response.toaster;
    } catch (error) {
        return rejectWithValue(error);
    }
});
export const updateDeviationToleranceLevelMaster = createAsyncThunk("updateDeviationToleranceLevelMaster", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await DeviationToleranceLevelMasterService.updateDeviationToleranceLevelMaster(requesrParams);
        console.log(requesrParams, "requesrParams");

        return response.toaster;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteDeviationToleranceLevelMaster = createAsyncThunk("deleteDeviationToleranceLevelMaster", async (requestParams: any, { rejectWithValue }) => {
    try {
        const response: any = await DeviationToleranceLevelMasterService.deleteDeviationToleranceLevelMaster(requestParams);
        return response.toaster;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const DeviationToleranceLevelMasterDataSlice = createSlice({
    name: "DeviationToleranceLevelMaster",
    initialState,
    reducers: {
        DeviationToleranceLevelMasterAddandSave: (state, action) => {
            state.isDeviationToleranceLevelAdded = false;
            state.isDeviationToleranceLevelUpdated = false;
            state.isDeviationToleranceLevelRemoved = false;
            //   state.isCompanyDeleted = false;
            //   state.isDuplicate = false;
            state.ToasterMsg.Message = action.payload.message;
            state.ToasterMsg.Status = action.payload.status;
        },
        updateUser: (state, action) => {
            if (action.payload.UserId) {
                let indexVendor = state.DeviationToleranceLevelTable.findIndex((data: any) => data.UserId === action.payload.UserId);
                let copyVendorData = [...state.DeviationToleranceLevelTable];
                copyVendorData[indexVendor] = action.payload;
                state.DeviationToleranceLevelTable = copyVendorData;
            }

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDeviationToleranceLevelMaster.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllDeviationToleranceLevelMaster.fulfilled, (state, action) => {                
                state.DeviationToleranceLevelTable = action.payload.map((data: any) => {
                    data.IsActive = data.isActive === true ? "Active" : "Inactive";
                    data.isActive = data.isActive === true ? "Active" : "Inactive";
                    return data;
                });
                state.isLoading = false;
            })
            .addCase(getAllDeviationToleranceLevelMaster.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(CreateDeviationToleranceLevelMaster.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(CreateDeviationToleranceLevelMaster.fulfilled, (state, action: PayloadAction<any>) => {

                state.isDeviationToleranceLevelAdded = true;
                state.isLoading = false;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(CreateDeviationToleranceLevelMaster.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                // state.ToasterMsg = action.payload.response.data;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(updateDeviationToleranceLevelMaster.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateDeviationToleranceLevelMaster.fulfilled, (state, action: PayloadAction<any>) => {

                state.isDeviationToleranceLevelUpdated = true;
                state.isLoading = false;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(updateDeviationToleranceLevelMaster.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                // state.ToasterMsg = action.payload.response.data;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(deleteDeviationToleranceLevelMaster.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteDeviationToleranceLevelMaster.fulfilled, (state, action: PayloadAction<any>) => {
                state.isDeviationToleranceLevelRemoved = true;
                state.isLoading = false;
                // state.ToasterMsg = action.payload.Message;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(deleteDeviationToleranceLevelMaster.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
    },
});

export const { DeviationToleranceLevelMasterAddandSave, updateUser } = DeviationToleranceLevelMasterDataSlice.actions;
export default DeviationToleranceLevelMasterDataSlice.reducer;
