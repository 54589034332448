
import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography, useTheme } from "@mui/material";
import CustomSvg from "../../../../common/CustomSvg";
import CustomDialog from "../../../../common/dynamicDialog/CustomDialog";
import BasicTableStyle from "../../../../common/dynamicDataGrid/basicTable.module.scss";
import { AssessmentResponseCOBAnswerMode } from "../assessmentResponseCOB/AssessmentResponseCOBAnswerMode";
import { AssessmentDetailContext } from "../CustomerOnBoardingAssessment";

interface basicTableProps {
    selectedGridRecord?: any;
    selectedRecordDetails: any;
    setSelectedRecordDetails?: any;
    closePopUp?: any;
    view?: boolean;
    edit?: boolean;
}
interface ComonCompareTableProps {
    latestcurrentQuestion: any;


  }


  
  const ComonCompareTable: React.FC<ComonCompareTableProps> = ({ latestcurrentQuestion  }) => {    
    const [openPopUp, setOpenPopUp] = useState(false)
    const [latestcurrentQuestionData, setlatestcurrentQuestionData] = useState<any[]>([])
    const [header1, setHeader1] = useState<any>([
        { "DisplayName": "" },
         { "DisplayName": "" },
          { "DisplayName": "Last Assessment Response" },
          
           { "DisplayName": "Current Assessment Response" }, 
           { "DisplayName": " " }]);

    const [header2, setHeader2] = useState<any>([{ "DisplayName": "" }, 
        { "DisplayName": "Questions" },
         { "DisplayName": "Response" }, 
         { "DisplayName": "SME Department" },
           { "DisplayName": " Responder " },
           { "DisplayName": "Action" },
            { "DisplayName": "Response" },
             { "DisplayName": "SME Department" }, 
             { "DisplayName": "Responder" }, 
            
            ]);

            const [attachmentUrl, setAttachmentUrl] = useState<string | null>(null);
           const [finalViewDatas, setfinalViewDatas]  = useState<any>([])
           const[dataView,setDataView ] = useState<any>([])
           const [selectedQuestion, setSelectedQuestion] =useState<any>("");

         const [selectedIndex, setSelectedIndex] = useState<any>()

    const theme = useTheme();
    
    const handleviewClick = (index:any) => {
         if (index || index === 0) setSelectedIndex(index); 
         setSelectedQuestion(latestcurrentQuestionData[index]?.latestAssessment);
        setOpenPopUp(true);
}

const setselectedQuestionindex = (index:any)=>{
    if (index || index === 0) setSelectedIndex(index); 
    setSelectedQuestion(latestcurrentQuestionData[index]?.latestAssessment);
   

 }
 

useEffect(() => {

setlatestcurrentQuestionData(latestcurrentQuestion)
 },[latestcurrentQuestion])

 
    return (
        <>

            <Stack>
                <Box>
 <TableContainer style={{ border: "1px solid #f1f1f1", borderRadius: "5px", maxHeight: "50vh" }}>
          <Table stickyHeader aria-label="simple basic table" sx={{ width: "100%", marginBottom: "24px" }}>
                                <TableHead >
                                    <TableRow>

                                        <TableCell
                                            key={`${header1[0].DisplayName}-Basic_Details_column`}
                                            sx={{
                                                textAlign: "center",
                                                fontFamily: "aptos-bold",
                                                border: "2px solid #f1f1f1",
                                              }}
                                          //  className={`${BasicTableStyle.tab_header} `}
                                        >
                                            {header1[0].DisplayName}
                                        </TableCell>
                                        <TableCell
                                            key={`${header1[1].DisplayName}-Basic_Details_column`}
                                            sx={{
                                                textAlign: "center",
                                                fontFamily: "aptos-bold",
                                                border: "2px solid #f1f1f1",
                                              }}
                                          //  className={`${BasicTableStyle.tab_header} `}
                                        >
                                            {header1[1].DisplayName}
                                        </TableCell>
                                        <TableCell
                                            key={`${header1[2].DisplayName}-Basic_Details_column`}
                                            colSpan={4}
                                            sx={{
                                                textAlign: "center",
                                                fontFamily: "aptos-bold",
                                                border: "2px solid #f1f1f1",
                                              }}
                                          //  className={`${BasicTableStyle.tab_header} `}
                                        >
                                            {header1[2].DisplayName}
                                        </TableCell>

                                        <TableCell
                                            key={`${header1[3].DisplayName}-Basic_Details_column`}
                                            colSpan={3}
                                            sx={{
                                                textAlign: "center",
                                                fontFamily: "aptos-bold",
                                                border: "2px solid #f1f1f1",
                                              }}
                                           // className={`${BasicTableStyle.tab_header} `}
                                        >
                                            {header1[3].DisplayName}
                                        </TableCell>
                                        

                                    </TableRow>
                                    <TableRow>
                                        {header2.map((col: any) => (
                                            <TableCell
                                                key={`${col.DisplayName}-Basic_Details_column`}
                                                sx={{
                                                    textAlign: "center",
                                                    fontFamily: "aptos-bold",
                                                    border: "2px solid #f1f1f1",
                                                  }}
                                               // className={`${BasicTableStyle.tab_header} `}
                                                style={{ top: 57}}
                                            >
                                                {col.DisplayName}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    
                                </TableHead>
                                <TableBody>
                                {latestcurrentQuestionData && latestcurrentQuestionData?.map((data, index) => (
                                    //console.log(data)
                                    <TableRow hover key={`${index+1} - com Question`} className={BasicTableStyle.alternateRow}>
                                        <TableCell className={`${BasicTableStyle.tab_data}`}>
                                            {index+1}
                                        </TableCell> <TableCell className={`${BasicTableStyle.tab_data}`}>
                                            {data.question}
                                        </TableCell>
                                        <TableCell className={`${BasicTableStyle.tab_data}`}>
                                            {data.latestResponse}
                                        </TableCell>
 <TableCell className={`${BasicTableStyle.tab_data}`}>
                                            {data.latestSME}
                                        </TableCell>
                                        <TableCell className={`${BasicTableStyle.tab_data}`}>
                                            {data.latestResponder}
                                        </TableCell>
                                       
                                        <TableCell className={`${BasicTableStyle.tab_data}`}>
                                       
                                            <Tooltip title="View">
                                            
                                               
                                             <IconButton
                                             disabled = {!data?.latestAssessment}
                                             sx={{ display: 'center',  color: data.attachment ? theme.palette.text.primary : theme.palette.text.secondary, }}
                                             onClick={() => {
                                              
                                                    handleviewClick(index);
                                                }}
                                              
                                                 
                                            
                                         >
                                               <CustomSvg
                                                        color={data.attachment ? 'blue' : theme.palette.text.secondary}
                                                        size={"20px"}
                                                        name={"view"}
                                                    />

                                                </IconButton>
                                              
                                                </Tooltip>

                            </TableCell>   
                                        <TableCell className={`${BasicTableStyle.tab_data}`}>
                                            {data.currentResponse}
                                        </TableCell>
                                        <TableCell className={`${BasicTableStyle.tab_data}`}>
                                            {data.currentSME}
                                        </TableCell>
                                        <TableCell className={`${BasicTableStyle.tab_data}`}>
                                            {data.currentResponder}
                                        </TableCell>
                                       
                                    </TableRow>
                                ))}
                            </TableBody>

                            </Table>
                        </TableContainer>
                </Box>

            </Stack >

        
                 
            <CustomDialog
                show={openPopUp}
                onHide={() => { setOpenPopUp(false)
                    // setAttachmentUrl(null)
                    setDataView(null)
                    
                 }}
                maxWidth={"xl"}
                minHeight={"50vh"}
                header={`Compare`}

                contentNode={
                    <>
                     <Grid >
                                <Typography sx={{ height: '12px', color: '#959595', display: 'block', fontSize: '15px' }}>
                                     QUESTION {latestcurrentQuestionData.length} 
                                </Typography>
                                <AssessmentDetailContext.Provider
              value={{
                selectedQuestion,
                QuestionList:latestcurrentQuestionData,
                setselectedQuestionindex,
                selectedQuestionIndex: selectedIndex
                
    
              }}
            >
  <AssessmentResponseCOBAnswerMode isAvailableDo={true} iscompleteDo={true} preViewMode={true} PageName={"N/A"}/>
 </AssessmentDetailContext.Provider>
                               </Grid>
                        
                    </>

                }
            />

        </>
    );
};

export default ComonCompareTable;

