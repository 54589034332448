import React, { useEffect, useState } from "react";
import { RolesandResponsibility } from "../rolesAndResponsibility/RolesAndResponsibility";
import { AddVendorDetails } from "../../vendorDetails/AddVendor";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import { AQMMapping } from "../aqmMapping/AQMMapping";
import {
  updaterolesandresponsAddandEdit,
  updaterolesandresponsresposeData,
} from "../../../store/slices/rolesAndResponsiblity";
import { updateVendorDetailsAddandEdit } from "../../../store/slices/vendorDetails";
import { updateAQMMappingAddandEdit } from "../../../store/slices/assesmentQuestionnaireMapping";
import workFlowService from "../../../store/services/workFlowService";
import { VOBWorflowPages, WorkFlowStatusName } from "../../../config/config";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { Box, Typography } from "@mui/material";
import vendorDetailsService from "../../../store/services/vendorDetails";

export interface vobonboardworkflowprops {
  data?: any;
  isRenewal?: any;
  onClose?: () => void;
  renewalVendorId? : any
}

export const VOBMainWorkFlow: React.FC<vobonboardworkflowprops> = ({ data, onClose , isRenewal , renewalVendorId}) => {
  const {
    vendorDetailsdata,
    isVendorDetailsAdded,
    isVendorDetailsUpdated,
    isVendorDetailsLoading,
    vendorDetailsToasterMsg,
  } = useSelector((state: RootState) => state.vendorDetails);
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const {
    isRolesAndResponsibilityAdded,
    isRolesAndResponsibilityUpdated,
    RolesAndResonsibilityInstanceId,
    isAddEditRolesAndResonsibilityLoading,
    RolesAndResonsibilitytoasterMsg,
  } = useSelector((state: RootState) => state.rolesAndResponsibilityData);
  const { isAQMMappingAdded, isAddEditAQMMappingLoading, AQMMappingToasterMsg } = useSelector(
    (state: RootState) => state.aQMMappingData
  );
  const dispatch = useAppDispatch();

  const [instanceValue, setInstanceValue] = useState<any>(null);
  const [workFlowData, setworkFlowData] = useState<any>();
  const [currentProcess, setcurrentProcess] = useState<any>("");
  const [processList, setprocessList] = useState<any>([]);
  const [instancedata, setinstanceNewFromrr] = useState<any>(null);
  const [currentProcessData, setCurrentProcessData] = useState<any>(null);
  const [VendorDetails, setVendorDetails] = useState<any>(undefined);
  const [isloadingProcessCompleteCheck, setisloadingProcessCompleteCheck] = useState<any>(false);

  useEffect(() => {
    return () => {
      dispatch(updaterolesandresponsAddandEdit(false));
      dispatch(updaterolesandresponsresposeData(false));
      dispatch(updateVendorDetailsAddandEdit(false));
      dispatch(updateAQMMappingAddandEdit(false));
    };
  }, []);

  useEffect(() => {
    dispatch(
      updateLoader(
        isVendorDetailsLoading ||
          isAddEditRolesAndResonsibilityLoading ||
          isloadingProcessCompleteCheck ||
          isAddEditAQMMappingLoading
      )
    );
  }, [
    isVendorDetailsLoading,
    isAddEditRolesAndResonsibilityLoading,
    isloadingProcessCompleteCheck,
    isAddEditAQMMappingLoading,
  ]);

  useEffect(() => {
    if (data) {
      console.log(data, "data && ");
      try {
        console.log("data && instanceData 11");
        let instanceData = JSON.parse(data?.WorkflowProcessAsJson);
        console.log(instanceData, "data && instanceData");
        let firstInstanceData = instanceData.find(
          (item: any) => item?.FormName == VOBWorflowPages.RR || item?.ProcessId == 26
        );
        console.log(instanceData, "data && instanceData" , firstInstanceData);
        if (firstInstanceData) {
          setcurrentProcess(VOBWorflowPages.RR);
          setworkFlowData(data);
          setprocessList([]);
        } else {
          setcurrentProcess("Not Found Process");
          setprocessList([]);
        }
      } catch {
        setcurrentProcess("Not Found Process");
        setprocessList([]);
      }
    }
  }, [data]);

  const closeWorflow = () => {
    if (onClose) {
      onClose();
      dispatch(updateLoader(false));
    }
  };

  const checkFlowCompleted = async (Instvalue: any) => {
    try {
      if (Instvalue) {
        const instanceValue = await workFlowService.getWorkflowInstanceById({
          workflowId: Instvalue?.Workflow_Id,
          InstanceId: Instvalue?.Workflow_InstanceId,
        });
        let resposseData = instanceValue?.data;
        setInstanceValue(resposseData);
        if (resposseData?.Id) {
          return resposseData;
        }
        return false;
      }
    } catch (error) {
      console.error("Error fetching workflow instance:", error);
      return false;
    }
  };

  const checkProcessCompleteandNextProcessGo = async (createdData: any) => {
    setinstanceNewFromrr(createdData);
    setisloadingProcessCompleteCheck(true);
    // call api and take update of this instance data
    let checkOk = await checkFlowCompleted(createdData);
    setisloadingProcessCompleteCheck(false);
    // then data get go next
    console.log(checkOk, "flow - checkOk number");
    if (checkOk) {
      let instanceData = JSON.parse(checkOk?.WorkflowProcessInstanceAsJson);

      let firstInstanceData = instanceData.find((item: any) => item.FormName == currentProcess);
      // take current process updated data and check current process completed or not , if completed go next or go work flow page
      if (firstInstanceData && firstInstanceData.Status == WorkFlowStatusName.Completed) {
        let nextSeq = "";
        // this condition used to cindition applied to process check next process IfYes_GoTo not condition applied find next sequence number of this workflow
        if (firstInstanceData?.ConditionTobeChecked != null && firstInstanceData?.ConditionTobeChecked != "") {
          nextSeq = firstInstanceData.IfYes_GoTo;
        } else {
          let currentSeqNo = firstInstanceData?.ProcessSeqno;
          const filteredData = instanceData.filter((SEQitem: any) => SEQitem.ProcessSeqno > currentSeqNo);

          const nextSequence = filteredData.reduce((prev: any, curr: any) => {
            return prev.ProcessSeqno < curr.ProcessSeqno ? prev : curr;
          });

          nextSeq = nextSequence?.ProcessSeqno;
        }
        // find the next process , not find go to workflow page
        let nextProcess = instanceData.find((item: any) => item.ProcessSeqno == nextSeq && item.Status == WorkFlowStatusName.NotStarted );
        const primaryUserIds = nextProcess?.Primary_User
        ? nextProcess.Primary_User.split(",").map((id: any) => Number(id.trim()))
        : [];
      const secondaryUserIds = nextProcess?.Secondary_User
        ? nextProcess.Secondary_User.split(",").map((id: any) => Number(id.trim()))
        : [];

        const nextStepusers = [...primaryUserIds, ...secondaryUserIds]
        
        if (nextProcess && nextStepusers?.find((userId : any) => userId == userLoginResponse?.UserId)) {
          let NextPageName = nextProcess?.FormName;
          if (NextPageName) {
            setCurrentProcessData(nextProcess);
            setcurrentProcess(NextPageName);
            if(nextProcess?.VendorId){
              callVendorDetails(nextProcess?.VendorId)
            }
          }
        } else {
          closeWorflow();
        }
      } else {
        closeWorflow();
      }
    } else {
      closeWorflow();
    }
  };


  const callVendorDetails = async (venId: any) => {
    try {
      const res = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: Number(venId) });
    
      setVendorDetails(res.data);
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    }
  };
  useEffect(() => {
    const runEffect = async () => {
      if (isRolesAndResponsibilityAdded && currentProcess === VOBWorflowPages.RR && RolesAndResonsibilityInstanceId) {
        checkProcessCompleteandNextProcessGo(RolesAndResonsibilityInstanceId);
        dispatch(updaterolesandresponsAddandEdit(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: RolesAndResonsibilitytoasterMsg }));
      }
    };
    runEffect();
  }, [isRolesAndResponsibilityAdded, RolesAndResonsibilityInstanceId]);

  useEffect(() => {
   
    const runEffect = async () => {
      if (isVendorDetailsAdded && currentProcess == VOBWorflowPages.VendorDetail && instancedata) {
      
        checkProcessCompleteandNextProcessGo(instancedata);
        dispatch(updateVendorDetailsAddandEdit(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: vendorDetailsToasterMsg }));
      }
    };
    runEffect();
  }, [isVendorDetailsAdded]);

  useEffect(() => {
    const runEffectaqm = async () => {
      if (isAQMMappingAdded && currentProcess == VOBWorflowPages.AQMMapping && instancedata) {
        checkProcessCompleteandNextProcessGo(instancedata);
        dispatch(updateAQMMappingAddandEdit(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: AQMMappingToasterMsg }));
        setcurrentProcess("Not Found Process");
      }
    };
    runEffectaqm();
  }, [isAQMMappingAdded]);

  return (
    <>
      {workFlowData && currentProcess === VOBWorflowPages.RR && (
        <RolesandResponsibility
          prePageName={isRenewal ? 'Assessment History' : "Workflow Manager"}
          isEditData={workFlowData}
          onClose={() => closeWorflow()}
          renewal={isRenewal ? true : false}
        />
      )}

      {workFlowData && currentProcess == VOBWorflowPages.VendorDetail && currentProcessData && (
        <AddVendorDetails
          view={false}
          edit={VendorDetails !== undefined ? true : false}
          prePageName={"Workflow Manager"}
          onClose={() => closeWorflow()}
          isEditData={VendorDetails || undefined}
          isDraftData={false}
          isWorkFlow={currentProcessData}
        />
      )}
      {workFlowData && currentProcess == VOBWorflowPages.AQMMapping && currentProcessData && (
        <AQMMapping
          currentProcessData={currentProcessData}
          prePageName={"Workflow Manager"}
          onClose={() => closeWorflow()}
        />
      )}

      {currentProcess === "Not Found Process" ? (
        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Box
            sx={{
              border: "1px solid gray",
              borderRadius: "10px",
              width: "500px",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: "18px !important" }}>
              Current Process Complete <br></br> or <br></br> Something went wrong try again later
            </Typography>
            <Typography onClick={() => closeWorflow()} sx={{ fontSize: "14px", mt: 2, cursor: "pointer" }}>
              Back to Workflow
            </Typography>
          </Box>
        </Box>
      ) : null}
    </>
  );
};
