
import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useAppDispatch } from "../../../../store/store";
import { CustomBasicAccordion } from "../../../../common/dynamicAccordion/CustomBasicAccordion";
import { textFieldWidth } from "../../../../utils/constants";
import LableandValuesPreview from "../../../../common/CommonPreview/LableandValuesPreview";
import { Assesmentdeviationcolums, thresholdQuestionViewColumns } from "../../VendorOnBoardingPreview/FinalAssesmentScoring";
import DynamicBasicTable from "../../../../common/dynamicbasicTable/DynamicBasicTable";
import CommonDataExport from "../../../../common/CommonSheetsDownload/ExcelDownload";
import { useTheme } from "@mui/material/styles";

interface VendorAssessmentSummeryCXOsignoffprops {
    calculatedAssessmentDetails:any
    setpreviewsection? : any
    assessmentHistoryDetails? : any
}

const responseQuestionsColumncommon = [
    { DisplayName: "Question ", Field: "Question" },
    { DisplayName: "Original Response", Field: "oldValue" },
    { DisplayName: "Revised Response by so", Field: "modified_value" }
  ]

export const VendorAssessmentSummeryCXOsignoff: React.FC<VendorAssessmentSummeryCXOsignoffprops> = ({assessmentHistoryDetails , calculatedAssessmentDetails , setpreviewsection}) => {
  const [expanded, setExpanded] = useState<any>({ 0: true, 1: true, 2: true, 3: true, 4: true });
  const [natureServiceDetails, setnatureServiceDetails] = useState<any>({});
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [Finaldeviationdata, setFinaldeviationdata] = useState([]);
  const [responseQuestions, setresponseQuestions] = useState([]);
  const [responseQuestionsColumn, setresponseQuestionsColumn] = useState(responseQuestionsColumncommon);
  const [Finaldeviationdatacolumns, setFinaldeviationdatacolumns] = useState(Assesmentdeviationcolums);
  const [thresholdQuestionsdata, setthresholdQuestionsdata] = useState<any>([]);
  const [thresholdQuestioncolums, setThresholdQuestioncolums] = useState(thresholdQuestionViewColumns);


  useEffect(() => {
      setThresholdQuestioncolums((prevColumns) => [...prevColumns, { DisplayName: "Actions", Field: "Actions" }]);
  }, []);
  useEffect(() => {

    if(calculatedAssessmentDetails){
        console.log(calculatedAssessmentDetails , "calculatedAssessmentDetails");
        
        setFinaldeviationdata(calculatedAssessmentDetails?.deviation)
        setthresholdQuestionsdata(calculatedAssessmentDetails?.thresholdQuestions)
    }

  },[calculatedAssessmentDetails])

  useEffect(() => {
    if(assessmentHistoryDetails){
        console.log(assessmentHistoryDetails , "responseQuestions");
        const response :any = assessmentHistoryDetails
        const transformed : any = Object.values(
            response.reduce((acc : any, item : any) => {
              const { Assessement_MappingId, Updated_On, Question , Answer_Type , ChoicesScore , choices , Response_Received } = item;
              const key = `${Assessement_MappingId}-${Question}`;
               let answer : any  = Answer_Type ? choices.find((choice_item : any) => choice_item?.Score == ChoicesScore)?.Attribute_Response : Response_Received
               if (!acc[key]) {
                acc[key] = {
                  Question,
                  oldValue: answer, 
                  modified_value: answer, 
                  oldUpdated_On: Updated_On, 
                  modifiedUpdated_On: Updated_On, 
                };
              } else {
              
                if (Updated_On < acc[key].oldUpdated_On) {
                  acc[key].oldValue = answer;
                  acc[key].oldUpdated_On = Updated_On;
                }
                if (Updated_On > acc[key].modifiedUpdated_On) {
                  acc[key].modified_value = answer;
                  acc[key].modifiedUpdated_On = Updated_On;
                }
              }
          
              return acc;
            }, {})
          );
        setresponseQuestions(transformed)
    }

  },[assessmentHistoryDetails])

  const handleAccordionChange = (index: number) => {
    setExpanded((prevExpanded: any[]) => ({ ...prevExpanded, [index]: !prevExpanded[index] }));
  };



  return (
    <>
    <Box sx={{display:'flex' , alignItems :'center' , justifyContent:'flex-end' , gap:2}}>
    <Typography
                          onClick={() => {
                            setpreviewsection && setpreviewsection(true);
                          }}

                         
                          color={`${theme.palette.primary.main}`} 
                          sx={{ textAlign: "left", cursor: "pointer", textDecoration: "underline" }}
                        >
                          Vendor Assessment Details
                        </Typography>
    <CommonDataExport
            header={responseQuestionsColumn}
            data={ responseQuestions}
            otherdatas={[[...Finaldeviationdata] , [...thresholdQuestionsdata]]}
            otherheaders={[[...Finaldeviationdatacolumns] , [...thresholdQuestioncolums]]}
            table_name={"Assessment Summary"}
            button_title={"Download"}
          />{" "}
    </Box>
      <CustomBasicAccordion
        expanded={expanded[0]}
        handleChange={() => handleAccordionChange(0)}
        title="Vendor Assessment Response"
        details={<>
                   <Box>
                   <DynamicBasicTable
            table_name={"VENDOR ASSESSMENT RESPONSE"}
            style={{ border: "1px solid #f1f1f1", borderRadius: "5px" }}
            rowData={responseQuestions}
            columns={responseQuestionsColumn}
          />
            </Box>
        
        </>}
      />
      
      <CustomBasicAccordion
        expanded={expanded[1]}
        handleChange={() => handleAccordionChange(1)}
        title="Category-Wise Summary"
        details={<>   <Box>
                <DynamicBasicTable
            table_name={"CATEGORY-WISE SUMMARY"}
            style={{ border: "1px solid #f1f1f1", borderRadius: "5px" }}
            rowData={Finaldeviationdata}
            columns={Finaldeviationdatacolumns}
          />
          </Box></>}
      />
       
      <CustomBasicAccordion
        expanded={expanded[2]}
        handleChange={() => handleAccordionChange(2)}
        title="Outliers & Mitigation"
        details={<>   <Box>
             <DynamicBasicTable
            table_name={"OUTLIERS & MITIGATIONS"}
            style={{ border: "1px solid #f1f1f1", borderRadius: "5px" }}
            rowData={thresholdQuestionsdata}
            columns={thresholdQuestioncolums}
          />
          </Box></>}
      />
       
    </>
  );
};
