import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import departmentService from "../services/departmentService";
import { IMakerCheckerStatus, IPendingApproval } from "../../models/pendingApproval.type";
import pendingApprovalService from "../services/pendingApprovalService";
export interface IPendingApprovalData {
  pendingApprovalRecords: IPendingApproval[];
  isLoading: boolean;
  pendingApprovalByID: any;
  makerCheckerStatus: IMakerCheckerStatus[];
  isApprovedorReject: boolean;
  isApprovedorRejectFailed: boolean;
  ApproveorRejectReq: any;
  ToasterMsg: any;
}

export const initialState: IPendingApprovalData = {
  pendingApprovalRecords: [],
  isLoading: false,
  pendingApprovalByID: {
    NewValues: [],
    OldValues: [],
  },
  makerCheckerStatus: [],
  isApprovedorReject: false,
  isApprovedorRejectFailed: false,
  ApproveorRejectReq: {},
  ToasterMsg: { Message: "", Status: "" },
};

export const getPendingApprovalList = createAsyncThunk(
  "getPendingApprovalList",
  async (requesrParams: { category: string }, { rejectWithValue }) => {
    try {
      const response = await pendingApprovalService.getPendingApprovalList(requesrParams);
      const { ApprovalList } = response.data;
      return ApprovalList;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMakerCheckerStatus = createAsyncThunk(
  "getMakerCheckerStatus",
  async (requesrParams: { Name: string }, { rejectWithValue }) => {
    try {
      const response = await pendingApprovalService.getMakerCheckerStatus(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMakerCheckerApprovalById = createAsyncThunk(
  "GetMakerCheckerApprovalById",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      const response = await pendingApprovalService.getMakerCheckerApprovalById(reqBody);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateApproveorReject = createAsyncThunk(
  "updateApproveorReject",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      const response = await pendingApprovalService.updateApproveorReject(reqBody);
      const { data } = response;
      return data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateApproveorRejectforCompany = createAsyncThunk(
  "updateApproveorRejectforCompany",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      const response = await pendingApprovalService.updateApproveorRejectCompany(reqBody);
      const { data } = response;
      return data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateApproveorRejectforVendor = createAsyncThunk(
  "updateApproveorRejectforVendor",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      const response = await pendingApprovalService.updateApproveorRejectVendor(reqBody);
      const { data } = response;
      return data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateApproveorRejectforAQM = createAsyncThunk(
  "updateApproveorRejectforAQM",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      const response = await pendingApprovalService.updateApproveorRejectforAQM(reqBody);
      const { data } = response;
      return data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateApproveorRejectforUser = createAsyncThunk(
  "updateApproveorRejectforUser",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      const response = await pendingApprovalService.updateApproveorRejectforUser(reqBody);
      const { data } = response;
      return data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateApproveorRejectforRole = createAsyncThunk(
  "updateApproveorRejectforRole",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      const response = await pendingApprovalService.updateApproveorRejectforRole(reqBody);
      const { data } = response;
      return data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateApproveorRejectforSC = createAsyncThunk(
  "updateApproveorRejectforSC",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      const response = await pendingApprovalService.updateApproveorRejectforSC(reqBody);
      const { data } = response;
      return data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const pendingApprovalDataSlice = createSlice({
  name: "pendingApprovalData",
  initialState,
  reducers: {
    updateApproveorRejectFlag: (state, action) => {
      state.isApprovedorReject = action.payload;
      state.pendingApprovalByID = {
        NewValues: [],
        OldValues: [],
      };
      state.ApproveorRejectReq = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPendingApprovalList.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(getPendingApprovalList.fulfilled, (state, action: PayloadAction<any>) => {
        state.pendingApprovalRecords = action.payload;
        // state.isLoading = false;
        console.log(action.payload)
      })
      .addCase(getPendingApprovalList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getMakerCheckerApprovalById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMakerCheckerApprovalById.fulfilled, (state, action: PayloadAction<any>) => {
        state.pendingApprovalByID = action.payload;
        state.isLoading = false;
      })
      .addCase(getMakerCheckerApprovalById.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getMakerCheckerStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMakerCheckerStatus.fulfilled, (state, action: PayloadAction<any>) => {
        state.makerCheckerStatus = action.payload;
        state.isLoading = false;
      })
      .addCase(getMakerCheckerStatus.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(updateApproveorReject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateApproveorReject.fulfilled, (state, action: PayloadAction<any>) => {
        state.isApprovedorReject = true;
        // state.ToasterMsg = action.payload.Message
        state.isLoading = false;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.IsSuccess;
      })
      .addCase(updateApproveorReject.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isApprovedorRejectFailed = true;
      })
      .addCase(updateApproveorRejectforCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateApproveorRejectforCompany.fulfilled, (state, action: PayloadAction<any>) => {
        state.isApprovedorReject = true;
        // state.ToasterMsg = action.payload.Message
        state.isLoading = false;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.IsSuccess;
      })
      .addCase(updateApproveorRejectforCompany.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isApprovedorRejectFailed = true;
      })

      .addCase(updateApproveorRejectforVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateApproveorRejectforVendor.fulfilled, (state, action: PayloadAction<any>) => {
        state.isApprovedorReject = true;
        // state.ToasterMsg = action.payload.Message
        state.isLoading = false;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.IsSuccess;
      })
      .addCase(updateApproveorRejectforVendor.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isApprovedorRejectFailed = true;
      })
      .addCase(updateApproveorRejectforAQM.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateApproveorRejectforAQM.fulfilled, (state, action: PayloadAction<any>) => {
        state.isApprovedorReject = true;
        // state.ToasterMsg = action.payload.Message
        state.isLoading = false;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.IsSuccess;
      })
      .addCase(updateApproveorRejectforAQM.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isApprovedorRejectFailed = true;
      })
      .addCase(updateApproveorRejectforUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateApproveorRejectforUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.isApprovedorReject = true;
        // state.ToasterMsg = action.payload.Message
        state.isLoading = false;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.IsSuccess;
      })
      .addCase(updateApproveorRejectforUser.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isApprovedorRejectFailed = true;
      })
      .addCase(updateApproveorRejectforRole.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateApproveorRejectforRole.fulfilled, (state, action: PayloadAction<any>) => {
        state.isApprovedorReject = true;
        // state.ToasterMsg = action.payload.Message
        state.isLoading = false;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.IsSuccess;
      })
      .addCase(updateApproveorRejectforRole.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isApprovedorRejectFailed = true;
      })
      .addCase(updateApproveorRejectforSC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateApproveorRejectforSC.fulfilled, (state, action: PayloadAction<any>) => {
        state.isApprovedorReject = true;
        state.isLoading = false;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.IsSuccess;
      })
      .addCase(updateApproveorRejectforSC.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isApprovedorRejectFailed = true;
      });

  },
});

export const { updateApproveorRejectFlag } = pendingApprovalDataSlice.actions;
export default pendingApprovalDataSlice.reducer;
