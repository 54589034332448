import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { memo } from "react";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import COBReferHistorical from "./SMEReferHistorical/COBReferHistorical";
import { textFieldWidth } from "../../../utils/constants";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import PageInnerHeading from "../../../common/CommonHeadings/PageInnerHeading";
import PageMainHeading from "../../../common/CommonHeadings/PageMainHeading";
import { CustomerDetailsSection } from "../CommonPages/CustomerDetailsSection";
import { DynamicToggleButton } from "../../../common/dynamicToggle/DynamicToggleButton";
import { AssessmentResponseCOBAnswerMode } from "./assessmentResponseCOB/AssessmentResponseCOBAnswerMode";
import ExcelDownload from "../../../common/CommonSheetsDownload/ExcelDownload";
import PreviousAssessmentReviewSO from "./SOReview/PreviousAssessmentReviewSO";
import { LovConfig, VOBWorflowPages } from "../../../config/config";
import { useTheme } from "@mui/material/styles";
import { VendorHistory } from "../../vendorHistory/VendorHistory";
import { RootState, useAppDispatch } from "../../../store/store";
import {
  addcustomerAssessment,
  getCustomerAssessmentbyId,
  updateCustomerAssessmentAddandEdit,
} from "../../../store/slices/cobAssessment";
import { useSelector } from "react-redux";
import { getLovList } from "../../../store/slices/lovMasterData";
import FilteViewer from "../../../common/fileViewer/FilteViewer";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import COBCXOApproval from "../CXOApproval/CXOApproval";
import { checkStepStatus, possibletosendDoCOBField, validateALLCOBField } from "./AssessmentValidation";
import workFlowService from "../../../store/services/workFlowService";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import CustomSvg from "../../../common/CustomSvg";
import DynamicBasicTable from "../../../common/dynamicbasicTable/DynamicBasicTable";
import CommonDataExport from "../../../common/CommonSheetsDownload/ExcelDownload";
import fileHandlerService from "../../../store/services/fileHandlerService";
import * as mime from "react-native-mime-types";
import AssessmentResponseCustomerOB from "./assessmentResponseCOB/AssessmentResponseCustomerOB";
interface CustomerOnBoardingAssessmentProps {
  isEditData?: any;
  view?: boolean;
  edit?: boolean;
  isViewOnly?: boolean;
  currentProcessData?: any;
  prePageName?: any;
  onclose?: () => void;
}

interface assesmentContextProps {
  selectedQuestion: any;
  QuestionList: any[];
  validationErrors?: any;
  selectedQuestionIndex?: any;
  setselectedQuestionindex?: (value: any) => void;
  onTableValueUpdate?: (value: any) => void;
  setsaveQuestion?: (value: any) => void;
}

export const AssessmentDetailContext = createContext<assesmentContextProps | undefined>(undefined);
const CustomerOnBoardingAssessment: React.FC<CustomerOnBoardingAssessmentProps> = ({
  isEditData,
  view,
  currentProcessData,
  prePageName,
  onclose,
  isViewOnly,
  edit,
}) => {
  // const { exportExcelNew } = ExcelDownload()
  const theme = useTheme();
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const {
    CustomerAssessmentdata,
    isAddEditcustomerAssessmentLoading,
    iscustomerAssessmentAdded,
    CustomerAssessmentToasterMsg,
    iscustomerAssessmentSuccess,
  } = useSelector((state: RootState) => state.CustomerAssessmentData);
  const dispatch = useAppDispatch();
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [assesmentTypeILV, setassesmentTypeILV] = useState<any[]>([]);
  const [assesmentTypeIILV, setassesmentTypeIILV] = useState<any[]>([]);
  const [PageName, setPageName] = useState<string>("");
  const [isHistorical, setisHistorical] = useState(false);
  const [customerDetails, setcustomerDetails] = useState<any>({});
  const [answerMode, setanswerMode] = useState<any>(0);
  const [isPriviousAssessment, setisPriviousAssessment] = useState(false);

  //------
  const [assessmentData, setassessmentData] = useState<any>("");
  const [validationErrors, setvalidationErrors] = useState<any>({});
  const [QuestionList, setQuestionList] = useState<any[]>([]);
  const [selectedQuestion, setselectedQuestion] = useState<any>({});
  const [selectedQuestionIndex, setselectedQuestionIndex] = useState<any>("");
  //..........
  const [processStepsList, setprocessStepsList] = useState<any>("");
  //-----
  const [isConform, setisConform] = useState<boolean>(false);
  const [issenttoDo, setissenttoDo] = useState<any>(false);
  const [isCXOPreview, setisCXOPreview] = useState<any>(false);
  // -----
  const [iscompleteDo, setiscompleteDo] = useState<boolean>(false);
  const [isAvailableDo, setisAvailableDo] = useState<boolean>(false);
  const [isAvailableSME, setisAvailableSME] = useState<boolean>(false);
  const [finalValidation, setFinalValidation] = useState<boolean>(false);
  const [doCheckedornot, setDoCheckedornot] = useState<boolean>(false);
  const [downloadcolums, setDownloadcolums] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState<any[]>([]);
  //-----------

  const setistoricalcloseoropen = (value: any) => {
    if (value == 0) {
    } else if (value == 1) {
      setisPriviousAssessment(false);
      setisHistorical(false);
    }
  };

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
  }, []);
  
  useEffect(() => {
    dispatch(updateLoader(isAddEditcustomerAssessmentLoading));
  }, [isAddEditcustomerAssessmentLoading]);

  useEffect(() => {
    if (iscustomerAssessmentAdded) {
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: CustomerAssessmentToasterMsg,
          isTosterFailed: !iscustomerAssessmentSuccess,
        })
      );
      dispatch(updateCustomerAssessmentAddandEdit(false));

      if (iscustomerAssessmentSuccess && onclose) {
        onclose();
      }
    }
  }, [iscustomerAssessmentAdded]);
  useEffect(() => {
    const callInstanceData = async () => {
      try {
        if (currentProcessData) {
          const instanceValue = await workFlowService.getWorkflowInstanceByVendorId({
            vendorId: currentProcessData?.VendorId,
            Project_Detail_Id: currentProcessData?.ProjectId,
          });
          let resposseData = instanceValue?.data;
          try {
            let processSteps: any = JSON.parse(resposseData?.WorkflowProcessInstanceAsJson);

            setprocessStepsList(processSteps);
          } catch {}
        }
      } catch {}
    };

    if (currentProcessData) {
      callInstanceData();
      setPageName(currentProcessData?.FormName);
    }
  }, []);

  useEffect(() => {
    const fetchStepStatus = async () => {
      if (processStepsList) {
        const obje: any = await checkStepStatus(processStepsList);
        setiscompleteDo(Boolean(obje?.Do_Status));
        setisAvailableDo(Boolean(obje?.Do));
        setisAvailableSME(Boolean(obje?.SME));
      }
    };

    fetchStepStatus();
  }, [processStepsList]);

  useEffect(() => {
    if (assessmentData) {
      let cuDetails: any = { ...assessmentData };
      cuDetails.Workflow_Name = cuDetails?.WorkFlow_Name;
      cuDetails.Assessment_Type_1 =
        assesmentTypeILV.find((item) => item.LovId == cuDetails.Assessment_Type_1)?.Options || "";
      cuDetails.Assessment_Type_2 =
        assesmentTypeIILV.find((item) => item.LovId == cuDetails.Assessment_Type_2)?.Options || "";
      setcustomerDetails(cuDetails);
    }
  }, [assesmentTypeILV, assesmentTypeIILV, assessmentData]);

  useEffect(() => {
    setassesmentTypeILV(lovTable.filter((item: any) => item.Name === LovConfig?.Assessment_Type) || []);
    setassesmentTypeIILV(lovTable.filter((item: any) => item.Name === LovConfig?.Assessment_TypeII) || []);
  }, [lovTable]);
  const setAnswerModeValue = (val: any) => {
    setanswerMode(val);
    if (val == 0) {
      setselectedQuestion({});
    } else {
      setselectedQuestionindex(0);
    }
  };

  const setsaveQuestion = (data: any) => {
   
    if (selectedQuestionIndex >= 0 && data) {
      setQuestionList((prev) => prev.map((item, index) => (index === selectedQuestionIndex ? data : item)));
    }
  };

  const onActiveHeadersUpdate = useCallback((headers: any) => {
    const activeHeaders = headers.filter((header: any) => header.condition);
    let DownloadColumn: any = [];
    activeHeaders.forEach((item: any) => {
      if (item?.label !== "Action" && item?.label !== "") {
        DownloadColumn.push({ DisplayName: item?.label, Field: item?.Field });
      }
    });
 
    setDownloadcolums(DownloadColumn);
  }, []);



  const onTableValueUpdate = (Data: any) => {
    if (Data) {
      setDownloadData(Data);
    }
  };

  const setselectedQuestionindex = (dataindex: any) => {
    console.log(dataindex, "dataindex 1");

    if (dataindex >= 0 && QuestionList && QuestionList.length > dataindex) {
      setselectedQuestionIndex(dataindex);
      let selectedQues: any = QuestionList[dataindex];
      console.log(dataindex, "dataindex 1");
      setanswerMode(1);
      setselectedQuestion(selectedQues);
    }
  };
  useEffect(() => {
    if (CustomerAssessmentdata) {
      setassessmentData(CustomerAssessmentdata);
      if (CustomerAssessmentdata?.AssessmentQuestionnaires) {
        console.log(CustomerAssessmentdata?.AssessmentQuestionnaires, "QuestionList");

        setQuestionList(CustomerAssessmentdata?.AssessmentQuestionnaires);
      }
    }
  }, [CustomerAssessmentdata]);

  useEffect(() => {
    if (PageName && currentProcessData && currentProcessData?.VendorId && currentProcessData?.ProjectId) {
      let isSME: any = VOBWorflowPages.SMEResponse == PageName ? true : false;
      let isDo: any = VOBWorflowPages.DoReview == PageName ? true : false;
      dispatch(
        getCustomerAssessmentbyId({
          Vendor_Id: currentProcessData?.VendorId,
          Project_Detail_Id: currentProcessData?.ProjectId,
          UserId: userLoginResponse?.UserId,
          IsSME: isSME,
          IsDo: isDo,
        })
      );
    }
  }, [currentProcessData, PageName]);

  const closecxoPrevieworsendtoCxo = (suretosubmit: any) => {
    if (suretosubmit) {
      Suretosubmit(false);
    } else {
      setisCXOPreview(false);
    }
  };
  const closeConformationPopup = () => {
    setisConform(false);
    setissenttoDo(false);
  };

  const Suretosubmit = (isdo: any) => {
    if (true) {
      setisConform(true);
      setissenttoDo(isdo);
    }
  };

  useEffect(() => {
    const validateFields = async () => {
      const isValid = await validateALLCOBField(PageName, QuestionList);
      setFinalValidation(isValid);
    };

    const checkDoStatus = async () => {
      const canSend = await possibletosendDoCOBField(QuestionList);
      setDoCheckedornot(canSend);
    };

    validateFields();
    checkDoStatus();
  }, [PageName, QuestionList]);

  const closeFromCxoPreview = (val?: any) => {
    console.log(val, "values");

    if (val == 0 && onclose) {
      onclose();
    } else {
      setisCXOPreview(false);
    }
  };



  const handleSubmitAssessmentForm = () => {
    if (true) {
      let Questions: any = [];

      QuestionList.forEach((item: any) => {
        let obj: any = {
          Assessement_Questionnaire_ResponseId: item?.Assessement_Questionnaire_ResponseId,
          Assessment_Questionnaire_Mapping_Id: item?.Assessment_Questionnaire_Mepping_Id,
          Assessment_Questionnaire_Id: item?.Assessment_Questionnaire_Id,
          Attachment: item?.Attachment ? item?.Attachment : "",
          Response_In_Option: Number(item?.Response_In_Option),
          Response_In_Text: item?.Response_In_Text,
          SO_ReviewStatus: item?.SO_ReviewStatus ? item?.SO_ReviewStatus : 0,
          SME_Remarks: "",
          SO_Remarks: item?.SO_Remarks || "",
          IsDo: item?.IsDo ? item?.IsDo : false,
          IsAddInPM: item?.IsAddInPM ? item?.IsAddInPM : false,
          DO_ReviewStatus: item?.DO_ReviewStatus ? item?.DO_ReviewStatus : 0,
          DO_Remarks: item?.DO_Remarks || "",
          Status: 1,
          Updated_By: userLoginResponse?.UserName,
          Updated_On: new Date(),
        };
        Questions.push(obj);
      });

      let formData: any = {
        ModuleName: currentProcessData?.FormName,
        Activity: "Create",
        UserId: userLoginResponse?.UserId,
        CreatedBy: userLoginResponse?.UserEmail,
        MenuId: currentPagePermissionData?.MenuId,
        Workflow_InstanceId: currentProcessData ? currentProcessData?.Workflow_InstanceId : 0,
        Project_Detail_Id: currentProcessData ? currentProcessData?.ProjectId : 0,
        IsRenewal: currentProcessData?.IsRenewal ? currentProcessData?.IsRenewal : false,
        Process_InstanceId: currentProcessData ? currentProcessData?.Id : 0,
        VendorId: currentProcessData ? currentProcessData?.VendorId : 0,
        FormId: currentProcessData?.ProcessId,
        CustomerAssessmentMapping: Questions,
        ConditionValue: currentProcessData?.ConditionName == "IsDOApprovalNeeded" ? (issenttoDo ? 1 : 0) : null,
      };
      dispatch(addcustomerAssessment(formData));
    }
  };
  return (
    <>
      {PageName && (
        <>
          {!isHistorical && !isPriviousAssessment && !isCXOPreview && (
            <>
              <Stack spacing={3}>
                {!isViewOnly && (
                  <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                    <CustomBreadcrumbs
                      list={[
                        { value: 0, name: prePageName ? prePageName : "COB Assessment" },
                        { value: 1, name: `${edit ? "COB Assessment" : view ? "COB Assessment" : "COB Assessment"}` },
                      ]}
                      onchange={(val: any) => {
                        if (val == 0 && onclose) onclose();
                      }}
                    />

                    {currentProcessData && (
                      <Box sx={{ width: "20" }}>
                        <VendorHistory isEditData={currentProcessData} />
                      </Box>
                    )}
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <PageMainHeading title={`${customerDetails?.Vendor_Name || ""}`} />
                </Box>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <CustomerDetailsSection isEditData={customerDetails} />

                  <Divider />
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  {PageName == VOBWorflowPages.SMEResponse ? (
                    <PageInnerHeading
                      title={
                        answerMode == 1
                          ? `QUESTION ${
                              selectedQuestionIndex >= 0
                                ? selectedQuestionIndex + 1 + "/" + QuestionList.length
                                : QuestionList.length
                            }`
                          : `QUESTION LIST`
                      }
                    />
                  ) : (
                    <PageInnerHeading
                      title={
                        answerMode == 1
                          ? `QUESTION ${
                              selectedQuestionIndex >= 0
                                ? selectedQuestionIndex + 1 + "/" + QuestionList.length
                                : QuestionList.length
                            }`
                          : `REVIEW ASSESSMENT`
                      }
                    />
                  )}

                  <Box sx={{ display: "flex", gap: 2 }}>
                    {answerMode == 0 && (
                      <>
                        <CommonDataExport
                          header={downloadcolums}
                          data={downloadData}
                          table_name={"Customer Assessment"}
                        />
                        {!isViewOnly && (
                          <>
                            {(PageName == VOBWorflowPages.SOReview || PageName == VOBWorflowPages.DoReview) && (
                              <CustomButton
                                variant="outlined"
                                name={"Previous Assessment"}
                                startIcon={
                                  <CustomSvg color={`${theme.palette.primary.main}`} size={"18px"} name={"compare"} />
                                }
                                onClick={() => setisPriviousAssessment(true)}
                              />
                            )}
                            {PageName == VOBWorflowPages.SMEResponse && (
                              <CustomButton
                                variant="outlined"
                                name={"Refer Historical Data"}
                                onClick={() => setisHistorical(true)}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                    <Box>
                      <DynamicToggleButton
                        list={["list", "grid_view"]}
                        onchange={setAnswerModeValue}
                        value={answerMode}
                      />
                    </Box>
                  </Box>
                </Box>
                <AssessmentDetailContext.Provider
                  value={{
                    selectedQuestion,
                    QuestionList,
                    selectedQuestionIndex,
                    validationErrors,
                    setselectedQuestionindex,
                    setsaveQuestion,
                    onTableValueUpdate,
                  }}
                >
                  {answerMode == 0 ? (
                    <AssessmentResponseCustomerOB
                      iscompleteDo={iscompleteDo}
                      isAvailableDo={isAvailableDo}
                      PageName={PageName}
                      onActiveHeadersUpdate={onActiveHeadersUpdate}
                    />
                  ) : (
                    <AssessmentResponseCOBAnswerMode
                      iscompleteDo={iscompleteDo}
                      isAvailableDo={isAvailableDo}
                      PageName={PageName}
                      isView={view}
                      preViewMode={isViewOnly}
                    />
                  )}
                </AssessmentDetailContext.Provider>

                {answerMode == 0 && !isViewOnly && (
                  <Box
                    sx={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", gap: 2, width: "100%" }}
                  >
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Close"}
                      onClick={() => {
                        // onclose &&
                        // onclose(false)
                      }}
                    />
                    {(PageName == VOBWorflowPages.SMEResponse || PageName == VOBWorflowPages.DoReview) && (
                      <CustomButton
                        type="reset"
                        disabled={Boolean(!finalValidation)}
                        name={"Send to SO"}
                        onClick={() => Suretosubmit(false)}
                      />
                    )}
                    {PageName == VOBWorflowPages.SOReview && (
                      <CustomButton
                        type="reset"
                        disabled={Boolean(!finalValidation)}
                        variant="outlined"
                        name={"CXO Preview"}
                        onClick={() => setisCXOPreview(true)}
                      />
                    )}
                    {PageName == VOBWorflowPages.SOReview &&
                      currentProcessData?.ConditionName == "IsDOApprovalNeeded" && (
                        <CustomButton
                          type="reset"
                          disabled={!finalValidation || !doCheckedornot}
                          name={"Send to DO"}
                          onClick={() => Suretosubmit(true)}
                        />
                      )}
                  </Box>
                )}
              </Stack>
            </>
          )}

          {isCXOPreview && (
            <COBCXOApproval
              currentProcessData={currentProcessData}
              prePageName={prePageName}
              onclose={closeFromCxoPreview}
              onsubmitso={closecxoPrevieworsendtoCxo}
              SoPreview={true}
            />
          )}
          {isHistorical && (
            <Box sx={{ width: "100%" }}>
              <COBReferHistorical currentProcessData={currentProcessData} onClose={setistoricalcloseoropen} />
            </Box>
          )}
          {isPriviousAssessment && (
            <Box sx={{ width: "100%" }}>
              <PreviousAssessmentReviewSO
                customerDetails={customerDetails}
                currentAssessmentData={assessmentData}
                currentQuestionList={QuestionList}
                currentProcessData={currentProcessData}
                onClose={setistoricalcloseoropen}
              />
            </Box>
          )}

          <ModalPopUp
            onDeleteMethod={() => handleSubmitAssessmentForm()}
            isDelete={true}
            onHide={() => closeConformationPopup()}
            show={isConform}
            ActionName={"Complete"}
            header={"Confirm"}
            text={`Are you sure you want to submit  the Assessment ?`}
          />

         
        </>
      )}
    </>
  );
};

export default memo(CustomerOnBoardingAssessment);
