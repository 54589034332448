import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomFileUploader from "../../../../common/dynamicFileUploader/CustomFileUploader";
import FilePreviewer from "../../../../common/fileViewer/FilePreviewer";
import ExcelJS from "exceljs";
import { AQMasterCOBImportdata } from "./BulkUploadQuestionCOBTemplate";
import { AQMMappingCOBQuestionsTable } from "../MappedQuestionListTable";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
interface BulkUploadQuestionCOBProps {
  onclose?: () => void;
  handleSubmitBulkAssessmentQuestion: (data:any) => void;
  DepartmentListdata: any;
  ExistingData: any;
}

export const BulkUploadQuestionCOB: React.FC<BulkUploadQuestionCOBProps> = ({ onclose, DepartmentListdata , handleSubmitBulkAssessmentQuestion , ExistingData }) => {
  const [excelsheet, setvalidexcelsheet] = useState<any>(null);
  const [uploaddata, setUploaddata] = useState<any[]>([]);
  const [DepartmentList, setDepartmentList] = useState<any[]>([]);
  const [Validatateddata, setselectedValidatateddata] = useState<any[]>([]);
  const [commonError, setcommonError] = useState<any>("");

  const clearOldData = () => {
    setvalidexcelsheet(null);
    setUploaddata([]);
    setselectedValidatateddata([]);
  };

  useEffect(() => {
    if (DepartmentListdata) {
      setDepartmentList(DepartmentListdata);
    }
  }, [DepartmentListdata]);
  useEffect(() => {
    if(uploaddata && uploaddata.length > 0){
      department_uploadsheet_validation()
    }
   
  },[uploaddata])
  const ExcelToJson = async (data: any) => {
    const headers = data[1];

    if (headers) {
      const jsonData = [];

      for (let i = 2; i < data.length; i++) {
        const row = data[i];
        if (!row) {
          continue;
        }
        const obj: any = { id: i - 1 };
        for (let j = 1; j < headers?.length; j++) {
          const cellValue = row[j];
          if (cellValue && typeof cellValue === "object" && "text" in cellValue) {
            obj[headers[j]] = cellValue.text;
          } else {
            obj[headers[j]] = typeof cellValue === "boolean" ? cellValue.toString() : cellValue;
          }
        }

        jsonData.push(obj);
      }

      return jsonData;
    }
  };
  const handleFileUpload = async (e: any | null) => {
    clearOldData();
    setvalidexcelsheet(e);
    if (e !== null) {
      const file = e[0];
      const workbook = new ExcelJS.Workbook();

      await workbook.xlsx.load(file);

      const worksheet = workbook.worksheets[0];
      const data = worksheet.getSheetValues();

      let JsonSheetData: any = await ExcelToJson(data);
      console.log(JsonSheetData, "JsonSheetData");
      setUploaddata(JsonSheetData || []);
    } else {
    }
  };

  const validateChoicesAndcount = (choicesStr: string, choicecount: any ) => {
    // Split choices and scores by comma
    const choices = choicesStr?.split(",");
    const choicescount = Number(choicecount);
    const choicesArray: any[] = [];

    let isValid : boolean = true ;
    let ismessage : any = "" ;

    if (choices.length !== choicescount ) {
      isValid = false ;
      ismessage =  "Choices and Count is mismatch."
    }

    const uniqueChoices = new Set(choices);
    if (uniqueChoices.size !== choices.length) {
       isValid = false ;
      ismessage =  "Choices contain duplicates."
    }

    if (choices ) {
       isValid = isValid ;
      ismessage =  ismessage;
        choices.forEach(choice => choicesArray.push(
          {
            Attribute_Response : choice.trim() ,
            Assessment_Questionaire_Id: 0,
            Assessment_Questionnaire_OptionId: 0,
            IsActive: true,
            Status: 0,
          }))
    }else{
      choicesArray.push(
        {
          Attribute_Response : "" ,
          Assessment_Questionaire_Id: 0,
          Assessment_Questionnaire_OptionId: 0,
          IsActive: true,
          Status: 0,
        })
       isValid = false ;
      ismessage = ismessage ? ismessage : "Choices is requied"
    }

    
    return { isValid: isValid, message: ismessage ,  values : choicesArray  };
  };

  const department_uploadsheet_validation = () => {
    if (uploaddata) {
      let ValidatedData: any[] = [];
      uploaddata.forEach((item: any) => {
        if(item["SrNo"]){
        let error: string = "";
   
        let object: any = {
          SME: 0,
          IsSME: false,
          Answer_Type: "",
          EvidenceRequired: false,
          Question: "",
          count: "",
          assessmentQuestionnaireOptions : ''
        };

        let findValidDepartment = DepartmentList.find((typeitem: any) => typeitem.DepartmentName == item["Department"]);
        let findValidIsSme = item["Is SME Respond"];

        AQMasterCOBImportdata.map((header_item: any) => {


          if (header_item.Field === "question_type") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              if (item[header_item.DisplayName] == "Multiple Choice" || item[header_item.DisplayName] == "Free Text") {
                if (item[header_item.DisplayName] == "Multiple Choice") {
                  object.Answer_Type = "multiple";
                }
                if (item[header_item.DisplayName] == "Free Text") {
                  object.Answer_Type = "free";
                }
              } else {
                error += " Invalid Answer Type,";
              }
            } else {
              error += " Answer Type is Required,";
            }
          }

          if (header_item.Field === "question") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object.Question = item[header_item.DisplayName];
              const isDuplicateInUpload = uploaddata.some(
                (uploadItem: any) => uploadItem["Question"] === item[header_item.DisplayName] && uploadItem !== item
              );
    
              // Check for duplicate questions in existing data
              const isDuplicateInExisting = ExistingData.some(
                (existingItem: any) => existingItem["Question"] === item[header_item.DisplayName]
              );
    
              if (isDuplicateInUpload || isDuplicateInExisting) {
                error += " Duplicate Question Detected,";
              }
            } else {
              error += " Question is Required,";
            }
          }

        

          if (item["Answer Type"] == "Multiple Choice") {
         
            if (header_item.Field === "choices") {
              if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
                let ScoreVal = item["Choices Count"];
                if (ScoreVal) {
                  let choiceValidation = validateChoicesAndcount(item[header_item.DisplayName], ScoreVal);
                  object.count = ScoreVal
                  if (choiceValidation && choiceValidation?.isValid) {
                    object.assessmentQuestionnaireOptions = choiceValidation?.values;
                  } else {
                    object.assessmentQuestionnaireOptions = choiceValidation?.values;
                    error += `${choiceValidation?.message}`;
                  }
                }else{
                  error += " Choices count is Required,";
                }
              } else {
                error += " Choices is Required,";
              }
            }
          }

          if (header_item.Field === "evidence_required") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              if (item[header_item.DisplayName] == "Yes") {
                object.EvidenceRequired = true;
              } else if (item[header_item.DisplayName] == "No") {
                object.EvidenceRequired = false;
              } else {
                error += " Invalid Evidence Required Value,";
              }
            } else {
              object.EvidenceRequired = false;
            }
          }

          if (header_item.Field === "Is_SME_respond") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              if (item[header_item.DisplayName] == "Yes") {
                object.IsSME = true;
              } else if (item[header_item.DisplayName] == "No") {
                object.IsSME = false;
              } else {
                error += " Invalid Evidence Required Value,";
              }
            } else {
              object.IsSME = false;
            }
          }

          if (header_item.Field === "department") {
            if (findValidIsSme == "Yes") {
              if (findValidDepartment?.DepartmentId) {
                object.SME = findValidDepartment?.DepartmentId;
              } else {
                if (item[header_item.DisplayName]) {
                  error += " Invalid Department,";
                } else {
                  error += " Department is Required,";
                }
              }
            }
          }

          object.error = error;
        });
        ValidatedData.push(object);
      }
      });
      console.log(ValidatedData , 'Validatateddata');
      // pass parend component
      if(ValidatedData && ValidatedData?.length > 0){
        setselectedValidatateddata(ValidatedData);
      }else{
        setcommonError("Please Upload Valid data or check SrNo ")    
      }
  
    }
  };
  const validateValidData = () => {
          
    if(Validatateddata && Validatateddata.length == 0){
         return  []
    }else{
            
          return Validatateddata.filter((item) => !item.error )
    }
  }
  const handlebulkuploadAQMMappingForm = () => {
           let validata : any[] = validateValidData()
           if(validata && validata.length > 0){
            handleSubmitBulkAssessmentQuestion(validata)
           }
  }



  return (
    <>
      <Stack spacing={2}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <Box sx={{ width: "50%" }}>
            {excelsheet == null ? (
              <CustomFileUploader onFileUpload={handleFileUpload} maxFileSize={1048576} acceptFormats={[".xlsx"]} />
            ) : (
              <FilePreviewer onRemovefile={() => handleFileUpload(null)} file={excelsheet} />
            )}
          </Box>
        
        </Box>
        {Validatateddata?.length > 0 && <Box sx={{width:'100%'}}>
                  
                  <AQMMappingCOBQuestionsTable QuestionList={Validatateddata} view={true} isbulkview = {true} />
                  </Box> }

                  <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent : 'flex-end' , gap: 2 , width :'100%' }}>
          <CustomButton
            type="reset"
            variant="outlined"
            name={"Close"}
            onClick={() => {
                onclose &&
                onclose() 
            }}
          />
          <CustomButton type="reset"  disabled={commonError || Validatateddata?.length == 0 || validateValidData().length != Validatateddata?.length } name={"Submit"} onClick={() => handlebulkuploadAQMMappingForm()} />
        </Box> 
      </Stack>
    </>
  );
};
