import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { PMResponseSoCXO } from "./endpoint/PMResonse";

const CreatePMResponseSoandCXO = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(PMResponseSoCXO.CreateSoandCxoReview, requestBody)
    .then((response: AxiosResponse<any>) => {

      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const PmResponseService = {
    CreatePMResponseSoandCXO
};

export default PmResponseService;
