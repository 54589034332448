import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import { IVendor } from "../../../models/authorize.type";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { AddDepartment } from "./AddDepartment";
import { RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  getDepartmentList,
  updateDepartmentAddandEdit,
  deleteDepartment,
  updateVersionHistory,
  getDepartmentHistory,
} from "../../../store/slices/departmentData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import ProgressBar from "../../../common/progressBar/progressBar";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { IMenu } from "../../../store/slices/menu";
import { getSaveandDrafList, updateSaveasDraftAddandEdit } from "../../../store/slices/saveasdraft";
import { ReleaseRecordLock } from "../../../store/slices/recordLock";
import masterEditEnableService from "../../../store/services/masterEditEnableService";
import { DepartmentConfig, Edit_Enable_tosterMSG, FormIdConfig, LovConfig } from "../../../config/config";
import { HandleRecordLockCheck, RecordLockUpdate } from "../../recordLock/RecordLock";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";

let columns: any = {
  table_name: "Department",
  isAction: true,
  columns: [
    // {
    //   Field: "DepartmentId",
    //   FieldType: "number",
    //   DisplayName: "Id",
    // },
    {
      Field: "DepartmentName",
      FieldType: "string",
      DisplayName: "Department Name",
    },
    {
      Field: "Department_Code",
      FieldType: "string",
      DisplayName: "Department Code",
    },
    {
      Field: "Department_TypeValue",
      FieldType: "string",
      DisplayName: "Type",
    },
    {
      Field: "ParentDepartmentName",
      FieldType: "string",
      DisplayName: "Parent",
    },
    {
      Field: "Parent_LevelValue",
      FieldType: "number",
      DisplayName: "Level",
    },
    {
      Field: "IsActive",
      FieldType: "string",
      DisplayName: "Status",
    },
    {
      Field: "UpdatedBy",
      FieldType: "string",
      DisplayName: "Modified By",
    },
  ],
};

// const columns_version: any = {
//   table_name: "Department History",
//   isAction: false,
//   columns: [
//     {
//       Field: "Dep_id",
//       FieldType: "number",
//       DisplayName: "Dep Id",
//     },
//     {
//       Field: "department",
//       FieldType: "string",
//       DisplayName: "Department",
//     },
//   ],
// };

const Department = () => {
  const columns_version: any = {
    table_name: "Department Version History Records",
    isAction: false,
    columns: [],
  };

  const {
    dataTable,
    versionHistory,
    isDepartmentAdded,
    isDepartmentUpdated,
    isDepartmentDeleted,
    ToasterMsg,
    isLoading,
  } = useSelector((state: RootState) => state.departmentData);
  const { draftdata, isLoadingDraft, isDraftAdded, isDraftUpdated, isDraftDeleted } = useSelector(
    (state: RootState) => state.saveAsDraft
  );

  const dispatch = useAppDispatch();
  const [formDisplay, setformDisplay] = useState(true);
  const [addForm, setAddForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [Option, setOption] = useState("");
  const [HeaderColumn, setHeaderColumn] = useState(columns);
  const [departmentHistoryColumn, setdepartmentHistoryColumn] = useState(columns_version);
  const [departmentData, setDepartmentData] = useState<any[]>([]);
  const [isEditDate, setIsEditDate] = useState<any>({});
  const [isDraftDate, setIsDraftDate] = useState<any>(null);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [permssionPage, setPermissionForpage] = useState<any>();
  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
 
  const [versionHistoryData, getVersionHistoryData] = useState<any>();
  const [editId, setEditId] = useState<any>(0);
  const [lablename, setLableNAme] = useState<any>("Department");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const [ParentDepartmentData, setParentDepartmentData] = useState<any[]>([]);
  const [DepartmentTypelov, setDepartmentTypelov] = useState<any[]>([]);
  const [Levelslov, setLevelslov] = useState<any[]>([]);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { currentpageTitleData } = useSelector(
    (state: RootState) => state.FormTitleMasterData
  );
  // const {
  //   watch,

  // } = useForm<FormData>({});
  const { handleRecordLockUpdate } = RecordLockRelease();
  const { watch } = useForm<FormData>({});

  useEffect(() => {
    dispatch(
      getDepartmentList({
        FormID: 2,
      })
    );
    callDraftData();
  }, []);

  useEffect(() => {
    if (draftdata && draftdata.ScreenName === "Department" && draftdata.DraftData) {
      setIsDraftDate(draftdata);
    } else {
      setIsDraftDate(null);
    }
  }, [draftdata]);

  useEffect(() => {
    const Levelslov = lovTable.filter((item: any) => item.Name === LovConfig?.Department_Level) || [];

    setLevelslov(Levelslov);
    setDepartmentTypelov(lovTable.filter((item: any) => item.Name === LovConfig?.Department_Type) || []);
  }, [lovTable]);

  useEffect(() => {
    if (versionHistory.departmentVHeader.length > 0 && versionHistory.departmentVHeader.length > 0) {
      if (versionHistory.departmentVHeader && versionHistory.departmentVHeader.length > 0) {
        // setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory.departmentVHeader,
        };
        getVersionHistoryData(re_columns);
      }
    }
  }, [versionHistory]);

  useEffect(() => {

    setDepartmentData(dataTable);
  }, [dataTable]);

  const handleOpen = async (data: any, option: string) => {
   
    if (option === "delete") {
    
        setIsDeleteData(data);
      
    } else if (option === "history") {
      setIsVersionHistory(true);
      setOption(option);
      setIsEditDate(data);
      dispatch(
        getDepartmentHistory({
          departmentId: data.DepartmentId,
          formID: FormIdConfig?.departmentFormId,
        })
      );
    } else if (option === "edit") {
      setOption(option);
      setIsEditDate(data);
      setIsEdit(true);
     
    } else {
      setIsEdit(true);
      setOption(option);
      setIsEditDate(data);
    }
  };

  const callDraftData = () => {
    dispatch(updateSaveasDraftAddandEdit(false));
    dispatch(
      getSaveandDrafList({
        DraftId: 12,
      })
    );
  };

  const deleteDepartmentList = () => {

    let finededDeptType = DepartmentTypelov.find(
      (item) => item.Options === DepartmentConfig?.Department && isDeleteData.Department_Type == item.LovId
    );

    let JsonDataForDisplay;
    if (finededDeptType) {
      JsonDataForDisplay = {
        "Department Type": isDeleteData.Department_TypeValue,
        "Department Name": isDeleteData.DepartmentName,
        "Department Code": isDeleteData.Department_Code,
        Status: isDeleteData.IsActive === "Active" ? "Inactive" : "Active",
        "Modified By": userLoginResponse?.UserName,
      };
    } else {
      JsonDataForDisplay = {
        "Department Type": isDeleteData.Department_TypeValue,
        "Department Name": isDeleteData.DepartmentName,
        "Department Code": isDeleteData.Department_Code,
        "Parent Level": isDeleteData.Parent_LevelValue,
        "Parent Department Name": isDeleteData.ParentDepartmentName,
        Status: isDeleteData.IsActive === "Active" ? "Inactive" : "Active",
        "Modified By": userLoginResponse?.UserName,
      };
    }

    let formData = {
      Is_Active: isDeleteData.IsActive === "Active" ? false : true,
      Activity: isDeleteData.IsActive === "Active" ? "inactive" : "active",
      MasterId: isDeleteData.DepartmentId,
      DepartmentName: isDeleteData.DepartmentName,
      UserId: userLoginResponse?.UserId,
      MenuId: currentPagePermissionData?.MenuId,
      Form_Id: FormIdConfig?.departmentFormId,
      ModuleName: "Department Master",
      CreatedBy: userLoginResponse?.UserName,
      DataForDisplay: JSON.stringify(JsonDataForDisplay),
    };
    dispatch(deleteDepartment(formData));
  };

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    if (isDepartmentAdded) {
      dispatch(updateDepartmentAddandEdit(false));
      dispatch(updateLoader(false));
      // dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.Message,
          isTosterFailed: ToasterMsg.Status === false ? true : false,
        })
      );
      dispatch(
        getDepartmentList({
          FormID: 2,
        })
      );
    } else if (isDepartmentUpdated) {
      dispatch(updateDepartmentAddandEdit(false));
      dispatch(updateLoader(false));
      // dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.Message,
          isTosterFailed: ToasterMsg.Status === false ? true : false,
        })
      );

      dispatch(
        getDepartmentList({
          FormID: 2,
        })
      );
    } else if (isDepartmentDeleted) {
      setIsDeleteData(null);
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.Message,
          isTosterFailed: ToasterMsg.Status === "false" ? true : false,
        })
      );
      setTimeout(() => {
        dispatch(updateDepartmentAddandEdit(false));
      }, 1000);
      dispatch(
        getDepartmentList({
          FormID: 2,
        })
      );
    }
  }, [isDepartmentAdded, isDepartmentUpdated, isDepartmentDeleted]);

  useEffect(() => {
    if (isDraftAdded) {
      callDraftData();
    } else if (isDraftUpdated) {
      callDraftData();
      handleRecordLockUpdate()
    } else if (isDraftDeleted) {
      callDraftData();
    }
  }, [isDraftAdded, isDraftUpdated, isDraftDeleted]);


  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
             <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                           
                        </Typography>
          </Box>

          {false ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Grid
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "right",
                }}
              >
                <CustomButton
                  variant="contained"
                  name={"Add Department"}
                  onClick={() => setAddForm(true)}
                  size="small"
                />
              </Grid>
            </Box>
          ) : null}

          {!formDisplay ? (
            ""
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={HeaderColumn}
                rows={departmentData}
                handleAction={handleOpen}
                handleAdd={() => setAddForm(true)}
                enableZoomIcon={true}
                showhistory={true}
                showAdd={permssionPage?.IsAdd === 1}
                showDelete={permssionPage?.IsDelete === 1}
                showView={permssionPage?.IsView === 1}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={permssionPage?.IsExport === 1}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                pageName="Department Master"
                isEnableRecordLock = {{ IdField : "DepartmentId" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                  FormID : FormIdConfig?.departmentFormId ,
                  MenuID : currentPagePermissionData?.MenuId,
                  IsCheckPending : true  }}}
              />
            </Box>
          )}
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => {
          setAddForm(false);
          setLableNAme("Department");
        }}
        maxWidth={"xs"}
        // header={`Add ${lablename}`}
        header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Department"}`}

        contentNode={
          <>
            {" "}
            <AddDepartment
              ModuleId={permssionPage?.MenuId}
              isDraftData={isDraftDate}
              onClose={() =>  {setAddForm(false)} }
              onchangeLable={(value: any) => setLableNAme(value)}
            />{" "}
          </>
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          handleRecordLockUpdate();
          setIsEdit(false);
}}
        isEdit={true}
        maxWidth={"xs"}
        header={Option === "view" ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Department"}` : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Department"}`}
        contentNode={
          <>
            {" "}
            <AddDepartment
              view={Option === "view" ? true : false}
              edit={true}
              onClose={() => {
                handleRecordLockUpdate();
                setIsEdit(false);
              }}
              isEditData={isEditDate}
              ModuleId={permssionPage?.MenuId}
              onchangeLable={(value: any) => setLableNAme(value)}
            />{" "}
          </>
        }
      />
      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          dispatch(updateVersionHistory([]));
          setIsVersionHistory(false);
        }}
        maxWidth={"lg"}
        header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Department"} Version History`}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistory.departmentRecords}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Department Version History"
            />
          </>
        }
      />
      <ModalPopUp
        onDeleteMethod={() => deleteDepartmentList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${
          isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
        } the selected department`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />
    </>
  );
};

export default Department;
