import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { AddContractCostApproval } from "./AddContractCostApproval";
import { IMenu } from "../../../models/loggin.type";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import {
  getallCostApprovalMetricList,
  createCostApprovalMetric,
  updateCostApprovalMetric,
  activeInactiveCostApprovalMetric,
  updateCostApprovalMetricAddandEdit,
} from "../../../store/contractManagement/slices/costApprovalMetricSlice";
import { getLovList } from "../../../store/slices/lovMasterData";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import costApprovalMetricService from "../../../store/contractManagement/services/costApprovalMetricService";
import { Edit_Enable_tosterMSG } from "../../../config/config";
import { ACTIVE, INACTIVE } from "../../../utils/constants";
import { RecordLockRelease } from "../../recordLock/CommonRecordLock";

export interface RegionalSettingsProps {
  handlePrevious?: () => void;
  handleNext?: () => void;
}

export const ContractCostApproval: React.FC<RegionalSettingsProps> = ({ handlePrevious, handleNext }) => {
  const dispatch = useAppDispatchThunk();

  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    costApprovalMetric,
    CostApprovalMetricData,
    versionHistory,
    isCostApprovalMetricDataAdded,
    isCostApprovalMetricDataUpdated,
    isCostApprovalMetricDataDeleted,
    CostApprovalMetricToasterStatus,
    ToasterMsg,
  }: any = contractManagementReducer.costApprovalMetric;

  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [currencyFormData, setCurrencyFormData] = useState<any>([]);
  const [addForm, setAddForm] = useState(false);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [formDisplay, setformDisplay] = useState(false);
  const [filterTableData, setFilterTableData] = useState<any[]>([]);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [isDeleteClause, setIsDeleteClause] = useState(false);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const { ParentLocationTable } = useSelector((state: RootState) => state.locationMasterData);
  const { handleRecordLockUpdate } = RecordLockRelease();

  const { currentpageTitleData } = useSelector((state: RootState) => state.FormTitleMasterData);
  const columns_version: any = {
    table_name: "Contract Cost Approval Metrics",
    isAction: false,
    columns: [],
  };
  useEffect(() => {
    if (versionHistory && versionHistory?.ClauseListHeader?.length > 0) {
      if (versionHistory?.ClauseListHeader && versionHistory?.ClauseListHeader.length > 0) {
        setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory?.ClauseListHeader,
        };
        setVersionHistoryData(re_columns);
      }
    }
  }, [versionHistory]);


  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );

  const columns: any = {
    table_name: "Cost Approval Metrics",
    isAction: true,
    columns: [
      {
        Field: "from_Amount",
        DisplayName: "From Amount",
        FieldType: "string",
      },
      {
        Field: "to_Amount",
        DisplayName: "To Amount",
        FieldType: "string",
      },
      {
        Field: "approver_Name",
        DisplayName: "Approver  Name",
        FieldType: "string",
      },
      {
        Field: "approver_Email",
        DisplayName: "Approver Email",
        FieldType: "string",
      },
      {
        Field: "approver_Designation",
        DisplayName: "Approver Designation ",
        FieldType: "string",
      },
      {
        Field: "status",
        DisplayName: "Status",
        FieldType: "string",
      },
    ],
  };

  useEffect(() => {
    setCurrencyFormData(CostApprovalMetricData);
  }, [CostApprovalMetricData]);

  //console.log(CostApprovalMetricData, "Jayakumar");
  useEffect(() => {
    callMasterData();
  }, []);

  const callMasterData = () => {
    dispatch(getallCostApprovalMetricList({ FormID: 0 }));
  };

  const handleOpen = async (data: any, option: string) => {
    const updatedData = CostApprovalMetricData?.find(
      (i: any) => i.costApproval_Metrics_Id === data.costApproval_Metrics_Id
    );
    if (!updatedData) return;
    setOption(option);
    console.log(data);

    switch (option) {
      case "delete":
        setIsDeleteData(updatedData);
        setIsDeleteClause(true);
        break;
      case "history":
        setIsVersionHistory(true);
        setIsEditData(updatedData);
        // dispatch(getDepartmentHistory({ departmentId: data.ClauseTypeId, formID: 7 }));
        break;
      case "view":
        setIsEditData(updatedData);
        setIsEdit(true);
        break;
      case "edit":
        setIsEditData(updatedData);
        dispatch(updateLoader(true));
        const response = { data: true };
        if (response.data) {
          dispatch(updateLoader(false));
          setIsEdit(true);
        } else {
          dispatch(updateLoader(false));
        }
        break;
      default:
        setIsEdit(true);
        break;
    }
  };
  console.log(
    isCostApprovalMetricDataAdded,
    isCostApprovalMetricDataDeleted,
    isCostApprovalMetricDataUpdated,
    "SlicesResponses"
  );
  useEffect(() => {
    if (isCostApprovalMetricDataAdded) {
      dispatch(updateCostApprovalMetricAddandEdit(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg?.Message,
          isTosterFailed: ToasterMsg?.Status === "false" ? true : false,
        })
      );
      if (ToasterMsg?.Status !== "false") {
        setAddForm(false);
        callMasterData();
      }

      dispatch(updateLoader(false));
    } else if (isCostApprovalMetricDataUpdated) {
      dispatch(updateCostApprovalMetricAddandEdit(false));
      handleRecordLockUpdate();

      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg?.Message,
          isTosterFailed: ToasterMsg?.Status === "false" ? true : false,
        })
      );
      setIsEdit(false);
      dispatch(updateLoader(false));

      callMasterData();
    } else if (isCostApprovalMetricDataDeleted) {
      setIsDeleteData(null);
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg?.Message,
          isTosterFailed: ToasterMsg?.Status === "false" ? true : false,
        })
      );
      setTimeout(() => {
        dispatch(updateCostApprovalMetricAddandEdit(false));
      }, 1000);
      setIsDeleteClause(false);
      callMasterData();
    }
  }, [isCostApprovalMetricDataUpdated, isCostApprovalMetricDataAdded, isCostApprovalMetricDataDeleted]);

  const handleStatusChange = () => {
    const updatedStatus = isDeleteData.isActive === ACTIVE ? false : true;

    // Prepare the formData for activation/deactivation
    const formData = {
      costApproval_Metrics_Id: isDeleteData.costApproval_Metrics_Id,
      from_Amount: isDeleteData.from_Amount,
      to_Amount: isDeleteData.to_Amount,
      approver_Name: isDeleteData.approver_Name,
      approver_Email: isDeleteData.approver_Email,
      approver_Designation: isDeleteData.approver_Designation,
      isActive: updatedStatus,
      activity: updatedStatus ? ACTIVE : INACTIVE,
      UpdatedBy: user?.UserName,
      CreatedBy: user?.UserName,
      ModuleName: "Cost Approval Metrics",
      actionTaken: updatedStatus ? ACTIVE : INACTIVE,
      MenuId: currentPagePermissionData?.MenuId,
      Form_Id: 13,
      UserId: userLoginResponse?.UserId,
    };

    dispatch(activeInactiveCostApprovalMetric(formData));

    console.log(formData);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={currencyFormData}
                isActivekey="status"
                handleAction={handleOpen}
                enableZoomIcon={true}
                handleAdd={() => setAddForm(true)}
                showAdd={true}
                showhistory={true}
                showDelete={true}
                showView={true}
                showEdit={true}
                showExports={true}
                pageName="Cost Approval Metrics"
                isEnableRecordLock={{
                  IdField: "costApproval_Metrics_Id",
                  data: {
                    moduleName: currentPagePermissionData?.MenuName || "",
                    MenuID: currentPagePermissionData?.MenuId,
                    IsCheckPending: false,
                  },
                }}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            </Box>
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"xl"}
        minHeight={""}
        header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Cost Approval Metrics"}`}
        contentNode={
          <AddContractCostApproval
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          handleRecordLockUpdate();
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          setIsEdit(false);
        }}
        maxWidth={"xl"}
        minHeight={"50vh"}
        header={
          Option === "view"
            ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Cost Approval Metrics"}`
            : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Cost Approval Metrics"}`
        }
        contentNode={
          <>
            {Option === "edit" && (
              <AddContractCostApproval
                view={false}
                closePopUp={() => {
                  setIsEdit(false);
                  handleRecordLockUpdate();
                }}
                edit={isEdit}
                isEditData={IsEditData}
              />
              // <></>
            )}
            {Option === "view" && (
              <AddContractCostApproval
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                isEditData={IsEditData}
              />
              // <></>
            )}
          </>
        }
      />

     

      <ModalPopUp
        onDeleteMethod={handleStatusChange}
        //onDeleteMethod={() => Currency()}
        isDelete
        onHide={() => setIsDeleteClause(false)}
        show={isDeleteClause}
        header={"Confirm"}
        text={`Are you sure you want to ${
          isDeleteData?.isActive === ACTIVE ? "Deactivate" : "Activate"
        } the selected Cost Approval`}
        ActionName={isDeleteData?.isActive === ACTIVE ? INACTIVE : ACTIVE}
      />

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
        }}
        maxWidth={"lg"}
        header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Contract Cost Approval"} Version History`}
        contentNode={
          <>
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistory?.ClauseList}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Contract Cost Approval Version History"
            />
          </>
        }
      />
    </>
  );
};
