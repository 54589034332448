import { Box, FormHelperText, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { textFieldWidth } from "../../../utils/constants";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomCheckBox from "../../../common/dynamicInputs/CustomCheckBox";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import RichTextEditor from "react-rte";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import { RootState, useAppDispatch } from "../../../store/store";
import { addContentMaster, updatecontentMaster } from "../../../store/slices/contentMasterData";
import { useSelector } from "react-redux";
import ProgressBar from "../../../common/progressBar/progressBar";
import { updateLoader } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import InactivityTimer from "../../../common/InactivityTimer/InactivityTimer";
import { ApplicationParameterName } from "../../../config/config";

interface FormData {
  Content_Id?: any;
  Content_Purpose?: any;
  Content_Text?: any;
  IsActive?: any;
}

export interface vendorConsentProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  masterId?: string;
  onClose?: any;
  isDraftData?: any;
  ModuleId?: number | undefined;
}

export const AddContentMaster: React.FC<vendorConsentProps> = ({
  view,
  isEditData,
  edit,
  masterId,
  onClose,
  ModuleId,
}) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [IsCancel, setIsCancel] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const { isVendorConsentLoading, isVendorConsentAdded, isVendorConsentUpdated } = useSelector(
    (state: RootState) => state.contentData
  );
  const [timeout, setTimeout] = useState<any>(null);
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  let formData = {
    Content_Id: isEditData ? isEditData?.Content_Id : 0,
    Content_Purpose: isEditData ? isEditData?.Content_Purpose : "",
    Content_Text: isEditData
      ? RichTextEditor.createValueFromString(isEditData?.Content_Text, "html")
      : RichTextEditor.createEmptyValue(),
    IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
  };

  const {
    handleSubmit: handleconsentForm,
    reset,
    setValue,
    formState: { errors: errorConsent, isDirty },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const isActiveDisabled = watch("IsActive");

  useEffect(() => {
        if (edit) {
          const RecordTimeOut: any = ApplicationParameterName.RecordTimeOut;
          let findRecordTimeOut = applicationParameterData.find(
            (applicationParamitem) =>
              applicationParamitem.Parameter_Name === RecordTimeOut &&
              applicationParamitem.Parameter_Group === RecordTimeOut
          )?.Parameter_Value;
          setTimeout(Number(findRecordTimeOut));
        }
      }, []);

  useEffect(() => {
    if (isVendorConsentAdded || isVendorConsentUpdated) {
      if (onClose) {
        onClose();
        dispatch(updateLoader(false));
      }
    }
  }, [isVendorConsentAdded, isVendorConsentUpdated]);

  const handleSubmit = () => {
    let finalData = [];
    let request: any = { ...getValues() };
    request.Content_Text = request.Content_Text.toString("html");
    dispatch(updateLoader(true));

    let JsonDataForDisplay: any = {
      "Content Purpose": request.Content_Purpose,
      "Content Text": request.Content_Text,
      Status: request.IsActive ? "Active" : "InActive",
      "Modified By": user?.UserName,
    };

    request.MenuId = currentPagePermissionData?.MenuId;
    request.Form_Id = 15;
    request.UserId = userLoginResponse?.UserId;

    if (edit) {
      request.Updated_By = user?.UserName;
      request.Updated_On = new Date().toISOString();
      request.Status = 1;
      request.ModuleName = "Content Master";
      request.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      request.Activity = "Edit";
      finalData.push(request);
      dispatch(updatecontentMaster(request));
    } else {
      // request.CreatedBy = user?.UserName;
      request.Updated_By = user?.UserName;
      request.Updated_On = new Date().toISOString();
      request.ModuleName = "Content Master";
      request.Activity = "Create";
      request.Status = 1;
      request.DataForDisplay = JSON.stringify(JsonDataForDisplay);

      finalData.push(request);
      dispatch(addContentMaster(request));
    }
  };

  const handleCancelPopup = () => {
    if (isDirty) {
      setIsCancel(true);
    } else {
      onClose && onClose();
    }
  };

  return (
    <>
      <Box component="main" sx={{ height: "auto", width: "100%", overflow: "hidden", position: "relative" }}>
        {/* {isVendorConsentLoading  ? <ProgressBar showProgress={isVendorConsentLoading} /> : null} */}
        <Grid container spacing={2}>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
            sx={{ mt: 1 }}
          >
            <Controller
              name="Content_Purpose"
              control={control}
              defaultValue={""}
              rules={{
                required: "Content Purpose is Required",
                validate: (data: any) => {
                  return data.length >= 4 || "Minimal 4 characters requires";
                },
              }}
              render={({ field }) => (
                <CustomTextField
                  name={"Content Purpose"}
                  required={true}
                  // disabled={view || edit || !isActiveDisabled}
                  // disabled={view || edit || !isActiveDisabled}
                  label={"Content Purpose"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorConsent.Content_Purpose)}
                  helperText={errorConsent.Content_Purpose && errorConsent.Content_Purpose.message?.toString()}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="Content_Text"
              control={control}
              rules={{
                required: "Content Text is Required",
                validate: {
                  minimalLength: (value) => {
                    const text = value
                      ? value
                          .toString("html")
                          .replace(/<[^>]*>/g, "")
                          .trim()
                      : "";
                    return text.length === 0
                      ? "Content Text is Required"
                      : text.length >= 4 || "Minimal 4 characters required";
                  },
                },
              }}
              render={({ field }) => (
                <>
                  <RichTextEditor
                    readOnly={view || !isActiveDisabled}
                    placeholder={"Content Text"}
                    editorClassName="editor"
                    editorStyle={{ fontSize: "25px", height: width >= 768 ? "30vh" : "30vh" }}
                    value={field.value}
                    //   onChange={(x: any) => setText(x)}
                    onChange={field.onChange}
                  />

                  {errorConsent.Content_Text ? (
                    <FormHelperText
                      error={Boolean(errorConsent.Content_Text && errorConsent.Content_Text.message?.toString())}
                      id="my-helper-text"
                    >
                      {errorConsent.Content_Text && errorConsent.Content_Text.message?.toString()}
                    </FormHelperText>
                  ) : null}
                </>
              )}
            />
          </Grid>
          {/* {edit || view ? (
            <Grid
              item
              xs={textFieldWidth.xs}
              // sm={textFieldWidth.sm}
              // md={textFieldWidth.md}
              // lg={textFieldWidth.lg}
              // xl={textFieldWidth.xl}
              md={6}
            >
              <Box sx={{ m: 1 }}>
                <Controller
                  name="IsActive"
                  control={control}
                  render={({ field }) => (
                    <CustomToogleSwitch
                      label={"User Status"}
                      dynamicLabel={field.value ? "ACTIVE" : "INACTIVE"}
                      disabled={view}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Box>
            </Grid>
          ) : null} */}
        </Grid>

        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "right",
            mt: 2,
          }}
        >
          {!view ? (
            !edit ? (
              <>
                <CustomButton
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    handleCancelPopup();
                  }}
                />
                <CustomButton
                  variant="contained"
                  disabled={!isDirty}
                  name={"Submit"}
                  onClick={handleconsentForm(handleSubmit)}
                />
              </>
            ) : (
              <>
                <CustomButton
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    handleCancelPopup();
                  }}
                />
                <CustomButton
                  variant="contained"
                  disabled={!isDirty}
                  name={"Update"}
                  onClick={handleconsentForm(handleSubmit)}
                />
              </>
            )
          ) : (
            <CustomButton
              variant="outlined"
              name={"Cancel"}
              onClick={() => {
                onClose();
              }}
            />
          )}
        </Box>
      </Box>

      <ModalPopUp
        onDeleteMethod={() => onClose && onClose()}
        isDelete={true}
        onHide={() => setIsCancel(false)}
        show={IsCancel}
        header={"Confirm"}
        text={`Are you sure to cancel`}
        ActionName={"Yes"}
      />
      {timeout && (
        <InactivityTimer
          timeout={timeout}
          onTimeout={() => {}}
          renderPrompt={(isVisible, handleResponse) =>
            isVisible && (
              <ModalPopUp
                onDeleteMethod={() => {}}
                isDelete={false}
                onHide={() => handleResponse(false)}
                show={isVisible}
                header={"Confirm"}
                text={`Are you still editing?`}
                ButtonName={"Yes"}
              />
            )
          }
        />
      )}
    </>
  );
};
