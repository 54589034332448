import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiCatalogService from "../services/apiCatalog";
import { chkDate } from "../../utils/constants";
import { IApiCatalog } from "../../models/apiCatalog.type";

export interface IApiCatalogData {
    ApiCatalogTable: IApiCatalog[];
    isLoading: boolean;
    isApiCatalogAdded: boolean;
    isApiCatalogUpdated: boolean;
    ToasterMsg: { Message: string; Status: string };
    apiCatalogDatas: any;
    isCatalogRemoved: boolean;
}

export const initialState: IApiCatalogData = {
    ApiCatalogTable: [],
    isLoading: false,
    isApiCatalogAdded: false,
    isApiCatalogUpdated: false,
    ToasterMsg: { Message: "", Status: "" },
    apiCatalogDatas: {},
    isCatalogRemoved: false,
};

export const getAllApiCatalog = createAsyncThunk(
    "getAllApiCatalog",
    async (requestParams: { FormID: number }, { rejectWithValue }) => {
        try {
            const response = await ApiCatalogService.getAllApiCatalog();
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const saveApiCatalog = createAsyncThunk("saveApiMaster", async (requestParams: any, { rejectWithValue }) => {
    try {
        const response: any = await ApiCatalogService.saveApiCatalog(requestParams);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const updateApiCatalog = createAsyncThunk("updateApiMaster", async (requestParams: any, { rejectWithValue }) => {
    try {
        const response: any = await ApiCatalogService.updateApiCatalog(requestParams);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});
export const GetApiCatalogWithAllDetailsByID = createAsyncThunk("GetApiCatalogWithAllDetailsByID", async (requestParams: any, { rejectWithValue }) => {
    try {
        console.log("slice", requestParams)
        let reqbody = { ...requestParams };
        const response: any = await ApiCatalogService.GetApiCatalogWithAllDetailsByID(reqbody);
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error);
    }
});
export const RemoveApiCatalog = createAsyncThunk("RemoveApiCatalog", async (requestParams: any, { rejectWithValue }) => {
    try {
        const response: any = await ApiCatalogService.RemoveApiCatalog(requestParams);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});
// export const deleteCompany = createAsyncThunk("deleteCompany", async (requestParams: any, { rejectWithValue }) => {
//   try {
//     const response: any = await CompanyMasterService.deleteCompany(requestParams);
//     return response.data;
//   } catch (error) {
//     return rejectWithValue(error);
//   }
// });
// export const getImageData = createAsyncThunk("getImage", async (requestParams: any, { rejectWithValue }) => {
//   try {
//     const url = CompanyMasterService.getImage(requestParams.fileName);
//     return url;
//   } catch (error) {
//     return rejectWithValue(error);
//   }
// });
// export const getCompanyHistory = createAsyncThunk(
//   "getCompanyHistory",
//   async (requestParams: any, { rejectWithValue }) => {
//     try {
//       let reqbody = { ...requestParams };
//       const response: any = await CompanyMasterService.getVersionHistoryCompany(reqbody);
//       const { CompanyMasters, HeaderJson } = response.data;
//       return { CompanyHistories: CompanyMasters, HeaderJson: HeaderJson.ColumnHeaders };
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const ApiCatalogDataSlice = createSlice({
    name: "ApiCatalog",
    initialState,
    reducers: {
        apiCatalogAddandSave: (state, action) => {
            state.isApiCatalogAdded = false;
            state.isCatalogRemoved = false;
            state.isApiCatalogUpdated = false;
            //   state.isCompanyDeleted = false;
            //   state.isDuplicate = false;
            state.ToasterMsg.Message = action.payload.Message;
            state.ToasterMsg.Status = action.payload.Status;
        },
        updateUser: (state, action) => {
            if (action.payload.UserId) {
                let indexVendor = state.ApiCatalogTable.findIndex((data: any) => data.UserId === action.payload.UserId);
                let copyVendorData = [...state.ApiCatalogTable];
                copyVendorData[indexVendor] = action.payload;
                state.ApiCatalogTable = copyVendorData;
            }
        },
        // updateVersionHistory: (state, action) => {
        //   state.versionHistory.CompanyRecords = [];
        //   state.versionHistory.CompanyVHeader = [];
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllApiCatalog.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllApiCatalog.fulfilled, (state, action) => {
                state.ApiCatalogTable = action.payload.map((data: any) => {
                    data.IsActive = data.IsActive === true ? "Active" : "Inactive";
                    return data;
                });
                state.isLoading = false;
            })
            .addCase(getAllApiCatalog.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(saveApiCatalog.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(saveApiCatalog.fulfilled, (state, action) => {
                state.isApiCatalogAdded = true;
                state.isLoading = false;
                state.isCatalogRemoved = false;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(saveApiCatalog.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(updateApiCatalog.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateApiCatalog.fulfilled, (state, action: PayloadAction<any>) => {
                state.isApiCatalogUpdated = true;
                state.isLoading = false;
                // state.ToasterMsg = action.payload.Message;
                // state.ToasterMsg = action.payload.Message;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(updateApiCatalog.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(GetApiCatalogWithAllDetailsByID.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetApiCatalogWithAllDetailsByID.fulfilled, (state, action: PayloadAction<any>) => {
                state.apiCatalogDatas = action.payload
                console.log(action.payload, "action.payload.data")
                state.isLoading = false;
            })
            .addCase(GetApiCatalogWithAllDetailsByID.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(RemoveApiCatalog.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(RemoveApiCatalog.fulfilled, (state, action: PayloadAction<any>) => {
                state.isCatalogRemoved = true;
                state.isLoading = false;
                // state.ToasterMsg = action.payload.Message;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(RemoveApiCatalog.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
        //   .addCase(deleteCompany.pending, (state) => {
        //     state.isLoading = true;
        //   })
        //   .addCase(deleteCompany.fulfilled, (state, action: PayloadAction<any>) => {
        //     state.isCompanyDeleted = true;
        //     state.isLoading = false;
        //     // state.ToasterMsg = action.payload.Message;
        //     state.ToasterMsg.Message = action.payload.Message;
        //     state.ToasterMsg.Status = action.payload.Status;
        //   })
        //   .addCase(deleteCompany.rejected, (state, action: PayloadAction<any>) => {
        //     state.isLoading = false;
        //   })
        //   .addCase(getCompanyHistory.pending, (state, action: PayloadAction<any>) => {
        //     state.isLoading = false;
        //   })
        //   .addCase(getCompanyHistory.fulfilled, (state, action: PayloadAction<any>) => {
        //     state.versionHistory.CompanyVHeader = action.payload.HeaderJson;
        //     state.versionHistory.CompanyRecords = action.payload.CompanyHistories.map((company: any) => {
        //       company.IsActive = company.IsActive === true ? "Active" : "Inactive";
        //       company.Approved_On = chkDate(company.Approved_On);
        //       return company;
        //     });
        //     state.isLoading = false;
        //   })
        //   .addCase(getImageData.pending, (state) => {
        //     state.isLoading = true;
        //   })
        //   .addCase(getImageData.fulfilled, (state, action: PayloadAction<string>) => {
        //     state.isLoading = false;
        //     state.getImageUrl = action.payload;
        //   })
        //   .addCase(getImageData.rejected, (state, action: PayloadAction<any>) => {
        //     state.isLoading = false;
        //   });
    },
});

export const { apiCatalogAddandSave, updateUser } = ApiCatalogDataSlice.actions;
export default ApiCatalogDataSlice.reducer;
