import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomerSignOffservices from "../services/customerSignoffService";

export interface ICustomerSignOffData {
  CustomerSignOffdataTable: any;
  isCustomerSignOffLoading: boolean;
  isAddEditCustomerSignOffLoading: boolean;
  isCustomerSignOffAdded: boolean;
  CustomerSignOffToasterMsg: string;
}

export const initialState: ICustomerSignOffData = {
  CustomerSignOffdataTable: [],
  isCustomerSignOffLoading: false,
  isAddEditCustomerSignOffLoading: false,
  isCustomerSignOffAdded: false,
  CustomerSignOffToasterMsg: "",
};

export const getCustomerSignOffbyId = createAsyncThunk(
  "getCustomerSignOffbyId ",
  async (requesrParams: { customerId : any , project_Detail_Id : any }, { rejectWithValue }) => {
    try {
      const response = await CustomerSignOffservices.getVendorList (requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addCustomerSignOff = createAsyncThunk("addCustomerSignOff", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response: any = await CustomerSignOffservices.addVendor(requesrParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});



export const customerSignOffDataSlice = createSlice({
  name: "customerSignOff",
  initialState,
  reducers: {

    updateCustomerSignOff:  (state, action)=>{            
      state.isCustomerSignOffAdded = false ;
      state.CustomerSignOffToasterMsg = "" ;
    
    }, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerSignOffbyId .pending, (state) => {
        state.isCustomerSignOffLoading = true;
      })
      .addCase(getCustomerSignOffbyId .fulfilled, (state, action: PayloadAction<any>) => {
        state.CustomerSignOffdataTable = action.payload
        state.isCustomerSignOffLoading = false;
      })
      .addCase(getCustomerSignOffbyId .rejected, (state, action: PayloadAction<any>) => {
        state.isCustomerSignOffLoading = false;
      })
      .addCase(addCustomerSignOff.pending, (state) => {
   
        state.isAddEditCustomerSignOffLoading = true;
      })
      .addCase(addCustomerSignOff.fulfilled, (state, action: PayloadAction<any>) => {
        state.isCustomerSignOffAdded = true;
      
        state.isAddEditCustomerSignOffLoading = false;
        state.CustomerSignOffToasterMsg = action.payload.Message;
      })
      .addCase(addCustomerSignOff.rejected, (state, action: PayloadAction<any>) => {
    
        state.isAddEditCustomerSignOffLoading = false;
      })
      

  },
});

export const { updateCustomerSignOff } = customerSignOffDataSlice.actions;
export default customerSignOffDataSlice.reducer;
