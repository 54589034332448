import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { CONTRACT_LIST_URL } from "./endpoints/contractList";
import {
  IContractGetResponse,
  IContractListResponse,
} from "../../../models/contractManagementModels/contractListModels";

const getContractDetailList = (requestBody: any): Promise<IContractListResponse> =>
  axiosInstance
    .post(CONTRACT_LIST_URL.GetAllContractList, requestBody)
    .then((response: AxiosResponse<IContractListResponse>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Service Catalog data: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const enableDisableContract = (requestBody: any): Promise<any> =>
  axiosInstance
    .put(CONTRACT_LIST_URL.EnableOrDisableContract, requestBody)
    .then((response: AxiosResponse<IContractGetResponse>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const createContract = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(CONTRACT_LIST_URL.CreateContract, requestBody)
    .then((response: AxiosResponse<IContractGetResponse>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateContract = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(CONTRACT_LIST_URL.UpdateContract, requestBody)
    .then((response: AxiosResponse<IContractGetResponse>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const sendEmailForcontractVendor = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(CONTRACT_LIST_URL.sendEmailForcontractVendor, requestBody)
    .then((response: AxiosResponse<IContractGetResponse>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const boardApproval = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(CONTRACT_LIST_URL.boardApproval, requestBody)
    .then((response: AxiosResponse<IContractGetResponse>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getContractById = (id: any): Promise<any> =>
  axiosInstance
    .get(`${CONTRACT_LIST_URL.VendorContractById}/${id}`)
    .then((response: AxiosResponse<IContractGetResponse>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getContractDetailsById = (id: any): Promise<any> =>
  axiosInstance
    .get(`${CONTRACT_LIST_URL.getContractDetailsById}/${id}`)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const contractListService = {
  getContractDetailList,
  enableDisableContract,
  createContract,
  updateContract,
  getContractById,
  sendEmailForcontractVendor,
  boardApproval,
  getContractDetailsById,
};

export default contractListService;
