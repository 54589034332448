import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { AddVendorConsentForm } from "../../consentForms/AddVendorConsentForm";
import { AddContentMaster } from "./AddContentMaster";
import { VendorConsentWithdrawalForm } from "../../vendorWithdrawal/VendorConsentWithdrawalForm";
import {
  deletecontentMaster,
  getContentForm,
  getContentHistory,
  getContentList,
  updateContentAddandEdit,
  updateVersionHistory,
} from "../../../store/slices/contentMasterData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import { ContentNameConfig, Edit_Enable_tosterMSG, FormIdConfig } from "../../../config/config";
import masterEditEnableService from "../../../store/services/masterEditEnableService";

import { RecordLockRelease } from "../../recordLock/CommonRecordLock";


let columns: any = {
  table_name: "Content",
  isAction: true,
  columns: [
    {
      Field: "Content_Purpose",
      FieldType: "string",
      DisplayName: "Content Purpose",
    },

    {
      Field: "Content_Text",
      FieldType: "string",
      DisplayName: "Content Text",
    },
    {
      Field: "IsActive",
      FieldType: "string",
      DisplayName: "Status",
    },
    {
      Field: "Updated_By",
      DisplayName: "Modified By",
      FieldType: "string",
    },
  ],
};

const ContentMaster = () => {
  const dispatch = useAppDispatch();

  const columns_version: any = {
    table_name: "Content Record",
    isAction: false,
    columns: [],
  };

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const {
    vendorConentPurposes,
    vendorConentDataTable,
    vendorConsentToasterMsg,
    versionHistory,
    isVendorConsentLoading,
    isVendorConsentAdded,
    isVendorConsentUpdated,
    isVendorConsentDeleted,
  } = useSelector((state: RootState) => state.contentData);
  const [HeaderColumn, setHeaderColumn] = useState(columns);
  const [HistoryColumn, setHistoryColumn] = useState([]);
  const [vendorConsentData, setVendorConsentData] = useState<any[]>([]);
  const [isEditDate, setIsEditDate] = useState<{ id?: string }>({});
  const [isEdit, setIsEdit] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [Option, setOption] = useState("");
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [addForm, setAddForm] = useState<any>(false);
  const [permssionPage, setPermissionForpage] = useState<any>();
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [isEditDataForm, setisEditDataForm] = useState<any>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { currentpageTitleData } = useSelector(
    (state: RootState) => state.FormTitleMasterData
  );

    const { handleRecordLockUpdate } = RecordLockRelease();
  

  useEffect(() => {
    setVendorConsentData(vendorConentDataTable);
  }, [vendorConentDataTable]);

  useEffect(() => {
    callPAgeLoadData();
  }, []);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    console.log(vendorConentPurposes);
    let EditObject = { Welcometext: "", ContestText: "" };

    vendorConentPurposes.map((item: any) => {
      if (item.Content_Purpose === ContentNameConfig.vendorContext) {
        EditObject.Welcometext = item.Content_Text;
      }
      if (item.Content_Purpose === ContentNameConfig.vendorContext) {
        EditObject.ContestText = item.Content_Text;
      }
    });
    setisEditDataForm(EditObject);
  }, [vendorConentPurposes]);

  useEffect(() => {

    if (versionHistory.VendorConsentVHeader && versionHistory.VendorConsentVHeader.length > 0) {
      setIsVersionHistory(true);
      let re_columns = {
        ...columns_version,
        columns: versionHistory.VendorConsentVHeader,
      };
      setVersionHistoryData(re_columns);
    }
  }, [versionHistory]);

  console.log(vendorConsentData, "vendorConsentData");

  const callPAgeLoadData = () => {
    dispatch(
      getContentList({
        FormID: 2,
      })
    );

    const consentPurposesArray = ["vendorwelcometext", "vendorconsenttext"];

    const queryString = consentPurposesArray.reduce((acc, purpose, index) => {
      acc[`consentPurposes[${index}]`] = purpose;
      return acc;
    }, {} as Record<string, string>);
    dispatch(getContentForm(queryString));
  };

  useEffect(() => {
    if (isVendorConsentAdded === true) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: vendorConsentToasterMsg }));
      dispatch(updateContentAddandEdit(false));
      callPAgeLoadData();
    }

    if (isVendorConsentUpdated === true) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: vendorConsentToasterMsg }));
      handleRecordLockUpdate()
      dispatch(updateContentAddandEdit(false));
      callPAgeLoadData();
    }
    if (isVendorConsentDeleted === true) {
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: vendorConsentToasterMsg }));
      dispatch(updateContentAddandEdit(false));
      callPAgeLoadData();
    }
  }, [isVendorConsentAdded, isVendorConsentUpdated, isVendorConsentDeleted]);

 

  const handleOpen = async (data: any, option: string) => {
   
    if (option === "delete") {

      setIsDeleteData(data);
      setOption(option);
     
    } else if (option === "history") {
      dispatch(
        getContentHistory({
          contentId: data.Content_Id,
          formID: FormIdConfig?.ContentFormId,
        })
      );
    } else if (option === "edit") {
      setOption(option);
      setIsEditDate(data);
      setIsEdit(true);
     
      
    } else {
      setIsEdit(true);
      setOption(option);
      setIsEditDate(data);
    }
  };
  const setAddFormcloseoropen = (data: any) => {
    setAddForm(data);
  };

  const deleteconsentList = () => {
    let JsonDataForDisplay: any = {
      "Content Purpose": isDeleteData.Content_Purpose,
      "Content Text": isDeleteData.Content_Text,
      Status: isDeleteData.IsActive === "Active" ? "InActive" : "Active",
      "Modified By": user?.UserName,
    };

    dispatch(
      deletecontentMaster({
        ContentId: isDeleteData.Content_Id,
        Content_Purpose: isDeleteData.Content_Purpose,
        CreatedBy: user?.UserName,
        IsActive: isDeleteData.IsActive === "Active" ? false : true,
        Activity: isDeleteData.IsActive === "Active" ? "inactive" : "active",
        DataForDisplay: JSON.stringify(JsonDataForDisplay),
        ModuleName: "Content Master",
        MenuId: currentPagePermissionData?.MenuId,
        Form_Id: 15,
        UserId: userLoginResponse?.UserId,
      })
    );
  };

 
  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
              <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                            {/* Holiday Master */}
                        </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <MaterialDynamicGrid
              data={HeaderColumn}
              rows={vendorConsentData}
              handleAction={handleOpen}
              handleAdd={() => setAddFormcloseoropen(true)}
              enableZoomIcon={true}
              showDelete={permssionPage?.IsDelete === 1}
              showhistory={true}
              showAdd={permssionPage?.IsAdd === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
               isEnableRecordLock = {{ IdField : "Content_Id" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                                FormID : FormIdConfig?.ContentFormId,
                                              
                                                MenuID : currentPagePermissionData?.MenuId,
                                                IsCheckPending : true  }}}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Contest Master"
            />
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          dispatch(updateVersionHistory([]));
          setIsVersionHistory(false);
        }}
        maxWidth={"lg"}
        header={`${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Content"} Version History`}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistory.VendorConsentRecords}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Department Version History"
            />
          </>
        }
      />

      <CustomDialog
        show={addForm}
        onHide={() => setAddFormcloseoropen(false)}
        maxWidth={"md"}
        header={`Add ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Content"}`}
        contentNode={
          <>
            {" "}
            <AddContentMaster onClose={() => setAddFormcloseoropen(false)} ModuleId={permssionPage?.MenuId} />{" "}
          </>
        }
      />

      <ModalPopUp
        onDeleteMethod={() => deleteconsentList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${
          isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
        } the selected Consent`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {setIsEdit(false);
          handleRecordLockUpdate();
        }}
        maxWidth={"md"}
        header={Option === "view" ? `View ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Content"}` : `Update ${currentpageTitleData ? currentpageTitleData?.ActionTitle : "Content"}`}
        contentNode={
          <>
            {" "}
            <AddContentMaster
              view={Option === "view" ? true : false}
              edit={true}
              onClose={() => {setIsEdit(false);
                handleRecordLockUpdate()
              }}
              isEditData={isEditDate}
              ModuleId={permssionPage?.MenuId}
            />{" "}
          </>
        }
      />
    </>
  );
};

export default ContentMaster;
