export const CONTRACT_LIST_URL = {
    GetAllContractList: `/VendorContract/VendorContracts`,    
    EnableOrDisableContract: "/VendorContract/EnableOrDisableVendorContract",
    CreateContract: "/VendorContract/InitiateVendorContract",
    UpdateContract: "/VendorContract/UpdateVendorContract",
    VendorContractById: "/VendorContract",
    getContractDetailsById:'/getContractDetailsById',
    sendEmailForcontractVendor: "/CostApproval/SendVendorReviewEmail",
    boardApproval : "/CostApproval/SubmitBoardApproval",
  };
  