import { useSelector } from "react-redux";
import masterEditEnableService from "../../store/services/masterEditEnableService";
import recordLockService from "../../store/services/recordLockService";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import { ReleaseRecordLock, SetRecordLockData } from "../../store/slices/recordLock";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { Edit_Enable_tosterMSG } from "../../config/config";

export interface IsItemWaitingForApprovalprops {
  FormID: any;
  recordId: any;
}

export interface CommonRecordLockandPendingCheckpropsFromPageData {
  moduleName: string;
  FormID?: any;
  MenuID: any;
  IsCheckPending: any;
}

export interface CommonRecordLockandPendingCheckpropsDataGrid {
  IdField: string;
  data: CommonRecordLockandPendingCheckpropsFromPageData;
}

export interface CommonRecordLockandPendingCheckprops {
  recordId: any;
  permissionPage: any;
  moduleName: string;
  dispatch: any;
  FormID?: any;
  MenuID: any;
  IsCheckPending: any;
  activity?: any;
}

interface RecordLockData {
  moduleName: string;
  FormID: any;
  MenuID: any;
  IsCheckPending: boolean;
}

interface IsEnableRecordLockProps {
  IdField: string;
  dispatch: any;
  data: RecordLockData;
  userLoginResponse: any;
  rowData: any;
}

export const CommonRecordLockandPendingCheck = async (props: CommonRecordLockandPendingCheckprops) => {
  const { recordId, permissionPage, moduleName, dispatch, FormID, MenuID, IsCheckPending, activity } = props;
  let RecordWaitingApproveOrNot = true;
  if (IsCheckPending && FormID) {
    RecordWaitingApproveOrNot = await IsItemWaitingForApproval({ recordId, FormID });
  }

  if (RecordWaitingApproveOrNot === false) {
    dispatch(
      updateToaster({
        isToaster: true,
        toasterMsg: `${Edit_Enable_tosterMSG.message}`,
        isTosterFailed: true,
      })
    );
    return false;
  } else {
    const checkRecordLockedOrNot: any = await GetRecordLockData({ MenuID, moduleName, recordId });
    const PendingOrNot: any = checkRecordLockedOrNot;

    if (PendingOrNot?.length > 0 && PendingOrNot[0]?.UserId !== permissionPage?.UserId) {
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: `${PendingOrNot[0]?.CreatedBy} is Editing`,
          isTosterFailed: true,
        })
      );
      return false;
    } else {
      try {
        // incase old record lock is not realse old value store again and close the form that time release the record
        if (PendingOrNot?.length > 0 && PendingOrNot[0]?.UserId == permissionPage?.UserId) {
          dispatch(
            SetRecordLockData({
              RecordlockId: PendingOrNot[0]?.RecordlockId,
              RecordId: recordId,
              IsActive: false,
            })
          );
          return true;
        } else {
          const AddRecordLockedOrNot: any = await recordLockService.AddRecordLock({
            RecordId: recordId,
            UserId: permissionPage?.UserId || 0,
            CreatedBy: permissionPage?.UserName || "",
            MenuId: MenuID,
            ModuleName: moduleName,
            Activity: activity ? activity : "Edit",
          });

          const AddedOrNot: any = AddRecordLockedOrNot;
          if (AddedOrNot) {
            const checkRecordaftersave: any = await GetRecordLockData({ MenuID, moduleName, recordId });

            dispatch(
              SetRecordLockData({
                RecordlockId: checkRecordaftersave[0]?.RecordlockId,
                RecordId: recordId,
                IsActive: false,
              })
            );
            return AddedOrNot;
          } else {
            updateToaster({
              isToaster: true,
              toasterMsg: `Somthing wrong in add Record lock , Please try after some time`,
              isTosterFailed: true,
            });
            return false;
          }
        }
      } catch {
        updateToaster({
          isToaster: true,
          toasterMsg: `Somthing wrong in add Record lock , Please try after some time`,
          isTosterFailed: true,
        });
        return false;
      }
    }
  }
};

export const isEnableRecordLock = async ({
  IdField,
  dispatch,
  data,
  rowData,
  userLoginResponse,
}: IsEnableRecordLockProps): Promise<any> => {
  let isOkToEdit = true;

  const recordLockUseData = data;
  dispatch(updateLoader(true));

  try {
    isOkToEdit = await CommonRecordLockandPendingCheck({
      recordId: rowData[IdField],
      permissionPage: userLoginResponse,
      moduleName: recordLockUseData?.moduleName,
      dispatch: dispatch,
      FormID: recordLockUseData?.FormID,
      MenuID: recordLockUseData?.MenuID,
      IsCheckPending: recordLockUseData?.IsCheckPending,
      activity: "Edit",
    });
  } catch (error) {
    console.error("Error in record lock check:", error);
    isOkToEdit = false;
  } finally {
    dispatch(updateLoader(false));
  }

  return isOkToEdit;
};

export const GetRecordLockData = async (props: any) => {
  const checkRecordLockedOrNot: any = await recordLockService.GetRecordLock({
    MenuId: props.MenuID,
    ModuleName: props.moduleName,
    RecordId: props.recordId,
  });

  return checkRecordLockedOrNot;
};

export const IsItemWaitingForApproval = async (props: IsItemWaitingForApprovalprops) => {
  const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: props.recordId, FormID: props.FormID });
  const PendingOrNot: any = response;
  return PendingOrNot?.data;
};

export const RecordLockRelease = () => {
  const dispatch = useAppDispatchThunk();
  const { lockeRecorddData } = useSelector((state: RootState) => state.recordLock);

  const handleRecordLockUpdate = () => {
    if (lockeRecorddData) {
      const data = lockeRecorddData;
      dispatch(ReleaseRecordLock(data));
    }
  };

  return { handleRecordLockUpdate };
};
