import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";

import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { textFieldWidth } from "../../../utils/constants";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { addEditRegional, getRegionalList, updateRegionalAddandEdit } from "../../../store/slices/regionalSettingsData";
import { getParentLocationList } from "../../../store/slices/locationMasterData";
import ProgressBar from "../../../common/progressBar/progressBar";
import { getLovList } from "../../../store/slices/lovMasterData";
import { ApplicationParameterName, LovConfig } from "../../../config/config";
import { updateLoader } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import InactivityTimer from "../../../common/InactivityTimer/InactivityTimer";

interface FormData {
  RegionalSettingId?: number;
  LocationId: string;
  Equivalent: string;
  Setting: string;
  DataType: string;
  Format: string;
  DataWidth: string;
  IsMandatory: string;
  IsActive: string;
}

export interface RegionalSettingsProps {
  view?: boolean;
  closePopUp?: () => void;
  edit?: boolean;
  isEditData?: any;
}

export const AddRegionalSettings: React.FC<RegionalSettingsProps> = ({ view, closePopUp, edit, isEditData }) => {
  const dispatch = useAppDispatchThunk();
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const { formConfiguration } = useSelector((state: RootState) => state.customFormFields);
  const [message, setMessage] = useState<boolean>(false);
  const [IsCancel, setIsCancel] = useState<boolean>(false);
  const [ParentLocationData, setParentLocationData] = useState<any[]>([]);
  const { RegionalTable, isRegionalAdded, isRegionalDeleted, ToasterMsg, isLoading } = useSelector(
    (state: RootState) => state.RegionalSettingsData
  );
  const { ParentLocationTable } = useSelector((state: RootState) => state.locationMasterData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  console.log(lovTable, "lovTableregion");
  const filteredLovTableDataType = lovTable.filter((item: any) => item.Name === LovConfig.Data_Type);
  const { applicationParameterData} = useSelector(
    (state: RootState) => state.applicationParameter
  );
  const [timeout, setTimeout] = useState<any>(null);

  useEffect(() => {
    console.log(ParentLocationTable, "dataTable");

    setParentLocationData(ParentLocationTable);
  }, [ParentLocationTable]);

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
  }, []);

  useEffect(() => {
    let locationTypeList = lovTable.filter((item: any) => item.Name == "Location Type");
    let countryTypeId: any = locationTypeList.find((item: any) => item.Options === "Country");
    if (countryTypeId && countryTypeId.LovId) {
      dispatch(getParentLocationList({ LocationtypeId: countryTypeId.LovId }));
    }
  }, [lovTable, isEditData]);
  useEffect(() => {
    if (edit) {
      const RecordTimeOut: any = ApplicationParameterName.RecordTimeOut;
      let findRecordTimeOut = applicationParameterData.find(
        (applicationParamitem) =>
          applicationParamitem.Parameter_Name === RecordTimeOut &&
          applicationParamitem.Parameter_Group === RecordTimeOut
      )?.Parameter_Value;
      setTimeout(Number(findRecordTimeOut));
    }
  }, []);

  console.log(isEditData, "isEditData");

  let formData: any = {
    RegionalSettingId: isEditData ? isEditData?.RegionalSettingId : 0,
    LocationId: isEditData ? isEditData?.LocationId : "",
    Equivalent: isEditData ? isEditData?.Equivalent : "",
    Setting: isEditData ? isEditData?.Setting : "",
    DataType: isEditData ? isEditData?.DataType : "",
    DataWidth: isEditData ? isEditData?.DataWidth : "",
    Format: isEditData ? isEditData?.Format : "",
    IsMandatory: isEditData ? (isEditData?.IsMandatory === "Yes" ? true : false) : true,
    IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
  };

  const {
    handleSubmit: handleRegionalSettings,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });
  const handleSubmit = (data: any) => {
    console.log(getValues());
    let formData = data;
    dispatch(updateLoader(true));

    // setLoader(true);
    formData.MenuId = currentPagePermissionData?.MenuId;
    formData.Form_Id = 11;
    formData.ModuleName = "Regional Settings";
    formData.Remarks = "";
    formData.UserId = userLoginResponse?.UserId;
    formData.CreatedBy = user?.UserName;
    formData.Status = 1;
    formData.DataWidth = "0";
    let JsonDataForDisplay: any = {
      Country: ParentLocationData.find((item: any) => item.Location_Id == formData?.LocationId)?.Location_Name || "",
      Setting: formData?.Setting || "",
      Equivalent: formData?.Equivalent || "",
      "Data Type": filteredLovTableDataType.find((item: any) => item.LovId == formData?.DataType)?.Options || "",
      // "Data Width": formData?.DataWidth || "",
      "Is Mandatory": formData.IsMandatory ? "Yes" : "No",
      Format: formData?.Format || "",
      Status: formData?.IsActive ? "Active" : "InActive" ,
      "Modified By": user?.UserName,
    };
    console.log(JsonDataForDisplay);

    if (edit) {
      formData.IsActive = formData.IsActive ? true : false;
      formData.IsMandatory = formData.IsMandatory ? true : false;
      formData.Activity = "Edit";
      console.log(formData, " edit formData");
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      dispatch(addEditRegional(formData));
    } else {
      formData.Activity = "Create";
      formData.IsMandatory = formData.IsMandatory ? true : false;
      formData.IsActive = true;
      console.log(formData, "formData");
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      dispatch(addEditRegional(formData));
    }
  };

  let isActiveDisabled = watch("IsActive");
  console.log(isActiveDisabled, "isActivedisable");

  useEffect(() => {
    if (isRegionalAdded) {
      dispatch(
        getRegionalList({
          FormID: 2,
        })
      );
      dispatch(updateRegionalAddandEdit(false));
      reset(formData);
      if (closePopUp) {
        closePopUp();
        // setLoader(false);
        dispatch(updateLoader(false));

      }
    }
  }, [isRegionalAdded]);

  const handleCancelPopup = () => {

    if (isDirty) {
      setIsCancel(true);
    } else {
      closePopUp && closePopUp();
      reset(formData);
    }
  };

  return (
    <>
      <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
        <Grid
          // item
          // item xs={12} sm={12} md={6} lg={6} xl={6}
          sx={{
            backgroundImage: `url()`,
            backgroundRepeat: "no-repeat",
            // backgroundColor: (t) =>
            //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
            // backgroundSize: "cover",
            backgroundPosition: "center",
            // height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            margin: "0",
          }}
        >
          <Grid
            sx={{
              height: "auto",
              width: "100%",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Box
              sx={{
                px: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
              <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="LocationId"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Country is required" }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"LocationId"}
                          valueKey={"Location_Id"}
                          optionLabelKey="Location_Name"
                          options={ParentLocationData}
                          disabled={view || !isActiveDisabled}
                          required={true}
                          label={"Country"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsloginform.LocationId)}
                          helperText={errorsloginform.LocationId && errorsloginform.LocationId.message?.toString()}
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="Setting"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "Setting Name is Required",
                        validate: {
                          noLeadingSpaces: (value) => !/^\s/.test(value ?? "") || "Leading spaces are not allowed",
                          maxLength: (value) => value.length < 50 || "Maximum 50 characters requires",
                          ValueLimitation: (value) =>
                            /^[a-zA-Z]+$/.test(value.trim()) ||
                            "Name cannot contain special characters, numbers and spaces",
                        },
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          name={"Setting"}
                          required={true}
                          disabled={view || !isActiveDisabled}
                          label={"Setting Name"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsloginform.Setting)}
                          helperText={errorsloginform.Setting && errorsloginform.Setting.message?.toString()}
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="Equivalent"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "Equivalent is Required",
                        validate: {
                          noLeadingSpaces: (value) => !/^\s/.test(value ?? "") || "Leading spaces are not allowed",
                          maxLength: (value) => value.length < 50 || "Maximum 50 characters requires",
                          ValueLimitation: (value) =>
                            /^[a-zA-Z]+$/.test(value.trim()) ||
                            "Name cannot contain special characters, numbers and spaces",
                        },
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          name={"Equivalent"}
                          required={true}
                          disabled={view || !isActiveDisabled}
                          label={"Equivalent"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsloginform.Equivalent)}
                          helperText={errorsloginform.Equivalent && errorsloginform.Equivalent.message?.toString()}
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="DataType"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Data Type is required" }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"DataType"}
                          valueKey={"LovId"}
                          optionLabelKey="Options"
                          options={filteredLovTableDataType}
                          disabled={view || !isActiveDisabled}
                          required={true}
                          label={"Data Type"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsloginform.DataType)}
                          helperText={errorsloginform.DataType && errorsloginform.DataType.message?.toString()}
                        />
                      )}
                    />
                  </Grid>

                  {/* <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="DataWidth"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Data Width Field is Required",
                      // validate: (data) => {
                      //     const number = parseInt(data, 10);
                      //     // Check if it's a valid number and falls within the specified range
                      //     if (isNaN(number) || number < 0 || number > 99999999) {
                      //         return "Please enter a number between 0 and 99999999";
                      //     }
                      // },
                      validate: {
                        noNegative: (value) => parseFloat(value) >= 0 || "Negative values are not allowed",
                        maxDigits: (value) => /^\d{1,20}$/.test(value) || "Please enter up to 20 digits only",
                      },
                    }}
                    render={({ field }) => (
                      <CustomNumberField
                        name={"DataWidth"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Data Width"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.DataWidth)}
                        helperText={errorsloginform.DataWidth && errorsloginform.DataWidth.message?.toString()}
                      />
                    )}
                  />
                </Grid> */}

                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="Format"
                      control={control}
                      defaultValue={""}
                      rules={{
                        validate: {
                          noLeadingSpaces: (value) => !value || !/^\s/.test(value) || "Leading spaces are not allowed",
                          maxLength: (value) => !value || value.length < 50 || "Maximum 50 characters required",
                        },
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          name={"Format"}
                          required={false}
                          disabled={view || !isActiveDisabled}
                          label={"Format"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsloginform.Format)}
                          helperText={errorsloginform.Format && errorsloginform.Format.message?.toString()}
                        />
                      )}
                    />
                  </Grid>

                  {/* {edit || view ? ( */}
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Box sx={{ mt: 1 }}>
                      <Controller
                        name="IsMandatory"
                        control={control}
                        render={({ field }) => (
                          <CustomToogleSwitch
                            label={"IsMandatory"}
                            dynamicLabel={"Is Mandatory"}
                            disabled={view || !isActiveDisabled}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  {/* // ) : null} */}

                  {/* {edit || view ? (
                                    <Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >
                                        <Box sx={{ mt: 1 }}>
                                            <Controller
                                                name="IsActive"
                                                control={control}
                                                render={({ field }) => (
                                                    <CustomToogleSwitch
                                                        label={"User Status"}
                                                        dynamicLabel={field.value ? 'ACTIVE' : 'INACTIVE'}

                                                        disabled={view}
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                    />
                                                )}
                                            />
                                        </Box>

                                    </Grid>
                                ) : null} */}
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "right",
                    mt: 2,
                  }}
                >
                  {view ? (
                    <CustomButton
                      type="reset"
                      name={"Cancel"}
                      onClick={() => {
                        closePopUp && closePopUp();
                      }}
                    />
                  ) : edit ? (
                    <React.Fragment>
                      <CustomButton
                        type="reset"
                        variant="outlined"
                        name={"Cancel"}
                        onClick={() => {
                          // reset(formData);
                          handleCancelPopup();
                        }}
                      />
                      <CustomButton
                        type="reset"
                        disabled={!isDirty}
                        name={"Update"}
                        onClick={handleRegionalSettings(handleSubmit)}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <CustomButton
                        type="reset"
                        variant="outlined"
                        name={"Cancel"}
                        onClick={() => {
                          // reset(formData);
                          handleCancelPopup();
                        }}
                      />
                      <CustomButton
                        type="reset"
                        disabled={!isDirty}
                        name={"Submit"}
                        onClick={handleRegionalSettings(handleSubmit)}
                      />
                    </React.Fragment>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* <ToastContainer 
      position='top-right'
      autoClose={3000}
      /> */}
      </Grid>
      <ModalPopUp
        onDeleteMethod={() => {closePopUp && closePopUp(); reset(formData);}}
        isDelete={true}
        onHide={() => setIsCancel(false)}
        show={IsCancel}
        header={"Confirm"}
        text={`Are you sure to cancel`}
        ActionName={"Yes"}
      />
       {timeout && (
        <InactivityTimer
          timeout={timeout}
          onTimeout={() => {}}
          renderPrompt={(isVisible, handleResponse) =>
            isVisible && (
              <ModalPopUp
                onDeleteMethod={() => {}}
                isDelete={false}
                onHide={() => handleResponse(false)}
                show={isVisible}
                header={"Confirm"}
                text={`Are you still editing?`}
                ButtonName={"Yes"}
              />
            )
          }
        />
       )}
    </>
  );
};
