import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import contractListService from "../services/contractListService";
import { IContractApiDetails, IContractDetails } from "../../../models/contractManagementModels/contractListModels";
import moment from "moment";

export interface IContractDetailsData {
  contractDetailsData: IContractDetails[];
  isContractDetailsLoading: boolean;
  isContractDetailsAdded: boolean;
  isContractDetailsUpdated: boolean;
  isContractDetailsDeleted: boolean;
  isContractDetails: any;
  ToasterMsg: { message: string; status: string };
  workFLowInstanceData: any;
  contractData: any;
}

export const initialState: IContractDetailsData = {
  contractDetailsData: [],
  isContractDetailsLoading: false,
  isContractDetailsAdded: false,
  isContractDetailsUpdated: false,
  isContractDetailsDeleted: false,
  ToasterMsg: { message: "", status: "" },
  isContractDetails: null,
  contractData: null,
  workFLowInstanceData: null,
};

export const getContractList = createAsyncThunk("getContractList", async () => {
  const response: any = await contractListService.getContractDetailList({});
  const data = response.vendorContractList.map((i: IContractApiDetails): IContractDetails => {
    return {
      contract_Number: i.contract_Ref_Number,
      contractId: i.vendor_Contract_Id,
      contractType: i?.contractType?.contractType_Name,
      amount: i.amount,      
      startDate: i.startDate ?  i.startDate: null,      
      endDate: i.endDate ? i.endDate : null,
      services:
        i?.serviceMappings?.length > 0
          ? i?.serviceMappings?.map((item: any) => item.services.service_Name).join(",")
          : "",
      company: i?.company ? i?.company?.company_Id : 0,
      companyName: i?.company ? i?.company?.company_Name : 0,
      vendor: i?.vendor ? i?.vendor?.vendor_Id : 0,
      vendorName: i?.vendor ? i?.vendor?.legal_Name : 0,
      isActive: i?.isActive ? "Active" : "Inactive",
      workflow_StatusId: i?.workflow_StatusId,
      workflowStatus: i?.workflow_Status === "Completed" ? true : false,
      workflowStatusString: i?.workflow_Status,
    };
  });
  return data;
});

export const getContractById = createAsyncThunk("getContractById", async (id: any, { rejectWithValue }) => {
  try {
    const response: any = await contractListService.getContractById(id);
    return response;
  } catch (error) {
    console.log(error);
  }
});

export const activeInactiveContract = createAsyncThunk(
  "activeInactiveContract",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const data: any = await contractListService.enableDisableContract(requestBody);
      return data;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);

export const createContract = createAsyncThunk("createContract", async (requestBody: any, { rejectWithValue }) => {
  try {
    const response: any = await contractListService.createContract(requestBody);
    return response;
  } catch (e: any) {
    //rejectWithValue({message:"Contract Added SuccessFully", status: "Success"})
    return rejectWithValue({ message: e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage });
  }
});

export const updateContract = createAsyncThunk("updateContract", async (requestBody: any, { rejectWithValue }) => {
  try {
    const response: any = await contractListService.updateContract(requestBody);
    return response;
  } catch (e: any) {
    //rejectWithValue({message:"Contract Added SuccessFully", status: "Success"})
    return rejectWithValue({ message: e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage });
  }
});
export const getContractDetailsById = createAsyncThunk(
  "getContractDetailsById",
  async (id: any, { rejectWithValue }) => {
    try {
      const response: any = await contractListService.getContractDetailsById(id);
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);
export const contractListSlice = createSlice({
  name: "contractListSlice",
  initialState,
  reducers: {
    updateContractDetailsAddandEdit: (state, action) => {
      state.isContractDetailsAdded = false;
      state.isContractDetailsUpdated = false;
      state.isContractDetailsDeleted = false;
      state.ToasterMsg = { message: "", status: "" };
    },
    clearData: (state, action) => {
      state.contractData = null;
    },
    updateVersionHistory: (state, action) => {
      console.log(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContractList.pending, (state) => {
        state.isContractDetailsLoading = true;
      })
      .addCase(getContractList.fulfilled, (state, action: PayloadAction<any>) => {
        state.contractDetailsData = action.payload;
        state.isContractDetailsLoading = false;
      })
      .addCase(getContractList.rejected, (state) => {
        state.isContractDetailsLoading = false;
      })
      
      .addCase(createContract.pending, (state) => {
        state.isContractDetailsLoading = true;
      })
      .addCase(createContract.fulfilled, (state, action: PayloadAction<any>) => {
        state.isContractDetailsLoading = false;
        state.isContractDetailsAdded = true;
        state.ToasterMsg = action?.payload?.toaster;
        state.workFLowInstanceData = action?.payload;
      })
      .addCase(createContract.rejected, (state, action: PayloadAction<any>) => {
        state.isContractDetailsLoading = false;
        state.isContractDetailsAdded = true;
        state.ToasterMsg = action?.payload;
      })
      .addCase(updateContract.pending, (state) => {
        state.isContractDetailsLoading = true;
      })
      .addCase(updateContract.fulfilled, (state, action: PayloadAction<any>) => {
        state.isContractDetailsLoading = false;
        state.isContractDetailsUpdated = true;
        state.ToasterMsg = action?.payload?.toaster;
      })
      .addCase(updateContract.rejected, (state, action: PayloadAction<any>) => {
        state.isContractDetailsLoading = false;
        state.isContractDetailsUpdated = true;
        state.ToasterMsg = action?.payload;
      })
      .addCase(getContractById.pending, (state) => {
        state.isContractDetailsLoading = true;
      })
      .addCase(getContractById.fulfilled, (state, action: PayloadAction<any>) => {
        state.isContractDetailsLoading = false;
        state.isContractDetails = action?.payload;
      })
      .addCase(getContractById.rejected, (state, action: PayloadAction<any>) => {
        state.isContractDetailsLoading = false;
      })
      .addCase(getContractDetailsById.pending, (state) => {
        state.isContractDetailsLoading = true;
      })
      .addCase(getContractDetailsById.fulfilled, (state, action: PayloadAction<any>) => {
        state.isContractDetailsLoading = false;
        state.isContractDetails = action?.payload;
      })
      .addCase(getContractDetailsById.rejected, (state, action: PayloadAction<any>) => {
        state.isContractDetailsLoading = false;
      })
      .addCase(activeInactiveContract.pending, (state) => {
        state.isContractDetailsLoading = true;
      })
      .addCase(activeInactiveContract.fulfilled, (state, action: PayloadAction<any>) => {
        state.isContractDetailsLoading = false;
        state.isContractDetailsDeleted = true;
        state.ToasterMsg = action?.payload?.toaster;
      })
      .addCase(activeInactiveContract.rejected, (state) => {
        state.isContractDetailsLoading = false;
      });
  },
});

export const { updateContractDetailsAddandEdit, updateVersionHistory, clearData } = contractListSlice.actions;
export default contractListSlice.reducer;
