import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SeverityMasterService from "../services/severityMaster";
import { ISeverityMaster } from "../../../models/performanceManagementModels/severityMaster.type";
import PmRRreviewService from "../services/pmRRreviewService";

export interface IPMRandRData {
    RandRTable: ISeverityMaster[];
    isLoading: boolean;
    isRandRAdded: boolean;
    ToasterMsg: { Message: string; Status: string };
    reviewDetails: any;
}

export const initialState: IPMRandRData = {
    RandRTable: [],
    isLoading: false,
    isRandRAdded: false,
    ToasterMsg: { Message: "", Status: "" },
    reviewDetails: [],
};

export const getAllPMRandRReview = createAsyncThunk(
    "getAllPMRandRReview",
    async (requestParams: { FormID: number }, { rejectWithValue }) => {
        try {
            const response = await PmRRreviewService.getAllRandRReview();

            const { reviewDetails } = response;
            return reviewDetails;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const CreateRandRReview = createAsyncThunk("saveRandRReview", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await PmRRreviewService.saveRandRReview(requesrParams);
        return response.toaster;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const PmRandRReviewDataSlice = createSlice({
    name: "SeverityMaster",
    initialState,
    reducers: {
        PmRandRAddandSave: (state, action) => {
            state.isRandRAdded = false;
            state.ToasterMsg.Message = action.payload.message;
            state.ToasterMsg.Status = action.payload.status;
        },
        updateUser: (state, action) => {
            if (action.payload.UserId) {
                let indexVendor = state.RandRTable.findIndex((data: any) => data.UserId === action.payload.UserId);
                let copyVendorData = [...state.RandRTable];
                copyVendorData[indexVendor] = action.payload;
                state.RandRTable = copyVendorData;
            }

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllPMRandRReview.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllPMRandRReview.fulfilled, (state, action) => {                
                state.RandRTable = action.payload.map((data: any) => {
                    data.IsActive = data.isActive === true ? "Active" : "Inactive";
                    data.isActive = data.isActive === true ? "Active" : "Inactive";
                    return data;
                });
                console.log(state.RandRTable,"state.SeverityMasterTable");
                
                state.isLoading = false;
            })
            .addCase(getAllPMRandRReview.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(CreateRandRReview.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(CreateRandRReview.fulfilled, (state, action: PayloadAction<any>) => {

                state.isRandRAdded = true;
                state.isLoading = false;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;                
            })
            .addCase(CreateRandRReview.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.ToasterMsg = action.payload.response.data;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
    },
});

export const { PmRandRAddandSave, updateUser } = PmRandRReviewDataSlice.actions;
export default PmRandRReviewDataSlice.reducer;
