import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { PM_CONTRACT_LIST_URL } from "./endpoints/PMcontractList";


const getContractViewDetail = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(PM_CONTRACT_LIST_URL.GetAllContractView, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Service Catalog data: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getContractViewWithdaterange = (requestBody: any): Promise<any> =>
  axiosInstance
    .get(PM_CONTRACT_LIST_URL.GetContractViewBasedDate, { params: requestBody })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getPerfomanceScopeDetail = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(PM_CONTRACT_LIST_URL.GetPerformanceScopeList, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error getting Service Catalog data: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

    const getClauseDetailsByContrctId = (requestBody: any): Promise<any> =>
      axiosInstance
    .get(PM_CONTRACT_LIST_URL.GetClauseDetailsByContrctId, { params: requestBody })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const PMContractService = {
  getContractViewDetail,
  getContractViewWithdaterange,
  getPerfomanceScopeDetail,
  getClauseDetailsByContrctId
};

export default PMContractService;
