import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { useSelector } from "react-redux";
import ProgressBar from "../../common/progressBar/progressBar";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { getWorkList, deleteWorkflowbyId, updateWorkFlowAddandEdit } from "../../store/slices/workFlowSlice";
import { IWorkflowData } from "../../models/workFlow.type";
import { useNavigate } from "react-router-dom";
import CreateWorkFlow from "./CreateWorkFlow";
// import AddResponse from "./addResponse";
import { updateToaster } from "../../store/slices/loader";
import { RolesandResponsibility } from "../vendorOnBoarding/rolesAndResponsibility/RolesAndResponsibility";
import { VOBMainWorkFlow } from "../vendorOnBoarding/vendorOnBoardingFromWorkflow/VOBMain";
import { getLovList } from "../../store/slices/lovMasterData";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { IMenu } from "../../models/loggin.type";
import { ToasterActivityConfig, WorkFlowType } from "../../config/config";
import { COBMainWorkFlow } from "../customerOnBoarding/customerOnBoardingFromWorkFlow/COBMain";
import { CMMMainWorkFlow } from "../contractManagement/CMOnBoardingFromWorkflow/CMMMainWorkFlow";
import { RecordLockRelease } from "../recordLock/CommonRecordLock";

const WorkFlow: React.FC = () => {
  const dispatch = useAppDispatchThunk();
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const [loader, setLoader] = useState<boolean>(false);
  const [workFlowData, setWorkFlowData] = useState<IWorkflowData[]>([]);
  const { workFlowsData, WorkflowToasterMsg, isWorkFlowCreated, isWorkFlowDeleted } = useSelector(
    (state: RootState) => state.workFlow
  );
  const [editWorkFlowData, setEditWorkFlowData] = useState<any>();
  const [editWorkFlowrunData, setEditWorkFlowrunData] = useState<any>();
  const [openWorkFlowForm, setOpenWorkFlowForm] = useState<boolean>(false);
  const [vendorOnBoardingForm, setVendorOnboardingForm] = useState<boolean>(false);
  const [customerOnboardingForm, setCustomerOnboardingForm] = useState<boolean>(false);
  const [cmOnboardingForm, setCMOnboardingForm] = useState<boolean>(false);
  const [editMode, setEditMode] = useState("");
  const [permissionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);

  const { currentpageTitleData } = useSelector(
    (state: RootState) => state.FormTitleMasterData
  );

    const { handleRecordLockUpdate } = RecordLockRelease();
  
  const navigate = useNavigate();

  const columns: any = {
    table_name: "WorkFlow",
    isAction: true,
    columns: [
      {
        Field: "WorkflowName",
        DisplayName: "Workflow Name",
        FieldType: "string",
      },
      {
        Field: "WorkflowCategoryName",
        DisplayName: "Workflow Category",
        FieldType: "string",
      },
      {
        Field: "WorkflowTypeName",
        DisplayName: "Type",
        FieldType: "string",
      },
      {
        Field: "WorkflowCode",
        DisplayName: "Workflow Code",
        FieldType: "string",
      },
      {
        Field: "WorkflowDescription",
        DisplayName: "Description",
        FieldType: "string",
      },
      {
        Field: "TAT",
        DisplayName: "Estimated Time(In Days)",
        FieldType: "number",
      },
      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",
      },
    ],
  };

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    dispatch(getWorkList());
    dispatch(getLovList({ Name: "" }));
  }, []);

  useEffect(() => {
    setWorkFlowData(workFlowsData);
  }, [workFlowsData]);

  useEffect(() => {
    if (isWorkFlowCreated) {
      handleRecordLockUpdate()
      dispatch(updateToaster({ isToaster: true, toasterMsg: WorkflowToasterMsg?.Message }));
      dispatch(getWorkList());
    }
    if (isWorkFlowDeleted) {
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: WorkflowToasterMsg?.Message }));
      dispatch(updateWorkFlowAddandEdit(false));
      dispatch(getWorkList());
    }
  }, [isWorkFlowCreated, isWorkFlowDeleted]);

  const handleOpen = (data: any, option: string) => {
    if (option === "edit" || option === "view") {
      setOpenWorkFlowForm(true);
      setEditWorkFlowData(data);
      setEditMode(option);
    } else if (option === "run") {
      console.log('Handle Action',data)
      const foundCategoryItem = lovTable?.find((item: any) => item.LovId === data?.WorkflowCategory)?.Options;
      if (WorkFlowType?.vendorOnboarding === foundCategoryItem) {
        setVendorOnboardingForm(true);
        setEditWorkFlowrunData(data);
      }
      if (WorkFlowType?.customerOnboarding === foundCategoryItem) {
        setVendorOnboardingForm(true);
        setEditWorkFlowrunData(data);
      }
      if(WorkFlowType?.contractManagement === foundCategoryItem){
        setCMOnboardingForm(true);
        setEditWorkFlowrunData(data);
      }
      if (!foundCategoryItem) {
        setVendorOnboardingForm(true);
        setEditWorkFlowrunData(data);
      }
    } else if (option === "delete") {
      setIsDeleteData(data);
    }
  };

  const deleteWorkflow = () => {
    const formData = {
      WorkflowId: isDeleteData.Id,
      Updated_By: userLoginResponse && userLoginResponse.UserName ? userLoginResponse.UserName : "",
      IsActive: isDeleteData.IsActive === "Active" ? false : true,
      ModuleName: permissionPage?.MenuName,
      Activity: isDeleteData?.IsActive === "Active" ? ToasterActivityConfig.inactive : ToasterActivityConfig.active,
    };
    dispatch(deleteWorkflowbyId(formData));
  };

  return (
    <>
      {openWorkFlowForm && (
        <CreateWorkFlow
          view={editMode == "view" ? true : false}
          edit={editMode == "edit" ? true : false}
          editWorkFlowData={editWorkFlowData}
          onClose={() => {
            handleRecordLockUpdate();
            setOpenWorkFlowForm(false);
          }}
        />
      )}
      {vendorOnBoardingForm && editWorkFlowrunData && (
        <VOBMainWorkFlow data={editWorkFlowrunData} onClose={() => setVendorOnboardingForm(false)} />
      )}
      {customerOnboardingForm && editWorkFlowrunData && (
        <COBMainWorkFlow data={editWorkFlowrunData} onClose={() => setCustomerOnboardingForm(false)} />
      )}
      {cmOnboardingForm && editWorkFlowrunData ?(
        <CMMMainWorkFlow data={editWorkFlowrunData} onClose={() => setCMOnboardingForm(false)} />
      ): null}

      {!vendorOnBoardingForm && !openWorkFlowForm && !customerOnboardingForm && !cmOnboardingForm && (
        <Grid
          container
          component="main"
          sx={{ height: "auto", width: "100%", overflow: "hidden", position: "relative" }}
        >
          {loader ? <ProgressBar showProgress={loader} /> : null}
          <Box>
          <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {currentpageTitleData ? currentpageTitleData?.ViewTitle : currentPagePermissionData?.MenuName}
                            {/* Holiday Master */}
                        </Typography>
          </Box>
          <Grid
            sx={{
              width: "100%",
            }}
          >
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <Box>
                <Box sx={{ display: "flex", flexDirection: "column", py: 2 }}>
                  <MaterialDynamicGrid
                    data={columns}
                    rows={workFlowData}
                    handleAction={handleOpen}
                    enableZoomIcon={true}
                    showhistory={false}
                    showDelete={permissionPage?.IsDelete === 1}
                    showView={true}
                    showRun={true}
                    showEdit={true}
                    showExports={true}
                    showAdd={true}
                    handleAdd={() => {
                      setEditWorkFlowData(null);
                      setOpenWorkFlowForm(true);
                      setEditMode("");
                    }}
                     isEnableRecordLock = {{ IdField : "WorkflowId" , data :  {   moduleName : currentPagePermissionData?.MenuName || "",
                                                      // FormID : FormIdConfig?.IndustryFormId,
                                                    
                                                      MenuID : currentPagePermissionData?.MenuId,
                                                      IsCheckPending : false  }}}
                    userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                    pageName="Workflow Manager"
                    isRunDisableByValue={[
                      { key: "IsActive", value: "Active" },
                      { key: "WorkflowTypeName", value: "Main" },
                    ]}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      <ModalPopUp
        onDeleteMethod={() => deleteWorkflow()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${
          isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
        } the selected Workflow`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />
    </>
  );
};

export default WorkFlow;
