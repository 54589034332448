import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PMEventLogService from "../services/pmEventlogservice";
import PmRRreviewService from "../services/pmRRreviewService";
import PmResponseService from "../services/pMResponseService";


export interface PMResponseData {
    isPMResponseLoading: boolean;
    pMResponseAAdded: boolean;
    PMResponseToasterMsg: { Message: string; Status: string };

}

export const initialState: PMResponseData = {

    isPMResponseLoading: false,
    pMResponseAAdded: false,
    PMResponseToasterMsg: { Message: "", Status: "" },

};



export const CreatePMResponseSoandCXO = createAsyncThunk("CreatePMResponseSoandCXO", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await PmResponseService.CreatePMResponseSoandCXO(requesrParams);

        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const PMResponseDataSlice = createSlice({
    name: "PMResponseData",
    initialState,
    reducers: {
        PMResponseAddandEditUpdate: (state, action) => {
            state.pMResponseAAdded = false;
            state.PMResponseToasterMsg.Message = "";
            state.PMResponseToasterMsg.Status = "";
        }
      
    },
    extraReducers: (builder) => {
        builder
            .addCase(CreatePMResponseSoandCXO.pending, (state) => {
                state.isPMResponseLoading = true;
            })
            .addCase(CreatePMResponseSoandCXO.fulfilled, (state, action) => {                
                state.isPMResponseLoading = false;
                state.pMResponseAAdded = true;
                state.PMResponseToasterMsg.Message = action.payload.message;
                state.PMResponseToasterMsg.Status = action.payload.status;
                })
           
            .addCase(CreatePMResponseSoandCXO.rejected, (state) => {
                state.isPMResponseLoading = false;
            })

          
           
    },
});

export const { PMResponseAddandEditUpdate } = PMResponseDataSlice.actions;
export default PMResponseDataSlice.reducer;
