import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SeverityMasterService from "../services/severityMaster";
import { IThresholdMaster } from "../../../models/performanceManagementModels/thresholdMaster.type";
import ThresholdMasterMasterService from "../services/thresholdMaster";

export interface IThresholdMasterData {
    ThresholdTable: IThresholdMaster[];
    isLoading: boolean;
    isThresholdMasterAdded: boolean;
    isThresholdMasterUpdated: boolean;
    ToasterMsg: { Message: string; Status: string };
    ThresholdMasterDatas: any;
    ThresholdMasterDetails: any;
    isThresholdMasterRemoved: boolean;
}

export const initialState: IThresholdMasterData = {
    ThresholdTable: [],
    isLoading: false,
    isThresholdMasterAdded: false,
    isThresholdMasterUpdated: false,
    ToasterMsg: { Message: "", Status: "" },
    ThresholdMasterDatas: {},
    ThresholdMasterDetails: [],
    isThresholdMasterRemoved: false,
};

export const getAllThresholdMaster = createAsyncThunk(
    "getAllThresholdMaster",
    async (requestParams: { FormID: number }, { rejectWithValue }) => {
        try {
            const response = await ThresholdMasterMasterService.getAllThresholdMaster();

            const { thresholdLists } = response;
            return thresholdLists;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const CreateThresholdMaster = createAsyncThunk("CreateThresholdMaster", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await ThresholdMasterMasterService.saveThresholdMaster(requesrParams);
        return response.toaster;
    } catch (error) {
        return rejectWithValue(error);
    }
});
export const updateThresholdMaster = createAsyncThunk("updateThresholdMaster", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await ThresholdMasterMasterService.updateThresholdMaster(requesrParams);
        console.log(requesrParams, "requesrParams");

        return response.toaster;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteThresholdMaster = createAsyncThunk("deleteThresholdMaster", async (requestParams: any, { rejectWithValue }) => {
    try {
        const response: any = await ThresholdMasterMasterService.deleteThresholdMaster(requestParams);
        return response.toaster;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const ThresholdMasterDataSlice = createSlice({
    name: "ThresholdMaster",
    initialState,
    reducers: {
        ThresholdMasterAddandSave: (state, action) => {
            state.isThresholdMasterAdded = false;
            state.isThresholdMasterUpdated = false;
            state.isThresholdMasterRemoved = false;
            //   state.isCompanyDeleted = false;
            //   state.isDuplicate = false;
            state.ToasterMsg.Message = action.payload.message;
            state.ToasterMsg.Status = action.payload.status;
        },
        updateUser: (state, action) => {
            if (action.payload.UserId) {
                let indexVendor = state.ThresholdTable.findIndex((data: any) => data.UserId === action.payload.UserId);
                let copyVendorData = [...state.ThresholdTable];
                copyVendorData[indexVendor] = action.payload;
                state.ThresholdTable = copyVendorData;
            }

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllThresholdMaster.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllThresholdMaster.fulfilled, (state, action) => {                
                state.ThresholdTable = action.payload.map((data: any) => {
                    data.IsActive = data.isActive === true ? "Active" : "Inactive";
                    data.isActive = data.isActive === true ? "Active" : "Inactive";
                    console.log(state.ThresholdTable,"state.ThresholdTable");
                    
                    return data;
                });
                state.isLoading = false;
            })
            .addCase(getAllThresholdMaster.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(CreateThresholdMaster.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(CreateThresholdMaster.fulfilled, (state, action: PayloadAction<any>) => {

                state.isThresholdMasterAdded = true;
                state.isLoading = false;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(CreateThresholdMaster.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                // state.ToasterMsg = action.payload.response.data;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(updateThresholdMaster.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateThresholdMaster.fulfilled, (state, action: PayloadAction<any>) => {

                state.isThresholdMasterUpdated = true;
                state.isLoading = false;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(updateThresholdMaster.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                // state.ToasterMsg = action.payload.response.data;
                state.ToasterMsg.Message = action.payload.message;
                state.ToasterMsg.Status = action.payload.status;
            })
            .addCase(deleteThresholdMaster.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteThresholdMaster.fulfilled, (state, action: PayloadAction<any>) => {
                state.isThresholdMasterRemoved = true;
                console.log(state.isThresholdMasterRemoved, "state.isSeverityMasterRemoved");

                state.isLoading = false;
                // state.ToasterMsg = action.payload.Message;
            })
            .addCase(deleteThresholdMaster.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
    },
});

export const { ThresholdMasterAddandSave, updateUser } = ThresholdMasterDataSlice.actions;
export default ThresholdMasterDataSlice.reducer;
